import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable()
export class ForgotPasswordService {
  public userCredential: Array<any>;

  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
    this.userCredential = [];
  }

  submitEmail(forgotPasswordObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/forgotPassword';
    return this.http
      .post(url, forgotPasswordObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  wrongOTPAttemptCheck(forgotPasswordObject): Promise<any> {
    const url =
      environment.nonValidateUrl + 'user/wrongOTPAttemptCheckForgotPassword';
    return this.http
      .post(url, forgotPasswordObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  submitOTP(userObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/validateForgotPasswordCode';
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  resetPassword(passwordObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/changePassword';
    return this.http
      .post(url, passwordObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  setUserCredential(userCredential) {
    this.userCredential.push(userCredential);
  }

  getUserCredential() {
    return this.userCredential;
  }

  clearUserCredential() {
    this.userCredential = [];
  }

  changePassword(passwordObject): Promise<any> {
    const url = environment.clientUrl + 'user/changeSystemGeneratedPassword';
    return this.httpClient
      .post(url, passwordObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
