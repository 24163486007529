import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgAddToCalendarService, ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal, NgbModalRef, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { GfmService } from '../../../services/gfm.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { Router } from '@angular/router';
import { MeetingType } from '../../../enums/meetingType'
import { RocketChatService } from "../../../services/rocketChat.service";
import { UserMasterService } from '../../../services/user-master.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { WebexService } from '../../../../app/services/webex.service';

declare var $: any;
declare var jQuery: any;

@Component({
  providers: [RocketChatService],
  selector: 'app-meetingdetails',
  templateUrl: './meetingdetails.component.html',
  styleUrls: ['./meetingdetails.component.css']
})
export class MeetingdetailsComponent implements OnInit {

  public googleCalendarEventUrl: SafeUrl;
  public iCalendarEventUrl: SafeUrl;
  public outLookEventUrl: SafeUrl;
  public yahooEventUrl: SafeUrl;
  public microsoftEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;
  NotRegularMeetingTab: boolean = false;
  complianceEngine: any;
  complianceEngineUrl: any;
  governanceUrl: any;
  gemAccessURL: any;
  isMeetingStarted: boolean = false;
  isButtonDisplayByRole: boolean = false;
  showMeetingInitiateBtn: boolean = false;
  isCompanySecretary: boolean = false;
  CSUserId: any;
  joinMeetingUserId: any;
  showJoinButton: boolean = false;
  hasMeetingStarted: boolean = false;
  joinSuccessMsg: boolean = false;
  initiateSuccessMsg: boolean = false;
  hasMeetingEnd: boolean = false;
  GemWeb: boolean = environment.Service.GemWeb;
  showAlertModal: boolean = false;
  isModerator: boolean = false;
  CustomYahooUrl: any;
  chat: boolean = environment.Service.Chat;
  modalRef: NgbModalRef;
  currentItem: string = 'newslsglstring';

  @Input('meetingObject') meetingObject: any;
  showAttendance: any;
  todayDate: any;
  dueDate : any;
  meetingDate: any;
  toDisableMemberTab: boolean = false;
  isRecordingAvailable: boolean = false;
  test: boolean;
  isAdminRole: boolean = false;
  MeetingCreator: any;
  MeetingjoinAsGuest: boolean = false;
  isDisabled: boolean = false;
  Approve: boolean;
  Disapprove: boolean;
  Interested: boolean;
  Abstain: boolean;
  meeting: boolean;
  responseDate: NgbDateStruct;
  director: any[];
  currentAgenda: any;
  loadAgendaFromPagignation: any;
  isDirector: boolean = false;
  publishDate: any;
  responseType: any;
  firstAttempt: boolean;
  responseReceived: any;


  constructor(private userMasterService: UserMasterService, private modalService: NgbModal, rocketChatService: RocketChatService, public activeModal: NgbActiveModal, private addToCalendarService: NgAddToCalendarService,
    private sanitizer: DomSanitizer, private webexService: WebexService, private meetingMasterService: MeetingMasterService, private gfmService: GfmService, private companyMasterService: CompanyMasterService, private router: Router) {

  
    this.todayDate = new Date();
    this.newEvent = {
      title: '',
      start: new Date(),
      duration: 240,
      end: new Date(),
      address: '',
      description: ''
    };
  }

  onFileURL({ FinalUrl, page ,filename}) {
    this.currentAgenda = { FinalUrl, page ,filename};
  }

  loadFromPagignation(agendaId) {
    console.log("onFileURLonFileURL", agendaId)
    this.loadAgendaFromPagignation = agendaId;
  }

  ngOnInit() {
    this.responseReceived = false
    this.publishDate = this.meetingObject.AtrDocument[0].agendaDate;
    if(this.publishDate != ''){
      this.publishDate = new Date(this.meetingObject.AtrDocument[0].agendaDate);
      this.dueDate = new Date(this.publishDate)
      this.dueDate.setDate(this.publishDate.getDate() + 6);
      this.todayDate = new Date();
    }
    else{
      this.publishDate = ''; 
    }
    console.log(this.publishDate)
    var user = JSON.parse(localStorage.getItem('user'))._id; //localStorage.getItem('CopyEmailAddress');
    this.director =  this.meetingObject.Directors;
    this.companyMasterService.getSingleCompnyDetails(this.meetingObject.CompanyID).then(response=>{
      this.meetingObject.companyName = response.companyData.CompanyName
    })

    for(let i = 0; i < this.director.length; i++){
      if(this.director[i].UserGovevaId == user){
        this.isDirector = true;
        console.log(this.isDirector)
        if (this.director[i].IsApprove == true) {
          this.Approve = true;
          this.isDisabled = true;
          this.responseReceived = true;
        }
        else if (this.director[i].IsDisapprove == true) {
          this.Disapprove = true;
          this.isDisabled = true;
          this.responseReceived = true;          
        }
        else if (this.director[i].IsInterested == true) {
          this.Interested = true;
          this.isDisabled = true;
          this.responseReceived = true;          
        }
        else if (this.director[i].IsAbstain == true) {
          this.Abstain = true;
          this.isDisabled = true;
          this.responseReceived = true;          
        }
        else if (this.director[i].IsMeeting == true) {
          this.meeting = true;
          this.isDisabled = true;
          this.responseReceived = true;          
        }

      }


    }



    let userDesignation = JSON.parse(localStorage.getItem('user')).Designation;
    let userId = JSON.parse(localStorage.getItem('user'))._id;
    let userRole = JSON.parse(localStorage.getItem('user')).Roles[0].RoleName;
    if (userRole == 'Admin' || userRole == 'All Role') {
      this.isAdminRole = true;
      localStorage.setItem("isModerator", 'true');
      localStorage.setItem("isAdmin", 'true');
    }
    this.isCompanySecretary = false;
    if (userDesignation == 'Company Secretary') {
      this.isCompanySecretary = true;
      this.CSUserId = userId;
    }

    if (this.meetingObject.meetingTypeId && this.meetingObject.meetingTypeId == 'regular') {
      let currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      let meetingDate = moment(this.meetingObject.meetingDate).format("YYYY-MM-DD");
      let meetingTime = moment(this.meetingObject.meetingTime, ["h:mm A"]).format("HH:mm");
      let combineMeetingDateTime = moment(meetingDate + ' ' + meetingTime);
      let appointmentDiff = combineMeetingDateTime.diff(currentDateTime, 'minutes');
      this.showMeetingInitiateBtn = false;
      console.log('combineMeetingDateTime', combineMeetingDateTime)
      console.log('appointmentDiff', appointmentDiff)
      if (appointmentDiff <= 10) {
        this.isModerator = true;
        this.showMeetingInitiateBtn = true;
        localStorage.setItem("isModerator", 'true');
      }
    } else {
      let currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      let meetingDate = moment(this.meetingObject.MeetingDate).format("YYYY-MM-DD");
      let meetingTime = moment(this.meetingObject.MeetingTime, ["h:mm A"]).format("HH:mm");
      let combineMeetingDateTime = moment(meetingDate + ' ' + meetingTime);
      let appointmentDiff = combineMeetingDateTime.diff(currentDateTime, 'minutes');
      this.showMeetingInitiateBtn = false;
      if (appointmentDiff <= 10) // Difference In minutes
      {
        this.isModerator = true;
        this.showMeetingInitiateBtn = true;
        localStorage.setItem("isModerator", 'true');
      }

    }

    var inviteesIds: Array<any> = [];
    var directorsIds: Array<any> = [];
    var membersIds: Array<any> = [];
    this.meetingObject.Invitees.forEach(function (Invitees) {
      inviteesIds.push(Invitees.UserGovevaId);
    });
    this.meetingObject.Directors.forEach(function (Directors) {
      directorsIds.push(Directors.UserGovevaId);
    });
    this.meetingObject.Members.forEach(function (Members) {
      membersIds.push(Members.UserGovevaId);
    });

    if (inviteesIds.includes(userId)) {
      this.showJoinButton = true;
      this.joinMeetingUserId = userId;
    } else if (directorsIds.includes(userId)) {
      this.showJoinButton = true;
      this.joinMeetingUserId = userId;
    } else if (membersIds.includes(userId)) {
      this.showJoinButton = true;
      this.joinMeetingUserId = userId;
    } else {
      this.showJoinButton = false;
    }
    this.companyMasterService.getCompanyList().then(companyResponse => {
      this.complianceEngine = companyResponse.companyData.ComplianceEngine;
      this.complianceEngineUrl = companyResponse.companyData.ComplianceEngineUrl
      // if (companyResponse.companyData.ClientLoginAPIUrl != undefined && companyResponse.companyData.ClientLoginAPIUrl != "" && (_.size(companyResponse.companyData.ClientLoginAPIUrl) > 0)) {
      //   this.governanceUrl = companyResponse.companyData.ClientLoginAPIUrl;
      // }
      this.governanceUrl = environment.nonValidateUrl

      if (companyResponse.companyData.GEMAccessURL != undefined && companyResponse.companyData.GEMAccessURL != "" && (_.size(companyResponse.companyData.GEMAccessURL) > 0)) {
        this.gemAccessURL = companyResponse.companyData.GEMAccessURL;
      } else {
        //this.gemAccessURL = "http://52.66.23.123:8085/api/";
        this.gemAccessURL = "http://" + environment.gemIp_domain + "/api/";
      }
      this.gfmService.isMeetingStarted(this.meetingObject._id, this.complianceEngine, this.complianceEngineUrl, this.governanceUrl, this.gemAccessURL).then(response => {
        this.hasMeetingStarted = response.meetingAttendance.hasMeetingStarted;
        if (response.meetingAttendance.meetingEndTime != null) {
          this.hasMeetingEnd = true;
        }
      })
    }).catch(error => {
      console.log("Error ===> ", error)
    })

    this.meetingObject.meetingType ? this.NotRegularMeetingTab = false : this.AddedTaskStatusToMeetingObject(); // to check if meeting is regular meeting or not
    if (new Date(this.meetingObject.MeetingDate).setHours(0, 0, 0, 0) < this.todayDate.setHours(0, 0, 0, 0)) {
      this.showAttendance = true;
    }
    else {
      this.showAttendance = false;
    }

    if (this.meetingObject.meetingType != undefined) {
      if (_.size(this.meetingObject)) {
        this.meetingDate = this.meetingObject.meetingDate;
      }

      this.newEvent.title = this.meetingObject.meetingType;
      var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
      var start = this.meetingObject.meetingDate ? moment(new Date(this.meetingObject.meetingDate)).format('YYYY-MM-DD') : " ";
      var time = (this.meetingObject.meetingTime ? this.meetingObject.meetingTime : " ")
      this.newEvent.start = new Date(start + " " + time)
      this.newEvent.end = new Date(this.newEvent.start.getTime() + 14400000) //bydefault adding 4 hours to start of meeting timings
      this.newEvent.address = (this.meetingObject.meetingPlace ? this.meetingObject.meetingPlace : " ");
      this.newEvent.description = 'Meeting Type : ' + this.newEvent.title + '.' + '\\n\\n' + 'Meeting Date : ' + new Date(this.meetingObject.meetingDate).toLocaleDateString('en-GB', options) + '\\n' + 'Meeting Time :' + time
    } else {
      if (_.size(this.meetingObject)) {
        this.meetingDate = this.meetingObject.MeetingDate;
        if (_.size(this.meetingObject.MeetingTypeID)) {
          if (this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Annual_General_Meeting
            || this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Board
            || this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Extraordinary_General_Meeting
            || this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Independent_Directors
            || this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Others
            || this.meetingObject.MeetingTypeID.MeetingType == MeetingType.Postal_Ballot) {
            this.toDisableMemberTab = true;
          } else {
            this.toDisableMemberTab = false;
          }
        }
      }

      this.newEvent.title = (this.meetingObject.MeetingTypeID ? (this.meetingObject.MeetingTypeID.MeetingType ? this.meetingObject.MeetingTypeID.MeetingType : " ") : " ")
      let options: any = { year: 'numeric', month: 'long', day: 'numeric' };
      var start = this.meetingObject.MeetingDate ? moment(new Date(this.meetingObject.MeetingDate)).format('YYYY-MM-DD') : " ";
      var time = (this.meetingObject.MeetingTime ? this.meetingObject.MeetingTime : " ")
      this.newEvent.start = new Date(start + " " + time)
      this.newEvent.end = new Date(this.newEvent.start.getTime() + 14400000) //bydefault adding 4 hours to start of meeting timings
      var issueNoticeDate = new Date(this.meetingObject.IssueNoticeDueDate ? (this.meetingObject.IssueNoticeDueDate) : " ").toLocaleDateString('en-GB', options);
      this.newEvent.address = (this.meetingObject.MeetingPlace ? this.meetingObject.MeetingPlace : " ");
      this.newEvent.description = 'Meeting Type : ' + this.newEvent.title + '.' + '\\n\\n' + 'Meeting Date : ' + new Date(this.meetingObject.MeetingDate).toLocaleDateString('en-GB', options) + '\\n' + 'Meeting Time :' + time + '\\n\\n' + 'Due date to issue notice : ' + issueNoticeDate
    }

    this.userMasterService.getUserByUserId(this.meetingObject.CreatedBy).then(response => {
      this.MeetingCreator = response.userDetails.FirstName + ' ' + response.userDetails.LastName;
    })

    this.googleCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.google, this.newEvent)
    );

    this.iCalendarEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.iCalendar, this.newEvent)
    );

    this.outLookEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlook, this.newEvent)
    );

    this.yahooEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.yahoo, this.newEvent)
    );

    var string = this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.yahoo, this.newEvent);
    var arr = string.split('?v'); // split string
    this.CustomYahooUrl = "https://calendar.yahoo.com/?v" + arr[1];

    this.microsoftEventUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlookLive, this.newEvent)
    );
    this.checkIsRecordingAvailable();
  }

  openChatModel(content) {
    this.modalRef = this.modalService.open(content, { size: "lg" })
  }
  cheackOne(type) {
    if (type == 'Approve') {
      this.responseType = 'Assent';
      this.Approve = true;
      this.Disapprove = false;
      this.Interested = false;
      this.Abstain = false;
      this.meeting = false;
      this.updateMeeting();
    } else if (type == 'Disapprove') {
      this.responseType = 'Dissent';
      this.Approve = false;
      this.Disapprove = true;
      this.Interested = false;
      this.Abstain = false;
      this.meeting = false;
      this.updateMeeting();
    } else if (type == 'Interested') {
      this.responseType = 'Interested but not voted';
      this.Approve = false;
      this.Disapprove = false;
      this.Interested = true;
      this.Abstain = false;
      this.meeting = false;
      this.updateMeeting();
    } else if (type == 'Abstain') {
      this.responseType = 'Not Voted / Abstain';
      this.Approve = false;
      this.Disapprove = false;
      this.Interested = false;
      this.Abstain = true;
      this.meeting = false;
      this.updateMeeting();
    }
    else {
      this.responseType = 'Take to Meeting';
      this.Approve = false;
      this.Disapprove = false;
      this.Interested = false;
      this.Abstain = false;
      this.meeting = true;
      this.updateMeeting();
    }
  }
  setDate(date) {

    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }
  updateMeeting() {
    var date = new Date();
    this.responseDate = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
    console.log(this.setDate(this.responseDate), this.publishDate)
    this.publishDate = this.meetingObject.AtrDocument[0].agendaDate;
    this.publishDate = new Date(this.meetingObject.AtrDocument[0].agendaDate);
    this.dueDate = new Date(this.publishDate)
    this.dueDate.setDate(this.publishDate.getDate() + 6);    
    //  this.publishDate = "7-7-2022"
    //  let data = "8-7-2022"

      var user = JSON.parse(localStorage.getItem('user'))._id //localStorage.getItem('CopyEmailAddress');
      this.director = this.meetingObject.Directors;
      var date = new Date();
      this.responseDate = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
      var newdata = {
        "IsApprove": this.Approve,
        "IsDisapprove": this.Disapprove,
        "IsInterested": this.Interested,
        "IsAbstain": this.Abstain,
        "IsMeeting": this.meeting,
        "DueResponseDate": this.setDate(this.responseDate),
      }

      for (let i = 0; i < this.director.length; i++) {
        if (this.director[i].UserGovevaId == user) {
          if (this.director[i].IsApprove == undefined || this.director[i].IsApprove == null && this.director[i].IsDisapprove == undefined || this.director[i].IsDisapprove == null && this.director[i].IsInterested == undefined || this.director[i].IsInterested == null && this.director[i].IsAbstain == undefined || this.director[i].IsAbstain == null && this.director[i].IsMeeting == undefined || this.director[i].IsMeeting == null) {
            this.director[i] = Object.assign(this.director[i], newdata);
            this.firstAttempt = true;
          }
          else {
            this.firstAttempt = false;
          }
          console.log(this.director);
        }

      }
      var documentObject = {
        noticeDate: this.meetingObject.AtrDocument[0].noticeDate,
        noticeTime: this.meetingObject.AtrDocument[0].noticeTime,
        agendaDate: this.meetingObject.AtrDocument[0].agendaDate,
        agendaTime: this.meetingObject.AtrDocument[0].agendaTime,
        draftTime: this.meetingObject.AtrDocument[0].draftTime,
        draftDate: this.meetingObject.AtrDocument[0].draftDate,
        finalDate: this.meetingObject.AtrDocument[0].finalDate,
        finalTime: this.meetingObject.AtrDocument[0].finalTime,
        isAgendaNotify: this.meetingObject.AtrDocument[0].isAgendaNotify,
        isNoticeNotify: this.meetingObject.AtrDocument[0].isNoticeNotify,
        isDraftNotify: this.meetingObject.AtrDocument[0].isDraftNotify,
        isATRNotify: this.meetingObject.AtrDocument[0].isATRNotify,
        isFinalNotify: this.meetingObject.AtrDocument[0].isFinalNotify
      }
      var meeting = {
        "_id": this.meetingObject._id,
        "URL": location.href,
        "CompanyID": this.meetingObject.CompanyID,
        "MeetingTypeID": this.meetingObject.MeetingTypeID._id,
        "MeetingTypeName": this.meetingObject.MeetingTypeName,
        "IsPublished": this.meetingObject.IsPublished,
        "MeetingNumber": this.meetingObject.MeetingNumber,
        "MeetingDate": this.meetingObject.MeetingDate,
        "MeetingTime": this.meetingObject.MeetingTime,
        "MeetingPlace": this.meetingObject.MeetingPlace,
        "NoticeSignatoryName": this.meetingObject.NoticeSignatoryName,
        "ContactNumber": this.meetingObject.ContactNumber,
        "EmailID": this.meetingObject.EmailID,
        "IssueNoticeDueDate": this.meetingObject.IssueNoticeDueDate,
        "NoticeSignatoryDesignation": this.meetingObject.NoticeSignatoryDesignation,
        "DinNumber": this.meetingObject.DinNumber,
        "ProcessID": this.meetingObject.ProcessID,
        "SigningNoticeDate": this.meetingObject.SigningNoticeDate,
        "SigningNoticePlace": this.meetingObject.SigningNoticePlace,
        "QuarterEnded": this.meetingObject.QuarterEnded,
        "Category": this.meetingObject.Category,
        "Compliance": this.meetingObject.Compliance,
        "Agenda": this.meetingObject.Agenda,
        "Directors": this.director,
        "Invitees": this.meetingObject.Invitees,
        "Members": this.meetingObject.Members,
        "Attendance": this.meetingObject.Attendance,
        "AttendanceFile": this.meetingObject.AttendanceFile,
        "AtrDocument": documentObject,
        "isMeetingDataGreaterSelectDate": true,
        "MeetType": this.meetingObject.MeetType,

      };
      if (this.firstAttempt == true) {
        this.isDisabled = true;
        alert("once selected you won't be able to edit later")
        if (!confirm("You have selected" + ' ' + this.responseType + ' ' + "do you want to proceed?")) {
          for (let i = 0; i < this.director.length; i++) {
            if (this.director[i].UserGovevaId == user) {
              if (this.director[i].IsApprove != undefined || this.director[i].IsApprove != null && this.director[i].IsDisapprove != undefined || this.director[i].IsDisapprove != null && this.director[i].IsInterested != undefined || this.director[i].IsInterested != null && this.director[i].IsAbstain != undefined || this.director[i].IsAbstain != null && this.director[i].IsMeeting != undefined || this.director[i].IsMeeting != null) {
               delete this.director[i].IsApprove;
               delete this.director[i].IsDisapprove;
               delete this.director[i].IsAbstain;
               delete this.director[i].IsInterested;
               delete this.director[i].IsMeeting;
               delete this.director[i].DueResponseDate;
              }
            }
          }
          this.isDisabled = false;
          return false;
        }
        else {
          this.meetingMasterService.addMeeting(meeting)
            .subscribe((meeting: any) => {
              console.log(meeting)
              if (meeting.status == 0) {
                alert("Response recorded successfully");
              }
              else {
                alert(meeting.message);
              }
            });
        }
      }

  }
  closeModal() {
    this.modalRef.close()
    $('body').css('overflow', 'hidden');
    $('.modal').css('overflow', 'auto');
  }

  AddedTaskStatusToMeetingObject() {
    this.NotRegularMeetingTab = true;
    this.meetingObject.ActivatedTasks.forEach(element => {
      if (element.TaskStatus == 1) {
        element.TaskStatusName = "Pending"
      }
      else if (element.TaskStatus == 2) {
        element.TaskStatusName = "Completed"
      }
      else if (element.TaskStatus == 3) {
        element.TaskStatusName = "Delayed Completion"
      }
      else if (element.TaskStatus == 4) {
        element.TaskStatusName = "Partially Completed"
      }
      else if (element.TaskStatus == 5) {
        element.TaskStatusName = "NA"
      }
      else if (element.TaskStatus == 6) {
        element.TaskStatusName = "Done"
      }
      else if (element.TaskStatus == 7) {
        element.TaskStatusName = "Cancelled"
      }
    })
  }

  openPopup(meetingId, buttonType) {
    if (this.MeetingjoinAsGuest) {
      this.activeModal.close(meetingId)
      window.location.href = '/#/webex/' + meetingId + '/?GuestLogin=true';
    } else {
      localStorage.removeItem('WebexAcessToken')
      var token = localStorage.getItem('token')
      console.log("buttonType---->", buttonType);
      var w = 600;
      var h = 600;
      var left = Number((screen.width / 2) - (w / 2));
      var tops = Number((screen.height / 2) - (h / 2));
      setTimeout(function () {
        window.open(environment.webexUrl, 'popup', 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
      }, 1800)

      localStorage.setItem("meetingId", meetingId);
      localStorage.setItem("isModerator", 'true');
      var isMeetingInitiated = localStorage.getItem("isMeetingInitiated");
      if (buttonType == 'initiate' || isMeetingInitiated == 'initiate') {
        localStorage.setItem("isMeetingInitiated", "initiate");
        localStorage.setItem("isModerator", 'true');
      }
      else {
        localStorage.setItem("isModerator", 'false');
      }
      this.activeModal.close(meetingId)
    }
  }

  checkIsRecordingAvailable() {
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    this.meetingMasterService.checkIsRecordingAvailableAtCiscoEnd('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL, this.meetingObject._id).then(isRecordingAvailableObj => {
      this.isRecordingAvailable = isRecordingAvailableObj.flag;
    });
  }


  openPopupForDownloadRecording() {
    $('#downloadRecordingPopup').modal('toggle');
  }

  uploadRecordingToGFM() {
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    this.meetingMasterService.downloadCiscoRecordingById('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL, this.meetingObject._id).then(isRecordingAvailable => {
      var data = isRecordingAvailable;
      if (data.status == 200) {
        $('#saveAlert').modal('toggle');
        $('body').css('overflow', 'hidden');
        $('.modal').css('overflow', 'auto');
      }
    });
  }

  goBack() {
    document.getElementById('Agenda').style.display = "none";
    document.getElementById('individual-meeting').style.display = "block";
    document.getElementById('Document').style.display = "none";
    document.getElementById('Attendance').style.display = "none";
    document.getElementById('agendaAnalytics').style.display = "none";
    document.getElementById('documentDetails').style.display = "none";
    document.getElementById('agendaDetails').style.display = "none";
  }

  openPopupForJoinMeetingOptions(loginType = 'normal') {
    if (loginType == 'guestLogin') {
      this.MeetingjoinAsGuest = true;
      localStorage.setItem("isModerator", 'false');
    } else {
      this.MeetingjoinAsGuest = false;
    }
    localStorage.setItem('isMeetingInitiated', 'join');
    this.showAlertModal = true;
  }

  showAlertMeetingNotStarted() {
    if (!this.showAlertModal && this.showJoinButton && this.hasMeetingStarted && !this.hasMeetingEnd && this.GemWeb) {
      this.openPopupForJoinMeetingOptions();
    } else {
      alert("Meeting not yet started by the moderator.");
    }
  }

  openPopupOnCancel(meetingId) {
    localStorage.setItem("joinInPerson", "yes");
    this.showAlertModal = false;
    this.activeModal.close(meetingId)
  }

  closePopupOnCancel(meetingId) {
    this.showAlertModal = false;
    this.activeModal.dismiss(meetingId)
    $('body').css('overflow', 'auto');
  }

  activateRemindButton() {
    document.getElementById("updateButton").removeAttribute('disabled');
  }
}
