import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BISCommunicationService } from '../../../services/BISCommunication.service';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: 'app-biscommunication',
  templateUrl: './biscommunication.component.html',
  styleUrls: ['./biscommunication.component.css'],
})
export class BiscommunicationComponent implements OnInit {
  taskListForm: FormGroup;
  cin: string;
  selectedStartBISDate: any;
  selectedEndBISDate: any;
  error: any = { isError: false, errorMessage: '' };
  compliancesList: any[];
  additionalActionsList: any[];
  timeBasedcompliancesList: any[];
  @Input() loading: boolean = false;
  invalidData: any;
  selectedTaskMode: string;
  taskDetailForm: FormGroup;
  taskDetails: any;
  taskId: string;
  //Meeting
  selectedMeetingType: string;
  meetingForm: FormGroup;
  meetingDetailForm: FormGroup;
  errorMeeting: any = { isError: false, errorMessage: '' };
  selectedStartMeetingDate: any;
  selectedEndMeetingDate: any;
  meetingList: any;
  meetingActionPointList: any;
  meetingResolutionList: any;
  meetingDetails: any;
  cinForMeeting: string;
  meetingId: string;
  //Notification
  notificationForm: FormGroup;
  notificationList: any;
  notificationDetails: any;
  cinForNotification: string;

  constructor(private bisCommunicationService: BISCommunicationService) { }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  compareTwoDates() {
    var dates = this.getFormatBISDate();
    //this.taskListForm.controls['EndBISDate'].value
    if (new Date(dates.endDate) < new Date(dates.startDate)) {
      this.error = {
        isError: true,
        errorMessage: 'End Date cannot be set before start date',
      };
      return false;
    } else this.error = { isError: false, errorMessage: '' };

    return true;
  }

  getComplianceList() {
    //
    this.compliancesList = [];
    var bisDates = this.checkValidDates();
    if (bisDates.isValidDate == true) {
      $('#tableHeader').text('');
      $('#divComplianceTask').show();
      $('#divAdditionalActions').hide();
      $('#divTimeBasedCompliance').hide();
      //this.taskListForm.controls['tableHeader'].setValue("com");
      this.loading = true;
      this.invalidData = false;

      this.bisCommunicationService
        .getComplianceTaskList(this.cin, bisDates.startDate, bisDates.endDate)
        .then((compliances) => {
          //
          if (compliances.complianceTaskBISModel != null) {
            if (compliances.complianceTaskBISModel.length) {
              $('#tableHeader').text('Compliance Tasks');
              //converts objects into arrays  as ngFor doesn't work with objects, only arrays.
              this.loading = false;
              this.compliancesList = $.map(
                compliances.complianceTaskBISModel,
                function (value, index) {
                  return [value];
                }
              );
            } else {
              this.loading = false;
              this.invalidData = true;
              $('#divComplianceTask').hide();
            }
          } else {
            this.loading = false;
            this.invalidData = true;
            $('#divComplianceTask').hide();
          }
        });
    }
  }

  getAdditionalActionsList() {
    //
    this.additionalActionsList = [];
    var bisDates = this.checkValidDates();
    if (bisDates.isValidDate == true) {
      $('#tableHeader').text('');
      $('#divAdditionalActions').show();
      $('#divComplianceTask').hide();
      $('#divTimeBasedCompliance').hide();
      this.loading = true;
      this.invalidData = false;
      this.bisCommunicationService
        .getAdditionalActionsList(
          this.cin,
          bisDates.startDate,
          bisDates.endDate
        )
        .then((additonActions) => {
          //
          if (additonActions.AdditionalActionList != null) {
            if (additonActions.AdditionalActionList.length) {
              $('#tableHeader').text('Additional Actions');
              //converts objects into arrays  as ngFor doesn't work with objects, only arrays.
              this.loading = false;
              this.additionalActionsList = $.map(
                additonActions.AdditionalActionList,
                function (value, index) {
                  return [value];
                }
              );
            } else {
              this.loading = false;
              this.invalidData = true;
              $('#divAdditionalActions').hide();
            }
          } else {
            this.loading = false;
            this.invalidData = true;
            $('#divAdditionalActions').hide();
          }
        });
    }
  }

  getTimeBasedComplianceList() {
    //
    this.timeBasedcompliancesList = [];
    var bisDates = this.checkValidDates();
    if (bisDates.isValidDate == true) {
      $('#tableHeader').text('');
      $('#divTimeBasedCompliance').show();
      $('#divComplianceTask').hide();
      $('#divAdditionalActions').hide();
      this.loading = true;
      this.invalidData = false;
      this.bisCommunicationService
        .getTimeBasedComplianceList(
          this.cin,
          bisDates.startDate,
          bisDates.endDate
        )
        .then((timeBasedcompliances) => {
          //
          if (timeBasedcompliances.TimeBasedComplianceList != null) {
            if (timeBasedcompliances.TimeBasedComplianceList.length) {
              $('#tableHeader').text('Time Based Compliances');
              //converts objects into arrays  as ngFor doesn't work with objects, only arrays.
              this.loading = false;
              this.timeBasedcompliancesList = $.map(
                timeBasedcompliances.TimeBasedComplianceList,
                function (value, index) {
                  return [value];
                }
              );
            } else {
              this.loading = false;
              this.invalidData = true;
              $('#divTimeBasedCompliance').hide();
            }
          } else {
            this.loading = false;
            this.invalidData = true;
            $('#divTimeBasedCompliance').hide();
          }
        });
    }
  }

  checkValidDates() {
    if (
      this.selectedStartBISDate != undefined &&
      this.selectedEndBISDate != undefined
    ) {
      if (this.compareTwoDates()) {
        var dates = this.getFormatBISDate();
        return {
          isValidDate: true,
          startDate: dates.startDate,
          endDate: dates.endDate,
        };
      }
    }
    return { isValidDate: false, startDate: '', endDate: '' };
  }

  getFormatBISDate() {
    let startDate =
      this.selectedStartBISDate.month +
      '/' +
      this.selectedStartBISDate.day +
      '/' +
      this.selectedStartBISDate.year;
    let endDate =
      this.selectedEndBISDate.month +
      '/' +
      this.selectedEndBISDate.day +
      '/' +
      this.selectedEndBISDate.year;
    return { startDate: startDate, endDate: endDate };
  }

  getTaskDetails() {
    this.taskDetails = [];
    $('#taskDetailTableHeader').text('');
    $('#divTaskDetails').show();
    this.loading = true;
    this.invalidData = false;
    //
    if (this.taskDetailForm.status == 'VALID') {
      let taskType = this.taskDetailForm.controls['TaskType'].value;
      let taskId = this.taskDetailForm.controls['TaskID'].value;
      this.bisCommunicationService
        .getTaskDetails(taskType, taskId)
        .then((taskDetails) => {
          //
          if (taskDetails.status == 0) {
            if (taskDetails.TaskDetails != null) {
              this.loading = false;
              $('#taskDetailTableHeader').text('Task Details');
              this.taskDetails = taskDetails.TaskDetails;
            } else {
              $('#divTaskDetails').hide();
              this.invalidData = true;
            }
          } else {
            $('#divTaskDetails').hide();
            this.invalidData = true;
          }
        });
    }
  }

  taskModeSelectionChanged(event) {
    this.loading = false;
    this.invalidData = false;
    //
    if (this.selectedTaskMode == 'TaskList') {
      $('#taskListContainer').show();
      $('#taskDetailContainer').hide();
    } else if (this.selectedTaskMode == 'TaskDetails') {
      $('#taskDetailContainer').show();
      $('#taskListContainer').hide();
    }
  }

  //Meeting

  meetingSelectionChanged(event) {
    this.loading = false;
    this.invalidData = false;
    //
    if (this.selectedMeetingType == 'MeetingDetails') {
      $('#meetingDetailsContainer').show();
      $('#meetingContainer').hide();
    } else {
      $('#meetingContainer').show();
      $('#meetingDetailsContainer').hide();
    }
  }

  checkValidMeetingDates() {
    if (
      this.selectedStartMeetingDate != undefined &&
      this.selectedEndMeetingDate != undefined
    ) {
      if (this.compareMeetingDates()) {
        var dates = this.getFormatMeetingDate();
        return {
          isValidDate: true,
          startDate: dates.startDate,
          endDate: dates.endDate,
        };
      }
    }
    return { isValidDate: false, startDate: '', endDate: '' };
  }

  compareMeetingDates() {
    var dates = this.getFormatMeetingDate();
    //this.taskListForm.controls['EndBISDate'].value
    if (new Date(dates.endDate) < new Date(dates.startDate)) {
      this.errorMeeting = {
        isError: true,
        errorMessage: 'End Date cannot be set before start date',
      };
      return false;
    } else this.errorMeeting = { isError: false, errorMessage: '' };

    return true;
  }

  getFormatMeetingDate() {
    let startDate =
      this.selectedStartMeetingDate.month +
      '/' +
      this.selectedStartMeetingDate.day +
      '/' +
      this.selectedStartMeetingDate.year;
    let endDate =
      this.selectedEndMeetingDate.month +
      '/' +
      this.selectedEndMeetingDate.day +
      '/' +
      this.selectedEndMeetingDate.year;
    return { startDate: startDate, endDate: endDate };
  }

  getMeetingList() {
    this.meetingList = [];
    $('#meetingListTableHeader').text('');
    $('#divMeetingList').show();
    this.loading = true;
    this.invalidData = false;
    var meetingDates = this.checkValidMeetingDates();
    if (meetingDates.isValidDate == true) {
      if (this.meetingForm.status == 'VALID') {
        this.bisCommunicationService
          .getMeetingList(
            this.cin,
            meetingDates.startDate,
            meetingDates.endDate
          )
          .then((meetingDetails) => {
            //
            if (meetingDetails.status == 0) {
              if (meetingDetails.MeetingList != null) {
                if (meetingDetails.MeetingList.length) {
                  // $("#divMeetingList").show();
                  $('#meetingListTableHeader').text('Meeting List');
                  this.meetingList = meetingDetails.MeetingList;
                  this.loading = false;
                } else {
                  $('#divMeetingList').hide();
                  this.invalidData = true;
                }
              } else {
                $('#divMeetingList').hide();
                this.invalidData = true;
              }
            } else {
              $('#divMeetingList').hide();
              this.invalidData = true;
            }
          });
      } else {
        $('#divMeetingList').hide();
        this.invalidData = true;
      }
    } else {
      $('#divMeetingList').hide();
      this.invalidData = true;
    }
  }

  getMeetingDetails() {
    this.meetingDetails = [];
    $('#meetingDetailsTableHeader').text('');
    $('#divMeetingResolutionList').hide();
    $('#divMeetingActionPointList').hide();
    $('#divMeetingDetailList').show();
    this.loading = true;
    this.invalidData = false;
    //
    if (this.meetingDetailForm.status == 'VALID') {
      let meetingId = this.meetingDetailForm.controls['MeetingID'].value;

      this.bisCommunicationService
        .getMeetingDetails(meetingId)
        .then((meetingDetails) => {
          //
          if (meetingDetails.status == 0) {
            if (meetingDetails.MeetingDetails != null) {
              this.loading = false;
              $('#meetingDetailsTableHeader').text('Meeting Details');
              this.meetingDetails = meetingDetails.MeetingDetails;
            } else {
              this.invalidData = true;
              $('#divMeetingDetailList').hide();
            }
          } else {
            $('#divMeetingDetailList').hide();
            this.invalidData = true;
          }
        });
    }
  }

  getMeetingResolutionList() {
    this.meetingResolutionList = [];
    $('#meetingDetailsTableHeader').text('');
    $('#divMeetingDetailList').hide();
    $('#divMeetingActionPointList').hide();
    $('#divMeetingResolutionList').show();
    this.loading = true;
    this.invalidData = false;
    //
    if (this.meetingDetailForm.status == 'VALID') {
      let meetingId = this.meetingDetailForm.controls['MeetingID'].value;

      this.bisCommunicationService
        .getMeetingResolutionList(meetingId)
        .then((meetingResolutionDetails) => {
          //
          if (meetingResolutionDetails.status == 0) {
            if (meetingResolutionDetails.MeetingResolutionList != null) {
              this.loading = false;
              $('#meetingDetailsTableHeader').text('Meeting Resolution List');
              this.meetingResolutionList =
                meetingResolutionDetails.MeetingResolutionList;
            } else {
              $('#divMeetingResolutionList').hide();
              this.invalidData = true;
            }
          } else {
            $('#divMeetingResolutionList').hide();
            this.invalidData = true;
          }
        });
    }
  }

  getMeetingActionPointList() {
    this.meetingActionPointList = [];
    $('#meetingDetailsTableHeader').text('');
    $('#divMeetingDetailList').hide();
    $('#divMeetingResolutionList').hide();
    $('#divMeetingActionPointList').show();
    this.loading = true;
    this.invalidData = false;
    //
    if (this.meetingDetailForm.status == 'VALID') {
      let meetingId = this.meetingDetailForm.controls['MeetingID'].value;

      this.bisCommunicationService
        .getMeetingActionPointList(meetingId)
        .then((meetingActionPointList) => {
          //
          if (meetingActionPointList.status == 0) {
            if (meetingActionPointList.MeetingActionPointList != null) {
              this.loading = false;
              $('#meetingDetailsTableHeader').text('Meeting Action Point List');
              this.meetingActionPointList =
                meetingActionPointList.MeetingActionPointList;
            } else {
              $('#divMeetingActionPointList').hide();
              this.invalidData = true;
            }
          } else {
            $('#divMeetingActionPointList').hide();
            this.invalidData = true;
          }
        });
    }
  }

  //NOTIFICATION
  getNotificationList() {
    this.notificationList = [];

    $('#divNotificationList').show();
    $('#divNotificationDetailList').hide();
    this.loading = true;
    this.invalidData = false;
    if (this.notificationForm.status == 'VALID') {
      this.bisCommunicationService
        .getNotificationList(this.cin)
        .then((notificationList) => {
          //
          if (notificationList.status == 0) {
            if (notificationList.NotificationList != null) {
              if (notificationList.NotificationList.length) {
                $('#notificationTableHeader').text('Notification List');
                this.notificationList = notificationList.NotificationList;
                this.loading = false;
              } else {
                $('#divNotificationList').hide();
                this.invalidData = true;
              }
            } else {
              $('#divNotificationList').hide();
              this.invalidData = true;
            }
          } else {
            $('#divNotificationList').hide();
            this.invalidData = true;
          }
        });
    }
  }

  getNotificationDetailList() {
    this.notificationDetails = [];
    $('#notificationTableHeader').text('');
    $('#divNotificationList').hide();
    $('#divNotificationDetailList').show();
    this.loading = true;
    this.invalidData = false;

    if (this.notificationForm.status == 'VALID') {
      this.bisCommunicationService
        .getNotificationDetailList(this.cin)
        .then((notificationDetails) => {
          //
          if (notificationDetails.status == 0) {
            if (notificationDetails.NotificationDetailList != null) {
              if (notificationDetails.NotificationDetailList.length) {
                $('#notificationTableHeader').text('Notification Detail List');
                this.notificationDetails =
                  notificationDetails.NotificationDetailList;
                this.loading = false;
              } else {
                $('#divNotificationDetailList').hide();
                this.invalidData = true;
              }
            } else {
              $('#divNotificationDetailList').hide();
              this.invalidData = true;
            }
          } else {
            $('#divNotificationDetailList').hide();
            this.invalidData = true;
          }
        });
    }
  }

  ngOnInit() {
    //
    //default input provided.
    $('body').removeClass('dashboard-page');

    this.cin = 'L65910MH2002NPL0020005';
    this.cinForMeeting = 'L65910MH2002NPL0020005';
    this.cinForNotification = 'L65910MH2002NPL0020005';
    this.meetingId = '38';
    this.taskId = '1';

    this.taskListForm = new FormGroup({
      CIN: new FormControl('', [Validators.required]),
      StartBISDate: new FormControl('', [Validators.required]),
      EndBISDate: new FormControl('', [Validators.required]),
      tableHeader: new FormControl('', []),
    });

    this.taskDetailForm = new FormGroup({
      TaskID: new FormControl('', [Validators.required]),
      TaskType: new FormControl('', [Validators.required]),
    });

    this.meetingForm = new FormGroup({
      CINForMeeting: new FormControl('', [Validators.required]),
      StartMeetingDate: new FormControl('', [Validators.required]),
      EndMeetingDate: new FormControl('', [Validators.required]),
    });

    this.meetingDetailForm = new FormGroup({
      MeetingID: new FormControl('', [Validators.required])
    })

    this.notificationForm = new FormGroup({
      CINForNotification: new FormControl('', [Validators.required])
    });

  }

  changeTab(event) {
    this.loading = false;
    this.invalidData = false;
    //
    if (event.nextId == 'ngb-nav-meeting') {
      this.meetingForm.reset();
      this.meetingDetailForm.reset();
      this.errorMeeting = { isError: false, errorMessage: '' };
      this.selectedMeetingType = '';
      this.selectedStartMeetingDate = '';
      this.selectedEndMeetingDate = '';
    } else if (event.nextId == 'ngb-nav-task') {
      this.taskListForm.reset();
      this.taskDetailForm.reset();
      this.error = { isError: false, errorMessage: '' };
      this.selectedTaskMode = '';
      this.selectedStartMeetingDate = '';
      this.selectedEndMeetingDate = '';
      this.selectedStartBISDate = '';
      this.selectedEndBISDate = '';
    } else if (event.nextId == 'ngb-nav-notification') {
      this.notificationForm.reset();
    }
  }
}
