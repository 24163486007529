<!-- START: ecommerce/product-details -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Product Details</strong>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-4">
                <div class="cat__ecommerce__catalog__item">
                    <div class="cat__ecommerce__catalog__item__img">
                        <div class="cat__ecommerce__catalog__item__status">
                            <span class="cat__ecommerce__catalog__item__status__title">New</span>
                        </div>
                        <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                            <i class="icmn-heart"><!-- --></i>
                        </div>
                        <a href="javascript: void(0);">
                            <img id="targetPhoto" alt="001" src="assets/modules/ecommerce/common/img/001.jpg" />
                        </a>
                    </div>
                </div>
                <div class="cat__ecommerce__product__photos clearfix">
                    <div class="cat__ecommerce__product__photos-item cat__ecommerce__product__photos-item--active">
                        <img  alt="001" src="assets/modules/ecommerce/common/img/001.jpg" />
                    </div>
                    <div class="cat__ecommerce__product__photos-item">
                        <img alt="002" src="assets/modules/ecommerce/common/img/002.jpg" />
                    </div>
                    <div class="cat__ecommerce__product__photos-item">
                        <img alt="003" src="assets/modules/ecommerce/common/img/003.jpg" />
                    </div>
                    <div class="cat__ecommerce__product__photos-item">
                        <img alt="004" src="assets/modules/ecommerce/common/img/004.jpg" />
                    </div>
                    <div class="cat__ecommerce__product__photos-item">
                        <img alt="002" src="assets/modules/ecommerce/common/img/002.jpg" />
                    </div>
                    <div class="cat__ecommerce__product__photos-item">
                        <img alt="003" src="assets/modules/ecommerce/common/img/003.jpg" />
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <ul class="breadcrumb breadcrumb--custom">
                    <li class="breadcrumb-item">
                        <a href="javascript: void(0);">Catalog</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript: void(0);">Chairs</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript: void(0);">With background</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript: void(0);">White</a>
                    </li>
                </ul>
                <div class="cat__ecommerce__product__sku">
                    SKU: #0000235
                    <br />
                    <div class="cat__ecommerce__product__rating">
                        <i class="icmn-star-full"></i>
                        <i class="icmn-star-full"></i>
                        <i class="icmn-star-full"></i>
                        <i class="icmn-star-full"></i>
                        <i class="icmn-star-empty"></i>
                    </div>
                </div>
                <h4 class="cat__ecommerce__product__main-title">
                    <strong>Beautiful Chair for Kids</strong>
                </h4>
                <div class="cat__ecommerce__product__price">
                    $189.95
                    <div class="cat__ecommerce__product__price-before">
                        $220.95
                    </div>
                </div>
                <hr />
                <div class="cat__ecommerce__product__descr">
                    <p>A chair is a piece of furniture with a raised surface, commonly used to seat a single person.
                        Chairs are supported most often by four legs and have a back; however, a chair can have three
                        legs or can have a different shape.</p>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="cat__ecommerce__product__option_title">
                            Color
                        </div>
                        <div class="cat__ecommerce__product__option">
                            <select class="select2">
                                <optgroup label="Group 1">
                                    <option selected>Option 1</option>
                                    <option>Option 2</option>
                                </optgroup>
                                <optgroup label="Group 2">
                                    <option selected>Option 3</option>
                                    <option>Option 4</option>
                                    <option>Option 5</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="cat__ecommerce__product__option_title">
                            Available Size
                        </div>
                        <div class="cat__ecommerce__product__option">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="" data-original-title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="" data-original-title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="" data-original-title="Size XL">XL</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="cat__ecommerce__product__controls">
                    <button type="button" class="btn btn-icon btn-primary btn-lg mr-3">
                        <i class="icmn-cart mr-3"></i>
                        Buy Now
                    </button>
                    <a href="javascript: void(0);" class="btn btn-link">
                        <i class="icmn-heart"></i>
                        Add to Wishlist
                    </a>
                    <a href="javascript: void(0);" class="btn btn-link">
                        <i class="icmn-stats-bars"></i>
                        Add to Compare
                    </a>
                </div>
                <div class="cat__ecommerce__product__info">
                    <div class="nav-tabs-horizontal">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#tab1" role="tab">Information</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#tab2" role="tab">Description</a>
                            </li>
                        </ul>
                        <div class="tab-content pt-3 pb-3">
                            <div class="tab-pane active" id="tab1" role="tabcard">
                                <dl class="dl-horizontal user-profile-dl">
                                    <dt>Description lists</dt>
                                    <dd>A description list is perfect for defining terms</dd>
                                    <dt>Euismod</dt>
                                    <dd>Vestibulum id ligula porta felis euismod semper eget lacinia
                                        odio sem nec elit</dd>
                                    <dd>Donec id elit non mi porta gravida</dd>
                                    <dt>Malesuada porta</dt>
                                    <dd>Etiam porta sem malesuada magna mollis euismod</dd>
                                    <dt>Qui eiusmod magna</dt>
                                    <dd>Lorem ipsum In enim nostrud ut in mollit sint cillum laborum
                                        ea quis qui</dd>
                                </dl>
                            </div>
                            <div class="tab-pane" id="tab2" role="tabcard">
                                <p>
                                    Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                    abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                    invidiae ferre constituto gaudeat contentam, omnium nescius,
                                    consistat interesse animi, amet fuisset numen graecos incidunt
                                    euripidis praesens, homines religionis dirigentur postulant.
                                    Magnum utrumvis gravitate appareat fabulae facio perveniri
                                    fruenda indicaverunt texit, frequenter probet diligenter
                                    sententia meam distinctio theseo legerint corporis quoquo,
                                    optari futurove expedita.
                                </p>
                                <p>
                                    Cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                    abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                    invidiae ferre constituto gaudeat contentam, omnium nescius,
                                    consistat interesse animi, amet fuisset numen graecos incidunt
                                    euripidis praesens.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Related Products</strong>
        </span>
    </div>
    <div class="card-block">
        <div class="cat__ecommerce__catalog">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img alt="001" src="assets/modules/ecommerce/common/img/001.jpg" />
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Elliot Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $678.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $754.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/002.jpg" />
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Maxtor Chair</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $245.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $166.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img alt="003" src="assets/modules/ecommerce/common/img/003.jpg" />
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Manroad Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $199.00
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/004.jpg" />
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Align Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $542.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $356.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: ecommerce/product-details -->
