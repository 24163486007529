import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Injectable()

export class DataImportService {

    constructor(private http: HttpClient) {
    }

    uploadFile(file) {
        let url = environment.nonValidateUrl;
        return new Promise((resolve, reject) => {
            const uploadData = new FormData();
            uploadData.append('file', file, file.name);
            const request = this.http
                .post(url + 'data/import/bs',
                    uploadData,
                    { reportProgress: true, observe: 'events' })
                .subscribe((event) => {
                    if (event.type === HttpEventType.Response) {
                        resolve(event.body);
                    }
                },
                    error => reject(error)
                );
        });
    }
}