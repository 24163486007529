import { Component, OnInit, Input } from "@angular/core";
import { DashboardService } from "./../../../services/dashboard.service";
import * as _ from "lodash";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { forkJoin } from "rxjs";

import { ActivatedTaskFromComplianceService } from "./../../../services/activatedTaskFromCompliance.service";
import { UserMasterService } from "./../../../services/user-master.service";

@Component({
  selector: "app-calendar-task-details",
  templateUrl: "./calendar-task-details.component.html",
  styleUrls: ["./calendar-task-details.component.css"],
})
export class CalendarTaskDetailsComponent implements OnInit {
  @Input("taskId") taskId: any;

  public taskDetails: Array<any>;
  public isEdit: Boolean;
  public cloneTaskDetail: any;
  public errorValue: boolean;
  public assignedUserName: string;
  public ResponsibleUser: string;
  complianceTaskStatus: string;
  public completionDate: any;
  public dueDate: any;
  public showCompletionCalendar: boolean = false;
  isMyTaskFetch: any;

  constructor(
    private dashboardService: DashboardService,
    public activeModal: NgbActiveModal,
    private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService,
    private userService: UserMasterService
  ) {}

  ngOnInit() {
    this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");
    console.log("taskDetails-----------", this.taskDetails);
    this.errorValue = false;
    this.isEdit = false;
    //console.log('checking for the taskId', this.taskId)

    // this.dashboardService.getTaskDetailById(this.taskId).then(response => {
    //   if(!response.status) {
    //     //console.log('checking for the response', response)
    //     this.taskDetails = _.first(response.taskDetail);
    //     this.cloneTaskDetail = _.clone(this.taskDetails);
    //   } else {
    //     this.errorValue = true;
    //   }
    // })

    if (this.isMyTaskFetch != "false") {
      if (this.taskId.calendarId == "teamTask") {
        //console.log('this.taskId.activatedTaskFromCompliance', this.taskId.activatedTaskFromCompliance)
        if (this.taskId.activatedTaskFromCompliance) {
          //this.activatedTaskFromComplianceService.getactivatedTaskDetailsJsonData()
          // fork Join to handle both request simultaneously
          forkJoin([
            this.dashboardService.getActivatedTask(this.taskId._id),
            this.userService.getUserList(),
          ]).subscribe((response) => {
            //console.log("activatedTaskDetails-----", response);
            this.assignedUserName = "";
            this.ResponsibleUser = "";

            this.taskDetails = _.first(response[0].taskData); //taskList
            console.log("taskDetails-----------", this.taskDetails);
            var assignedToId = response[0].taskData[0].AssignedTo;

            let user = response[1].result.filter((o) => {
              return o._id == assignedToId;
            });
            if (user.length != 0)
              this.assignedUserName = user[0].FirstName + " " + user[0].LastName;
            else this.assignedUserName = "";

            var ResponsibleUserTo = response[0].taskData[0].ResponsibleUser;

            let userRes = response[1].result.filter((o) => {
              return o._id == ResponsibleUserTo;
            });
            if (userRes.length != 0)
              this.ResponsibleUser = userRes[0].FirstName + " " + userRes[0].LastName;
            else this.ResponsibleUser = "";

            var taskStatusId = response[0].taskData[0].TaskStatus;

            if (taskStatusId == 1) this.complianceTaskStatus = "Pending";
            else if (taskStatusId == 2) this.complianceTaskStatus = "Completed";
            else if (taskStatusId == 3) this.complianceTaskStatus = "Delayed Completion";
            else if (taskStatusId == 4) this.complianceTaskStatus = "Partially Completed";
            else if (taskStatusId == 5) this.complianceTaskStatus = "NA";
            else if (taskStatusId == 6) this.complianceTaskStatus = "Done";
            else if (taskStatusId == 7) this.complianceTaskStatus = "Cancelled";
          });

          // this.dashboardService.getActivatedTask(this.taskId._id)
          //   .then(response => {
          //     //console.log("activateTaskDetails", response);
          //     this.taskDetails = _.first(response.taskData);
          //     var assignedToId = (response.taskData)[0].AssignedTo;

          //     var taskStatusId = (response.taskData)[0].TaskStatus;

          //     if (taskStatusId == 1)
          //       this.complianceTaskStatus = "Pending";
          //     else if (taskStatusId == 2)
          //       this.complianceTaskStatus = "Completed";
          //     else if (taskStatusId == 3)
          //       this.complianceTaskStatus = "Delayed Completion";
          //     else if (taskStatusId == 4)
          //       this.complianceTaskStatus = "Partially Completed";
          //     else if (taskStatusId == 5)
          //       this.complianceTaskStatus = "NA";
          //     else if (taskStatusId == 6)
          //       this.complianceTaskStatus = "Done";
          //     else if (taskStatusId == 7)
          //       this.complianceTaskStatus = "Cancelled";

          //     this.userService.getUserList().then(response => {
          //       //console.log('checking for the user list', response);
          //       if (response.status == 0) {
          //
          //         //only active user will be filtered

          //         let user = response.result.filter((o) => { return o._id == assignedToId })
          //         if (user.length != 0)
          //           this.assignedUserName = user[0].FirstName + " " + user[0].LastName;
          //         else
          //           this.assignedUserName = "";

          //       }
          //     })

          //   })
        } else {
          this.dashboardService.getTaskDetailById(this.taskId).then((response) => {
            if (!response.status) {
              //console.log('checking for the response', response)
              this.taskDetails = _.first(response.taskDetail);
              console.log("taskDetails-----------", this.taskDetails);
              this.cloneTaskDetail = _.clone(this.taskDetails);
            } else {
              this.errorValue = true;
            }
          });
        }
      } else {
        //My Task

        // if task is from compliance if condition will get true else roadmap condition will get active
        if (this.taskId.activatedTaskFromCompliance) {
          ///

          forkJoin([
            this.dashboardService.getActivatedTask(this.taskId._id),
            this.userService.getUserList(),
          ]).subscribe((response) => {
            //console.log("activatedTaskDetails-----", response);
            this.assignedUserName = "";

            this.taskDetails = _.first(response[0].taskData); //taskList
            console.log("taskDetails-----------", this.taskDetails);
            var assignedToId = response[0].taskData[0].AssignedTo;

            let user = response[1].result.filter((o) => {
              return o._id == assignedToId;
            });
            if (user.length != 0)
              this.assignedUserName = user[0].FirstName + " " + user[0].LastName;
            else this.assignedUserName = "";

            var ResponsibleUserTo = response[0].taskData[0].ResponsibleUser;

            let userRes = response[1].result.filter((o) => {
              return o._id == ResponsibleUserTo;
            });
            if (userRes.length != 0)
              this.ResponsibleUser = userRes[0].FirstName + " " + userRes[0].LastName;
            else this.ResponsibleUser = "";

            var taskStatusId = response[0].taskData[0].TaskStatus;

            if (taskStatusId == 1) this.complianceTaskStatus = "Pending";
            else if (taskStatusId == 2) this.complianceTaskStatus = "Completed";
            else if (taskStatusId == 3) this.complianceTaskStatus = "Delayed Completion";
            else if (taskStatusId == 4) this.complianceTaskStatus = "Partially Completed";
            else if (taskStatusId == 5) this.complianceTaskStatus = "NA";
            else if (taskStatusId == 6) this.complianceTaskStatus = "Done";
            else if (taskStatusId == 7) this.complianceTaskStatus = "Cancelled";
          });

          //this.activatedTaskFromComplianceService.getactivatedTaskDetailsJsonData()
          // this.dashboardService.getActivatedTask(this.taskId._id)
          //   .then(response => {
          //
          //     //console.log("activateTaskDetails", response);

          //     this.taskDetails = _.first(response.taskData);//taskList

          //     var assignedToId = (response.taskData)[0].AssignedTo;

          //     var taskStatusId = (response.taskData)[0].TaskStatus;

          //     if (taskStatusId == 1)
          //       this.complianceTaskStatus = "Pending";
          //     else if (taskStatusId == 2)
          //       this.complianceTaskStatus = "Completed";
          //     else if (taskStatusId == 3)
          //       this.complianceTaskStatus = "Delayed Completion";
          //     else if (taskStatusId == 4)
          //       this.complianceTaskStatus = "Partially Completed";
          //     else if (taskStatusId == 5)
          //       this.complianceTaskStatus = "NA";
          //     else if (taskStatusId == 6)
          //       this.complianceTaskStatus = "Done";
          //     else if (taskStatusId == 7)
          //       this.complianceTaskStatus = "Cancelled";

          //     //this.taskDetails[0].TaskStatus = this.complianceTaskStatus;

          //     this.userService.getUserList().then(response => {
          //       //console.log('checking for the user list', response);
          //       if (response.status == 0) {
          //
          //         //only active user will be filtered

          //         let user = response.result.filter((o) => { return o._id == assignedToId })
          //         if (user.length != 0)
          //           this.assignedUserName = user[0].FirstName + " " + user[0].LastName;
          //         else
          //           this.assignedUserName = "";

          //       }
          //     })

          //   })
        } else {
          //Local Task
          this.dashboardService.getTaskDetailById(this.taskId).then((response) => {
            if (!response.status) {
              //console.log('checking for the response', response)
              this.taskDetails = _.first(response.taskDetail);
              console.log("taskDetails-----------", this.taskDetails);
              this.cloneTaskDetail = _.clone(this.taskDetails);
            } else {
              this.errorValue = true;
            }
          });
        }
      }
    }
  }

  enableCompletionDate() {
    if (this.complianceTaskStatus == "Completed") {
      this.showCompletionCalendar = true;
    } else {
      this.showCompletionCalendar = false;
      this.completionDate = null;
    }
  }

  today() {
    var now = new Date();
    return { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  }

  // update compliance and goverance task status update
  updateTaskStatus(taskObject) {
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let userId = loginUsers._id;

    if (this.assignedUserName != undefined) {
      var complianceTaskStatus = "";
      // if (taskObject.Status == "PartiallyCompleted") {
      //   complianceTaskStatus = "Partially Completed";
      // } else if (taskObject.Status == "DelayedCompletion") {
      //   complianceTaskStatus = "Delayed Completion";
      // } else {
      //   complianceTaskStatus = taskObject.Status;
      // }
      if (this.complianceTaskStatus == "PartiallyCompleted") {
        complianceTaskStatus = "Partially Completed";
      } else if (this.complianceTaskStatus == "DelayedCompletion") {
        complianceTaskStatus = "Delayed Completion";
      } else {
        complianceTaskStatus = this.complianceTaskStatus;
      }
      var updateStatus;
      if (this.completionDate) {
        updateStatus = {
          UserID: userId,
          // TaskStatus: this.complianceTaskStatus //taskObject.Status
          TaskStatus: complianceTaskStatus,
          CompletionDate: new Date(
            this.completionDate.year,
            this.completionDate.month,
            this.completionDate.day
          ),
        };
      } else {
        updateStatus = {
          UserID: userId,
          // TaskStatus: this.complianceTaskStatus //taskObject.Status
          TaskStatus: complianceTaskStatus,
        };
      }

      this.dashboardService.updateActivatedTask(updateStatus, taskObject._id).then((response) => {
        //console.log('response for the updatedActivatedTask ===>', response)
        if (!response.status) {
          //console.log('checking for the updated response', response);
          this.activeModal.close(response);
        }
      });
    } else {
      const updateStatus = {
        _id: taskObject._id,
        Status: taskObject.Status,
      };
      this.dashboardService.updateTask(updateStatus).then((response) => {
        if (!response.status) {
          //console.log('checking for the updated response', response);
          this.activeModal.close(response);
        }
      });
    }
  }
}

//////----------------------------------------------------

// this.taskDetails = [{
//   "ActivatedTaskFromCompliance":true,
//   "ActivatedTaskParticularId":"5a9e3b5654008c09b4e010f8",
//   "AssignedByID":"5a4de0fcfd7bc731b330ebf6",
//   "AssignedTo":"Sneha Lanjewar",
//   "CategoryName":"",
//   "CompanyID":"5a37c53fecb8830cf4137a41",
//   "CompletedDate":"2018-02-27T13:47:10.957Z",
//   "CompletionDays":10,
//   "CreatedBy":"5a4ddcf8aeafbf2d1450919a",
//   "CreatedDate":"2018-02-27T13:47:10.957Z",
//   "DateOption":2,
//   // DepartmentGroupID:1,
//   // DueDate:"2018-03-27T13:47:10.957Z",
//   // EmailContents:"",
//   "EndDate":"2018-03-27T13:47:10.957Z",
//   // Escalation1ofAssignedTo:"5a4de0fcfd7bc731b330ebf6",
//   // Escalation1ofResponsibleUser:"5a4de0fcfd7bc731b330ebf6",
//   // Escalation2ofAssignedTo:"5a4de0fcfd7bc731b330ebf6",
//   // Escalation2ofResponsibleUser:"5a4de0fcfd7bc731b330ebf6",
//   // EscalationDays:6,
//   // FilePath:"",
//   // Filename:"",
//   // GroupID:"5a8450861823713600e21333",
//   // IsPredefined:true,
//   // Level2EscalationDays:7,
//   // MeetingDate:"",
//   // MeetingID:"",
//   // MeetingTypeID:"5a27f2388e048bc3d05394a4",
//   // ModifiedBy:"5a4de0fcfd7bc731b330ebf6",
//   // ModifiedDate:"",
//   // OriginalFileName:"",
//   "Owner":"Swarup Maskare",
//   // PolicyType:2,
//   // PreConsequences:"test",
//   "ProcessName":"",
//   // ReminderDays:4,
//   // ReminderFrequency:5,
//   // ReminderFrequencyLevel1:2,
//   // ReminderFrequencyLevel2:4,
//   // ResponsibleUser:"5a4de0fcfd7bc731b330ebf6",
//   // RiskType:1,
//   "StartDate":"2018-02-22T05:49:59.426Z",
//   // TaskBackendName:"",
//   "TaskDescription":"task abc 22 feb",
//   "TaskID":"Task9740205",
//   "TaskName":"task abc 22 feb",
//   // TaskRemarks:"test",
//   "TaskStatus":"Selected",
//   // TriggerID:"5a2a90aa8e048bc3d056c472",
//   // TriggerStartDate:"2018-02-22T05:49:59.426Z",
//   // TriggerType:1,
//   // UserConsequences:"test",
//   "_id":"5a74665a75a7de2610222148",
// }]
