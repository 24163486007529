<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table table-hover compact cell-border nowrap stripe">
        <thead>
          <th>Category Name</th>
          <th>Global</th>
        </thead>
        <tbody>
          <tr *ngIf="isCategoryObjectEmpty">
            <td colspan="2" class="text-center">No records found.</td>
          </tr>
          <tr *ngFor="let category of categoryObject">
            <td>{{ category.CategoryName ?  category.CategoryName : " " }}</td>
            <td>{{ category.IsGlobal ? "Yes" : "No" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


