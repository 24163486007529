<!-- START: ecommerce/products-catalog -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Products Catalog</strong>
        </span>

        <div class="row">
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--primary">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Creative Chairs</span>
                        <p>Some description</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--default">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Beautiful Tables</span>
                        <p>Good news for you!</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--danger">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Nice Beds</span>
                        <p>Clean and simple</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--default">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Good Tables</span>
                        <p>Full modular</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--default">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Awesome Chairs</span>
                        <p>Some description</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-2">
                <a href="javascript: void(0);" class="cat__core__step cat__core__step--default">
                    <div class="cat__core__step__desc">
                        <span class="cat__core__step__title">Pretty Tables</span>
                        <p>Good news for you!</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="card-block">
        <div class="cat__ecommerce__catalog">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/001.jpg" alt="001"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Elliot Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $678.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $754.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/002.jpg" alt="002"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Maxtor Chair</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $245.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $166.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/003.jpg" alt="003"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Manroad Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $199.00
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/004.jpg" alt="004"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Align Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $542.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $356.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/003.jpg" alt="003"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Manroad Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $199.00
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/004.jpg" alt="004"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Align Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $542.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $356.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/001.jpg" alt="001"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Elliot Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $678.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $754.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/002.jpg" alt="002"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Maxtor Chair</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $245.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $166.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/001.jpg" alt="001"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Elliot Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $678.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $754.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/002.jpg" alt="002"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Maxtor Chair</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $245.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $166.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__like cat__ecommerce__catalog__item__like--selected">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/003.jpg" alt="003"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Manroad Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $199.00
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div class="cat__ecommerce__catalog__item">
                        <div class="cat__ecommerce__catalog__item__img">
                            <div class="cat__ecommerce__catalog__item__status">
                                <span class="cat__ecommerce__catalog__item__status__title">New</span>
                            </div>
                            <div class="cat__ecommerce__catalog__item__like">
                                <i class="icmn-heart"><!-- --></i>
                            </div>
                            <a href="javascript: void(0);">
                                <img src="assets/modules/ecommerce/common/img/004.jpg" alt="004"/>
                            </a>
                        </div>
                        <div class="cat__ecommerce__catalog__item__title">
                            <a href="javascript: void(0);">Align Glasses</a>
                            <div class="cat__ecommerce__catalog__item__price">
                                $542.00
                                <div class="cat__ecommerce__catalog__item__price--old">
                                    $356.00
                                </div>
                            </div>
                        </div>
                        <div class="cat__ecommerce__catalog__item__descr">
                            <div class="cat__ecommerce__catalog__item__descr__sizes">
                                <span data-toggle="tooltip" data-placement="right" title="Size S">S</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size M">M</span>
                                <span data-toggle="tooltip" data-placement="right" title="Size XL">XL</span>
                            </div>
                            Including Lenses
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: ecommerce/products-catalog -->
