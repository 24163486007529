<div class="card">
  <div class="card-header">
    <div style="float: left" (click)="navigationLink('', null)">
      <a href="javascript: void(0);">{{ 'File Manager ' }} </a>
    </div>
    <div *ngIf="showBreadCrumpath">
      <div style="float: left" *ngFor="let bread of breadcrumbs; let count = index"
        (click)="navigationLink(bread, count)">
        {{ ' / ' }}
        <a href="javascript: void(0);">{{ bread }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSpinner" class="center-all">
  <i class="fa fa-spinner fa-spin" style="font-size: 35px; color: #07a7a5"></i>
</div>

<div class="container" id="mainBodyContainer">
  <div class="row m-t-60" *ngIf="!isDeleteAllSelected">
    <!-- <div class="col-md-4"></div> -->
    <div class="col-md-5 text-body m-b-10 search-panel">
      <div class="search-bg">
        <label class="toggle-label">
          <input type="checkbox" />
          <span class="back" style="top: 0">
            <span class="toggle"></span>
            <span class="label on" (click)="getTypeToSerch($event)">NAME</span>
            <span class="label off" (click)="getTypeToSerch($event)">TAG</span>
          </span>
        </label>

        <div class="input-group add-on fl-r w-60">
          <input class="form-control" [formControl]="uploadForm.controls['searchByValue']" placeholder="Search"
            name="srch-term" id="srch-term" type="text" style="width: 60%; float: right"
            (keyup)="searchFileName($event)" />
          <div class="input-group-btn">
            <i class="fa fa-search" style="font-size: 18px"></i>
          </div>
        </div>
      </div>
      <div *ngIf="searchResultsList.length > 0" class="search-result">
        <div *ngFor="let searchResult of searchResultsList" style="cursor:pointer;">
          <i aria-hidden="true"
            [ngClass]="searchResult.recordType == 'file' ? 'fa fa-file-text' : 'fa fa-folder-open'"></i>
          <span (click)="serachFileRedirect(searchResult)">{{searchResult.recordName}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 text-body " *ngIf="sharedFolder == 'false' || sharedFolder  == undefined">
      <!-- <div class="col-md-5 upload-btn-wrapper">
        <button class="button button1">UPLOAD</button>
        <input type="file" name="file" (change)="fileEvent($event)"
          accept=".xlsx, .xls, .csv, .jpg, .jpeg, .png, .doc, .docx, .txt, .pdf, .ppt, .mp4" />
      </div> -->
      <button class="button button1" (click)="addNewFolder()">
        <i class="fa fa-plus" aria-hidden="true"></i> FOLDER
      </button>
    </div>
  </div>
  <div *ngIf="isDeleteAllSelected">
    <button class="btn btn-primary btn-sm" (click)="cancleSelectedList()">
      Cancel
    </button>
    <button class="btn btn-danger btn-sm" (click)="confirmMultipleDelete()">
      Delete selected
    </button>
  </div>
  <div class="row m-t-60">
    <div class="container-box" appDragndrop (fileDropped)="onFileDropped($event)">
      <input class="input-box" [disabled]="this.showSpinner==true" type="file" #fileDropRef id="fileDropRef" multiple  (change)="fileBrowseHandler($event.target.files)" />
      
      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
        <g fill="#3B454F" fill-rule="nonzero">
          <path
            d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
          <path
            d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
          <path
            d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
        </g>
      </svg>
      <div class="dragndropText">
        <h3>Drag and drop files here</h3>
        <h3>or</h3>
        <label class="label-file" for="fileDropRef">Browse for file</label>
      </div>
    </div>
  </div>
  
  <!-- modal for folder configuration -->
  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-s" role="document">
      <div class="modal-content">
        <div class="modal-header" style="justify-content: unset !important">
          <h4 class="modal-title" id="myModalLabel">Create Folder</h4>
          <button type="button" class="close" style="right: 5px; position: absolute" data-dismiss="modal"
            (click)="closeForm('myModal')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form [formGroup]="uploadForm" novalidate>
          <div class="modal-body pt-3 pb-3 mod-label">
            <div class="form-group d-inline-flex col-lg-12 p-0">
              <label class="folderLabel label col-lg-3 p-0 text-left">
                <span style="vertical-align: -webkit-baseline-middle">Folder Name
                </span>
              </label>
              <div class="col-lg-9">
                <input class="form-control" placeholder="enter folder name" type="text"
                  [formControl]="uploadForm.controls['folderName']" name="folderName" [value]="folderName"
                  (keyup)="getFolderName($event)" (change)="getFolderName($event)" style="margin-bottom: 10px"
                  required />
                <div *ngIf="
                    uploadForm.controls['folderName'].hasError('required') &&
                    uploadForm.controls['folderName'].touched
                  " class="form-control-error-custom p-0 mt-1">
                  Folder Name required.
                </div>
                <div *ngIf="
                    !uploadForm.controls['folderName'].valid &&
                    !uploadForm.controls['folderName'].hasError('required') &&
                    uploadForm.controls['folderName'].touched
                  " class="form-control-error-custom">
                  (Valid characters are A-Z a-z 0-9 _ - and space)
                </div>
                <div *ngIf="recordNameAlreadyPresent" class="form-control-error-custom p-0 mt-1">
                  Folder Name Already Present
                </div>
              </div>
            </div>
            <div class="form-group d-inline-flex col-lg-12 p-0">
              <label class="folderLabel label col-lg-3 p-0 text-left">
                <span style="vertical-align: -webkit-baseline-middle">Upload document
                </span>
              </label>
              <div class="col-lg-9">
                <input class="form-control" placeholder="upload file" type="file" multiple
                  (change)="multipleFileEvent($event)" [formControl]="
                    uploadForm.controls['multipleFileDocumentForUpload']
                  " name="{{ multipleFileDocumentForUpload }}" style="width: 93px"
                  accept=".xlsx, .xls, .csv, .jpg, .jpeg, .png, .doc, .docx, .txt, .pdf, .ppt, .mp4" />
                <span style="color: red" *ngIf="!multifileExtension">Please upload valid file format.</span>
              </div>
            </div>
            <div *ngFor="let file of uploadMultipleFileDocument; let i = index">
              <span>{{ file.name }}</span>
              <span class="fa fa-times" (click)="deleteSelectedFile($event, file, i)"></span>
            </div>

            <div class="col-lg-12 p-0" *ngIf="showAddTagButtonForCreateFolder">
              <div class="col-lg-10" *ngIf="showAddTagDiv" style="
                  border: 1px solid lightgrey;
                  padding: 5px;
                  min-height: inherit !important;
                ">
                <button class="btn btn-sm btn-primary" style="margin-right: 10px; margin-bottom: 5px"
                  *ngFor="let tag of selectedTagsForFolder" (click)="removeSelectedTagsForFolder(tag)">
                  {{ tag.label }}
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div class="col-lg-2" style="float: right">
                <button class="btn btn-sm btn-primary" (click)="tagPopForFolders()">
                  Add Tags
                </button>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" (click)="saveFolderNameWithFileUplaod()" [disabled]="
                (recordNameAlreadyPresent && !uploadForm.valid) ||
                disabledOnclick ||
                !multifileExtension ||
                uploadForm.controls['folderName'].hasError('required')
              ">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- model for  folder created successfully -->
  <div class="modal fade" id="folderCreatedSuccessfully" role="dialog" aria-labelledby="folderCreatedSuccessfully">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="folderCreatedSuccessfully">
            <i class="mr-2"></i>Success
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Folder created successfully</p>
        </div>
      </div>
    </div>
  </div>

  <!-- shared file deleted successfully -->
  <div class="modal fade" id="sharedFileDeleteSuccessfully" role="dialog"
    aria-labelledby="sharedFileDeleteSuccessfully">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sharedFileDeleteSuccessfully">
            <i class="mr-2"></i>Success
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Shared File Deleted successfully</p>
        </div>
      </div>
    </div>
  </div>

  <!-- shared folder deleted successfully -->
  <div class="modal fade" id="sharedFolderDeleteSuccessfully" role="dialog"
    aria-labelledby="sharedFolderDeleteSuccessfully">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sharedFolderDeleteSuccessfully">
            <i class="mr-2"></i>Success
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Shared Folder Deleted successfully</p>
        </div>
      </div>
    </div>
  </div>

  <!-- file rename successfully -->
  <div class="modal fade" id="renameFolderModalSuccessfull" role="dialog"
    aria-labelledby="renameFolderModalSuccessfull">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="renameFolderModalSuccessfull">
            <i class="mr-2"></i>Success
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>File Rename successfully</p>
        </div>
      </div>
    </div>
  </div>

  <!--modal for rename File Name -->
  <div class="modal fade" id="renameFileModal" role="dialog" aria-labelledby="renameFileModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="renameFileModal">
            <i class="mr-2"></i>Rename File Name
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-3 pb-3 mod-label">
          <form [formGroup]="uploadForm" novalidate>
            <div class="form-group d-inline-flex col-lg-12 p-0">
              <label class="folderLabel label col-lg-3 p-0 text-left">
                <span style="vertical-align: -webkit-baseline-middle">File Name
                </span>
              </label>
              <div class="col-lg-9">
                <input class="form-control" placeholder="enter file name" type="text"
                  [formControl]="uploadForm.controls['fileName']" name="fileName" [value]="fileName"
                  (keyup)="getNewFileName($event)" (change)="getNewFileName($event)" style="margin-bottom: 10px"
                  required />
                <div *ngIf="
                    uploadForm.controls['fileName'].hasError('required') &&
                    uploadForm.controls['fileName'].touched
                  " class="form-control-error-custom p-0 mt-1">
                  File Name required.
                </div>
                <div *ngIf="fileAfterRenameSame" class="form-control-error-custom p-0 mt-1">
                  File Name Already Present
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" (click)="renameFileName()"
            [disabled]="fileAfterRenameSame">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--modal for rename Folder Name -->
  <div class="modal fade" id="renameFolderModal" role="dialog" aria-labelledby="renameFolderModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="renameFolderModal">
            <i class="mr-2"></i>Rename Folder Name
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-3 pb-3 mod-label">
          <form [formGroup]="uploadForm" novalidate>
            <div class="form-group d-inline-flex col-lg-12 p-0">
              <label class="folderLabel label col-lg-3 p-0 text-left">
                <span style="vertical-align: -webkit-baseline-middle">Folder Name
                </span>
              </label>
              <div class="col-lg-9">
                <input class="form-control" placeholder="enter folder name" type="text" name="folderNameAfterRename"
                  [formControl]="uploadForm.controls['folderNameAfterRename']" [value]="folderNameAfterRename"
                  (keyup)="getNewFolderName($event)" (change)="getNewFolderName($event)" style="margin-bottom: 10px"
                  required />
                <div *ngIf="
                    uploadForm.controls['folderNameAfterRename'].hasError(
                      'required'
                    ) && uploadForm.controls['folderNameAfterRename'].touched
                  " class="form-control-error-custom p-0 mt-1">
                  Folder Name required.
                </div>
                <div *ngIf="
                    !uploadForm.controls['folderNameAfterRename'].valid &&
                    !uploadForm.controls['folderNameAfterRename'].hasError(
                      'required'
                    ) &&
                    uploadForm.controls['folderNameAfterRename'].touched
                  " class="form-control-error-custom">
                  (Valid characters are A-Z a-z 0-9 _ - and space)
                </div>
                <div *ngIf="folderNamePresentWhileRename" class="form-control-error-custom p-0 mt-1">
                  Folder Name Already Present
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" (click)="renameFolderName()"
            [disabled]="
              folderNamePresentWhileRename ||
              !uploadForm.controls['folderNameAfterRename'].valid
            ">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--confirm delete file dialogbox-->
  <div class="modal fade" id="deleteFileModel" role="dialog" aria-labelledby="deleteFileModel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteFileModel">
            <i class="fa fa-question-circle-o mr-2"></i>Confirm
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this file?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" (click)="confirmDeleteFile()">
            Confirm
          </button>
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
            (click)="closeForm('deleteFileModel')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- confirm delete share file modal -->
  <div class="modal fade" id="deleteSharedFileModel" role="dialog" aria-labelledby="deleteSharedFileModel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteSharedFileModel">
            <i class="fa fa-question-circle-o mr-2"></i>Confirm
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this file?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" (click)="confirmDeleteSharedFile()">
            Confirm
          </button>
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
            (click)="closeForm('deleteSharedFileModel')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--confirm delete folder dialogbox-->
  <div class="modal fade" id="deleteFolderModel" role="dialog" aria-labelledby="deleteFolderModel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteFolderModel">
            <i class="fa fa-question-circle-o mr-2"></i>Confirm
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this folder?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" (click)="confirmDeleteFolder()">
            Confirm
          </button>
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
            (click)="closeForm('deleteFolderModel')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--modal for share file -->
  <div class="modal fade" id="shareFileModal" role="dialog" aria-labelledby="shareFileModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="shareFileModal">
            <i class="mr-2"></i>Share
          </h5>
          <button class="btn btn-primary btn-sm" style="float: right" type="button" (click)="shareRecords()">
            Done
          </button>
        </div>
        <div class="modal-body pt-3 pb-3 mod-label">
          <div *ngFor="let user of userListToBind; let i = index">
            <span>{{ user.label }}</span>
            <div>
              <form action="/action_page.php">
                <div class="form-check-inline">
                  <label class="form-check-label" for="check1">
                    <input type="checkbox" class="form-check-input" id="{{ 'view' + i }}" name="vehicle1"
                      value="something" />view
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label" for="check2">
                    <input type="checkbox" class="form-check-input" id="{{ 'annotate' + i }}" name="vehicle2"
                      value="something" />annotate
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" id="{{ 'share' + i }}" />share
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label" for="check2">
                    <input type="checkbox" class="form-check-input" id="{{ 'delete' + i }}" name="vehicle2"
                      value="something" />delete
                  </label>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <!--confirm delete multiple records dialogbox-->
  <div class="modal fade" id="confirmMultipleDelete" role="dialog" aria-labelledby="confirmMultipleDelete">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmMultipleDelete">
            <i class="fa fa-question-circle-o mr-2"></i>Confirm
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this item?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" (click)="deleteMultipleRecords()">
            Yes
          </button>
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
            (click)="closeForm('confirmMultipleDelete')">
            No
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- model for uplaoded file already present -->
  <div class="modal fade" id="uploadedSingleFileAlreadyPresent" role="dialog"
    aria-labelledby="uploadedSingleFileAlreadyPresent">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadedSingleFileAlreadyPresent">
            <i class="fa fa-question-circle-o mr-2"></i>
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Uploaded File Already Present</p>
        </div>
      </div>
    </div>
  </div>

  <!-- recent files -->
  <div *ngIf="recentFileList" class="backgroundBlur">
    <div class="row m-t-60">
      <div class="col-md-12">
        <h4>Recent Files</h4>
      </div>
    </div>

    <div class="row m-t-10 m-b-10" id="test1">
      <div class="col-md-3 col-sm-6 white-box m-b-20" style="position:relative"
        *ngFor="let file of recentFileList;let i=index">
        <div class="wht-card p-10" data-toggle="tooltip" [title]='file.recordName'>
          <i class="fa fa-ellipsis-h" aria-hidden="true" style="float:right;"
            (contextmenu)="rightClick($event,file,i,'recentFile')"
            (click)="showRecentFileMenu(file,i,'recentFile')"></i>
          <div class="text-body">
            <p>
              <span *ngIf="isDeleteAllSelected">
                <input name="selectrecord" type="checkbox" style="float: left; margin: 9px 7px 0 0; width: 25px"
                  (click)="getCheckedRecords($event, file)" />
              </span>
              <i aria-hidden="true" class="fa fa-file-text"></i>{{ sendShortName(file.recordName) }}
            </p>
          </div>
          <i *ngIf="file.recordName.split('.').slice(-1) == 'pdf'" aria-hidden="true" class="fa fa-eye fa-lg"
            style="float: right; margin-top:25px; cursor:pointer; " (click)="openFileInNewTab(file)">
          </i>
        </div>
        <div id="{{ 'recentFile' + i }}" class="hide dropdown clearfix rightMenu">
          <ul class="ulList">
            <li style="cursor: pointer">
              <a (click)="shareFileDialogBox(file, i)">Share</a>
            </li>
            <li style="cursor: pointer">
              <a (click)="renameFileDialogBox(file, i, 'recentFile')">Rename</a>
            </li>
            <li style="cursor: pointer">
              <a (click)="openTagPopupComponent(file, i, 'recentFile')">Tags</a>
            </li>
            <li style="cursor: pointer">
              <a (click)="confirmDeleteFileDialogBox(file, i)">Delete</a>
            </li>
            <li style="cursor: pointer">
              <a (click)="deleteSelectedRecords(file)">Delete selected</a>
            </li>
            <li style="cursor: pointer">
              <a (click)="
                  downloadGFMFileDialogBox(file, i, 'recentFile', 'false')
                ">Download</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- for folders -->
  <div *ngIf="folderList.length > 0" class="backgroundBlur">
    <div class="row m-t-60">
      <div class="col-md-12">
        <h4>All Folders</h4>
      </div>
    </div>

    <div class="row m-t-10 m-b-10">
      <div class="col-md-3 col-sm-4 white-box m-b-20 text-center" style="position: relative"
        *ngFor="let folder of folderList; let i = index">
        <div data-toggle="tooltip" [title]="folder.recordName">
          <div class="p-10 record" style="
              background: #fff;
              overflow: hidden;
              padding: 0;
              box-shadow: 0 0 16px #c7c5d8;
              -webkit-box-shadow: 0 0 16px #c7c5d8;
              -moz-box-shadow: 0 0 16px #c7c5d8;
              -ms-box-shadow: 0 0 16px #c7c5d8;
            ">
            <span *ngIf="isDeleteAllSelected">
              <input name="vehicle1" type="checkbox" style="width: 30px; float: left; margin: 21px 0"
                (click)="getCheckedRecords($event, folder)" />
            </span>

            <i class="fa fa-ellipsis-h" aria-hidden="true" style="
                float: right;
                position: absolute;
                right: 20px;
                border: 1px solid #c5c1c1;
                padding: 5px;
                border-radius: 50%;
                height: 23px;
                width: 23px;
                text-align: center;
                top: 18px;
              " (contextmenu)="rightClick($event, folder, i, 'folder')"
              (click)="showFolderMenu(folder, i, 'folder')"></i>
            <div class="text-body" (click)="
                sharedFolder
                  ? getSelectedFolderFileList(folder, 'true', i)
                  : getSelectedFolderFileList(folder, 'false', i)
              " style="cursor: pointer">
              <p>
                <i class="fa fa-folder" aria-hidden="true"></i>
              </p>
              <p style="white-space: pre">
                {{ sendShortName(folder.recordName) }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="sharedFolder == undefined && isSharedFolder == undefined || isSharedFolder == 'false'">
          <div id="{{'folder'+i}}" class="hide dropdown clearfix rightMenu">
            <ul class="ulList">
              <li style="cursor: pointer">
                <a (click)="shareFileDialogBox(folder, i)">Share</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="renameFolderDialogBox(folder, i, 'folder')">Rename</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="openTagPopupComponent(folder, i, 'allFiles')">Tags</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="confirmDeleteFolderDialogBox(folder)">Delete</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="deleteSelectedRecords(folder)">Delete selected</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="
                    downloadGFMFileDialogBox(folder, i, 'allFiles', 'false')
                  ">Download</a>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="sharedFolder == 'true' || isSharedFolder == 'true'">
          <div id="{{ 'folder' + i }}" class="hide dropdown clearfix shareRightMenu rightMenu"
            style="right: 10px !important">
            <ul class="ulList">
              <div *ngFor="let permisson of folder.recordSharedWith; let i = index">
                <div *ngIf="permisson.uniqueUserId == loggedInUserUniqueId">
                  <div *ngFor="let p of permisson.permissions">
                    <li class="" style="cursor: pointer; padding: 5px 20px" *ngIf="p != 'view' && p != 'annotate'">
                      <a (click)="shareCheckType(folder, i, p)">{{
                        p | titlecase
                        }}</a>
                    </li>
                  </div>
                </div>
              </div>
              <li style="cursor: pointer">
                <a (click)="confirmDeleteSharedFileDialogBox(folder, i)">Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- all files -->
  <div *ngIf="fileList.length > 0" class="backgroundBlur">
    <div class="row m-t-60">
      <div class="col-md-12">
        <h4>All Files</h4>
      </div>
    </div>

    <div class="row m-t-10 m-b-10">
      <div class="col-md-3 col-sm-6 white-box m-b-20" style="position: relative"
        *ngFor="let file of fileList; let i = index">
        <div class="wht-card p-10" data-toggle="tooltip" [title]="file.recordName">
          <i class="fa fa-ellipsis-h" aria-hidden="true" style="float: right"
            (contextmenu)="rightClick($event, file, i, 'allFiles')" (click)="showAllFileMenu(file, i, 'allFiles')"></i>
          <div class="text-body">
            <p>
              <span *ngIf="isDeleteAllSelected">
                <input name="selectrecord" type="checkbox" style="float: left; margin: 9px 7px 0 0; width: 25px"
                  (click)="getCheckedRecords($event, file)" />
              </span>
              <i _ngcontent-c1="" aria-hidden="true" class="fa fa-file-text"></i>{{ sendShortName(file.recordName) }}
            </p>
          </div>
          <i *ngIf="file.recordName.split('.').slice(-1) == 'pdf'" aria-hidden="true" class="fa fa-eye fa-lg"
            style="float: right; margin-top:25px; cursor:pointer; " (click)="openFileInNewTab(file)">
          </i>
        </div>

        <div *ngIf="
            (sharedFolder == undefined && isSharedFolder == undefined) ||
            isSharedFolder == 'false'
          ">
          <div id="{{ 'allFiles' + i }}" class="hide dropdown clearfix rightMenu">
            <ul class="ulList">
              <li style="cursor: pointer">
                <a (click)="shareFileDialogBox(file, i)">Share</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="renameFileDialogBox(file, i, 'allFiles')">Rename</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="openTagPopupComponent(file, i, 'allFiles')">Tags</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="confirmDeleteFileDialogBox(file, i)">Delete</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="deleteSelectedRecords(file)">Delete selected</a>
              </li>
              <li style="cursor: pointer">
                <a (click)="
                    downloadGFMFileDialogBox(file, i, 'allFiles', 'false')
                  ">Download</a>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="sharedFolder == 'true' || isSharedFolder == 'true'">
          <div id="{{ 'allFiles' + i }}" class="hide dropdown clearfix shareRightMenu rightMenu"
            style="right: 10px !important">
            <ul class="ulList">
              <div *ngFor="let permisson of file.recordSharedWith; let i = index">
                <div *ngIf="permisson.uniqueUserId == loggedInUserUniqueId">
                  <div *ngFor="let p of permisson.permissions">
                    <li class="" style="cursor: pointer; padding: 5px 20px" *ngIf="p != 'view' && p != 'annotate'">
                      <a (click)="shareCheckType(file, i, p)">{{
                        p | titlecase
                        }}</a>
                    </li>
                  </div>
                </div>
              </div>
              <li style="cursor: pointer">
                <a (click)="confirmDeleteSharedFileDialogBox(file, i)">Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- share files -->
  <div *ngIf="showShareListDiv" class="backgroundBlur">
    <div class="row m-t-60">
      <div class="col-md-12">
        <h4>Shared Files and Folders</h4>
      </div>
    </div>

    <div class="row m-t-10 m-b-10 right-po">
      <div class="col-md-3 col-sm-6 white-box m-b-20" style="position: relative"
        *ngFor="let file of shareList; let i = index">
        <div class="wht-card p-10" data-toggle="tooltip" [title]="file.recordName">
          <i class="fa fa-ellipsis-h" aria-hidden="true" style="float: right"
            (contextmenu)="rightClick($event, file, i, 'shareFiles')"
            (click)="showShareFileMenu(file, i, 'shareFiles')"></i>
          <div class="text-body">
            <p>
              <span *ngIf="file.recordType == 'file'">
                <i aria-hidden="true" class="fa fa-file-text"></i>
                {{ sendShortName(file.recordName) }}</span>
              <span *ngIf="file.recordType == 'folder'" (click)="getSelectedFolderFileList(file, 'true', i)"
                style="cursor: pointer">
                <i aria-hidden="true" class="fa fa-folder-open"></i>
                {{ sendShortName(file.recordName) }}</span>
            </p>
          </div>
          <i *ngIf="file.recordName.split('.').slice(-1) == 'pdf'" aria-hidden="true" class="fa fa-eye fa-lg"
            style="float: right; margin-top:25px; cursor:pointer; " (click)="openFileInNewTab(file)">
          </i>
        </div>

        <div id="{{ 'shareFiles' + i }}" class="hide dropdown clearfix shareRightMenu rightMenu"
          style="right: 10px !important">
          <ul class="ulList">
            <div *ngFor="let permisson of file.recordSharedWith; let i = index">
              <div *ngIf="permisson.uniqueUserId == loggedInUserUniqueId">
                <div *ngFor="let p of permisson.permissions">
                  <li class="" style="cursor: pointer; padding: 5px 20px" *ngIf="p != 'view' && p != 'annotate'">
                    <a (click)="shareCheckType(file, i, p)">{{
                      p | titlecase
                      }}</a>
                  </li>
                </div>
              </div>
            </div>
            <li style="cursor: pointer">
              <a (click)="confirmDeleteSharedFileDialogBox(file, i)">Delete</a>
            </li>
            <li style="cursor:pointer;">
              <a (click)="downloadGFMFileDialogBox(file,i,'shareFiles','true')">Download</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


