<div class="modal fade" id="environmentModal" tabindex="-1" role="dialog">
	<div class="modal-dialog modal-xl" role="document" style="max-width:1200px">
		<div class="modal-content">
			<div class="modal-header border-bottom-0 pb-0 pl-3 app-theme-bg">
				<nav ngbNav #nav="ngbNav" class="nav-tabs nav p-1" (navChange)="onNavChange($event)" [destroyOnHide]="false">
					<ng-container [ngbNavItem]="1">
						<a ngbNavLink>Summary</a>
						<ng-template ngbNavContent class="pt-3">
							<div class="card-block watermark">
								<div class="row">
									<div id="pieChart" class="col-lg-5">
									</div>
									<div id="barChart" class="col-lg-7 pl-0">
									</div>
									<div id="columnChart" class="col-lg-12">
									</div>
									<div id="summary-grid" class="col-lg-12" *ngIf="isChartsApisLoaded">
										<ag-grid-angular style="width: 100%; height: 300px;" class="ag-theme-alpine"
											[gridOptions]="summaryGridOptions"></ag-grid-angular>
									</div>
								</div>
							</div>
						</ng-template>
					</ng-container>
				</nav>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" style="color: black;">&times;</span>
        </button>
      </div>
			<div class="modal-body pt-0 app-theme-bg">
				<div [ngbNavOutlet]="nav" class=""></div>
			</div>
		</div>
	</div>
</div>