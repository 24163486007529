import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { UINotificationService } from './../../services/notificationUI.service';
import { UserMasterService } from './../../services/user-master.service';
import { RocketChatService } from './../../services/rocketChat.service';

import { EditUserModalComponent } from './../../structure/user-master/edit-user-modal/edit-user-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../environments/environment';
import { LoginService } from '../../services/login.service';
import { rocketChatRemove } from '../../enums/rocketChatRemove';
import { LastLoginDetailsService } from '../../services/last-login-details.service';
import { BoardEvalStaticComponent} from '../../board-eval-static/board-eval-static.component'

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: 'cat-top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent implements OnChanges {
  @Input('notificationData') notificationData: any;
  @Input('associatedCompanyList') associatedCompanyList: any;
  @Input('companyLogo') companyLogo: any;
  @Output() changeCompany = new EventEmitter();
  companyName: string = '';
  subsidiaryCompanyName: string = '';
  loggedinUserId: string = '';
  loggedinUserProfilePic: string = '';
  lastLoginDetails: any = {};
  enableLoginDetails: Boolean = false;
  modalRef: NgbModalRef;
  userType: any;
  chat: boolean = environment.Service.Chat;

  constructor(
    private router: Router,
    private uiNotificationService: UINotificationService,
    private userMasterService: UserMasterService,
    private rocketChatService: RocketChatService,
    private modalService: NgbModal,
    private lastloginService: LastLoginDetailsService,
    private loginService: LoginService
  ) {}


  toggleCompanySelectTopBarDropdown(){
    var x = document.getElementById('companySelectTopBarDropdown');
    if(x.style.display != 'block'){
      x.style.display = 'block';
    }else{
      x.style.display = 'none';
    }
    
  }

  toggleTopBarProfileDropdown(){

    var x = document.getElementById('TopBarProfileDropdown').classList;
    if(x.contains('show')){
      x.remove('show');
    }else{
      x.add('show');
    }

  }
  
  toggleNav() {
    var x = document.getElementById('leftMenu').classList;
    var leftSpace = document.getElementById('maincontent').classList;

    if (x.contains('visible')) {
      x.remove('visible');
      leftSpace.remove('visible');
      var catobj:any = document.getElementsByClassName(
        'cat__menu-left__logo'
      )[0]
      catobj.style.width = '0';
    } else {
      x.add('visible');
      leftSpace.add('visible');
      var catobj:any = document.getElementsByClassName(
        'cat__menu-left__logo'
      )[0]
      catobj.style.width = '250px';
    }

    var meterclass = document.getElementById('meters').classList;
    var calClass = document.getElementById('meetingCol').classList;

    if (x.contains('visible')) {
      meterclass.add('col-md-12');
      meterclass.remove('col-md-6');
      calClass.add('col-md-12');
      calClass.remove('col-md-6');
    } else {
      meterclass.add('col-md-6');
      meterclass.remove('col-md-12');
      calClass.add('col-md-6');
      calClass.remove('col-md-12');
    }
  }
  
  logOut() {
    //remove data from local storage

    if (rocketChatRemove.isRemove.toString() != 'true') {
      this.rocketChatService
        .logoutFromRocketChat()
        .then((rocketChatResponse) => {
          if (rocketChatResponse.status == 'success') {
            for (var count = 0; count < 10000; count++) {
              window.clearInterval(count);
            }
          }
        });
    } else {
      this.loginService.logOut().subscribe(
        (response) => {
          window.localStorage.clear();

          // location.reload();
          for (var count = 0; count < 10000; count++) {
            window.clearInterval(count);
          }
          this.router.navigate(['/login']);
        },
        (err) => (error) => {
          window.localStorage.clear();

          // location.reload();
          for (var count = 0; count < 10000; count++) {
            window.clearInterval(count);
          }
          this.router.navigate(['/login']);
        }
      );
    }
  }

  logOutSystem() {
    //remove data from local storage

    if (rocketChatRemove.isRemove.toString() != 'true') {
      this.rocketChatService.logoutFromSystem().then((systemResponce) => {
        if (systemResponce.status == 'success') {
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    //
    var sessionValue = sessionStorage.getItem('SessionName');
    if (sessionValue != 'userLoggedIn') {
      this.logOut();
    }
    let userInfo = JSON.parse(localStorage.getItem('user'));
    this.userType = userInfo.TypeOfUser;

    this.companyName = JSON.parse(localStorage.getItem('user')).CompanyName;
    this.subsidiaryCompanyName = localStorage.getItem('subsidiaryCompanyName');

    this.loggedinUserId = JSON.parse(localStorage.getItem('user'))._id;

    this.userMasterService
      .getUserByUserId(this.loggedinUserId)
      .then((response) => {
        if (response.status == 0) {
          if (
            response.userDetails.ProfilePicture != undefined &&
            response.userDetails.ProfilePicture != null &&
            response.userDetails.ProfilePicture != ''
          ) {
            $('#userProfilePic').attr(
              'src',
              response.userDetails.ProfilePicture
            );
          } else {
            $('#userProfilePic').attr('src', 'assets/images/DefaultImage.png');
            // $("#userProfilePic").attr("src", "assets/images/profile.svg");
            // this.loggedinUserProfilePic = "";
          }
        }
      });

    this.lastLoginDetails = this.lastloginService.getUserDetails();
    if (!_.isUndefined(this.lastLoginDetails)) {
      this.enableLoginDetails = true;
    } else {
      this.enableLoginDetails = false;
    }
  }

  ngOnChanges() {}

  viewNotification(notificationID) {
    this.uiNotificationService
      .markNotificationStatusToReadByID(notificationID)
      .then((response) => {
        if (response.status == 0) {
          // let asd: any = _.filter(this.notificationData.notificationList, function (o) { return o._id === notificationID });
          // this.router.navigate(['UInotifications', notificationID]);
          this.router.navigate([
            'structure',
            {
              outlets: {
                'structure-outlet': ['UInotifications', notificationID],
              },
            },
          ]);
        }
      });
  }

  viewAllNotification() {
    // this.router.navigate(['UInotifications', 0]);
    this.router.navigate([
      'structure',
      { outlets: { 'structure-outlet': ['UInotifications', 0] } },
    ]);
  }

   openBoardEvalPage() {
    const modalRef = this.modalService.open(BoardEvalStaticComponent, {
      size: 'lg',
      backdrop: 'static'
    })
    
   }
  //Profile edit functionlity from dashboard
  openEditProfilePopUp() {
    const modalRef = this.modalService.open(EditUserModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.userId = this.loggedinUserId;
    //'shouldDisable' disable fields if page hits from dashboard.
    modalRef.componentInstance.shouldDisable = true;
    modalRef.result.then((response) => {
      if (!response.status) {
        $('#alertTitle').text('Success!');
        $('#alertTitle').css('color', 'black');
        $('#alertMessage').text('User updated successfully.');
        $('#alertMessage').css('color', 'black');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      } else {
        //
        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text('Failure!');
        $('#alertMessage').text(response.message);
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
    });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  demo(company) {
    localStorage.setItem('isSubsidiary', 'false');
    localStorage.removeItem('subsidiaryCompanyName');
    localStorage.removeItem('subsidiaryFincode');
    this.changeCompany.emit(company);
    // location.reload();
  }

  switchToSubsidiary(fincode, subCompanyName) {
    localStorage.setItem('subsidiaryFincode', fincode);
    var isSubsidiary = localStorage.getItem('isSubsidiary');
    localStorage.removeItem('isSubsidiary');
    localStorage.setItem('isSubsidiary', 'true');
    localStorage.setItem('subsidiaryCompanyName', subCompanyName);
    // this.companyName = subCompanyName;
    location.reload();
  }

  navigateToChangePasswordScreen() {
    this.router.navigate(['/changePassword']);
  }

  openChatModel(content) {
    this.modalRef = this.modalService.open(content, { size: 'xl' as any });
  }

  closeModal() {
    this.modalRef.close();
  }
}
