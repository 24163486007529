<div class="card-block meetingtab p-0">

  <div class="nav-tabs-horizontal">
    <ul class="nav nav-tabs tab-list" style="border: none" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          data-target="#meetingTab"
          data-toggle="tab"
          href="javascript: void(0);"
          role="tab"
          aria-expanded="true"
          (click)="tabSelected('meetingTab')"
          >My Meetings</a
        >
      </li>
      <li class="nav-item" *ngIf="rowFive">
        <a
          class="nav-link"
          data-target="#teamTaskTab"
          data-toggle="tab"
          href="javascript: void(0);"
          role="tab"
          aria-expanded="false"
          (click)="tabSelected('teamTaskTab')"
          >Team Task</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="meetingTab"
        role="tabcard"
        aria-expanded="true"
      >
        <div class="example-calendar-block" id="meeting"></div>
      </div>

      <div class="tab-pane" id="teamTaskTab" role="tabcard" aria-expanded="true">
        <div class="example-calendar-block" id="teamTask"></div>
      </div>
    </div>
  </div>
</div>
