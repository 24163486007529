import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RocketChatService } from '../services/rocketChat.service';
import { Router } from '@angular/router';

import { rocketChatRemove } from '../enums/rocketChatRemove';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private rocketChatService: RocketChatService
  ) {}

  ngOnInit() {
    //Following code will be executed when user logout from pss.
    //remove data from local storage
    if (rocketChatRemove.isRemove.toString() != 'true') {
      this.rocketChatService
        .logoutFromRocketChat()
        .then((rocketChatResponse) => {
          if (rocketChatResponse.status == 'success') {
            for (var count = 0; count < 10000; count++) {
              window.clearInterval(count);
            }
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('resources');
            localStorage.removeItem('rocketAuth');
            localStorage.removeItem('rocketUserId');
            localStorage.removeItem('rocketBaseURL');
            this.router.navigate(['/login']);
          }
        });
    } else {
      for (var count = 0; count < 10000; count++) {
        window.clearInterval(count);
      }
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('resources');
      localStorage.removeItem('rocketBaseURL');
      console.log('user logout');
      this.router.navigate(['/login']);
    }
  }
}
