import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-meetingcompliance',
  templateUrl: './meetingcompliance.component.html',
  styleUrls: ['./meetingcompliance.component.css']
})
export class MeetingcomplianceComponent implements OnInit {

  @Input('complianceObject') complianceObject: any;
  isComplianceObjectEmpty : boolean
  constructor() { }

  ngOnInit() {
    if(_.size(this.complianceObject)){
      this.isComplianceObjectEmpty = false;
    }else{
      this.isComplianceObjectEmpty = true;
    }


    


  }

  getTaskStatus(statusCode){

    switch (statusCode){
      case 1 : 
        return "Pending";
      case 2 : 
      return "Completed";
      case 3 : 
      return "Delayed Completion";
      case 4 : 
      return "Partially Completed";
      case 5 : 
      return "NA";
      case 6 : 
      return "Done";
      case 7 : 
      return "Cancelled";
    }

  //   if (taskStatusId == 1)
  //   this.complianceTaskStatus = "Pending";
  // else if (taskStatusId == 2)
  //   this.complianceTaskStatus = "Completed";
  // else if (taskStatusId == 3)
  //   this.complianceTaskStatus = "Delayed Completion";
  // else if (taskStatusId == 4)
  //   this.complianceTaskStatus = "Partially Completed";
  // else if (taskStatusId == 5)
  //   this.complianceTaskStatus = "NA";
  // else if (taskStatusId == 6)
  //   this.complianceTaskStatus = "Done";
  // else if (taskStatusId == 7)
  //   this.complianceTaskStatus = "Cancelled";
  }

}
