import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()

export class FinancialDataService {
    constructor(private http: HttpClient) { }

    //Get last five Quarters Profit and Loss Results
    getLastFiveQuartersPnLResults() {
        const url = environment.clientUrl + 'dashboard/financialchart/fiveQuartersProfitLossResults';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }

    //Get last five Years Profit and Loss Results
    getLastFiveYearsPnLResults() {
        const url = environment.clientUrl + 'dashboard/financialchart/fiveYearsProfitLossResults';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }

    //Get last five Years Balance Sheet Results
    getLastFiveYearsBalanceSheetResults() {
        const url = environment.clientUrl + 'dashboard/financialchart/fiveYearsBalanceSheetResults';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }

    //Get last five Quaters Key Ratios
    getLastFiveQuatersKeyRatios() {
        const url = environment.clientUrl + 'dashboard/financialchart/fiveQuartersKeyRatios';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }

    //Get last five Years Key Ratios
    getLastFiveYearsKeyRatios() {
        const url = environment.clientUrl + 'dashboard/financialchart/fiveYearsKeyRatios';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }

    getInternalAndRFactorScore() {
        const url = environment.clientUrl + 'currentStateAnalysis/getCategoryScore';
        return this.http.get(url)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError);
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}