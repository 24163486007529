<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table table-hover compact cell-border nowrap stripe">
        <thead>
          <tr>
            <th>Director Name</th>
            <th>Designation</th>
            <th>Appointment Date</th>
            <th>Executive/Non-Executive</th>
            <th>Chairman</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngIf="isDirectorObjectEmpty">
                <td colspan="5" class="text-center">
                    No Records Found.
                </td>              
              </tr>
          <tr *ngFor="let director of directorObject">
            <td>{{director.DirectorName}}</td>
            <td>{{director.Designation}}</td>
            <td>{{director.AppointmentDate ? (director.AppointmentDate | date) : " "}}</td>
            <td>{{director.IsExecutive}}</td>
            <td>{{ (director.Chairman ? ((director.Chairman == "Yes" || director.Chairman == "true")? "Yes" : "No") : " " )}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
