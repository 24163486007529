<!-- START: pages/register -->
<div class="cat__pages__login" style="background-image: url(assets/modules/pages/common/img/login/1.jpg)">
    <div class="cat__pages__login__header">
        <div class="row">
            <div class="col-lg-8">
                <div class="cat__pages__login__header__logo">
                    <a href="javascript: history.back();">
                        <img src="assets/modules/pages/common/img/logo-inverse.png" alt="Clean UI Admin Template" />
                    </a>
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 switch-to-fullscreen">Switch Fullscreen / Windowed</a>
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 random-bg-image" data-img="1">Randomize Background Image</a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cat__pages__login__header__menu">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="javascript: history.back();">&larr; Back</a></li>
                        <li class="list-inline-item active"><a href="javascript: void(0);">Login</a></li>
                        <li class="list-inline-item"><a href="javascript: void(0);">About</a></li>
                        <li class="list-inline-item"><a href="javascript: void(0);">Support</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__block">
        <div class="row">
            <div class="col-xl-12">
                <div class="cat__pages__login__block__inner">
                    <div class="cat__pages__login__block__form">
                        <h4 class="text-uppercase">
                            <strong>Please register</strong>
                        </h4>
                        <br />
                        <form id="form-validation" name="form-validation" method="POST">
                            <div class="form-group">
                                <div class="form-input-icon form-input-icon-right">
                                    <i class="icmn-spinner11 cat__core__spin"></i>
                                    <input id="validation-email"
                                           class="form-control"
                                           placeholder="Email or Username"
                                           name="validation[email]"
                                           type="text"
                                           data-validation="[EMAIL]">
                                </div>
                            </div>
                            <div class="form-group">
                                <input id="validation-password"
                                       class="form-control password"
                                       name="validation[password]"
                                       type="password" data-validation="[L>=6]"
                                       data-validation-message="$ must be at least 6 characters"
                                       placeholder="Password">
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Repeat Password">
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-primary">Sign Up</button>
                                <label class="ml-3">
                                    <input type="checkbox" name="example6" class="mr-1" checked>
                                    Mail Subscription
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__footer text-center">
        <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><a href="javascript: void(0);">Terms of Use</a></li>
            <li class="active list-inline-item"><a href="javascript: void(0);">Compliance</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Confidential Information</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Support</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Contacts</a></li>
        </ul>
    </div>
</div>
<!-- END: pages/register -->
