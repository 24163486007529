// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ForgotPasswordService } from './../../services/forgot-password.service';
import { NotificationComponent } from './../notification/notification.component';
import { LoginService } from './../../services/login.service';
import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.css'],
})
export class RequestPasswordComponent implements OnInit {
  public emailId: string;
  public requestPasswordForm: FormGroup;
  public companyList: Array<any>;
  selectedCompanyId: any;
  isEmailForCompany: string = 'Please enter valid email id';
  constructor(
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    $('body').removeClass('dashboard-page');
    // $('.cat__menu-left').hide();
    this.requestPasswordForm = new FormGroup({
      emailId: new FormControl('', [Validators.required, Validators.email]),
      companyName: new FormControl('', [Validators.required]),
    });
    //this.getCompanies()
    var token = localStorage.getItem('token');
    if (_.isNil(token)) {
      //this.getCompanies();
      this.hideTopBar();
    } else {
      //if already login
      this.showTopBar();
      // this.router.navigate(["dashboards/alpha"]);
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
    }
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }

  // getCompanies() {
  //   this.loginService.companyListForUserManagement()
  //     .then(companies => {
  //       if (companies.status == 0) {
  //         this.selectedCompanyId = companies.companyList[0]._id;
  //         this.companyList = companies.companyList.map(function (o) {
  //           return {
  //             'label': o.CompanyName,
  //             'value': o._id
  //           }
  //         });
  //       }
  //     });
  // }
  emailIdChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  getCompaniesByEmailId() {
    if (this.requestPasswordForm.controls.emailId.status == 'VALID') {
      //get array of company Id using email id
      // this.listOfCompanyDetails = [];
      this.companyList = [];
      this.requestPasswordForm.controls.companyName.setValue('');
      let emailId = this.emailId;
      if (emailId != '') {
        this.isEmailForCompany =
          'Either due to low bandwidth or API unavailable';
        this.loginService
          .getCompanyIdsByEmailId(emailId)
          .subscribe((companyIds) => {
            if (companyIds.status == 0) {
              if (companyIds.companyIds.length > 0) {
                this.loginService
                  .getCompanyListByIds(companyIds)
                  .subscribe((companies) => {
                    // @ts-ignore
                    if (companies.status == 0) {
                      // @ts-ignore
                      this.companyList = companies.companyList.map(function (
                        o
                      ) {
                        return {
                          label: o.CompanyName,
                          value: o._id,
                        };
                      });
                    } else {
                      this.router.navigate(['companySetup']);
                    }
                  });
              } else {
                this.isEmailForCompany =
                  'Either due to low bandwidth or API unavailable';
              }
            } else {
              this.isEmailForCompany = 'Please enter valid email id';
            }
          });
      }
    } else {
      this.companyList = [];
    }
  }

  submitEmail() {
    //
    const emailId = _.toLower(this.requestPasswordForm.get('emailId').value);
    const companyId = this.requestPasswordForm.get('companyName').value;
    // const companyId = this.companyList.find(function(o) { return o.label == companyName})
    const forgotPasswordObject = {
      EmailId: emailId,
      CompanyId: companyId.value, // check for the company ID
    };

    this.forgotPasswordService
      .wrongOTPAttemptCheck(forgotPasswordObject)
      .then((result) => {
        if (!result.status) {
          if (result.SecretCodeAttempt >= 5) {
            const modalRef = this.modalService.open(NotificationComponent);
            modalRef.componentInstance.notify = {
              status: 'Failure',
              message:
                'You have entered the wrong OTP five times.It will unblock after 15 minutes. Old OTP will not work after 15 minutes, So Generate new OTP after 15 Minutes.',
            };

            this.router.navigate(['login']);
          } else {
            this.forgotPasswordService
              .submitEmail(forgotPasswordObject)
              .then((response) => {
                console.log('response =========>  : ', response);
                if (!response.status) {
                  // const email = this.emailId;
                  // navigate user to the OTP page.
                  // localStorage.setItem('emailId', emailId )
                  this.forgotPasswordService.clearUserCredential();
                  this.forgotPasswordService.setUserCredential(response);
                  localStorage.setItem('emailId', emailId);
                  localStorage.setItem('companyId', companyId);
                  this.router.navigate(['SubmitOTP']);
                  const modalRef = this.modalService.open(
                    NotificationComponent
                  );
                  modalRef.componentInstance.notify = {
                    status: 'Success',
                    message: response.message,
                  };
                } else {
                  // show error message to the user
                  const modalRef = this.modalService.open(
                    NotificationComponent
                  );
                  modalRef.componentInstance.notify = {
                    status: 'Failure',
                    message: response.message,
                  };
                  // this.modalService.open(NotificationComponent).result.then(result => {

                  // })
                }
              });
          }
        } else {
          // show error message to the user
          const modalRef = this.modalService.open(NotificationComponent);
          modalRef.componentInstance.notify = {
            status: 'Failure',
            message: result.message,
          };
        }
      });

    // this.forgotPasswordService.submitEmail(forgotPasswordObject).then(response => {
    //   if (!response.status) {
    //     // const email = this.emailId;
    //     // navigate user to the OTP page.
    //     // localStorage.setItem('emailId', emailId )
    //     this.forgotPasswordService.setUserCredential(response)
    //     this.router.navigate(["SubmitOTP"])
    //     const modalRef = this.modalService.open(NotificationComponent);
    //     modalRef.componentInstance.notify = { status: 'Success', message: 'OTP is sent to your Email address' };
    //   } else {
    //     // show error message to the user
    //     const modalRef = this.modalService.open(NotificationComponent);
    //     modalRef.componentInstance.notify = { status: 'Failure', message: response.message };
    //     // this.modalService.open(NotificationComponent).result.then(result => {

    //     // })
    //   }
    // })
  }
}
