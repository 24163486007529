import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserMasterService } from './../../../services/user-master.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { forkJoin } from 'rxjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import * as CryptoJS from 'crypto-js';

import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';

import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { PssLink } from './../../../../environments/environment';
import { PermissionLinks } from './../../../enums/permissionLinks';
import { CommaSeperatedNumber } from '../../../helpers/CommaSeperatedNumber';
import { CommonFunction } from '../../../helpers/commonFunction';

import { environment } from '../../../../environments/environment';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.css'],
  providers: [UserMasterService],
})
export class EditUserModalComponent implements OnInit, AfterViewInit {
  @Input('userId') userId: string;
  //'shouldDisable' disable fields if page hits from dashboard.
  @Input('shouldDisable') shouldDisable: boolean;
  @Input('isGuestSuperAdmin') isGuestSuperAdmin: boolean;
  @Input('isVisibleForChecker') isVisibleForChecker: boolean;

  isSuperUser: boolean = false;

  companyName: any;
  roleName: any[];
  reportTo: any;
  profilePictureByRemoveButton: boolean = false;
  isAdminUser: Boolean = false;

  public user: any;
  public userForm: any;
  public roleList: Array<any>;
  public editUserMasterForm: FormGroup;
  //public companyList: any;
  // public selectedCompany: any;
  public committeeList = [];
  committees = [];
  reportToUserList = [];
  directorTypesList = [];
  titleList = [];
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  // pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\/ ]*[a-zA-Z]+[a-zA-Z0-9\-\_\/ ]*$";//Pattern for validating blank input and space at first place
  pattern = '^[a-zA-Z0-9][a-zA-Z0-9-_/. ]*[a-zA-Z.]+[a-zA-Z0-9]*$'; //Pattern for validating blank input and space at first place
  public selectedCommmittee = [];
  minDate: any;
  dateOfBirthModel: any;
  DOBDate: any;
  finCode: any;

  //image cropper
  isInvalidImage: any;
  isValidUserType: boolean = true;
  croppedWidth: number;
  croppedHeight: number;
  cropperSettings: CropperSettings;
  base64Code: any;
  data: any;
  handleDINError: boolean;
  esclationUserList = [];

  createdUserId: any;
  categoryList: any;
  boardDesignationlist: any;
  executiveDesignationlist: any;
  nonExecutiveDesignationlist: any;
  kmpDesignationList: any;
  handleCategoryError: any;
  isICCheckerOrICMaker: boolean;
  inputRoleName: any;
  subsideryList: any;
  chosenSubsideries: any;
  selectedSubsideries: any;
  isAuthorise: boolean;
  displayMultiple: boolean;
  disabledRoleControl: boolean;
  isPANExists: boolean = false;
  isDINExists: boolean = false;
  isProceed: boolean = false;
  NotificationType: any = [];
  isADIdExists: boolean = false;
  userIDForedit: any;
  oldEmailId: string;
  loggedInUserRoles: any;

  isAuth: boolean = false;

  isPSSConfig = environment.Service.PSS;
  isGovevaConfig = environment.Service.GovEva;
  isUPSIConfig = environment.Service.UPSI;
  isAllConfig = environment.Service.All;
  isNCDConfig = environment.Service.NCD;
  secretKey = 'Secret message';
  // isBoardEvaluationConfig = environment.Service.BoardEvaluation;

  @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
  isADEnabled: any;
  departmentList: any = [];
  branchList: any = [];
  branchData: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private userMasterService: UserMasterService,
    private fb: FormBuilder,
    private commonFunction: CommonFunction,
    private companyMasterService: CompanyMasterService
  ) {
    this.editUserMasterForm = this.fb.group({
      UserId: new FormControl('', [Validators.required]),
      TypeOfUser: new FormControl('', [Validators.required]),
      FirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern), //"[a-zA-Z ]*"
      ]),
      LastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      EmailId: new FormControl('', [
        Validators.required,
        Validators.email,
        // EmailValidator.isVa
      ]),
      SecondaryEmailId: new FormControl('', [
        // Validators.required,
        // Validators.email
      ]),
      MobileNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('[0-9]{10}'),
      ]),
      MobileNumber2: new FormControl('', [
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('[0-9]{10}'),
      ]),
      RoleName: new FormControl('', [Validators.required]),
      Experience: new FormControl('', [
        // Validators.required
      ]),

      DOB: new FormControl(null, [
        // Validators.required
      ]),
      Remuneration: new FormControl('', [
        // Validators.required
      ]),
      ReportToUser: new FormControl('', []),
      TitleName: new FormControl('', [
        //Validators.required
      ]),
      Designation: new FormControl('', [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500),
        Validators.pattern(this.pattern),
      ]),
      AreaofExpertise: new FormControl('', [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500),
        Validators.pattern(this.pattern),
      ]),
      DirectorType: new FormControl('', []),
      PAN: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$'),
      ]),
      DIN: new FormControl('', [
        //Validators.required,
        Validators.pattern('^([0-9]){8}?$'),
      ]),
      Category: new FormControl('', [
        // Validators.required
      ]),
      Gender: new FormControl('', [
        // Validators.required
      ]),
      DesignationSelection: new FormControl('', [
        // Validators.required
      ]),
      UniqueUserId: new FormControl('', []),
      SubsideryName: new FormControl('', []),
      Remarks: new FormControl('', [Validators.maxLength(35)]),
      BranchName: new FormControl(''),
      DepartmentName: new FormControl(''),
      departmentName: new FormControl('', []),
      branchName: new FormControl('', []),
      tempBranchName: new FormControl(null, []),
      tempDepartmentName: new FormControl(null, []),
      SMSNotification: new FormControl(false, []),
      EmailNotification: new FormControl(false, []),
      BellNotification: new FormControl(false, []),
      IPADNotification: new FormControl(false, []),
      BrowserNotification: new FormControl(false, []),
      ADID: new FormControl('', [Validators.pattern('^([a-zA-Z0-9 _.-]+)$')]),
      WebexId: new FormControl('', [Validators.email]),
      WebexPassword: new FormControl('', []),
      Password: new FormControl('', []),
      // PushNotification : new FormControl('',[]),
    });

    //image cropper

    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 150;
    this.cropperSettings.height = 150;

    this.cropperSettings.croppedWidth = 150;
    this.cropperSettings.croppedHeight = 150;

    this.cropperSettings.canvasWidth = 465;
    this.cropperSettings.canvasHeight = 300;

    this.cropperSettings.minWidth = 10;
    this.cropperSettings.minHeight = 10;

    this.cropperSettings.rounded = false;
    this.cropperSettings.keepAspect = false;

    this.cropperSettings.cropperDrawSettings.strokeColor =
      'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings.noFileInput = true;

    this.data = {};
    this.handleDINError = false;
    this.handleCategoryError = false;
    this.getDepartment();
    this.getBranch();
  }

  dobDateValidation() {
    //dob date validation
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear() - 18;
    this.DOBDate = { year: year, month: month, day: day };
  }

  ngOnInit() {
    //
    var self = this;
    var ADEnabled = false;

    let companyId = JSON.parse(
      localStorage.getItem('companyDetailsGoveva')
    ).value;
    this.companyMasterService
      .getSingleCompnyDetails(companyId)
      .then((responseForAD) => {
        // @ts-ignore
        if (!responseForAD.status) {
          // @ts-ignore
          ADEnabled = responseForAD.companyData.IsADSubscribedFlag;
        }

        this.isICCheckerOrICMaker = false;
        this.isInvalidImage = true;
        this.fillCommitteeList();
        this.fillSalutationTitleList();
        this.fillDirectorTypesList();
        this.minDate = { year: 1910, month: 1, day: 1 };
        this.dobDateValidation();
        //console.log("this.isGuestSuperAdmin", this.isGuestSuperAdmin);
        this.user = {
          FirstName: '',
          LastName: '',
          EmailId: '',
          DateOfBirth: 'null',
          MobileNumber: '',
          Roles: [],
          TypeOfUser: '',
          CompanyName: '',
          CompanyId: '',
          Experience: '',
          Remuneration: '',
          ReportsTo: '',
          Committees: [],
          NotificationType: [],
          // "Remarks" : '',
          // "WebexId" : '',
          // "WebexPassword" : '',
          // "Password" : '',
          // "Gender" : '',
          // "Category" : '',
          // "TitleName" : '',
          // "ADID" : '',
          // "SecondaryEmailId" : '',
          // "MobileNumber2" : '',
          // "PAN" : '',
        };

        this.fillCategoryList();
        this.getSubsideries();

        this.kmpDesignationList = [
          {
            label: 'Chief Executive Officer',
            value: 'Chief Executive Officer',
          },
          {
            label: 'Chief Financial Officer',
            value: 'Chief Financial Officer',
          },
          { label: 'Company Secretary', value: 'Company Secretary' },
          { label: 'Manager', value: 'Manager' },
          { label: 'Managing Director', value: 'Managing Director' },
          { label: 'Others', value: 'Others' },
          { label: 'Whole Time Director', value: 'Whole Time Director' },
        ];
        this.executiveDesignationlist = [
          { label: 'Managing Director', value: 'Managing Director' },
          {
            label: 'Managing Director (Additional Director)',
            value: 'Managing Director (Additional Director)',
          },
          { label: 'Whole Time Director', value: 'Whole Time Director' },
          {
            label: 'Whole Time Director (Additional Director)',
            value: 'Whole Time Director (Additional Director)',
          },
        ];
        this.nonExecutiveDesignationlist = [
          { label: 'Additional Director', value: 'Additional Director' },
          { label: 'Alternate Director', value: 'Alternate Director' },
          { label: 'Director', value: 'Director' },
          {
            label: 'Director Appointed in Casual Vacancy',
            value: 'Director Appointed in Casual Vacancy',
          },
          { label: 'Nominee Director', value: 'Nominee Director' },
        ];

        forkJoin([
          this.userMasterService.getCompanyList(),
          this.userMasterService.getRoleListOfUser(),
          this.userMasterService.getAdminRoles(),
        ]).subscribe((responses) => {

          $('body').css('overflow', 'hidden');
          //alert("Opened");

          if (responses[1].status == 4) {
            self.activeModal.close(responses[1]);
          }
          this.roleList = responses[1].RolesList.map(function (o) {
            return {
              _id: o._id,
              label: o.RoleName,
              value: o._id,
              isActive: o.IsActive,
              RoleId: o._id,
              RoleName: o.RoleName,
            };
          });

          //sort ascending for Role Name
          this.roleList = this.roleList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });

          //console.log("this.roleList", this.roleList);
          //display user details on selected user
          this.userMasterService
            .getUserByUserId(this.userId)
            .then((response) => {
              if (response.status == 4) {
                self.activeModal.close(response);
              }
              if (!response.status) {
                if (response.userDetails != null) {
                  this.userIDForedit = response.userDetails._id;

                  this.editUserMasterForm.controls.UserId.setValue(
                    response.userDetails._id
                  );
                  this.editUserMasterForm.controls.TypeOfUser.setValue(
                    response.userDetails.TypeOfUser
                  );

                  this.editUserMasterForm.controls.FirstName.setValue(
                    response.userDetails.FirstName
                  );
                  this.editUserMasterForm.controls.LastName.setValue(
                    response.userDetails.LastName
                  );
                  this.editUserMasterForm.controls.EmailId.setValue(
                    response.userDetails.EmailId
                  );
                  this.oldEmailId = response.userDetails.EmailId;
                  this.editUserMasterForm.controls.SecondaryEmailId.setValue(
                    response.userDetails.SecondaryEmailId
                  );
                  this.editUserMasterForm.controls.MobileNumber.setValue(
                    response.userDetails.MobileNumber
                  );
                  this.editUserMasterForm.controls.MobileNumber2.setValue(
                    response.userDetails.MobileNumber2
                  );
                  this.companyName = response.userDetails.CompanyId;
                  this.finCode = response.userDetails.Fincode;
                  this.editUserMasterForm.controls.Experience.setValue(
                    response.userDetails.Experience
                  );
                  this.editUserMasterForm.controls.Remuneration.setValue(
                    this.commonFunction.formatToCommaSeperatedNumber(
                      response.userDetails.Remuneration
                    )
                  );
                  this.editUserMasterForm.controls.PAN.setValue(
                    response.userDetails.PAN
                  );
                  // var PANNo = response.userDetails.PAN ? CryptoJS.AES.decrypt(response.userDetails.PAN, this.secretKey.trim()).toString(CryptoJS.enc.Utf8) : "";
                  // this.editUserMasterForm.controls.PAN.setValue(PANNo);
                  this.editUserMasterForm.controls.DIN.setValue(
                    response.userDetails.DIN
                  );
                  this.editUserMasterForm.controls.Gender.setValue(
                    response.userDetails.Gender
                  );
                  this.editUserMasterForm.controls.UniqueUserId.setValue(
                    response.userDetails.UniqueUserId
                  );
                  this.editUserMasterForm.controls.Password.setValue(
                    response.userDetails.Password
                  );
                  this.editUserMasterForm.controls.Category.setValue(
                    response.userDetails.Category
                  );
                  this.editUserMasterForm.controls.WebexId.setValue(
                    response.userDetails.WebexId
                  );
                  this.editUserMasterForm.controls.WebexId.setErrors(null);
                  this.editUserMasterForm.controls.WebexPassword.setValue(
                    response.userDetails.WebexPassword
                  );

                  this.editUserMasterForm.controls.BranchName.setValue(
                    response.userDetails.BranchName
                  );
                  this.editUserMasterForm.controls.DepartmentName.setValue(
                    response.userDetails.DepartmentName
                  );
                  this.editUserMasterForm.controls.branchName.setValue(
                    response.userDetails.branchName
                  );
                  this.editUserMasterForm.controls.departmentName.setValue(
                    response.userDetails.departmentName
                  );

                  if (response.userDetails.NotificationType.includes('Email')) {
                    this.editUserMasterForm.controls.EmailNotification.setValue(
                      true
                    );
                  }

                  if (response.userDetails.NotificationType.includes('Bell')) {
                    this.editUserMasterForm.controls.BellNotification.setValue(
                      true
                    );
                  }

                  if (response.userDetails.NotificationType.includes('SMS')) {
                    this.editUserMasterForm.controls.SMSNotification.setValue(
                      true
                    );
                  }

                  if (response.userDetails.NotificationType.includes('Ipad')) {
                    this.editUserMasterForm.controls.IPADNotification.setValue(
                      true
                    );
                  }

                  if (
                    response.userDetails.NotificationType.includes('Browser')
                  ) {
                    this.editUserMasterForm.controls.BrowserNotification.setValue(
                      true
                    );
                  }

                  this.NotificationType = response.userDetails.NotificationType;

                  let dt;
                  if (response.userDetails.DateOfBirth != null) {
                    dt = new Date(
                      this.setFormatDate(response.userDetails.DateOfBirth)
                    );
                    this.dateOfBirthModel = {
                      month: dt.getMonth() + 1,
                      day: dt.getDate(),
                      year: dt.getFullYear(),
                    };
                  }

                  //Commitees commented as per client requirement
                  // if (response.userDetails.Committees) {
                  //   response.userDetails.Committees.forEach((committee) => {
                  //     this.selectedCommmittee.push({ CommitteeName: committee });
                  //   });
                  // }

                  //used to set checked committee chekbox
                  //this.committees = response.userDetails.Committees;

                  if (
                    response.userDetails.TypeOfUser == 'BoardMember' ||
                    response.userDetails.TypeOfUser == 'KMP'
                  )
                    this.setValidatiorForDesignationSelection();
                  else this.setValidatiorForDesignationInput();

                  this.editUserMasterForm.controls.AreaofExpertise.setValue(
                    response.userDetails.AreaOfExpertise
                  );
                  this.editUserMasterForm.controls.TitleName.setValue(
                    response.userDetails.Title
                  );
                  //
                  this.onChangeUserType(response.userDetails.ReportsTo); //display director type if TypeOfUser is BoardMember & filtered 'ReportToUser'
                  if (response.userDetails.TypeOfUser == 'BoardMember') {
                    this.fillDirectorTypesList();
                    let directorType = response.userDetails.DirectorType == 'ExcecutiveDirector'? "ExecutiveDirector": "Non-ExecutiveDirector"
                    this.editUserMasterForm.controls.DirectorType.setValue(
                      directorType
                    );

                    if (
                      response.userDetails.DirectorType == 'ExcecutiveDirector'
                    ) {
                      this.categoryList = $.grep(
                        this.categoryList,
                        function (category) {
                          return category.label != 'Independent';
                        }
                      );
                    }

                    this.editUserMasterForm.controls.Category.setValue(
                      response.userDetails.Category
                    );
                    if (
                      response.userDetails.DirectorType == 'ExcecutiveDirector'
                    )
                      this.boardDesignationlist = this.executiveDesignationlist;
                    else
                      this.boardDesignationlist =
                        this.nonExecutiveDesignationlist;
                  }

                  //this.reportTo = "5a4ddcf8aeafbf2d1450919a";//response.userDetails.ReportsTo;
                  if (
                    response.userDetails.TypeOfUser == 'BoardMember' ||
                    response.userDetails.TypeOfUser == 'KMP'
                  ) {
                    this.editUserMasterForm.controls.DesignationSelection.setValue(
                      response.userDetails.Designation
                    );
                  } else {
                    this.editUserMasterForm.controls.Designation.setValue(
                      response.userDetails.Designation
                    );
                  }

                  this.editUserMasterForm.controls.Remarks.setValue(
                    response.userDetails.Remarks
                  );

                  //reading profile image from database and showing.
                  if (
                    response.userDetails.ProfilePicture != null &&
                    response.userDetails.ProfilePicture != ''
                  ) {
                    $('#profilePhoto').attr(
                      'src',
                      response.userDetails.ProfilePicture
                    );
                    this.base64Code = response.userDetails.ProfilePicture;
                  }

                  if (
                    response.userDetails.TypeOfUser ==
                    PermissionLinks.GuestSuperAdmin
                  ) {
                    this.isGuestSuperAdmin = true;
                  } else {
                    this.isGuestSuperAdmin = false;
                  }

                  this.loggedInUserRoles = JSON.parse(
                    localStorage.getItem('user')
                  ).Roles;
                  _.forEach(this.loggedInUserRoles, (element) => {
                    if (element.RoleName == PermissionLinks.Admin) {
                      this.isAdminUser = true;
                    }
                  });

                  if (this.isGuestSuperAdmin || this.shouldDisable) {
                    if (_.size(response.userDetails.Roles)) {
                      self.roleName = response.userDetails.Roles.map(function (
                        o
                      ) {
                        return o.RoleName;
                      });

                      self.editUserMasterForm.controls.RoleName.disable();
                      self.editUserMasterForm.controls.RoleName.updateValueAndValidity();
                    }
                  } else {
                    //console.log("this.roleList", this.roleList);
                    if (_.size(response.userDetails.Roles)) {
                      response.userDetails.Roles.forEach((element) => {
                        if (
                          element.RoleName == PermissionLinks.ICChecker ||
                          element.RoleName == PermissionLinks.ICMaker ||
                          element.RoleName == PermissionLinks.Admin
                        ) {
                          self.isICCheckerOrICMaker = true;
                          self.isGuestSuperAdmin = false;

                          self.isSuperUser = true;

                          if (!responses[2].status) {
                            //console.log("RoleList", responses[2].RoleList);
                            if (_.size(responses[2].RoleList)) {
                              responses[2].RoleList.forEach(function (o) {
                                //console.log("o", o);
                                if (element.RoleName == o.RoleName) {
                                  self.roleList = self.roleList.concat({
                                    _id: o._id,
                                    label: o.RoleName,
                                    value: o._id,
                                    isActive: o.IsActive,
                                    RoleId: o._id,
                                    RoleName: o.RoleName,
                                  });
                                } else if (element.RoleName == o.RoleName) {
                                  self.roleList = self.roleList.concat({
                                    _id: o._id,
                                    label: o.RoleName,
                                    value: o._id,
                                    isActive: o.IsActive,
                                    RoleId: o._id,
                                    RoleName: o.RoleName,
                                  });
                                } else if (element.RoleName == o.RoleName) {
                                  self.roleList = self.roleList.concat({
                                    _id: o._id,
                                    label: o.RoleName,
                                    value: o._id,
                                    isActive: o.IsActive,
                                    RoleId: o._id,
                                    RoleName: o.RoleName,
                                  });
                                }
                              });
                            }
                          }

                          this.roleName = response.userDetails.Roles.map(
                            function (o) {
                              return o.RoleId;
                            }
                          );

                          if (this.shouldDisable) {
                            //console.log("this.shouldDisable-----", this.shouldDisable);
                            this.editUserMasterForm.controls.RoleName.disable();
                            this.editUserMasterForm.controls.RoleName.updateValueAndValidity();
                          }
                        } else {
                          this.isICCheckerOrICMaker = false;

                          this.roleName = response.userDetails.Roles.map(
                            function (o) {
                              return o.RoleId;
                            }
                          );

                          this.isSuperUser = false;
                        }
                      });

                      this.roleList = _.uniqBy(this.roleList, '_id');
                    }
                  }

                  response.userDetails.Roles.map((o) => {
                    this.user.Roles.push({
                      RoleId: o.RoleId,
                      RoleName: o.RoleName,
                    });
                  });

                  this.chosenSubsideries = [];
                  if (_.size(response.userDetails.SubsidiaryCompanies) > 0) {
                    this.selectedSubsideries =
                      response.userDetails.SubsidiaryCompanies.map(function (
                        o
                      ) {
                        return {
                          label: o.Name,
                          value: o._id,
                        };
                      });
                  } else {
                    this.selectedSubsideries = [];
                  }

                  // this.chosenSubsideries = response.userDetails.SubsidiaryCompanies.map(function (o) {
                  this.chosenSubsideries = this.selectedSubsideries.map(
                    function (o) {
                      return o.value;
                    }
                  );

                  this.isAuthorise = response.userDetails.IsAuthorize;
                  //this.isAuth=this.isAuthorise;
                  //alert(this.isAuthorise);

                  //display multiple
                  if (
                    this.isGuestSuperAdmin ||
                    this.isSuperUser ||
                    !this.isAuthorise
                  ) {
                    // this.displayMultiple = false;

                    this.displayMultiple = true;
                  } else {
                    this.displayMultiple = true;
                  }

                  if (!this.isAuthorise && this.isVisibleForChecker) {
                    // this.disabledRoleControl = true;

                    this.disabledRoleControl = false;
                  } else this.disabledRoleControl = false;

                  //disabledRoleControl

                  if (this.shouldDisable == true) {
                    // this.disabledRoleControl = true;
                    this.disabledRoleControl = false;
                  }

                  //set validation for category and din
                  if (response.userDetails.TypeOfUser == 'BoardMember') {
                    this.setValidationForCategoryAndDIN();
                  } else {
                    this.removedValidationForCategoryAndDIN();
                  }

                  this.isADEnabled = ADEnabled;
                  this.editUserMasterForm.updateValueAndValidity();

                  if (ADEnabled) {
                    this.editUserMasterForm.controls.ADID.setValidators([
                      Validators.required,
                    ]);
                    this.editUserMasterForm.controls.ADID.setValue(
                      response.userDetails.ADID
                    );
                    this.editUserMasterForm.controls.ADID.updateValueAndValidity();
                  }
                }
              }
            });
        });
      });
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  getDepartment() {
    this.userMasterService.getDepartment().then((response: any) => {
      if (response.data != undefined) {
        this.departmentList = response.data.map((o) => {
          return {
            label: o.departmentName,
            // "value": o._id,
            value: o.departmentId,
          };
        });
      }
    });
  }

  getBranch() {
    this.userMasterService.getBranch().then((response: any) => {
      if (response.data != undefined) {
        this.branchList = response.data.map((o) => {
          return {
            label: o.branchName,
            // "value": o._id,
            value: o.branchId,
          };
        });
        // this.branchList= this.branchData
      }
    });
  }

  onSelectDepartmebt(e) {
    this.editUserMasterForm.patchValue({
      DepartmentName: e.value,
      tempDepartmentName: e.label,
    });
  }
  onSelectBranch(e) {
    this.editUserMasterForm.patchValue({
      BranchName: e.value,
      tempBranchName: e.label,
    });
  }

  setValidationForCategoryAndDIN() {
    //
    this.editUserMasterForm.controls.DIN.setValidators([
      Validators.required,
      Validators.pattern('^([0-9]){8}?$'),
    ]);
    // this.editUserMasterForm.controls.Category.setValidators([Validators.required]);
    this.editUserMasterForm.controls.Category.setValidators([]);
  }

  activateUpdate() {
    try {
      document.getElementById('updateUser').removeAttribute('disabled');
    }
    catch {
      //pass
    }
  }

  removedValidationForCategoryAndDIN() {
    //
    this.editUserMasterForm.controls.DIN.clearValidators();
    this.editUserMasterForm.controls.DIN.setValidators([
      Validators.pattern('^([0-9]){8}?$'),
    ]);
    this.editUserMasterForm.controls.DIN.updateValueAndValidity();
    this.editUserMasterForm.controls.Category.clearValidators();
    this.editUserMasterForm.controls.Category.updateValueAndValidity();
  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
      //.toLocaleDateString();
    }
    return null;
  }

  fillCategoryList() {
    this.categoryList = [
      {
        value: 'Independent',
        label: 'Independent',
      },
      {
        value: 'Promoter',
        label: 'Promoter',
      },
      {
        value: 'Professional',
        label: 'Professional',
      },
    ];
  }

  fillReportToUser(selectedUserType, selectedReportToUser) {
    //
    // var loginUsers = JSON.parse(localStorage.getItem("user"));
    // let loginUserId = loginUsers._id;

    this.userMasterService.getUserList().then((response) => {
      //
      //filter users according to user type
      // response.result = response.result.filter((o) => { return o.TypeOfUser == selectedUserType })
      //only active user will be filtered
      response.result = response.result.filter((o) => {
        return o.IsActive != false;
      });
      //check login user and remove it from report to list
      response.result = response.result.filter((o) => {
        return o._id != this.userId;
      });
      //check RoleName for SuperAdmin, IC maker, IC Checker and remove it from report to list
      response.result = response.result.filter(function (userElement) {
        return (
          _.get(_.first(userElement.Roles), 'RoleName') != 'GuestSuperAdmin' &&
          _.get(_.first(userElement.Roles), 'RoleName') != 'IC Maker' &&
          _.get(_.first(userElement.Roles), 'RoleName') != 'IC Checker'
        );
      });

      this.reportToUserList = response.result.map((o) => {
        return {
          label: o.FirstName + ' ' + o.LastName,
          value: o._id,
        };
      });

      this.reportToUserList = this.reportToUserList.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });

      if (selectedReportToUser != '' && selectedReportToUser != null)
        this.reportTo = selectedReportToUser;
    });
  }

  fillSalutationTitleList() {
    this.userMasterService.getSalutationTitle().then((response) => {
      this.titleList = response.salutationTitle.map((o) => {
        return {
          label: o.label,
          value: o.value,
        };
      });

      // this.titleList = [{
      //   "value": "Mr.", "label": "Mr."
      // },
      // { "value": "Mrs.", "label": "Mrs." },
      // { "value": "Shri.", "label": "Shri." },
      // { "value": "Smt.", "label": "Smt." },
      // { "value": "Dr.", "label": "Dr." },
      // { "value": "Adv.", "label": "Adv." },
      // { "value": "Ms.", "label": "Ms." }
      // ]
    });
  }

  getMultipleEsclationUser(selectedUserType) {
    //selectedUserType, selectedReportToUser
    this.userMasterService.getUserList().then((response) => {
      //filter users according to user type
      response.result = response.result.filter((o) => {
        return o.TypeOfUser == selectedUserType;
      });
      //only active user will be filtered
      response.result = response.result.filter((o) => {
        return o.IsActive != false;
      });
      //check login user and remove it from report to list
      response.result = response.result.filter((o) => {
        return o._id != this.userId;
      });
      this.esclationUserList = response.result.map((o) => {
        return {
          Name: o.FirstName + ' ' + o.LastName,
          _id: o._id,
          EmailId: o.EmailId,
        };
      });
    });
  }

  fillCommitteeList() {
    this.userMasterService.getCommitteeList().then((response) => {
      if (_.size(response.committeeList)) {
        this.committeeList = response.committeeList.map((o) => {
          return {
            _id: o._id,
            CommitteeName: o.CommitteeAlias,
          };
        });
        //sort ascending for committee alias
        this.committeeList = this.committeeList.sort(function (a, b) {
          return a.CommitteeName.localeCompare(b.CommitteeName);
        });
      }
    });

    // this.committeeList = [{ "_id": 1, "CommitteeName": "Audit committee" },
    // { "_id": 2, "CommitteeName": "Borrowing committee" },
    // { "_id": 3, "CommitteeName": "CSR committee" },
    // { "_id": 4, "CommitteeName": "Debentures committee" },
    // { "_id": 5, "CommitteeName": "Granting of Loans, guarantee and security committee" },
    // { "_id": 6, "CommitteeName": "Investment committee" },
    // { "_id": 7, "CommitteeName": "Nomination and Remuneration Committee" },
    // { "_id": 8, "CommitteeName": "Risk management committee" },
    // { "_id": 9, "CommitteeName": "Share allotment committee" },
    // { "_id": 10, "CommitteeName": "Stakeholder relationship committee" },
    // { "_id": 11, "CommitteeName": "Vigilence committee" },
    // { "_id": 12, "CommitteeName": "Vigil mechanism Committee" }
    // ]
  }

  fillDirectorTypesList() {
    this.directorTypesList = [
      // {
      //   "value": "IndependentDirector", "label": "Independent Director"
      // },
      { value: 'ExcecutiveDirector', label: 'Excecutive Director' },
      { value: 'NonExcecutiveDirector', label: 'Non-Excecutive Director' },
    ];
  }

  onChangeUserType(selectedReportToUser) {
    this.directorTypesList = [];
    let selectedUserType = this.editUserMasterForm.get('TypeOfUser').value;
    if (this.editUserMasterForm.get('TypeOfUser').value == 'BoardMember') {
      //if (selectedUserType == "BoardMember") {
      this.fillDirectorTypesList();
      $('#directorTypeDiv').show();
    } else {
      $('#directorTypeDiv').hide();
    }

    if (
      this.editUserMasterForm.get('TypeOfUser').value == 'BoardMember' ||
      this.editUserMasterForm.get('TypeOfUser').value == 'KMP'
    ) {
      this.editUserMasterForm.controls.DesignationSelection.reset();
      this.setValidatiorForDesignationSelection();
    } else {
      this.setValidatiorForDesignationInput();
    }

    this.fillReportToUser(selectedUserType, selectedReportToUser);

    //commented
    //this.getMultipleEsclationUser(selectedUserType);
  }

  //director type is required if user type 'BoardMember' is selected
  checkValidUserTypeForDirectorType() {
    //
    // if (this.editUserMasterForm.get('TypeOfUser').value == "BoardMember") {
    //   if (this.editUserMasterForm.get('DirectorType').value == "" || this.editUserMasterForm.get('DirectorType').value == undefined) {
    //     this.isValidUserType = false;
    //     return false;
    //   }
    //   else
    //     this.isValidUserType = true;
    // }
    return true;
  }

  // Commitees commented as per client requirement
  //bind array  with selected committee
  // changeCommittee(e, type) {
  //   //

  //   if (e.target.checked) {

  //     this.selectedCommmittee.push({ CommitteeName: type.CommitteeName });
  //     //this.selectedCommmittee.push( type.CommitteeName );
  //   }
  //   else {

  //     let updateItem = this.selectedCommmittee.find(this.findIndexToUpdate, type.CommitteeName);
  //     let index = this.selectedCommmittee.indexOf(updateItem);
  //     this.selectedCommmittee.splice(index, 1);
  //   }
  // }

  findIndexToUpdate(type) {
    return type.CommitteeName === this;
  }

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      (keyCode > 34 && keyCode < 41) ||
      keyCode == 16 ||
      keyCode == 46 ||
      keyCode == 9
    ) {
      if (
        ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) &&
        this.numList.indexOf(ev.key) == -1
      ) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      // if ((ev.target.value + ev.key).length > 11) {
      //   ev.preventDefault();
      // }
      // //
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }
  }

  //restrict user to type zero as first digit
  restrictFirstDigitForZero(e) {
    let firstChar = e.target.value.substring(0, 1);

    if (firstChar == 0) e.target.value = e.target.value.slice(1);
  }

  ngAfterViewInit() {}

  //Role Dropdown is single select -- client requirement
  onSelectRole(data: any) {
    if (this.isGuestSuperAdmin || this.isSuperUser) {
      this.user.Roles = [];
    }

    this.user.Roles = data.map(item=>_.pick(item,['RoleId', 'RoleName']));
  }

  //Role Dropdown is single select -- client requirement
  multipleDeselected(data: any) {
    //console.log('checking for the deselected data', data)
    const self = this;
    const indexOfDeselectedValue = _.findIndex(self.user.Roles, function (o) {
      return _.get(o, 'RoleId') === data.value;
    });
    this.user.Roles.splice(indexOfDeselectedValue, 1);
  }

  setDate(date) {
    if (date != '' && date != null) {
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }

  edit() {
    const self = this;
    this.saveUserInformation(function (response) {
      $('body').css('overflow', 'auto');
      //alert('Save');
      //console.log('response for edit user', response)
      if (!response.status) {
        self.activeModal.close(response);
      } else {
        self.activeModal.close(response);
      }
    });
    // //
    // if (this.checkValidUserTypeForDirectorType()) {

    //   if ((self.editUserMasterForm.get("TypeOfUser").value == "BoardMember") && _.isEmpty(self.editUserMasterForm.get('DIN').value)) {
    //     this.handleDINError = true;
    //     return;
    //   }
    //   this.handleDINError = false;
    //   this.user.UserId = this.userId,
    //     this.user.FirstName = this.editUserMasterForm.get('FirstName').value
    //   this.user.LastName = this.editUserMasterForm.get('LastName').value
    //   this.user.MobileNumber = this.editUserMasterForm.get('MobileNumber').value
    //   this.user.EmailId = this.editUserMasterForm.get('EmailId').value
    //   this.user.TypeOfUser = this.editUserMasterForm.get('TypeOfUser').value
    //   var loginUsers = JSON.parse(localStorage.getItem("user"));
    //   this.user.CompanyName = loginUsers.CompanyName;  //this.selectedCompany.label;
    //   this.user.CompanyId = loginUsers.CompanyId;      //this.selectedCompany.value;
    //   this.user.Fincode = this.finCode;
    //   this.user.Experience = this.editUserMasterForm.get('Experience').value,
    //     this.user.Remuneration = this.editUserMasterForm.get('Remuneration').value,
    //     this.user.ReportsTo = this.editUserMasterForm.get('ReportToUser').value,
    //     this.user.DateOfBirth = this.setDate(this.editUserMasterForm.controls.DOB.value)

    //   //added new fields 14 feb
    //   this.user.Title = this.editUserMasterForm.get('TitleName').value;
    //   this.user.Designation = this.editUserMasterForm.get('Designation').value;
    //   this.user.AreaOfExpertise = this.editUserMasterForm.get('AreaofExpertise').value;
    //   this.user.DirectorType = this.editUserMasterForm.get('DirectorType').value;
    //   this.user.PAN = this.editUserMasterForm.get('PAN').value.toUpperCase();
    //   this.user.DIN = this.editUserMasterForm.get('DIN').value;

    //   var committees = [];
    //   _.forEach(self.selectedCommmittee, function (data) {
    //     committees.push(data.CommitteeName)
    //   })

    //   this.user.Committees = committees;

    //   //if user not change picture
    //   //
    //   if (this.base64Code != null) {
    //     this.user.ProfilePicture = this.base64Code;
    //   }

    //   this.userMasterService.editUser(this.user).then(response => {
    //     if (!response.status) {
    //       // this.createdUserId =
    //       this.activeModal.close(response);
    //     }
    //     else {
    //       this.activeModal.close(response);
    //     }
    //   })
    // }
    // else {
    //   //console.log("invalid form submit");
    // }
  }

  // onSelectedCompanyName(data) {
  //   //console.log(data)
  //   this.selectedCompany = data;
  // }

  // onDeselectedCompanyName(data) {
  //   //console.log(data)
  // }

  onSelectedReportToUser(data) { }

  onDeselectedReportToUser(data) { }

  cancel() {
    this.activeModal.dismiss('dismissed');
    $('body').css('overflow', 'auto');
    //alert('Main Model Closed');
  }

  cancelProfilePhotoModel() {
    $('#uploadProfilePopUp').modal('hide');
    $('.modal').css('overflow', 'auto');
    $('body').css('overflow', 'hidden');
    //alert('Cancel');
  }

  //open profile upload pop up
  showUploadImagePopUp() {
    // //
    // if (!(!this.isAuthorise && this.isVisibleForChecker)) {
    this.resetImage();
    $('#uploadProfilePopUp').modal('toggle');
    //}
  }

  //reset profile image & cropper in profile dialog
  resetImage() {
    this.cropper.reset(); //reset cropper
    $('#custom-input').val(''); //reset file name title
    this.isInvalidImage = true;
  }

  readImageData: any;
  filesSelect(data) {
    // //
    this.readImageData = data;
  }

  //set profile photo
  setProfilePhoto() {
    // //

    if (Object.keys(this.data).length != 0) {
      let imageString = this.data.image;
      this.base64Code = imageString;
      $('#profilePhoto').attr('src', imageString);
      $('#uploadProfilePopUp').modal('toggle');
      // this.uploadProfilePopUpTimeoutToogle();

      $('.modal').css('overflow', 'auto');
      $('body').css('overflow', 'hidden');
      //alert('Set Profile Data');
    }

    // if (this.readImageData != null) {
    //   if (this.readImageData.target.files && this.readImageData.target.files[0]) {
    //     var reader = new FileReader();  // // Create instance of the FileReader

    //     reader.onload = (e) => {
    //       // //
    //       $('#profilePhoto').attr('src', _.get(e.target, 'result'));  //set uploaded image data as background of div
    //       this.base64Code = _.get(e.target, 'result'); // string to be saved to database
    //     }

    //     reader.readAsDataURL(this.readImageData.target.files[0]);   //// read the image uploaded

    //     //close the modal
    //     $('#uploadProfilePopUp').modal('toggle');
    //     this.uploadProfilePopUpTimeoutToogle();
    //   }
    // }
  }

  uploadProfilePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#uploadProfilePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#uploadProfilePopUp').modal('toggle');
        //alert('Hiii');
      }
    }, 3000);
  }

  removeProfilePicture() {
    //console.log("userId", this.userId);
    let userObject = { id: this.userId, type: 'profile' };
    this.companyMasterService.removeLogo(userObject).then((response) => {
      //console.log("response after deleting", response)
      this.profilePictureByRemoveButton = true;
      this.base64Code = response.profilePicture;
      $('#profilePhoto').attr(
        'src',
        'assets/modules/dummy-assets/common/img/avatars/UserProfile.png'
      );
    });
    document.getElementById('updateUser').removeAttribute('disabled');
  }

  //set the image for cropping
  fileChangeListener($event) {
    // //
    this.cropper.reset();
    this.isInvalidImage = true;

    var image: any = new Image();
    debugger
    var file: File = $event.target.files[0];
    //check valid file type
    var fileType = file['type'];
    var ValidImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if ($.inArray(fileType, ValidImageTypes) > 0) {
      // valid file type code goes here.
      this.isInvalidImage = false;
    } else this.isInvalidImage = true; //if uploaded file is invalid

    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      var img: any = document.getElementById('selected_image');
      img.onerror = () => {
        that.isInvalidImage = true;
      }
      img.onload = () => {
        that.isInvalidImage = false;
      }
      img.src = loadEvent.target.result
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
    };
    myReader.readAsDataURL(file);
  }

  redirectToPSS() {
    //first save data into database and then redirect....
    //only for the KMP and Director
    // this.activeModal.close()
    // this.edit()
    const self = this;
    this.saveUserInformation(function (response) {
      console.log('response for edit user', response);
      if (!response.status) {
        console.log('test1');
        self.activeModal.close(response);
        const user = JSON.parse(localStorage.getItem('user'));
        if (self.editUserMasterForm.get('TypeOfUser').value == 'BoardMember') {
          console.log('test2');
          var url = PssLink.EditDirector.replace(
            '{email}',
            self.commonFunction.decrypt(user.EmailId)
          ).replace('{userId}', response.pssObject.ID);
          window.open(url, '_self');
        }
        if (self.editUserMasterForm.get('TypeOfUser').value == 'KMP') {
          var url = PssLink.EditKMP.replace(
            '{email}',
            self.commonFunction.decrypt(user.EmailId)
          ).replace('{userId}', response.pssObject.ID);
          window.open(url, '_self');
        }
      } else {
        self.activeModal.close(response);
      }
    });
  }

  saveUserInformation(callback) {
    if (this.checkValidUserTypeForDirectorType()) {
      const self = this;
      if ((self.editUserMasterForm.get("TypeOfUser").value == "BoardMember") && _.isEmpty(self.editUserMasterForm.get('DIN').value) && _.isEmpty(self.editUserMasterForm.get('Category').value)) {
        this.handleDINError = true;
        // this.handleCategoryError = true;
        return;
      }
      this.handleDINError = false;
      this.handleCategoryError = false;
      this.isProceed = true;
      this.user.UserId = this.userId;
      //this.user.Roles = 
      this.user.FirstName = this.editUserMasterForm.get('FirstName').value
      this.user.LastName = this.editUserMasterForm.get('LastName').value
      this.user.MobileNumber = this.editUserMasterForm.get('MobileNumber').value
      this.user.MobileNumber2 = this.editUserMasterForm.get('MobileNumber2').value
      this.user.EmailId = this.editUserMasterForm.get('EmailId').value
      this.user.DepartmentName = this.editUserMasterForm.get("DepartmentName").value
      this.user.BranchName = this.editUserMasterForm.get("BranchName").value
      this.user.departmentName = this.editUserMasterForm.get("tempDepartmentName").value
      this.user.branchName = this.editUserMasterForm.get("tempBranchName").value
      this.user.oldEmailId = this.oldEmailId
      this.user.SecondaryEmailId = this.editUserMasterForm.get('SecondaryEmailId').value
      this.user.TypeOfUser = this.editUserMasterForm.get('TypeOfUser').value
      var loginUsers = JSON.parse(localStorage.getItem("user"));
      this.user.CompanyName = loginUsers.CompanyName;  //this.selectedCompany.label;
      this.user.CompanyId = loginUsers.CompanyId;      //this.selectedCompany.value;    
      this.user.Fincode = this.finCode;
      this.user.Experience = this.editUserMasterForm.get('Experience').value
      this.user.Remuneration = this.commaSeperatedToNumberOnly(this.editUserMasterForm.get('Remuneration').value)
      this.user.ReportsTo = this.editUserMasterForm.get('ReportToUser').value
      this.user.DateOfBirth = this.setDate(this.editUserMasterForm.controls.DOB.value)

      this.user.ADID = this.editUserMasterForm.get('ADID').value;
      this.user.shouldDisable = "'" + this.shouldDisable + "'";
      this.user.Title = this.editUserMasterForm.get('TitleName').value;

      if (self.editUserMasterForm.get("TypeOfUser").value == "BoardMember" || self.editUserMasterForm.get("TypeOfUser").value == "KMP") {
        this.user.Designation = this.editUserMasterForm.get('DesignationSelection').value;
      }
      else
        this.user.Designation = this.editUserMasterForm.get('Designation').value;


      this.user.AreaOfExpertise = this.editUserMasterForm.get('AreaofExpertise').value;
      // this.user.PAN = this.editUserMasterForm.get('PAN').value.toUpperCase();
      var encryptedPANNo = CryptoJS.AES.encrypt(this.editUserMasterForm.get('PAN').value.toUpperCase(), this.secretKey.trim()).toString();
      this.user.PAN = encryptedPANNo;

      this.user.DIN = this.editUserMasterForm.get('DIN').value;

      if (this.editUserMasterForm.get("TypeOfUser").value == "BoardMember") {
        this.user.Category = this.editUserMasterForm.get('Category').value;
        this.user.DirectorType = this.editUserMasterForm.get('DirectorType').value;
      }
      this.user.Gender = this.editUserMasterForm.get("Gender").value;
      this.user.UniqueUserId = this.editUserMasterForm.get("UniqueUserId").value;
      //console.log(this.editUserMasterForm.get("UniqueUserId").value);

      if (this.isVisibleForChecker == true) {
        this.user.IsAuthorize = true;
      }
      this.user.NotificationType = this.NotificationType;

      //Commitees commented as per client requirement
      // var committees = [];
      // _.forEach(self.selectedCommmittee, function (data) {
      //   committees.push(data.CommitteeName)
      // })

      // this.user.Committees = committees;

      //if user not change picture
      //  
      if (this.base64Code != null || this.profilePictureByRemoveButton) {
        this.user.ProfilePicture = this.base64Code;
      }

      //save subsideries
      //  
      if (_.size(this.selectedSubsideries)) {
        this.selectedSubsideries = this.selectedSubsideries.map(function (o) {
          return {
            'Name': o.label,
            'ID': o.value //o._id,
          }
        });
      }


      var filteredSubsideryList = [];

      for (var subIndex in this.subsideryList) {
        for (var selectedSubIndex in this.selectedSubsideries) {
          if (this.subsideryList[subIndex]._id == this.selectedSubsideries[selectedSubIndex].ID && this.subsideryList[subIndex].Name == this.selectedSubsideries[selectedSubIndex].Name) {

            filteredSubsideryList.push(this.subsideryList[subIndex]);
          }
        }
      }

      this.user.remarks = this.editUserMasterForm.get('Remarks').value;
      this.user.Password = this.editUserMasterForm.get('Password').value;

      this.user.SubsidiaryCompanies = filteredSubsideryList;


      if (this.isAuthorise == true) {
        this.user.IsAuthorize = true;
      }

      //authorizer
      if (this.isVisibleForChecker == true && this.isAuthorise == false) {
        this.userMasterService.authorizeUser(this.user).then(response => {
          this.isProceed = false;
          callback(response)
        })

      }
      else {
        this.userMasterService.editUser(this.user).then(response => {
          this.isProceed = false;
          callback(response)

          if (this.base64Code != null) {
            //  
            //dashboard profile pic updated if logged-in user is updated
            let loginWithUserId = loginUsers._id;
            if (this.userId == loginWithUserId)
              $("#userProfilePic").attr("src", this.base64Code);
          }
        })
      }

    }
    else {
      //console.log("invalid form submit");
      // callback({status: 1})
    }
  }

  //converts comma seperated number to Number only
  commaSeperatedToNumberOnly(value) {
    if (value == '' || value == null || value == undefined) {
      return '';
    } else {
      value = value.toString();
      return value.replace(
        new RegExp(','.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'),
        ''
      );
    }
  }

  onSelectedDirectorType(data) {
    console.log(data)
    if (data.value == 'ExcecutiveDirector') {
      //Fill designation according to ExcecutiveDirector
      this.boardDesignationlist = this.executiveDesignationlist;
      this.categoryList = $.grep(this.categoryList, function (category) {
        //
        return category.label != 'Independent';
      });
    } //if Non-ExcecutiveDirector
    else {
      //Fill designation according to Non-ExcecutiveDirector
      this.boardDesignationlist = this.nonExecutiveDesignationlist;
      this.categoryList = [];
      this.fillCategoryList();
    }
  }

  //set validator for Designation input box
  setValidatiorForDesignationInput() {
    // this.editUserMasterForm.controls.Designation.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(500), Validators.pattern(this.pattern)]);
    this.editUserMasterForm.controls.Designation.setValidators([
      Validators.minLength(5),
      Validators.maxLength(500),
      Validators.pattern(this.pattern),
    ]);
    this.editUserMasterForm.controls.DesignationSelection.clearValidators();
    this.editUserMasterForm.controls.DesignationSelection.updateValueAndValidity();
  }
  //set validator for Designation selction box
  setValidatiorForDesignationSelection() {
    // this.editUserMasterForm.controls.DesignationSelection.setValidators([Validators.required]);
    this.editUserMasterForm.controls.DesignationSelection.setValidators([]);
    this.editUserMasterForm.controls.Designation.clearValidators();
    this.editUserMasterForm.controls.Designation.updateValueAndValidity();
  }

  getSubsideries() {
    this.userMasterService.getSubsideryList().then((response) => {
      //console.log('checking for the user list', response);
      if (!response.status) {
        //
        this.subsideryList = response.companyData.SubsidiaryCompanies.map(
          function (o) {
            return {
              label: o.Name,
              value: o._id,
              CIN: o.CIN,
              Type: o.Type,
              Shareholding: o.Shareholding,
              Name: o.Name,
              _id: o._id,
              Fincode: o.Fincode,
            };
          }
        );

        //sort ascending for user Name
        this.subsideryList = this.subsideryList.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
      }
    });
  }

  //bind selected subsideries id and name to the list
  getSelectedSubsideries(item: any) {
    //
    // this.selectedPeers.push([item.label, item.value]);
    this.selectedSubsideries.push(item);
  }

  //remove peers from list which is removed from ng-select control
  multipleSubsideriesDeselected(item: any) {
    //
    let indexOfItem = this.selectedSubsideries.findIndex(
      (o) => o.value === item.value
    );
    this.selectedSubsideries.splice(indexOfItem, 1);
  }

  onChangePAN() {
    //
    var PAN = this.editUserMasterForm.controls['PAN'].value;
    this.user.PAN = this.userMasterService.encodePAN(PAN);
    this.user.UserId = this.userId;

    this.userMasterService
      .checkPANExistsForExistingUser(this.user)
      .then((response) => {
        if (response.status) {
          this.isPANExists = true;
        } else {
          this.isPANExists = false;
        }
      });
  }
  onADIdChange() {
    console.log('this.editUserMasterForm', this.editUserMasterForm);
    //var emailId = this.userMasterForm.get('EmailId').hasError('required');
    var ADID = this.editUserMasterForm.controls['ADID'].value;
    var isBlankADID = this.editUserMasterForm.get('ADID').hasError('required');
    var isADIDInvalid = this.editUserMasterForm
      .get('ADID')
      .hasError('/^([a-zA-Z0-9 _.-]+)$/');
    if (!isBlankADID && !isADIDInvalid) {
      this.userMasterService
        .checkADIdExists(ADID, this.userIDForedit)
        .then((response) => {
          if (response.status) {
            this.isADIdExists = true;
          } else {
            this.isADIdExists = false;
          }
        });
    }
  }

  onChangeDIN() {
    //
    var DIN = this.editUserMasterForm.controls['DIN'].value;
    var isBlankDIN = this.editUserMasterForm.get('DIN').hasError('required');
    let isDINInvalid = this.editUserMasterForm
      .get('DIN')
      .hasError('^([0-9]){8}?$');
    this.user.DIN = DIN;
    this.user.UserId = this.userId;
    if (DIN != null && DIN != '') {
      if (!isDINInvalid) {
        this.userMasterService
          .checkDINExistsForExistingUser(this.user)
          .then((response) => {
            if (response.status) {
              this.isDINExists = true;
            } else {
              this.isDINExists = false;
            }
          });
      }
    }
  }

  notificationCheckboxChangeEvent(event, value) {
    if (!event.target.checked) {
      if (
        !this.editUserMasterForm.controls['EmailNotification'].value &&
        !this.editUserMasterForm.controls['BellNotification'].value &&
        !this.editUserMasterForm.controls['SMSNotification'].value
      ) {
        this.editUserMasterForm.controls[value].setValue(true);
      }
    }

    this.checkNotificationArray();
  }

  loginAccessCheckboxChangeEvent(event, value) {
    if (!event.target.checked) {
      if (
        !this.editUserMasterForm.controls['IPADNotification'].value &&
        !this.editUserMasterForm.controls['BrowserNotification'].value
      ) {
        this.editUserMasterForm.controls[value].setValue(true);
      }
    }

    this.LoginAccesscheckNotificationArray();
  }

  LoginAccesscheckNotificationArray() {
    var ipadTypePresent;
    var browserTypePresent;

    ipadTypePresent = this.NotificationType.includes('Ipad');
    browserTypePresent = this.NotificationType.includes('Browser');

    if (this.editUserMasterForm.controls['IPADNotification'].value) {
      if (!ipadTypePresent) {
        this.NotificationType.push('Ipad');
      }
    } else {
      if (ipadTypePresent) {
        var indexOfIpad = this.NotificationType.indexOf('Ipad');
        if (indexOfIpad !== -1) {
          this.NotificationType.splice(indexOfIpad, 1);
        }
      }
    }

    if (this.editUserMasterForm.controls['BrowserNotification'].value) {
      // this.notificationType.push("SMS")
      if (!browserTypePresent) {
        this.NotificationType.push('Browser');
      }
    } else {
      if (browserTypePresent) {
        var indexOfBrowser = this.NotificationType.indexOf('Browser');
        if (indexOfBrowser !== -1) {
          this.NotificationType.splice(indexOfBrowser, 1);
        }
      }
    }
  }

  checkNotificationArray() {
    var emailTypePresent;
    var smsTypePresent;
    // var pushTypePresent;
    var bellTypepresent;
    emailTypePresent = this.NotificationType.includes('Email');
    smsTypePresent = this.NotificationType.includes('SMS');
    // pushTypePresent = this.notificationType.includes("Push");
    bellTypepresent = this.NotificationType.includes('Bell');

    if (this.editUserMasterForm.controls['EmailNotification'].value) {
      if (!emailTypePresent) {
        this.NotificationType.push('Email');
      }
    } else {
      if (emailTypePresent) {
        var indexOfEmail = this.NotificationType.indexOf('Email');
        if (indexOfEmail !== -1) {
          this.NotificationType.splice(indexOfEmail, 1);
        }
      }
    }

    if (this.editUserMasterForm.controls['SMSNotification'].value) {
      // this.notificationType.push("SMS")
      if (!smsTypePresent) {
        this.NotificationType.push('SMS');
      }
    } else {
      if (smsTypePresent) {
        var indexOfEmail = this.NotificationType.indexOf('SMS');
        if (indexOfEmail !== -1) {
          this.NotificationType.splice(indexOfEmail, 1);
        }
      }
    }

    if (this.editUserMasterForm.controls['BellNotification'].value) {
      // this.notificationType.push("Bell")
      if (!bellTypepresent) {
        this.NotificationType.push('Bell');
      }
    } else {
      if (bellTypepresent) {
        var indexOfEmail = this.NotificationType.indexOf('Bell');
        if (indexOfEmail !== -1) {
          this.NotificationType.splice(indexOfEmail, 1);
        }
      }
    }

    // if(this.editUserMasterForm.controls['PushNotification'].value){
    //   // this.notificationType.push("Push")
    //   if (!pushTypePresent) {
    //     this.notificationType.push("Push")
    //   }
    // } else {
    //   if (pushTypePresent) {
    //     var indexOfEmail = this.notificationType.indexOf("Push");
    //     if (indexOfEmail !== -1) {
    //       this.notificationType.splice(indexOfEmail, 1);
    //     }
    //   }
    // }

    //console.log("notificationType",this.notificationType);
  }

  ADWhitespaceError() {
    this.editUserMasterForm.controls.ADID.setValue(
      this.editUserMasterForm.controls.ADID.value.trim()
    );
    this.editUserMasterForm.controls.ADID.updateValueAndValidity();
  }

  editData() {
    this.isAuth = true;
  }
  saveData() {
    if (this.checkValidUserTypeForDirectorType()) {
      if (
        this.editUserMasterForm.get('TypeOfUser').value == 'BoardMember' &&
        _.isEmpty(this.editUserMasterForm.get('DIN').value)
      ) {
        this.handleDINError = true;
        return;
      }
      this.handleDINError = false;
      (this.user.UserId = this.userId),
        (this.user.FirstName = this.editUserMasterForm.get('FirstName').value);
      this.user.LastName = this.editUserMasterForm.get('LastName').value;
      this.user.MobileNumber =
        this.editUserMasterForm.get('MobileNumber').value;
      this.user.EmailId = this.editUserMasterForm.get('EmailId').value;
      this.user.TypeOfUser = this.editUserMasterForm.get('TypeOfUser').value;
      var loginUsers = JSON.parse(localStorage.getItem('user'));
      this.user.CompanyName = loginUsers.CompanyName; //this.selectedCompany.label;
      this.user.CompanyId = loginUsers.CompanyId; //this.selectedCompany.value;
      this.user.Fincode = this.finCode;
      (this.user.Experience = this.editUserMasterForm.get('Experience').value),
        (this.user.Remuneration =
          this.editUserMasterForm.get('Remuneration').value),
        (this.user.ReportsTo =
          this.editUserMasterForm.get('ReportToUser').value),
        (this.user.DateOfBirth = this.setDate(
          this.editUserMasterForm.controls.DOB.value
        ));

      //added new fields 14 feb

      this.user.Title = this.editUserMasterForm.get('TitleName').value;

      if (
        this.editUserMasterForm.get('TypeOfUser').value == 'BoardMember' ||
        this.editUserMasterForm.get('TypeOfUser').value == 'KMP'
      ) {
        this.user.Designation = this.editUserMasterForm.get(
          'DesignationSelection'
        ).value;
      } else {
        this.user.Designation =
          this.editUserMasterForm.get('Designation').value;
      }

      this.user.AreaOfExpertise =
        this.editUserMasterForm.get('AreaofExpertise').value;
      this.user.DirectorType =
        this.editUserMasterForm.get('DirectorType').value;
      this.user.PAN = this.editUserMasterForm.get('PAN').value.toUpperCase();
      this.user.DIN = this.editUserMasterForm.get('DIN').value;

      this.user.remarks = this.editUserMasterForm.get('Remarks').value;
      this.user.ADID = this.editUserMasterForm.get('ADID').value;
      this.user.WebexId = this.editUserMasterForm.get('WebexId').value;
      this.user.WebexPassword =
        this.editUserMasterForm.get('WebexPassword').value;
      this.user.Password = this.editUserMasterForm.get('Password').value;
      this.user.Category = this.editUserMasterForm.get('Category').value;
      this.user.Gender = this.editUserMasterForm.get('Gender').value;
      this.user.DepartmentName =
        this.editUserMasterForm.get('DepartmentName').value;
      this.user.BranchName = this.editUserMasterForm.get('BranchName').value;
      this.user.departmentName =
        this.editUserMasterForm.get('tempDepartmentName').value;
      this.user.branchName =
        this.editUserMasterForm.get('tempBranchName').value;
      this.user.UniqueUserId =
        this.editUserMasterForm.get('UniqueUserId').value;
      this.user.NotificationType = this.NotificationType;

      var committees = [];
      _.forEach(this.selectedCommmittee, function (data) {
        committees.push(data.CommitteeName);
      });

      this.user.Committees = committees;

      //if user not change picture
      //
      if (this.base64Code != null) {
        this.user.ProfilePicture = this.base64Code;
      }

      this.userMasterService.editUser(this.user).then((response) => {
        if (!response.status) {
          // this.createdUserId =
          this.activeModal.close(response);
          $('body').css('overflow', 'auto');
        } else {
          this.activeModal.close(response);
          $('body').css('overflow', 'auto');
        }
      });
    } else {
      //console.log("invalid form submit");
    }
  }
}

export class NgbdDatepickerPopup {
  model: NgbDateStruct;
}