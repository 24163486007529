import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";

import * as _ from "lodash";
declare var swal: any;

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = localStorage.getItem("token");
    var companyDetails = localStorage.getItem("companyDetailsGoveva");
    
    if (!_.isNil(token)) {
      let header = new HttpHeaders();
      header.set("x-access-token", token);
      header.set("GovevaCompany", companyDetails);
      req.headers.set("x-access-token", token)
      let authReq = req.clone({ setHeaders: {"x-access-token":token,  GovevaCompany: companyDetails} });
      
      return next.handle(authReq).pipe(
        catchError((error) => {
          
          return observableThrowError(error);
        }),
        retry(3)
      );
    }
  }
}

@Injectable()
export class MyHttpInterceptor2 implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = localStorage.getItem("token");
    var companyDetails = localStorage.getItem("companyDetailsGoveva");
    
    if (!_.isNil(token)) {
      let header = new HttpHeaders();
      header.append("x-access-token", token);
      header.append("GovevaCompany", companyDetails);
      //console.log("req.headersreq.headersreq.headers", req.headers)
      let authReq = req.clone({ setHeaders: { GovevaCompany: companyDetails} });

      // let authReq = req.clone({ headers: req.headers.set("govevacompany", companyDetails) });
      
      
      
      
      
      return next.handle(authReq).pipe(
        catchError((error) => {
          
          return observableThrowError(error);
        })
      );
    }
  }
}

@Injectable()
export class subsidiaryInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var isSubsidiary = localStorage.getItem("isSubsidiary");
    var subsidiaryFincode;
    
    if (isSubsidiary == "true") {
      subsidiaryFincode = localStorage.getItem("subsidiaryFincode");
    } else {
      subsidiaryFincode = "";
    }
    let authReq = req.clone({ setHeaders: { subsidiaryfincode: subsidiaryFincode} });

    //console.log("req.headersreq.headersreq.headers", req.headers)
    return next.handle(authReq).pipe(
      catchError((error, caught) => {
        
        console.log(error);
        
        return observableThrowError(error);
      })
    ) as any;
  }
}
