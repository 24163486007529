<style>
  #companyLogoImg {
    height: 50px;
  }

  .cat__top-bar__logo a {
    display: block;
  }

  .cat__top-bar__logo {
    width: 15.28rem;
    margin-right: 0;
    padding: 1rem 27px 1rem 0;
  }

  .top-bar__flfl {
    width: 100%;
    display: flex;
    padding: 1rem 1.28rem 1rem 0;
  }

  .top-bar__flfl .btn.btn-link {
    display: block;
    padding-left: 0;
  }

  .top-bar__flfl .dropdown-menu img {
    border-left: 0;
    padding-left: 10px;
  }

  .top-bar__flfl img {
    max-height: 1.9rem;
    border-left: 1px solid #f1f1f1;
    padding-left: 27px;
  }

  .cat__top-bar__right {
    padding-right: 10px;
  }

  .cat__top-bar__right .fa-bars {
    cursor: pointer;
    color: #37a7a5;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .cat__top-bar__right .fa-bars.openbtn {
    font-size: 22px;
    cursor: pointer;
    color: #37a7a5;
  }

  @media screen and (max-width: 991px) {
    .lastLoginTime2 {
      display: none;
    }

    #companyLogoImg {
      height: 46px;
      width: 52px;
    }

    .cat__top-bar__right .cat__top-bar__item {
      margin-left: 0;
      cursor: pointer;
      padding: 5px;
    }

    .cat__top-bar__right {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .cat__top-bar__logo {
      padding-right: 55px;
    }

    .top-bar__flfl img {
      padding-left: 55px;
    }
  }

  @media screen and (max-width: 669px) {
    .cat__top-bar {
      height: auto !important;
    }
  }
</style>
<div class="cat__top-bar">
  <!-- left aligned items -->
  <div class="cat__top-bar__left">
    <div class="cat__top-bar__logo">
      <a href="/#/structure/(structure-outlet:dashboards/alpha)">
        <img src="../../../assets/modules/dummy-assets/common/img/GovEVA.png" class="hide-xs" alt="GovEVA" />
        <img src="../assets/images/logo-mob.svg" class="logo-mob" alt="GovEVA" />
      </a>
    </div>
    <div class="top-bar__flfl">
      <span class="company-logo-label" *ngIf=" companyLogo">
        <img id="companyLogoImg" [src]="companyLogo" alt="Logo" style="height: 50px;"> </span> <span
        class="company-logo-label" *ngIf="!companyLogo"> <img src="assets/images/company-icon.svg" />
      </span>
      <div style="margin-left: 0px;" (click)="toggleCompanySelectTopBarDropdown()">
        <div class="dropdown"> <a href="javascript: void(0);" class="dropdown-toggle" data-toggle="dropdown"
            aria-expanded="false"> <span class="company-name-label">{{companyName}}</span>
          </a>
          <ul class="dropdown-menu " aria-labelledby="" role="menu" id="companySelectTopBarDropdown">
            <div class="cat__top-bar__activity w-auto">
              <div *ngFor="let company of associatedCompanyList;let indexCount=index">
                <div class="cat__top-bar__activity__item" style="cursor: pointer;" (click)="demo(company)">
                  <div class="cat__top-bar__activity__inner">
                    <div class="cat__top-bar__activity__descr"> {{company.CompanyName}} </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- right aligned items -->
  <div class="cat__top-bar__right d-flex justify-content-end">
    <div class="cat__top-bar__item">
      <i class="fa fa-bars openbtn" aria-hidden="true" (click)="toggleNav()"></i>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Messages</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style="padding: 0px" class="modal-body chat-modal">
        <app-webx-chat></app-webx-chat>
      </div>
    </ng-template>

    <div class="cat__top-bar__item" *ngIf="chat && userType != 'GuestSuperAdmin'"
      [appHasPermission]="{ Url: 'chat', method: 'get', menu: 'Dashboard' }">
      <div class="dropdown">
        <a href="javascript: void(0);" class="dropdown-notification-toggle" (click)="openChatModel(content)"
          data-toggle="dropdown" aria-expanded="false" style="font-size: 24px;">
          <img src="../assets/images/top-comment-icon.png" alt="Comment" />
        </a>
      </div>
    </div>

    <div class="cat__top-bar__item">
      <div class="dropdown">
        <a href="javascript: void(0);" class="dropdown-notification-toggle" data-toggle="dropdown" aria-expanded="false" (click)="viewAllNotification()"
          style="font-size: 24px;">
          <img src="../assets/images/top-notification-icon.png" alt="notification" />
          <span class="notification_count"
            *ngIf="notificationData!=null && notificationData.unreadNotificationCount > 0">{{notificationData.unreadNotificationCount}}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right notificationParent" aria-labelledby="" role="menu">
          <div class="cat__top-bar__activity notificationList"
            *ngIf="notificationData!=null && notificationData.unreadNotificationCount > 0">
            <div *ngFor="let notification of notificationData.unreadNotificationList;let indexCount=index">
              <div id={{notification._id}} class="cat__top-bar__activity__item" style="cursor: pointer;"
                (click)="viewNotification(notification._id)" *ngIf="indexCount < 3">
                <div class="cat__top-bar__activity__inner">
                  <div class="cat__top-bar__activity__title">
                    <span class="pull-right">{{notification.CreatedDate | date: 'dd/MM/yyyy'}}</span>
                  </div>
                  <div class="cat__top-bar__activity__descr" style="clear: both;"
                    [innerHTML]="notification.Description | safeHtml">
                  </div>
                </div>
              </div>
            </div>
            <div class="cat__top-bar__notification__view-all">
              <a (click)="viewAllNotification()" href="javascript: void(0);">View All</a>
            </div>
          </div>

          <div class="cat__top-bar__activity" *ngIf="
              notificationData == null ||
              notificationData.unreadNotificationCount <= 0
            ">
            <div class="cat__top-bar__activity__item" style="text-align: center; color: #919191; padding: 15px 0">
              All caught up!
            </div>
            <div class="cat__top-bar__notification__view-all">
              <a (click)="viewAllNotification()" href="javascript: void(0);">View All</a>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="cat__top-bar__item">
      <div id = "TopBarProfileDropdown" (click)="toggleTopBarProfileDropdown()" class="dropdown cat__top-bar__avatar-dropdown">
        <!-- <a href="javascript: void(0);" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"> -->
        <a href="javascript: void(0);" class="dropdown-toggle" >
          <span class="cat__top-bar__avatar" href="javascript:void(0);">
            <img id="userProfilePic" src="../assets/images/top-user-icon.png" alt="ProfilePic" />
          </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="" role="menu">
          <a class="dropdown-item">{{companyName}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="openEditProfilePopUp();">
            <i class="dropdown-icon icmn-user"></i> Profile</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToChangePasswordScreen();">
            <i class="dropdown-icon fa fa-key"></i> Change Password</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">
            <i class="dropdown-icon icmn-exit"></i> Logout</a>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  style="top: 50px">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px"></div>
      <div class="modal-body" style="padding: 10px 15px">
        <div id="alertMessage" style="font-size: 16px"></div>
      </div>
    </div>
  </div>
</div>