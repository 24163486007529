import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GfmService } from './../../services/gfm.service';
import { CompanyMasterService } from './../../services/companymaster.service';
import { environment } from '../../../environments/environment';
import { MeetingMasterService } from './../../services/meetingMaster.service';
import * as _ from 'lodash';
import { safeJsonStringify } from '../../shared/utils';
declare var $: any;
declare var NProgress: any;
declare var jQuery: any;

@Component({
  selector: 'app-gfmmodule',
  templateUrl: './gfmmodule.component.html',
  styleUrls: ['./gfmmodule.component.css'],
})
export class GfmmoduleComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private gfmservice: GfmService,
    private companyMasterService: CompanyMasterService,
    private meetingMasterService: MeetingMasterService
  ) {}

  uploadForm = new FormGroup({
    file1: new FormControl(),
    folderName: new FormControl(),
    uploadMultipleFileDocument: new FormControl(),
  });
  filedata: any;
  fileName: any;
  token: any;
  companyID: any;
  companyName: any;
  complianceEngine: any;
  complianceEngineUrl: any;
  governanceUrl: any;
  recordDetails: any;
  uniqueUserId: any;
  userId: any;
  recordPath: any;
  gemAccessUrl: any;
  folderName: any;
  count: any;
  disabledOnclick: boolean;
  uploadMultipleFileDocument: any = []; //this will select multiple files so it should be array

  ngOnInit() {
    var self = this;
    let userInfo = JSON.parse(localStorage.getItem('user'));
    this.userId = JSON.parse(localStorage.getItem('user'))._id;
    this.uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    this.token = localStorage.getItem('token');
    this.companyID = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyID;
    this.companyName = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyName;
    var governanceUrl = environment.clientUrl;
    this.companyMasterService.getCompanyList().then((company) => {
      if (company.status == 0) {
        this.complianceEngine = company.companyData.ComplianceEngine;
        this.complianceEngineUrl = company.companyData.ComplianceEngineUrl;
        if (company.GEMAccessURL == '') {
          // this.gemAccessUrl = 'http://52.66.23.123:8085/api/';
          this.gemAccessUrl = "http://" + environment.gemIp_domain + "/api/";
        } else {
          // this.gemAccessUrl = 'http://52.66.23.123:8085/api/';
          this.gemAccessUrl = "http://" + environment.gemIp_domain + "/api/";
        }
      } else {
        console.log('company data blank');
      }
    });

    this.recordPath = '/home/ec2-user/GEM/GFM/' + this.uniqueUserId;
    this.recordDetails = safeJsonStringify({
      recordPath: '/home/ec2-user/GEM/GFM/' + this.uniqueUserId,
    });

    // this.gfmservice.getAllFilesAndFolders(this.recordDetails, this.complianceEngine, this.complianceEngineUrl, environment.nonValidateUrl,"").then(response => {
    //   if (response.status == 0) {
    //     console.log("response---------", response)
    //   } else {

    //   }
    // });
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
    this.ngOnInit();
  }

  fileEvent(event) {
    this.filedata = <File>event.target.files[0];
  }

  uploadFile() {
    let formData = new FormData();
    formData.append('file', this.filedata);
    this.gfmservice
      .postFileUploadForGFM(
        this.filedata,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        environment.nonValidateUrl,
        ''
      )
      .then((response) => {
        if (response.status == 0) {
          // this.gfmservice.getAllFilesAndFolders(this.recordDetails, this.complianceEngine, this.complianceEngineUrl,environment.nonValidateUrl,"").then(response => {
          //   if (response.status == 0) {
          //     console.log("response after file upload", response)
          //   } else {
          //   }
          // });
        } else {
        }
      });
  }

  multipleFileEvent(event) {
    var self = this;
    self.uploadMultipleFileDocument = [];
    this.count = event.target.files.length;
    console.log('event', event.target.files);
    console.log('file length', event.target.files.length);
    var i;
    for (i = 0; i < this.count; i++) {
      self.uploadMultipleFileDocument.push(<File>event.target.files[i]);
    }
    console.log(
      'uploadMultipleFileDocument list',
      this.uploadMultipleFileDocument
    );
  }

  getFolderName(event) {
    this.folderName = event.target.value;
    console.log('folderName', this.folderName);
    if (event.target.value.trim() == '') {
      this.uploadForm.controls.folderName.setValue(event.target.value.trim());
      this.uploadForm.controls.folderName.updateValueAndValidity();
    }
    // this.gfmservice.getFileUniqueUserIdForGFM(this.recordDetails, this.complianceEngine, this.complianceEngineUrl).then(response => {
    //   if (response) {
    //     console.log("response after file upload", response)
    //   } else {

    //   }
    // });
  }

  saveFolderNameWithFileUplaod() {
    this.disabledOnclick = true;
    let formData = new FormData();
    this.recordDetails = safeJsonStringify({
      action: 'create',
      recordName: this.folderName,
      recordPath: '/home/ec2-user/GEM/GFM/' + this.uniqueUserId,
    });

    this.gfmservice
      .createFolderForGFM(
        this.filedata,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        environment.nonValidateUrl,
        ''
      )
      .then((response) => {
        console.log('folder list', response);
        this.disabledOnclick = false;
        if (response) {
          this.recordDetails = safeJsonStringify({
            recordPath:
              '/home/ec2-user/GEM/GFM/' +
              this.uniqueUserId +
              '/' +
              this.folderName,
          });
          console.log('this.recordDetails', this.recordDetails);
          console.log(
            'uploadMultipleFileDocument list---------',
            this.uploadMultipleFileDocument.length
          );
          var count1 = this.uploadMultipleFileDocument.length;
          var i = 0;
          for (i = 0; i < count1; i++) {
            console.log(
              'uploadMultipleFileDocument list---------',
              this.uploadMultipleFileDocument[i]
            );
            formData.append('files', this.uploadMultipleFileDocument[i]);
          }
          formData.append('recordDetails', this.recordDetails);

          console.log('formData', formData);
          this.gfmservice
            .postMultipleFileUploadForGFM(
              formData,
              this.recordDetails,
              this.complianceEngine,
              this.complianceEngineUrl,
              environment.nonValidateUrl,
              ''
            )
            .then((response) => {
              if (response) {
                this.recordDetails = safeJsonStringify({
                  recordPath:
                    '/home/ec2-user/GEM/GFM/' +
                    this.uniqueUserId +
                    '/' +
                    this.folderName,
                });
                // this.gfmservice.getAllFilesAndFolders(this.recordDetails, this.complianceEngine, this.complianceEngineUrl,environment.nonValidateUrl,).then(response => {
                //   if (response.status == 0) {
                //     console.log("response after file upload", response)
                //   } else {

                //   }
                // });
              } else {
              }
            });
        } else {
        }
      });
  }
}
