<section>
  <div style="width: 95%; margin: 0 auto; position: absolute; top: 5px; left: 40px;" class="pb-2 pl-3">
    <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
  </div>
  <div class="inner-wrap">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel" style="font-weight: bold;">Create Intuit Consultant</h4>
      </div>

      <div class="col-lg-2">
        <label class="form-control-label" for="l0">Last Name<span style="color: red">*</span></label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Last Name" type="text" class="form-control" formControlName="ICMakerLastName" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerLastName')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICMakerLastName').touched
                  " class="form-control-error-custom">
            Last Name is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerLastName')
                      .hasError('pattern')
                  " class="form-control-error-custom">
            Please enter valid Last Name
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-control-label" for="l0">
          Email Id<span _ngcontent-c2="" style="color: red">*</span></label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Email Id" type="email" class="form-control" formControlName="ICMakerEmailId" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerEmailId')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICMakerEmailId').touched
                  " class="form-control-error-custom">
            Email Id is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerEmailId')
                      .hasError('email') &&
                    !createICMakerICChekerForm
                      .get('ICMakerEmailId')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICMakerEmailId').touched
                  " class="form-control-error-custom">
            Please enter valid Email Id
          </div>
          <div *ngIf="
                    isEmailIdInvalid &&
                    !createICMakerICChekerForm
                      .get('ICMakerEmailId')
                      .hasError('email')
                  " class="form-control-error-custom">
            IC Maker Email Id cannot be same as IC Checker Email Id
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-control-label" for="l0">Mobile No.<span _ngcontent-c2="" style="color: red">*</span></label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Mobile No." type="text" class="form-control" formControlName="ICMakerMobileNumber"
            min="10" max="10" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerMobileNumber')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICMakerMobileNumber').touched
                  " class="form-control-error-custom">
            Mobile Number is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICMakerMobileNumber')
                      .hasError('pattern') &&
                    !createICMakerICChekerForm
                      .get('ICMakerMobileNumber')
                      .hasError('required')
                  " class="form-control-error-custom">
            Please enter valid mobile number
          </div>
          <div *ngIf="
                    isMobileNoInvalid &&
                    !createICMakerICChekerForm
                      .get('ICMakerMobileNumber')
                      .hasError('pattern')
                  " class="form-control-error-custom">
            IC Maker Mobile Number cannot be same as IC Checker Mobile
            Number
          </div>
        </div>
      </div>
    </div>

    <hr />
    <h4 class="modal-title" id="myModalLabel" style="padding-bottom: 16px; font-size: large; font-weight: bold">
      IC Checker
    </h4>
    <div class="row">
      <div class="col-lg-2">
        <label class="form-control-label" for="l0">First Name
          <span style="color: red">*</span>
        </label>
      </div>
      <div class="col-lg-1">
        <ng-select [items]="titleList" formControlName="ICCheckerTitleName" [clearable]="false"></ng-select>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <input placeholder="First Name" type="text" class="form-control" formControlName="ICCheckerFirstName" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerFirstName')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICCheckerFirstName').touched
                  " class="form-control-error-custom">
            First Name is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerFirstName')
                      .hasError('pattern')
                  " class="form-control-error-custom">
            Please enter valid First Name
          </div>
        </div>
      </div>

      <div class="col-lg-2">
        <label class="form-control-label" for="l0">Last Name
          <span style="color: red">*</span>
        </label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Last Name" type="text" class="form-control" formControlName="ICCheckerLastName" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerLastName')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICCheckerLastName').touched
                  " class="form-control-error-custom">
            Last Name is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerLastName')
                      .hasError('pattern')
                  " class="form-control-error-custom">
            Please enter valid Last Name
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-control-label" for="l0">Email Id<span _ngcontent-c2="" style="color: red">*</span></label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Email Id" type="email" class="form-control" formControlName="ICCheckerEmailId" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerEmailId')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICCheckerEmailId').touched
                  " class="form-control-error-custom">
            Email Id is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerEmailId')
                      .hasError('email') &&
                    !createICMakerICChekerForm
                      .get('ICCheckerEmailId')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICCheckerEmailId').touched
                  " class="form-control-error-custom">
            Please enter valid Email Id
          </div>
          <div *ngIf="
                    isEmailIdInvalid &&
                    !createICMakerICChekerForm
                      .get('ICCheckerEmailId')
                      .hasError('email')
                  " class="form-control-error-custom">
            IC Checker Email Id cannot be same as IC Maker Email Id
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <label class="form-control-label" for="l0">Mobile No.
          <span _ngcontent-c2="" style="color: red">*</span></label>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input placeholder="Mobile No." type="text" class="form-control" formControlName="ICCheckerMobileNumber"
            min="10" max="10" />
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerMobileNumber')
                      .hasError('required') &&
                    createICMakerICChekerForm.get('ICCheckerMobileNumber')
                      .touched
                  " class="form-control-error-custom">
            Mobile Number is required
          </div>
          <div *ngIf="
                    createICMakerICChekerForm
                      .get('ICCheckerMobileNumber')
                      .hasError('pattern') &&
                    !createICMakerICChekerForm
                      .get('ICCheckerMobileNumber')
                      .hasError('required')
                  " class="form-control-error-custom">
            Please enter valid Mobile Number
          </div>
          <div *ngIf="
                    isMobileNoInvalid &&
                    !createICMakerICChekerForm
                      .get('ICCheckerMobileNumber')
                      .hasError('pattern')
                  " class="form-control-error-custom">
            IC Checker Mobile Number cannot be same as IC Maker Mobile
            Number
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <button style="float: right" class="btn btn-primary btn-sm" [disabled]="!createICMakerICChekerForm.valid"
          (click)="save()">
          Save
        </button>
        <!-- ||isFirstNameInvalid||isLastNameInvalid||isMobileNoInvalid||isEmailIdInvalid -->
      </div>
    </div>
  </div>

  <div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 50px">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div id="alertPopUpTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px">
        </div>
        <div class="modal-body" style="padding: 10px 15px">
          <div id="alertMessage" style="font-size: 16px"></div>
        </div>
      </div>
    </div>
  </div>
</section>