import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

import { MeetingMasterService } from '../../../services/meetingMaster.service';

@Component({
  selector: 'app-meetingattendance',
  templateUrl: './meetingattendance.component.html',
  styleUrls: ['./meetingattendance.component.css'],
  providers: [MeetingMasterService]
})
export class MeetingattendanceComponent implements OnInit {

  // @Input('attendanceObject') attendanceObject: any;
  @Input('meetingObject') meetingObject: any;
  attendanceObject: any;
  IsattendecCount: any;
  attendanceFile: any;
  isAttendanceObjectEmpty: boolean;
  intervalRef;
  meetingId: any;
  count : number = null;
  Attended : number = null;
  NotAttended : number = null;

  constructor(private meetingMasterService: MeetingMasterService) { }

  ngOnInit() {

    this.meetingId = this.meetingObject._id;
    
    this.attendanceObject = this.meetingObject.Attendance;
    if (_.size(this.attendanceObject)) {
      this.isAttendanceObjectEmpty = false;
    } else {
      this.isAttendanceObjectEmpty = true;
    }

    this.attendanceFile = this.meetingObject.AttendanceFile;    
    

      if (this.meetingId !== null) {
        this.meetingMasterService.getMeetingById(this.meetingId).then(response => {

         // console.log('response---Participant',response);
          this.attendanceObject = response.meetingData[0].Attendance
          this.count = this.attendanceObject.length;
          const attendance_array = this.attendanceObject;
          
          const isAttended = true;
          this.Attended = attendance_array.filter((obj) => obj.isAttended === isAttended).length;

          const NtAttended = false;
          this.NotAttended = attendance_array.filter((obj) => obj.isAttended === NtAttended).length;
          
          //console.log('Attended',this.Attended);
          //console.log('Not Attended',this.NotAttended);

        })
      }

  }

  downloadAttendanceFile() {
    this.meetingMasterService.getAttendanceFile(this.attendanceFile);
    // .then(response => {
    //   console.log("response", response);
    // })
    // .catch(error => console.log("error", error));
  }

  ngOnDestroy() {
    clearInterval(this.intervalRef);
  }

}
