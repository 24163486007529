import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WebexService } from '../../../services/webex.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


import { DashboardsAlpha } from '../alpha.page';

declare var $: JQueryStatic;


@Component({
  selector: 'app-attendinginperson',
  templateUrl: './attendinginperson.component.html',
  styleUrls: ['./attendinginperson.component.css'],
})
export class WebexAttendingInpersonComponent implements OnInit {

  meetingId: any;
  showIFrame: boolean;
  dashboardUrl: any;
  joinInPerson: boolean = false;
  autoHideTimeout: any;
  constructor(@Inject(DOCUMENT) document, private http: HttpClient, private route: ActivatedRoute, private router: Router, private webexService: WebexService) { }

  ngOnInit() {
    document.getElementById("cover-spin").style.display = "block";
    var attendInPerson = localStorage.getItem("joinInPerson");
    if(attendInPerson == "yes"){
      var element = document.getElementById('webExSection');
      element.style.background = null;
      this.meetingId = this.route.snapshot.paramMap.get('id');
      localStorage.setItem('meetingId', this.meetingId)
      this.openMeetingNew();
      this.joinInPerson = true;

      document.getElementById("sec1").style.display = "none";
      document.getElementById("sec2").classList.remove("col-lg-6", "col-sm-6", "col-xs-6");
      document.getElementById("sec2").classList.add("col-lg-12", "col-sm-12", "col-xs-12");
      this.webexService.joinMeetingAndParticipantAttendance('PSS', environment.pssServerUrl, 
        environment.nonValidateUrl, environment.GEMURL, this.meetingId, 'ATTENDING_IN_PERSON').then(webexMeeting => {
      });
    }

    localStorage.setItem('comingFromVcPage', 'false')
    $('body').css('padding-top', '0px')

    this.dashboardUrl = environment.UIBaseUrl + '/#/structure/(structure-outlet:dashboards/alpha)';
    var self = this;
    this.meetingId = this.route.snapshot.paramMap.get('id');
    document.getElementById("cover-spin").style.display = "none";

 setTimeout(() => {
      document.getElementById('ControlBtn').style.display = "block";
    }, 13000);   
  }
  ngAfterContentInit() {
    this.autoHideControlsButListener().subscribe();
  }
  autoHideControlsButListener() {
    return {
      subscribe: () => {

        //alert('Hii');
        $("#contorl-buts").addClass("hide");
        $("#sec1").mousemove(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").removeClass("hide");
          this.autoHideTimeout = setTimeout(function () {
            $("#contorl-buts").addClass("hide");
          }, 5 * 1000);
        }).mouseleave(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").addClass("hide");
        });

        $("#ControlBtn").mousemove(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").removeClass("hide");
          this.autoHideTimeout = setTimeout(function () {
            $("#contorl-buts").addClass("hide");
          }, 5 * 1000);
        }).mouseleave(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").addClass("hide");
        });


      },
      unsubscribe: () => {
        // alert("Hello...");
        clearTimeout(this.autoHideTimeout)
        //$("#contorl-buts").removeClass("hide");
        $("#sec1").off("mousemove", null)
        $("#sec1").off("mouseleave", null)
      }
    }
  }

  openIfram() {
    localStorage.setItem('isMeetingInitiated', 'join');
    localStorage.setItem("joinInPerson", "yes");
    this.showIFrame = true;
    var iframe = document.getElementById('iframeWind')
    iframe.setAttribute("src", environment.UIBaseUrl + '/#/structure/(structure-outlet:dashboards/alpha)');

  }

  // openMeeting(meetingId) {
  //   document.getElementById("cover-spin").style.display = "block";
  //   this.showIFrame = false;
  //   var iframe = document.getElementById('iframeWind')
  //   iframe.setAttribute("src", environment.UIBaseUrl + '/#/meeting-view/' + meetingId);
  //   document.getElementById("cover-spin").style.display = "block";
  // }

  openMeetingNew()
  {
    localStorage.setItem('isMeetingInitiated', 'join');
    localStorage.setItem("joinInPerson", "yes");
    this.meetingId = this.route.snapshot.paramMap.get('id');
    console.log("this.meetingId =======",this.meetingId )
    this.showIFrame = false;
    var iframe = document.getElementById('iframeWind')
    iframe.setAttribute("src", environment.UIBaseUrl + '/#/meeting-view/' + this.meetingId);
  }

  leaveMeeting() {
    console.log('leave')
    window.parent.location.href = environment.UIBaseUrl+"/#/structure/(structure-outlet:dashboards/alpha)"; 
    // this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
  }

}