<div class="modal-header">
  <h4 class="modal-title">Task List</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
    <!-- to provide close button click function -->
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" style="padding:0!important">
  <div class="col-md-12 col-lg-12 col-sm-12">
    <!-- main page -->
    <div class="col-md-12 col-lg-12 col-sm-12">
      <div class="taskliststatus">
        <p>All Status</p>
        <div class="row">
          <div class="status_div">
            <span class="statusBox complete_div"></span>Completed
          </div>
          <div class="status_div">
            <span class="statusBox done_div"></span>Done
          </div>
          <div class="status_div">
            <span class="statusBox delay_div"></span>Delayed Completion
          </div>
          <div class="status_div">
            <span class="statusBox pending_div"></span>Pending
          </div>
          <div class="status_div">
            <span class="statusBox partial_div"></span>Partially Completed
          </div>
          <div class="status_div">
            <span class="statusBox navailable_div"></span>Not Applicable
          </div>
          <div class="status_div">
            <span class="statusBox cancel_div"></span>Cancelled
          </div>
        </div>
      </div>
      <section class="card search-box" style="margin-bottom:5px">
        <form>
          <div class="card-block">

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <label>Process Name</label>
                  </div>
                  <div class="col-md-4">
                    <label>Search By Date:</label>
                  </div>
                  <div class="col-md-3">
                  </div>
                  <div class="col-md-1">
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedProcessById"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="processByIdList">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group" (click)="$event.stopPropagation()">
                      <input class="form-control" [(ngModel)]="selectedFromDate" style="padding:6px;z-index: 0;"
                        placeholder="dd-mm-yyyy" ngbDatepicker #c="ngbDatepicker" (click)="c.toggle();r.close();"
                        (document:click)="c.close();" (ngModelChange)="selectedFromDateChange(); c.close()"
                        [ngModelOptions]="{standalone: true}">
                      <button class="input-group-addon" (click)="c.toggle();r.close();" type="button">
                        <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                      </button> &nbsp;&nbsp;&nbsp;

                      <input class="form-control" [(ngModel)]="selectedToDate" [disabled]="!isFromDateSelected"
                        style="padding:6px;z-index: 0;" placeholder="dd-mm-yyyy" ngbDatepicker #r="ngbDatepicker"
                        (click)="r.toggle();c.close();" (document:click)="r.close();"
                        (ngModelChange)="selectedToDateChange(); r.close()" [ngModelOptions]="{standalone: true}"
                        [minDate]="minDateForToDate">
                      <button class="input-group-addon" (click)="r.toggle();c.close();" [disabled]="!isFromDateSelected"
                        type="button">
                        <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="input-group">
                      <button type="button" (click)="searchFilter()" class="btn btn-sm btn-primary" title="search">
                        <i class="fa fa-search"></i>Search
                      </button> &nbsp; &nbsp; &nbsp;
                      <button type="button" class="btn btn-sm btn-default" (click)="clearFilterValues()"
                        title="refresh">
                        <i class="fa fa-refresh"></i>Clear
                      </button>
                    </div>
                  </div>
                  <div class="col-md-1" style="text-align:center">
                    <button type="button" class="btn btn-sm btn-primary" (click)="showDetailsRoadmapTask()">Show
                      Details</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      <section class="card main-box">
        <div class="row" style="padding-left:2%;padding-right:2%;margin-top:2%">
          <div class="col-md-3">
            <legend class="legendsBtn btn-md btn-primary legends" style="background:#07a7a5">Process Name</legend>
          </div>
          <div class="col-md-9">
            <legend class="legendsBtn btn-md btn-primary legends" style="background:#282828">Task Status</legend>
          </div>
        </div>
        <div class="row card"
          style="padding:2%;margin-right:2%;margin-left:2%;display: block;margin-bottom:1%!important"
          *ngFor=" let process of processList">
          <div class="col-md-3" title="{{process.ProcessOwnerName}}">
            {{process.ProcessName}}
          </div>
          <div class="col-md-9">
            <div class="row" style="font-weight: bold;">
              <span class="col-md-2"
                *ngIf="((process.Completed > 0) || (process.Delayed_Completion > 0) || (process.Done > 0))"
                [ngClass]="{'completed' : ((process.Completed > 0) || (process.Delayed_Completion > 0) || (process.Done > 0)) }">{{process.Completed
                + process.Delayed_Completion + process.Done}}</span>
              <span class="col-md-2" *ngIf="process.Pending > 0"
                [ngClass]="{'pending' : process.Pending > 0}">{{process.Pending}}</span>
              <span class="col-md-2" *ngIf="process.Partially_Completed > 0"
                [ngClass]="{'partially_Completed' : process.Partially_Completed > 0 }">{{process.Partially_Completed}}</span>
              <span class="col-md-2" *ngIf="((process.Not_Applicable > 0) || (process.Cancelled > 0))"
                [ngClass]="{'cancelled' : ((process.Not_Applicable > 0) || (process.Cancelled > 0))}">{{process.Not_Applicable
                + process.Cancelled}}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<div class="modal-footer">

</div>