import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ActivatedTaskFromComplianceService } from './services/activatedTaskFromCompliance.service';
import { AssessmentHistoryService } from './services/assessmenthistory.service';
import { AssessmentSetupService } from './services/assessmentsetup.service';
import { BISCommunicationService } from './services/BISCommunication.service';
import { BoardEvaluationService } from './services/boardEvaluation.service';
import { CompanyMasterService } from './services/companymaster.service';
import { CompanySetupService } from './services/companySetup.service';
import { FinancialDataService } from './services/financialData.service';
import { EmissionCalculationService } from './services/emission-calculation.service';


import { LoginService } from './services/login.service';
import { MeetingMasterService } from './services/meetingMaster.service';
import { UINotificationService } from './services/notificationUI.service';
import { OTPLoginService } from './services/otp-login.service';
import { RoadmapService } from './services/roadmap.service';
import { RocketChatService } from './services/rocketChat.service';
import { RoleMasterService } from './services/rolemaster.service';
import { SocketService } from './services/socket.service';
import { SuperAdminSetupService } from './services/superAdminSetup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
  providers: [
    AssessmentSetupService,
    AssessmentHistoryService,
    BISCommunicationService,
    CompanyMasterService,
    CompanySetupService,
    SuperAdminSetupService,
    MeetingMasterService,
    LoginService,
    RoleMasterService,
    RoadmapService,
    FinancialDataService,
    UINotificationService,
    RocketChatService,
    SocketService,
    ActivatedTaskFromComplianceService,
    BoardEvaluationService,
    OTPLoginService,
    EmissionCalculationService
  ],
})
export class AppComponent {
  isLogin: any = true;

  constructor(public authService: SocialAuthService, public router: Router) {
    var isLoginFromAuth = localStorage.getItem('isAuthLogin');
    if (isLoginFromAuth == 'yes') {
      this.authService.authState.subscribe((resData) => {
        this.isLogin = resData != null;
      });
      setTimeout(() => {
        if (!this.isLogin) {
          localStorage.clear();
          this.router.navigate(['login']);
        }
      }, 1000 * 10);
    }
  }
}
