import { Component, OnInit } from '@angular/core';
import { FinancialDataService } from './../../../services/financialData.service';

import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PermissionLinks } from '../../../enums/permissionLinks';
import { CompanyMasterService } from '../../../services/companymaster.service';

declare var $: any;

@Component({
  selector: 'app-financial-results',
  templateUrl: './financialResults.component.html',
  styleUrls: ['./financialResults.component.css'],
})
export class FinancialResultsComponent implements OnInit {
  quarterlyPnLChart: Object;
  yearlyPnLChart: Object;
  financialQuarterlyResultData: any;
  financialYearlyResultData: any;
  balanceSheetData: any;
  companyFinancialYearFromMonthGlobel: any;
  balanceSheetDataCount = 0;
  financialDataStandalone = PermissionLinks.financialDataStandalone;
  financialDataConsolidated = PermissionLinks.financialDataConsolidated;
  isCompanyListed: any;
  isFinancialResults: any;
  constructor(
    private financialDataService: FinancialDataService,
    private modalService: NgbModal,
    private companyMasterService: CompanyMasterService
  ) { }

  ngOnInit() {
    // $("body").removeClass("dashboard-page");
    $('body').addClass('dashboard-page dark-theme');
    this.isCompanyListed = localStorage.getItem('isCompanyListed');
    this.isFinancialResults = localStorage.getItem('isFinancialResults');
    if (this.isCompanyListed != 'false' && this.isFinancialResults != 'false') {
      this.getQuarterlyTrend(this.financialDataStandalone);
    }
  }
  getQuarterlyTrend(financialDataType) {
    this.companyMasterService.getCompanyList().then(company => {
      try {
        //console.log("type",financialDataType);
        this.financialDataService.getLastFiveQuartersPnLResults().then(responseData => {
          //  
          //console.log("responseData",responseData);
          if (responseData.status == 0) {
            var count = 0;
            var self = this;
            if (responseData.financialResults != null && _.size(responseData.financialResults) > 0) {
              //  
              this.financialYearlyResultData = null;
              this.financialQuarterlyResultData = _.filter(responseData.financialResults);

              //console.log('financialQuarterlyResultData:', this.financialQuarterlyResultData);

              //---- consolidated and standalone conditions
              this.financialQuarterlyResultData.forEach(element => {
                _.remove(element.financialData, function (a: any) {
                  if (financialDataType == self.financialDataStandalone) {
                    return a.Type == "C"
                  }
                  else if (financialDataType == self.financialDataConsolidated) {
                    return a.Type == "S"
                  }
                });
              });

              var quaterLabel = [];
              var patData = [];
              var epsData = [];
              _.forEach(this.financialQuarterlyResultData, function (value) {
                //
                if (_.size(value.financialData[0])) {
                  patData.push(value.financialData[0].qr_PAT);
                  epsData.push(value.financialData[0].qr_EPSABS);
                } else {
                  patData.push(' ');
                  epsData.push(' ');
                }

                var financialQuater = value._id.toString();
                //console.log('financialQuater', financialQuater);
                var year = financialQuater.substr(0, 4);
                var quater = financialQuater.substr(4, 2);

                var companyFinancialYearFromyear;
                var companyFinancialYearFromMonth;

                if (company.status == 0) {
                  if (company.companyData.FinancialYearFrom) {
                    companyFinancialYearFromyear =
                      company.companyData.FinancialYearFrom;
                    var d = new Date(companyFinancialYearFromyear);
                    companyFinancialYearFromMonth = d.getMonth() + 1;
                    self.companyFinancialYearFromMonthGlobel =
                      companyFinancialYearFromMonth;
                  }
                }

                //console.log(
                //  'companyFinancialYearFromMonth',
                //  companyFinancialYearFromMonth
                //);

                if (companyFinancialYearFromMonth == 1) {
                  switch (quater) {
                    case '03':
                      quater = 'Q1';
                      break;
                    case '06':
                      quater = 'Q2';
                      break;
                    case '09':
                      quater = 'Q3';
                      break;
                    case '12':
                      quater = 'Q4';
                      break;
                    default:
                      break;
                  }
                } else if (companyFinancialYearFromMonth == 7) {
                  switch (quater) {
                    case '09':
                      quater = 'Q1';
                      break;
                    case '12':
                      quater = 'Q2';
                      break;
                    case '03':
                      quater = 'Q3';
                      //   year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q4';
                      //    year = (year - 1).toString();
                      break;
                    default:
                      break;
                  }
                } else {
                  switch (quater) {
                    case '03':
                      quater = 'Q4';
                      // year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q1';
                      break;
                    case '09':
                      quater = 'Q2';
                      break;
                    case '12':
                      quater = 'Q3';
                      break;
                    default:
                      break;
                  }
                }

                quaterLabel.push(quater + ' ' + year);
                value.FinancialQuater = quater + ' ' + year;
                // console.log('value', value);
                // quaterLabel.push(value._id);
                // console.log("financialQuater,year,quater", financialQuater, year, quater);
              });

              $('#standaloneAnnualTrend').removeClass('active');
              $('#consolidatedAnnualTrend').removeClass('active');
              $('#quarterlyTrendStandaloneAndConsolidated').addClass(
                'active'
              );
              $('#annualTrendStandaloneAndConsolidated').removeClass(
                'active'
              );

              if (financialDataType == self.financialDataStandalone) {
                $('.widget_sp').removeClass('active');
                $('#standaloneQuarterlyTrend').addClass('active');
                $('#consolidatedQuarterlyTrend').removeClass('active');
              } else if (
                financialDataType == self.financialDataConsolidated
              ) {
                $('.widget_sp').removeClass('active');
                $('#consolidatedQuarterlyTrend').addClass('active');
                $('#standaloneQuarterlyTrend').removeClass('active');
              }

              _.reverse(this.financialQuarterlyResultData);
              //
              this.quarterlyPnLChart = {
                chart: {
                  type: 'spline',
                  renderTo: 'quartelyDataSection',
                  zoomType: 'xy',
                  animation: {
                    enabled: false,
                  },
                },
                title: {
                  text: '',
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  title: {
                    text: false,
                  },
                },

                xAxis: {
                  categories: quaterLabel,
                  tickInterval: 1,
                },
                plotOptions: {
                  series: {
                    color: '#00ff00',
                  },
                },

                series: [
                  {
                    name: 'PAT',
                    data: patData,
                    color: '#00ff00',
                  },
                  {
                    name: 'EPS',
                    data: epsData,
                    color: '#37a7a5',
                  },
                ],
                pointStart: 0,
              };
              // console.log('quarterlyPnLChart',this.quarterlyPnLChart)
            }
          } else {
          }
          $('.widget_fr').removeClass('active');
          $('#annualTrend').removeClass('active');
          $('#quartelyTrend').addClass('active');
          $('#profitAndLossTab').addClass('active');
          $('#quartelyDataSection').css('display', 'block');
          $('#yearlyDataSection').css('display', 'none');
          $('#yearlyDataSectionCst').hide();
          $('#quartelyDataSectionCst').show();
        });
      } catch (error) { }
    });
  }

  getAnnualTrend(financialDataType) {
    try {
      this.financialDataService
        .getLastFiveYearsPnLResults()
        .then((responseData) => {
          //
          if (responseData.status == 0) {
            var count = 0;
            var self = this;
            if (
              responseData.financialResults != null &&
              _.size(responseData.financialResults) > 0
            ) {
              //
              this.financialQuarterlyResultData = null;
              this.financialYearlyResultData = _.filter(
                responseData.financialResults
              );
              this.financialYearlyResultData.forEach((element) => {
                _.remove(element.financialData, function (a: any) {
                  if (financialDataType == self.financialDataStandalone) {
                    return a.Type == 'C';
                  } else if (
                    financialDataType == self.financialDataConsolidated
                  ) {
                    return a.Type == 'S';
                  }
                });
              });

              var yearLabel = [];
              var patData = [];
              var epsData = [];
              _.forEach(this.financialYearlyResultData, function (value) {
                //
                if (_.size(value.financialData[0])) {
                  patData.push(value.financialData[0].pl_Profit_after_tax);
                  epsData.push(value.financialData[0].pl_Reported_EPS);
                } else {
                  patData.push(' ');
                  epsData.push(' ');
                }

                var financialQuater = value._id.toString();
                var year = financialQuater.substr(0, 4);
                var quater = financialQuater.substr(4, 2);

                if (self.companyFinancialYearFromMonthGlobel == 1) {
                  switch (quater) {
                    case '03':
                      quater = 'Q1';
                      break;
                    case '06':
                      quater = 'Q2';
                      break;
                    case '09':
                      quater = 'Q3';
                      break;
                    case '12':
                      quater = 'Q4';
                      break;
                    default:
                      break;
                  }

                  yearLabel.push('December ' + year);
                  value.FinancialQuater = 'December ' + year;
                } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                  switch (quater) {
                    case '09':
                      quater = 'Q1';
                      break;
                    case '12':
                      quater = 'Q2';
                      break;
                    case '03':
                      quater = 'Q3';
                      //   year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q4';
                      //    year = (year - 1).toString();
                      break;
                    default:
                      break;
                  }

                  yearLabel.push('June ' + year);
                  value.FinancialQuater = 'June ' + year;
                } else {
                  switch (quater) {
                    case '03':
                      quater = 'Q4';
                      // year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q1';
                      break;
                    case '09':
                      quater = 'Q2';
                      break;
                    case '12':
                      quater = 'Q3';
                      break;
                    default:
                      break;
                  }
                  yearLabel.push('March ' + year);
                  value.FinancialQuater = 'March ' + year;
                }

                // switch (quater) {
                //     case '03':
                //         quater = 'Q1';
                //         break;
                //     case '06':
                //         quater = 'Q2';
                //         break;
                //     case '09':
                //         quater = 'Q3';
                //         break;
                //     case '12':
                //         quater = 'Q4';
                //         break;
                //     default:
                //         break;
                // }

                // yearLabel.push('March ' + year);
                // value.FinancialQuater = 'March ' + year;
                // yearLabel.push(value._id);
              });

              if (financialDataType == self.financialDataStandalone) {
                $('.widget_sp').removeClass('active');
                $('#standaloneAnnualTrend').addClass('active');
              } else if (financialDataType == self.financialDataConsolidated) {
                $('.widget_sp').removeClass('active');
                $('#consolidatedAnnualTrend').addClass('active');
              }

              // console.log("this.financialYearlyResultData", this.financialYearlyResultData)

              _.reverse(this.financialYearlyResultData);
              //
              this.yearlyPnLChart = {
                chart: {
                  type: 'spline',
                  renderTo: 'yearlyDataSection',
                  zoomType: 'xy',
                  animation: {
                    enabled: false,
                  },
                },
                title: {
                  text: '',
                },
                credits: {
                  enabled: false,
                },
                yAxis: {
                  title: {
                    text: false,
                  },
                },

                xAxis: {
                  categories: yearLabel,
                  tickInterval: 1,
                },
                plotOptions: {
                  series: {
                    color: '#00ff00',
                  },
                },

                series: [
                  {
                    name: 'PAT',
                    data: patData,
                    color: '#00ff00',
                  },
                  {
                    name: 'EPS',
                    data: epsData,
                    color: '#37a7a5',
                  },
                ],
                pointStart: 0,
              };
            }
          } else {
          }
          $('.widget_fr').removeClass('active');

          $('#annualTrend').addClass('active');
          $('#quartelyDataSection').css('display', 'none');
          $('#yearlyDataSection').css('display', 'block');
          $('#yearlyDataSectionCst').show();
          $('#quartelyDataSectionCst').hide();
        });
    } catch (error) { }
  }

  getBalanceSheetData(financialDataType) {
    try {
      this.financialDataService
        .getLastFiveYearsBalanceSheetResults()
        .then((responseData) => {
          //
          var self = this;
          if (responseData.status == 0) {
            var count = 0;
            if (
              responseData.financialResults != null &&
              _.size(responseData.financialResults) > 0
            ) {
              //
              this.balanceSheetData = _.filter(responseData.financialResults);
              this.balanceSheetData.forEach((element) => {
                _.remove(element.financialData, function (a: any) {
                  if (financialDataType == self.financialDataStandalone) {
                    return a.Type == 'C';
                  } else if (
                    financialDataType == self.financialDataConsolidated
                  ) {
                    return a.Type == 'S';
                  }
                  // return a.Type == "C"
                });
              });

              this.balanceSheetData.forEach((element) => {
                var financialQuater = element._id.toString();
                var year = financialQuater.substr(0, 4);
                var quater = financialQuater.substr(4, 2);

                if (self.companyFinancialYearFromMonthGlobel == 1) {
                  switch (quater) {
                    case '03':
                      quater = 'Q1';
                      break;
                    case '06':
                      quater = 'Q2';
                      break;
                    case '09':
                      quater = 'Q3';
                      break;
                    case '12':
                      quater = 'Q4';
                      break;
                    default:
                      break;
                  }

                  // yearLabel.push('December ' + year);
                  element.FinancialQuater = 'December ' + year;
                } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                  switch (quater) {
                    case '09':
                      quater = 'Q1';
                      break;
                    case '12':
                      quater = 'Q2';
                      break;
                    case '03':
                      quater = 'Q3';
                      //   year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q4';
                      //    year = (year - 1).toString();
                      break;
                    default:
                      break;
                  }

                  // yearLabel.push('June ' + year);
                  element.FinancialQuater = 'June ' + year;
                } else {
                  switch (quater) {
                    case '03':
                      quater = 'Q4';
                      // year = (year - 1).toString();
                      break;
                    case '06':
                      quater = 'Q1';
                      break;
                    case '09':
                      quater = 'Q2';
                      break;
                    case '12':
                      quater = 'Q3';
                      break;
                    default:
                      break;
                  }
                  // yearLabel.push('March ' + year);
                  element.FinancialQuater = 'March ' + year;
                }
                // switch (quater) {
                //     case '03':
                //         quater = 'Q1';
                //         break;
                //     case '06':
                //         quater = 'Q2';
                //         break;
                //     case '09':
                //         quater = 'Q3';
                //         break;
                //     case '12':
                //         quater = 'Q4';
                //         break;
                //     default:
                //         break;
                // }

                // element.FinancialQuater = "March " + year;
              });
              this.balanceSheetDataCount = _.size(this.balanceSheetData);

              if (financialDataType == self.financialDataStandalone) {
                $('.widget_sp').removeClass('active');
                $('#standaloneBalanceSheet').addClass('active');
              } else if (financialDataType == self.financialDataConsolidated) {
                $('.widget_sp').removeClass('active');
                $('#consolidatedBalanceSheet').addClass('active');
              }

              _.reverse(this.balanceSheetData);
            }
          }
        });
    } catch (error) { }
  }

  tagToogle(tabName) {
    if (this.isCompanyListed != 'false' && this.isFinancialResults != 'false') {
      if (tabName == 'profitAndLossTab') {
        this.getQuarterlyTrend(this.financialDataStandalone);
      } else if (tabName == 'balanceSheetTab') {
        this.getBalanceSheetData(this.financialDataStandalone);
      }
    }
  }
}
