export enum PermissionLinks {
    dashboard = 'Dashboard',
    stockPrice = 'stockPrice',
    financialResults = 'financialResults',
    financialDataStandalone = 'Standalone',
    financialDataConsolidated = 'Consolidated',
    keyRatios = 'keyRatios',
    orgchart = 'orgchart',
    progressMeter = 'progressMeter',
    scoreMeter = 'scoreMeter',
    myTasks = 'myTasks',
    companyNews = 'companyNews',
    digitalAssisstanceMyTask = 'digitalAssisstanceMyTask',
    digitalAssisstanceTeamTask = 'digitalAssisstanceTeamTask',
    chat = 'chat',
    organizationChartBoardMemeber = 'organizationChartBoardMemeber',
    organizationChartExecutiveManagement = 'organizationChart-ExecutiveManagement',
    GuestSuperAdmin = 'GuestSuperAdmin',
    ICChecker = 'IC Checker',
    ICMaker = 'IC Maker',
    Admin = 'Admin',
    UPSIAdmin = 'UPSI Admin'
}