import { Component, OnInit, Input, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from './../../../services/dashboard.service';
import { UserMasterService } from './../../../services/user-master.service';
import { MeetingMasterService } from './../../../services/meetingMaster.service'
import { DataTableDirective } from 'angular-datatables';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs/Rx';
import { taskStatus } from '../../../enums/taskStatus'
import * as _ from 'lodash';

import { ActivatedTaskFromComplianceService } from './../../../services/activatedTaskFromCompliance.service'

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

  @Input() type: any;

  public taskList: Array<any>;
  public errorStatus: boolean;
  @ViewChildren(DataTableDirective)
  dtElement: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  selectedFromDate: any;
  selectedToDate: any;
  selectedAssignedById: any;
  assignToByIdList: any;
  taskStatusList: any;
  selectedtaskStatus: any;
  selectedInputMeetingType: any;
  isFromDateSelected: boolean = false;

  minDateForToDate: any;
  selectedProcessById: any;
  processByIdList: any;
  govevaProcessList: any;
  complianceProcessList: any;
  meetingTypeList: any;
  isMyTaskFetch: any;

  constructor(private dashboardService: DashboardService, public meetingService: MeetingMasterService, public activeModal: NgbActiveModal, private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService, private userMasterService: UserMasterService) { }

  ngOnInit() {
    this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      pageLength: 10,
      search: true,
      lengthChange: true,
      language: { //made changes for datatables for next previous button
        "paginate": {
          "first": "<<",
          "last": ">>",
          "next": ">",
          "previous": "<"
        }
      }
    };

    this.taskStatusList = taskStatus;
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let companyId = loginUsers.CompanyId;


    if (this.type.name != "Roadmap") {
      if (this.isMyTaskFetch != "false") {
        this.dashboardService.getActivatedProcessList(companyId).then(response => {
          if (!response.status) {
            this.processByIdList = response.processList.map(function (o) {
              return {
                'label': o.ProcessName,
                'value': o.ProcessID
              }
            })
          }
        }).catch(reason => console.log(reason));
      }
      this.meetingService.getMeetingTypeList(companyId).then(response => {

        if (!response.status) {
          this.meetingTypeList = response.data.map(function (o) {
            return {
              'label': o.MeetingType,
              'value': o._id
            }
          })
        }
      }).catch(reason => console.log(reason));
    }

    if (this.type.name == "Roadmap" && this.isMyTaskFetch != "false") {
      this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails({}).then(response => {
        let self = this;
        if (!response.status) {
          this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails({ 'dataType': "count" }).then(response => {
            if (!response.status) {
              let result = response.TaskList;

              if (_.size(result)) {
                self.processByIdList = result.map(function (o) {
                  return {
                    'label': o.ProcessName,
                    'value': o.ProcessId
                  }
                });
              }
            }

          }).catch(reason => console.log(reason))
          this.taskList = response.TaskList;
          this.assignToByIdList = response.TaskList.map(function (element) {
            return { "label": element.AssignedTo, "value": element.AssignedToId }
          })
          this.assignToByIdList = _.uniqBy(this.assignToByIdList, 'value');
          this.dtTrigger.next();
        }

      }, error => console.log(error)).catch(reason => console.log(reason))
    } else {
      if (companyId != "" && this.isMyTaskFetch != "false") {

        var ObjectToPass = {
          "CompanyID": companyId,
          "MeetingTypeId": null,
          "AssignedTo": null,
          "ProcessId": null,
          "FromDate": null,
          "ToDate": null,
          "TaskStatusId": null
        };


        this.activatedTaskFromComplianceService.getActivatedTasks(ObjectToPass).then(response => {
          if (response != undefined) {
            if (response.length > 0) {
              response = response.filter(g => g.StartDate != null && g.StartDate != undefined && g.EndDate != undefined && g.EndDate != null)
            }
          }
          if (response) {
            this.taskList = response;
            this.assignToByIdList = response.map(function (element) {
              return { "label": element.AssignedTo, "value": element.AssignedByID }
            })
            this.assignToByIdList = _.uniqBy(this.assignToByIdList, 'value');
            this.dtTrigger.next();
          } else {
            this.errorStatus = true;
            setTimeout(() => {
              this.dtTrigger.next();
            }, 100);

          }
        })
      }
    }
  }


  statusToShow(status) {
    var statusToReturn = ""

    switch (status) {
      case "Pending":
        statusToReturn = "Pending";
        break;
      case "Completed":
        statusToReturn = "Completed";
        break;
      case "DelayedCompletion":
        statusToReturn = "Delayed Completion";
        break;
      case "PartiallyCompleted":
        statusToReturn = "Partially Completed";
        break;
      case "NA":
        statusToReturn = "Not Applicable";
        break;
      case "Done":
        statusToReturn = "Done";
        break;
      case "Cancelled":
        statusToReturn = "Cancelled";
        break;
    }
    return statusToReturn;
  }

  searchFilter() {
    if (this.dtElement != undefined && this.dtElement != null) {
      this.dtElement.forEach((dtElement: DataTableDirective) => {
        if (dtElement.dtInstance != undefined && dtElement.dtInstance != null) {
          dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }
      });
    }
    var searchObject = {};
    if (this.type.name == "Roadmap" && this.isMyTaskFetch != "false") {
      var toDateToSearch = null;
      var fromDateToSearch = null;
      if (this.selectedToDate) {
        toDateToSearch = this.setFormatDate(this.setDate(this.selectedToDate));
      }
      if (this.selectedFromDate) {
        fromDateToSearch = this.setFormatDate(this.setDate(this.selectedFromDate));
      }

      searchObject = {
        'processId': this.selectedProcessById && this.selectedProcessById.value ? this.selectedProcessById.value : null,
        'toDate': toDateToSearch,
        'fromDate': fromDateToSearch,
        'assignedToId': this.selectedAssignedById && this.selectedAssignedById.value ? this.selectedAssignedById.value : null,
        'taskStatus': this.selectedtaskStatus && this.selectedtaskStatus.value ? this.selectedtaskStatus.value : null
      };
      this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails(searchObject).then(response => {
        this.taskList = response.TaskList;
        this.dtTrigger.next();
      })
    } else {


      var loginUsers = JSON.parse(localStorage.getItem("user"));
      let companyId = loginUsers.CompanyId;

      var taskstatusNumber;
      switch (this.selectedtaskStatus) {
        case "Pending":
          taskstatusNumber = 1
          break;
        case "Completed":
          taskstatusNumber = 2
          break;
        case "DelayedCompletion":
          taskstatusNumber = 3
          break;
        case "PartiallyCompleted":
          taskstatusNumber = 4
          break;
        case "NA":
          taskstatusNumber = 5
          break;
        case "Done":
          taskstatusNumber = 6
          break;
        case "Cancelled":
          taskstatusNumber = 7
          break;
      }

      if (taskstatusNumber == null || taskstatusNumber == undefined) {
        taskstatusNumber = null;
      }

      if (this.selectedInputMeetingType == null || this.selectedInputMeetingType == undefined) {
        this.selectedInputMeetingType = null;
      }

      var toDateToSearch = null;
      var fromDateToSearch = null;
      if (this.selectedToDate) {
        toDateToSearch = this.setDate(this.selectedToDate);
      }
      if (this.selectedFromDate) {
        fromDateToSearch = this.setDate(this.selectedFromDate);
      }


      if (companyId != "" && this.isMyTaskFetch != "false") {
        var ObjectToPass = {
          "CompanyID": companyId,
          "MeetingTypeId": this.selectedInputMeetingType,
          "AssignedTo": this.selectedAssignedById && this.selectedAssignedById.value ? this.selectedAssignedById.value : null,
          "ProcessId": this.selectedProcessById && this.selectedProcessById.value ? this.selectedProcessById.value : null,
          "FromDate": fromDateToSearch,
          "ToDate": toDateToSearch,
          "TaskStatusId": taskstatusNumber
        };

        this.activatedTaskFromComplianceService.getActivatedTasks(ObjectToPass).then(response => {
          if (response != undefined) {
            if (response.length > 0) {
              response = response.filter(g => g.StartDate != null && g.StartDate != undefined && g.EndDate != undefined && g.EndDate != null)
            }
          }

          if (response) {
            this.taskList = response;
            this.dtTrigger.next();
          } else {
            this.errorStatus = true;
          }
        })
      }
    }
  }

  setDate(date) {
    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }


  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
    } else {
      return null;
    }

  }

  selectedFromDateChange(c: any) {
    c.close();
    if (this.selectedFromDate != null && this.selectedFromDate != "") {
      this.minDateForToDate = this.selectedFromDate;
      this.selectedToDate = this.selectedFromDate;
      this.isFromDateSelected = true;
    } else {
      this.isFromDateSelected = false;
      this.selectedToDate = this.selectedFromDate;
    }
  }

  selectedToDateChange(r: any) {
    r.close();
    if (this.selectedToDate != "" && this.selectedToDate != null) {
    } else {
      if (this.selectedFromDate != null && this.selectedFromDate != "") {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }

  // reset search filter values
  clearFilterValues() {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.selectedProcessById = null;
    this.selectedtaskStatus = null;
    this.selectedAssignedById = null;
    this.selectedInputMeetingType = null;

    this.ngOnInit();
    this.isFromDateSelected = false;
    this.searchFilter();
  }

}
