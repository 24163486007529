<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>BIS Communication</strong>
    </span>
  </div>
  <ul ngbNav #bisCommNav="ngbNav" (navChange)="changeTab($event)">
    <!-- Task -->
    <li ngbNavItem="ngb-nav-task">
      <a ngbNavLink><b>Task</b></a>
      <ng-template ngbNavContent>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-1">
              <label>Select Option</label>
            </div>
            <div class="col-sm-3">
              <select
                class="form-control"
                [(ngModel)]="selectedTaskMode"
                (change)="taskModeSelectionChanged($event)"
              >
                <option value="TaskList">Task List</option>
                <option value="TaskDetails">Task Details</option>
              </select>
            </div>
          </div>
        </div>
        <div id="taskListContainer" style="display: none">
          <form [formGroup]="taskListForm" class="well">
            <div class="card-block watermark">
              <div class="row">
                <div class="col-md-1">
                  <label>CIN</label>
                </div>
                <div class="col-lg-2">
                  <input
                    type="text"
                    [formControl]="taskListForm.controls['CIN']"
                    class="form-control"
                    [value]="cin"
                    (change)="cin = getInputValueChange($event)"
                    style="padding: 6px"
                  />
                  <div
                    *ngIf="
                      taskListForm.controls['CIN'].hasError('required') &&
                      taskListForm.controls['CIN'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    CIN is required
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-1">
                  <label>Start Date</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                    <div class="col-xs-4">
                      <input
                        [formControl]="taskListForm.controls['StartBISDate']"
                        class="form-control"
                        [value]="selectedStartBISDate"
                        (change)="
                          selectedStartBISDate = getInputValueChange($event)
                        "
                        style="padding: 6px"
                        placeholder="dd-mm-yyyy"
                        name="dpStartDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        (click)="d.toggle()"
                      />
                    </div>
                    <button
                      class="input-group-addon"
                      (click)="d.toggle()"
                      type="button"
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                  </div>
                  <div
                    *ngIf="
                      taskListForm.controls['StartBISDate'].hasError(
                        'required'
                      ) && taskListForm.controls['StartBISDate'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    Start date is required
                  </div>
                </div>

                <div class="col-md-1">
                  <label>End Date</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                    <div class="col-xs-4">
                      <input
                        [formControl]="taskListForm.controls['EndBISDate']"
                        class="form-control"
                        [value]="selectedEndBISDate"
                        (change)="
                          selectedEndBISDate = getInputValueChange($event)
                        "
                        style="padding: 6px"
                        placeholder="dd-mm-yyyy"
                        name="dpenddate"
                        ngbDatepicker
                        #s="ngbDatepicker"
                        (click)="s.toggle()"
                      />
                    </div>
                    <button
                      class="input-group-addon"
                      (click)="s.toggle()"
                      type="button"
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                  </div>
                  <div
                    *ngIf="
                      taskListForm.controls['EndBISDate'].hasError(
                        'required'
                      ) && taskListForm.controls['EndBISDate'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    End date is required
                  </div>
                  <div
                    *ngIf="
                      error.isError &&
                      !taskListForm.controls['EndBISDate'].hasError('required')
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    {{ error.errorMessage }}
                  </div>
                </div>
              </div>
              <br />

              <div class="row" style="padding: 7px">
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!taskListForm.valid"
                      (click)="getAdditionalActionsList()"
                    >
                      Additional Actions
                    </button>
                  </div>
                </div>
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      [disabled]="!taskListForm.valid"
                      (click)="getComplianceList()"
                    >
                      Compliance Tasks
                    </button>
                  </div>
                </div>
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!taskListForm.valid"
                      (click)="getTimeBasedComplianceList()"
                    >
                      Time Based Compliances
                    </button>
                  </div>
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <strong> <label id="tableHeader"> </label></strong>
                    <div
                      *ngIf="invalidData"
                      style="float: left; color: #fb434a; z-index: 1"
                    >
                      No data found
                    </div>
                  </div>
                  <div class="table-responsive mb-2">
                    <div id="divComplianceTask" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div
                        class="container"
                        style="width: 2000px; height: 500px"
                      >
                        <table
                          class="table table-hover nowrap"
                          style="width: 2000px"
                        >
                          <thead>
                            <tr>
                              <th style="width: 100px">Task Id</th>
                              <th style="width: 200px">Task</th>
                              <th style="width: 100px">Task Status</th>
                              <th style="width: 100px">Section Number</th>
                              <th style="width: 100px">Start Date</th>
                              <th style="width: 100px">Due Date</th>
                              <th style="width: 100px">Owner</th>
                              <th style="width: 100px">Assigned To</th>
                              <th style="width: 100px">Attachment Id</th>
                              <th style="width: 100px">Compliance Id</th>
                              <th style="width: 100px">Escalation Date</th>
                              <th style="width: 100px">Escalation To</th>
                              <th style="width: 100px">File Name</th>
                              <th style="width: 100px">Active</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let compliance of compliancesList;
                                let j = index
                              "
                            >
                              <td>{{ compliance.TaskId }}</td>
                              <td>{{ compliance.Task }}</td>
                              <td>{{ compliance.TaskStatus }}</td>
                              <td>{{ compliance.SectionNumber }}</td>
                              <td>
                                {{ compliance.StartDate | date: 'dd-MM-y' }}
                              </td>
                              <td>
                                {{ compliance.DueDate | date: 'dd-MM-y' }}
                              </td>
                              <td>{{ compliance.Owner }}</td>
                              <td>{{ compliance.AssignedTo }}</td>
                              <td>{{ compliance.AttachmentId }}</td>
                              <td>{{ compliance.ComplianceId }}</td>
                              <td>
                                {{
                                  compliance.EscalationDate | date: 'dd-MM-y'
                                }}
                              </td>
                              <td>{{ compliance.EscalationTo }}</td>
                              <td>{{ compliance.FileName }}</td>
                              <td>{{ compliance.IsActive }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="divAdditionalActions" style="display:none">
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>

                      <table class="table table-hover nowrap" width="100%">
                        <thead>
                          <tr>
                            <th style="width:5%">Task Id </th>
                            <th style="width:20%">Task </th>
                            <th style="width:10%">Task Status </th>
                            <th style="width:15%">Event Name </th>
                            <th style="width:10%">Start Date </th>
                            <th style="width:10%">Due Date</th>
                            <th style="width:15%">Owner</th>
                            <th style="width:10%">Assigned To </th>
                            <th style="width:5%">Active</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let additionalAction of additionalActionsList;
                              let j = index
                            "
                          >
                            <td>{{ additionalAction.TaskId }}</td>
                            <td>{{ additionalAction.Task }}</td>
                            <td>{{ additionalAction.TaskStatus }}</td>
                            <td>{{ additionalAction.EventName }}</td>
                            <td>
                              {{ additionalAction.StartDate | date: 'dd-MM-y' }}
                            </td>
                            <td>
                              {{ additionalAction.DueDate | date: 'dd-MM-y' }}
                            </td>
                            <td>{{ additionalAction.Owner }}</td>
                            <td>{{ additionalAction.AssignedTo }}</td>
                            <td>{{ additionalAction.IsActive }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div id="divTimeBasedCompliance" style="display: none">
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <table class="table table-hover nowrap" width="100%">
                        <thead>
                          <tr>
                            <th style="width: 5%">Task Id</th>
                            <th style="width: 30%">Task</th>
                            <th style="width: 5%">Task Status</th>
                            <th style="width: 5%">Start Date</th>
                            <th style="width: 5%">Due Date</th>
                            <th style="width: 10%">Assigned To</th>
                            <th style="width: 5%">Active</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let timeBasedCompliance of timeBasedcompliancesList;
                              let j = index
                            "
                          >
                            <td>{{ timeBasedCompliance.TaskId }}</td>
                            <td>{{ timeBasedCompliance.Task }}</td>
                            <td>{{ timeBasedCompliance.TaskStatus }}</td>
                            <td>
                              {{
                                timeBasedCompliance.StartDate | date: 'dd-MM-y'
                              }}
                            </td>
                            <td>
                              {{
                                timeBasedCompliance.DueDate | date: 'dd-MM-y'
                              }}
                            </td>
                            <td>{{ timeBasedCompliance.AssignedTo }}</td>
                            <td>{{ timeBasedCompliance.IsActive }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="taskDetailContainer" style="display: none">
          <form [formGroup]="taskDetailForm" class="well">
            <div class="card-block watermark">
              <div class="row">
                <div class="col-md-1">
                  <label>Task ID</label>
                </div>
                <div class="col-lg-2">
                  <input
                    type="text"
                    [formControl]="taskDetailForm.controls['TaskID']"
                    [value]="taskId"
                    (change)="taskId = getInputValueChange($event)"
                    class="form-control"
                    style="padding: 6px"
                  />
                  <div
                    *ngIf="
                      taskDetailForm.controls['TaskID'].hasError('required') &&
                      taskDetailForm.controls['TaskID'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    Task Id is required.
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-1">
                  <label>Task Type</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                    <select
                      class="form-control"
                      [formControl]="taskDetailForm.controls['TaskType']"
                    >
                      <option value="COMPLIANCES">COMPLIANCES</option>
                      <option value="COMPLIANCES CALENDAR">
                        COMPLIANCES CALENDAR
                      </option>
                    </select>
                  </div>
                  <div
                    *ngIf="
                      taskDetailForm.controls['TaskType'].hasError(
                        'required'
                      ) && taskDetailForm.controls['TaskType'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    Please select Task type.
                  </div>
                </div>
              </div>
              <br />

              <div class="row" style="padding: 7px">
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!taskDetailForm.valid"
                      (click)="getTaskDetails()"
                    >
                      Task Details
                    </button>
                  </div>
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <strong>
                      <label id="taskDetailTableHeader"> </label
                    ></strong>
                    <div
                      *ngIf="invalidData"
                      style="float: left; color: #fb434a; z-index: 1"
                    >
                      No data found
                    </div>
                  </div>
                  <div class="table-responsive mb-2">
                    <div id="divTaskDetails" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div class="" style="width: 100%; height: 500px">
                        <table
                          class="table table-hover nowrap"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th style="width: 20%">TimeStamp</th>
                              <th style="width: 15%">Status</th>
                              <th style="width: 15%">Updated By</th>
                              <th style="width: 30%">Comment</th>
                              <th style="width: 20%">Attachment</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngIf="taskDetails">
                                {{ taskDetails.TimeStamp | date: 'dd-MM-y' }}
                              </td>
                              <td *ngIf="taskDetails">
                                {{ taskDetails.Status }}
                              </td>
                              <td *ngIf="taskDetails">
                                {{ taskDetails.UpdatedBy }}
                              </td>
                              <td *ngIf="taskDetails">
                                {{ taskDetails.Comment }}
                              </td>
                              <td *ngIf="taskDetails">
                                {{ taskDetails.Attachment }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </li>

    <!-- Meeting -->

    <li ngbNavItem="ngb-nav-meeting">
      <a ngbNavLink><b>Meeting</b></a>
      <ng-template ngbNavContent>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-1">
              <label>Select Option</label>
            </div>
            <div class="col-sm-3">
              <select
                class="form-control"
                [(ngModel)]="selectedMeetingType"
                (change)="meetingSelectionChanged($event)"
              >
                <option value="MeetingList">Meeting List</option>
                <option value="MeetingDetails">Meeting Details</option>
              </select>
            </div>
          </div>
        </div>

        <div id="meetingContainer" style="display: none">
          <form [formGroup]="meetingForm" class="well">
            <div class="card-block watermark">
              <div class="row">
                <div class="col-md-1">
                  <label>CIN</label>
                </div>
                <div class="col-lg-2">
                  <input type="text" [formControl]="meetingForm.controls['CINForMeeting']" [(ngModel)]="cinForMeeting" class="form-control"  style="padding:6px;">
                  <div *ngIf="meetingForm.controls['CINForMeeting'].hasError('required') && meetingForm.controls['CINForMeeting'].touched" style="color: #fb434a;font-size: 80%;z-index: 1;">
                    CIN is required
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-1">
                  <label>Start Date</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                    <div class="col-xs-4">
                      <input
                        [formControl]="meetingForm.controls['StartMeetingDate']"
                        class="form-control"
                        [value]="selectedStartMeetingDate"
                        (change)="
                          selectedStartMeetingDate = getInputValueChange($event)
                        "
                        style="padding: 6px"
                        placeholder="dd-mm-yyyy"
                        name="dpStartDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        (click)="d.toggle()"
                      />
                    </div>
                    <button
                      class="input-group-addon"
                      (click)="d.toggle()"
                      type="button"
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                  </div>
                  <div
                    *ngIf="
                      meetingForm.controls['StartMeetingDate'].hasError(
                        'required'
                      ) && meetingForm.controls['StartMeetingDate'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    Start date is required
                  </div>
                </div>

                <div class="col-md-1">
                  <label>End Date</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group row">
                    <div class="col-xs-4">
                      <input
                        [formControl]="meetingForm.controls['EndMeetingDate']"
                        class="form-control"
                        [value]="selectedEndMeetingDate"
                        (change)="
                          selectedEndMeetingDate = getInputValueChange($event)
                        "
                        style="padding: 6px"
                        placeholder="dd-mm-yyyy"
                        name="dpenddate"
                        ngbDatepicker
                        #s="ngbDatepicker"
                        (click)="s.toggle()"
                      />
                    </div>
                    <button
                      class="input-group-addon"
                      (click)="s.toggle()"
                      type="button"
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                  </div>
                  <div
                    *ngIf="
                      meetingForm.controls['EndMeetingDate'].hasError(
                        'required'
                      ) && meetingForm.controls['EndMeetingDate'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    End date is required
                  </div>
                  <div
                    *ngIf="
                      errorMeeting.isError &&
                      !meetingForm.controls['EndMeetingDate'].hasError(
                        'required'
                      )
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    {{ errorMeeting.errorMessage }}
                  </div>
                </div>
              </div>
              <br />

              <div class="row" style="padding: 7px">
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!meetingForm.valid"
                      (click)="getMeetingList()"
                    >
                      Meeting List
                    </button>
                  </div>
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <strong>
                      <label id="meetingListTableHeader"> </label
                    ></strong>
                    <div
                      *ngIf="invalidData"
                      style="float: left; color: #fb434a; z-index: 1"
                    >
                      No data found
                    </div>
                  </div>
                  <div class="table-responsive mb-2">
                    <div id="divMeetingList" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div class="" style="width: 100%; height: 500px">
                        <table
                          class="table table-hover nowrap"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th style="width: 15%">Meeting Id</th>
                              <th style="width: 15%">Meeting Type</th>
                              <th style="width: 30%">Meeting Title</th>
                              <th style="width: 20%">Meeting Number</th>
                              <th style="width: 10%">Start Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let meeting of meetingList; let j = index"
                            >
                              <td>{{ meeting.MeetingId }}</td>
                              <td>{{ meeting.MeetingType }}</td>
                              <td>{{ meeting.MeetingTitle }}</td>
                              <td>{{ meeting.MeetingNo }}</td>
                              <td>
                                {{
                                  meeting.MeetingStartDateTime | date: 'dd-MM-y'
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="meetingDetailsContainer" style="display: none">
          <form [formGroup]="meetingDetailForm" class="well">
            <div class="card-block watermark">
              <div class="row">
                <div class="col-md-1">
                  <label>Meeting ID</label>
                </div>
                <div class="col-lg-2">
                  <input
                    type="text"
                    [formControl]="meetingDetailForm.controls['MeetingID']"
                    [value]="meetingId"
                    (change)="meetingId = getInputValueChange($event)"
                    class="form-control"
                    style="padding: 6px"
                  />
                  <div
                    *ngIf="
                      meetingDetailForm.controls['MeetingID'].hasError(
                        'required'
                      ) && meetingDetailForm.controls['MeetingID'].touched
                    "
                    style="color: #fb434a; font-size: 80%; z-index: 1"
                  >
                    Meeting ID is required.
                  </div>
                </div>
              </div>
              <br />

              <div class="row" style="padding: 7px">
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!meetingDetailForm.valid"
                      (click)="getMeetingDetails()"
                    >
                      Meeting Details
                    </button>
                  </div>
                </div>
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!meetingDetailForm.valid"
                      (click)="getMeetingResolutionList()"
                    >
                      Meeting Resolution List
                    </button>
                  </div>
                </div>
                <div class="col-xs-4">
                  <div class="btn-group mr-2">
                    <button
                      class="btn btn-sm btn-primary"
                      type="button"
                      [disabled]="!meetingDetailForm.valid"
                      (click)="getMeetingActionPointList()"
                    >
                      Meeting Action Point List
                    </button>
                  </div>
                </div>
              </div>
              <br /><br />
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <strong>
                      <label id="meetingDetailsTableHeader"> </label
                    ></strong>
                    <div
                      *ngIf="invalidData"
                      style="float: left; color: #fb434a; z-index: 1"
                    >
                      No data found
                    </div>
                  </div>
                  <div class="table-responsive mb-2">
                    <div id="divMeetingDetailList" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div class="" style="width: 1500px">
                        <table
                          class="table table-hover nowrap"
                          style="width: 1500px"
                        >
                          <thead>
                            <tr>
                              <th style="width: 300px">Meeting Members</th>
                              <th style="width: 150px">Start Time</th>
                              <th style="width: 150px">End Time</th>
                              <th style="width: 300px">Venu</th>
                              <th style="width: 105px">Meeting Notice Id</th>
                              <th style="width: 123px; text-align: center">
                                Minutes Of MeetingId
                              </th>
                              <th style="width: 118px; text-align: center">
                                Meeting Agenda Id
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td *ngIf="meetingDetails">
                                <span
                                  *ngFor="
                                    let members of meetingDetails.MeetingMembers;
                                    let i = index
                                  "
                                >
                                  {{ members
                                  }}<span
                                    *ngIf="
                                      meetingDetails.MeetingMembers?.length !=
                                      i + 1
                                    "
                                    >,
                                  </span>
                                </span>
                              </td>
                              <td *ngIf="meetingDetails">
                                {{
                                  meetingDetails.MeetingStartDateTime
                                    | date: 'dd-MM-y'
                                }}
                              </td>
                              <td *ngIf="meetingDetails">
                                {{
                                  meetingDetails.MeetingEndDateTime
                                    | date: 'dd-MM-y'
                                }}
                              </td>
                              <td *ngIf="meetingDetails">
                                {{ meetingDetails.Venue }}
                              </td>
                              <td
                                *ngIf="meetingDetails"
                                style="text-align: center"
                              >
                                {{ meetingDetails.MeetingNoticeId }}
                              </td>
                              <td
                                *ngIf="meetingDetails"
                                style="text-align: center"
                              >
                                {{ meetingDetails.MinutesOfMeetingId }}
                              </td>
                              <td
                                *ngIf="meetingDetails"
                                style="text-align: center"
                              >
                                {{ meetingDetails.MeetingAgendaId }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div id="divMeetingResolutionList" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div class="" style="width: 100%; height: 500px">
                        <table
                          class="table table-hover nowrap"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th style="width: 30%">Agenda Item</th>
                              <th style="width: 25%">Resolution Title</th>
                              <th style="width: 25%">Key Resolution</th>
                              <th style="width: 20%">Attachment Id</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let meeting of meetingResolutionList;
                                let j = index
                              "
                            >
                              <td>{{ meeting.AgendaItem }}</td>
                              <td>{{ meeting.ResolutionTitle }}</td>
                              <td>{{ meeting.KeyResolution }}</td>
                              <td>{{ meeting.AttachmentId }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div id="divMeetingActionPointList" style="display: none">
                      <!-- showing loader code -->
                      <div class="cat__app-loader center" *ngIf="loading">
                        <div></div>
                      </div>
                      <div class="" style="width: 100%; height: 500px">
                        <table
                          class="table table-hover nowrap"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th style="width: 15%">Action Point</th>
                              <th style="width: 15%">Assigned To</th>
                              <th style="width: 15%">Other Person</th>
                              <th style="width: 20%">Email</th>
                              <th style="width: 10%">Due Date</th>
                              <th style="width: 10%">Completion Date</th>
                              <th style="width: 15%">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let meeting of meetingActionPointList;
                                let j = index
                              "
                            >
                              <td>{{ meeting.ActionPoint }}</td>
                              <td>{{ meeting.AssignedTo }}</td>
                              <td>{{ meeting.OtherPerson }}</td>
                              <td>{{ meeting.Email }}</td>
                              <td>{{ meeting.DueDate | date: 'dd-MM-y' }}</td>
                              <td>
                                {{ meeting.CompletionDate | date: 'dd-MM-y' }}
                              </td>
                              <td>{{ meeting.Status }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </li>

    <!-- Notification -->
    <li ngbNavItem="ngb-nav-notification">
      <a ngbNavLink><b>Notification</b></a>
      <ng-template ngbNavContent>
        <form [formGroup]="notificationForm" class="well">
          <div class="card-block watermark">
            <div class="row">
              <div class="col-md-1">
                <label>CIN</label>
              </div>
              <div class="col-lg-2">
                <input
                  type="text"
                  [formControl]="
                    notificationForm.controls['CINForNotification']
                  "
                  class="form-control"
                  [value]="cinForNotification"
                  (change)="cinForNotification = getInputValueChange($event)"
                  style="padding: 6px"
                />
                <div
                  *ngIf="
                    notificationForm.controls['CINForNotification'].hasError(
                      'required'
                    ) && notificationForm.controls['CINForNotification'].touched
                  "
                  style="color: #fb434a; font-size: 80%; z-index: 1"
                >
                  CIN is required.
                </div>
              </div>
            </div>
            <br />

            <div class="row" style="padding: 7px">
              <div class="col-xs-4">
                <div class="btn-group mr-2">
                  <button
                    class="btn btn-sm btn-primary"
                    type="button"
                    [disabled]="!notificationForm.valid"
                    (click)="getNotificationList()"
                  >
                    Notification List
                  </button>
                </div>
              </div>
              <div class="col-xs-4">
                <div class="btn-group mr-2">
                  <button
                    class="btn btn-sm btn-primary"
                    type="button"
                    [disabled]="!notificationForm.valid"
                    (click)="getNotificationDetailList()"
                  >
                    Notification Detail List
                  </button>
                </div>
              </div>
            </div>
            <br /><br />
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <strong>
                    <label id="notificationTableHeader"> </label
                  ></strong>
                  <div
                    *ngIf="invalidData"
                    style="float: left; color: #fb434a; z-index: 1"
                  >
                    No data found
                  </div>
                </div>
                <div class="table-responsive mb-2">
                  <div id="divNotificationList" style="display: none">
                    <!-- showing loader code -->
                    <div class="cat__app-loader center" *ngIf="loading">
                      <div></div>
                    </div>
                    <div class="" style="width: 100%; height: 500px">
                      <table
                        class="table table-hover nowrap"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th style="width: 100%">Notification Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let notification of notificationList;
                              let j = index
                            "
                          >
                            <td>{{ notification.NotificationTitle }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div id="divNotificationDetailList" style="display: none">
                    <!-- showing loader code -->
                    <div class="cat__app-loader center" *ngIf="loading">
                      <div></div>
                    </div>
                    <div class="" style="width: 100%; height: 500px">
                      <table
                        class="table table-hover nowrap"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th style="width: 40%">Notification Title</th>
                            <th style="width: 30%">Created By</th>
                            <th style="width: 30%">Created On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let notification of notificationDetails;
                              let j = index
                            "
                          >
                            <td>{{ notification.NotificationTitle }}</td>
                            <td>{{ notification.CreatedBy }}</td>
                            <td>
                              {{ notification.CreatedOn | date: 'dd-MM-y' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="bisCommNav"></div>
</section>
