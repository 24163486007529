<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table table-hover compact cell-border nowrap stripe" *ngIf="notRegularMeeting">
        <thead>
          <tr>
            <th>Document Type</th>
            <th>Date</th>
            <th>Time</th>
            <th colspan="2">File Details</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="isDodumentObjectEmpty">
            <td colspan="5" class="text-center">
              No Records Found.
            </td>
          </tr>
          <tr *ngIf="!isDodumentObjectEmpty">
            <td>Notice</td>
            <td>{{ noticeDate }}</td>
            <td>{{ noticeTime }}</td>
            <td>{{ noticeFileName }}</td>
            <td *ngIf="noticeFileType == 'PDF' || noticeFileType == 'pdf' ">
              <button *ngIf="isNoticeFileAvailable" (click)="downloadDocumentFile(documentObject.noticeFile.docId,noticeFileType)">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="noticeFileType != 'PDF' && noticeFileType != 'pdf' ">
              <button *ngIf="isNoticeFileAvailable" (click)="downloadDocumentFile(documentObject.noticeFile.docId,noticeFileType)">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
          <tr *ngIf="!isDodumentObjectEmpty">
            <td>Agenda</td>
            <td>{{ agendaDate }}</td>
            <td>{{ agendaTime }}</td>
            <td>{{ agendaFileName }}</td>
            <td *ngIf="agendaFileType == 'PDF' || agendaFileType == 'pdf' ">
              <button *ngIf="isAgendaFileAvailable" (click)="downloadDocumentFile(documentObject.agendaFile.docId,agendaFileType)">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="agendaFileType != 'PDF' && agendaFileType != 'pdf' ">
              <button *ngIf="isAgendaFileAvailable" (click)="downloadDocumentFile(documentObject.agendaFile.docId,agendaFileType)">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
          <tr *ngFor="let agenda of documentObject.agendaFileArray" >
            <td>Agenda</td>
            <td class="date">{{ agenda.UploadDate | date:"shortDate"  }}</td>
            <td class="date">{{ agenda.UploadDate | date:"shortTime"  }}</td>                        
            <td>{{ agenda.name }}</td>
            <td *ngIf="agenda.name.substr(-3) == 'PDF' || agenda.name.substr(-3) == 'pdf' " class="eye-btn">
              <button (click)="downloadDocumentFile(agenda.docId,agenda.name.substr(-3))">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="agenda.name.substr(-3) != 'PDF' && agenda.name.substr(-3) != 'pdf' " class="eye-btn">
              <button (click)="downloadDocumentFile(agenda.docId,agenda.name.substr(-3))">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
        </tr>           
          <tr *ngIf="!isDodumentObjectEmpty">
            <td>Draft Minutes</td>
            <td>{{ draftDate }}</td>
            <td>{{ draftTime }}</td>
            <td>{{ draftFileName }}</td>
            <td *ngIf="draftFileType == 'PDF' || draftFileType == 'pdf' ">
              <button *ngIf="isDraftFileAvailable" (click)="downloadDocumentFile(documentObject.draftFile.docId,draftFileType)">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="draftFileType != 'PDF' && draftFileType != 'pdf' ">
              <button *ngIf="isDraftFileAvailable" (click)="downloadDocumentFile(documentObject.draftFile.docId,draftFileType)">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
          <tr *ngIf="!isDodumentObjectEmpty">
            <td>Final Minutes</td>
            <td>{{ finalDate }}</td>
            <td>{{ finalTime }}</td>
            <td>{{ finalFileName }}</td>
            <td *ngIf="finalFiletype == 'PDF' || finalFiletype == 'pdf' ">
              <button *ngIf="isFinalFileAvailable" (click)="downloadDocumentFile(documentObject.finalFile.docId,finalFiletype)">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="finalFiletype != 'PDF' && finalFiletype != 'pdf' ">
              <button *ngIf="isFinalFileAvailable" (click)="downloadDocumentFile(documentObject.finalFile.docId,finalFiletype)">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
          <tr *ngIf="!isDodumentObjectEmpty">
            <td>ATR</td>
            <td></td>
            <td></td>
            <td>{{ ATRFileName }}</td>
            <td *ngIf="ATRFileType == 'PDF' || ATRFileType == 'pdf' ">
              <button *ngIf="isATRFileAvailable" (click)="downloadDocumentFile(documentObject.ATRFile.docId,ATRFileType)">
                <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
            <td *ngIf="ATRFileType != 'PDF' && ATRFileType != 'pdf' ">
              <button *ngIf="isATRFileAvailable" (click)="downloadDocumentFile(documentObject.ATRFile.docId,ATRFileType)">
                <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-hover compact cell-border nowrap stripe" *ngIf="!notRegularMeeting">
        <thead>
          <tr>
            <th colspan="2">File Details</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isDodumentObjectEmpty">
            <td colspan="5" class="text-center">
              No Records Found.
            </td>
          </tr>
          <tr *ngFor="let document of regularDocumentList">
            <td>{{document.originalFileName}}</td>
            <td>
                <button *ngIf="document.fileName" (click)="downloadRegularDocumentFile(document.fileName,document.originalFileName)">
                    <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
                  </button>
            </td>

          </tr>

        </tbody>
        </table>

    </div>
  </div>
</div>

<div *ngIf="iframeURL" class="modal fade" id="myModalForDocument" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width:1090px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabelcompliance">
          <i class="fa fa-plus"></i>{{title}}</h5>
        <button  aria-label="Close" class="close" data-dismiss="modal" type="button">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body" style="padding: 0px">

        
        <div style="text-align: center;">
          <iframe [src]="iframeURL" style="width:100%; height:500px;" frameborder="0"></iframe>
        </div>
      </div>
      <div class="modal-footer">
        <button  type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>