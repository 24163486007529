import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonFunction } from '../helpers/commonFunction';

@Injectable()
export class OTPLoginService {
  public userCredential: Array<any>;


  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private commonFunction: CommonFunction
  ) {
    this.http = new HttpClient(handler);
    this.userCredential = [];
  }

  //add new assessment
  login(loginModel): Promise<any> {
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    // //head('Content-Type', 'application/json');
    return this.http
      .post(environment.nonValidateUrl + 'user/login', loginModel)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  logOut(): Promise<any> {
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    // //head('Content-Type', 'application/json');
    return this.httpClient
      .get(environment.clientUrl + 'userMaster/logOut')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkCompanyExists(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + 'companyMaster/checkCompanyExists')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getResourcesForUserAuthorisationByUserId(userId): Promise<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
        'user/getResourcesForUserAuthorisation/' +
        userId
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  companyListForUserManagement(): Promise<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
        'companyMaster/companyListForUserManagement'
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getCompanyData(): Promise<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
        'companyMaster/companyListForUserManagement'
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  loginWithoutPassword(loginwithoutModel) {
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    head('Content-Type', 'application/json');
    let data = this.commonFunction.encrypt(JSON.stringify(loginwithoutModel))    
    return this.http.post(environment.nonValidateUrl + 'user/checkUserValidation', { encryptedToken: data })
    .map(res => res['data'] ? JSON.parse(this.commonFunction.decrypt(res['data'])) : res);
  }

  loginWithUniqueUserId(loginwithoutModel) {
    loginwithoutModel.oldUserID = JSON.parse(localStorage.getItem('user'))._id;
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    head('Content-Type', 'application/json');
    return this.http
      .post(
        environment.nonValidateUrl + 'user/loginWithUniqueUserId',
        loginwithoutModel
      )
      .pipe(map((res: Response) => res.json()));
  }

  //get the company ids by user email id
  getCompanyIdsByEmailId(emailId) {
    type ApiResponse = {
      [key: string]: any;
      message: string;
      status: number
    };

    return this.http.get(
      `${environment.nonValidateUrl}userMaster/getCompanyIdsByUserEmail/${emailId}`
    )
    .pipe(
      map((res: ApiResponse) => {
        res.companyIds = JSON.parse(this.commonFunction.decrypt(res.companyIds))
        return res;
      })
    )
  }

  //get the company ids by user email id
  getCompanyListByIds(companyIds) {
    let headers = {};
        let head = (key, header) => {headers[key] = header};
    // head('Content-Type', 'application/json');
    type ApiResponse = {
      [key: string]: any;
      message: string;
      status: number
    };

    return this.http.post(
      environment.nonValidateUrl + 'companyMaster/getCompanyListByIds',
      companyIds
    ).pipe(
      map((res: ApiResponse) => {
        res.companyList = JSON.parse(this.commonFunction.decrypt(res.companyList))
        console.log('res',res)
        return res;
      })
    )
  }

  loginForADUser(loginModel): Promise<any> {
    return this.http
      .post(environment.nonValidateUrl + 'adUser/login', loginModel)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    //console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  checkRoutesAuth(FnlUrlState): Promise<any> {
    var body = {
      url: FnlUrlState,
    };
    return this.httpClient
      .post(environment.clientUrl + 'user/routesAuth', body)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  submitEmail(loginOTPObject): Promise<any> {
    const url = environment.nonValidateUrl + 'user/generateOTPforLogin';
    return this.http
      .post(url, loginOTPObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  wrongOTPAttemptCheck(loginOTPObject): Promise<any> {
    const url =
      environment.nonValidateUrl + 'user/wrongOTPAttemptCheckForLogin';
    return this.http
      .post(url, loginOTPObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  setUserCredential(userCredential) {
    this.userCredential.push(userCredential);
  }

  getUserCredential() {
    return this.userCredential;
  }

  clearUserCredential() {
    this.userCredential = [];
  }

  submitOTP(userObject): Promise<any> {
    const url = environment.nonValidateUrl + "user/validateLoginWithOTPCode";
    return this.http.post(url, userObject).toPromise()
        .then((result) => {
            return Promise.resolve(JSON.parse(this.commonFunction.decrypt(result['response'], userObject.SecretCode)))
        })
        .catch(this.handleError)
}

    //get the user details ids by user email id
    getUserEmail(userDetailsObject): Promise<any> {
      let encryptedToken = this.commonFunction.encrypt(JSON.stringify(userDetailsObject))
      const url = environment.nonValidateUrl + "user/getUserEmail";
      return this.http.post(url, { encryptedToken }).toPromise()
          .then(response => {
              console.log("responseresponseresponse", response)
              return Promise.resolve(response)
          })
          .catch(this.handleError)
  }
}
