<div class="modal-body">
    <div class="row">
      <div class="table-responsive">
        <table class="table table-hover compact cell-border nowrap stripe">
          <thead>
            <tr>
              <th>Member Name</th>
              <th>Email Id</th>
              <th>Designation</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngIf="isMemberObjectEmpty">
                  <td colspan="4" class="text-center">
                      No Records Found.
                  </td>
              </tr>
            <tr *ngFor="let member of memberObject">
              <td>{{member.MemberName}}</td>
              <td>{{member.EmailId}}</td>
              <td>{{member.Designation}}</td>
              <td>
                <span *ngIf="member.MobileNo">                  
                <i class="fa fa-mobile" style="color:red"></i>
                : {{member.MobileNo}}{{ member.MobileNo ? ',' : " "}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>