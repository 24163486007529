<section class="mt-4 mb-5 agenda">
    <div class="container" style="padding: 0 50px;">
        <div class="row" *ngIf="isAgendaObjectEmpty">
            <div class="col-md-12 head">
                <div class="mr-auto">
                    <h4><img src="/assets/images/agenda-icon.png" alt="Agenda">Agenda</h4>
                    <h4>No Records Found.</h4>
                </div>
            </div>
        </div>
        <div *ngIf="!isAgendaObjectEmpty">
            <div class="row">
                <div class="col-md-12 head">
                    <div class="mr-auto">
                        <h4><img src="/assets/images/agenda-icon.png" alt="Agenda">Agenda<span>({{ count
                                }})</span>:</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 mt-4" *ngFor="let agenda of agendaObject; let i = index;">
                    <div (click)="clickFunc(agenda.AgendaConvertedDocxToPdf.DocumentManagementID, agenda.AgendaNumber); showAgendaDetail(agenda.AgendaNumber);" class="box" *ngIf="agenda.isPublished">
                        <div class="srNo">{{agenda.AgendaNumber}}</div>
                        <strong *ngIf="agenda.isPublished">
                            <h5 style="cursor: pointer;">{{agenda.AgendaTitle}}</h5>
                        </strong>
                        <div class="attach" *ngIf="agenda.isPublished">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- AgendaCompliance POPUP LIST -->
<div class="modal fade " id="agendasCompliancePopUp" style="padding:0;overflow-y:hidden" tabindex="-1" role="dialog"
    aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document" style="margin: 0;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="agendasCompliancePopUpLabel">
                    Agenda Compliances</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="datatable-card-block">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="margin-bottom-50">
                                <div class="table-responsive">
                                    <table datatable [dtOptions]="dtOptionsOfAgendaCompliancePopUp"
                                        [dtTrigger]="dtTriggerAgendaCompliancePopUp"
                                        class="table table-hover compact cell-border nowrap stripe"
                                        id="agendaCompliancePopUpTable" style="cursor: pointer;width:100%">
                                        <thead>
                                            <tr>
                                                <th>Task Name</th>
                                                <th>Task Description</th>
                                                <th>Global/Company Specific</th>
                                                <th>Group Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let agendaCompliance of agendaCompliances">
                                                <td style="white-space: normal;">{{agendaCompliance.TaskName}}</td>
                                                <td style="white-space: normal;">{{agendaCompliance.TaskDescription}}
                                                </td>
                                                <td>
                                                    <span *ngIf="agendaCompliance.IsGlobal">Global</span>
                                                    <span *ngIf="!agendaCompliance.IsGlobal">Company Specific</span>
                                                </td>
                                                <td>{{agendaCompliance.GroupName}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-sm" data-dismiss="modal">
                    <i class="fa fa-times"></i>Close
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade modal-size-medium" id="agendaPdfUploadDialog" tabindex="-1" role="dialog" data-backdrop="static"
    data-keyboard="false" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 60%;margin: auto;">
            <div class="modal-header">
                <h5 class="modal-title">
                    <i class="fa fa-upload"></i>
                    <span id="AgendaPdfUploadTitle"></span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body" style="border:1px solid #ccc;">
                <div class="col-md-12">
                    <div class="row collapse1">
                        <table class="table table-hover compact cell-border nowrap stripe" style="cursor: pointer;">
                            <thead>
                                <tr>
                                    <td style="border-top:0px !important;"> <span
                                            style="padding:10px 15px;background: #d8ebeb;font-weight: 600;letter-spacing: 1px;display: inline-block;">
                                            {{agendaGenerationNumber}} </span> </td>
                                </tr>
                                <tr>
                                    <th>Filename1111</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let agenda of AgendaFile; let count=index">
                                    <td tabindex="1" style="width: 70%;">
                                        {{agenda.PdfName}}
                                    </td>
                                    <td>
                                        <button type="button" class="btn-sm btn btn-warning"
                                            (click)="downloadDocumentFile(agenda.DocumentManagementID,agenda.FileType)">
                                            <span title="View"
                                                *ngIf="agenda.FileType == 'PDF' || agenda.FileType == 'pdf' "
                                                class="fa fa-eye"></span>
                                            <span title="Download"
                                                *ngIf="agenda.FileType != 'PDF' && agenda.FileType != 'pdf' "
                                                class="fa fa-download"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>