<div *ngIf="taskDetails">
  <div class="modal-body p-0">
    <div class="col-lg-12 taskName">
      <h4>Task Name:</h4>
      <p>{{taskDetails.TaskName}}</p>
    </div>
    <div class="content">
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-lg-3">
          <label>Category:</label>
        </div>
        <div class="col-lg-3">
          <p>{{taskDetails.CategoryName}}</p>
          <p *ngIf="taskDetails.PolicyType">{{taskDetails.PolicyType == 1 ? 'Statutory' : 'Internal'}}</p>
        </div>
        <div class="col-lg-3">
          <label>Process:</label>
        </div>
        <div class="col-lg-3">
          <p>{{taskDetails.ProcessName}}</p>
        </div>
      </div>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-lg-3" *ngIf="this.assignedUserName==undefined">
          <label>Start Date:</label>
        </div>
        <div class="col-lg-3" *ngIf="this.assignedUserName!=undefined">
          <label>Trigger Start Date:</label>
        </div>
        <!-- Roadmap task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName==undefined">
          <p>{{taskDetails.StartDate | date: 'dd-MM-yyyy'}}</p>
        </div>
        <!-- compliance task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName!=undefined">
          <p>{{taskDetails.TriggerStartDate | date: 'dd-MM-yyyy'}}</p>
        </div>
        <div class="col-lg-3" *ngIf="this.assignedUserName==undefined">
          <label>End Date:</label>
        </div>
        <div class="col-lg-3" *ngIf="this.assignedUserName!=undefined">
          <label>Due Date:</label>
        </div>
        <!-- Roadmap task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName==undefined">
          <p>{{taskDetails.EndDate| date: 'dd-MM-yyyy'}}</p>
        </div>
        <!-- compliance task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName!=undefined">
          <p>{{taskDetails.DueDate| date: 'dd-MM-yyyy'}}</p>
        </div>

      </div>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-lg-3">
          <label>Owner:</label>
        </div>
        <div class="col-lg-3">
          <p>{{taskDetails.Owner}}</p>
          <p *ngIf="taskDetails.ResponsibleUser">{{ResponsibleUser}}</p>
        </div>
        <div class="col-lg-3">
          <label>Assigned To:</label>
        </div>
        <!-- Roadmap task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName==undefined">
          <p>{{taskDetails.AssignedTo}}</p>
        </div>
        <!-- compliance task -->
        <div class="col-lg-3" *ngIf="this.assignedUserName!=undefined">
          <p> {{this.assignedUserName}}</p>
        </div>

      </div>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-lg-3">
          <label>Status:</label>
        </div>
        <div class="col-lg-3" *ngIf="taskId.calendarId == 'myTask'&&this.assignedUserName==undefined">
          <select class="form-control" [(ngModel)]="taskDetails.Status">
            <option value="Pending">Pending</option>
            <option value="PartiallyCompleted">Partially Completed</option>
            <option value="Completed">Completed</option>
            <option value="Done">Done</option>
            <option value="DelayedCompletion">Delayed Completion</option>
            <option value="Cancelled">Cancelled</option>
            <option value="NA">Not Applicable</option>
          </select>
        </div>

        <div class="col-lg-3" *ngIf="taskId.calendarId == 'myTask'&&this.assignedUserName!=undefined">
          <select class="form-control" [(ngModel)]="complianceTaskStatus" (change)="enableCompletionDate()">
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Delayed Completion">Delayed Completion</option>
            <option value="Partially Completed">Partially Completed</option>
            <option value="NA">NA</option>
            <option value="Done">Done</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        <!-- done by vedant -->
        <!-- governance task       -->
        <div class="col-lg-3" *ngIf="taskId.calendarId != 'myTask'&&this.assignedUserName==undefined">
          <select class="form-control" [(ngModel)]="taskDetails.Status" disabled>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Delayed Completion">Delayed Completion</option>
            <option value="Partially Completed">Partially Completed</option>
            <option value="NA">NA</option>
            <option value="Done">Done</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
        <!-- compliance task -->
        <div class="col-lg-3" *ngIf="taskId.calendarId != 'myTask'&&this.assignedUserName!=undefined">
          <select class="form-control" [(ngModel)]="complianceTaskStatus" disabled>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Delayed Completion">Delayed Completion</option>
            <option value="Partially Completed">Partially Completed</option>
            <option value="NA">NA</option>
            <option value="Done">Done</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>


        <div class="col-lg-3" *ngIf="showCompletionCalendar">
          <label>Completion Date</label>
        </div>
        <div class="col-lg-3" *ngIf="showCompletionCalendar">
          <div class="input-group">
            <div class="input-group" (click)="$event.stopPropagation()">
              <input class="form-control" [(ngModel)]="completionDate" style="padding:6px; z-index: 0;" placeholder="dd-mm-yyyy"
                [maxDate]="today()" ngbDatepicker #cmptlnDate="ngbDatepicker" (focus)="cmptlnDate.toggle();"
                (document:click)="cmptlnDate.close();" [ngModelOptions]="{standalone: true}" readonly="true">
              <button class="input-group-addon" (click)="cmptlnDate.toggle();" type="button">
                <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
              </button>
            </div>
          </div>
          <div *ngIf="showCompletionCalendar && !completionDate" class="form-control-error-custom">Completion date is
            required</div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-sm btn-primary" (click)="updateTaskStatus(taskDetails)" *ngIf="taskId.calendarId == 'myTask'"
      [disabled]="(showCompletionCalendar && !completionDate)">Update</button>
    <button class="btn btn-sm" (click)="activeModal.dismiss('dismissed')">Cancel</button>
  </div>
</div>
<div *ngIf="!taskDetails && errorValue">
  <div class="modal-header">
    <h5 class="modal-title">
      Task Details
    </h5>
    <button type="button" class="close" aria-label="Close">
      <span (click)="activeModal.dismiss('dismissed')">×</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h3>No details found for this task.</h3>
  </div>
  <div class="modal-footer text-center">
    <button class="btn btn-sm" (click)="activeModal.dismiss('dismissed')">Close</button>
  </div>
</div>