import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { SuperAdminSetupService } from '../services/superAdminSetup.service';
import { HttpClient } from '@angular/common/http';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-superAdmin-setup',
  templateUrl: './superAdminSetup.component.html',
  styleUrls: ['./superAdminSetup.component.css'],
})
export class SuperAdminSetupComponent implements OnInit, AfterViewInit {
  userMasterForm: FormGroup;
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  pattern = '^[a-zA-Z0-9][a-zA-Z0-9-_/ ]*[a-zA-Z]+[a-zA-Z0-9-_/ ]*$';
  CompanyId: any;
  CompanyName: any;
  public user: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private superAdminSetupService: SuperAdminSetupService,
    private http: HttpClient
  ) {
    this.userMasterForm = new FormGroup({
      FirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      LastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      EmailId: new FormControl('', [Validators.required, Validators.email]),
      MobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{8,15}'),
      ]),
    });
  }

  ngOnInit() {
    this.user = {
      FirstName: '',
      LastName: '',
      EmailId: '',
      DateOfBirth: null,
      MobileNumber: '',
      Roles: [],
      TypeOfUser: '',
      CompanyName: '',
      CompanyId: '',
      Experience: '',
      Remuneration: '',
      ReportsTo: '',
      Committees: [],
    };

    this.getCompanies();
  }

  ngAfterViewInit() {}

  getCompanies() {
    this.loginService.companyListForUserManagement().subscribe((companies) => {
      // @ts-ignore
      if (companies.status == 0) {
        // @ts-ignore
        this.CompanyId = companies.companyList[0]._id;
        // @ts-ignore
        this.CompanyName = companies.companyList[0].CompanyName;
      } else {
        this.router.navigate(['companySetup']);
      }
    });
  }

  addUserData() {
    this.user.FirstName = this.userMasterForm.get('FirstName').value;
    this.user.LastName = this.userMasterForm.get('LastName').value;
    this.user.MobileNumber = this.userMasterForm.get('MobileNumber').value;
    this.user.EmailId = this.userMasterForm.get('EmailId').value;
    this.user.CompanyName = this.CompanyName; //this.selectedCompany.label;
    this.user.CompanyId = this.CompanyId; //this.selectedCompany.value;
    this.user.TypeOfUser = 'SuperAdmin';
    var loginUsers = '';

    this.user.Experience = 5;
    this.user.Remuneration = 1000;
    this.user.ReportsTo = null;

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear() - 18;

    var DOBDate = { year: year, month: month, day: day };

    this.user.DateOfBirth = this.setDate(DOBDate);
    this.user.Title = '';
    this.user.Designation = 'SuperAdmin';
    this.user.AreaOfExpertise = 'Administration';
    this.user.PAN = 'ABCDE0007F';
    this.user.DIN = '';
    this.user.DirectorType = '';

    this.superAdminSetupService.createUser(this.user).then((response) => {
      if (response.status == 0) {
        $('#alertTitle').text('Success!');
        $('#alertTitle').css('color', 'black');
        $('#alertMessage').text('Super Admin setup completed successfully.');
        $('#alertMessage').css('color', 'black');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();

        // setTimeout(this.navigateToLogin.bind(this), 3000);
        setTimeout(this.navigateToLogin.bind(this), 3000);
      } else {
        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text('Failure!');
        $('#alertMessage').text(response.message);
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
        //NEED TO Deconfigure company from PSS also -- deleteCmpnyFromPSS
      }
      if (response.status == 0) {
        //console.log('Success!');
      } else {
        //console.log('Failure!');
      }
    });
  }

  setDate(date) {
    if (date != '' && date != null) {
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }

  navigateToLogin() {
    this.router.navigate(['login']);
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      (keyCode > 34 && keyCode < 41) ||
      keyCode == 16 ||
      keyCode == 46 ||
      keyCode == 9
    ) {
      if (
        ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) &&
        this.numList.indexOf(ev.key) == -1
      ) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      if ((ev.target.value + ev.key).length > 11) {
        ev.preventDefault();
      }
      //
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }
  }

  clearData() {}
}
