import { Component, OnInit, Input } from "@angular/core";
import { MeetingMasterService } from "../../../services/meetingMaster.service";

import * as _ from "lodash";
import * as moment from "moment";

import { environment } from "../../../../environments/environment";
@Component({
  selector: "app-meeting-generated-agenda",
  templateUrl: "./meeting-generated-agenda.component.html",
  styleUrls: ["./meeting-generated-agenda.component.css"],
  providers: [MeetingMasterService],
})
export class MeetingGeneratedAgendaComponent implements OnInit {
  generatedAgendaObjectNotEmpty: boolean;
  isgeneratedAgendaObjectEmpty: boolean;

  isDocxFileEnable: boolean = false;
  isPdfFileEnable: boolean = false;

  date: any;

  @Input("GeneratedAgendaObject") generatedAgendaObject: any;

  constructor(private meetingMasterService: MeetingMasterService) {}

  ngOnInit() {
    var self = this;
    //console.log("generatedAgendaObject", this.generatedAgendaObject)

    if (_.size(this.generatedAgendaObject)) {
      self.generatedAgendaObjectNotEmpty = true;
      this.isgeneratedAgendaObjectEmpty = false;
      this.date = moment(this.generatedAgendaObject[0].CreatedDate).format("DD-MM-YYYY");
      if (_.size(this.generatedAgendaObject[0].DocxFilePath)) {
        this.isDocxFileEnable = true;
      }
      if (_.size(this.generatedAgendaObject[0].PdfFilePath)) {
        this.isPdfFileEnable = true;
      }
      //console.log("date", this.date)
    } else {
      self.generatedAgendaObjectNotEmpty = false;
      this.isgeneratedAgendaObjectEmpty = true;
    }
  }

  downloadDocxDocumentFile(fileID) {
    this.meetingMasterService.getAttendanceFileDetails(fileID).then((meetingDetails) => {
      //console.log('########################################',meetingDetails);
      var split = meetingDetails.data.indexOf("/");
      var stringSize = _.size(meetingDetails.data);
      var splittedstring = "";
      if (split == 0) {
        splittedstring = meetingDetails.data.slice(1, stringSize);
      } else {
        splittedstring = meetingDetails.data;
      }
      //console.log("split",split,stringSize);
      //console.log("splittedstring",splittedstring);

      // window.open(environment.pssServerUrl + splittedstring)
      var url = environment.pssServerUrl + splittedstring;
      window.location.href = url;
    });

    //console.log("fileID", fileID)
  }

  downloadPdfDocumentFile(fileID) {
    this.meetingMasterService.getAttendanceFile(fileID);
    //console.log("fileID", fileID)
  }
}
