import { Component,OnInit} from '@angular/core';
import { RoadmapService } from '../../services/roadmap.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  assesMentQuestionsList: any;

  constructor(private RoadmapService: RoadmapService) { 
    
  }

  ngOnInit() {
    $("body").removeClass("dashboard-page");
    
  }

}
