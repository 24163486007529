import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable()
export class EmissionCalculationService {

  constructor(private http: HttpClient) { }
  /*------------ Emission Config Start ------------*/
  addEmissionConfig(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addEmissionConfig', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateEmissionConfig(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/updateEmissionConfig', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  getEmissionConfig(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getEmissionConfig',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  setEmissionConfig(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/setEmissionConfig', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }

  /*------------ Emission Config Over -------------*/
  /*------------ Air Freight Emission Start ------------*/
  addOwnedVesselsEmissionCalculation(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addOwnedVesselsEmissionDetails', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateOwnedVesselsEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateOwnedVesselsEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  deleteOwnedVesselsEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/deleteOwnedVesselsEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listOwnedVesselsEmissionCalculation(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/listOwnedVesselsEmissionDetails',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  /*------------ Air Freight Emission Over -------------*/

  /*------------ AG Air Freight Emission Start ------------*/

  addAirFreightEmissionCalculation(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addAirFreightEmissionDetails', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateAirFreightEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateAirFreightEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  deleteAirFreightEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/deleteAirFreightEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listAirFreightEmissionCalculation(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/listAirFreightEmissionDetails',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  /*------------ Air Freight Emission Over ------------*/

  /*------------ Owned Vehical Emission Start ------------*/

  addOwnedVehicalEmissionCalculation(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addOwnedVehicalEmissionDetails', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }

  listOwnedVehicalEmissionCalculation(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/listOwnedVehicalEmissionDetails',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  updateOwnedVehicalEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateOwnedVehicalEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  deleteOwnedVehicalEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/deleteOwnedVehicalEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  /*------------ Owned Vehical Emission End   ------------*/

  /*------------ Air Travel Emission Start ------------*/

  addAirTravelEmissionCalculation(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addAirTravelEmissionDetails', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateAirTravelEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateAirTravelEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  deleteAirTravelEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/deleteAirTravelEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listAirTravelEmissionCalculation(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/listAirTravelEmissionDetails',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  getSelectedCityDetails(city): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getSelectedCityDetails?city='+city)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  /*------------ Air Travel Emission End -------------*/

  addEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/addEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  updateEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  deleteEmissionCalculation(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/deleteEmissionDetails', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listEmissionCalculation(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/listEmissionDetails',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  selectedCityEmissionFactor(Country="",City=""): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/selectedCityEmissionFactor?Country='+Country+'&City='+City)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listCountry(searchValue): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getCountryList?searchValue='+searchValue,)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  listCity(searchValue,country=""): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/cityList?Country='+country+'&searchValue='+searchValue)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  allCityList(country=""): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/allCityList?Country='+country)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  autoCompleteDataEmissionCalculation(emissionData): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/autoCompleteDataEmissionDetails?searchValue='+emissionData.searchValue+'&city='+emissionData.city)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  getLstInsertedEmissionFactor(emissionData): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getLstInsertedEmissionFactor?searchValue='+emissionData.searchValue)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  /*------------ AgGrid Calculator API's -------------*/
  getCities(searchValue,country=""): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getCities?Country='+country+'&searchValue='+searchValue)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  getCountries(searchValue): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getCountries?searchValue='+searchValue)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  getAirFreightEmissionList(): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getAirFreightEmissionList',)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  addAirFreightEmissions(data): Promise<any> {
    console.log(data)
    return this.http.post(environment.clientUrl + 'emission/addAirFreightEmissions', (data))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateAirFreightEmissions(data): Promise<any> {
    console.log(data)
    return this.http.post(environment.clientUrl + 'emission/updateAirFreightEmissions', (data))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  removeAirFreightEmissions(data): Promise<any> {
    console.log(data)
    return this.http.post(environment.clientUrl + 'emission/removeAirFreightEmissions', (data))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }

  getOceanVesselType(searchValue): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getOceanVesselType?searchValue='+searchValue)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  getOceanFreightEmissionList(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getOceanFreightEmissionList',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  addOceanFreightEmissions(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addOceanFreightEmissions', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateOceanFreightEmissions(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateOceanFreightEmissions', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  removeOceanFreightEmissions(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/removeOceanFreightEmissions', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  getRoadVehicleType(searchValue): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getRoadVehicleType?searchValue='+searchValue)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  getRoadFreightEmissionList(): Promise<any> {
      return this.http.get(environment.clientUrl + 'emission/getRoadFreightEmissionList',)
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  addRoadFreightEmissions(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addRoadFreightEmissions', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateRoadFreightEmissions(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/updateRoadFreightEmissions', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }
  removeRoadFreightEmissions(emissionData): Promise<any> {
      return this.http.post(environment.clientUrl + 'emission/removeRoadFreightEmissions', (emissionData))
          .toPromise()
          .then(response => { return Promise.resolve(response); })
  }

  getElectricityEmissionList(): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/getElectricityEmissionList',)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  addElectricityEmissions(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/addElectricityEmissions', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  updateElectricityEmissions(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/updateElectricityEmissions', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }
  removeElectricityEmissions(emissionData): Promise<any> {
    return this.http.post(environment.clientUrl + 'emission/removeElectricityEmissions', (emissionData))
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }

  getCrossFilteredTCO2Summary(): Promise<any> {
    return this.http.get(environment.clientUrl + 'emission/charts/getCrossFilteredTCO2Summary',)
        .toPromise()
        .then(response => { return Promise.resolve(response); })
  }

}
