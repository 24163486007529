import { reject } from "lodash";
import { resolve } from "url";
import { baseUrl, getHeaders } from "../urls";

interface PersonalDataParams {
  callingData?: boolean;
}

export class Peoples {
  getPersonalData(params?: PersonalDataParams) {
    return new Promise((resolve, reject) => {
      fetch(baseUrl + "/v1/people/me", {
        method: "get",
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  fromString(searchString: string) {
    return new Promise((resolve, reject) => {
      let emailRegx = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+";

      let query = searchString.match(emailRegx)
        ? `/?email=${searchString}`
        : `/?displayName=${searchString}`;

      console.log("query", query);
      fetch(baseUrl + "/v1/people" + query, {
        method: "get",
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }
}
