<div class="modal-body">
  <div class="row">
    <div class="table-responsive">
      <table class="table table-hover compact cell-border nowrap stripe" *ngIf="generatedAgendaObjectNotEmpty">
        <thead>
          <tr>
            <th style="width:25%">Document Type</th>
            <th style="width:15%">Created Date</th>
            <th style="width:50%">File Details</th>
            <th style="width:10%" colspan="2"></th>
          </tr>
        </thead>



        <tbody>

            <tr *ngIf="isDocxFileEnable">
              <td style="width:25%">Consolidated Generated Docx File</td>
              <td style="width:15%">{{date}}</td>
              <td style="width:50%">{{generatedAgendaObject[0].DocxFilePath}}</td>
              <td style="width:10%">
                <button>
                  <i class="fa fa-download" style="font-size:15px" aria-hidden="true" (click)="downloadDocxDocumentFile(generatedAgendaObject[0].MeetingID)">&nbsp;&nbsp;Download</i>
                </button>
              </td>
            </tr>
            <tr *ngIf="isPdfFileEnable">
              <td style="width:25%">Consolidated Generated Pdf File</td>
              <td style="width:15%">{{date}}</td>
              <td style="width:50%">{{generatedAgendaObject[0].PdfFilePath}}</td>
              <td style="width:10%">
                <button>
                  <i class="fa fa-download" style="font-size:15px" aria-hidden="true" (click)="downloadPdfDocumentFile(generatedAgendaObject[0].PdfDocumentID)">&nbsp;&nbsp;Download</i>
                </button>
              </td>
            </tr>
        </tbody>
      </table>

      <table class="table table-hover compact cell-border nowrap stripe" *ngIf="!generatedAgendaObjectNotEmpty">
        <thead>
          <tr>
            <th>Document Type</th>
            <th>Created Date</th>
            <th>File Details</th>
            <th colspan="2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5" class="text-center">
              No Records Found.
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>