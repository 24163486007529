import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
// import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import { ChartModule } from 'angular2-highcharts';
// import * as Highcharts from 'highcharts';
import { AuthGuard } from '../../authGuard/auth.guard';
import { MeetingMasterService } from '../../services/meetingMaster.service';
import { WebexService } from '../../services/webex.service';
import { SharedModule } from './../../shared/sharedmodule';
import { AgendadetailComponent } from './agendadetail/agendadetail.component';
import { DashboardsAlpha } from './alpha.page';
import { WebexAttendingInpersonComponent } from './attending-inperson/attendinginperson.component';
import { DashboardsBeta } from './beta.page';
import { BulkUpdateTaskComponent } from './bulk-update-task/bulk-update-task.component';
import { CalendarTaskDetailsComponent } from './calendar-task-details/calendar-task-details.component';
import { CalendarTaskComponent } from './calendar-task/calendar-task.component';
import { ChatsComponent } from './chats/chats.component';
import { CompanyNewsComponent } from './company-news/company-news.component';
import { ComplianceAndRoadmapChartComponent } from './compliance-and-roadmap-chart/compliance-and-roadmap-chart.component';
import { DocumentdetailsComponent } from './documentdetails/documentdetails.component';
import { FinancialResultsComponent } from './financialResults/financialResults.component';
import { IndividualMeetingComponent } from './individual-meeting/individual-meeting.component';
import { KeyRatiosComponent } from './keyRatios/keyRatios.component';
import { MeetingAgendaAnalyticsComponent } from './meeting-agenda-analytics/meeting-agenda-analytics.component';
import { MeetingAssistentWrapperComponent } from './meeting-assistent-wrapper/meeting-assistent-wrapper.component';
import { MeetingGeneratedAgendaComponent } from './meeting-generated-agenda/meeting-generated-agenda.component';
import { MeetingViewComponent } from './meeting-view/meeting-view.component';
import { MeetingComponent } from './meeting/meeting.component';
import { MeetingagendaComponent } from './meetingagenda/meetingagenda.component';
import { MeetingagendacomplianceComponent } from './meetingagendacompliance/meetingagendacompliance.component';
import { MeetingAgendaViewComponent } from './meetingagendaView/meetingagenda.component';
import { MeetingattendanceComponent } from './meetingattendance/meetingattendance.component';
import { MeetingcategoryComponent } from './meetingcategory/meetingcategory.component';
import { MeetingcomplianceComponent } from './meetingcompliance/meetingcompliance.component';
import { MeetingdetailsComponent } from './meetingdetails/meetingdetails.component';
import { MeetingdirectorComponent } from './meetingdirector/meetingdirector.component';
import { MeetingdocumentComponent } from './meetingdocument/meetingdocument.component';
import { MeetingdocumentViewComponent } from './meetingdocumentView/meetingdocument.component';
import { MeetinginviteeComponent } from './meetinginvitee/meetinginvitee.component';
import { MeetingmemberComponent } from './meetingmember/meetingmember.component';
import { MytaskComponent } from './mytask/mytask.component';
import { NotificationComponent } from './notification/notification.component';
import { OrgchartComponent } from './orgchart/orgchart.component';
import { PictorialRoadmapComponent } from './pictorial-roadmap/pictorial-roadmap.component';
import { RegularMeetingModalComponent } from './regular-meeting-modal/regular-meeting-modal.component';
import { StockPriceComponent } from './stockPrice/stockPrice.component';
import { TaskListComponent } from './task-list/task-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';
// import { WebexComponent } from './webex/webex.component';
import { WebxChatComponent } from './webx-chat/webx-chat.component';
import { EnvironmentDataComponent } from './environment-data/environment-data.component';
import { SocialDataComponent } from './social-data/social-data.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ActivatedTaskFromComplianceService } from 'src/app/services/activatedTaskFromCompliance.service';
import { DashboardMyTaskService } from 'src/app/services/dashboardmytask.service';
import { ChartsService } from 'src/app/services/charts.service';
import { WebexComponent } from './webex/webex.component';
//import { EnvironmentDataComponent } from './environment-data/environment-data.component';
//import { EnvironmentDataComponent } from './environment-data/environment-data.component';

// declare var require: any;

// require('highcharts/highcharts-more')(Highcharts);
// require('highcharts/modules/solid-gauge')(Highcharts);
// require('highcharts/modules/heatmap')(Highcharts);
// require('highcharts/modules/treemap')(Highcharts);
// require('highcharts/modules/funnel')(Highcharts);
// require('highcharts/modules/stock')(Highcharts);
// Highcharts.setOptions({ lang: { thousandsSep: ',' } });

let chartHolder;

export const routes: Routes = [
  {
    path: 'dashboards/alpha',
    canActivate: [AuthGuard],
    component: DashboardsAlpha,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/stockPrice',
    canActivate: [AuthGuard],
    component: StockPriceComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/financialResults',
    canActivate: [AuthGuard],
    component: FinancialResultsComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/keyRatios',
    canActivate: [AuthGuard],
    component: KeyRatiosComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/orgchart',
    canActivate: [AuthGuard],
    component: OrgchartComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/meetingdocument',
    canActivate: [AuthGuard],
    component: MeetingdocumentComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/alpha/meetingattendance',
    canActivate: [AuthGuard],
    component: MeetingattendanceComponent,
    outlet: 'structure-outlet',
  },
  {
    path: 'dashboards/beta',
    component: DashboardsBeta,
    outlet: 'structure-outlet',
  },
  {
    path: 'meeting-view/:meetingId',
    canActivate: [AuthGuard],
    component: MeetingViewComponent,
  },
  {
    path: 'attending-inperson/:id',
    canActivate: [AuthGuard],
    component: WebexAttendingInpersonComponent,
  },
  {
    path: 'webex/:meetingId',
    canActivate: [AuthGuard],
    component: WebexComponent,
  },
];

@NgModule({
  exports: [WebxChatComponent, WebexAttendingInpersonComponent],
  imports: [
    CommonModule,
    DataTablesModule,
    ChartModule.forRoot(
      require('highcharts')
    ),
    ChartModule.forRoot(
      require('highcharts/highstock')
    ),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    NgbModule,
    RouterModule.forChild(routes),
    AgGridModule,
    AgChartsAngularModule,
  ],
  declarations: [
    WebexComponent,
    WebxChatComponent,
    DashboardsAlpha,
    DashboardsBeta,
    TaskListComponent,
    CompanyNewsComponent,
    MytaskComponent,
    ComplianceAndRoadmapChartComponent,
    CalendarTaskComponent,
    StockPriceComponent,
    FinancialResultsComponent,
    KeyRatiosComponent,
    CalendarTaskDetailsComponent,
    ChatsComponent,
    NotificationComponent,
    OrgchartComponent,
    MeetingComponent,
    MeetingdetailsComponent,
    MeetingagendaComponent,
    MeetingcomplianceComponent,
    MeetingdirectorComponent,
    MeetinginviteeComponent,
    MeetingmemberComponent,
    MeetingagendacomplianceComponent,
    MeetingattendanceComponent,
    MeetingdocumentComponent,
    MeetingcategoryComponent,
    BulkUpdateTaskComponent,
    PictorialRoadmapComponent,
    MeetingGeneratedAgendaComponent,
    MeetingViewComponent,
    MeetingdocumentViewComponent,
    MeetingAgendaViewComponent,
    RegularMeetingModalComponent,
    // WebexComponent,
    WebexAttendingInpersonComponent,
    MeetingAssistentWrapperComponent,
    IndividualMeetingComponent,
    MeetingAgendaAnalyticsComponent,
    DocumentdetailsComponent,
    AgendadetailComponent,
    EnvironmentDataComponent,
    SocialDataComponent,
    //EnvironmentDataComponent,
    //EnvironmentDataComponent,
  
  ],
  providers: [
    AuthGuard,
    DashboardService,
    ActivatedTaskFromComplianceService,
    DashboardMyTaskService,
    MeetingMasterService,
    WebexService,
    ChartsService,
    NgbActiveModal,
    MeetingMasterService,
    WebexService,
  ],
  entryComponents: [
    TaskListComponent,
    CalendarTaskDetailsComponent,
    MeetingdetailsComponent,
    MeetingagendacomplianceComponent,
    NotificationComponent,
    BulkUpdateTaskComponent,
    PictorialRoadmapComponent,
    RegularMeetingModalComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class DashboardsModule {
  constructor() {}
}
