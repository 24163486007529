import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CommonFunction {
  FileExtension: any;

  secretKey = 'Secret message';
  constructor() {}

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key].toLowerCase();
      var y = b[key].toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  setDate(date) {
    if (date != '' && date != null) {
      //return date.day + "-" + date.month + "-" + date.year;
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
    }
    return null;
  }

  //format number with commas
  formatToCommaSeperatedNumber(value) {
    if (value != null) {
      value = value.toString();
      if (value == null || value == '' || value == undefined) {
        return '';
      }

      var valArray = value.split('.') || [];

      for (var i = 0; i < valArray.length; ++i) {
        valArray[i] = valArray[i].replace(/[^\d.-]/g, '');
      }

      var newVal;

      if (valArray.length === 0) {
        newVal = '';
      } else {
        let matches = valArray[0].match(/[0-9]{3}/gim);

        if (matches !== null && valArray[0].length > 3) {
          let commaGroups = Array.from(
            Array.from(valArray[0])
              .reverse()
              .join('')
              .match(/[0-9]{3}/gim)
              .join()
          )
            .reverse()
            .join('');
          let replacement = valArray[0].replace(
            commaGroups.replace(/[^\d.-]/g, ''),
            ''
          );

          if (parseInt(valArray[0]) > 0) {
            newVal =
              (replacement.length > 0 ? replacement + ',' : '') + commaGroups;
          } else {
            newVal = (replacement.length > 0 ? replacement : '') + commaGroups;
          }
        } else {
          newVal = valArray[0];
        }

        if (valArray.length > 1) {
          newVal += '.' + valArray[1].substring(0, 2);
        }
      }

      return newVal;
    }
  }

  removeDataTableSaveStateKey(tableID) {
    var dataTablekey = 'DataTables_' + tableID + '_/';
    sessionStorage.removeItem(dataTablekey);
  }

  allExtensions() {
    return [
      'csv',
      'xlsx',
      'xls',
      'jpg',
      'jpeg',
      'png',
      'doc',
      'docx',
      'txt',
      'pdf',
      'ppt',
      'pptx',
      'mp4',
      'apk',
      'bat',
      'bin',
      'cgi',
      'com',
      'exe',
      'jar',
      'py',
      'wsf',
      'aif',
      'cda',
      'iff',
      'mid',
      'midi',
      'mp3',
      'mpa',
      'wav',
      'wma',
      'wpl',
      'avi',
      'flv',
      'h264',
      'm4v',
      'mkv',
      'mov',
      'mpg',
      'mpeg',
      'rm',
      'swf',
      'vob',
      'wmv',
      '3g2',
      '3gp',
      'odt',
      'msg',
      'rtf',
      'tex',
      'wks',
      'wps',
      'wpd',
      'ods',
      'xlr',
      'key',
      'odp',
      'pps',
      'accdb',
      'dat',
      'db',
      'dbf',
      'log',
      'mdb',
      'pdb',
      'sav',
      'sql',
      'tar',
      'bak',
      'cab',
      'cfg',
      'cpl',
      'cur',
      'dll',
      'dmp',
      'drv',
      'icns',
      'ico',
      'ini',
      'lnk',
      'msi',
      'sys',
      'tmp',
      'asp',
      'aspx',
      'cer',
      'cfm',
      'cgi',
      'pl',
      'css',
      'htm',
      'html',
      'js',
      'jsp',
      'part',
      'php',
      'rss',
      'xhtml',
      'ai',
      'bmp',
      'gif',
      'ico',
      'max',
      'obj',
      'ps',
      'psd',
      'svg',
      'tif',
      'tiff',
      '3ds',
      '3dm',
    ];
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  //old code
  // checkFileExtension($event) {
  //     console.log('file changed', $event.target.files[0])
  //     var allowedExtensions = ["csv", "xlsx", "xls", "csv", "jpg", "jpeg", "png", "doc", "docx", "txt", "pdf", "ppt", "mp4"];
  //     var filename = $event.target.files[0].name;
  //     this.FileExtension = filename.toString().split('.').pop();
  //     console.log("filename", filename);
  //     console.log("FileExtension", this.FileExtension);

  //     if (this.isInArray(allowedExtensions, this.FileExtension)) {
  //         return true;
  //     } else {

  //         return false;

    //old code
    // checkFileExtension($event) {
    //     console.log('file changed', $event.target.files[0])
    //     var allowedExtensions = ["csv", "xlsx", "xls", "csv", "jpg", "jpeg", "png", "doc", "docx", "txt", "pdf", "ppt", "mp4"];
    //     var filename = $event.target.files[0].name;
    //     this.FileExtension = filename.toString().split('.').pop();
    //     console.log("filename", filename);
    //     console.log("FileExtension", this.FileExtension);

    //     if (this.isInArray(allowedExtensions, this.FileExtension)) {
    //         return true;
    //     } else {
            
    //         return false;
          
    //     }
    // }

    //new code for unrestricted file upload

    checkFileExtension($event) {
        var count=0;
        var self=this;
        console.log('file changed', $event.target.files[0])
        var allowedExtensions = ["csv", "xlsx", "xls", "jpg", "jpeg", "png", "doc", "docx", "txt", "pdf", "ppt","pptx", "mp4"];
        var filename = $event.target.files[0].name;
        //console.log("filename.split('.')",filename.split('.'));
       // console.log("filename.split('.')[1].toLowerCase();",filename.split('.')[1].toLowerCase());
       var checkFilesExtension=filename.toString().split('.').pop();
       console.log(" checkFilesExtension : ====>  ", checkFilesExtension )
         this.FileExtension = filename.split('.');
        console.log("  this.FileExtension ",  this.FileExtension )
         this.FileExtension.forEach(function(value, key) {
            console.log(key + ': ' + value);
            if(self.isInArray(self.allExtensions(), value))
            {
                count++; 
            }
          });

          console.log('count : ',count);
       
        if (count==1 && self.isInArray(allowedExtensions, checkFilesExtension)) {
            return true;
        }
        else {
            
            return false;
          
        }
     
      
    }
    
 
    encrypt(value : string) : string{
        return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }
    
    decrypt(textToDecrypt : string, secret = this.secretKey.trim()){
        return CryptoJS.AES.decrypt(textToDecrypt, secret).toString(CryptoJS.enc.Utf8);
    }
  }
