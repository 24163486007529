<div class="modal fade" id="socialModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document" style="max-width:1000px">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0;">
        <!-- START: forms/internal assets -->
        <section class="card financeResult">
            <button _ngcontent-c3="" aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span _ngcontent-c3="" aria-hidden="true">×</span>
            </button>
            <div class="nav-tabs-horizontal">
                <ul class="nav nav-tabs inverse" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" href="javascript: void(0);">Social Data</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="profitAndLossTab" role="tabcard" aria-expanded="true">
                        
                        <div class="card-block watermark">
                            <div id="quartelyDataSection" class=" trends">
                                <div class="row">
                                    <div class="col-sm-12"  width="100%" >
                                      
                                      <div class="card-block">
                                          <div id="soc-output"></div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: forms/internal assets -->

      </div>

    </div>

  </div>

</div>