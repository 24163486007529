<!-- START: components/progress-bars -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Progress Bars</strong>
            <a href="http://v4-alpha.getbootstrap.com/components/progress/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <h5 class="text-black"><strong>Default Progress Bars</strong></h5>
        <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/progress/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
        <div class="mb-5">
            <div class="mb-5">
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" style="width: 3%" aria-valuenow="3" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
        <h5 class="text-black"><strong>Striped Progress Bars</strong></h5>
        <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/progress/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
        <div class="mb-5">
            <div class="mb-5">
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped bg-info" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
        <h5 class="text-black"><strong>Striped and Animated Progress Bars</strong></h5>
        <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/progress/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
        <div class="mb-5">
            <div class="mb-5">
                <div class="progress mb-2">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style="width: 40%"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/progress-bars -->