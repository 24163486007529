<section>
  <div>
      <div class="inner-wrap">
          <p class="text-center">
              <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
          </p>
          <form [formGroup]="loginForm">
                <div class="form-group">
                    <label>Mobile Number:</label>
                    <input [(ngModel)]="mobileNumber" class="form-control" type="text" formControlName="mobileNumber" max="10" min="10">
                    <div *ngIf="loginForm.controls['mobileNumber'].hasError('required') && loginForm.controls['mobileNumber'].touched" class="form-control-error-custom" style="text-align:left;">
                        Mobile Number is required
                    </div>
                    <div *ngIf="loginForm.controls['mobileNumber'].hasError('pattern') && !loginForm.controls['mobileNumber'].hasError('required')" class="form-control-error-custom" style="text-align:left;">
                        Please enter valid mobile Number
                    </div>

                </div>                
                <div class="form-group">
                    <label>E-mail address / Username:</label>
                    <input [(ngModel)]="userName" (ngModelChange)="getCompaniesByEmailId()" class="form-control" type="text" formControlName="userName">
                      <div *ngIf="loginForm.controls['userName'].hasError('required') && loginForm.controls['userName'].touched"
                        class="form-control-error-custom" style="text-align:left;">
                        Email / Username is required.
                      </div>                    
<!--                     <div *ngIf="loginForm.controls['userName'].hasError('email') && !loginForm.controls['userName'].hasError('required')   && loginForm.controls['userName'].touched"
                    class="form-control-error-custom" style="text-align:left;">
                    Please enter the correct Email / Username.
                    </div> -->
                </div>
                <div class="form-group" style="margin-bottom: 5px;">
                    <label>Select Company:</label>
                    <ng-select [notFoundMsg]="isEmailForCompany" [items]="companyList" class="companyddl" formControlName="companyName" style="text-align: left;"></ng-select>
                    <div *ngIf="loginForm.controls['companyName'].hasError('required') && loginForm.controls['companyName'].touched" class="form-control-error-custom"
                        style="text-align:left;">
                        Company Name is required.
                    </div>
                </div>
                <h6 class="text-right">
                    <a href="#" style="width: 100%;display: block; margin-top: 0;font-weight: 400; color: #37a7a5;" 
                    novalidate [routerLink]="['/login']">Back to Sign In</a>
                </h6>
                <div class="clearfix"></div>
                <div class="text-center">
                <button class="btn btn-primary btn-inline mr-2 mb-2" [disabled]="!loginForm.valid" (click)="sendOTP()"><i aria-hidden="true"></i>Login with OTP</button>
                </div>
          </form>
      </div>
  </div>
</section>
