export enum roleMasterMenuname {
  Master = "Master",
  BoardEvaluation = "BoardEvaluation",
  UPSI = "UPSI",
  Governance = "Governance",
  Compliance = "Compliance",
  DocumentManagement = "DocumentManagement",
  Admin = "Admin",
  Dashboard = "Dashboard",
  LawsAndRegulations = "LawsAndRegulations",
  GEM = "GEM",
  NCD = "NCD",
  RoleMenu = "RoleMaster",
  UserMenu = "UserMaster",
  CompanyMenu = "CompanyMaster",
  UPSIMaster = "UPSI Master",
  UPSIParameter = "UPSI Parameter",
  EmailTemplate = "Email Template",
  SMTP = "SMTP"
}

export enum roleMasterIconClass {
    add = "fa fa-plus-circle",
    edit = "fa fa-pencil",
    delete = "fa fa fa-trash-o",
    viewOnly = "fa fa-eye"
}