import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { baseUrl, getHeaders } from '../../app/structure/dashboards/webx-chat/urls';
import { environment } from './../../environments/environment';
import 'rxjs/add/operator/map';
@Injectable()
export class WebexService {
 

  constructor(private httpWithoutToken: HttpClient, private http: HttpClient) { }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }


  endMeetingByModerator(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, meetingId, status): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')

    const url = gemAccessUrl + "meetingAttendance/startStopMeeting/" + meetingId + "/startStopBooleanStatus/" + status;
    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }

    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    getHeraders('authentication-token', token);
    getHeraders('companyId', companyID);
    getHeraders('companyName', companyName);
    getHeraders('complianceEngine', complianceEngine);
    getHeraders('complianceEngineUrl', complianceEngineUrl);
    getHeraders('governanceUrl', governanceUrl);
    getHeraders('uniqueUserId', uniqueUserId);
    getHeraders('userId', userId);

    return this.httpWithoutToken.post(url, {}, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  leaveMeetingByParticipants(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, meetingId, leaveDateTimefromDevice): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')

    const url = gemAccessUrl + "meetingAttendance/leaveMeeting/" + meetingId + "/leaveDateTimefromDevice/" + leaveDateTimefromDevice;

    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }

    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    getHeraders('authentication-token', token);
    getHeraders('companyId', companyID);
    getHeraders('companyName', companyName);
    getHeraders('complianceEngine', complianceEngine);
    getHeraders('complianceEngineUrl', complianceEngineUrl);
    getHeraders('governanceUrl', governanceUrl);
    getHeraders('uniqueUserId', uniqueUserId);
    getHeraders('userId', userId);

    return this.httpWithoutToken.put(url, {}, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  joinMeetingAndParticipantAttendance(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, meetingId, attendanceType): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var userName = JSON.parse(localStorage.getItem('user')).FirstName;
    var memberType = JSON.parse(localStorage.getItem('user')).TypeOfUser;
    const url = gemAccessUrl + `meetingAttendance/save/${meetingId}/attendanceMode/${attendanceType}`;

    var attendanceObject = {
      attendanceObject: {
        "meetingId": meetingId,
        "meetingAttendanceMode": attendanceType,
        "memberName": userName,
        "memberType": memberType,
        "userGovevaId": userId,
        "uniqueUserId": uniqueUserId
      }
    }

    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }
    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    getHeraders('authentication-token', token);
    getHeraders('companyId', companyID);
    getHeraders('companyName', companyName);
    getHeraders('complianceEngine', complianceEngine);
    getHeraders('complianceEngineUrl', complianceEngineUrl);
    getHeraders('governanceUrl', governanceUrl);
    getHeraders('uniqueUserId', uniqueUserId);
    getHeraders('userId', userId);

    return this.httpWithoutToken.post(url, JSON.stringify(attendanceObject), { headers: headers }).toPromise()
      .then(response => {
        var isMeetingInitiated = localStorage.getItem('isMeetingInitiated');
        console.log("isMeetingInitiated------>>>", isMeetingInitiated);
        if (isMeetingInitiated == "join" && localStorage.getItem('WebexAcessToken')) {
          this.setWebExAccessToken();
        }
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  setWebExAccessToken() {
    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }
    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    var token = localStorage.getItem('token');
    getHeraders('x-access-token', token);
    var token = localStorage.getItem('token')
    var webExAccessToken = localStorage.getItem('WebexAcessToken');
    var webExAccessTokenExpiry = localStorage.getItem('WebexAcessTokenExpiry');
    var webExRefreshToken = localStorage.getItem('WebexRefreshToken');
    var webExRefreshTokenExpiry = localStorage.getItem('WebexRefreshTokenExpiry');

    var body = {
      webExAccessToken: webExAccessToken,
      webExAccessTokenExpiry: webExAccessTokenExpiry,
      webExRefreshToken: webExRefreshToken,
      webExRefreshTokenExpiry: webExRefreshTokenExpiry
    }
    console.log("setWebExAccessToken body------>>>", body);
    return this.httpWithoutToken.post(environment.nonValidateUrl + 'user/setWebExAccessToken', body, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }




  autoLeftParticipant(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, meetingId): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')

    const url = gemAccessUrl + "meetingAttendance/hasMeetingStarted/" + meetingId;

    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }
    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    getHeraders('authentication-token', token);
    getHeraders('companyId', companyID);
    getHeraders('companyName', companyName);
    getHeraders('complianceEngine', complianceEngine);
    getHeraders('complianceEngineUrl', complianceEngineUrl);
    getHeraders('governanceUrl', governanceUrl);
    getHeraders('uniqueUserId', uniqueUserId);
    getHeraders('userId', userId);

    return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getSIPurl(meetingId) {
    var token = localStorage.getItem('token');
    const url = environment.clientUrl + "meetingForVc/" + meetingId;

    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }
    getHeraders('Content-Type', 'application/json');
    getHeraders('x-access-token', token);

    return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }



  listMeetings(meetingNumber) {
    return new Promise((resolve, reject) => {
      fetch(baseUrl + "/v1/meetings?meetingNumber=" + meetingNumber, {
        method: "GET",
        headers: getHeaders()
      })
        .then(res => res.json())
        .then(resJson => resolve(resJson))
        .catch(err => reject(err))
    })
  }


  getGuestToken(username) {
    const url = environment.nonValidateUrl + "auth/getJWTTForWebexGuest/" + username;

    return this.httpWithoutToken.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  testInternetSpeed() {

    const url = environment.nonValidateUrl + "webex/testInternetSpeed";

    return this.httpWithoutToken.get(url).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getCiscoIP(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')

    const url = gemAccessUrl + "configuration/getAll";

    let headers = {};

    const getHeraders = (key, data) => {
      headers[key] = data;
    }
    getHeraders('Content-Type', 'application/json');
    getHeraders('accept', '*/*');
    getHeraders('authentication-token', token);
    getHeraders('companyId', companyID);
    getHeraders('companyName', companyName);
    getHeraders('complianceEngine', complianceEngine);
    getHeraders('complianceEngineUrl', complianceEngineUrl);
    getHeraders('governanceUrl', governanceUrl);
    getHeraders('uniqueUserId', uniqueUserId);
    getHeraders('userId', userId);

    return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }
  recording(meetingId: any,recordingStarted: boolean, recordingPaused: boolean, locked: boolean): Observable<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
  
    let headers = new HttpHeaders()
    headers.append('Content-Type', 'application/json');
    headers.append('accept', '*/*');
    headers.append('authentication-token', token);
    headers.append('companyId', companyID);
    headers.append('companyName', companyName);
    headers.append('uniqueUserId', uniqueUserId);
    headers.append('userId', userId);
  
    return this.http.put(`https://webexapis.com/v1/meetings/controls?meetingId=${meetingId}`, {
      recordingStarted: +recordingStarted,
      recordingPaused: +recordingPaused,
      locked: +locked
    },{ 'headers': headers } );
  }
//   muteAll(meetingId: any,object) {
//     var headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     var token = localStorage.getItem('token')
//     headers.append("x-access-token", token)
//     console.log('JSON.stringify(formData)', object);
//     return this.http.put('https://webexapis.com/v1/meetingParticipants/' + meetingId, object, { headers: headers })
//         .map(res => res.json())
// }
muteAllParticipant(userId: any,object): Promise<any> {

  var userInfo = JSON.parse(localStorage.getItem('user'));
  var userId = JSON.parse(localStorage.getItem('user'))._id;
  var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
  var token = localStorage.getItem('token');
  var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
  var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
  var companyName = localStorage.getItem('companyDetailsGoveva')

  const url =  "https://webexapis.com/v1/meetingParticipants/" + userId;
  let headers = {};

  const getHeraders = (key, data) => {
    headers[key] = data;
  }
  getHeraders('Content-Type', 'application/json');
  getHeraders('accept', '*/*');
  getHeraders('authentication-token', token);
  getHeraders('companyId', companyID);
  getHeraders('companyName', companyName);
  getHeraders('uniqueUserId', uniqueUserId);
  getHeraders('userId', userId);

  return this.httpWithoutToken.put(url, object, { headers: headers }).toPromise()
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(this.handleError);
}

muteall(userId:any,object): Promise<any> {
  const url =  "https://webexapis.com/v1/meetingParticipants/" + userId;
  return this.http.put(url, object).toPromise()
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(this.handleError)
}

}
