import { Observable } from 'rxjs/Observable';
import { DashboardService } from './../../services/dashboard.service';
import { Location } from '@angular/common';
import 'rxjs/add/observable/forkJoin';
import * as _ from 'lodash';
import { Component, OnInit, OnChanges, Input, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FinancialDataService } from './../../services/financialData.service';
import 'rxjs/add/operator/map';
import { EnvironmentDataComponent } from './environment-data/environment-data.component';
import { SocialDataComponent } from './social-data/social-data.component';
import { Router, Routes, ActivatedRoute } from '@angular/router';
import { CompanyMasterService } from '../../services/companymaster.service';
import { MeetingMasterService } from '../../services/meetingMaster.service';
import { PermissionLinks } from './../../enums/permissionLinks';
import { UserMasterService } from '../../services/user-master.service';
import { LoginService } from '../../services/login.service';
import { RocketChatService } from '../../services/rocketChat.service';
import { environment } from './../../../environments/environment';
import { rocketChatRemove } from '../../enums/rocketChatRemove';
import { ChartsService } from './../../../app/services/charts.service';



declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;



export const ScriptStore: any = [
  //{ name: 'jquery', src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js' },

  /*
  { name: 'jquery1', src: 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js' },
  { name: 'pivot', src: 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui-touch-punch/0.2.3/jquery.ui.touch-punch.min.js' },
  { name: 'pivot0', src: 'https://cdn.plot.ly/plotly-basic-latest.min.js' },
  { name: 'pivot1', src: 'https://cdnjs.cloudflare.com/ajax/libs/pivottable/2.13.0/pivot.min.js' },
  { name: 'pivot2', src: 'https://cdnjs.cloudflare.com/ajax/libs/pivottable/2.22.0/plotly_renderers.min.js' },
  { name: 'pivot3', src: 'https://cdnjs.cloudflare.com/ajax/libs/pivottable/2.6.0/tips_data.min.js' },
  */

  { name: 'jquery1', src: '../../../assets/vendors/by_bower/jquery-uiMin-cdn/index.js' },
  { name: 'pivot', src: '../../../assets/vendors/by_bower/jquery-touchPunchMin-cdn/index.js' },
  { name: 'pivot0', src: '../../../assets/vendors/by_bower/jquery-PlotlyLatestBasicMin-cdn/index.js' },
  { name: 'pivot1', src: '../../../assets/vendors/by_bower/jquery-PivotMin-cdn/index.js' },
  { name: 'pivot2', src: '../../../assets/vendors/by_bower/jquery-PlotlyRenderersMin-cdn/index.js' },
  { name: 'pivot3', src: '../../../assets/vendors/by_bower/jquery-ui-TipsDataMin-cdn/index.js' },
];

@Component({
  selector: 'cat-page',
  templateUrl: './alpha.html',
  styleUrls: ['./alpha.css']
})

export class DashboardsAlpha implements OnInit {

  @Output() changeCompany = new EventEmitter();
  @ViewChild('environmentModal') EnvironmentModal: EnvironmentDataComponent;
  @ViewChild('socialModal') SocialModal: SocialDataComponent;
  companyId: any;
  Testtest: any;
  RowOne: boolean = false;
  RowTwo: boolean = false;
  RowThree: boolean = false;
  RowFour: boolean = false;
  RowFive: boolean = false;
  RowSix: boolean = false;
  WidgetCount: any = 0;
  CardCount: any = 0;
  Cards: Array<number> = [];
  CardRowOne: boolean = false;
  CardRowTwo: boolean = false;
  CardRowThree: boolean = false;
  CardRowFour: boolean = false;
  companyFinancialYearFromMonthGlobel: any;
  tradingWindowStatus: any;
  quarterlyPnLChart: object;
  yearlyPnLChart: object;

  earningsRatiosQuarterlyChart: object;
  earningsRatiosYearlyChart: object;
  marginRatiosQuarterlyChart: object;
  marginRatiosYearlyChart: object;
  growthRatiosQuarterlyChart: object;
  growthRatiosYearlyChart: object;
  returnsRatiosYearlyChart: object;


  internalScoreChart: object;
  rFactorScoreChart: object;
  stockClosingPrice: string = "";
  previousStockClosingPrice: string = "";
  differenceInStockPrice: any;
  percentageChange: any;
  meetingList: any;

  orgchartWidgetEnable: boolean;
  digitalAssistantWidgetEnable: boolean;

  previousSPLow: string = "";
  previousSPHigh: string = "";
  week52SPLow: string = "";
  week52SPHigh: string = "";

  financialResultChartTrend: string = "";
  keyRatiosChartTrend: string = "";
  ratioType: string = "";
  ratioTypeLabel: string = "";
  companyName: string = "";
  companyEVA: any;
  private scripts: any = {};

  public internalScoreStatus: boolean = true;
  public rFactorScoreStatus: boolean = true;
  public internalAssessmentId: String;
  public RFactorAssessmentId: String;
  public taskObject: any;
  public selectedStockExchange: string;
  isNSEDataFound: boolean = true;
  isBSEDataFound: boolean = true;

  base64Code: any = "";
  subsidiaryList: any = [];
  associatedCompanyList: any = [];
  companyTabName: string = '';

  changeDetailsArray: any;

  isRocketChatRemove = rocketChatRemove.isRemove;

  // APP Configuration
  isPSSConfig = environment.Service.PSS;
  isGovevaConfig = environment.Service.GovEva;
  isUPSIConfig = environment.Service.UPSI;
  isAllConfig = environment.Service.All;
  isNCDConfig = environment.Service.NCD;
  // isBoardEvaluationConfig = environment.Service.BoardEvaluation;
  // isESGConfig = environment.Service.Governance;
  enableDashboard: boolean = true;
  isMeetingsFetch: any = "false";
  isTaskFetch: any = "false";
  isCompanyNewsFetch: any = "false";
  isStockPriceFetch: any = "false";
  isFinancialResults: any = "false";
  isKeyRatios: any = "false";
  showSpinner: boolean = true;
  companyData: any;

  public dimType: any = [];
  public selectedDim: any = "";
  refreshMeter: any;

  constructor(private dashboardService: DashboardService, private userMasterService: UserMasterService, private location: Location,
    private financialDataService: FinancialDataService, private companyMasterService: CompanyMasterService,
    private router: Router, private meetingMasterService: MeetingMasterService, private route: ActivatedRoute,
    private loginService: LoginService, private rocketChatService: RocketChatService,
    private chartsService: ChartsService) {


  }

  ngAfterViewInit() {


    let name = [];
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
      name.push(script.name)
    });
    this.load(name);
    // })
  }

  load(scripts) {
    console.log("loading --------------------")
    const promises: any[] = [];
    // scripts.forEach((script) => promises.push(this.loadScript(script)));
    this.loadScript(scripts[0]).then(res0 => {
      this.loadScript(scripts[1]).then(res1 => {
        this.loadScript(scripts[2]).then(res1 => {
          this.loadScript(scripts[3]).then(res1 => {
            this.loadScript(scripts[4]).then(res1 => {
              this.loadScript(scripts[5]).then(res1 => {
                //this.loadScript(scripts[6]).then(res1 => {
                // if (this.isESGConfig) {
                  
                // }
                this.loadChart();
                //})
              })

            })
          })
        })
      })
    })
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name] && this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          console.log(`${name} Loaded.`);
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }


  setClasses() {
    let classes = {
      'col-lg-3': (this.WidgetCount >= 4),
      'col-lg-6': (this.WidgetCount == 2),
      'col-lg-4': (this.WidgetCount == 3),
      'col-lg-12': (this.WidgetCount == 1),
      'pl-0': true
    };
    return classes;
  }

  setHighClasses() {
    if (this.WidgetCount >= 6) {
      let classes = {
        'col-lg-6': true,
        'pl-0': true
      };
      return classes;
    } else if (this.WidgetCount == 5) {
      let classes = {
        'col-lg-12': true,
        'pl-0': true
      };
      return classes;
    } else {
      let classes = {
        'col-lg-3': (this.WidgetCount >= 4),
        'col-lg-6': (this.WidgetCount == 2),
        'col-lg-4': (this.WidgetCount == 3),
        'col-lg-12': (this.WidgetCount == 1),

        'pl-0': true
      };
      return classes;
    }

  }

  setCardClasses() {
    let classes = {
      'col-lg-3': (this.CardCount == 4),
      'col-lg-6': (this.CardCount == 2),
      'col-lg-4': (this.CardCount == 3),
      'col-lg-12': (this.CardCount == 1),

      'dark-theme': true,
      'p-b-xs-20': true,
      'bdr-r-1-dark-pink': true,
    };
    return classes;
  }

  setSubCardClasses(n, m) {
    let classes = {};
    if (this.Cards.includes(n) && this.Cards.includes(m)) {
      classes['col-md-6'] = true
    } else if (this.Cards.includes(n) || this.Cards.includes(m)) {
      classes['col-md-12'] = false
    }
    return classes;
  }


  ngOnInit() {





    this.showSpinner = true;
    localStorage.removeItem("isModerator");
    localStorage.removeItem("WebexAcessToken");
    localStorage.removeItem("WebexAcessTokenExpiry");
    localStorage.removeItem("WebexRefreshToken");
    localStorage.removeItem("WebexRefreshTokenExpiry");
    localStorage.removeItem("isMeetingInitiated");
    localStorage.removeItem("joinInPerson");
    var vcstatus = localStorage.getItem('comingFromVcPage')
    if (vcstatus) {
      localStorage.removeItem('comingFromVcPage')
      window.location.reload()
    }
    let companyId = this.route.snapshot.queryParams['CompanyId'] || '/';
    let uniqueUserId = this.route.snapshot.queryParams['UniqueUserId'] || '/';

    $('body').css('padding-top', '4.64rem')

    // Avoid gray Screen from PSPDF if modal not closed
    $(".modal-backdrop").css("display", "none")

    //meeting ReturnURL

    let redirectNotification = this.route.snapshot.queryParams['redirectionNotification'] || false;

    //console.log("alpha, redirectNotificationt", redirectNotification, this.route.url);

    let returnURL = this.route.snapshot.queryParams['returnURL'] || '/';

    if (this.isPSSConfig || this.isAllConfig || this.isGovevaConfig) {
      this.enableDashboard = true;
    } else if (this.isUPSIConfig && !(this.isPSSConfig || this.isAllConfig || this.isGovevaConfig)) {
      this.enableDashboard = false;
    }

    //console.log("this.enableDashboard ", this.enableDashboard)

    //console.log('checking for the change in the company', companyId)
    //console.log('checking for retuen url', returnURL);

    if (companyId != '/' && uniqueUserId != '/') {
      var loginCredentialObject = {
        // "UserName": _.toLower(user.EmailId),
        // "Password": this.loginForm.controls.password.value,
        "CompanyId": companyId,
        "UniqueUserId": uniqueUserId
      };
      let rocketBaseURL = localStorage.getItem('rocketBaseURL')
      this.loginService.loginWithUniqueUserId(loginCredentialObject).subscribe((response: any) => {
        //console.log('response of user-------------------11111111111', response)
        if (!response.status) {

          if (rocketChatRemove.isRemove.toString() != 'true') {
            let loginChatModel = {
              "user": response.user.RocketChatUserName,  //FirstName + data.user.LastName,
              "password": response.user.RocketChatPassword//this.loginForm.controls.password.value//"Demo@1234"            
            };
            Observable.forkJoin([
              this.rocketChatService.loginToRocketChat(loginChatModel, rocketBaseURL),
              this.companyMasterService.getSingleCompnyDetails(companyId)

            ]).subscribe((multipleResponse) => {
              //console.log('resultOfCompanyDetails', multipleResponse)
              this.changeDetailsArray = multipleResponse;
              // clear localStorage...
              localStorage.removeItem('companyEVA')
              localStorage.removeItem('user')
              localStorage.removeItem('companyDetailsGoveva')
              localStorage.removeItem('resources')
              localStorage.removeItem('rocketUserId')
              localStorage.removeItem('token')
              localStorage.removeItem('rocketAuth')
              localStorage.removeItem("companyDetails")

              // set localStroage
              // //console.log('multipleResponse[1].EVA', multipleResponse[1].EVA)
              localStorage.setItem('companyEVA', multipleResponse[1].companyData.EVA)
              localStorage.setItem('user', JSON.stringify(response.user))
              localStorage.setItem('companyDetailsGoveva', JSON.stringify({ label: multipleResponse[1].companyData.CompanyName, value: multipleResponse[1].companyData._id }))
              localStorage.setItem('resources', JSON.stringify(response.resources))
              localStorage.setItem('rocketUserId', multipleResponse[0].data.userId)
              localStorage.setItem('token', response.token)
              localStorage.setItem('rocketAuth', multipleResponse[0].data.authToken)
              localStorage.setItem("rocketBaseURL", multipleResponse[1].companyData.ChatEngineURL);
              localStorage.setItem("companyDetails", JSON.stringify({ companyName: multipleResponse[1].companyData.CompanyName, companyID: multipleResponse[1].companyData._id }))

              if (returnURL != '/') {
                window.open(environment.pssUIURL + returnURL, "_self")// pss throw
              } else {
                location.reload();
                this.router.navigate(['/'])
              }
              //console.log("this.changeDetailsArray", this.changeDetailsArray);
            })

          } else {

            this.companyMasterService.getSingleCompnyDetails(companyId).then((multipleResponse) => {
              this.changeDetailsArray = multipleResponse;
              // clear localStorage...
              localStorage.removeItem('companyEVA')
              localStorage.removeItem('user')
              localStorage.removeItem('companyDetailsGoveva')
              localStorage.removeItem('resources')
              localStorage.removeItem('token')
              localStorage.removeItem("companyDetails")

              // set localStroage
              // //console.log('multipleResponse[1].EVA', multipleResponse[1].EVA)
              localStorage.setItem('companyEVA', multipleResponse.companyData.EVA)
              localStorage.setItem('user', JSON.stringify(response.user))
              localStorage.setItem('companyDetailsGoveva', JSON.stringify({ label: multipleResponse.companyData.CompanyName, value: multipleResponse.companyData._id }))
              localStorage.setItem('resources', JSON.stringify(response.resources))
              localStorage.setItem('token', response.token)
              localStorage.setItem("rocketBaseURL", multipleResponse.companyData.ChatEngineURL);
              localStorage.setItem("companyDetails", JSON.stringify({ companyName: multipleResponse.companyData.CompanyName, companyID: multipleResponse.companyData._id }))

              if (returnURL != '/') {
                window.open(environment.pssUIURL + returnURL, "_self")// pss throw
              } else {
                location.reload();
                this.router.navigate(['/'])
              }
              //console.log("this.changeDetailsArray", this.changeDetailsArray);
            })

          }

        }

      })
    }

    this.orgchartWidgetEnable = false;
    this.digitalAssistantWidgetEnable = false;

    const resourcesValue = JSON.parse(localStorage.getItem('resources'));
    this.companyEVA = JSON.parse(localStorage.getItem('companyEVA'));
    // this.companyId = JSON.parse(localStorage.getItem('user')).CompanyId;

    this.getCompanyData();

    if (this.enableDashboard) {
      this.getMeetingsList();
      this.getsubsidiaryList();
    }


    const self = this;
    _.forEach(resourcesValue, function (data) {
      if (data.Menu === "Dashboard") {
        _.forEach(data.ChildResources, function (childData) {
          if (childData.DisplayName == "Organization Chart - Board Member" || childData.DisplayName == "Organization Chart - Executive Management") {
            self.orgchartWidgetEnable = true;
          }

          if (childData.Url == "digitalAssisstanceTeamTask" || childData.Url == "digitalAssisstanceMyTask") {
            self.digitalAssistantWidgetEnable = true;
          }

          if (childData.Url == "progressMeter") {

            self.WidgetCount++;

            self.RowOne = true;

          }
          if (childData.Url == "scoreMeter") {

            self.WidgetCount++;

            self.RowTwo = true;

          }

          if (childData.Url == "myTasks") {

            self.WidgetCount++;

            self.RowThree = true;

          }
          if (childData.Url == "companyNews") {

            self.WidgetCount++;

            self.RowFour = true;

          }

          if (childData.Url == "digitalAssisstanceMyTask" || childData.Url == "digitalAssisstanceTeamTask") {
            if (self.RowFive == false) {
              self.WidgetCount++;
            }
            self.RowFive = true;

          }


          if (childData.Url == "meetings") {
            if (self.RowSix == false) {
              self.WidgetCount++;
            }

            self.RowSix = true;
          }

          if (childData.Url == "stockPrice") {
            if (self.CardRowOne == false) {
              self.CardCount++;
              self.Cards.push(1);
            }

            self.CardRowOne = true;
          }

          if (childData.Url == "financialResults") {
            if (self.CardRowTwo == false) {
              self.CardCount++;
            }

            self.CardRowTwo = true;
          }

          if (childData.Url == "keyRatios") {
            if (self.CardRowThree == false) {
              self.CardCount++;
              self.Cards.push(3)
            }

            self.CardRowThree = true;
          }


          if (childData.Url == "organizationChartBoardMemeber" || childData.Url == "organizationChart-ExecutiveManagement") {
            if (self.CardRowFour == false) {
              self.CardCount++;
              self.Cards.push(4)
            }

            self.CardRowFour = true;
          }





        })
      }
    })

    // $(function () {

    //   $("body").addClass("dashboard-page dark-theme");
    //   ///////////////////////////////////////////////////////////
    //   // tooltips
    //   $("[data-toggle=tooltip]").tooltip();
    //   $('.reset-button').on('click', function () {
    //     localStorage.removeItem('order-left-col');
    //     localStorage.removeItem('order-right-col');
    //     localStorage.removeItem('order-bottom-col');
    //     setTimeout(function () {
    //       location.reload();
    //     }, 500)
    //   });

    //   ///////////////////////////////////////////////////////////
    //   // card controls
    //   $('.cat__core__sortable__collapse, .cat__core__sortable__uncollapse').on('click', function () {
    //     $(this).closest('.card').toggleClass('cat__core__sortable__collapsed');
    //   });
    //   ///////////////////////////////////////////////////////////
    //   // ladda buttons
    //   Ladda.bind('.ladda-button', { timeout: 2000 });

    //   ///////////////////////////////////////////////////////////
    //   // chart1
    //   new Chartist.Line(".chart-line", {
    //     labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    //     series: [
    //       [5, 0, 7, 8, 12],
    //       [2, 1, 3.5, 7, 3],
    //       [1, 3, 4, 5, 6]
    //     ]
    //   }, {
    //     fullWidth: !0,
    //     chartPadding: {
    //       right: 40
    //     },
    //     plugins: [
    //       Chartist.plugins.tooltip()
    //     ]
    //   });

    //   ///////////////////////////////////////////////////////////
    //   // chart 2
    //   var overlappingData = {
    //     labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    //     series: [
    //       [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
    //       [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4]
    //     ]
    //   },
    //     overlappingOptions = {
    //       seriesBarDistance: 10,
    //       plugins: [
    //         Chartist.plugins.tooltip()
    //       ]
    //     },
    //     overlappingResponsiveOptions = [
    //       ["", {
    //         seriesBarDistance: 5,
    //         axisX: {
    //           labelInterpolationFnc: function (value) {
    //             return value[0]
    //           }
    //         }
    //       }]
    //     ];

    //   new Chartist.Bar(".chart-overlapping-bar", overlappingData, overlappingOptions, overlappingResponsiveOptions);


    //   ///////////////////////////////////////////////////////////
    //   // custom scroll
    //   if (!(/Mobi/.test(navigator.userAgent)) && jQuery().jScrollPane) {
    //     $('.custom-scroll').each(function () {
    //       $(this).jScrollPane({
    //         contentWidth: '100%',
    //         autoReinitialise: true,
    //         autoReinitialiseDelay: 100
    //       });
    //       var api = $(this).data('jsp'),
    //         throttleTimeout;
    //       $(window).bind('resize', function () {
    //         if (!throttleTimeout) {
    //           throttleTimeout = setTimeout(function () {
    //             api.reinitialise();
    //             throttleTimeout = null;
    //           }, 50);
    //         }
    //       });
    //     });
    //   }

    //   ///////////////////////////////////////////////////////////
    //   // adjustable textarea
    //   autosize($('.adjustable-textarea'));

    //   ///////////////////////////////////////////////////////////
    //   // slider
    //   $("#slider-1").ionRangeSlider({
    //     min: 0,
    //     max: 16000,
    //     from: 12000,
    //     step: 1000,
    //     grid: true,
    //     grid_num: 8
    //   });

    //   $("#slider-2").ionRangeSlider({
    //     type: "double",
    //     min: 0,
    //     max: 100,
    //     from: 20,
    //     from_min: 10,
    //     from_max: 30,
    //     from_shadow: true,
    //     to: 60,
    //     to_min: 50,
    //     to_max: 70,
    //     to_shadow: true,
    //     grid: true,
    //     grid_num: 10
    //   });
    // });
  }
  loadEmissionCalculationData() {
    let emissionChartdata = [];
    this.chartsService.emissionCalculationData().then((resData: any) => {
      let data = JSON.parse(JSON.stringify(resData.data))
      /*if(resData.emissionCalculation.length > 0){
        resData.emissionCalculation= resData.emissionCalculation.map((val, index)=>{
          return {Location:val.Location, Value:val.CalculatedEmission, Year:Number(val.PeriodOfEmission.split(" ")[0])};
        })
      }*/
      if (typeof data == 'undefined' || data.length <= 0) {
        $("#emission-chart-output")[0].innerHTML = 'No data available'
      } else {
        $("#emission-chart-output").pivotUI(
          data, {
          rows: ["Country", "City", "Location"],
          cols: ["Year"],
          vals: ["Value", "Scope", "Type"],
          aggregatorName: "Sum",
          rendererName: "Bar Chart",
          renderers: $.extend(
            $.pivotUtilities.renderers,
            $.pivotUtilities.plotly_renderers),
          rendererOptions: { plotly: { width: 500, height: 300 } },
          onRefresh: (config) => {
            var config_copy = JSON.parse(JSON.stringify(config));
            var inclusions = config_copy['inclusions']
            var exclusions = config_copy['exclusions']
            console.log(config_copy['exclusions'])
            console.log(data.length)
            let new_data = []

            for (let i = 0; i < Object.keys(inclusions).length; i++) {
              console.log(Object.keys(inclusions)[i])
              console.log(inclusions[Object.keys(inclusions)[i]])
            }
          }/*,
              filter: function(data){
                console.log(data)
                return data['Type'] == "Air Travel"
              },*/
        });
      }
    })
  }
  loadChart() {
    this.loadEmissionCalculationData();
  }

  getCompanyData() {

    this.companyName = JSON.parse(localStorage.getItem('user')).CompanyName;
    const resources = JSON.parse(localStorage.getItem('resources'));
    const dashboardObject = _.find(resources, function (resourceObject) { return resourceObject.Menu == PermissionLinks.dashboard })
    var companyFinancialYearFromyear;
    var companyFinancialYearFromMonth;
    var self = this;
    this.companyMasterService.getCompanyList().then(company => {
      if (company.status == 0) {
        this.companyData = company.companyData;
        if (company.companyData.FinancialYearFrom) {
          companyFinancialYearFromyear = company.companyData.FinancialYearFrom
          var d = new Date(companyFinancialYearFromyear);
          companyFinancialYearFromMonth = d.getMonth() + 1;
          self.companyFinancialYearFromMonthGlobel = companyFinancialYearFromMonth;
        }
        if (company.companyData.CompanyLogoImage != null && company.companyData.CompanyLogoImage != "") {
          this.base64Code = company.companyData.CompanyLogoImage;
          $("#clientCompanyLogo").attr("src", this.base64Code);
        }
        else {
          this.base64Code = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
          $("#clientCompanyLogo").attr("src", this.base64Code);
        }
        if (this.enableDashboard) {

          this.getCompanyTradingWindowStatus();
          if (!_.isNil(dashboardObject) && _.find(dashboardObject.ChildResources, function (childResource) { return childResource.Url == PermissionLinks.stockPrice })) {
            this.getStockPriceData("NSE");
          }
          if (!_.isNil(dashboardObject) && _.find(dashboardObject.ChildResources, function (childResource) { return childResource.Url == PermissionLinks.scoreMeter })) {
            this.getInternalAndRFactorScore();
          }
          if (!_.isNil(dashboardObject) && _.find(dashboardObject.ChildResources, function (childResource) { return childResource.Url == PermissionLinks.financialResults })) {
            this.getFinancialResultQuarterlyData();
          }

          if (!_.isNil(dashboardObject) && _.find(dashboardObject.ChildResources, function (childResource) { return childResource.Url == PermissionLinks.keyRatios })) {
            this.getAnnualTrendReturnsRatios();
          }

        }

      }
    });


  }

  getsubsidiaryList() {

    let loggedinUserId = JSON.parse(localStorage.getItem('user'))._id;

    this.userMasterService.getUserByUserId(loggedinUserId).then(response => {

      if (response.status == 0) {
        if (_.size(response.userDetails.SubsidiaryCompanies) <= 0) {
          this.subsidiaryList = null;
          this.companyTabName = response.userDetails.CompanyName;
        } else {
          this.subsidiaryList = response.userDetails.SubsidiaryCompanies;
          this.companyTabName = response.userDetails.CompanyName;
        }

      } else {
        this.subsidiaryList = null;
        this.companyTabName = response.userDetails.CompanyName;
      }
    });
  }


  getMeetingsList() {
    var companyId = JSON.parse(localStorage.getItem('user')).CompanyId;
    // //console.log("companyId", companyId);

    this.meetingMasterService.getMeetingList(companyId)
      .subscribe(response => {
        if (response.status == 0) {
          this.meetingList = response.MeetingList;
        }
      })
  }

  getCompanyTradingWindowStatus() {
    this.dashboardService.getCompanyTradingWindowStatus().then(company => {
      if (company.status == 0) {
        this.tradingWindowStatus = company.companyData.TradingWindowStatus;
      }
    });
  }

  getStockPriceData(value) {

    this.selectedStockExchange = value;
    // this.dashboardService.getStockPriceData().then(stockPriceData => {
    //   if (stockPriceData.status == 0) {
    //     if (value == "NSE") {
    //       if (stockPriceData.nseStockPrice != null) {
    //         console.log('stockPriceData.nseStockPrice.PreviousClosePrice--------------', stockPriceData.nseStockPrice.PreviousClosePrice)
    //         this.isNSEDataFound = true;
    //         this.stockClosingPrice = stockPriceData.nseStockPrice.ClosePrice;
    //         this.previousStockClosingPrice = stockPriceData.nseStockPrice.PreviousClosePrice == undefined ? stockPriceData.nseStockPrice.ClosePrice : stockPriceData.nseStockPrice.PreviousClosePrice;
    //         this.differenceInStockPrice = (stockPriceData.nseStockPrice.ClosePrice - (stockPriceData.nseStockPrice.PreviousClosePrice == undefined ? stockPriceData.nseStockPrice.ClosePrice : stockPriceData.nseStockPrice.PreviousClosePrice));
    //         this.percentageChange = (this.differenceInStockPrice / (stockPriceData.nseStockPrice.PreviousClosePrice == undefined ? stockPriceData.nseStockPrice.ClosePrice : stockPriceData.nseStockPrice.PreviousClosePrice)) * 100;

    //         this.previousSPLow = stockPriceData.nseStockPrice.PreviousDayLow;
    //         this.previousSPHigh = stockPriceData.nseStockPrice.PreviousDayHigh;
    //         this.week52SPLow = stockPriceData.nseStockPrice.Week52Low;
    //         this.week52SPHigh = stockPriceData.nseStockPrice.Week52High;
    //       }
    //       else
    //         this.isNSEDataFound = false;

    //       $("#toggleNSEInfoID").addClass("active");
    //     }
    //     else //BSE
    //     {
    //       if (stockPriceData.bseStockPrice != null) {
    //         this.isBSEDataFound = true;
    //         this.stockClosingPrice = stockPriceData.bseStockPrice.ClosePrice;
    //         this.previousStockClosingPrice = stockPriceData.bseStockPrice.PreviousClosePrice == undefined ? stockPriceData.bseStockPrice.ClosePrice : stockPriceData.bseStockPrice.PreviousClosePrice;
    //         this.differenceInStockPrice = (stockPriceData.bseStockPrice.ClosePrice - (stockPriceData.bseStockPrice.PreviousClosePrice == undefined ? stockPriceData.bseStockPrice.ClosePrice : stockPriceData.bseStockPrice.PreviousClosePrice));
    //         this.percentageChange = (this.differenceInStockPrice / (stockPriceData.bseStockPrice.PreviousClosePrice == undefined ? stockPriceData.bseStockPrice.ClosePrice : stockPriceData.bseStockPrice.PreviousClosePrice)) * 100;

    //         this.previousSPLow = stockPriceData.bseStockPrice.PreviousDayLow;
    //         this.previousSPHigh = stockPriceData.bseStockPrice.PreviousDayHigh;
    //         this.week52SPLow = stockPriceData.bseStockPrice.Week52Low;
    //         this.week52SPHigh = stockPriceData.bseStockPrice.Week52High;
    //       }
    //       else
    //         this.isBSEDataFound = false;

    //       $("#toggleBSEInfoID").addClass("active");
    //     }

    //   }
    // });
  }

  toggleStockExchangeInfo(value) {
    this.getStockPriceData(value);
  }


  getFinancialResultQuarterlyData() {
    this.financialResultChartTrend = "Quarterly";
    var self = this;
    this.financialDataService.getLastFiveQuartersPnLResults().then(responseData => {

      if (responseData.status == 0) {
        var count = 0;
        if (responseData.financialResults != null && _.size(responseData.financialResults) > 0) {

          var financialQuarterlyResultData = _.filter(responseData.financialResults);
          financialQuarterlyResultData.forEach(element => {
            _.remove(element.financialData, function (a: any) { return a.Type == "C" });
          });
          var quaterLabel = [];
          var patData = [];
          var epsData = [];
          _.forEach(financialQuarterlyResultData, function (value) {
            //  

            if (_.size(value.financialData[0])) {
              patData.push(value.financialData[0].qr_PAT);
              epsData.push(value.financialData[0].qr_EPSABS);
            } else {
              patData.push(" ");
              epsData.push(" ");
            }

            // patData.push(value.financialData[0].qr_PAT);
            // epsData.push(value.financialData[0].qr_EPSABS);

            var financialQuater = value._id.toString();
            var year = financialQuater.substr(0, 4);
            var quater = financialQuater.substr(4, 2);
            if (self.companyFinancialYearFromMonthGlobel == 1) {
              switch (quater) {
                case '03':
                  quater = 'Q1';
                  break;
                case '06':
                  quater = 'Q2';
                  break;
                case '09':
                  quater = 'Q3';
                  break;
                case '12':
                  quater = 'Q4';
                  break;
                default:
                  break;
              }


            } else if (self.companyFinancialYearFromMonthGlobel == 7) {

              switch (quater) {
                case '09':
                  quater = 'Q1';
                  break;
                case '12':
                  quater = 'Q2';
                  break;
                case '03':
                  quater = 'Q3';
                  //   year = (year - 1).toString();
                  break;
                case '06':
                  quater = 'Q4';
                  //    year = (year - 1).toString();
                  break;
                default:
                  break;
              }


            } else {
              switch (quater) {
                case '03':
                  quater = 'Q4';
                  // year = (year - 1).toString();
                  break;
                case '06':
                  quater = 'Q1';
                  break;
                case '09':
                  quater = 'Q2';
                  break;
                case '12':
                  quater = 'Q3';
                  break;
                default:
                  break;
              }

            }
            // switch (quater) {
            //   case '03':
            //     quater = 'Q4';
            //     year = (year - 1).toString();
            //     break;
            //   case '06':
            //     quater = 'Q1';
            //     break;
            //   case '09':
            //     quater = 'Q2';
            //     break;
            //   case '12':
            //     quater = 'Q3';
            //     break;
            //   default:
            //     break;
            // }

            quaterLabel.push(quater + ' ' + year);
            value.FinancialQuater = quater + ' ' + year;
            // quaterLabel.push(value._id);
          });
          //  
          this.quarterlyPnLChart = {
            chart: {
              renderTo: 'quarterlyPnLContainer',
              height: 130,
              spacingTop: 0,
              spacingBottom: 0,
              spacingLeft: 0,
              spacingRight: 0
            },
            title: {
              text: ''
            },
            credits: {
              enabled: false
            },
            legend: {
              enabled: false
            },
            yAxis: {
              title: {
                text: ''
              },
              labels: {
                enabled: false
              }
            },

            xAxis: {
              categories: quaterLabel,
              tickInterval: 1,
              labels: {
                autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
              }
            },

            series: [{
              name: 'PAT',
              data: patData
            }, {
              name: 'EPS',
              data: epsData
            }],
          };
        }
      }
      else {
        this.financialResultChartTrend = "false"
      }
    });
  }


  getFinancialResultYearlyData() {
    this.financialResultChartTrend = "Yearly";
    var self = this;
    this.financialDataService.getLastFiveYearsPnLResults().then(responseData => {
      //  
      if (responseData.status == 0) {
        var count = 0;
        if (responseData.financialResults != null && _.size(responseData.financialResults) > 0) {

          var financialYearlyResultData = _.filter(responseData.financialResults);
          financialYearlyResultData.forEach(element => {
            _.remove(element.financialData, function (a: any) { return a.Type == "C" });
          });
          var yearLabel = [];
          var patData = [];
          var epsData = [];
          _.forEach(financialYearlyResultData, function (value) {
            //  
            if (_.size(value.financialData[0])) {
              patData.push(value.financialData[0].pl_Profit_after_tax);
              epsData.push(value.financialData[0].pl_Reported_EPS);
            } else {
              patData.push(" ");
              epsData.push(" ");
            }
            // patData.push(value.financialData[0].pl_Profit_after_tax);
            // epsData.push(value.financialData[0].pl_Reported_EPS);

            var financialQuater = value._id.toString();
            var year = financialQuater.substr(0, 4);
            var quater = financialQuater.substr(4, 2);
            if (self.companyFinancialYearFromMonthGlobel == 1) {
              switch (quater) {
                case '03':
                  quater = 'Q1';
                  break;
                case '06':
                  quater = 'Q2';
                  break;
                case '09':
                  quater = 'Q3';
                  break;
                case '12':
                  quater = 'Q4';
                  break;
                default:
                  break;
              }
              yearLabel.push('Dec ' + year);

            } else if (self.companyFinancialYearFromMonthGlobel == 7) {

              switch (quater) {
                case '09':
                  quater = 'Q1';
                  break;
                case '12':
                  quater = 'Q2';
                  break;
                case '03':
                  quater = 'Q3';
                  //   year = (year - 1).toString();
                  break;
                case '06':
                  quater = 'Q4';
                  //    year = (year - 1).toString();
                  break;
                default:
                  break;
              }

              yearLabel.push('June ' + year);


            } else {
              switch (quater) {
                case '03':
                  quater = 'Q4';
                  // year = (year - 1).toString();
                  break;
                case '06':
                  quater = 'Q1';
                  break;
                case '09':
                  quater = 'Q2';
                  break;
                case '12':
                  quater = 'Q3';
                  break;
                default:
                  break;
              }
              yearLabel.push('March ' + year);
            }
            // yearLabel.push(quater +" " + year);
            value.FinancialQuater = quater + " " + year;
            // quaterLabel.push(value._id);
          });
          //  
          this.yearlyPnLChart = {
            chart: {
              renderTo: 'yearlyPnLContainer',
              height: 130,
              spacingTop: 0,
              spacingBottom: 0,
              spacingLeft: 0,
              spacingRight: 0
            },
            title: {
              text: ''
            },
            credits: {
              enabled: false
            },
            legend: {
              enabled: false
            },
            yAxis: {
              title: {
                text: ''
              },
              labels: {
                enabled: false
              }
            },

            xAxis: {
              categories: yearLabel,
              tickInterval: 1,
              labels: {
                autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
              }
            },

            series: [{
              name: 'PAT',
              data: patData
            }, {
              name: 'EPS',
              data: epsData
            }],
          };
        }
      }
    });
  }

  getAnnualTrendReturnsRatios() {
    try {
      this.keyRatiosChartTrend = "Yearly";
      this.ratioTypeLabel = 'Returns Ratios';
      this.ratioType = 'ReturnsRatios';
      var self = this
      this.financialDataService.getLastFiveYearsKeyRatios().then(responseData => {

        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var annualTrendReturnsRatios = _.filter(responseData.keyRatios);
            annualTrendReturnsRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });

            var yearLabel = [];
            var roaData = [];
            var roeData = [];
            var roceData = [];
            var roicData = [];
            _.forEach(annualTrendReturnsRatios, function (value) {

              roaData.push(value.financialData[0].fr_ROA);
              roeData.push(value.financialData[0].fr_ROE);
              roceData.push(value.financialData[0].fr_ROCE);
              // roicData.push(value.financialData[0].fr_ROIC);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);



              if (self.companyFinancialYearFromMonthGlobel == 1) {
                switch (quater) {
                  case '03':
                    quater = 'Q1';
                    break;
                  case '06':
                    quater = 'Q2';
                    break;
                  case '09':
                    quater = 'Q3';
                    break;
                  case '12':
                    quater = 'Q4';
                    break;
                  default:
                    break;
                }

                yearLabel.push('Dec ' + year);
                // value.FinancialQuater = 'Dec ' + year;

              } else if (self.companyFinancialYearFromMonthGlobel == 7) {

                switch (quater) {
                  case '09':
                    quater = 'Q1';
                    break;
                  case '12':
                    quater = 'Q2';
                    break;
                  case '03':
                    quater = 'Q3';
                    //   year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q4';
                    //    year = (year - 1).toString();
                    break;
                  default:
                    break;
                }

                yearLabel.push('June ' + year);
                // value.FinancialQuater = 'June ' + year;

              } else {
                switch (quater) {
                  case '03':
                    quater = 'Q4';
                    // year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q1';
                    break;
                  case '09':
                    quater = 'Q2';
                    break;
                  case '12':
                    quater = 'Q3';
                    break;
                  default:
                    break;
                }
                yearLabel.push('March ' + year);
                // value.FinancialQuater = 'March ' + year;
              }
              // switch (quater) {
              //   case '03':
              //     quater = 'Q1';
              //     break;
              //   case '06':
              //     quater = 'Q2';
              //     break;
              //   case '09':
              //     quater = 'Q3';
              //     break;
              //   case '12':
              //     quater = 'Q4';
              //     break;
              //   default:
              //     break;
              // }

              // yearLabel.push("March " + year);
              // yearLabel.push(value._id);
            });
            //  
            this.returnsRatiosYearlyChart = {
              chart: {
                renderTo: 'returnsRatiosYearlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },

              xAxis: {
                categories: yearLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },

              series: [{
                name: 'ROA',
                data: roaData
              }, {
                name: 'ROE',
                data: roeData
              }, {
                name: 'ROCE',
                data: roceData
              }
                // , {
                //   name: 'ROIC',
                //   data: roicData
                // }
              ],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  getInternalAndRFactorScore() {
    const self = this;
    try {

      // currentStateAnalysis/getCategoryScore
      this.financialDataService.getInternalAndRFactorScore().then(responseData => {

        if (responseData.status == 0) {
          var count = 0;

          if (responseData.categoryList != null && _.size(responseData.categoryList) > 0) {
            var internalDataScore: any;
            var rFactorDataScore: any;
            responseData.categoryList.forEach(element => {
              if (element.AssessmentType == "Internal") {
                if (element.CompositeScore == undefined || element.CompositeScore == null || element.CompositeScore < 0) {
                  this.internalScoreStatus = false;
                  internalDataScore = 0;
                } else {
                  internalDataScore = parseFloat(element.CompositeScore.toFixed(2));
                  self.internalAssessmentId = element._id;
                  document.getElementById("internalScoreChart").style.width = String(internalDataScore) + "%";
                  document.getElementById("internalCount").innerHTML = String(internalDataScore) + "%";
                }
              }
              else if (element.AssessmentType == "R-Factor") {
                if (element.CompositeScore == undefined || element.CompositeScore == null || element.CompositeScore < 0) {
                  this.rFactorScoreStatus = false;
                  rFactorDataScore = 0;
                } else {
                  // rFactorDataScore = parseFloat(element.CompositeScore.toFixed(2));
                  // self.RFactorAssessmentId = element._id;
                  // document.getElementById("rFactorScoreChart").style.width = String(rFactorDataScore) + "%";
                  // document.getElementById("rFactorCount").innerHTML = String(rFactorDataScore) + "%";
                }
              }
            });

            if (this.internalScoreStatus) {
              this.internalScoreChart = {
                chart: {
                  renderTo: 'internalScoreContainer',
                  type: 'gauge',
                  height: 170,
                  plotBackgroundColor: null,
                  plotBackgroundImage: null,
                  plotBorderWidth: 0,
                  plotShadow: false,
                  spacingTop: 0,
                  spacingBottom: 0,
                  spacingLeft: 0,
                  spacingRight: 0,
                },
                title: {
                  text: 'Internal',
                  style: {
                    fontWeight: 'bold',
                    fontSize: 15
                  },
                  verticalAlign: 'bottom',
                  margin: 0,
                  y: -5
                },
                credits: {
                  enabled: false
                },
                pane: {
                  startAngle: -150,
                  endAngle: 150,
                  background: [{
                    backgroundColor: null,
                    borderWidth: null
                  }]
                },
                // the value axis
                yAxis: {
                  min: 0,
                  max: 100,

                  minorTickInterval: 'auto',
                  minorTickWidth: 1,
                  minorTickLength: 10,
                  minorTickPosition: 'inside',
                  //minorTickColor: '#666',
                  minorTickColor: 'rgba(0,0,0,0.2)',
                  tickPixelInterval: 30,
                  tickWidth: 2,
                  tickPosition: 'inside',
                  tickLength: 10,
                  //tickColor: '#666',
                  tickColor: 'rgba(0,0,0,0.1)',
                  labels: {
                    step: 2,
                    rotation: 'auto'
                  },
                  plotBands: [{
                    from: 0,
                    to: 35,
                    //color: '#DF5353' // green
                    color: '#ec403d'
                  }, {
                    from: 35,
                    to: 80,
                    //color: '#DDDF0D' // yellow
                    color: '#fb8819'

                  }, {
                    from: 80,
                    to: 100,
                    //color: '#55BF3B' // red
                    color: '#15a843'
                  }]
                },

                series: [{
                  name: 'Internal Score',
                  data: [internalDataScore],
                  dataLabels: {
                    enabled: true,
                    backgroundColor: '#151323',
                    //color:'#c8bdff',
                    color: '#c8bdff',
                    borderWidth: null,
                    y: 20
                  },
                  tooltip: {
                    valueSuffix: '%'
                  }
                }]
              };
            }

            if (this.rFactorScoreStatus) {
              this.rFactorScoreChart = {
                chart: {
                  renderTo: 'rFactorScoreContainer',
                  type: 'gauge',
                  height: 170,
                  plotBackgroundColor: null,
                  plotBackgroundImage: null,
                  plotBorderWidth: 0,
                  plotShadow: false,
                  spacingTop: 0,
                  spacingBottom: 0,
                  spacingLeft: 0,
                  spacingRight: 0,
                },
                title: {
                  text: 'R-Factor',
                  style: {
                    fontWeight: 'bold',
                    fontSize: 15
                  },
                  verticalAlign: 'bottom',
                  margin: 0,
                  y: -5
                },
                credits: {
                  enabled: false
                },
                pane: {
                  startAngle: -150,
                  endAngle: 150,
                  background: [{
                    backgroundColor: null,
                    borderWidth: null
                  }]
                },
                // the value axis
                yAxis: {
                  min: 0,
                  max: 100,

                  minorTickInterval: 'auto',
                  minorTickWidth: 1,
                  minorTickLength: 10,
                  minorTickPosition: 'inside',
                  //minorTickColor: '#666',
                  minorTickColor: 'rgba(0,0,0,0.2)',

                  tickPixelInterval: 30,
                  tickWidth: 2,
                  tickPosition: 'inside',
                  tickLength: 10,
                  //tickColor: '#666',
                  tickColor: 'rgba(0,0,0,0.1)',
                  labels: {
                    step: 2,
                    rotation: 'auto'
                  },
                  plotBands: [{
                    from: 0,
                    to: 35,
                    //color: '#DF5353' // green
                    color: '#ec403d'
                  }, {
                    from: 35,
                    to: 80,
                    //color: '#DDDF0D' // yellow
                    color: '#fb8819'
                  }, {
                    from: 80,
                    to: 100,
                    //color: '#55BF3B' // red
                    color: '#15a843'
                  }]
                },

                series: [{
                  name: 'R-Factor Score',
                  data: [rFactorDataScore],
                  dataLabels: {
                    enabled: true,
                    // backgroundColor: null,
                    borderWidth: null,
                    color: '#c8bdff',
                    y: 20
                  },
                  tooltip: {
                    valueSuffix: '%'
                  }
                }]
              };
            }
          } else {
            this.internalScoreStatus = false;
            this.rFactorScoreStatus = false;
          }
        } else {
          this.internalScoreStatus = false;
          this.rFactorScoreStatus = false;

        }
      });
    } catch (error) { }
  }

  navigateTo(navigateToKey) {
    if (navigateToKey == 'StockPrice') {
      // this.router.navigate(['/dashboards/alpha/stockPrice']);
      this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha/stockPrice' } }]);
    } else if (navigateToKey == 'FinancialResults') {
      // this.router.navigate(['/dashboards/alpha/financialResults']);
      this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha/financialResults' } }]);
    } else if (navigateToKey == 'KeyRatios') {
      // this.router.navigate(['/dashboards/alpha/keyRatios']);
      this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha/keyRatios' } }]);
    } else if (navigateToKey == 'Internal') {
      this.router.navigate(['structure', { outlets: { 'structure-outlet': ['assessmentCategoryScore', 'Internal', this.internalAssessmentId] } }]);
    } else if (navigateToKey == 'RFactor') {
      this.router.navigate(['structure', { outlets: { 'structure-outlet': ['assessmentCategoryScore', 'R-Factor', this.RFactorAssessmentId] } }]);
    } else if (navigateToKey == 'orgchart') {
      this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha/orgchart' } }]);
    }
  }

  financialRatioPnLChart(trend) {
    if (trend == 'Quarterly') {
      this.getFinancialResultQuarterlyData();
    } else if (trend == 'Yearly') {
      this.getFinancialResultYearlyData();
    }
  }

  keyRatiosChart(chartType) {
    if (chartType == "EarningsRatios") {
      if (this.keyRatiosChartTrend == "Quarterly") {
        this.getQuarterlyTrendEarningsRatios();
      } else if (this.keyRatiosChartTrend == "Yearly") {
        this.getAnnualTrendEarningsRatios();
      }
    } else if (chartType == "MarginRatios") {
      if (this.keyRatiosChartTrend == "Quarterly") {
        this.getQuarterlyTrendMarginRatios();
      } else if (this.keyRatiosChartTrend == "Yearly") {
        this.getAnnualTrendMarginRatios();
      }
    } else if (chartType == "GrowthRatios") {
      this.getAnnualTrendGrowthRatios();
      // if (this.keyRatiosChartTrend == "Quarterly") {
      //   // this.getQuarterlyTrendGrowthRatios();
      // } else if (this.keyRatiosChartTrend == "Yearly") {
      //   this.getAnnualTrendGrowthRatios();
      // }
    } else if (chartType == "ReturnsRatios") {
      this.getAnnualTrendReturnsRatios();
    }
  }

  keyRatiosChartTrendChange(trend) {
    if (trend == 'Quarterly') {
      if (this.ratioType == 'EarningsRatios') {
        this.getQuarterlyTrendEarningsRatios();
      } else if (this.ratioType == 'MarginRatios') {
        this.getQuarterlyTrendMarginRatios();
      } else if (this.ratioType == 'GrowthRatios') {
        this.getAnnualTrendGrowthRatios();
      }
    } else if (trend == 'Yearly') {
      if (this.ratioType == 'EarningsRatios') {
        this.getAnnualTrendEarningsRatios();
      } else if (this.ratioType == 'MarginRatios') {
        this.getAnnualTrendMarginRatios();
      } else if (this.ratioType == 'GrowthRatios') {
        this.getAnnualTrendGrowthRatios();
      } else if (this.ratioType == 'ReturnsRatios') {
        this.getAnnualTrendReturnsRatios();
      }
    }
  }

  getQuarterlyTrendEarningsRatios() {
    try {
      this.keyRatiosChartTrend = "Quarterly";
      this.ratioTypeLabel = 'Earnings Ratios';
      this.ratioType = 'EarningsRatios';
      this.financialDataService.getLastFiveQuatersKeyRatios().then(responseData => {
        //  
        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var quarterlyTrendEarningsRatios = _.filter(responseData.keyRatios);
            quarterlyTrendEarningsRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });
            var quaterLabel = [];
            var epsData = [];
            var cepsData = [];
            var dpsData = [];
            _.forEach(quarterlyTrendEarningsRatios, function (value) {
              //  
              // epsData.push(value.financialData[0].qr_EPSABS);
              epsData.push(value.financialData[0].qr_EPS_BASIC);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);
              switch (quater) {
                case '03':
                  quater = 'Q1';
                  break;
                case '06':
                  quater = 'Q2';
                  break;
                case '09':
                  quater = 'Q3';
                  break;
                case '12':
                  quater = 'Q4';
                  break;
                default:
                  break;
              }

              quaterLabel.push(quater + ' ' + year);
              // quaterLabel.push(value._id);
            });
            //  
            this.earningsRatiosQuarterlyChart = {
              chart: {
                renderTo: 'earningsRatiosQuarterlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: quaterLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },
              series: [{
                name: 'BASIC_EPS',
                data: epsData
              }, {
                name: 'CEPS',
                data: cepsData
              }, {
                name: 'DPS',
                data: dpsData
              }],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  getAnnualTrendEarningsRatios() {
    try {
      this.keyRatiosChartTrend = "Yearly";
      this.ratioTypeLabel = 'Earnings Ratios';
      this.ratioType = 'EarningsRatios';
      var self = this;
      this.financialDataService.getLastFiveYearsKeyRatios().then(responseData => {
        //  
        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var annualTrendEarningsRatios = _.filter(responseData.keyRatios);
            annualTrendEarningsRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });
            //              
            var yearLabel = [];
            var epsData = [];
            var cepsData = [];
            var dpsData = [];
            _.forEach(annualTrendEarningsRatios, function (value) {
              //  
              epsData.push(value.financialData[0].fr_Reported_EPS);
              cepsData.push(value.financialData[0].fr_CEPS);
              dpsData.push(value.financialData[0].fr_DPS);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);

              if (self.companyFinancialYearFromMonthGlobel == 1) {
                switch (quater) {
                  case '03':
                    quater = 'Q1';
                    break;
                  case '06':
                    quater = 'Q2';
                    break;
                  case '09':
                    quater = 'Q3';
                    break;
                  case '12':
                    quater = 'Q4';
                    break;
                  default:
                    break;
                }

                yearLabel.push('Dec ' + year);
                // value.FinancialQuater = 'Dec ' + year;

              } else if (self.companyFinancialYearFromMonthGlobel == 7) {

                switch (quater) {
                  case '09':
                    quater = 'Q1';
                    break;
                  case '12':
                    quater = 'Q2';
                    break;
                  case '03':
                    quater = 'Q3';
                    //   year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q4';
                    //    year = (year - 1).toString();
                    break;
                  default:
                    break;
                }

                yearLabel.push('June ' + year);
                // value.FinancialQuater = 'June ' + year;

              } else {
                switch (quater) {
                  case '03':
                    quater = 'Q4';
                    // year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q1';
                    break;
                  case '09':
                    quater = 'Q2';
                    break;
                  case '12':
                    quater = 'Q3';
                    break;
                  default:
                    break;
                }
                yearLabel.push('March ' + year);
                // value.FinancialQuater = 'March ' + year;
              }
              // switch (quater) {
              //   case '03':
              //     quater = 'Q1';
              //     break;
              //   case '06':
              //     quater = 'Q2';
              //     break;
              //   case '09':
              //     quater = 'Q3';
              //     break;
              //   case '12':
              //     quater = 'Q4';
              //     break;
              //   default:
              //     break;
              // }

              // yearLabel.push("March " + year);
              // yearLabel.push(value._id);
            });
            //  
            this.earningsRatiosYearlyChart = {
              chart: {
                renderTo: 'earningsRatiosYearlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: yearLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },
              series: [{
                name: 'EPS',
                data: epsData
              }, {
                name: 'CEPS',
                data: cepsData
              }, {
                name: 'DPS',
                data: dpsData
              }],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  getQuarterlyTrendMarginRatios() {
    try {
      this.keyRatiosChartTrend = "Quarterly";
      this.ratioTypeLabel = 'Margin Ratios';
      this.ratioType = 'MarginRatios';
      this.financialDataService.getLastFiveQuatersKeyRatios().then(responseData => {
        //  
        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var quarterlyTrendMarginRatios = _.filter(responseData.keyRatios);
            quarterlyTrendMarginRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });
            //              
            var quaterLabel = [];
            var ebidtaData = [];
            var pbitData = [];
            var patData = [];
            _.forEach(quarterlyTrendMarginRatios, function (value) {
              //  
              // ebidtaData.push(value.financialData[0].qr_OPERATING_PROFIT_MARGIN);
              ebidtaData.push(value.financialData[0].qr_PBIDTMEXOI);
              patData.push(value.financialData[0].qr_PATM);
              // patData.push(value.financialData[0].qr_NET_PROFIT_MARGIN);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);
              switch (quater) {
                case '03':
                  quater = 'Q1';
                  break;
                case '06':
                  quater = 'Q2';
                  break;
                case '09':
                  quater = 'Q3';
                  break;
                case '12':
                  quater = 'Q4';
                  break;
                default:
                  break;
              }

              quaterLabel.push(quater + ' ' + year);
              // quaterLabel.push(value._id);
            });
            //  
            this.marginRatiosQuarterlyChart = {
              chart: {
                renderTo: 'marginRatiosQuarterlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: quaterLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },
              series: [{
                name: 'EBIDTA',
                data: ebidtaData
              }, {
                name: 'PBIT',
                data: pbitData
              }, {
                name: 'PAT',
                data: patData
              }],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  getAnnualTrendMarginRatios() {
    try {
      this.keyRatiosChartTrend = "Yearly";
      this.ratioTypeLabel = 'Margin Ratios';
      this.ratioType = 'MarginRatios';
      var self = this;
      this.financialDataService.getLastFiveYearsKeyRatios().then(responseData => {
        //  
        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var annualyTrendMarginRatios = _.filter(responseData.keyRatios);
            annualyTrendMarginRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });
            //              
            var yearLabel = [];
            var ebidtaData = [];
            var pbitData = [];
            var patData = [];
            _.forEach(annualyTrendMarginRatios, function (value) {
              //  
              ebidtaData.push(value.financialData[0].fr_Core_EBITDA_Margin);
              pbitData.push(value.financialData[0].fr_EBIT_Margin);
              patData.push(value.financialData[0].fr_PAT_Margin);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);

              if (self.companyFinancialYearFromMonthGlobel == 1) {
                switch (quater) {
                  case '03':
                    quater = 'Q1';
                    break;
                  case '06':
                    quater = 'Q2';
                    break;
                  case '09':
                    quater = 'Q3';
                    break;
                  case '12':
                    quater = 'Q4';
                    break;
                  default:
                    break;
                }

                yearLabel.push('Dec ' + year);
                // value.FinancialQuater = 'Dec ' + year;

              } else if (self.companyFinancialYearFromMonthGlobel == 7) {

                switch (quater) {
                  case '09':
                    quater = 'Q1';
                    break;
                  case '12':
                    quater = 'Q2';
                    break;
                  case '03':
                    quater = 'Q3';
                    //   year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q4';
                    //    year = (year - 1).toString();
                    break;
                  default:
                    break;
                }

                yearLabel.push('June ' + year);
                // value.FinancialQuater = 'June ' + year;

              } else {
                switch (quater) {
                  case '03':
                    quater = 'Q4';
                    // year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q1';
                    break;
                  case '09':
                    quater = 'Q2';
                    break;
                  case '12':
                    quater = 'Q3';
                    break;
                  default:
                    break;
                }
                yearLabel.push('March ' + year);
                // value.FinancialQuater = 'March ' + year;
              }
              // switch (quater) {
              //   case '03':
              //     quater = 'Q1';
              //     break;
              //   case '06':
              //     quater = 'Q2';
              //     break;
              //   case '09':
              //     quater = 'Q3';
              //     break;
              //   case '12':
              //     quater = 'Q4';
              //     break;
              //   default:
              //     break;
              // }

              // yearLabel.push("March " + year);
              // yearLabel.push(value._id);
            });
            //  
            this.marginRatiosYearlyChart = {
              chart: {
                renderTo: 'marginRatiosYearlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: yearLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },

              series: [{
                name: 'EBIDTA',
                data: ebidtaData
              }, {
                name: 'PBIT',
                data: pbitData
              }, {
                name: 'PAT',
                data: patData
              }],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  // getQuarterlyTrendGrowthRatios() {
  //   try {
  //     this.keyRatiosChartTrend = "Quarterly";
  //     this.ratioTypeLabel = 'Growth Ratios';
  //     this.ratioType = 'GrowthRatios';
  //     this.financialDataService.getLastFiveQuatersKeyRatios().then(responseData => {
  //       //  
  //       if (responseData.status == 0) {
  //         var count = 0;
  //         if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
  //           var quarterlyTrendGrowthRatios = _.filter(responseData.keyRatios);
  //           quarterlyTrendGrowthRatios.forEach(element => {
  //             _.remove(element.financialData, function (a: any) { return a.Type == "C" });
  //           });
  //           //              
  //           var quaterLabel = [];
  //           var netSalesGrowthData = [];
  //           var patGrowthData = [];
  //           _.forEach(quarterlyTrendGrowthRatios, function (value) {
  //             //  

  //             var financialQuater = value._id.toString();
  //             var year = financialQuater.substr(0, 4);
  //             var quater = financialQuater.substr(4, 2);
  //             switch (quater) {
  //               case '03':
  //                 quater = 'Q1';
  //                 break;
  //               case '06':
  //                 quater = 'Q2';
  //                 break;
  //               case '09':
  //                 quater = 'Q3';
  //                 break;
  //               case '12':
  //                 quater = 'Q4';
  //                 break;
  //               default:
  //                 break;
  //             }

  //             quaterLabel.push(quater + ' ' + year);
  //             // quaterLabel.push(value._id);
  //           });
  //           //  
  //           this.growthRatiosQuarterlyChart = {
  //             chart: {
  //               renderTo: 'growthRatiosQuarterlyContainer',
  //               height: 130,
  //               spacingTop: 0,
  //               spacingBottom: 0,
  //               spacingLeft: 0,
  //               spacingRight: 0
  //             },
  //             title: {
  //               text: ''
  //             },
  //             credits: {
  //               enabled: false
  //             },
  //             legend: {
  //               enabled: false
  //             },
  //             yAxis: {
  //               title: {
  //                 text: ''
  //               },
  //               labels: {
  //                 enabled: false
  //               }
  //             },
  //             xAxis: {
  //               categories: quaterLabel,
  //               tickInterval: 1,
  //               labels: {
  //                 autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
  //               }
  //             },
  //             series: [{
  //               name: 'Net Sales Growth',
  //               data: netSalesGrowthData
  //             }, {
  //               name: 'PAT Growth',
  //               data: patGrowthData
  //             }],
  //           };
  //         }
  //       }
  //     });
  //   } catch (error) {

  //   }
  // }

  getAnnualTrendGrowthRatios() {
    try {
      this.keyRatiosChartTrend = "Yearly";
      this.ratioTypeLabel = 'Growth Ratios';
      this.ratioType = 'GrowthRatios';
      var self = this;
      this.financialDataService.getLastFiveYearsKeyRatios().then(responseData => {
        //  
        if (responseData.status == 0) {
          var count = 0;
          if (responseData.keyRatios != null && _.size(responseData.keyRatios) > 0) {
            var annualTrendGrowthRatios = _.filter(responseData.keyRatios);
            annualTrendGrowthRatios.forEach(element => {
              _.remove(element.financialData, function (a: any) { return a.Type == "C" });
            });
            //              
            var yearLabel = [];
            var netSalesGrowthData = [];
            var patGrowthData = [];
            _.forEach(annualTrendGrowthRatios, function (value) {
              //  
              netSalesGrowthData.push(value.financialData[0].fr_Net_Sales_Growth);
              patGrowthData.push(value.financialData[0].fr_PAT_Growth);

              var financialQuater = value._id.toString();
              var year = financialQuater.substr(0, 4);
              var quater = financialQuater.substr(4, 2);

              if (self.companyFinancialYearFromMonthGlobel == 1) {
                switch (quater) {
                  case '03':
                    quater = 'Q1';
                    break;
                  case '06':
                    quater = 'Q2';
                    break;
                  case '09':
                    quater = 'Q3';
                    break;
                  case '12':
                    quater = 'Q4';
                    break;
                  default:
                    break;
                }

                yearLabel.push('Dec ' + year);
                // value.FinancialQuater = 'Dec ' + year;

              } else if (self.companyFinancialYearFromMonthGlobel == 7) {

                switch (quater) {
                  case '09':
                    quater = 'Q1';
                    break;
                  case '12':
                    quater = 'Q2';
                    break;
                  case '03':
                    quater = 'Q3';
                    //   year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q4';
                    //    year = (year - 1).toString();
                    break;
                  default:
                    break;
                }

                yearLabel.push('June ' + year);
                // value.FinancialQuater = 'June ' + year;

              } else {
                switch (quater) {
                  case '03':
                    quater = 'Q4';
                    // year = (year - 1).toString();
                    break;
                  case '06':
                    quater = 'Q1';
                    break;
                  case '09':
                    quater = 'Q2';
                    break;
                  case '12':
                    quater = 'Q3';
                    break;
                  default:
                    break;
                }
                yearLabel.push('March ' + year);
                // value.FinancialQuater = 'March ' + year;
              }
              // switch (quater) {
              //   case '03':
              //     quater = 'Q1';
              //     break;
              //   case '06':
              //     quater = 'Q2';
              //     break;
              //   case '09':
              //     quater = 'Q3';
              //     break;
              //   case '12':
              //     quater = 'Q4';
              //     break;
              //   default:
              //     break;
              // }

              // yearLabel.push("March " + year);
              // yearLabel.push(value._id);
            });
            //  
            this.growthRatiosYearlyChart = {
              chart: {
                renderTo: 'growthRatiosYearlyContainer',
                height: 130,
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: ''
                },
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: yearLabel,
                tickInterval: 1,
                labels: {
                  autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                }
              },
              series: [{
                name: 'Net Sales Growth',
                data: netSalesGrowthData
              }, {
                name: 'PAT Growth',
                data: patGrowthData
              }],
            };
          }
        }
        else {
          this.ratioType = 'false';
        }
      });
    } catch (error) {

    }
  }

  updatedTask(data) {
    this.refreshMeter = data;
    this.taskObject = data;
  }

  switchToSubsidiary(fincode, subCompanyName) {

    $(".tab-pane").addClass("active");
    $(".combine").removeClass("active");
    $(".tab-pane").attr("id", subCompanyName);
    $(".combine").attr("id", "");
    localStorage.setItem('subsidiaryFincode', fincode)
    var isSubsidiary = localStorage.getItem('isSubsidiary')
    localStorage.removeItem('isSubsidiary')
    localStorage.setItem('isSubsidiary', "true");
    localStorage.setItem('subsidiaryCompanyName', subCompanyName);
    this.getCompanyData();
  }

  demo(company) {

    $(".tab-pane").addClass("active");
    $(".combine").removeClass("active");
    $(".tab-pane").attr("id", company);
    $(".combine").attr("id", "");
    localStorage.setItem('isSubsidiary', "false")
    localStorage.removeItem('subsidiaryCompanyName')
    localStorage.removeItem('subsidiaryFincode')
    this.getCompanyData();

  }

  // getAllData(company){
  //    
  //   $(".combine").attr("id", "");
  //   $(".combine").attr("id", "combine");
  //   $(".combine").addClass("active");
  //   localStorage.setItem('isSubsidiary', "false")
  //   localStorage.removeItem('subsidiaryCompanyName')
  //   localStorage.removeItem('subsidiaryFincode')

  // }

}