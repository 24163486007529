import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "./../../environments/environment";
import { CommonFunction } from "../helpers/commonFunction";

@Injectable()
export class SmtpService {
  constructor(private httpWithoutToken: HttpClient, private http: HttpClient, public commonFunction: CommonFunction) {}

  getSmtpDetails(): Promise<any> {
    // const url = environment.clientUrl + 'userMaster/getUserList';
    const url = environment.nonValidateUrl + "get/smtp";
    // const url = "http://localhost:5000/api/v1/get/smtp;

    return this.httpWithoutToken
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  updateSmtpDetails(smtp): Promise<any> {
    const url = environment.nonValidateUrl + "edit";

    return this.httpWithoutToken
      .put(`${url}/${smtp._id}`, smtp)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  // public getSmtpDetails2()
  // {
  //   //const url = environment.nonValidateUrl + 'get/smtp';
  //   const url = "http://localhost:5000/api/v1/get/smtp";
  //   return this.http.get(url);
  // }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  updateAuthConfig(data): Promise<any>{
    const url = environment.nonValidateUrl + 'companyMaster/auth/config';
    const encryptedToken = this.commonFunction.encrypt(JSON.stringify(data));
    return this.http.put(`${url}`, {encryptedToken})
      .toPromise()
      .then((response: any) => {
        return Promise.resolve(response);
      })
  }

  getAuthConfig(id) : Promise<any>{
    const url = environment.nonValidateUrl + 'companyMaster/auth/config/' + id;
    console.log("subscribe", id)
    return this.http.get(`${url}`)
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
  }

  getCompanyConfig(id) : any{
    const url = environment.nonValidateUrl + 'companyMaster/auth/config/' + id;
    console.log("subscribe", id)
    return this.http.get(`${url}`).toPromise()
    .then(response => {  return Promise.resolve(response)});
      // .toPromise()
      // .then(response => {
      //   return Promise.resolve(response);
      // })
  }
}
