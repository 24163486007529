import {
  Component,
  OnInit,
  Input,
  DoCheck,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GfmService } from '../../../services/gfm.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { forkJoin } from 'rxjs';

import { GfmconfirmationPopupComponent } from '../gfmconfirmation-popup/gfmconfirmation-popup.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-tag-popup-component',
  templateUrl: './tag-popup-component.component.html',
  styleUrls: ['./tag-popup-component.component.css'],
})
export class TagPopupComponentComponent implements OnInit, DoCheck {
  @Input('fileDetail') fileDetail: any;

  @Input('complianceEngine') complianceEngine: any;
  @Input('complianceEngineUrl') complianceEngineUrl: any;

  @Input('governanceUrl') governanceUrl: any;
  @Input('gemAccessURL') gemAccessURL: any;
  @Input('isFolderCreation') isFolderCreation: boolean;

  @Output() tagPopupCloseParameter: EventEmitter<any> = new EventEmitter();

  taglist: any = [];
  showAddCustomDiv: Boolean = false;
  newTagName: any = '';
  savedTagObjectResponse: any = {};
  selectedTagForFile: any = [];
  selectedTagId: any = []; // it is for mapping with drop down list of selected Tag
  selectedTagNameToRemove: any = '';
  selectedTagIdToRemove: any = '';
  savedTag: any = [];
  showTags: Boolean = false;
  addTagForm: FormGroup;

  tagAlreadyPresent: Boolean = false;
  tagAlreadyInUse: Boolean = false;

  selectedTagsCacheCount: any;
  disabledOnclick: boolean;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private gfmservice: GfmService,
    private companyMasterService: CompanyMasterService
  ) {}

  ngOnInit() {
    this.addTagForm = new FormGroup({
      tagName: new FormControl('', []),
    });

    this.getAllGFMTagList();
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  getAllGFMTagList() {
    this.savedTag = [];
    var tagCounts = _.size(this.fileDetail.tags);
    this.selectedTagsCacheCount = tagCounts;
    this.gfmservice
      .getAllGFMTags(
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((tagsResponse) => {
        console.log('tagsResponse', tagsResponse);
        this.taglist = tagsResponse.fgmTagList.map(function (element) {
          return {
            label: _.get(element, 'tagName'),
            value: _.get(element, 'gfmTagId'),
          };
        });

        // if(tagCounts > 0){
        //   // this.showAddCustomDiv = true;
        var promiseArray = [];

        var index = 0;

        for (index; index < tagCounts; index++) {
          promiseArray.push(
            this.gfmservice.getTagDetailsByTagId(
              this.fileDetail.tags[index],
              this.complianceEngine,
              this.complianceEngineUrl,
              this.governanceUrl,
              this.gemAccessURL
            )
          );
        }

        forkJoin(promiseArray).subscribe((responsearray) => {
          this.selectedTagForFile = responsearray.map(function (element) {
            return {
              label: _.get(element['gfmTag'], 'tagName'),
              value: _.get(element['gfmTag'], 'gfmTagId'),
            };
          });

          if (_.size(this.selectedTagForFile) > 0) {
            this.showTags = true;
          }

          this.selectedTagId = responsearray.map(function (element) {
            return _.get(element['gfmTag'], 'gfmTagId');
          });
        });
      });
  }

  addCustomTagDiv() {
    this.showAddCustomDiv = true;
    this.newTagName = '';
    this.addTagForm.controls.tagName.reset();
    this.addTagForm.controls.tagName.setValidators([Validators.required]);
    this.addTagForm.controls.tagName.updateValueAndValidity();
    console.log(
      'this.addTagForm.controls.tagName',
      this.addTagForm.controls.tagName
    );
  }

  removeCustomTagDiv() {
    this.showAddCustomDiv = false;
    this.newTagName = '';
    this.addTagForm.controls.tagName.reset();
    this.addTagForm.controls.tagName.clearValidators();
    this.addTagForm.controls.tagName.updateValueAndValidity();
  }

  ngDoCheck() {
    if (_.size(this.selectedTagForFile) > 0) {
      this.showTags = true;
    } else {
      this.showTags = false;
    }
    // console.log("this.showTags",this.showTags)
  }

  // this will add tag name into database irrespective of Files
  saveTags() {
    var self = this;
    console.log('form', this.addTagForm.controls.tagName.value);

    this.gfmservice
      .createTag(
        this.newTagName,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((responseSavedTag) => {
        if (responseSavedTag == 500) {
          this.tagAlreadyPresent = true;
        } else {
          this.tagAlreadyPresent = false;
        }

        this.newTagName = '';
        //  console.log("responseSavedTag",responseSavedTag)
        if (_.size(responseSavedTag) > 0) {
          this.savedTagObjectResponse = <Object>responseSavedTag;

          self.savedTag.push(responseSavedTag);
          this.selectedTagForFile.push({
            value: responseSavedTag.gfmTag.gfmTagId,
            label: responseSavedTag.gfmTag.tagName,
          });
          var tempTagList = [];
          tempTagList = this.taglist;
          tempTagList.push({
            label: responseSavedTag.gfmTag.tagName,
            value: responseSavedTag.gfmTag.gfmTagId,
          });
          // tempTagList = this.taglist;
          tempTagList = tempTagList.map(function (tagElement) {
            return { label: tagElement.label, value: tagElement.value };
          });
          this.taglist = [];
          this.taglist = tempTagList;
          // push({ "label" : responseSavedTag.tagName , "value" : responseSavedTag.gfmTagId });
          this.selectedTagId.push(responseSavedTag.gfmTag.gfmTagId);

          var selectedListTag = [];
          selectedListTag = this.selectedTagForFile.map((element) => {
            return element.value;
          });
          this.selectedTagId = selectedListTag;
          // console.log("this.savedTag",this.savedTag , this.selectedTagForFile,this.taglist,this.selectedTagId)
        }
      });
  }

  close() {
    if (this.isFolderCreation) {
      this.activeModal.close();
    } else {
      var recordPath = this.getRecordPath();
      this.tagPopupCloseParameter.emit(recordPath);
      this.activeModal.close(recordPath);
    }
  }

  // this will add selected Tags to File
  saveTagsToFile() {
    this.disabledOnclick = true;
    var tags = [];

    if (_.size(this.selectedTagForFile)) {
      _.forEach(this.selectedTagForFile, function (tagElement) {
        tags.push(tagElement.value);
      });
    }

    var recordPath = this.getRecordPath();

    var object = {
      recordName: this.fileDetail.recordName,
      recordPath: recordPath,
      tags: tags,
    };

    if (this.fileDetail.recordType == 'file') {
      this.gfmservice
        .tagOperationsForFile(
          object,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.governanceUrl,
          this.gemAccessURL
        )
        .then((responseSavedTag) => {
          //  console.log("responseSavedTag",responseSavedTag)
          //console.log("error", error);
          this.disabledOnclick = false;
          this.tagPopupCloseParameter.emit(recordPath);
          this.activeModal.close(recordPath);

          const modalRef = this.modalService.open(
            GfmconfirmationPopupComponent,
            {
              backdrop: 'static',
            }
          );

          var message = '';
          if (this.selectedTagsCacheCount > _.size(this.selectedTagForFile)) {
            message = 'Tags removed successfully.';
          } else if (
            this.selectedTagsCacheCount < _.size(this.selectedTagForFile)
          ) {
            message = 'Tags added successfully.';
          } else {
            message = 'Tags updated successfully.';
          }

          modalRef.componentInstance.Message = message;
          modalRef.result.then(
            (resp) => {},
            (error) => {}
          );
        });
    } else {
      this.gfmservice
        .tagOperationsForFolder(
          object,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.governanceUrl,
          this.gemAccessURL
        )
        .then((responseSavedTag) => {
          //  console.log("responseSavedTag",responseSavedTag)
          //console.log("error", error);
          this.disabledOnclick = false;
          this.tagPopupCloseParameter.emit(recordPath);
          this.activeModal.close(recordPath);

          const modalRef = this.modalService.open(
            GfmconfirmationPopupComponent,
            {
              backdrop: 'static',
            }
          );

          var message = '';
          if (this.selectedTagsCacheCount > _.size(this.selectedTagForFile)) {
            message = 'Tags removed successfully.';
          } else if (
            this.selectedTagsCacheCount < _.size(this.selectedTagForFile)
          ) {
            message = 'Tags added successfully.';
          } else {
            message = 'Tags updated successfully.';
          }

          modalRef.componentInstance.Message = message;
          modalRef.result.then(
            (resp) => {},
            (error) => {}
          );
        });
    }
  }

  getRecordPath() {
    var recordName = this.fileDetail.recordName;
    var path = this.fileDetail.recordPath;

    var stringLocation = path.indexOf(recordName);
    var recordPath = '';
    if (stringLocation >= 0) {
      recordPath = path.substring(stringLocation - 1, -1);
      console.log('recordPath', recordPath);
    } else {
      recordPath = this.fileDetail.recordPath;
    }

    return recordPath;
  }

  multipleTagsDeselection(item) {
    // console.log("iem",item)
    let indexOfItem = this.selectedTagForFile.findIndex(
      (o) => o.value === item.value
    );
    // console.log("indexOfItem",indexOfItem)
    if (indexOfItem >= 0) {
      this.selectedTagForFile.splice(indexOfItem, 1);
    }

    var selectedListTag = [];
    selectedListTag = this.selectedTagForFile.map((element) => {
      return element.value;
    });
    this.selectedTagId = selectedListTag;

    // console.log("this.selectedTagForFile",this.selectedTagForFile, this.selectedTagId)
  }

  selectedTagList(item) {
    this.selectedTagForFile.push(item);
    // console.log(this.selectedTagForFile)
  }

  selectedTagListToRemove(item) {
    this.tagAlreadyInUse = false;
    this.selectedTagIdToRemove = item.value;
    this.selectedTagNameToRemove = item.label;
    console.log(this.selectedTagIdToRemove);
  }

  removeTagsFromList() {
    this.gfmservice
      .deleteTag(
        this.selectedTagIdToRemove,
        this.selectedTagNameToRemove,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((responseSavedTag) => {
        console.log('responseSavedTag', responseSavedTag);
        if (responseSavedTag.isDeleted == true) {
          this.tagAlreadyInUse = false;
          this.getAllGFMTagList();

          const modalRef = this.modalService.open(
            GfmconfirmationPopupComponent,
            {
              backdrop: 'static',
            }
          );

          var message = '';
          message = 'Tag Deleted successfully.';

          modalRef.componentInstance.Message = message;
          modalRef.result.then(
            (resp) => {},
            (error) => {}
          );
        } else if (responseSavedTag.status == 208) {
          this.tagAlreadyInUse = true;
        }
      });
  }

  saveTagsToFolder() {
    this.activeModal.close(this.selectedTagForFile);
  }
}
