<!-- START: pages/pricing-tables -->
<div class="cat__pricing-table mb-5">
    <div class="cat__pricing-table__item">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">40</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Standard</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>10GB</strong> of Bandwidth</li>
            <li><strong>200MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>256MB</strong> Memory</li>
            <li><strong>1 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-primary btn-lg" role="button">
                <i class="icmn-download mr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>

    <div class="cat__pricing-table__item cat__pricing-table__item--featured cat__pricing-table__item--primary">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">60</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Medium</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>20GB</strong> of Bandwidth</li>
            <li><strong>400MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>512</strong> Memory</li>
            <li><strong>2 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-outline-primary btn-lg" role="button">
                <i class="icmn-downloadmr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>


    <div class="cat__pricing-table__item">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">40</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Performance</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>30GB</strong> of Bandwidth</li>
            <li><strong>600MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>1024MB</strong> Memory</li>
            <li><strong>4 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-primary btn-lg" role="button">
                <i class="icmn-download mr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>


    <div class="cat__pricing-table__item cat__pricing-table__item--default">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">40</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Premium</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>50GB</strong> of Bandwidth</li>
            <li><strong>1000MB</strong> Max File Size</li>
            <li><strong>3GHZ</strong> CPU</li>
            <li><strong>4096MB</strong> Memory</li>
            <li><strong>8 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-outline-default btn-lg" role="button">
                <i class="icmn-download mr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>

</div>
<!-- End Pricing Tables -->

<!-- Pricing Tables -->
<div class="cat__pricing-table mb-5">
    <div class="cat__pricing-table__item">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">40</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Standard</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>10GB</strong> of Bandwidth</li>
            <li><strong>200MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>256MB</strong> Memory</li>
            <li><strong>1 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-outline-success btn-lg" role="button">
                <i class="icmn-download mr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>

    <div class="cat__pricing-table__item cat__pricing-table__item--featured">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">60</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Medium</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>20GB</strong> of Bandwidth</li>
            <li><strong>400MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>512</strong> Memory</li>
            <li><strong>2 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-danger btn-lg" role="button">
                <i class="icmn-download" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>


    <div class="cat__pricing-table__item">
        <div class="cat__pricing-table__header">
            <div class="cat__pricing-table__pricing__price">
                <span class="cat__pricing-table__pricing__currency">$</span>
                <span class="cat__pricing-table__pricing__amount">40</span>
                <span class="cat__pricing-table__pricing__period">/ mo</span>
            </div>
            <div class="cat__pricing-table__header__title">Performance</div>
        </div>
        <ul class="cat__pricing-table__features">
            <li><strong>30GB</strong> of Bandwidth</li>
            <li><strong>600MB</strong> Max File Size</li>
            <li><strong>2GHZ</strong> CPU</li>
            <li><strong>1024MB</strong> Memory</li>
            <li><strong>4 GB</strong> Storage</li>
        </ul>
        <div class="cat__pricing-table__footer">
            <a href="javascript: void(0);" class="btn btn-outline-success btn-lg" role="button">
                <i class="icmn-download mr-3" aria-hidden="true"></i>
                Buy Item
            </a>
        </div>
    </div>

</div>
<!-- END: pages/pricing-tables -->