import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()

export class AssessmentSetupService {

    constructor(private http: HttpClient) {

    }

    //get list of categories
    getCategoryList(assessmentType): Promise<any> {
        return this.http.get(environment.clientUrl + 'category/questionWithCategory?AssessmentType=' + assessmentType)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //get list of categories
    getCategoryListForModification(assessmentType): Promise<any> {
        return this.http.get(environment.clientUrl + 'category/questionWithCategoryForModification?AssessmentType=' + assessmentType)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //add new assessment
    addCurrentAssessment(newAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'currentAssessment/addcurrentAssessment', (newAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get details of current assessment list
    getCurrentAssesmentList(AssessmentType): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/currentAssessmentList?AssessmentType=' + AssessmentType)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get details of current assessment list by AssessmentID
    getAssesmentByAssessmentID(AssessmentID): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/getAssessmentByAssessmentId?AssessmentId=' + AssessmentID)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get Selected Category List By AssessmentID
    getSelectedCategoryListByAssessmentID(assessmentID): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/categoryList?AssessmentId=' + assessmentID)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //edit existing category assesment list. 
    editCurrentAssesmentList(existingAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'currentAssessment/editcurrentAssessment', (existingAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })

        // {'data': (existingAssessment), 'AssessmentId': AssessmentId}
    }

    //update Assessment status 
    editAssessmentStatusToUnderModification(existingAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'currentAssessment/editAssessmentStatusToUnderModification', (existingAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })

        // {'data': (existingAssessment), 'AssessmentId': AssessmentId}
    }

    //delete assessment category
    deleteAssessmentCategory(assessmentCategory): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/deleteAssessmentCategory?CategoryId=' + assessmentCategory.CategoryId + '&AssessmentId=' + assessmentCategory.AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //delete assessment category
    disableAssessmentCategoryAndQuestions(assessmentCategory): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/disableAssessmentCategoryAndQueations?CategoryId=' + assessmentCategory.CategoryId + '&AssessmentId=' + assessmentCategory.AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //delete assessment category
    deleteRFactorAssessmentCategory(assessmentCategory): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/deleteRFactorAssessmentCategory?CategoryId=' + assessmentCategory.CategoryId + '&AssessmentId=' + assessmentCategory.AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    
    //delete assessment category
    disableRFactorAssessmentCategoryAndRatios(assessmentCategory): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/disableRFactorAssessmentCategoryAndRatios?CategoryId=' + assessmentCategory.CategoryId + '&AssessmentId=' + assessmentCategory.AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get details of question withtags list
    getQuestionTagsList(arrayOfTagID): Promise<any> {
        var token = localStorage.getItem('token')
        let header: any = {'x-access-token': token};
        return this.http.post(environment.clientUrl + 'questionAssessment/questionWithTagsList', (arrayOfTagID), {headers: header})
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get details of tags list
    getTagsList(): Promise<any> {
        var token = localStorage.getItem('token')
        return this.http.get(environment.clientUrl + 'questionAssessment/tagsList', {headers: {'x-access-token': token}})
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }


    //get Assessment list with questions wrt categoryid
    getCategoryAssessmentQuestions(CategoryId, AssessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'questionAssessment/categoryAssessmentQuestions?CategoryId=' + CategoryId + '&AssessmentId=' + AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //get Assessment list with ratings wrt categoryid
    getCategoryAssessmentRatings(CategoryId, AssessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'assessmentRatings/categoryAssessmentRatings?CategoryId=' + CategoryId + '&AssessmentId=' + AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //get Assessment list with questions not in category of categoryid
    getAssessmetQuestionsNotInCategory(CategoryId, AssessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'questionAssessment/assessmetQuestionsNotInCategory?CategoryId=' + CategoryId + '&AssessmentId=' + AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //get Assessment list with Ratios not in category of categoryid
    getRFAssessmetRatiosNotInCategory(CategoryId, AssessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'assessmentRatios/assessmetRatiosNotInCategory?CategoryId=' + CategoryId + '&AssessmentId=' + AssessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    // get Assessment list with questions not in category of categoryid
    getRFAssessmetRatiosByCategoryID(CategoryId): Promise<any> {
        var token = localStorage.getItem('token');
        return this.http.get(environment.clientUrl + 'assessmentRatios/categoryIdWiseRFRatios?CategoryId=' + CategoryId, {headers: {"x-access-token": token}})
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    // get Assessment list with questions not in category of categoryid
    getRFRatiosFromAssessmentRatioByCategoryID(categoryId, assessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'assessmentRatios/categoryIdWiseAssessmentRatios?CategoryId=' + categoryId + '&AssessmentId=' + assessmentId)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //get rating list
    getRatingList(): Promise<any> {
        var token = localStorage.getItem('token')
        let header: any = {'x-access-token': token};
        return this.http.get(environment.clientUrl + 'ratingMaster/ratinglist', {headers: header})
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //add new question assessment
    addQuestionAssessment(newQuestionAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'questionAssessment/addquestionAssessment', (newQuestionAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    
    //add new question assessment
    addQuestionAssessmentModification(newQuestionAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'questionAssessment/addQuestionAssessmentModification', (newQuestionAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    

    //add RFAssessment Ratio
    addRFAssessmentRatio(newRFAssessmentRatio): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatios/addRatios', (newRFAssessmentRatio))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //add new Ratings assessment
    addRatingsAssessment(newRatingsAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatings/addAssessmentRatings', (newRatingsAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    
    //add RFAssessment Ratio
    addRFAssessmentRatioModification(newRFAssessmentRatio): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatios/addRatiosModification', (newRFAssessmentRatio))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //add new Ratings assessment
    addRatingsAssessmentModification(newRatingsAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatings/addAssessmentRatingsModification', (newRatingsAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //update existing question assessment
    editQuestionAssessment(existingQuestionAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'questionAssessment/editquestionAssessment', (existingQuestionAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //update existing question assessment
    editQuestionAssessmentModification(existingQuestionAssessment): Promise<any> {
        
                return this.http.post(environment.clientUrl + 'questionAssessment/editquestionAssessmentModification', (existingQuestionAssessment))
                    .toPromise()
                    .then(response => { return Promise.resolve(response); })
            }

    //update RFAssessment Ratio
    editRFAssessmentRatio(existingRFAssessmentRatio): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatios/editRatios', (existingRFAssessmentRatio))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //update existing Ratings assessment
    editRatingsAssessment(existingRatingsAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'assessmentRatings/editAssessmentRatings ', (existingRatingsAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Schedule assessment 
    saveScheduleAssessment(scheduleAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'currentAssessment/scheduleAssessment', (scheduleAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    
    //Schedule assessment Modification
    saveScheduleAssessmentModification(scheduleAssessment): Promise<any> {

        return this.http.post(environment.clientUrl + 'currentAssessment/scheduleAssessmentModification', (scheduleAssessment))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    

    deleteAssessment(): Promise<any> {

        const url = environment.clientUrl + 'assessment/delete';
        return this.http.delete(url)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    //Get Inactive Assessment Id By Assessment Type
    getInactiveAssessmentIdByAssessmentType(assessmentType): Promise<any> {
        return this.http.get(environment.clientUrl + 'currentAssessment/incompleteAssessmentId?AssessmentType=' + assessmentType)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    deleteInternalAssessment(): Promise<any> {

        const url = environment.clientUrl + 'assessment/internal/delete';
        return this.http.get(url)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    deleteRFactorAssessment(): Promise<any> {

        const url = environment.clientUrl + 'assessment/rfactor/delete';
        return this.http.get(url)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    getAlltAssesmentList(): Promise<any> {

        const url = environment.clientUrl + 'currentStateAnalysis/getAllAssesmentBaseOnCompany';
        return this.http.get(url)
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    getAssessmentQuestionDetailsBasedOnIDType(assessmentIDBasedOnType): Promise<any> {

        const url = environment.clientUrl + 'currentStateAnalysis/getAssessmentQuestionDetailsBasedOnIDType';
        let assessmentID = { assessmentID: assessmentIDBasedOnType }

        return this.http.post(url, (assessmentID))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }

    updateAssessmentDate(assessmentDate): Promise<any> {

        const url = environment.clientUrl + 'currentStateAnalysis/updateAssessmentDate';

        return this.http.post(url, (assessmentDate))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
    }
    checkExistingAssessment(): Promise<any> {
        const url = environment.clientUrl + 'assessment/checkAssessmentExist'
        return this.http.get(url)
            .toPromise()
            .then(function (response) {
                return Promise.resolve(response)
            })
    }

    updateAssessmentWeight(assessmentObject): Promise<any> {
        const url = environment.clientUrl + 'assessment/updateAssessmentWeight'
        return this.http.post(url, assessmentObject)
            .toPromise()
            .then(function (response) {
                return Promise.resolve(response)
            })
    }

    lockAssessmentForEdit(assessmentId): Promise<any> {
        const url = environment.clientUrl + 'assessment/lockAssessmentForEdit/' + assessmentId
        return this.http.post(url, assessmentId)
            .toPromise()
            .then(function (response) {
                return Promise.resolve(response)
            })
    }
}