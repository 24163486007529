import { Component, OnInit, Input } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { GfmService } from "../../../services/gfm.service";

import { GfmconfirmationPopupComponent } from "../gfmconfirmation-popup/gfmconfirmation-popup.component";

import * as _ from "lodash";

declare var $: any;
declare var NProgress: any;
declare var jQuery: any;

@Component({
  selector: "app-gfm-popupfor-share",
  templateUrl: "./gfm-popupfor-share.component.html",
  styleUrls: ["./gfm-popupfor-share.component.css"],
})
export class GfmPopupforShareComponent implements OnInit {
  @Input("ObjectToSend") ObjectToSend: any;
  @Input("recordDetails") recordDetails: any;
  @Input("complianceEngine") complianceEngine: any;
  @Input("complianceEngineUrl") complianceEngineUrl: any;
  @Input("gfmRecordId") gfmRecordId: any;
  @Input("recordName") recordName: any;
  @Input("fileDetails") fileDetails: any;
  @Input("complianceDocumentId") complianceDocumentId: any;
  @Input("governanceUrl") governanceUrl: any;
  @Input("gemAccessURL") gemAccessURL: any;
  @Input("sharedFolder") sharedFolder: any;

  roleForm: FormGroup;
  userListToBind: any;
  userListWithPermissions: any = [];
  userObject: any;
  permissions: any = [];
  listOfGFMRecordRequest: any;
  userInfo: any;
  noPermissionSelected: boolean = true;
  disabledOnclick: boolean;

  get arrayOfParentActivities(): FormArray {
    return <FormArray>this.roleForm.get("arrayOfParentActivities");
  }

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private gfmservice: GfmService
  ) {}

  ngOnInit() {
    console.log("sharedFolder----", this.sharedFolder);
    console.log("fileDetails", this.fileDetails.recordType);
    var self = this;
    this.roleForm = this.fb.group({});
    this.userListToBind = this.ObjectToSend;
    this.userInfo = JSON.parse(localStorage.getItem("user"));

    _.remove(this.userListToBind, function (element) {
      return (
        _.get(element, "unqiueUserId") == self.userInfo.UniqueUserId ||
        _.get(element, "unqiueUserId") == self.fileDetails.uniqueUserId
      );
    });

    if (_.size(this.fileDetails.recordSharedWith) > 0) {
      _.forEach(self.fileDetails.recordSharedWith, function (element) {});
    }

    this.formInitialisation();
    this.pushFormGroupInArray();

    if (_.size(this.fileDetails.recordSharedWith) > 0) {
      _.forEach(self.fileDetails.recordSharedWith, function (element) {
        var foundUser = _.findIndex(self.userListToBind, function (user) {
          return _.get(user, "unqiueUserId") == element.uniqueUserId;
        });
        if (foundUser >= 0) {
          self.intialiseALreadySharedUser(element, foundUser);
        }
      });
    }
  }

  intialiseALreadySharedUser(sharedUserDetails, index) {
    var parentArray: FormArray = <FormArray>this.roleForm.get("arrayOfParentActivities");
    var parentNode: FormGroup = <FormGroup>this.arrayOfParentActivities.controls[index];
    if (_.size(sharedUserDetails.permissions) > 0) {
      var nodeControlCount = _.size(sharedUserDetails.permissions);
      var nodeCount = 0;
      for (; nodeCount < nodeControlCount; nodeCount++) {
        switch (sharedUserDetails.permissions[nodeCount]) {
          case "view":
            parentNode.controls["view" + index].setValue(true);
            break;
          case "annotate":
            parentNode.controls["annotate" + index].setValue(true);
            break;
          case "download":
            parentNode.controls["download" + index].setValue(true);
            break;
          case "share":
            parentNode.controls["share" + index].setValue(true);
            break;
        }
      }
    }
  }

  close() {
    this.activeModal.close();
  }

  formInitialisation() {
    this.roleForm = this.fb.group({
      arrayOfParentActivities: this.fb.array([]),
    });
  }

  createParentFormGroup(): FormGroup {
    const group = this.fb.group({});
    return group;
  }

  pushFormGroupInArray() {
    const parentArray: FormArray = <FormArray>this.roleForm.get("arrayOfParentActivities");

    for (var i = 0; i < _.size(this.userListToBind); i++) {
      parentArray.push(this.createParentFormGroup());
      var parentActivity: FormGroup = <FormGroup>parentArray.controls[i];
      this.pushFormControlInFormGroupArray(parentActivity, i);
    }
  }

  pushFormControlInFormGroupArray(parentActivity, i) {
    parentActivity.addControl("view" + i, this.fb.control(false));
    parentActivity.addControl("annotate" + i, this.fb.control(false));
    parentActivity.addControl("share" + i, this.fb.control(false));
    parentActivity.addControl("download" + i, this.fb.control(false));
    // console.log("parentActivity" + i, parentActivity)
  }

  selectCheckbox(user, event, index, checkboxName) {
    this.permissions = [];
    for (var i = 0; i < this.userListToBind.length; i++) {
      this.userListToBind[i].index = i;
    }

    var parentArray: FormArray = <FormArray>this.roleForm.get("arrayOfParentActivities");
    var parentNode: FormGroup = <FormGroup>this.arrayOfParentActivities.controls[index];

    if (
      parentArray.controls[index].value["view" + index] == true ||
      parentArray.controls[index].value["annotate" + index] == true ||
      parentArray.controls[index].value["share" + index] == true ||
      parentArray.controls[index].value["download" + index] == true
    ) {
      parentNode.controls["view" + index].setValue(true);
    } else {
      parentNode.controls["view" + index].setValue(false);
    }

    for (var i = 0; i < this.userListToBind.length; i++) {
      if (this.userListToBind[i].index == index) {
        if (parentArray.controls[index].value["annotate" + index] == true) {
          this.permissions.push("annotate");
        }
        if (parentArray.controls[index].value["view" + index] == true) {
          this.permissions.push("view");
        }
        if (parentArray.controls[index].value["share" + index] == true) {
          this.permissions.push("share");
        }
        if (parentArray.controls[index].value["download" + index] == true) {
          this.permissions.push("download");
        }
      }
    }

    // if (parentArray.controls[index].value['view' + index] == true || parentArray.controls[index].value['annotate' + index] == true || parentArray.controls[index].value['share' + index] == true || parentArray.controls[index].value['download' + index] == true) {
    //   this.noPermissionSelected = false;
    // } else {
    //   this.noPermissionSelected = true;
    // }

    parentNode.controls["view" + index].updateValueAndValidity();

    if (_.size(this.arrayOfParentActivities.controls) > 0) {
      var count = 0;
      var sizeOfArray = _.size(this.arrayOfParentActivities.controls);
      for (; count < sizeOfArray; count++) {
        var parentNode: FormGroup = <FormGroup>this.arrayOfParentActivities.controls[count];
        var parentArray: FormArray = <FormArray>this.roleForm.get("arrayOfParentActivities");
        // console.log("parentNode" + count, parentNode)
        console.log("parentArray", parentArray.controls[count].value);

        if (
          parentArray.controls[count].value["view" + count] == true ||
          parentArray.controls[count].value["annotate" + count] == true ||
          parentArray.controls[count].value["share" + count] == true ||
          parentArray.controls[count].value["download" + count] == true
        ) {
          this.noPermissionSelected = false;
          return;
        } else {
          this.noPermissionSelected = true;
        }
      }
    }
    // this.userListWithPermissions = _.uniqBy(this.userListWithPermissions, "value")
    // console.log("this.userListWithPermissions", this.userListWithPermissions)
  }

  shareRecords() {
    this.disabledOnclick = true;
    var self = this;
    var parentArray: FormArray = <FormArray>this.roleForm.get("arrayOfParentActivities");

    var arrayToAppend = [];

    if (_.size(this.arrayOfParentActivities.controls) > 0) {
      var count = 0;
      var sizeOfArray = _.size(this.arrayOfParentActivities.controls);

      for (; count < sizeOfArray; count++) {
        var permissionObject = {
          sharedWithUserId: "",
          permissions: [],
        };
        // this.arrayOfParentActivities.controls[count].controls
        var parentNode: FormGroup = <FormGroup>this.arrayOfParentActivities.controls[count];

        var nodeControlCount = _.size(parentNode.controls);
        var nodeCount = 0;
        for (nodeCount; nodeCount < nodeControlCount; nodeCount++) {
          switch (nodeCount) {
            case 0:
              if (parentNode.value["view" + count] == true) {
                permissionObject.permissions.push("view");
              }
              permissionObject.sharedWithUserId = self.userListToBind[count].value;
              console.log("permissionObject", permissionObject);

              break;
            case 1:
              if (parentNode.value["annotate" + count] == true) {
                permissionObject.permissions.push("annotate");
              }
              permissionObject.sharedWithUserId = self.userListToBind[count].value;
              console.log("permissionObject", permissionObject);

              break;
            case 2:
              if (parentNode.value["download" + count] == true) {
                permissionObject.permissions.push("download");
              }
              permissionObject.sharedWithUserId = self.userListToBind[count].value;
              console.log("permissionObject", permissionObject);

              break;
            case 3:
              if (parentNode.value["share" + count] == true) {
                permissionObject.permissions.push("share");
              }
              permissionObject.sharedWithUserId = self.userListToBind[count].value;
              console.log("permissionObject", permissionObject);

              break;
          }
        }

        if (_.size(permissionObject.sharedWithUserId) > 0) {
          arrayToAppend.push(permissionObject);
        }
      }
      this.recordDetails = JSON.parse(this.recordDetails);
      var uniqueUserId = JSON.parse(localStorage.getItem("user")).UniqueUserId;

      for (var i = 0; i < arrayToAppend.length; i++) {
        this.userListWithPermissions.push({
          action: "",
          complianceDocumentId: this.complianceDocumentId ? this.complianceDocumentId : "",
          permissions: arrayToAppend[i].permissions,
          recordName: this.recordName,
          recordPath: this.recordDetails.recordPath,
          renameRecordName: "",
          sharedWithUserId: arrayToAppend[i].sharedWithUserId,
          // "uniqueUserId": uniqueUserId,
          uniqueUserId: arrayToAppend[i].sharedWithUserId,
          recordSharedByUniqueUserId: uniqueUserId,
          tags: [],
        });
      }

      // this.listOfGFMRecordRequest = this.userListWithPermissions
      if (this.fileDetails.recordType == "file") {
        this.gfmservice
          .shareFile(
            this.userListWithPermissions,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.gfmRecordId,
            this.governanceUrl,
            this.gemAccessURL
          )
          .then((response) => {
            this.disabledOnclick = false;
            if (response) {
              this.activeModal.close();
              const modalRef = this.modalService.open(GfmconfirmationPopupComponent, {
                backdrop: "static",
              });
              var message = "";
              if (response.status != undefined && response.status == 0) {
                message = "File shared successfully.";
              } else {
                message = "File shared unsuccessfully.";
              }
              modalRef.componentInstance.Message = message;
              modalRef.result.then(
                (resp) => {},
                (error) => {}
              );
            }
          })
          .catch((error) => {});
      } else {
        this.gfmservice
          .shareFolder(
            this.userListWithPermissions,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.gfmRecordId,
            this.governanceUrl,
            this.gemAccessURL
          )
          .then((response) => {
            this.disabledOnclick = false;
            if (response) {
              this.activeModal.close();
              const modalRef = this.modalService.open(GfmconfirmationPopupComponent, {
                backdrop: "static",
              });
              var message = "";
              if (response.status != undefined && response.status == 0) {
                message = "Folder shared successfully.";
              } else {
                message = "Folder shared unsuccessfully.";
              }
              modalRef.componentInstance.Message = message;
              modalRef.result.then(
                (resp) => {},
                (error) => {}
              );
            }
          })
          .catch((error) => {});
      }
      console.log("gfmRecordId", this.gfmRecordId);
      console.log("recordDetails", this.recordDetails);
      console.log("complianceEngine", this.complianceEngine);
      console.log("complianceEngineUrl", this.complianceEngineUrl);
      console.log("this.userListWithPermissions", this.userListWithPermissions);
    }
  }
}
