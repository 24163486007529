import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meeting-agenda-analytics',
  templateUrl: './meeting-agenda-analytics.component.html',
  styleUrls: ['./meeting-agenda-analytics.component.css'],
})
export class MeetingAgendaAnalyticsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    var data = [
      {
        fill: 60,
        color: '#1512a5',
      },
      {
        fill: 10,
        color: '#e33a3b',
      },
      {
        fill: 5,
        color: '#f7a502',
      },
    ];
    var doughnut = document.querySelector('#doughnut'),
      svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      filled = 0;
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    svg.setAttribute('viewBox', '0 0 100 100');
    //doughnut.appendChild(svg);
    data.forEach(function (o, i) {
      var circle = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'circle'
        ),
        startAngle = -90,
        radius = 30,
        cx = 50,
        cy = 50,
        animationDuration = 2000,
        strokeWidth = 4,
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray - (dashArray * o.fill) / 75,
        angle = (filled * 360) / 75 + startAngle,
        currentDuration = (animationDuration * o.fill) / 75,
        delay = (animationDuration * filled) / 75;
      circle.setAttribute('r', String(radius));
      circle.setAttribute('cx', String(cx));
      circle.setAttribute('cy', String(cy));
      circle.setAttribute('fill', 'transparent');
      circle.setAttribute('stroke', o.color);
      circle.setAttribute('stroke-width', String(strokeWidth));
      circle.setAttribute('stroke-dasharray', String(dashArray));
      circle.setAttribute('stroke-dashoffset', String(dashArray));
      circle.style.transition =
        'stroke-dashoffset ' + currentDuration + 'ms linear ' + delay + 'ms';
      circle.setAttribute(
        'transform',
        'rotate(' + angle + ' ' + cx + ' ' + cy + ')'
      );
      svg.appendChild(circle);
      filled += o.fill;
      setTimeout(function () {
        circle.style['stroke-dashoffset'] = dashOffset;
      }, 100);
    });
  }
}
