<div *ngIf="isLoading; then Loader else ChatComponent"></div>
<ng-template #Loader>
  <div class="loader-container">
    <div class="cat__app-loader center">
      <div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #ChatComponent>
  <div id="ChatModal" class="chat-container">
    <div class="chat-container-left">
      <ng-template #groupCreationDialog let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Start a new conversation</h4>
          <button type="button" class="close" aria-label="Close" (click)="hideGroupCreationDialog()">
            <span aria-hidden="true">&times;</span>
          </button>
          
        </div>
        <div class="modal-body group-creation" style="padding: 20px;">
          <div class="form-group-lg">
            <span>Participants ({{groupParticipents.length}})</span>
            <br />
            <span *ngFor="let item of groupParticipents; index as i">
              <span style="cursor: pointer;" (click)="removeParticipent(i)">{{item.displayName + ","}}</span>
            </span>
            <div id="myDropdown" ngbDropdown style="position: relative;" class="dropdown-content">
              <div class="search-container">
                <input id="search-participents" autocomplete="off" ngbDropdownToggle type="text"
                  placeholder="Search by name or email address" />
                <button id="send-but" (click)="searchPeople()">
                  <i class="fas fa-search" style="padding: 5px;"></i>
                </button>
              </div>
              <div ngbDropdownMenu *ngIf="participentsSearchResult.length !== 0" class="search-result-container">
                <div *ngFor="let item of participentsSearchResult; index as i">
                  <div class="list-item-container" (click)="addParticepent(item)">
                    <p>{{item.displayName}}</p>
                    <p>{{item.emails[0]}}</p>
                  </div>
                </div>
              </div>
            </div>
            <p id="participentsErr"></p>
          </div>
          <div class="form-group-lg">
            <span>Thread name:</span>
            <div class="search-container">
              <input placeholder="Name" autocomplete="off" id="group-subject" />
            </div>
            <p id="threadNameErr"></p>
          </div>
          <div class="form-group-lg">
            <span>Message:</span>
            <div class="search-container">
              <textarea id="initialMsg" placeholder="Type here..." style="height: 90px;" cols="4"></textarea>
            </div>
          </div>
          <button class="sendBut" (click)="createGroup()">
            SEND
          </button>
        </div>
      </ng-template>

      <!-- Contact Search -->
      <div class="left-head">
        <div class="searchBarContainer">
          <input id="search-input" autocomplete="off" type="text" placeholder="Search" />
          <img src="../assets/images/search-icon.png"  alt="search-icon" />
        </div>
        <div style="padding: 10px 0px; cursor: pointer;" (click)="showGroupCreationDialog(groupCreationDialog)">
          <div class="createGroupButContainer">
          <img src="../assets/images/new-conversation-icon.png" alt="new-conversation-icon"/>
            <span>Start a new conversation</span>
          </div>
        </div>
      </div>

      <!-- Room List -->
      <div id="users-list" class="contact-section">
        <div *ngIf="searchResult.length === 0; then RoomList else SearchResult"></div>
        <ng-template #RoomList>
          <div *ngFor="let room of rooms; index as i">
            <div style="display: flex;"
              [ngClass]="selectedRoom && (room.id === selectedRoom.id) ? 'list-item-container active' : 'list-item-container'"
              (click)="selectRoom(room)">
              <div style="flex: 1;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
                <p>{{room.title}}</p>
                <span>{{room?.participents?.length ? room?.participents?.length + " Participants" : ""}}</span>
                <p>{{room.text || "..."}}</p>
              </div>
              <div style="display: flex; align-items: flex-end; flex-direction: column;">
                <span>{{getCreatedDate(room.lastActivity)}}</span>
                <div *ngIf="room?.unreaded > 0"
                  style="width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; background-color: red; color: white;">
                  <span style="font-size: 12px; margin:0px; padding: 0px;">{{room?.unreaded}}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #SearchResult>
          <div *ngFor="let room of searchResult; index as i">
            <div class="list-item-container" (click)="selectRoom(room)">
              <p>{{room.title}}</p>
              <span>{{room?.participents?.length ? room?.participents?.length + " Participants" : ""}}</span>
              <p>{{room.text || "..."}}</p>
            </div>
          </div>
        </ng-template>

      </div>
    </div>

    <div class="saperator"></div>

    <!-- Right Container -->
    <div class="chat-container-right" *ngIf="selectedRoom">

      <!-- Chat Head -->
      <div class="chat-head">
        <div>
          <h1 id="reciverName" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 200px;">{{selectedRoom?.title}}</h1>
          <h5>{{selectedRoom?.participents && selectedRoom.participents.length + " Participants"}}</h5>
        </div>
        <div class="dropdown">
          <button type="button" class="btn dropdown-toggle p-0 d-flex" data-toggle="dropdown">
            <i class="fa fa-circle" aria-hidden="true"></i>
            <i class="fa fa-circle" aria-hidden="true"></i>
            <i class="fa fa-circle" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="javascript:void(0)" >Delete thread</a>
            <a class="dropdown-item" href="javascript:void(0)" >Leave thread</a>
          </div>
        </div>
      </div>
      <div class="saperator-h"></div>

      <!-- Chat List -->
      <div *ngIf="files.length === 0; then chatList else filesList"></div>
      <ng-template #chatList>
        <div class="chat-section">
          <ng-container *ngFor="let item of selectedRoom?.messages">
            <div class="chat-item-container"
              [ngClass]="(personalDetails.emails[0] === item.personEmail)? 'receiver':''">
              <span
                class="person-name">{{personalDetails.emails[0] === item.personEmail ? "You" : getDisplayNameOfParticipants(item.personEmail)}}</span>
              <div style="background-color: #ebf5f5;padding: 10px;color: #000;">
                <div class="chat-files-container" *ngFor="let file of item.files; index as i">
                  <button (click)="downloadAttachment(file, item, i)">
                    <i class="fas fa-download"></i>
                  </button>
                  <span>{{"IMG-"+ getFormattedTime(item.created)}}</span>
                </div>
                <div [innerHTML]="item.html"></div>
                <div style="font-size: 12px;">{{getCreatedDate(item.created)}}</div>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Chat input -->
        <div id="chat-foot" class="chat-foot">
          <div class="chat-input-container">
            <input id="chat-input" (keypress)="onInputKeyPress($event)" autocomplete="off" type="text" placeholder="Type message..." />
            <button style="background-color: white;" (click)="openBrower()">
              <i class="fas fa-paperclip"></i>
              <input hidden type="file" accept="image/*" id="file-input"
                (change)="handleFileInput($event.target.files)">
            </button>
            <button id="send-but" (click)="sendMessage()">
              <span>Send</span>
            </button>
          </div>
        </div>
      </ng-template>

      <!-- File upload -->
      <ng-template #filesList>
        <div class="files-section">
          <div class="preview-container">
            <button (click)="cancelAttachments()">
              <i class="fas fa-close"></i>
            </button>
            <p>Preveiw</p>
          </div>

          <div class="files-container">
            <div class="files-list" *ngFor="let item of imageData">
              <img src="{{item}}" alt="attachment" />
            </div>
          </div>

        </div>

        <!-- Chat input -->
        <div class="chat-foot">
          <div class="chat-input-container">
            <input id="chat-input" (keypress)="onInputKeyPress($event)" type="text" autocomplete="off" placeholder="Type your message" />
            <button id="send-but" (click)="sendMessage()">
              <i style="padding: 5px;" class="fas fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</ng-template>