<section>

  <div class="modal-header">
    <h4 class="modal-title">Share</h4>
    <button class="btn btn-primary btn-sm" style="float:right;" type="button" (click)="shareRecords()" [disabled]="noPermissionSelected || disabledOnclick">Done</button>
  </div>

  <div class="modal-body" style="height: 400px;overflow: scroll;">
    <div *ngFor="let user of userListToBind;let i=index">
      <span>{{user.label}}</span>
      <div>
        <form [formGroup]="roleForm">
          <div formArrayName="arrayOfParentActivities">
            <div [formGroupName]="i">
              <div class="form-check-inline d-h">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" id="{{'view'+ i}}" formControlName="{{'view' + i}}" name="'view' + i" (change)="selectCheckbox(user,$event,i,'view')">VIEW
                </label>
              </div>
              <div class="form-check-inline d-h">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" id="{{'annotate'+ i}}" formControlName="{{'annotate' + i}}" name="'annotate' + i"
                    (change)="selectCheckbox(user,$event,i,'annotate')">ANNOTATE
                </label>
              </div>
              <div class="form-check-inline ">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" id="{{'download'+ i}}" formControlName="{{'download' + i}}" name="'download' + i"
                    (change)="selectCheckbox(user,$event,i,'download')">DOWNLOAD
                </label>
              </div>
              <div class="form-check-inline d-h">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" id="{{'share'+ i}}" formControlName="{{'share' + i}}" name="'share' + i"
                    (change)="selectCheckbox(user,$event,i,'share')">SHARE
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <hr>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
  </div>
</section>