import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AssesmentTableCellComponent } from './components/tabular-question/assesmentTableCell.component';
import { TwoDigitDecimaNumberDirective } from './components/tabular-question/two-digit-decima-number.directive';
import { ReferralStatusReportComponent } from './components/referral-status-report/referral-status-report.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
export { ReferralStatusReportComponent } from './components/referral-status-report/referral-status-report.component';
export { FileUploadComponent } from './components/file-upload/file-upload.component';

@NgModule({
  declarations: [
    AssesmentTableCellComponent,
    TwoDigitDecimaNumberDirective,
    ReferralStatusReportComponent,
    FileUploadComponent
  ],
  imports: [
    FormsModule,
    NgbModule,
    CommonModule
  ],
  exports:[
    CommonModule,
    AssesmentTableCellComponent,
    TwoDigitDecimaNumberDirective,
    ReferralStatusReportComponent,
    FileUploadComponent,
  ]
})
export class SharedComponentsModuleModule { }
