<div class="modal-header">
  <h4 class="modal-title"> Task List
    <span class="badge" style="background-color: lightgray; border-radius: 15px;"></span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <!-- search filter starts here -->
  <section class="card search-box" style="margin-bottom:5px">

    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2" *ngIf="type.name!='Roadmap'">
              <label>Meeting Type</label>
            </div>
            <div class="col-md-2">
              <label>Process Name</label>
            </div>
            <div [ngClass]="{'col-md-3'  : type.name!='Roadmap' , 'col-md-4' : type.name=='Roadmap'}">
              <div class="row">
                <div class="col-md-6">
                  <label>Assigned To</label>
                </div>
                <div class="col-md-6">
                  <label>Status</label>
                </div>
              </div>
            </div>

            <div [ngClass]="{'col-md-3'  : type.name!='Roadmap' , 'col-md-4' : type.name=='Roadmap'}">
              <label>Search By Date:</label>
            </div>
            <div class="col-md-2">

            </div>
          </div>

        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-2" *ngIf="type.name!='Roadmap'">
              <div class="form-group">
                <ng-select class="form-control ngSelectBox" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedInputMeetingType" [multiple]="false" [items]="meetingTypeList"></ng-select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedProcessById"
                  [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="processByIdList">
                </ng-select>
              </div>
            </div>

            <div [ngClass]="{'col-md-3'  : type.name!='Roadmap' , 'col-md-4' : type.name=='Roadmap'}">
              <div class="row form-group">
                <div class="col-md-6">
                  <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedAssignedById"
                    [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="assignToByIdList">
                  </ng-select>
                </div>

                <div class="col-md-6">
                  <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedtaskStatus"
                    [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="taskStatusList">

                  </ng-select>
                </div>
              </div>
            </div>

            <div [ngClass]="{'col-md-3'  : type.name!='Roadmap' , 'col-md-4' : type.name=='Roadmap'}">
              <div class="input-group" (click)="$event.stopPropagation()">
                <input class="form-control" [(ngModel)]="selectedFromDate" style="padding:6px;z-index: 0;"
                  placeholder="dd-mm-yyyy" ngbDatepicker #c="ngbDatepicker" (click)="c.toggle();r.close();"
                  (document:click)="c.close();" (ngModelChange)="selectedFromDateChange(c)"
                  [ngModelOptions]="{standalone: true}">
                <button class="input-group-addon" (click)="c.toggle();r.close();" type="button">
                  <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                </button> &nbsp;&nbsp;&nbsp;

                <input class="form-control" [(ngModel)]="selectedToDate" id="toDatePicker"
                  [disabled]="!isFromDateSelected" style="padding:6px;z-index: 0;" placeholder="dd-mm-yyyy"
                  ngbDatepicker #r="ngbDatepicker" (click)="r.toggle();c.close();" (document:click)="r.close();"
                  (ngModelChange)="selectedToDateChange(r)" [ngModelOptions]="{standalone: true}"
                  [minDate]="minDateForToDate">
                <button class="input-group-addon" (click)="r.toggle();c.close();" [disabled]="!isFromDateSelected"
                  type="button">
                  <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                </button>

              </div>
            </div>

            <div class="col-md-2">
              <div class="input-group">
                <button type="button" (click)="searchFilter()" class="btn btn-sm btn-primary" title="search">
                  <i class="fa fa-search"></i>Search
                </button> &nbsp; &nbsp; &nbsp;
                <button type="button" class="btn btn-sm btn-default" (click)="clearFilterValues()" title="refresh">
                  <i class="fa fa-refresh"></i>Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- search filter ends here -->


  <!-- add loader in the modal -->
  <div class="table-responsive table-bordered" *ngIf="taskList">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="taskTable"
      class="table table-hover compact cell-border nowrap table-striped dataTable">
      <thead>
        <tr class="table-header">
          <th *ngIf="type.name!='Roadmap'">Meeting Type</th>
          <th>Process Name</th>
          <th>Task Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Type</th>
          <th *ngIf="type.name=='Roadmap'">Owner</th>
          <th>Assigned To</th>
          <th *ngIf="type.name=='Roadmap'">First Escalation To</th>
          <th *ngIf="type.name=='Roadmap'">First Escalation Date</th>
          <th *ngIf="type.name=='Roadmap'">Second Escalation To</th>
          <th *ngIf="type.name=='Roadmap'">Second Escalation Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let task of taskList">
          <td *ngIf="type.name!='Roadmap'">{{task.MeetingType}}</td>
          <td>{{task.ProcessName}}</td>
          <td>
            <span ngbTooltip={{task.TaskName}} placement="right" style="white-space: normal">{{task.TaskName}}</span>
          </td>
          <td><span style="display: none">{{task.StartDate | date : "yMMdd"}}</span> {{task.StartDate | date :
            "dd-MM-y"}}</td>
          <td><span style="display: none">{{task.EndDate | date : "yMMdd"}}</span>{{task.EndDate | date : "dd-MM-y"}}
          </td>
          <td *ngIf="type.name=='Roadmap'">{{statusToShow(task.Status)}}</td>
          <td *ngIf="type.name!='Roadmap'">{{task.ComplianceTaskStatus}}</td>
          <td *ngIf="type.name=='Roadmap'">ESG</td>
          <td *ngIf="type.name!='Roadmap'">Compliance</td>
          <td *ngIf="type.name=='Roadmap'">{{task.Owner}}</td>
          <td>{{task.AssignedTo}}</td>
          <td *ngIf="type.name=='Roadmap'">{{task.FirstEscalationTo}}</td>
          <td *ngIf="type.name=='Roadmap'"> <span style="display: none">{{task.FirstEscalationDate | date :
              "yMMdd"}}</span> {{task.FirstEscalationDate | date : "dd-MM-y"}}</td>
          <td *ngIf="type.name=='Roadmap'">{{task.SecondEscalationTo}}</td>
          <td *ngIf="type.name=='Roadmap'"><span style="display: none">{{task.SecondEscalationDate | date :
              "yMMdd"}}</span> {{task.SecondEscalationDate | date : "dd-MM-y"}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-light btn-sm " title="Close" (click)="activeModal.dismiss('modal closed')">Close</button>
</div>