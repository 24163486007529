


    <div class="modal-body">
        <div class="row">         
          <div class="table-responsive">
            <table class="table table-hover compact cell-border nowrap stripe">
              <thead>
                <tr>
                  <th style="width: 45%">Task Name</th>
                  <th style="width: 15%">Task Particulars</th>
                  <th style="width: 30%">Tag Name</th>
                  <th style="width: 10%">Task Status</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngIf="isComplianceObjectEmpty">
                    <td colspan="5" class="text-center">
                      No Records Found.
                    </td>
                  </tr>
                <tr *ngFor="let compliance of complianceObject">
                  <td>{{compliance?.ID?.TaskName}}</td>
                  <td>{{compliance?.ID?.ActivatedTaskParticularId?.TaskParticulars}}</td>
                  <td>{{compliance?.ID?.GroupID?.GroupName}}</td>
                  <td>{{compliance?.ID?.TaskStatus ? getTaskStatus(compliance?.ID?.TaskStatus) : " " }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

  



