import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ExportExcelService } from '../../../services/export-excel.service';

import { NgbDateStruct, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
// import * as XLSX from 'xlsx';

import { CreateUserModalComponent } from './../create-user-modal/create-user-modal.component';
import { EditUserModalComponent } from './../edit-user-modal/edit-user-modal.component';
import { ConfirmationModalComponent } from './../confirmation-modal/confirmation-modal.component';

import { UserMasterService } from './../../../services/user-master.service';

import { PermissionLinks } from '../../../enums/permissionLinks';

import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Rx';
import { rocketChatRemove } from '../../../enums/rocketChatRemove';
import { environment } from '../../../../environments/environment';
import { OTPLoginService } from '../../../services/otp-login.service';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  // @ViewChild(DataTableDirective) 
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  // dtUnAuthOptions: any = {};
  dtUnAuthTrigger: Subject<any> = new Subject();

  dtnotInsertdata: any = {};
  dtnotInsertdataTrigger: Subject<any> = new Subject();

  public userListStatus: boolean = true;
  public userList: Array<any>;
  public authroizedUserList: Array<any>;
  public unAuthroizedUserList: Array<any>;
  exportDetailToExcel: Array<any> = [];
  loggedInUserInfo: any
  isGuestSuperAdmin: boolean;
  loggedInUserRoles: any
  isSuperUser: boolean = false;
  public isVisibleForChecker: boolean = true;
  public isVisibleAuthUser: boolean = false;
  public excelFileUserBulk: any;
  public filesToBulkUpload: any;
  isUploadNotValid: boolean;
  fileExtensionError: any;
  fileSizeError: any;
  fileExtension: any;
  notinserteddata: any;
  isAdminUser : Boolean = false;

  constructor(private userService: UserMasterService, private modalService: NgbModal, private exportExcelService: ExportExcelService, private loginService: OTPLoginService) { }

  ngOnInit(): void {
    this.fileSizeError = false;
    this.isUploadNotValid = true;
    this.dtnotInsertdata = {
      processing: true,
      serverSide: false,
      scrollX: true,
      deferRender: true,
      stateSave: true,
      stateDuration: -1,
      scrollY: "420px",
      scrollCollapse: true,
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      pageLength: 10,
      search: true,
      order: [0, 'asc'],
      lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"]],
      dom: 'lBfrtip',
      buttons: [
        {
          text: '<i class="fa fa-files-o"></i> Export to Upload Again',
          className: 'btn btn-sm btn-warning export-btn',
          filename: 'Not Inserted Records',
          extend: 'excel',
          title: '',
          init: function(api, node, config) {
            $(node).removeClass('dt-button')
         },
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11]
          }
        }
      ]

    }
    $("body").removeClass("dashboard-page");
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      // dom: 'lBfrtip',
      processing: true,  
      language: { //made changes for datatables for next previous button
        "paginate": {
          "first": "<<",
          "last": ">>",
          "next": ">",
          "previous": "<"
        }
      }
    };
    this.loggedInUserInfo = JSON.parse(localStorage.getItem("user")).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }

    this.loggedInUserRoles = JSON.parse(localStorage.getItem("user")).Roles;
    _.forEach(this.loggedInUserRoles, (element) => {
      if (element.RoleName == PermissionLinks.Admin) {
        this.isAdminUser = true;
      }
    })

    this.loggedInUserRoles = _.filter(this.loggedInUserRoles, (o) => {
      if (o.RoleName == PermissionLinks.ICChecker || o.RoleName == PermissionLinks.ICMaker || o.RoleName == PermissionLinks.Admin || o.RoleName == PermissionLinks.UPSIAdmin || o.RoleName == "BoardEval Admin") {
        this.isSuperUser = true;
      }
    })

    this.fillUserList();
    //get child resources    
    let resourcesValue = JSON.parse(localStorage.getItem('resources'));
    _.forEach(resourcesValue, (data) => {
      if (data.Menu == "UserMaster") {
        let child = data.ChildResources.filter((childData) => {
          if (childData.Url == "/api/v1/secure/userMaster/getUserList") {
            this.isVisibleAuthUser = true;
          }

          if (childData.Url == "/api/v1/secure/userMaster/authorize") {
            return childData;
          }
        })

        //  
        if (_.size(child)) {
          this.isVisibleForChecker = true;
        } else {
          this.isVisibleForChecker = false;
        }
      }
    })
    if (this.isGuestSuperAdmin == true) {
      this.isVisibleForChecker = false;
    }
  }

  exportAsXLSX(state): void {
    this.exportDetailToExcel = [];
    var UserList = [];
    if (state == 'authorize') {
      UserList = this.authroizedUserList;
    } else if (state == 'unauthorize') {
      UserList = this.unAuthroizedUserList;
    }
    if (UserList.length) {
      for (let i = 0; i < UserList.length; i++) {
        var isActive;
        var IsAuthorize;
        if (UserList[i].IsActive == true) {
          isActive = "Active"
        } else {
          isActive = "Inactive"
        }
        if (UserList[i].IsAuthorize == true) {
          IsAuthorize = "Authorize"
        } else {
          IsAuthorize = "Unauthorize"
        }

        // roles //converting rolesArray into Comma sepearted String
        var rolesAssigned = UserList[i].Roles.join(", ");

        var k = i;
        var exportDetailObj = {
          "Sr No.": k + 1,
          "Name": UserList[i].Title + ' ' + UserList[i].FirstName + ' ' + UserList[i].LastName,
          "Type Of User": UserList[i].TypeOfUser,
          "Designation": UserList[i].Designation,
          "Mobile Number": UserList[i].MobileNumber,
          "Email": UserList[i].EmailId,
          "Roles": rolesAssigned,
          "Active": isActive,
          "Status": IsAuthorize,
        }
        this.exportDetailToExcel.push(exportDetailObj);
      }
    }
    // this.exportExcelService.exportAsExcelFile(this.exportDetailToExcel, state + ' ' + 'User Master');
  }

  fillUserList() {

    this.userService.getAllUserList().subscribe(response => {
      if (response.status) {
        this.userListStatus = false;
      } else {
        this.userListStatus = true;
        const data = this.manageRolename(response);
        this.userList = _.orderBy(data.result, ['CreatedOn'], ['desc']);
        this.dtTrigger.next();
        if (_.size(this.userList)) {
          this.userList.forEach(function (userData) {
            _.assign(userData, { userType: _.startCase(userData.TypeOfUser) })
          })
        }
        this.authroizedUserList = this.userList.filter(function (o) { return (o.IsAuthorize == true); });
        this.dtTrigger.next();
        this.unAuthroizedUserList = this.userList.filter(function (o) { return (o.IsAuthorize == false); });
        this.dtUnAuthTrigger.next();
      }
    })
  }

  userBulkUpload() {
    this.excelFileUserBulk = environment.nonValidateUrl + "downloadExcelFile"
    this.userService.getRoleForEcxcelFile().then(function (response) {
      $("#BulkUpload").modal('show')
    })
  }


  fileChanged($event) {
    console.log('file changed', $event.target.files[0])
    if ($event.target.files[0] / (1024 * 1024) > (1024 * 1024)) {
      this.fileSizeError = true;
      this.isUploadNotValid = true;
    } else {
      var allowedExtensions = ["xlsx", "XLSX"];
      var filename = $event.target.files[0].name;
      this.fileExtension = filename.toString().split('.').pop();
      console.log("filename", filename);
      console.log("fileExtension", this.fileExtension);

      if (this.isInArray(allowedExtensions, this.fileExtension)) {
        this.fileExtensionError = false;
        this.fileSizeError = false;
        this.isUploadNotValid = false;
        this.filesToBulkUpload = $event.target.files;
      } else {
        this.fileExtensionError = true;
        this.isUploadNotValid = true;
      }
    }

  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


  upload() {
    var file = this.filesToBulkUpload;
    if (file != undefined) {
      console.log("file ", file)
      this.makeFileRequest(environment.clientUrl + "csvUplaodAddUser", [], file).then((result) => {
        $("#fileInput").val('');
        this.isUploadNotValid = true;
        if (result['status'] == 0) {
          console.log('TypeOfUser', result['data'])


          this.notinserteddata = result['data'];
          $("#CsvUploadtable").DataTable().destroy();
          console.log(result['data'])
          this.dtnotInsertdataTrigger.next();

          if (_.size(result['data'])) {

            $('#notInsertdata').modal('show');
          }
          else {

            $('#alertTitle').text("Success!");
            $('#alertTitle').css("color", "black");
            $('#alertMessage').text("Users uploaded successfully.");
            $('#alertMessage').css("color", "black");
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();

            // const data = this.manageRolename(response)
            // response.result.Roles.map(function(o){
            //   return o.RoleName
            // })


            // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //   dtInstance.destroy();
            // });

            //  


            console.log("this.dtElements", this.dtElements);
            this.dtElements.forEach((dtElement: DataTableDirective) => {
              console.log("dtElement", dtElement)
              var self = dtElement;
              if (!_.isUndefined(dtElement.dtInstance)) {
                self.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                });
              }
            });

            //clear state after adding record
            var datable = $('#userDetailsTable').DataTable();

            datable.state.clear();
            console.log("clearlist")
            this.fillUserList();
          }
          console.log("result", result)
        } else {
          console.log("resultErr", result)
        }

      });
    } else {
      console.log("file undefined")
    }
  }


  refreshThePage() {
    console.log("this.dtElements", this.dtElements);
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      console.log("dtElement", dtElement)
      var self = dtElement;
      if (!_.isUndefined(dtElement.dtInstance)) {
        self.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
    });

    //clear state after adding record
    var datable = $('#userDetailsTable').DataTable();

    datable.state.clear();
    console.log("clearlist")
    this.fillUserList();
  }


  makeFileRequest(url: string, params: Array<string>, files: Array<File>) {
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      for (var i = 0; i < files.length; i++) {
        var newDate = Date.now();
        formData.append("file", files[i], newDate + '-' + files[i].name.replace(',', ''));
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", url, true);
      var token = localStorage.getItem('token')
      xhr.setRequestHeader("x-access-token", token)
      xhr.setRequestHeader('govevacompany', localStorage.getItem("companyDetails"))
      xhr.send(formData);
    });
  }

  createUserModal() {
    const modalRef = this.modalService.open(CreateUserModalComponent, {
      size: 'lg',
      backdrop: 'static'
    })
    modalRef.componentInstance.isGuestSuperAdmin = this.isGuestSuperAdmin;
    modalRef.componentInstance.isSuperUser = this.isSuperUser;
    modalRef.result.then(response => {
      if (response.status === 0) {
        //  

        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");
        $('#alertMessage').text("User created successfully.");
        $('#alertMessage').css("color", "black");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();

        // const data = this.manageRolename(response)
        // response.result.Roles.map(function(o){
        //   return o.RoleName
        // })


        // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //   dtInstance.destroy();
        // });

        //  


        console.log("this.dtElements", this.dtElements);
        this.dtElements.forEach((dtElement: DataTableDirective) => {
          console.log("dtElement", dtElement)
          var self = dtElement;
          if (!_.isUndefined(dtElement.dtInstance)) {
            self.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
        });

        //clear state after adding record
        var datable = $('#userDetailsTable').DataTable();

        datable.state.clear();
        console.log("clearlist")
        this.fillUserList();

        //this.ngOnInit()
      }
      else {
         

        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text("Failure!");
        $('#alertMessage').text(response.message);
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
    }, (reason) => {

    })
  }

  deactivateUser(data) {
    //  
    const self = this;
    this.modalService.open(ConfirmationModalComponent).result.then(response => {
      //  
      if (response === 'Yes') {
        // var object = {
        //   userId: data._id,
        //   RocketChatAuthToken: localStorage.getItem('rocketAuth'),
        //   RocketChatUserId: localStorage.getItem('rocketUserId'),
        //   RocketChatBaseURL: localStorage.getItem('rocketBaseURL')
        // }
        var object;
        if (rocketChatRemove.isRemove.toString() != 'true') {
          object = {
            userId: data._id,
            userName: `${data.FirstName} ${data.LastName}`,
            RocketChatAuthToken: localStorage.getItem('rocketAuth'),
            RocketChatUserId: localStorage.getItem('rocketUserId'),
            RocketChatBaseURL: localStorage.getItem('rocketBaseURL')
          }
        } else {
          object = {
            userId: data._id,
            userName: `${data.FirstName} ${data.LastName}`,
            RocketChatBaseURL: localStorage.getItem('rocketBaseURL'),
            isRemoveRocketChat: rocketChatRemove.isRemove
          }
        }

        this.userService.deactivateUser(object).then(response => {
          if (!response.status) {
            const indexOfDeactiveUser = _.findIndex(self.userList, function (o) { return o._id === data._id });
            // this.userList.splice(indexOfDeactiveUser, 1)
            $('#messagePopUpforDeactivateUser').modal('toggle');
            $('#alertTitles').text("Success!");
            $('#alertTitles').css("color", "black");
            $('#alertMessages').text("User deactivated successfully.");
            $('#alertMessages').css("color", "black");

            this.messagePopUpTimeoutToogle();
            _.forEach(this.userList, function (user, index) {
              if (data._id == user._id) {
                user.IsActive = false
              }
            })
          } else {
            $('#messagePopUpforDeactivateUser').modal('toggle');
            $('#alertTitles').text("Failure!");
            $('#alertTitles').css("color", "red");
            $('#alertMessages').text(response.message);
            $('#alertMessages').css("color", "red");

            this.messagePopUpTimeoutToogle();
          }
        })
      }
    }, (reason) => {
      ////console.log('Declined')
    })
  }

  private manageRolename(data) {
    if (this.isGuestSuperAdmin) {
      var filterData = _.filter(data.result, function (element) {
        if (_.size(element.Roles)) {
          element.Roles = _.filter(element.Roles, function (o) {
            if (o.RoleName == PermissionLinks.ICChecker || o.RoleName == PermissionLinks.ICMaker || o.RoleName == PermissionLinks.Admin || o.RoleName == "BoardEval Admin") {
              return o;
            }
          })
          if (_.size(element.Roles)) {
            element.Roles = element.Roles.map(o => {
              return o.RoleName
            })
            return element;
          }
        }
      })
      data.result = filterData;
      return data;
    } else {

      if (_.size(data.result)) {

        let filterData = _.filter(data.result, (element: any) => {
          //  
          if (_.size(element.Roles)) {
            element.Roles = _.filter(element.Roles, (o) => {
              if (o.RoleName == PermissionLinks.GuestSuperAdmin || o.RoleName == PermissionLinks.ICChecker || o.RoleName == PermissionLinks.ICMaker || o.RoleName == PermissionLinks.Admin || o.RoleName == "BoardEval Admin") {

              } else {

                return o;
              }
            })
            if (_.size(element.Roles)) {
              element.Roles = element.Roles.map(o => {
                return o.RoleName
              })
              return element;
            }

          }
        })

        data.result = filterData;
        return data;


        // data.result.forEach(element => {
        //   if (_.size(element.Roles)) {
        //     element.Roles = element.Roles.map(o => {
        //       return o.RoleName
        //     })
        //   }
        // })
        // return data;
      }
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == "modal fade show") {
        // $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  activateUser(userDetails) {
    //  

    ////console.log('checking for the user detail', userDetails)
    // var object = {
    //   userId: userDetails._id,
    //   RocketChatAuthToken: localStorage.getItem('rocketAuth'),
    //   RocketChatUserId: localStorage.getItem('rocketUserId'),
    //   RocketChatBaseURL: localStorage.getItem('rocketBaseURL')
    // }

    var object;
    if (rocketChatRemove.isRemove.toString() != 'true') {
      object = {
        userId: userDetails._id,
        userName: `${userDetails.EmailId} ${userDetails.FirstName} ${userDetails.LastName} ${userDetails.CompanyName}`,
        RocketChatAuthToken: localStorage.getItem('rocketAuth'),
        RocketChatUserId: localStorage.getItem('rocketUserId'),
        RocketChatBaseURL: localStorage.getItem('rocketBaseURL')
      }

    } else {

      object = {
        userId: userDetails._id,
        userName: `${userDetails.EmailId} ${userDetails.FirstName} ${userDetails.LastName} ${userDetails.CompanyName}`,
        RocketChatBaseURL: localStorage.getItem('rocketBaseURL'),
        isRemoveRocketChat: rocketChatRemove.isRemove
      }
    }

    this.userService.activateUser(object).then(response => {
      // console.info(response)
      if (!response.status) {
        $('#messagePopUp').modal('toggle');
        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");
        $('#alertMessage').text("User activated successfully.");
        $('#alertMessage').css("color", "black");
        this.messagePopUpTimeoutToogle();
        _.forEach(this.userList, function (user, index) {
          if (userDetails._id == user._id) {
            user.IsActive = true
          }
        })

        // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //   dtInstance.destroy();
        // });

        this.dtElements.forEach((dtElement: DataTableDirective) => {
          // dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //   dtInstance.destroy();
          // });
          var self = dtElement;
          if (!_.isUndefined(dtElement.dtInstance)) {
            self.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
        });

        this.fillUserList();
        // this.ngOnInit()
      } else {
        $('#messagePopUp').modal('toggle');
        $('#alertTitle').text("Failure!");
        $('#alertTitle').css("color", "red");
        $('#alertMessage').text(response.message);
        $('#alertMessage').css("color", "red");
        this.messagePopUpTimeoutToogle();
      }
    })
  }

  //edit user
  editUserModal(user) {
    //modal-xlg
    const modalRef = this.modalService.open(EditUserModalComponent, {
      size: 'lg',
      backdrop: 'static'
    })
    modalRef.componentInstance.userId = user._id
    //'shouldDisable' disable fields if page hits from dashboard.
    modalRef.componentInstance.shouldDisable = false;
    modalRef.componentInstance.isGuestSuperAdmin = this.isGuestSuperAdmin;
    modalRef.componentInstance.isVisibleForChecker = this.isVisibleForChecker;
    modalRef.result.then(response => {
      if (!response.status) {
        //  

        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");
        if (response.message == "User authorized successfully.")
          $('#alertMessage').text("User authorized successfully.");
        else
          $('#alertMessage').text("User updated successfully.");

        $('#alertMessage').css("color", "black");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();

        //  

        // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //   dtInstance.destroy();
        // });

        this.dtElements.forEach((dtElement: DataTableDirective) => {
          // dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //   dtInstance.destroy();
          // });
          var self = dtElement;
          if (!_.isUndefined(dtElement.dtInstance)) {
            self.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
        });

        // this.ngOnInit();
        //this.dtTrigger.next();  
        this.fillUserList();

      }
      else {
        //  

        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text("Failure!");
        $('#alertMessage').text(response.message);
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
    }, (reason) => {

    })
  }

  unlockUser(user)
  {       
    this.userService.unlockUser(user._id).then(response => {
      if(response.status == 0){

        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");
        
          $('#alertMessage').text(response.message);
       
        $('#alertMessage').css("color", "black");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();

        //  

        // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //   dtInstance.destroy();
        // });

        this.dtElements.forEach((dtElement: DataTableDirective) => {
          // dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //   dtInstance.destroy();
          // });
          var self = dtElement;
          if (!_.isUndefined(dtElement.dtInstance)) {
            self.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
        });

        // this.ngOnInit();
        //this.dtTrigger.next();  
        this.fillUserList();

      }else{
        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text("Failure!");
        $('#alertMessage').text(response.message);
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
    }).catch(errorResponse => {

    })

  }


  resetPasswordForUser(userDetails){

    this.userService.resetPasswordByAdmin(userDetails._id).then(response => {
      console.log("password response", response)

      if(response.status == 0){
        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");      
        $('#alertMessage').text(response.message);    
        $('#alertMessage').css('color', 'black');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }else{
        $('#alertTitle').text("Failure!");
        $('#alertTitle').css("color", "#f00");      
        $('#alertMessage').text(response.message);    
        $('#alertMessage').css("color", "#f00");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
     

    }).catch(error => {
      $('#alertTitle').text("Failure!");
      $('#alertTitle').css("color", "#f00");      
      $('#alertMessage').text("Reset password was not successful.Please try again");    
      $('#alertMessage').css("color", "#f00");
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle();
    })

  }

  resendActivationEmail(userDetails){
    // try{
    //   var loginOTPObject = {
    //     'EmailId': userDetails.EmailId,
    //     'CompanyId': userDetails.CompanyId, // check for the company ID
    //     'MobileNumber': userDetails.MobileNumber,
    //     'isResendOTP': true
    //   }
    //   this.loginService.wrongOTPAttemptCheck(loginOTPObject).then(result => {
    //     if(!result.status) 
    //     {
    //         if(result.SecretCodeAttempt>=3)
    //         {
    //             var errorMessage = "You have entered the wrong OTP three times.It will unblock after 15 minutes. Old OTP will not work after 15 minutes, So Generate new OTP after 15 Minutes.";

    //             $('#alertTitle').text("Failure!");
    //             $('#alertTitle').css("color", "#f00");      
    //             $('#alertMessage').text(errorMessage);    
    //             $('#alertMessage').css("color", "#f00");
    //             $('#messagePopUp').modal('toggle');
    //             this.messagePopUpTimeoutToogle();
    //         }else{

    //             this.loginService.submitEmail(loginOTPObject).then(response => {
                  
    //               if (!response.status) {
    //                 $('#alertTitle').text("Success!");
    //                 $('#alertTitle').css("color", "black");      
    //                 $('#alertMessage').text(response.message);    
    //                 $('#alertMessage').css('color', 'black');
    //                 $('#messagePopUp').modal('toggle');
    //                 this.messagePopUpTimeoutToogle();
    //               } else {
    //                 // show error message to the user
    //                 $('#alertTitle').text("Failure!");
    //                 $('#alertTitle').css("color", "#f00");      
    //                 $('#alertMessage').text(response.message);    
    //                 $('#alertMessage').css("color", "#f00");
    //                 $('#messagePopUp').modal('toggle');
    //                 this.messagePopUpTimeoutToogle();
    //               }
    //             })
    //       }
    //     }else{
    //         // show error message to the user
    //         $('#alertTitle').text("Failure!");
    //         $('#alertTitle').css("color", "#f00");      
    //         $('#alertMessage').text(result.message);    
    //         $('#alertMessage').css("color", "#f00");
    //         $('#messagePopUp').modal('toggle');
    //         this.messagePopUpTimeoutToogle();
    //     }
    //   })
    // }catch(error) {
    //   $('#alertTitle').text("Failure!");
    //   $('#alertTitle').css("color", "#f00");      
    //   $('#alertMessage').text("Activation email was not successful.Please try again");    
    //   $('#alertMessage').css("color", "#f00");
    //   $('#messagePopUp').modal('toggle');
    //   this.messagePopUpTimeoutToogle();
    // }
    this.userService.resendActivationEmailByAdmin(userDetails._id).then(response => {
      console.log("password response", response)

      if(response.status == 0){
        $('#alertTitle').text("Success!");
        $('#alertTitle').css("color", "black");      
        $('#alertMessage').text(response.message);    
        $('#alertMessage').css('color', 'black');
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }else{
        $('#alertTitle').text("Failure!");
        $('#alertTitle').css("color", "#f00");      
        $('#alertMessage').text(response.message);    
        $('#alertMessage').css("color", "#f00");
        $('#messagePopUp').modal('toggle');
        this.messagePopUpTimeoutToogle();
      }
     

    }).catch(error => {
      $('#alertTitle').text("Failure!");
      $('#alertTitle').css("color", "#f00");      
      $('#alertMessage').text("Activation email was not successful.Please try again");    
      $('#alertMessage').css("color", "#f00");
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle();
    })
  }

  changeTab(event) { }

 

}
