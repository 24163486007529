<!-- START: forms/internal assets -->
<section class="card mt-4 financeResult">
  <button _ngcontent-c3="" aria-label="Close" class="close" data-dismiss="modal" type="button">
      <span _ngcontent-c3="" aria-hidden="true">×</span>
  </button>
  <div class="nav-tabs-horizontal">
      <ul class="nav nav-tabs inverse" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" data-target="#profitAndLossTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="true"
                  (click)="tagToogle('profitAndLossTab')">Profit & Loss</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" data-target="#balanceSheet" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                  (click)="tagToogle('balanceSheetTab')">Balance Sheet</a>
          </li>
      </ul>
      <div class="tab-content">
          <div class="tab-pane active" id="profitAndLossTab" role="tabcard" aria-expanded="true">
              <div class="nav-tabs-horizontal">
                  <ul class="nav nav-tabs inverse" role="tablist" style="background: #fff;">
                      <li class="nav-item">
                          <a class="nav-link active" id="quartelyTrend" data-target="#quarterlyTrendStandaloneAndConsolidated" data-toggle="tab" href="javascript: void(0);"
                              role="tab" aria-expanded="true" (click)="getQuarterlyTrend(financialDataStandalone)">Quarterly Trend</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" id="annualTrend" data-target="#annualTrendStandaloneAndConsolidated" data-toggle="tab" href="javascript: void(0);"
                              role="tab" aria-expanded="true" (click)="getAnnualTrend(financialDataStandalone)">Annual Trend</a>
                      </li>
                  </ul>

                  <div class="tab-content">
                      <div class="tab-pane active" id="quarterlyTrendStandaloneAndConsolidated">
                          <div class="row sub-tabs-section">
                              <div class="tabs-2 widget_sp pull-left" id="standaloneQuarterlyTrend" (click)="getQuarterlyTrend(financialDataStandalone)">
                                  <span class="widget_sp_title">Standalone</span>
                              </div>
                              <div class="tabs-2 widget_sp" id="consolidatedQuarterlyTrend" (click)="getQuarterlyTrend(financialDataConsolidated)">
                                  <span class="widget_sp_title">Consolidated</span>
                              </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="annualTrendStandaloneAndConsolidated">
                          <div class="row sub-tabs-section">
                              <div class="tabs-2 widget_sp pull-left" id="standaloneAnnualTrend" (click)="getAnnualTrend(financialDataStandalone)">
                                  <span class="widget_sp_title">Standalone</span>
                              </div>
                              <div class="tabs-2 widget_sp" id="consolidatedAnnualTrend" (click)="getAnnualTrend(financialDataConsolidated)">
                                  <span class="widget_sp_title">Consolidated</span>
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
              
              <div class="card-block watermark">
                  <div id="quartelyDataSection" class="container trends">
                      <div class="row">
                          <div class="col-sm-12"  class="m-auto" width="100%" style="padding: 40px;">
                              <chart [options]="quarterlyPnLChart"></chart>
                          </div>
                      </div>
                  </div>
                  <div id="quartelyDataSectionCst" class="container trends" style="padding-top:0px !important;">                        
                      <div class="row">
                          <div class="col-lg-12">
                              <div class="title-2">Profit &amp; Loss (in Rs. Millions)</div>
                              <div class="table-responsive">
                                  <table class="table table-hover table-bordered nowrap table-striped dataTable" width="100%">
                                      <thead>
                                          <tr>
                                              <th style="text-align: left;">For The Quarter</th>
                                              <th *ngFor="let quarterlyData of financialQuarterlyResultData;">{{quarterlyData.FinancialQuater}}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td  style="text-align: left;">Revenue</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_NET_SALES | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Other Income</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_OTHER_INCOME | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Total Income</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_TOTAL_INCOME | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Expenditure</td>
                                              <td style="text-align: right;" *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_EXPENDITURE | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">PBIDT</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_OPERATING_PROFIT | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Interest</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_INTEREST | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Depreciation</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_DEPRECIATION | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Tax</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_TAX | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">PAT</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_PAT | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">EPS</td>
                                              <td *ngFor="let quarterlyData of financialQuarterlyResultData; let last = first">
                                                  <span>{{quarterlyData.financialData[0] ? (quarterlyData.financialData[0].qr_EPSABS | number) : " "}}</span>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div id="yearlyDataSection" class="container trends" style='display:none'>
                      <div class="row">
                          <div class="col-sm-12"  class="m-auto" width="100%" style="padding: 40px;">
                              <chart [options]="yearlyPnLChart"></chart>
                          </div>
                      </div>
                  </div>
                  <div id="yearlyDataSectionCst"  style='display:none;padding-top:0px !important;' class="container trends">
                      <div class="row">                            
                          <div class="col-lg-12">
                              <div class="title-2">
                                  Profit & Loss (in Rs. Millions)
                              </div>
                              <div class="table-responsive">
                                  <table class="table table-hover table-bordered table-striped dataTable" width="100%">
                                      <thead>
                                          <tr>
                                              <th style="text-align: left;">For The Year Ended</th>
                                              <th *ngFor="let yearlyData of financialYearlyResultData">
                                                  <div style="white-space: -o-pre-wrap;word-wrap: break-word;white-space: pre-line;white-space: -moz-pre-line;white-space: -pre-line;">{{yearlyData.FinancialQuater}}</div></th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td style="text-align: left;">Revenue</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? ( yearlyData.financialData[0].pl_Net_sales | number ) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Other Income</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Other_income | number ) : " " }}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Total Income</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Total_income | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Expenditure</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Total_expendiure | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">PBIDT</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Operating_profit | number ) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Interest</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Interest | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Depreciation</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Depreciation | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">Tax</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Taxation | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">PAT</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Profit_after_tax | number) : " "}}</span>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="text-align: left;">EPS</td>
                                              <td *ngFor="let yearlyData of financialYearlyResultData; let last = first">
                                                  <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].pl_Reported_EPS | number) : " "}}</span>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tab-pane" id="balanceSheet" role="tabcard" aria-expanded="false">

              <div class="row sub-tabs-section">
                  <div class="tabs-2 widget_sp pull-left" id="standaloneBalanceSheet" (click)="getBalanceSheetData(financialDataStandalone)">
                      <span class="widget_sp_title">Standalone</span>
                  </div>
                  <div class="tabs-2 widget_sp" id="consolidatedBalanceSheet" (click)="getBalanceSheetData(financialDataConsolidated)">
                      <span class="widget_sp_title">Consolidated</span>
                  </div>
              </div>

              <div class="card-block watermark">
                  <div class="title-2 mt-3">Balance Sheet for last 5 years (In Rs. Millions)</div>
                  <div class="container" style="padding:0 20px 50px;">
                      <div class="table-responsive">
                          <table class="table table-hover table-bordered table-striped dataTable" width="100%">
                              <thead>
                                  <tr>
                                      <th style="text-align: left;">For The Year Ended</th>
                                      <th *ngFor="let yearlyData of balanceSheetData">{{yearlyData.FinancialQuater}}</th>
                                  </tr>
                              </thead>
      
                              <tbody>                          
                                  <tr>
                                      <td style="text-align: left;">EQUITIES AND LIABILITIES</td>
                                      <td *ngFor="let yearlyData of balanceSheetData"></td>
                                  </tr>
                                  <tr>
                                      <td style="text-align: left;">Share Capital</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{ yearlyData.financialData[0] ? (yearlyData.financialData[0].Share_Capital | number) : " "}}</span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="text-align: left;">Reserves and Surplus</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{ yearlyData.financialData[0] ? (yearlyData.financialData[0].Reserve | number) : " "}}</span>
                                      </td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">Non-Current Liabilities</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{ yearlyData.financialData[0] ? (yearlyData.financialData[0].Total_Nonliab | number) : " "}}</span>
                                      </td>
                                  </tr>                          
      
                                 
                                  <tr>
                                      <td style="text-align: left;">Current Liabilities</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].Current_Laib_Prov_Nf | number) : " "}}</span>
                                      </td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">TOTAL</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].Total_Sounces_Funds_Nf | number ) : " "}}</span>
                                      </td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">ASSETS</td>
                                      <td *ngFor="let yearlyData of balanceSheetData"></td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">Non-Current Assets</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].Total_Nonassets | number) : " "}}</span>
                                      </td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">Current Assets</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].Currant_Assets_Nf | number) : " "}}</span>
                                      </td>
                                  </tr>
      
                                  <tr>
                                      <td style="text-align: left;">TOTAL</td>
                                      <td *ngFor="let yearlyData of balanceSheetData; let last = first">
                                          <span>{{yearlyData.financialData[0] ? (yearlyData.financialData[0].Total_Applications_Nf | number ) : " "}}</span>
                                      </td>
                                  </tr>
                                
                              </tbody>
      
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- END: forms/internal assets -->