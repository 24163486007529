import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl } from '@angular/platform-browser';


//import { MeetingAgendaViewComponent } from '../meetingagendaView/meetingagenda.component';
//import { MeetingcomplianceComponent } from '../meetingcompliance/meetingcompliance.component';
//import { MeetingdirectorComponent } from '../meetingdirector/meetingdirector.component';
//import { MeetinginviteeComponent } from '../meetinginvitee/meetinginvitee.component';
//import { MeetingmemberComponent } from '../meetingmember/meetingmember.component';
//import { MeetingattendanceComponent } from '../meetingattendance/meetingattendance.component';
//import { MeetingdocumentViewComponent } from '../meetingdocumentView/meetingdocument.component';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { environment } from './../../../../environments/environment';

//import { MeetingType } from '../../../enums/meetingType'
//import * as moment from 'moment';

import * as _ from 'lodash';
@Component({
  selector: 'app-meeting-view',
  templateUrl: './meeting-view.component.html',
  styleUrls: ['./meeting-view.component.css'],
})

export class MeetingViewComponent implements OnInit {

  public googleCalendarEventUrl: SafeUrl;
  public iCalendarEventUrl: SafeUrl;
  public outLookEventUrl: SafeUrl;
  public yahooEventUrl: SafeUrl;
  public microsoftEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;
  NotRegularMeetingTab: boolean = true;
  chat: boolean = environment.Service.Chat;
  isAttendInPerson: any;
  @Input('meetingObject') meetingObject: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private meetingMasterService: MeetingMasterService) { }

  ngOnInit() {
    this.isAttendInPerson = localStorage.getItem('joinInPerson')
    console.log("this.isAttendInPerson---->>", this.isAttendInPerson);
    document.getElementById("cover-spin").style.display = "block";
    document.body.className = document.body.className.replace("dark-theme", "");
    document.body.className = document.body.className.replace("dashboard-page", "");

    $('body').css('padding-top', '0px')

    // Avoid gray Screen from PSPDF if modal not closed
    $(".modal-backdrop").css("display", "none")

    // this.activatedRoute.params.subscribe(params => {
    //   if (params['meetingId']) {
    //var meetingId =  localStorage.getItem("meetingId")
    var pageUrl = window.location.href;
    var meetingId = pageUrl.split('/').pop();
    this.meetingMasterService.getMeetingById(meetingId).then(response => {
      this.meetingObject = response.meetingData[0]


      let user = JSON.parse(localStorage.getItem("user"));
      const agendaObject = []
      this.meetingObject.Agenda.forEach((element, index) => {
        let isAccess = true;
        element.AgendaAccessRights.forEach(subElement => {
          if ((subElement.UserGovevaId == user._id) && (subElement.View == false)) {
            isAccess = false;
          }
        })
        if (isAccess) {
          agendaObject.push(element)
        }
      });

      this.meetingObject.Agenda = agendaObject;

      //     console.log('4545454545454', response)
      //   })
      // } else {
      //   console.log('iiiiiiiiiiiiiii')
      // }
      document.getElementById("cover-spin").style.display = "none";
    })
  }

  // goToDashboard() {
  //   console.log('dashboard')
  //   window.parent.location.href = environment.UIBaseUrl+"/#/structure/(structure-outlet:dashboards/alpha)"; 
  //   // this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
  // }

  // leaveMeeting() {
  //   console.log('leave')
  //   window.parent.location.href = environment.UIBaseUrl+"/#/structure/(structure-outlet:dashboards/alpha)"; 
  //   // this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
  // }

}
