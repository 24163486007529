import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuard } from '../authGuard/auth.guard';
import { RouteGuard } from '../routeGaurd/route.guard';
import { BiscommunicationComponent } from './BIS/biscommunication/biscommunication.component';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { DashboardsAlpha } from './dashboards/alpha.page';
import { UINotificationComponent } from './notifications/notifications-UI.component';
import { GfmmoduleComponent } from './gfmmodule/gfmmodule.component';
import { GfmDashboardComponent } from './govevaFileManager/gfm-dashboard/gfm-dashboard.component';
import { ViewSmtpComponent } from '../smtp/view-smtp/view-smtp.component';
import { DataImportComponent } from '../data-import/data-import.component';


export const strucRoutes: Routes =
    [
        { path: 'dashboards/alpha', canActivate: [AuthGuard, RouteGuard], component: DashboardsAlpha, outlet: 'structure-outlet' },
        { path: '', redirectTo: 'dashboards/alpha', pathMatch: 'full' },
        { path: 'BIS/biscommunication', canActivate: [AuthGuard], component: BiscommunicationComponent, outlet: 'structure-outlet' },
        { path: 'companymaster', canActivate: [AuthGuard, RouteGuard], component: CompanymasterComponent, outlet: 'structure-outlet' },
        { path: 'rolemaster', canActivate: [AuthGuard, RouteGuard], component: RolemasterComponent, outlet: 'structure-outlet' },
        // { path: 'questionMasterBoardEval', component: QuestionMasterBoardEvalComponent, outlet: 'structure-outlet' },
        { path: 'UInotifications/:id', canActivate: [AuthGuard], component: UINotificationComponent, outlet: 'structure-outlet' },
        { path: '**', redirectTo: 'dashboards/alpha', pathMatch: 'full' },
        { path: 'GFM', component: GfmmoduleComponent, outlet: 'structure-outlet' },
        { path: 'gfmDashboard', component: GfmDashboardComponent, outlet: 'structure-outlet' },
        { path: 'conmpany-config', component: ViewSmtpComponent, outlet: 'structure-outlet' },
        { path: 'data-import', component: DataImportComponent, outlet: 'structure-outlet' },


    ];

export const structureRouting: any = RouterModule.forChild(strucRoutes);


// this.router.navigate([{ outlets: {modal: 'modal/user' + '/' + 'this.id'} }]); passing parameters in routes

