import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HasPermissionDirective } from '../../directive/has-permission.directive';
import * as _ from 'lodash';
// import { PssLink } from './../../enums/pss-link.enum'
import { PssLink } from './../../../environments/environment';
import { RocketChatService } from "./../../services/rocketChat.service";
import { Router } from '@angular/router';
import { TypeOfUser } from '../../enums/TypeOfUser';
import { environment } from './../../../environments/environment';

import { rocketChatRemove } from '../../enums/rocketChatRemove';
import { CommonFunction } from './../../helpers/commonFunction';

import { LastLoginDetailsService } from '../../services/last-login-details.service';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'cat-menu-left',
  templateUrl: './menu-left-vertical.component.html',
})
export class MenuLeftComponent implements OnInit, OnChanges {
  @Input('changedUser') changedUser: any;
  public menuName: string;
  public enableAssessmentSetupMenu: boolean;
  public menuLinkObject: any;
  public enableInternalAssessmentSetupMenu: boolean;
  public enableMasterMenu: boolean;
  public aprroveRoadmapEnable: boolean;
  enableLoginDetails:boolean = false;
  userType: any;
  upsiMenu:boolean;
  ncdMenu:boolean;
  masterMenuEnable: boolean;
  governanceMenuEnable: boolean;
  complianceMenuEnable: boolean;
  documentManagementMenuEnable: boolean;
  boardEvaluationMenuEnable:boolean;
  isEvaluationMasterEnable:boolean
  reportMenuEnable: boolean;
  taskAndActivityEnable: boolean;
  registerComplianceUser: boolean;
  processEnable: boolean;
  adminEnable: boolean;
  auditTrailEnable: boolean;
  addNewCompanyEnable: boolean;
  lawAndRegulationMenuEnable: boolean;
  UPSIDetailMenuEnable: boolean;
  UPSIBulkDataMenuEnable: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  upsiEmailMenuEnable : boolean = true;
  upsiEnable:boolean;
  lastLoginDetails:any;
  EvaluationManagementAndMonitoringMenuEnable: boolean;
  UPSIBulkData:any;
  // APP Configuration
  FinalMenu = environment.FinalMenu;
  isPSSConfig = environment.Service.PSS ;
  isGovevaConfig = environment.Service.GovEva ;
  isUPSIConfig = environment.Service.UPSI ;
  isAllConfig = environment.Service.All ;
  isNCDConfig = environment.Service.NCD ;
  isGEMConfig = environment.Service.GEM ;
  // isBoardEvaluationConfig = environment.Service.BoardEvaluation;
  // isGovernance =  environment.Service.Governance;
  MeetingMakerWithAgenda =  environment.Service.MeetingMakerWithAgenda;
  MeetingMakerWithoutAgenda =  environment.Service.MeetingMakerWithoutAgenda;
  //BaseMenuDisplay = environment.Service.BaseMenuDisplay;
  testvar: boolean = true;

  constructor(private lastloginService : LastLoginDetailsService,private router: Router, private rocketChatService: RocketChatService,private commonFunction:CommonFunction) {

  }

  ngOnInit() {
    const self = this;
    this.lastLoginDetails = this.lastloginService.getUserDetails()
    if(!_.isUndefined(this.lastLoginDetails)){
      this.enableLoginDetails = true;
    }else{
      this.enableLoginDetails = false;
    }
    
    this.enableAssessmentSetupMenu = false;
    this.upsiMenu = false;
    this.ncdMenu = false;
    this.enableInternalAssessmentSetupMenu = false;
    this.masterMenuEnable = false;
    this.boardEvaluationMenuEnable = false;
    this.governanceMenuEnable = false;
    this.complianceMenuEnable = false;
    this.documentManagementMenuEnable = false;
    this.reportMenuEnable = false;
    this.taskAndActivityEnable = false;
    this.registerComplianceUser = false;
    this.processEnable = false;
    this.adminEnable = false;
    this.lawAndRegulationMenuEnable = false;
    self.auditTrailEnable = false;
    self.addNewCompanyEnable = false;
    this.UPSIDetailMenuEnable = false;
    this.UPSIBulkDataMenuEnable = false;
    this.isSuperAdmin = false;
    this.UPSIBulkData = environment.pssUIBaseUrl + 'upsibulkdata';
    this.isAdmin = false;
    this.EvaluationManagementAndMonitoringMenuEnable=false;


    $(function () {

      // scripts for "menu-left" module

      /////////////////////////////////////////////////////////////////////////////////////////
      // add backdrop

      $('.cat__menu-left').after('<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>');

      /////////////////////////////////////////////////////////////////////////////////////////
      // submenu

      $('.cat__menu-left__submenu > a').on('click', function () {
        if ($('body').hasClass('cat__config--vertical') || $('body').width() < 768) {

          var parent = $(this).parent(),
            opened = $('.cat__menu-left__submenu--toggled');

          if (!parent.hasClass('cat__menu-left__submenu--toggled') && !parent.parent().closest('.cat__menu-left__submenu').length)
            opened.removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').slideUp(200);

          parent.toggleClass('cat__menu-left__submenu--toggled');
          parent.find('> .cat__menu-left__list').slideToggle(200);

        }

      });

      // remove submenu toggle class when viewport back to full view
      $(window).on('resize', function () {
        if ($('body').hasClass('cat__config--horizontal') || $('body').width() > 768) {
          $('.cat__menu-left__submenu--toggled').removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').attr('style', '');
        }
      });


      /////////////////////////////////////////////////////////////////////////////////////////
      // custom scroll init

      if ($('body').hasClass('cat__config--vertical')) {
        if (!(/Mobi/.test(navigator.userAgent)) && jQuery().jScrollPane) {
          $('.cat__menu-left__inner').each(function () {
            $(this).jScrollPane({
              contentWidth: '0px',
              autoReinitialise: true,
              autoReinitialiseDelay: 100
            });
            var api = $(this).data('jsp'),
              throttleTimeout;
            $(window).bind('resize', function () {
              if (!throttleTimeout) {
                throttleTimeout = setTimeout(function () {
                  api.reinitialise();
                  throttleTimeout = null;
                }, 50);
              }
            });
          });
        }
      }


      /////////////////////////////////////////////////////////////////////////////////////////
      // toggle menu

      // $('.cat__menu-left__action--menu-toggle').on('click', function () {
      //   $("#leftMenu").removeClass('visible');
      //   if ($('body').width() < 768) {
      //     $('body').toggleClass('cat__menu-left--visible--mobile');
      //   } else {
      //     $('body').toggleClass('cat__menu-left--visible');
      //   }
      // })

      // $('.cat__menu-left__action--backdrop-toggle').on('click', function () {
      //   $('body').removeClass('cat__menu-left--visible--mobile');
      // })

      // $('.cat__menu-left__item').on('click',function(){
      //   $('.cat__menu-left__submenu').removeClass('cat__menu-left__submenu--toggled');
      // })


      /////////////////////////////////////////////////////////////////////////////////////////
      // colorful menu

      var colorfulClasses = 'cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow',
        colorfulClassesArray = colorfulClasses.split(' ');

      function setColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').each(function () {
          var randomClass = colorfulClassesArray[Math.floor(Math.random() * colorfulClassesArray.length)];
          $(this).addClass(randomClass);
        })
      }

      function removeColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').removeClass(colorfulClasses);
      }

      if ($('body').hasClass('cat__menu-left--colorful')) {
        setColorfulClasses();
      }

      $('body').on('setColorfulClasses', function () {
        setColorfulClasses();
      });

      $('body').on('removeColorfulClasses', function () {
        removeColorfulClasses();
      });


    });

    let userInfo = JSON.parse(localStorage.getItem('user'))
    this.userType = userInfo.TypeOfUser;

    // console.log("approve this.userType", this.userType);
    if (this.userType == TypeOfUser.Director || this.userType == TypeOfUser.KMP) {
      //console.log("approve enable");
      this.aprroveRoadmapEnable = true
    } else {
      //console.log("approve disable");
      this.aprroveRoadmapEnable = false;
    }

    var userMailId=JSON.parse(localStorage.getItem('user')).EmailId;
    var decEmailId=self.commonFunction.decrypt(userMailId);
    if( decEmailId == 'superadmin@gmail.com') {
      self.isSuperAdmin = true
    }

    var userRolesArray=JSON.parse(localStorage.getItem('user')).Roles.map(role=>role.RoleName)//[0]['RoleName'];
    // var decEmailId=self.commonFunction.decrypt(userMailId);
    console.log("userRole", userRolesArray)
    if( userRolesArray.includes('Admin')|| userRolesArray.includes('All Role')) {
      self.isAdmin = true
    }


    let resourcesValue = JSON.parse(localStorage.getItem('resources'))
    // This is for the RFactor Assessment
    _.forEach(resourcesValue, function (data) {
      if (data.Menu == "Assessment") {
        _.forEach(data.ChildResources, function (element) {
          if ((element.DisplayName == "IC Execute") || (element.DisplayName == "IC Review")) {
            self.enableAssessmentSetupMenu = true;
          }
        })
      }


      if (data.Menu == "RoleMaster" || data.Menu == "UserMaster" || data.Menu == "CompanyMaster" || data.Menu == "CompanyFinancialDetails" || data.Menu == "Committee" || data.Menu == "Category" || data.Menu == "Holiday" || data.Menu == "NCD Document" || data.Menu == "AgendaGroup" || data.Menu == "DefaultMeeting" || data.Menu == "UPSI Master"|| data.Menu == "UPSI Parameter" || data.Menu =="department" || data.Menu =="branch" || data.Menu =="userteam") {
        self.masterMenuEnable = true;
      }

      if (data.Menu == "EvaluationType" || data.Menu == "EvaluationCriteria" || data.Menu == "EvaluationQuestion" || data.Menu == "EvaluationFormSetup" || data.Menu == "EvaluationFormReview" || data.Menu == "EvaluationFormAssign" || data.Menu == "EvaluationFormMonitoring" || data.Menu == "EvaluationFormExecution" || data.Menu == "QuestionnaireBank") {
        self.boardEvaluationMenuEnable = true;
      }

      if(data.Menu == "EvaluationType" || data.Menu == "EvaluationCriteria" || data.Menu == "EvaluationQuestion" || data.Menu == "EvaluationFormSetup"){
        self.isEvaluationMasterEnable = true;
      }

      if (data.Menu == "Meetings" || data.Menu == "Agenda" || data.Menu == "LibraryTask" || data.Menu == "ActivatedTask" || data.Menu == "ActivatedTaskAll"|| data.Menu == "Process" || data.Menu == "ActivatedProcessTasks" || data.Menu == "Director") {
        self.complianceMenuEnable = true;
      }

      if(data.Menu == 'UPSI Detail All' || data.Menu == 'UPSI Sharer' ||  data.Menu == 'UPSI Recipient'  ||  data.Menu == 'UPSI Detail Individual'  ||  data.Menu == 'UPSI Master' || data.Menu == 'UPSI Parameter'){
        self.upsiEnable =  true;
      }
  
      if(data.Menu == "Reports" || data.Menu == "ApproveRoadmap" || data.Menu == "Roadmap" || data.Menu == "CurrentStateAnalysisReport" || data.Menu == "Assessment" || data.Menu == "EmissionCalculator" ){
        self.governanceMenuEnable = true;
      }
      if(data.Menu == "UPSI Detail All" || data.Menu == "UPSI Recipient" || data.Menu == "UPSI Sharer" ||  data.Menu == "UPSI Detail Individual" ){
        self.upsiMenu = true
      }

      if(data.Menu == "Term Sheet" || data.Menu == "Term Sheet Library" || data.Menu == "Allottees Library"){
        self.ncdMenu = true
      }


      if (data.Menu === "DocumentManagement" || data.Menu === "DocumentType") {
        self.documentManagementMenuEnable = true;
      }

      if (data.Menu === "LibraryTask" || data.Menu === "ActivatedTask") {
        self.taskAndActivityEnable = true;
      }

      if (data.Menu === "Director" || data.Menu === "KMP") {
        self.registerComplianceUser = true;
      }

      if (data.Menu === "Process" || data.Menu === "ActivatedProcessTasks") {
        self.processEnable = true;
      }

      if (data.Menu === "Admin") {
        self.adminEnable = true;
        _.forEach(data.ChildResources, function (element) {
          if (element.DisplayName == "Audit Trail & Database Backup") {
            self.auditTrailEnable = true;
          }
          if (element.DisplayName == "Add New Company") {
            self.addNewCompanyEnable = true;
          }
        })

      }

      if (data.Menu === "LawsAndRegulations") {
        self.lawAndRegulationMenuEnable = true;
      }
      
      if (data.Menu === "UPSI Detail All" || data.Menu === "UPSI Detail Individual") {
        self.UPSIDetailMenuEnable = true;
        self.UPSIBulkDataMenuEnable = true;
        // console.log("UPSI Details Role",self.UPSIDetailMenuEnable);
      }

      if (data.Menu === "EvaluationFormMonitoring" || data.Menu === "EvaluationFormReports") {
        self.EvaluationManagementAndMonitoringMenuEnable = true;
      }

      // if(JSON.parse(localStorage.getItem('user')).EmailId == 'superadmin@gmail.com') {
      //   self.isSuperAdmin = true
      // }

     

      // if(data.Menu == "ComplianceEngine"){
      //   _.forEach(data.ChildResources, function(childData){     
      //     if(childData.Url === "/company/financeDetails?goveva=true&email=" || childData.Url === "/committee?goveva=true&email=" || childData.Url ===  "/category?goveva=true&email=" || childData.Url ===  "/holiday?goveva=true&email="){
      //       self.masterMenuEnable = true;
      //     }

      //     if(childData.Url === "/meeting?goveva=true&email=" || childData.Url === "/agenda?goveva=true&email=" || childData.Url === "/activatedTaskList?goveva=true&email=" || childData.Url === "/regulation?goveva=true&email=" || childData.Url === "/taskStatus?goveva=true&email=" ||  childData.Url === "/complianceSnapshot?goveva=true&email="){
      //       self.complianceMenuEnable = true;
      //     }

      //     if( childData.Url === "/taskStatus?goveva=true&email=" ||  childData.Url === "/complianceSnapshot?goveva=true&email="){
      //       self.reportMenuEnable = true;
      //     }

      //     if(childData.Url === "/documentType?goveva=true&email=" || childData.Url === "/documentManagement?goveva=true&email=") {
      //       self.documentManagementMenuEnable = true;
      //     } 


      //     if(childData.Url === "/group?goveva=true&email=" || childData.Url === "/tasks/library?goveva=true&email=" || childData.Url === "/tasks/activated?goveva=true&email=") {
      //       self.taskAndActivityEnable = true;
      //     } 

      //     if(childData.Url === "/process?goveva=true&email=" || childData.Url === "/process/activatedtasks?goveva=true&email="){
      //       self.processEnable = true;
      //     }
      //     //else  //remove else part -- It is for testing purpose.
      //      // self.taskAndActivityEnable = true;

      //   })
      // }
    })
    //RFactor Assessment end here

    // This is for the Internal Assessment 
    _.forEach(resourcesValue, function (data) {
      if (data.Menu == "Assessment") {
        _.forEach(data.ChildResources, function (element) {
          if ((element.DisplayName == "Execute") || (element.DisplayName == "Review") || (element.DisplayName == 'IC Review') || (element.DisplayName == 'IC Execute')) {
            self.enableInternalAssessmentSetupMenu = true;
          }
        })
      }
    })

    if(this.isUPSIConfig && this.isPSSConfig){
      this.upsiEmailMenuEnable = false;
    }
    // Internal Assessment end here

    // This is for the Master Menu 
    // _.forEach(resourcesValue, function (data) {
    //   if((data.Menu == "CompanyMaster") || (data.Menu == "RoleMaster") || (data.Menu == "UserMaster")) {
    //     self.enableMasterMenu = true
    //   }
    // })
    // Master menu end here
    //console.log('typeof', typeof (this.changedUser))

    
    // console.log("aprroveRoadmapEnable",(this.aprroveRoadmapEnable &&  (this.isGovevaConfig || this.isAllConfig)))

    if (typeof (this.changedUser) == 'string') {

      this.changedUser = JSON.parse(this.changedUser)
    }
    let emailAddress = this.changedUser.EmailId
    this.menuLinkInitialization(emailAddress);
  }

  ngOnChanges() {
    // //console.log('user is chenged', this.changedUser)
    if (typeof (this.changedUser) == 'string') {

      this.changedUser = JSON.parse(this.changedUser)
    }
    let emailAddress = this.changedUser.EmailId
    this.menuLinkInitialization(emailAddress);
  }

  toggleNav() {
    var x = document.getElementById('leftMenu').classList;
    var leftSpace = document.getElementById('maincontent').classList;

    if (x.contains('visible')) {
      x.remove('visible');
      leftSpace.remove('visible');
      var catobj:any = document.getElementsByClassName(
        'cat__menu-left__logo'
      )[0]
      catobj.style.width = '0';
    } else {
      x.add('visible');
      leftSpace.add('visible');
      var catobj:any = document.getElementsByClassName(
        'cat__menu-left__logo'
      )[0]
      catobj.style.width = '250px';
    }

    var meterclass = document.getElementById('meters').classList;
    var calClass = document.getElementById('meetingCol').classList;

    if (x.contains('visible')) {
      meterclass.add('col-md-12');
      meterclass.remove('col-md-6');
      calClass.add('col-md-12');
      calClass.remove('col-md-6');
    } else {
      meterclass.add('col-md-6');
      meterclass.remove('col-md-12');
      calClass.add('col-md-6');
      calClass.remove('col-md-12');
    }
  }
  
  menuLinkInitialization(emailAddress) {
    // //console.log('emailAddress', emailAddress)
    // let emailId = JSON.parse(localStorage.getItem('user')).EmailId;
    // //console.log('emailId', JSON.parse(localStorage.getItem('user')).EmailId)
    // setTimeout(function(){
    //   emailId = JSON.parse(localStorage.getItem('user')).EmailId
    // }, 1000)
    let companyId = JSON.parse(localStorage.getItem('companyDetailsGoveva')).value

    _.forEach(PssLink, function (element, data) {
      PssLink[data] = element.replace('{email}', emailAddress)
        .replace('{companyId}', companyId)
    })
    this.menuLinkObject = PssLink
  }

  activeMenu(data: string) {
    if(data == this.menuName){
      if(data == "Register" || data == "TaskAndActivities" || data == "Process" || data == "Certificate"){
        this.menuName = "Compliance";
      }else{
        this.menuName = "";
      }
    }else{
      this.menuName = data
    }
    
  }

  isComplianceMenuActive(active :String){

    if(active == "Compliance" || active == "TaskAndActivities" || active == "Process" || active == "Register" || active == "Certificate" ){
      return true
    }else{
      return false;
    }

  }

  redirectToCompanySetup() {

    //log out 

    if(rocketChatRemove.isRemove.toString() != 'true')
    {  
      this.rocketChatService.logoutFromRocketChat().then(rocketChatResponse => {
        if (rocketChatResponse.status == "success") {
          for (var count = 0; count < 10000; count++) {
            window.clearInterval(count);
          }
          window.localStorage.clear();
          // window.open(environment.pssUIBaseUrl + 'logout', "_self")
          //window.open(environment.pssUIBaseUrl + 'logout').close();  
          location.reload();
          //this.router.navigate(["/companySetup"]);                             
          this.router.navigate(["companySetup"]);
        }
      });
    }else{

      for (var count = 0; count < 10000; count++) {
        window.clearInterval(count);
      }
      window.localStorage.clear();
      location.reload();                            
      this.router.navigate(["companySetup"]);

    }

  }



}
