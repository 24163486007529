<section class="card mb-3 my-task" order-id="card-3">
    <div class="card-header">
        <div class="pull-right cat__core__sortable__control">
            <i class="icmn-minus mr-2 cat__core__sortable__collapse" data-original-title="Minimize"
                data-placement="left" data-toggle="tooltip" title=""></i>
            <i class="icmn-plus mr-2 cat__core__sortable__uncollapse" data-original-title="Expand" data-placement="left"
                data-toggle="tooltip" title=""></i>
        </div>
        <div class="pull-right" style="margin:0 10px;">
            <i class="fa fa-table" style="cursor: pointer;" data-original-title="Show Table" data-placement="left"
                data-toggle="tooltip" title="" (click)="bulkUpdateModal($event)"></i>
        </div>
        <h5 class="mb-0 mr-3 d-inline-block">
            My Task
        </h5>
        <div class="pull-right filterDrpDwn">
            <span class="dropdown cat__top-bar__avatar-dropdown">
                <span aria-expanded="false" class="dropdown-toggle lbl-wrapper" data-toggle="dropdown"
                    (click)="showDropDown = !showDropDown">
                    <span *ngIf="displayLabel == 'All'" class="filterDrpDwn_txt"
                        style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #7e77a7;">{{displayLabel}}</span>
                    <span *ngIf="displayLabel=='Pending'" class="filterDrpDwn_txt"
                        style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #3b73bb;">{{displayLabel}}</span>
                    <span *ngIf="displayLabel=='Partially Completed'" class="filterDrpDwn_txt"
                        style="padding: 0px 4px 2px; font-size:13px; font-weight: bold; vertical-align: middle; color: #d5a325;">{{displayLabel}}</span>
                    <span
                        *ngIf="displayLabel=='Completed' || displayLabel=='Done' || displayLabel=='Delayed Completion'"
                        class="filterDrpDwn_txt"
                        [ngStyle]='{"font-size" : displayLabel == "Delayed Completion" ? "13px" : "initial" }'
                        style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #32c359;">{{displayLabel}}</span>
                    <span *ngIf="displayLabel=='Cancelled' || displayLabel=='Not Applicable'" class="filterDrpDwn_txt"
                        style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #f26f6c;">{{displayLabel}}</span>

                </span>
                <ul aria-labelledby="" id="dropdown-menu" *ngIf="showDropDown"
                    class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">
                    <a class="dropdown-item " href="javascript:void(0)" (click)="filterTasks('All'); "
                        style="color: #7e77a7; ">All</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('Pending')"
                        style="color: #3b73bb; ">Pending</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('PartiallyCompleted')"
                        style="color: #d5a325;"><span style="font-size:13px">Partially Completed</span></a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('Completed')"
                        style="color: #32c359;">Completed</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('Done')"
                        style="color: #32c359;">Done</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('DelayedCompletion')"
                        style="color: #32c359;">Delayed Completion</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('Cancelled')"
                        style="color: #f26f6c;">Cancelled</a>
                    <div class="dropdown-divider m-0"></div>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="filterTasks('NA')"
                        style="color: #f26f6c;">Not Applicable</a>
                </ul>

            </span>

        </div>
    </div>
    <div class="card-block">
        <div *ngFor="let task of myTaskDetails;let j=index" (click)="taskDetails(task)" style="cursor: pointer;">
            <div class="task-widget-assigned row" *ngIf="task.ColorDisplayStatus=='Pending'">
                <div class="col-md-12 pb-3  px-0" style="border-bottom: 1px solid #e6e6e6;">
                    <div class="d-flex justify-content-between">
                        <p class="task-text" style="font-weight:500">{{task.TaskName}}</p>
                        <p class="task-text text-right">Company Name: {{task.CompanyName}}</p>
                    </div>
                    <div class="d-flex">
                        <i class="fa fa-circle circle-pin"></i>
                        <span>From: {{task.StartDate | date : "dd-MM-y"}}</span>
                        <span class="pull-right pr-3 end-date-label">End: {{task.EndDate | date : "dd-MM-y"}}</span>
                    </div>
                </div>
            </div>
            <div class="task-widget-started row" *ngIf="task.ColorDisplayStatus=='PartiallyCompleted'">
                <div class="col-lg-12 pb-3 px-0" style="border-bottom: 1px solid #e6e6e6;">
                    <div class="d-flex justify-content-between">
                        <p class="task-text" style="font-weight:500">{{task.TaskName}}</p>
                        <p class="task-text text-right">Company Name: {{task.CompanyName}}</p>
                    </div>
                    <div class="d-flex">
                        <i class="fa fa-circle circle-pin"></i>
                        <span>From: {{task.StartDate | date : "dd-MM-y"}}</span>
                        <span class="pull-right pr-3 end-date-label">End: {{task.EndDate | date : "dd-MM-y"}}</span>
                    </div>
                </div>
            </div>
            <div class="task-widget-completed  row"
                *ngIf="task.ColorDisplayStatus=='Completed' || task.ColorDisplayStatus=='Done' || task.ColorDisplayStatus=='DelayedCompletion'">
                <div class="col-lg-12 pb-3 px-0" style="border-bottom: 1px solid #e6e6e6;">
                    <div class="d-flex justify-content-between">
                        <p class="task-text" style="font-weight:500">{{task.TaskName}}</p>
                        <p class="task-text text-right">Company Name: {{task.CompanyName}}</p>
                    </div>
                    <div class="d-flex">
                        <i class="fa fa-check-circle circle-pin"></i>
                        <span>From: {{task.StartDate | date : "dd-MM-y"}}</span>
                        <span class="pull-right pr-3 end-date-label">End: {{task.EndDate | date : "dd-MM-y"}}</span>
                    </div>
                </div>
            </div>
            <div class="task-widget-danger row"
                *ngIf="task.ColorDisplayStatus=='Cancelled' || task.ColorDisplayStatus=='NA' || task.ColorDisplayStatus=='Expired'">
                <div class="col-lg-12 pb-3 px-0" style="border-bottom: 1px solid #e6e6e6;">
                    <div class="d-flex justify-content-between">
                        <p class="task-text" style="font-weight:500">{{task.TaskName}}</p>
                        <p class="task-text text-right">Company Name: {{task.CompanyName}}</p>
                    </div>
                    <div class="d-flex">
                        <i class="fa fa-circle circle-pin"></i>
                        <span>From: {{task.StartDate | date : "dd-MM-y"}}</span>
                        <span class="pull-right pr-3 end-date-label">End: {{task.EndDate | date : "dd-MM-y"}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>