import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { nextTick } from 'process';
import { CommonFunction } from './../../app/helpers/commonFunction';

@Injectable()
export class LoginService {
  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private commonFunction: CommonFunction
  ) {
    this.http = new HttpClient(handler);
  }

  //add new assessment
  login(loginModel): Observable<any> {
    let headers = {};
        let head = (key, header) => {headers[key] = header};
    // //head('Content-Type', 'application/json');
    return this.http
      .post(environment.nonValidateUrl + 'user/login', loginModel)
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  logOut(): Observable<any> {
    let headers = {};
        let head = (key, header) => {headers[key] = header};
    // head('Content-Type', 'application/json');
    var token = localStorage.getItem('token');
    head('x-access-token', token);
    return this.httpClient
      .get(environment.clientUrl + 'userMaster/logOut')
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  clearSession(body): Observable<any> {
    const url = environment.nonValidateUrl + 'clearUserSession';
    return this.http.post(url, body).pipe(catchError(
      function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
    ));
  }

  checkauthSecret(authSecret: any){
    return this.http
      .get(environment.nonValidateUrl + 'companyMaster/checkCompanyExists')
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  checkCompanyExists(): Observable<any> {
    return this.http
      .get(environment.nonValidateUrl + 'companyMaster/checkCompanyExists')
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  getResourcesForUserAuthorisationByUserId(userId): Observable<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
          'user/getResourcesForUserAuthorisation/' +
          userId
      )
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  companyListForUserManagement(): Observable<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
          'companyMaster/companyListForUserManagement'
      )
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  getCompanyData(): Observable<any> {
    return this.http
      .get(
        environment.nonValidateUrl +
          'companyMaster/companyListForUserManagement'
      )
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  loginWithoutPassword(loginwithoutModel) {
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    head('Content-Type', 'application/json');
    let data = this.commonFunction.encrypt(JSON.stringify(loginwithoutModel))    
    return this.http.post(environment.nonValidateUrl + 'user/checkUserValidation', { encryptedToken: data })
    .map(res => res['data'] ? JSON.parse(this.commonFunction.decrypt(res['data'])) : res);
  }

  loginWithUniqueUserId(loginwithoutModel) {
    loginwithoutModel.oldUserID = JSON.parse(localStorage.getItem('user'))._id;
    let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('Content-Type', 'application/json');
    return this.http
      .post(
        environment.nonValidateUrl + 'user/loginWithUniqueUserId',
        loginwithoutModel
      )
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  //get the company ids by user email id
  getCompanyIdsByEmailId(emailId) {
    // const reqHeader = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'No-Auth': 'True',
    // });
    // in get           // , { headers: reqHeader }

    // in pipe
    // map((res: Response) => {
    //   const dbgres = res.json();
    //   console.log(`debug: ${dbgres}`);
    // }),

    // return this.http
    //   .get(
    //     `${environment.nonValidateUrl}userMaster/getCompanyIdsByUserEmail/${emailId}`
    //   )
    //   .pipe(
    //     catchError(() => {
    //       this.handleError('yucky');
    //       return EMPTY;
    //     })
    //   );
    type ApiResponse = {
      [key: string]: any;
      message: string;
      status: number
    };

    return this.http.get(
      `${environment.nonValidateUrl}userMaster/getCompanyIdsByUserEmail/${emailId}`
    )
    .pipe(
      map((res: ApiResponse) => {
        res.companyIds = JSON.parse(this.commonFunction.decrypt(res.companyIds))
        return res;
      })
    )
  }

  //get the company ids by user email id
  getCompanyListByIds(companyIds) {
    let headers = {};
        let head = (key, header) => {headers[key] = header};
    // head('Content-Type', 'application/json');
    type ApiResponse = {
      [key: string]: any;
      message: string;
      status: number
    };

    return this.http.post(
      environment.nonValidateUrl + 'companyMaster/getCompanyListByIds',
      companyIds
    ).pipe(
      map((res: ApiResponse) => {
        res.companyList = JSON.parse(this.commonFunction.decrypt(res.companyList))
        // console.log('res',res)
        return res;
      }),
      catchError(this.handleError)
    )
    // .pipe(
    //   map((res: Response) => res.json()),
    //   catchError(this.handleError)
    // );
  }

  loginForADUser(loginModel): Observable<any> {
    return this.http
      .post(environment.nonValidateUrl + 'adUser/login', loginModel)
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  private handleError(error) {
    console.error('An error occurred in login service', error); // for demo purposes only
    return throwError(error);
    //return from(Promise.reject(error.message || error));
  }

  checkRoutesAuth(FnlUrlState): Observable<any> {
    var body = {
      url: FnlUrlState,
    };
    return this.httpClient.post(environment.clientUrl + 'user/routesAuth', body)
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }

  clearTokenByUserAgent(loginModel): Observable<any> {
    return this.http.post(environment.clientUrl + 'user/clearLoginToken', loginModel)
      .pipe(catchError(
        function (error) {
          console.error('An error occurred in login service', error); // for demo purposes only
          return throwError(error);
          //return from(Promise.reject(error.message || error));
        }
      ));
  }
}
