<section class="card mb-3 progress-meter" order-id="card-1">
  <div class="card-header">
    <h6 class="mb-0 mr-3 d-inline-block">
      Progress Meter
    </h6>
  </div>
  <div class="card-block container pt-0">
    <div class="row">
      <!-- <div class="col-md-12 col-sm-12" style="padding: 25px 25px 0;">
                <div id="complianceContainer" style="cursor: pointer;" (click)="showComplianceAndGovernance('Compliance')" class="m-auto"
                    width="100%" *ngIf="complianceTaskStatus">
                    <div class="d-flex justify-content-between">
                        <label>Compliance</label>
                        <span id="complienceCount"></span>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" id="complianceChart" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <div class="m-auto" *ngIf="!complianceTaskStatus" >
                    <span style="font-weight: 600;color: #000;">No Compliance available</span>
                </div>
            </div> -->

      <!-- <div class="col-md-12 col-sm-12" style="padding: 30px 25px 0;">
        <div id="roadMapContainer" (click)="showComplianceAndGovernance('Roadmap')" class="m-auto" width="100%"
          *ngIf="roadMapTaskStatus">
          <div class="d-flex justify-content-between">
            <label>Roadmap</label>
            <span id="roadmapCount"></span>
          </div>
          <div class="progress">
            <div class="progress-bar" id="roadMapTaskChart" role="progressbar" aria-valuenow="0" aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
        <div class="m-auto" *ngIf="!roadMapTaskStatus">
          <div class="d-flex justify-content-between" style="margin-bottom:5px; font-weight: 600;color: #000;">
            <label>No Road map available</label>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>