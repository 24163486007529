import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { CompanySetupComponent } from './companySetup/companySetup.component';
import { SuperAdminSetupComponent } from './superAdminSetup/superAdminSetup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { StructureComponent } from './structure/structure.component';
import { CreateiccheckericmakerComponent } from './createiccheckericmaker/createiccheckericmaker.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard } from './authGuard/auth.guard';
import { SubmitOtpComponent } from './otp-login/submit-otp/submit-otp.component';
import { OTPLoginComponent } from './otp-login/otp-login.component';
import { BoardEvalStaticComponent } from './board-eval-static/board-eval-static.component';
import { AutologinComponent } from './autologin/autologin.component';
import { WebexComponent } from './structure/dashboards/webex/webex.component';

const routes: Routes = [
  { path: 'board', component: BoardEvalStaticComponent },
  { path: 'login', component: LoginComponent },
  { path: 'autologin/:UniqueUserId/:CompanyId/:authSecret', component: AutologinComponent },
  { path: 'autologin/:UniqueUserId/:CompanyId/:authSecret/:report', component: AutologinComponent },
  { path: 'companySetup', component: CompanySetupComponent },
  {
    path: 'createIntuitConsultant',
    component: CreateiccheckericmakerComponent,
  },
  { path: 'superAdminSetup', component: SuperAdminSetupComponent },
  {
    path: 'structure',
    canActivate: [AuthGuard],
    component: StructureComponent,
    loadChildren: () =>
      import('./structure/structure.module').then((m) => m.StructureModule),
  },
  {
    path: 'changePassword',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent,
  },
  { path: 'login-with-otp', component: OTPLoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'Submit/LoginOTP', component: SubmitOtpComponent },
  { path: 'Submit/LoginOTP/:otpId', component: SubmitOtpComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // {
  //   path: 'webex/:meetingId',
  //   canActivate: [AuthGuard],
  //   component: WebexComponent,
  // },
  { path: '**', redirectTo: 'pages/page-404' },
];

const routes2: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/page-404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledNonBlocking',
    }), // DEV_TODO: Remove  enableTracing: true
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
