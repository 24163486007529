import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import * as io from 'socket.io-client';

@Injectable()
export class SocketService {
  /*
   * specifying Base URL.
   */
  private BASE_URL = 'http://localhost:3000';
  private socket: any; //: SocketIOClient.Socket;

  constructor() {}

  /*
   * Method to connect the users to socket
   */
  connectSocket(userId: string) {
    this.socket = io.connect(this.BASE_URL, { query: { userId: userId } });
  }

  /*
   * Method to emit the add-messages event.
   */
  sendMessage(message: any): void {
    this.socket.emit('add-message', message);
  }

  /*
   * Method to emit the logout event.
   */
  logout(userId): any {
    this.socket.emit('logout', userId);

    let observable = new Observable((observer) => {
      this.socket.on('logout-response', (data) => {
        observer.next(data);
      });

      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

  /*
   * Method to receive add-message-response event.
   */
  receiveMessages(): any {
    let observable = new Observable((observer) => {
      this.socket.on('message', (data) => {
        // console.log('hello');
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

  /*
   * Method to receive chat-list-response event.
   */
  getChatList(userId: string): any {
    this.socket.emit('chat-list', { userId: userId });

    let observable = new Observable((observer) => {
      this.socket.on('chat-list-response', (data) => {
        observer.next(data);
      });

      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }
}
