<section style="background:#000;width:100% !important;height:100vh !important;">

    <div class="container-fluid" style="height: 100% !important;">
        <!-- LOADER -->
        <div id="cover-spin">
            <div class="container" style="height: 200px;position: relative;">
                <div class="vertical-center"
                    style="margin: 0;position: absolute;top: 240%;text-align:center;width: 100%;padding: 0 20px;">
                    Please wait for a few seconds, while the system checks and ensures a secure meeting environment for you
                </div>
            </div>
        </div>
        <div class="row" style="height: 100% !important;">
            <div id="sec1" class="col-lg-6 col-sm-6 col-xs-12" style="padding:0px !important;height: 100% !important;overflow: hidden;">

                <div id="internetstatus" style="
                    background-color:  red;color: white;text-align: center;font-size: large;
                    position: absolute; top: 0; z-index: 1000; width: 100%;">
                    <b>Poor Internet Connection...<a href="javascript:void()"
                            style="color: white;font-size: large;text-decoration: underline;"
                            (click)="refresh()">Retry</a>
                    </b>
                </div>

                <div class="col-md-12" style="flex: 1; display: flex; flex-direction: column; position:relative;padding:0px !important;height: 100% !important; overflow: hidden;">
                    <div id="main-video" class="main-img">
                        <span style="top: 10px;right: 15px;position: absolute;z-index: 99;" id="full-screen"
                            type="button">
                            <img id="expand" alt="expand" src="../assets/images/fullscreen-white-icon.png" title="Full screen" />
                            <img id="compress" alt="compress" src="../assets/images/exit-fullscreen-icon.png" title="Exit full screen" style="display: none;margin-top: 10px;"/>
                        </span>
                        <div id="video-container">
                            <video class="text-center" id="remote-screen" style="display: none;" muted autoplay playsinline></video>
                            <video #videoElement class="text-center" id="remote-view-video" autoplay playsinline></video>
                        </div>
                        <audio id="remote-view-audio" autoplay playsinline></audio>
                    </div>
                    <div class="inner-box">
                        <div class="row thumb-img" id="innerVideo">
                            <video
                                style="left: 20px; display:none"
                                id="self-screen" class="slef-video-view" muted autoplay playsinline=""></video>
                        </div>
                    </div>
                </div>
                <video class="reverseMirror slef-video-view " style="right: 20px"
                                id="self-view" muted autoplay playsinline=""></video>
            </div>


            <div id="sec2" class="col-lg-6 col-sm-6 col-xs-12"
                style="padding:0px !important;height: 100% !important;background-color: #d9efee; overflow: hidden; display: flex; flex-direction: column;">
                <div id="docFull-screen" type="button">
                    <button title="Full screen" id="fullscreen" class="rhsButtons"
                        style="float: right;margin-top: 10px;margin-right: 10px;">
                        <img id="expandDoc" alt="fullscreen-black-icon" src="../assets/images/fullscreen-black-icon.png" title="Full screen" />
                    </button>
                    <button title="Exit full screen" id="exitscreen" class="rhsButtons"
                        style="display: none;float:right;margin-right: 10px;margin-top: 10px;">
                        <img id="compressDoc" alt="exit-fullscreen-black" src="../assets/images/exit-fullscreen-black.png" title="Exit full screen" style="display: none;"/>
                    </button>
                    <button title="Meeting Information" class="rhsButtons" style="float:right;margin-top: 0px;" data-toggle="modal"
                        data-target="#myModal"><i id="meetingInfo" class="fas fa-info-circle"
                            style="color: #07a7a5;font-size: x-large;margin-top: 10px;"></i>
                    </button>
                </div>
                <div class="main-img" style="padding:0px !important; overflow: auto;">                    
                    <iframe id="iframeWind" frameBorder="0" style="width: 100%;height: 100% !important;" src=""></iframe>
                </div>                
            </div>      
        <div style="clear:both;"></div>
         <div id="ControlBtn" class="col-lg-12 col-sm-12 col-xs-12" style="position: absolute;bottom: 0;left: 0;right: 0;clear: both;padding:0px !important;display:none;">
          <div class="inner-box-old">
            <div class="row thumb-img" id="innerVideo">        
              <div id="contorl-buts" class="col-md-12 text-center" style="height:50px;background-color: #D9EfEE; ">
                <!-- <button id="endMeeting" title="End Meeting" type="button" style="display:none;">
                    <i style="background: #ff0000;color: white;" class="fa fa-times"></i>
                </button> -->
                <button id="leaveMeeting" title="Leave Meeting" style="display:none;">
                    <i style="background: #ff0000;color: white;" class="fa fa-phone"></i>
                </button>

                <button title="endleaveMeeting" id="endleaveMeeting" data-toggle="modal"
                        data-target="#myModalNew" style="display:none;">
                        <i style="background: #ff0000;color: white;" class="fa fa-times"></i>
                </button>

                <button id="allMuteUnmute" type="button" style="display:none;border-radius: 25px;background-color:black ;width:120px">
                    <div id="allMute" title="Unmute All" (click)="onMuteallChange('Umuteall')" style="color:white;font-size: 11px">
                    <i class="fas fa-microphone-slash"></i> Unmute All
                    </div>
                    <div id="allUnmute" title="Mute All" (click)="onMuteallChange('Muteall')" style="color:white;font-size: 11px">
                    <i class="fas fa-microphone"></i> Mute All
                    </div>
                </button>

                <button id="muteUnmute" type="button">
                    <i id="mute" title="Unmute Audio" class="fas fa-microphone-slash"></i>
                    <i id="unmute" title="Mute Audio" class="fas fa-microphone"></i>
                </button>
                
                <button id="videoOnOff" type="button">
                    <i id="videoOff" title="Turn camera on" class="fas fa-video-slash"></i>
                    <i id="videoOn" title="Turn camera off" class="fas fa-video"></i>
                </button>
                <button id="share-screen" title="Share Screen" type="button">
                    <i class="fas fa-desktop"></i>
                </button>

                <button title="Reconnect" (click)="refresh()" type="button">
                    <i class="fas fa-refresh"></i>
                </button>
                <button id="changeLayout" type="button">
                    <i style="color: white;" title="Change Layout" class="fas fa-th-large"></i>
                </button>               
                <button id="dashboard" type="button" (click)="openIfram();">
                    <i style="color: white;" title="Dashboard" class="fa fa-dashboard"></i>
                </button>
                <button id="iframeBackButton" type="button" (click)="openMeeting();">
                    <i style="color: white;" title="Meeting Details" class="fa fa-info"></i>
                </button>
                <button id="recording" type="button" style="display:none; border-radius: 25px;background-color:black ;border: none;color: white;text-align: center;text-decoration: none;font-size: 15px;cursor: pointer;width:130px">
                    <div id="playButton" title="click to pause recording" (click)="onPageChange('recordingStarted')" style="font-size: 11px;color: red;">
                    <i class='fas fa-record-vinyl fa-lg' style="color: red;vertical-align: sub;font-size:2.0em"></i>  Recording On
                </div>
                <div id="pauseButton" title="click to resume recording" (click)="onPageChange('recordingPaused')" style="font-size: 11px;color: white;">
                   <i class='fas fa-record-vinyl fa-lg' style="color: white;vertical-align: sub;font-size:2.0em"></i>  Recording Off
                </div>
                </button>
              </div>
            </div>
          </div>        
         </div>  
     </div>
    </div>

    <!-- Modal 1 for Meeting Details-->
    <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog" style="float: right;padding-right: 60px;">

            <!-- Modal content-->
            <div class="modal-content" style="width: 400px;">
                <div class="modal-header">
                    <h4 class="modal-title">Meeting Details</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <table>
                        <tbody>
                            <tr>
                                <th>SIP Login:</th>
                                <td>{{SIPURLforMeeting}}</td>
                            </tr>
                            <tr>
                                <th>IP Login:</th>
                                <td></td>
                            </tr>
                            <tr>
                                <th class="insideTr">1. IP Address:</th>
                                <td>{{ciscoIP}}</td>
                            </tr>
                            <tr>
                                <th class="insideTr" style="padding-left: 40px;">2. Meeting Number:</th>
                                <td>{{meetingNumber}}</td>
                            </tr>
                            <tr>
                                <th class="insideTr">3. Password:</th>
                                <td>{{meetingPassword}}</td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

     <!-- Modal 2 for Leave Meeting Popup-->
     <div class="modal fade" id="myModalNew" role="dialog">
        <div class="modal-dialog" style="  position: absolute; width: 20px; bottom: -2px; margin-left: 400px;">

            <!-- Modal content-->
            <div class="modal-content" style="width: 180px;">
                <div class="modal-header" style="height: 20px;">
                    <button type="button" class="close" data-dismiss="modal" style="margin-left:140px">&times;</button>
                </div>
                <div class="modal-body">
                    <table>
                        <tbody>     
                            <tr>
                                <button id="leaveMeetingNew" title="Leave Meeting" style="width:150px;height:40px">
                                    <i class="fa fa-sign-out" style="font-size: 1.0em;"></i>  Leave meeting
                                </button>
                            </tr>
                            <hr>
                            <tr>
                                <button id="endMeetingNew" title="End Meeting" type="button" style="width:150px;height:40px">
                                    <i class="fa fa-times"></i>  End meeting for all
                                </button>
                              </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

 

</section>