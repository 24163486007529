<div class="card-block" style="padding: 0px  0px;">
  <div class="nav-tabs-horizontal">
  
    <span *ngIf="isRegularMeetingConfig" class="heading-button" (click)="regularMeetingModal()">
      <input id="hiddenInput" type="hidden">
      <span style="cursor: pointer;">Create</span>
    </span>
  
    <div class="pull-right">
      <span class="dropdown cat__top-bar__avatar-dropdown mr-4">
        <span _ngcontent-c3="" aria-expanded="false" class="dropdown-toggle dd-text" data-toggle="dropdown"
          style="cursor: pointer; vertical-align: middle;">
          <span class="dd-box meeting-filter-color">{{"Meeting " + sortByType}}</span>
        </span>
  
        <ul _ngcontent-c3="" aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing"
          role="menu">
          <a _ngcontent-c3="" class="dropdown-item" href="javascript:void(0)" (click)="sortBy('TIME')">Meeting Time</a>
          <div _ngcontent-c3="" class="dropdown-divider m-0"></div>
          <a _ngcontent-c3="" class="dropdown-item" href="javascript:void(0)" (click)="sortBy('NAME')">Meeting Name</a>
        </ul>
      </span>
    </div>
  
    <div class="pull-right">
      <span class="dropdown cat__top-bar__avatar-dropdown">
        <span aria-expanded="false" class="dropdown-toggle meeting" data-toggle="dropdown"
          style="cursor: pointer; vertical-align: middle;">
          <span *ngIf="displayLabel=='All'"
            style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #7e77a7;">{{displayLabel}}</span>
          <span *ngIf="displayLabel=='Today'"
            style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color:  #32c359 ;">{{displayLabel}}</span>
          <span *ngIf="displayLabel=='Future'"
            style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #d5a325;">{{displayLabel}}</span>
          <span *ngIf="displayLabel=='Past'"
            style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #f26f6c;">{{displayLabel}}</span>
        </span>
  
  
  
        <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">
          <a class="dropdown-item" href="javascript:void(0)" (click)="filterMeetings('All')"
            style="color: #7e77a7; ">All</a>
          <div class="dropdown-divider m-0"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="filterMeetings('Today')"
            style="color:  #32c359; ">Today</a>
          <div class="dropdown-divider m-0"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="filterMeetings('Future')"
            style="color:  #d5a325;">Future</a>
          <div class="dropdown-divider m-0"></div>
          <a class="dropdown-item" href="javascript:void(0)" (click)="filterMeetings('Past')"
            style="color: #f26f6c;">Past</a>
        </ul>
      </span>
  
    </div>
  
  </div>
  <div class="card-block pl-0 pr-0 pt-2 pb-1 text-center  mCustomScrollbar" style="overflow-y: auto; "
    *ngIf="isMeetingListEmpty" [ngClass]="isRocketChatRemove ? 'task-custom-scroll':'custom-height'">
    No Meetings.
  </div>

  <div class="card-block" style="padding:15px 0">
    <div class="card-block pl-0 pr-0 pt-2 pb-1 text-center  mCustomScrollbar" style="overflow-y: auto; "
      *ngIf="isMeetingListEmpty" [ngClass]="isRocketChatRemove ? 'task-custom-scroll':'custom-height'">
      No Meetings.
    </div>

    <div class="card-block  task-custom-scroll mCustomScrollbar" style="overflow-y: auto;" *ngIf="!isMeetingListEmpty"
      [ngClass]="isRocketChatRemove ? 'task-custom-scroll':'custom-height'">
      <div *ngFor="let meeting of regularAndComplianceMeetingList; let i=index" style="cursor: pointer">

      <div class="meeting-Future mb-2 row" *ngIf="meeting.meetingStatus == 'Future'">
        <div class="col-lg-12 row">
          <div class="col-sm-12 col-md-6 pl-0" style="font-weight:600" (click)="meetingModal(meeting)">
            {{meeting.MeetType=="Meeting" ? meeting.MeetingTypeName: "CR-"+meeting.MeetingTypeName }}
          </div>
          <div class="col-sm-12 col-md-6 text-right  d-flex justify-content-end" >
            <div *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-warning" style="padding: 0.08rem 0.48rem;font-size: 10px"
                id="{{meeting.meetingId}}" (click)="loadRegularMeetingByID(meeting.meetingId);">
                <span class="fa fa-pencil"></span></button>
            </div>
            <div *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-danger"
              style="padding: 0.08rem 0.48rem;font-size: 10px;margin-left: 5px;"
              (click)="activeModal.dismiss('dismissed');confirmDelete(meeting.meetingId)">
              <span class="icmn-bin"></span>
            </button>   
            </div>
          </div>
          <div class="meeting-info">
            <ul>
              <li>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType != 'REGULAR_MEETING'"
                  class="meeting-text">{{ meeting.meetingType ?
                  meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                  meeting.MeetingTypeID.MeetingType : " " ) : " " )}}
                </div>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType == 'REGULAR_MEETING'"
                      class="meeting-text">{{ meeting.meetingType ?
                      meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                      meeting.MeetingTypeID.MeetingType : " " ) : " " )}}
                </div>
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{meeting.meetingType ? (meeting.meetingDate | date : 'dd-MM-yyyy') : (meeting.MeetingDate | date :
                  'dd-MM-yyyy')}}
                </div>
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{ meeting.meetingType ? meeting.meetingTime : meeting.MeetingTime}}</div>
              </li>
            </ul>

            <div class="ml-auto" style="color: #979797;margin-top: 5px;margin-right: 40px;">Location</div>
          </div>
        </div>
      </div>

      <div class="meeting-Today mb-2 row" *ngIf="meeting.meetingStatus == 'Today'">
        <div class="col-lg-12 row">
          <div class="col-sm-12 col-md-6 pl-0" style="font-weight:600" (click)="meetingModal(meeting)">
            {{meeting.MeetType=="Meeting" ? meeting.MeetingTypeName: "CR-"+meeting.MeetingTypeName }} 
          </div>
          <div class="col-sm-12 col-md-6 text-right  d-flex justify-content-end">
            <div *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-warning" style="padding: 0.08rem 0.48rem;font-size: 10px"
                id="{{meeting.meetingId}}" (click)="loadRegularMeetingByID(meeting.meetingId);">
                <span class="fa fa-pencil"></span></button>
            </div>
            <div *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-danger"
                style="padding: 0.08rem 0.48rem;font-size: 10px;margin-left: 5px;"
                (click)="activeModal.dismiss('dismissed');confirmDelete(meeting.meetingId)">
                <span class="icmn-bin"></span>
              </button>
            </div>
          </div>
          <div class="meeting-info">   
            <ul>
              <li>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType != 'REGULAR_MEETING'"
                  class="meeting-text">{{ meeting.meetingType ?
                  meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                  meeting.MeetingTypeID.MeetingType : " " ) : " " )}}
                </div>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType == 'REGULAR_MEETING'"
                  class="meeting-text">{{ meeting.meetingType ?
                  meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                  meeting.MeetingTypeID.MeetingType : " " ) : " " )}}   
                </div>
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{meeting.meetingType ? (meeting.meetingDate | date : 'dd-MM-yyyy') : (meeting.MeetingDate | date :
                  'dd-MM-yyyy')}}
                </div>
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{ meeting.meetingType ? meeting.meetingTime : meeting.MeetingTime}}</div> 
              </li>
            </ul>
            <div class="ml-auto" style="color: #979797;margin-top: 5px;margin-right: 40px;">Location</div>
          </div>
        </div>
      </div>

      <div class="meeting-Past row" *ngIf="meeting.meetingStatus == 'Past'" style="position: relative;">
        <div class="col-lg-12 row">
          <div class="col-sm-12 col-md-6 pl-0" style="font-weight:600" (click)="meetingModal(meeting)">
            {{meeting.MeetType=="Meeting" ? meeting.MeetingTypeName: "CR-"+meeting.MeetingTypeName }}
          </div>
          <div class="col-sm-12 col-md-6 text-right  d-flex justify-content-end">
            <div  *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-warning" style="padding: 0.08rem 0.48rem;font-size: 10px"
              id="{{meeting.meetingId}}" (click)="loadRegularMeetingByID(meeting.meetingId);">
                <span class="fa fa-pencil"></span></button>
            </div>
            <div  *ngIf="meeting.meetingType == 'REGULAR_MEETING'">
              <button type="button" class="btn-sm btn btn-danger"
                style="padding: 0.08rem 0.48rem;font-size: 10px;margin-left: 5px;"
                (click)="activeModal.dismiss('dismissed');confirmDelete(meeting.meetingId)">
                <span class="icmn-bin"></span>
              </button>
            </div>
          </div>
          <div class="meeting-info">
            <ul>
              <li>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType != 'REGULAR_MEETING'"
                    class="meeting-text">{{meeting.meetingType ?
                    meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                    meeting.MeetingTypeID.MeetingType
                    : " " ) : " ")}}
                </div>
                <div (click)="meetingModal(meeting)" *ngIf="meeting.meetingType == 'REGULAR_MEETING'"
                    class="meeting-text">{{meeting.meetingType ?
                    meeting.meetingName : (meeting.MeetingTypeID ? (meeting.MeetingTypeID.MeetingType ?
                    meeting.MeetingTypeID.MeetingType
                    : " " ) : " ")}}
                </div>    
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{meeting.meetingType ? (meeting.meetingDate | date : 'dd-MM-yyyy') : (meeting.MeetingDate | date :
                  'dd-MM-yyyy')}}
                </div>
              </li>
              <li>
                <div (click)="meetingModal(meeting)">
                  {{meeting.meetingType ? meeting.meetingTime : (meeting.MeetingTime)}}
                </div>
              </li>
            </ul>
            <div class="ml-auto" style="color: #979797;margin-top: 5px;margin-right: 40px;">Location</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="confirmDeleteMeetings" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  style="top: 50px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabelForDelete">
          <i class="fa fa-question-circle-o mr-2"></i>Confirm
        </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            Are you sure you want to delete regular meeting?
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger" (click)="deleteRegularMeeting()" data-dismiss="modal">
          <i class="fa fa-check"></i>Yes
        </button>
        <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">
          <i class="fa fa-times"></i>No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ALERT MODAL-->
<div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  style="top: 50px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
        Title!
      </div>
      <div class="modal-body" style="padding: 10px 15px;">
        <div id="alertMessage" style="font-size: 16px;">
          Message..
        </div>
      </div>
    </div>
  </div>
</div>