import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @Input() notify;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const self = this;
    setTimeout(function(){
      self.activeModal.dismiss('dismiss')
    }, 3000)
  }

}
