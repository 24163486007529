import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from '../../../app/forgot-password/notification/notification.component';
import { CommonFunction } from '../../../app/helpers/commonFunction';
import { UserMasterService } from '../../../app/services/user-master.service';


import { SmtpService } from '../../services/smtp.service';


@Component({
  selector: 'app-view-smtp',
  templateUrl: './view-smtp.component.html',
  styleUrls: ['./view-smtp.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSmtpComponent implements OnInit {


  public smtp: any;
  public smtpForm: FormGroup;
  isDisable: boolean = true;
  isEditable: boolean = true; //for edit button
  submitted = true;
  pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\/. ]*[a-zA-Z.]+[a-zA-Z0-9]*$";//Pattern for validating blank input and space at first place
  authForm: FormGroup;
  isADCompany: any = false;


  constructor(private smtpService: SmtpService,
    private fb: FormBuilder, 
    private CommonFunction: CommonFunction,
    private smptService: SmtpService, 
    public modalService: NgbModal, 
    public userService: UserMasterService) {
  }




  ngOnInit() {

    this.smtpForm = this.fb.group({
      id: ['', [Validators.required, Validators.pattern(this.pattern)]],
      host: ['', [Validators.required, Validators.pattern(this.pattern)]],
      //port:['',[Validators.required,Validators.pattern(this.pattern)]],
      port: ['', Validators.required],
      // userName:['',[Validators.required,Validators.pattern(this.pattern)]],
      userName: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      SSL: ['', [Validators.required, Validators.pattern(this.pattern)]],
      ignoreTLS: ['', [Validators.required, Validators.pattern(this.pattern)]]


    });

    this.authForm = this.fb.group({
      towWayConfig: ['Inactive'],
      authLoginConfig: ['Inactive'],
      OTPLoginFoAD: ['Inactive']
    })

    this.smtpService.getSmtpDetails().then((smtpData) => {
      var data = JSON.parse(this.CommonFunction.decrypt(smtpData.data));
      console.log("datadata", data)
      this.smtpForm.patchValue({
        id: data._id,
        host: data.host,
        port: data.port,
        userName: data.username,
        password: data.password,
        SSL: data.SSL,
        ignoreTLS: data.ignoreTLS
      })
    })

    this.smtp = {
      "_id": '',
      "host": '',
      "port": '',
      "username": '',
      "password": '',
      "SSL": '',
      "ignoreTLS": ''
    }

    this.getAuthConfig();

    this.getCompanyConfig();

  }


  getCompanyConfig() {
    this.userService.getSubsideryList().then(response => {
      //console.log('checking for the user list', response);
      if (!response.status) {
        this.isADCompany = response.companyData.IsADSubscribedFlag;
      }
    })
  }
  get f() { return this.smtpForm.controls; }

  onEdit() {
    this.isDisable = false;
    this.isEditable = false;
  }
  onSubmit() {

    // this.smtp.host=this.smtpForm.controls.host.value;
    // this.smtp.port=this.smtpForm.controls.port.value;

    this.submitted = true;

    for (let el in this.smtpForm.controls) {
      if (this.smtpForm.controls[el].errors) {
        console.log(el)
      }
    }


    if (this.smtpForm.invalid) {

      return;
    }
    this.smtp._id = this.f.id.value;
    this.smtp.host = this.f.host.value;
    this.smtp.port = this.f.port.value;
    this.smtp.username = this.f.userName.value;
    this.smtp.password = this.f.password.value;
    this.smtp.SSL = this.f.SSL.value;
    this.smtp.ignoreTLS = this.f.ignoreTLS.value;
    // alert(this.smtp.host);
    // alert(this.smtp.value);

    console.log(this.smtp);
    this.smptService.updateSmtpDetails(this.smtp).then((data) => {
      if (data.status == 200)
        alert("Updated Successfully");



      //for showing updated data
      this.smtpService.getSmtpDetails().then((smtpData) => {
        var data = smtpData.data;
        this.smtpForm.patchValue({
          id: data._id,
          host: data.host,
          port: data.port,
          userName: data.username,
          password: data.password,
          SSL: data.SSL,
          ignoreTLS: data.ignoreTLS
        })
      })

    })
      .catch((error) => {
        alert(error);
      })

      ;


  }

  onCancel() {
    //this.isEditable=false;

    this.isDisable = true;
    this.isEditable = true;
    this.ngOnInit();
  }

  changeTab(event) { }

  onConfigSubmit(): any {
    var companyDetails: any = JSON.parse(localStorage.getItem("companyDetails"));
    var params = {
      authLogin: this.authForm.value.authLoginConfig,
      twoFactor: this.authForm.value.towWayConfig,
      OTPLoginFoAD: this.authForm.value.OTPLoginFoAD,
      companyId: companyDetails.companyID
    }
    this.smptService.updateAuthConfig(params).then((data: any) => {
      console.log("data", data);
      if (data.status == 0) {
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = { status: 'Success', message: data.message };
      } else {
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = { status: 'Failure', message: 'Fail to Update' };
      }
    })
  }

  getAuthConfig(): any {
    var companyDetails: any = JSON.parse(localStorage.getItem("companyDetails"));
    console.log("localData", companyDetails, companyDetails.companyID);
    this.smptService.getAuthConfig(companyDetails.companyID).then((data: any) => {
      data.configData = JSON.parse(this.CommonFunction.decrypt(data.configData))
      console.log("data", data)
      if (data) {
        this.authForm.patchValue({
          authLoginConfig: data.configData.authLogin,
          towWayConfig: data.configData.twoFactor,
          OTPLoginFoAD: data.configData.OTPLoginFoAD,
        })
      }
    })
  }

}
