
<style>

.subMenuViewAll{
  position: relative;
  display: block;
  float: right;
}

</style>


<div class="modal-header">
  <h4 class="modal-title">Edit Role</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body" style="overflow-y: auto;">
  <form [formGroup]="editRoleForm" novalidate>
    <div class="form-group">

      <!-- RoleName Text Input -->
      <div class="row">
        <div class="col-md-10">
          <input type="text" class="col-md-12 form-control" formControlName="roleName" (change)="inputRoleNameTextboxChanged($event)"
            placeholder="Role Name" />
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-primary btn-sm col-md-12" (click)="viewAll();$event.stopPropagation(); myDrop.open();">View All</button>
        </div>
      </div>
      <div class="row" *ngIf="editRoleForm.controls['roleName'].hasError('required') && editRoleForm.controls['roleName'].touched">
        <div class="col-md-12" style="color:red">
          <span style="padding-left:5px">*</span>Please enter role name</div>
      </div>
      <div class="row" *ngIf="editRoleForm.controls['roleName'].hasError('pattern') && editRoleForm.controls['roleName'].touched">
        <div class="col-md-12" style="color:red">
          <span style="padding-left:5px">*</span>Special Characters and Numbers are not allowed</div>
      </div>
      <div class="row" *ngIf="editRoleForm.controls['roleName'].hasError('maxlength') ">
        <div class="col-md-12" style="color:red">
          <span style="padding-left:5px">*</span>Please enter Rolename not exceeding 50 characters</div>
      </div>

      <div class="clearfix" style="margin-top:10px">
      </div>

      <!-- master Menu -->
      <div class="row">
        <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown #myDrop="ngbDropdown" id="masterMenu">

          <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('Master')"
            data-toggle="dropdown" aria-expanded="false">Master</a>

          <div aria-labelledby="" id="masterSubMenu" class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall"
            role="menu">

            <div class="dropdown-item">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="masterCheckbox" (click)="ParentMenuCheckboxClicked('masterCheckbox','masterSubMenu', masterMenu)">
              <span style="font-weight:bolder;">Select All</span>
            </div>

            <a href="javascript: void(0);" class="dropdown-item" style="margin-top:10px" id="{{menu._id}}" *ngFor="let menu of masterMenu"
              (mouseenter)="mouseoverDisplay(menu,$event,'Master',menu.MenuDisplayName)" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Master',menu.MenuDisplayName)">


              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'masterCheckbox',masterMenu)">

              <span style="float:right;display:inline-flex">
                <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute', 'fa-file' : child.IconName == 'summaryReport','fa-file-pdf-o' : child.IconName == 'individualReport'}"
                  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:10px!important;"
                  (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'masterCheckbox',masterMenu)"></i>

              </span>
              {{menu.MenuDisplayName}}
            </a>

          </div>
        </div>
      </div>

     <!-- Board Evaluation Menu -->
     <!-- <div class="row" *ngIf="(isBoardEvaluationConfig)">
      <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown #myDrop="ngbDropdown" id="boardEvaluationMenu">
    
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick()"
          data-toggle="dropdown" aria-expanded="false">Board Evaluation</a>
    
        <div aria-labelledby="" id="boardEvaluationSubMenu" class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall"
          role="menu">

            <div class="dropdown-item">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="boardEvaluationCheckbox" (click)="ParentMenuCheckboxClicked('boardEvaluationCheckbox','boardEvaluationSubMenu', boardEvaluationMenu)">
              <span style="font-weight:bolder;">Select All</span>
            </div>

            <a href="javascript: void(0);" class="dropdown-item" style="margin-top:10px" id="{{menu._id}}" *ngFor="let menu of boardEvaluationMenu"
              (mouseenter)="mouseoverDisplay(menu,$event,'Master',menu.MenuDisplayName)" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Master',menu.MenuDisplayName)">


              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'boardEvaluationCheckbox',boardEvaluationMenu)">

              <span style="float:right;display:inline-flex">
                <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute', 'fa-file' : child.IconName == 'summaryReport','fa-file-pdf-o' : child.IconName == 'individualReport','fa-star' : child.IconName == 'status','fa-paper-plane' : child.IconName == 'sendToAssessee'}"
                  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:10px!important;"
                  (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'boardEvaluationCheckbox',boardEvaluationMenu)"></i>

              </span>
              {{menu.MenuDisplayName}}
            </a>
        </div>
      </div>
    </div> -->

    <!-- GOvernanace Menu -->

    <!-- <div class="row" *ngIf="(isGovevaConfig && isGovernance)">
      <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="governanceMenu">
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('governanceMenu')" data-toggle="dropdown" aria-expanded="false">Governance</a>
    
        <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="governanceSubMenu" aria-labelledby="" role="menu">
          
          <div class="dropdown-item" >
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="governanceCheckbox"  (click)="ParentMenuCheckboxClicked('governanceCheckbox','governanceSubMenu', governanceMenu)">       
            <span style="font-weight:bolder;">Select All</span>           
          </div>
          
          <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of governanceMenu" (mouseenter)="mouseoverDisplay(menu,$event, 'Governance')" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Governance')">
            
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'governanceCheckbox',governanceMenu)">
            <span style="float:right;display:inline-flex">            
              <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute'}"  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:5px!important;" (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'governanceCheckbox',governanceMenu)"></i>
            </span>
            {{menu.MenuDisplayName}}  
          </a>
        </div>
      </div>
    </div> -->


    <!-- Compliance  Menu -->
    <div class="row" >
       <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="complianceMenu">
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('complianceMenu')" data-toggle="dropdown" aria-expanded="false">Compliance</a>
    
        <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="complianceSubMenu" aria-labelledby="" role="menu">
          
          <div class="dropdown-item" >
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="complianceCheckbox"  (click)="ParentMenuCheckboxClicked('complianceCheckbox','complianceSubMenu', complianceMenu)">       
            <span style="font-weight:bolder;">Select All</span>           
          </div>
        
          <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of complianceMenu" (mouseenter)="mouseoverDisplay(menu,$event,'Compliance')" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Compliance')">
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'complianceCheckbox',complianceMenu)">
            <span style="float:right;display:inline-flex">              
              <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute'}"  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:5px!important;" (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'complianceCheckbox',complianceMenu)"></i>
            </span>
            {{menu.MenuDisplayName}}  
          </a>

        </div>
      </div> 
    </div>


      <!-- UPSI Menu-->
      <!-- DO Not ALter ANy Dom Structure -->

      <div class="row" *ngIf="(isUPSIConfig)">
        <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="upsiMenu">
          <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('upsiMenu')"
            data-toggle="dropdown" aria-expanded="false">UPSI</a>

          <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="upsiSubMenu"
            aria-labelledby="" role="menu">

            <div class="dropdown-item">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="upsiCheckbox" (click)="ParentMenuCheckboxClicked('upsiCheckbox','upsiSubMenu', upsiMenu)">
              <span style="font-weight:bolder;">Select All</span>
            </div>

            <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of upsiMenu" (mouseenter)="mouseoverDisplay(menu,$event,'upsi')"
              (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'upsi')">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'upsiCheckbox',upsiMenu)">
              <span style="float:right;display:inline-flex">
                <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute', 'fa-file' : child.IconName == 'summaryReport','fa-file-pdf-o' : child.IconName == 'individualReport'}"
                  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:5px!important;"
                  (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'complianceCheckbox',complianceMenu)"></i>
              </span>
              {{menu.MenuDisplayName}}
            </a>
          </div>
        </div>
      </div>


         <!-- NCD Menu-->
      <!-- DO Not ALter ANy Dom Structure -->
      <div class="row"  *ngIf="(isNCDConfig)">
        <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="ncdMenu">
          <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('ncdMenu')"
            data-toggle="dropdown" aria-expanded="false">NCD</a>

          <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="ncdSubMenu"
            aria-labelledby="" role="menu">

            <div class="dropdown-item">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="ncdCheckbox" (click)="ParentMenuCheckboxClicked('ncdCheckbox','ncdSubMenu', ncdMenu)">
              <span style="font-weight:bolder;">Select All</span>
            </div>

            <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of ncdMenu" (mouseenter)="mouseoverDisplay(menu,$event,'ncd')"
              (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'ncd')">
              <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'ncdCheckbox',ncdMenu)">
              <span style="float:right;display:inline-flex">
                <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute', 'fa-file' : child.IconName == 'summaryReport','fa-file-pdf-o' : child.IconName == 'individualReport'}"
                  *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:5px!important;"
                  (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'ncdCheckbox',ncdMenu)"></i>
              </span>
              {{menu.MenuDisplayName}}
            </a>
          </div>
        </div>
      </div>

    <!-- Admin menu -->
    <div class="row">
      <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="adminMenu">
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5"
          (click)="LeftMenuClick('adminMenu')" data-toggle="dropdown" aria-expanded="false">Admin</a>
    
            <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall"
              id="adminSubMenu" aria-labelledby="" role="menu">
    
              <div class="dropdown-item">
                <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="adminCheckbox"
                  (click)="ParentMenuCheckboxClicked('adminCheckbox','adminSubMenu', adminMenu)">
                <span style="font-weight:bolder;">Select All</span>
              </div>
    
              <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of adminMenu" (mouseenter)="mouseoverDisplay(menu,$event,'Admin')"
                (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Admin')">
    
                <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.DisplayName}}"
                  (click)="checkboxClicked(menu,$event,menu.DisplayName,'adminCheckbox',adminMenu)" ng-model="checkboxClickSelected">
    
                <span style="float:right;display:inline-flex">
                  <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : menu.IconName == 'view','fa-pencil' : menu.IconName == 'edit','fa-plus-circle' : menu.IconName == 'add','fa-trash-o' : menu.IconName == 'delete' , 'fa-shield' : menu.IconName == 'authorize' , 'fa-plus-square-o' : menu.IconName == 'create', 'fa-download' : menu.IconName == 'download','fa-database' : menu.IconName == 'database-backup','fa-check-square-o' : menu.IconName == 'review' , 'fa-caret-square-o-right' : menu.IconName == 'execute', 'fa-file' : menu.IconName == 'summaryReport','fa-file-pdf-o' : menu.IconName == 'individualReport'}"
                    title="{{menu.IconName}}" id="{{menu.Url}}" style="margin-right:5px!important;" (click)="iconClicked($event,menu,'view',menu.DisplayName , menu,'adminCheckbox',adminMenu)"></i>
                </span>
                {{menu.DisplayName}}
              </a>
            </div>
        </div>
        </div>
      </div>

    <!-- dashboard Menu -->
    <div class="row" *ngIf="(isGovevaConfig || isAllConfig || isPSSConfig)">
       <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="dashboardMenu">
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('dashboardMenu')" data-toggle="dropdown" aria-expanded="false">Dashboard</a>
    
        <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="dashboardSubMenu" aria-labelledby="" role="menu">
          
          <div class="dropdown-item" >
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="dashboardCheckbox"  (click)="ParentMenuCheckboxClicked('dashboardCheckbox','dashboardSubMenu', dashboardMenu)">       
            <span style="font-weight:bolder;">Select All</span>           
          </div>

          <a href="javascript: void(0);" class="dropdown-item" style="margin-top:5px"  *ngFor="let menu of dashboardMenu" (mouseenter)="mouseoverDisplay(menu,$event,'Dashboard')" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'Dashboard')">
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.DisplayName}}" (click)="checkboxClicked(menu,$event,menu.DisplayName,'dashboardCheckbox',dashboardMenu)"  ng-model="checkboxClickSelected">
            <span style="float:right;display:inline-flex">
              <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : menu.IconName == 'view','fa-pencil' : menu.IconName == 'edit','fa-plus-circle' : menu.IconName == 'add','fa-trash-o' : menu.IconName == 'delete' , 'fa-shield' : menu.IconName == 'authorize' , 'fa-plus-square-o' : menu.IconName == 'create' , 'fa-download' : menu.IconName == 'download', 'fa-database' : menu.IconName == 'database-backup','fa-check-square-o' : menu.IconName == 'review' , 'fa-caret-square-o-right' : menu.IconName == 'execute'}"  title="{{menu.IconName}}" id="{{menu.Url}}" style="margin-right:5px!important;" (click)="iconClicked($event,menu,'view',menu.DisplayName , menu,'dashboardCheckbox',dashboardMenu)"></i>
            </span> 
            {{menu.DisplayName}} 
          </a>

        </div>
      </div> 
    </div>    
    
    
       <!-- DO Not ALter ANy Dom Structure -->
        <!-- GEM Menu -->

        <div class="row" *ngIf = "isGEMConfig">
          <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown #myDrop="ngbDropdown" id="gemMenu">
        
            <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('gemMenu')"
              data-toggle="dropdown" aria-expanded="false">GovEVA Boards</a>
        
            <div aria-labelledby="" id="gemSubMenu" class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall"
              role="menu">
    
              <div class="dropdown-item" >
                  <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="gemCheckbox"  (click)="ParentMenuCheckboxClicked('gemCheckbox','gemSubMenu', gemMenu)">       
                  <span style="font-weight:bolder;">Select All</span>           
              </div>
    
              <a href="javascript: void(0);" class="dropdown-item" style="margin-top:10px" id="{{menu._id}}" *ngFor="let menu of gemMenu"
                (mouseenter)="mouseoverDisplay(menu,$event,'GEM',menu.MenuDisplayName)" (click)="Submenu($event)" (mouseleave)="mouseleaveDisplay(menu,$event,'GEM',menu.MenuDisplayName)">{{menu.MenuDisplayName}}
        
                <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'gemCheckbox',gemMenu)">
        
                <span style="float:right;display:inline-flex">
                  <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' , 'fa-download' : child.IconName == 'download', 'fa-database' : child.IconName == 'database-backup','fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute', 'fa-file' : child.IconName == 'summaryReport','fa-file-pdf-o' : child.IconName == 'individualReport'}"
                    *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:10px!important;"
                    (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'gemCheckbox',gemMenu)"></i>
        
                </span>
              </a>
        
            </div>
          </div>
        </div>
     <!-- Law And Regulation Menu -->
     <div class="row" *ngIf="(isPSSConfig || isAllConfig) && (!isMeetingMakerWithoutAgenda && !isMeetingMakerWithAgenda) && isLawsConfig">
       <div class="dropdown col-md-12 rolemaster-parentmenu" ngbDropdown id="lawAndRegulationMenu">
        <a href="javascript: void(0);" style="text-align: left;" class="btn rolemaster-menu dropdown-toggle col-md-5" (click)="LeftMenuClick('lawAndRegulationMenu')" data-toggle="dropdown" aria-expanded="false">Laws & Regulations</a>
    
        <div class="dropdown-menu rolemaster-menu dropdown-menu-right col-md-7 dropdown-top-align-menu rolemaster-viewall" id="lawAndRegulationSubMenu"
          aria-labelledby="" role="menu">
          
          <div class="dropdown-item" >
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="lawAndRegulationCheckbox"  (click)="ParentMenuCheckboxClicked('lawAndRegulationCheckbox','lawAndRegulationSubMenu', lawAndRegulationMenu)">       
            <span style="font-weight:bolder;">Select All</span>           
          </div>


          <a href="javascript: void(0);" class="dropdown-item" *ngFor="let menu of lawAndRegulationMenu" (mouseenter)="mouseoverDisplay(menu,$event,'lawAndRegulation')" (click)="Submenu($event)" 
            (mouseleave)="mouseleaveDisplay(menu,$event,'lawAndRegulation')">
            <input type="checkbox" class="pull-left" style="margin-right:5px;width:18px;height:18px" id="{{menu.MenuDisplayName}}" (click)="checkboxClicked(menu,$event,menu.MenuDisplayName,'lawAndRegulationCheckbox',lawAndRegulationMenu)">
            <span style="float:right;display:inline-flex">            
              <i class="rolemaster-icons rolemaster-icon-hide icon-unselected fa" [ngClass]="{'fa-eye' : child.IconName == 'view','fa-pencil' : child.IconName == 'edit','fa-plus-circle' : child.IconName == 'add','fa-trash-o' : child.IconName == 'delete' , 'fa-shield' : child.IconName == 'authorize' , 'fa-plus-square-o' : child.IconName == 'create' ,'fa-check-square-o' : child.IconName == 'review' , 'fa-caret-square-o-right' : child.IconName == 'execute'}"
                *ngFor="let child of menu.ChildResources" title="{{child.IconName}}" id="{{child.Url}}" style="margin-right:5px!important;"
                (click)="iconClicked($event,menu,'view',menu.MenuDisplayName , child,'lawAndRegulationCheckbox',lawAndRegulationMenu)"></i>
            </span>
            {{menu.MenuDisplayName}}
          </a>


        </div>
      </div> 
    </div>
    


      <!-- role activities validation Message -->
      <div class="row">
        <div class="col-md-12 text-left" *ngIf="isfinalRoleListEmpty">
          <strong class="pl-1" style="color:red">Please select Role activities from the list</strong>
        </div>
      </div>


      <!-- added roles display list -->
      <div class="row" style="margin-top:10px">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 text-left mb-2">
              <strong class="pl-1">Added Roles</strong>
            </div>
            <div class="form-group" *ngFor="let role of addedRoles;let i=index">
              <div class="btn-xs pl-2 pr-1" style="border:2px solid lightgrey;border-radius:15px">
                {{role.MenuDisplayName}}
                <i class="fa fa-times fa-fw" style="cursor: pointer;" (click)=removeAddedRoleList(i,role)></i>
              </div>
            </div>
          </div>
        </div>
      </div>

  </form>
</div>



<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" [disabled]="isfinalRoleListEmpty" (click)="UpdateRole()">
    Update
  </button>

  <button type="button" class="btn btn-default btn-sm" style="background-color:#ffffff;color:#acb7bf!important" (click)="cancel()">
    Cancel
  </button>
</div>