<!-- START: components/breadcrumbs -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Breadcrumbs</strong>
            <a href="https://v4-alpha.getbootstrap.com/components/breadcrumb/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <h5 class="text-black"><strong>Text Breadcrumbs</strong></h5>
        <p class="text-muted">Element: read <a href="https://v4-alpha.getbootstrap.com/components/breadcrumb/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
        <div class="mb-5">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active">Home</li>
            </ol>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Library</li>
            </ol>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Library</a></li>
                <li class="breadcrumb-item active">Data</li>
            </ol>
        </div>

        <h5 class="text-black"><strong>Button Breadcrumbs</strong></h5>
        <p class="text-muted">Modifier: <code>.breadcrumb--custom</code></p>
        <div class="mb-5">
            <ul class="breadcrumb breadcrumb--custom">
                <li class="breadcrumb-item">
                    <a href="javascript: void(0);">Main</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript: void(0);">Layout</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript: void(0);">With background</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript: void(0);">White</a>
                </li>
                <li class="breadcrumb-item">
                    <span>Sidebars</span>
                </li>
            </ul>
        </div>
    </div>
</section>
<!-- END: components/breadcrumbs -->