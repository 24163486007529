<div class="modal-header">
    <h4 class="modal-title">Create User</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="userMasterForm">
    <div class="modal-body">
        <div class="row userdetail">
                <div class="col-md-6" style="padding: 0 45px 0 20px;"> 
                    <h4 class="title">Personal Details:</h4>
                    <div class="row">
                        <div class="col-md-12 d-flex">
                            <label class="form-control-label">User:<span >*</span></label>
                            <div class="form-group gender d-flex" *ngIf="!isGuestSuperAdmin" style="margin: 0 20px;">
                                <label>New
                                    <input type="radio" name="IsExist" value="false" formControlName="IsExist"
                                        (change)="onChangeAddUser()"> 
                                        <span class="checkmark"></span> 
                                </label>
                                <label>Existing
                                    <input type="radio" name="IsExist" value="true" formControlName="IsExist"
                                        (change)="onChangeAddUser()">
                                        <span class="checkmark"></span>  
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="display:none" id="userList">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Select User: <span>*</span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                                <ng-select [items]="NGusersList" formControlName="UniqueUserId"
                                    (selected)="onSelectedUser($event)"></ng-select>
                                <div *ngIf="!isValidUser" class="form-control-error-custom">
                                    User is required
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div class="row">
                        <label class="col-md-12">Type: <span>*</span></label>
                        <div class='col-md-12 form-group gender'>
                            <label style="margin: 0 10px 10px 0;display: inline-block;">Employee
                                <input type="radio" name="TypeOfUser" value="Employee" formControlName="TypeOfUser"
                                    (change)="onChangeUserType()"> 
                                    <span class="checkmark"></span> 
                            </label>
                            <label style="margin: 0 10px 10px 0;display: inline-block;">Executive Management
                                <input type="radio" name="TypeOfUser" value="ExecutiveManagement" formControlName="TypeOfUser"
                                    (change)="onChangeUserType()"> 
                                    <span class="checkmark"></span> 
                            </label>
                            <label style="margin: 0 10px 10px 0;display: inline-block;">KMP
                                <input type="radio" name="TypeOfUser" value="KMP" formControlName="TypeOfUser"
                                    (change)="onChangeUserType()"> 
                                    <span class="checkmark"></span> 
                            </label>
                            <label style="margin: 0 10px 10px 0;display: inline-block;">Board Member
                                <input type="radio" name="TypeOfUser" value="BoardMember" formControlName="TypeOfUser"
                                    (change)="onChangeUserType()"> 
                                    <span class="checkmark"></span> 
                            </label>
                            <label style="margin: 0 10px 10px 0;display: inline-block;">Others
                                <input type="radio" name="TypeOfUser" value="Others" formControlName="TypeOfUser"
                                    (change)="onChangeUserType()"> 
                                    <span class="checkmark"></span> 
                            </label>
                        </div>
                    </div>
            
                    <div class="row" style="display:none" id="directorTypeDiv">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Director Type: </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <ng-select [items]="directorTypesList" bindLabel="label" bindValue="value"  formControlName="DirectorType"
                                    (change)="onSelectedDirectorType($event)"></ng-select>
                                <div *ngIf="!isValidUserType" class="form-control-error-custom">
                                    Director Type is required
                                </div>
                            </div>
            
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">First Name:<span >*</span>
                            </label>
                        </div>
                         <div class="col-md-4" style="padding-left:10px;">
                            <div class="form-group">
                            <ng-select [items]="titleList" [ngModel]="defaultTitle" formControlName="TitleName"
                                [disabled]="is_edit" bindLabel="label" bindValue="value"></ng-select>
                                </div>
                        </div> 
                        <div class="col-md-8">
                            <div class="form-group">
                                <input placeholder="First Name" [attr.disabled]="is_edit?'':null" type="text" class="form-control"
                                    formControlName="FirstName">
                                <div *ngIf="userMasterForm.get('FirstName').hasError('required') && userMasterForm.get('FirstName').touched"
                                    class="form-control-error-custom">
                                    First Name is required
                                </div>
                                <div *ngIf="userMasterForm.get('FirstName').hasError('pattern')" class="form-control-error-custom">
                                    Please enter valid First Name
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Last Name:<span >*</span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input placeholder="Last Name" [attr.disabled]="is_edit?'':null" type="text" class="form-control"
                                    formControlName="LastName">
                                <div *ngIf="userMasterForm.get('LastName').hasError('required') && userMasterForm.get('LastName').touched"
                                    class="form-control-error-custom">
                                    Last Name is required
                                </div>
                                <div *ngIf="userMasterForm.get('LastName').hasError('pattern')" class="form-control-error-custom">
                                    Please enter valid Last Name
                                </div>
                            </div>
                        </div>
            
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0"> Designation: 
                                <span *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember' || userMasterForm.get('TypeOfUser').value == 'KMP'" >*</span>
                            </label>
                        </div>
                        <div class="col-md-12" *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember'">
                            <div class="form-group">
                                <ng-select [items]="boardDesignationlist" bindLabel="label" bindValue="value" 
                                formControlName="DesignationSelection"></ng-select>
                                <div *ngIf="userMasterForm.get('DesignationSelection').hasError('required') && userMasterForm.get('DesignationSelection').touched"
                                    class="form-control-error-custom">
                                    Please select Designation.
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-12" *ngIf=" userMasterForm.get('TypeOfUser').value == 'KMP'">
                            <div class="form-group">
                                <ng-select [items]="kmpDesignationList" formControlName="DesignationSelection" bindLabel="label" bindValue="value" ></ng-select>
                                <div *ngIf="userMasterForm.get('DesignationSelection').hasError('required') && userMasterForm.get('DesignationSelection').touched"
                                    class="form-control-error-custom">
                                    Please select Designation.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12"
                            *ngIf="userMasterForm.get('TypeOfUser').value != 'BoardMember' && userMasterForm.get('TypeOfUser').value != 'KMP'">
                            <div class="form-group">
                                <input placeholder="Designation" type="text" class="form-control" formControlName="Designation">
                                <div *ngIf="userMasterForm.get('Designation').hasError('required') && userMasterForm.get('Designation').touched"
                                    class="form-control-error-custom">
                                    Designation is required
                                </div>
                                <div *ngIf="userMasterForm.get('Designation').hasError('pattern')"
                                    class="form-control-error-custom">
                                    Please enter valid Designation
                                </div>
                                <div *ngIf="!userMasterForm.get('Designation').hasError('pattern') && userMasterForm.get('Designation').hasError('minlength')"
                                    class="form-control-error-custom">
                                    Minimum 5 character required.
                                </div>
                                <div *ngIf="!userMasterForm.get('Designation').hasError('pattern') && userMasterForm.get('Designation').hasError('maxlength')"
                                    class="form-control-error-custom">
                                    Maximum 500 character required.
                                </div>
            
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Email Id: <span>*</span></label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input placeholder="Email Id" type="email" class="form-control" formControlName="EmailId"
                                    [attr.disabled]="isPrimaryEmailIdReadOnly ? true : null" (focusout)="onEmailIdChange()">
                                <div *ngIf="userMasterForm.get('EmailId').hasError('required') && userMasterForm.get('EmailId').touched"
                                    class="form-control-error-custom">
                                    Email Id is required
                                </div>
                                <div *ngIf="userMasterForm.get('EmailId').hasError('email') && !userMasterForm.get('EmailId').hasError('required') && userMasterForm.get('EmailId').touched"
                                    class="form-control-error-custom">
                                    Please enter valid Email Id
                                </div>
                                <div *ngIf="isPrimaryEmailIdExists && !userMasterForm.get('EmailId').hasError('email') && !userMasterForm.get('EmailId').hasError('required') && userMasterForm.get('EmailId').touched || isPrimaryEmailIdExists"
                                    class="form-control-error-custom">
                                    Email Id is already exists.
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Mobile No:<span >*</span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input placeholder="Mobile No." type="text" class="form-control" formControlName="MobileNumber" min="" max="">
                                <div *ngIf="userMasterForm.get('MobileNumber').hasError('required') && userMasterForm.get('MobileNumber').touched" class="form-control-error-custom">
                                    Mobile Number is required
                                </div>
                                <div *ngIf="userMasterForm.get('MobileNumber').hasError('pattern') && !userMasterForm.get('MobileNumber').hasError('required')"
                                    class="form-control-error-custom">
                                    Please enter valid mobile Number
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Gender:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group gender">
                                <label>Male
                                    <input type="radio" name="Gender" value="Male" formControlName="Gender">
                                    <span class="checkmark"></span> 
                                </label>
                                <label>Female
                                    <input type="radio" name="Gender" value="Female" formControlName="Gender">
                                    <span class="checkmark"></span> 
                                </label>
                                <label>Transgender
                                    <input type="radio" name="Gender" value="Transgender" formControlName="Gender">
                                    <span class="checkmark"></span> 
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Report To:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                                <ng-select [allowClear]="true" [items]="reportToUserList" formControlName="ReportToUser"
                                    (selected)="onSelectedReportToUser($event)" (deselected)="onDeselectedReportToUser($event)">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">PAN:<span >*</span>
                            </label>
                        </div>
                        <div class="col-md-12" style="padding-bottom: 11px">
                            <input placeholder="PAN" [attr.disabled]="is_edit?'':null" type="text" class="form-control upperCase"
                                formControlName="PAN" (focusout)="onPANChange()">
                            <div *ngIf="userMasterForm.get('PAN').hasError('required') && userMasterForm.get('PAN').touched"
                                class="form-control-error-custom">
                                PAN is required
                            </div>
                            <div *ngIf="userMasterForm.get('PAN').hasError('pattern') && !userMasterForm.get('PAN').hasError('required')"
                                class="form-control-error-custom">
                                Please enter valid PAN number
                            </div>
                            <div *ngIf="isPANExists && !userMasterForm.get('PAN').hasError('pattern') && !userMasterForm.get('PAN').hasError('required')"
                                class="form-control-error-custom">
                                PAN already exists
                            </div>
                        </div>
                    </div>
                    <div class="row">
                    </div>
                    <div class="row">
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="isADEnabled">
                            <label class="form-control-label" for="l0">AD ID:<span>*</span>
                            </label>
                        </div>
                        <div class="col-md-12" *ngIf="isADEnabled">
                            <input type="text" class="form-control" formControlName="ADID" (focusout)="onADIdChange();ADWhitespaceError();" required>
                            <div *ngIf="userMasterForm.get('ADID').hasError('required') && userMasterForm.get('ADID').touched"
                                    class="form-control-error-custom">
                                    AD ID is required.
                                </div>
            
                                <div *ngIf="isADIdExists"
                                    class="form-control-error-custom">
                                    AD Id is already exists.
                                </div>
            
                                <div *ngIf="userMasterForm.controls['ADID'].hasError('pattern')"
                                class="form-control-error-custom">
                                AD ID is not valid.
                            </div>
                        </div>
                    </div>
            
            
                    <div class="row">
                        <div class="col-md-12">
                            <label style="padding-top:6px;" class="form-control-label" for="l0"> Subsidiaries: </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                                <ng-select id=subsideriesdropdown [items]="subsideryList" [ngModel]="chosenSubsideries"
                                    formControlName="SubsideryName" [multiple]="true" (selected)="getSelectedSubsideries($event)"
                                    (deselected)="multipleSubsideriesDeselected($event)">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6"  style="padding: 0 20px;">
                    <!-- Profile pic upload  -->
                    <div class="mb-5">
                        <h4 class="title">Profile Picture:</h4>
                        <a class="img-circle img-responsive" (click)="showUploadImagePopUp()" id="profileImage">
                            <img id="profilePhoto" class="img-circle img-responsive"
                                src="assets/modules/dummy-assets/common/img/avatars/default-profile.png" alt="user pic"
                                title="User Image">
                            <span class="add_button">Add</span>
                        </a>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">DOB:</label>
                        </div>
            
                        <div class="col-md-12">
                            <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                                <div class="input-group" (click)="$event.stopPropagation()">
                                    <input class="form-control" [attr.readonly]="true" [attr.disabled]="is_edit?'':null"
                                        [maxDate]="DOBDate" [formControl]="userMasterForm.controls['DOB']" name="dpDOB"
                                        style="padding:6px;z-index: 0;background-color:white;" placeholder="dd-mm-yyyy"
                                        ngbDatepicker #a="ngbDatepicker" (click)="a.toggle()" [(ngModel)]="dateOfBirthModel"
                                        [minDate]=minDate (document:click)="a.close();">
                                    <button class="input-group-addon" (click)="a.toggle()" type="button">
                                        <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                                    </button>
                                </div>
                                <span *ngIf="userMasterForm.get('DOB').hasError('required') && userMasterForm.get('DOB').touched"
                                    class="form-control-error-custom">Date of Birth is required</span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Experience:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input placeholder="Years" type="text" class="form-control" maxlength="2"
                                    formControlName="Experience" (keydown)="onlyNumber($event)"
                                    (input)="restrictFirstDigitForZero($event)">
                                <div *ngIf="userMasterForm.get('Experience').hasError('required') && userMasterForm.get('Experience').touched"
                                    class="form-control-error-custom">
                                    Experience is required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <label class="form-control-label" for="l0"> Area of expertise:</label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input placeholder="Area of Expertise" type="text" class="form-control"
                                    formControlName="AreaofExpertise">
                                <div *ngIf="userMasterForm.get('AreaofExpertise').hasError('required') && userMasterForm.get('AreaofExpertise').touched"
                                    class="form-control-error-custom">
                                    Area of Expertise is required
                                </div>
                                <div *ngIf="userMasterForm.get('AreaofExpertise').hasError('pattern')"
                                    class="form-control-error-custom">
                                    Please enter valid Area of Expertise
                                </div>
                                <div *ngIf="!userMasterForm.get('AreaofExpertise').hasError('pattern') && userMasterForm.get('AreaofExpertise').hasError('minlength')"
                                    class="form-control-error-custom">
                                    Minimum 5 character required.
                                </div>
                                <div *ngIf="!userMasterForm.get('AreaofExpertise').hasError('pattern') && userMasterForm.get('AreaofExpertise').hasError('maxlength')"
                                    class="form-control-error-custom">
                                    Maximum 500 character required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">Role:<span >*</span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                            -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                            <ng-select *ngIf="isGuestSuperAdmin" [items]="roleList" formControlName="RoleName" 
                            (change)="onSelectRole($event)">
                            </ng-select>
                            
                            <ng-select *ngIf="!isGuestSuperAdmin" [multiple]="true" 
                            [items]="roleList"  bindLabel="RoleName" bindValue="RoleId"
                            [formControl]="userMasterForm.controls['RoleName']" 
                            (add)="onSelectRole($event)" (remove)="multipleDeselected($event)">
                            </ng-select> 
                                <div *ngIf="userMasterForm.get('RoleName').hasError('required') && userMasterForm.get('RoleName').touched"
                                    class="form-control-error-custom">
                                    Please select user role.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0">DIN:
                                <span *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember'">*</span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <input placeholder="DIN" type="text" class="form-group form-control upperCase" formControlName="DIN"
                                (focus)="handleDINError=false" (focusout)="onDINChange()">
                            <div *ngIf="handleDINError" class="form-control-error-custom">
                                DIN is required
                            </div>
                            <div *ngIf="userMasterForm.get('DIN').hasError('pattern') && !userMasterForm.get('DIN').hasError('required')"
                                class="form-control-error-custom">
                                Please enter valid DIN number
                            </div>
                            <div *ngIf="isDINExists && !userMasterForm.get('DIN').hasError('pattern') && !userMasterForm.get('DIN').hasError('required')"
                                class="form-control-error-custom">
                                DIN number already exists
                            </div>
                        </div>
            
                        <div class="col-md-12" *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember'">
                            <label class="form-control-label" for="l0" >Category
                            </label>
                        </div>
                        <div class="col-md-12" *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember'">
                            <div class="form-group">
                                <ng-select  [items]="categoryList" formControlName="Category"></ng-select>
                                <div *ngIf="handleCategoryError" class="form-control-error-custom">
                                    Category is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-control-label" for="l0"
                            style="margin: 15px 0;display: block;">Notification Type
                            </label>
                            <ul class="notification">
                                <li>
                                    <input type="checkbox" formControlName="SMSNotification"
                                    (change)="notificationCheckboxChangeEvent($event, 'SMSNotification')"
                                    name="notification" value="1" id="notification1" >
                                    <label for="notification1">SMS</label>
                                </li>
                                <li>
                                    <input type="checkbox" formControlName="EmailNotification"
                                    (change)="notificationCheckboxChangeEvent($event,'EmailNotification')"
                                    name="notification" value="2" id="notification2" >
                                    <label for="notification2">Email</label>
                                </li>
                                <li>
                                    <input type="checkbox" formControlName="BellNotification"
                                    (change)="notificationCheckboxChangeEvent($event,'BellNotification')"
                                    name="notification" value="3" id="notification3" >
                                    <label for="notification3">Bell</label>
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-12" *ngIf="isAdminUser">
                            <label class="form-control-label" for="l0"
                            style="margin: 15px 0;display: block;">Login Access
                            </label>
                            <ul class="notification">
                                <li>
                                    <input type="checkbox" formControlName="IPADNotification"
                                    (change)="loginAccessCheckboxChangeEvent($event, 'IPADNotification')"
                                    name="notification" value="4" id="notification4" >
                                    <label for="notification4">Ipad</label>
                                </li>
                                <li>
                                    <input type="checkbox" formControlName="BrowserNotification"
                                    (change)="loginAccessCheckboxChangeEvent($event,'BrowserNotification')"
                                    name="notification" value="5" id="notification5" >
                                    <label for="notification5">Browser</label>
                                </li>
                            </ul>
                        </div>
                           
                        <!-- Branch Name and Department Name Add -->

<div class="col-md-12">
    <label class="form-control-label" for="l0">Department Name
    </label>
</div>
<div class="col-md-12">
    <ng-select [items]="departmentList" formControlName="DepartmentName" (selected)="onSelectDepartmebt($event)">
    </ng-select>

</div>


<div class="col-md-12">
    <label class="form-control-label" for="l0">Branch Name
    </label>
</div>
<div class="col-md-12">
    <ng-select [items]="branchList" formControlName="BranchName" (selected)="onSelectBranch($event)">
    </ng-select>

</div>
                    </div>
                </div>
        </div>
        <!-- uplaod div pop up -->
        <div class="modal fade" id="uploadProfilePopUp" data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="" aria-hidden="true" style="top: 50px;">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="border: 1px solid rgba(0,0,0,.2);">
                    <div id="alertTitle" class="modal-header"
                        style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                        Select profile photo
                        <button type="button" class="close" (click)="cancelProfilePhotoModel()">&times;</button>
                    </div>
                    <div class="modal-body" style="padding: 10px 15px;">
                        <div>
                            <div>

                                <div class="file-upload">
                                    <input id="custom-input" type="file" (change)="fileChangeListener($event)"
                                        accept="image/gif, image/jpeg, image/png">
                                </div>
                                <img-cropper #cropper [image]="base64Code" [settings]="cropperSettings"></img-cropper>
                                <br>
                                <span class="result rounded" *ngIf="base64Code.image">
                                    <img [src]="base64Code.image" [width]="cropperSettings.croppedWidth"
                                        [height]="cropperSettings.croppedHeight" alt="GovEVA">
                                </span>

                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-sm" [disabled]="isInvalidImage"
                            (click)="setProfilePhoto()">Set as profile photo</button>
                        <button class="btn btn-sm" (click)="cancelProfilePhotoModel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="PanNoConfirmationPopUp" data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="" aria-hidden="true" style="top: 50px;">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="border: 1px solid rgba(0,0,0,.2);">
                    <div id="alertTitle" class="modal-header"
                        style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                        Confirmtion
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body" style="padding: 10px 15px;">
                        <div>

                            <div>
                                <span> Pan already exists in system; Please select Existing User option or change the
                                    Pan to proceed.</span>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-sm" (click)="clearUserFormData()">OK</button>
                        <button class="btn btn-sm" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div style="width:100%; text-align: left;">
            <span >*</span> Indicates Mandatory field
        </div>


        <button *ngIf="isGuestSuperAdmin" class="btn btn-primary btn-sm"
            [disabled]="!userMasterForm.valid || isADIdExists || !user.Roles.length||isPrimaryEmailIdExists||isSecondaryEmailIdExists||isPANExists||isDINExists||isProceed"
            (click)="save()">Save</button>

        <button *ngIf="!isGuestSuperAdmin" class="btn btn-primary btn-sm" [disabled]="!userMasterForm.valid || isADIdExists || !user.Roles.length||isPrimaryEmailIdExists||isSecondaryEmailIdExists||isPANExists||isDINExists||isProceed"
            (click)="save()">Send for Approval</button>

        <button class="btn btn-primary btn-sm" [disabled]="!userMasterForm.valid || !user.Roles.length||isPrimaryEmailIdExists||isSecondaryEmailIdExists||isPANExists||isDINExists||isProceed" (click)="redirectToPSS()"
            *ngIf="userMasterForm.get('TypeOfUser').value == 'KMP' && (!isUPSIConfig || isPSSConfig)">Add Details</button> 
         <button class="btn btn-primary btn-sm" [disabled]="!userMasterForm.valid || !user.Roles.length||isPrimaryEmailIdExists||isSecondaryEmailIdExists||isPANExists||isDINExists||isProceed" (click)="redirectToPSS()"
            *ngIf="userMasterForm.get('TypeOfUser').value == 'BoardMember'  && (!isUPSIConfig || isPSSConfig) ">Add Details</button>
        <button class="btn btn-sm" (click)="cancel()">Cancel</button>
    </div>
</form>