import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-documentdetails',
  templateUrl: './documentdetails.component.html',
  styleUrls: ['./documentdetails.component.css'],
})
export class DocumentdetailsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
