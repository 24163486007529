<!-- START: forms/internal assets -->
<section class="card  mt-4 key-ratios">
  <button _ngcontent-c3="" aria-label="Close" class="close" data-dismiss="modal" type="button">
      <span _ngcontent-c3="" aria-hidden="true">×</span>
  </button>
  <div class="nav-tabs-horizontal">
      <ul class="nav nav-tabs inverse" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" data-target="#earningsRatiosTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="true"
                  (click)="tagToogle('earningsRatiosTab')">Earnings Ratios</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" data-target="#marginRatiosTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                  (click)="tagToogle('marginRatiosTab')">Margin Ratios</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" data-target="#growthRatiosTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                  (click)="tagToogle('growthRatiosTab')">Growth Ratios</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" data-target="#returnsRatiosTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                  (click)="tagToogle('returnsRatiosTab')">Returns Ratios</a>
          </li>
      </ul>
      <div class="tab-content">
          <div class="tab-pane active" id="earningsRatiosTab" role="tabcard" aria-expanded="true">
              <div class="row sub-tabs-section">
                  <div id="earningsRatiosQuartelyTrend" class="tabs-2 widget_kr pull-left" (click)="getQuarterlyTrendEarningsRatios()">
                      <span>Quarterly Trend</span>
                  </div>
                  <div id="earningsRatiosAnnualTrend" class="tabs-2 widget_kr" (click)="getAnnualTrendEarningsRatios()">
                      <span>Annual Trend</span>
                  </div>
              </div>
              <div class="card-block watermark" style="min-height: 300px;">
                  <div class="container">
                      <div id="earningsRatiosQuartelyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="earningsRatiosQuarterlyChart"></chart>
                          </div>
                      </div>
  
                      <div id="earningsRatiosYearlyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="earningsRatiosYearlyChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="tab-pane" id="marginRatiosTab" role="tabcard" aria-expanded="true">
              <div class="row sub-tabs-section">
                  <div id="marginRatiosQuartelyTrend" class="tabs-2 widget_kr pull-left" (click)="getQuarterlyTrendMarginRatios()">
                      <span>Quarterly Trend</span>
                  </div>
                  <div id="marginRatiosAnnualTrend" class="tabs-2 widget_kr" (click)="getAnnualTrendMarginRatios()">
                      <span>Annual Trend</span>
                  </div>
              </div>
              <div class="card-block watermark" style="min-height: 300px;">
                  <div class="container">
                      <div id="marginRatiosQuartelyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="marginRatiosQuarterlyChart"></chart>
                          </div>
                      </div>
  
                      <div id="marginRatiosYearlyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="marginRatiosYearlyChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="tab-pane" id="growthRatiosTab" role="tabcard" aria-expanded="true">
              <div class="row sub-tabs-section">
                  <div id="growthRatiosAnnualTrend" class="tabs-2 widget_kr" (click)="getAnnualTrendGrowthRatios()">
                      <span>Annual Trend</span>
                  </div>
              </div>
              <div class="card-block watermark" style="min-height: 300px;">
                  <div class="container">

                      <div id="growthRatiosYearlyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="growthRatiosYearlyChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="tab-pane" id="returnsRatiosTab" role="tabcard" aria-expanded="true">
              <div class="row sub-tabs-section">
                  <div id="returnsRatiosAnnualTrend" class="tabs-2 widget_kr disabled" (click)="getAnnualTrendReturnsRatios()">
                      <span>Annual Trend</span>
                  </div>
              </div>
              <div class="card-block watermark" style="min-height: 300px;">
                  <div class="container">

                      <div id="returnsRatiosYearlyDataSection" style="display: none;">
                          <div style="align-items: center;padding: 40px;" class="m-auto" width="100%">
                              <chart [options]="returnsRatiosYearlyChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
</section>
<!-- END: forms/internal assets -->