import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { rocketChatRemove } from '../enums/rocketChatRemove';
import { CompanyMasterService } from '../services/companymaster.service';
import { LastLoginDetailsService } from '../services/last-login-details.service';
import { LoginService } from '../services/login.service';
import { UINotificationService } from '../services/notificationUI.service';
import { RocketChatService } from '../services/rocketChat.service';
import { UserMasterService } from '../services/user-master.service';
import { safeJsonStringify } from '../shared/utils';
import { CommonFunction } from './../helpers/commonFunction';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;

@Component({
  selector: 'app-structure',
  templateUrl: './structure.html',
})
export class StructureComponent {
  notificationData: any;
  companyLogo: any;
  associatedCompanyList: any;
  userLastLoginDetails: any;
  @Output() changeCompany = new EventEmitter();

  public userInfo: any;

  constructor(
    private uiNotificationService: UINotificationService,
    private loginService: LoginService,
    private userMasterService: UserMasterService,
    private router: Router,
    private route: ActivatedRoute,
    private companyMasterService: CompanyMasterService,
    private rocketChatService: RocketChatService,
    private lastloginService: LastLoginDetailsService,
    private commonFunction: CommonFunction
  ) {
    this.userInfo = localStorage.getItem('user');

    let self = this;
    setTimeout(function () {
      self.logOut();
    }, environment.sessionTime);
  }

  ngOnInit() {}
  changeOfRoutes() {
    // console.log('DEBUGGING: structureComponent.changeOfRoutes entered');
    // console.log('structure, this.route.snapshot', this.route);
    // console.log(
    //   'present',
    //   this.route.snapshot.queryParams['redirectionNotification']
    // );

    var redirectNotification =
      this.route.snapshot.queryParams['redirectionNotification'];
    ////
    if (this.router.url != '/structure/(structure-outlet:dashboards/alpha)') {
      $('body').removeClass('dashboard-page');
    }
    this.companyMasterService.getCompanyList().then((response) => {
      //
      if (
        response.status == 0 &&
        !_.isUndefined(response.companyData.CompanyLogo) &&
        !_.isNil(response.companyData.CompanyLogo) &&
        !_.isEmpty(response.companyData.CompanyLogo)
      ) {
        this.companyLogo =
          environment.serverIP + response.companyData.CompanyLogo;
      } else {
        this.companyLogo = null;
      }
    });

    let userId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    this.uiNotificationService
      .getUINotificationForUser(userId)
      .then((response) => {
        if (response.status == 0) {
          this.notificationData = response;
        } else {
          this.notificationData = null;
        }
      });

    let uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    let companyId = JSON.parse(
      localStorage.getItem('companyDetailsGoveva')
    ).value;
    this.userMasterService
      .getAssociatedCompanyList(uniqueUserId)
      .subscribe((response) => {
        //
        if (response.status == 0) {
          if (_.size(response.companyList) <= 0) {
            this.associatedCompanyList = null;
          } else {
            this.associatedCompanyList = response.companyList;
          }
        } else {
          this.associatedCompanyList = null;
        }
      });

    //region start for company notification redirection
    if (
      redirectNotification &&
      _.size(this.route.snapshot.queryParams['CompanyId'])
    ) {
      var uniqueUserIdForNotofication = JSON.parse(
        localStorage.getItem('user')
      ).UniqueUserId;
      var companyIdForNotificationRediection =
        this.route.snapshot.queryParams['CompanyId'];
      var loginCredentialObject = {
        CompanyId: companyIdForNotificationRediection,
        UniqueUserId: uniqueUserIdForNotofication,
      };
      let rocketBaseURL = localStorage.getItem('rocketBaseURL');
      this.loginService
        .loginWithUniqueUserId(loginCredentialObject)
        .subscribe((response) => {
          // @ts-ignore
          if (!response.status) {
            if (rocketChatRemove.isRemove.toString() != 'true') {
              let loginChatModel = {
                // @ts-ignore
                user: response.user.RocketChatUserName,
                // @ts-ignore
                password: response.user.RocketChatPassword,
              };
              forkJoin([
                this.rocketChatService.loginToRocketChat(
                  loginChatModel,
                  rocketBaseURL
                ),
                this.companyMasterService.getSingleCompnyDetails(
                  companyIdForNotificationRediection
                ),
              ]).subscribe((multipleResponse: any) => {
                // clear localStorage...
                localStorage.removeItem('companyEVA');
                localStorage.removeItem('user');
                localStorage.removeItem('companyDetailsGoveva');
                localStorage.removeItem('resources');
                localStorage.removeItem('rocketUserId');
                localStorage.removeItem('token');
                localStorage.removeItem('rocketAuth');
                localStorage.removeItem('companyDetails');

                // set localStroage
                localStorage.setItem(
                  'companyEVA',
                  // @ts-ignore
                  multipleResponse[1].companyData.EVA
                );
                // @ts-ignore
                localStorage.setItem('user', safeJsonStringify(response.user));
                localStorage.setItem(
                  'companyDetailsGoveva',
                  safeJsonStringify({
                    // @ts-ignore
                    label: multipleResponse[1].companyData.CompanyName,
                    // @ts-ignore
                    value: multipleResponse[1].companyData._id,
                  })
                );
                localStorage.setItem(
                  'resources',
                  // @ts-ignore
                  safeJsonStringify(response.resources)
                );
                localStorage.setItem(
                  'rocketUserId',
                  multipleResponse[0].data.userId
                );
                // @ts-ignore
                localStorage.setItem('token', response.token);
                localStorage.setItem(
                  'rocketAuth',
                  multipleResponse[0].data.authToken
                );

                localStorage.setItem(
                  'rocketBaseURL',
                  // @ts-ignore
                  multipleResponse[1].companyData.ChatEngineURL
                );
                localStorage.setItem(
                  'companyDetails',
                  safeJsonStringify({
                    // @ts-ignore
                    companyName: multipleResponse[1].companyData.CompanyName,
                    // @ts-ignore
                    companyID: multipleResponse[1].companyData._id,
                  })
                );

                location.reload();
                this.router.navigate(['/']);

                var res = this.router.url.slice(this.router.url.indexOf('?'));

                var redirectingUrl = this.router.url.replace(res, '') || '/';

                if (redirectingUrl.indexOf('structure') > 0) {
                  var resultUrl = redirectingUrl.slice(
                    redirectingUrl.indexOf('outlet')
                  );
                  var resultUrl = resultUrl.slice(resultUrl.indexOf(':') + 1);

                  var arrayURL = resultUrl.split('');
                  var result = '';
                  if (arrayURL[arrayURL.length - 1] == ')') {
                    for (var i = 0; i < arrayURL.length - 1; i++) {
                      result = result.concat(arrayURL[i]);
                    }
                  }

                  this.router.navigate([
                    'structure',
                    { outlets: { 'structure-outlet': result.trim() } },
                  ]);
                } else {
                  this.router.navigate(['/']);
                }
              });
            } else {
              this.companyMasterService
                .getSingleCompnyDetails(companyIdForNotificationRediection)
                .then((multipleResponse) => {
                  // clear localStorage...
                  localStorage.removeItem('companyEVA');
                  localStorage.removeItem('user');
                  localStorage.removeItem('companyDetailsGoveva');
                  localStorage.removeItem('resources');
                  localStorage.removeItem('token');
                  localStorage.removeItem('companyDetails');

                  localStorage.setItem(
                    'companyEVA',
                    // @ts-ignore
                    multipleResponse.companyData.EVA
                  );

                  localStorage.setItem(
                    'user',
                    // @ts-ignore
                    safeJsonStringify(response.user)
                  );
                  localStorage.setItem(
                    'companyDetailsGoveva',
                    safeJsonStringify({
                      // @ts-ignore
                      label: multipleResponse.companyData.CompanyName,
                      // @ts-ignore
                      value: multipleResponse.companyData._id,
                    })
                  );

                  localStorage.setItem(
                    'resources',
                    // @ts-ignore
                    safeJsonStringify(response.resources)
                  );
                  // @ts-ignore
                  localStorage.setItem('token', response.token);

                  localStorage.setItem(
                    'rocketBaseURL',
                    // @ts-ignore
                    multipleResponse.companyData.ChatEngineURL
                  );
                  localStorage.setItem(
                    'companyDetails',
                    safeJsonStringify({
                      // @ts-ignore
                      companyName: multipleResponse.companyData.CompanyName,
                      // @ts-ignore
                      companyID: multipleResponse.companyData._id,
                    })
                  );

                  location.reload();
                  this.router.navigate(['/']);
                  var res = this.router.url.slice(this.router.url.indexOf('?'));

                  var redirectingUrl = this.router.url.replace(res, '') || '/';

                  if (redirectingUrl.indexOf('structure') > 0) {
                    var resultUrl = redirectingUrl.slice(
                      redirectingUrl.indexOf('outlet')
                    );
                    var resultUrl = resultUrl.slice(resultUrl.indexOf(':') + 1);

                    var arrayURL = resultUrl.split('');
                    var result = '';
                    if (arrayURL[arrayURL.length - 1] == ')') {
                      for (var i = 0; i < arrayURL.length - 1; i++) {
                        result = result.concat(arrayURL[i]);
                      }
                    }

                    this.router.navigate([
                      'structure',
                      { outlets: { 'structure-outlet': result.trim() } },
                    ]);
                  } else {
                    this.router.navigate(['/']);
                  }
                });
            }
          }
        });
    }
    //region end for company notification redirection
  }

  CompanyChange(companyDetails) {
    console.log('companyDetails', companyDetails);
    let user = JSON.parse(localStorage.getItem('user'));
    let companyDetailsGoveva = JSON.parse(
      localStorage.getItem('companyDetailsGoveva')
    );
    let resources = JSON.parse(localStorage.getItem('resources'));
    let rocketUserId = localStorage.getItem('rocketUserId');
    let token = localStorage.getItem('token');
    let rocketAuth = localStorage.getItem('rocketAuth');
    let companyEVA = localStorage.getItem('companyEVA');
    let rocketBaseURL = localStorage.getItem('rocketBaseURL');
    var decEmailId = this.commonFunction.decrypt(user.EmailId);

    // remove clear all local storage
    var loginCredentialObject = {
      UserName: _.toLower(decEmailId),
      CompanyId: companyDetails._id,
      UniqueUserId: user.UniqueUserId,
    };
    this.loginService
      .loginWithUniqueUserId(loginCredentialObject)
      .subscribe((response) => {
        console.log('response of user--------22222222222222', response);
        // @ts-ignore
        if (!response.status) {
          if (rocketChatRemove.isRemove.toString() != 'true') {
            let loginChatModel = {
              // @ts-ignore
              user: response.user.RocketChatUserName,
              // @ts-ignore
              password: response.user.RocketChatPassword,
            };
            forkJoin([
              this.rocketChatService.loginToRocketChat(
                loginChatModel,
                rocketBaseURL
              ),
              this.companyMasterService.getSingleCompnyDetails(
                companyDetails._id
              ),
            ]).subscribe((multipleResponse: any) => {
              console.log(
                'resultOfCompanyDetails----------------------uuuu',
                multipleResponse
              );
              // clear localStorage...
              localStorage.removeItem('companyEVA');
              localStorage.removeItem('user');
              localStorage.removeItem('companyDetailsGoveva');
              localStorage.removeItem('resources');
              localStorage.removeItem('rocketUserId');
              localStorage.removeItem('token');
              localStorage.removeItem('rocketAuth');
              localStorage.removeItem('companyDetails');

              // set localStroage
              localStorage.setItem(
                'companyEVA',
                // @ts-ignore
                multipleResponse[1].companyData.EVA
              );
              // @ts-ignore
              localStorage.setItem('user', safeJsonStringify(response.user));
              localStorage.setItem(
                'companyDetailsGoveva',
                safeJsonStringify({
                  label: companyDetails.CompanyName,
                  value: companyDetails._id,
                })
              );
              localStorage.setItem(
                'resources',
                // @ts-ignore
                safeJsonStringify(response.resources)
              );
              localStorage.setItem(
                'rocketUserId',
                multipleResponse[0].data.userId
              );
              // @ts-ignore
              localStorage.setItem('token', response.token);
              localStorage.setItem(
                'rocketAuth',
                multipleResponse[0].data.authToken
              );

              localStorage.setItem(
                'rocketBaseURL',
                // @ts-ignore
                multipleResponse[1].companyData.ChatEngineURL
              );
              localStorage.setItem(
                'companyDetails',
                safeJsonStringify({
                  companyName: companyDetails.CompanyName,
                  companyID: companyDetails._id,
                })
              );
              location.reload();
            });
          } else {
            localStorage.removeItem('token');
            // @ts-ignore
            localStorage.setItem('token', response.token);
            this.companyMasterService
              .getSingleCompnyDetails(companyDetails._id)
              .then((multipleResponse) => {
                // clear localStorage...
                localStorage.removeItem('companyEVA');
                localStorage.removeItem('user');
                localStorage.removeItem('companyDetailsGoveva');
                localStorage.removeItem('resources');
                localStorage.removeItem('companyDetails');

                // set localStroage
                localStorage.setItem(
                  'companyEVA',
                  // @ts-ignore
                  multipleResponse.companyData.EVA
                );
                // @ts-ignore
                localStorage.setItem('user', safeJsonStringify(response.user));
                localStorage.setItem(
                  'companyDetailsGoveva',
                  safeJsonStringify({
                    label: companyDetails.CompanyName,
                    value: companyDetails._id,
                  })
                );

                localStorage.setItem(
                  'resources',
                  // @ts-ignore
                  safeJsonStringify(response.resources)
                );

                localStorage.setItem(
                  'rocketBaseURL',
                  // @ts-ignore
                  multipleResponse.companyData.ChatEngineURL
                );
                localStorage.setItem(
                  'companyDetails',
                  safeJsonStringify({
                    companyName: companyDetails.CompanyName,
                    companyID: companyDetails._id,
                  })
                );
                location.reload();
              });
          }
        } else {
          $('#alertTitle').text('Failure!');
          $('#alertTitle').css('color', 'red');
          $('#alertMessage').text('You are currenlty active in this company.');
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
        }
      });
  }

  logOut() {
    //remove data from local storage
    window.localStorage.clear();
    this.router.navigate(['/login']);

    if (rocketChatRemove.isRemove.toString() != 'true') {
      this.rocketChatService
        .logoutFromRocketChat()
        .then((rocketChatResponse) => {
          if (rocketChatResponse.status == 'success') {
            for (var count = 0; count < 10000; count++) {
              window.clearInterval(count);
            }
          }
        });
    }
  }
}
