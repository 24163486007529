import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";

@Injectable()
export class CompanySetupService {
  constructor(
    private http: HttpClient,
    private httpClient: HttpClient,
    private handler: HttpBackend) 
    {
    this.http = new HttpClient(handler);
  }

  getCompanyList(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/companyData")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getIndustryTypesList(): Promise<any> {
    return this.http
      .get(environment.clientUrl + "industry/getIndustryListAll")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  addCompany(newCompany): Promise<any> {
    // let headers = {};
    // let head = (key, value) => {headers[key] = value};
    // head('Content-Type', 'application/x-www-form-urlencoded');
  //   var token = localStorage.getItem('token');

  //   let headers = {};
  //      let head = (key, header) => {headers[key] = header};

  //  head('authentication-token', token);
  //  head("x-access-token",token)
    // var token = localStorage.getItem("token");
    // let header = {"x-access-token": token};
    return this.http
      .post(environment.nonValidateUrl + "companyMaster/add", newCompany)//, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  activateCompanyByCompanyId(companyId): Promise<any> {
    return this.http
      .post(
        environment.nonValidateUrl + "companyMaster/activateCompanyByCompanyId/" + companyId,
        companyId
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  registerSuperAdmin(companyinfo): Promise<any> {
    return this.http
      .post(environment.nonValidateUrl + "user/create/superAdmin", companyinfo)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  deleteCompany(companyId): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/delete/" + companyId)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  deleteCompanyByCompanyId(companyId): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/deleteCompanyByCompanyId/" + companyId)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  editCompany(updatedCompany): Promise<any> {
    return this.http
      .post(
        environment.nonValidateUrl + "companyMaster/edit/" + updatedCompany.CompanyId,
        updatedCompany
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getRegistrationIDTypes(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/getRegistrationIDTypes")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  // getTypesOfCompany(): Promise<any> {
  getCategoryOfCompany(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/getTypesOfCompany")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  // getClassesOfCompany(): Promise<any> {
  getTypesOfCompany(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/getClassesOfCompany")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getIndustryTypes(industryCode): Promise<any> {
    //environment.nonValidateUrl + 'companyMaster/getTypesOfIndustry'
    return this.http
      .get(environment.clientUrl + "getIndustryList?IndustryCode=" + industryCode)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getcompanyAutoComplete(): Promise<any> {
    return this.http
      .get(environment.nonValidateUrl + "companyMaster/companyAutoComplete")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  //get industry id and house id of selected company
  getSelectedCompanyDetailsFromFinCode(finCode): Promise<any> {
    var token = localStorage.getItem("token");
    let header = {};
    let head = (key, value) => { header[key] = value };
    head("x-access-token", token);
    return this.http
      .get(
        environment.nonValidateUrl +
        "companyMaster/selectedCompanyDetailsFromFinCode?Fincode=" +
        finCode,
        { headers: header }
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  //get industry and housing wise peers
  getindustryAndHousingWisePeers(industryCode, housingCode): Promise<any> {
    var token = localStorage.getItem("token");
    let header = {};
    let head = (key, value) => { header[key] = value };
    head("x-access-token", token);
    return this.http
      .get(
        environment.nonValidateUrl +
        "companyMaster/industryAndHousingWisePeers?IndustryCode=" +
        industryCode +
        "&HousingCode=" +
        housingCode,
        { headers: header }
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  fileUpload(fileObject: any): Promise<any> {
    const url = environment.clientUrl + "companyMaster/uploadImage";
    return this.http
      .post(url, fileObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  toggleTradingWindow(value, companyId): Promise<any> {
    return this.http
      .post(environment.nonValidateUrl + "companyMaster/updateTradingWindowStatus/" + value, {
        CompanyId: companyId,
      })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  synchistoricalData(): Promise<any> {
    var token = localStorage.getItem("token");
    let header = {};
    let head = (key, value) => { header[key] = value };
    head("x-access-token", token);
    const url = environment.nonValidateUrl + "externalDataFeed/syncHistoricData";
    return this.http
      .get(url, { headers: header })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  uploadCompanyLogo(companyId, formData): Promise<any> {
    return this.http
      .post(environment.nonValidateUrl + "companyMaster/uploadCompanyLogo/" + companyId, formData)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  checkSubsidiaryCompany(subsidiaryCompanyObject): Promise<any> {
    return this.http
      .post(
        environment.nonValidateUrl + "companyMaster/subsidiaryCompanyExist",
        subsidiaryCompanyObject
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getCompanyListTypeAhead(searchQuery) {
    let header = { "Authorization": environment.Service.authorization };
    return this.http.get(environment.url + "xcompany/searchCompanyName/" + searchQuery, {
      headers: header,
    });
  }

  getCompanyByCINNumber(searchQuery): Promise<any> {
    let header = {"Authorization": environment.Service.authorization};
    return this.http
      .get(environment.url + "xcompany/searchCompanyNameByCIN/" + searchQuery, { headers: header })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }
}
