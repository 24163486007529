import { Component, OnInit, Input } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-meetingagendacompliance',
  templateUrl: './meetingagendacompliance.component.html',
  styleUrls: ['./meetingagendacompliance.component.css']
})
export class MeetingagendacomplianceComponent implements OnInit {

  @Input('agendaComplianceObject') agendaComplianceObject: any;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // console.log("agendaComplianceObject",this.agendaComplianceObject)
  }

}
