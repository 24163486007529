import { Component, OnInit } from '@angular/core';
import { ChartsService } from '../../../../app/services/charts.service';
import { EmissionCalculationService } from '../../../services/emission-calculation.service';
import { GridApi, CreateRangeChartParams, GridReadyEvent, AgChartTheme } from 'ag-grid-community';
import { AgChartThemeOverrides } from 'ag-charts-community'
// import { LicenseManager } from 'ag-grid-enterprise';
import * as _ from 'lodash';
// LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-3_December_2022_[v2]_MTY3MDAyNTYwMDAwMA==1aa4d914a67239f5ebefcff68a17cead")

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-environment-data',
  templateUrl: './environment-data.component.html',
  styleUrls: ['./environment-data.component.css']
})
export class EnvironmentDataComponent implements OnInit {
  public isChartsApisLoaded: boolean = false;
  private gridApi!: GridApi;
  private params: any = {};
  public chartOptions: any = {};
  public crossFilterGridOptions: any = {};
  public summaryGridOptions: any = {};
  public summaryData: any = {};
  public chart2Data: any = {};
  public dimType: any = [];
  public selectedDim:any = "";

  public chartThemes = ['govevaChartTheme', 'ag-default'];
  public customChartThemes = {
    govevaChartTheme: <AgChartTheme> {
      baseTheme: 'ag-default',
      palette: {
        fills: ['#5C2983', '#0076C5', '#21B372', '#FDDE02', '#F76700', '#D30018'],
        strokes: ['black'],
      },
      overrides: {
        cartesian: {
          axes: {
            crossLines: [
              {
                lineDash: []
              }
            ],
            category: {
              label: {
                rotation: 0,
              },
              gridStyle: [
                {
                  lineDash: []
                }
              ]
            },
            number: {
              label: {
                formatter: (params) => {
                  return params.value / 1000 + 'k';
                },
              },
              gridStyle: [
                {
                  lineDash: []
                }
              ]
            },
          },
          series: {
            bar:{
              stacked: true,
              lineDash: [],
              lineDashOffset: 0,
              shadow: {
                enabled: true,
                blur: 5
              },
              strokeWidth: 0,
              fillOpacity: 1,
              strokeOpacity: 0,
              //fills: ['#0000FF']
            },
            column: {
              stacked: true,
              lineDash: [],
              lineDashOffset: 0,
              shadow: {
                enabled: true,
                blur: 5
              },
              strokeWidth: 0,
              fillOpacity: 1,
              strokeOpacity: 0,
              //fills: ['#007500']
            },
          }
        },
        pie:{
          series:{
            fillOpacity: 1,
            strokeOpacity: 0,
            strokeWidth: 0,
            lineDashOffset: 0,
            rotation: 0,
            outerRadiusOffset: 4,
            outerRadiusRatio: 1,
            innerRadiusOffset: -50,
            innerRadiusRatio: 1,
            shadow: {
              enabled: true,
              blur: 5
            },
            fills: ['#0000FF','#007500','#FAD02C']            
          }
        },
      },
    }
  }


  constructor(
    private chartsService: ChartsService,
    public emissionCalculationService: EmissionCalculationService
  ) { }

  onGridReady(params: GridReadyEvent) {
    // console.log('GridReadyEvent')
    // this.gridApi = params.api;
  }
  ngOnInit() {
    const that = this;
    this.isChartsApisLoaded = false;
    this.emissionCalculationService.getCrossFilteredTCO2Summary()
    .then(response => {
      this.summaryGridOptions = {
        chartThemes: this.chartThemes,
        customChartThemes: this.customChartThemes,
        columnDefs: response.data.columnDefs,
        defaultColDef: {
          flex: 1,
          sortable: true,
          filter: 'agSetColumnFilter',
          floatingFilter: true,
          resizable: true,
        },
        rowData: response.data.rowData,
        enableCharts: true,
        onFirstDataRendered: function(params){
          that.createMonthlyEmissionChart(params.api);
          that.createEmissionByScopeChart(params.api);
          that.createCalculatorsEmissionChart(params.api);
        },
      };
      this.isChartsApisLoaded = true;
    })
  }
  createMonthlyEmissionChart(gridApi) {
    gridApi.createCrossFilterChart({
      chartType: 'column',
      cellRange: {
        columns: ['date', 'emission'],
      },
      aggFunc: 'sum',
      chartThemeOverrides:<AgChartThemeOverrides> {
        common: {
          title: {
            enabled: true,
            text: 'Emission by Month',
          },
          legend: {
            enabled: false,
          },
        },
      },
      chartContainer: document.querySelector('#columnChart'),
    });
  }
  createEmissionByScopeChart(gridApi) {
    gridApi.createCrossFilterChart({
      chartType: 'pie',
      cellRange: {
        columns: ['scope', 'emission'],
      },
      aggFunc: 'sum',
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Emission by Scope',
          },
        },
        pie: {
          series: {
            legend: {
              enabled: false,
            },
            title: {
              enabled: false,
            },
            calloutLabel:{
            enabled:false
          },
            sectorLabelKey:'scope',
            sectorLabel: {
              color: 'white',
            },
            innerRadiusOffset: -50,
          },
        },
      },
      chartContainer: document.querySelector('#pieChart'),
    });
  }
  createCalculatorsEmissionChart(gridApi) {
    gridApi.createCrossFilterChart({
      chartType: 'bar',
      cellRange: {
        columns: ['calculator', 'emission'],
      },
      aggFunc: 'sum',
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Emission by Source',
          },
          legend: {
            enabled: false,
          },
        },
      },
      chartContainer: document.querySelector('#barChart'),
    });
  }

  onNavChange($event){
    this.isChartsApisLoaded = false;
    const tab = $event.nextId;
    switch(tab){
      case 1: {
        if(_.isEmpty(this.summaryData)){
          this.emissionCalculationService.getCrossFilteredTCO2Summary()
          .then(response => {
            this.summaryData = response.data;
            this.isChartsApisLoaded = true;
          })
        } else {
          this.isChartsApisLoaded = true;
        }
        break;
      }
    }
  }

  show(){
    // this.loadEnvironmentData();
  }

}
