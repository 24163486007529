import { DashboardMyTaskService } from './../../../services/dashboardmytask.service'
import { Component, OnInit, OnChanges, Input, ViewChild, ViewChildren, AfterViewInit, Output, EventEmitter, QueryList } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Rx';
import * as _ from 'lodash';
import { CalendarTaskDetailsComponent } from './../calendar-task-details/calendar-task-details.component';
import { NgbDateStruct, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ActivatedTaskFromComplianceService } from './../../../services/activatedTaskFromCompliance.service'
import { UserMasterService } from './../../../services/user-master.service';
import * as moment from 'moment';
import { NotificationComponent } from '../notification/notification.component';
import { Observable } from 'rxjs/Observable';
import { BulkUpdateTaskComponent } from './../bulk-update-task/bulk-update-task.component';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-mytask',
  templateUrl: './mytask.component.html',
  styleUrls: ['./mytask.component.css']
})
export class MytaskComponent implements OnInit, OnChanges {

  @Input('refreshStatus') refreshStatus: any;
  @Output() UpdatedStatus = new EventEmitter();

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtElement: DataTableDirective;
  dtGovernanceOptions: DataTables.Settings = {};
  dtGovernanceTrigger: Subject<any> = new Subject();

  dtComplianceOptions: DataTables.Settings = {};
  dtComplianceTrigger: Subject<any> = new Subject();

  // dtOptions: DataTables.Settings = {};
  // The trigger needed to re-render the table
  // dtTrigger: Subject<any> = new Subject();
  filteredData: any;
  displayLabel: string;


  //my task activites -- 3 Jan
  //display task details
  complianceTaskDetails: any = [];
  complianceTaskDetailsCache: any = [];
  governanceTaskDetails: any = [];
  governanceTaskDetailsCache: any = [];
  myTaskDetails: any = [];
  myTaskDetailsCache: any = [];
  myTaskList: any = [];
  taskObj: any = {
    taskListIds: [],
    taskStatus: ''
  }
  complianceTaskList: any = [];
  complianceTaskObj: any = {
    taskListIds: [],
    taskStatus: ''
  }

  activeComplianceTab: boolean = false;
  isMyTaskFetch: any;
  showDropDown = false;
  constructor(private dashboardMyTaskService: DashboardMyTaskService, private modalService: NgbModal, private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService, private userService: UserMasterService) { }

  ngOnInit() {

    this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");

    if (this.isMyTaskFetch != "false") {
      this.showMyTaskDetails();
      this.dtGovernanceOptions = {
        destroy: true,
        pagingType: 'full_numbers',
        paging: true,
        pageLength: 6,
        order: [1, 'asc'],
        columnDefs: [{ orderable: false, targets: [0] }],
        scrollCollapse: true,
        searching: false,
        lengthChange: false,
        language: { //made changes for datatables for next previous button
          "paginate": {
            "first": "<<",
            "last": ">>",
            "next": ">",
            "previous": "<"
          }
        }
      };

      this.dtComplianceOptions = {
        destroy: true,
        pagingType: 'full_numbers',
        paging: true,
        pageLength: 6,
        order: [1, 'asc'],
        columnDefs: [{ orderable: false, targets: [0] }],
        scrollCollapse: true,
        searching: false,
        lengthChange: false,
        language: { //made changes for datatables for next previous button
          "paginate": {
            "first": "<<",
            "last": ">>",
            "next": ">",
            "previous": "<"
          }
        }
      };
    }
  }

  ngOnChanges() {
    // //console.log('checking for the updated object', this.refreshStatus);
    this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");
    if (this.isMyTaskFetch != "false") {
      this.showMyTaskDetails();
    }
  }

  showMyTaskDetails() {

    this.displayLabel = "All";
    // this.dtOptions = {
    //   destroy: true,
    //   pagingType: 'full_numbers',
    //   paging: true,
    //   pageLength: 3,
    //   search: true,
    //   lengthChange: false,
    //   language: { //made changes for datatables for next previous button
    //     "paginate": {
    //       "first": "<<",
    //       "last": ">>",
    //       "next": ">",
    //       "previous": "<"
    //     }
    //   }
    // };


    this.myTaskDetails = [];
    this.myTaskDetailsCache = [];
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let assignedToId = loginUsers._id;
    let companyId = loginUsers.CompanyId;
    let uniqueUesrId = loginUsers.UniqueUserId;
    //console.log("isMyTaskFetch", this.isMyTaskFetch)
    if (assignedToId != "" && this.isMyTaskFetch != "false") {
      this.intializeTaskListOnbehaveOfUniqueUserId(uniqueUesrId, companyId)
    }
  }

  filterTasks(data) {
    this.showDropDown = !this.showDropDown;
    const doc: any = document.getElementById('dropdown-menu')
    doc.style.display = 'none';
    this.myTaskDetails = [];


    this.displayLabel = data;
    this.myTaskDetails = _.filter(this.myTaskDetailsCache, function (o) { return o.LocalStatus === data });
    if (data == 'All') {
      this.myTaskDetails = [];
      this.myTaskDetails = this.myTaskDetailsCache;
    } else if (data == 'PartiallyCompleted') {
      this.displayLabel = 'Partially Completed';
    } else if (data == 'DelayedCompletion') {
      this.displayLabel = 'Delayed Completion';
    } else if (data == 'NA') {
      this.displayLabel = 'Not Applicable';
    }

  }

  taskDetails(task) {
    //  
    // //console.log('task', task)
    let activatedTaskFromCompliance: boolean
    if (task.ActivatedTaskFromCompliance == undefined) {
      activatedTaskFromCompliance = false;
    } else if (task.ActivatedTaskFromCompliance) {
      activatedTaskFromCompliance = task.ActivatedTaskFromCompliance;
    }
    console.log("activatedTaskFromCompliance------", activatedTaskFromCompliance)
    const modalRef = this.modalService.open(CalendarTaskDetailsComponent, { size: 'lg' })
    modalRef.componentInstance.taskId = { _id: task._id, calendarId: 'myTask', activatedTaskFromCompliance: activatedTaskFromCompliance };
    modalRef.result.then(response => {
      // //console.log('checling for the response', response)
      //this.showMyTaskDetails();
      this.UpdatedStatus.emit(response);
    }, (reason) => {
      // //console.log('checling for the reason', reason)
    })
  }

  //----------//Bulk Update//----------//  
  showGovernanceTasks() {
    $(".gov").val("All");
    $(".govFilter").val("All");
    this.activeComplianceTab = false;
    $("#govTab").addClass("active");
    $("#governanceTab").addClass("active");
    $("#compTab").removeClass("active");
    $("#complianceTab").removeClass("active");
    // this.displayLabel = "All";   


    this.governanceTaskDetails = [];
    this.governanceTaskDetailsCache = [];
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let assignedToId = loginUsers._id;
    let companyId = loginUsers.CompanyId;
    let uniqueUesrId = loginUsers.UniqueUserId;

    // $('#myTasksTable').DataTable({ destroy: true }).destroy();
    //  
    if (assignedToId != "" && this.isMyTaskFetch != "false") {
      this.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUesrId).then((responseOfListOfId) => {
        if (!responseOfListOfId.status) {
          var promiseArray = [];
          var compliancePromiseArray = [];
          var self = this;
          for (var index = 0; index < responseOfListOfId.listOfId.length; index++) {
            promiseArray.push(self.dashboardMyTaskService.getMyTaskList(_.get(responseOfListOfId.listOfId[index], '_id')))
            compliancePromiseArray.push(self.activatedTaskFromComplianceService.getMyActivatedTaskList(_.get(responseOfListOfId.listOfId[index], 'CompanyId'), _.get(responseOfListOfId.listOfId[index], '_id')))
          }
          Observable.forkJoin(promiseArray).subscribe(multipleCompanyTaskList => {
            let taskList = _.filter(multipleCompanyTaskList, function (element) { return !_.isNil(_.get(element, 'taskList')) })
            let mytaskList = { taskList: [] }
            let tempList = [];
            mytaskList.taskList = _.map(taskList, function (element) { return _.concat(tempList, _.get(element, 'taskList')) })
            mytaskList.taskList = _.flatten(mytaskList.taskList)
            // mytaskList.taskList = _.reduce(taskList, function (currentElement, nextElement) { return _.concat(_.get(currentElement, 'taskList'), _.get(nextElement, 'taskList')) })
            mytaskList.taskList.forEach(task => {
              if (task.Status === "Completed" || task.Status === "Done" || task.Status === "DelayedCompletion") {
                task.LocalStatus = task.Status;
                task.ColorDisplayStatus = task.Status;
              } else {

                var tempEndDate: Date = new Date(task.EndDate);
                var date = tempEndDate.getDate();
                var month = tempEndDate.getMonth();
                var year = tempEndDate.getFullYear();
                var endDate = new Date(year, month, date);

                var tempTodayDate: Date = new Date();
                var dateT = tempTodayDate.getDate();
                var monthT = tempTodayDate.getMonth();
                var yearT = tempTodayDate.getFullYear();
                var todayDate = new Date(yearT, monthT, dateT);


                if (endDate < todayDate) {
                  task.LocalStatus = task.Status;
                  task.ColorDisplayStatus = "Expired";
                } else {
                  task.LocalStatus = task.Status;
                  task.ColorDisplayStatus = task.Status;
                }
              }
            });
            for (var taskIndex = 0; taskIndex < mytaskList.taskList.length; taskIndex++) {
              mytaskList.taskList[taskIndex].isSelected = false;
            }
            //console.log('mytaskList.taskList', mytaskList.taskList)
            this.governanceTaskDetails = mytaskList.taskList;
            this.governanceTaskDetailsCache = mytaskList.taskList;

            if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
              this.dtGovernanceTrigger.next();
              this.dtComplianceTrigger.next();
            }
          })
        }
      })
    }
  }

  filterGovernaceTasks(data) {
    $('input:checkbox').attr('checked', false);
    $("#checkAll").prop('checked', false);
    this.taskObj.taskStatus = "";
    this.taskObj.taskListIds = [];
    if (data.target.value == 'All') {
      this.governanceTaskDetails = [];
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.governanceTaskDetails = this.governanceTaskDetailsCache;
      // this.dtGovernanceTrigger.next();
      if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    else {
      this.governanceTaskDetails = [];
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.governanceTaskDetails = _.filter(this.governanceTaskDetailsCache, function (o) { return o.LocalStatus === data.target.value });
      // this.dtGovernanceTrigger.next();
      if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    // $('#myTasksTable').DataTable({ destroy: true }).destroy();    
  }

  markTasks(event) {
    var taskStatus = event.target.value;
    this.taskObj.taskStatus = taskStatus;
    // this.taskObj.taskListIds = this.myTaskList;
  }

  markAll(event) {
    //console.log('event.target.checked', event.target.checked)
    if (event.target.checked) {
      this.governanceTaskDetails.forEach(element => {
        element.isSelected = true;
      });
    } else {
      this.governanceTaskDetails.forEach(element => {
        element.isSelected = false;
      });
    }

    //  
    // if (event.target.checked == true) {
    //   $('input:checkbox').attr('checked', true);
    //   // $("#input").prop('checked', true);
    //   this.myTaskList = this.governanceTaskDetails.map(function (o) {
    //     return o._id;
    //   })
    //   this.taskObj.taskListIds = this.myTaskList;
    // }
    // else if (event.target.checked == false) {
    //   $('input:checkbox').attr('checked', false);
    //   this.myTaskList = [];
    //   this.taskObj.taskListIds = []
    // }
  }

  showComplianceTasks() {
    $(".comp").val("All");
    $(".compFilter").val("All");
    this.activeComplianceTab = true;
    this.displayLabel = "All";

    this.complianceTaskDetails = [];
    this.complianceTaskDetailsCache = [];
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let assignedToId = loginUsers._id;
    let companyId = loginUsers.CompanyId;
    let uniqueUesrId = loginUsers.UniqueUserId;

    $('#complianceTasksTable').DataTable({ destroy: true }).destroy();

    if (assignedToId != "" && this.isMyTaskFetch != "false") {

      if (companyId != "") {


        this.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUesrId).then((responseOfListOfId) => {
          if (!responseOfListOfId.status) {
            var promiseArray = [];
            var compliancePromiseArray = [];
            var self = this;
            for (var index = 0; index < responseOfListOfId.listOfId.length; index++) {
              // promiseArray.push(self.dashboardMyTaskService.getMyTaskList(_.get(responseOfListOfId.listOfId[index], '_id')))
              // compliancePromiseArray.push(self.activatedTaskFromComplianceService.getMyActivatedTaskList(_.get(responseOfListOfId.listOfId[index], 'CompanyId'), _.get(responseOfListOfId.listOfId[index], '_id')))
            }
            self.activatedTaskFromComplianceService.getMyActivatedTaskList(companyId, assignedToId).then(listOfcomplianceTaskList => {
              let removedUndefinedValues = _.filter(listOfcomplianceTaskList, function (element) { return !_.isNil(element) })
              if (_.size(removedUndefinedValues)) {
                let complianceTaskList = _.flattenDeep(removedUndefinedValues)
                complianceTaskList = complianceTaskList.filter(g => _.get(g, 'StartDate') != null && _.get(g, 'StartDate') != undefined && _.get(g, 'EndDate') != undefined && _.get(g, 'EndDate') != null)

                for (let complianceIndex = 0; complianceIndex < complianceTaskList.length; complianceIndex++) {
                  let task = {
                    ActivatedTaskFromCompliance: true,
                    // TaskStatus: "",
                    LocalStatus: "",
                    EndDate: "",
                    ColorDisplayStatus: ""
                  }
                  complianceTaskList[complianceIndex] = _.assign(task, complianceTaskList[complianceIndex])
                  complianceTaskList[complianceIndex].ActivatedTaskFromCompliance = true;

                  switch (_.toInteger(complianceTaskList[complianceIndex].TaskStatus)) {
                    case 1://Pending
                      //console.log('it is coming here')
                      complianceTaskList[complianceIndex].LocalStatus = "Pending";
                      break;
                    case 2://Completed
                      complianceTaskList[complianceIndex].LocalStatus = "Completed";
                      break;
                    case 3://DelayedCompletion
                      complianceTaskList[complianceIndex].LocalStatus = "DelayedCompletion";
                      break;
                    case 4://PartiallyCompleted
                      complianceTaskList[complianceIndex].LocalStatus = "PartiallyCompleted";
                      break;
                    case 5://NA
                      complianceTaskList[complianceIndex].LocalStatus = "NA";
                      break;
                    case 6://Done
                      complianceTaskList[complianceIndex].LocalStatus = "Done";
                      break;
                    case 7://Cancelled
                      complianceTaskList[complianceIndex].LocalStatus = "Cancelled";
                      break;
                    default:
                      break;
                  }
                  if (complianceTaskList[complianceIndex].LocalStatus != "Completed" && complianceTaskList[complianceIndex].LocalStatus != "Done" && complianceTaskList[complianceIndex].LocalStatus != "DelayedCompletion") {
                    //  
                    // var endDate: Date = new Date(Date.parse(task.EndDate));
                    // var todayDate: Date = new Date(Date.parse(Date()));

                    var tempEndDate: Date = new Date(complianceTaskList[complianceIndex].EndDate);
                    var date = tempEndDate.getDate();
                    var month = tempEndDate.getMonth();
                    var year = tempEndDate.getFullYear();
                    var endDate = new Date(year, month, date);

                    var tempTodayDate: Date = new Date();
                    var dateT = tempTodayDate.getDate();
                    var monthT = tempTodayDate.getMonth();
                    var yearT = tempTodayDate.getFullYear();
                    var todayDate = new Date(yearT, monthT, dateT);

                    if (endDate < todayDate) {
                      complianceTaskList[complianceIndex].ColorDisplayStatus = "Expired";
                    } else {
                      complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
                    }
                  } else {
                    complianceTaskList[complianceIndex].LocalStatus = complianceTaskList[complianceIndex].LocalStatus;
                    complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
                  }
                }
                this.complianceTaskDetails = complianceTaskList;
                this.complianceTaskDetailsCache = complianceTaskList;

                if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
                  this.dtGovernanceTrigger.next();
                  this.dtComplianceTrigger.next();
                }
              }
            })
          }
        })
      }
      // this.dtComplianceTrigger.next();
    }
  }

  markComplianceTasks(event) {

    var taskStatus = event.target.value;
    this.complianceTaskObj.taskStatus = taskStatus;
    //   this.complianceTaskObj.taskListIds = this.complianceTaskList;
  }

  updateStatus() {
    let self = this;
    if (this.activeComplianceTab == false) {
      // Goverance Tab for updating status...
      //console.log('self.governanceTaskDetails', self.governanceTaskDetails)
      let selectedTask = _.filter(self.governanceTaskDetails, function (element) { return element.isSelected })
      //console.log('selectedTask==>', selectedTask)
      _.forEach(self.governanceTaskDetails, function (element) {
        element.isSelected = false;
      })
      return;
      // if (this.taskObj.taskListIds.length != 0) {
      //   if ((this.taskObj.taskStatus != "") && (this.taskObj.taskStatus != "All")) {
      //     this.dashboardMyTaskService.bulkUpdateTaskStatus(this.taskObj).then(response => {
      //        
      //       if (response.status == 0) {
      //         const modalRef = this.modalService.open(NotificationComponent)
      //         modalRef.componentInstance.notify = { status: 'Success', message: 'Tasks Status are updated successfully.' }
      //         this.myTaskList = [];
      //         this.governanceTaskDetails = [];
      //         this.governanceTaskDetailsCache = [];
      //         $('input:checkbox').attr('checked', false);
      //         $("#checkAll").prop('checked', false);
      //         $(".gov").val("All");
      //         this.taskObj.taskStatus = "";
      //         this.taskObj.taskListIds = [];
      //         this.showMyTaskDetails();
      //          
      //         this.dtElements.forEach((dtElement: DataTableDirective) => {
      //            
      //           dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //              
      //             dtInstance.destroy();
      //           });
      //         });
      //         // var datable = $('#myTasksTable').DataTable();
      //         // datable.state.clear();
      //         this.UpdatedStatus.emit(response);
      //       } else {
      //         const modalRef = this.modalService.open(NotificationComponent)
      //         modalRef.componentInstance.notify = { status: 'Failure', message: 'OOPS! Something went wrong.' }
      //         this.myTaskList = [];
      //         this.governanceTaskDetails = [];

      //         $('input:checkbox').attr('checked', false);
      //         $("#checkAll").prop('checked', false);
      //         $(".gov").val("All");
      //         this.taskObj.taskStatus = "";
      //         this.taskObj.taskListIds = [];
      //         this.showMyTaskDetails();
      //         this.dtElements.forEach((dtElement: DataTableDirective) => {
      //           dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //             dtInstance.destroy();
      //           });
      //         });
      //       }
      //     })
      //   }
      //   else {
      //     const modalRef = this.modalService.open(NotificationComponent)
      //     modalRef.componentInstance.notify = { status: 'Failure', message: 'Please select status.' }
      //     this.myTaskList = [];
      //     this.governanceTaskDetails = [];
      //     this.taskObj.taskStatus = "";
      //     this.taskObj.taskListIds = [];
      //     $('input:checkbox').attr('checked', false);
      //     $("#checkAll").prop('checked', false);
      //     // this.showGovernanceTasks();
      //     this.showMyTaskDetails();
      //     this.dtElements.forEach((dtElement: DataTableDirective) => {
      //       dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //         dtInstance.destroy();
      //       });
      //     });
      //   }

      // } else {
      //   const modalRef = this.modalService.open(NotificationComponent)
      //   modalRef.componentInstance.notify = { status: 'Failure', message: 'No tasks can be updated.' }
      //   this.myTaskList = [];
      //   this.governanceTaskDetails = [];
      //   this.taskObj.taskStatus = "";
      //   this.taskObj.taskListIds = [];
      //   $('input:checkbox').attr('checked', false);
      //   $("#checkAll").prop('checked', false);
      //   // this.showGovernanceTasks();
      //   this.showMyTaskDetails();
      //   this.dtElements.forEach((dtElement: DataTableDirective) => {
      //     dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //       dtInstance.destroy();
      //     });
      //   });
      // }
    }
    else {
      // compliance

      if (this.complianceTaskObj.taskListIds.length != 0 && this.isMyTaskFetch != "false") {
        if ((this.complianceTaskObj.taskStatus != "") && (this.complianceTaskObj.taskStatus != "All")) {
          this.dashboardMyTaskService.bulkUpdateTaskStatus(this.complianceTaskObj).then(response => {

            if (response.status == 0) {
              const modalRef = this.modalService.open(NotificationComponent)
              modalRef.componentInstance.notify = { status: 'Success', message: 'Tasks Status are updated successfully.' }
              this.complianceTaskList = [];
              this.complianceTaskDetails = [];
              $('input:checkbox').attr('checked', false);
              $("#checkAllCompliance").prop('checked', false);
            } else {
              const modalRef = this.modalService.open(NotificationComponent)
              modalRef.componentInstance.notify = { status: 'Failure', message: 'OOPS! Something went wrong.' }
              this.complianceTaskList = [];
              this.complianceTaskDetails = [];
              $('input:checkbox').attr('checked', false);
              $("#checkAllCompliance").prop('checked', false);
            }
          })
          $('input:checkbox').attr('checked', false);
          $(".comp").val("All");
          $("#checkAllCompliance").prop('checked', false);
          this.taskObj.taskStatus = "";
          this.showComplianceTasks();
          this.showMyTaskDetails();
        }
        else {
          const modalRef = this.modalService.open(NotificationComponent)
          modalRef.componentInstance.notify = { status: 'Failure', message: 'Please select status.' }
          this.complianceTaskList = [];
          this.complianceTaskDetails = [];
          $('input:checkbox').attr('checked', false);
          $("#checkAllCompliance").prop('checked', false);
          this.showComplianceTasks();
          this.showMyTaskDetails();
        }

      } else

        if (this.complianceTaskObj.taskListIds.length == 0) {
          const modalRef = this.modalService.open(NotificationComponent)
          modalRef.componentInstance.notify = { status: 'Failure', message: 'Tasks Status of Compliance cannot be updated.' }
          this.complianceTaskList = [];
          this.complianceTaskDetails = [];
          $('input:checkbox').attr('checked', false);
          $("#checkAllCompliance").prop('checked', false);
          this.showComplianceTasks();
          this.showMyTaskDetails();
        }
    }

  }

  checkTask(event, task, index) {

    if (!this.governanceTaskDetails[index].isSelected) {
      this.governanceTaskDetails[index].isSelected = true;
    } else {
      this.governanceTaskDetails[index].isSelected = false;
    }
    //console.log('checking for the element', this.governanceTaskDetails[index].isSelected)
    //  
    // if (event.target.checked == true) {
    //   this.myTaskList.push(task._id);
    // } else if (event.target.checked == false) {
    //   this.myTaskList.splice(task._id);
    // }
    // this.taskObj.taskListIds = this.myTaskList;
    //  //console.log("this.myTaskStatusList", this.mytaskList);
  }

  checkComplianceTask(event, task) {

    if (event.target.checked == true) {
      this.complianceTaskList.push(task._id);
    }
    else if (event.target.checked == false) {
      this.complianceTaskList.splice(task._id);
    }
    this.complianceTaskObj.taskListIds = this.complianceTaskList;
    //  //console.log("this.myTaskStatusList", this.mytaskList);
  }

  markAllCompliance(event) {

    if (event.target.checked == true) {
      $('input:checkbox').attr('checked', 'checked');
      // $("#input").prop('checked', true);
      this.complianceTaskList = this.complianceTaskDetails.map(function (o) {
        return o._id;
      })
      this.complianceTaskObj.taskListIds = this.complianceTaskList;
    }
    else if (event.target.checked == false) {
      $('input:checkbox').attr('checked', '');
      this.complianceTaskList = [];
      this.complianceTaskObj.taskListIds = [];
    }
  }

  filterComplianceTasks(data) {

    this.complianceTaskDetails = [];


    //this.displayLabel = data;
    this.complianceTaskDetails = _.filter(this.complianceTaskDetailsCache, function (o) { return o.LocalStatus === data.target.value });
    if (data.target.value == 'All') {
      this.complianceTaskDetails = [];
      this.complianceTaskDetails = this.complianceTaskDetailsCache;
    }
    $('#complianceTasksTable').DataTable({ destroy: true }).destroy();
    //  else if (data == 'PartiallyCompleted') {
    //   this.displayLabel = 'Partially Completed';
    // } else if (data == 'DelayedCompletion') {
    //   this.displayLabel = 'Delayed Completion';
    // } else if (data == 'NA') {
    //   this.displayLabel = 'Not Applicable';
    // }
  }


  intializeTaskListOnbehaveOfUniqueUserId(uniqueUesrId, companyId) {
    this.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUesrId).then((responseOfListOfId) => {
      if (!responseOfListOfId.status) {
        var promiseArray = [];
        var compliancePromiseArray = [];
        var self = this;
        for (var index = 0; index < responseOfListOfId.listOfId.length; index++) {
          promiseArray.push(self.dashboardMyTaskService.getMyTaskList(_.get(responseOfListOfId.listOfId[index], '_id')))
          compliancePromiseArray.push(self.activatedTaskFromComplianceService.getMyActivatedTaskList(_.get(responseOfListOfId.listOfId[index], 'CompanyId'), _.get(responseOfListOfId.listOfId[index], '_id')))
        }
        Observable.forkJoin(promiseArray).subscribe(multipleCompanyTaskList => {
          let taskList = _.filter(multipleCompanyTaskList, function (element) { return !_.isNil(_.get(element, 'taskList')) })
          let mytaskList = { taskList: [] }
          let tempList = [];
          mytaskList.taskList = _.map(taskList, function (element) { return _.concat(tempList, _.get(element, 'taskList')) })
          mytaskList.taskList = _.flatten(mytaskList.taskList)
          // mytaskList.taskList = _.reduce(taskList, function (currentElement, nextElement) { return _.concat(_.get(currentElement, 'taskList'), _.get(nextElement, 'taskList')) })
          mytaskList.taskList.forEach(task => {
            let companyObject = _.find(responseOfListOfId.listOfId, function (company) { return task.CompanyId == company.CompanyId })
            task.CompanyName = companyObject.CompanyName;
            if (task.Status === "Completed" || task.Status === "Done" || task.Status === "DelayedCompletion") {
              task.LocalStatus = task.Status;
              task.ColorDisplayStatus = task.Status;
            } else {

              var tempEndDate: Date = new Date(task.EndDate);
              var date = tempEndDate.getDate();
              var month = tempEndDate.getMonth();
              var year = tempEndDate.getFullYear();
              var endDate = new Date(year, month, date);

              var tempTodayDate: Date = new Date();
              var dateT = tempTodayDate.getDate();
              var monthT = tempTodayDate.getMonth();
              var yearT = tempTodayDate.getFullYear();
              var todayDate = new Date(yearT, monthT, dateT);


              if (endDate < todayDate) {
                task.LocalStatus = task.Status;
                task.ColorDisplayStatus = "Expired";
              } else {
                task.LocalStatus = task.Status;
                task.ColorDisplayStatus = task.Status;
              }
            }
          });
          // this.myTaskDetails = mytaskList.taskList;
          // this.myTaskDetailsCache = mytaskList.taskList;

          if (companyId != "") {

            self.activatedTaskFromComplianceService.getMyActivatedTaskList(companyId, uniqueUesrId).then(listOfcomplianceTaskList => {
              //console.log('listOfcomplianceTaskList', listOfcomplianceTaskList)
              let removedUndefinedValues = _.filter(listOfcomplianceTaskList, function (element) { return !_.isNil(element) })
              //console.log('removedUndefinedValues', removedUndefinedValues)
              if (_.size(removedUndefinedValues)) {
                let complianceTaskList = _.flattenDeep(removedUndefinedValues)
                complianceTaskList = complianceTaskList.filter(g => _.get(g, 'StartDate') != null && _.get(g, 'StartDate') != undefined && _.get(g, 'EndDate') != undefined && _.get(g, 'EndDate') != null)
                let compliancetaskArray = [];
                for (let complianceIndex = 0; complianceIndex < complianceTaskList.length; complianceIndex++) {
                  let task = {
                    ActivatedTaskFromCompliance: true,
                    // TaskStatus: "",
                    LocalStatus: "",
                    EndDate: "",
                    ColorDisplayStatus: ""
                  }
                  task = _.assign(task, complianceTaskList[complianceIndex])
                  // task.ActivatedTaskFromCompliance = true;

                  switch (_.toString(_.get(complianceTaskList[complianceIndex], 'TaskStatus'))) {
                    case '1'://Pending
                      task.LocalStatus = "Pending";
                      break;
                    case '2'://Completed
                      task.LocalStatus = "Completed";
                      break;
                    case '3'://DelayedCompletion
                      task.LocalStatus = "DelayedCompletion";
                      break;
                    case '4'://PartiallyCompleted
                      task.LocalStatus = "PartiallyCompleted";
                      break;
                    case '5'://NA
                      task.LocalStatus = "NA";
                      break;
                    case '6'://Done
                      task.LocalStatus = "Done";
                      break;
                    case '7'://Cancelled
                      task.LocalStatus = "Cancelled";
                      break;
                    default:
                      break;
                  }
                  if (task.LocalStatus != "Completed" && task.LocalStatus != "Done" && task.LocalStatus != "DelayedCompletion") {

                    // var endDate: Date = new Date(Date.parse(task.EndDate));
                    // var todayDate: Date = new Date(Date.parse(Date()));

                    var tempEndDate: Date = new Date(task.EndDate);
                    var date = tempEndDate.getDate();
                    var month = tempEndDate.getMonth();
                    var year = tempEndDate.getFullYear();
                    var endDate = new Date(year, month, date);

                    var tempTodayDate: Date = new Date();
                    var dateT = tempTodayDate.getDate();
                    var monthT = tempTodayDate.getMonth();
                    var yearT = tempTodayDate.getFullYear();
                    var todayDate = new Date(yearT, monthT, dateT);

                    if (endDate < todayDate) {
                      task.ColorDisplayStatus = "Expired";
                    } else {
                      task.ColorDisplayStatus = task.LocalStatus;
                    }
                  } else {
                    task.LocalStatus = task.LocalStatus;
                    task.ColorDisplayStatus = task.LocalStatus;
                  }
                  compliancetaskArray.push(task)
                }
                //console.log('compliancetaskArray', compliancetaskArray)
                //console.log('this.myTaskDetails=>', this.myTaskDetails)
                this.myTaskDetails = [];
                this.myTaskDetailsCache = [];
                this.myTaskDetails = mytaskList.taskList;
                this.myTaskDetailsCache = mytaskList.taskList;
                // this.myTaskDetails = this.myTaskDetails.concat(complianceTaskList);
                this.myTaskDetails = this.myTaskDetails.concat(compliancetaskArray);
                this.myTaskDetailsCache = this.myTaskDetailsCache.concat(compliancetaskArray);
                // this.myTaskDetailsCache = this.myTaskDetailsCache.concat(complianceTaskList);
              } else {
                this.myTaskDetails = [];
                this.myTaskDetailsCache = [];
                this.myTaskDetails = mytaskList.taskList;
                this.myTaskDetailsCache = mytaskList.taskList;
              }
            })
          }

        })
      }
    })
  }

  bulkUpdateModal($event) {
    const modalRef = this.modalService.open(BulkUpdateTaskComponent, {
      size: 'lg',
      windowClass: 'bulkUpdateModal',
      backdrop: 'static'
    })
    modalRef.result.then((responseOfModal) => {
      if (!responseOfModal.status) {
        const modalRefNotification = this.modalService.open(NotificationComponent)
        modalRefNotification.componentInstance.notify = { status: 'Success', message: 'Tasks Status are updated successfully.' }
        this.showMyTaskDetails();
        this.UpdatedStatus.emit(responseOfModal);
      } else {
        const modalRefNotification = this.modalService.open(NotificationComponent)
        modalRefNotification.componentInstance.notify = { status: 'Failure', message: responseOfModal.message }
      }
    }, (reason) => {
      //console.log(reason)
    })
  }

}
