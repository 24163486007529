<div class="modal-body">
  <div class="row" >
    <div class="table-responsive">
      <table class="table table-hover compact cell-border nowrap stripe">
        <thead>
          <tr>
            <th>Agenda Number</th>
            <th>Agenda Title</th>
            <th>Attachment</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngIf="isAgendaObjectEmpty">
                <td colspan="5" class="text-center">
                    No Records Found.
                </td>
            </tr>
           
          <tr *ngFor="let agenda of agendaObject">
            
            <td *ngIf="agenda.isPublished" >{{agenda.AgendaNumber}}</td>
            <td *ngIf="agenda.isPublished" ><a id="pdfFileAgendaLink"  (click)="downloadDocumentFile(agenda.AgendaConvertedDocxToPdf.DocumentManagementID,'pdf')">{{agenda.AgendaTitle}}</a></td>
            <td *ngIf="agenda.AgendaGenerationPdf.length >= 1 && agenda.isPublished">               
                <span  title="View Attachment" (click)="initiateUploadAgendaPdf(agenda)" *ngIf=" agenda.AgendaGenerationPdf.length > 0">
                    <i class="fa fa-paperclip"  title="View" style="font-size: 28px;font-weight: normal;cursor: pointer;"></i>
                </span>                
            </td>
            <!-- <td *ngIf=" agenda.AgendaGenerationPdf.length == 1 && agenda.isPublished" style="text-align: center">
               
                <button type="button" class="btn-sm btn btn-warning" (click)="downloadDocumentFile(agenda.AgendaGenerationPdf[0].DocumentManagementID,agenda.fileType[0])">
                    <span title="View" *ngIf="agenda.fileType[0] ==  'PDF' ||  agenda.fileType[0]   == 'pdf' " class="fa fa-paperclip"></span>
                    <span title="Download" *ngIf="agenda.fileType[0] != 'PDF' && agenda.fileType[0] != 'pdf' " class="fa fa-download"></span>
                </button>  
                
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


  <!-- AgendaCompliance POPUP LIST -->
  <div class="modal fade " id="agendasCompliancePopUp" style="padding:0;overflow-y:hidden" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document" style="margin: 0;">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="agendasCompliancePopUpLabel">
                     Agenda Compliances</h5>
                  <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <i class="fa fa-times"></i>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="datatable-card-block">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="margin-bottom-50">
                                  <div class="table-responsive">
                                      <table datatable [dtOptions]="dtOptionsOfAgendaCompliancePopUp" [dtTrigger]="dtTriggerAgendaCompliancePopUp" class="table table-hover compact cell-border nowrap stripe"
                                          id="agendaCompliancePopUpTable" style="cursor: pointer;width:100%">
                                          <thead>
                                              <tr>
                                                  <th>Task Name</th>
                                                  <th>Task Description</th>
                                                  <th>Global/Company Specific</th>
                                                  <th>Group Name</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr *ngFor="let agendaCompliance of agendaCompliances">
                                                  <td style="white-space: normal;">{{agendaCompliance.TaskName}}</td>
                                                  <td style="white-space: normal;">{{agendaCompliance.TaskDescription}}</td>
                                                  <td>
                                                      <span *ngIf="agendaCompliance.IsGlobal">Global</span>
                                                      <span *ngIf="!agendaCompliance.IsGlobal">Company Specific</span>
                                                  </td>
                                                  <td>{{agendaCompliance.GroupName}}</td>
                                              </tr>
                                          </tbody>
                                      </table>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="modal-footer">
                  <button type="button" class="btn btn-sm" data-dismiss="modal">
                      <i class="fa fa-times"></i>Close
                  </button>
              </div>
          </div>
      </div>
  </div>
  <div class="modal fade modal-size-medium" id="agendaPdfUploadDialog" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
                aria-labelledby="" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header modHeadCls">   
              <div id="AgendaNum" class="AgnCls"></div>   
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="width: 36px !important;line-height: 20px !important;">
                  <i class="fa fa-times"></i>
              </button>              
          </div>  
          <div class="modal-body">
              <div class="col-md-12">
                  <div class="row collapse1"> 
                     <div *ngFor="let agenda of AgendaFile; let count=index" class="AgnBlock">
                       <div style="float: left;font-weight:bold;width: 85% !important;" tabindex="1">
                        {{agenda.PdfName}}                        
                       </div>
                       <div style="float: right;padding-left: 10px;width: 15% !important;">
                        <button type="button" class="btn-sm btn btn-warning" data-dismiss="modal" (click)="downloadDocumentFile(agenda.DocumentManagementID,agenda.FileType)" style="margin-top:0px !important;">
                          <span title="View" *ngIf="agenda.FileType == 'PDF' || agenda.FileType == 'pdf' " class="fa fa-eye"></span>
                          <span title="Download" *ngIf="agenda.FileType != 'PDF' && agenda.FileType != 'pdf' " class="fa fa-download"></span>
                         </button>  
                       </div>                      
                     </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
