import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path : 'reports', component: ReportsComponent, outlet:'structure-outlet'}
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ReportsComponent
  ]
})
export class ReportsModule { }
