import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class AssessmentHistoryService {

    constructor(private http: HttpClient) {

    }

    //get list of assessment
    getListOfAssessment(): Promise<any> {
        return this.http.get(environment.clientUrl + 'assessment/historicAssessmentList')
            .toPromise()
            .then(res => { return  Promise.resolve(res); })
    }

    //get list of categories 
    getListOfAssessmentCategories(assessmentId): Promise<any> {

        return this.http.get(environment.clientUrl + 'assessment/AssessmentCategories/' + assessmentId)
            // .map(res => res);
            .toPromise()
            .then(res => { return  Promise.resolve(res); })
    }

    //get list of categories 
    getListOfAssessmentQuestionsByCategory(categoryId): Promise<any> {

        return this.http.get(environment.clientUrl + 'questionAssessment/assessmentQuestionsByCategory/' + categoryId)
            // .map(res => res);
            .toPromise()
            .then(res => { return  Promise.resolve(res); })
    }
}