import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonFunction } from '../../app/helpers/commonFunction';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'any',
})
export class DashboardService {

  constructor(private http: HttpClient, private httpWithoutToken: HttpClient, public commonFunction: CommonFunction) { }

  getComplianceTask(): Promise<any> {
    const url = environment.clientUrl + 'dashboard/complianceChart';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getRoadmapTask(): Promise<any> {
    const url = environment.clientUrl + 'dashboard/roadMapChart';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAllTaskList(): Promise<any> {
    const url = environment.clientUrl + 'dashboard/getAllTaskList';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getRoadMapTaskByUserId(userId): Promise<any> {
    const url =
      environment.clientUrl +
      'roadmap/tasksForAssignedUser?AssignedToId=' +
      userId;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getCompanyTradingWindowStatus(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'companyMaster/companyTradingWindowStatus')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getTaskDetailById(idObject): Promise<any> {
    const url =
      environment.clientUrl + 'dashboard/getTaskDetails/' + idObject._id;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  updateTask(statusObject): Promise<any> {
    const url = environment.clientUrl + 'dashboard/updateTask';
    return this.http
      .post(url, statusObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getTeamTaskByUserId(rangeOfDateAndUserId): Promise<any> {
    const url = environment.clientUrl + 'dashboard/myTeamTaskByUserId/';
    return this.http
      .post(url, rangeOfDateAndUserId)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getTaskListbydateRange(rangeOfDate): Promise<any> {
    const url = environment.clientUrl + 'dashboard/getTaskByDateRange';
    return this.http
      .post(url, rangeOfDate)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //localjson  -- Ishan
  //compliance
  // getActivatedTaskList(companyId): Promise<any> {

  //   //return this.http.get("/assets/localJson/activeTask.json")
  //   const url = environment.pssServerUrl + 'govEva/activatedTaskList/' + companyId
  //   return this.httpWithoutToken.get(url)
  //     .toPromise()
  //     .then(response => {
  //       return Promise.resolve(response)
  //     })
  //     .catch(this.handleError)
  // }

  getActivatedTaskList(object): Promise<any> {
    //return this.http.get("/assets/localJson/activeTask.json")
    const url = environment.pssServerUrl + 'govEva/activatedTaskList/';
    return this.httpWithoutToken
      .post(url, object)
      .toPromise()
      .then((response: any) => {
        return Promise.resolve({...response, taskList: JSON.parse(this.commonFunction.decrypt(response.taskList))})
      })
      .catch(this.handleError);
  }

  //returns compliance task list for logged in user. This list will show under My Task in Dashboard
  getMyActivatedTaskList(companyId, assignedTo): Promise<any> {
    const url =
      environment.pssServerUrl +
      'govEva/activatedTaskListByAssignedUser/' +
      companyId +
      '/' +
      assignedTo;
    return this.httpWithoutToken
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //compliance
  getActivatedTask(taskId): Promise<any> {
    const url = environment.pssServerUrl + 'govEva/activatedTaskData/' + taskId;
    return this.httpWithoutToken
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //compliance
  updateActivatedTask(statusObject, taskId): Promise<any> {
    const url =
      environment.pssServerUrl + 'govEva/updateActivatedTaskStatus/' + taskId;
    return this.httpWithoutToken
      .post(url, statusObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getStockPriceData(companyID): Promise<any> {
    return this.http
      .post(environment.clientUrl + 'dashboard/financialchart/stockPriceDetailsExternal',{
        "companyid": companyID
      })
      .toPromise()
      .then((response) => {
        // console.log('getStockPriceData', response)
        return Promise.resolve(response);
      });
  }

  get52WeekEquityData(): Promise<any> {
    return this.http
      .get(
        environment.clientUrl + 'dashboard/financialchart/companyEquityDetails'
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getAllTaskListOfRoadMap(): Promise<any> {
    const url = environment.clientUrl + 'dashboard/getAllTaskListOfRoadMap';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  // same api for roadmap Pop Up and Show details popup for roadmap and search filters
  getAllTaskListOfRoadMapForCountOrFullDetails(object): Promise<any> {
    const url =
      environment.clientUrl +
      'dashboard/getAllTaskListOfRoadMapForCountOrFullDetails';
    return this.http
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getMyTaskFilteredList(object): Promise<any> {
    const url = environment.clientUrl + 'dashboard/filterTasksForAssignedUser';
    return this.http
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getMyFilteredActivatedTaskList(object): Promise<any> {
    const url =
      environment.pssServerUrl + 'govEva/filterActivatedTaskListByAssignedUser';
    return this.httpWithoutToken
      .post(url, object)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAllTaskListOfCompliance(): Promise<any> {
    const url = environment.clientUrl + 'dashboard/getAllTaskListOfCompliance';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //get compliance team task
  getComplianceTeamTask(rangeOfDateAndUserId): Promise<any> {
    const url = environment.clientUrl + 'user/getComplianceTeamTask/';
    return this.http
      .post(url, rangeOfDateAndUserId)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  //get goveva process list
  getGovevaprocessList(): Promise<any> {
    // var token = localStorage.getItem('token');
    let header = {};
        let head = (key, value) => {header[key] = value};
    // head('x-access-token', token);
    head('Authorization', environment.Service.authorization);
    const url = environment.url + 'processMaster/processList';
    // return this.httpWithoutToken.get(url).toPromise().then(response => {
    //   return Promise.resolve(response)
    // }).catch(this.handleError)
    return this.http
      .get(url, { headers: header })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getActivatedProcessList(companyID): Promise<any> {
    const url =
      environment.pssServerUrl + 'govEva/getActivatedProcessList/' + companyID;
    return this.httpWithoutToken
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }
  // getStockPriceData(companyID): Observable<any> {
  //   const url =
  //     environment.DigiCompanyURL + '/stockpile/stockprice?cin=' + companyID;
  //   return this.http.get(url);
  // }
}
