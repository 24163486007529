import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';

declare var google:any;

@Injectable()
export class GoogleOrgChartService extends GoogleChartsBaseService {

    constructor(){
        super();
    }

    public BuildOrgChart(elementId:string, data:any[]):void{
        var chartFunc = () => {
            return new google.visualization.OrgChart(document.getElementById(elementId)); };
        
            var options = {
                allowHtml :true,
                color: 'white',
                tooltip: { isHtml: true }
            };
            this.buildChart(data , chartFunc , options);
    }
}