import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
declare var $: any;
declare var jQuery: any;
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-meetingdocumentview',
  templateUrl: './meetingdocument.component.html',
  styleUrls: ['./meetingdocument.component.css'],
  providers: [MeetingMasterService, CompanyMasterService]
})
export class MeetingdocumentViewComponent implements OnInit {

  @Input('documentObject') documentObject: any;
  @Input('meetingObjectView') meetingObject: any;

  notRegularMeeting: boolean = true;

  isDodumentObjectEmpty: boolean;
  atrDocument: any;
  isNoticeFileAvailable: boolean = false;
  noticeFileName: any;
  noticeFileType: any;
  agendaFileType: any;
  draftFileType: any;
  finalFiletype: any;
  ATRFileType: any;
  noticeDate: any;
  noticeTime: any;
  isAgendaFileAvailable: boolean = false;
  agendaFileName: any;
  agendaDate: any;
  agendaTime: any;
  isDraftFileAvailable: boolean = false;
  draftFileName: any;
  draftDate: any;
  draftTime: any;
  isFinalFileAvailable: boolean = false;
  finalFileName: any;
  finalDate: any;
  finalTime: any;
  isATRFileAvailable: boolean = false;
  ATRFileName: any;
  regularDocumentList: any;
  iframeURL: any
  constructor(private router: Router,private companyMasterService: CompanyMasterService, private meetingMasterService: MeetingMasterService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    var self = this;
    //console.log("meeting Object DOcument", this.meetingObject, this.documentObject);

    // //console.log("documentObject",this.documentObject);

    if (this.meetingObject.meetingType) {
      this.notRegularMeeting = false;
      this.regularDocumentList = this.documentObject;

      if (_.size(this.documentObject)) {
        self.isDodumentObjectEmpty = false;
      }
      else {
        self.isDodumentObjectEmpty = true;
      }

    } else {
      this.notRegularMeeting = true;

      this.atrDocument = this.meetingObject.AtrDocument[0];
      // //console.log("atrDocument",this.atrDocument);

      if (_.size(this.documentObject)) {
        self.isDodumentObjectEmpty = false;

        if (_.size(this.atrDocument)) {

          if (_.size(this.atrDocument.noticeDate)) {
            let date = this.atrDocument.noticeDate;
            date = date.split('-')
            date = date[1]+'-'+date[0]+'-'+date[2]
            this.noticeDate = date;
            this.noticeTime = this.atrDocument.noticeTime;
          } else {
            this.noticeDate = "";
            this.noticeTime = "";
          }

          if (_.size(this.atrDocument.agendaDate)) {
            let date = this.atrDocument.agendaDate;
            date = date.split('-')
            date = date[1]+'-'+date[0]+'-'+date[2]
            this.agendaDate = date
            this.agendaTime = this.atrDocument.agendaTime;
          } else {
            this.agendaDate = "";
            this.agendaTime = "";
          }

          if (_.size(this.atrDocument.draftDate)) {
            let date = this.atrDocument.draftDate
            date = date.split('-')
            date = date[1]+'-'+date[0]+'-'+date[2]
            this.draftDate = date;
            this.draftTime = this.atrDocument.draftTime;
          } else {
            this.draftDate = "";
            this.draftTime = "";
          }

          if (_.size(this.atrDocument.finalDate)) {
            let date = this.atrDocument.finalDate;
            date = date.split('-')
            date = date[1]+'-'+date[0]+'-'+date[2]
            this.finalDate = date
            this.finalTime = this.atrDocument.finalTime;
          } else {
            this.finalDate = "";
            this.finalTime = "";
          }

        } else {
          this.noticeDate = "";
          this.noticeTime = "";
          this.agendaDate = "";
          this.agendaTime = "";
          this.draftDate = "";
          this.draftTime = "";
          this.finalDate = "";
          this.finalTime = "";
        }

        if (_.size(this.documentObject.noticeFile)) {
          if (this.documentObject.noticeFile.isAvaible) {
            this.isNoticeFileAvailable = true;
            this.noticeFileName = this.documentObject.noticeFile.name
            this.noticeFileType = (this.noticeFileName).substr(-3)
          }
          else {
            this.noticeFileName = "-";
            this.isNoticeFileAvailable = false;
          }

        } else {
          this.noticeFileName = "-";
        }

        if (_.size(this.documentObject.agendaFile)) {
          if (this.documentObject.agendaFile.isAvaible) {
            this.isAgendaFileAvailable = true;
            this.agendaFileName = this.documentObject.agendaFile.name
            this.agendaFileType = (this.agendaFileName).substr(-3)

          }
          else {
            this.isAgendaFileAvailable = false;
            this.agendaFileName = "-";
          }

        } else {
          this.agendaFileName = "-";
        }

        if (_.size(this.documentObject.draftFile)) {
          if (this.documentObject.draftFile.isAvaible) {
            this.isDraftFileAvailable = true;
            this.draftFileName = this.documentObject.draftFile.name
            this.draftFileType = (this.draftFileName).substr(-3)


          }
          else {
            this.isDraftFileAvailable = false;
            this.draftFileName = "-";
          }

        } else {
          this.draftFileName = "-";
        }

        if (_.size(this.documentObject.finalFile)) {
          if (this.documentObject.finalFile.isAvaible) {
            this.isFinalFileAvailable = true;
            this.finalFileName = this.documentObject.finalFile.name

            this.finalFiletype = (this.finalFileName).substr(-3)


          }
          else {
            this.isFinalFileAvailable = false;
            this.finalFileName = "-";
          }

        } else {
          this.finalFileName = "-";
        }

        if (_.size(this.documentObject.ATRFile)) {
          if (this.documentObject.ATRFile.isAvaible) {
            this.isATRFileAvailable = true;
            this.ATRFileName = this.documentObject.ATRFile.name;
            this.ATRFileType = (this.ATRFileName).substr(-3)

          } else {
            this.isATRFileAvailable = false;
            this.ATRFileName = "-";
          }
        } else {
          this.ATRFileName = "-";
        }



      } else {
        self.isDodumentObjectEmpty = true;
      }

    }





  }

  downloadDocumentFile(fileID, fileType) {

    console.log("fileTypefileType",fileType)
    if (fileType == 'PDF' || fileType == 'pdf') {
      $('#myModalForDocument').data('modal', null);
      console.log('MeetingdocumentComponent view')
      this.meetingMasterService.getAttendanceFileName(fileID).then(response => {
        var url  =   environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0" ;
        
      //  url = encodeURIComponent(url)
      //  var newurl = "/#/pdfviewer/" + url +'/' + this.meetingObject._id + '/'+ fileID +'/'+ btoa(response.data.OriginalFileName)
      //  window.open(newurl,'_blank')
        // console.log('response filename ',  response.data.Filename  )
        // var fileUrl  = "https://docs.google.com/gview?url="+ environment.PSSFileurl +"/Document_Type/"+ response.data.Filename;
      //  this.router.navigate(["pdfviewer", url, this.meetingObject._id, fileID, btoa(response.data.OriginalFileName)])
      window.open(url, '_blank');
      //  window.open(environment.UIBaseUrl + '/#/pdfviewer/'+url + '/'+this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName), '_blank');
        // this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0");
        // // this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl();
        // $("#myModalForDocument").modal('show')
      })
    } else {

      this.meetingMasterService.getAttendanceFile(fileID);

    }
  }

  downloadRegularDocumentFile(filepath, filename) {

    var gemURL = ""
    this.companyMasterService.getGEMUrl().then(responseCompany => {
      //console.log("response GEM URL", responseCompany);
      if (responseCompany.status == 0) {
        var companyDetails = responseCompany.companyData;
        //console.log("response GEM URL companyDetails", companyDetails);
        if (_.size(companyDetails)) {
          gemURL = companyDetails[0].GEMAccessURL ? companyDetails[0].GEMAccessURL : "";
          //console.log("gemURL",gemURL);

          this.meetingMasterService.getRegularFile(filepath, this.meetingObject.meetingId, filename, gemURL).then(response => {

          })

        }
      }
    })

    // this.meetingMasterService.getRegularFile(filepath, this.meetingObject.meetingId,filename).then(response => {



    var gemURL = ""
    this.companyMasterService.getGEMUrl().then(responseCompany => {
      //console.log("response GEM URL", responseCompany);
      if (responseCompany.status == 0) {
        var companyDetails = responseCompany.companyData;
        if (_.size(companyDetails)) {
          gemURL = companyDetails.GEMAccessURL ? companyDetails.GEMAccessURL : ""
          if (_.size(gemURL)) {

            this.meetingMasterService.getRegularFile(filepath, this.meetingObject.meetingId, filename, gemURL).then(response => {

              // //console.log("response", response.size);

              // var byteArray = new Uint8Array(response.size)
              // //console.log("byteArray", byteArray);

              // var downloadUrl = URL.createObjectURL(response);

              // //console.log("downloadUrl", downloadUrl);

              // var link = document.createElement('a');
              // document.body.appendChild(link);
              // link.href = downloadUrl;
              // link.download = filename;
              // link.click();

            })

          }
        }
      }
    })





    // var link = document.createElement('a');
    // document.body.appendChild(link);
    // link.href = downloadUrl;
    // link.download = filename;
    // link.click();

    // })

  }

}
