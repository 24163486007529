<!-- START: components/tabs -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Tabs</strong>
            <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Horizontal Tabs</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <p class="text-muted">Outer element: <code>.nav-tabs-horizontal</code></p>
                <div class="mb-5">
                    <div class="nav-tabs-horizontal">
                        <ul class="nav nav-tabs mb-4" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#home1" role="tab">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#profile1" role="tab">Profile</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#messages1" role="tab">Messages</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#settings1" role="tab">Settings</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="home1" role="tabcard">
                                Mel, incorruptis confidam derepta inportuno perpetuam placatae. Expetenda summam
                                venandi cotidie euripidis vexetur, scribendi recta fortunam
                                hanc oblivione. Iniuria ipso statuam utrumque asperiores
                                eae cogitemus, evertitur triarium perspexit conclusionemque
                                propterea repellendus agatur scilicet, leniter intervalla
                                nocet praeterierunt tuum privatione, senserit sale vias,
                                delectatum dedecora ratione o religionis derigatur diuturnum
                                arbitrantur conspiratione, legam opera splendore iste democritum
                                apte romanum legum egregios.
                            </div>
                            <div class="tab-pane" id="profile1" role="tabcard">
                                Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                invidiae ferre constituto gaudeat contentam, omnium nescius,
                                consistat interesse animi, amet fuisset numen graecos incidunt
                                euripidis praesens, homines religionis dirigentur postulant.
                                Magnum utrumvis gravitate appareat fabulae facio perveniri
                                fruenda indicaverunt texit, frequenter probet diligenter
                                sententia meam distinctio theseo legerint corporis quoquo,
                                optari futurove expedita.
                            </div>
                            <div class="tab-pane" id="messages1" role="tabcard">
                                Faciendi denique miserius iudico, significet ingenii adduci angere efficit linguam.
                                Delectatum copulatas solemus tenere legam ignaviamque diis
                                peccant ornamenta videro, adquiescere bonorum quisquam ceramico
                                minime quodsi corporisque. Nostri pauca gravioribus reici
                                solvantur, angoribus naturalem sed epicurei occultum sola
                                institutionem meminerunt quem fonte, confirmat. Cui eodem
                                perspecta vim solis innumerabiles, videatur status qui quanto
                                sapientiam nomini honestum, comit sumitur nati via.
                            </div>
                            <div class="tab-pane" id="settings1" role="tabcard">
                                Dicent feramus necesse proficiscuntur libidinem quisquis, petulantes divitias compositis,
                                disseretur voluptates crudeli sustulisti. Hostis res utuntur
                                bono incurrunt navigandi laboribus istae tali, miserum metuamus
                                labor quasi synephebos iudicante. Effecerit sicine falsarum
                                pugnantibus imperitos, vero successionem exhorrescere illis
                                magnopere deteriora maioribus necessariam industria. Illi
                                variari stabilique augendas suscipiet, corrigere conducunt,
                                divinum affecti, eruditus clarorum. Spatio gustare cupiditates
                                desideret aliena sinat utrumque.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Horizontal Tabs w/ Icons</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <p class="text-muted">Outer element: <code>.nav-tabs-horizontal</code></p>
                <div class="mb-5">
                    <div class="nav-tabs-horizontal">
                        <ul class="nav nav-tabs mb-4" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#home2" role="tab">
                                    <i class="icmn-home"></i>
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#profile2" role="tab">
                                    <i class="icmn-users"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#messages2" role="tab">
                                    <i class="icmn-database"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#settings2" role="tab">
                                    <i class="icmn-cog"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="home2" role="tabcard">
                                Mel, incorruptis confidam derepta inportuno perpetuam placatae. Expetenda summam
                                venandi cotidie euripidis vexetur, scribendi recta fortunam
                                hanc oblivione. Iniuria ipso statuam utrumque asperiores
                                eae cogitemus, evertitur triarium perspexit conclusionemque
                                propterea repellendus agatur scilicet, leniter intervalla
                                nocet praeterierunt tuum privatione, senserit sale vias,
                                delectatum dedecora ratione o religionis derigatur diuturnum
                                arbitrantur conspiratione, legam opera splendore iste democritum
                                apte romanum legum egregios.
                            </div>
                            <div class="tab-pane" id="profile2" role="tabcard">
                                Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                invidiae ferre constituto gaudeat contentam, omnium nescius,
                                consistat interesse animi, amet fuisset numen graecos incidunt
                                euripidis praesens, homines religionis dirigentur postulant.
                                Magnum utrumvis gravitate appareat fabulae facio perveniri
                                fruenda indicaverunt texit, frequenter probet diligenter
                                sententia meam distinctio theseo legerint corporis quoquo,
                                optari futurove expedita.
                            </div>
                            <div class="tab-pane" id="messages2" role="tabcard">
                                Faciendi denique miserius iudico, significet ingenii adduci angere efficit linguam.
                                Delectatum copulatas solemus tenere legam ignaviamque diis
                                peccant ornamenta videro, adquiescere bonorum quisquam ceramico
                                minime quodsi corporisque. Nostri pauca gravioribus reici
                                solvantur, angoribus naturalem sed epicurei occultum sola
                                institutionem meminerunt quem fonte, confirmat. Cui eodem
                                perspecta vim solis innumerabiles, videatur status qui quanto
                                sapientiam nomini honestum, comit sumitur nati via.
                            </div>
                            <div class="tab-pane" id="settings2" role="tabcard">
                                Dicent feramus necesse proficiscuntur libidinem quisquis, petulantes divitias compositis,
                                disseretur voluptates crudeli sustulisti. Hostis res utuntur
                                bono incurrunt navigandi laboribus istae tali, miserum metuamus
                                labor quasi synephebos iudicante. Effecerit sicine falsarum
                                pugnantibus imperitos, vero successionem exhorrescere illis
                                magnopere deteriora maioribus necessariam industria. Illi
                                variari stabilique augendas suscipiet, corrigere conducunt,
                                divinum affecti, eruditus clarorum. Spatio gustare cupiditates
                                desideret aliena sinat utrumque.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Vertical Tabs</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <p class="text-muted">Outer element: <code>.nav-tabs-vertical</code></p>
                <div class="mb-5">
                    <div class="nav-tabs-vertical">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#home3" role="tab">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#profile3" role="tab">Profile</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#messages3" role="tab">Messages</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#settings3" role="tab">Settings</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="home3" role="tabcard">
                                Mel, incorruptis confidam derepta inportuno perpetuam placatae. Expetenda summam
                                venandi cotidie euripidis vexetur, scribendi recta fortunam
                                hanc oblivione. Iniuria ipso statuam utrumque asperiores
                                eae cogitemus, evertitur triarium perspexit conclusionemque
                                propterea repellendus agatur scilicet, leniter intervalla
                                nocet praeterierunt tuum privatione, senserit sale vias,
                                delectatum dedecora ratione o religionis derigatur diuturnum
                                arbitrantur conspiratione, legam opera splendore iste democritum
                                apte romanum legum egregios.
                            </div>
                            <div class="tab-pane" id="profile3" role="tabcard">
                                Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                invidiae ferre constituto gaudeat contentam, omnium nescius,
                                consistat interesse animi, amet fuisset numen graecos incidunt
                                euripidis praesens, homines religionis dirigentur postulant.
                                Magnum utrumvis gravitate appareat fabulae facio perveniri
                                fruenda indicaverunt texit, frequenter probet diligenter
                                sententia meam distinctio theseo legerint corporis quoquo,
                                optari futurove expedita.
                            </div>
                            <div class="tab-pane" id="messages3" role="tabcard">
                                Faciendi denique miserius iudico, significet ingenii adduci angere efficit linguam.
                                Delectatum copulatas solemus tenere legam ignaviamque diis
                                peccant ornamenta videro, adquiescere bonorum quisquam ceramico
                                minime quodsi corporisque. Nostri pauca gravioribus reici
                                solvantur, angoribus naturalem sed epicurei occultum sola
                                institutionem meminerunt quem fonte, confirmat. Cui eodem
                                perspecta vim solis innumerabiles, videatur status qui quanto
                                sapientiam nomini honestum, comit sumitur nati via.
                            </div>
                            <div class="tab-pane" id="settings3" role="tabcard">
                                Dicent feramus necesse proficiscuntur libidinem quisquis, petulantes divitias compositis,
                                disseretur voluptates crudeli sustulisti. Hostis res utuntur
                                bono incurrunt navigandi laboribus istae tali, miserum metuamus
                                labor quasi synephebos iudicante. Effecerit sicine falsarum
                                pugnantibus imperitos, vero successionem exhorrescere illis
                                magnopere deteriora maioribus necessariam industria. Illi
                                variari stabilique augendas suscipiet, corrigere conducunt,
                                divinum affecti, eruditus clarorum. Spatio gustare cupiditates
                                desideret aliena sinat utrumque.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Vertical Tabs w/ Icons</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <p class="text-muted">Outer element: <code>.nav-tabs-vertical</code></p>
                <div class="mb-5">
                    <div class="nav-tabs-vertical">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#home4" role="tab">
                                    <i class="icmn-home"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#profile4" role="tab">
                                    <i class="icmn-users"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#messages4" role="tab">
                                    <i class="icmn-database"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#settings4" role="tab">
                                    <i class="icmn-cog"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="home4" role="tabcard">
                                Mel, incorruptis confidam derepta inportuno perpetuam placatae. Expetenda summam
                                venandi cotidie euripidis vexetur, scribendi recta fortunam
                                hanc oblivione. Iniuria ipso statuam utrumque asperiores
                                eae cogitemus, evertitur triarium perspexit conclusionemque
                                propterea repellendus agatur scilicet, leniter intervalla
                                nocet praeterierunt tuum privatione, senserit sale vias,
                                delectatum dedecora ratione o religionis derigatur diuturnum
                                arbitrantur conspiratione, legam opera splendore iste democritum
                                apte romanum legum egregios.
                            </div>
                            <div class="tab-pane" id="profile4" role="tabcard">
                                Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                invidiae ferre constituto gaudeat contentam, omnium nescius,
                                consistat interesse animi, amet fuisset numen graecos incidunt
                                euripidis praesens, homines religionis dirigentur postulant.
                                Magnum utrumvis gravitate appareat fabulae facio perveniri
                                fruenda indicaverunt texit, frequenter probet diligenter
                                sententia meam distinctio theseo legerint corporis quoquo,
                                optari futurove expedita.
                            </div>
                            <div class="tab-pane" id="messages4" role="tabcard">
                                Faciendi denique miserius iudico, significet ingenii adduci angere efficit linguam.
                                Delectatum copulatas solemus tenere legam ignaviamque diis
                                peccant ornamenta videro, adquiescere bonorum quisquam ceramico
                                minime quodsi corporisque. Nostri pauca gravioribus reici
                                solvantur, angoribus naturalem sed epicurei occultum sola
                                institutionem meminerunt quem fonte, confirmat. Cui eodem
                                perspecta vim solis innumerabiles, videatur status qui quanto
                                sapientiam nomini honestum, comit sumitur nati via.
                            </div>
                            <div class="tab-pane" id="settings4" role="tabcard">
                                Dicent feramus necesse proficiscuntur libidinem quisquis, petulantes divitias compositis,
                                disseretur voluptates crudeli sustulisti. Hostis res utuntur
                                bono incurrunt navigandi laboribus istae tali, miserum metuamus
                                labor quasi synephebos iudicante. Effecerit sicine falsarum
                                pugnantibus imperitos, vero successionem exhorrescere illis
                                magnopere deteriora maioribus necessariam industria. Illi
                                variari stabilique augendas suscipiet, corrigere conducunt,
                                divinum affecti, eruditus clarorum. Spatio gustare cupiditates
                                desideret aliena sinat utrumque.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Default Tabs</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/navs/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div>
                        <ul class="nav nav-tabs mb-4" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#home5" role="tab">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#profile5" role="tab">Profile</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#messages5" role="tab">Messages</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#settings5" role="tab">Settings</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="home5" role="tabcard">
                                Mel, incorruptis confidam derepta inportuno perpetuam placatae. Expetenda summam
                                venandi cotidie euripidis vexetur, scribendi recta fortunam
                                hanc oblivione. Iniuria ipso statuam utrumque asperiores
                                eae cogitemus, evertitur triarium perspexit conclusionemque
                                propterea repellendus agatur scilicet, leniter intervalla
                                nocet praeterierunt tuum privatione, senserit sale vias,
                                delectatum dedecora ratione o religionis derigatur diuturnum
                                arbitrantur conspiratione, legam opera splendore iste democritum
                                apte romanum legum egregios.
                            </div>
                            <div class="tab-pane" id="profile5" role="tabcard">
                                Mnesarchum velit cumanum utuntur tantam deterritum, democritum vulgo contumeliae
                                abest studuisse quanta telos. Inmensae. Arbitratu dixisset
                                invidiae ferre constituto gaudeat contentam, omnium nescius,
                                consistat interesse animi, amet fuisset numen graecos incidunt
                                euripidis praesens, homines religionis dirigentur postulant.
                                Magnum utrumvis gravitate appareat fabulae facio perveniri
                                fruenda indicaverunt texit, frequenter probet diligenter
                                sententia meam distinctio theseo legerint corporis quoquo,
                                optari futurove expedita.
                            </div>
                            <div class="tab-pane" id="messages5" role="tabcard">
                                Faciendi denique miserius iudico, significet ingenii adduci angere efficit linguam.
                                Delectatum copulatas solemus tenere legam ignaviamque diis
                                peccant ornamenta videro, adquiescere bonorum quisquam ceramico
                                minime quodsi corporisque. Nostri pauca gravioribus reici
                                solvantur, angoribus naturalem sed epicurei occultum sola
                                institutionem meminerunt quem fonte, confirmat. Cui eodem
                                perspecta vim solis innumerabiles, videatur status qui quanto
                                sapientiam nomini honestum, comit sumitur nati via.
                            </div>
                            <div class="tab-pane" id="settings5" role="tabcard">
                                Dicent feramus necesse proficiscuntur libidinem quisquis, petulantes divitias compositis,
                                disseretur voluptates crudeli sustulisti. Hostis res utuntur
                                bono incurrunt navigandi laboribus istae tali, miserum metuamus
                                labor quasi synephebos iudicante. Effecerit sicine falsarum
                                pugnantibus imperitos, vero successionem exhorrescere illis
                                magnopere deteriora maioribus necessariam industria. Illi
                                variari stabilique augendas suscipiet, corrigere conducunt,
                                divinum affecti, eruditus clarorum. Spatio gustare cupiditates
                                desideret aliena sinat utrumque.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/tabs -->