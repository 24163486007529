import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-meetinginvitee',
  templateUrl: './meetinginvitee.component.html',
  styleUrls: ['./meetinginvitee.component.css']
})
export class MeetinginviteeComponent implements OnInit {

  @Input('inviteeObject') inviteeObject: any;
  isInviteeObjectEmpty:boolean;
  constructor() { }

  ngOnInit() {

    if(_.size(this.inviteeObject)){
      this.isInviteeObjectEmpty = false;
    }else{
      this.isInviteeObjectEmpty = true;
    }

  }

}
