<div class="form-group row">
    
    <ng-container *ngIf="!forIDP && question.EvidenceDocuments && question.EvidenceDocuments.length>0 && !IDPTableData; else IDPTableView">
        <label class="col-md-2 col-form-label">Evidence Attachments</label>
        <div class="btn btn-info btn-sm download-button" *ngFor="let document of question.EvidenceDocuments" >
            <div class="row">
                <div class="col-8" (click)="downloadFile($event, document)">{{document.OriginalFileName}}</div>
                <div class="col-2"></div>
                <div class="col-2"><i class="fa fa-times" (click)="removeSelectedFile($event, document)"></i></div>
            </div>
        </div>
    </ng-container>

    <ng-template #IDPTableView>
        <ng-container *ngIf="IDPTableData; else IDPlockedview">
            <label *ngIf="IDPTableData.EvidenceDocuments; else noUploads" class="col-md-10 col-form-label">Evidence Attachments</label>
            <div class="btn btn-info btn-sm download-button" *ngFor="let document of IDPTableData.EvidenceDocuments" >
                <div class="row">
                    <div class="col-8" (click)="downloadFile($event, document)">{{document.OriginalFileName}}</div>
                    <div class="col-2"></div>
                    <div class="col-2"><i class="fa fa-check-square-o" (click)="copyEvidenceToQuestion($event, document)"></i></div>
                </div>
            </div>
        </ng-container>
    </ng-template>


    <ng-template #IDPlockedview>
        <ng-container *ngIf="forIDP && currentIDPUserInfo.EvidenceDocuments && currentIDPUserInfo.EvidenceDocuments.length>0 && questionLocked; else IDPunlockedview">
            <label class="col-md-2 col-form-label">Evidence Attachments</label>
            <div class="btn btn-info btn-sm download-button" *ngFor="let document of currentIDPUserInfo.EvidenceDocuments" >
                <div class="row">
                    <div class="col-8" (click)="downloadFile($event, document)">{{document.OriginalFileName}}</div>
                    <div class="col-2"></div>
                    <div class="col-2"></div>
                </div>
            </div>
        </ng-container>
    </ng-template>
    
    <ng-template #IDPunlockedview>
        <ng-container *ngIf="forIDP && currentIDPUserInfo.EvidenceDocuments && currentIDPUserInfo.EvidenceDocuments.length>0; else noUploads">
            <label class="col-md-2 col-form-label">Evidence Attachments</label>
            <div class="btn btn-info btn-sm download-button" *ngFor="let document of currentIDPUserInfo.EvidenceDocuments" >
                <div class="row">
                    <div class="col-8" (click)="downloadFile($event, document)">{{document.OriginalFileName}}</div>
                    <div class="col-2"></div>
                    <div class="col-2"><i class="fa fa-times" (click)="removeSelectedFile($event, document)"></i></div>
                </div>
            </div>
        </ng-container>
    </ng-template>



    <ng-template #noUploads>
        <div>
            <p>No attachments uploaded yet.</p>
        </div>
    </ng-template>
</div>

<div *ngIf="(!questionLocked || !forIDP) &&  !forIDPTable" class="form-group row">
    <label class="col-md-2 col-form-label">Upload Evidence</label>
    <div class="fileUpload btn btn-success btn-sm col-md-1">
        <div>Upload</div>
        <input type="file" multiple class="upload" (change)="onFileSelected($event)" />
    </div>
</div>
