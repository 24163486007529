<section class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-6">
        <span class="cat__core__title">Company Master</span>
      </div>
      <div class="col-lg-6 text-right mt-1 trade-window">
        <label class="form-control-label mx-2">
          TRADING WINDOW:
        </label>

        <div class="btn-group" data-toggle="buttons" *ngIf="toDisableTradingWindowlabel">
          <label id="tradingWindowOpenID" class="btn btn-sm" (click)="toggleTradingWindow('On')">
            <input name="tadingWindowToggle" type="radio" value="Open" [checked]="tradingWindowValue == 'On'">
            On
          </label>
          <label id="tradingWindowClosedID" class="btn btn-sm" (click)="toggleTradingWindow('Off')">
            <input name="tadingWindowToggle" type="radio" value="Closed" [checked]="tradingWindowValue == 'Off'">
            Off
          </label>
        </div>

        <div class="btn-group" *ngIf="!toDisableTradingWindowlabel">
          <label id="tradingWindowOpenID" class="btn btn-sm">
            <span>On</span>
          </label>
          <label id="tradingWindowClosedID" class="btn btn-sm">
            <span>Off</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="companyMasterForm" class="well">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 col-sm-6">
                <label class="form-control-label">Company Logo</label>
                <div>
                  <span *ngIf="!IsUpdate" class="mb-4 d-block">
                    <a class="img-circle img-responsive">
                      <img style="width: 100%; height: 100px;" id="companyLogoDisplay" alt="" class="img-circle img-responsive"
                        [src]="base64Code.image" alt="Company Logo" title="Change Company Logo">
                    </a>
                  </span>
                  <span *ngIf="IsUpdate" class="mb-4 d-block">
                    <a class="img-circle img-responsive" id="companyLogoImg" (click)="showUploadImagePopUp()">
                      <img id="companyLogo" alt="" class="img-circle img-responsive"
                        [src]="base64Code.image" alt="Company Logo" title="Change Company Logo">
                      <span>change</span>
                    </a>
                    <a class="remove" (click)="removeLogoImage()">
                      <span style="cursor: pointer;">remove</span>
                    </a>
                  </span>
                </div>
              </div>
              <div class="col-md-8 col-sm-6 text-right btns">
                <button [appHasPermission]="{'Url': 'companyMaster/addNewCompany', 'method':'get', 'menu': 'CompanyMaster'}"
                type="button" class="btn btn-sm btn-primary" (click)="redirectToCompanySetup()"><i class="fa fa-plus mr-2" aria-hidden="true"></i>Add New Company</button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label" for="l0">Compliance Engine</label>
                <div class="form-group">
                  <input class="form-control" placeholder="complianceEngine" name="PSS" [formControl]="companyMasterForm.controls['complianceEngine']">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label" for="l0">API URL
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" placeholder="URL" name="url" [formControl]="companyMasterForm.controls['complianceEngineURL']">
                  <div *ngIf="companyMasterForm.controls['complianceEngineURL'].hasError('required') && companyMasterForm.controls['complianceEngineURL'].touched"
                    class="form-control-error-custom">
                    Compliance URL is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <label class="form-control-label">Chat Engine Base URL
                </label>
                <div class="form-group">
                  <input class="form-control" type="url" placeholder="URL" name="url" [formControl]="companyMasterForm.controls['chatEngineURL']">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label" for="l0">Company Name <span _ngcontent-c2="" style="color: red;">*</span>
                  <span style="font-style: italic;font-size: 12px;">As registered with ROC</span>
                </label>
                <div class="form-group">
                  <input class="form-control" placeholder="Company Name" name="companyName" [formControl]="companyMasterForm.controls['companyName']">
                  <div *ngIf="companyMasterForm.controls['companyName'].hasError('required') && companyMasterForm.controls['companyName'].touched"
                    class="form-control-error-custom">
                    Company name is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['companyName'].hasError('maxlength') && companyMasterForm.controls['companyName'].touched"
                    class="form-control-error-custom">
                    Company name should be maximum 100 character.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 mt-4">
                <label class="form-control-label">Is GovEVA Boards Subscribed
                </label>
              </div>
              <div class="col-md-4 mt-4">
                <div class="form-group">
                  <label class="container">
                    <input type="checkbox" [checked]="isGEMSubscribedFlag" [formControl]="companyMasterForm.controls['isGEMSubscribedFlag']"
                      (change)="onGEMSubscriptionChange($event)">
                      <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-6" *ngIf="isGEMSubscribedFlag">
                <label class="form-control-label">GovEVA Boards API URL
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="url" placeholder="URL" name="url" [formControl]="companyMasterForm.controls['GEMAccessURL']">
                  <div *ngIf="companyMasterForm.controls['GEMAccessURL'].hasError('required') && companyMasterForm.controls['GEMAccessURL'].touched"
                    class="form-control-error-custom">
                    GovEVA Boards URL is required.
                  </div>
                </div>
              </div>
            </div>

             <!-- START AD -->
            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label">Is AD Subscribed
                </label>
              </div>
              <div class="col-lg-10">
                <div class="form-group">
                  <label class="container">
                    <input type="checkbox" [checked]="isADSubscribedFlag" [formControl]="companyMasterForm.controls['isADSubscribedFlag']"
                      (change)="onADSubscriptionChange($event)">
                      <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
  
  
            <div class="row"  *ngFor="let server of adServerArray; let count = index; let last=last; let first= first">
  
              <div class="col-lg-12 pb-2">
                <i class="fa fa-close btn btn-sm btn-light" style="float: right!important;" (click)="removeADServerConfirguration(count)" *ngIf="!first && last && editBtn"></i>
              </div> 
              <div class="col-lg-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD URL
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="url" placeholder="Url" [name]="'ADUrl'+ count" [formControl]="companyMasterForm.controls['ADUrl'+count]"
                    required>
                  <div *ngIf="companyMasterForm.controls['ADUrl'+count].hasError('required') && companyMasterForm.controls['ADUrl'+count].touched"
                    class="form-control-error-custom">
                    AD Url is required.
                  </div>
                </div>
              </div>
              <div class="col-lg-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD Administrator Email ID
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="email" placeholder="Email ID" [name]="'ADEmailID' + count" [formControl]="companyMasterForm.controls['ADEmailID' + count]"
                    required>
                  <div *ngIf="companyMasterForm.controls['ADEmailID' + count].hasError('required') && companyMasterForm.controls['ADEmailID' + count].touched"
                    class="form-control-error-custom">
                    AD Administrator email is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['ADEmailID' + count].hasError('email') && companyMasterForm.controls['ADEmailID' + count].touched && !companyMasterForm.controls['ADEmailID' + count].hasError('required')"
                    class="form-control-error-custom">
                    Please enter valid email Id.
                  </div>
                </div>
              </div>
              <div class="col-lg-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD Administrator Password:
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="password" placeholder="AD Password" [name]="'ADPassword' + count" [formControl]="companyMasterForm.controls['ADPassword' + count]"
                    required>
                  <div *ngIf="companyMasterForm.controls['ADPassword' + count].hasError('required') && companyMasterForm.controls['ADPassword' + count].touched"
                    class="form-control-error-custom">
                    AD Administrator password is required.
                  </div>
                </div>
              </div>
              <div class="col-lg-6 btns" *ngIf="isADSubscribedFlag && last && editBtn" >
                <button class="btn btn-sm btn-primary" style="float:right;position: absolute;right: 5px;bottom: 15px;padding: 10px 20px;"  
                (click)="addAdServerControls()">Add More Server</button>
              </div>
  
            </div>
            <!-- END AD -->

            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label" for="l0">Date of Incorporation</label>
                <div class="form-group">
                  <div class="input-group" (click)="$event.stopPropagation()">
                    <input class="form-control" [attr.readonly]="true" [minDate]="minDate" [maxDate]="maxDate"
                      [formControl]="companyMasterForm.controls['dateOfIncorporation']" [(ngModel)]="dateOfIncorporationModel"
                      style="z-index: 0;" placeholder="dd-mm-yyyy" name="dpIncorporation"
                      ngbDatepicker #a="ngbDatepicker" (click)="a.toggle();s.close();d.close();" (document:click)="a.close();">
                    <button class="input-group-addon" (click)="clearDateOfIncoporation()" type="button" [disabled]="editCompanyData">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" (click)="a.toggle();s.close();d.close();" type="button"
                      [disabled]="editCompanyData">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Type of Company</label>
                <div class="form-group">
                  <select class="form-control" [formControl]="companyMasterForm.controls['typeOfCompany']" (change)="ListedConditionCheck(companyMasterForm.controls['typeOfCompany'].value)"
                    style="padding:8px 20px;">
                    <option value="">Select</option>
                    <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">{{typeOfCompany}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Category of Company </label>
                <div class="form-group">
                  <select class="form-control" [formControl]="companyMasterForm.controls['categoryOfCompany']" style="padding:8px 20px;">
                    <option value="">Select</option>
                    <option *ngFor="let categoryOfCompany of categoriesOfCompany" value="{{categoryOfCompany}}">{{categoryOfCompany}}</option>
                  </select>
                </div>
                <label class="form-control-label">ISIN</label>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="ISIN" name="isin" [formControl]="companyMasterForm.controls['ISIN']"
                    maxlength="12">
                  <div *ngIf="companyMasterForm.controls['ISIN'].hasError('minlength') && companyMasterForm.controls['ISIN'].touched"
                    class="form-control-error-custom">
                    The ISIN field must accept only 12 digits.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Registered Office Address</label>
                <div class="form-group">
                  <textarea [formControl]="companyMasterForm.controls['registeredOfficeAddress']" class="form-control"
                    rows="3" style="min-height: 130px;"></textarea>

                  <div *ngIf="companyMasterForm.controls['registeredOfficeAddress'].hasError('minlength') && companyMasterForm.controls['registeredOfficeAddress'].touched"
                    class="form-control-error-custom">
                    Registered Office Address should be minimum 10 character.
                  </div>
                  <div *ngIf="companyMasterForm.controls['registeredOfficeAddress'].hasError('maxlength') && companyMasterForm.controls['registeredOfficeAddress'].touched"
                    class="form-control-error-custom">
                    Registered Office Address should be maximum 500 character.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">EVA </label>
                <div class="form-group">
                  <input type="number" class="form-control" placeholder="EVA" name="eva" [formControl]="companyMasterForm.controls['EVA']"
                    (keydown)="onlyDecimalNumber($event)">
                  <div *ngIf="companyMasterForm.controls['EVA'].hasError('pattern') && companyMasterForm.controls['EVA'].touched"
                    class="form-control-error-custom">
                    Please enter maximum 5 digits before decimal point and 2 decimal after decimal point
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Registration ID Type
                  <span _ngcontent-c2="" style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <select [formControl]="companyMasterForm.controls['registrationIDType']" class="form-control" style="padding:8px 20px;"
                    (change)="onSelectRegistrationId()">
                    <option value="">Select</option>
                    <option *ngFor="let registrationIDType of registrationIDTypes" value="{{registrationIDType}}">{{registrationIDType}}</option>
                  </select>
                  <div *ngIf="companyMasterForm.controls['registrationIDType'].hasError('required') && companyMasterForm.controls['registrationIDType'].touched"
                    class="form-control-error-custom">
                    Registration ID Type is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Registration Number
                  <span _ngcontent-c2="" style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input [formControl]="companyMasterForm.controls['registrationNumber']" class="form-control"
                    placeholder="Registration Number" (keydown)="restrictCIN($event)" (focusout)="checkForRepeatCINForCompany(companyMasterForm.controls['registrationNumber'].value)">
                  <!-- focusout added -->
                  <div *ngIf="companyMasterForm.controls['registrationNumber'].hasError('required') && companyMasterForm.controls['registrationNumber'].touched"
                    class="form-control-error-custom">
                    Registration number is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].touched"
                    class="form-control-error-custom">
                    Registration number should not be allowed special characters.
                  </div>
                  <div *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany && !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('maxlength') && companyMasterForm.controls['registrationNumber'].touched"
                    class="form-control-error-custom">
                    Registration number should be 21 digit.
                  </div>
                  <div *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany &&  !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('minlength') && companyMasterForm.controls['registrationNumber'].touched"
                    class="form-control-error-custom">
                    Registration number should be 21 digit.
                  </div>
                  <div *ngIf="errorUniqueCINForCompany&&!errorValidCINForCompany" class="form-control-error-custom">
                    Please enter unique Registration number for CIN.
                  </div>

                  <div *ngIf="errorValidCINForCompany&&!companyMasterForm.controls['registrationNumber'].hasError('required')"
                    class="form-control-error-custom">
                    Please enter valid Registration number for CIN.
                  </div>
                </div>
              </div>
              <!-- -----------------------------------changes for PSS to Goveva----------------------------------------------------- -->
              <div class="col-md-6">
                <label class="form-control-label">Exchange Code</label>
                <div class="form-group">
                  <input class="form-control" placeholder="Exchange Code" name="exchangeCode" [formControl]="companyMasterForm.controls['exchangeCode']">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex  mt-3" *ngIf="hideListedCompany" id="listCompanyCheck">
                <!-- -----------------------------------changes for PSS to Goveva--------------------------------------------------------- -->
                <label class="form-control-label mr-5">Listed Company</label>
                <div class="form-group">

                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['listedCompany']" style="position:absolute"
                      name="listedCompany" value=true class="form-check-input" (click)="showListingTypeDiv()">Yes
                      <span class="checkmark"></span>
                  </label>
                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['listedCompany']" style="position:absolute"
                      name="listedCompany" value=false class="form-check-input" (click)="hideListingTypeDiv()">No
                      <span class="checkmark"></span>
                  </label>
                  <div *ngIf="showSelected" class="mt-3 list-comp-submenu">

                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['equityListing']" [checked]="equityListingFlag"
                        (change)="onListingTypeChange('equityListing', companyMasterForm.controls['equityListing'].value)">
                        Equity Listing 
                      <span class="checkmark"></span></label>
                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['equityListingSMESegment']"
                        [checked]="equityListingSMESegmentFlag" (change)="onListingTypeChange('equityListingSMESegment',companyMasterForm.controls['equityListingSMESegment'].value)">SME
                      Listing
                      <span class="checkmark"></span></label>

                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['onlyDebtListing']" [checked]="onlyDebtListingFlag"
                        (change)="onListingTypeChange('onlyDebtListing',companyMasterForm.controls['onlyDebtListing'].value)">Only
                      Debt Listing
                      <span class="checkmark"></span></label>
                    <div *ngIf="debtListingLabelHide">
                      <label class="container">
                        <input type="checkbox" [formControl]="companyMasterForm.controls['debtListing']" [checked]="debtListingFlag"
                          (change)="onListingTypeChange('debtListing',companyMasterForm.controls['debtListing'].value)">Debt
                        Listing (along with other)
                        <span class="checkmark"></span></label>
                    </div>

                    <div *ngIf="!isChekedListedCompany" class="form-control-error-custom">
                      Please select at least one Listing type
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex mt-3">
                <label class="form-control-label mr-5">NBFC Regulations</label>
                <div class="form-group">
                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['nbfcRegulations']" style="position:absolute"
                      name="nbfc" value="Yes" class="form-check-input" (click)="nbfcRegulationsValueTrue()">Yes
                  <span class="checkmark"></span>
                </label>
                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['nbfcRegulations']" style="position:absolute"
                      name="nbfc" value="No" class="form-check-input" (click)="nbfcRegulationsValueFalse()">No
                      <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex mt-3">
                <label class="form-control-label mr-5">Accepts Deposits</label>
                <div class="form-group">
                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['acceptsDeposits']" style="position:absolute"
                      name="acceptdeposits" value=true class="form-check-input" (click)="acceptDepositValueTrue()">Yes
                    <span class="checkmark"></span>
                  </label>
                  <label class="form-check-label">
                    <input type="radio" [formControl]="companyMasterForm.controls['acceptsDeposits']" style="position:absolute"
                      name="acceptdeposits" value=false class="form-check-input" (click)="acceptDepositValueFalse()">No
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <!-------------- --------------------------------------------------------------------------------------------------------- -->

              <div class="col-md-6">
                <label class="form-control-label">Number of Shareholders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfShareholders']" class="form-control" 
                    (keydown)="onlyNumber($event)" maxlength="11">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="acceptsDeposits">
                <label class="form-control-label" for="l0">Asset Size
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['amountoFDeposits']" class="form-control"
                    placeholder="Asset Size" style="padding:6px;" required>
                  <div *ngIf=" companyMasterForm.controls['amountoFDeposits'].hasError('required') && companyMasterForm.controls['amountoFDeposits'].touched"
                    class="form-control-error-custom">
                    Asset Size is required.
                  </div>
                  <div *ngIf=" companyMasterForm.controls['amountoFDeposits'].hasError('min') && companyMasterForm.controls['amountoFDeposits'].touched"
                    class="form-control-error-custom">
                    Please enter amount greater than 0.
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Number of Debenture-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfDebentureHolders']" class="form-control"
                    (keydown)="onlyNumber($event)" maxlength="11">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Number of Deposit-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfDepositHolders']" class="form-control"
                    (keydown)="onlyNumber($event)" maxlength="11">
                </div>
              </div>
            </div>
            <div class="row">
              <!-- -----------------------------------changes for PSS to Goveva--------------------------------------------------------- -->
              <div class="col-md-6">
                <label class="form-control-label">Number of Security-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfSecurityHolders']" class="form-control"
                    (keydown)="onlyNumber($event)" maxlength="11">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Financial Year</label>
                <div class="form-group">
                  <div class="input-group" (click)="$event.stopPropagation()" style="float:left; width:50%; padding-right: 5px;">
                    <span style="padding: 8px 10px 0 0;">From</span>
                    <input class="form-control" [attr.readonly]="true" [formControl]="companyMasterForm.controls['FinancialYearFrom']"
                      [(ngModel)]="financialYearFromModel" placeholder="dd-mm-yyyy"
                      name="dpFinancialYearFrom" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle();s.close();a.close();"
                      (document:click)="d.close();">
                    <button class="input-group-addon" (click)="clearFinancialYearFrom()" type="button" [disabled]="editCompanyData">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" (click)="d.toggle();s.close();a.close();" type="button"
                      [disabled]="editCompanyData">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                  <div class="input-group" (click)="$event.stopPropagation()" style="width:50%;">
                    <span style="padding: 8px 10px;">To</span>
                    <input class="form-control" [attr.readonly]="true" [formControl]="companyMasterForm.controls['FinancialYearTo']"
                      [(ngModel)]="financialYearToModel" placeholder="dd-mm-yyyy"
                      name="dpFinancialYearTo" ngbDatepicker #s="ngbDatepicker" (click)="s.toggle();d.close();a.close();"
                      (document:click)="s.close();">
                    <button class="input-group-addon" (click)="clearFinancialYearTo()" type="button" [disabled]="editCompanyData">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" (click)="s.toggle();d.close();a.close();" type="button"
                      [disabled]="editCompanyData">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                  <div *ngIf="error.isError" style="color: #fb434a;font-size: 80%;z-index: 1;">
                    {{ error.errorMessage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Type of Industry</label>
                <div class="form-group">
                  <select (change)="selectIndustry($event)" [formControl]="companyMasterForm.controls['typeOfIndustry']"
                    class="form-control" style="padding:8px 20px;">
                    <option value="">Select</option>
                    <option *ngFor="let typeOfIndustry of industryTypes" value="{{typeOfIndustry.IND_CODE}}">
                      {{typeOfIndustry.INDUSTRY}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label custom-tooltip">
                  Peer Companies
                  <span class="custom-tooltiptext">
                    Define companies against which you want to do Peer Analysis for Ratios
                  </span>
                </label>
                <div class="form-group">
                  <ng-select id=peersdropdown [items]="peersList" [ngModel]="chosenPeers" [formControl]="companyMasterForm.controls['peersName']"
                    [multiple]="true" (selected)="getSelectedPeers($event)" (deselected)="multiplePeersDeselected($event)">
                  </ng-select>
                  <div *ngIf="invalidPeers" class="form-control-error-custom">
                    Maximum 5 Peer companies can be selected.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <b>Subsidiary Company Information (This will be a table to be filled by CS of Company)</b>
                <table class="table table-striped table-bordered mt-3">
                  <thead>
                    <tr>
                      <th width="320">Name Of Subsidiary Company1</th>
                      <th width="230">
                        <span class="custom-tooltip">
                          CIN
                          <span class="custom-tooltiptext" style="font-weight: normal">
                            Company Incorporation Number
                          </span>
                        </span>

                      </th>
                      <th width="420">Type</th>
                      <th width="20">Shareholding</th>
                      <th width="100">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subsidiary of subsidiaryArray; let indexCount = index">
                      <td>
                        <div *ngIf="!subsidiary.isEdit">
                          {{subsidiary.Name}}
                        </div>

                        <div *ngIf="subsidiary.isEdit" class="ddl-custom">
                          <input type="text" class="form-control" [(ngModel)]="subsidiary.dirtyFincode == 'other'? subsidiary.dirtyFincode : subsidiary.dirtySubsideryName"
                            formControlName="exSubsidiaryName" [ngbTypeahead]="searchSubsidiaryCompany" (selectItem)="getExistingSubsidiarySelected($event, subsidiary)" />

                        </div>

                        <div *ngIf="subsidiary.isEdit && subsidiary.isFoundOtherSubsidery">
                          <input class="form-control" [disabled]="!IsUpdate" value="{{subsidiary.otherSubsideryName}}"
                            type="text" (focusout)="onLeaveEventOfExistingOtherSubsidery($event,subsidiary)" />
                        </div>

                      </td>
                      <td>
                        <div *ngIf="!subsidiary.isEdit">
                          {{subsidiary.CIN}}
                        </div>
                        <div *ngIf="subsidiary.isEdit">
                          <input class="form-control" [disabled]="!IsUpdate" type="text" maxlength="21" [formControl]="companyMasterForm.controls['exSubsidiaryCIN']"
                            value="{{subsidiary.CIN}}" [(ngModel)]='subsidiary.dirtyCIN' (focusout)="checkForRepeatCINForExistingSubsidery(subsidiary)" />
                          <div *ngIf="!subsidiary.isValidCIN" class="form-control-error-custom">
                            Please enter valid CIN of 21 characters.
                          </div>

                          <div *ngIf="!subsidiary.isUniqueCIN && subsidiary.isValidCIN" class="form-control-error-custom">
                            Please enter unique CIN of 21 characters.
                          </div>

                        </div>
                      </td>
                      <td>

                        <div *ngIf="!subsidiary.isEdit">
                          {{subsidiary.Type}}
                        </div>
                        <div *ngIf="subsidiary.isEdit">
                          <select class="form-control" [disabled]="!IsUpdate" type="text" [formControl]="companyMasterForm.controls['exSubsidiaryType']"
                            value="{{subsidiary.Type}}" [(ngModel)]='subsidiary.dirtySubsideryType'>
                            <option value="">Select</option>
                            <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">{{typeOfCompany}}</option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div *ngIf="!subsidiary.isEdit">
                          {{subsidiary.Shareholding + " % "}}

                        </div>
                        <div *ngIf="subsidiary.isEdit">
                          <input class="form-control" type="number" [formControl]="companyMasterForm.controls['exSubsidiaryShareholding']"
                            [disabled]="!IsUpdate" value="{{subsidiary.Shareholding}}" [(ngModel)]='subsidiary.dirtyShareholding'  min="1" max="100"/>
                        </div>
                      </td>
                      <td>
                        <div *ngIf="subsidiary.isEdit">
                          <button class="btn btn-sm btn-primary" [disabled]="((!subsidiary.isValidExSubsideryName) ||  !subsidiary.isValidCIN || subsidiary.dirtySubsideryType=='' || subsidiary.dirtyShareholding==null || errorUniqueCIN || !(subsidiary.dirtyShareholding > 0 && subsidiary.dirtyShareholding <= 100 ))"
                            type="button" (click)="updateSubsidiaryData(subsidiary)">
                            <i class="fa fa-plus pr-2"></i>Update</button>
                          <button class="btn btn-sm btn-primary" [disabled]="!IsUpdate" type="button" (click)="cancelDirtySelection(subsidiary)">Cancel</button>
                        </div>
                        <div *ngIf="!subsidiary.isEdit">
                          <button class="btn btn-sm btn-primary" [disabled]="!IsUpdate" type="button" (click)="selectSubsiderydata(subsidiary);">Edit</button>
                          <button class="btn btn-sm btn-danger" [disabled]="!IsUpdate" type="button" (click)="deleteSubsidiaryData(indexCount)">Delete</button>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="IsUpdate">
                      <td class="ddl-custom">
                        <input type="text" class="form-control" formControlName="newSubsidiaryCompanyName"
                          [ngbTypeahead]="searchSubsidiaryCompany" (selectItem)="selectedSubsidiaryCompanyItem($event)" />
                        <input class="form-control" [formControl]="companyMasterForm.controls['otherSubsidiaryCompanyName']"
                          [disabled]="!IsUpdate" [(ngModel)]="otherSubsideryName" type="text" id="newSubsidiaryName"
                          name="newSubsidiaryName" style="display: none" (focusout)="onLeaveEventOfOtherSubsideryName()" />
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryCompanyName'].hasError('maxlength')"
                          class="form-control-error-custom">
                          Name should be less than 100 characters.
                        </div>
                      </td>
                      <td>
                        <input class="form-control" [formControl]="companyMasterForm.controls['newSubsidiaryCIN']"
                          [disabled]="!IsUpdate" type="text" id="newSubsidiaryCIN" name="newSubsidiaryCIN" maxlength="21"
                          (focusout)="checkForRepeatCIN(companyMasterForm.controls['newSubsidiaryCIN'].value)" />
                        <div *ngIf="companyMasterForm.controls['newSubsidiaryCIN'].touched && companyMasterForm.controls['newSubsidiaryCIN'].hasError('minlength') && !errorUniqueCIN"
                          class="form-control-error-custom">
                          Please enter CIN of 21 characters.
                        </div>
                        <div *ngIf="errorUniqueCIN" class="form-control-error-custom">
                          Please enter Unique CIN of 21 characters.
                        </div>
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryCIN'].touched &&  companyMasterForm.controls['newSubsidiaryCIN'].hasError('maxlength') && !errorUniqueCIN"
                          class="form-control-error-custom">
                          Please enter CIN of 21 characters.
                        </div>
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryCIN'].touched && !companyMasterForm.controls['newSubsidiaryCIN'].hasError('minlength') && !companyMasterForm.controls['newSubsidiaryCIN'].hasError('maxlength') &&  companyMasterForm.controls['newSubsidiaryCIN'].hasError('pattern') && !errorUniqueCIN"
                          class="form-control-error-custom">
                          Please enter valid CIN of 21 characters.
                        </div>

                      </td>
                      <td>
                        <select class="form-control" [formControl]="companyMasterForm.controls['newSubsidiaryType']"
                          [disabled]="!IsUpdate" type="text" id="newSubsidiaryType" name="newSubsidiaryType" style="padding: 6px 20px;">
                          <option value="">Select</option>
                          <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">{{typeOfCompany}}</option>
                        </select>
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryType'].touched && companyMasterForm.controls['newSubsidiaryType'].value== ''"
                          class="form-control-error-custom">
                          Please select company type.
                        </div>
                      </td>
                      <td>
                        <input class="form-control" type="number" [formControl]="companyMasterForm.controls['newSubsidiaryShareholding']"
                          [disabled]="!IsUpdate" id="newSubsidiaryShareholding" name="newSubsidiaryShareholding"  
                          min="1" max="100" maxlength = "3"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryShareholding'].hasError('max')" class="form-control-error-custom">
                          Please enter valid percentage upto 100.
                        </div>
                        <div *ngIf=" companyMasterForm.controls['newSubsidiaryShareholding'].hasError('min')" class="form-control-error-custom">
                          Please enter value greater than 0.
                        </div>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-primary" type="button" [disabled]="!( (companyMasterForm.controls['newSubsidiaryCompanyName'].value!='' && isValidSubsdieryName  && companyMasterForm.controls['newSubsidiaryCompanyName'].valid) && (companyMasterForm.controls['newSubsidiaryCIN'].valid)  && (companyMasterForm.controls['newSubsidiaryType'].value!='' && companyMasterForm.controls['newSubsidiaryType'].valid) && (companyMasterForm.controls['newSubsidiaryShareholding'].value!='' && companyMasterForm.controls['newSubsidiaryShareholding'].valid) && !(errorUniqueCIN))"
                          (click)="addSubsidiaryData()">
                          <i class="fa fa-plus pr-2"></i>Add</button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-between">
            <div style="text-align: left;">
              <span style="color: #ff0000">*</span> Indicates Mandatory field
            </div>
            <div class="btns text-right">
              <button [appHasPermission]="{ 'Url': 'companyMaster/edit', 'method':'put', 'menu': 'CompanyMaster' }" *ngIf="editBtn==false"
              [disabled]='editBtn' type="button" class="btn btn-sm btn-primary" data-dismiss="modal" (click)="toogle('edit')">Edit</button>
              <button id="btnupdateCompany" style="display:none;" type="button" class="btn btn-sm btn-primary" [disabled]="((!companyMasterForm.valid) || (errorUniqueCINForCompany) || invalidPeers || errorValidCINForCompany)"
              (click)="editCompany()">Save</button>
              <button *ngIf="cancelBtn==false" [disabled]='cancelBtn' type="button" class="btn btn-sm btn-primary"
                data-dismiss="modal" (click)="toogle('cancel')">Cancel</button>
            </div>
          </div>
        </form>

        <div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
          style="top: 50px; z-index: 9999;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div id="alertPopUpTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                Title!
              </div>
              <div class="modal-body" style="padding: 10px 15px;">
                <div id="alertMessage" style="font-size: 16px;">
                  Message..
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" data-backdrop="static" id="uploadCompanyLogoPopUp" tabindex="-1" role="dialog"
          aria-labelledby="" aria-hidden="true" style="top: 50px;">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="border: 1px solid rgba(0,0,0,.2);">
              <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                Select Company Logo
              </div>
              <div class="modal-body" style="padding: 10px 15px;">
                <div>
                  <div class="file-upload">
                    <input id="custom-input" type="file" (change)="fileChangeListener($event)" accept="image/gif, image/jpeg, image/png">
                    <img [src]="base64Code.image" alt="image" style="height: 100%; width: 100%;">
                  </div>
                  <br>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary btn-sm" [disabled]="isInvalidImage" (click)="setCompanyLogo()">Set as
                  company logo</button>
                <button class="btn btn-sm" data-dismiss="modal" (click)="restoreImage()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="confirmation" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
          style="top: 50px;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="myModalLabelForDelete">
                  <i class="fa fa-question-circle-o mr-2"></i>Confirm</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    Are you sure you want to delete this company?
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-sm" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</section>