import { Injectable } from "@angular/core";
// import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class RocketChatService {
  constructor(private http: HttpClient, private httpWithToken: HttpClient) {}

  createUser(userObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/users.create";
    // const url = environment.rocketChatUrl + '/api/v1/users.create';

    var authToken = JSON.parse(localStorage.getItem("user")).AuthenticationToken;
    var userId = JSON.parse(localStorage.getItem("user")).UserId;
    let headers = {};
    let head = (key, header) => {headers[key] = header};

    head("X-Auth-Token", authToken);
    head("X-User-Id", userId);

    return this.http
      .post(url, { headers: { "X-Auth-Token": authToken, "X-User-Id": userId } }, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  loginToRocketChat(userObject, chatEngineURL): Promise<any> {
    const url = chatEngineURL + "/api/v1/login";
    let myHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    let options = { headers: myHeaders };

    return this.http
      .post(url, userObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  logoutFromRocketChat(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/logout";
    // const url = environment.rocketChatUrl + '/api/v1/logout';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  logoutFromSystem(): Promise<any> {
    var companyDetails = localStorage.getItem("UserName");
    // console.log('companyDetails==============mangesh', companyDetails);

    return this.http
      .post(environment.clientUrl + "userMaster/logOut", { companyDetails: companyDetails })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getQuickUserInformation(): Promise<any> {
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/me";
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getCommunicatedUsersList(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/dm.list";
    // const url = environment.rocketChatUrl + '/api/v1/dm.list';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getRoomsList(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/rooms.get";
    // const url = environment.rocketChatUrl + '/api/v1/dm.list';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("Content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAvatarByUserId(userId): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/users.getAvatar?userId=" + userId;
    // const url = environment.rocketChatUrl + '/api/v1/users.getAvatar?userId=' + userId;
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getUsersList(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/users.list";
    // const url = environment.rocketChatUrl + '/api/v1/users.list';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getICUsersData(): Promise<any> {
    const url = environment.clientUrl + "user/getICUsers";
    return this.httpWithToken
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  postMessage(messageObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/chat.postMessage";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, messageObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getMessagesCompleteHistory(roomId, count): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/dm.history?roomId=" + roomId + "&count=" + count;
    // const url = environment.rocketChatUrl + '/api/v1/dm.history?roomId=' + roomId + '&count=' + count;
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getLatestMessages(reqObject: any): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url =
      chatBaseURL +
      "/api/v1/dm.history?roomId=" +
      reqObject.PartnerRoomId +
      "&oldest=" +
      reqObject.lastMessageDT;
    // const url = environment.rocketChatUrl + '/api/v1/dm.history?roomId=' + reqObject.PartnerRoomId + '&oldest=' + reqObject.lastMessageDT;
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  sendMessage(messageObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/chat.postMessage";
    // const url = environment.rocketChatUrl + '/api/v1/chat.postMessage';

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
    let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, messageObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  //CHANNELS DATA
  getChannelList(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.list";
    // const url = environment.rocketChatUrl + '/api/v1/channels.list';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getChannelListJoinedByUser(): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.list.joined";
    // const url = environment.rocketChatUrl + '/api/v1/channels.list';
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  createChannel(channelObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.create";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, channelObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  inviteUserToChannel(userObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.invite";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, userObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  removeUserFromChannel(userObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.kick";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, userObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getChannelCompleteMsgHistory(roomId, count): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.history?roomId=" + roomId + "&count=" + count;
    // const url = environment.rocketChatUrl + '/api/v1/dm.history?roomId=' + roomId + '&count=' + count;
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getChannelLatestMessges(reqObject: any): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url =
      chatBaseURL +
      "/api/v1/channels.history?roomId=" +
      reqObject._id +
      "&oldest=" +
      reqObject.lastMessageDT;
    // const url = environment.rocketChatUrl + '/api/v1/dm.history?roomId=' + roomId + '&count=' + count;
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getChannelMembersList(channelId): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/channels.members?roomId=" + channelId;
    // const url = environment.rocketChatUrl + '/api/v1/dm.history?roomId=' + roomId + '&count=' + count;
    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);

    let options = { headers: myHeaders };

    return this.http
      .get(url, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  deleteMessageById(userObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/chat.delete";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, userObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  deleteAllMessages(userObject): Promise<any> {
    var chatBaseURL = localStorage.getItem("rocketBaseURL");
    const url = chatBaseURL + "/api/v1/im.close";

    var authToken = localStorage.getItem("rocketAuth");
    var userId = localStorage.getItem("rocketUserId");
     let myHeaders = {};
    let myhead = (key, header) => {myHeaders[key] = header};

    myhead("X-Auth-Token", authToken);
    myhead("X-User-Id", userId);
    myhead("content-type", "application/json");

    let options = { headers: myHeaders };

    return this.http
      .post(url, userObject, options)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
