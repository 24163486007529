import { Component, OnInit, Input} from '@angular/core';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gfmconfirmation-popup',
  templateUrl: './gfmconfirmation-popup.component.html',
  styleUrls: ['./gfmconfirmation-popup.component.css'],
})
export class GfmconfirmationPopupComponent implements OnInit {
  @Input("Message") Message : any;
  constructor(private modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit() {
    setTimeout(() => {
      this.activeModal.close();
    }, 2100);
  }

  close(){
    // this.activeModal.close();
  }
}
