import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { ImageCropperModule } from 'ngx-img-cropper';
import { FooterComponent } from '../components/footer/footer.component';
import { MenuLeftComponent } from '../components/menu-left/menu-left.component';
import { MenuRightComponent } from '../components/menu-right/menu-right.component';
import { TopBarComponent } from '../components/top-bar/top-bar.component';
import { SharedModule } from '../shared/sharedmodule';
import { SharedComponentsModuleModule } from 'src/app/shared-components-module/shared-components-module.module';
import { ViewSmtpComponent } from '../smtp/view-smtp/view-smtp.component';
import { UINotificationComponent } from '../structure/notifications/notifications-UI.component';
import { AuthGuard } from '../authGuard/auth.guard';

import { AuditTrailModule } from './audit-trail/audit-trail.module';
import { BISModule } from './BIS/biscommunication.module';
// import { AppsModule } from './apps/apps.module';

import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("CompanyName=GovEVA Private Limited,LicensedApplication=Goveva E-Carbon,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-035321,SupportServicesEnd=28_November_2023_[v2]_MTcwMTEyOTYwMDAwMA==f9eba16d2879f28cf7ff6020d0a782d7")

import { ChartsModule } from './charts/charts.module';
import { CompanymasterComponent } from './companymaster/companymaster.component';
import { ComponentsModule } from './components/components.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { DocumentationModule } from './documentation/documentation.module';
import { EcommerceModule } from './ecommerce/ecommerce.module';
import { InnerFormsModule } from './forms/forms.module';
import { GfmmoduleComponent } from './gfmmodule/gfmmodule.component';
import { GfmDashboardComponent } from './govevaFileManager/gfm-dashboard/gfm-dashboard.component';
import { govevaFileManagerModule } from './govevaFileManager/govevaFileManager.Module';
import { IconsModule } from './icons/icons.module';
import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';
import { ReportsModule } from './reports/reports.module';
import { RoleMasterModule } from './role-master/role-master.module';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { StructureComponent } from './structure.component';
import { structureRouting } from './structure.routing';
import { TablesModule } from './tables/tables.module';
import { UserMasterModule } from './user-master/user-master.module';
import { DataImportComponent } from '../data-import/data-import.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // AppsModule,
    NgSelectModule,
    ChartsModule,
    ComponentsModule,
    DashboardsModule,
    DocumentationModule,
    EcommerceModule,
    InnerFormsModule,
    FormsModule,
    IconsModule,
    LayoutModule,
    PagesModule,
    TablesModule,
    NgbModule,
    DataTablesModule,
    RoleMasterModule,
    UserMasterModule,
    SharedModule,
    SharedComponentsModuleModule,
    ImageCropperModule,
    ReportsModule,
    AuditTrailModule,
    BISModule,
    govevaFileManagerModule,
    structureRouting,
    AgGridModule,
  ],
  providers: [AuthGuard, DatePipe],
  declarations: [
    CompanymasterComponent,
    RolemasterComponent,
    UINotificationComponent,
    TopBarComponent,
    MenuLeftComponent,
    MenuRightComponent,
    FooterComponent,
    StructureComponent,
    GfmmoduleComponent,
    GfmDashboardComponent,

    ViewSmtpComponent,
    DataImportComponent,
    // GfmPopupforShareComponent,
    // ReviewDetailsPopupComponent,
    // QuestionnaireAssignmentComponent,
    // QuestionnaireReviewComponent,
    // IndividualReportListComponent,
    //EvaluationMonitoringComponent,
    //IndividualReportComponent,
    //SummaryReportComponent,
    //EvaluationFormsComponent,
    //EvaluationReviewComponent,
    //EvaluationFormToAssignComponent,
    //EntityFormSetupComponent,
    //EntityMasterComponent,
    //EvaluateCategoryComponent,
    //RoadmapReviewerComponent,
    //SafeHtmlPipe
  ],
  exports: [],
  entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class StructureModule {
  constructor() {}
}
