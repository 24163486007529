<section class="card">
  <div class="card-header">
    <a href="/#/structure/(structure-outlet:dashboards/alpha)">
      <img src="../../../assets/modules/dummy-assets/common/img/GovEVA.png" style="max-height: 2.9rem;" alt="GovEVA" class="hide-xs" />
      <img src="../assets/images/logo-mob.svg" class="logo-mob" alt="GovEVA" />
    </a>
  </div>
  <div id="cover-spin">
    <div class="container">
        <div class="vertical-center"
            style="margin: 0;position: absolute;top: 220%;left: 43%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
        </div>
    </div>
  </div>
  <div class="card-header" style="background-color: #07a7a5;height: 43px; color:white;display: flex;">
    <h5>Meeting Details</h5>
  </div>
    <div class="card-block p-3">
      <div class="row">
        <div class="col-lg-12">
          <h4>
            <strong>
              {{meetingObject.MeetingTypeName}}
               {{meetingObject.MeetingDate ? (meetingObject.MeetingDate | date : 'MMMM dd,yyyy' ) :" " }} 
               - {{meetingObject.MeetingTime ? meetingObject.MeetingTime: " " }} 
               {{(meetingObject.MeetingPlace) ? " - at " :   " " }} {{(meetingObject.meetingPlace) ? " - at " :   " " }}
               {{meetingObject.MeetingPlace}}  {{meetingObject.meetingPlace}}
            </strong>
          </h4>
          <ul class="nav nav-tabs" id="MeetingTab" role="tablist" style="margin-top:20px;">
            <li class="nav-item Agenda" *ngIf="NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#Agenda" role="tab">
                <strong>Agenda Items</strong>
              </a>
            </li>
            <li class="nav-item Invitee" *ngIf="!NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#Invitee" role="tab">Invitee</a>
            </li>
            <li class="nav-item Documents" *ngIf="NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#Document" role="tab">
                <strong>Documents </strong>
              </a>
            </li>
            <li class="nav-item Documents" *ngIf="!NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link active" href="javascript: void(0);" data-toggle="tab" data-target="#Document" role="tab">
                <strong>Documents </strong>
              </a>
            </li>
            <li class="nav-item Participants" *ngIf="NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#Attendance" role="tab">
                <strong>Participants </strong>
              </a>
            </li>
            <li [appHasPermission]="{'Url': 'chat', 'method':'get', 'menu': 'Dashboard'}" class="nav-item Messages" *ngIf="chat && NotRegularMeetingTab" style="width:100%;">
              <a class="nav-link" href="javascript: void(0);" data-toggle="tab" data-target="#Messages" role="tab">
                <strong>Messages </strong>
              </a>
            </li>
          </ul>
  
          <div class="tab-content restrict-Height">
  
            <div class="tab-pane " id="Meeting" role="tabcard">
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-control-label font-weight-bold">Meeting Type</label>
                    <span class="colon-right">:</span>
                  </div>
                  <div class="col-lg-3">
                    <label>{{meetingObject.meetingType ? meetingObject.meetingType : (meetingObject.MeetingTypeID ? (meetingObject.MeetingTypeID.MeetingType
                      ? meetingObject.MeetingTypeID.MeetingType : " " ) : " " )}}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-control-label font-weight-bold">Meeting Number
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label>{{meetingObject.meetingType ? (meetingObject.meetingNumber ? meetingObject.meetingNumber : " ") : (meetingObject.MeetingNumber
                      ? meetingObject.MeetingNumber : " " )}}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-control-label font-weight-bold">Meeting Date
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label>{{meetingObject.meetingType ? (meetingObject.meetingDate | date) : (meetingObject.MeetingDate ? (meetingObject.MeetingDate
                      | date ) : " " ) }}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-control-label font-weight-bold">Meeting Time
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label>{{meetingObject.meetingType ? meetingObject.meetingTime : (meetingObject.MeetingTime ? meetingObject.MeetingTime
                      : " " )}}</label>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-control-label font-weight-bold">Venue
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3">
                    <label>{{meetingObject.meetingType ? meetingObject.meetingPlace : (meetingObject.MeetingPlace ? meetingObject.MeetingPlace
                      : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Due date to issue notice
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.IssueNoticeDueDate ? (meetingObject.IssueNoticeDueDate| date) : " " ) }}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Name of person signing Notice
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.NoticeSignatoryName ? meetingObject.NoticeSignatoryName : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Mobile Number
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.ContactNumber ? meetingObject.ContactNumber : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Email address
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="line-wrap">{{(meetingObject.EmailID ? meetingObject.EmailID : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Designation of person signing Notice
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.NoticeSignatoryDesignation ? meetingObject.NoticeSignatoryDesignation : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">DIN / ACS no.
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.DinNumber ? meetingObject.DinNumber : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Date of signing Notice
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.SigningNoticeDate ? (meetingObject.SigningNoticeDate| date) : " " )}}</label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label class="form-control-label font-weight-bold">Place of signing Notice
                      <span class="colon-right">:</span>
                    </label>
                  </div>
                  <div class="col-lg-3" *ngIf="NotRegularMeetingTab">
                    <label>{{(meetingObject.SigningNoticePlace ? meetingObject.SigningNoticePlace : " " )}}</label>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="tab-pane" id="Category" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingcategory [categoryObject]="meetingObject.Category"></app-meetingcategory>
            </div>
  
            <div class="tab-pane active" id="Agenda" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingagendaview [meetingObjectView]="meetingObject" [agendaObject]="meetingObject.Agenda"></app-meetingagendaview>
            </div>
  
            <div class="tab-pane" id="Compliance" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingcompliance [complianceObject]="meetingObject.ActivatedTasks"></app-meetingcompliance>
            </div>
  
            <div class="tab-pane" id="Director" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingdirector [meetingDate]="meetingDate" [directorObject]="meetingObject.Directors">
              </app-meetingdirector>
            </div>
  
            <div class="tab-pane" id="Invitee" role="tabcard">
              <app-meetinginvitee [inviteeObject]="meetingObject.meetingTypeName ? meetingObject.meetingInvitees : meetingObject.Invitees">
              </app-meetinginvitee>
            </div>
  
            <div class="tab-pane" id="Member" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingmember [memberObject]="meetingObject.Members"></app-meetingmember>
            </div>
  
            <div class="tab-pane" id="Attendance" *ngIf="NotRegularMeetingTab" role="tabcard">
              <app-meetingattendance [meetingObject]="meetingObject"></app-meetingattendance>
            </div>
  
            <div class="tab-pane" id="Document" role="tabcard" *ngIf="NotRegularMeetingTab">
              <app-meetingdocumentview [meetingObjectView]="meetingObject" [documentObject]="meetingObject.Documents ? meetingObject.Documents : (meetingObject.attachments ? meetingObject.attachments : '')">
              </app-meetingdocumentview>
            </div>
  
            <div class="tab-pane active" id="Document" role="tabcard" *ngIf="!NotRegularMeetingTab">
              <app-meetingdocumentview [meetingObjectView]="meetingObject" [documentObject]="meetingObject.Documents ? meetingObject.Documents : (meetingObject.attachments ? meetingObject.attachments : '')">
              </app-meetingdocumentview>
            </div>
  
             <!-- Messages tab -->
            <div class="tab-pane" id="Messages" role="tabcard" *ngIf="chat && NotRegularMeetingTab">
              <app-webx-chat>
              </app-webx-chat>
            </div>
  
            <div class="tab-pane" id="GeneratedAgenda" role="tabcard">
              <app-meeting-generated-agenda [GeneratedAgendaObject]="meetingObject.AgendaGeneration ? meetingObject.AgendaGeneration : []">
              </app-meeting-generated-agenda>
            </div>
  
          </div>
  
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 14px;">
      <div class="col-lg-12">
       <!-- <button *ngIf="isAttendInPerson == 'yes'" class="btn btn-default btn-link" (click)="leaveMeeting();" class="btn btn-default btn-link" style="position: relative;font-size: 14px;font-weight: 500;cursor: pointer;background: #07a7a5 !important;border-radius: 5px;margin-right: 10px;">Leave Meeting</button>
       <button *ngIf="isAttendInPerson == 'yes'" class="btn btn-default btn-link" (click)="goToDashboard();" style="position: relative;font-size: 14px;font-weight: 500;cursor: pointer;background: #07a7a5 !important;border-radius: 5px;">Dashboard</button> -->
      </div>
    </div>
  </section>
  