<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <!-- (tabChange)="changeTab($event)" -->
      <nav ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
        <ng-container ngbNavItem>
          <a ngbNavLink>SMTP Config</a>
          <ng-template ngbNavContent>
            <div class="mb-5">
              <h5 class="text-black"><strong>SMTP Details</strong></h5>
              <form [formGroup]="smtpForm" (ngSubmit)="onSubmit()">
                <fieldset [disabled]="isDisable">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group" hidden>
                        <label for="id">Id</label>
                        <input type="text" name="id" class="form-control" formControlName="id" placeholder="id">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="Host">Host <sup style="color:red;">*</sup> </label>
                        <input type="text" name="host" class="form-control" formControlName="host" placeholder="Host">

                        <div *ngIf="submitted && f.host.errors" class="text-danger">
                          <div *ngIf="f.host.errors.required">Host Name is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="Port">Port <sup style="color:red;">*</sup></label>
                        <input type="text" class="form-control" name="port" formControlName="port" placeholder="Port">
                        <div *ngIf="submitted && f.port.errors" class="text-danger">
                          <div *ngIf="f.port.errors.required">Port is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="UserName">UserName <sup style="color:red;">*</sup></label>
                        <input type="text" class="form-control" name="userName" formControlName="userName"
                          placeholder="UserName">

                        <div *ngIf="submitted && f.userName.errors" class="text-danger">
                          <div *ngIf="f.userName.errors.required">UserName is required.</div>
                          <div *ngIf="f.userName.errors.email">UserName must be a valid email address.</div>

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="Password">Password <sup style="color:red;">*</sup></label>
                        <div class="input-group">
                          <input type="password" class="form-control" name="password" formControlName="password"
                            placeholder="Password">
                          <div *ngIf="submitted && f.password.errors" class="text-danger">
                            <div *ngIf="f.password.errors.required">Password is required.</div>
                          </div>
                          <span class="input-group-addon">
                            <i class="icmn-key"></i>
                          </span>


                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="SSL">SSL <sup style="color:red;">*</sup></label>
                        <input type="text" class="form-control" name="SSL" formControlName="SSL"
                          placeholder="true/false">

                        <div *ngIf="submitted && f.SSL.errors" class="text-danger">
                          <div *ngIf="f.SSL.errors.required">SSL is required.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="IgnoreTLS">IgnoreTLS <sup style="color:red;">*</sup></label>
                        <input type="text" class="form-control" name="ignoreTLS" formControlName="ignoreTLS"
                          placeholder="true/false">

                        <div *ngIf="submitted && f.ignoreTLS.errors" class="text-danger">
                          <div *ngIf="f.ignoreTLS.errors.required">ignoreTLS is required.</div>
                        </div>
                      </div>
                    </div>

                  </div>


                </fieldset>

                <div class="form-actions">
                  <input type="submit" *ngIf="!isEditable" class="btn btn-primary" />
                  <button type="button" class="btn btn-default" *ngIf="isEditable" (click)="onEdit()">Edit</button>
                  <button type="button" *ngIf="!isEditable" class="btn btn-default" (click)="onCancel()">Cancel</button>
                </div>
              </form>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>Auth Config</a>
          <ng-template ngbNavContent>
            <div class="mb-5">
              <form [formGroup]="authForm" (ngSubmit)="onConfigSubmit()">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group" >
                        <label for="id">Two Factor Authentication</label>
                        <select name="id" class="form-control" formControlName="towWayConfig">
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group" >
                        <label for="id">OAuth Login Authentication</label>
                        <select name="authLoginConfig" class="form-control" formControlName="authLoginConfig">
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-12" *ngIf="isADCompany">
                      <div class="form-group" >
                        <label for="id">OTP Login</label>
                        <select name="OTPLoginFoAD" class="form-control" formControlName="OTPLoginFoAD">
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <input type="submit" class="btn btn-primary" />
                  </div>
                </fieldset>
              </form>
            </div>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div> 
    </div>
  </div>
</div>