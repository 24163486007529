import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';

declare var $: any;
declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.css'],

})
export class AutologinComponent implements OnInit {
  CompanyId: any;
  UniqueUserId: any;
  authSecret: any
  report: any
  constructor(private route: ActivatedRoute, private router: Router, private loginService: LoginService,) { }

  ngOnInit() {
    this.UniqueUserId = this.route.snapshot.paramMap.get('UniqueUserId');
    this.CompanyId = this.route.snapshot.paramMap.get('CompanyId');
    this.authSecret = this.route.snapshot.paramMap.get('authSecret');

    this.report = this.route.snapshot.paramMap.get('report');

    this.loginService.checkauthSecret(this.authSecret).subscribe((responseauthSecret: any) => {
      console.log('authSecretresponse of user--------------3', responseauthSecret)
      if (!responseauthSecret.status) {
        var loginCredentialObject = {
          // "UserName": _.toLower(user.EmailId),
          // "Password": this.loginForm.controls.password.value,
          "autoLogin": true,
          "CompanyId": this.CompanyId,
          "UniqueUserId": this.UniqueUserId
        };
        this.loginService.loginWithUniqueUserId(loginCredentialObject).subscribe((response: any) => {
          console.log('response of user--------------3', response)
          if (!response.status) {

            localStorage.removeItem('companyEVA')
            localStorage.removeItem('user')
            localStorage.removeItem('companyDetailsGoveva')
            localStorage.removeItem('resources')
            localStorage.removeItem('rocketUserId')
            localStorage.removeItem('token')
            localStorage.removeItem('rocketAuth')
            localStorage.removeItem("companyDetails")

            // set localStroage
            console.log('multipleResponse[1].EVA', response)
            //   localStorage.setItem('companyEVA', multipleResponse[1].companyData.EVA)
            localStorage.setItem('user', JSON.stringify(response.user))
            localStorage.setItem('companyDetailsGoveva', JSON.stringify({ label: response.user.CompanyName, value: response.user.CompanyId }))
            localStorage.setItem('resources', JSON.stringify(response.resources))

            localStorage.setItem('token', response.token)
            localStorage.setItem("companyDetails", JSON.stringify({ companyName: response.user.CompanyName, companyID: response.user.CompanyId }))

            if(this.report!=undefined && this.report == "report"){
              this.router.navigate(['structure', { outlets: { 'structure-outlet': 'individualReportList' } }]);
            }else{
              this.router.navigate(['structure', { outlets: { 'structure-outlet': 'evaluationFormList' } }]);
            }



          }else{
            $('#alertTitle').css('color', '#f00');
            $('#alertTitle').text("Failure!");
            $('#alertMessage').css('color', '#f00');
            $('#messagePopUp').modal('toggle');
            $('#alertMessage').text(response.message);
            this.messagePopUpTimeoutToogle();
          }
        })
      }else{
        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text("Failure!");
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        $('#alertMessage').text(responseauthSecret.message);
        this.messagePopUpTimeoutToogle();
      }

    })
    }

    messagePopUpTimeoutToogle() {
      setTimeout(function () {
        var popUpClass = $('#messagePopUp').attr('class');
        if (popUpClass == "modal fade show") {
          $('#messagePopUp').modal('toggle');
        }
      }, 3000);
    }

}

