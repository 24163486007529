<section>
  <div style="width: 95%; margin: 0 auto; position: absolute; top: 5px; left: 40px;" class="pb-2 pl-3">
    <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
  </div>
  <div class="inner-wrap">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel" style="font-weight: bold">
          Super Admin Setup
        </h4>
      </div>
      <form [formGroup]="userMasterForm">
        <div class="modal-body watermark" style="min-height: 300px">
          <div>
            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label"
                  >First Name<span style="color: red">*</span></label
                >
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="url"
                    placeholder="First Name"
                    name="url"
                    [formControl]="userMasterForm.controls['FirstName']"
                  />
                  <div
                    *ngIf="
                      userMasterForm.controls['FirstName'].hasError(
                        'required'
                      ) && userMasterForm.controls['FirstName'].touched
                    "
                    class="form-control-error-custom"
                  >
                    First Name is required.
                  </div>
                  <div
                    *ngIf="
                      userMasterForm.controls['FirstName'].hasError(
                        'pattern'
                      ) && userMasterForm.controls['FirstName'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Please enter valid First Name
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Last Name<span style="color: red">*</span></label
                >
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="url"
                    placeholder="Last Name"
                    name="url"
                    [formControl]="userMasterForm.controls['LastName']"
                  />
                  <div
                    *ngIf="
                      userMasterForm.controls['LastName'].hasError(
                        'required'
                      ) && userMasterForm.controls['LastName'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Last Name is required.
                  </div>
                  <div
                    *ngIf="
                      userMasterForm.controls['LastName'].hasError('pattern') &&
                      userMasterForm.controls['LastName'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Please enter valid Last Name
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Email Id<span style="color: red">*</span></label
                >
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input
                    placeholder="Email Id"
                    type="email"
                    class="form-control"
                    [formControl]="userMasterForm.controls['EmailId']"
                  />
                  <div
                    *ngIf="
                      userMasterForm.get('EmailId').hasError('required') &&
                      userMasterForm.get('EmailId').touched
                    "
                    class="form-control-error-custom"
                  >
                    Email Id is required
                  </div>
                  <div
                    *ngIf="
                      userMasterForm.get('EmailId').hasError('email') &&
                      !userMasterForm.get('EmailId').hasError('required') &&
                      userMasterForm.get('EmailId').touched
                    "
                    class="form-control-error-custom"
                  >
                    Please enter valid Email Id
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <label class="form-control-label"
                  >Last Name<span style="color: red">*</span></label
                >
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input
                    placeholder="Mobile No."
                    type="text"
                    class="form-control"
                    [formControl]="userMasterForm.controls['MobileNumber']"
                    min="10"
                    max="10"
                  />
                  <div
                    *ngIf="
                      userMasterForm.get('MobileNumber').hasError('required') &&
                      userMasterForm.get('MobileNumber').touched
                    "
                    class="form-control-error-custom"
                  >
                    Mobile Number is required
                  </div>
                  <div
                    *ngIf="
                      userMasterForm.get('MobileNumber').hasError('pattern') &&
                      !userMasterForm.get('MobileNumber').hasError('required')
                    "
                    class="form-control-error-custom"
                  >
                    Please enter valid mobile number
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div style="width: 100%; text-align: left">
            <span style="color: #ff0000">*</span> Indicates Mandatory field
          </div>
          <button
            id="btnSaveData"
            type="button"
            class="btn btn-sm btn-primary"
            [disabled]="!userMasterForm.valid"
            (click)="addUserData()"
          >
            Save
          </button>
          <button
            id="btnClearData"
            type="button"
            class="btn btn-sm btn-primary"
            (click)="clearData()"
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  </div>

  <div
    class="modal fade"
    id="messagePopUp"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
    style="top: 50px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          id="alertPopUpTitle"
          class="modal-header"
          style="font-size: 20px; font-weight: bold; padding: 5px 15px"
        ></div>
        <div class="modal-body" style="padding: 10px 15px">
          <div id="alertMessage" style="font-size: 16px"></div>
        </div>
      </div>
    </div>
  </div>
</section>
