import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserMasterService } from '../../../services/user-master.service';
import * as _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import * as moment from 'moment';
import { CommonFunction } from '../../../helpers/commonFunction';
import { safeJsonStringify } from '../../../shared/utils';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-regular-meeting-modal',
  templateUrl: './regular-meeting-modal.component.html',
  styleUrls: ['./regular-meeting-modal.component.css'],
})
export class RegularMeetingModalComponent implements OnInit {
  userList: any[];
  regularMeetingForm: FormGroup;
  meetingDateModel: any;
  docsToUpload: any;
  allUserDetails: any;
  selectedInviteesList: any = [];
  uploadMultipleFileDocument: any = [];
  minDate: { year: number; month: number; day: number };
  count: any;

  @Input('meetingObject') meetingObject: any;
  addBtn: boolean;
  attachmentsArray: any = [];
  attachmentsArrayForRemovingFile: any = [];
  multifileExtension: boolean = true;
  invalidDate: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private commonFunction: CommonFunction,
    private userMasterService: UserMasterService,
    private meetingMasterService: MeetingMasterService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    console.log(
      'Regular meetingObject=========================',
      this.meetingObject
    );
    $(function () {
      $('.timepicker-init').datetimepicker({
        widgetPositioning: {
          horizontal: 'left',
        },
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-arrow-up',
          down: 'fa fa-arrow-down',
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right',
        },
        format: 'LT',
      });
    });

    this.regularMeetingForm = this.fb.group({
      meetingName: ['', [Validators.required]],
      meetingDate: ['', [Validators.required]],
      meetingTime: ['', [Validators.required]],
      meetingPlace: ['', [Validators.required]],
      meetingDoc: ['', []],
      meetingInvitees: ['', [Validators.required]],
    });

    var dateObj = new Date();
    var month = dateObj.getMonth() + 1; //months from 1-12
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    this.minDate = { year: year, month: month, day: day };

    this.getInternalUserList();
    this.getRegularMeetingDetails();
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  noBlankSpaceAtStart(ev) {
    if (ev.which === 32 && ev.target.selectionStart === 0) {
      return false;
    }
  }

  checkValidDate() {
    this.invalidDate = false;
    var currentDate = new Date(
      new Date(
        new Date().getFullYear() +
          '/' +
          (new Date().getMonth() + 1) +
          '/' +
          new Date().getDate()
      )
    ).getTime();

    if (this.meetingDateModel != undefined) {
      var date1 = new Date(this.setDate(this.meetingDateModel));
      var meetDate = new Date(date1).toString();
      var date = new Date(
        new Date(
          new Date(meetDate).getFullYear() +
            '/' +
            (new Date(meetDate).getMonth() + 1) +
            '/' +
            new Date(meetDate).getDate()
        )
      ).getTime();

      if (date >= currentDate) {
        this.invalidDate = false;
      } else {
        this.invalidDate = true;
      }
    }
  }

  getInternalUserList() {
    this.userMasterService.getInternalUserList().then((response) => {
      if (response.status == 0) {
        if (_.size(response.result)) {
          this.allUserDetails = response.result;
          this.userList = [];
          this.userList = response.result.map(function (user) {
            return {
              label: user.FirstName + ' ' + user.LastName,
              value: user._id,
            };
          });
        }
        // console.log("this.userList=================", this.userList);
      }
    });
  }

  setDate(date) {
    if (date != '' && date != null) {
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }

  convertToJSONDate(date) {
    if (date != '' && date != null) {
      var dt = new Date(date);
      var newDate = new Date(
        Date.UTC(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          dt.getHours(),
          dt.getMinutes(),
          dt.getSeconds(),
          dt.getMilliseconds()
        )
      );
      return newDate.getTime();
    }
    return null;
  }

  check(event) {
    // console.log("meetingdate===============", $('.timepicker-init').val());
    this.regularMeetingForm.controls.meetingTime.setValue(
      $('.timepicker-init').val()
    );
  }

  uploadMultipleFile(fileInput: any) {
    // this.uploadMultipleFileDocument = [];
    this.count = fileInput.target.files.length;
    // this.uploadMultipleFileDocument = <File>fileInput.target.files[0]
    for (let i = 0; i < this.count; i++) {
      var checkfile = this.commonFunction.checkFileExtension(event);
      if (checkfile) {
        this.uploadMultipleFileDocument.push(<File>fileInput.target.files[i]);
        this.attachmentsArray.push(fileInput.target.files[i].name);
        this.multifileExtension = true;
      } else {
        this.multifileExtension = false;
      }
    }
    console.log(
      'uploadMultipleFileDocument list',
      this.uploadMultipleFileDocument
    );
  }

  deleteFile(filename) {
    var self = this;
    if (
      this.uploadMultipleFileDocument != [] &&
      this.uploadMultipleFileDocument.length != 0
    ) {
      this.uploadMultipleFileDocument.forEach(function (ele, index) {
        if (ele.name == filename) {
          self.uploadMultipleFileDocument.splice(index, 1);
          self.attachmentsArray.splice(index, 1);
        }
      });
    } else {
      self.attachmentsArrayForRemovingFile.forEach(function (ele, index) {
        if (ele.originalFileName == filename) {
          self.attachmentsArrayForRemovingFile.splice(index, 1);
          self.attachmentsArray.splice(index, 1);

          var gemURL = '';
          gemURL = environment.GEMURL;
          self.meetingMasterService
            .removeRegularMeetingFile(
              self.meetingObject.meetingId,
              ele.fileName,
              gemURL
            )
            .then((response) => {
              if (response.status == 0) {
                // self.alertPopUpForSuccess('Meeting File deleted successfully.');
              } else {
                // self.alertPopUpForFailure();
              }
            });
        }
      });
    }
    console.log(
      'UPDATED uploadMultipleFileDocument list',
      this.uploadMultipleFileDocument
    );
  }

  saveAndUpdateRegularMeeting() {
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    let date = new Date(this.setDate(this.meetingDateModel));
    this.regularMeetingForm.controls.meetingDate.setValue(date.toString());

    let meetingDate = moment(date).format('ddd MMM DD HH:mm:ss +0530 YYYY');
    var formatedMeetingDate = meetingDate.replace('00:00:00', time);

    var companyId = JSON.parse(localStorage.getItem('user')).CompanyId;
    var userdetails = JSON.parse(localStorage.getItem('user'));
    var self = this;
    if (
      self.regularMeetingForm.controls.meetingInvitees.value.includes(
        userdetails._id
      )
    ) {
    } else {
      self.regularMeetingForm.controls.meetingInvitees.value.push(
        userdetails._id
      );
    }
    console.log(
      'self.regularMeetingForm.controls.meetingInvitees.value=============',
      self.regularMeetingForm.controls.meetingInvitees.value
    );
    var userList = this.allUserDetails.map(function (ele) {
      if (
        self.regularMeetingForm.controls.meetingInvitees.value.includes(ele._id)
      ) {
        let inviteeObj = {
          userId: ele._id,
          firstName: ele.FirstName,
          lastName: ele.LastName,
          dateOfBirth: ele.DateOfBirth,
          emailId: ele.EmailId,
          userName: ele.UserName,
          mobileNumber: ele.MobileNumber,
          experience: ele.Experience,
          companyId: ele.CompanyId,
          companyName: ele.CompanyName,
          firstTimeLogin: ele.FirstTimeLogin,
          gender: ele.Gender,
          typeOfUser: ele.TypeOfUser,
          title: ele.Title,
          uniqueUserId: ele.UniqueUserId,
          designation: ele.Designation,
          password: ele.Password,
          areaOfExpertise: ele.AreaOfExpertise,
          pan: ele.PAN,
          din: ele.DIN,
          isActive: ele.IsActive,
          createdOn: ele.CreatedOn,
          secondaryEmailId: ele.SecondaryEmailId,
          isAuthorize: ele.IsAuthorize,
          mobileNumber2: ele.MobileNumber2,
          remarks: ele.Remarks,
          regularMeetingAttendanceStatus: 'UNCONFIRMED',
          isBlockedForAgenda: false,
          subsidiaryCompanies: ele.SubsidiaryCompanies,
          roles: ele.Roles,
        };
        self.selectedInviteesList.push(inviteeObj);
      }
    });
    console.log(
      'formatedMeetingDate.toString()===============',
      formatedMeetingDate.toString()
    );

    var meetingObject = {
      companyId: companyId,
      meetingPlace: this.regularMeetingForm.controls.meetingPlace.value,
      meetingTime: this.regularMeetingForm.controls.meetingTime.value,
      meetingDate: formatedMeetingDate.toString(),
      meetingName: this.regularMeetingForm.controls.meetingName.value,
      meetingInvitees: self.selectedInviteesList,
      createdByFullUserName: userdetails.FirstName + ' ' + userdetails.LastName,
      meetingType: 'REGULAR_MEETING',
      meetingTypeId: 'regular',
      latitude: '15',
      longitude: '33',
      createdByUser: {
        userId: userdetails._id,
        typeOfUser: userdetails.TypeOfUser,
        uniqueUserId: userdetails.UniqueUserId,
        areaOfExpertise: '',
        isActive: userdetails.IsActive,
        isAuthorize: true,
        regularMeetingAttendanceStatus: 'UNCONFIRMED',
        isBlockedForAgenda: false,
        subsidiaryCompanies: [],
        roles: userdetails.Roles,
      },
    };

    console.log(
      'Regular meetingObject=========================',
      meetingObject
    );

    var gemURL = '';
    gemURL = environment.GEMURL;

    if (this.meetingObject == 'null') {
      this.meetingMasterService
        .saveRegularMeeting(
          this.uploadMultipleFileDocument,
          safeJsonStringify(meetingObject),
          gemURL
        )
        .then((response) => {
          if (_.size(response)) {
            console.log('Console.log 1============', response);
            this.activeModal.close();
          }
        });
    } else {
      console.log(
        'update Regular meetingObject=========================',
        this.uploadMultipleFileDocument
      );
      this.meetingMasterService
        .updateRegularMeeting(
          this.meetingObject.meetingId,
          this.uploadMultipleFileDocument,
          safeJsonStringify(meetingObject),
          gemURL
        )
        .then((response) => {
          if (_.size(response)) {
            this.activeModal.close();
          }
        });
    }
  }

  getRegularMeetingDetails() {
    if (this.meetingObject != 'null') {
      this.addBtn = false;
      var meetingDetails = this.meetingObject;
      var meetDate = new Date(meetingDetails.meetingDate).toString();
      let meetingDate = {
        year: new Date(meetDate).getFullYear(),
        month: new Date(meetDate).getMonth() + 1,
        day: new Date(meetDate).getDate(),
      };
      let userIdArray = meetingDetails.meetingInvitees.map(function (element) {
        return element.userId;
      });

      // let date = new Date(this.setDate(meetingDate)).getTime();
      let currentDate = new Date(
        new Date(
          new Date().getFullYear() +
            '/' +
            (new Date().getMonth() + 1) +
            '/' +
            new Date().getDate()
        )
      ).getTime();
      let date = new Date(
        new Date(
          new Date(meetDate).getFullYear() +
            '/' +
            (new Date(meetDate).getMonth() + 1) +
            '/' +
            new Date(meetDate).getDate()
        )
      ).getTime();
      if (date >= currentDate) {
        this.invalidDate = false;
      } else {
        this.invalidDate = true;
      }

      this.regularMeetingForm.controls.meetingName.setValue(
        meetingDetails.meetingName
      );
      this.meetingDateModel = meetingDate;
      this.regularMeetingForm.controls.meetingPlace.setValue(
        meetingDetails.meetingPlace
      );
      this.regularMeetingForm.controls.meetingTime.setValue(
        meetingDetails.meetingTime
      );
      this.regularMeetingForm.controls.meetingInvitees.setValue(userIdArray);

      // File
      if (
        meetingDetails.attachments != null &&
        meetingDetails.attachments != 'undefined'
      ) {
        this.attachmentsArray = meetingDetails.attachments.map(function (
          element
        ) {
          return element.originalFileName;
        });
        this.attachmentsArrayForRemovingFile = meetingDetails.attachments;
      }
    } else {
      this.addBtn = true;
      this.regularMeetingForm.reset();
    }
  }
}
