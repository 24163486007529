import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RoleMasterService } from './../../services/rolemaster.service';
import { SharedModule } from '../../shared/sharedmodule';

import { ImageCropperModule } from 'ngx-img-cropper';
import { AuthGuard } from '../../authGuard/auth.guard';
import { RouteGuard } from '../../routeGaurd/route.guard';
import { CommaSeperatedNumber } from '../../helpers/CommaSeperatedNumber';
import { CommonFunction } from '../../helpers/commonFunction';
import { RolemasterComponent } from './rolemaster/rolemaster.component';
import { AddRoleModalComponent } from './add-role-modal/add-role-modal.component';
import { EditRoleModalComponent } from './edit-role-modal/edit-role-modal.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';

export const routes: Routes = [
  {
    path: 'role-master/roleMaster',
    component: RolemasterComponent,
    canActivate: [AuthGuard, RouteGuard],
    outlet: 'structure-outlet',
  },
  // { path: 'userMaster', component: UserManagementComponent ,outlet:'structure-outlet'}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataTablesModule,
    NgSelectModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ImageCropperModule,
  ],
  declarations: [
    RolemasterComponent,
    AddRoleModalComponent,
    EditRoleModalComponent,
    DeleteConfirmationModalComponent,
    // ImageCropperComponent
  ],
  providers: [RoleMasterService, CommonFunction],
  entryComponents: [
    AddRoleModalComponent,
    EditRoleModalComponent,
    DeleteConfirmationModalComponent,
  ],
  // exports: [
  //   ImageCropperComponent
  // ]
})
export class RoleMasterModule {}
