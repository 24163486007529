<section class="mt-4 mb-5 document">
	<div class="container" *ngIf="notRegularMeeting">
		<div class="row" *ngIf="isDodumentObjectEmpty">
			<div class="col-md-12 head">
				<div class="mr-auto">
        <h4><img src="/assets/images/documents-icon.png">Documents</h4>
				<h4>No Records Found</h4>
			</div>
			</div>
		</div>
    <div class="row"  *ngIf="!isDodumentObjectEmpty">
			<div class="col-md-12 head">
				<div class="mr-auto">
				<h4><img src="/assets/images/documents-icon.png" alt="document">Documents <span>({{count}})</span>:</h4>
			</div>
			</div>
		</div>
		<div class="row">
			<div *ngIf="!isDodumentObjectEmpty && isNoticeFileAvailable" class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ noticeFileName }}</strong>
          <div class="date">Added on: {{ noticeDate }} | {{ noticeTime }}</div>
          <div *ngIf="noticeFileType == 'PDF' || noticeFileType == 'pdf' " class="eye-btn">
            <button *ngIf="isNoticeFileAvailable" (click)="downloadDocumentFile(documentObject.noticeFile.docId,noticeFileType)">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
          <div *ngIf="noticeFileType != 'PDF' && noticeFileType != 'pdf' " class="eye-btn">
            <button *ngIf="isNoticeFileAvailable" (click)="downloadDocumentFile(documentObject.noticeFile.docId,noticeFileType)">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
			</div>
      <div *ngIf="!isDodumentObjectEmpty && isAgendaFileAvailable" class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ agendaFileName }}</strong>
          <div class="date">Added on: {{ agendaDate }} | {{ agendaTime }}</div>          
          <div *ngIf="agendaFileType == 'PDF' || agendaFileType == 'pdf' " class="eye-btn">
            <button *ngIf="isAgendaFileAvailable" (click)="downloadDocumentFile(documentObject.agendaFile.docId,agendaFileType)">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
          <div *ngIf="agendaFileType != 'PDF' && agendaFileType != 'pdf' " class="eye-btn">
            <button *ngIf="isAgendaFileAvailable" (click)="downloadDocumentFile(documentObject.agendaFile.docId,agendaFileType)">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
			</div>
      <div *ngFor="let agenda of documentObject.agendaFileArray"  class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ agenda.name }}</strong>
          <div class="date">Added on: {{ agenda.UploadDate | date:"shortDate"  }} | {{ agenda.UploadDate | date:"shortTime"  }}</div>          
          <div *ngIf="agenda.name.substr(-3) == 'PDF' || agenda.name.substr(-3) == 'pdf' " class="eye-btn">
            <button (click)="downloadDocumentFile(agenda.docId,agenda.name.substr(-3))">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
          <div *ngIf="agenda.name.substr(-3) != 'PDF' && agenda.name.substr(-3) != 'pdf' " class="eye-btn">
            <button (click)="downloadDocumentFile(agenda.docId,agenda.name.substr(-3))">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
			</div>         
      <div *ngIf="!isDodumentObjectEmpty && isDraftFileAvailable" class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ draftFileName }}</strong>
          <div class="date">Added on: {{ draftDate }} | {{ draftTime }}</div>          
          <td *ngIf="draftFileType == 'PDF' || draftFileType == 'pdf' " class="eye-btn">
            <button *ngIf="isDraftFileAvailable" (click)="downloadDocumentFile(documentObject.draftFile.docId,draftFileType)">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </td>
          <td *ngIf="draftFileType != 'PDF' && draftFileType != 'pdf' " class="eye-btn">
            <button *ngIf="isDraftFileAvailable" (click)="downloadDocumentFile(documentObject.draftFile.docId,draftFileType)">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </td>
        </div>
			</div>
      <div *ngIf="!isDodumentObjectEmpty && isFinalFileAvailable" class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ finalFileName }}</strong>
          <div class="date">Added on: {{ finalDate }} | {{ finalTime }}</div>         
          <div *ngIf="finalFiletype == 'PDF' || finalFiletype == 'pdf' " class="eye-btn">
            <button *ngIf="isFinalFileAvailable" (click)="downloadDocumentFile(documentObject.finalFile.docId,finalFiletype)">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
          <div *ngIf="finalFiletype != 'PDF' && finalFiletype != 'pdf' " class="eye-btn">
            <button *ngIf="isFinalFileAvailable" (click)="downloadDocumentFile(documentObject.finalFile.docId,finalFiletype)">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
			</div>
      <div *ngIf="!isDodumentObjectEmpty && isATRFileAvailable" class="col-lg-4 col-md-6 col-sm-6 mt-4 px-3">
        <div class="box">
          <strong>{{ ATRFileName }}</strong>
          <div *ngIf="ATRFileType == 'PDF' || ATRFileType == 'pdf' " class="eye-btn">
            <button *ngIf="isATRFileAvailable" (click)="downloadDocumentFile(documentObject.ATRFile.docId,ATRFileType)">
              <i class="fa fa-eye" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
          <div *ngIf="ATRFileType != 'PDF' && ATRFileType != 'pdf' " class="eye-btn">
            <button *ngIf="isATRFileAvailable" (click)="downloadDocumentFile(documentObject.ATRFile.docId,ATRFileType)">
              <i class="fa fa-download" style="font-size:15px" aria-hidden="true"> </i>
            </button>
          </div>
        </div>
			</div>      
    </div>
  </div>
</section>

<div *ngIf="iframeURL" class="modal fade" id="myModalForDocument" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" style="max-width:1090px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabelcompliance">
          <i class="fa fa-plus"></i>{{title}}
        </h5>
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body" style="padding: 0px">

        <div style="text-align: center;">
          <iframe [src]="iframeURL" style="width:100%; height:500px;" frameborder="0"></iframe>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>