import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/sharedmodule';

import { AuthGuard } from '../../authGuard/auth.guard';
import { CommaSeperatedNumber } from '../../helpers/CommaSeperatedNumber';
import { CommonFunction } from '../../helpers/commonFunction';
import { govevaFileManagerService } from '../../services/govevaFileManager.service';
import { RoadmapModuleService } from '../../services/roadmapModule.service';
import { GfmDashboardComponent } from '../govevaFileManager/gfm-dashboard/gfm-dashboard.component';
import { TagPopupComponentComponent } from '../govevaFileManager/tag-popup-component/tag-popup-component.component';
import { GfmPopupforShareComponent } from '../govevaFileManager/gfm-popupfor-share/gfm-popupfor-share.component';
import { GfmconfirmationPopupComponent } from '../govevaFileManager/gfmconfirmation-popup/gfmconfirmation-popup.component';

import { RouteGuard } from '../../routeGaurd/route.guard';
import { DragndropDirective } from './dragndrop.directive';
export const routes: Routes = [
  {
    path: 'gfmDashboard/:internalPath',
    component: GfmDashboardComponent,
    canActivate: [AuthGuard],
    outlet: 'structure-outlet',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataTablesModule,
    NgSelectModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
  ],

  declarations: [
    TagPopupComponentComponent,
    GfmPopupforShareComponent,
    GfmconfirmationPopupComponent,
    DragndropDirective
  ],
  providers: [CommonFunction,RoadmapModuleService,govevaFileManagerService],
  entryComponents: [
    TagPopupComponentComponent,
    GfmPopupforShareComponent,
    GfmconfirmationPopupComponent,
  ],
})
export class govevaFileManagerModule {}
