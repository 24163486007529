<!-- START: components/nestable -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Nestable</strong>
            <a href="https://github.com/dbushell/Nestable" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Basic</strong></h5>
                <p class="text-muted">Element: read <a href="https://github.com/dbushell/Nestable" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="dd" id="nestable1">
                        <ol class="dd-list">
                            <li class="dd-item" data-id="1">
                                <div class="dd-handle">Level 1</div>
                            </li>
                            <li class="dd-item" data-id="2">
                                <div class="dd-handle">Level 1</div>
                                <ol class="dd-list">
                                    <li class="dd-item" data-id="3"><div class="dd-handle">Level 2</div></li>
                                    <li class="dd-item" data-id="4"><div class="dd-handle">Level 2</div></li>
                                    <li class="dd-item" data-id="5">
                                        <div class="dd-handle">Level 2</div>
                                        <ol class="dd-list">
                                            <li class="dd-item" data-id="6"><div class="dd-handle">Level 3</div></li>
                                            <li class="dd-item" data-id="7"><div class="dd-handle">Level 3</div></li>
                                            <li class="dd-item" data-id="8"><div class="dd-handle">Level 3</div></li>
                                        </ol>
                                    </li>
                                    <li class="dd-item" data-id="9"><div class="dd-handle">Level 2</div></li>
                                    <li class="dd-item" data-id="10"><div class="dd-handle">Level 2</div></li>
                                </ol>
                            </li>
                            <li class="dd-item" data-id="11">
                                <div class="dd-handle">Level 1</div>
                            </li>
                            <li class="dd-item" data-id="12">
                                <div class="dd-handle">Level 1</div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Draggable by Icon</strong></h5>
                <p class="text-muted">Element: read <a href="https://github.com/dbushell/Nestable" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="dd" id="nestable2">
                        <ol class="dd-list">
                            <li class="dd-item dd3-item" data-id="13">
                                <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 1</div>
                            </li>
                            <li class="dd-item dd3-item" data-id="14">
                                <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 1</div>
                            </li>
                            <li class="dd-item dd3-item" data-id="15">
                                <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 1</div>
                                <ol class="dd-list">
                                    <li class="dd-item dd3-item" data-id="16">
                                        <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 2</div>
                                    </li>
                                    <li class="dd-item dd3-item" data-id="17">
                                        <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 2</div>
                                    </li>
                                    <li class="dd-item dd3-item" data-id="18">
                                        <div class="dd-handle dd3-handle">Drag</div><div class="dd3-content">Level 2</div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/carousel -->
