import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Group } from './Group/Group';
import { Peoples } from './Peoples/Peoples';
import { Messages } from './Messages/Messages';
import {
  LocalDataStorageProps,
  PersonalDetails,
  RoomDetailsProps,
  SendMessage,
} from './types';
import { Membership } from './Membership/membership';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { safeJsonStringify } from '../../../shared/utils';

@Component({
  selector: 'app-webx-chat',
  templateUrl: './webx-chat.component.html',
  styleUrls: ['./webx-chat.component.css'],
})
export class WebxChatComponent implements OnInit {
  peopleInstance = new Peoples();
  groupInstance = new Group();
  messagesInstance = new Messages();
  membershipInstance = new Membership();

  messageLoopInstance: any;

  @Input() isLoading: boolean = true;
  rooms = [];
  searchResult = [];
  participentsSearchResult = [];
  files: Array<any> = [];
  imageData: Array<any> = [];
  groupParticipents: Array<PersonalDetails> = [];
  personalDetails: PersonalDetails;
  selectedRoom: RoomDetailsProps;
  groupCreationDialog = false;
  webexToken: string = null;
  webexAuthWindow: Window;
  authCodeInterval: any;
  groupCreationModalRef: NgbModalRef;
  showToast = true;
  chatInputListener;

  constructor(
    private router: Router,
    private _location: Location,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.webexToken = localStorage.getItem('WebexAcessToken');
    let localData = localStorage.getItem('webexChat');
    let values: LocalDataStorageProps = localData
      ? JSON.parse(localData)
      : null;

    if (values !== null) {
      this.personalDetails = values.personalDetails;
      this.rooms = values.rooms || [];
    }

    if (this.webexToken !== null) {
      await this.initiateChats();
      this.startLoop();
      this.isLoading = false;

      $(document).ready(() => {
        $('#search-input').on('change paste keyup', (e) => {
          e.preventDefault();
          var searchQuery = $('#search-input').val();
          if (searchQuery !== '' && typeof searchQuery !== 'undefined') {
            this.searchResult = this.rooms.filter((v) => {
              return v.title
                .toLowerCase()
                .includes(searchQuery.toString().toLowerCase());
            });
            console.log(this.searchResult);
          } else {
            this.searchResult = [];
          }
        });
      });
    } else {
      this.openAuthWindow();
    }
  }

  openAuthWindow() {
    var w = 600;
    var h = 600;
    var left = Number((screen.width / 2) - (w / 2));
    var tops = Number((screen.height / 2) - (h / 2));
    var token = localStorage.getItem("WebexAcessToken")

    if(token !== null){
    window.open('https://idbroker.webex.com/idb/saml2/jsp/doSSO.jsp?type=logout', 'popup', 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
    }

    setTimeout(async () => {

      var token = localStorage.getItem("WebexAcessToken")
      if(token !== null){
      this.webexAuthWindow = window.open(environment.webexUrl, 'popup', 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
      }

      this.authCodeInterval = setInterval(() => {
        if (this.webexAuthWindow.closed) {
          if (token !== null) {
            console.log(token);
            this.webexToken = token;
            //this.initiateChats()
            this.ngOnInit();
          }
          clearInterval(this.authCodeInterval);
        }
      }, 1000);
    }, 1800);
  }

  async initiateChats() {
    try {
      console.log('Initiaing chats...');
      // Get Personal Details
      let personalDetails: PersonalDetails =
        await this.peopleInstance.getPersonalData();
      if (
        typeof this.personalDetails !== 'undefined' &&
        personalDetails.id !== this.personalDetails.id
      ) {
        this.personalDetails = personalDetails;
        this.rooms = [];
      } else {
        this.personalDetails = personalDetails;
      }

      //List Rooms
      if (typeof this.rooms === 'undefined' || this.rooms.length === 0) {
        let res = await this.groupInstance.listRooms({
          type: 'group',
          sortBy: 'lastactivity',
        });
        const { items }: any = res;
        if (typeof items === 'undefined') throw new Error('got undefiend');
        this.rooms = [...items];

        console.log(this.rooms);

        // List participents and single message of each rooms
        this.rooms.forEach(async (val, ind) => {
          try {
            let msgRes: any = await this.messagesInstance.listMessages({
              roomId: val.id,
              max: '1',
            });
            let participentsRes: any =
              await this.membershipInstance.listParticipent(val.id);
            if (typeof participentsRes.items === 'undefined')
              throw participentsRes;
            this.rooms[ind].participents = participentsRes.items;
            if (typeof msgRes.items === 'undefined') throw msgRes;
            this.rooms[ind].text = msgRes.items[0].text;
          } catch (error) {
            console.log(error);
          }
        });
        console.log(this.rooms);
      }
    } catch (error) {
      console.log(error);
    }
  }

  startLoop() {
    this.messageLoopInstance = setInterval(() => {
      if (typeof this.selectedRoom !== 'undefined' && this.shouldUpdate()) {
        this.messagesInstance
          .listMessages({ roomId: this.selectedRoom.id })
          .then((res: any) => {
            if (
              typeof res.items !== 'undefined' &&
              safeJsonStringify(this.selectedRoom.messages) !==
                safeJsonStringify(res.items.reverse())
            ) {
              this.selectedRoom.messages = [...res.items];
              scrollToEnd();
            }
          })
          .catch((err) => {
            console.log(err);
            scrollToEnd();
          });
      }
      this.getRoomList();
    }, 2 * 1000);
  }

  getRoomList() {
    this.groupInstance
      .listRooms({ type: 'group', sortBy: 'created' })
      .then((res) => {
        const { items }: any = res;
        var resRoom = [...items].reverse();
        var currentRooms = [...this.rooms].sort((a, b) => {
          var date1: any = new Date(a.created);
          var date2: any = new Date(b.created);
          return date1 - date2;
        });

        var filteredRooms = [];
        resRoom.forEach((val, ind) => {
          if (typeof currentRooms[ind] !== 'undefined') {
            if (val.lastActivity !== currentRooms[ind].lastActivity) {
              filteredRooms.push(val);
            }
            currentRooms[ind] = {
              ...currentRooms[ind],
              ...val,
            };
          } else {
            currentRooms.push(val);
            filteredRooms.push(val);
          }
        });

        this.rooms = [...currentRooms].sort(sortByLastActivity).reverse();

        filteredRooms.forEach((val, ind) => {
          var index = _.findIndex(
            this.rooms,
            (room, ind) => room.id === val.id
          );
          this.messagesInstance
            .listMessages({ roomId: val.id, max: '1' })
            .then((res: any) => {
              console.log('messages', res);
              this.rooms[index].text = res.items[0].text;
              //
              if (typeof this.selectedRoom !== 'undefined') {
                if (
                  this.personalDetails.emails[0] !== res.items[0].personEmail &&
                  this.selectedRoom.id !== val.id
                ) {
                  this.rooms[index].unreaded = this.rooms[index].unreaded
                    ? this.rooms[index].unreaded + 1
                    : 1;
                }
              } else {
                this.rooms[index].unreaded = this.rooms[index].unreaded
                  ? this.rooms[index].unreaded + 1
                  : 1;
              }
            });
          this.membershipInstance.listParticipent(val.id).then((res: any) => {
            console.log('pR', res);
            this.rooms[index].participents = res.items;
          });
        });
      })
      .catch((err) => console.log(err));
  }

  onInputKeyPress(e) {
    if (e.key === 'Enter') {
      this.sendMessage();
    }
  }

  shouldUpdate() {
    if (!this.groupCreationDialog) {
      return true;
    }
    return false;
  }

  sendMessage() {
    //@ts-ignore
    var message = document.getElementById('chat-input').value;
    if (message !== '' || this.files.length !== 0) {
      var query: SendMessage = {
        [this.selectedRoom.type !== 'person' ? 'roomId' : 'toPersonId']:
          this.selectedRoom.id || this.selectedRoom.emails[0],
        markdown: message,
      };
      if (this.files.length !== 0) {
        query['files'] = this.files[0];
      }
      console.log(query);
      this.messagesInstance
        .sendMessage(query)
        .then((res) => {
          console.log(res);
          this.selectedRoom.messages = [...this.selectedRoom.messages, res];
          //@ts-ignore
          document.getElementById('chat-input').value = '';
          scrollToEnd();
        })
        .catch((err) => console.log(err));
      this.cancelAttachments();
    }
  }

  selectRoom(room: any) {
    console.log(room);
    if (typeof room.title !== 'undefined') {
      this.selectedRoom = room;
      let index = _.findIndex(this.rooms, (val) => val.id === room.id);
      this.rooms[index].unreaded = 0;
      this.messagesInstance
        .listMessages({ roomId: this.selectedRoom.id })
        .then((res: any) => {
          if (
            typeof res.items !== 'undefined' &&
            safeJsonStringify(this.selectedRoom.messages) !==
              safeJsonStringify(res.items.reverse())
          ) {
            this.selectedRoom.messages = [...res.items];
            //this.selectedRoom.unreaded = 0
            scrollToEnd();
          }
        })
        .catch((err) => {
          console.log(err);
          scrollToEnd();
        });
    }
  }

  searchPeople(searchQuery) {
    var query = $('#search-participents').val();
    this.peopleInstance
      .fromString(searchQuery || query)
      .then((res) => {
        const { items }: any = res;
        this.participentsSearchResult = [...items];
        console.log('search', res);
      })
      .catch((err) => console.log(err));
  }

  createGroup() {
    var groupSubject: any = $('#group-subject').val();
    var msg: string = $('#initialMsg').val().toString();
    if (this.groupParticipents.length >= 1) {
      this.groupInstance
        .createRoom({ title: groupSubject })
        .then((res: any) => {
          this.groupParticipents.forEach((item, index) => {
            console.log(item);
            this.membershipInstance
              .addParticipent({ roomId: res.id, personId: item.id })
              .then((membershipRes) => {
                console.log('add member res', membershipRes);
              })
              .catch((membershipErr) => {
                console.log('add member res', membershipErr);
              });
          });
          this.messagesInstance.sendMessage({
            roomId: res.id,
            markdown: msg,
          });
          this.hideGroupCreationDialog();
        })
        .catch((err) => {
          console.log(err);
          this.hideGroupCreationDialog();
        });
    } else {
      alert('Group must contain more than one participents.');
    }
  }

  openBrower(event) {
    $('#file-input').click();
  }

  handleFileInput(files: FileList) {
    try {
      const fileTypes = ['png', 'jpeg', 'jpg'];
      let fileExt = files[0].type.split('/')[1];
      if (!fileTypes.includes(fileExt)) throw 'Invalid file type';
      this.files.push(files[0]);
      var fr = new FileReader();
      if (FileReader && files.length) {
        fr.onload = () => {
          this.imageData.push(fr.result);
        };
        for (let i = 0; i < files.length; i++) {
          fr.readAsDataURL(files[i]);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }

  cancelAttachments() {
    this.files = [];
    this.imageData = [];
  }

  getFormattedTime(date) {
    var today = new Date(date);
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + '-' + m + '-' + d + '-' + h + '-' + mi + '-' + s;
  }

  downloadAttachment(url: string, item, index) {
    console.log(item, index);
    var date = this.getFormattedTime(item.created);
    this.messagesInstance
      .downloadAttachment(url)
      .then((blobdata) => {
        var url = window.URL.createObjectURL(blobdata);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'IMG-' + date;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => console.log(err));
  }

  deleteAttachment(index) {
    //this.files.splice(index, 1)
  }

  uploadDragedFile() {
    console.log('hit');
  }

  getFileName(params) {
    this.messagesInstance.getFileName(params);
  }

  addParticepent(user) {
    if (this.groupParticipents.findIndex((o) => o.id === user.id) < 0) {
      this.groupParticipents.push(user);
    } else {
      alert('User already added');
    }
  }

  removeParticipent(index) {
    this.groupParticipents.splice(index, 1);
  }

  showGroupCreationDialog(content) {
    this.groupCreationModalRef = this.modalService.open(content, {
      backdrop: true,
    });

    $('#search-participents').on('change paste keyup', (e) => {
      console.log(e);
      console.log($('#search-participents').val());
      var searchQuery = $('#search-participents').val();
      if (searchQuery !== '' && typeof searchQuery !== 'undefined') {
        this.searchPeople(searchQuery);
      } else {
        this.participentsSearchResult = [];
      }
    });
  }

  hideGroupCreationDialog() {
    this.groupCreationModalRef.close();
    this.groupParticipents = [];
    this.participentsSearchResult = [];
  }

  clearSearch() {
    $('#search-input').val('');
    this.searchResult = [];
  }

  getCreatedDate(dateStr) {
    const monthArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateObj = new Date(dateStr);
    let date = dateObj.getDate();
    let month = monthArr[dateObj.getMonth()];
    let year = dateObj.getFullYear().toString().slice(2, 4);
    var strTime = dateObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return date + ' ' + month + ' ' + year + ' | ' + strTime;
  }

  getDisplayNameOfParticipants(email) {
    const { participents } = this.selectedRoom;
    let obj = _.find(participents, (o) => o.personEmail === email);
    return obj.personDisplayName || email;
  }

  ngOnDestroy() {
    clearInterval(this.messageLoopInstance);

    if (this.webexToken) {
      let values: LocalDataStorageProps = {
        personalDetails: this.personalDetails,
        rooms: this.rooms,
      };
      localStorage.setItem('webexChat', safeJsonStringify(values));
    }
  }
}

function scrollToEnd() {
  var messageBox = $('.chat-section'); // document.getElementsByClassName("chat-section").item(0)
  setTimeout(() => {
    messageBox.scrollTop(messageBox.prop('scrollHeight'));
    console.log(messageBox.prop('scrollHeight'));
  }, 1 * 1000);
}

function sortByLastActivity(a: any, b: any) {
  var date1: any = new Date(a.lastActivity);
  var date2: any = new Date(b.lastActivity);
  return date1 - date2;
}

function filterGroups(val, ind) {
  return val.type === 'group';
}
