import { Component, OnInit } from '@angular/core';
import { OTPLoginComponent } from './../../otp-login/otp-login.component';
import { OTPLoginService } from './../../services/otp-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './../notification/notification.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { safeJsonStringify } from '../../shared/utils';

import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

@Component({
  providers: [OTPLoginComponent],
  selector: 'app-submit-otp',
  templateUrl: './submit-otp.component.html',
  styleUrls: ['./submit-otp.component.css'],
})
export class SubmitOtpComponent implements OnInit {
  public otpCode: string;
  isADUser: boolean = false;
  adUserEmailId: string;
  adUserId: string;
  adUserPassword: string;
  secretKey = 'Secret message';
  showSpinner: Boolean = false;
  otpId: any;
  verifyOTPForm = new FormGroup({
    otpCode: new FormControl('', [Validators.required]),
  });
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: OTPLoginService,
    private modalService: NgbModal,
    private loginComponent: OTPLoginComponent
  ) { }

  ngOnInit() {
    this.otpId = this.route.snapshot.paramMap.get('otpId');
    console.log('this.otpId', this.otpId);
    // const userCredential = this.loginService.getUserCredential();
    // if(!userCredential.length) {
    //   this.router.navigate(['Submit/LoginOTP'])
    // }
    var token = localStorage.getItem('token');
    if (_.isNil(token)) {
      // this.getCompanies();
      this.hideTopBar();
    } else {
      //if already login
      this.showTopBar();
      // this.router.navigate(["dashboards/alpha"]);
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
    }
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }
  onlyNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 95 && keyCode < 106) ||
      keyCode == 8
    ) {
      // if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106))) {
      // ev.preventDefault();
      // }
    } else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      } else {
        ev.preventDefault();
      }
    }
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  /**
   *  OTP VERIFICATION FOR LOGIN
   *  Below function is used to verify the OTP for login.
   *  If mobile number does not exist into the database user will not able to login.
   */
  submitOTP() {
    const self = this;
    const userCredential = this.loginService.getUserCredential();
    console.log('userCredential', userCredential);
    var emailId = localStorage.getItem('emailId');
    var companyId = localStorage.getItem('companyId');
    var userToken = localStorage.getItem('userToken');
    var userLoginToken = '';
    var isResendOTP = false;
    if (userCredential.length) {
      userLoginToken = userCredential[0].userToken;
    } else {
      console.log('user otp token----->', this.otpId);
      if (this.otpId != undefined && this.otpId != null) {
        userLoginToken = this.otpId;
        isResendOTP = true;
      } else {
        userLoginToken = userToken;
      }
    }

    const submitObj = {
      userToken: userLoginToken,
      SecretCode: self.otpCode,
      isResendOTP: isResendOTP,
    };
    this.showSpinner = true;
    this.loginService.submitOTP(submitObj).then(async (response) => {
      if (!response.status) {
        // navigate to the next page.
        this.loginService.clearUserCredential();
        this.loginService.setUserCredential(response);
        // modalRef.componentInstance.notify = {status: 'Success', message: 'OTP is confirmed!'};

        //User login with OTP

        var checkUserDetails = {
          EmailId: response.userDetails.EmailId,
          CompanyId: response.userDetails.CompanyId,
        };
        emailId = response.userDetails.EmailId;
        companyId = response.userDetails.CompanyId;
        console.log('user Response details', checkUserDetails);
        var getUserEmail = await this.loginService.getUserEmail(
          checkUserDetails
        );
        // .then(userEmailResult => {
        console.log('userEmailResult', getUserEmail);
        if (!getUserEmail.status) {
          if (getUserEmail.userDetails[0].ADID) {
            this.isADUser = true;
            this.adUserEmailId = getUserEmail.userDetails[0].EmailId;
            this.adUserId = getUserEmail.userDetails[0].ADID;
          }
        }

        if (this.isADUser) {
          var loginWithoutPassword = {
            UserName: this.adUserEmailId,
            CompanyId: companyId,
          };
        } else {
          var loginWithoutPassword = {
            UserName: emailId,
            CompanyId: companyId,
          };
        }

        this.loginService
          .loginWithoutPassword(loginWithoutPassword)
          .subscribe((data) => {
            // @ts-ignore
            if (data.status == 0) {
              localStorage.setItem('CopyEmailAddress',  localStorage.getItem('emailId'));
              localStorage.removeItem('userToken');
              //localStorage.removeItem('companyEVA')
              localStorage.removeItem('user');
              localStorage.removeItem('companyDetailsGoveva');
              localStorage.removeItem('resources');
              localStorage.removeItem('rocketUserId');
              localStorage.removeItem('token');
              localStorage.removeItem('rocketAuth');
              localStorage.removeItem('companyDetails');
              localStorage.removeItem('emailId');
              localStorage.removeItem('companyId');
              // set localStroage
              var companyEVA = localStorage.getItem('companyEVA');
              sessionStorage.setItem('SessionName', 'userLoggedIn');
              if (!companyEVA) {
                sessionStorage.setItem('companyEVA', "null");
              } else {
                sessionStorage.setItem('companyEVA', companyEVA);
              }
              // @ts-ignore
              localStorage.setItem('user', safeJsonStringify(data.user));
              localStorage.setItem(
                'companyDetailsGoveva',
                safeJsonStringify({
                  // @ts-ignore
                  label: data.user.CompanyName,
                  // @ts-ignore
                  value: data.user.CompanyId,
                })
              );

              localStorage.setItem(
                'resources',
                // @ts-ignore
                safeJsonStringify(data.resources)
              );
              // @ts-ignore
              localStorage.setItem('token', data.token);
              localStorage.setItem(
                'companyDetails',
                safeJsonStringify({
                  // @ts-ignore
                  companyName: data.user.CompanyName,
                  // @ts-ignore
                  companyID: data.user.CompanyId,
                })
              );
              // if (data.user.FirstTimeLogin) {
              //   this.router.navigate(['/changePassword']);
              // } else {
              this.showSpinner = false;
              this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              if (this.returnUrl != '/') {
                try {
                  var check64 = btoa(atob(this.returnUrl)) == this.returnUrl;
                } catch (err) {
                  var check64 = false;
                }
                if (check64 == true) {
                  this.returnUrl = atob(this.returnUrl);
                } else {
                  this.returnUrl = this.returnUrl;
                }
                if (_.includes(this.returnUrl, 'http')) {
                  window.open(this.returnUrl, '_self');
                } else {
                  const urlAfterSplit = this.returnUrl.split(':');
                  const lastElement = urlAfterSplit[urlAfterSplit.length - 1];
                  const redirectUrl = _.first(lastElement.split(')'));
                  this.router.navigate([
                    'structure',
                    { outlets: { 'structure-outlet': redirectUrl } },
                  ]);
                  // .then(() => {
                  //   location.reload();
                  // });
                }
              }
              else {
                this.router.navigate([
                  'structure',
                  { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                ]);
              }

              // }
            }
          });
      } else {
        // show the error message
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = {
          status: 'Failure',
          message: response.message,
        };
        if (response.blockOTP === true) {
          this.router.navigate(['login']);
        }
      }
    });
  }

  changePassword() {
    this.router.navigate(['/changePassword']);
  }

  async sendOTP() {
    var emailId = localStorage.getItem('emailId');
    var companyId = localStorage.getItem('companyId');
    const mobileNumber = localStorage.getItem('mobileNumber');
    var checkUserDetails = {
      EmailId: emailId,
      CompanyId: companyId, // check for the company ID
      MobileNumber: mobileNumber,
    };

    var getUserEmail = await this.loginService.getUserEmail(checkUserDetails);

    // .then(userEmailResult => {
    console.log('userEmailResult', getUserEmail);
    if (!getUserEmail.status) {
      if (getUserEmail.userDetails[0].ADID) {
        this.isADUser = true;
        this.adUserEmailId = getUserEmail.userDetails[0].EmailId;
        console.log('this.adUserEmailId', this.adUserEmailId);
      }
    } else {
      const modalRef = this.modalService.open(NotificationComponent);
      modalRef.componentInstance.notify = {
        status: 'Failure',
        message: getUserEmail.message,
      };
      return;
    }

    // });
    if (this.isADUser) {
      console.log('emailId', this.adUserEmailId);
      var loginOTPObject = {
        EmailId: this.adUserEmailId,
        CompanyId: companyId, // check for the company ID
        MobileNumber: mobileNumber,
      };
      console.log('loginOTPObject', loginOTPObject);
    } else {
      var loginOTPObject = {
        EmailId: emailId,
        CompanyId: companyId, // check for the company ID
        MobileNumber: mobileNumber,
      };
    }

    this.loginService.wrongOTPAttemptCheck(loginOTPObject).then((result) => {
      if (!result.status) {
        if (result.SecretCodeAttempt >= 5) {
          const modalRef = this.modalService.open(NotificationComponent);
          modalRef.componentInstance.notify = {
            status: 'Failure',
            message:
              'You have entered the wrong OTP five times.It will unblock after 15 minutes. Old OTP will not work after 15 minutes, So Generate new OTP after 15 Minutes.',
          };

          this.router.navigate(['login']);
        } else {
          this.loginService.submitEmail(loginOTPObject).then((response) => {
            if (!response.status) {
              localStorage.removeItem('userToken');
              localStorage.setItem('emailId', emailId);
              localStorage.setItem('companyId', companyId);
              this.loginService.clearUserCredential();
              this.loginService.setUserCredential(response);
              localStorage.setItem('userToken', response.userToken);
              // this.router.navigate(["Submit/LoginOTP"])
              const modalRef = this.modalService.open(NotificationComponent);
              modalRef.componentInstance.notify = {
                status: 'Success',
                message: response.message,
              };
            } else {
              // show error message to the user
              const modalRef = this.modalService.open(NotificationComponent);
              modalRef.componentInstance.notify = {
                status: 'Failure',
                message: response.message,
              };
              // this.modalService.open(NotificationComponent).result.then(result => {

              // })
            }
          });
        }
      } else {
        // show error message to the user
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = {
          status: 'Failure',
          message: result.message,
        };
      }
    });
  }
}
