import { Component, OnInit, Input } from '@angular/core';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { CompanyMasterService } from '../../../services/companymaster.service';

import { MeetingdetailsComponent } from '../meetingdetails/meetingdetails.component';

import {
  NgbDateStruct,
  NgbModal,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DashboardMyTaskService } from './../../../services/dashboardmytask.service';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { RegularMeetingModalComponent } from '../regular-meeting-modal/regular-meeting-modal.component';
import { Router } from '@angular/router';

declare var $: any;
declare var jQuery: any;

type SORT_TYPE = 'NAME' | 'TIME';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css'],
})
export class MeetingComponent implements OnInit {
  // @Input('meetingList')meetingList : any;

  displayLabel: any;
  meetingListCache: any = [];
  meetingList: any;
  meetingStatus: any;
  todayDate: any;
  isMeetingListEmpty: boolean;
  regularMeetingList: any;
  regularAndComplianceMeetingList: any = [];
  deleteMeetingId: any;
  isRegularMeetingConfig = environment.Service.RegularMeeting;
  sortByType: SORT_TYPE = 'TIME';
  isMeetingsFetch: any;

  constructor(
    private router: Router,
    private companyMasterService: CompanyMasterService,
    private meetingMasterService: MeetingMasterService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private dashboardMyTaskService: DashboardMyTaskService
  ) {}

  ngOnInit() {
    this.displayLabel = 'Today';
    this.isMeetingListEmpty = false;
    this.todayDate = new Date().setHours(0, 0, 0, 0);

    // this.meetingMasterService.getRegularMeetingList().then(response => {
    //   //console.log("response for regular meeting list",response);
    // })
    this.isMeetingsFetch = localStorage.getItem('isMeetingsFetch');
    if (this.isMeetingsFetch != 'false') {
      this.getMeetingsList();
    }
  }

  getMeetingsList() {
    this.regularAndComplianceMeetingList = [];
    this.meetingListCache = [];
    console.log('In get');
    var companyId = JSON.parse(localStorage.getItem('user')).CompanyId;
    // //console.log("companyId", companyId);

    let userInfo = JSON.parse(localStorage.getItem('user'));
    let assignedToId = userInfo._id;
    let uniqueUserId = userInfo.UniqueUserId;

    this.dashboardMyTaskService
      .getUserIdbyUniqueUserId(uniqueUserId)
      .then((responseForCompanyIdandUserId) => {
        //console.log('responseForCompanyIdandUserId', responseForCompanyIdandUserId)
        if (!responseForCompanyIdandUserId.status) {
          let promise = [];
          for (
            var index = 0;
            index < responseForCompanyIdandUserId.listOfId.length;
            index++
          ) {
            promise.push(
              this.meetingMasterService.getMeetingList(
                responseForCompanyIdandUserId.listOfId[index].CompanyId
              )
            );
          }
          // promise.push(this.meetingMasterService.getRegularMeetingList())
          forkJoin(promise).subscribe((responseforMeetings) => {
            //console.log('responseforMeetings', responseforMeetings)
            // //console.log('responseforMeetings--- size',_.size(responseforMeetings));
            // this.regularMeetingList = responseforMeetings[_.size(responseforMeetings)-1] ;
            // //console.log("this.regularMeetingList",this.regularMeetingList);
            let errorObject = _.find(responseforMeetings, function (element) {
              return _.get(element, 'status') == 1;
            });
            if (_.isUndefined(errorObject)) {
              let userSpecificMeetingList: any = [];
              // let meetingList = _.reduce(responseforMeetings, function (currentElement, nextElement) {
              //   return _.concat(currentElement, nextElement)
              let tempArray = [];
              let meetingList = _.map(responseforMeetings, function (element) {
                // this logic has been implemented for regular meetings to included in meeting list

                if (!_.isUndefined(_.get(element, 'MeetingList'))) {
                  //console.log("element -- ",element)
                  return _.concat(tempArray, _.get(element, 'MeetingList'));
                } else {
                  //console.log("element -- else",element)
                  // this is regular meeting list
                  return _.concat(tempArray, element);
                }
              });

              //console.log("meetingList -- ", meetingList)

              // })
              meetingList = _.flattenDeep(meetingList);
              //console.log('meetingList', meetingList)

              if (_.size(meetingList)) {
                userSpecificMeetingList = _.filter(
                  meetingList,
                  function (meetingObject) {
                    if (_.size(_.get(meetingObject, 'Directors'))) {
                      var directorArray = _.find(
                        _.get(meetingObject, 'Directors'),
                        function (directorObject) {
                          if (
                            _.get(directorObject, 'UserGovevaId') != undefined
                          ) {
                            let assignedUser = _.find(
                              responseForCompanyIdandUserId.listOfId,
                              function (element) {
                                return (
                                  element._id ==
                                  _.get(directorObject, 'UserGovevaId')
                                );
                              }
                            );
                            if (!_.isUndefined(assignedUser)) {
                              // if (_.get(directorObject, 'UserGovevaId') == assignedToId) {
                              return directorObject;
                            }
                          }
                        }
                      );

                      if (_.size(directorArray)) {
                        return meetingObject;
                      }
                    }

                    if (_.size(_.get(meetingObject, 'Members'))) {
                      var memberArray = _.find(
                        _.get(meetingObject, 'Members'),
                        function (memberObject) {
                          let assignedUser = _.find(
                            responseForCompanyIdandUserId.listOfId,
                            function (element) {
                              return (
                                element._id ==
                                _.get(memberObject, 'UserGovevaId')
                              );
                            }
                          );
                          if (!_.isUndefined(assignedUser)) {
                            // if (_.get(memberObject, 'UserGovevaId') == assignedToId) {
                            return memberObject;
                          }
                        }
                      );

                      if (_.size(memberArray)) {
                        return meetingObject;
                      }
                    }

                    if (_.size(_.get(meetingObject, 'Invitees'))) {
                      var inviteesArray = _.find(
                        _.get(meetingObject, 'Invitees'),
                        function (inviteesObject) {
                          let assignedUser = _.find(
                            responseForCompanyIdandUserId.listOfId,
                            function (element) {
                              return (
                                element._id ==
                                _.get(inviteesObject, 'UserGovevaId')
                              );
                            }
                          );
                          if (!_.isUndefined(assignedUser)) {
                            // if (_.get(inviteesObject, 'UserGovevaId') == assignedToId) {
                            return inviteesObject;
                          }
                        }
                      );

                      if (_.size(inviteesArray)) {
                        return meetingObject;
                      }
                    }

                    if (_.size(_.get(meetingObject, 'meetingType'))) {
                      var meetingType = _.get(meetingObject, 'meetingType');
                      if (!_.isUndefined(meetingType)) {
                        // //console.log("regular meeting added",meetingType, 'REGULAR_MEETING');
                        if (meetingType == 'REGULAR_MEETING') {
                          return meetingObject;
                        }
                      }
                    }
                  }
                );
                //console.log('userSpecificMeetingList', userSpecificMeetingList)
                var self = this;
                this.meetingList = _.sortBy(
                  userSpecificMeetingList,
                  function (data) {
                    return new Date(data.MeetingDate);
                  }
                );
                this.regularAndComplianceMeetingList =
                  this.regularAndComplianceMeetingList.concat(this.meetingList);
                this.meetingListCache = this.meetingListCache.concat(
                  this.meetingList
                );
                console.log(
                  ' this.regularAndComplianceMeetingList-----1111111',
                  this.regularAndComplianceMeetingList
                );
                this.getRegularMeetings();
                if (_.size(userSpecificMeetingList)) {
                  // _.forEach(this.meetingList, function (o) {

                  //   var meetingdate = new Date(o.MeetingDate).setHours(0, 0, 0, 0);

                  //   if(o.meetingType == 'REGULAR_MEETING'){

                  //     //console.log("meetingdate",meetingdate,o.meetingDate,new Date(o.meetingDate));
                  //     meetingdate = new Date(o.meetingDate).setHours(0, 0, 0, 0);
                  //   }
                  //   // //console.log("meetingdate",meetingdate, "this.todayDate",self.todayDate);
                  //   if (meetingdate == self.todayDate) {
                  //     _.assign(o, { meetingStatus: "Today" })
                  //   } else if (meetingdate > self.todayDate) {
                  //     _.assign(o, { meetingStatus: "Future" })
                  //   } else if (meetingdate < self.todayDate) {
                  //     _.assign(o, { meetingStatus: "Past" })
                  //   }
                  // })

                  this.toAssignMeetingStatus();

                  this.isMeetingListEmpty = false;

                  this.filterMeetings('Today');
                } else {
                  this.isMeetingListEmpty = true;
                }
              }
            }
          });
        }
      });
  }

  getRegularMeetings() {
    // to get GEM URL First and then calling other function
    var gemURL = '';
    this.companyMasterService.getGEMUrl().then((responseCompany) => {
      //console.log("response GEM URL", responseCompany);
      if (responseCompany.status == 0) {
        var companyDetails = responseCompany.companyData;
        //console.log("response GEM URL companyDetails", companyDetails);
        if (_.size(companyDetails)) {
          gemURL = companyDetails[0].GEMAccessURL
            ? companyDetails[0].GEMAccessURL
            : '';
          //console.log("gemURL", gemURL);
          this.meetingMasterService
            .getRegularMeetingList(gemURL)
            .then((response) => {
              if (_.size(response)) {
                this.regularAndComplianceMeetingList =
                  this.regularAndComplianceMeetingList.concat(
                    response.regularMeetingList
                  );
                this.meetingListCache = this.meetingListCache.concat(
                  response.regularMeetingList
                );
                this.toAssignMeetingStatus();
                this.filterMeetings('Today');
                this.sortBy(this.sortByType);
              }
              //console.log(" response -------", response, this.regularAndComplianceMeetingList);
            });
        }
      }
    });

    // this.meetingMasterService.getRegularMeetingList().then(response => {

    //   if(_.size(response)){

    //     this.regularAndComplianceMeetingList = this.regularAndComplianceMeetingList.concat(response) ;
    //     this.meetingListCache = this.meetingListCache.concat(response);
    //     this.toAssignMeetingStatus()
    //   }
    //   //console.log(" response -------", response, this.regularAndComplianceMeetingList);
    // })
  }

  toAssignMeetingStatus() {
    var self = this;
    _.forEach(this.regularAndComplianceMeetingList, function (o) {
      var meetingdate = new Date(o.MeetingDate).setHours(0, 0, 0, 0);

      if (o.meetingType == 'REGULAR_MEETING') {
        //console.log("meetingdate",meetingdate,o.meetingDate,new Date(o.meetingDate));
        meetingdate = new Date(o.meetingDate).setHours(0, 0, 0, 0);
      }
      // //console.log("meetingdate",meetingdate, "this.todayDate",self.todayDate);
      if (meetingdate == self.todayDate) {
        _.assign(o, { meetingStatus: 'Today' });
      } else if (meetingdate > self.todayDate) {
        _.assign(o, { meetingStatus: 'Future' });
      } else if (meetingdate < self.todayDate) {
        _.assign(o, { meetingStatus: 'Past' });
      }
    });
  }

  filterMeetings(MeetingStatus) {
    this.displayLabel = MeetingStatus;
    // //console.log("displayLabel", this.displayLabel);
    var meetingListToShow: any;
    meetingListToShow = [];
    this.meetingList = [];

    var temptodaysDate = new Date(Date.parse(Date()));
    // //console.log("temptodaysDate",temptodaysDate);
    var todayDate = new Date().setHours(0, 0, 0, 0);

    switch (this.displayLabel) {
      case 'All':
        this.meetingStatus = this.displayLabel;
        // //console.log("meeting List All")
        // this.meetingList = this.meetingListCache;
        this.regularAndComplianceMeetingList = this.meetingListCache;
        // //console.log("meeting List ",this.meetingList)
        break;

      case 'Today':
        this.meetingStatus = this.displayLabel;
        meetingListToShow = _.filter(this.meetingListCache, function (o) {
          // setHours has been implemented as we compare date for whole day without time.
          var meetingdate = new Date(o.MeetingDate).setHours(0, 0, 0, 0);
          var RgmeetingDate = new Date(o.meetingDate).setHours(0, 0, 0, 0);
          if (RgmeetingDate == todayDate) {
            return o;
          }
          // //console.log("todayDate,meetingdate", todayDate, meetingdate);
          if (meetingdate == todayDate) {
            return o;
          }
        });

        // this.meetingList = meetingListToShow;
        this.regularAndComplianceMeetingList = meetingListToShow;
        break;

      case 'Future':
        this.meetingStatus = this.displayLabel;
        meetingListToShow = _.filter(this.meetingListCache, function (o) {
          var meetingdate = new Date(o.MeetingDate).setHours(0, 0, 0, 0);
          if (meetingdate > todayDate) {
            return o;
          }
          var RgmeetingDate = new Date(o.meetingDate).setHours(0, 0, 0, 0);
          if (RgmeetingDate > todayDate) {
            return o;
          }
        });
        // this.meetingList = meetingListToShow;
        this.regularAndComplianceMeetingList = meetingListToShow;
        break;

      case 'Past':
        this.meetingStatus = this.displayLabel;
        meetingListToShow = _.filter(this.meetingListCache, function (o) {
          var meetingdate = new Date(o.MeetingDate).setHours(0, 0, 0, 0);
          if (meetingdate < todayDate) {
            return o;
          }

          var RgmeetingDate = new Date(o.meetingDate).setHours(0, 0, 0, 0);
          if (RgmeetingDate < todayDate) {
            return o;
          }
        });
        // this.meetingList = meetingListToShow;
        this.regularAndComplianceMeetingList = meetingListToShow;
        break;

      default:
        this.meetingStatus = this.displayLabel;
        // this.meetingList = this.meetingListCache;
        this.regularAndComplianceMeetingList = this.meetingListCache;
        break;
    }
    this.sortBy(this.sortByType);
  }

  meetingModal(meetingDetails) {
    // //console.log("meetingDetails",meetingDetails);
    var self = this;
    const modalRef = this.modalService.open(MeetingdetailsComponent, {
      windowClass: 'modal-xlC',
    });
    modalRef.componentInstance.meetingObject = meetingDetails;
    modalRef.result
      .then(
        (response) => {
          console.log(
            'gjgjgj==66666666666666666666==================',
            response
          );
          var joinInPerson = localStorage.getItem('joinInPerson');
          if (joinInPerson != 'yes') {
            self.router.navigate(['webex', response]);
          } else {
            var meetingIdToSend = localStorage.getItem('meetingId');
            self.router.navigate(['/attending-inperson', response]);
          }
        },
        (error) => {
          //console.log("modalthenerror", error)
        }
      )
      .catch((error) => {
        //console.log("Catch Error", error)
      });
  }

  regularMeetingModal() {
    var self = this;
    const modalRef = this.modalService.open(RegularMeetingModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.meetingObject = 'null';
    modalRef.result
      .then(
        (response) => {
          console.log('regular meeting response================', response);
          this.alertPopUpForSuccess('Meeting saved successfully.');
          self.getMeetingsList();
        },
        (error) => {
          console.log('regular meeting errror================', error);
          // self.getMeetingsList();
          // //console.log("modalthenerror", error)
        }
      )
      .catch((error) => {
        console.log('regular meeting errror catch================');
        // self.getMeetingsList();
        // //console.log("Catch Error", error)
      });
  }

  loadRegularMeetingByID(meetingId) {
    // this.activeModal.close();
    var gemURL = '';
    gemURL = environment.GEMURL;
    this.meetingMasterService
      .getRegularMeetingById(meetingId, gemURL)
      .then((response) => {
        if (_.size(response)) {
          var self = this;
          const modalRef = this.modalService.open(RegularMeetingModalComponent);
          modalRef.componentInstance.meetingObject = response;
          modalRef.result
            .then(
              (response) => {
                self.alertPopUpForSuccess('Meeting updated successfully.');
                self.getMeetingsList();
              },
              (error) => {
                // //console.log("modalthenerror", error)
              }
            )
            .catch((error) => {
              // //console.log("Catch Error", error)
            });
        }
        console.log(' response -------', response);
      });
  }

  confirmDelete(id) {
    this.deleteMeetingId = id;
    $('#confirmDeleteMeetings').modal('toggle');
  }

  deleteRegularMeeting() {
    var gemURL = '';
    gemURL = environment.GEMURL;
    this.meetingMasterService
      .removeRegularMeeting(this.deleteMeetingId, gemURL)
      .then((response) => {
        if (response.status == 5) {
          this.alertPopUpForSuccess('Meeting deleted successfully.');
        } else {
          this.alertPopUpForFailure();
        }
        this.getMeetingsList();
      });
  }

  // ALERT
  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  alertPopUpForSuccess(message) {
    $('#alertTitle').text('Success!');
    $('#alertTitle').css('color', 'black');
    $('#alertMessage').text(message);
    $('#alertMessage').css('color', 'black');
    $('#messagePopUp').modal('toggle');
    this.messagePopUpTimeoutToogle();
  }

  alertPopUpForFailure() {
    $('#alertTitle').css('color', '#f00');
    $('#alertTitle').text('Failure!');
    $('#alertMessage').css('color', '#f00');
    $('#alertMessage').text('Oops, Something went wrong!');
    $('#messagePopUp').modal('toggle');
    this.messagePopUpTimeoutToogle();
  }

  sortBy(type: SORT_TYPE) {
    console.log(this.regularAndComplianceMeetingList);
    if (type === 'NAME') {
      this.sortByType = 'NAME';
      let arr: Array<object> = this.regularAndComplianceMeetingList;
      arr.sort(
        (a: any, b: any) =>
          a.MeetingTypeName.charCodeAt(0) - b.MeetingTypeName.charCodeAt(0)
      );
      this.regularAndComplianceMeetingList = arr;
    } else {
      this.sortByType = 'TIME';
      let arr: Array<object> = this.regularAndComplianceMeetingList;
      arr.sort((a: any, b: any) => {
        let date1 = new Date(a.MeetingDate).toLocaleDateString();
        let date2 = new Date(b.MeetingDate).toLocaleDateString();
        let time1 = Date.parse(date1 + ' ' + a.MeetingTime);
        let time2 = Date.parse(date2 + ' ' + b.MeetingTime);
        console.log(time1, time2);
        return time1 - time2;
      });
      this.regularAndComplianceMeetingList = arr;
    }
  }
}
