<!-- START: charts/c3 -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>C3</strong>
            <a href="http://c3js.org/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Simple Line</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-simple-line"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Spline</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-spline"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Step</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-step"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Area</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-area"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Scatter</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-scatter"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Bar</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-bar"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Stacked Bar</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-stacked-bar"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Combination</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-combination"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Sub Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-sub-chart"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Zoom</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-zoom"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Pie Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-pie-chart"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Donut Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://c3js.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-donut-chart"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: charts/c3 -->
