import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/sharedmodule';
import { AuthGuard } from '../../authGuard/auth.guard';
import { AuditComponent } from './audit/audit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuditTrailService } from './../../services/audit-trail.service';
import { UserMasterService } from './../../services/user-master.service';

export const routes: Routes = [
  {
    path: 'auditTrail',
    component: AuditComponent,
    canActivate: [AuthGuard],
    outlet: 'structure-outlet',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DataTablesModule,
    NgSelectModule,
  ],
  declarations: [AuditComponent],
  providers: [AuditTrailService, UserMasterService],
})
export class AuditTrailModule {}
