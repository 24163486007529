<div class="modal-content">
    <div class="modal-header">
        <H5 class="modal-title" >Agenda Compliance</H5> 
        <button type="button" class="close"   style="right:5px;position:absolute">
          <span aria-hidden="true" (click)="activeModal.dismiss('dismissed')">&times;</span>
        </button>
    </div>

<div class="modal-body">
    <div class="row">
      <div class="table-responsive">
        <table class="table table-hover compact cell-border nowrap stripe">
          <thead>
            <tr>
              <th>Task Name</th>
              <th>Task Description</th>
              <th>Group Name</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngIf="agendaComplianceObject.length == 0">
                <td colspan="5" class="text-center">
                  No Records Found.
                </td>
              </tr>
            <tr *ngFor="let compliance of agendaComplianceObject">
              <td>{{compliance.TaskName}}</td>
              <td>{{compliance.TaskDescription}}</td>
              <td>{{compliance.GroupName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-footer text-center">
      <button class="btn btn-sm" (click)="activeModal.dismiss('dismissed')">Close</button>
  </div>
</div>
  