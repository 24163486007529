import { Component, OnInit } from '@angular/core';
import { ChartsService } from '../../../../app/services/charts.service';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-social-data',
  templateUrl: './social-data.component.html',
  styleUrls: ['./social-data.component.css'],
})
export class SocialDataComponent implements OnInit {

  constructor(private chartsService: ChartsService) { }

  ngOnInit() {
  }
  show(){
    this.loadSocialData();
  }
  loadSocialData() {
      let { companyName } = JSON.parse(localStorage.getItem('companyDetails'))
      this.chartsService.socialData().then(resData => {
          if (typeof resData[companyName] == 'undefined') {
              $("#soc-output")[0].innerHTML = 'No data available'
          } else {
              $("#soc-output").pivotUI(
                  resData[companyName], {
                      rows: ["Category", "Gender"],
                      cols: ["Year"],
                      vals: ["Value"],
                      aggregatorName: "Sum",
                      rendererName: "Stacked Bar Chart",
                      renderers: $.extend(
                          $.pivotUtilities.renderers,
                          $.pivotUtilities.plotly_renderers),
                          rendererOptions: {
                          plotly: {
                              autosize:true,
                              width: 700,
                              height: 400,
                              autotypenumbers: "strict",
                              showlegend: true,
                              modebar:{
                                orientation:"v"
                              },
                              legend: {
                                  x: 0,
                                  y: -0.1,
                                  orientation: "h",
                                  tracegroupgap:5,
                                  traceorder:"grouped"
                              },
                              margin: {
                                b:10,
                                l:10,
                                r:10,
                                t:50
                              }
                          }
                      }
                  });

          }
      })
  }

}
