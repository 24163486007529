<!-- START: pages/lockscreen -->
<div class="cat__pages__login" style="background-image: url(assets/modules/pages/common/img/login/1.jpg)">
    <div class="cat__pages__login__header">
        <div class="row">
            <div class="col-lg-8">
                <div class="cat__pages__login__header__logo">
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 switch-to-fullscreen">Switch Fullscreen / Windowed</a>
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 random-bg-image" data-img="1">Randomize Background Image</a>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__block">
        <div class="row">
            <div class="col-xl-12">
                <div class="cat__pages__login__block__inner">
                    <div class="cat__pages__login__block__form">
                        <form id="form-validation" name="form-validation" method="POST">
                            <div class="text-center mb-3">
                                <span class="cat__core__avatar cat__core__avatar--border cat__core__avatar--90" href="javascript:void(0);">
                                    <img src="assets/modules/pages/common/img/login/avatar.jpg" alt="Alternative text to the image">
                                </span>
                            </div>
                            <h2 class="text-center">
                                <i class="icmn-lock"></i>
                                <strong>Artour Arteezy</strong>
                            </h2>
                            <br />
                            <div class="form-group">
                                <input id="validation-password"
                                       class="form-control password"
                                       name="validation[password]"
                                       type="password" data-validation="[L>=6]"
                                       data-validation-message="$ must be at least 6 characters"
                                       placeholder="Password">
                            </div>
                            <div class="form-actions text-center">
                                <button type="submit" class="btn btn-success">Unlock</button>
                            </div>
                            <div class="form-group text-center">
                                <a href="javascript: void(0);">Or sign in as a different user</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__footer text-center">

    </div>
</div>
<!-- END: pages/lockscreen -->
