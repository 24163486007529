import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { RoleMasterService } from "../../../services/rolemaster.service";

import { PermissionLinks } from "../../../enums/permissionLinks";

import { AddRoleModalComponent } from "../add-role-modal/add-role-modal.component";
import { EditRoleModalComponent } from "../edit-role-modal/edit-role-modal.component";
import { DeleteConfirmationModalComponent } from "../delete-confirmation-modal/delete-confirmation-modal.component";

import { Subject } from "rxjs";

import * as _ from "lodash";
declare var $: any;
declare var jQuery: any;

@Component({
  selector: "app-rolemaster",
  templateUrl: "./rolemaster.component.html",
  styleUrls: ["./rolemaster.component.css"],
})
export class RolemasterComponent implements OnInit {
  listOfRoleNames: any;
  loggedInUserInfo: any;
  isGuestSuperAdmin: boolean;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  constructor(private roleMasterService: RoleMasterService, private modalService: NgbModal) {
    this.loggedInUserInfo = JSON.parse(localStorage.getItem("user")).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }
  }

  ngOnInit() {
    $("body").removeClass("dashboard-page");

    this.dtOptions = {
      destroy: true,
      pagingType: "full_numbers",
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: "<<",
          last: ">>",
          next: ">",
          previous: "<",
        },
      },
    };

    this.loggedInUserInfo = JSON.parse(localStorage.getItem("user")).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }

    this.listOfRoles();
  }

  //to get list of all Role Names with dt.trigger which is used to rerender datatable
  listOfRoles() {
    this.listOfRoleNames = [];
    // $('#roleMasterTable').DataTable({ destroy: true }).destroy();
    this.roleMasterService.getRolesList().then(
      (response) => {
        this.listOfRoleNames = _.orderBy(response.RolesList, ["CreatedOn"], ["desc"]);

        if (this.isGuestSuperAdmin) {
          this.listOfRoleNames = _.filter(response.RolesList, function (role) {
            // if (role.RoleName != PermissionLinks.Admin) {
              // if( role.RoleName != PermissionLinks.UPSIAdmin) {
              return role;
              // }
            // }
          })
        }
        this.dtTrigger.next();
        // setTimeout(() => {this.dtTrigger.next()});
      },
      (error) => console.log("")
    );
  }

  addRoleModal() {
    const modalRef = this.modalService.open(AddRoleModalComponent, {
      size: "lg",
      windowClass: "RoleMasterModal",
      backdrop: "static",
    });

    modalRef.result.then(
      (response) => {
        if (response.status === 0) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          //console.log("success modal add role");
          $("#alertTitle").text("Success!");
          $("#alertTitle").css("color", "black");
          $("#alertMessage").text("Role added successfully.");
          $("#alertMessage").css("color", "black");
          $("#messagePopUp").modal("toggle");
          this.listOfRoles();
          this.messagePopUpTimeoutToogle();
        } else {
          $("#alertTitle").text("Failure!");
          $("#alertTitle").css("color", "red");
          $("#alertMessage").text(response.message);
          $("#alertMessage").css("color", "red");
          $("#messagePopUp").modal("toggle");
          this.messagePopUpTimeoutToogle();
        }
      },
      (reason) => {
        //console.log("reason")
      }
    );
  }

  editRoleModal(roleObjectToEdit) {
    //console.log('checking for the roleId and roleName', roleObjectToEdit)
    const modalRef = this.modalService.open(EditRoleModalComponent, {
      size: "lg",
      windowClass: "RoleMasterModal",
      backdrop: "static",
    });

    modalRef.componentInstance.roleObjectToEdit = roleObjectToEdit;
    modalRef.result.then(
      (response) => {
        //console.log("modal response" ,response);
        if (response.status === 0) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          //console.log("success modal edit role");

          $("#alertTitle").text("Success!");
          $("#alertTitle").css("color", "black");
          $("#alertMessage").text("Role updated successfully.");
          $("#alertMessage").css("color", "black");
          $("#messagePopUp").modal("toggle");
          this.listOfRoles();
          this.messagePopUpTimeoutToogle();
        } else {
          //console.log("failure modal edit role");
          $("#alertTitle").text("Failure!");
          $("#alertTitle").css("color", "red");
          $("#alertMessage").text(response.message);
          $("#alertMessage").css("color", "red");
          $("#messagePopUp").modal("toggle");
          this.messagePopUpTimeoutToogle();
        }
      },
      (reason) => {
        //console.log("reason")
      }
    );
  }

  deleteConfirmationModal(roleObjectToDelete) {
    const modalRef = this.modalService.open(DeleteConfirmationModalComponent, {
      backdrop: "static",
    });

    modalRef.componentInstance.roleObjectToDelete = roleObjectToDelete;

    modalRef.result.then(
      (response) => {
        if (response.status === 0) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          //console.log("success modal delete role");

          $("#alertTitle").text("Success!");
          $("#alertTitle").css("color", "black");
          $("#alertMessage").text("Role deleted successfully.");
          $("#alertMessage").css("color", "black");
          $("#messagePopUp").modal("toggle");
          this.listOfRoles();
          this.messagePopUpTimeoutToogle();
        } else {
          //console.log("failure modal delete role");
          $("#alertTitle").text("Failure!");
          $("#alertTitle").css("color", "red");
          $("#alertMessage").text(response.message);
          $("#alertMessage").css("color", "red");
          $("#messagePopUp").modal("toggle");
          this.messagePopUpTimeoutToogle();
        }
      },
      (reason) => {
        //console.log("reason")
      }
    );
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $("#messagePopUp").attr("class");
      if (popUpClass == "modal fade show") {
        $("#messagePopUp").modal("toggle");
      }
    }, 3000);
  }
}
