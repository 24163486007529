<style>
.board-pack {
  background-color: #fff;
  margin-top: 35px;
  padding: 25px;
}
.board-pack a,
.agendablock .info a {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle;
  font-size: 17px;
  margin-left: 15px;
  letter-spacing: 1px;
  text-decoration: none;
}
.board-pack .last-edit span {
  color: #979797;
  vertical-align: middle;
}
.board-pack .last-edit a {
  margin-left: 10px;
}
.agendablock {
  margin: 20px 0;
}
.agendablock .info {
  background-color: #fff;
  padding: 25px;
}
.agendablock .info a {
  margin-top: 7px;
}
.agendablock .info span.attend {
  color: #3bb1b0;
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}
.agendablock .info span.uncnfrmd {
  color: #b3b3b3;
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}
.agendablock .info span.not-attend {
  color: #f56073;
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}
.post-meeting strong,
.updates strong {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}
.post-meeting .info {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 30px 25px;
  color: #aeaeae;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100% !important;
}
.post-meeting .info a {
  color: #aeaeae;
  text-decoration: none;
}
.updates .info {
  background-color: #fff;
  padding: 10px 0 15px;
  max-height: 292px;
  height: 292px;
  overflow: hidden;
}
.updates .info ul {
  overflow-y: scroll;
  height: 275px;
  max-height: 275px;
  max-width: 720px;
  list-style: none;
  padding-left: 30px;
  padding-right: 20px;
}
.updates .info ul::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.updates .info ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.updates .info ul::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.updates .info ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.updates .info ul li {
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 0;
}
.updates .info ul li strong {
  margin-top: 0;
  text-transform: none;
  font-weight: 500;
  font-size: 15px;
  color: #000;
}
.updates .info ul li .meta {
  color: #979797;
}
.updates .info ul li .meta span {
  padding: 0 10px;
}
.updates .info ul li .meta span:first-child {
  border-right: 1px solid #979797;
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  .agendablock .info {
    margin-bottom: 20px;
  }
}
  
</style>
<section class="my-3">
  <div class="container" >
    <div class="board-pack" *ngIf="meetingObject.MeetType != 'Circular Resolution'">
      <div class="row">
        <div class="col-lg-6">
            <img src="/assets/images/board-pack-icon.png" alt="search-pack-icon"><a (click)="showBoardPack()" href="javascript:void(0)">Board Pack</a>
        </div>	
        <div class="col-lg-6 text-right last-edit">
            <a (click)="showBoardPack()" href="javascript:void(0)"><img src="/assets/images/download-icon.png" alt="download"></a>
        </div>
      </div>
    </div>

    <div class="agendablock">
      <div class="row">
        <div class="col-lg-4" *ngIf="meetingObject.MeetType != 'Circular Resolution'">
          <div class="d-flex info">
            <img src="/assets/images/agenda-icon.png" alt="agenda">
            <a (click)="showAgenda()" href="javascript:void(0)">Agenda </a>
            <span class="attend  ml-auto p-2">{{count}}</span>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-flex info">
            <img src="/assets/images/documents-icon.png" alt="Documents">
            <a (click)="showDocument()" href="javascript:void(0)">Documents</a>
            <span class="attend  ml-auto p-2">{{countDoc}}</span>
          </div>
        </div>
        <div class="col-lg-4" *ngIf="meetingObject.MeetType != 'Circular Resolution'">
          <div class="d-flex info" >
            <img src="/assets/images/participants-icon.png" alt="Participants">
            <a (click)="showParticipant()" href="javascript:void(0)">Participants</a>
            <div class="ml-auto p-2">
              <span class="attend">{{Attended}}</span> 
              <span class="uncnfrmd">{{NotAttended}}</span> 
              <span class="not-attend">0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>