<section class="card allMeetings" order-id="card-5">
  <div class="card-header">
    <div class="controls-wrapper d-flex">
      <div class="left-controls-wrapper"></div>
      <div class="right-controls-wrapper">
        <div [ngClass]="isCalenderVisible ? 'active': ''" (click)="showCalender()" style="border-right: 1px solid #A1A1A1;">
          <i class="far fa-calendar-alt right-controls-ic" [ngStyle]="isCalenderVisible && {'color': '#fff'}"></i>
        </div>
        <div [ngClass]="!isCalenderVisible ? 'active': ''" (click)="showList()">
          <i class="fas fa-bars right-controls-ic" [ngStyle]="!isCalenderVisible && {'color': '#fff'}"></i>
        </div>
      </div>
    </div>
    <h5 class="mb-0 mr-3 d-inline-block">
      All Meetings
    </h5>
    <a href="javascript:void();"><span>+</span></a>
  </div>
  <div *ngIf="isCalenderVisible; then calender else list">here is ignored</div>
  <ng-template #calender>
    <app-calendar-task [refreshData]="refreshData" [rowFive]="rowFive" (outputStatus)="calenderOutputStatus"></app-calendar-task>
  </ng-template>
  <ng-template #list>
    <div [appHasPermission]="{'Url': 'chat', 'method':'get', 'menu': 'Dashboard'}" *ngIf="isRocketChatRemove!='true'">

      <app-chats></app-chats>
    </div>

    <div [appHasPermission]="{'Url': 'meetings', 'method':'get', 'menu': 'Dashboard'}">
      <app-meeting></app-meeting>
    </div>
  </ng-template>
</section>