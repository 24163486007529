<div class="cat__menu-right">
  <div class="cat__menu-right__inner">
    <div class="cat__menu-right__header">
      <span class="fa fa-times pull-right cat__menu-right__action--menu-toggle"><!-- --></span>
      Theme Settings
    </div>
    <div class="cat__menu-right__content">
      <div class="cat__menu-right__descr">
        This module "block-menu-right" gives possibility to construct custom blocks with any widgets, components and elements inside, like this theme settings
      </div>
      <div class="cat__menu-right__label">
        Theme Style
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="" autocomplete="off"> Dark
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="cat__theme--light" autocomplete="off"> Light
            </label>
          </div>
        </div>
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="cat__theme--green" autocomplete="off"> Green
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="cat__theme--blue" autocomplete="off"> Blue
            </label>
          </div>
        </div>
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="cat__theme--red" autocomplete="off"> Red
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-theme" value="cat__theme--orange" autocomplete="off"> Orange
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Boxed Layout
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-boxed" value="cat__config--boxed" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-boxed" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Compact Menu
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-boxed" value="cat__config--compact" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-boxed" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Fixed Top Bar
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-topbar" value="" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-topbar" value="cat__config--unfixed" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Super Clean Mode
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-superclean" value="cat__config--superclean" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-superclean" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Colorful Menu
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-colorful" value="cat__menu-left--colorful" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-colorful" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Menu Shadow
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-shadow" value="cat__config--shadow" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-shadow" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
      <div class="cat__menu-right__label">
        Squared Corners
      </div>
      <div class="cat__menu-right__setting cat__menu-right--example-option">
        <div class="btn-group btn-group-justified" data-toggle="buttons">
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-corners" value="cat__config--squared-corners" autocomplete="off"> On
            </label>
          </div>
          <div class="btn-group">
            <label class="btn btn-default">
              <input type="radio" name="options-corners" value="" autocomplete="off"> Off
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
