<!-- START: components/carousel -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Carousel</strong>
            <a href="http://v4-alpha.getbootstrap.com/components/carousel/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Default Carousel</strong></h5>
                <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/carousel/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
                            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <img src="assets/modules/dummy-assets/common/img/photos/2.jpeg" alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img src="assets/modules/dummy-assets/common/img/photos/3.jpeg" alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img src="assets/modules/dummy-assets/common/img/photos/5.jpeg" alt="First slide">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carousel-example-generic" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carousel-example-generic" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Captions</strong></h5>
                    <p class="text-muted">Element: read <a href="http://v4-alpha.getbootstrap.com/components/carousel/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <div id="carousel-example-generic-2" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#carousel-example-generic-2" data-slide-to="0" class="active"></li>
                            <li data-target="#carousel-example-generic-2" data-slide-to="1"></li>
                            <li data-target="#carousel-example-generic-2" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <img src="assets/modules/dummy-assets/common/img/photos/4.jpeg" alt="First slide">
                                <div class="carousel-caption">
                                    <h3>First Slide Label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="assets/modules/dummy-assets/common/img/photos/1.jpeg" alt="Second slide">
                                <div class="carousel-caption">
                                    <h3>Second Slide Label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <img src="assets/modules/dummy-assets/common/img/photos/3.jpeg" alt="Third slide">
                                <div class="carousel-caption">
                                    <h3>Third Slide Label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carousel-example-generic-2" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carousel-example-generic-2" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Carousel -->


<!-- Carousel -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Owl Carousel</strong>
            <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Basic</strong></h5>
                <p class="text-muted">Element: read <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="owl-carousel owl-theme owl-demo" id="owl1">
                        <div class="item"><h5 class="text-black"><strong>1</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>2</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>3</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>4</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>5</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>6</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>7</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>8</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>9</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>10</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>11</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>12</strong></h5></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Center</strong></h5>
                <p class="text-muted">Element: read <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="owl-carousel owl-theme owl-demo" id="owl2">
                        <div class="item"><h5 class="text-black"><strong>1</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>2</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>3</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>4</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>5</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>6</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>7</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>8</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>9</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>10</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>11</strong></h5></div>
                        <div class="item"><h5 class="text-black"><strong>12</strong></h5></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Image Lazy Loading</strong></h5>
                <p class="text-muted">Element: read <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="owl-carousel owl-theme owl-demo-img" id="owl3">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=1" data-src-retina="http://placehold.it/350x250/9dabb6/ffffff/&text=1" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=2" data-src-retina="http://placehold.it/350x250/9dabb6/ffffff/&text=2" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=3" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=4" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=5" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=6" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=7" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=8" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=9" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=10" alt="">
                        <img class="owl-lazy" data-src="http://placehold.it/350x250/9dabb6/ffffff/&text=11" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Auto Height</strong></h5>
                <p class="text-muted">Element: read <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="owl-carousel owl-theme owl-demo" id="owl4">
                        <div class="item" style="height:132px"><h5 class="text-black"><strong>1</strong></h5></div>
                        <div class="item" style="height:200px"><h5 class="text-black"><strong>2</strong></h5></div>
                        <div class="item" style="height:132px"><h5 class="text-black"><strong>3</strong></h5></div>
                        <div class="item" style="height:170px"><h5 class="text-black"><strong>4</strong></h5></div>
                        <div class="item" style="height:220px"><h5 class="text-black"><strong>5</strong></h5></div>
                        <div class="item" style="height:90px"><h5 class="text-black"><strong>6</strong></h5></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="text-black"><strong>Video w/ YouTube / Vimeo / Vzaar</strong></h5>
                <p class="text-muted">Element: read <a href="https://owlcarousel2.github.io/OwlCarousel2/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="owl-carousel owl-theme owl-demo-video" id="owl5">
                        <div class="item-video" data-merge="2"><a class="owl-video" href="http://www.youtube.com/watch?v=H3jLkJrhHKQ"></a></div>
                        <div class="item-video" data-merge="1"><a class="owl-video" href="https://www.youtube.com/watch?v=JpxsRwnRwCQ"></a></div>
                        <div class="item-video" data-merge="1"><a class="owl-video" href="http://www.youtube.com/watch?v=oy18DJwy5lI"></a></div>
                        <div class="item-video" data-merge="3"><a class="owl-video" href="http://vimeo.com/23924346"></a></div>
                        <div class="item-video" data-merge="3"><a class="owl-video" href="https://www.youtube.com/watch?v=g3J4VxWIM6s"></a></div>
                        <div class="item-video" data-merge="2"><a class="owl-video" href="https://www.youtube.com/watch?v=EF_kj2ojZaE"></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/carousel -->
