<section>
    <div>
        <form [formGroup]="verifyOTPForm" (keydown.enter)="submitOTP()">
            <div class="inner-wrap">
                    <p>
                    <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
                </p>
                <h2>Enter OTP</h2>
                <div class="form-group">
                        <input id="validation-email" class="form-control" placeholder="OTP " type="text" [(ngModel)]="otpCode" formControlName="otpCode" (keydown)="onlyNumber($event)"
                            minlength="4" maxlength="4" autocomplete="off">
                        <div *ngIf="verifyOTPForm.controls['otpCode'].hasError('required')"
                        class="form-control-error-custom" style="text-align:left;">
                        Please enter the correct OTP code.
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-block mr-2 mb-2" [disabled]="!otpCode" (click)="submitOTP()" type="submit"><i aria-hidden="true"></i>Confirm OTP</button>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-block mr-2 mb-2" (click)="resendOTP()" type="button"><i aria-hidden="true"></i>Resend OTP</button>
                    </div>
            </div>
        </form>
    </div>
</section>
