import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

import { forkJoin } from "rxjs";
import * as _ from "lodash";

import { UserMasterService } from "../services/user-master.service";
import { DashboardService } from "../services/dashboard.service";
import { DashboardMyTaskService } from "../services/dashboardmytask.service";

@Injectable(
  { providedIn: 'root'}
  )
export class ActivatedTaskFromComplianceService {
  activatedTaskuserListforkJoin: any;
  activatedTaskCache: any;
  tempactivatedTask: any;
  userListCache: any;

  constructor(
    private http: HttpClient,
    private dashboardService: DashboardService,
    private userService: UserMasterService,
    private dashboardMyTaskService: DashboardMyTaskService
  ) {}

  //getActivatedTaskWithAdditionalJsonData(companyId): Promise<any> {

  getActivatedTasks(companyId): Promise<any> {
    let userList = this.userService.getUserList();

    let activatedTask = this.dashboardService.getActivatedTaskList(companyId);

    //-------------------------------------------

    // //console.log("activatedTask", activatedTask);

    return forkJoin([activatedTask, userList])
      .toPromise()
      .then((res) => {
        // //console.log("activatedTask", activatedTask);
        this.activatedTaskuserListforkJoin = res;

        this.activatedTaskCache = res[0].taskList;
        this.userListCache = res[1].result;

        let activatedTaskforkJoin = res[0].taskList;
        let userListforkJoin = res[1].result;
        // //console.log("this.fork, this.fork1, this.fork2", this.activatedTaskuserListforkJoin, activatedTaskforkJoin, userListforkJoin);

        // AssignedTo , CreatedBy
        _.forEach(activatedTaskforkJoin, function (o) {
          var createdBy = _.filter(userListforkJoin, ["_id", o.CreatedBy]);
          //   createdBy.find(function(t){
          //   o.CreatedBy =  t.FirstName + " " + t.LastName;
          //   return false
          // })
          // //console.log("createdBy",createdBy.length)

          if (createdBy.length) {
            _.assign(o, { Owner: createdBy[0].FirstName + " " + createdBy[0].LastName });
          } else {
            _.assign(o, { Owner: " " });
          }

          if (o.CategoryName == undefined) {
            // //console.log("there is no such field with value CategoryName");
            _.assign(o, { CategoryName: "" });
          }
          if (o.ProcessName == undefined) {
            // //console.log("there is no such field with value CategoryName");
            _.assign(o, { ProcessName: "" });
          }

          var assignedByID = o.AssignedTo;
          var assignedTo = _.filter(userListforkJoin, ["_id", o.AssignedTo[0]]);
          // _.assign(o, { assignedByName: assignedTo[0].FirstName + "" + assignedTo[0].LastName })
          assignedTo.find(function (t) {
            o.AssignedTo = t.FirstName + " " + t.LastName;
            return false;
          });

          //test -- add status
          var complianceTaskStatus;
          if (o.TaskStatus == 1) complianceTaskStatus = "Pending";
          else if (o.TaskStatus == 2) complianceTaskStatus = "Completed";
          else if (o.TaskStatus == 3) complianceTaskStatus = "Delayed Completion";
          else if (o.TaskStatus == 4) complianceTaskStatus = "Partially Completed";
          else if (o.TaskStatus == 5) complianceTaskStatus = "NA";
          else if (o.TaskStatus == 6) complianceTaskStatus = "Done";
          else if (o.TaskStatus == 7) complianceTaskStatus = "Cancelled";

          if (o.TriggerStartDate > o.DueDate)
            _.assign(
              o,
              { LocalStatus: o.TaskStatus },
              { AssignedByID: assignedByID },
              { ActivatedTaskFromCompliance: true },
              { StartDate: o.DueDate },
              { EndDate: o.TriggerStartDate },
              { ComplianceTaskStatus: complianceTaskStatus }
            );
          else
            _.assign(
              o,
              { LocalStatus: o.TaskStatus },
              { AssignedByID: assignedByID },
              { ActivatedTaskFromCompliance: true },
              { StartDate: o.TriggerStartDate },
              { EndDate: o.DueDate },
              { ComplianceTaskStatus: complianceTaskStatus }
            );

          // _.assign(o, { LocalStatus: o.TaskStatus }, { AssignedByID: assignedByID }, { ActivatedTaskFromCompliance: true }, { StartDate: o.TriggerStartDate }, { EndDate: o.DueDate }, { ComplianceTaskStatus: complianceTaskStatus });

          var assignedTo = _.filter(userListforkJoin, ["_id", o.AssignedTo[0]]);
        });

        this.tempactivatedTask = activatedTaskforkJoin;
        // //console.log("after forloop - activatedTaskforkJoin",activatedTaskforkJoin,"userListforkJoin",userListforkJoin )
        // //console.log("usersh:" + this.tempactivatedTask);
        return this.tempactivatedTask;
      });

    // return [activatedTask,testservice.then(res=>{
    //     return res;
    // }),testservice];

    //-------------------------------------------
  }

  getMyActivatedTaskList(companyId, assignedTo) {
    let uniqueUserId = JSON.parse(localStorage.getItem("user")).UniqueUserId;
    var self = this;
    return this.dashboardMyTaskService
      .getUserIdbyUniqueUserId(uniqueUserId)
      .then((responseforUser) => {
        // //console.log('responseforUser', responseforUser)
        if (!responseforUser.status) {
          var promise = [this.userService.getUserList()];
          for (var index = 0; index < responseforUser.listOfId.length; index++) {
            // //console.log('companyId', responseforUser.listOfId[index].CompanyId, 'userId', responseforUser.listOfId[index]._id)
            promise.push(
              self.dashboardService.getMyActivatedTaskList(
                responseforUser.listOfId[index].CompanyId,
                responseforUser.listOfId[index]._id
              )
            );
          }
          return forkJoin(promise)
            .toPromise()
            .then((responseForMutipleActivatedTask) => {
              // //console.log('responseForMutipleActivatedTask', responseForMutipleActivatedTask)
              let errorObject = _.find(responseForMutipleActivatedTask, function (element) {
                return element.status == 1;
              });
              //console.log('errorObject', _.isNil(errorObject))
              if (_.isNil(errorObject)) {
                let userList = responseForMutipleActivatedTask[0].result;
                //console.log('userList', userList)
                let activatedTaskListResponse = responseForMutipleActivatedTask;
                activatedTaskListResponse.splice(0, 1);
                let taskArray = [];
                let activatedTaskList = _.map(activatedTaskListResponse, function (element) {
                  return _.concat(taskArray, element.taskList);
                });
                //console.log('activatedTaskList', activatedTaskList)
                // let activatedTaskList = _.reduce(activatedTaskListResponse, function(currentElement, NextElement){
                //     //console.log('currentElement', currentElement, `NextElement`, NextElement)
                //     return _.concat(_.get(currentElement, 'taskList'), _.get(NextElement, 'taskList'))})
                activatedTaskList = _.flatten(activatedTaskList);
                //console.log('activatedTaskList', activatedTaskList)
                _.forEach(activatedTaskList, function (element) {
                  var companyObject = _.find(responseforUser.listOfId, function (user) {
                    return user._id == _.get(element, "AssignedTo");
                  });
                  _.assign(element, { CompanyName: companyObject.CompanyName });
                  var createdBy = _.filter(userList, ["_id", _.get(element, "CreatedBy")]);
                  if (createdBy.length) {
                    _.assign(element, {
                      Owner: createdBy[0].FirstName + " " + createdBy[0].LastName,
                    });
                  } else {
                    _.assign(element, { Owner: " " });
                  }

                  if (_.get(element, "CategoryName") == undefined) {
                    // //console.log("there is no such field with value CategoryName");
                    _.assign(element, { CategoryName: "" });
                  }
                  if (_.get(element, "ProcessName") == undefined) {
                    // //console.log("there is no such field with value CategoryName");
                    _.assign(element, { ProcessName: "" });
                  }

                  var assignedByID = _.get(element, "AssignedTo");
                  var assignedTo = _.filter(userList, ["_id", _.get(element, "AssignedTo")]);
                  // _.assign(o, { assignedByName: assignedTo[0].FirstName + "" + assignedTo[0].LastName })
                  // assignedTo.find(function (t) {
                  //     _.get(element, 'AssignedTo') = t.FirstName + " " + t.LastName;
                  //     return false;
                  // })

                  if (_.get(element, "TriggerStartDate") > _.get(element, "DueDate")) {
                    _.assign(
                      element,
                      { LocalStatus: _.get(element, "TaskStatus") },
                      { AssignedByID: assignedByID },
                      { ActivatedTaskFromCompliance: true },
                      { StartDate: _.get(element, "DueDate") },
                      { EndDate: _.get(element, "TriggerStartDate") }
                    );
                  } else {
                    _.assign(
                      element,
                      { LocalStatus: _.get(element, "TaskStatus") },
                      { AssignedByID: assignedByID },
                      { ActivatedTaskFromCompliance: true },
                      { StartDate: _.get(element, "TriggerStartDate") },
                      { EndDate: _.get(element, "DueDate") }
                    );
                  }
                });
                this.tempactivatedTask = activatedTaskList;
                return Promise.resolve(this.tempactivatedTask);
              }
            });
        }
      });
  }

  getactivatedTaskDetailsJsonData(taskId): Promise<any> {
    return this.http
      .get("/assets/localJson/activeTaskDetails.json")
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(Error);
  }

  getComplianceTeamTaskList(rangeOfDate) {
    let uniqueUserId = JSON.parse(localStorage.getItem("user")).UniqueUserId;
    var self = this;
    return this.dashboardMyTaskService
      .getUserIdbyUniqueUserId(uniqueUserId)
      .then((responseforUser) => {
        // //console.log('responseforUser', responseforUser)
        if (!responseforUser.status) {
          var promise = [this.userService.getUserList()];
          for (var index = 0; index < responseforUser.listOfId.length; index++) {
            // //console.log('companyId', responseforUser.listOfId[index].CompanyId, 'userId', responseforUser.listOfId[index]._id)
            rangeOfDate.companyId = responseforUser.listOfId[index].CompanyId;
            rangeOfDate.userId = responseforUser.listOfId[index]._id;
            promise.push(self.dashboardService.getComplianceTeamTask(rangeOfDate));
            // promise.push(self.dashboardService.getMyActivatedTaskList(responseforUser.listOfId[index].CompanyId, responseforUser.listOfId[index]._id))
          }
          return forkJoin(promise)
            .toPromise()
            .then((responseForMutipleActivatedTask) => {
              // //console.log('responseForMutipleActivatedTask', responseForMutipleActivatedTask)
              let errorObject = _.find(responseForMutipleActivatedTask, function (element) {
                return element.status == 1;
              });
              //console.log('errorObject', _.isNil(errorObject))
              if (_.isNil(errorObject)) {
                let userList = responseForMutipleActivatedTask[0].result;
                //console.log('userList', userList)
                let activatedTaskListResponse = responseForMutipleActivatedTask;
                activatedTaskListResponse.splice(0, 1);
                let taskArray = [];
                let activatedTaskList = _.map(activatedTaskListResponse, function (element) {
                  return _.concat(taskArray, element.taskList);
                });
                // let activatedTaskList = _.reduce(activatedTaskListResponse, function(currentElement, NextElement){
                //     //console.log('currentElement', currentElement, `NextElement`, NextElement)
                //     return _.concat(_.get(currentElement, 'taskList'), _.get(NextElement, 'taskList'))})
                activatedTaskList = _.flatMapDeep(activatedTaskList);
                //console.log('activatedTaskList', activatedTaskList)
                _.forEach(activatedTaskList, function (element) {
                  var createdBy = _.filter(userList, ["_id", _.get(element, "CreatedBy")]);
                  if (createdBy.length) {
                    _.assign(element, {
                      Owner: createdBy[0].FirstName + " " + createdBy[0].LastName,
                    });
                  } else {
                    _.assign(element, { Owner: " " });
                  }

                  if (_.get(element, "CategoryName") == undefined) {
                    // //console.log("there is no such field with value CategoryName");
                    _.assign(element, { CategoryName: "" });
                  }
                  if (_.get(element, "ProcessName") == undefined) {
                    // //console.log("there is no such field with value CategoryName");
                    _.assign(element, { ProcessName: "" });
                  }

                  var assignedByID = _.get(element, "AssignedTo");
                  var assignedTo = _.filter(userList, ["_id", _.get(element, "AssignedTo")]);
                  // _.assign(o, { assignedByName: assignedTo[0].FirstName + "" + assignedTo[0].LastName })
                  // assignedTo.find(function (t) {
                  //     _.get(element, 'AssignedTo') = t.FirstName + " " + t.LastName;
                  //     return false;
                  // })

                  if (_.get(element, "TriggerStartDate") > _.get(element, "DueDate")) {
                    _.assign(
                      element,
                      { LocalStatus: _.get(element, "TaskStatus") },
                      { AssignedByID: assignedByID },
                      { ActivatedTaskFromCompliance: true },
                      { StartDate: _.get(element, "DueDate") },
                      { EndDate: _.get(element, "TriggerStartDate") }
                    );
                  } else {
                    _.assign(
                      element,
                      { LocalStatus: _.get(element, "TaskStatus") },
                      { AssignedByID: assignedByID },
                      { ActivatedTaskFromCompliance: true },
                      { StartDate: _.get(element, "TriggerStartDate") },
                      { EndDate: _.get(element, "DueDate") }
                    );
                  }
                });
                this.tempactivatedTask = activatedTaskList;
                return Promise.resolve(this.tempactivatedTask);
              }
            });
        }
      });
  }
}
