import { ListDirectMessagesProps, ListMessagesProps, SendMessage } from "../types";
import { baseUrl, getHeaders } from "../urls";

export class Messages {
  sendMessage(params: SendMessage) {
    var header = getHeaders();
    header.delete("Content-Type");

    var formData = new FormData();
    Object.keys(params).forEach((val, ind) => {
      formData.append(val, params[val]);
    });

    return new Promise((resolve, reject) => {
      fetch(baseUrl + "/v1/messages", {
        method: "post",
        body: formData,
        headers: header,
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  downloadAttachment(url: string) {
    var header = getHeaders();
    header.append("host", null);

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: header,
      })
        .then((res) => {
          console.log(res.headers.has("content-disposition"));
          res.headers.forEach((val, key) => {
            console.log(key, val);
          });
          res.blob().then((blobdata) => {
            resolve(blobdata);
          });
        })
        .catch((err) => reject(err));
    });
  }

  listMessages(params: ListMessagesProps) {
    return new Promise((resolve, reject) => {
      let url = new URL(baseUrl + "/v1/messages");
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
      fetch(url.toString(), {
        method: "get",
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  listDirectMessages(params: ListDirectMessagesProps) {
    return new Promise((resolve, reject) => {
      let url = new URL(baseUrl + "/v1/messages/direct");
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
      fetch(url.toString(), {
        method: "get",
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  getFileName(url: string) {
    let token = "Bearer " + localStorage.getItem("WebexAcessToken");

    var header = new Headers();
    header.append("Authorization", token);
    fetch(url, {
      method: "HEAD",
      headers: header,
    })
      .then((res) => {
        console.log(res.body);
        console.log(res.headers);
      })
      .catch((err) => console.log(err));
  }
}
