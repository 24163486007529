import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
} from '@angular/core';
import { DashboardService } from './../../../services/dashboard.service';
import { forkJoin } from 'rxjs';

import * as _ from 'lodash';
import * as moment from 'moment';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskListComponent } from './../task-list/task-list.component';

import { PermissionLinks } from './../../../enums/permissionLinks';

import { PictorialRoadmapComponent } from '../../dashboards/pictorial-roadmap/pictorial-roadmap.component';

import { ActivatedTaskFromComplianceService } from './../../../services/activatedTaskFromCompliance.service';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;

@Component({
  selector: 'app-compliance-and-roadmap-chart',
  templateUrl: './compliance-and-roadmap-chart.component.html',
  styleUrls: ['./compliance-and-roadmap-chart.component.css'],
})
export class ComplianceAndRoadmapChartComponent implements OnInit, OnChanges {
  @Input('refreshMeter') refreshMeter: any;

  public complianceTaskStatus: boolean = true;
  public roadMapTaskStatus: boolean = true;
  roadMapTaskChart: object;
  complianceChart: object;
  daysRemaingForRoadmapToEnd: any;
  isMyTaskFetch: any;

  constructor(
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService
  ) { }

  ngOnInit() { }



  ngAfterViewInit() {
    console.log("refressh called", this.refreshMeter)
    this.isMyTaskFetch = localStorage.getItem('isMyTaskFetch');
    var loginUsers = JSON.parse(localStorage.getItem('user'));
    let companyId = loginUsers.CompanyId;

    var currentDate = moment(new Date()).format('YYYY-MM-DD');
    const resources = JSON.parse(localStorage.getItem('resources'));
    const dashboardObject = _.find(resources, function (resourceObject) {
      return resourceObject.Menu == PermissionLinks.dashboard;
    });
    if (
      !_.isNil(dashboardObject) &&
      _.find(dashboardObject.ChildResources, function (childResources) {
        return childResources.Url == PermissionLinks.progressMeter;
      })
    ) {
      if (companyId != '' && this.isMyTaskFetch != 'false') {
        var ObjectToPass = {
          CompanyID: companyId,
          MeetingTypeId: null,
          AssignedTo: null,
          ProcessId: null,
          FromDate: null,
          ToDate: null,
          TaskStatusId: null,
        };
        forkJoin([
          // this.dashboardService.getComplianceTask(),
          this.activatedTaskFromComplianceService.getActivatedTasks(
            ObjectToPass
          ),
          this.dashboardService.getRoadmapTask(),
        ]).subscribe((responses) => {
          // var complianceTaskObject = responses[0];
          var complianceTaskObject;
          // if (responses[0] == undefined) {
          if (responses[0] == undefined || responses[0].length == 0) {
            complianceTaskObject = [];
          } else {
            if (responses[0].length > 0) {
              //remove records whose start date and end date is null or not avilable
              responses[0] = responses[0].filter(
                (g) =>
                  g.StartDate != null &&
                  g.StartDate != undefined &&
                  (moment(g.StartDate).format('YYYY-MM-DD') <= currentDate ||
                    g.TaskStatus == 2 ||
                    g.TaskStatus == 3 ||
                    g.TaskStatus == 6) &&
                  g.EndDate != undefined &&
                  g.EndDate != null &&
                  g.TaskStatus != 5 &&
                  g.TaskStatus != 7
              );
              if (responses[0].length > 0) complianceTaskObject = responses[0];
              else complianceTaskObject = [];
            }
          }

          var complianceListTotalCount: any;
          var complianceListCountComplied: any;

          if (complianceTaskObject) {
            complianceListTotalCount = complianceTaskObject.length;

            complianceListCountComplied = _.countBy(
              complianceTaskObject,
              function (o) {
                if (
                  o.TaskStatus === 2 ||
                  o.TaskStatus === 3 ||
                  o.TaskStatus === 6
                ) {
                  return true;
                }
              }
            );
          }
          var roadMapObject = responses[1];
          if (
            complianceTaskObject.length &&
            _.isEmpty(complianceListTotalCount)
          ) {
            this.complianceTaskStatus = true;
          } else {
            this.complianceTaskStatus = false;
          }

          if (!roadMapObject.status && !_.isEmpty(roadMapObject.countObject)) {
            this.roadMapTaskStatus = true;
            this.daysRemaingForRoadmapToEnd = this.calculateDaysForRoadmapToEnd(
              new Date(roadMapObject.countObject.roadMapStartDate),
              new Date(roadMapObject.countObject.roadMapEndDate)
            );
            this.drawRoadMapTaskPieChart(
              'roadMapTask',
              roadMapObject.countObject.countComplied,
              roadMapObject.countObject.totalCount
            );
          } else {
            this.roadMapTaskStatus = false;
          }
        });
      }
    }
  }

  calculateDaysForRoadmapToEnd(startDate, endDate) {
    // //console.log("startDate ,endDate",startDate,endDate)
    // // var fromDate = moment(startDate, 'DD/MM/YYYY');
    var fromDate = moment(new Date()).startOf('day');
    var toDate = moment(endDate).startOf('day');
    // //console.log("fromDate ,toDate",fromDate,toDate)
    var remainingDays = toDate.diff(fromDate, 'days');
    if (remainingDays > 1) {
      return remainingDays + ' days left';
    } else if (remainingDays > 0 && remainingDays <= 1) {
      return remainingDays + ' day left';
    } else if (remainingDays == 0) {
      return remainingDays + ' day left';
    } else {
      return 'Past Due Date';
    }
  }

  ngOnChanges() {
    this.ngAfterViewInit();
  }

  drawCompliancePieChart(DOMId, compliedCount, totalCount) {
    compliedCount =
      compliedCount == undefined || compliedCount == null ? 0 : compliedCount;
    if (totalCount == 0) {
      this.complianceTaskStatus = false;
      return;
    }

    // var compliedPercentage = ((compliedCount / totalCount) * 100);
    var compliedPercentage = Math.round((compliedCount / totalCount) * 100);
    document.getElementById('complianceChart').style.width =
      String(compliedPercentage) + '%';
    document.getElementById('complienceCount').innerHTML =
      compliedPercentage + '%';
    this.complianceChart = {
      chart: {
        renderTo: 'complianceContainer',
        type: 'gauge',
        height: 170,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: 'Compliance',
        style: {
          fontWeight: 'bold',
          fontSize: 15,
        },
        verticalAlign: 'bottom',
        margin: 0,
        y: -5,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: null,
            borderWidth: null,
          },
        ],
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 100, //totalCount,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        //minorTickColor: '#666',
        minorTickColor: 'rgba(0,0,0,0.2)',
        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        //tickColor: '#666',
        tickColor: 'rgba(0,0,0,0.1)',

        labels: {
          step: 2,
          rotation: 'auto',
        },
        plotBands: [
          {
            from: 0,
            to: 35, // totalCount / 3,
            //color: '#DF5353' // green
            color: '#ec403d',
          },
          {
            from: 35, //totalCount / 3,
            to: 80, //(totalCount / 3) + (totalCount / 3),
            //color: '#DDDF0D' // yellow
            color: '#fb8819',
          },
          {
            from: 80, //(totalCount / 3) + (totalCount / 3),
            to: 100, //totalCount,
            //color: '#55BF3B' // red
            color: '#15a843',
          },
        ],
      },

      series: [
        {
          name: 'Compliance',
          data: [compliedPercentage],
          dataLabels: {
            enabled: true,
            // backgroundColor: null,
            borderWidth: null,
            color: '#c8bdff',
            y: 20,
          },
        },
      ],
    };
  }

  drawRoadMapTaskPieChart(DOMId, completedCount, totalCount) {
    console.log("DOMId, completedCount, totalCount", DOMId, completedCount, totalCount)
    completedCount =
      completedCount == undefined || completedCount == null
        ? 0
        : completedCount;
    if (totalCount == 0) {
      this.roadMapTaskStatus = false;
      return;
    }

    var completedPercentage = _.floor((completedCount / totalCount) * 100, 2);
    document.getElementById('roadMapTaskChart').style.width =
      String(completedPercentage) + '%';
    document.getElementById('roadmapCount').innerHTML =
      completedPercentage + '%';
    this.roadMapTaskChart = {
      chart: {
        renderTo: 'roadMapContainer',
        type: 'gauge',
        height: 170,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: 'Roadmap',
        style: {
          fontWeight: 'bold',
          fontSize: 15,
        },
        verticalAlign: 'bottom',
        margin: 0,
        y: -5,
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: null,
            borderWidth: null,
          },
        ],
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 100, //totalCount,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        //minorTickColor: '#666',
        minorTickColor: 'rgba(0,0,0,0.2)',

        title: {
          text: this.daysRemaingForRoadmapToEnd,
          style: {
            fontWeight: 'bold',
            fontSize: 12,
            color: '#bebdff',
          },
          y: -40,
          // x:20,
          verticalAlign: top,
        },
        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        //tickColor: '#666',
        tickColor: 'rgba(0,0,0,0.1)',
        labels: {
          step: 2,
          rotation: 'auto',
        },
        plotBands: [
          {
            from: 0,
            to: 35, //totalCount / 3,
            //color: '#DF5353' // green
            color: '#ec403d',
          },
          {
            from: 35, //totalCount / 3,
            to: 80, //(totalCount / 3) + (totalCount / 3),
            //color: '#DDDF0D' // yellow
            color: '#fb8819',
          },
          {
            from: 80, //(totalCount / 3) + (totalCount / 3),
            to: 100, //totalCount,
            //color: '#55BF3B' // red
            color: '#15a843',
          },
        ],
      },

      series: [
        {
          name: 'Roadmap',
          data: [completedPercentage],
          dataLabels: {
            enabled: true,
            // backgroundColor: null,
            borderWidth: null,
            color: '#c8bdff',
            y: 20,
          },
        },
      ],
    };
  }

  showComplianceAndGovernance(type) {
    if (type == 'Roadmap') {
      const modalRef = this.modalService.open(PictorialRoadmapComponent, {
        size: 'lg',
        windowClass: 'pictorialRoadmapModal',
        backdrop: 'static',
      });
      modalRef.componentInstance.type = { name: type };
      modalRef.result.then(
        (response) => { },
        (reason) => { }
      );
    } else {
      const modalRef = this.modalService.open(TaskListComponent, {
        size: 'lg',
        windowClass: 'pictorialRoadmapShowDetailModal',
      });
      modalRef.componentInstance.type = { name: type };
      modalRef.result.then(
        (response) => { },
        (reason) => { }
      );
    }
  }
}
