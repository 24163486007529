import { Component, OnInit } from '@angular/core';
import { DataImportService } from './../../app/services/dataImport.service';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.css'],
})
export class DataImportComponent implements OnInit {
  BSFile: any;
  constructor(public dataImportService: DataImportService) {}

  ngOnInit() {}

  onChangeFile(event) {
    this.BSFile = event.target.files[0];
  }

  uploadBS() {
    this.dataImportService
      .uploadFile(this.BSFile)
      .then((resData) => {
        console.log(resData);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
}
