import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WebexService } from './../../../services/webex.service';
import { UserMasterService } from './../../../services/user-master.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Membership } from "../webx-chat/Membership/membership";

import { DashboardsAlpha } from '../alpha.page';
declare var Webex: any;
declare var $: JQueryStatic;
const RATIO = 4 / 3;

interface VideoElement extends HTMLVideoElement {
  requestPictureInPicture(): any;
}

interface DocumentCustome extends Document {
  exitPictureInPicture: () => Promise<any>;
}

@Component({
  selector: 'app-webex',
  templateUrl: './webex.component.html',
  styleUrls: ['./webex.component.css'],
})
export class WebexComponent implements OnInit {
  @ViewChild("videoElement") videoElement: ElementRef;
  membershipInstance = new Membership();
  meetingId: any;
  meetingNumberNew: any;
  webexmeetingId: any;
  WebexInstance: any;
  SIPURLforMeeting: any;
  ActiveMeeting: any;
  showIFrame: boolean;
  GlobalCheckStop: any;
  dashboardUrl: any;
  ciscoIP: any;
  meetingNumber: any;
  meetingPassword: any;
  autoHideTimeout: any;
  screenHeight: number;
  screenWidth: number;
  activeLayoutIndex: number = 0;
  isVideoFullScreen: boolean = false;
  isScreenSharing: boolean = false;
  timeoutRef: any = null;
  Mgt1List: any[];
  ParticipantsList: any[];
  guestLogin: any = false;
  eventCalledOnce: any = 0;
  constructor(@Inject(DOCUMENT) document, private http: HttpClient, private route: ActivatedRoute, private router: Router, private webexService: WebexService, private userMasterService: UserMasterService) { }

  refresh() {
    if (this.ActiveMeeting && this.ActiveMeeting != undefined) {
      this.ActiveMeeting.reconnect();
      if (this.ActiveMeeting.isSharing) {
        this.ActiveMeeting.updateShare({
          sendShare: false,
          receiveShare: true
        });
      }
    }
  }


  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.guestLogin = params.GuestLogin;

      var object;
      var userInfo = JSON.parse(localStorage.getItem('user'));
      var userId = JSON.parse(localStorage.getItem('user'))._id;
      var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
      var token = localStorage.getItem('token');
      var meetingId = localStorage.getItem('meetingId');
      var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
      var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
      var companyName = localStorage.getItem('companyDetailsGoveva')
      object = {
        //meetingId: this.meetingId,
        recordingStarted: true,
        recordingPaused: false,
        locked: false,
        token: token,
        userInfo: userInfo,
        userId: userId,
        uniqueUserId: uniqueUserId,
        companyInfo: companyInfo,
        companyID: companyID,
        companyName: companyName,
        muted: false,
        meetingId: meetingId
      }
      // this.userMasterService.recording(object).then(response => {
      //   console.log("respone ===================recording",response)
      // })

      // this.userMasterService.mute(object).then(response => {
      //   console.log("respone ====mute===============recording",response)
      // })
      localStorage.setItem('isPageReload', 'not-yet');

      setInterval(() => {
        var isReloaded = localStorage.getItem('isPageReload');
        if (isReloaded == 'done') {
          localStorage.setItem('isPageReload', 'not-yet');
          window.location.reload();
        }
      }, 2000)

      document.getElementById('videoOn').style.display = "block";
      document.getElementById('videoOff').style.display = "none";
      document.getElementById('unmute').style.display = "block";
      document.getElementById('mute').style.display = "none";

      document.getElementById('muteUnmute').style.display = "none";
      document.getElementById('share-screen').style.display = "none";
      document.getElementById('videoOnOff').style.display = "none";
      document.getElementById('changeLayout').style.display = "none";
      $("#internetstatus").hide();
      //$("#onlineOffline").hide();

      dragElement(document.getElementById("self-view"))

      let elem = document.getElementById("remote-view-video")
      elem.style.width = elem.parentElement.clientWidth + 10 + "px";
      elem.style.height = elem.parentElement.clientWidth / RATIO + "px";


      localStorage.setItem('comingFromVcPage', 'true')
      $('body').css('padding-top', '0px')

      this.dashboardUrl = environment.UIBaseUrl + '/#/structure/(structure-outlet:dashboards/alpha)';
      var self = this;
      this.meetingId = this.route.snapshot.paramMap.get('meetingId');

      this.webexService.getCiscoIP('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL)
        .then(result => {
          var data = result;
          data.allConfigurations.forEach(function (ele) {
            if (ele.key == "CISCO_IP") {
              self.ciscoIP = ele.value;
            }
          })
        });

      // document.body.style["paddingTop"] = "0px !important";

      (<HTMLVideoElement>document.getElementById('self-view')).muted = true;
      (<HTMLVideoElement>document.getElementById('self-view')).autoplay = true;
      var WebexAcessToken;
      var activeMeeting;
      var selfWebexId;
      var isModerator = false;
      var isParticipant = false;
      var webex;
      var webexInterval;
      var object;
      var userInfo = JSON.parse(localStorage.getItem('user'));
      var userId = JSON.parse(localStorage.getItem('user'))._id;
      var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
      var token = localStorage.getItem('token');
      var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
      var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
      var companyName = localStorage.getItem('companyDetailsGoveva')

      this.webexService.getSIPurl(this.meetingId).then(response => {
        var data = response;
        if (data.status == 0) {
          this.SIPURLforMeeting = data.data.sipUri;
          this.meetingNumber = data.data.meetingKey;
          this.meetingNumberNew = this.meetingNumber;
          this.meetingPassword = data.data.meetingPassword;
          this.Mgt1List = [];

          if (!this.guestLogin) {
            this.membershipInstance.listMeetings(this.meetingNumberNew)
              .then((res: any) => {
                console.log(res)
                this.webexmeetingId = res.items[0].id;
                this.Mgt1List.push(this.webexmeetingId);
              })
            webexInterval = setInterval(function () {
              WebexAcessToken = localStorage.getItem('WebexAcessToken');
              if (WebexAcessToken != '' && WebexAcessToken != undefined && WebexAcessToken != null && WebexAcessToken != ' ') {
                readytest();
              }
            }, 90);
          } else {
            let user = JSON.parse(localStorage.getItem('user'))
            this.webexService.getGuestToken(user.FirstName + ' ' + user.LastName).then(response => {
              var data = response;
              WebexAcessToken = data.data.token;
              readytest();
            })
          }
        }

      }).catch(error => {
        console.log("errorerrorerror1", error)
      })

      function timeToString(time) {
        let diffInHrs = time / 3600000;
        let hh = Math.floor(diffInHrs);

        let diffInMin = (diffInHrs - hh) * 60;
        let mm = Math.floor(diffInMin);

        let diffInSec = (diffInMin - mm) * 60;
        let ss = Math.floor(diffInSec);

        let diffInMs = (diffInSec - ss) * 100;
        let ms = Math.floor(diffInMs);

        let formattedMM = mm.toString().padStart(2, "0");
        let formattedSS = ss.toString().padStart(2, "0");
        let formattedMS = ms.toString().padStart(2, "0");

        return `${formattedMM}:${formattedSS}:${formattedMS}`;
      }

      function print(txt) {
        document.getElementById("display").innerHTML = txt;
      }

      function start() {
        self.ActiveMeeting.resumeRecording()
        showButton("PAUSE");
      }

      function pause() {
        self.ActiveMeeting.pauseRecording()
        showButton("PLAY");
      }

      function showButton(buttonKey) {
        const buttonToShow = buttonKey === "PLAY" ? playButton : pauseButton;
        const buttonToHide = buttonKey === "PLAY" ? pauseButton : playButton;
        buttonToShow.style.display = "block";
        buttonToHide.style.display = "none";
      }
      let playButton = document.getElementById("playButton");
      let pauseButton = document.getElementById("pauseButton");
      playButton.addEventListener("click", start);
      pauseButton.addEventListener("click", pause);

      function readytest() {
        if (!self.guestLogin) {

          webex = Webex.init({
            config: {
              meetings: {
                reconnection: {
                  enabled: true
                }
              }
            },
            credentials: {
              access_token: WebexAcessToken
            }
          });
          self.WebexInstance = webex;
          connect();
          clearInterval(webexInterval);
        } else {
          webex = Webex.init({
            config: {
              meetings: {
                reconnection: {
                  enabled: true
                }
              }
            }
          });
          webex.once('ready', () => {
            webex.authorization.requestAccessTokenFromJwt({ jwt: WebexAcessToken })
              .then((resData) => {
                self.WebexInstance = webex;
                connect();
                clearInterval(webexInterval);
              })
          })
        }
      }

      function connect() {
        return new Promise((resolve) => {
          if (!webex.meetings.registered) {
            webex.meetings.register()
              .then(() => webex.meetings.syncMeetings())
              .then(() => {
                showPage();
                document.body.classList.add('listening');
                dialerfun();
                resolve(null);
              })
              .catch((err) => {
                if (self.guestLogin && self.eventCalledOnce == 0) {
                  document.body.classList.add('listening');
                  self.eventCalledOnce = 1;
                  showPage();
                  dialerfun();
                  resolve(null);
                } else if (!self.guestLogin) {
                  var w = 600;
                  var h = 600;
                  var left = Number((screen.width / 2) - (w / 2));
                  var tops = Number((screen.height / 2) - (h / 2));
                  window.open('https://idbroker.webex.com/idb/saml2/jsp/doSSO.jsp?type=logout', 'popup', 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
                  setTimeout(function () {
                    localStorage.setItem('isPageReload', 'not-yet');
                    window.open(environment.webexUrl, 'popup', 'width=' + w + ', height=' + h + ', top=' + tops + ', left=' + left);
                  }, 1800)
                }
              });
          }
          else {
            resolve(null);
          }
        });
      }

      function dialerfun() {
        const destination = self.SIPURLforMeeting;
        connect()
          .then(() => {
            return webex.meetings.create(destination).then((meeting) => {
              return joinMeeting(meeting);
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }

      function joinMeeting(meeting) {
        activeMeeting = meeting;
        self.ActiveMeeting = activeMeeting;
        bindMeetingEvents(meeting);
      }

      function bindMeetingEvents(meeting) {
        meeting.on('error', (err) => {
          console.error("errorr- meeting", err);
        });

        meeting.on('media:ready', (media) => {

          if (!media) {
            return;
          }

          if (media.type === 'local') {
            (<HTMLVideoElement>document.getElementById('self-view')).srcObject = media.stream;
          }
          if (media.type === 'remoteVideo') {
            (<HTMLVideoElement>document.getElementById('remote-view-video')).srcObject = media.stream;
          }
          if (media.type === 'remoteAudio') {
            (<HTMLVideoElement>document.getElementById('remote-view-audio')).srcObject = media.stream;
            var iframe = document.getElementById('iframeWind')
            iframe.setAttribute("src", environment.UIBaseUrl + '/#/meeting-view/' + self.meetingId);
          }
          if (media.type === 'remoteShare') {
            (<HTMLVideoElement>document.getElementById('remote-screen')).srcObject = media.stream;
          }
          if (media.type === 'localShare') {
            (<HTMLVideoElement>document.getElementById('self-screen')).srcObject = media.stream;
          }

          document.getElementById('muteUnmute').style.display = "initial";
          document.getElementById('share-screen').style.display = "initial";
          document.getElementById('videoOnOff').style.display = "initial";
          document.getElementById('changeLayout').style.display = "initial";
        });

        meeting.on('media:stopped', (media) => {
          if (media.type === 'local') {
            (<HTMLVideoElement>document.getElementById('self-view')).srcObject = null;
          }
          if (media.type === 'remoteVideo') {
            (<HTMLVideoElement>document.getElementById('remote-view-video')).srcObject = null;
          }
          if (media.type === 'remoteAudio') {
            (<HTMLVideoElement>document.getElementById('remote-view-audio')).srcObject = null;
          }
          if (media.type === 'remoteShare') {
            (<HTMLVideoElement>document.getElementById('remote-screen')).srcObject = null;
          }
          if (media.type === 'localShare') {
            (<HTMLVideoElement>document.getElementById('self-screen')).srcObject = null;
          }
        });

        meeting.members.on('members:update', (delta) => {
          const { full: membersData } = delta;
          const memberIDs = Object.keys(membersData);
          memberIDs.forEach((memberID) => {
            const memberObject = membersData[memberID];
            if (memberObject.isSelf) {
              console.log(memberObject.isAudioMuted ? "muted" : "unmuted")
              if (memberObject.isAudioMuted) {
                document.getElementById('unmute').style.display = "none";
                document.getElementById('mute').style.display = "block";
              }
              else {
                document.getElementById('unmute').style.display = "block";
                document.getElementById('mute').style.display = "none";

              }

              selfWebexId = memberID
            }

            var isModeratorG = localStorage.getItem('isModerator')
            var isAdmin = localStorage.getItem('isAdmin')
            var isMeetingInitiated = localStorage.getItem('isMeetingInitiated')
            if (isModeratorG == "true" || isMeetingInitiated == 'initiate' || isAdmin == 'true') {
              if (isModeratorG == "true" && isMeetingInitiated == 'initiate') {
                document.getElementById('allMuteUnmute').style.display = "initial";
                document.getElementById('allUnmute').style.display = "block";
                document.getElementById('allMute').style.display = "none";
                document.getElementById('recording').style.display = "initial";
              }

              if (isModerator == false) {
                self.startStopMeeting(true);
                isModerator = true;
                isParticipant = false;
                document.getElementById('endleaveMeeting').style.display = "initial";
              }

              if (isMeetingInitiated == 'join') {
                self.joinParticipantMeeting();
              }
            } else {
              if (isParticipant == false) {
                self.joinParticipantMeeting();
                isParticipant = true;
                isModerator = false;
                document.getElementById('leaveMeeting').style.display = "initial";
                document.getElementById('leaveMeeting').style.display = "contents";
              }
            }
          });
        });



        meeting.members.on('members:content:update', (payload) => {
          if (payload.activeContentSharingId != null) {
            if (selfWebexId == payload.activeContentSharingId) {
              document.getElementById('self-screen').style.display = "block";
            } else {
              self.isScreenSharing = true;
              document.getElementById('remote-screen').style.display = "block";
              document.getElementById('self-screen').style.display = "none";
              document.getElementById("remote-view-video").classList.add("activePIP")
              document.getElementById('remote-view-video').style.width = null;
              document.getElementById('remote-view-video').style.height = null;
            }
          }

          if (payload.activeContentSharingId == null) {
            try {
              if (activeMeeting) {
                waitForMediaReady(activeMeeting).then(() => {
                  activeMeeting.updateShare({
                    sendShare: false,
                    receiveShare: true
                  });


                });
              }
            } catch (error) {
              console.log("errorr", error);
            }

            if (payload.activeContentSharingId == null || payload.activeContentSharingId == "" || payload.activeContentSharingId == ' ') {
              self.isScreenSharing = false;
              document.getElementById('remote-screen').style.display = "none";
              document.getElementById('self-screen').style.display = "none";
              document.getElementById('remote-view-video').classList.remove("activePIP");
              let elem = document.getElementById("remote-view-video")
              if (!self.isVideoFullScreen) {
                elem.style.width = elem.parentElement.clientWidth + 2 + "px";
                elem.style.height = elem.parentElement.clientWidth / RATIO + "px";
              } else {
                self.screenHeight = window.screen.height
                elem.style.width = self.screenHeight * RATIO + "px";
                elem.style.height = self.screenHeight + "px";
              }
            }
          }

        });

        meeting.on("meeting:media:remote:start", () => {
        })

        meeting.on("network:disconnected", () => {
          $("#internetstatus").show();
          $("#internetstatus").css("background-color", "red")
          $("#internetstatus").html("No Internet Connection!! Please check that you are connected to internet!");
        })

        meeting.on("network:connected", () => {
          $("#internetstatus").html("Internet Connected");
          $("#internetstatus").css("background-color", "green")
          setTimeout(() => $("#internetstatus").hide(), 1000)
        })

        meeting.on("meeting:reconnectionStarting", () => {
          $("#internetstatus").html("Reconnecting...");
          $("#internetstatus").css("background-color", "red")
          $("#internetstatus").show();
        })

        meeting.on("meeting:reconnectionSuccess", () => {
          $("#internetstatus").html("Reconnection Successful");
          $("#internetstatus").css("background-color", "green")
          setTimeout(() => $("#internetstatus").hide(), 1000)
        })

        meeting.on("meeting:reconnectionFailure", (err) => {
          $("#internetstatus").html("Reconnection Failed.");
          console.log(err)
        })

        meeting.on("meeting:highPacketLoss", (data) => {
          $("#internetstatus").html("Poor Internet Connection...");
          $("#internetstatus").css("background-color", "red")
          $("#internetstatus").show();
          clearTimeout(self.timeoutRef)
          self.timeoutRef = setTimeout(() => {
            $("#internetstatus").hide()
          }, 5000)
          console.log("packets loss", data)
        })



        return meeting.join().then(() => {
          const mediaSettings = {
            receiveVideo: true,
            receiveAudio: true,
            receiveShare: true,
            sendVideo: true,
            sendAudio: true,
            sendShare: false
          };


          return meeting.getMediaStreams(mediaSettings).then((mediaStreams) => {
            const [localStream, localShare] = mediaStreams;

            meeting.addMedia({
              localShare,
              localStream,
              mediaSettings
            });
          });
        }).catch((err) => {
          if (confirm("This meeting has not started yet or it has been ended, Please try again later or click on OK to reconnect")) {
          } else {
            self.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
          }
        });
      }

      function waitForMediaReady(meeting) {
        return new Promise((resolve, reject) => {
          if (1 == 1) {
            resolve(null);
          }
          else {
            let retryAttempts = 0;

            const retryInterval = setInterval(() => {
              retryAttempts += 1;
              if (meeting.canUpdateMedia()) {
                clearInterval(retryInterval);
                resolve(null);
              }
              else if (retryAttempts > 15) {
                clearInterval(retryInterval);
                reject();
              }
            }, 1000);
          }
        });
      }

      function showPage() {
        document.getElementById("cover-spin").style.display = "none";
      }
      document.getElementById('share-screen').addEventListener('click', () => {
        if (activeMeeting) {
          waitForMediaReady(activeMeeting).then(() => {
            const mediaSettings = {
              receiveShare: true,
              sendShare: true
            };
            activeMeeting.getMediaStreams(mediaSettings)
              .then((mediaSettingsResultsArray) => {
                const [, localShare] = mediaSettingsResultsArray;
                return activeMeeting.updateShare({
                  sendShare: true,
                  receiveShare: true,
                  stream: localShare
                });
              })
          });

        }

      });


      document.getElementById('muteUnmute').addEventListener('click', () => {
        if (activeMeeting) {

          if (activeMeeting.audio.muted == false) {
            document.getElementById('unmute').style.display = "none";
            document.getElementById('mute').style.display = "block";

            activeMeeting.muteAudio();
          } else {
            document.getElementById('mute').style.display = "none";
            document.getElementById('unmute').style.display = "block";
            activeMeeting.unmuteAudio();
          }
        }
      });

      document.getElementById('expand').addEventListener('click', () => {
        this.isVideoFullScreen = true
        if (!this.isScreenSharing) {
          var elem = document.getElementById("remote-view-video")
          this.screenHeight = window.screen.height
          elem.style.width = this.screenHeight * RATIO + "px";
          elem.style.height = this.screenHeight + "px";
        }
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
          docElmWithBrowsersFullScreenFunctions.requestFullscreen()
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
          docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
          docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }

        document.getElementById('expand').style.display = "none";
        document.getElementById('compress').style.display = "block";
        document.getElementById("sec2").style.display = "none";
        document.getElementById("main-video").style.height = null;
        document.getElementById("main-video").style.width = null;
        document.getElementById("sec1").classList.remove("col-lg-6", "col-sm-6", "col-xs-6");
        document.getElementById("sec1").classList.add("col-lg-12", "col-sm-12", "col-xs-12");
      });

      document.getElementById('compress').addEventListener('click', () => {
        this.isVideoFullScreen = false
        if (document.getElementById('remote-screen').style.display == 'block') {
          document.getElementById("innerVideo").style.display = "flex";
          document.getElementById("remote-view-video").style.display = "block";

        } else if (document.getElementById('self-screen').style.display == 'inline-block') {
          document.getElementById("innerVideo").style.display = "flex";
          document.getElementById("remote-view-video").style.display = "block";
          document.getElementById("self-view").style.display = "inline-block";
          document.getElementById("self-screen").style.height = "100px";
          document.getElementById("self-screen").style.width = "150px";

        } else {
          document.getElementById("innerVideo").style.display = "flex";
          document.getElementById("remote-view-video").style.display = "block";
        }
        document.getElementById('compress').style.display = "none";
        document.getElementById('expand').style.display = "block";
        document.getElementById("sec1").classList.remove("col-lg-12", "col-sm-12", "col-xs-12");
        document.getElementById("sec1").classList.add("col-lg-6", "col-sm-6", "col-xs-6");
        document.getElementById("sec2").style.display = "flex";
        document.getElementById("main-video").style.height = "100%";
        document.getElementById("main-video").style.width = "100%";
        document.getElementById("innerVideo").style.cssFloat = null;

        if (!this.isScreenSharing) {
          var elem = document.getElementById("remote-view-video")
          elem.style.width = elem.parentElement.clientWidth + 2 + "px";
          elem.style.height = elem.parentElement.clientWidth / RATIO + "px";
        }
      });
      document.getElementById('expandDoc').addEventListener('click', () => {
        const video: VideoElement = this.videoElement.nativeElement;
        video.requestPictureInPicture();

        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
          docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
          docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
          docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }

        document.getElementById('expandDoc').style.display = "none";
        document.getElementById('fullscreen').style.display = "none";
        document.getElementById('compressDoc').style.display = "block";
        document.getElementById('exitscreen').style.display = "inline-block";
        document.getElementById("sec1").style.display = "none";
        document.getElementById("sec2").classList.remove("col-lg-6", "col-sm-6", "col-xs-6");
        document.getElementById("sec2").classList.add("col-lg-12", "col-sm-12", "col-xs-12");
      });

      document.getElementById('compressDoc').addEventListener('click', () => {
        const documentCustome: DocumentCustome | any = document
        documentCustome.exitPictureInPicture()

        document.getElementById('compressDoc').style.display = "none";
        document.getElementById('exitscreen').style.display = "none";
        document.getElementById('fullscreen').style.display = "inline-block";
        document.getElementById('expandDoc').style.display = "block";
        document.getElementById("sec2").classList.remove("col-lg-12", "col-sm-12", "col-xs-12");
        document.getElementById("sec2").classList.add("col-lg-6", "col-sm-6", "col-xs-6");
        document.getElementById("sec1").style.display = "block";
      });

      setTimeout(() => {
        document.getElementById('ControlBtn').style.display = "block";
      }, 13000);

      document.getElementById('videoOnOff').addEventListener('click', () => {
        if (activeMeeting) {
          if (activeMeeting.video.muted == false) {
            document.getElementById('videoOn').style.display = "none";
            document.getElementById('videoOff').style.display = "block";
            activeMeeting.muteVideo();
          } else {
            document.getElementById('videoOff').style.display = "none";
            document.getElementById('videoOn').style.display = "block";
            activeMeeting.unmuteVideo();
          }
        }
      });

      document.getElementById('changeLayout').addEventListener('click', () => {
        const LAYOUT_TYPES = ['ActivePresence', 'Equal'];
        this.activeLayoutIndex = (this.activeLayoutIndex + 1) % LAYOUT_TYPES.length
        activeMeeting.changeVideoLayout(LAYOUT_TYPES[this.activeLayoutIndex]).then((res) => console.log(res)).catch((err) => console.log(err))
      });
      document.getElementById('allMuteUnmute').addEventListener('click', (event) => {
        event.preventDefault();
        if (activeMeeting) {

          if (activeMeeting.audio.muted == false) {
            document.getElementById('allUnmute').style.display = "none";
            document.getElementById('allMute').style.display = "block";
          } else {
            document.getElementById('allMute').style.display = "none";
            document.getElementById('allUnmute').style.display = "block";
          }
        }

      });

      document.getElementById('endMeetingNew').addEventListener('click', (event) => {
        event.preventDefault();
        if (activeMeeting) {
          activeMeeting.leave();
        }
        this.startStopMeeting(false)
      });
      document.getElementById('leaveMeeting').addEventListener('click', (event) => {
        event.preventDefault();
        var d = new Date();
        var date = d.getTime();
        if (activeMeeting) {
          activeMeeting.leave();
        }
        this.leaveParticipantMeeting(date)
        localStorage.removeItem('WebexAcessToken');
        localStorage.removeItem('WebexRefreshTokenExpiry');
        localStorage.removeItem('WebexRefreshToken');
        localStorage.removeItem('WebexAcessTokenExpiry');
      });
      document.getElementById('leaveMeetingNew').addEventListener('click', (event) => {
        event.preventDefault();
        var d = new Date();
        var date = d.getTime();
        if (activeMeeting) {
          activeMeeting.leave();
        }
        this.leaveParticipantMeeting(date)
        localStorage.removeItem('WebexAcessToken');
        localStorage.removeItem('WebexRefreshTokenExpiry');
        localStorage.removeItem('WebexRefreshToken');
        localStorage.removeItem('WebexAcessTokenExpiry');
      });
    })

  }

  ngAfterContentInit() {
    var checkStop = setInterval(() => {
      this.webexService.autoLeftParticipant('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL, this.meetingId).then(webexMeeting => {
        var data = webexMeeting;
        if (data.status == 5) {
          if (data.meetingAttendance.meetingEndTime != null) {
            clearInterval(checkStop);
            this.ActiveMeeting.leave();
            localStorage.setItem('comingFromVcPage', 'true')
            alert('Moderator has ended meeting.')
            this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
          }
        }
      });
    }, 10000);
    this.GlobalCheckStop = checkStop;

    window.onresize = () => {
      if (!this.isScreenSharing) {
        let elem = document.getElementById("remote-view-video")
        if (!this.isVideoFullScreen) {
          elem.style.width = elem.parentElement.clientWidth + 2 + "px";
          elem.style.height = elem.parentElement.clientWidth / RATIO + "px";
        } else {
          this.screenHeight = window.screen.height
          elem.style.width = this.screenHeight * RATIO + "px";
          elem.style.height = this.screenHeight + "px";
        }
      }
    }

    this.autoHideControlsButListener().subscribe();
  }


  startStopMeeting(status) {

    this.webexService.endMeetingByModerator('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL, this.meetingId, status).then(webexMeeting => {
      var data = webexMeeting;
      if (data.status == 0) {
        if (status == false) {
          localStorage.setItem('comingFromVcPage', 'true')
          this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
        }
      }
    });
  }


  leaveParticipantMeeting(leaveDateTimefromDevice) {
    localStorage.setItem('comingFromVcPage', 'true')

    this.webexService.leaveMeetingByParticipants('PSS', environment.pssServerUrl, environment.nonValidateUrl, environment.GEMURL, this.meetingId, leaveDateTimefromDevice).then(webexMeeting => {
      var data = webexMeeting;
      if (data.status == 0) {
        this.router.navigate(['structure', { outlets: { 'structure-outlet': 'dashboards/alpha' } }]);
      }
    });
  }

  autoHideControlsButListener() {
    return {
      subscribe: () => {
        $("#contorl-buts").addClass("hide");
        $("#sec1").mousemove(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").removeClass("hide");
          this.autoHideTimeout = setTimeout(function () {
            $("#contorl-buts").addClass("hide");
          }, 5 * 1000);
        }).mouseleave(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").addClass("hide");
        });

        $("#ControlBtn").mousemove(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").removeClass("hide");
          this.autoHideTimeout = setTimeout(function () {
            $("#contorl-buts").addClass("hide");
          }, 5 * 1000);
        }).mouseleave(() => {
          clearTimeout(this.autoHideTimeout);
          $("#contorl-buts").addClass("hide");
        });


      },
      unsubscribe: () => {
        clearTimeout(this.autoHideTimeout)
        $("#sec1").off("mousemove", null)
        $("#sec1").off("mouseleave", null)
      }
    }
  }


  joinParticipantMeeting() {
    this.webexService.joinMeetingAndParticipantAttendance('PSS', environment.pssServerUrl, environment.nonValidateUrl,
      environment.GEMURL, this.meetingId, "ATTENDING_REMOTELY").then(webexMeeting => {
        var data = webexMeeting;
        if (data.status == 0) {
        }
      });
  }

  openIfram() {
    this.showIFrame = true;
    var iframe = document.getElementById('iframeWind')
    iframe.setAttribute("src", environment.UIBaseUrl + '/#/structure/(structure-outlet:dashboards/alpha)');

  }


  openChat() {
    document.getElementById('ChatModal').style.display = "block";
    document.getElementById('Attendance').style.display = "none";
    document.getElementById('Document').style.display = "none";
    document.getElementById('Agenda').style.display = "none";
    document.getElementById('Invitee').style.display = "none";

    $('#MeetingTab .Agenda a').removeClass('active');
    $('#MeetingTab .Invitee a').removeClass('active');
    $('#MeetingTab .Documents a').removeClass('active');
    $('#MeetingTab .Participants a').removeClass('active');
    $('#MeetingTab .Messages a').addClass('active');
  }

  openMeeting() {
    this.showIFrame = false;
    var iframe = document.getElementById('iframeWind')
    iframe.setAttribute("src", environment.UIBaseUrl + '/#/meeting-view/' + this.meetingId);
  }

  ngOnDestroy() {
    localStorage.setItem('comingFromVcPage', 'true')

    if (this.ActiveMeeting && this.ActiveMeeting != undefined) {
      this.ActiveMeeting.leave();
    }
    clearInterval(this.GlobalCheckStop)
    $('body').css('padding-top', '4.64rem')
  }
  myFunction() {
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
  }

  recordingUpdate(recordingStarted: boolean, recordingPaused: boolean, locked: boolean) {
    this.membershipInstance.recordingUpdate(this.Mgt1List[0], { recordingStarted: recordingStarted, recordingPaused: recordingPaused, locked: locked })
      .then((response) => {
      })
      .catch(error => {
        console.error("error updateCustomerCode", error)
      })
  }
  onPageChange(action: string) {
    if (action == "recordingPaused") {
      this.recordingUpdate(true, false, false);
    }
    else {
      this.recordingUpdate(true, true, false);
    }
  }

  MuteAllUpdateParticipant(muted: boolean, admit: boolean, expel: boolean) {
    this.ParticipantsList = [];
    this.membershipInstance.listMeetingParticipants(this.Mgt1List[0])
      .then((res: any) => {
        for (let i = 0; i < res.items.length; i++) {
          if (res.items[i].host == false) {
            this.ParticipantsList.push(res.items[i].id);
            for (let j = 0; j < this.ParticipantsList.length; j++) {
              this.membershipInstance.MuteAllUpdateParticipant(this.ParticipantsList[j], { muted: muted, admit: admit, expel: expel })
                .then((response) => {
                  if (muted == true) {
                    document.getElementById('allUnmute').style.display = "none";
                    document.getElementById('allMute').style.display = "block";
                  } else {
                    document.getElementById('allMute').style.display = "none";
                    document.getElementById('allUnmute').style.display = "block";
                  }
                })
                .catch(error => {
                  console.error("error MuteAllUpdateParticipant", error)
                })
            }
          }
        }

      })

  }

  onMuteallChange(action: string) {
    if (action == "Umuteall") {
      this.MuteAllUpdateParticipant(false, false, false);
    }
    else {
      this.MuteAllUpdateParticipant(true, false, false);
    }
  }

}

function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    elmnt.style.cursor = "move"
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement(e) {
    document.onmouseup = null;
    document.onmousemove = null;
    elmnt.style.top = null;
    elmnt.style.left = null;
    elmnt.style.transition = "all 0.25s linear"
    elmnt.style.cursor = "default"
    var x = elmnt.parentElement.clientWidth
    var y = elmnt.parentElement.clientHeight
    if (e.clientX < x / 2 && e.clientY < y / 2) {
      elmnt.style.top = 50 + "px";
      elmnt.style.left = 25 + "px";
      elmnt.style.right = null
      elmnt.style.bottom = null;
    } else if (e.clientX > x / 2 && e.clientY < y / 2) {
      elmnt.style.top = 50 + "px";
      elmnt.style.left = null;
      elmnt.style.right = 25 + "px";
      elmnt.style.bottom = null;
    } else if (e.clientX < x / 2 && e.clientY > y / 2) {
      elmnt.style.top = null
      elmnt.style.left = 25 + "px";
      elmnt.style.right = null;
      elmnt.style.bottom = 25 + "px";
    } else {
      elmnt.style.top = null;
      elmnt.style.left = null;
      elmnt.style.right = 25 + "px";
      elmnt.style.bottom = 25 + "px";
    }
    elmnt.style.transition = null
  }
}