import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoginService } from '../services/login.service';
import { HttpClient } from '@angular/common/http';
import { LastLoginDetailsService } from '../services/last-login-details.service';
import { govevaLinks } from '../enums/govevaUrlLinks';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var swal: any;
@Injectable()
export class AuthGuard implements CanActivate {
  StataURl: any;

  constructor(
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService,
    private lastloginService: LastLoginDetailsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var token = localStorage.getItem('token');
    var self = this;
    var UrlState = state.url;
    this.StataURl = state.url;
    var FnlUrlState = UrlState.substring(1);
    var path = next.routeConfig.path;

    if (!_.isNil(token)) {
      //for token based
      this.http
        .get(environment.clientUrl + 'updateAactivityTime')
        .subscribe((response) => {});

      return new Promise((resolve, reject) => {
        this.http
          .get(environment.clientUrl + 'validatetoken')
          .subscribe((response) => {
            if (_.get(response, 'status') == 0) {
              if (
                !(
                  path == govevaLinks.dashboard || path == govevaLinks.structure
                )
              ) {
                this.loginService.checkRoutesAuth(path).subscribe(
                  (authResponse) => {
                    // @ts-ignore
                    if (authResponse.status == 0) {
                      this.lastloginService.returnUserDetails(
                        _.get(response, 'userLoginDetails')
                      );
                      resolve(true);
                    } else {
                      swal(
                        {
                          title: 'You are not authorized to access this page.',
                          type: 'warning',
                          confirmButtonClass: 'btn-success',
                          confirmButtonText: 'Back',
                        },
                        function (inputValue) {
                          resolve(false);
                        }
                      );
                    }
                  },
                  (err) => (error) => {
                    self.lastloginService.returnUserDetails(
                      _.get(response, 'userLoginDetails')
                    );
                    resolve(true);
                  }
                );
              } else {
                self.lastloginService.returnUserDetails(
                  _.get(response, 'userLoginDetails')
                );
                resolve(true);
              }
            } else {
              // alert(_.get(response, 'message') + 'Kindly login again.')

              if (this.router.url == '/login') {
                // swal({
                //   title: "Kindly login again.",

                //   type: "warning",
                //   confirmButtonClass: "btn-success",
                //   confirmButtonText: "Login"
                // }, function (inputValue) {

                self.loginService.logOut().subscribe(
                  (response) => {
                    window.localStorage.clear();

                    // location.reload();
                    for (var count = 0; count < 10000; count++) {
                      window.clearInterval(count);
                    }
                    self.router.navigate(['/login']);
                  },
                  (err) => (error) => {
                    window.localStorage.clear();

                    // location.reload();
                    for (var count = 0; count < 10000; count++) {
                      window.clearInterval(count);
                    }
                    self.router.navigate(['/login']);
                  }
                );

                // });
                return false;
              }
            }
          });
      });
    } else {
      // this.router.navigate(['login'])
      localStorage.clear();
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
