import { Component, OnInit , Input } from '@angular/core';
import {NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RoleMasterService } from '../../../services/rolemaster.service';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.css'],
})
export class DeleteConfirmationModalComponent implements OnInit {

  @Input('roleObjectToDelete') roleObjectToDelete : any;

  constructor(private activeModal: NgbActiveModal, private roleMasterService: RoleMasterService) { }

  ngOnInit() {
  }


  confirmation(data: string) {
    
    if(data === 'Yes'){
      let roleId = this.roleObjectToDelete._id ; 
      this.roleMasterService.deleteRole(roleId)
      .then(response => {
        if(response.status == 0){
          //console.log("successfully deleted role");
          this.activeModal.close(response);
        }else{
          //console.log("successfully deleted role");
          this.activeModal.close(response);
        }       
      }, error => console.log(""))
      
    }
    else {
      this.activeModal.dismiss('No')
    }

  }

}
