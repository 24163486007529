import { Component } from '@angular/core';
import { OnInit, AfterViewInit } from '@angular/core';
@Component({
  selector: 'cat-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  copyrightYears:any;

  ngOnInit() {
    this.copyrightYears= new Date().getFullYear(); 
  }
 
 

}


