import { Component, OnInit, Input, ViewChild } from '@angular/core';

import {
  NgbDateStruct,
  NgbModal,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';

import { MeetingagendacomplianceComponent } from '../meetingagendacompliance/meetingagendacompliance.component';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { environment } from '../../../../environments/environment';

import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-meetingagendaview',
  templateUrl: './meetingagenda.component.html',
  styleUrls: ['./meetingagenda.component.css'],
})
export class MeetingAgendaViewComponent implements OnInit {
  @Input('agendaObject') agendaObject: any;
  @Input('meetingObjectView') meetingObject: any;

  agendaCompliances: any;
  isAgendaObjectEmpty: boolean;

  @ViewChild(DataTableDirective)
  dtElementAgendaCompliancePopUp: DataTableDirective;
  dtOptionsOfAgendaCompliancePopUp: DataTables.Settings = {};
  dtTriggerAgendaCompliancePopUp: Subject<any> = new Subject();

  agendaPdfIsAvailable: any;
  agendaUplaodedPdfID: any;
  agendaGenerationTitle: any;
  agendaGenerationID: any;
  AgendaFile: any;
  AgendaTitlePdf: any;
  AgendaNumberPdf: any;
  constructor(
    private router: Router, 
    private modalService: NgbModal, 
    public activeModal: NgbActiveModal,
    private meetingMasterService: MeetingMasterService,
  ) { }

  ngOnInit() {
    if (_.size(this.agendaObject)) {

     
      this.agendaObject = _.sortBy(this.agendaObject, (o) => o.Agendaorder);
      this.agendaObject = this.agendaObject.map(function (item) {
        var o = Object.assign({}, item);

        o.fileType = item.AgendaGenerationPdf.map(function (subItem) {
          var oSub = Object.assign({}, subItem);
          oSub = subItem.UploadFileName.slice(-3);

          return oSub;
        });

        return o;
      });
      console.log('this.agendaObject', this.agendaObject);

      this.isAgendaObjectEmpty = false;
    } else {
      this.isAgendaObjectEmpty = true;
    }

    //   $(document).on('show.bs.modal', '.modal', function () {
    //     var zIndex = 1040 + (10 * $('.modal:visible').length);
    //     $(this).css('z-index', zIndex);
    //     setTimeout(function() {
    //         $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    //     }, 0);
    // });

    this.dtOptionsOfAgendaCompliancePopUp = {
      destroy: true,
      scrollY: '400px',
      scrollCollapse: true,
      pagingType: 'full_numbers',
      paging: true,
      pageLength: 5,
      search: true,
      order: [1, 'asc'],
      columnDefs: [{ orderable: false, targets: [0, 1, 2, 3] }],
      lengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, 'All'],
      ],
    };

    // console.log("agendaObject-modal",this.agendaObject);
  }

  agendasCompliancepopUpDataToShow(agenda) {
    const modalRef = this.modalService.open(MeetingagendacomplianceComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.agendaComplianceObject = agenda.AgendaCompliance;

    modalRef.result
      .then(
        (response) => {
          // console.log("response", response);
        },
        (error) => {
          // console.log("modal agenda compliance", error)
        }
      )
      .catch((error) => {
        // console.log("Catch Error", error)
      });
    // var agendaCompliancePopUpTable = $('#agendaCompliancePopUpTable').DataTable({destroy : true}).destroy();

    // console.log("agendaDetailsObject",agenda);
    // if(agenda.AgendaCompliance == undefined || agenda.AgendaCompliance == []){
    //   this.agendaCompliances = [];
    // }else if(agenda.AgendaCompliance.length > 0 ){
    //   this.agendaCompliances = agenda.AgendaCompliance
    // }
    // this.dtTriggerAgendaCompliancePopUp.next()
  }

  initiateUploadAgendaPdf(data) {
    this.AgendaTitlePdf = data.AgendaTitle;
    this.AgendaNumberPdf = data.AgendaNumber;
    this.agendaPdfIsAvailable = data.AgendaGenerationPdf.IsPdfAvailable;
    this.agendaUplaodedPdfID = data.AgendaGenerationPdf.DocumentManagementID;
    this.agendaGenerationTitle = data.AgendaTitle;
    this.agendaGenerationID = data._id;

    this.AgendaFile = data.AgendaGenerationPdf.map(element => {
      var o = Object.assign({}, element)
      o.FileType = (element.PdfName).substr(-3)
      return o;
    })

    $("#AgendaPdfUploadTitle").html(this.AgendaTitlePdf);
    $("#AgendaNum").html(this.AgendaNumberPdf);
    $("#agendaPdfUploadDialog").modal('toggle');
  }

  downloadDocumentFile(fileID, fileType) {
    console.error('sklog> fileTypefileType', fileType);
    if (fileType == 'PDF' || fileType == 'pdf') {
      console.log("meetingagendacomponent-view")
      $('#myModalForDocument').data('modal', null);
      this.meetingMasterService
        .getAttendanceFileName(fileID)
        .then((response) => {
          var url =
            environment.PSSFileurl +
            '/Document_Type/' +
            response.data.Filename +
            '#toolbar=0';
          console.log(
            'response',
            environment.PSSFileurl + '/Document_Type/' + response.data.Filename
          );

          // url = encodeURIComponent(url)
          // var newurl = "/#/pdfviewer/" + url + '/' + this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName)
          console.error('sklog> fileTypefileType', url);
          window.open(url, '_blank');

          // this.router.navigate([
          //   'pdfviewer',
          //   url,
          //   this.meetingObject._id,
          //   fileID,
          //   btoa(response.data.OriginalFileName),
          // ]);
        });
    } else {
      this.meetingMasterService.getAttendanceFile(fileID);
    }
  }
}
