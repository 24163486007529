<div class="row m-0">
    <div class="col-sm-12 nav d-flex justify-content-between">
        <a href="javascript:void(0)" class="firstAgenda"><i class="fa fa-caret-left" aria-hidden="true"></i><i
                class="fa fa-caret-left" aria-hidden="true"></i> Agenda</a>
        <a (click)="showBack()" href="javascript:void(0)" class="back" style="display:none;"><i class="fa fa-caret-left"
                aria-hidden="true"></i><i class="fa fa-caret-left" aria-hidden="true"></i> Agenda</a>


        <span *ngFor="let agenda of agendaObject; let i = index;" id="content-{{+i}}" attr.data-id='{{+i + 1}}'
            [ngStyle]="{'display': i == this.currentAgendaOnDisplay ? 'block' : 'none' }">
            <div *ngIf="agenda.AgendaGenerationPdf.length >= 1">
                <a href="javascript:void(0)" (click)="initiateUploadAgendaPdf(agenda)" id="openAttachment-{{i}}">
                    <i class="fa fa-paperclip" aria-hidden="true"></i> {{agenda.AgendaGenerationPdf.length}}
                </a>
            </div>
        </span> 
        

        <a (click)="showNext()" href="javascript:void(0)" class="next" >Agenda <i class="fa fa-caret-right"
                aria-hidden="true"></i><i class="fa fa-caret-right" aria-hidden="true"></i></a>
        <a href="javascript:void(0)" class="lastAgenda" style="display:none;">Agenda <i class="fa fa-caret-right"
                aria-hidden="true"></i><i class="fa fa-caret-right" aria-hidden="true"></i></a>
    </div>
</div>
<div class="agenda-details">
    <div *ngIf="currentAgenda && currentAgenda?.FinalUrl && showAgenda">
            <div id='pdf-div' style="width:100% !important; height: 50em;" class='pdf-view'></div>
    </div>
    <!-- <div class="content-holder">
        <div class="content-pspdf">
            <div *ngIf="currentAgenda && currentAgenda?.FinalUrl && showAgenda">
                 <iframe style="width:100% !important" class="PSpdfClass"
                    [src]='sanitizer.bypassSecurityTrustResourceUrl(currentAgenda?.FinalUrl)' frameborder="0"
                    allow="autoplay; encrypted-media" allowfullscreen></iframe> 
                    <div>Adobe PDF View</div>
                    <div id='pdf-div' style="width:100% !important" class='pdf-view'></div>
            </div>
        </div>
    </div> -->
    <div class="content-holder" [hidden]="!hideAgenda">
        <div id='pdf-div-attach' style="width:100% !important; height: 50em;" class='pdf-view'></div>
    </div>
</div>
<div class="modal fade modal-size-medium" id="agendaPdfUploadDialogPSPFD" tabindex="-1" role="dialog"
    data-backdrop="static" data-keyboard="false" aria-labelledby="" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 60%;margin: auto;">
            <div class="modal-header">
                <h5 class="modal-title">
                    <i class="fa fa-upload"></i>
                    <span id="AgendaPdfUploadTitlePSPDF"></span>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body" style="border:1px solid #ccc;">
                <div class="col-md-12">
                    <div class="row collapse1">
                        <table class="table table-hover compact cell-border nowrap stripe" style="cursor: pointer;">
                            <thead>
                                <tr>
                                    <td style="border-top:0px !important;"> <span
                                            style="padding:10px 15px;background: #d8ebeb;font-weight: 600;letter-spacing: 1px;display: inline-block;">
                                            {{agendaGenerationNumber}} </span> </td>
                                </tr>
                                <tr>
                                    <td style="border-top:0px !important;"> <a (click)="showCurrent()"
                                            href="javascript:void(0)" data-dismiss="modal"><span id="AgendaTitleOnPopup"
                                                style="font-weight: bold;"></span></a></td>
                                </tr>
                                <tr>
                                    <th>Filename</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let agenda of AgendaFile; let count=index">
                                    <td tabindex="1" style="width: 70%;">
                                        {{agenda.PdfName}}
                                    </td>
                                    <td>
                                        <button type="button" class="btn-sm btn btn-warning"
                                            (click)="downloadDocumentFile(agenda.DocumentManagementID,agenda.FileType)">
                                            <span title="View"
                                                *ngIf="agenda.FileType == 'PDF' || agenda.FileType == 'pdf' "
                                                class="fa fa-eye"></span>
                                            <span title="Download"
                                                *ngIf="agenda.FileType != 'PDF' && agenda.FileType != 'pdf' "
                                                class="fa fa-download"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>