<!-- START: charts/chartjs -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Chart.js</strong>
            <a href="http://www.chartjs.org/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Line Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Line Chart -->
                    <canvas id="chart-line" width="400" height="200"></canvas>
                    <!-- End Line Chart -->
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Bar Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Bar Chart -->
                    <canvas id="chart-bar" width="400" height="200"></canvas>
                    <!-- End Bar Chart -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Radar Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Radar Chart -->
                    <canvas id="chart-radar" width="400" height="200"></canvas>
                    <!-- End Radar Chart -->
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Polar Area Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Polar Area Chart -->
                    <canvas id="chart-polar" width="400" height="200"></canvas>
                    <!-- End Polar Area Chart -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Pie Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Radar Chart -->
                    <canvas id="chart-pie" width="400" height="200"></canvas>
                    <!-- End Radar Chart -->
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Doughnut Chart</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.chartjs.org/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <!-- Doughnut Chart -->
                    <canvas id="chart-doughnut" width="400" height="200"></canvas>
                    <!-- End Doughnut Chart -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: charts/chartjs -->
