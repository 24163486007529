<div class="modal-content" id="meetingDetailPopupShow">
  <div class="modal-header">
    <H5 class="modal-title">Details</H5>
    <button type="button" class="close " style="right:15px;position:absolute">
      <span aria-hidden="true" (click)="closePopupOnCancel(meetingObject._id)">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!showAlertModal" style="background-color:#d8ebeb;padding:0;">
    <div class="location">
      <div class="container" style="width:100% !important">
        <div class="row">
          <div class="col-md-5 col-sm-12" *ngIf="meetingObject.MeetType != 'Circular Resolution'">
            <h3><a href="javascript:void(0)" (click)="goBack()"><i class="fa fa-angle-left"
                  aria-hidden="true"></i></a>{{meetingObject.MeetingTypeName}} - {{meetingObject.companyName}}</h3>
            <div class="meta">
              <span>Created by {{MeetingCreator}} </span><span>{{meetingObject.meetingType ? (meetingObject.meetingDate
                | date: 'MMMM dd,yyyy') : (meetingObject.MeetingDate ? (meetingObject.MeetingDate | date : 'MMMM
                dd,yyyy' ) :
                " " ) }}</span><span>{{meetingObject.meetingType ? meetingObject.meetingTime :
                (meetingObject.MeetingTime ? meetingObject.MeetingTime
                : " " )}} {{(meetingObject.MeetingPlace) ? " " : " " }}
                {{(meetingObject.meetingPlace) ? " " : " " }}
              </span>
            </div>
            <div class="address mt-2"><img src="/assets/images/pin-icon.png" alt="pin-icon"><span style="text-decoration: underline;              color: #979797;
              margin-left: 7px;">{{meetingObject.MeetingPlace}} {{meetingObject.meetingPlace}}</span></div>
          </div>
          <div class="col-md-7  col-sm-12 text-right" style="padding-top: 5px;">
            <span *ngIf="!showAlertModal">
              <span ngbDropdown #myDrop="ngbDropdown" style="position:relative">
                <button _ngcontent-c15="" aria-expanded="false" class="btn btn-outline-primary btn1"
                  data-toggle="dropdown" style="padding:8px !important"
                  *ngIf="meetingObject.MeetType != 'Circular Resolution'">Add to Calendar
                </button>
                <div class="dropdown-menu" style="left: 100%;top: 0%!important;">
                  <a class="dropdown-item" [href]="iCalendarEventUrl" target="_blank">ICalendar</a>
                  <a class="dropdown-item" [href]="googleCalendarEventUrl" target="_blank">Google Calendar </a>
                  <a class="dropdown-item" [href]="outLookEventUrl" target="_blank">Outllook</a>
                  <a class="dropdown-item" [href]="CustomYahooUrl" target="_blank">Yahoo Calendar</a>
                  <a class="dropdown-item" [href]="microsoftEventUrl" target="_blank">Microsoft Calendar</a>
                </div>
              </span>
            </span>

            <button style="padding:8px !important" class="btn btn-outline-primary btn2"
              (click)="openPopupForJoinMeetingOptions('guestLogin')"
              *ngIf="!showAlertModal && showJoinButton && hasMeetingStarted && !hasMeetingEnd && GemWeb">JOIN
              MEETING</button>

            <button style="padding:8px !important" class="btn btn-outline-primary btn1"
              (click)="showAlertMeetingNotStarted('guestLogin')"
              *ngIf="!isAdminRole && !isModerator && !showMeetingInitiateBtn && !showAlertModal && showJoinButton && !hasMeetingStarted && !hasMeetingEnd && GemWeb">JOIN
              MEETING</button>

            <button style="padding:8px !important" class="btn btn-outline-primary btn2"
              (click)="openPopup(meetingObject._id, 'initiate')"
              *ngIf="!showAlertModal && showMeetingInitiateBtn && !hasMeetingStarted && !hasMeetingEnd && GemWeb && meetingObject.MeetType != 'Circular Resolution'"
              [appHasPermission]="{'Url': '', 'method':'', 'menu': 'GEMModerator'}">INITIATE MEETING</button>
            <button *ngIf="!showAlertModal && GemWeb && isRecordingAvailable" style="padding:8px !important"
              class="btn btn-outline-primary btn2" (click)="openPopupForDownloadRecording()"
              [appHasPermission]="{'Url': '', 'method':'', 'menu': 'GEMModerator'}">Meeting Recording</button>
          </div>
        </div>
        <div class="row" *ngIf="meetingObject.MeetType == 'Circular Resolution' && isDirector">
          <div class="col-lg-12">
            <label>
              <h3> Response</h3>
            </label>

            <div class="form-group" *ngIf="(todayDate < dueDate) || (responseReceived)">
              <div class="col-md-12">
                <button class="btn btn-outline-green" [ngClass]="{'btn-outline-green-disable':Approve == true}"
                  (click)="cheackOne('Approve')"  [disabled]="isDisabled">Assent</button>&nbsp;&nbsp;
                <button class="btn btn-outline-green" [ngClass]="{'btn-outline-green-disable':Disapprove == true }"
                  (click)="cheackOne('Disapprove')"  [disabled]="isDisabled">Dissent</button>&nbsp;&nbsp;
                <button class="btn btn-outline-green" [ngClass]="{'btn-outline-green-disable':Interested == true }"
                  (click)="cheackOne('Interested')"  [disabled]="isDisabled">Interested but not voted</button>&nbsp;&nbsp;
                <button class="btn btn-outline-green" [ngClass]="{'btn-outline-green-disable':Abstain == true }"
                  (click)="cheackOne('Abstain')" [disabled]="isDisabled">Not Voted / Abstain</button>&nbsp;&nbsp;
                <button class="btn btn-outline-green" [ngClass]="{'btn-outline-green-disable':meeting == true }"
                  (click)="cheackOne('meeting')"  [disabled]="isDisabled">Take to Meeting</button>&nbsp;&nbsp;
              </div>              
              <br><br><br>
            </div>
            <div class="row" *ngIf="(todayDate > dueDate) && (!responseReceived) ">
              <p>You cannot submit a response for this circular as the due date is past</p> 
            </div>
            <label>
              <h5> Due Date of Response: {{dueDate | date : "dd-MM-y"}}</h5>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div id="individual-meeting">
      <app-individual-meeting [meetingObject]="meetingObject" [agendaObject]="meetingObject.Agenda"
        [documentObject]="meetingObject.Documents ? meetingObject.Documents : (meetingObject.attachments ? meetingObject.attachments : '')">
      </app-individual-meeting>
    </div>
    <div id="Agenda" style="display:none;">
      <app-meetingagenda [meetingObject]="meetingObject" [agendaObject]="meetingObject.Agenda"
        [clickedAgenda]="currentItem" [loadAgendaFromPagignation]="loadAgendaFromPagignation" 
        (emitFileURL)="onFileURL($event)">
      </app-meetingagenda>
    </div>

    <div id="Document" style="display:none;">
      <app-meetingdocument [meetingObject]="meetingObject"
        [documentObject]="meetingObject.Documents ? meetingObject.Documents : (meetingObject.attachments ? meetingObject.attachments : '')">
      </app-meetingdocument>
    </div>

    <div id="Attendance" style="display:none;">
      <app-meetingattendance [meetingObject]="meetingObject"></app-meetingattendance>
    </div>

    <div id="agendaAnalytics" style="display:none;">
      <app-meeting-agenda-analytics></app-meeting-agenda-analytics>
    </div>

    <div id="documentDetails" style="display:none;">
      <app-documentdetails></app-documentdetails>
    </div>


    <div id="agendaDetails" style="display:none;">
      <app-agendadetail [meetingObject]="meetingObject" [agendaObject]="meetingObject.Agenda"
      [clickedAgenda]="currentItem" [currentAgenda]="currentAgenda" (emitFileURL)="loadFromPagignation($event)">
    </app-agendadetail>
    </div>
  </div>
  <div *ngIf="showAlertModal" style="width: 100%;padding: 40px 0px;" class="location text-center">
    <button class="btn btn-outline-primary btn2"
      *ngIf="showAlertModal && showJoinButton && hasMeetingStarted && !hasMeetingEnd && GemWeb"
      (click)="openPopupOnCancel(meetingObject._id)">Attending in person</button>
    <button class="btn btn-outline-primary btn2" (click)="openPopup(meetingObject._id, 'join')"
      *ngIf="showAlertModal && showJoinButton && hasMeetingStarted && !hasMeetingEnd && GemWeb">Attending Remotely with
      VC</button>
    <button *ngIf="showAlertModal" type="button" class="btn btn-outline-primary btn1" data-dismiss="modal"
      (click)="closePopupOnCancel(meetingObject._id)">
      Cancel
    </button>
  </div>


  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Messages</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div style="padding: 0px;" class="modal-body chat-modal">
      <app-webx-chat></app-webx-chat>
    </div>
  </ng-template>

  <!-- Download Recording if available at cisco end -->
  <div class="modal fade" id="downloadRecordingPopup" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 54px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 25%;margin: 0 auto;">
        <div class="modal-header">
          <span>Download Meeting Recording?</span>
        </div>
        <div class="modal-footer" style="height: 100px !important;border: 1px solid #ccc !important;">
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary btn-sm" style="margin-left: 0%;" (click)="uploadRecordingToGFM()"
            data-dismiss="modal">
            Save to GFM
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="saveAlert" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 54px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 25%;margin: 0 auto;">
        <div class="modal-header">
          <span>Meeting Recording saved to GFM successfully.</span>
        </div>
        <div class="modal-footer" style="margin: 0 auto;">
          <button type="button" class="btn btn-default btn-sm" data-dismiss="modal">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Remind Me Modal -->
  <div class="modal" id="remindMePopup">
    <div class="modal-dialog" style="max-width: 600px;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Remind Me:</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="eventName">Event name:</label>
              <input type="text" class="form-control" id="eventName" (keyup)="activateRemindButton()">
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Remind me:</label>
              <select class="form-control" id="remindOption" (click)="activateRemindButton()">
                <option>30 minutes prior to the meeting</option>
                <option>10 minutes prior to the meeting</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Description:</label>
              <textarea class="form-control" id="" rows="4" placeholder="Type here" style="height: 88px;"
                (keyup)="activateRemindButton()"></textarea>
            </div>
            <button type="submit" class="btn float-right" disabled id="updateButton">Set Reminder</button>
          </form>
        </div>
      </div>
    </div>
  </div>