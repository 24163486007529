<div class="modal-body">
  <div class="row">

    <div class="table-responsive" >
      <table class="table table-hover compact cell-border nowrap stripe">
        <thead>
          <tr>
            <th>Invitee Name</th>
            <th>Email Id</th>
            <th>Designation</th>
            <th>Contact</th>
          </tr>
        </thead>
        
        <tbody>
          <tr *ngIf="isInviteeObjectEmpty">
            <td colspan="4" class="text-center">
                No Records Found.
            </td>              
          </tr>
          <tr *ngFor="let invitee of inviteeObject">
            <td>{{invitee.InviteeName ? invitee.InviteeName : (invitee.title + " " + invitee.firstName + " " + invitee.lastName )}}</td>
            <td>{{invitee.EmailId ? invitee.EmailId : invitee.emailId}}</td>
            <td>{{invitee.Designation ? invitee.Designation : invitee.designation}}</td>
            <td>
              <span *ngIf="invitee.MobileNo">
                <i class="fa fa-mobile" style="color:red"></i>
                : {{invitee.MobileNo}}{{ invitee.MobileNo ? ',' : ' '}}
              </span>
              <span *ngIf="invitee.mobileNumber">
                <i class="fa fa-mobile" style="color:red"></i>
                : {{invitee.mobileNumber}}{{ invitee.mobileNumber ? ',' : ' '}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>