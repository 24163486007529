<!-- START: forms/autocomplete -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Autocomplete</strong>
            <a href="http://www.runningcoder.org/jquerytypeahead/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Default</strong></h5>
                <p class="text-muted">Element: read <a href="http://www.runningcoder.org/jquerytypeahead/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <label for="example1">Countries</label>
                        <!-- Typeahead Container -->
                        <div class="typeahead__container">
                            <div class="typeahead__field">
                                <div class="typeahead__query">
                                    <div class="input-group">
                                        <input id="example1"
                                               class="form-control"
                                               name=""
                                               type="text"
                                               placeholder=""
                                               autocomplete="off" />
                                        <span class="input-group-btn">
                                            <a href="javascript: void(0);" class="btn btn-default">
                                                Find Shop
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Typeahead Container -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: forms/autocomplete -->
