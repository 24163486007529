<section class="card">
    <div class="card-header">
        <span class="cat__core__title col-md-6 text-uppercase mt-1">
            Notifications
        </span>
                <span class="col-md-6  col-sm-12 text-right">
                    <button type="button" class="btn btn-outline-primary btn1">delete all</button>
                    <button type="button" class="btn btn-outline-primary btn1">mark all as read</button>
                </span>
    </div>

    <div class="card-block watermark p-0">
        <div class="mb-5">
            <div class="accordion" id="accordionSingle" *ngIf="getAllNotification==false">
                <div class="card" *ngFor="let notification of notificationObject;let indexCount=index">
                    <div class="card-header" role="tab" id="headingSingle" data-toggle="collapse" data-parent="#accordionSingle" data-target="#singleNotification"
                        aria-expanded="true" aria-controls="singleNotification">
                        <div class="card-title">
                            <span class="accordion-indicator pull-right">
                                        <i class="plus fa fa-plus"></i>
                                        <i class="minus fa fa-minus"></i>
                                    </span>
                            <h5 class="mb-0 mr-3 d-inline-block" style="color: #07a7a5;">
                                <strong>{{notification.Title}}</strong>
                            </h5>
                        </div>
                    </div>
                    <div id="singleNotification" class="card-collapse collapse show" role="tabcard" aria-labelledby="headingSingle" aria-expanded="true">
                        <div class="card-block">
                            <div [innerHTML]="notification.Description | safeHtml">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="accordionAll" *ngIf="getAllNotification==true">
                <table class="table  dataTable" width="100%">
                    <tbody>
                        <tr *ngFor="let unreadNotification of unreadNotificationList;let indexCountUnread=index" 
                        class="unRead">
                            <td style="padding-left: 30px;">{{indexCountUnread+1}}</td>
                            <td style=" width: 12%;">{{unreadNotification.Title}}</td>
                            <td style=" width: 60%;" (click)="markNotificationStatusToReadByID(unreadNotification._id, 'One')" [innerHTML]="unreadNotification.Description | safeHtml">
                            </td>
                            <td style="padding-right: 30px;" class="text-right">
                                <a href="javascript: void(0);" title="Mark as Read"
                                (click)="markNotificationStatusToReadByID(unreadNotification._id, 'One')">Mark as Read</a><span>06:57 pm</span> 
                            </td>
                        </tr>
                        <tr *ngFor="let readNotification of readNotificationsList;let indexCountRead=index" 
                        class="read">
                            <td style="padding-left: 30px;">{{indexCountRead+1}}</td>
                            <td style=" width: 12%;">{{readNotification.Title}}</td>
                            <td style=" width: 60%;" [innerHTML]="readNotification.Description | safeHtml">
                            </td>
                            <td style="padding-right: 30px;" class="text-right">
                                <img (click)="deleteNotificationByID(readNotification._id)" alt="GovEVA" src="../assets/images/bin-icon.png"><span>03:57 pm</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>