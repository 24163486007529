<div class="clearfix"></div>
<div class="trade-window d-flex justify-content-between" *ngIf="enableDashboard && companyData?.Listed">
    <div class="leftblock dashboard-section "
        [appHasPermission]="{'Url': 'stockPrice', 'method':'get', 'menu': 'Dashboard'}">
        <div class="anch-pointer d-flex">
            <label class="mr-4">Trading Window:</label>
            <div *ngIf="selectedStockExchange=='NSE'">
                <div>
                    <div class="row">
                        <div *ngIf="tradingWindowStatus=='Off'">
                            <span>ON</span>
                            <span class="tradeOff">OFF</span>
                        </div>
                        <div *ngIf="tradingWindowStatus=='On'">
                            <span class="tradeOn">ON</span>
                            <span>OFF</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedStockExchange=='BSE'">
                <div>
                    <div class="row">
                        <div *ngIf="tradingWindowStatus=='Off'">
                            <span>ON</span>
                            <span class="tradeOff">OFF</span>
                        </div>

                        <div *ngIf="tradingWindowStatus=='On'">
                            <span class="tradeOff">ON</span>
                            <span>OFF</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="rightblock"><label>EVA: {{companyEVA | number : '1.2-2'}}</label></div>
</div>
<div class="tab-content" *ngIf="!enableDashboard">
    <section style="background-color: white">
        <h2>Welcome</h2>
    </section>
</div>
<div *ngIf="showSpinner" style="background-color:transparent"></div>
<div class="tab-content" *ngIf="enableDashboard">
    <div class="tab-pane active" role="tabcard">
        <div class="row dashboard-page">
            <div class="col-12" style="padding: 0;">
                <div class="row" style="margin: 0;">
                    <div class="col" *ngIf="companyData?.Listed">
                        <div [ngClass]="setSubCardClasses(1,2)"
                            [appHasPermission]="{'Url': 'stockPrice', 'method':'get', 'menu': 'Dashboard'}">
                            <section class="card" order-id="card-2">
                                <div class="card-heading">

                                    <div class="col-lg-12" style="padding: 0px">
                                        <div class="image"><img src="/assets/images/share-info-icon.png"
                                                alt="share information"></div>
                                        <span class="heading-button" data-toggle="modal" data-target="#ShareInfoModal"
                                            title="Share Information">Share<br>
                                            Information</span>
                                    </div>
                                    <div class="modal fade" id="ShareInfoModal" tabindex="-1" role="dialog">
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content" style="background: transparent;">
                                                <div class="modal-body">
                                                    <app-stock-price [data]="companyData"></app-stock-price>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col">
                        <div [ngClass]="setSubCardClasses(1,2)"
                            [appHasPermission]="{'Url': 'financialResults', 'method':'get', 'menu': 'Dashboard'}">
                            <section class="card" order-id="card-2">

                                <div class="card-heading">
                                    <div style="padding: 0px">
                                        <div class="image"><img src="/assets/images/financial-icon.png"
                                                alt="Financials"></div>
                                        <span class="heading-button" data-toggle="modal" data-target="#FinancialsModal"
                                            title="Financials" style="margin-top: 15px;">Financials</span>
                                    </div>
                                    <div class="modal fade" id="FinancialsModal" tabindex="-1" role="dialog">
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content" style="background: transparent;">
                                                <div class="modal-body">
                                                    <app-financial-results></app-financial-results>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col" [appHasPermission]="{'Url': 'keyRatios', 'method':'get', 'menu': 'Dashboard'}">
                        <section class="card" order-id="card-2">
                            <div class="card-heading">
                                <div style="padding: 0px">
                                    <div class="image"><img src="/assets/images/keyratio-icon.png" alt="KeyRatios">
                                    </div>
                                    <span class="heading-button" data-toggle="modal" data-target="#KeyRatiosModal"
                                        title="Key Ratios Details" style="margin-top: 15px;">KEY
                                        RATIOS</span>
                                </div>
                                <div class="modal fade" id="KeyRatiosModal" tabindex="-1" role="dialog">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content" style="background: transparent;">
                                            <div class="modal-body">
                                                <app-key-ratios></app-key-ratios>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col" [appHasPermission]="{'Url': 'keyRatios', 'method':'get', 'menu': 'Dashboard'}">
                        <section class="card" order-id="card-2">
                            <div class="card-heading">
                                <div style="padding: 0px">
                                    <div class="image"><img src="/assets/images/orgchart-icon.png" alt="orgchart"></div>
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#orgChartModal"
                                        class="org-chart-btn">
                                        <span class="orgchart-heading-button" title="Organization Chart">
                                            Organization<br>Chart</span>
                                    </a>
                                </div>
                            </div>
                            <app-orgchart></app-orgchart>
                        </section>
                    </div>
                    <!-- <div class="col" [appHasPermission]="{'Url': 'keyRatios', 'method':'get', 'menu': 'Dashboard'}">
                        <section class="card" order-id="card-2">
                            <div class="card-heading">
                                <div style="padding: 0px">
                                    <div class="image"><img src="/assets/images/orgchart-icon.png" alt="orgchart"></div>

                                    <a href="javascript:void(0)" data-toggle="modal"
                                        data-target="#materialityAssesmentModal" class="org-chart-btn">
                                        <span class="orgchart-heading-button" title="Materiality Assessment">
                                            Materiality Assessment</span>
                                    </a>
                                </div>
                            </div>
                            <app-materiality-assessment></app-materiality-assessment>
                        </section>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row dashboard-page">
            <div class="col-lg-6 col-md-6 col-sm-12" id="meters" *ngIf="RowTwo || RowTwo || RowThree || RowFour">
                <div class="row" style="padding: 0 10px;">
                    <!-- <div class="col-lg-6 col-md-6  col-sm-6 p-0" *ngIf="RowTwo">
                        <div class="col-sm-12 p-0" id="left-col">
                            <section class="card score-meter" order-id="card-2"
                                [appHasPermission]="{'Url': 'scoreMeter', 'method':'get', 'menu': 'Dashboard'}">
                                <div class="card-header">
                                    <h6 class="mb-0 mr-3 d-inline-block">

                                        ESG Assessment Score
                                    </h6>
                                </div>

                                <div class="card-block pt-0">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12" style="padding: 25px 25px 0 0;">
                                                <div id="internalScoreContainer" class="m-auto" width="100%"
                                                    *ngIf="internalScoreStatus" (click)="navigateTo('Internal')"
                                                    style="cursor: pointer;">
                                                    <div class="d-flex justify-content-between">
                                                        <label>Baseline</label>
                                                        <span id="internalCount">0%</span>
                                                    </div>
                                                    <div class="progress">
                                                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0"
                                                            class="progress-bar" id="internalScoreChart"
                                                            role="progressbar"></div>
                                                    </div>
                                                </div>
                                                <div class="m-auto" *ngIf="!internalScoreStatus">
                                                    <span style="font-weight: 600;color: #000;">No Internal Score
                                                        available</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div> -->
                    <div class="col-sm-12 p-0">
                        <div id="left-col">
                            <div>
                                <app-compliance-and-roadmap-chart [refreshMeter]="refreshMeter">
                                </app-compliance-and-roadmap-chart>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 m-0  col-sm-6 p-0" *ngIf="RowOne">
                        
                    </div>
                    <div class="col-lg-6 col-md-6 m-0  col-sm-6 p-0" *ngIf="RowOne">
                        <div class="row-sm-12 ml-2">
                            <div [ngClass]="setSubCardClasses(1,2)">
                                <div id="left-col">
                                    <section class="card" order-id="card-2">
                                        <div class="card-heading" style="padding:5px 5px;">
                                            <div style="padding: 0px">
                                                <div class="image">
                                                    <img src="/assets/images/env_data.png" alt="orgchart">
                                                </div>
    
                                                <a href="javascript:void(0)" (click)="EnvironmentModal.show()"
                                                    data-toggle="modal" data-target="#environmentModal"
                                                    class="org-chart-btn">
                                                    <span class="orgchart-heading-button" title="Materiality Assessment">
                                                        Environment Data</span>
                                                </a>
                                            </div>
                                        </div>
                                        <app-environment-data #environmentModal></app-environment-data>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row-sm-12 ml-2">
                            <div [ngClass]="setSubCardClasses(1,2)">
                                <div id="left-col">
                                    <section class="card" order-id="card-2">
                                        <div class="card-heading" style="padding:5px 5px;">
                                            <div style="padding: 0px">
                                                <div class="image"><img src="/assets/images/social_data.png" alt="orgchart">
                                                </div>
    
                                                <a href="javascript:void(0)" (click)="SocialModal.show()"
                                                    data-toggle="modal" data-target="#socialModal" class="org-chart-btn">
                                                    <span class="orgchart-heading-button" title="Materiality Assessment"
                                                        style="margin-top: 10px;">
                                                        Social Data</span>
                                                </a>
                                            </div>
                                        </div>
                                        <app-social-data #socialModal></app-social-data>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row" style="margin: 0;">
                    <!-- <div class="col">
                        <div [ngClass]="setSubCardClasses(1,2)">
                            <div id="left-col">
                                <section class="card" order-id="card-2">
                                    <div class="card-heading" style="padding:5px 5px;">
                                        <div style="padding: 0px">
                                            <div class="image">
                                                <img src="/assets/images/env_data.png" alt="orgchart">
                                            </div>

                                            <a href="javascript:void(0)" (click)="EnvironmentModal.show()"
                                                data-toggle="modal" data-target="#environmentModal"
                                                class="org-chart-btn">
                                                <span class="orgchart-heading-button" title="Materiality Assessment">
                                                    Environment Data</span>
                                            </a>
                                        </div>
                                    </div>
                                    <app-environment-data #environmentModal></app-environment-data>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div [ngClass]="setSubCardClasses(1,2)">
                            <div id="left-col">
                                <section class="card" order-id="card-2">
                                    <div class="card-heading" style="padding:5px 5px;">
                                        <div style="padding: 0px">
                                            <div class="image"><img src="/assets/images/social_data.png" alt="orgchart">
                                            </div>

                                            <a href="javascript:void(0)" (click)="SocialModal.show()"
                                                data-toggle="modal" data-target="#socialModal" class="org-chart-btn">
                                                <span class="orgchart-heading-button" title="Materiality Assessment"
                                                    style="margin-top: 10px;">
                                                    Social Data</span>
                                            </a>
                                        </div>
                                    </div>
                                    <app-social-data #socialModal></app-social-data>
                                </section>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col">
                        <div [ngClass]="setSubCardClasses(1,2)">
                            <div id="left-col">
                                <section class="card" order-id="card-2">
                                    <div class="card-heading" style="padding:5px 5px;">
                                        <div style="padding: 0px">
                                            <div class="image"><img src="/assets/images/gov_data.png" alt="orgchart">
                                            </div>

                                            <a href="javascript:void(0)" (click)="GovernanceModal.show()"
                                                data-toggle="modal" data-target="#governanceModal"
                                                class="org-chart-btn">
                                                <span class="orgchart-heading-button" title="Governance Data">
                                                    Governance Data</span>
                                            </a>
                                        </div>
                                    </div>
                                    <app-governance-data #governanceModal></app-governance-data>
                                </section>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row" style="padding: 0 10px;">
                    <div class="col-lg-12 col-md-12 m-0  col-sm-12 p-0" *ngIf="RowOne">
                        <div class="col-sm-12 p-0">
                            <div id="left-col">
                                <div class="cat__core__sortable" id="left-center-col">
                                    <div [appHasPermission]="{'Url': 'myTasks', 'method':'get', 'menu': 'Dashboard'}">
                                        <app-mytask [refreshStatus]="taskObject" (UpdatedStatus)="updatedTask($event)">
                                        </app-mytask>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding: 0 10px;">
                    <div class="col-lg-12 col-md-12 m-0  col-sm-12 p-0" *ngIf="RowOne">
                        <div class="col-sm-12 p-0">
                            <div id="left-col">
                                <div class="cat__core__sortable" id="left-center-col">
                                    <div
                                        [appHasPermission]="{'Url': 'companyNews', 'method':'get', 'menu': 'Dashboard'}">
                                        <app-company-news></app-company-news>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row dashboard-page esg" *ngIf="isESGConfig">
                </div>
            </div>
            <div
                [ngClass]="!(RowTwo || RowTwo || RowThree || RowFour)?'col-lg-12 col-md-12 col-sm-12':'col-lg-6 col-md-6 col-sm-12'">
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="RowFive || RowSix" id="meetingCol">
                    <div class="cat__core__sortable" id="right-center-col">
                        <app-meeting-assistent-wrapper [refreshData]="taskObject" (outputStatus)="updatedTask($event)"
                            [rowFive]="RowFive" [rowSix]="RowSix" [isRocketChatRemove]="isRocketChatRemove">
                        </app-meeting-assistent-wrapper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>