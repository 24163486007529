<header>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
</button>
  <div class="navbar navbar-dark bg-light box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="navbar-brand d-flex align-items-center">
  <img src="../../assets/Boardeval/image/logo.png">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</header>

<main role="main">


<div class="con-fluid">
<img src="../../assets/Boardeval/image/banner.jpg" class="img-fluid">
</div>	
<div class="py-5 bg-dark module-area">
    <div class="container">

      <div class="row">
  <div class="col-md-3 col-md-3 col-sm-6 col-12 text-center">
    <div class="green-box">
      <img src="../../assets/Boardeval/image/governance-icon.png">
      <h3>ESG</h3>
    </div>		
        </div>
  
  <div class="col-md-3 col-md-3 col-sm-6 col-12 text-center">
    <div class="green-box">
      <img src="../../assets/Boardeval/image/compliance-icon.png">
      <h3>Compliance Management</h3>
    </div>
        </div>
  
  
  
  <div class="col-md-3 col-md-3 col-sm-6 col-12 text-center">
    <div class="green-box">
      <img src="../../assets/Boardeval/image/e-Meeting-icon.png">
      <h3>e-Meetings & Collaboration</h3>
    </div>
        </div>
  
  <div class="col-md-3 col-md-3 col-sm-6 col-12 text-center">
    <div class="green-box">
      <img src="../../assets/Boardeval/image/board-icon.png">
      <h3>Board Evaluation</h3>
    </div>
  </div>


        
      </div>
    </div>
</div>

</main>

<footer class="text-muted">
  <div class="container">
<div class="row">
  <div class="col-md-12 text-center">
    <h3>TO KNOW MORE -</h3>
  </div>
  <div class="col-md-4 col-12 col-sm-6 text-center">
    <p>Visit <a href="#">www.goveva.con</a></p>
  </div>
  <div class="col-md-4 col-12 col-sm-6 text-center">
    <p>Contact:- <a href="#">+91 81692 40556</a></p>
  </div>
  <div class="col-md-4 col-12 col-sm-6 text-center">
    <p>Email:- <a href="#">sales@goveva.con</a></p>
  </div>
</div>
  </div>
</footer>
<div class="container">
<div class="row">
  <div class="col-md-12 bottom-menu">
    <a href="javascript:void()"  (click)="cancel()">Proceed to Evaluation</a>
  </div>
</div>
</div>