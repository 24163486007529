import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UINotificationService } from "../../services/notificationUI.service";
import * as _ from "lodash";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: "app-notifications-UI",
  templateUrl: "./notifications-UI.component.html",
  styleUrls: ["./notifications-UI.component.css"],
})
export class UINotificationComponent implements OnInit {
  notificationID: any;
  getAllNotification: boolean = false;
  unreadNotificationList: any = [];
  readNotificationsList: any = [];
  notificationObject: any;

  constructor(
    private route: ActivatedRoute,
    private uiNotificationService: UINotificationService,
    private modalService: NgbModal,
    public datepipe: DatePipe
  ) {
    this.route.params.subscribe((data) => {
      if (data.id == undefined || data.id == null || data.id == 0) {
        this.notificationID = null;
        this.getAllNotification = true;
      } else {
        this.notificationID = data.id;
        this.getAllNotification = false;
      }
    });
  }

  ngOnInit() {
    if (this.getAllNotification) {
      // let userId = JSON.parse(localStorage.getItem('user'))._id
      let userId = JSON.parse(localStorage.getItem("user")).UniqueUserId;
      this.uiNotificationService.getUINotificationForUser(userId).then((response) => {
        if (response.status == 0) {
          this.readNotificationsList =
            _.size(response.readNotificationList) <= 0
              ? response.readNotificationList
              : _.orderBy(response.readNotificationList, ["CreatedDate"], ["desc"]);
          this.unreadNotificationList =
            _.size(response.unreadNotificationList) <= 0
              ? response.unreadNotificationList
              : _.orderBy(response.unreadNotificationList, ["CreatedDate"], ["desc"]);
        } else {
          this.readNotificationsList = null;
          this.unreadNotificationList = null;
        }
      });
      // this.uiNotificationService.getUINotificationByID(this.notificationID).then(response => {
      //   if (response.status == 0) {
      //     this.notifications = [response.notificationObject];
      //   }
      // });
    } else {
      this.uiNotificationService.getUINotificationByID(this.notificationID).then((response) => {
        if (response.status == 0) {
          this.notificationObject = [response.notificationObject];
        }
      });
    }
  }

  markNotificationStatusToReadByID(notificationIDToUpdate, action) {
    if (action == "All") {
      // this.notifications.forEach(notificationData => {
      //   this.uiNotificationService.markNotificationStatusToReadByID(notificationData._id).then(response => {
      //     if (response.status == 0) {
      //       _.remove(this.notifications, function (o: any) { return o._id == notificationData._id });
      //     }
      //   });
      // });
    } else if (action == "One") {
      this.uiNotificationService
        .markNotificationStatusToReadByID(notificationIDToUpdate)
        .then((response) => {
          if (response.status == 0) {
            var notificationToMark = _.filter(this.unreadNotificationList, function (o) {
              return o._id === notificationIDToUpdate;
            });
            _.remove(this.unreadNotificationList, function (o: any) {
              return o._id === notificationIDToUpdate;
            });
            this.readNotificationsList.push(notificationToMark[0]);
            this.readNotificationsList =
              _.size(this.readNotificationsList) <= 0
                ? this.readNotificationsList
                : _.orderBy(this.readNotificationsList, ["CreatedDate"], ["desc"]);

            var count = $(".notification_count").text();
            $(".notification_count").text(count - 1);
            $("#" + notificationIDToUpdate).remove();

            let userId = JSON.parse(localStorage.getItem("user"))._id;
            let notificationData: any = [];

            this.uiNotificationService.getUINotificationForUser(userId).then((response) => {
              if (response.status == 0) {
                notificationData = response.unreadNotificationList;
              } else {
                notificationData = null;
              }

              if (_.size(notificationData) > 2) {
                $(".notificationList").html("");
                var index = 0;
                var html = "";
                notificationData.forEach((notification) => {
                  index++;
                  if (index <= 3) {
                    html =
                      html +
                      "<div>" +
                      "<div id=" +
                      notification._id +
                      ' class="cat__top-bar__activity__item" style="cursor: pointer;" (click)="viewNotification(' +
                      notification._id +
                      ')">' +
                      '<div class="cat__top-bar__activity__inner">' +
                      '<div class="cat__top-bar__activity__title">' +
                      '<span class="pull-right">' +
                      this.datepipe.transform(notification.CreatedDate, "dd-MM-yyyy") +
                      "</span>" +
                      "</div>" +
                      '<div class="cat__top-bar__activity__descr" style="clear: both;">' +
                      notification.Description +
                      "</div>" +
                      "</div>" +
                      "</div>" +
                      "</div>";
                  }
                });
                html =
                  html +
                  '<div class="cat__top-bar__notification__view-all">' +
                  '<a (click)="viewAllNotification()" href="javascript: void(0);">View All</a>' +
                  "</div>";
                $(".notificationList").html(html);
              }

              if (count - 1 <= 0) {
                $(".notification_count").remove();
                $(".notificationList").remove();
                $(".notificationParent").append(
                  '<div class="cat__top-bar__activity">' +
                    '<div class="cat__top-bar__activity__item" style="text-align: center; color: #919191; padding: 15px 0;">' +
                    "All caught up!" +
                    "</div>" +
                    '<div class="cat__top-bar__notification__view-all">' +
                    '<a (click)="viewAllNotification()" href="javascript: void(0);">View All</a>' +
                    "</div>" +
                    "</div>"
                );
              }
            });

            $.notify(
              {
                title: "",
                message: "Selected notification marked as read.",
              },
              {
                type: "success",
              }
            );
          }
        });
    }
  }

  deleteNotificationByID(notificationIDToDelete) {
    this.uiNotificationService.deleteNotificationById(notificationIDToDelete).then((response) => {
      if (response.status == 0) {
        _.remove(this.readNotificationsList, function (o: any) {
          return o._id == notificationIDToDelete;
        });

        $.notify(
          {
            title: "",
            message: "Selected notification deleted successfully.",
          },
          {
            type: "danger",
          }
        );
      }
    });
  }

  deleteAllNotification() {
    var UserId = JSON.parse(localStorage.getItem("user"))._id;
    console.log("ppppppppppppppppp", UserId);
    this.uiNotificationService.deleteAllNotification(UserId).then((response) => {
      if (response.status == 0) {
        $.notify(
          {
            title: "",
            message: "All notification deleted successfully.",
          },
          {
            type: "danger",
          }
        );
      }
    });
  }
}
