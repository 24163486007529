import { Component, OnInit } from '@angular/core';
import { FinancialDataService } from './../../../services/financialData.service';

import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyMasterService } from '../../../services/companymaster.service';

declare var $: any;

@Component({
  selector: 'app-key-ratios',
  templateUrl: './keyRatios.component.html',
  styleUrls: ['./keyRatios.component.css'],
})
export class KeyRatiosComponent implements OnInit {
  earningsRatiosQuarterlyChart: Object;
  earningsRatiosYearlyChart: Object;
  marginRatiosQuarterlyChart: object;
  marginRatiosYearlyChart: object;
  // growthRatiosQuarterlyChart: object;
  growthRatiosYearlyChart: object;
  returnsRatiosYearlyChart: object;

  quarterlyKeyRatiosData: any;
  yearlyKeyRatiosData: any;
  companyFinancialYearFromMonthGlobel: any;
  isCompanyListed: any;
  isKeyRatios: any;
  constructor(private financialDataService: FinancialDataService, private modalService: NgbModal, private companyMasterService: CompanyMasterService) { }

  ngOnInit() {
    //  
    // $("body").removeClass("dashboard-page");
    $("body").addClass("dashboard-page dark-theme");
    //console.log("init, keyRatiosComponent", this.isCompanyListed);
    this.isCompanyListed = localStorage.getItem("isCompanyListed")
    this.isKeyRatios = localStorage.getItem("isKeyRatios")
    if (this.isCompanyListed != "false" && this.isKeyRatios != "false") {
      this.getQuarterlyTrendEarningsRatios();
    }
  }

  ngAfterViewInit() { }

  getQuarterlyTrendEarningsRatios() {
    this.companyMasterService.getCompanyList().then((company) => {
      try {
        if (this.isCompanyListed != 'false') {
          var self = this;
          this.financialDataService
            .getLastFiveQuatersKeyRatios()
            .then((responseData) => {
              //
              if (responseData.status == 0) {
                var count = 0;
                if (
                  responseData.keyRatios != null &&
                  _.size(responseData.keyRatios) > 0
                ) {
                  //
                  this.quarterlyKeyRatiosData = _.filter(
                    responseData.keyRatios
                  );
                  this.quarterlyKeyRatiosData.forEach((element) => {
                    _.remove(element.financialData, function (a: any) {
                      return a.Type == 'C';
                    });
                  });
                  var quaterLabel = [];
                  var epsData = [];
                  var cepsData = [];
                  var dpsData = [];
                  _.forEach(this.quarterlyKeyRatiosData, function (value) {
                    //  qr_EPS_BASIC
                    // epsData.push(value.financialData[0].qr_EPSABS);
                    epsData.push(value.financialData[0].qr_EPS_BASIC);

                    var financialQuater = value._id.toString();
                    var year = financialQuater.substr(0, 4);
                    var quater = financialQuater.substr(4, 2);
                    var companyFinancialYearFromyear;
                    var companyFinancialYearFromMonth;

                    if (company.status == 0) {
                      if (company.companyData.FinancialYearFrom) {
                        companyFinancialYearFromyear =
                          company.companyData.FinancialYearFrom;
                        var d = new Date(companyFinancialYearFromyear);
                        companyFinancialYearFromMonth = d.getMonth() + 1;
                        self.companyFinancialYearFromMonthGlobel =
                          companyFinancialYearFromMonth;
                      }
                    }

                    if (companyFinancialYearFromMonth == 1) {
                      switch (quater) {
                        case '03':
                          quater = 'Q1';
                          break;
                        case '06':
                          quater = 'Q2';
                          break;
                        case '09':
                          quater = 'Q3';
                          break;
                        case '12':
                          quater = 'Q4';
                          break;
                        default:
                          break;
                      }
                    } else if (companyFinancialYearFromMonth == 7) {
                      switch (quater) {
                        case '09':
                          quater = 'Q1';
                          break;
                        case '12':
                          quater = 'Q2';
                          break;
                        case '03':
                          quater = 'Q3';
                          //   year = (year - 1).toString();
                          break;
                        case '06':
                          quater = 'Q4';
                          //    year = (year - 1).toString();
                          break;
                        default:
                          break;
                      }
                    } else {
                      switch (quater) {
                        case '03':
                          quater = 'Q4';
                          // year = (year - 1).toString();
                          break;
                        case '06':
                          quater = 'Q1';
                          break;
                        case '09':
                          quater = 'Q2';
                          break;
                        case '12':
                          quater = 'Q3';
                          break;
                        default:
                          break;
                      }
                    }

                    quaterLabel.push(quater + ' ' + year);
                    // quaterLabel.push(value._id);
                  });
                  //console.log("this.quarterlyKeyRatiosData", this.quarterlyKeyRatiosData)
                  //
                  this.earningsRatiosQuarterlyChart = {
                    chart: {
                      type: 'spline',
                      zoomType: 'xy',
                      animation: {
                        enabled: false,
                      },
                      renderTo: 'earningsRatiosQuartelyDataSection',
                    },
                    title: {
                      text: '',
                    },
                    credits: {
                      enabled: false,
                    },
                    yAxis: {
                      title: {
                        text: false,
                      },
                    },

                    xAxis: {
                      categories: quaterLabel,
                      tickInterval: 1,
                    },
                    plotOptions: {
                      series: {
                        color: '#00ff00',
                      },
                    },
                    series: [
                      {
                        name: 'BASIC_EPS',
                        data: epsData,
                        color: '#00ff00',
                      },
                      {
                        name: 'CEPS',
                        data: cepsData,
                        color: '#37a7a5',
                      },
                      {
                        name: 'DPS',
                        data: dpsData,
                        color: '#2c1eae',
                      },
                    ],
                  };
                }
              } else {
              }
              $('.widget_kr').removeClass('active');
              $('#earningsRatiosQuartelyTrend').addClass('active');
              $('#earningsRatiosQuartelyDataSection').css('display', 'block');
              $('#earningsRatiosYearlyDataSection').css('display', 'none');
            });
        }
      } catch (error) { }
    });
  }

  getAnnualTrendEarningsRatios() {
    if (this.isKeyRatios != 'false') {
      try {
        this.financialDataService
          .getLastFiveYearsKeyRatios()
          .then((responseData) => {
            //
            var self = this;
            if (responseData.status == 0) {
              var count = 0;
              if (
                responseData.keyRatios != null &&
                _.size(responseData.keyRatios) > 0
              ) {
                //
                this.yearlyKeyRatiosData = _.filter(responseData.keyRatios);
                this.yearlyKeyRatiosData.forEach((element) => {
                  _.remove(element.financialData, function (a: any) {
                    return a.Type == 'C';
                  });
                });
                var yearLabel = [];
                var epsData = [];
                var cepsData = [];
                var dpsData = [];
                _.forEach(this.yearlyKeyRatiosData, function (value) {
                  //
                  epsData.push(value.financialData[0].fr_Reported_EPS);
                  cepsData.push(value.financialData[0].fr_CEPS);
                  dpsData.push(value.financialData[0].fr_DPS);

                  var financialQuater = value._id.toString();
                  var year = financialQuater.substr(0, 4);
                  var quater = financialQuater.substr(4, 2);
                  if (self.companyFinancialYearFromMonthGlobel == 1) {
                    switch (quater) {
                      case '03':
                        quater = 'Q1';
                        break;
                      case '06':
                        quater = 'Q2';
                        break;
                      case '09':
                        quater = 'Q3';
                        break;
                      case '12':
                        quater = 'Q4';
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('December ' + year);
                    value.FinancialQuater = 'December ' + year;
                  } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                    switch (quater) {
                      case '09':
                        quater = 'Q1';
                        break;
                      case '12':
                        quater = 'Q2';
                        break;
                      case '03':
                        quater = 'Q3';
                        //   year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q4';
                        //    year = (year - 1).toString();
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('June ' + year);
                    value.FinancialQuater = 'June ' + year;
                  } else {
                    switch (quater) {
                      case '03':
                        quater = 'Q4';
                        // year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q1';
                        break;
                      case '09':
                        quater = 'Q2';
                        break;
                      case '12':
                        quater = 'Q3';
                        break;
                      default:
                        break;
                    }
                    yearLabel.push('March ' + year);
                    value.FinancialQuater = 'March ' + year;
                  }

                  // yearLabel.push("March " + year);
                  // yearLabel.push(value._id);
                });
                //
                this.earningsRatiosYearlyChart = {
                  chart: {
                    type: 'spline',
                    zoomType: 'xy',
                    animation: {
                      enabled: false,
                    },
                    renderTo: 'earningsRatiosYearlyDataSection',
                    // width: 700
                  },
                  title: {
                    text: '',
                  },
                  credits: {
                    enabled: false,
                  },
                  yAxis: {
                    title: {
                      text: false,
                    },
                  },
                  xAxis: {
                    categories: yearLabel,
                    tickInterval: 1,
                  },
                  plotOptions: {
                    series: {
                      color: '#00ff00',
                    },
                  },
                  series: [
                    {
                      name: 'BASIC_EPS',
                      data: epsData,
                      color: '#00ff00',
                    },
                    {
                      name: 'CEPS',
                      data: cepsData,
                      color: '#37a7a5',
                    },
                    {
                      name: 'DPS',
                      data: dpsData,
                      color: '#2c1eae',
                    },
                  ],
                };
              }
            } else {
            }
            $('.widget_kr').removeClass('active');
            $('#earningsRatiosAnnualTrend').addClass('active');
            $('#earningsRatiosQuartelyDataSection').css('display', 'none');
            $('#earningsRatiosYearlyDataSection').css('display', 'block');
          });
      } catch (error) { }
    }
  }

  getQuarterlyTrendMarginRatios() {
    if (this.isKeyRatios != 'false') {
      try {
        if (this.isCompanyListed != 'false') {
          this.financialDataService
            .getLastFiveQuatersKeyRatios()
            .then((responseData) => {
              //
              var self = this;
              if (responseData.status == 0) {
                var count = 0;
                if (
                  responseData.keyRatios != null &&
                  _.size(responseData.keyRatios) > 0
                ) {
                  //
                  this.quarterlyKeyRatiosData = _.filter(
                    responseData.keyRatios
                  );
                  this.quarterlyKeyRatiosData.forEach((element) => {
                    _.remove(element.financialData, function (a: any) {
                      return a.Type == 'C';
                    });
                  });
                  var quaterLabel = [];
                  var ebidtaData = [];
                  var pbitData = [];
                  var patData = [];
                  _.forEach(this.quarterlyKeyRatiosData, function (value) {
                    //

                    // ebidtaData.push(value.financialData[0].qr_OPERATING_PROFIT_MARGIN);
                    // patData.push(value.financialData[0].qr_NET_PROFIT_MARGIN);
                    ebidtaData.push(value.financialData[0].qr_PBIDTMEXOI);
                    patData.push(value.financialData[0].qr_PATM);

                    var financialQuater = value._id.toString();
                    var year = financialQuater.substr(0, 4);
                    var quater = financialQuater.substr(4, 2);
                    if (self.companyFinancialYearFromMonthGlobel == 1) {
                      switch (quater) {
                        case '03':
                          quater = 'Q1';
                          break;
                        case '06':
                          quater = 'Q2';
                          break;
                        case '09':
                          quater = 'Q3';
                          break;
                        case '12':
                          quater = 'Q4';
                          break;
                        default:
                          break;
                      }
                    } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                      switch (quater) {
                        case '09':
                          quater = 'Q1';
                          break;
                        case '12':
                          quater = 'Q2';
                          break;
                        case '03':
                          quater = 'Q3';
                          //   year = (year - 1).toString();
                          break;
                        case '06':
                          quater = 'Q4';
                          //    year = (year - 1).toString();
                          break;
                        default:
                          break;
                      }
                    } else {
                      switch (quater) {
                        case '03':
                          quater = 'Q4';
                          // year = (year - 1).toString();
                          break;
                        case '06':
                          quater = 'Q1';
                          break;
                        case '09':
                          quater = 'Q2';
                          break;
                        case '12':
                          quater = 'Q3';
                          break;
                        default:
                          break;
                      }
                    }

                    quaterLabel.push(quater + ' ' + year);
                    // quaterLabel.push(value._id);
                  });
                  //
                  this.marginRatiosQuarterlyChart = {
                    chart: {
                      type: 'spline',
                      zoomType: 'xy',
                      animation: {
                        enabled: false,
                      },
                      renderTo: 'marginRatiosQuartelyDataSection',
                    },
                    title: {
                      text: '',
                    },
                    credits: {
                      enabled: false,
                    },
                    yAxis: {
                      title: {
                        // text: 'Margin %'
                        text: false,
                      },
                    },

                    xAxis: {
                      categories: quaterLabel,
                      tickInterval: 1,
                    },
                    plotOptions: {
                      series: {
                        color: '#00ff00',
                      },
                    },

                    series: [
                      {
                        name: 'EBIDTA',
                        data: ebidtaData,
                        color: '#00ff00',
                      },
                      {
                        name: 'PBIT',
                        data: pbitData,
                        color: '#37a7a5',
                      },
                      {
                        name: 'PAT',
                        data: patData,
                        color: '#2c1eae',
                      },
                    ],
                  };
                }
              } else {
              }
              $('.widget_kr').removeClass('active');
              $('#marginRatiosQuartelyTrend').addClass('active');
              $('#marginRatiosQuartelyDataSection').css('display', 'block');
              $('#marginRatiosYearlyDataSection').css('display', 'none');
            });
        }
      } catch (error) { }
    }
  }

  getAnnualTrendMarginRatios() {
    if (this.isKeyRatios != 'false') {
      try {
        this.financialDataService
          .getLastFiveYearsKeyRatios()
          .then((responseData) => {
            //
            var self = this;
            if (responseData.status == 0) {
              var count = 0;
              if (
                responseData.keyRatios != null &&
                _.size(responseData.keyRatios) > 0
              ) {
                //
                this.yearlyKeyRatiosData = _.filter(responseData.keyRatios);
                this.yearlyKeyRatiosData.forEach((element) => {
                  _.remove(element.financialData, function (a: any) {
                    return a.Type == 'C';
                  });
                });
                var yearLabel = [];
                var ebidtaData = [];
                var pbitData = [];
                var patData = [];
                _.forEach(this.yearlyKeyRatiosData, function (value) {
                  //
                  ebidtaData.push(value.financialData[0].fr_Core_EBITDA_Margin);
                  pbitData.push(value.financialData[0].fr_EBIT_Margin);
                  patData.push(value.financialData[0].fr_PAT_Margin);

                  var financialQuater = value._id.toString();
                  var year = financialQuater.substr(0, 4);
                  var quater = financialQuater.substr(4, 2);

                  if (self.companyFinancialYearFromMonthGlobel == 1) {
                    switch (quater) {
                      case '03':
                        quater = 'Q1';
                        break;
                      case '06':
                        quater = 'Q2';
                        break;
                      case '09':
                        quater = 'Q3';
                        break;
                      case '12':
                        quater = 'Q4';
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('December ' + year);
                    value.FinancialQuater = 'December ' + year;
                  } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                    switch (quater) {
                      case '09':
                        quater = 'Q1';
                        break;
                      case '12':
                        quater = 'Q2';
                        break;
                      case '03':
                        quater = 'Q3';
                        //   year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q4';
                        //    year = (year - 1).toString();
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('June ' + year);
                    value.FinancialQuater = 'June ' + year;
                  } else {
                    switch (quater) {
                      case '03':
                        quater = 'Q4';
                        // year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q1';
                        break;
                      case '09':
                        quater = 'Q2';
                        break;
                      case '12':
                        quater = 'Q3';
                        break;
                      default:
                        break;
                    }
                    yearLabel.push('March ' + year);
                    value.FinancialQuater = 'March ' + year;
                  }

                  // yearLabel.push("March " + year);
                  // yearLabel.push(value._id);
                });
                //
                this.marginRatiosYearlyChart = {
                  chart: {
                    type: 'spline',
                    zoomType: 'xy',
                    animation: {
                      enabled: false,
                    },
                    renderTo: 'marginRatiosYearlyDataSection',
                  },
                  title: {
                    text: '',
                  },
                  credits: {
                    enabled: false,
                  },
                  yAxis: {
                    title: {
                      // text: 'Margin %'
                      text: false,
                    },
                  },

                  xAxis: {
                    categories: yearLabel,
                    tickInterval: 1,
                  },
                  plotOptions: {
                    series: {
                      color: '#00ff00',
                    },
                  },

                  series: [
                    {
                      name: 'EBIDTA',
                      data: ebidtaData,
                      color: '#00ff00',
                    },
                    {
                      name: 'PBIT',
                      data: pbitData,
                      color: '#37a7a5',
                    },
                    {
                      name: 'PAT',
                      data: patData,
                      color: '#2c1eae',
                    },
                  ],
                };
              }
            } else {
            }
            $('.widget_kr').removeClass('active');
            $('#marginRatiosAnnualTrend').addClass('active');
            $('#marginRatiosQuartelyDataSection').css('display', 'none');
            $('#marginRatiosYearlyDataSection').css('display', 'block');
          });
      } catch (error) { }
    }
  }

  getAnnualTrendGrowthRatios() {
    if (this.isKeyRatios != 'false') {
      try {
        this.financialDataService
          .getLastFiveYearsKeyRatios()
          .then((responseData) => {
            //
            var self = this;
            if (responseData.status == 0) {
              var count = 0;
              if (
                responseData.keyRatios != null &&
                _.size(responseData.keyRatios) > 0
              ) {
                //
                this.yearlyKeyRatiosData = _.filter(responseData.keyRatios);
                this.yearlyKeyRatiosData.forEach((element) => {
                  _.remove(element.financialData, function (a: any) {
                    return a.Type == 'C';
                  });
                });
                var yearLabel = [];
                var netSalesGrowthData = [];
                var patGrowthData = [];
                _.forEach(this.yearlyKeyRatiosData, function (value) {
                  //
                  netSalesGrowthData.push(
                    value.financialData[0].fr_Net_Sales_Growth
                  );
                  patGrowthData.push(value.financialData[0].fr_PAT_Growth);

                  var financialQuater = value._id.toString();
                  var year = financialQuater.substr(0, 4);
                  var quater = financialQuater.substr(4, 2);

                  if (self.companyFinancialYearFromMonthGlobel == 1) {
                    switch (quater) {
                      case '03':
                        quater = 'Q1';
                        break;
                      case '06':
                        quater = 'Q2';
                        break;
                      case '09':
                        quater = 'Q3';
                        break;
                      case '12':
                        quater = 'Q4';
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('December ' + year);
                    value.FinancialQuater = 'December ' + year;
                  } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                    switch (quater) {
                      case '09':
                        quater = 'Q1';
                        break;
                      case '12':
                        quater = 'Q2';
                        break;
                      case '03':
                        quater = 'Q3';
                        //   year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q4';
                        //    year = (year - 1).toString();
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('June ' + year);
                    value.FinancialQuater = 'June ' + year;
                  } else {
                    switch (quater) {
                      case '03':
                        quater = 'Q4';
                        // year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q1';
                        break;
                      case '09':
                        quater = 'Q2';
                        break;
                      case '12':
                        quater = 'Q3';
                        break;
                      default:
                        break;
                    }
                    yearLabel.push('March ' + year);
                    value.FinancialQuater = 'March ' + year;
                  }

                  // yearLabel.push("March " + year);
                  // yearLabel.push(value._id);
                });
                //
                this.growthRatiosYearlyChart = {
                  chart: {
                    type: 'spline',
                    zoomType: 'xy',
                    animation: {
                      enabled: false,
                    },
                    renderTo: 'growthRatiosYearlyDataSection',
                  },
                  title: {
                    text: '',
                  },
                  credits: {
                    enabled: false,
                  },
                  yAxis: {
                    title: {
                      // text: '%'
                      text: false,
                    },
                  },

                  xAxis: {
                    categories: yearLabel,
                    tickInterval: 1,
                  },
                  plotOptions: {
                    series: {
                      color: '#00ff00',
                    },
                  },

                  series: [
                    {
                      name: 'Net Sales Growth',
                      data: netSalesGrowthData,
                      color: '#00ff00',
                    },
                    {
                      name: 'PAT Growth',
                      data: patGrowthData,
                      color: '#37a7a5',
                    },
                  ],
                };
              }
            } else {
            }
            $('.widget_kr').removeClass('active');
            $('#growthRatiosAnnualTrend').addClass('active');
            $('#growthRatiosQuartelyDataSection').css('display', 'none');
            $('#growthRatiosYearlyDataSection').css('display', 'block');
          });
      } catch (error) { }
    }
  }

  getAnnualTrendReturnsRatios() {
    if (this.isKeyRatios != 'false') {
      try {
        this.financialDataService
          .getLastFiveYearsKeyRatios()
          .then((responseData) => {
            //
            var self = this;
            if (responseData.status == 0) {
              var count = 0;
              if (
                responseData.keyRatios != null &&
                _.size(responseData.keyRatios) > 0
              ) {
                //
                this.yearlyKeyRatiosData = _.filter(responseData.keyRatios);
                this.yearlyKeyRatiosData.forEach((element) => {
                  _.remove(element.financialData, function (a: any) {
                    return a.Type == 'C';
                  });
                });
                var yearLabel = [];
                var roaData = [];
                var roeData = [];
                var roceData = [];
                var roicData = [];
                _.forEach(this.yearlyKeyRatiosData, function (value) {
                  //
                  roaData.push(value.financialData[0].fr_ROA);
                  roeData.push(value.financialData[0].fr_ROE);
                  roceData.push(value.financialData[0].fr_ROCE);
                  // roicData.push(value.financialData[0].fr_ROIC);

                  var financialQuater = value._id.toString();
                  var year = financialQuater.substr(0, 4);
                  var quater = financialQuater.substr(4, 2);

                  if (self.companyFinancialYearFromMonthGlobel == 1) {
                    switch (quater) {
                      case '03':
                        quater = 'Q1';
                        break;
                      case '06':
                        quater = 'Q2';
                        break;
                      case '09':
                        quater = 'Q3';
                        break;
                      case '12':
                        quater = 'Q4';
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('December ' + year);
                    value.FinancialQuater = 'December ' + year;
                  } else if (self.companyFinancialYearFromMonthGlobel == 7) {
                    switch (quater) {
                      case '09':
                        quater = 'Q1';
                        break;
                      case '12':
                        quater = 'Q2';
                        break;
                      case '03':
                        quater = 'Q3';
                        //   year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q4';
                        //    year = (year - 1).toString();
                        break;
                      default:
                        break;
                    }

                    yearLabel.push('June ' + year);
                    value.FinancialQuater = 'June ' + year;
                  } else {
                    switch (quater) {
                      case '03':
                        quater = 'Q4';
                        // year = (year - 1).toString();
                        break;
                      case '06':
                        quater = 'Q1';
                        break;
                      case '09':
                        quater = 'Q2';
                        break;
                      case '12':
                        quater = 'Q3';
                        break;
                      default:
                        break;
                    }
                    yearLabel.push('March ' + year);
                    value.FinancialQuater = 'March ' + year;
                  }

                  // yearLabel.push("March " + year);
                  // yearLabel.push(value._id);
                });
                //
                this.returnsRatiosYearlyChart = {
                  chart: {
                    type: 'spline',
                    zoomType: 'xy',
                    animation: {
                      enabled: false,
                    },
                    renderTo: 'returnsRatiosYearlyDataSection',
                  },
                  title: {
                    text: '',
                  },
                  credits: {
                    enabled: false,
                  },
                  yAxis: {
                    title: {
                      // text: '%'
                      text: false,
                    },
                  },
                  xAxis: {
                    categories: yearLabel,
                    tickInterval: 1,
                  },
                  plotOptions: {
                    series: {
                      color: '#00ff00',
                    },
                  },
                  series: [
                    {
                      name: 'ROA',
                      data: roaData,
                      color: '#00ff00',
                    },
                    {
                      name: 'ROE',
                      data: roeData,
                      color: '#37a7a5',
                    },
                    {
                      name: 'ROCE',
                      data: roceData,
                      color: '#2c1eae',
                    },
                    // , {
                    //     name: 'ROIC',
                    //     data: roicData
                    // }
                  ],
                };
              }
            } else {
            }
            $('.widget_kr').removeClass('active');
            $('#returnsRatiosAnnualTrend').addClass('active');
            // $('#growthRatiosQuartelyDataSection').css('display', 'none');
            $('#returnsRatiosYearlyDataSection').css('display', 'block');
          });
      } catch (error) { }
    }
  }

  tagToogle(tabName) {
    if (this.isCompanyListed != 'false' && this.isKeyRatios != 'false') {
      if (tabName == 'earningsRatiosTab') {
        this.getQuarterlyTrendEarningsRatios();
      } else if (tabName == 'marginRatiosTab') {
        this.getQuarterlyTrendMarginRatios();
      } else if (tabName == 'growthRatiosTab') {
        // this.getQuarterlyTrendGrowthRatios();
        this.getAnnualTrendGrowthRatios();
      } else if (tabName == 'returnsRatiosTab') {
        this.getAnnualTrendReturnsRatios();
      }
    }
  }
}
