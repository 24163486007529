import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-meetingdirector',
  templateUrl: './meetingdirector.component.html',
  styleUrls: ['./meetingdirector.component.css']
})
export class MeetingdirectorComponent implements OnInit {

  @Input('directorObject') directorObject: any;
  @Input('meetingDate') meetingDate : any;
  isDirectorObjectEmpty : boolean
  constructor() { }

  ngOnInit() {

    //console.log("meetingDate",this.meetingDate);

    if(_.size(this.directorObject)){
      this.isDirectorObjectEmpty = false;

      var activeDirectors = [];
      this.meetingDate = moment(this.meetingDate).format('YYYY-MM-DD');

      this.directorObject.forEach(element => {
        var CessationDate = element.CessationDate;
        var AppointmentDate = element.AppointmentDate;

        if (element.AppointmentDate == "") {
          activeDirectors.push(element);
        }
        else if ((CessationDate == null || moment(this.meetingDate).diff(moment(CessationDate).format('YYYY-MM-DD'), "days") <= 0)
          && moment(this.meetingDate).diff(moment(AppointmentDate).format('YYYY-MM-DD'), "days") >= 0) {
          activeDirectors.push(element);
        }
      })

      this.directorObject = activeDirectors ;

      this.directorObject.forEach(directorElement => {
        if(directorElement.IsExecutive == "true" || directorElement.IsExecutive == true){
          directorElement.IsExecutive = "Executive";
        }else{
          directorElement.IsExecutive = "Non-Executive";
        }
      });
    }else{
      this.isDirectorObjectEmpty = true;
    }
  }

}
