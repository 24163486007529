import { Component, OnInit, Input} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-meetingcategory',
  templateUrl: './meetingcategory.component.html',
  styleUrls: ['./meetingcategory.component.css']
})
export class MeetingcategoryComponent implements OnInit {

  @Input('categoryObject') categoryObject : any;
  isCategoryObjectEmpty : boolean;
  constructor() { }

  ngOnInit() {
    var self = this;

    if(_.size(this.categoryObject)){
      this.isCategoryObjectEmpty = false;
    }
    else{
      this.isCategoryObjectEmpty = true;
    }

  }

}
