<section class="card">

  <div class="card-header">
    <span class="cat__core__title">
      <strong>Role Master</strong>
    </span>

  </div>

  <div class="card-block watermark">
    <div class="row">
      <div class="col-lg-12">
        <button type="button" class="btn btn-primary btn-sm mb-3" data-toggle="modal" data-target="#myModal" (click)="getRoleActivityCheckboxList()"
          [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/addRole', 'method':'post', 'menu': 'RoleMaster' }">
          Add Role
        </button>

        <!-- table Layout -->
        <div class="table-responsive table-bordered margin-bottom-50" [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/getUserRoles', 'method':'get', 'menu': 'RoleMaster' }">
          <table datatable [dtOptions]="dtOptions" id="roleMasterTable" [dtTrigger]="dtTrigger" class="table table-hover compact cell-border nowrap table-striped dataTable"
            style="background:transparent" width="100%">
            <thead>
              <tr>
                <th style="width:5%">Sr. No.</th>
                <th style="width:75%">Name</th>
                <th style="width:5%" [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/editRole', 'method':'put', 'menu': 'RoleMaster' }">Edit</th>
                <th style="width:5%" [hidden]="isGuestSuperAdmin"  [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/deleteRole', 'method':'delete', 'menu': 'RoleMaster' }" >Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let roleNames of listOfRoleNames;let count=index">
                <td style="text-align:center;width:5%">{{count + 1}}</td>
                <td style="width: 75%">{{roleNames.RoleName.trim()}}</td>
                  <td style="width: 5%;text-align: center;color: #74708d;cursor: pointer;" 
                  (click)="editRoleNameObjectToPassIntoModal(roleNames)" [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/editRole', 'method':'put', 'menu': 'RoleMaster' }">
                  <div class="fa fa-pencil"></div>
                </td>
                <td style="width: 5%;text-align: center;color: #74708d;cursor: pointer;" [hidden]="isGuestSuperAdmin" data-toggle="modal" data-target="#roleDeletionMessageModal"
                  (click)="deleteRoleNameObjectToPassIntoModal(roleNames)" [appHasPermission]="{ 'Url': '/api/v1/secure/roleMaster/deleteRole', 'method':'delete', 'menu': 'RoleMaster' }">
                  <div class="fa fa-trash-o"></div>
                </td>                
              </tr>
            </tbody>
          </table>
        </div>


        <!-- Modal for adding new Role-->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header" style="justify-content:unset!important">
                <h4 class="modal-title" id="myModalLabel"> Add Role</h4>
                <button type="button" class="close " style="right:5px;position:absolute" data-dismiss="modal" (click)="closeForm()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body" style="height: 500px;overflow-y: auto;">
                <form [formGroup]="roleForm" novalidate>
                  <div class="form-group d-inline-flex col-lg-12 p-0" style="height:30px;margin-bottom:0">
                    <label class="label col-lg-2 p-0 h5 text-left">
                      <span style="vertical-align: -webkit-baseline-middle;">Role Name
                        <span class="text-danger">*</span>
                      </span>
                    </label>
                    <input class="col-lg-10 form-control" type="text" formControlName="roleName" (paste)="inputRoleNameTextboxChangedDueToPaste($event)"
                      (change)="inputRoleNameTextboxChanged($event)" />
                  </div>
                  <!-- Validation message for required rolename -->
                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="!validationInputRoleName.errors?.pattern && roleForm.controls['roleName'].touched && !roleForm.controls['roleName'].valid &&  !validationInputRoleName.errors?.maxlength">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Please enter Role Name</div>
                  </div>

                  <!-- Validation message for exceeding max characters for rolename -->
                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="validationInputRoleName.errors?.maxlength ">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Please enter Rolename not exceeding 50 characters</div>
                  </div>

                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="validationInputRoleName.errors?.pattern">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Special Characters and Numbers are not allowed</div>
                  </div>

                  <div style="margin-top:2%">
                    <strong>List Of Operations
                      <span class="text-danger">*</span>
                    </strong>
                  </div>
                  <div *ngIf="roleForm.controls['roleName'].valid && !checkboxValidationFlag">
                    <span style="color:red">Please select at least one operation</span>
                  </div>
                  <hr style="width:100%;margin:0">
                  <div formArrayName="arrayOfParentActivities" class="checkbox-text" >
                    <div class="form-group col-sm-4 pull-left" *ngFor="let parent of parentActivityObject;let i=index">
                      <div [formGroupName]="i">
                        <input type="checkbox" style="margin:0" id="{{ 'parentCheckbox' + i}}" (click)="parentActivityCheckboxClicked($event,i)"
                          [checked]="isParentActivityChecked[i]">
                        <span class="align-text-bottom pl-1">
                          <b>{{parent.MenuDisplayName}}</b>                          
                        </span>
                        <label class="pl-4" *ngFor="let child of parent.ChildResources" style="cursor:pointer">
                          <input type="checkbox" style="margin:0" [formControlName]="child.Url" (change)="childCheckboxClicked($event,i)">
                          <span class="align-text-bottom pl-1">{{child.DisplayName}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="modal-footer">
                <div style="width:100%; text-align: left;">
                  <span style="color: #ff0000">*</span> Indicates Mandatory field
                </div>
                <button type="button" class="btn btn-primary btn-sm" [disabled]="!checkboxValidationFlag || !roleForm.valid" (click)="submitAddRole()">Save </button>
                <button type="button" class="btn btn-default btn-sm" style="background-color:#ffffff;color:#acb7bf!important" data-dismiss="modal"
                  (click)="closeForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal for Editing Role -->
        <div class="modal fade" id="editingRoleModal" tabindex="-1" role="dialog" aria-labelledby="editingRoleModal">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header" style="justify-content:unset!important">
                <h4 class="modal-title" id="editingRoleModal">Edit Role</h4>
                <button type="button" class="close" style="right:5px;position:absolute" data-dismiss="modal" (click)="editModalClose()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="height: 500px;overflow-y: auto;">
                <form [formGroup]="roleForm" novalidate>
                  <div class="form-group d-inline-flex col-lg-12 p-0" style="height:30px;margin-bottom:0">
                    <label class="label col-lg-2 p-0 h5 text-left">
                      <span style="vertical-align: -webkit-baseline-middle;">Role Name
                        <span class="text-danger">*</span>
                      </span>
                    </label>
                    <input class="col-lg-10 form-control" type="text" formControlName="roleName" (change)="inputRoleNameTextboxChanged($event)"
                      (paste)="inputRoleNameTextboxChangedDueToPaste($event)" disabled />
                  </div>

                  <!-- Validation message for required rolename -->
                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="!validationInputRoleName.errors?.pattern && roleForm.controls['roleName'].touched && !roleForm.controls['roleName'].valid &&  !validationInputRoleName.errors?.maxlength">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Please enter Role Name</div>
                  </div>

                  <!-- Validation message for exceeding max characters for rolename -->
                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="validationInputRoleName.errors?.maxlength ">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Please enter Rolename not exceeding 50 characters</div>
                  </div>

                  <div class="d-inline-flex col-lg-12 p-0" *ngIf="validationInputRoleName.errors?.pattern">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10" style="color:red;padding:0">Special Characters and Numbers are not allowed</div>
                  </div>


                  <div style="margin-top:2%">
                    <strong>List Of Operations
                      <span class="text-danger">*</span>
                    </strong>
                  </div>
                  <div *ngIf="roleForm.controls['roleName'].valid && !checkboxValidationFlag">
                    <span style="color:red">Please select at least one operation</span>
                  </div>
                  <hr style="width:100%;margin:0">
                  <div formArrayName="arrayOfParentActivities" class="checkbox-text" >
                    <div class="form-group col-sm-4 pull-left" *ngFor="let parent of parentActivityObject;let i=index">
                      <div [formGroupName]="i">
                        <input type="checkbox" style="margin:0" id="{{ 'parentCheckboxEditModal' + i}}" (click)="parentActivityCheckboxClicked($event,i)"
                          [checked]="isParentActivityChecked[i]">
                        <span class="align-text-bottom pl-1">
                          <b>{{parent.MenuDisplayName}}</b> 
                        </span>
                        <label class="pl-4" *ngFor="let child of parent.ChildResources" style="cursor:pointer">
                          <input type="checkbox" style="margin:0" [formControlName]="child.Url" (change)="childCheckboxEditModalClicked($event,i)">
                          <span class="align-text-bottom pl-1">{{child.DisplayName}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div style="width:100%; text-align: left;">
                  <span style="color: #ff0000">*</span> Indicates Mandatory field
                </div>
                <button type="button" class="btn btn-primary btn-sm" [disabled]="!checkboxValidationFlag || !roleForm.valid" (click)="submitUpdateRole()">
                  Update </button>
                <button type="button" class="btn btn-default btn-sm" style="background-color:#ffffff;color:#acb7bf!important" data-dismiss="modal"
                  (click)="editModalClose()"> Cancel </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal of Role deletion confirmation message  -->
        <div class="modal fade" id="roleDeletionMessageModal" role="dialog" aria-labelledby="roleDeletionMessageModalLabel">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="roleDeletionMessageModalLabel">
                  <i class="fa fa-question-circle-o mr-2"></i>Confirm</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this role?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" (click)="deleteRole()">Confirm </button>
                <button type="button" class="btn btn-default btn-sm" data-dismiss="modal" (click)="closeForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <!-- modal for showing error messages -->
        <div class="modal fade" id="errorMessageModal" style="top: 50px; display: none;" tabindex="-1" role="dialog" aria-labelledby="errorMessageModal">
          <div class="modal-dialog" style="height: 70%;" role="document">
            <div class="modal-content">
              <div class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                <span *ngIf="headerSuccessFlag" style="color: rgb(0, 0, 0);">
                  <h4>
                    <b>{{headerSuccessMessage}}</b>
                  </h4>
                </span>
                <span *ngIf="headerErrorFlag" style=" color: rgb(255, 0, 0);">
                  <h4>
                    <b>{{headerErrorMessage}}</b>
                  </h4>
                </span>
              </div>
              <div class="modal-body" style="padding: 10px 15px;">
                <span *ngIf="headerSuccessFlag" style="font-size: 16px;color: rgb(0, 0, 0);">{{errorMessage}}</span>
                <span *ngIf="headerErrorFlag" style="font-size: 16px; color: rgb(255, 0, 0);">{{errorMessage}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>