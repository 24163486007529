<!-- START: components/media-players -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>
                Video Player
                <sup>11$ FREE</sup>
            </strong>
            <a href="http://cleanhtmlplayer.com/player/video/jquery/documentation/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
        <p class="mb-0">
            Player license valid only with Clean UI template use under Envato Regular License. In any other case you need to buy personal license
        </p>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Video Player w/ Playlist Light Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/video/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanvideoplayer">
                        <ul data-theme="default">
                            <li data-title="Blond Woman In White Bikini Sitting On Grass" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/1.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/1.jpg" data-free="true"></li>
                            <li data-title="Woman In Bikini Sitting Cross Legged Pool Side" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/2.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/2.jpg" data-free="true"></li>
                            <li data-title="Blond Woman In White Bikini Sitting On Grass" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/1.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/1.jpg" data-free="true"></li>
                            <li data-title="Woman In Bikini Sitting Cross Legged Pool Side" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/2.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/2.jpg" data-free="true"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Video Player w/ Playlist Dark Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/video/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanvideoplayer">
                        <ul data-theme="dark">
                            <li data-title="Woman In Bikini Sitting Cross Legged Pool Side" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/2.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/2.jpg" data-free="true"></li>
                            <li data-title="Blond Woman In White Bikini Sitting On Grass" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/1.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/1.jpg" data-free="true"></li>
                            <li data-title="Woman In Bikini Sitting Cross Legged Pool Side" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/2.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/2.jpg" data-free="true"></li>
                            <li data-title="Blond Woman In White Bikini Sitting On Grass" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/1.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/1.jpg" data-free="true"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Single Video Player Light Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/video/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanvideoplayer">
                        <ul data-theme="default">
                            <li data-title="Blond Woman In White Bikini Sitting On Grass" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/1.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/1.jpg" data-free="true"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Single Video Player w/ Playlist Dark Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/video/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanvideoplayer">
                        <ul data-theme="dark">
                            <li data-title="Woman In Bikini Sitting Cross Legged Pool Side" data-type="m4v" data-url="http://cleanhtmlplayer.com/cdn/video/media/2.mp4" data-poster="http://cleanhtmlplayer.com/cdn/video/media/2.jpg" data-free="true"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>
                Audio Player
                <sup>11$ FREE</sup>
            </strong>
            <a href="http://cleanhtmlplayer.com/player/audio/jquery/documentation/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
        <p class="mb-0">
            Player license valid only with Clean UI template use under Envato Regular License. In any other case you need to buy personal license
        </p>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Audio Player w/ Playlist Light Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/audio/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanaudioplayer">
                        <ul data-theme="default">
                            <li data-title="Shoutcast: 1.FM - 80s EURO" data-type="mp3" data-url="http://sc2b-sjc.1.fm:8097/;"></li>
                            <li data-title="Swinskog — Roads! (Original Mix)" data-artist="Swinskog" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/Swinskog - Roads! (Original Mix).mp3" data-free="true"></li>
                            <li data-title="DJ Kama — Sueno Latino (Original Mix)" data-artist="DJ Kama" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/DJ Kama - Sueno Latino (Original Mix).mp3" data-free="true"></li>
                            <li data-title="Erkka — Talk To The Hand" data-artist="Erkka" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/Erkka - Talk To The Hand.mp3"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Audio Player w/ Playlist Dark Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/audio/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanaudioplayer">
                        <ul data-theme="dark">
                            <li data-title="Shoutcast: 1.FM - 80s EURO" data-type="mp3" data-url="http://sc2b-sjc.1.fm:8097/;"></li>
                            <li data-title="Swinskog — Roads! (Original Mix)" data-artist="Swinskog" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/Swinskog - Roads! (Original Mix).mp3" data-free="true"></li>
                            <li data-title="DJ Kama — Sueno Latino (Original Mix)" data-artist="DJ Kama" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/DJ Kama - Sueno Latino (Original Mix).mp3" data-free="true"></li>
                            <li data-title="Erkka — Talk To The Hand" data-artist="Erkka" data-type="mp3" data-url="http://cleanhtmlplayer.com/cdn/audio/media/Erkka - Talk To The Hand.mp3"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Single Audio Player Light Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/audio/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanaudioplayer">
                        <ul data-theme="default">
                            <li data-title="Shoutcast: 1.FM - 80s EURO" data-type="mp3" data-url="http://sc2b-sjc.1.fm:8097/;"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Single Audio Player Dark Theme</strong></h5>
                <p class="text-muted">Element: read <a href="http://cleanhtmlplayer.com/player/audio/jquery/documentation/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="mediatec-cleanaudioplayer">
                        <ul data-theme="dark">
                            <li data-title="Shoutcast: 1.FM - 80s EURO" data-type="mp3" data-url="http://sc2b-sjc.1.fm:8097/;"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- START: components/media-players -->
