import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-meetingmember',
  templateUrl: './meetingmember.component.html',
  styleUrls: ['./meetingmember.component.css']
})
export class MeetingmemberComponent implements OnInit {

  @Input('memberObject') memberObject: any;
  isMemberObjectEmpty:boolean;
  
  constructor() { }

  ngOnInit() {
    var self= this;

    if(_.size(this.memberObject)){
      self.isMemberObjectEmpty = false;
    }else{
      self.isMemberObjectEmpty = true;
    }

  }

}
