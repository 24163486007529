<div class="modal-header">
  <h5 class="modal-title" id="title" style="text-align:justify;font-size:15px;font-weight: bolder;">
    My Tasks</h5>
  <button aria-label="Close" class="close" type="button" (click)="activeModal.dismiss()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="nav-tabs-horizontal">
    <ul class="nav nav-tabs tab-custom-one" role="tablist" style="background: #07a7a5;">
      <li class="nav-item" style="width:50%;text-align:center">
        <a class="nav-link active" id="govTab" style="background: #07a7a5; color: #ffffff; padding:6px 10px;"
          data-target="#governanceTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="true"
          (click)="showGovernanceTasks()">Governance

        </a>
      </li>
      <li class="nav-item" style="width:50%;text-align:center">
        <a class="nav-link" id="compTab" style="background: #07a7a5; color: #ffffff; padding:6px 10px;"
          data-target="#complianceTab" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
          (click)="searchComplianceFilteredMyTask()">Compliance
        </a>
        <!-- showComplianceTasks -->
      </li>
    </ul>

    <div class="tab-content" style="min-height: 400px;">
      <div class="tab-pane active" id="governanceTab" role="tabcard" aria-expanded="true">

        <!-- search filter starts here -->
        <section class="card search-box mt-4">
          <div class="card-block container py-3">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <label>Company Name</label>
                  </div>
                  <div class="col-md-3">
                    <label>Task Status</label>
                  </div>
                  <div class="col-md-3">
                    <label>Process Name</label>
                  </div>
                  <div class="col-md-3">
                    <label>Search By Date</label>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedCompanyById"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="associatedCompanyList">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedtaskStatus"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="taskStatusList"></ng-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedProcessById"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="processByIdList">
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <!--Due Date Range filter From and To Date Functionality -->
                    <div class="input-group">
                      <div class="input-group" (click)="$event.stopPropagation()">

                        <input class="form-control" [(ngModel)]="selectedFromDate" style="padding:6px; z-index: 0;"
                          placeholder="dd-mm-yyyy" ngbDatepicker #fromDateGov="ngbDatepicker"
                          (document:click)="fromDateGov.close();toDateGov.close();"
                          (ngModelChange)="selectedFromDateChange();" [ngModelOptions]="{standalone: true}"
                          readonly="true">
                        <button class="input-group-addon" (click)="fromDateGov.toggle();toDateGov.close();"
                          type="button">
                          <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                        </button>
                        <input class="form-control" [(ngModel)]="selectedToDate" style="padding:6px; z-index: 0;"
                          placeholder="dd-mm-yyyy" ngbDatepicker #toDateGov="ngbDatepicker"
                          (click)="toDateGov.toggle();fromDateGov.close();"
                          (document:click)="toDateGov.close();fromDateGov.close()"
                          (ngModelChange)="selectedToDateChange();" [ngModelOptions]="{standalone: true}"
                          [minDate]="minDateForToDate" readonly="true">
                        <button class="input-group-addon" (click)="toDateGov.toggle();fromDateGov.close();"
                          type="button">
                          <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="float:right;">
                  <div style="padding-right: 10px !important;">
                    <div class="input-group">
                      <button type="button" class="btn btn-sm btn-primary" (click)="searchGovFilteredMyTask()"
                        title="search">
                        <i class="fa fa-search mr-1"></i>Search
                      </button>
                      <button type="button" class="btn btn-sm btn-default ml-1" (click)="clearGovFilterValues()"
                        title="refresh">
                        <i class="fa fa-refresh mr-1"></i>Clear
                      </button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <div class="row pt-2 pb-2 pl-3">
          <div class="col-md-3">
            <span>Change Status To</span>
          </div>
          <div class="col-md-5">
            <ng-select class="form-control gov ngSelectBox" [(ngModel)]="selectedTaskStatusToChange"
              [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="taskStatusList"
              (ngModelChange)="markTasks($event)"></ng-select>
          </div>
          <div class="col-md-4"></div>
        </div>
        <div class="table-responsive margin-bottom-50">
          <table datatable [dtOptions]="dtGovernanceOptions" id="myTasksTable" [dtTrigger]="dtGovernanceTrigger"
            class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable"
            style="background:transparent;">
            <thead>
              <tr>
                <th style="width: 5%">
                  <input type="checkbox" (click)="markAll($event)">
                </th>
                <th style="width: 5%">Sr. No.</th>
                <th style="width: 20%">Company Name</th>
                <th style="width: 35%">Tasks</th>
                <th style="width: 15%">Status</th>
                <th style="width: 10%">Start Date</th>
                <th style="width: 10%">End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let task of governanceTaskDetails;let count=index">
                <td style="white-space: normal; width: 5%">
                  <input type="checkbox" (click)="checkTask($event,task, count)" [ngModel]="task.isSelected">
                </td>
                <td style="text-align:center; white-space: normal; width: 5%">{{count + 1}}</td>
                <td placement="right" style="white-space: normal; width: 20%">{{task.CompanyName}}</td>
                <td placement="right" style="white-space: normal; width: 35%">{{task.TaskName}}</td>
                <td style="white-space: normal; width: 15%; color:#3b73bb;" *ngIf="task.Status=='Pending'">
                  {{task.Status}}</td>
                <td style="white-space: normal; width: 15%; color:#d5a325;" *ngIf="task.Status=='PartiallyCompleted'">
                  {{(task.Status == 'PartiallyCompleted') ? "Partially Completed" : "PartiallyCompleted" }}</td>
                <td style="white-space: normal; width: 15%; color:#32c359;"
                  *ngIf="task.Status=='Completed' || task.Status=='Done' || task.Status=='DelayedCompletion'">
                  {{(task.Status =='DelayedCompletion') ? " Delayed Completion" : task.Status}}</td>
                <td style="white-space: normal; width: 15%; color:#f26f6c;"
                  *ngIf="task.Status=='Cancelled' || task.Status=='NA'">{{(task.Status =='NA') ? "Not Applicable" :
                  task.Status}}</td>
                <td style="white-space: normal; width: 10%">
                  <span style="display: none">{{task.StartDate | date : "yMMdd"}}</span> {{task.StartDate | date :
                  "dd-MM-y"}}
                </td>
                <td style="white-space: normal; width: 10%">
                  <span style="display: none">{{task.EndDate | date : "yMMdd"}}</span> {{task.EndDate | date :
                  "dd-MM-y"}}
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

      <div id="complianceTab" role="tabcard" aria-expanded="true" class="tab-pane">

        <!-- search filter starts here -->
        <section class="card search-box mt-4">
          <div class="card-block container py-2">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <label>Company Name</label>
                  </div>
                  <div class="col-md-2">
                    <label>Task Status</label>
                  </div>
                  <div class="col-md-2">
                    <label>Process Name</label>
                  </div>
                  <div class="col-md-2">
                    <label>Meeting Type</label>
                  </div>
                  <div class="col-md-4">
                    <label>Search By Date</label>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedCompanyByIdComp"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="associatedCompanyListComp">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedtaskStatusComp"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="taskStatusList"></ng-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedProcessByIdComp"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="processByIdListComp">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <ng-select class="form-control ngSelectBox" [(ngModel)]="selectedMeetingTypeById"
                        [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="meetingTypeList">
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <!--Due Date Range filter From and To Date Functionality -->
                    <div class="input-group">
                      <div class="input-group" (click)="$event.stopPropagation()">

                        <input class="form-control" [(ngModel)]="selectedFromDateComp" style="padding:6px; z-index: 0;"
                          placeholder="dd-mm-yyyy" ngbDatepicker #fromDate="ngbDatepicker"
                          (focus)="fromDate.toggle();toDate.close();" (document:click)="fromDate.close();"
                          (ngModelChange)="selectedFromDateChangeForComp()" [ngModelOptions]="{standalone: true}"
                          readonly="true">
                        <button class="input-group-addon" (click)="fromDate.toggle();toDate.close();" type="button">
                          <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                        </button>
                        <input class="form-control" [(ngModel)]="selectedToDateComp" style="padding:6px; z-index: 0;"
                          placeholder="dd-mm-yyyy" ngbDatepicker #toDate="ngbDatepicker"
                          (focus)="toDate.toggle();fromDate.close();" (document:click)="toDate.close();"
                          (ngModelChange)="selectedToDateChange()" [ngModelOptions]="{standalone: true}"
                          [minDate]="minDateForToDateComp" readonly="true">
                        <button class="input-group-addon" (click)="toDate.toggle();fromDate.close();" type="button">
                          <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 container">
                <div class="row" style="float:right;">
                  <div class="col-md-12 p-0">
                    <div class="input-group">
                      <button type="button" class="btn btn-sm btn-primary" (click)="searchComplianceFilteredMyTask()"
                        title="search">
                        <i class="fa fa-search mr-1"></i>Search
                      </button>
                      <button type="button" class="btn btn-sm btn-default ml-1" (click)="clearComplianceFilterValues()"
                        title="refresh">
                        <i class="fa fa-refresh mr-1"></i>Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </section>
        <div class="row pt-2 pb-2 pl-3">
          <div class="col-md-3">
            <span>Change Status To</span>
          </div>
          <div class="col-md-5">
            <ng-select class="form-control gov ngSelectBox" [(ngModel)]="selectedTaskStatusToChange"
              [ngModelOptions]="{standalone: true}" [multiple]="false" [items]="taskStatusList"
              (ngModelChange)="markComplianceTasks($event)"></ng-select>
          </div>
          <div class="col-md-4"></div>
        </div>

        <div class="row pt-2 pb-2 pl-3" *ngIf="showCompletionCalendar">
          <div class="col-md-3">
            <span>Completion Date</span>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <div class="input-group" (click)="$event.stopPropagation()">
                <input class="form-control" [(ngModel)]="completionDate" style="padding:6px; z-index: 0;"
                  placeholder="dd-mm-yyyy" [maxDate]="today()" ngbDatepicker #cmptlnDate="ngbDatepicker"
                  (focus)="cmptlnDate.toggle();" (document:click)="cmptlnDate.close();"
                  [ngModelOptions]="{standalone: true}" readonly="true">
                <button class="input-group-addon" (click)="cmptlnDate.toggle();" type="button">
                  <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                </button>
              </div>
            </div>
            <div *ngIf="showCompletionCalendar && !completionDate" class="form-control-error-custom">Completion date is
              required
            </div>
          </div>
          <div class="col-md-4"></div>
        </div>


        <div class=" margin-bottom-50">
          <div class="table-responsive margin-bottom-50">
            <table datatable [dtOptions]="dtComplianceOptions" id="complianceTasksTable"
              [dtTrigger]="dtComplianceTrigger"
              class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable"
              style="background:transparent;">
              <thead>
                <tr>
                  <th style="width: 5%;">
                    <input type="checkbox" id="checkAllCompliance" (click)="markAllCompliance($event)">
                  </th>
                  <th style="width: 5%;">Sr. No.</th>
                  <th style="width: 15%;">Company Name</th>
                  <th style="width: 25%;">Tasks</th>
                  <th style="width: 10%;">Status</th>
                  <th style="width: 10%;">Process Name</th>
                  <th style="width: 10%;">Meeting Type</th>
                  <th style="width: 10%;">Start Date</th>
                  <th style="width: 10%;">End Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let task of complianceTaskDetails;let count=index">
                  <td style="white-space: normal; width: 5%;">
                    <input type="checkbox" [ngModel]="task.isSelected"
                      (click)="checkComplianceTask($event,task, count)">
                  </td>
                  <td style="text-align:center; white-space: normal; width: 5%;">{{count + 1}}</td>
                  <td placement="right" style="white-space: normal; width: 15%">{{task.CompanyName}}</td>
                  <td placement="right" style="white-space: normal; width: 25%">{{task.TaskName}}</td>
                  <td style="white-space: normal; width: 10%; color:#3b73bb;" *ngIf="task.LocalStatus=='Pending'">
                    {{task.LocalStatus}}</td>
                  <td style="white-space: normal; width: 10%; color:#d5a325;"
                    *ngIf="task.LocalStatus=='PartiallyCompleted'">{{(task.LocalStatus == 'PartiallyCompleted') ?
                    "Partially Completed" : "PartiallyCompleted"}}</td>
                  <td style="white-space: normal; width: 10%; color:#32c359;"
                    *ngIf="task.LocalStatus=='Completed' || task.LocalStatus=='Done' || task.LocalStatus=='DelayedCompletion'">
                    {{(task.LocalStatus =='DelayedCompletion') ? "Delayed Completion" : task.LocalStatus}}</td>
                  <td style="white-space: normal; width: 10%; color:#f26f6c;"
                    *ngIf="task.LocalStatus=='Cancelled' || task.LocalStatus=='NA'">{{(task.LocalStatus =='NA') ? "Not
                    Applicable" : task.LocalStatus}}</td>

                  <td style="white-space: normal; width: 10%" *ngIf="task.ProcessName">{{task.ProcessName}}</td>
                  <td style="white-space: normal; width: 10%" *ngIf="!task.ProcessName">{{task.ProcessName}}</td>
                  <td style="white-space: normal; width: 10%" *ngIf="task.MeetingTypeID">
                    {{task.MeetingTypeID.MeetingType}}</td>
                  <td style="white-space: normal; width: 10%" *ngIf="!task.MeetingTypeID"></td>

                  <td style="white-space: normal; width: 10%">
                    <span style="display: none">{{task.StartDate | date : "yMMdd"}}</span> {{task.StartDate | date :
                    "dd-MM-y"}}
                  </td>
                  <td style="white-space: normal; width: 10%">
                    <span style="display: none">{{task.EndDate | date : "yMMdd"}}</span> {{task.EndDate | date :
                    "dd-MM-y"}}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>


  </div>



</div>
<div class="modal-footer">
  <button type="submit" class="btn btn-primary btn-sm" *ngIf="!activeComplianceTab" (click)="updateStatus()"
    [disabled]="!(governanceTaskSelected.length  && (taskObj.taskStatus != 'All')) ">Save</button>
  <button type="submit" class="btn btn-primary btn-sm" *ngIf="activeComplianceTab" (click)="updateStatus()"
    [disabled]="!(compliancedTaskSelected.length && (complianceTaskObj.taskStatus != 'All')) || (showCompletionCalendar && !completionDate)">Save</button>
  <button type="button" class="btn btn-default btn-sm" (click)="activeModal.dismiss()">Close</button>
</div>