import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserMasterService } from './../../../services/user-master.service';
import { CompanyMasterService } from './../../../services/companymaster.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin'
import * as _ from 'lodash';
// import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
// import { PssLink } from './../../../enums/pss-link.enum';
import { PssLink } from './../../../../environments/environment';
import { CommaSeperatedNumber } from '../../../helpers/CommaSeperatedNumber';
import { CommonFunction } from '../../../helpers/commonFunction';

import { rocketChatRemove } from '../../../enums/rocketChatRemove';

import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { PermissionLinks } from './../../../enums/permissionLinks';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

const now = new Date();

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.css']
})
export class CreateUserModalComponent implements OnInit {

  @Input('isGuestSuperAdmin') isGuestSuperAdmin: boolean;
  @Input('isSuperUser') isSuperUser: boolean;

  

  public dateOfBirthModel: any;
  public user: any;
  public userForm: any;
  public roleList: Array<any>;
  public userMasterForm: FormGroup;
  //public companyList: any;
  //public selectedCompany: any;
  public committeeList = [];
  public selectedCommmittee = [];
  public handleDINError: boolean;
  ids = [];
  reportToUserList = [];
  directorTypesList = [];
  existingUsersList = [];
  NGusersList = [];
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  minDate: any;
  DOBDate: any;
  titleList = [];
  defaultTitle: any;
  isExist: boolean = false;
  userList = [];
  // pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\/ ]*[a-zA-Z]+[a-zA-Z0-9]*$";//Pattern for validating blank input and space at first place
  pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\/. ]*[a-zA-Z.]+[a-zA-Z0-9]*$";//Pattern for validating blank input and space at first place

  //image cropper
  isInvalidImage: any;
  isValidUserType: boolean = true;
  isValidUser: boolean = true;
  is_edit: boolean = false;
  croppedWidth: number;
  croppedHeight: number;
  cropperSettings: CropperSettings;

  base64Code: any;
  createdUserId: any;
  categoryList: any;
  executiveDesignationlist: any;
  nonExecutiveDesignationlist: any;
  boardDesignationlist: any;
  kmpDesignationList: any;
  handleCategoryError: any;
  subsideryList: any;
  chosenSubsideries: any;
  selectedSubsideries: any;
  isPrimaryEmailIdReadOnly: boolean = false;
  isPrimaryEmailIdExists: boolean = false;
  isSecondaryEmailIdExists: boolean = false;
  isPANExists: boolean = false;
  isDINExists: boolean = false;
  isProceed: boolean = false;
  isADIdExists:boolean = false;

  isPSSConfig = environment.Service.PSS ;
  isGovevaConfig = environment.Service.GovEva ;
  isUPSIConfig = environment.Service.UPSI ;
  isAllConfig = environment.Service.All ;
  isNCDConfig = environment.Service.NCD ;
  // isBoardEvaluationConfig = environment.Service.BoardEvaluation;
  secretKey = "Secret message";
  notificationType: any = [];
  loggedInUserRoles:any;
  isAdminUser:any;

  @ViewChild('cropper',  { static: true }) cropper: ImageCropperComponent;
  isADEnabled: any;
  departmentList: any = [];
  branchList: any = [];

  constructor(public activeModal: NgbActiveModal, private userMasterService: UserMasterService, private fb: FormBuilder, private commonFunction: CommonFunction, private companyMasterService: CompanyMasterService) {
    this.userMasterForm = this.fb.group({
      IsExist: new FormControl(false, [
        Validators.required
      ]),
      UniqueUserId: new FormControl('', [

      ]),
      SMSNotification: new FormControl(false, []),
      EmailNotification: new FormControl(true, []),
      BellNotification: new FormControl(false, []),

      IPADNotification: new FormControl(true, []),
      BrowserNotification: new FormControl(true, []),
      //BothNotification: new FormControl(false, []),


      // PushNotification : new FormControl('',[]),
      TypeOfUser: new FormControl('', [
        Validators.required
      ]),
      FirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern)  //"[a-zA-Z ]*"
      ]),
      LastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern)
      ]),
      EmailId: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      SecondaryEmailId: new FormControl('', [
        // Validators.required,
        // Validators.email
      ]),
      MobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern("[0-9]{8,15}"),
      ]),
      MobileNumber2: new FormControl('', [
        Validators.pattern("[0-9]{8,15}"),
      ]),
      Experience: new FormControl('', [
        // Validators.required
      ]),
      DOB: new FormControl('', [
        // Validators.required
      ]),
      Remuneration: new FormControl('', [
        // Validators.required
      ]),
      RoleName: new FormControl('', [
        Validators.required
      ]),
      ReportToUser: new FormControl('', [
      ]),
      Designation: new FormControl('', [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500),
        Validators.pattern(this.pattern)
      ]),
      AreaofExpertise: new FormControl('', [
        // Validators.required,
        Validators.minLength(5),
        Validators.maxLength(500),
        Validators.pattern(this.pattern)
      ]),
      TitleName: new FormControl('', [
        Validators.required
      ]),
      DirectorType: new FormControl('', [
      ]),
      PAN: new FormControl('', [
        Validators.required,
        Validators.pattern("^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$")
      ]),
      DIN: new FormControl('', [
        //Validators.required,
        Validators.pattern("^([0-9]){8}?$")
      ]),
      Category: new FormControl('', [
        // Validators.required
      ]),
      Gender: new FormControl('', [
        // Validators.required
      ]),
      DesignationSelection: new FormControl('', [
        // Validators.required
      ]),
      SubsideryName: new FormControl('', [
      ]),
      Remarks: new FormControl('', [
        Validators.maxLength(35),
        Validators.pattern(this.pattern)
      ]),
      ADID: new FormControl('', [Validators.pattern('^([a-zA-Z0-9 _.-]+)$')]),
      BranchName: new FormControl(''),
      DepartmentName: new FormControl(''),
      departmentName: new FormControl('', []),
      branchName: new FormControl('', []),
      tempBranchName: new FormControl(null, []),
      tempDepartmentName: new FormControl(null, [])
    });

    //cropper settings
    this.handleDINError = false;
    this.handleCategoryError = false;
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 150;
    this.cropperSettings.height = 150;

    this.cropperSettings.croppedWidth = 150;
    this.cropperSettings.croppedHeight = 150;

    this.cropperSettings.canvasWidth = 465;
    this.cropperSettings.canvasHeight = 300;

    this.cropperSettings.minWidth = 10;
    this.cropperSettings.minHeight = 10;

    this.cropperSettings.rounded = false;
    this.cropperSettings.keepAspect = false;

    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings.noFileInput = true;


    this.base64Code = {};
  }


  //test image cropper
  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
  }

  dobDateValidation() {
    //dob date validation     
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear() - 18;
    this.DOBDate = { year: year, month: month, day: day };
  }

  ngOnInit() {

    $('body').css('overflow','hidden');
    
    let companyId = JSON.parse(localStorage.getItem('companyDetailsGoveva')).value;
    // console.log('companyId',companyId)
    this.companyMasterService.getSingleCompnyDetails(companyId).then(responseForAD => {
      // console.log('responseForAD', responseForAD)
      if (!responseForAD.status) {
        this.isADEnabled = responseForAD.companyData.IsADSubscribedFlag
      }
      this.fillCommitteeList();
      //this.fillReportToUser();
      this.fillSalutationTitleList();

      this.minDate = { year: 1910, month: 1, day: 1 };
      this.dobDateValidation();
      this.setValidatiorForDesignationInput();

      this.getBranch();
      this.getDepartment();

      this.isInvalidImage = true;

      this.user = {
        "FirstName": '',
        "LastName": '',
        "EmailId": '',
        "DateOfBirth": null,
        "MobileNumber": '',
        "Roles": [],
        "TypeOfUser": '',
        "CompanyName": '',
        "CompanyId": '',
        "Experience": '',
        "Remuneration": '',
        "ReportsTo": '',
        "Committees": [],
        "RocketChatBaseURL": '',
        "RocketChatAuthToken": '',
        "RocketChatUserId": '',
        "notificationType": [],
      }

      this.userMasterForm.controls['SMSNotification'].setValue(false);
      this.userMasterForm.controls['EmailNotification'].setValue(true)
      this.userMasterForm.controls['BellNotification'].setValue(false);
      // this.userMasterForm.controls['PushNotification'].setValue(false);

      this.userMasterForm.controls['IPADNotification'].setValue(true);
      this.userMasterForm.controls['BrowserNotification'].setValue(true)
      //this.userMasterForm.controls['BothNotification'].setValue(false);      

      this.checkNotificationArray();

      Observable.forkJoin([
        this.userMasterService.getCompanyList(),
        this.userMasterService.getRoleListOfUser()
      ]).subscribe(responses => {
        //console.log('checking for the result', responses)
        // this.companyList = responses[0].companyList.map(function (o) {
        //   return {
        //     "label": o.CompanyName,
        //     "value": o._id
        //   }
        // });


        this.roleList = responses[1].RolesList.map(function (o) {
          return {
            "_id": o._id,
            "label": o.RoleName,
            "value": o._id,
            "isActive": o.IsActive,
            "RoleId": o._id,
            "RoleName": o.RoleName
          }
        })

        //sort ascending for Role Name
        this.roleList = this.roleList.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
        //console.log('checking for the roleList', this.roleList)
      })

      // if (this.isGuestSuperAdmin) {
      //   this.userMasterForm.controls.TypeOfUser.clearValidators();
      //   this.userMasterForm.controls.TypeOfUser.updateValueAndValidity();
      // }

      this.fillCategoryList();

      this.kmpDesignationList = [
        { label: "Chief Executive Officer", value: "Chief Executive Officer" },
        { label: "Chief Financial Officer", value: "Chief Financial Officer" },
        { label: "Company Secretary", value: "Company Secretary" },
        { label: "Manager", value: "Manager" },
        { label: "Managing Director", value: "Managing Director" },
        { label: "Others", value: "Others" },
        { label: "Whole Time Director", value: "Whole Time Director" },
      ]
      this.executiveDesignationlist = [
        { label: "Managing Director", value: "Managing Director" },
        { label: "Managing Director (Additional Director)", value: "Managing Director (Additional Director)" },
        { label: "Whole Time Director", value: "Whole Time Director" },
        { label: 'Whole Time Director (Additional Director)', value: 'Whole Time Director (Additional Director)' }
      ]

      this.nonExecutiveDesignationlist = [
        { label: "Additional Director", value: "Additional Director" },
        { label: "Alternate Director", value: "Alternate Director" },
        { label: "Director", value: "Director" },
        { label: 'Director Appointed in Casual Vacancy', value: 'Director Appointed in Casual Vacancy' },
        { label: "Nominee Director", value: "Nominee Director" }
      ]

      // this.fillCommitteeList();
      // this.fillReportToUser();  

      //get all subsideries
      this.selectedSubsideries = [];
      this.getSubsideries();
      this.userMasterForm.value.IsExist = "false";
      this.userMasterForm.controls["IsExist"].setValue("false");
    })


    this.loggedInUserRoles = JSON.parse(localStorage.getItem("user")).Roles;      
      _.forEach(this.loggedInUserRoles, (element) => {              
        if (element.RoleName == PermissionLinks.Admin) {          
          this.isAdminUser = true;                
        }
      })
  }

  fillCategoryList() {
    this.categoryList = [
      {
        value: 'Independent',
        label: 'Independent'
      },
      {
        value: 'Promoter',
        label: 'Promoter'
      },
      {
        value: 'Professional',
        label: 'Professional'
      }
    ];
  }

  fillReportToUser(selectedUserType) {
    // var loginUsers = JSON.parse(localStorage.getItem("user"));
    // let loginUserId= loginUsers._id;
    this.userMasterService.getUserList().then(response => {
       

      if (response.result != undefined) {

        //only active user will be filtered
        response.result = response.result.filter(function (o) { return o.IsActive != false })
        //check RoleName for SuperAdmin, IC maker, IC Checker and remove it from report to list
        response.result = response.result.filter(function (userElement) { return _.get(_.first(userElement.Roles), 'RoleName') != 'GuestSuperAdmin' && _.get(_.first(userElement.Roles), 'RoleName') != 'IC Maker' && _.get(_.first(userElement.Roles), 'RoleName') != 'IC Checker' })

        this.reportToUserList = response.result.map((o) => {
          return {
            "label": o.FirstName + " " + o.LastName,
            "value": o._id
          }
        });

        //sort ascending for user Name
        this.reportToUserList = this.reportToUserList.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
      }
    })
  }

  //get salutation list
  fillSalutationTitleList() {
    this.userMasterService.getSalutationTitle().then(response => {

      this.titleList = response.salutationTitle.map((o) => {
        return {
          "label": o.label,
          "value": o.value,
        }
      });


      // this.titleList = [{
      //   "value": "Mr.", "label": "Mr."
      // },
      // { "value": "Mrs.", "label": "Mrs." },
      // { "value": "Shri.", "label": "Shri." },
      // { "value": "Smt.", "label": "Smt." },
      // { "value": "Dr.", "label": "Dr." },
      // { "value": "Adv.", "label": "Adv." },
      // { "value": "Ms.", "label": "Ms." }
      // ]
      //default selected
      this.defaultTitle = "Mr.";

    })
  }

  fillCommitteeList() {

    // this.userMasterService.getCommitteeList().then(response => {
    //    
    //   this.committeeList = response.committeeList.sort().map((o) => {
    //     return {
    //       "_id": o._id,
    //       "CommitteeName": o.CommitteeAlias
    //     }
    //   });

    //   //sort ascending for committee alias
    //   this.committeeList = this.committeeList.sort(function (a, b) {
    //     return a.CommitteeName.localeCompare(b.CommitteeName);
    //   });

    // });



    // this.committeeList = [{ "_id": 1, "CommitteeName": "Audit committee" },
    // { "_id": 2, "CommitteeName": "Borrowing committee" },
    // { "_id": 3, "CommitteeName": "CSR committee" },
    // { "_id": 4, "CommitteeName": "Debentures committee" },
    // { "_id": 5, "CommitteeName": "Granting of Loans, guarantee and security committee" },
    // { "_id": 6, "CommitteeName": "Investment committee" },
    // { "_id": 7, "CommitteeName": "Nomination and Remuneration Committee" },
    // { "_id": 8, "CommitteeName": "Risk management committee" },
    // { "_id": 9, "CommitteeName": "Share allotment committee" },
    // { "_id": 10, "CommitteeName": "Stakeholder relationship committee" },
    // { "_id": 11, "CommitteeName": "Vigilence committee" },
    // { "_id": 12, "CommitteeName": "Vigil mechanism Committee" }   
    // ]

  }

  onChangeUserType() {
     
    this.directorTypesList = [];
    let selectedUserType = this.userMasterForm.get('TypeOfUser').value;
    if (this.userMasterForm.get('TypeOfUser').value == "BoardMember") {
      //if (selectedUserType == "BoardMember") {
      $("#directorTypeDiv").show();
      this.directorTypesList = [
        //   {
        //   "value": "IndependentDirector", "label": "Independent Director"
        // },
        { "value": "ExcecutiveDirector", "label": "Executive Director" },
        { "value": "NonExcecutiveDirector", "label": "Non-Executive Director" }]

      //this.userMasterForm.controls.DIN.reset();
      this.setValidationForCategoryAndDIN();

      this.setValidationForDesignation();

    }else if (this.userMasterForm.get('TypeOfUser').value == "BoardMember"){
      

      this.setValidationForDesignation();
      $("#directorTypeDiv").hide();
      this.removedValidationForCategoryAndDIN();
    }
    else {
      $("#directorTypeDiv").hide();
      this.removedValidationForCategoryAndDIN();
    }

    if (this.userMasterForm.get('TypeOfUser').value == "BoardMember" || this.userMasterForm.get('TypeOfUser').value == "KMP") {
      this.userMasterForm.controls.DesignationSelection.reset();
      this.setValidatiorForDesignationSelection();
    }
    else {
      this.setValidatiorForDesignationInput();
    }

    this.fillReportToUser(selectedUserType);

  }

  setValidationForDesignation() {
    
    this.userMasterForm.controls.DesignationSelection.setValidators([Validators.required]);
  }

  setValidationForCategoryAndDIN() {
     
    this.userMasterForm.controls.DIN.setValidators([Validators.required, Validators.pattern("^([0-9]){8}?$")]);
    // this.userMasterForm.controls.Category.setValidators([Validators.required]);
    this.userMasterForm.controls.Category.setValidators([]);

    this.userMasterForm.controls.DIN.updateValueAndValidity();
  }

  removedValidationForCategoryAndDIN() {
     
    this.userMasterForm.controls.DIN.clearValidators();
    this.userMasterForm.controls.DIN.setValidators([Validators.pattern("^([0-9]){8}?$")]);
    this.userMasterForm.controls.DIN.updateValueAndValidity();
    this.userMasterForm.controls.Category.clearValidators();
    this.userMasterForm.controls.Category.updateValueAndValidity();
  }


  //director type is required if user type 'BoardMember' is selected 
  checkValidUserTypeForDirectorType() {
     
    // if (this.userMasterForm.get('TypeOfUser').value == "BoardMember") {
    //   if (this.userMasterForm.get('DirectorType').value == "" || this.userMasterForm.get('DirectorType').value == undefined) {
    //     this.isValidUserType = false;
    //     return false;
    //   }
    //   else
    //     this.isValidUserType = true;

    // }
    return true;
  }

  // To check if the User is new or Existing
  onChangeAddUser() {
    this.existingUsersList = [];
    let selectedUser = this.userMasterForm.get('IsExist').value;
    this.defaultTitle = "Mr.";

    if (this.userMasterForm.get('IsExist').value == "true") {
       
      this.userMasterForm.reset();
      console.log("this.userMasterForm",this.userMasterForm)
      this.userMasterForm.controls["IsExist"].setValue("true");
      this.onChangeUserType();
      this.userMasterForm.controls["TitleName"].setValue('Mr.');



      this.userMasterForm.value.IsExist = true;
      this.userMasterForm.value.EmailNotification = true;
      this.userMasterForm.value.IPADNotification = true;
      this.userMasterForm.value.BrowserNotification = true;
      $("#userList").show();
      this.getUserList();
      this.userMasterService.getExistingUsersList().then(response => {
         
        this.existingUsersList = response.result;
        //filtered list with user if already present in company.
        response.result.filter((item) => {
          var exists = this.userList.filter(e => e.UniqueUserId == item.UniqueUserId);
          if (exists.length > 0) {
            var idx = this.existingUsersList.indexOf(item);
            this.existingUsersList.splice(idx, 1);
          }
        });


        // for (let index = 0; index < response.result.length; index++) {

        //   var exists = 0;
        //   if (response.result[index] != undefined) {
        //     for (let i = 0; i < this.userList.length; i++) {
        //       if (this.userList[i].UniqueUserId == response.result[index].UniqueUserId) {
        //         // exists++;
        //         if (exists > 0) {
        //           var idx = this.existingUsersList.indexOf(response.result[index]);
        //           this.existingUsersList.splice(idx, 1);
        //         }
        //         // break;
        //       }
        //     }

        //   }
        // }       

        this.NGusersList = _.map(this.existingUsersList, function (element) {
          return {
            label: element.Title + ' ' + element.FirstName + ' ' + element.LastName,
            value: element._id
          }
        })

        this.userMasterForm.controls['SMSNotification'].setValue(false);
        this.userMasterForm.controls['EmailNotification'].setValue(true)
        
        this.userMasterForm.controls['EmailNotification'].updateValueAndValidity();
        this.userMasterForm.controls['BellNotification'].setValue(false);

        this.userMasterForm.controls['IPADNotification'].setValue(true);
        this.userMasterForm.controls['BrowserNotification'].setValue(true)

        this.userMasterForm.updateValueAndValidity();

      });
    }
    else {
      this.clearAllFields();
      this.userMasterForm.updateValueAndValidity();
    }

  }

  clearAllFields() {
    $("#userList").hide();
    this.is_edit = false;
    this.userMasterForm.controls["FirstName"].setValue('');
    this.userMasterForm.controls["LastName"].setValue('');
    this.userMasterForm.controls["DOB"].setValue('');
    this.userMasterForm.controls["TitleName"].setValue('');
    this.userMasterForm.controls["PAN"].setValue('');
    this.isPrimaryEmailIdReadOnly = false;
    //added on 3Sep
    this.userMasterForm.reset();
    this.userMasterForm.controls["IsExist"].setValue("false");
    this.userMasterForm.value.IsExist = "false";
    this.isPrimaryEmailIdReadOnly = false;
    this.isPrimaryEmailIdExists = false;
    this.isSecondaryEmailIdExists = false;
    this.isValidUserType = true;
    this.isValidUser = true;
    this.isPANExists = false;
    this.isDINExists = false;
    this.onChangeUserType();
    this.defaultTitle = "Mr.";
    this.userMasterForm.controls["TitleName"].setValue('Mr.');

    this.userMasterForm.controls['SMSNotification'].setValue(false);
    this.userMasterForm.controls['EmailNotification'].setValue(true)
    this.userMasterForm.controls['BellNotification'].setValue(false);

    this.userMasterForm.controls['IPADNotification'].setValue(false);
    this.userMasterForm.controls['BrowserNotification'].setValue(true);
    //this.setValidationForCategoryAndDIN();
  }


  getUserList() {
    this.userMasterService.getUserList().then(response => {
      //console.log('checking for the user list', response);
      if (!response.status) {
        // const data = this.manageRolename(response);
         
        //console.log('responses', response.result)
        //this.userList = data.result;
        //sort by created on
        this.userList = _.orderBy(response.result, ['CreatedOn'], ['desc']);
      }
    });
  }

  //commented Committee as per client req-
  //bind array  with selected committee
  // changeCommittee(e, type) {
  //   //  

  //   if (e.target.checked) {

  //     this.selectedCommmittee.push({ CommitteeName: type.CommitteeName });
  //   }
  //   else {

  //     let updateItem = this.selectedCommmittee.find(this.findIndexToUpdate, type.CommitteeName);
  //     let index = this.selectedCommmittee.indexOf(updateItem);
  //     this.selectedCommmittee.splice(index, 1);

  //   }
  // }


  findIndexToUpdate(type) {
    return type.CommitteeName === this;
  }


  onlyNumber(ev) {

    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9)) {

      if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      // if ((ev.target.value + ev.key).length > 11) {
      //   ev.preventDefault();
      // }
      //  
    }
    else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }
  }

  //restrict user to type zero as first digit
  restrictFirstDigitForZero(e) {
    let firstChar = e.target.value.substring(0, 1);

    if (firstChar == 0)
      e.target.value = e.target.value.slice(1);
  }

  //Role Dropdown is single select -- client requirement

  onSelectRole(data: any) {
     
    //console.log('checking for selected data', data)
    const self = this;
    const selectedRole = _.find(self.roleList, function (o) { return o._id === data.value })

    //this.user.Roles = [];  -- multiple roles
    if (this.isGuestSuperAdmin) {   //|| this.isSuperUser
      this.user.Roles = [];
    }

    this.user.Roles.push({
      "RoleId": selectedRole.RoleId,
      "RoleName": selectedRole.RoleName
    })
    //console.log("this.user.Roles", this.user.Roles);
  }

  //Role Dropdown is single select -- client requirement
  multipleDeselected(data: any) {
    //console.log('checking for the deselected data', data)
    const self = this;
    const indexOfDeselectedValue = _.findIndex(self.user.Roles, function (o) { return _.get(o, 'RoleId') === data.value });
    this.user.Roles.splice(indexOfDeselectedValue, 1)
  }

  setDate(date) {

    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }


  save() {

    const self = this;
   
    this.isProceed = true;

    //  
    // //check panno exist for other company    
    var pan = this.userMasterForm.get('PAN').value.toUpperCase();
    var ecodedPAN = this.userMasterService.encodePAN(pan);
    if (this.userMasterForm.value.IsExist == "false") {
      this.userMasterService.getUserListByPAN(ecodedPAN).then(userList => {
        if (userList.status == 0) {
           
          if (userList.listOfId.length > 0) {

            $("#PanNoConfirmationPopUp").modal('toggle');
            console.log("Pan no already exists");
            
          }
          else {
            this.saveUserInformation(function (responseForUsercreation) {
              $('body').css('overflow','auto');
              
              if (!responseForUsercreation.status) {
                self.activeModal.close(responseForUsercreation);
              } else {
                self.activeModal.close(responseForUsercreation);
              }
            })
          }
        }
      });
    }
    else {
      this.saveUserInformation(function (responseForUsercreation) {
        $('body').css('overflow','auto');
        
        if (!responseForUsercreation.status) {
          self.activeModal.close(responseForUsercreation);
        } else {
          self.activeModal.close(responseForUsercreation);
        }
      })
    }


   

  }

  // onSelectedCompanyName(data) {
  //   //console.log(data)
  //   this.selectedCompany = data;
  // }

  // onDeselectedCompanyName(data) {
  //   //console.log(data)
  // }

  onSelectedReportToUser(data) {

  }

  onDeselectedReportToUser(data) {

  }

  cancel() {
    this.activeModal.dismiss('dismissed');
    $('body').css('overflow','auto');    
  }

  cancelProfilePhotoModel() {
    $('#uploadProfilePopUp').modal('hide');
    $('.modal').css('overflow', 'auto');
    $('body').css('overflow','hidden');    
  }

  //profile upload

  showUploadImagePopUp() {
     
    this.resetImage();
    $("#uploadProfilePopUp").modal('toggle');
    // setTimeout(function(){ $("#uploadProfilePopUp").modal('toggle'); }, 100);
  }

  //reset profile image & cropper in profile dialog
  resetImage() {
    this.cropper.reset();        //reset cropper 
    $("#custom-input").val("");  //reset file name title
    this.isInvalidImage = true;
  }

  readImageData: any;
  filesSelect(data) {
    //  
    this.readImageData = data;
  }

  setProfilePhoto() {
    //  
    //if (this.base64Code != null) {
    if (Object.keys(this.base64Code).length != 0) {
      let imageString = this.base64Code.image;
      $('#profilePhoto').attr('src', imageString);
      $('#uploadProfilePopUp').modal('toggle');
      // this.uploadProfilePopUpTimeoutToogle();

      $('.modal').css('overflow', 'auto');
      $('body').css('overflow','hidden');      
    }

    // if (this.readImageData != null) {
    //   if (this.readImageData.target.files && this.readImageData.target.files[0]) {
    //     var reader = new FileReader();  // // Create instance of the FileReader

    //     reader.onload =  (e) => {
    //       //  
    //       $('#profilePhoto').attr('src', _.get(e.target, 'result'));  //set uploaded image data as background of div
    //       this.base64Code = _.get(e.target, 'result');
    //     }

    //     reader.readAsDataURL(this.readImageData.target.files[0]);   //// read the image uploaded

    //     //close the modal
    //     $('#uploadProfilePopUp').modal('toggle');
    //     this.uploadProfilePopUpTimeoutToogle();
    //   }
    // }
  }

  uploadProfilePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#uploadProfilePopUp').attr('class');
      if (popUpClass == "modal fade show") {
        $('#uploadProfilePopUp').modal('toggle');
      }
    }, 3000);
  }


  //set the image for cropping
  fileChangeListener($event) {
    //  

    this.cropper.reset();
    this.isInvalidImage = true;

    var image: any = new Image();
    var file: File = $event.target.files[0];
    var fileType = file["type"];
    var ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if ($.inArray(fileType, ValidImageTypes) > 0) {
      // valid file type code goes here.
      this.isInvalidImage = false;
    }
    else
      this.isInvalidImage = true;          //if uploaded file is invalid

    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
    };

    myReader.readAsDataURL(file);

  }

  redirectToPSS() {
    //first save data into database and then redirect....
    //only for the KMP and Director
    // this.activeModal.close()
    const self = this;
    this.saveUserInformation(function (responseForCreatedUser) {
      console.log('oooooooooooooooo-------ooooooooooo',responseForCreatedUser)
      if (!responseForCreatedUser.status) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (self.userMasterForm.get("TypeOfUser").value == "BoardMember") {
          var url = PssLink.EditDirector
            .replace('{email}', self.commonFunction.decrypt(user.EmailId))
            .replace('{userId}', responseForCreatedUser.pssObject.ID)
          window.open(url, "_self")
          self.activeModal.close(responseForCreatedUser);
        }
        if (self.userMasterForm.get("TypeOfUser").value == "KMP") {
          // var url = PssLink.EditKMP.replace('{email}', user.EmailId)
          var url = PssLink.EditKMP.replace('{email}', self.commonFunction.decrypt(user.EmailId))
            // .replace('{userId}', self.createdUserId)
            .replace('{userId}', responseForCreatedUser.pssObject.ID)
          window.open(url, "_self")
          self.activeModal.close(responseForCreatedUser);
        }
      } else {
        self.activeModal.close(responseForCreatedUser);
      }

    })
  }

  

  saveUserInformation(callback) {
     
    const self = this;

    if (this.checkValidUserTypeForDirectorType()) {
      $('body').css('overflow','auto');      
      // if ((self.userMasterForm.get("TypeOfUser").value == "BoardMember") && _.isEmpty(self.userMasterForm.get('DIN').value) && _.isEmpty(self.userMasterForm.get('Category').value)) {
      if ((self.userMasterForm.get("TypeOfUser").value == "BoardMember") && _.isEmpty(self.userMasterForm.get('DIN').value)) {

        this.handleDINError = true;
        // this.handleCategoryError = true;
        return;
      }
      this.handleDINError = false;
      this.handleCategoryError = false;
      this.isProceed = true;

      // console.log("this.userMasterForm.get('Experience').value.",this.userMasterForm.get('Experience').value)
      // 
      this.user.FirstName = this.userMasterForm.get('FirstName').value;
      this.user.LastName = this.userMasterForm.get('LastName').value;
      this.user.MobileNumber = this.userMasterForm.get('MobileNumber').value;
      this.user.MobileNumber2 = this.userMasterForm.get('MobileNumber2').value;
      this.user.EmailId = this.userMasterForm.get('EmailId').value.toLowerCase();
      this.user.SecondaryEmailId = this.userMasterForm.get('SecondaryEmailId').value ? this.userMasterForm.get('SecondaryEmailId').value.toLowerCase() : null;
      this.user.TypeOfUser = this.userMasterForm.get('TypeOfUser').value;
      var loginUsers = JSON.parse(localStorage.getItem("user"));
      this.user.CompanyName = loginUsers.CompanyName;  //this.selectedCompany.label;
      this.user.CompanyId = loginUsers.CompanyId;      //this.selectedCompany.value;  
      this.user.IsExist = this.userMasterForm.get('IsExist').value;
      this.user.UniqueUserId = this.userMasterForm.get('UniqueUserId').value;

      this.user.BranchName = this.userMasterForm.get("BranchName").value;
      this.user.DepartmentName = this.userMasterForm.get("DepartmentName").value;

      this.user.branchName = this.userMasterForm.get("tempBranchName").value;
      let branch = this.branchList.find(i => i.value === this.user.BranchName)
      this.user.branchId = branch && branch._id
      this.user.departmentName = this.userMasterForm.get("tempDepartmentName").value;
      let department = this.departmentList.find(i => i.value === this.user.DepartmentName)
      this.user.departmentId = department && department._id
      this.user.Experience = this.userMasterForm.get('Experience').value;
      this.user.Remuneration = this.commaSeperatedToNumberOnly(this.userMasterForm.get('Remuneration').value);
      this.user.ReportsTo = this.userMasterForm.get('ReportToUser').value;

      this.user.DateOfBirth = this.setDate(this.userMasterForm.controls.DOB.value);

      this.user.Title = this.userMasterForm.get('TitleName').value;

      if (self.userMasterForm.get("TypeOfUser").value == "BoardMember" || self.userMasterForm.get("TypeOfUser").value == "KMP") {
        this.user.Designation = this.userMasterForm.get('DesignationSelection').value;
      }
      else
        this.user.Designation = this.userMasterForm.get('Designation').value;

      this.user.AreaOfExpertise = this.userMasterForm.get('AreaofExpertise').value;
      this.user.PAN = this.userMasterForm.get('PAN').value.toUpperCase();
      this.user.PAN = CryptoJS.AES.encrypt(this.userMasterForm.get('PAN').value.toUpperCase(), this.secretKey.trim()).toString();
      this.user.DIN = this.userMasterForm.get('DIN').value;

      if (this.userMasterForm.get("TypeOfUser").value == "BoardMember") {
        this.user.DirectorType = this.userMasterForm.get('DirectorType').value;
        this.user.Category = this.userMasterForm.get('Category').value;
      }

      this.user.Gender = this.userMasterForm.get("Gender").value;

      //RocketChatData
      if (rocketChatRemove.isRemove.toString() != 'true') {
        this.user.RocketChatAuthToken = localStorage.getItem('rocketAuth');
        this.user.RocketChatUserId = localStorage.getItem('rocketUserId');
      }
      this.user.RocketChatBaseURL = localStorage.getItem('rocketBaseURL');

      if (this.userMasterForm.controls['IPADNotification'].value == true && this.userMasterForm.controls['BrowserNotification'].value == true) {        
        this.notificationType.push("Ipad")
        this.notificationType.push("Browser")
      }

      this.user.notificationType = this.notificationType;
      this.user.isRemoveRocketChat = rocketChatRemove.isRemove;

      //Commitees commented as per client requirement
      // var committees = [];
      // _.forEach(self.selectedCommmittee, function (data) {
      //   committees.push(data.CommitteeName)
      // })

      // this.user.Committees = committees;

       
      if (Object.keys(this.base64Code).length != 0) {
        this.user.ProfilePicture = this.base64Code.image;
      }

      //save subsideries
       
      if (_.size(this.selectedSubsideries)) {
        this.selectedSubsideries = this.selectedSubsideries.map(function (o) {
          return {
            'Name': o.label,
            '_id': o.value.ID,
            'CIN': o.value.CIN,
            'Type': o.value.Type,
            'Shareholding': o.value.Shareholding,
            'Fincode': o.value.Fincode
          }
        });
      }

      this.user.SubsidiaryCompanies = this.selectedSubsideries;

      this.user.remarks = this.userMasterForm.get('Remarks').value;

      //not Authorized.
      this.user.IsAuthorize = false;

      if (this.isGuestSuperAdmin) {
        this.user.IsExist = 'false';
        //this.user.IsAuthorize = true;
      }


      this.user.ADID = this.userMasterForm.get('ADID').value;
      this.userMasterService.createUser(this.user).then(response => {
        this.isProceed = false;

        // setTimeout(() => {    //<<<---    using ()=> syntax
        //   this.isProceed = false;
        // }, 3000);

        if (!response.status) {
          if ('user' in response) {
             
            this.createdUserId = response.user._id

            //If login user is superadmin then created user will be authorized.
            if (this.isGuestSuperAdmin) {
              this.user.UserId = response.user._id;
              this.user.IsAuthorize = true;
              this.userMasterService.authorizeUser(this.user).then(response => {
                //callback(response)
              })
            }

          }
           
          callback(response)
          // this.activeModal.close(response);
        }
        else {
          callback(response)
          // this.activeModal.close(response);
        }
      })
    }
    else {
      // callback()
      //console.log("invalid form submit");
    }
  }

  //converts comma seperated number to Number only
  commaSeperatedToNumberOnly(value) {
    if (value == "" || value == null || value == undefined) {
      return "";
    }
    else {
      value = value.toString();
      return value.replace(new RegExp(",".replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), "");
    }
  }

  

  onSelectedDirectorType(data) {
     
    if (data.value == "ExcecutiveDirector") {
      //Fill designation according to ExcecutiveDirector
      this.boardDesignationlist = this.executiveDesignationlist;
      this.categoryList = $.grep(this.categoryList, function (category) {
         
        return category.label != "Independent";
      });
    }
    else //if Non-ExcecutiveDirector 
    {
      //Fill designation according to Non-ExcecutiveDirector
      this.boardDesignationlist = this.nonExecutiveDesignationlist;
      this.categoryList = [];
      this.fillCategoryList();
    }
  }

  onSelectedUser(data) {
     
    if (data.value != undefined) {
      var userId = data.value;
      this.is_edit = true;
      //get the selected existing user details. 
      this.userMasterService.getUserdataByUserId(userId).then(response => {
         
        let dt;
        dt = new Date(this.setFormatDate(response.result.DateOfBirth));
        this.dateOfBirthModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
        this.userMasterForm.controls["FirstName"].setValue(response.result.FirstName);
        this.userMasterForm.controls["LastName"].setValue(response.result.LastName);
        //this.userMasterForm.controls["DOB"].setValue(this.setFormatDate(response.result.DOB));
        this.userMasterForm.controls["TitleName"].setValue(response.result.Title);
        this.userMasterForm.controls["PAN"].setValue(response.result.PAN);
        //added on 17 July
        this.userMasterForm.controls["Gender"].setValue(response.result.Gender);
        this.userMasterForm.controls["DIN"].setValue(response.result.DIN);
        
        //reading profile image from database and showing.
        if (response.result.ProfilePicture != null && response.result.ProfilePicture != "") { 
          $('#profilePhoto').attr('src', response.result.ProfilePicture);
          response.result.ProfilePicture = this.base64Code.image;
        }else{
          $('#profilePhoto').attr('src', "assets/modules/dummy-assets/common/img/avatars/default-profile.png");
        }
        //Primary Email Id
        this.userMasterForm.controls["EmailId"].setValue(response.result.EmailId);
        this.isPrimaryEmailIdReadOnly = true;

        //added on 8Aug -- client req
        this.userMasterForm.controls["Experience"].setValue(response.result.Experience);
        this.userMasterForm.controls["AreaofExpertise"].setValue(response.result.AreaOfExpertise);

        var isBlankEmail = this.userMasterForm.get('EmailId').hasError('required');
        var isEmailInvalid = this.userMasterForm.get('EmailId').hasError('email');

        // added on 31 aug 2021
        if (!isBlankEmail && !isEmailInvalid) {
          this.userMasterService.checkEmailIdExists(response.result.EmailId).then(response => {
            if (response.status) {
              this.isPrimaryEmailIdExists = true;
            }
            else {
              this.isPrimaryEmailIdExists = false;
            }
          });
         }        
      })
    }
  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
      //.toLocaleDateString();
    }
    return null;
  }

  //set validator for Designation input box
  setValidatiorForDesignationInput() {
    // this.userMasterForm.controls.Designation.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(500), Validators.pattern(this.pattern)]);
    this.userMasterForm.controls.Designation.setValidators([Validators.minLength(5), Validators.maxLength(500), Validators.pattern(this.pattern)]);
    this.userMasterForm.controls.DesignationSelection.clearValidators();
    this.userMasterForm.controls.DesignationSelection.updateValueAndValidity();
  }
  //set validator for Designation selction box
  setValidatiorForDesignationSelection() {
    // this.userMasterForm.controls.DesignationSelection.setValidators([Validators.required]);
    this.userMasterForm.controls.Designation.clearValidators();
    this.userMasterForm.controls.Designation.updateValueAndValidity();
  }

  getSubsideries() {
    this.userMasterService.getSubsideryList().then(response => {
      //console.log('checking for the user list', response);
      if (!response.status) {
         
        this.subsideryList = response.companyData.SubsidiaryCompanies.map(function (o) {
          return {
            'label': o.Name,
            'value': {
              'ID': o._id,
              'CIN': o.CIN,
              'Type': o.Type,
              'Shareholding': o.Shareholding,
              'Name': o.Name,
              'Fincode': o.Fincode
            }
          }
        });

        //sort ascending for user Name
        this.subsideryList = this.subsideryList.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });
      }
    });
  }

  //bind selected subsideries id and name to the list
  getSelectedSubsideries(item: any) {
     
    // this.selectedPeers.push([item.label, item.value]);
    this.selectedSubsideries.push(item);
  }

  //remove peers from list which is removed from ng-select control
  multipleSubsideriesDeselected(item: any) {
    //  
    let indexOfItem = this.selectedSubsideries.findIndex(o => o.value === item.value);
    this.selectedSubsideries.splice(indexOfItem, 1);
  }

  clearUserFormData() {
    //$("#PanNoConfirmationPopUp").hide();
    $("#PanNoConfirmationPopUp").modal('toggle');
    this.userMasterForm.controls["FirstName"].setValue('');
    this.userMasterForm.reset();

    $('.modal').css('overflow-y','scroll');
  }

  

  onEmailIdChange() {
     
    //var emailId = this.userMasterForm.get('EmailId').hasError('required');    
    var emailId = this.userMasterForm.controls['EmailId'].value;
    var isBlankEmail = this.userMasterForm.get('EmailId').hasError('required');
    var isEmailInvalid = this.userMasterForm.get('EmailId').hasError('email');
    if (!isBlankEmail && !isEmailInvalid) {
      this.userMasterService.checkEmailIdExists(emailId).then(response => {
        if (response.status) {          
          this.isPrimaryEmailIdExists = true;
        }
        else {
          this.isPrimaryEmailIdExists = false;
        }
      });
    }
  }


  onADIdChange() {
    
    //var emailId = this.userMasterForm.get('EmailId').hasError('required');    
    var ADID = this.userMasterForm.controls['ADID'].value;
    var isBlankADID = this.userMasterForm.get('ADID').hasError('required');
    var isADIDInvalid = this.userMasterForm.get('ADID').hasError('/^([a-zA-Z0-9 _.-]+)$/');
    if (!isBlankADID && !isADIDInvalid) {
      this.userMasterService.checkADIdExistsAdd(ADID).then(response => {
        if (response.status) {
          this.isADIdExists = true;
        }
        else {
          this.isADIdExists = false;
        }
      });
    }
  }

  onSecondaryEmailIdChange() {
     
    //var emailId = this.userMasterForm.get('EmailId').hasError('required');    
    var emailId = this.userMasterForm.controls['SecondaryEmailId'].value;
    var isBlankEmail = this.userMasterForm.get('SecondaryEmailId').hasError('required');
    var isEmailInvalid = this.userMasterForm.get('SecondaryEmailId').hasError('email');
    if (!isBlankEmail && !isEmailInvalid) {
      this.userMasterService.checkSecondaryEmailIdExists(emailId).then(response => {
        if (response.status) {
          this.isSecondaryEmailIdExists = true;
        }
        else {
          this.isSecondaryEmailIdExists = false;
        }
      });
    }
  }

  onPANChange() {
     
    //var emailId = this.userMasterForm.get('EmailId').hasError('required');    
    var PAN = this.userMasterForm.controls['PAN'].value;
    var isBlankPAN = this.userMasterForm.get('PAN').hasError('required');
    var isPANInvalid = this.userMasterForm.get('PAN').hasError('pattern');
    if (!isBlankPAN && !isPANInvalid) {
      this.userMasterService.checkPANExists(PAN).then(response => {
        if (response.status) {
          this.isPANExists = true;
        }
        else {
          this.isPANExists = false;
        }
      });
    }
  }

  onDINChange() {
     
    var DIN = this.userMasterForm.controls['DIN'].value;
    var isBlankDIN = this.userMasterForm.get('DIN').hasError('required');
    var isDINInvalid = this.userMasterForm.get('DIN').hasError("^([0-9]){8}?$");
    if (DIN != null) {
      if (!isBlankDIN && !isDINInvalid) {
        this.userMasterService.checkDINExists(DIN).then(response => {
          if (response.status) {
            this.isDINExists = true;
          }
          else {
            this.isDINExists = false;
          }
        });
      }
    }
  }

  notificationCheckboxChangeEvent(event, value) {

    if (!event.target.checked) {
      if (!this.userMasterForm.controls['EmailNotification'].value && !this.userMasterForm.controls['BellNotification'].value
        && !this.userMasterForm.controls['SMSNotification'].value) {
        this.userMasterForm.controls[value].setValue(true);
      }
    }

    this.checkNotificationArray();

  }


  loginAccessCheckboxChangeEvent(event, value) {

    if (!event.target.checked) {
      if (!this.userMasterForm.controls['IPADNotification'].value && !this.userMasterForm.controls['BrowserNotification'].value) {
        this.userMasterForm.controls[value].setValue(true);
      }
    }

    this.LoginAccesscheckNotificationArray();

  }

  LoginAccesscheckNotificationArray() {
    var ipadTypePresent;
    var browserTypePresent;    
    //var bothTypepresent;
    ipadTypePresent = this.notificationType.includes("Ipad");
    browserTypePresent = this.notificationType.includes("Browser");    
    //bothTypepresent = this.notificationType.includes("Both")

    if (this.userMasterForm.controls['IPADNotification'].value) {
      console.log('if select','Val');
      if (!ipadTypePresent) {
        this.notificationType.push("Ipad")
      }
    } else {
      console.log('if select','No Val');
      if (ipadTypePresent) {
        var indexOfIpad = this.notificationType.indexOf("Ipad");
        if (indexOfIpad !== -1) {
          this.notificationType.splice(indexOfIpad, 1);
        }
      }
    }

    if (this.userMasterForm.controls['BrowserNotification'].value) {      
      if (!browserTypePresent) {
        this.notificationType.push("Browser")
      }
    } else {
      if (browserTypePresent) {
        var indexOfBrowser = this.notificationType.indexOf("Browser");
        if (indexOfBrowser !== -1) {
          this.notificationType.splice(indexOfBrowser, 1);
        }
      }
    }

    // if (this.userMasterForm.controls['BothNotification'].value) {      
    //   if (!bothTypepresent) {
    //     this.notificationType.push("Both")
    //   }
    // } else {
    //   if (bothTypepresent) {
    //     var indexOfEmail = this.notificationType.indexOf("Both");
    //     if (indexOfEmail !== -1) {
    //       this.notificationType.splice(indexOfEmail, 1);
    //     }
    //   }
    // }
  }

  checkNotificationArray() {
    var emailTypePresent;
    var smsTypePresent;
    // var pushTypePresent; 
    var bellTypepresent;
    emailTypePresent = this.notificationType.includes("Email");
    smsTypePresent = this.notificationType.includes("SMS");
    // pushTypePresent = this.notificationType.includes("Push");
    bellTypepresent = this.notificationType.includes("Bell")

    if (this.userMasterForm.controls['EmailNotification'].value) {
      if (!emailTypePresent) {
        this.notificationType.push("Email")
      }
    } else {
      if (emailTypePresent) {
        var indexOfEmail = this.notificationType.indexOf("Email");
        if (indexOfEmail !== -1) {
          this.notificationType.splice(indexOfEmail, 1);
        }
      }
    }

    if (this.userMasterForm.controls['SMSNotification'].value) {
      // this.notificationType.push("SMS")
      if (!smsTypePresent) {
        this.notificationType.push("SMS")
      }
    } else {
      if (smsTypePresent) {
        var indexOfEmail = this.notificationType.indexOf("SMS");
        if (indexOfEmail !== -1) {
          this.notificationType.splice(indexOfEmail, 1);
        }
      }
    }


    if (this.userMasterForm.controls['BellNotification'].value) {
      // this.notificationType.push("Bell")
      if (!bellTypepresent) {
        this.notificationType.push("Bell")
      }
    } else {
      if (bellTypepresent) {
        var indexOfEmail = this.notificationType.indexOf("Bell");
        if (indexOfEmail !== -1) {
          this.notificationType.splice(indexOfEmail, 1);
        }
      }
    }

    // if(this.userMasterForm.controls['PushNotification'].value){
    //   // this.notificationType.push("Push")
    //   if (!pushTypePresent) {
    //     this.notificationType.push("Push")
    //   }
    // } else {
    //   if (pushTypePresent) {
    //     var indexOfEmail = this.notificationType.indexOf("Push");
    //     if (indexOfEmail !== -1) {
    //       this.notificationType.splice(indexOfEmail, 1);
    //     }
    //   }
    // }

    //console.log("notificationType",this.notificationType);

  }

  ADWhitespaceError(){
    this.userMasterForm.controls.ADID.setValue(this.userMasterForm.controls.ADID.value.trim());
    this.userMasterForm.controls.ADID.updateValueAndValidity();
  }

  getDepartment() {
    this.userMasterService.getDepartment().then((response: any) => {
      if (response.data != undefined) {
        this.departmentList = response.data.map((o) => {
          return {
            "_id": o._id,
            "label": o.departmentName,
            // "value": o._id,
            "value": o.departmentId
          }
        });
      }
    })
  }


  getBranch() {
    this.userMasterService.getBranch().then((response: any) => {
      if (response.data != undefined) {
        this.branchList = response.data.map((o) => {
          return {
            "_id": o._id,
            "label": o.branchName,
            // "value": o._id,
            "value": o.branchId
          }
        });
      }
    })
  }

  onSelectDepartmebt(e){
   this.userMasterForm.patchValue({DepartmentName: e.value, tempDepartmentName: e.label})
  }
  onSelectBranch(e){
   this.userMasterForm.patchValue({BranchName: e.value, tempBranchName: e.label})
   }

}
