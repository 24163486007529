<div class="modal-content">
  <div class="modal-header">
    <H5 class="modal-title">Create Meeting</H5>
    <button type="button" class="close" style="right: 5px; position: absolute">
      <span aria-hidden="true" (click)="activeModal.dismiss('dismissed')"
        >&times;</span
      >
    </button>
  </div>
  <form
    [formGroup]="regularMeetingForm"
    class="well"
    (document:click)="a.close()"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label class="form-control-label">Meeting Name:</label>
                <span _ngcontent-c2="" style="color: red">*</span>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    id="issueSeries"
                    class="form-control"
                    (keydown)="noBlankSpaceAtStart($event)"
                    formControlName="meetingName"
                    required="required"
                  />
                  <div
                    *ngIf="
                      regularMeetingForm.controls['meetingName'].hasError(
                        'required'
                      ) && regularMeetingForm.controls['meetingName'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Meeting Name is required.
                  </div>
                </div>
              </div>

              <div
                class="col-md-6"
                style="padding-left: 0px; padding-right: 0px"
              >
                <div class="col-md-12">
                  <label class="form-control-label">Date:</label>
                  <span _ngcontent-c2="" style="color: red">*</span>
                </div>
                <div class="col-md-12 form-group">
                  <div class="input-group" (click)="$event.stopPropagation()">
                    <input
                      class="form-control"
                      formControlName="meetingDate"
                      [minDate]="minDate"
                      [value]="meetingDateModel"
                      (change)="meetingDateModel = getInputValueChange($event)"
                      (keyup)="checkValidDate()"
                      style="padding: 6px; z-index: 0"
                      id="meetingdate"
                      placeholder="dd-mm-yyyy"
                      name="meetingdate"
                      ngbDatepicker
                      #a="ngbDatepicker"
                      (click)="a.toggle()"
                      required="required"
                    />
                    <button
                      class="input-group-addon"
                      (click)="a.toggle()"
                      type="button"
                    >
                      <span
                        class="fa fa-calendar"
                        style="width: 1.2rem; height: 1rem; cursor: pointer"
                      ></span>
                    </button>
                  </div>
                  <div
                    *ngIf="
                      regularMeetingForm.controls['meetingDate'].hasError(
                        'required'
                      ) && regularMeetingForm.controls['meetingDate'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Meeting Date is required.
                  </div>
                  <div *ngIf="invalidDate" class="form-control-error-custom">
                    Please select current date to update.
                  </div>
                </div>
              </div>

              <div
                class="col-md-6"
                style="padding-left: 0px; padding-right: 0px"
              >
                <div class="col-md-12">
                  <label class="form-control-label">Time:</label>
                  <span _ngcontent-c2="" style="color: red">*</span>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="input-group" (focusout)="check($event)">
                      <input
                        type="text"
                        class="form-control timepicker-init"
                        formControlName="meetingTime"
                        required="required"
                      />
                      <span class="input-group-addon">
                        <i class="icmn-clock"></i>
                      </span>
                    </label>
                    <div
                      *ngIf="
                        regularMeetingForm.controls['meetingTime'].hasError(
                          'required'
                        ) && regularMeetingForm.controls['meetingTime'].touched
                      "
                      class="form-control-error-custom"
                    >
                      Meeting Time required.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label class="form-control-label">Meeting Location:</label>
                <span _ngcontent-c2="" style="color: red">*</span>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    (keydown)="noBlankSpaceAtStart($event)"
                    formControlName="meetingPlace"
                    required="required"
                  />
                  <div
                    *ngIf="
                      regularMeetingForm.controls['meetingPlace'].hasError(
                        'required'
                      ) && regularMeetingForm.controls['meetingPlace'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Meeting Place is required.
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label class="form-control-label">Upload documents:</label>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    type="file"
                    #file
                    (change)="uploadMultipleFile($event)"
                    class="hidden"
                    multiple
                    formControlName="meetingDoc"
                    accept=".xlsx, .xls, .csv, .jpg, .jpeg, .png, .doc, .docx, .txt, .pdf, .ppt, .mp4"
                  />
                  <label class="input-group">
                    <input class="form-control ng-untouched ng-pristine" placeholder="Browse" data-toggle="tooltip"
                      type="text" disabled>
                    <span class="input-group-addon warning">
                      <em class="fa fa-upload" (click)="file.click()"></em>
                    </span>
                  </label>
                  <span style="color: red" *ngIf="!multifileExtension"
                    >Please upload valid file format.</span
                  >
                </div>
              </div>
              <ul>
                <div class="row mb-lg-1" *ngFor="let file of attachmentsArray">
                  <div class="col-md-10">
                    <li>{{ file }}</li>
                  </div>
                  <div class="col-md-2">
                    <button style="font-size: 0px;" class="btn-sm btn btn-danger"
                      (click)="deleteFile(file)" type="button">
                      <span class="fa fa-close" style="font-size: 14px;"></span>
                    </button>
                  </div>
                </div>
              </ul>

              <div class="col-md-12">
                <label class="form-control-label">Add participants:</label>
                <span _ngcontent-c2="" style="color: red">*</span>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <ng-select
                    placeholder="Select User"
                    class="form-control p-8"
                    formControlName="meetingInvitees"
                    [multiple]="true"
                    [items]="userList"
                    style="padding: 0px; text-align: left"
                    [clearable]="false"
                  >
                  </ng-select>
                  <div
                    *ngIf="
                      regularMeetingForm.controls['meetingInvitees'].hasError(
                        'required'
                      ) &&
                      regularMeetingForm.controls['meetingInvitees'].touched
                    "
                    class="form-control-error-custom"
                  >
                    Meeting Participants are required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-center">
      <button
        *ngIf="addBtn"
        class="btn btn-sm btn-primary"
        [disabled]="!regularMeetingForm.valid"
        (click)="saveAndUpdateRegularMeeting()"
      >
        Save
      </button>
      <button
        *ngIf="!addBtn"
        class="btn btn-sm btn-primary"
        [disabled]="!regularMeetingForm.valid"
        (click)="saveAndUpdateRegularMeeting()"
      >
        Update
      </button>
      <button class="btn btn-sm" (click)="activeModal.dismiss('dismissed')">
        Close
      </button>
    </div>
  </form>
</div>
