<div class="col-sm-12" >
  <div class="cat__footer">
    <div class="cat__footer__top">
      <div class="row">
        <div class="col-lg-12">
          <div class="row footer-text">
              <div class="col-sm-6">
                <div class="v-middle">
              <span>Copyright © {{copyrightYears}}, All rights reserved</span>
            </div>
          </div>
  
            <div class="col-sm-6 version">
              <span>Version P1.2.2</span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

