import { Directive, ElementRef, AfterViewInit, Input, Renderer2, OnInit } from "@angular/core";
import * as _ from "lodash";

@Directive({
  selector: "[appHasPermission]",
})
export class HasPermissionDirective implements AfterViewInit {
  @Input("appHasPermission") permissionUrlAndMethod: any;
  constructor(private elemntRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const self = this;
    const resourcesValue = JSON.parse(localStorage.getItem("resources"));
    // console.log("resourcesvalue", resourcesValue);
    let allChildResourcesArray = [];
    _.forEach(resourcesValue, function (data) {
      allChildResourcesArray = _.concat(allChildResourcesArray, data.ChildResources);
    });

    // if (_.isEmpty(this.permissionUrlAndMethod.menu)) {
    //   const findElementByURLandMethod = _.find(allChildResourcesArray, function (o) { return o.Url == self.permissionUrlAndMethod.Url && o.MethodType == self.permissionUrlAndMethod.method })
    //   if (_.isNil(findElementByURLandMethod)) {
    //     this.elemntRef.nativeElement.outerHTML = '';
    //   }
    // } else {
    //   const findElementByMenu = _.find(resourcesValue, function(o){ return o.Menu == self.permissionUrlAndMethod.menu});
    //   if(_.isNil(findElementByMenu)) {
    //     this.elemntRef.nativeElement.outerHTML = '';
    //   }
    // }

    if (!_.isEmpty(this.permissionUrlAndMethod.Url && this.permissionUrlAndMethod.method)) {
      const findElementByURLandMethod = _.find(allChildResourcesArray, function (o) {
        return (
          o.Url == self.permissionUrlAndMethod.Url &&
          o.MethodType == self.permissionUrlAndMethod.method
        );
      });
      if (_.isNil(findElementByURLandMethod)) {
        if (this.permissionUrlAndMethod.menu == "Dashboard") {
          //
          this.elemntRef.nativeElement.outerHTML = "";
          // this.elemntRef.nativeElement.innerHTML = '<div style="width: 100%; text-align:  center; font-weight: bold;">No Data Found</div>';
        } else {
          this.elemntRef.nativeElement.outerHTML = "";
        }
      }
    } else {
      const findElementByMenu = _.find(resourcesValue, function (o) {
        return o.Menu == self.permissionUrlAndMethod.menu;
      });
      if (_.isNil(findElementByMenu)) {
        this.elemntRef.nativeElement.outerHTML = "";
      }
    }
  }
}
