import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class RoleMasterService {
    
        constructor(private http: HttpClient) {
           
        }

        //to get checkbox list of all the activities
        roleActivityCheckboxList(): Promise<any>{
            return this.http.get(environment.clientUrl + "resourceMaster/getResources")
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }

        //to get all Roles
        getRolesList(): Promise<any>{
            // return this.http.get(environment.clientUrl + "roleMaster/getUserRoles")
            return this.http.get(environment.clientUrl + "roleMaster/getUserRoles")
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }

        //to get the role to edit
        getRoleToEdit(RoleName): Promise<any>{
            return this.http.get(environment.clientUrl + "resourceMaster/getRoleWiseResources?RoleName=" + RoleName)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }

        getRoleToEditByRoleId(RoleId): Promise<any>{
            return this.http.get(environment.clientUrl + "resourceMaster/getRoleWiseResourcesByRoleId?RoleId=" + RoleId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }

        //to add new Role
        addNewRole(roleName,Resources): Promise<any>{
            return this.http.post(environment.clientUrl + "roleMaster/addRole",{ 
                'RoleName':roleName,
                'Resources':Resources
            })
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }

        //to update specific Role
        editRole(roleId,roleName,Resources): Promise<any>{
            //  
            return this.http.post(environment.clientUrl + "roleMaster/editRole" ,{
                RoleId:roleId,
                RoleName:roleName,
                Resources:Resources
            })
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })            
        }

        //to delete specific Role
        deleteRole(roleId): Promise<any>{
           
            return this.http.get(environment.clientUrl + "roleMaster/deleteRole/"  + roleId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
        }



    }