<!-- START: components/date-picker -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Date Picker</strong>
            <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Date Picker Only</strong></h5>
                <p class="text-muted">Element: read <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <label class="input-group datepicker-only-init">
                            <input type="text" class="form-control" placeholder="Select Date" />
                            <span class="input-group-addon">
                                <i class="icmn-calendar"></i>
                            </span>
                        </label>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control datepicker-only-init" placeholder="Select Date" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Time Picker Only</strong></h5>
                <p class="text-muted">Element: read <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <label class="input-group timepicker-init">
                            <input type="text" class="form-control" placeholder="Select Time" />
                            <span class="input-group-addon">
                                <i class="icmn-clock"></i>
                            </span>
                        </label>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control timepicker-init" placeholder="Select Time" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Date & Time Picker</strong></h5>
                <p class="text-muted">Element: read <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <input type="text" class="form-control datepicker-init" placeholder="Select Date" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Ranged Date Picker</strong></h5>
                <br />
                <div class="mb-5">
                    <div class="row">
                        <div class="col-lg-3">
                            <input type="text" class="form-control datepicker-only-init width-200 display-inline-block mr-2 mb-2" placeholder="From" />
                        </div>
                        <div class="col-lg-1">
                            <div class="text-center mt-2">—</div>
                        </div>
                        <div class="col-lg-3">
                            <input type="text" class="form-control datepicker-only-init width-200 display-inline-block mr-2 mb-2" placeholder="To" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Inline Date Picker</strong></h5>
                <p class="text-muted">Element: read <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <div class="datepicker-inline-init"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Inline Time Picker</strong></h5>
                <p class="text-muted">Element: read <a href="http://eonasdan.github.io/bootstrap-datetimepicker/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="form-group">
                        <div class="timepicker-inline-init"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/date-picker -->
