import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

import { MeetingMasterService } from '../../../services/meetingMaster.service';

@Component({
  selector: 'app-individual-meeting',
  templateUrl: './individual-meeting.component.html',
  styleUrls: ['./individual-meeting.component.css'],
  providers: [MeetingMasterService],
})
export class IndividualMeetingComponent implements OnInit {
  count: any;
  countDoc: any;
  meetingId: any;
  attendanceObject: any;
  IsattendecCount: any;
  attendanceFile: any;
  isAttendanceObjectEmpty: boolean;
  intervalRef;
  Attended: number = null;
  NotAttended: number = null;

  val1: number = null;
  val2: number = null;
  val3: number = null;
  val4: number = null;
  val5: number = null;

  @Input('agendaObject') agendaObject: any;
  @Input('meetingObject') meetingObject: any;
  @Input('documentObject') documentObject: any;

  constructor(private meetingMasterService: MeetingMasterService) {}

  ngOnInit() {
    this.meetingId = this.meetingObject._id;

    let user = JSON.parse(localStorage.getItem("user"));

    this.agendaObject.forEach((element, index) => {
      element.AgendaAccessRights.forEach(subElement=>{
        if ((subElement.UserGovevaId == user._id) && (subElement.View == false)) {
          console.log(index)
          this.agendaObject.splice(index,1)

        }
      })
      
    });

    this.count = this.agendaObject.length;   

    if (this.documentObject.noticeFile.isAvaible) {
      this.val1 = 1;
    }

    if (this.documentObject.agendaFile.isAvaible) {
      this.val2 = 1;
    }

    if (this.documentObject.draftFile.isAvaible) {
      this.val3 = 1;
    }

    if (this.documentObject.finalFile.isAvaible) {
      this.val4 = 1;
    }

    if (this.documentObject.ATRFile.isAvaible) {
      this.val5 = 1;
    }

    this.countDoc = this.val5 + this.val4 + this.val3 + this.val2 + this.val1+this.documentObject.agendaFileArray.length;
    this.attendanceObject = this.meetingObject.Attendance;

    if (this.meetingId !== null) {
      this.meetingMasterService
        .getMeetingById(this.meetingId)
        .then((response) => {
          this.attendanceObject = response.meetingData[0].Attendance;
          const attendance_array = this.attendanceObject;

          const isAttended = true;
          this.Attended = attendance_array.filter(
            (obj) => obj.isAttended === isAttended
          ).length;

          const NtAttended = false;
          this.NotAttended = attendance_array.filter(
            (obj) => obj.isAttended === NtAttended
          ).length;
        });
    }

    this.meetingMasterService
      .getUpdateMessagesList(this.meetingId)
      .then((response) => {
        console.log('get_update_message_list:', response);
      });
  }

  showAgenda() {
    document.getElementById('Agenda').style.display = 'block';
    document.getElementById('individual-meeting').style.display = 'none';
    document.getElementById('Document').style.display = 'none';
    document.getElementById('Attendance').style.display = 'none';
    document.getElementById('agendaAnalytics').style.display = 'none';
  }
  showDocument() {
    document.getElementById('Agenda').style.display = 'none';
    document.getElementById('individual-meeting').style.display = 'none';
    document.getElementById('Document').style.display = 'block';
    document.getElementById('Attendance').style.display = 'none';
    document.getElementById('agendaAnalytics').style.display = 'none';
  }
  showParticipant() {
    document.getElementById('Agenda').style.display = 'none';
    document.getElementById('individual-meeting').style.display = 'none';
    document.getElementById('Document').style.display = 'none';
    document.getElementById('Attendance').style.display = 'block';
    document.getElementById('agendaAnalytics').style.display = 'none';
  }
  showBoardPack() {
    document.getElementById('individual-meeting').style.display = 'none';
    document.getElementById('Agenda').style.display = 'block';
    document.getElementById('Document').style.display = 'block';
    document.getElementById('Attendance').style.display = 'block';
    document.getElementById('agendaAnalytics').style.display = 'none';
  }
  showAgendaAnalytics() {
    document.getElementById('individual-meeting').style.display = 'none';
    document.getElementById('Agenda').style.display = 'none';
    document.getElementById('Document').style.display = 'none';
    document.getElementById('Attendance').style.display = 'none';
    document.getElementById('agendaAnalytics').style.display = 'block';
  }
}
