<div class="modal-header">
  <h4 class="modal-title">Edit User</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="editUserMasterForm">
  <div class="modal-body">
      <fieldset>           
          <!-- Profile pic upload  -->
          <div class="row userdetail">
              <div class="col-md-6" style="padding: 0 45px 0 20px;"> 
                  <h4 class="title">Personal Details:</h4>
                  <div class="form-group">
                      <label>Type:<span >*</span></label><br>
                       <div *ngIf="isAuthorise">
                          <input disabled type="text" name="TypeOfUser" 
                          formControlName="TypeOfUser" (change)="onChangeUserType(null)" class="form-control" (click)="activateUpdate()"> 
                       </div>
                       <div *ngIf="!isAuthorise">                            
                          <label class="form-check-label">
                              <input type="radio" name="TypeOfUser" value="Employee"
                                  formControlName="TypeOfUser" (change)="onChangeUserType(null)" (click)="activateUpdate()"> Employee
                                  <span class="checkmark"></span> 
                          </label>
                          <label class="form-check-label">
                              <input type="radio" name="TypeOfUser" value="ExecutiveManagement" (click)="activateUpdate()"
                                  formControlName="TypeOfUser" (change)="onChangeUserType(null)"> Executive Management
                                  <span class="checkmark"></span> 
                          </label>
                          <label class="form-check-label">
                              <input type="radio" name="TypeOfUser" value="KMP" formControlName="TypeOfUser"
                                  (change)="onChangeUserType(null)" (click)="activateUpdate()"> KMP
                                  <span class="checkmark"></span> 
                          </label>
                          <label class="form-check-label">
                              <input type="radio" name="TypeOfUser" value="BoardMember" (click)="activateUpdate()"
                                  formControlName="TypeOfUser" (change)="onChangeUserType(null)"> Board Member
                                  <span class="checkmark"></span> 
                          </label>
                          <label class="form-check-label">
                              <input type="radio" name="TypeOfUser" value="Others" (click)="activateUpdate()"
                                  formControlName="TypeOfUser" (change)="onChangeUserType(null)"> Others
                                  <span class="checkmark"></span> 
                          </label>
                      </div>
                  </div>


                  <div class="row" style="display:none" id="directorTypeDiv">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Director Type:
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select [items]="directorTypesList"  formControlName="DirectorType"
                                  (change)="onSelectedDirectorType($event)" (click)="activateUpdate()" ></ng-select>
                              <div *ngIf="!isValidUserType" class="form-control-error-custom">
                                  Director Type is required
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">First Name:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-4  pr-0">
                          <ng-select [items]="titleList"
                              formControlName="TitleName" class="initial" style="width:100%"  (click)="activateUpdate()" 
                              bindLabel="label" bindValue="value">
                          </ng-select> 
                      </div>
                      <div class="col-sm-8">                            
                          <div hidden>
                              <div class="col-lg-2">
                                  <label class="form-control-label" for="l0">Id:
                                      <span _ngcontent-c2="" >*</span>
                                  </label>
                              </div>
                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <input placeholder="Id" type="text" class="form-control"
                                          formControlName="UserId" (keyup)="activateUpdate()">
                                      <div *ngIf="editUserMasterForm.get('UserId').hasError('required') && editUserMasterForm.get('UserId').touched"
                                          class="form-control-error-custom">
                                          UserId is required
                                      </div>
                                     
                                  </div>
                              </div>
                          </div>
                          <div class="form-group fName">
                              <input placeholder="First Name" type="text" class="form-control"
                                  formControlName="FirstName" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('FirstName').hasError('required') && editUserMasterForm.get('FirstName').touched"
                                  class="form-control-error-custom">
                                  First Name is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('FirstName').hasError('pattern')"
                                  class="form-control-error-custom">
                                  Please enter valid First Name
                              </div>
                          </div>
                      </div>

                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Last Name:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group">
                              <input placeholder="Last Name" type="text" class="form-control"
                                  formControlName="LastName" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('LastName').hasError('required') && editUserMasterForm.get('LastName').touched"
                                  class="form-control-error-custom">
                                  Last Name is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('LastName').hasError('pattern')"
                                  class="form-control-error-custom">
                                  Please enter valid Last Name
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0"> Designation:
                          </label>
                      </div>
                      <div class="col-sm-12" *ngIf="editUserMasterForm.get('TypeOfUser').value == 'BoardMember'">
                          <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select [items]="boardDesignationlist" bindLabel="label" bindValue="value"  
                              formControlName="DesignationSelection" (click)="activateUpdate()"></ng-select>
                              <div *ngIf="editUserMasterForm.get('DesignationSelection').hasError('required') && editUserMasterForm.get('DesignationSelection').touched"
                                  class="form-control-error-custom">
                                  Please select Designation.
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12" *ngIf=" editUserMasterForm.get('TypeOfUser').value == 'KMP'">
                          <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select [items]="kmpDesignationList" formControlName="DesignationSelection"
                              bindLabel="label" bindValue="value" (click)="activateUpdate()"></ng-select>
                              <div *ngIf="editUserMasterForm.get('DesignationSelection').hasError('required') && editUserMasterForm.get('DesignationSelection').touched"
                                  class="form-control-error-custom">
                                  Please select Designation.
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12"
                          *ngIf="editUserMasterForm.get('TypeOfUser').value != 'BoardMember' && editUserMasterForm.get('TypeOfUser').value != 'KMP'">
                          <div class="form-group">
                              <input placeholder="Designation" type="text" class="form-control"
                                  formControlName="Designation" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('Designation').hasError('required') && editUserMasterForm.get('Designation').touched"
                                  class="form-control-error-custom">
                                  Designation is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('Designation').hasError('pattern')"
                                  class="form-control-error-custom">
                                  Please enter valid Designation
                              </div>
                              <div *ngIf="!editUserMasterForm.get('Designation').hasError('pattern') && editUserMasterForm.get('Designation').hasError('minlength')"
                                  class="form-control-error-custom">
                                  Minimum 5 character required.
                              </div>
                              <div *ngIf="!editUserMasterForm.get('Designation').hasError('pattern') && editUserMasterForm.get('Designation').hasError('maxlength')"
                                  class="form-control-error-custom">
                                  Maximum 500 character required.
                              </div>

                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Email Id:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group">
                              <input placeholder="Email Id" type="email" class="form-control"
                                  formControlName="EmailId" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('EmailId').hasError('required') && editUserMasterForm.get('EmailId').touched"
                                  class="form-control-error-custom">
                                  Email Id is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('EmailId').hasError('email') && !editUserMasterForm.get('EmailId').hasError('required') && editUserMasterForm.get('EmailId').touched"
                                  class="form-control-error-custom">
                                  Please enter valid Email Id
                              </div>
                          </div>
                      </div>

                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Mobile:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group">
                              <input placeholder="Mobile No." type="text" class="form-control"
                                  formControlName="MobileNumber" min="10" max="10" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('MobileNumber').hasError('required') && editUserMasterForm.get('MobileNumber').touched"
                                  class="form-control-error-custom">
                                  Mobile Number is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('MobileNumber').hasError('pattern') && !editUserMasterForm.get('MobileNumber').hasError('required')"
                                  class="form-control-error-custom">
                                  Please enter valid mobile number
                              </div>
                          </div>
                      </div>

                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Gender:
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group gender">
                              <label>Male
                                  <input type="radio" name="Gender" value="Male" formControlName="Gender" (click)="activateUpdate()">
                                  <span class="checkmark"></span> 
                              </label>
                              <label>Female
                                  <input type="radio" name="Gender" value="Female" formControlName="Gender" (click)="activateUpdate()"> 
                                  <span class="checkmark"></span>
                              </label>
                              <label>Transgender
                                  <input type="radio" name="Gender" value="Transgender" formControlName="Gender" (click)="activateUpdate()">
                                  <span class="checkmark"></span>
                              </label>
                          </div>
                      </div>
                  </div>

                  <div class="row">
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Report To:

                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select [allowClear]="true" [items]="reportToUserList"
                                  [ngModel]="reportTo" formControlName="ReportToUser"
                                  (selected)="onSelectedReportToUser($event)"
                                  (deselected)="onDeselectedReportToUser($event)" (click)="activateUpdate()"></ng-select>
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">PAN:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-12" style="padding-bottom: 11px">
                          <input placeholder="PAN" type="text" class="form-control upperCase" formControlName="PAN"
                          (keyup)="activateUpdate()" (focusout)="onChangePAN()">
                          <div *ngIf="editUserMasterForm.get('PAN').hasError('required') && editUserMasterForm.get('PAN').touched"
                              class="form-control-error-custom">
                              PAN is required
                          </div>
                          <div *ngIf="editUserMasterForm.get('PAN').hasError('pattern') && !editUserMasterForm.get('PAN').hasError('required')"
                              class="form-control-error-custom">
                              Please enter valid PAN number
                          </div>
                          <div *ngIf="isPANExists && !editUserMasterForm.get('PAN').hasError('pattern') && !editUserMasterForm.get('PAN').hasError('required')"
                              class="form-control-error-custom">
                              PAN Number already exists
                          </div>
                      </div>

                  </div>

                  <div class="row">

                      <div class="col-sm-12" *ngIf="editUserMasterForm.get('TypeOfUser').value == 'BoardMember'">
                          <label class="form-control-label" for="l0">Category
                          </label>
                      </div>
                      <div class="col-sm-12" *ngIf="editUserMasterForm.get('TypeOfUser').value == 'BoardMember'">
                          <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select [items]="categoryList"
                                  formControlName="Category" (click)="activateUpdate()"></ng-select>
                              <div *ngIf="handleCategoryError" class="form-control-error-custom">
                                  Category is required
                              </div>
                          </div>
                      </div>

                  </div>

                  <div class="row">
                  </div>

                  <div class="row">
                  </div>

                  <!-- AD FIeld -->
                  <div class="row">
                      <div class="col-sm-12" *ngIf="isADEnabled">
                          <label class="form-control-label" for="l0">AD ID<span style="color: red">*</span>
                          </label>
                      </div>
                      <div class="col-sm-12" *ngIf="isADEnabled">
                          <input type="text" pattern="^([a-zA-Z0-9 _.-]+)$" class="form-control"
                              formControlName="ADID" (focusout)="onADIdChange();ADWhitespaceError();" required
                              (keyup)="activateUpdate()">
                          <div *ngIf="editUserMasterForm.get('ADID').hasError('required') && editUserMasterForm.get('ADID').touched"
                              class="form-control-error-custom">
                              AD ID is required.
                          </div>
                          <div *ngIf="isADIdExists" class="form-control-error-custom">
                              AD Id is already exists.
                          </div>
                          <div *ngIf="editUserMasterForm.controls['ADID'].hasError('pattern')"
                              class="form-control-error-custom">
                              AD ID is not valid.
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0"> Subsidiaries

                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group" style="padding-top:6px;box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                              <ng-select id=subsideryDropdown
                                  [ngModel]="chosenSubsideries" [items]="subsideryList"
                                  formControlName="SubsideryName" [multiple]="true"
                                  (selected)="getSelectedSubsideries($event)"
                                  (deselected)="multipleSubsideriesDeselected($event)"  (click)="activateUpdate()">
                              </ng-select>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6"  style="padding: 0 20px;">
                      <div class="mb-5">
                          <h4 class="title">Profile Picture:</h4>
                          <a class="img-circle img-responsive" (click)="showUploadImagePopUp()" id="profileImage">
                              <img id="profilePhoto" class="img-circle img-responsive"
                                  src="assets/modules/dummy-assets/common/img/avatars/default-profile.png" alt="user pic"
                                  title="Change Picture">
                              <span>Change</span>
                          </a>
                          <a href="javascript:void(0)" (click)="removeProfilePicture()"
                              class="remove">Remove</a>
                              
                      </div>

                <div class="row">
                          <div class="col-sm-12">
                              <label class="form-control-label" for="l0">DOB:
                              </label>
                          </div>
                    <div class="col-md-12">
                        <div class="form-group" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                        -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                        -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;">
                            <div class="input-group" (click)="$event.stopPropagation()">
                                <input class="form-control"
                                    [maxDate]="DOBDate" [formControl]="editUserMasterForm.controls['DOB']" name="dpDOB"
                                    style="padding:6px;z-index: 0;background-color:white;" placeholder="dd-mm-yyyy"
                                    ngbDatepicker #a="ngbDatepicker" (click)="a.toggle()" [(ngModel)]="dateOfBirthModel"
                                    [minDate]=minDate (document:click)="a.close();" 
                                    (dateSelect)="activateUpdate()">
                                <button class="input-group-addon" (click)="a.toggle()" type="button">
                                    <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                                </button>
                            </div>
                            <span *ngIf="editUserMasterForm.get('DOB').hasError('required') && editUserMasterForm.get('DOB').touched"
                                class="form-control-error-custom">Date of Birth is required</span>
                        </div>
                    </div>
                </div> 

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Experience:
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group">
                              <input placeholder="Years" type="text" class="form-control" maxlength="2"
                                  formControlName="Experience" (keydown)="onlyNumber($event)"
                                  (input)="restrictFirstDigitForZero($event)"
                                  (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('Experience').hasError('required') && editUserMasterForm.get('Experience').touched"
                                  class="form-control-error-custom">
                                  Experience is required
                              </div>
                          </div>
                      </div> 
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0"> Area of expertise:
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group">
                              <input placeholder="Area of Expertise" type="text" class="form-control"
                                  formControlName="AreaofExpertise" (keyup)="activateUpdate()">
                              <div *ngIf="editUserMasterForm.get('AreaofExpertise').hasError('required') && editUserMasterForm.get('AreaofExpertise').touched"
                                  class="form-control-error-custom">
                                  Area of Expertise is required
                              </div>
                              <div *ngIf="editUserMasterForm.get('AreaofExpertise').hasError('pattern')"
                                  class="form-control-error-custom">
                                  Please enter valid Area of Expertise
                              </div>
                              <div *ngIf="!editUserMasterForm.get('AreaofExpertise').hasError('pattern') && editUserMasterForm.get('AreaofExpertise').hasError('minlength')"
                                  class="form-control-error-custom">
                                  Minimum 5 character required.
                              </div>
                              <div *ngIf="!editUserMasterForm.get('AreaofExpertise').hasError('pattern') && editUserMasterForm.get('AreaofExpertise').hasError('maxlength')"
                                  class="form-control-error-custom">
                                  Maximum 500 character required.
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">Role:
                              <span _ngcontent-c2="" >*</span>
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <div class="form-group mb-4" style="box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -webkit-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;
                          -moz-box-shadow: 1px 1px 4px 0px rgb(220 220 220)!important;max-height: 32px;width: 100%;display: inline-table;">
                          
                              <input *ngIf="(isGuestSuperAdmin && shouldDisable) || shouldDisable" type="text" class="form-control"
                               [ngModel]="roleName" formControlName="RoleName">  
                                   
                              <ng-select [multiple]="true" *ngIf="!shouldDisable"  [disabled]="disabledRoleControl||null"
                                  [items]="roleList"  bindLabel="label"  bindValue="_id" [ngModel]="roleName"
                                  [formControl]="editUserMasterForm.controls['RoleName']" 
                                  (change)="onSelectRole($event)" (deselected)="multipleDeselected($event)">
                              </ng-select> 
                          
                              <div *ngIf="editUserMasterForm.get('RoleName').hasError('required') && editUserMasterForm.get('RoleName').touched"
                                  class="form-control-error-custom">
                                  Please select user role.
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0">DIN
                              <span _ngcontent-c2="" 
                                  *ngIf="editUserMasterForm.get('TypeOfUser').value == 'BoardMember'">*</span>
                          </label>
                      </div>
                      <div class="col-sm-12">
                          <input placeholder="DIN" type="text" class="form-control upperCase" formControlName="DIN"
                              (focus)="handleDINError=false" (focusout)="onChangeDIN();" (keyup)="activateUpdate()">
                          <div *ngIf="handleDINError" class="form-control-error-custom">
                              DIN is required
                          </div>
                          <div *ngIf="editUserMasterForm.get('DIN').hasError('pattern') && !editUserMasterForm.get('DIN').hasError('required')"
                              class="form-control-error-custom">
                              Please enter valid DIN number
                          </div>
                          <div *ngIf="isDINExists && !editUserMasterForm.get('DIN').hasError('pattern') && !editUserMasterForm.get('DIN').hasError('required')"
                              class="form-control-error-custom">
                              DIN number already exists
                          </div>
                      </div>
                  </div>
                   <!-- Branch Name and Department Name Add -->
<div class="row">
  <div class="col-sm-12">
      <label class="form-control-label" for="l0">Department Name
      </label>
  </div>
  <div class="col-sm-12">
        <ng-select [items]="departmentList" formControlName="DepartmentName" bindLabel="label" bindValue="value"
          (selected)="onSelectDepartmebt($event)">
        </ng-select>
  </div>

  <div class="col-sm-12">
      <label class="form-control-label" for="l0">Branch Name
      </label>
  </div>
  <div class="col-sm-12">
        <ng-select [items]="branchList" formControlName="BranchName" bindLabel="label" bindValue="value" 
            (selected)="onSelectBranch($event)">
        </ng-select>

  </div>
</div>
                  <div class="row">
                      <div class="col-sm-12">
                          <label class="form-control-label" for="l0"
                          style="margin: 15px 0;display: block;">Notification Type
                          </label>
                          <ul class="notification">
                              <li>
                                  <input type="checkbox" formControlName="SMSNotification"
                                  (change)="notificationCheckboxChangeEvent($event, 'SMSNotification')" 
                                  name="notification" value="1" id="notification1" autocomplete="off"  (click)="activateUpdate()">
                                  <label for="notification1">SMS</label>
                              </li>
                              <li>
                                  <input type="checkbox" formControlName="EmailNotification"
                                  (change)="notificationCheckboxChangeEvent($event,'EmailNotification')"
                                  name="notification" value="2" id="notification2" autocomplete="off"  (click)="activateUpdate()">
                                  <label for="notification2">Email</label>
                              </li>
                              <li>
                                  <input type="checkbox" formControlName="BellNotification"
                                  (change)="notificationCheckboxChangeEvent($event,'BellNotification')"
                                  name="notification" value="3" id="notification3" autocomplete="off"  (click)="activateUpdate()">
                                  <label for="notification3">Bell</label>
                              </li>
                          </ul>
                      </div>

                      <div class="col-sm-12" *ngIf="isAdminUser">
                          <label class="form-control-label" for="l0"
                          style="margin: 15px 0;display: block;">Login Access
                          </label>
                          <ul class="notification">
                              <li>
                                  <input type="checkbox" formControlName="IPADNotification"
                                  (change)="loginAccessCheckboxChangeEvent($event, 'IPADNotification')" 
                                  name="notification" value="4" id="notification4" autocomplete="off"  (click)="activateUpdate()">
                                  <label for="notification4">Ipad</label>
                              </li>
                              <li>
                                  <input type="checkbox" formControlName="BrowserNotification"
                                  (change)="loginAccessCheckboxChangeEvent($event,'BrowserNotification')"
                                  name="notification" value="5" id="notification5" autocomplete="off"  (click)="activateUpdate()">
                                  <label for="notification5">Browser</label>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <!-- uplaod div pop up -->
          <div class="modal fade" data-backdrop="static" id="uploadProfilePopUp" tabindex="-1" role="dialog"
              aria-labelledby="" aria-hidden="true" style="top: 30px;">
              <div class="modal-dialog" role="document">
                  <div class="modal-content" style="border: 1px solid rgba(0,0,0,.2);">
                      <div id="alertTitle" class="modal-header"
                          style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
                          Select profile photo
                          <button type="button" class="close" (click)="cancelProfilePhotoModel()">&times;</button>
                      </div>
                      <div class="modal-body" style="padding: 10px 15px;">
                          <div>
                              <div>

                                  <div class="file-upload">
                                      <input id="custom-input" type="file" (change)="fileChangeListener($event)"
                                          accept="image/gif, image/jpeg, image/png" (click)="activateUpdate()">
                                  </div>
                                  <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
                                  <br>
                                  <img src="" id="selected_image" style="display: none;">
                                  <span class="result rounded" *ngIf="data.image">
                                      <img [src]="data.image" [width]="cropperSettings.croppedWidth"
                                          [height]="cropperSettings.croppedHeight" alt="GovEVA">
                                  </span>

                              </div>

                          </div>
                      </div>
                      <div class="modal-footer">
                          <button class="btn btn-primary btn-sm" [disabled]="isInvalidImage"
                              (click)="setProfilePhoto()">Set as profile photo</button>
                          <button class="btn btn-sm" (click)="cancelProfilePhotoModel()">Cancel</button>
                      </div>
                  </div>
              </div>
          </div>
      </fieldset>
  </div>
  <div class="modal-footer">
      <div style="width:100%; text-align: left;">
          <span style="color: #ff0000">*</span> Indicates Mandatory field
      </div>

      <button *ngIf="!isAuthorise && isVisibleForChecker" class="btn btn-primary btn-sm" (click)="saveData()"
      id="updateUser" disabled >Update
      </button>

      <button *ngIf="!isAuthorise && isVisibleForChecker" class="btn btn-primary btn-sm"
          [disabled]="!editUserMasterForm.valid || isADIdExists || !user.Roles.length||isPANExists||isDINExists||isProceed"
          (click)="edit()">Authorize</button>
      <button *ngIf="!isAuthorise && !isVisibleForChecker" class="btn btn-primary btn-sm"
          [disabled]="!editUserMasterForm.valid || isADIdExists || !user.Roles.length||isPANExists||isDINExists||isProceed"
          (click)="edit()">Send for Approval</button>
      <button *ngIf="isAuthorise" class="btn btn-primary btn-sm"
          [disabled]="!editUserMasterForm.valid || isADIdExists || !user.Roles.length||isPANExists||isDINExists||isProceed"
          (click)="edit()">Save</button>

      <button class="btn btn-primary btn-sm"
          [disabled]="!editUserMasterForm.valid || !user.Roles.length||isPANExists||isDINExists||isProceed"
          (click)="redirectToPSS()"
          *ngIf="editUserMasterForm.get('TypeOfUser').value == 'KMP'  && (!isUPSIConfig || isPSSConfig)  && ((isAuthorise && isVisibleForChecker) || (!isVisibleForChecker))">Add
          Details</button>
      <button class="btn btn-primary btn-sm"
          [disabled]="!editUserMasterForm.valid || !user.Roles.length||isPANExists||isDINExists||isProceed"
          (click)="redirectToPSS()"
          *ngIf="editUserMasterForm.get('TypeOfUser').value == 'BoardMember'  && (!isUPSIConfig || isPSSConfig)  && ((isAuthorise && isVisibleForChecker) || (!isVisibleForChecker))">Add
          Details</button>
      <button class="btn btn-sm" (click)="cancel()">Cancel</button>
  </div>
</form>