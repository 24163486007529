<cat-menu-right></cat-menu-right>
<cat-menu-left id="leftMenu" [changedUser]="userInfo"></cat-menu-left>
<cat-top-bar
  [notificationData]="notificationData"
  [associatedCompanyList]="associatedCompanyList"
  [companyLogo]="companyLogo"
  (changeCompany)="CompanyChange($event)"
></cat-top-bar>

<div class="cat__content" id="maincontent">
  <router-outlet
    name="structure-outlet"
    (activate)="changeOfRoutes()"
  ></router-outlet>
  <cat-footer></cat-footer>
</div>

<div
  class="modal fade"
  id="messagePopUp"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  style="top: 50px"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div
        id="alertTitle"
        class="modal-header"
        style="font-size: 20px; font-weight: bold; padding: 5px 15px"
      ></div>
      <div class="modal-body" style="padding: 10px 15px">
        <div id="alertMessage" style="font-size: 16px"></div>
      </div>
    </div>
  </div>
</div>
