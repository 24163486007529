<!-- START: pages/login-beta -->
<div class="cat__pages__login" style="background-image: url(assets/modules/pages/common/img/login/1.jpg)">
    <div class="cat__pages__login__header">
        <div class="row">
            <div class="col-lg-8">
                <div class="cat__pages__login__header__logo">
                    <a href="javascript: history.back();">
                        <img src="assets/modules/pages/common/img/logo-inverse.png" alt="Clean UI Admin Template" />
                    </a>
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 switch-to-fullscreen">Switch Fullscreen / Windowed</a>
                    <a href="javascript: void(0);" class="btn btn-sm btn-outline ml-3 random-bg-image" data-img="1">Randomize Background Image</a>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cat__pages__login__header__menu">
                    <ul class="list-unstyled list-inline">
                        <li class="list-inline-item"><a href="javascript: history.back();">&larr; Back</a></li>
                        <li class="list-inline-item active"><a href="javascript: void(0);">Login</a></li>
                        <li class="list-inline-item"><a href="javascript: void(0);">About</a></li>
                        <li class="list-inline-item"><a href="javascript: void(0);">Support</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__block cat__pages__login__block--extended">
        <div class="row">
            <div class="col-xl-12">
                <div class="cat__pages__login__block__inner">
                    <div class="cat__pages__login__block__form">
                        <h4 class="text-uppercase">
                            <strong>Please log in</strong>
                        </h4>
                        <br />
                        <form id="form-validation" name="form-validation" method="POST">
                            <div class="form-group">
                                <label class="form-label">Username</label>
                                <input id="validation-email"
                                       class="form-control"
                                       placeholder="Email or Username"
                                       name="validation[email]"
                                       type="text"
                                       data-validation="[EMAIL]">
                            </div>
                            <div class="form-group">
                                <label class="form-label">Password</label>
                                <input id="validation-password"
                                       class="form-control password"
                                       name="validation[password]"
                                       type="password" data-validation="[L>=6]"
                                       data-validation-message="$ must be at least 6 characters"
                                       placeholder="Password">
                            </div>
                            <div class="form-group">
                                <a href="javascript: void(0);" class="pull-right cat__core__link--blue cat__core__link--underlined">Forgot Password?</a>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" name="example6" checked>
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div class="form-actions">
                                <button type="submit" class="btn btn-primary mr-3">Sign In</button>
                                <span class="register-link">
                                    <a href="" class="cat__core__link--blue cat__core__link--underlined">Register</a> if you don't have account
                                </span>
                            </div>
                            <div class="form-group">
                                <span>
                                    Use another service to Log In
                                </span>
                                <div class="mt-2">
                                    <a href="javascript: void(0);" class="btn btn-icon">
                                        <i class="icmn-facebook"></i>
                                    </a>
                                    <a href="javascript: void(0);" class="btn btn-icon">
                                        <i class="icmn-google"></i>
                                    </a>
                                    <a href="javascript: void(0);" class="btn btn-icon">
                                        <i class="icmn-windows"></i>
                                    </a>
                                    <a href="javascript: void(0);" class="btn btn-icon">
                                        <i class="icmn-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="cat__pages__login__block__sidebar">
                        <h4 class="cat__pages__login__block__sidebar__title">
                            <strong>Agile Dev Meetup</strong>
                            <br />
                            <span>2017</span>
                        </h4>
                        <div class="cat__pages__login__block__sidebar__item">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the 1500s.
                        </div>
                        <div class="cat__pages__login__block__sidebar__item">
                            Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </div>
                        <div class="cat__pages__login__block__sidebar__place">
                            <i class="icmn-location mr-3"><!-- --></i>
                            New York, USA
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cat__pages__login__footer text-center">
        <ul class="list-unstyled list-inline">
            <li class="list-inline-item"><a href="javascript: void(0);">Terms of Use</a></li>
            <li class="active list-inline-item"><a href="javascript: void(0);">Compliance</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Confidential Information</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Support</a></li>
            <li class="list-inline-item"><a href="javascript: void(0);">Contacts</a></li>
        </ul>
    </div>
</div>
<!-- END: pages/login-beta -->
