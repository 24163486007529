import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { CommonFunction } from '../../app/helpers/commonFunction';

@Injectable()
export class ChartsService {

  constructor(private http: HttpClient) { }

  environmentData() {
    return from(
        fetch('assets/chartConfigs/environmentData.json').then(function(response) {
          return response.json();
        })
      ).toPromise();
  }

  governanceData() {
    return from(
        fetch('assets/chartConfigs/governanceData.json').then(function(response) {
          return response.json();
        })
      ).toPromise();
  }

  socialData() {
    return from(
        fetch('assets/chartConfigs/socialData.json').then(function(response) {
          return response.json();
        })
      ).toPromise();
  }

  emissionCalculationData() { 
    /*return from(
        fetch('assets/chartConfigs/emissionCalculationData.json').then(function(response) {
          return response.json();
        })
      ).toPromise();*/
    //return this.http.get(environment.clientUrl + 'emission/listEmissionDetails',)
    return this.http.get(environment.clientUrl + 'emission/getAllEmisionChartData',)
          .toPromise()
          .then(response => {
            return Promise.resolve(response); 
          })
  }

}
