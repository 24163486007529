import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-board-eval-static',
  templateUrl: './board-eval-static.component.html',
  styleUrls: ['./board-eval-static.component.css'],
 
})
export class BoardEvalStaticComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit() {
  }


  cancel() {
    // localStorage.setItem('openBoardEvalPopup', 'true')
    this.activeModal.dismiss('dismissed')
  }

}
