import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class BoardEvaluationService {

    constructor(private http: HttpClient) {

    }

    getQuestionMasterBoardEvalList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationQuestion')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getQuestionByQuestionID(questionID): Promise<any> {
        return this.http.get(environment.clientUrl + 'companyMaster/companyData')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationTypeList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationTypeList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationCriteriaByEvalIdList(EvaluationTypeId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvalCriteriaListByEvalTypeID/' + EvaluationTypeId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationQuestionsByCriteriaIdList(EvaluationCriteriaID): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationQuestionByCriteriaId/' + EvaluationCriteriaID)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    updateEvaluationFormEndDate(dateObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateEvaluationFormEndDate', (dateObject))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }
    
    //board Entity Evaluation Forms
    getEvaluationFormDetailList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationFormList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    sendForReviewEvalutionQuestionnaire(reviewObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/sendForReview', (reviewObject))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    addQuestionDetails(newQuestion): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'evaluationMaster/addEvaluationQuestion', (newQuestion))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    getQuestionListByEvaluationTypeIdAndEvaultionCriteriaID(EvaluationTypeId, EvaluationCriteriaID): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationQuestion/' + EvaluationTypeId + '/' + EvaluationCriteriaID)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    updateEvaluationQuestionDetails(questionObject): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateEvaluationQuestion', (questionObject))
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    deleteQuestionDetails(questionId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/deleteQueestionDetails?questionID=' + questionId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    saveBoardEntityForm(entityFormObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/createEvaluationSetupForm', entityFormObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationFormList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationFormList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getEvaluationCriteriaList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationCriteriaList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }



    getListOfEvaluationFormsCreatedByUserId(userId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getEvaluationFormListToAssignBycreatedById/' + userId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    // to get Reviewers Details By FormId
   
    getReviewersDetailsEvaluationForm(formId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getReviewersDetailsEvaluationForm/' + formId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    assignEvaluationFormToAssingee(assigneeObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/assignEvaluationForm', assigneeObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    

    getAssignedFormsByuserId(userId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/assignEvaluationFormByUserId/' + userId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    reviewApproved(entityFormObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/approveReviewStatusEvaluationFormByUserId', entityFormObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    updateEvaluationForm(formObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateEvaluationSetupForm', formObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }
    
    // updateEvaluationFormEndDate(formObject): Promise<any> {
    //     return this.http.put(environment.clientUrl + 'evaluationMaster/updateEvaluationFormEndDate', formObject)
    //         .toPromise()
    //         .then(response => {
    //             return Promise.resolve(response);
    //         })
    // }

    getListOfAssigneeForm(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getAssignedEvaluationForms')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }
    
    getListOfAssigneeForMonitoring(): Promise<any> {
        
        return this.http.get(environment.clientUrl + 'evaluationMaster/getQuestionnaireForMonitoring')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getListOfAssigneeStatusFormByPeriodParameter(period): Promise<any> {
        var periodObject = {
            'periodParameter' : period
        }
        return this.http.post(environment.clientUrl + 'evaluationMaster/getQuestionnaireForMonitoringByPeriodParamter',periodObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    updateEvaluationMonitoringAssignee(assigneeObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateAssignedId', assigneeObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getViewSubmittedQuestionList(assignedFormId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/viewAssignedEvaluationFormDetails/' + assignedFormId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getIndividualReport(evalObject): Promise<any> {
        // var evalObject = {
        //     "EvaluationFormId": "5c40336ef725f207349c57ab",
        //     "EvaluationTypeID": "5c4033eff725f207349c57bb"
        // }
        return this.http.post(environment.clientUrl + 'evaluationMaster/getIndividualReport', evalObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getIndividualReportList(userId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getReportsListByAssesseeId/' + userId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }



    updateReviewerComments(questionObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateReviewerComment', questionObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getFormDetailsByFormId(formId): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getReviewersDetailsEvaluationForm/' + formId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    // to created assignment of Evaluation forms in assigneeStatus
    createAssigneeStatus(AssesseeObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/AssigneeStatus' , AssesseeObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }
    
    updateAssigneeStatus(AssesseeObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/UpdateAssigneeStatus' , AssesseeObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    publishMonitoringScreen(publishObject): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/publishEvaluation' , publishObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    getPeriodParameterList(): Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/getPeriodParameterList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    getSummaryReportDetails(periodParameter): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/generateSummaryReport',{"periodParameter" : periodParameter})
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }
 
    getStatusOfAssesorsList(object): Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/statusOfAssesors',object)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

     updateReportsToGenerate(object) :  Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/updateReportsToGenerate',object)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    // to get evaluation assignment Status form by Id
    getAssignmentStatusFormDetailsById(object) :  Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/getAssignmentStatusForm' , object)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    } 

    // to downloadReport 
    downloadReport(object):  Promise<any> {
        return this.http.post(environment.clientUrl + 'evaluationMaster/downloadReport' , object)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    } 

    // to delete questionnaire
    deleteQuestionnaireById(evaluationFormId):  Promise<any> {
        return this.http.get(environment.clientUrl + 'evaluationMaster/deleteQuestionnaire/'+ evaluationFormId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    } 

}