<!-- START: components/loading-progress -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Nprogress</strong>
            <a href="http://ricostacruz.com/nprogress/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <h5 class="text-black"><strong>Page Loading Progress Bar</strong></h5>
        <p class="text-muted">Element: read <a href="http://ricostacruz.com/nprogress/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
        <div class="mb-5">
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.start();">
                <i class="icmn-play4 margin-right-5"></i>
                NProgress Start
                &nbsp;&nbsp;
            </button>
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.set(0.4);">
                <i class="icmn-pencil5 margin-right-5"></i>
                NProgress Set 40%
                &nbsp;&nbsp;
            </button>
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.inc();">
                <i class="icmn-plus3 margin-right-5"></i>
                NProgress Increment
                &nbsp;&nbsp;
            </button>
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.done();">
                <i class="icmn-stop2 margin-right-5"></i>
                NProgress Done
                &nbsp;&nbsp;
            </button>
            <br />
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.configure({ showSpinner: true });">
                Show Spinner (needs to restart)
                &nbsp;&nbsp;
            </button>
            <button type="button" class="btn btn-default mr-2 mb-2" data-toggle="modal" (click)="NProgress.configure({ showSpinner: false });">
                Hide Spinner (needs to restart)
                &nbsp;&nbsp;
            </button>
        </div>
    </div>
</section>
<!-- END: components/loading-progress -->