<div class="modal fade" id="orgChartModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document" style="max-width: 1200px">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="p-0" style="height: max-content; overflow: auto">
          <div class="nav-tabs-horizontal">
            <ul class="nav nav-tabs tab-custom-one" role="tablist">
              <li
                class="nav-item"
                [appHasPermission]="{
                  Url: 'organizationChartBoardMemeber',
                  method: 'get',
                  menu: 'DashboardOrgChart'
                }"
              >
                <a
                  class="nav-link active"
                  data-target="#directorTab"
                  data-toggle="tab"
                  href="javascript: void(0);"
                  role="tab"
                  aria-expanded="true"
                  (click)="OrgChartId('directorChart')"
                  >Director
                  <!--Organization Chart-->
                </a>
              </li>
              <li
                class="nav-item"
                [appHasPermission]="{
                  Url: 'organizationChart-ExecutiveManagement',
                  method: 'get',
                  menu: 'DashboardOrgChart'
                }"
              >
                <a
                  class="nav-link"
                  id="managementTabLink"
                  data-target="#managementTab"
                  data-toggle="tab"
                  href="javascript: void(0);"
                  role="tab"
                  aria-expanded="false"
                  (click)="OrgChartId('managementChart')"
                  >Management
                  <!--Organization Chart-->
                </a>
              </li>
            </ul>

            <div
              class="tab-content chart-img-radius"
              style="min-height: 400px; display: inline-block; width: 100%"
            >
              <div
                class="tab-pane active"
                id="directorTab"
                role="tabcard"
                aria-expanded="true"
                [appHasPermission]="{
                  Url: 'organizationChartBoardMemeber',
                  method: 'get',
                  menu: 'DashboardOrgChart'
                }"
              >
                <div id="directorChart"></div>
              </div>

              <div
                id="managementTab"
                role="tabcard"
                aria-expanded="true"
                class="tab-pane"
                [appHasPermission]="{
                  Url: 'organizationChart-ExecutiveManagement',
                  method: 'get',
                  menu: 'DashboardOrgChart'
                }"
              >
                <div id="managementChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
