<!-- START: icons/icomoon-free -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Icomoon Free</strong>
            <a href="https://icomoon.io/#icons-icomoon" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-xl-6 offset-xl-3">
                <ul class="cat__core__icons-block list-unstyled">
                    <li><i class="icmn-home"></i></li>
                    <li><i class="icmn-home2"></i></li>
                    <li><i class="icmn-home3"></i></li>
                    <li><i class="icmn-office"></i></li>
                    <li><i class="icmn-newspaper"></i></li>
                    <li><i class="icmn-pencil"></i></li>
                    <li><i class="icmn-pencil2"></i></li>
                    <li><i class="icmn-quill"></i></li>
                    <li><i class="icmn-pen"></i></li>
                    <li><i class="icmn-blog"></i></li>
                    <li><i class="icmn-eyedropper"></i></li>
                    <li><i class="icmn-droplet"></i></li>
                    <li><i class="icmn-paint-format"></i></li>
                    <li><i class="icmn-image"></i></li>
                    <li><i class="icmn-images"></i></li>
                    <li><i class="icmn-camera"></i></li>
                    <li><i class="icmn-headphones"></i></li>
                    <li><i class="icmn-music"></i></li>
                    <li><i class="icmn-play"></i></li>
                    <li><i class="icmn-film"></i></li>
                    <li><i class="icmn-video-camera"></i></li>
                    <li><i class="icmn-dice"></i></li>
                    <li><i class="icmn-pacman"></i></li>
                    <li><i class="icmn-spades"></i></li>
                    <li><i class="icmn-clubs"></i></li>
                    <li><i class="icmn-diamonds"></i></li>
                    <li><i class="icmn-bullhorn"></i></li>
                    <li><i class="icmn-connection"></i></li>
                    <li><i class="icmn-podcast"></i></li>
                    <li><i class="icmn-feed"></i></li>
                    <li><i class="icmn-mic"></i></li>
                    <li><i class="icmn-book"></i></li>
                    <li><i class="icmn-books"></i></li>
                    <li><i class="icmn-library"></i></li>
                    <li><i class="icmn-file-text"></i></li>
                    <li><i class="icmn-profile"></i></li>
                    <li><i class="icmn-file-empty"></i></li>
                    <li><i class="icmn-files-empty"></i></li>
                    <li><i class="icmn-file-text2"></i></li>
                    <li><i class="icmn-file-picture"></i></li>
                    <li><i class="icmn-file-music"></i></li>
                    <li><i class="icmn-file-play"></i></li>
                    <li><i class="icmn-file-video"></i></li>
                    <li><i class="icmn-file-zip"></i></li>
                    <li><i class="icmn-copy"></i></li>
                    <li><i class="icmn-paste"></i></li>
                    <li><i class="icmn-stack"></i></li>
                    <li><i class="icmn-folder"></i></li>
                    <li><i class="icmn-folder-open"></i></li>
                    <li><i class="icmn-folder-plus"></i></li>
                    <li><i class="icmn-folder-minus"></i></li>
                    <li><i class="icmn-folder-download"></i></li>
                    <li><i class="icmn-folder-upload"></i></li>
                    <li><i class="icmn-price-tag"></i></li>
                    <li><i class="icmn-price-tags"></i></li>
                    <li><i class="icmn-barcode"></i></li>
                    <li><i class="icmn-qrcode"></i></li>
                    <li><i class="icmn-ticket"></i></li>
                    <li><i class="icmn-cart"></i></li>
                    <li><i class="icmn-coin-dollar"></i></li>
                    <li><i class="icmn-coin-euro"></i></li>
                    <li><i class="icmn-coin-pound"></i></li>
                    <li><i class="icmn-coin-yen"></i></li>
                    <li><i class="icmn-credit-card"></i></li>
                    <li><i class="icmn-calculator"></i></li>
                    <li><i class="icmn-lifebuoy"></i></li>
                    <li><i class="icmn-phone"></i></li>
                    <li><i class="icmn-phone-hang-up"></i></li>
                    <li><i class="icmn-address-book"></i></li>
                    <li><i class="icmn-envelop"></i></li>
                    <li><i class="icmn-pushpin"></i></li>
                    <li><i class="icmn-location"></i></li>
                    <li><i class="icmn-location2"></i></li>
                    <li><i class="icmn-compass"></i></li>
                    <li><i class="icmn-compass2"></i></li>
                    <li><i class="icmn-map"></i></li>
                    <li><i class="icmn-map2"></i></li>
                    <li><i class="icmn-history"></i></li>
                    <li><i class="icmn-clock"></i></li>
                    <li><i class="icmn-clock2"></i></li>
                    <li><i class="icmn-alarm"></i></li>
                    <li><i class="icmn-bell"></i></li>
                    <li><i class="icmn-stopwatch"></i></li>
                    <li><i class="icmn-calendar"></i></li>
                    <li><i class="icmn-printer"></i></li>
                    <li><i class="icmn-keyboard"></i></li>
                    <li><i class="icmn-display"></i></li>
                    <li><i class="icmn-laptop"></i></li>
                    <li><i class="icmn-mobile"></i></li>
                    <li><i class="icmn-mobile2"></i></li>
                    <li><i class="icmn-tablet"></i></li>
                    <li><i class="icmn-tv"></i></li>
                    <li><i class="icmn-drawer"></i></li>
                    <li><i class="icmn-drawer2"></i></li>
                    <li><i class="icmn-box-add"></i></li>
                    <li><i class="icmn-box-remove"></i></li>
                    <li><i class="icmn-download"></i></li>
                    <li><i class="icmn-upload"></i></li>
                    <li><i class="icmn-floppy-disk"></i></li>
                    <li><i class="icmn-drive"></i></li>
                    <li><i class="icmn-database"></i></li>
                    <li><i class="icmn-undo"></i></li>
                    <li><i class="icmn-redo"></i></li>
                    <li><i class="icmn-undo2"></i></li>
                    <li><i class="icmn-redo2"></i></li>
                    <li><i class="icmn-forward"></i></li>
                    <li><i class="icmn-reply"></i></li>
                    <li><i class="icmn-bubble"></i></li>
                    <li><i class="icmn-bubbles"></i></li>
                    <li><i class="icmn-bubbles2"></i></li>
                    <li><i class="icmn-bubble2"></i></li>
                    <li><i class="icmn-bubbles3"></i></li>
                    <li><i class="icmn-bubbles4"></i></li>
                    <li><i class="icmn-user"></i></li>
                    <li><i class="icmn-users"></i></li>
                    <li><i class="icmn-user-plus"></i></li>
                    <li><i class="icmn-user-minus"></i></li>
                    <li><i class="icmn-user-check"></i></li>
                    <li><i class="icmn-user-tie"></i></li>
                    <li><i class="icmn-quotes-left"></i></li>
                    <li><i class="icmn-quotes-right"></i></li>
                    <li><i class="icmn-hour-glass"></i></li>
                    <li><i class="icmn-spinner"></i></li>
                    <li><i class="icmn-spinner2"></i></li>
                    <li><i class="icmn-spinner3"></i></li>
                    <li><i class="icmn-spinner4"></i></li>
                    <li><i class="icmn-spinner5"></i></li>
                    <li><i class="icmn-spinner6"></i></li>
                    <li><i class="icmn-spinner7"></i></li>
                    <li><i class="icmn-spinner8"></i></li>
                    <li><i class="icmn-spinner9"></i></li>
                    <li><i class="icmn-spinner10"></i></li>
                    <li><i class="icmn-spinner11"></i></li>
                    <li><i class="icmn-binoculars"></i></li>
                    <li><i class="icmn-search"></i></li>
                    <li><i class="icmn-zoom-in"></i></li>
                    <li><i class="icmn-zoom-out"></i></li>
                    <li><i class="icmn-enlarge"></i></li>
                    <li><i class="icmn-shrink"></i></li>
                    <li><i class="icmn-enlarge2"></i></li>
                    <li><i class="icmn-shrink2"></i></li>
                    <li><i class="icmn-key"></i></li>
                    <li><i class="icmn-key2"></i></li>
                    <li><i class="icmn-lock"></i></li>
                    <li><i class="icmn-unlocked"></i></li>
                    <li><i class="icmn-wrench"></i></li>
                    <li><i class="icmn-equalizer"></i></li>
                    <li><i class="icmn-equalizer2"></i></li>
                    <li><i class="icmn-cog"></i></li>
                    <li><i class="icmn-cogs"></i></li>
                    <li><i class="icmn-hammer"></i></li>
                    <li><i class="icmn-magic-wand"></i></li>
                    <li><i class="icmn-aid-kit"></i></li>
                    <li><i class="icmn-bug"></i></li>
                    <li><i class="icmn-pie-chart"></i></li>
                    <li><i class="icmn-stats-dots"></i></li>
                    <li><i class="icmn-stats-bars"></i></li>
                    <li><i class="icmn-stats-bars2"></i></li>
                    <li><i class="icmn-trophy"></i></li>
                    <li><i class="icmn-gift"></i></li>
                    <li><i class="icmn-glass"></i></li>
                    <li><i class="icmn-glass2"></i></li>
                    <li><i class="icmn-mug"></i></li>
                    <li><i class="icmn-spoon-knife"></i></li>
                    <li><i class="icmn-leaf"></i></li>
                    <li><i class="icmn-rocket"></i></li>
                    <li><i class="icmn-meter"></i></li>
                    <li><i class="icmn-meter2"></i></li>
                    <li><i class="icmn-hammer2"></i></li>
                    <li><i class="icmn-fire"></i></li>
                    <li><i class="icmn-lab"></i></li>
                    <li><i class="icmn-magnet"></i></li>
                    <li><i class="icmn-bin"></i></li>
                    <li><i class="icmn-bin2"></i></li>
                    <li><i class="icmn-briefcase"></i></li>
                    <li><i class="icmn-airplane"></i></li>
                    <li><i class="icmn-truck"></i></li>
                    <li><i class="icmn-road"></i></li>
                    <li><i class="icmn-accessibility"></i></li>
                    <li><i class="icmn-target"></i></li>
                    <li><i class="icmn-shield"></i></li>
                    <li><i class="icmn-power"></i></li>
                    <li><i class="icmn-switch"></i></li>
                    <li><i class="icmn-power-cord"></i></li>
                    <li><i class="icmn-clipboard"></i></li>
                    <li><i class="icmn-list-numbered"></i></li>
                    <li><i class="icmn-list"></i></li>
                    <li><i class="icmn-list2"></i></li>
                    <li><i class="icmn-tree"></i></li>
                    <li><i class="icmn-menu"></i></li>
                    <li><i class="icmn-menu2"></i></li>
                    <li><i class="icmn-menu3"></i></li>
                    <li><i class="icmn-menu4"></i></li>
                    <li><i class="icmn-cloud"></i></li>
                    <li><i class="icmn-cloud-download"></i></li>
                    <li><i class="icmn-cloud-upload"></i></li>
                    <li><i class="icmn-cloud-check"></i></li>
                    <li><i class="icmn-download2"></i></li>
                    <li><i class="icmn-upload2"></i></li>
                    <li><i class="icmn-download3"></i></li>
                    <li><i class="icmn-upload3"></i></li>
                    <li><i class="icmn-sphere"></i></li>
                    <li><i class="icmn-earth"></i></li>
                    <li><i class="icmn-link"></i></li>
                    <li><i class="icmn-flag"></i></li>
                    <li><i class="icmn-attachment"></i></li>
                    <li><i class="icmn-eye"></i></li>
                    <li><i class="icmn-eye-plus"></i></li>
                    <li><i class="icmn-eye-minus"></i></li>
                    <li><i class="icmn-eye-blocked"></i></li>
                    <li><i class="icmn-bookmark"></i></li>
                    <li><i class="icmn-bookmarks"></i></li>
                    <li><i class="icmn-sun"></i></li>
                    <li><i class="icmn-contrast"></i></li>
                    <li><i class="icmn-brightness-contrast"></i></li>
                    <li><i class="icmn-star-empty"></i></li>
                    <li><i class="icmn-star-half"></i></li>
                    <li><i class="icmn-star-full"></i></li>
                    <li><i class="icmn-heart"></i></li>
                    <li><i class="icmn-heart-broken"></i></li>
                    <li><i class="icmn-man"></i></li>
                    <li><i class="icmn-woman"></i></li>
                    <li><i class="icmn-man-woman"></i></li>
                    <li><i class="icmn-happy"></i></li>
                    <li><i class="icmn-happy2"></i></li>
                    <li><i class="icmn-smile"></i></li>
                    <li><i class="icmn-smile2"></i></li>
                    <li><i class="icmn-tongue"></i></li>
                    <li><i class="icmn-tongue2"></i></li>
                    <li><i class="icmn-sad"></i></li>
                    <li><i class="icmn-sad2"></i></li>
                    <li><i class="icmn-wink"></i></li>
                    <li><i class="icmn-wink2"></i></li>
                    <li><i class="icmn-grin"></i></li>
                    <li><i class="icmn-grin2"></i></li>
                    <li><i class="icmn-cool"></i></li>
                    <li><i class="icmn-cool2"></i></li>
                    <li><i class="icmn-angry"></i></li>
                    <li><i class="icmn-angry2"></i></li>
                    <li><i class="icmn-evil"></i></li>
                    <li><i class="icmn-evil2"></i></li>
                    <li><i class="icmn-shocked"></i></li>
                    <li><i class="icmn-shocked2"></i></li>
                    <li><i class="icmn-baffled"></i></li>
                    <li><i class="icmn-baffled2"></i></li>
                    <li><i class="icmn-confused"></i></li>
                    <li><i class="icmn-confused2"></i></li>
                    <li><i class="icmn-neutral"></i></li>
                    <li><i class="icmn-neutral2"></i></li>
                    <li><i class="icmn-hipster"></i></li>
                    <li><i class="icmn-hipster2"></i></li>
                    <li><i class="icmn-wondering"></i></li>
                    <li><i class="icmn-wondering2"></i></li>
                    <li><i class="icmn-sleepy"></i></li>
                    <li><i class="icmn-sleepy2"></i></li>
                    <li><i class="icmn-frustrated"></i></li>
                    <li><i class="icmn-frustrated2"></i></li>
                    <li><i class="icmn-crying"></i></li>
                    <li><i class="icmn-crying2"></i></li>
                    <li><i class="icmn-point-up"></i></li>
                    <li><i class="icmn-point-right"></i></li>
                    <li><i class="icmn-point-down"></i></li>
                    <li><i class="icmn-point-left"></i></li>
                    <li><i class="icmn-warning"></i></li>
                    <li><i class="icmn-notification"></i></li>
                    <li><i class="icmn-question"></i></li>
                    <li><i class="icmn-plus"></i></li>
                    <li><i class="icmn-minus"></i></li>
                    <li><i class="icmn-info"></i></li>
                    <li><i class="icmn-cancel-circle"></i></li>
                    <li><i class="icmn-blocked"></i></li>
                    <li><i class="icmn-cross"></i></li>
                    <li><i class="icmn-checkmark"></i></li>
                    <li><i class="icmn-checkmark2"></i></li>
                    <li><i class="icmn-spell-check"></i></li>
                    <li><i class="icmn-enter"></i></li>
                    <li><i class="icmn-exit"></i></li>
                    <li><i class="icmn-play2"></i></li>
                    <li><i class="icmn-pause"></i></li>
                    <li><i class="icmn-stop"></i></li>
                    <li><i class="icmn-previous"></i></li>
                    <li><i class="icmn-next"></i></li>
                    <li><i class="icmn-backward"></i></li>
                    <li><i class="icmn-forward2"></i></li>
                    <li><i class="icmn-play3"></i></li>
                    <li><i class="icmn-pause2"></i></li>
                    <li><i class="icmn-stop2"></i></li>
                    <li><i class="icmn-backward2"></i></li>
                    <li><i class="icmn-forward3"></i></li>
                    <li><i class="icmn-first"></i></li>
                    <li><i class="icmn-last"></i></li>
                    <li><i class="icmn-previous2"></i></li>
                    <li><i class="icmn-next2"></i></li>
                    <li><i class="icmn-eject"></i></li>
                    <li><i class="icmn-volume-high"></i></li>
                    <li><i class="icmn-volume-medium"></i></li>
                    <li><i class="icmn-volume-low"></i></li>
                    <li><i class="icmn-volume-mute"></i></li>
                    <li><i class="icmn-volume-mute2"></i></li>
                    <li><i class="icmn-volume-increase"></i></li>
                    <li><i class="icmn-volume-decrease"></i></li>
                    <li><i class="icmn-loop"></i></li>
                    <li><i class="icmn-loop2"></i></li>
                    <li><i class="icmn-infinite"></i></li>
                    <li><i class="icmn-shuffle"></i></li>
                    <li><i class="icmn-arrow-up-left"></i></li>
                    <li><i class="icmn-arrow-up"></i></li>
                    <li><i class="icmn-arrow-up-right"></i></li>
                    <li><i class="icmn-arrow-right"></i></li>
                    <li><i class="icmn-arrow-down-right"></i></li>
                    <li><i class="icmn-arrow-down"></i></li>
                    <li><i class="icmn-arrow-down-left"></i></li>
                    <li><i class="icmn-arrow-left"></i></li>
                    <li><i class="icmn-arrow-up-left2"></i></li>
                    <li><i class="icmn-arrow-up2"></i></li>
                    <li><i class="icmn-arrow-up-right2"></i></li>
                    <li><i class="icmn-arrow-right2"></i></li>
                    <li><i class="icmn-arrow-down-right2"></i></li>
                    <li><i class="icmn-arrow-down2"></i></li>
                    <li><i class="icmn-arrow-down-left2"></i></li>
                    <li><i class="icmn-arrow-left2"></i></li>
                    <li><i class="icmn-circle-up"></i></li>
                    <li><i class="icmn-circle-right"></i></li>
                    <li><i class="icmn-circle-down"></i></li>
                    <li><i class="icmn-circle-left"></i></li>
                    <li><i class="icmn-tab"></i></li>
                    <li><i class="icmn-move-up"></i></li>
                    <li><i class="icmn-move-down"></i></li>
                    <li><i class="icmn-sort-alpha-asc"></i></li>
                    <li><i class="icmn-sort-alpha-desc"></i></li>
                    <li><i class="icmn-sort-numeric-asc"></i></li>
                    <li><i class="icmn-sort-numberic-desc"></i></li>
                    <li><i class="icmn-sort-amount-asc"></i></li>
                    <li><i class="icmn-sort-amount-desc"></i></li>
                    <li><i class="icmn-command"></i></li>
                    <li><i class="icmn-shift"></i></li>
                    <li><i class="icmn-ctrl"></i></li>
                    <li><i class="icmn-opt"></i></li>
                    <li><i class="icmn-checkbox-checked"></i></li>
                    <li><i class="icmn-checkbox-unchecked"></i></li>
                    <li><i class="icmn-radio-checked"></i></li>
                    <li><i class="icmn-radio-checked2"></i></li>
                    <li><i class="icmn-radio-unchecked"></i></li>
                    <li><i class="icmn-crop"></i></li>
                    <li><i class="icmn-make-group"></i></li>
                    <li><i class="icmn-ungroup"></i></li>
                    <li><i class="icmn-scissors"></i></li>
                    <li><i class="icmn-filter"></i></li>
                    <li><i class="icmn-font"></i></li>
                    <li><i class="icmn-ligature"></i></li>
                    <li><i class="icmn-ligature2"></i></li>
                    <li><i class="icmn-text-height"></i></li>
                    <li><i class="icmn-text-width"></i></li>
                    <li><i class="icmn-font-size"></i></li>
                    <li><i class="icmn-bold"></i></li>
                    <li><i class="icmn-underline"></i></li>
                    <li><i class="icmn-italic"></i></li>
                    <li><i class="icmn-strikethrough"></i></li>
                    <li><i class="icmn-omega"></i></li>
                    <li><i class="icmn-sigma"></i></li>
                    <li><i class="icmn-page-break"></i></li>
                    <li><i class="icmn-superscript"></i></li>
                    <li><i class="icmn-subscript"></i></li>
                    <li><i class="icmn-superscript2"></i></li>
                    <li><i class="icmn-subscript2"></i></li>
                    <li><i class="icmn-text-color"></i></li>
                    <li><i class="icmn-pagebreak"></i></li>
                    <li><i class="icmn-clear-formatting"></i></li>
                    <li><i class="icmn-table"></i></li>
                    <li><i class="icmn-table2"></i></li>
                    <li><i class="icmn-insert-template"></i></li>
                    <li><i class="icmn-pilcrow"></i></li>
                    <li><i class="icmn-ltr"></i></li>
                    <li><i class="icmn-rtl"></i></li>
                    <li><i class="icmn-section"></i></li>
                    <li><i class="icmn-paragraph-left"></i></li>
                    <li><i class="icmn-paragraph-center"></i></li>
                    <li><i class="icmn-paragraph-right"></i></li>
                    <li><i class="icmn-paragraph-justify"></i></li>
                    <li><i class="icmn-indent-increase"></i></li>
                    <li><i class="icmn-indent-decrease"></i></li>
                    <li><i class="icmn-share"></i></li>
                    <li><i class="icmn-new-tab"></i></li>
                    <li><i class="icmn-embed"></i></li>
                    <li><i class="icmn-embed2"></i></li>
                    <li><i class="icmn-terminal"></i></li>
                    <li><i class="icmn-share2"></i></li>
                    <li><i class="icmn-mail"></i></li>
                    <li><i class="icmn-mail2"></i></li>
                    <li><i class="icmn-mail3"></i></li>
                    <li><i class="icmn-mail4"></i></li>
                    <li><i class="icmn-amazon"></i></li>
                    <li><i class="icmn-google"></i></li>
                    <li><i class="icmn-google2"></i></li>
                    <li><i class="icmn-google3"></i></li>
                    <li><i class="icmn-google-plus"></i></li>
                    <li><i class="icmn-google-plus2"></i></li>
                    <li><i class="icmn-google-plus3"></i></li>
                    <li><i class="icmn-hangouts"></i></li>
                    <li><i class="icmn-google-drive"></i></li>
                    <li><i class="icmn-facebook"></i></li>
                    <li><i class="icmn-facebook2"></i></li>
                    <li><i class="icmn-instagram"></i></li>
                    <li><i class="icmn-whatsapp"></i></li>
                    <li><i class="icmn-spotify"></i></li>
                    <li><i class="icmn-telegram"></i></li>
                    <li><i class="icmn-twitter"></i></li>
                    <li><i class="icmn-vine"></i></li>
                    <li><i class="icmn-vk"></i></li>
                    <li><i class="icmn-renren"></i></li>
                    <li><i class="icmn-sina-weibo"></i></li>
                    <li><i class="icmn-rss"></i></li>
                    <li><i class="icmn-rss2"></i></li>
                    <li><i class="icmn-youtube"></i></li>
                    <li><i class="icmn-youtube2"></i></li>
                    <li><i class="icmn-twitch"></i></li>
                    <li><i class="icmn-vimeo"></i></li>
                    <li><i class="icmn-vimeo2"></i></li>
                    <li><i class="icmn-lanyrd"></i></li>
                    <li><i class="icmn-flickr"></i></li>
                    <li><i class="icmn-flickr2"></i></li>
                    <li><i class="icmn-flickr3"></i></li>
                    <li><i class="icmn-flickr4"></i></li>
                    <li><i class="icmn-dribbble"></i></li>
                    <li><i class="icmn-behance"></i></li>
                    <li><i class="icmn-behance2"></i></li>
                    <li><i class="icmn-deviantart"></i></li>
                    <li><i class="icmn-500px"></i></li>
                    <li><i class="icmn-steam"></i></li>
                    <li><i class="icmn-steam2"></i></li>
                    <li><i class="icmn-dropbox"></i></li>
                    <li><i class="icmn-onedrive"></i></li>
                    <li><i class="icmn-github"></i></li>
                    <li><i class="icmn-npm"></i></li>
                    <li><i class="icmn-basecamp"></i></li>
                    <li><i class="icmn-trello"></i></li>
                    <li><i class="icmn-wordpress"></i></li>
                    <li><i class="icmn-joomla"></i></li>
                    <li><i class="icmn-ello"></i></li>
                    <li><i class="icmn-blogger"></i></li>
                    <li><i class="icmn-blogger2"></i></li>
                    <li><i class="icmn-tumblr"></i></li>
                    <li><i class="icmn-tumblr2"></i></li>
                    <li><i class="icmn-yahoo"></i></li>
                    <li><i class="icmn-yahoo2"></i></li>
                    <li><i class="icmn-tux"></i></li>
                    <li><i class="icmn-appleinc"></i></li>
                    <li><i class="icmn-finder"></i></li>
                    <li><i class="icmn-android"></i></li>
                    <li><i class="icmn-windows"></i></li>
                    <li><i class="icmn-windows8"></i></li>
                    <li><i class="icmn-soundcloud"></i></li>
                    <li><i class="icmn-soundcloud2"></i></li>
                    <li><i class="icmn-skype"></i></li>
                    <li><i class="icmn-reddit"></i></li>
                    <li><i class="icmn-hackernews"></i></li>
                    <li><i class="icmn-wikipedia"></i></li>
                    <li><i class="icmn-linkedin"></i></li>
                    <li><i class="icmn-linkedin2"></i></li>
                    <li><i class="icmn-lastfm"></i></li>
                    <li><i class="icmn-lastfm2"></i></li>
                    <li><i class="icmn-delicious"></i></li>
                    <li><i class="icmn-stumbleupon"></i></li>
                    <li><i class="icmn-stumbleupon2"></i></li>
                    <li><i class="icmn-stackoverflow"></i></li>
                    <li><i class="icmn-pinterest"></i></li>
                    <li><i class="icmn-pinterest2"></i></li>
                    <li><i class="icmn-xing"></i></li>
                    <li><i class="icmn-xing2"></i></li>
                    <li><i class="icmn-flattr"></i></li>
                    <li><i class="icmn-foursquare"></i></li>
                    <li><i class="icmn-yelp"></i></li>
                    <li><i class="icmn-paypal"></i></li>
                    <li><i class="icmn-chrome"></i></li>
                    <li><i class="icmn-firefox"></i></li>
                    <li><i class="icmn-IE"></i></li>
                    <li><i class="icmn-edge"></i></li>
                    <li><i class="icmn-safari"></i></li>
                    <li><i class="icmn-opera"></i></li>
                    <li><i class="icmn-file-pdf"></i></li>
                    <li><i class="icmn-file-openoffice"></i></li>
                    <li><i class="icmn-file-word"></i></li>
                    <li><i class="icmn-file-excel"></i></li>
                    <li><i class="icmn-libreoffice"></i></li>
                    <li><i class="icmn-html-five"></i></li>
                    <li><i class="icmn-html-five2"></i></li>
                    <li><i class="icmn-css3"></i></li>
                    <li><i class="icmn-git"></i></li>
                    <li><i class="icmn-codepen"></i></li>
                    <li><i class="icmn-svg"></i></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- END: icons/icomoon-free -->
