<!-- START: pages/page-500 -->
<div class="text-center">
    <div class="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
        <h1 class="mb-4"><strong>Error 500</strong></h1>
        <p>Looks like something went wrong!</p>
        <p class="mb-4">We track these errors automatically, but if the problem persists feel free to contact us. In the meantime, try refreshing.</p>
        <a href="javascript: history.back();" class="btn btn-link">Go Back</a>
        <a href="javascript: void(0);" class="btn btn-link">Contact Us</a>
    </div>
</div>
<!-- END: pages/page-500 -->