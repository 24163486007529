<section class="card cat__core__sortable__collapsed" order-id="card-4">
    <div class="card-header">
        <div class="pull-right cat__core__sortable__control">
            <i class="icmn-minus mr-2 cat__core__sortable__collapse" data-original-title="Minimize" data-placement="left" data-toggle="tooltip"
                title=""></i>
            <i class="icmn-plus mr-2 cat__core__sortable__uncollapse" data-original-title="Expand" data-placement="left" data-toggle="tooltip"
                title=""></i>
        </div>
        <h5 class="mb-0 mr-3 d-inline-block">
            Company News
        </h5>
        <div class="pull-right filterDrpDwn">
            <span class="dropdown cat__top-bar__avatar-dropdown mr-4">
                <span aria-expanded="false" class="dropdown-toggle lbl-wrapper filterDrpDwn_txt" data-toggle="dropdown">
                    <span style="padding: 0px 4px 2px; font-weight: bold; vertical-align: middle; color: #7e77a7;">{{displayLabel}}</span>
            </span>
            <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">
                <a class="dropdown-item" href="javascript:void(0)" (click)="filterNews('Latest')" style="color: #7e77a7; ">Latest</a>
                <div class="dropdown-divider m-0"></div>
                <a class="dropdown-item" href="javascript:void(0)" (click)="filterNews('All')" style="color: #7e77a7; ">All</a>
            </ul>
            </span>
        </div>

    </div>
    <div class="card-block task-custom-scroll mCustomScrollbar _mCS_1 mCS_no_scrollbar" style="height: 200px; overflow: auto;padding: 20px;">

        <div class="nav-tabs-horizontal">
            <ul class="nav nav-tabs tab-list" role="tablist">
                <li class="nav-item">
                    <a class="nav-link pt-0 pb-0 active" data-target="#companyNews" data-toggle="tab" href="javascript: void(0);" role="tab"
                        aria-expanded="true" (click)="tabSwitch('AllCompany')">Company News</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link pt-0 pb-0" data-target="#peerCompanyNews" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                        (click)="tabSwitch('PeerCompany')">Peer Company News</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="companyNews" role="tabcard" aria-expanded="true">
                    <div *ngFor="let companyNews of recentCompanyNews; let count=index" style="cursor: pointer;" class="pr-2">
                        <div class="task-widget-assigned mb-2 ">
                            <div class="col-lg-12 news-wrapper" (click)="showCompanyNewsDetailsByNewsId(companyNews.News_ID)" data-toggle="modal" data-target="#assignTaskDialog">
                                <div class="news-heading">{{companyNews.Headline}}</div>
                                <div class="news-date">{{companyNews.Date | date : "dd MMM y"}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="peerCompanyNews" role="tabcard" aria-expanded="true">
                    <div *ngFor="let companyNews of recentPeerCompanyNews;let count=index" style="cursor: pointer;" class="pr-2">
                        <div class="task-widget-assigned mb-2 ">
                            <div class="col-lg-12 news-wrapper" (click)="showCompanyNewsDetailsByNewsId(companyNews.News_ID)" data-toggle="modal" data-target="#assignTaskDialog">
                                <div class="news-heading">{{companyNews.Headline}}</div>
                                <div class="news-date">{{companyNews.Date | date : "dd MMM y"}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="companyNewsDialog" tabindex="-1" role="dialog" aria-labelledby="">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 *ngIf="companyNewsDetails" class="modal-title" id="newsTitle" 
                        style="text-align: justify;font-size: 15px;font-weight: 600;text-transform: none;letter-spacing: normal;">
                            {{companyNewsDetails.Headline}}</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                  <span aria-hidden="true">×</span>
                              </button>
                    </div>
                    <div class="modal-body">
                        <div *ngIf="companyNewsDetails" innerHTML=" {{companyNewsDetails.Detail_News}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>