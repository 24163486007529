import { Injectable } from "@angular/core";
import { environment } from './../../environments/environment';
import { HttpClient , HttpHeaders } from '@angular/common/http';


@Injectable()
export class govevaFileManagerService{

    public headers :  any;
    public complianceEngine : any;
    public complianceEngineURL : any;
    public authenticationToken : any ;
    public companyId : any; 
    public companyName : any;
    public uniqueUserId : any;
    public userId : any;

    constructor(private http: HttpClient,private httpWithoutToken : HttpClient){
      

        this.companyId = JSON.parse(localStorage.getItem('companyDetailsGoveva')).value ;
        this.userId = JSON.parse(localStorage.getItem('user'))._id ;
        this.uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId ;
        this.authenticationToken = localStorage.getItem('token');
        this.companyName = JSON.parse(localStorage.getItem('companyDetailsGoveva')).label;

        // preDefined HttpHeaders to set

             

        // console.log("headers-constructor", this.headers);
        // console.log("headers-constructor", this.companyId)

        // console.log("uniqueUserId-constructor", this.userId)

        // console.log("headers-uniqueUserId", this.uniqueUserId)

        // console.log("headers-conauthenticationTokenstructor", this.authenticationToken)

        // console.log("headers-companyName", this.companyName)


    }

    private handleError(error: any): Promise < any > {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    getAllFilesAndFolders(complianceEngine,ComplianceEngineUrl) : Promise<any>{

        var governanceUrl  = environment.clientUrl;
    

        let headers = {};
        let head = (key, header) => {headers[key] = header};
    
         head('accept','*/*')
         head('authentication-token' , this.authenticationToken);
         head('companyId' , this.companyId);
         head('companyName' , this.companyName);
         head('uniqueUserId' , this.uniqueUserId);
         head('userId' , this.userId);  
         head('complianceEngine' , complianceEngine);
         head('complianceEngineUrl' , ComplianceEngineUrl);
         head('governanceUrl', governanceUrl);
         head('Content-Type', 'application/json');

        // let options = new RequestOptions({ headers: headers });

        // console.log("headers", headers)
        // console.log("environment.GFMRootPath",environment.GFMRootPath)

        // const url= "http://52.66.23.123:8085/api/" + 'gfm/getAllFilesAndFolders/false';
        const url= "http://" + environment.gemIp_domain + "/api/" + 'gfm/getAllFilesAndFolders/false';
        // return this.httpWithoutToken.post(url , { "recordPath": "/home/ec2-user/GEM/GFM/" + this.uniqueUserId },{ headers: headers }).toPromise()
        //   .then(response => {
        //     return Promise.resolve(response)
        // })
        return this.httpWithoutToken.post(url , { "recordPath": environment.GFMRootPath + this.uniqueUserId },{ headers: headers }).toPromise()
          .then(response => {
            return Promise.resolve(response)
        })
        .catch(this.handleError)
    }


} 