import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { UserMasterService } from './../services/user-master.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-createiccheckericmaker',
  templateUrl: './createiccheckericmaker.component.html',
  styleUrls: ['./createiccheckericmaker.component.css'],
})
export class CreateiccheckericmakerComponent implements OnInit, AfterViewInit {
  public createICMakerICChekerForm: FormGroup;
  pattern = '^[a-zA-Z0-9][a-zA-Z0-9-_/ ]*[a-zA-Z]+[a-zA-Z0-9-_/ ]*$'; //Pattern for validating blank input and space at first place
  titleList = [];
  defaultTitle: any;
  isFirstNameInvalid: boolean = false;
  isLastNameInvalid: boolean = false;
  isMobileNoInvalid: boolean = false;
  isEmailIdInvalid: boolean = false;

  constructor(
    private router: Router,
    private userMasterService: UserMasterService,
    private fb: FormBuilder
  ) {
    this.createICMakerICChekerForm = this.fb.group({
      ICCheckerFirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      ICCheckerTitleName: new FormControl('', []),
      ICMakerTitleName: new FormControl('', []),
      ICCheckerLastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      ICCheckerEmailId: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      ICCheckerMobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern("[0-9]{8,15}")
      ]),
      ICMakerFirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      ICMakerLastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.pattern),
      ]),
      ICMakerEmailId: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      ICMakerMobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern("[0-9]{8,15}")
      ])
    })
  }

  ngOnInit() {
    //restrict access to a page after login
    var token = localStorage.getItem('token');

    if (!_.isNil(token)) {
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
    }

    this.fillSalutationTitleList();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createICMakerICChekerForm.controls['ICMakerTitleName'].setValue(
        this.defaultTitle
      );
      this.createICMakerICChekerForm.controls['ICCheckerTitleName'].setValue(
        this.defaultTitle
      );
    });
  }

  fillSalutationTitleList() {
    this.titleList = [
      {
        value: 'Mr.',
        label: 'Mr.',
      },
      { value: 'Mrs.', label: 'Mrs.' },
      { value: 'Shri.', label: 'Shri.' },
      { value: 'Smt.', label: 'Smt.' },
      { value: 'Dr.', label: 'Dr.' },
      { value: 'Adv.', label: 'Adv.' },
      { value: 'Ms.', label: 'Ms.' },
    ];
    //default selected
    this.defaultTitle = 'Mr.';

    // this.userMasterService.getSalutationTitle().then(response => {

    //   this.titleList = response.salutationTitle.map( (o) => {
    //     return {
    //       "label": o.label,
    //       "value": o.value,
    //     }
    //   });
    // })
  }

  save() {
    if (this.createICMakerICChekerForm.status == 'VALID') {
      if (this.isValidInfoForICConsultant()) {
        //console.log("submit");

        var userDet = {
          ICCheckerTitleName:
            this.createICMakerICChekerForm.get('ICCheckerTitleName').value,
          ICCheckerFirstName:
            this.createICMakerICChekerForm.get('ICCheckerFirstName').value,
          ICCheckerLastName:
            this.createICMakerICChekerForm.get('ICCheckerLastName').value,
          ICCheckerMobileNumber: this.createICMakerICChekerForm.get(
            'ICCheckerMobileNumber'
          ).value,
          ICCheckerEmailId:
            this.createICMakerICChekerForm.get('ICCheckerEmailId').value,
          ICMakerTitleName:
            this.createICMakerICChekerForm.get('ICMakerTitleName').value,
          ICMakerFirstName:
            this.createICMakerICChekerForm.get('ICMakerFirstName').value,
          ICMakerLastName:
            this.createICMakerICChekerForm.get('ICMakerLastName').value,
          ICMakerMobileNumber: this.createICMakerICChekerForm.get(
            'ICMakerMobileNumber'
          ).value,
          ICMakerEmailId:
            this.createICMakerICChekerForm.get('ICMakerEmailId').value,
        };

        this.userMasterService.createICUsers(userDet).then((user) => {
          if (user.status == 0 || user.status == 200) {
            $('#alertTitle').text('Success!');
            $('#alertTitle').css('color', 'black');
            $('#alertMessage').text('Intuit Consultant created successfully.');
            $('#alertMessage').css('color', 'black');
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();

            setTimeout(this.navigateToLogin.bind(this), 3000);
          } else {
            $('#alertTitle').css('color', '#f00');
            $('#alertTitle').text('Failure!');
            $('#alertMessage').text(user.message);
            $('#alertMessage').css('color', '#f00');
            $('#messagePopUp').modal('toggle');
            this.messagePopUpTimeoutToogle();
          }
        });
      }
    } else {
      //console.log('Invalid form submit.')
    }
  }

  isValidInfoForICConsultant() {
    let ICCheckerMobileNo = this.createICMakerICChekerForm
      .get('ICCheckerMobileNumber')
      .value.trim();
    let ICMakerMobilNo = this.createICMakerICChekerForm
      .get('ICMakerMobileNumber')
      .value.trim();
    let ICCheckerEmailId = this.createICMakerICChekerForm
      .get('ICCheckerEmailId')
      .value.trim();
    let ICMakerEmailId = this.createICMakerICChekerForm
      .get('ICMakerEmailId')
      .value.trim();

    if (ICCheckerMobileNo != '' && ICMakerMobilNo != '') {
      if (ICCheckerMobileNo == ICMakerMobilNo) this.isMobileNoInvalid = true;
      else this.isMobileNoInvalid = false;
    }
    if (ICCheckerEmailId != '' && ICMakerEmailId != '') {
      if (ICCheckerEmailId == ICMakerEmailId) this.isEmailIdInvalid = true;
      else this.isEmailIdInvalid = false;
    }
    if (this.isEmailIdInvalid == true || this.isMobileNoInvalid == true)
      return false;
    else return true;
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  navigateToLogin() {
    this.router.navigate(['login']);
  }
}
