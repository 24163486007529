import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-meeting-assistent-wrapper',
  templateUrl: './meeting-assistent-wrapper.component.html',
  styleUrls: ['./meeting-assistent-wrapper.component.css'],
})
export class MeetingAssistentWrapperComponent implements OnInit {
  @Input('refreshData') refreshData: any;
  @Input('isRocketChatRemove') isRocketChatRemove: any;
  @Input('appHasPermission') appHasPermission: any;
  @Input('rowFive') rowFive: any;
  @Input('rowSix') rowSix: any;
  @Output() calenderOutputStatus = new EventEmitter();

  isCalenderVisible: boolean = true;

  constructor() {}

  ngOnInit() {
    if (this.rowFive || this.rowSix) {
      this.isCalenderVisible = true;
    } else {
      this.isCalenderVisible = false;
    }
  }

  showCalender() {
    this.isCalenderVisible = true;
  }

  showList() {
    this.isCalenderVisible = false;
  }
}
