
<section class="mt-4 mb-5 participant">
	<div class="container" *ngIf="isAttendanceObjectEmpty">
		<div class="row">
			<div class="col-md-12 title">
				<div class="mr-auto">
				<h4><img src="/assets/images/participants-icon.png" alt="Participants">Attendance Records Yet To Update.</h4>
			</div>
			</div>
		</div>
	</div>
  <div class="container"  *ngIf="!isAttendanceObjectEmpty">
    <div class="row">
			<div class="col-md-12 title">
				<div class="mr-auto">
				<h4><img src="/assets/images/participants-icon.png" alt="Participants">Participants <span>({{count}})</span>:</h4>
			</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6 mt-4" >
			  <div class="box attending">
					<div class="d-flex justify-content-between px-3 py-2">
						<strong>Attending:</strong>
						<span>{{Attended}}</span>
					</div>
					<hr>
					<div class="info">
						<ul *ngFor="let attendance of attendanceObject" >
							<li *ngIf="attendance.isAttended">
								<strong>{{attendance.membeberName}}</strong>
								<p>{{attendance.memberType}}</p>
								<span>{{attendance.isAttended ? "Yes" : "No"}}</span>
							</li>
						</ul>
					</div>
			  </div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-6 mt-4">
			  <div class="box not-attending">
					<div class="d-flex justify-content-between px-3 py-2">
						<strong>Not Attending:</strong>
						<span>{{NotAttended}}</span>
					</div>
					<hr>
					<div class="info">
						<ul *ngFor="let attendance of attendanceObject" >
							<li *ngIf="!attendance.isAttended">
								<strong>{{attendance.membeberName}}</strong>
								<p>{{attendance.memberType}}</p>
								<span>{{attendance.isAttended ? "Yes" : "No"}}</span>
							</li>
						</ul>
					</div>
			  </div>
			</div>
		</div>
	</div>
</section>