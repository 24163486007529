import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonFunction } from '../helpers/commonFunction';


@Injectable()
export class RoadmapModuleService {

    constructor(private http: HttpClient, public commonFunction: CommonFunction) { }

    getUserList(): Promise<any> {
        const url = environment.clientUrl + 'userMaster/getUserList';
        return this.http.get(url).toPromise()
            .then((response: any) => {
                response.result = JSON.parse(this.commonFunction.decrypt(response.result))
                return Promise.resolve(response);
            })
            .catch(this.handleError)
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    //get all list of tasks with process
    //assessmentId
    getTaskWithProcessList(): Promise<any> {
        // + assessmentId
        return this.http.get(environment.clientUrl + 'assessment/taskWithProcess')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


    assignTask(roadmapTaskDetails): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'roadmapWithProcessOwner/task', (roadmapTaskDetails))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //get list of tasks with process which are assigned 
    //assessmentId
    getRoadmapTaskDetails(): Promise<any> {
        // + assessmentId
        return this.http.get(environment.clientUrl + 'roadmapWithProcessOwner/taskList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


    //get list of assessment names whose CurrentState is ICCheckerReviewDone
    getAssessmentsForICCheckerReviewDone(): Promise<any> {

        return this.http.get(environment.clientUrl + 'assessment/forICCheckerReviewDone')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    // assigntask/roadmap/create
    createRoadmap(roadmapTasks): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'assigntask/roadmapWithProcessOwner/create', (roadmapTasks))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //edit task in roadmap
    editRoadmapTask(roadmapTaskDetails): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'roadmapWithProcessOwner/editTask', (roadmapTaskDetails))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //delete task in roadmap
    deleteRoadmapTask(rodmapTaskId): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.get(environment.clientUrl + 'roadmapWithProcessOwner/deleteTask/' + rodmapTaskId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


    getTaskWithProcessListByAssessmentId(assessmentId): Promise<any> {
        if (assessmentId == undefined) {
            assessmentId = 0
        }
        return this.http.get(environment.clientUrl + 'assessment/taskWithProcessOwner/' + assessmentId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    gettaskRelatedToRoadmapId(roadmapId): Promise<any> {
        // console.log("roadmapIdapi call",roadmapId);
        var path;

        if (roadmapId == undefined) {
            path = 'roadmapWithProcessOwner/getRoadmapTaskById';
        } else {
            path = 'roadmapWithProcessOwner/getRoadmapTaskById/' + roadmapId;
        }

        return this.http.get(environment.clientUrl + path)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    sendRoadmapForReview(reviewObject): Promise<any> {
        return this.http.post(environment.clientUrl + "roadmapWithProcessOwner/sendForReview", reviewObject)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    getRaodmaptaskByAssessmentID(assessmentId): Promise<any> {

        return this.http.get(environment.clientUrl + 'roadmapWithProcessOwner/getroadmapByAssessmentId/' + assessmentId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    approveRoadmapReview(object, status): Promise<any> {
        return this.http.post(environment.clientUrl + 'roadmapReview/Approve', object, { params: { status } })
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    getRoadmapReviewDetails(AssessmentId): Promise<any> {
        return this.http.get(environment.clientUrl + 'roadmapReview/getRoadmapReviewDetails/' + AssessmentId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }


    updateRoadmap(roadmapTasks): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'assigntask/roadmap/update', (roadmapTasks))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    getReviewListByUserId(userId): Promise<any> {
        let headers = {};
        let head = (key, header) => { headers[key] = header };
        //head('Content-Type', 'application/json');
        return this.http.get(environment.clientUrl + 'roadmapReview/getReviewListByuserId/' + userId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


}
