<div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  style="top: 50px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
        Title!
      </div>
      <div class="modal-body" style="padding: 10px 15px;">
        <div id="alertMessage" style="font-size: 16px;">
          Message..
        </div>
      </div>
    </div>
  </div>
</div>
<section>
  <div>
    <div class="inner-wrap">
      <p class="text-center">
        <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
      </p>
      <form [formGroup]="loginForm">
        <div class="form-group">
          <label>E-mail address / Username:</label>
          <input [(ngModel)]="userName" (focusout)="getCompaniesByEmailId()" class="form-control" type="email"
            name="Email" [formControl]="loginForm.controls['userName']">
          <div *ngIf="loginForm.controls['userName'].hasError('required') && loginForm.controls['userName'].touched"
            class="form-control-error-custom" style="text-align:left;">
            Email / Username is required.
          </div>
        </div>
        <div class="form-group">
          <label>Select your company:</label>
          <div class="below" style="border:0"></div>
          <ng-select [notFoundMsg]="isEmailForCompany" [(ngModel)]="selectedCompanyId"
            bindLabel="label" bindValue="value"
            (change)="companySelection(selectedCompanyId)" class="companyddl" [multiple]=false [items]="companyList"
            [formControl]="loginForm.controls['companyName']" style="text-align:left;">
          </ng-select>
          <div
            *ngIf="loginForm.controls['companyName'].hasError('required') && loginForm.controls['companyName'].touched && companyList.length"
            class="form-control-error-custom" style="text-align:left;">
            Company Name is required.
          </div>
        </div>

        <div class="form-group input-box">
          <label>Password:</label>
          <div id="fake-input" class="fake-input form-control"><span class="blinking-cursor"></span></div>
          <input autocomplete="off" class="real-input" (focusin)="blinkCur()" (focusout)="blinkCurOut()"
            (keyup)="RemoveSavePassword()" type="text" (keyup.enter)="login()" name="Password"
            [formControl]="loginForm.controls['password']">
          <div *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched"
            class="form-control-error-custom" style="text-align:left;padding-top: 32px;">
            Password is required.
          </div>
        </div>

        <div *ngIf="!isADCompany">
          <h6 class="text-right">
            <a href="#" style="width: 100%;display: block;margin-top: 10px;font-weight: 400;color: #37a7a5;" novalidate
              [routerLink]="['/requestPassword']">Forgot Password?</a>
          </h6>
        </div>
        <div class="clearfix"></div>
        <div class="row" *ngIf="companyConfig?.normalLogin">
          <!-- <div [ngClass]="{'col-md-12': !(companyConfig?.authLogin == 'Active' && companyConfig?.twoFactor == 'Inactive')
          && !(companyConfig?.authLogin == 'Inactive' && companyConfig?.twoFactor == 'Inactive'), 
          'col-md-6': ((companyConfig?.authLogin == 'Active' && companyConfig?.twoFactor == 'Inactive') 
          || (companyConfig?.authLogin == 'Inactive' && companyConfig?.twoFactor == 'Inactive'))}">
            <div class="text-center">
              <button class="btn btn-primary btn-block mr-2 mb-2" style="margin-top: 10px;"
                [disabled]="!loginForm.valid || LoginDisabled" (click)="login()">
                <i aria-hidden="true"></i>Login <span *ngIf="companyConfig?.twoFactor == 'Active'"> (2Fa) </span></button>
            </div>
          </div> -->

          <div class="col">
            <div class="text-center">
              <button class="btn btn-primary btn-block mr-2 mb-2" style="margin-top: 10px;"
                [disabled]="!loginForm.valid || LoginDisabled" (click)="login()">
                <i aria-hidden="true"></i>Login <span *ngIf="companyConfig?.twoFactor == 'Active'"> (2Fa) </span></button>
            </div>
          </div>
          
          <div class="col" *ngIf="(companyConfig?.authLogin == 'Active' && companyConfig?.twoFactor == 'Inactive' && isADCompany && companyConfig?.OTPLoginFoAD == 'Active') 
          || (companyConfig?.authLogin == 'Inactive' && companyConfig?.twoFactor == 'Inactive') && isADCompany && companyConfig?.OTPLoginFoAD == 'Active'">
            <div class="text-center">
              <button class="btn btn-primary btn-block mr-2 mb-2" type="button" style="margin-top: 10px;"
                [routerLink]="['/login-with-otp']" [queryParams]="{returnUrl: redirectPath}">   
                <i aria-hidden="true"></i>Login With OTP</button>
            </div>
          </div>
          <div class="col" *ngIf="(companyConfig?.authLogin == 'Active' && companyConfig?.twoFactor == 'Inactive' && !isADCompany) 
          || (companyConfig?.authLogin == 'Inactive' && companyConfig?.twoFactor == 'Inactive') && !isADCompany">
            <div class="text-center">
              <button class="btn btn-primary btn-block mr-2 mb-2" type="button" style="margin-top: 10px;"
                [routerLink]="['/login-with-otp']" [queryParams]="{returnUrl: redirectPath}">
                <i aria-hidden="true"></i>Login With OTP</button>
            </div>
          </div>
        </div>
        <div class="text-center" *ngIf="companyConfig?.authLogin == 'Active'">Or</div>
        <div class="row" *ngIf="companyConfig?.authLogin == 'Active'">
          <div class="col-md-12">
            <div class="text-center">
              <!-- <button class="btn btn-primary btn-block mr-2 mb-2" style="margin-top: 10px;" (click)="login('loginByGoogle')">
                <i aria-hidden="true"></i>Login with Google</button> -->
                  <div id="customBtn" class="customGPlusSignIn" (click)="login('loginByGoogle')">
                    <span class="icon">
                      <img src="./../../assets/images/google-login-icon-24.jpg" alt="">
                    </span>
                    <span class="buttonText">Login with Google</span>
                  </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
