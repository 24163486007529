import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { CompanyMasterService } from './../services/companymaster.service';
import { Subject } from 'rxjs';
import { CommonFunction } from "../helpers/commonFunction";
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class MeetingMasterService {

    Pagination = new Subject <any>();
    
    constructor(private http: HttpClient, 
      private httpWithoutToken: HttpClient, 
      private companyMasterService: CompanyMasterService,
      private commonFunction: CommonFunction) { 

      }
    
     
    getMeetingList(companyId): Observable<any> {
        const url = environment.pssServerUrl + "govEva/meetingType/meetingList/" + companyId;
        let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('accept', '*/*');
        head('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        var token = localStorage.getItem('token')
        head("x-access-token", token)
        
        head("userdetail", JSON.parse(localStorage.getItem("user")))
        head("pssdetail", localStorage.getItem('companyDetails'))
        
        return this.httpWithoutToken.get(url, {headers: headers}).pipe(
          catchError(this.handleError)
        )

    }
   
    addMeeting(newMeeting) {
        var user = JSON.parse(localStorage.getItem("user"));
        var resources  = JSON.parse(localStorage.getItem("resources"));
        var companyDetails = JSON.parse(localStorage.getItem("companyDetailsGoveva"));
        
        var decrEmailId=this.commonFunction.decrypt(user.EmailId);
        var resourcesData  = resources.filter(function(item) {
            return item.Menu === 'UPSI Detail All';
            
          })[0]
  
          var upsiAdmin;
         if(resourcesData){
          upsiAdmin  = (resourcesData['Menu'])?true:false
         }else{
          upsiAdmin  = false
         }
        var loginUser = {
            id:user._id,
            firstName:user.FirstName,
            lastName:user.LastName,
            upsiAdmin:upsiAdmin,
            
            EmailId:decrEmailId,
            companyDetails:companyDetails,
            title: user.Title
        }
        
        var PSSDetail = localStorage.getItem('companyDetails');
    
        var UserDetail =  JSON.stringify(loginUser);
         let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('Content-Type', 'application/json');
        head('Access-Control-Allow-Origin', '*');
        head('PSSDetail', PSSDetail);
        head('UserDetail', UserDetail);
        var token = localStorage.getItem('token')
        head("x-access-token", token)
        var request = {
            URL: 'meeting',
            DATA: newMeeting
        }
       
       console.log(newMeeting,'meeting');
       console.log(typeof newMeeting)
      
       return this.httpWithoutToken.post(environment.pssServerUrl + request.URL, newMeeting,{ headers: headers }).pipe((res: any) => res);
    }
    getDocumentAnnotation(documentId): Promise<any> {
        const url = environment.GEMURL + "documentAnnotation/" + documentId;
        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
        .catch(this.handleError);
    }
    
    getMeetingById(meetingId): Promise<any> {

        const url = environment.pssServerUrl + "meetingType/meetingByMeetingID/" + meetingId;

        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);

    }

    
    getAttendanceFile(fileId) {
        var token = localStorage.getItem('token')
        const url = environment.pssServerUrl + "documentManagement/downloadSingleFileOnID/" + fileId + "/" + token;
        
        window.location.href = url;

    }

    getmeetingAttendance(meetingId) {
        const url = environment.GEMURL + `meetingAttendance/get/${meetingId}`;

        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);

    }



    getAttendanceFileName(fileId) {
        const url = environment.PSSFileNotValidurl + "documentManagement/getFileNameByID/" + fileId;

        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);
    }

    getAttendanceFileDetails(fileId) {
        var token = localStorage.getItem('token')
        const url = environment.pssServerUrl + "viewDocketDocFile/" + fileId + "/" + token;
        

        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);
    }

    getMeetingTypeList(companyId): Promise<any> {
        const url = environment.pssServerUrl + "govEva/getMeetingTypeList/" + companyId;
        return this.httpWithoutToken.get(url).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); 
        return Promise.reject(error.message || error);
    }

    getUpdateMessagesList(meetingId): Promise<any> {
        var userInfo = JSON.parse(localStorage.getItem('user'));
        var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
        const url = environment.GEMURL + "documentAnnotation/meetingId/" + meetingId;
        var token = localStorage.getItem('token');

         let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('accept', '*/*');
        head('authentication-token', token);
        head('companyId', companyInfo.companyID);
        head('companyName', localStorage.getItem('companyDetailsGoveva'));
        head('Access-Control-Allow-Origin', '*');
        head('complianceEngine', 'PSS');
        head('complianceEngineUrl', environment.pssServerUrl);
        head('governanceUrl', environment.nonValidateUrl);
        head('uniqueUserId', userInfo.UniqueUserId);
        head('userId', userInfo._id);
        head('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

        return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
        .then((response: any) => {
          return Promise.resolve(response);
        })
        .catch(this.handleError);
    }

    getRegularMeetingList(gemURL): Promise<any> {
        var userInfo = JSON.parse(localStorage.getItem('user'));
        var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
        const url = gemURL + "meeting/regular/userMeetings";
        var token = localStorage.getItem('token');

         let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('accept', '*/*');
        head('authentication-token', token);
        head('companyId', companyInfo.companyID);
        head('companyName', localStorage.getItem('companyDetailsGoveva'));
        head('Access-Control-Allow-Origin', '*');
        head('complianceEngine', 'PSS');
        head('complianceEngineUrl', environment.pssServerUrl);
        head('governanceUrl', environment.nonValidateUrl);
        head('uniqueUserId', userInfo.UniqueUserId);
        head('userId', userInfo._id);

        return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);
    }

    getRegularFile(filePath, meetingId, filename, gemURL) {

        var userInfo = JSON.parse(localStorage.getItem('user'));
        var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
        var extn = filename.split('.').pop().toLowerCase()
        var url;
        
        url = gemURL + "meeting/regular/getBase64EncodedFile/" + meetingId + "/file/" + filePath;
        
        
        

        var token = localStorage.getItem('token');

         let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('accept', '*/*');
        head('authentication-token', token);
        head('companyId', companyInfo.companyID);
        head('companyName', localStorage.getItem('companyDetailsGoveva'));
        head('Access-Control-Allow-Origin', '*');
        head('complianceEngine', 'PSS');
        head('complianceEngineUrl', environment.pssServerUrl);
        
        head('fileName', filePath);
        head('meetingId', meetingId);
        head('uniqueUserId', userInfo.UniqueUserId);
        head('userId', userInfo._id);

        return this.httpWithoutToken.get(url, { headers: headers }).toPromise()
            .then(response => {
                console.log(response);

                let byteCharacters = atob(response["_body"]);

                let byteNumbers = new Array(byteCharacters.length);

                for (var i = 0; i < byteCharacters.length; i++)
                    byteNumbers[i] = byteCharacters.charCodeAt(i);

                let byteArray = new Uint8Array(byteNumbers);

                

                
                
                var contentType = 'application/octet-stream';

                var blob = new Blob([byteArray], { type: contentType });
                var downloadUrl = URL.createObjectURL(blob);

                console.log("downloadUrl", downloadUrl);

                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = downloadUrl;
                link.download = filename;
                link.click();
            })
            .catch(this.handleError);


    }

    saveRegularMeeting(fileObj, meetingObject, gemURL): Promise<any> {
        console.log("fileObj==service=================", fileObj);
        var userInfo = JSON.parse(localStorage.getItem('user'));
        var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
        const url = gemURL + "meeting/regular";
        var token = localStorage.getItem('token');

         let headers = {};
        let head = (key, header) => {headers[key] = header};
        head('accept', '*/*');
        head('authentication-token', token);
        head('companyId', companyInfo.companyID);
        head('companyName', localStorage.getItem('companyDetailsGoveva'));
        head('Access-Control-Allow-Origin', '*');
        head('complianceEngine', 'PSS');
        head('complianceEngineUrl', environment.pssServerUrl);
        head('governanceUrl', environment.nonValidateUrl);
        head('uniqueUserId', userInfo.UniqueUserId);
        head('userId', userInfo._id);

        let formData = new FormData();

        var count1 = fileObj.length
        var i = 0;
        for (i = 0; i < count1; i++) {
          console.log("fileObj list---------", fileObj[i])
          formData.append("files", fileObj[i]);
        }

        
        formData.append("regularMeeting", meetingObject);
        console.log("regular meeting ADD formData=====================",meetingObject);
        return this.httpWithoutToken.post(url, formData, { headers: headers }).toPromise()
            .then((response: any) => {
                return Promise.resolve(response);
            })
            .catch(this.handleError);
    }

  updateRegularMeeting(
    meetingId,
    fileObj,
    meetingObject,
    gemURL
  ): Promise<any> {
    console.log('fileObj===================', fileObj);
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    const url = gemURL + 'meeting/regular/' + meetingId;
    var token = localStorage.getItem('token');

     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyInfo.companyID);
    head('companyName', localStorage.getItem('companyDetailsGoveva'));
    head('Access-Control-Allow-Origin', '*');
    head('complianceEngine', 'PSS');
    head('complianceEngineUrl', environment.pssServerUrl);
    head('governanceUrl', environment.nonValidateUrl);
    head('uniqueUserId', userInfo.UniqueUserId);
    head('userId', userInfo._id);

    let formData = new FormData();

    var count1 = fileObj.length;
    var i = 0;
    for (i = 0; i < count1; i++) {
      console.log('fileObj list---------', fileObj[i]);
      formData.append('files', fileObj[i]);
    }

    formData.append('files', fileObj);
    formData.append('regularMeeting', meetingObject);
    console.log(
      'regular meeting UPDATE formData=====================',
      formData
    );
    return this.httpWithoutToken
      .post(url, formData, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getRegularMeetingById(meetingId, gemURL): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    const url = gemURL + 'meeting/regular/' + meetingId;
    var token = localStorage.getItem('token');

     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyInfo.companyID);
    head('companyName', localStorage.getItem('companyDetailsGoveva'));
    head('Access-Control-Allow-Origin', '*');
    head('complianceEngine', 'PSS');
    head('complianceEngineUrl', environment.pssServerUrl);
    head('governanceUrl', environment.nonValidateUrl);
    head('uniqueUserId', userInfo.UniqueUserId);
    head('userId', userInfo._id);

    return this.httpWithoutToken
      .get(url, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  removeRegularMeeting(meetingId, gemURL): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    const url = gemURL + 'meeting/regular/' + meetingId + '/removeMeeting';
    var token = localStorage.getItem('token');

     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyInfo.companyID);
    head('companyName', localStorage.getItem('companyDetailsGoveva'));
    head('Access-Control-Allow-Origin', '*');
    head('complianceEngine', 'PSS');
    head('complianceEngineUrl', environment.pssServerUrl);
    head('governanceUrl', environment.nonValidateUrl);
    head('uniqueUserId', userInfo.UniqueUserId);
    head('userId', userInfo._id);

    return this.httpWithoutToken
      .put(url, {}, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  removeRegularMeetingFile(meetingId, fileName, gemURL): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    const url =
      gemURL +
      'meeting/regular/' +
      meetingId +
      '/fileName' +
      '/' +
      fileName +
      '/removeFile';
    var token = localStorage.getItem('token');

     let headers = {};
        let head = (key, header) => {headers[key] = header};
    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyInfo.companyID);
    head('companyName', localStorage.getItem('companyDetailsGoveva'));
    head('Access-Control-Allow-Origin', '*');
    head('complianceEngine', 'PSS');
    head('complianceEngineUrl', environment.pssServerUrl);
    head('governanceUrl', environment.nonValidateUrl);
    head('uniqueUserId', userInfo.UniqueUserId);
    head('userId', userInfo._id);

    return this.httpWithoutToken
      .put(url, {}, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  checkIsRecordingAvailableAtCiscoEnd(
    complianceEngine,
    complianceEngineUrl,
    governanceUrl,
    gemAccessUrl,
    meetingId
  ): Promise<any> {
    console.log('isRecordingAvailable meetingId-------------', meetingId);
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyID;
    var companyName = localStorage.getItem('companyDetailsGoveva');
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    const url = gemAccessUrl + 'meeting/cisco/getRecordingFlag/' + meetingId;
    
    var governanceUrl = governanceUrl;
    
    const headerDict = {
    'accept': '*/*',
    'authentication-token': token,
    'companyId': companyID,
    'companyName': companyName,
    'complianceEngine': complianceEngine,
    'complianceEngineUrl': complianceEngineUrl,
    'governanceUrl': governanceUrl,
    'userId': userId,
    'uniqueUserId': uniqueUserId,
    'Access-Control-Allow-Origin': '*',
    }
    const requestOptions = { 
      headers: new HttpHeaders(headerDict)
    };

    return this.httpWithoutToken
      .get(url, requestOptions)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  downloadCiscoRecordingById(
    complianceEngine,
    complianceEngineUrl,
    governanceUrl,
    gemAccessUrl,
    meetingId
  ): Promise<any> {
    console.log('isRecordingAvailable meetingId-------------', meetingId);
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyID;
    var companyName = localStorage.getItem('companyDetailsGoveva');
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    const url =
      gemAccessUrl + 'meeting/cisco/downloadCiscoRecording/' + meetingId;
    
    var governanceUrl = governanceUrl;
     let headers = {};
        let head = (key, header) => {headers[key] = header};

    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.httpWithoutToken
      .get(url, { headers: headers })
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }
}
