import { Component, OnInit } from '@angular/core';
// import { AssessmentService } from '../../../structure/assessment-execution/service/assessment.service';
import { ActivatedRoute , RouterState, Router, NavigationEnd } from "@angular/router";
// import { QuestionService } from '../../../structure/assessment-execution/service/question.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { NotificationComponent } from '../../../structure/assessment-execution/notification/notification.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-referral-status-report',
  templateUrl: './referral-status-report.component.html',
  styleUrls: ['./referral-status-report.component.css']
})
export class ReferralStatusReportComponent implements OnInit {
  referredQuestions: any;
  assessmentId:any;
  allReferralUsersData: any;
  uniqueIDPUsers: any;
  uniqueIDRUsers: any;
  uniqueIDAUsers: any;
  allUniqueUsers: any;
  assessmentState:any;

  constructor(
  //  private assessmentService: AssessmentService,
    private route: ActivatedRoute,
   // private questionService: QuestionService,
    private modalService: NgbModal, 
    ) { }
  

  ngOnInit(): void {
    this.route.params.subscribe( params =>  {
      this.assessmentId=params.id;
      });

    // this.assessmentService.getAssessmentStatus().then(response=> {
    //   this.assessmentState = response.result[0].CurrentState

    // })

  //   this.assessmentService.getAssessmentQuestionData(this.assessmentId).then(response => {
  //   this.referredQuestions = response.questionAssessmentList.filter((question, index)=>(question.aggregatedAnswers.length>0 ))    
  //   this.referredQuestions.sort((a,b)=>(a.CategoryName.localeCompare(b.CategoryName) || a.serialOrder - b.serialOrder  ))
  //   //console.log(this.referredQuestions)

  //   this.allReferralUsersData = this.referredQuestions.map(question=>question.aggregatedAnswers).map(aggAnswer=>aggAnswer.flat()).flat()
  //   this.allReferralUsersData = this.allReferralUsersData.filter(question=>question.lastEmailSent!=null)

  //   this.uniqueIDPUsers = Array.from(new Set (this.allReferralUsersData.map(data=>({Email:data.EmailId, UserName:data.UserName})))) 
  //   this.uniqueIDRUsers = Array.from (new Set (this.allReferralUsersData.map(data=>({Email:data.IDREmailId, UserName:data.IDRUserName}))))
  //   this.uniqueIDAUsers = []//Array.from (new Set (this.allReferralUsersData.map(data=>({Email:data.IDAEmailId, UserName:data.IDAUserName}))))

  //   let combinedArray = this.uniqueIDPUsers.concat(this.uniqueIDRUsers, this.uniqueIDAUsers)
  //   const key = 'Email';
  //   this.allUniqueUsers = [...new Map(combinedArray.map(item => [item[key], item])).values()]
  //   this.allUniqueUsers= this.allUniqueUsers.filter(userInfo=>userInfo.Email!='')
    
  // })

  
  
}

IDPQuestions(user){
  return this.referredQuestions.map(question=>question.aggregatedAnswers).flat().filter(answerData => answerData.EmailId == user.Email)
}

//Sub Question Level
IDRQuestions(user){
  return this.referredQuestions.map(question=>question.aggregatedAnswers).flat().filter(answerData => answerData.IDREmailId == user.Email)
}

//Question Level
IDRMainQuestions(user){
  return this.referredQuestions.filter(obj => obj.aggregatedAnswers.some(answer => answer.IDREmailId == user.Email));
}


IDAQuestions(user){
  return this.referredQuestions.filter(obj => obj.aggregatedAnswers.some(answer => answer.IDAEmailId == user.Email));
}

Count(answerData){
  let answeredCount = 0
  let unansweredCount = 0
  answerData.forEach(element => {
    let a = element.textAnswer? "Yes": 
    (element.tabularStructure && this.isTabularAnswerDirty(element))? "Yes": 
    element.mcqAnswer? "Yes":
    answerData.binaryAnswer? "Yes": "No";  

    a == 'Yes'?answeredCount+=1:unansweredCount+=1
    
  });
  return {answered:answeredCount, unanswered:unansweredCount}
}

IDRCount(answerData){
  let answeredCount = 0
  let unansweredCount = 0
  answerData.forEach(element => {
    let a = element.textAnswer? "Yes": 
    (element.tabularStructure && this.isTabularAnswerDirty(element))? "Yes": 
    element.mcqAnswer? "Yes": 
    answerData.binaryAnswer? "Yes": "No";  

    ((a == 'Yes') && (element.approved))?answeredCount+=1:unansweredCount+=1

  });
  return {answered:answeredCount, unanswered:unansweredCount}
}
//Question Level
IDRQCount(answerData){
  let answeredCount = 0
  let unansweredCount = 0
  answerData.forEach(element => {
    var a = (element.ProvidedAnswer && element.ProvidedAnswer!='')? "Yes": 
    (element.tabularStructure && this.isTabularAnswerDirty(element))? "Yes": 
    element.mcqAnswer? "Yes": 
    answerData.binaryAnswer? "Yes": "No"; 

    (a == 'Yes' && element.QuestionStatus != 'Not Answered' && element.QuestionStatus != 'Marked')?answeredCount+=1:unansweredCount+=1
    
  });
  return {answered:answeredCount, unanswered:unansweredCount}
}

sendReminderEmail(question, answerData, IDR=false) {

  const config = {
    UserName : answerData.UserName,
    CategoryName : question.CategoryName,
    QuestionText : question.QuestionText,
    url : window.location.origin + '/#/structure/(structure-outlet:instructions)',
    EmailId : IDR? answerData.IDREmailId : answerData.EmailId ,
    Reminder: true,
    IDR: IDR
  }
  //console.log('sendEmailToAggregationUser',config)
  // this.questionService.sendAggregationEmail(config).then(response => {
  //   if (response.status == 0) {
  //     const modalRef = this.modalService.open(NotificationComponent)
  //     modalRef.componentInstance.title = 'Success';
  //     modalRef.componentInstance.message = 'Email sent successfully.';
      
  //   } else {
  //     const modalRef = this.modalService.open(NotificationComponent)
  //     modalRef.componentInstance.message = response.message;
  //   }
  // });
}

sendUserReminderEmail(username, email, userType, unanswered) {

  const config = {
    UserName : username,
    url : window.location.origin + '/#/structure/(structure-outlet:instructions)',
    EmailId : email,
    Reminder: true,
    UserType: userType,
    UnansweredCount:unanswered
  }
  //console.log('sendEmailToAggregationUser',config)
  // this.questionService.sendUserReminderEmail(config).then(response => {
  //   if (response.status == 0) {
  //     const modalRef = this.modalService.open(NotificationComponent)
  //     modalRef.componentInstance.title = 'Success';
  //     modalRef.componentInstance.message = 'Email sent successfully.';
      
  //   } else {
  //     const modalRef = this.modalService.open(NotificationComponent)
  //     modalRef.componentInstance.message = response.message;
  //   }
  // });
}


sendBulkReminderEmail(){
  console.log('placeholder')
  // const configs = this.referredQuestions.map((question)=>{
  //   var config2 = question.aggregatedAnswers.map(answers=>{
  //     return {
  //       UserName : answers.UserName,
  //       CategoryName : question.CategoryName,
  //       QuestionText : question.QuestionText,
  //       url : window.location.origin + '/#/structure/(structure-outlet:instructions)',
  //       EmailId : answers.EmailId,
  //       // EmailId: 'm.tata@goveva.com',
  //       Reminder: true
  //     }
      
  //   })
  //   console.log(config2)

  // })  
}

isTabularAnswerDirty(question) {
  const answers = (question.tabularStructure && question.tabularStructure.answers) ? question.tabularStructure.answers : null;
  if(!answers) { return false; }
  else {
    const inputValueString = _.map(answers, (answer)=>{
      const clean = _.omit(answer, ['default', 'isEdit']);
      return _.values(clean).join('');
    }).join('')
    return (inputValueString==='')? false : true;
  }
}

}
