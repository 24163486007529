import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var jQuery: any;
declare var Chartist: any;

@Component({
  selector: 'cat-page',
  templateUrl: './chartistjs.html',
})
export class ChartsChartistJs implements OnInit {
  ngOnInit() {
    $(function () {
      // CSS STYLING & ANIMATIONS
      var cssAnimationData = {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          series: [
            [1, 2, 2.7, 0, 3, 5, 3, 4, 8, 10, 12, 7],
            [0, 1.2, 2, 7, 2.5, 9, 5, 8, 9, 11, 14, 4],
            [10, 9, 8, 6.5, 6.8, 6, 5.4, 5.3, 4.5, 4.4, 3, 2.8],
          ],
        },
        cssAnimationResponsiveOptions = [
          [
            {
              axisX: {
                labelInterpolationFnc: function (value, index) {
                  return index % 2 !== 0 ? !1 : value;
                },
              },
            },
          ],
        ];

      new Chartist.Line(
        '.chart-css-animations',
        cssAnimationData,
        {
          plugins: [Chartist.plugins.tooltip()],
        },
        cssAnimationResponsiveOptions
      );

      // SMIL ANIMATIONS
      var animationsChart = new Chartist.Line(
          '.chart-smil-animations',
          {
            labels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ],
            series: [
              [12, 9, 7, 8, 5, 4, 6, 2, 3, 3, 4, 6],
              [4, 5, 3, 7, 3, 5, 5, 3, 4, 4, 5, 5],
              [5, 3, 4, 5, 6, 3, 3, 4, 5, 6, 3, 4],
              [3, 4, 5, 6, 7, 6, 4, 5, 6, 7, 6, 3],
            ],
          },
          {
            low: 0,
            plugins: [Chartist.plugins.tooltip()],
          }
        ),
        seq = 0,
        delays = 80,
        durations = 500;

      animationsChart.on('created', function () {
        seq = 0;
      }),
        animationsChart.on('draw', function (data) {
          if ((seq++, 'line' === data.type))
            data.element.animate({
              opacity: {
                begin: seq * delays + 1e3,
                dur: durations,
                from: 0,
                to: 1,
              },
            });
          else if ('label' === data.type && 'x' === data.axis)
            data.element.animate({
              y: {
                begin: seq * delays,
                dur: durations,
                from: data.y + 100,
                to: data.y,
                easing: 'easeOutQuart',
              },
            });
          else if ('label' === data.type && 'y' === data.axis)
            data.element.animate({
              x: {
                begin: seq * delays,
                dur: durations,
                from: data.x - 100,
                to: data.x,
                easing: 'easeOutQuart',
              },
            });
          else if ('point' === data.type)
            data.element.animate({
              x1: {
                begin: seq * delays,
                dur: durations,
                from: data.x - 10,
                to: data.x,
                easing: 'easeOutQuart',
              },
              x2: {
                begin: seq * delays,
                dur: durations,
                from: data.x - 10,
                to: data.x,
                easing: 'easeOutQuart',
              },
              opacity: {
                begin: seq * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'easeOutQuart',
              },
            });
          else if ('grid' === data.type) {
            var pos1Animation = {
                begin: seq * delays,
                dur: durations,
                from: data[data.axis.units.pos + '1'] - 30,
                to: data[data.axis.units.pos + '1'],
                easing: 'easeOutQuart',
              },
              pos2Animation = {
                begin: seq * delays,
                dur: durations,
                from: data[data.axis.units.pos + '2'] - 100,
                to: data[data.axis.units.pos + '2'],
                easing: 'easeOutQuart',
              },
              ctAnimations = {};
            (ctAnimations[data.axis.units.pos + '1'] = pos1Animation),
              (ctAnimations[data.axis.units.pos + '2'] = pos2Animation),
              data.element.animate(ctAnimations);
          }
        }),
        animationsChart.on('created', function () {});

      // SCATTER
      var ctScatterTimes = function (n) {
          return Array.apply(null, new Array(n));
        },
        ctScatterData = ctScatterTimes(52)
          .map(Math.random)
          .reduce(
            function (data, rnd, index) {
              return (
                data.labels.push(index + 1),
                data.series.forEach(function (series) {
                  series.push(100 * Math.random());
                }),
                data
              );
            },
            {
              labels: [],
              series: ctScatterTimes(4).map(function () {
                return [];
              }),
            }
          ),
        ctScatterOptions = {
          showLine: !1,
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 13 === 0 ? 'W' + value : null;
            },
          },
          plugins: [Chartist.plugins.tooltip()],
        },
        ctScatterResponsiveOptions = [
          [
            'screen and (min-width: 640px)',
            {
              axisX: {
                labelInterpolationFnc: function (value, index) {
                  return index % 4 === 0 ? 'W' + value : null;
                },
              },
            },
          ],
        ];

      new Chartist.Line(
        '.chart-scatter',
        ctScatterData,
        ctScatterOptions,
        ctScatterResponsiveOptions
      );

      // LINE
      new Chartist.Line(
        '.chart-line',
        {
          labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          series: [
            [12, 9, 7, 8, 5],
            [2, 1, 3.5, 7, 3],
            [1, 3, 4, 5, 6],
          ],
        },
        {
          fullWidth: !0,
          chartPadding: {
            right: 40,
          },
          plugins: [Chartist.plugins.tooltip()],
        }
      );

      // AREA
      new Chartist.Line(
        '.chart-area',
        {
          labels: [1, 2, 3, 4, 5, 6, 7, 8],
          series: [[5, 9, 7, 8, 5, 3, 5, 4]],
        },
        {
          low: 0,
          showArea: true,
          plugins: [Chartist.plugins.tooltip()],
        }
      );

      // BI POLAR LINE
      new Chartist.Line(
        '.chart-bi-polar-line',
        {
          labels: [1, 2, 3, 4, 5, 6, 7, 8],
          series: [
            [1, 2, 3, 1, -2, 0, 1, 0],
            [-2, -1, -2, -1, -2.5, -1, -2, -1],
            [0, 0, 0, 1, 2, 2.5, 2, 1],
            [2.5, 2, 1, 0.5, 1, 0.5, -1, -2.5],
          ],
        },
        {
          high: 3,
          low: -3,
          showArea: !0,
          showLine: !1,
          showPoint: !1,
          fullWidth: !0,
          axisX: {
            showLabel: false,
            showGrid: false,
          },
          plugins: [Chartist.plugins.tooltip()],
        }
      );

      // BI POLAR BAR
      var biPolarData = {
          labels: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10'],
          series: [[1, 2, 4, 8, 6, -2, -1, -4, -6, -2]],
        },
        biPolarOptions = {
          high: 10,
          low: -10,
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 2 === 0 ? value : null;
            },
          },
          plugins: [Chartist.plugins.tooltip()],
        };

      new Chartist.Bar('.chart-bi-polar-bar', biPolarData, biPolarOptions);

      // OVERLAPPING BAR
      var overlappingData = {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          series: [
            [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
            [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4],
          ],
        },
        overlappingOptions = {
          seriesBarDistance: 10,
          plugins: [Chartist.plugins.tooltip()],
        },
        overlappingResponsiveOptions = [
          [
            '',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ];

      new Chartist.Bar(
        '.chart-overlapping-bar',
        overlappingData,
        overlappingOptions,
        overlappingResponsiveOptions
      );

      // HORIZONTAL BAR
      new Chartist.Bar(
        '.chart-horizontal-bar',
        {
          labels: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          series: [
            [5, 4, 3, 7, 5, 10, 3],
            [3, 2, 9, 5, 4, 6, 4],
          ],
        },
        {
          seriesBarDistance: 10,
          reverseData: !0,
          horizontalBars: !0,
          axisY: {
            offset: 70,
          },
          plugins: [Chartist.plugins.tooltip()],
        }
      );

      // STACKED BAR
      new Chartist.Bar(
        '.chart-stacked-bar',
        {
          labels: ['Q1', 'Q2', 'Q3', 'Q4'],
          series: [
            [8e5, 12e5, 14e5, 13e5],
            [2e5, 4e5, 5e5, 3e5],
            [1e5, 2e5, 4e5, 6e5],
          ],
        },
        {
          stackBars: !0,
          axisY: {
            labelInterpolationFnc: function (value) {
              return value / 1e3 + 'k';
            },
          },
          plugins: [Chartist.plugins.tooltip()],
        }
      ).on('draw', function (data) {
        'bar' === data.type &&
          data.element.attr({
            style: 'stroke-width: 30px',
          });
      });

      // SIMPLE PIE
      var simplePiedata = {
          series: [5, 3, 4],
        },
        simplePieSum = function (a, b) {
          return a + b;
        };

      new Chartist.Pie('.chart-simple-pie', simplePiedata, {
        labelInterpolationFnc: function (value) {
          return (
            Math.round(
              (value / simplePiedata.series.reduce(simplePieSum)) * 100
            ) + '%'
          );
        },
      });

      // PIE WITH LABELS
      var labelsPieData = {
          labels: ['Bananas', 'Apples', 'Grapes'],
          series: [20, 15, 40],
        },
        labelsPieOptions = {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
        labelsPieResponsiveOptions = [
          [
            'screen and (min-width: 640px)',
            {
              chartPadding: 30,
              labelOffset: 100,
              labelDirection: 'explode',
              labelInterpolationFnc: function (value) {
                return value;
              },
            },
          ],
          [
            'screen and (min-width: 1024px)',
            {
              labelOffset: 80,
              chartPadding: 20,
            },
          ],
        ];

      new Chartist.Pie(
        '.chart-pie-w-labels',
        labelsPieData,
        labelsPieOptions,
        labelsPieResponsiveOptions
      );
    });
  }
}
