import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonFunction } from '../../app/helpers/commonFunction';


@Injectable()
export class RoadmapService {

    constructor(private http: HttpClient, private commonFunction: CommonFunction) { }

    getUserList(): Promise<any> {
        const url = environment.clientUrl + 'userMaster/getUserList';
        return this.http.get(url).toPromise()
            .then((response: any) => {
                response.result = JSON.parse(this.commonFunction.decrypt(response.result))
                return Promise.resolve(response);
            })
            .catch(this.handleError)
    }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    //get all list of tasks with process
      //assessmentId
    getTaskWithProcessList(): Promise<any> { 
        // + assessmentId
        var token = localStorage.getItem('token')
        let header = {};
        let head = (key, header) => {header[key] = header};
        head('x-access-token', token);
        return this.http.get(environment.clientUrl + 'assessment/taskWithProcess', {headers: header})
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    assignTask(roadmapTaskDetails): Promise<any> {
        let headers = {};
        let head = (key, header) => {headers[key] = header};
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'roadmap/task', (roadmapTaskDetails))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //get list of tasks with process which are assigned 
    //assessmentId
    getRoadmapTaskDetails(): Promise<any> {
        // + assessmentId
        return this.http.get(environment.clientUrl + 'roadmap/taskList')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


    //get list of assessment names whose CurrentState is ICCheckerReviewDone
    getAssessmentsForICCheckerReviewDone(): Promise<any> {

        return this.http.get(environment.clientUrl + 'assessment/forICCheckerReviewDone')
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    // assigntask/roadmap/create
    createRoadmap(roadmapTasks): Promise<any> {
        let headers = {};
        let head = (key, header) => {headers[key] = header};
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'assigntask/roadmap/create', (roadmapTasks))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //edit task in roadmap
    editRoadmapTask(roadmapTaskDetails): Promise<any>
    {
        let headers = {};
        let head = (key, header) => {headers[key] = header};
        //head('Content-Type', 'application/json');
        return this.http.post(environment.clientUrl + 'roadmap/editTask', (roadmapTaskDetails))
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    //delete task in roadmap
    deleteRoadmapTask(rodmapTaskId): Promise<any> {
        let headers = {};
        let head = (key, header) => {headers[key] = header};
        //head('Content-Type', 'application/json');
        return this.http.get(environment.clientUrl + 'roadmap/deleteTask/' + rodmapTaskId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }


    gettaskRelatedToRoadmapId(roadmapId): Promise<any>{
        // console.log("roadmapIdapi call",roadmapId);
        return this.http.get(environment.clientUrl + 'roadmapWithProcessOwner/getRoadmapTaskById/' + roadmapId)
        .toPromise()
        .then(response => {
            return Promise.resolve(response);
        })
    }

}
