<!-- START: components/steps -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Steps</strong>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Block Steps w/ Digit</strong></h5>
                    <p class="text-muted">Element: <code>div.cat__core__step</code></p>
                    <p class="text-muted">Modifiers: <code>default</code>, <code>primary</code>, <code>secondary</code>, <code>success</code>, <code>info</code>, <code>warning</code>, <code>danger</code>, <code>disabled</code></p>
                    <p class="text-muted">Color Modifier: <code>.cat__core__step--(modifier)</code></p>
                    <div class="row">
                        <div class="col-lg-3">
                            <!-- Example State Default -->
                            <div class="cat__core__step cat__core__step--success">
                                <span class="cat__core__step__digit">1</span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Default -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Error -->
                            <div class="cat__core__step cat__core__step--primary">
                                <span class="cat__core__step__digit">2</span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Error -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Current -->
                            <div class="cat__core__step cat__core__step--danger">
                                <span class="cat__core__step__digit">3</span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Current -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Disabled -->
                            <div class="cat__core__step cat__core__step--default cat__core__step--disabled">
                                <span class="cat__core__step__digit">14</span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Disabled</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Disabled -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Squared Block Steps w/ Icon</strong></h5>
                    <p class="text-muted">Element: <code>span.cat__core__step__digit</code></p>
                    <div class="row">
                        <div class="col-lg-3">
                            <!-- Example State Done -->
                            <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                <span class="cat__core__step__digit">
                                    <i class="icmn-home"><!-- --></i>
                                </span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Done -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Error -->
                            <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                <span class="cat__core__step__digit">
                                    <i class="icmn-key"><!-- --></i>
                                </span>
                                <div class="cat__core__step__desc">
                                    <span class="scat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Error -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Current -->
                            <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                <span class="cat__core__step__digit">
                                    <i class="icmn-play2"><!-- --></i>
                                </span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Current -->
                        </div>
                        <div class="col-lg-3">
                            <!-- Example State Disabled -->
                            <div class="cat__core__step">
                                <span class="cat__core__step__digit">
                                    <i class="icmn-database"><!-- --></i>
                                </span>
                                <div class="cat__core__step__desc">
                                    <span class="cat__core__step__title">Block Title</span>
                                    <p>Waiting for review</p>
                                </div>
                            </div>
                            <!-- End Example State Disabled -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="text-black"><strong>Steps in card Heading</strong></h5>
                <p class="text-muted">Insert Step into <code>.card-header</code> element</p>
                <div class="mb-5">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-3">
                                    <!-- Example State Default -->
                                    <div class="cat__core__step cat__core__step--success">
                                        <span class="cat__core__step__digit">1</span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Default -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Error -->
                                    <div class="cat__core__step cat__core__step--primary">
                                        <span class="cat__core__step__digit">2</span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Error -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Current -->
                                    <div class="cat__core__step cat__core__step--danger">
                                        <span class="cat__core__step__digit">3</span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Current -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Disabled -->
                                    <div class="cat__core__step cat__core__step--default cat__core__step--disabled">
                                        <span class="cat__core__step__digit">14</span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Disabled</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Disabled -->
                                </div>
                            </div>
                        </div>
                        <div class="card-block">
                            <div class="row">
                                <div class="col-lg-12">
                                    card Body Content
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="text-black"><strong>Steps in card Heading w/ Icon</strong></h5>
                <p class="text-muted">Element: <code>span.cat__core__step__digit</code></p>
                <div class="mb-5">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-3">
                                    <!-- Example State Done -->
                                    <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                        <span class="cat__core__step__digit">
                                            <i class="icmn-home"><!-- --></i>
                                        </span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Done -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Error -->
                                    <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                        <span class="cat__core__step__digit">
                                            <i class="icmn-key"><!-- --></i>
                                        </span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Error -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Current -->
                                    <div class="cat__core__step cat__core__step--squared cat__core__step--default">
                                        <span class="cat__core__step__digit">
                                            <i class="icmn-play2"><!-- --></i>
                                        </span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Current -->
                                </div>
                                <div class="col-lg-3">
                                    <!-- Example State Disabled -->
                                    <div class="cat__core__step">
                                        <span class="cat__core__step__digit">
                                            <i class="icmn-database"><!-- --></i>
                                        </span>
                                        <div class="cat__core__step__desc">
                                            <span class="cat__core__step__title">Block Title</span>
                                            <p>Waiting for review</p>
                                        </div>
                                    </div>
                                    <!-- End Example State Disabled -->
                                </div>
                            </div>
                        </div>
                        <div class="card-block">
                            <div class="row">
                                <div class="col-lg-12">
                                    card Body Content
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="text-black"><strong>Inline Steps</strong></h5>
                <p class="text-muted">Element: <code>div.cat__core__steps-inline</code></p>
                <div class="cat__core__steps-inline mb-2 mr-2">
                    <div class="cat__core__step cat__core__step--primary"><i class="icmn-home"></i></div>
                    <div class="cat__core__step cat__core__step--primary">1</div>
                    <div class="cat__core__step cat__core__step--primary">2</div>
                    <div class="cat__core__step">3</div>
                </div>
                <div class="cat__core__steps-inline  mb-2 mr-2">
                    <a href="javascript: void(0);" class="cat__core__step cat__core__step--success"></a>
                    <a href="javascript: void(0);" class="cat__core__step cat__core__step--success cat__core__step--disabled"></a>
                    <a href="javascript: void(0);" class="cat__core__step"></a>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- END: components/steps -->