import { Component, OnInit,Input, NgModule } from '@angular/core';
import { DashboardService } from './../../../services/dashboard.service';

import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-stock-price',
  templateUrl: './stockPrice.component.html',
  styleUrls: ['./stockPrice.component.css'],
})
export class StockPriceComponent implements OnInit {
  @Input() data: any[];
  nseStockClosingPrice: string = '';
  nsePreviousStockClosingPrice: string = '';
  nseDifferenceInStockPrice: any;
  nsePercentageChange: any;

  nsePreviousSPLow: string = '';
  nsePreviousSPHigh: string = '';
  nseWeek52SPLow: string = '';
  nseWeek52SPHigh: string = '';

  bseStockClosingPrice: string = '';
  bsePreviousStockClosingPrice: string = '';
  bseDifferenceInStockPrice: any;
  bsePercentageChange: any;

  bsePreviousSPLow: string = '';
  bsePreviousSPHigh: string = '';
  bseWeek52SPLow: string = '';
  bseWeek52SPHigh: string = '';

  standaloneMCapChart: Object;
  consolidatedMCapChart: Object;
  standalonePEChart: Object;
  consolidatedPEChart: Object;
  standaloneBookValueChart: Object;
  consolidatedBookValueChart: Object;

  standaloneMCapData: any = [];
  consolidatedMCapData: any = [];
  standalonePEData: any = [];
  consolidatedPEData: any = [];
  standaloneBookValueData: any = [];
  consolidatedBookValueData: any = [];

  isNSEDataFound: boolean = true;
  isBSEDataFound: boolean = true;
  companyId: any;
  constructor(
    private dashboardService: DashboardService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    //$("body").removeClass("dashboard-page");
    
    this.get52WeekEquityData();
    console.log('hello parth');
    $('body').addClass('dashboard-page dark-theme');
    console.log('stock data',this.data['RegistrationNumber']);
    this.getStockPriceData();
  }

  ngAfterViewInit() {
    
  }

  getStockPriceData(){
    this.dashboardService.getStockPriceData(this.data['RegistrationNumber']).then(stockPriceData => {
        console.log('stockPriceData: ',stockPriceData);
    });
  }

  // getStockPriceData() {
  //
  //     this.dashboardService.getStockPriceData().then(stockPriceData => {
  //         if (stockPriceData.status == 0) {
  //             if (stockPriceData.stockPrice != null) {
  //                 this.stockClosingPrice = stockPriceData.stockPrice.ClosePrice;
  //                 this.previousStockClosingPrice = stockPriceData.stockPrice.PreviousClosePrice == undefined ? stockPriceData.stockPrice.ClosePrice : stockPriceData.stockPrice.PreviousClosePrice;
  //                 this.differenceInStockPrice = (stockPriceData.stockPrice.ClosePrice - (stockPriceData.stockPrice.PreviousClosePrice == undefined ? stockPriceData.stockPrice.ClosePrice : stockPriceData.stockPrice.PreviousClosePrice));
  //                 this.percentageChange = (this.differenceInStockPrice / (stockPriceData.stockPrice.PreviousClosePrice == undefined ? stockPriceData.stockPrice.ClosePrice : stockPriceData.stockPrice.PreviousClosePrice)) * 100;

  //                 this.previousSPLow = stockPriceData.stockPrice.PreviousDayLow;
  //                 this.previousSPHigh = stockPriceData.stockPrice.PreviousDayHigh;
  //                 this.week52SPLow = stockPriceData.stockPrice.Week52Low;
  //                 this.week52SPHigh = stockPriceData.stockPrice.Week52High;
  //             }
  //
  //         }
  //     });
  // }

  // getStockPriceData() {
  //   this.dashboardService.getStockPriceData().then((stockPriceData) => {
  //     if (stockPriceData.status == 0) {
  //       if (stockPriceData.nseStockPrice != null) {
  //         this.isNSEDataFound = true;
  //         this.nseStockClosingPrice = stockPriceData.nseStockPrice.ClosePrice;
  //         this.nsePreviousStockClosingPrice =
  //           stockPriceData.nseStockPrice.PreviousClosePrice == undefined
  //             ? stockPriceData.nseStockPrice.ClosePrice
  //             : stockPriceData.nseStockPrice.PreviousClosePrice;
  //         this.nseDifferenceInStockPrice =
  //           stockPriceData.nseStockPrice.ClosePrice -
  //           (stockPriceData.nseStockPrice.PreviousClosePrice == undefined
  //             ? stockPriceData.nseStockPrice.ClosePrice
  //             : stockPriceData.nseStockPrice.PreviousClosePrice);
  //         this.nsePercentageChange =
  //           (this.nseDifferenceInStockPrice /
  //             (stockPriceData.nseStockPrice.PreviousClosePrice == undefined
  //               ? stockPriceData.nseStockPrice.ClosePrice
  //               : stockPriceData.nseStockPrice.PreviousClosePrice)) *
  //           100;

  //         this.nsePreviousSPLow = stockPriceData.nseStockPrice.PreviousDayLow;
  //         this.nsePreviousSPHigh = stockPriceData.nseStockPrice.PreviousDayHigh;
  //         this.nseWeek52SPLow = stockPriceData.nseStockPrice.Week52Low;
  //         this.nseWeek52SPHigh = stockPriceData.nseStockPrice.Week52High;
  //       } else this.isNSEDataFound = false;

  //       if (stockPriceData.bseStockPrice != null) {
  //         this.isBSEDataFound = true;
  //         this.bseStockClosingPrice = stockPriceData.bseStockPrice.ClosePrice;
  //         this.bsePreviousStockClosingPrice =
  //           stockPriceData.bseStockPrice.PreviousClosePrice == undefined
  //             ? stockPriceData.bseStockPrice.ClosePrice
  //             : stockPriceData.bseStockPrice.PreviousClosePrice;
  //         this.bseDifferenceInStockPrice =
  //           stockPriceData.bseStockPrice.ClosePrice -
  //           (stockPriceData.bseStockPrice.PreviousClosePrice == undefined
  //             ? stockPriceData.bseStockPrice.ClosePrice
  //             : stockPriceData.bseStockPrice.PreviousClosePrice);
  //         this.bsePercentageChange =
  //           (this.bseDifferenceInStockPrice /
  //             (stockPriceData.bseStockPrice.PreviousClosePrice == undefined
  //               ? stockPriceData.bseStockPrice.ClosePrice
  //               : stockPriceData.bseStockPrice.PreviousClosePrice)) *
  //           100;

  //         this.bsePreviousSPLow = stockPriceData.bseStockPrice.PreviousDayLow;
  //         this.bsePreviousSPHigh = stockPriceData.bseStockPrice.PreviousDayHigh;
  //         this.bseWeek52SPLow = stockPriceData.bseStockPrice.Week52Low;
  //         this.bseWeek52SPHigh = stockPriceData.bseStockPrice.Week52High;
  //       } else this.isBSEDataFound = false;
  //     }
  //   });
  // }

  get52WeekEquityData() {
    this.dashboardService.get52WeekEquityData().then((equityData) => {
      if (equityData.status == 0) {
        var standaloneData: any;
        var consolidatedData: any;
        standaloneData = _.first(
          _.filter(equityData.companyEquityData, function (o) {
            return o.Type == 'S';
          })
        );
        consolidatedData = _.first(
          _.filter(equityData.companyEquityData, function (o) {
            return o.Type == 'C';
          })
        );

        if (typeof standaloneData !== 'undefined') {
          standaloneData.companyEquityData.forEach((element) => {
            this.standaloneMCapData.push([
              this.convertToJSONDate(element.PriceDate),
              element.MarketCapitalization,
            ]);
            this.standalonePEData.push([
              this.convertToJSONDate(element.PriceDate),
              element.PE,
            ]);
            this.standaloneBookValueData.push([
              this.convertToJSONDate(element.PriceDate),
              element.BookValuePerShare,
            ]);
          });
        }

        if (typeof consolidatedData !== 'undefined') {
          consolidatedData.companyEquityData.forEach((element) => {
            this.consolidatedMCapData.push([
              this.convertToJSONDate(element.PriceDate),
              element.MarketCapitalization,
            ]);
            this.consolidatedPEData.push([
              this.convertToJSONDate(element.PriceDate),
              element.PE,
            ]);
            this.consolidatedBookValueData.push([
              this.convertToJSONDate(element.PriceDate),
              element.BookValuePerShare,
            ]);
          });
        }
        this.getStandaloneMCAPData();
      }
    });
  }

  //M-CAP
  getStandaloneMCAPData() {
    this.standaloneMCapChart = {
      chart: {
        type: 'spline',
        renderTo: 'standaloneMCapContainer',
      },
      title: { text: 'Market Capitalization (in Rs. Millions)' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      plotOptions: {
        series: {
          color: '#00ff00',
        },
      },
      series: [
        {
          name: 'M-CAP',
          data: this.standaloneMCapData,
          allowPointSelect: true,
          color: '#00ff00',
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    // console.log('*standaloneMCapChart*', this.standaloneMCapChart)
    $('.widget_sp').removeClass('active');
    $('#standaloneMCAPSection').addClass('active');
    $('#standaloneMCAPDataSection').css('display', 'block');
    $('#consolidatedMCAPDataSection').css('display', 'none');
  }

  getConsolidatedMCAPData() {
    this.consolidatedMCapChart = {
      chart: {
        type: 'spline',
        renderTo: 'consolidatedMCapContainer',
      },
      title: { text: 'Market Capitalization (in Rs. Millions)' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      series: [
        {
          name: 'M-CAP',
          data: this.consolidatedMCapData,
          allowPointSelect: true,
          color: '#00ff00',
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    $('.widget_sp').removeClass('active');
    $('#consolidatedMCAPSection').addClass('active');
    $('#consolidatedMCAPDataSection').css('display', 'block');
    $('#standaloneMCAPDataSection').css('display', 'none');
  }
  //M-CAP -- END --

  //PE
  getStandalonePEData() {
    this.standalonePEChart = {
      chart: {
        type: 'spline',
        renderTo: 'standalonePEContainer',
      },
      // title: { text: 'Price-Earning' },
      title: { text: 'PE' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      series: [
        {
          name: 'PE',
          data: this.standalonePEData,
          allowPointSelect: true,
          color: '#00ff00',
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    $('.widget_sp').removeClass('active');
    $('#standalonePESection').addClass('active');
    $('#standalonePEDataSection').css('display', 'block');
    $('#consolidatedPEDataSection').css('display', 'none');
  }

  getConsolidatedPEData() {
    this.consolidatedPEChart = {
      chart: {
        type: 'spline',
        renderTo: 'consolidatedPEContainer',
      },
      title: { text: 'Price-Earning' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      series: [
        {
          name: 'PE',
          color: '#00ff00',
          data: this.consolidatedPEData,
          allowPointSelect: true,
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    $('.widget_sp').removeClass('active');
    $('#consolidatedPESection').addClass('active');
    $('#consolidatedPEDataSection').css('display', 'block');
    $('#standalonePEDataSection').css('display', 'none');
  }
  //PE -- END --

  //Book Value
  getStandaloneBookValueData() {
    this.standaloneBookValueChart = {
      chart: {
        type: 'spline',
        renderTo: 'standaloneBookValueContainer',
      },
      title: { text: 'Book Value (Per Share)' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      series: [
        {
          name: 'Book Value',
          data: this.standaloneBookValueData,
          allowPointSelect: true,
          color: '#00ff00',
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    $('.widget_sp').removeClass('active');
    $('#standaloneBookValueSection').addClass('active');
    $('#standaloneBookValueDataSection').css('display', 'block');
    $('#consolidatedBookValueDataSection').css('display', 'none');
  }

  getConsolidatedBookValueData() {
    this.consolidatedBookValueChart = {
      chart: {
        type: 'spline',
        renderTo: 'consolidatedBookValueContainer',
      },
      title: { text: 'Book Value (Per Share)' },
      credits: {
        enabled: false,
      },
      rangeSelector: {
        buttonPosition: {
          align: 'left',
        },
        inputEnabled: false,
      },
      series: [
        {
          name: 'Book Value',
          data: this.consolidatedBookValueData,
          allowPointSelect: true,
          color: '#00ff00',
          tooltip: {
            valueDecimals: 2,
          },
        },
      ],
    };
    $('.widget_sp').removeClass('active');
    $('#consolidatedBookValueSection').addClass('active');
    $('#consolidatedBookValueDataSection').css('display', 'block');
    $('#standaloneBookValueDataSection').css('display', 'none');
  }
  //Book Value -- END --

  tabToogle(tabName) {
    if (tabName == 'MCAP') {
      this.getStandaloneMCAPData();
    } else if (tabName == 'PE') {
      this.getStandalonePEData();
    } else if (tabName == 'BookValue') {
      this.getStandaloneBookValueData();
    }
  }

  convertToJSONDate(strDate) {
    var dt = new Date(strDate);
    var newDate = new Date(
      Date.UTC(
        dt.getFullYear(),
        dt.getMonth(),
        dt.getDate(),
        dt.getHours(),
        dt.getMinutes(),
        dt.getSeconds(),
        dt.getMilliseconds()
      )
    );
    return newDate.getTime();
  }

  getStandaloneData() {
    $('.widget_sp').removeClass('active');
    $('#standaloneSection').addClass('active');
  }

  getConsolidatedData() {
    $('.widget_sp').removeClass('active');
    $('#consolidatedSection').addClass('active');
  }
}
