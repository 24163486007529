import { CreateMembershipProps,Recording, MuteAll } from "../types";
import { baseUrl, getHeaders } from "../urls";
import { safeJsonStringify } from '../../../../shared/utils';

export class Membership {
  addParticipent(params: CreateMembershipProps) {
    return new Promise((resolve, reject) => {
      fetch(baseUrl + '/v1/memberships', {
        method: 'post',
        body: safeJsonStringify(params),
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

    listParticipent(roomId) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "/v1/memberships?roomId=" + roomId, {
                method: "GET",
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(resJson => resolve(resJson))
                .catch(err => reject(err))
        })
    }
   
    listMeetings(meetingNumber) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "/v1/meetings?meetingNumber=" + meetingNumber, {
                method: "GET",
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(resJson => resolve(resJson))
                .catch(err => reject(err))
        })
    }
   
    recordingUpdate(meetingId, params: Recording) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "/v1/meetings/controls?meetingId=" + meetingId, {
                method: 'PUT',
                body: JSON.stringify(params),
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(resJson => resolve(resJson))
                .catch(err => reject(err))
        })
    }

    listMeetingParticipants(meetingId) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "/v1/meetingParticipants?meetingId=" + meetingId, {
                method: "GET",
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(resJson => resolve(resJson))
                .catch(err => reject(err))
        })
    }

    MuteAllUpdateParticipant(participantId, params: MuteAll) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "/v1/meetingParticipants/" + participantId, {
                method: 'PUT',
                body: JSON.stringify(params),
                headers: getHeaders()
            })
                .then(res => res.json())
                .then(resJson => resolve(resJson))
                .catch(err => reject(err))
        })
    }

}
