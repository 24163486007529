import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MeetingagendacomplianceComponent } from '../meetingagendacompliance/meetingagendacompliance.component';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
declare var $: any;
declare var jQuery: any;

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/Rx';

@Component({
  selector: 'app-meetingagenda',
  templateUrl: './meetingagenda.component.html',
  styleUrls: ['./meetingagenda.component.css']
})
export class MeetingagendaComponent implements OnInit {

  @Input('agendaObject') agendaObject: any;
  @Input('meetingObject') meetingObject: any;
  @Input('clickedAgenda') clickedAgenda: any;
  @Input('loadAgendaFromPagignation') loadAgendaFromPagignation: any;
  @Output() emitFileURL = new EventEmitter();

  agendaCompliances: any;
  isAgendaObjectEmpty: boolean;
  @ViewChild(DataTableDirective)
  dtElementAgendaCompliancePopUp: DataTableDirective;
  dtOptionsOfAgendaCompliancePopUp: DataTables.Settings = {};
  dtTriggerAgendaCompliancePopUp: Subject<any> = new Subject();

  agendaPdfIsAvailable: any;
  agendaUplaodedPdfID: any;
  agendaGenerationTitle: any;
  agendaGenerationNumber: any;
  agendaGenerationID: any;
  AgendaFile: any;
  count: number = null;
  AttachMent: any;
  showAgenda: any;
  hideAgenda: any;
  AgdNum: any;
  Number: any;
  FinalUrl: any;
  groupList: any = [];
  urlSafe: SafeResourceUrl;
  page: any = 0;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private meetingMasterService: MeetingMasterService,
    private sanitizer: DomSanitizer) {

    this.meetingMasterService.Pagination.subscribe(id => {
      this.Number = id;
    })
  }

  ngOnChanges(){
    if(this.loadAgendaFromPagignation && this.loadAgendaFromPagignation.agendaId){
      console.log("000000", this.loadAgendaFromPagignation)
      this.clickFunc(this.loadAgendaFromPagignation.agendaId, this.loadAgendaFromPagignation.page)
    }
  }

  ngOnInit() {

    console.log('agendaObject:', this.agendaObject);
    let user = JSON.parse(localStorage.getItem("user"));

    this.agendaObject.forEach((element, index) => {
      element.AgendaAccessRights.forEach(subElement=>{
        if ((subElement.UserGovevaId == user._id) && (subElement.View == false)) {
          console.log(index)
          this.agendaObject.splice(index,1)

        }
      })
      
    });
    this.count = this.agendaObject.length;
    localStorage.setItem("AgendaCount", this.agendaObject.length);
    if (_.size(this.agendaObject)) {

      this.agendaObject = _.sortBy(this.agendaObject, o => o.Agendaorder)
      this.agendaObject = (this.agendaObject).map(function (item) {
        var o = Object.assign({}, item);

        o.fileType = (item.AgendaGenerationPdf).map(function (subItem) {
          var oSub = Object.assign({}, subItem);
          oSub = (subItem.UploadFileName).slice(-3)

          return oSub;
        });

        return o;

      });

      this.isAgendaObjectEmpty = false;
    }
    else {
      this.isAgendaObjectEmpty = true
    }

    this.dtOptionsOfAgendaCompliancePopUp = {
      destroy: true,
      scrollY: "400px",
      scrollCollapse: true,
      pagingType: 'full_numbers',
      paging: true,
      pageLength: 5,
      search: true,
      order: [1, 'asc'],
      columnDefs: [{ orderable: false, targets: [0, 1, 2, 3] }],
      lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"]],
    }
  }

  agendasCompliancepopUpDataToShow(agenda) {
    const modalRef = this.modalService.open(MeetingagendacomplianceComponent, { size: 'lg' });
    modalRef.componentInstance.agendaComplianceObject = agenda.AgendaCompliance;
  }

  initiateUploadAgendaPdf(data) {

    this.agendaPdfIsAvailable = data.AgendaGenerationPdf.IsPdfAvailable;
    this.agendaUplaodedPdfID = data.AgendaGenerationPdf.DocumentManagementID;
    this.agendaGenerationTitle = data.AgendaTitle;
    this.agendaGenerationNumber = data.AgendaNumber;
    this.agendaGenerationID = data._id;

    this.AgendaFile = data.AgendaGenerationPdf.map(element => {

      var o = Object.assign({}, element)
      o.FileType = (element.PdfName).substr(-3)

      return o;
    })

    $("#AgendaPdfUploadTitle").html("Attachment For Agenda");
    $("#agendaPdfUploadDialog").modal('toggle');
  }


  downloadDocumentFile(fileID, fileType) {
    console.log("fileTypefileType", fileType)
    if (fileType == 'PDF' || fileType == 'pdf') {
      $('#myModalForDocument').data('modal', null);
      console.log('meetingagendacomponent')
      this.meetingMasterService.getAttendanceFileName(fileID).then(response => {
        var url = environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0";
        var arr = url.split('https:'); // split string        
        var exc = arr[1];
        var url_exc = encodeURIComponent(exc);
        var newUrlPdf = url_exc + '/' + this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName)
        this.AttachMent = location.protocol + "//" + location.host + "/#/pdfviewer/https:" + newUrlPdf;
        this.showAgenda = false;
        this.hideAgenda = true;
        $("#agendaPdfUploadDialogPSPFD").modal('hide');
        $('body').css('overflow', 'hidden');
        $('.modal').css('overflow', 'auto');
      })
    } else {
      this.meetingMasterService.getAttendanceFile(fileID);
    }
  }

  showAgendaDetail(id, agenda = null) {
    id = parseInt(id)
    this.meetingMasterService.Pagination.next(id);
    document.getElementById('agendaDetails').style.display = "block";
    document.getElementById('Document').style.display = "none";
    document.getElementById('Agenda').style.display = "none";
    document.getElementById('Attendance').style.display = "none";
    document.getElementById('individual-meeting').style.display = "none";
    document.getElementById('agendaAnalytics').style.display = "none";
  }

  async clickFunc(fileId, page = null) {
    const FinalUrlObj: any = await this.getFileUrlPlain(fileId);
    const FinalUrl =  FinalUrlObj.url
    this.clickedAgenda = FinalUrl;
    if (FinalUrl) {
      this.emitFileURL.emit({FinalUrl, page: parseInt(page), filename : FinalUrlObj.filename})
    }
  }

  getFileUrlPlain = async (fileID) => {
    console.log("sklog> getFileUrlPlain hit")

    const response = await this.meetingMasterService.getAttendanceFileName(fileID)
    var url = environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0";
    
    var filename ;
    try{
      console.log("sklog> getFileUrlPlain filename array:",response.data.Filename.split("-"))
      filename  = response.data.Filename.split("-")[1] ;
      //console.error("sklog> getFileUrlPlain filename:",filename)
      if(!filename){
        filename = "File";
      }
    }catch(ex){
      //console.error("Unable to split and fetch filename error:",ex);
      filename  = "File"
    }
    
    return {url:url,filename:filename};
  }

  getFileUrl = async (fileID) => {
    console.log("sklog> getFileUrl hit")

    const response = await this.meetingMasterService.getAttendanceFileName(fileID)
    var url = environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0";
    var arr = url.split('https:'); // split string        
    var exc = arr[1];
    var url_exc = encodeURIComponent(exc);
    console.log("sklog> getFileUrl url:",url)
    var newUrlPdf = url_exc + '/' + this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName)
    this.FinalUrl = location.protocol + "//" + location.host + "/#/pdfviewer/https:" + newUrlPdf;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.FinalUrl);
    this.groupList.push({ 'name': this.FinalUrl });
    return this.FinalUrl;
  }
}
