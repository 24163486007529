<!-- START: forms/selectboxes -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Default Selectbox</strong>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Single Item</strong></h5>
                    <p class="text-muted">Element: <code>select</code></p>
                    <p class="text-muted">Modifier: <code>[disabled]</code></p>
                    <div class="form-group">
                        <label>Enabled</label>
                        <select class="form-control">
                            <option>Option 1</option>
                            <option disabled>Option 2 (disabled)</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Disabled</label>
                        <select class="form-control" disabled>
                            <option>Option 1</option>
                            <option disabled>Option 2 (disabled)</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Multiple Items</strong></h5>
                    <p class="text-muted">Modifier: <code>[multiple]</code></p>
                    <div class="form-group">
                        <label>Enabled</label>
                        <select class="form-control" size="7" multiple>
                            <option>Option 1</option>
                            <option disabled>Option 2 (disabled)</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                            <option>Option 6</option>
                            <option>Option 7</option>
                            <option>Option 8</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Bootstrap Select</strong>
            <a href="https://silviomoreto.github.io/bootstrap-select/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Standard Select Boxes</strong></h5>
                    <p class="text-muted">Element: read <a href="https://silviomoreto.github.io/bootstrap-select/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Bootstrap Select Single Item -->
                    <select class="selectpicker">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>
                    <!-- End Bootstrap Select Single Item -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Select Boxes w/ Optgroups</strong></h5>
                    <p class="text-muted">Element: read <a href="https://silviomoreto.github.io/bootstrap-select/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Select Boxes w/ Optgroups -->
                    <select class="selectpicker">
                        <optgroup label="Picnic">
                            <option>Mustard</option>
                            <option>Ketchup</option>
                            <option>Relish</option>
                        </optgroup>
                        <optgroup label="Camping">
                            <option>Tent</option>
                            <option>Flashlight</option>
                            <option>Toilet Paper</option>
                        </optgroup>
                    </select>
                    <!-- End Select Boxes w/ Optgroups -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Live Search</strong></h5>
                    <p class="text-muted">Element: read <a href="https://silviomoreto.github.io/bootstrap-select/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Live Search -->
                    <select class="selectpicker" data-live-search="true">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>
                    <!-- End Live Search -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Placeholder</strong></h5>
                    <p class="text-muted">Element: read <a href="https://silviomoreto.github.io/bootstrap-select/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Placeholder -->
                    <select class="selectpicker" multiple title="Choose one of the following...">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>
                    <!-- End Placeholder  -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Button Classes</strong></h5>
                    <p class="text-muted">Modifier: <code>[data-style]</code>: <code>btn-primary</code>, <code>btn-info</code>, <code>btn-success</code>, <code>btn-warning</code>, <code>btn-danger</code></p>
                    <!-- Button Classes -->
                    <select class="selectpicker" data-style="btn-primary">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>

                    <select class="selectpicker" data-style="btn-info">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>

                    <select class="selectpicker" data-style="btn-success">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>

                    <select class="selectpicker" data-style="btn-warning">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>

                    <select class="selectpicker" data-style="btn-danger">
                        <option>Mustard</option>
                        <option>Ketchup</option>
                        <option>Relish</option>
                    </select>
                    <!-- End Button Classes -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Select2</strong>
            <a href="https://select2.github.io/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Single Item</strong></h5>
                    <p class="text-muted">Element: read <a href="https://select2.github.io/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Select2 Single Item -->
                    <div class="form-group">
                        <label>Enabled</label>
                        <select class="select2">
                            <option>Option 1</option>
                            <option disabled>Option 2 (disabled)</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Disabled</label>
                        <select class="select2" disabled>
                            <option>Option 1</option>
                            <option disabled>Option 2 (disabled)</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Grouped</label>
                        <select class="select2">
                            <optgroup label="Group 1">
                                <option>Option 1</option>
                                <option>Option 2</option>
                            </optgroup>
                            <optgroup label="Group 2">
                                <option>Option 3</option>
                                <option>Option 4</option>
                                <option>Option 5</option>
                            </optgroup>
                        </select>
                    </div>
                    <!-- End Select2 Single Item  -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Multiple Items</strong></h5>
                    <p class="text-muted">Element: read <a href="https://select2.github.io/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Select2 Multiple Item -->
                    <div class="form-group">
                        <label>Enabled</label>
                        <select class="select2" multiple>
                            <option selected>Option 1</option>
                            <option>Option 2</option>
                            <option selected>Option 3</option>
                            <option>Option 4</option>
                            <option selected>Option 5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Disabled</label>
                        <select class="select2" multiple disabled>
                            <option selected>Option 1</option>
                            <option>Option 2</option>
                            <option selected>Option 3</option>
                            <option>Option 4</option>
                            <option selected>Option 5</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Grouped</label>
                        <select class="select2" multiple>
                            <optgroup label="Group 1">
                                <option selected>Option 1</option>
                                <option>Option 2</option>
                            </optgroup>
                            <optgroup label="Group 2">
                                <option selected>Option 3</option>
                                <option>Option 4</option>
                                <option>Option 5</option>
                            </optgroup>
                        </select>
                    </div>
                    <!-- End Select2 Multiple Item -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-5">
                    <h5 class="text-black"><strong>Dynamic Tags</strong></h5>
                    <p class="text-muted">Element: read <a href="https://select2.github.io/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                    <!-- Select2 Dynamic Tags -->
                    <div class="form-group">
                        <select class="select2-tags" multiple>
                            <option selected>Type tag and press Enter</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                            <option>Option 4</option>
                            <option>Option 5</option>
                        </select>
                    </div>
                    <!-- End Select2 Dynamic Tags -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: forms/selectboxes -->
