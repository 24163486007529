import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CommonFunction } from '../../app/helpers/commonFunction';

@Injectable()
export class OrganisationChartService {
  constructor(
    private http: HttpClient,
    private httpWithoutToken: HttpClient,
    private handler: HttpBackend,
    private commonFunction: CommonFunction
  ) {
    this.httpWithoutToken = new HttpClient(handler);
  }

  

    getDirectorHierarchyData(): Promise<any> {
        return this.http.get(environment.clientUrl + "user/directorscompanyHierarchy")
            .toPromise()
            .then(response => {
                return Promise.resolve(response)
            })
    }

    getManagementHierarchyData() {
        return this.http.get(environment.clientUrl + "user/execmanagementcompanyHierarchy")
            .toPromise()
            .then((response: any) => {
                return Promise.resolve({response,companyHierarchy: JSON.parse(this.commonFunction.decrypt(response.companyHierarchy)) })
            })
    }

  getDiscloserOfInterestOfDirectors(directorsList): Promise<any> {
    return this.httpWithoutToken
      .post(
        environment.pssServerUrl + 'getDisclosureOfIneterstCount',
        directorsList
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }
}
