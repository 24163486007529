// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { OTPLoginService } from '../services/otp-login.service';
import { RocketChatService } from '../services/rocketChat.service';
import { LastLoginDetailsService } from '../services/last-login-details.service';
import * as CryptoJS from 'crypto-js';
import { CompanyMasterService } from '../services/companymaster.service';
import { rocketChatRemove } from '../enums/rocketChatRemove';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './notification/notification.component';
// import { SuccessNotificationComponent } from './success-notification/success-notification.component';
import { safeJsonStringify } from '../shared/utils';

declare var $: any;
declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css'],
})
export class OTPLoginComponent implements OnInit {
  companyList: any;
  selectedCompanyId: any;
  chatEngineURL: any;
  eva: any;
  returnUrl: any;
  listOfCompanyDetails: any;
  loginModel: any;
  userName: string;
  mobileNumber: string;
  isADCompany: boolean = false;
  LoginDisabled: boolean = false;
  userLoginDetails: any;
  isADUser: boolean = false;
  adUserEmailId: string;
  isEmailForCompany: string = 'Please enter a valid email id or username';
  loginForm = new FormGroup({
    companyName: new FormControl('', [Validators.required]),
    userName: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('', [
    //   Validators.required
    // ]),
    mobileNumber: new FormControl('', [
      Validators.required,
      Validators.pattern("[0-9]{8,15}"),
    ])
  });
  constructor(
    private router: Router,
    private companyService: CompanyMasterService,
    private loginService: OTPLoginService,
    private rocketChatService: RocketChatService,
    private route: ActivatedRoute,
    private lastloginService: LastLoginDetailsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    var token = localStorage.getItem('token');
    var CopyEmailAddress = localStorage.getItem('CopyEmailAddress');
    this.userName = CopyEmailAddress;
    window.localStorage.removeItem('CopyEmailAddress');

    //added on 4Apr
    if (_.size(this.route.snapshot.queryParams['emailId'])) {
      this.userName = this.route.snapshot.queryParams['emailId'];
      this.loginForm.controls.userName.updateValueAndValidity();
      this.getCompaniesByEmailId();
    }

    var returnURL = this.returnUrl;
    var splitQueryParameters = '';
    if (returnURL != undefined) {
      splitQueryParameters = returnURL.split('?');
    }
    //
    if (_.isNil(token)) {
      this.getCompanies();
      this.hideTopBar();
    }
    //following condition occures when user login through pss and jumps to goveva menus
    else if (splitQueryParameters.length === 2) {
      var queryParameters = splitQueryParameters[1];
      var parameters = queryParameters.split('&');
      if (parameters.length === 2) {
        var IsgoEvaUser = parameters[0].split('=');
        var goEvaUserEmail = parameters[1].split('=');
        if (IsgoEvaUser[1]) {
          var loginWithoutPassword = {
            UserName: goEvaUserEmail[1],
          };
          //login from PSS and click on the goveva menu then [login into Goveva system without password and redirect to request page [This request coming from PSS]
          this.loginService
            .loginWithoutPassword(loginWithoutPassword)
            .subscribe((data) => {
              // @ts-ignore
              if (data.status == 0) {
                // @ts-ignore
                localStorage.setItem('user', safeJsonStringify(data.user));
                // @ts-ignore
                localStorage.setItem('token', data.token);
                // @ts-ignore
                localStorage.setItem(
                  'resources',
                  safeJsonStringify(data.resources)
                );
                //this.router.navigate([splitQueryParameters[0]]);
                this.router.navigate([
                  'structure',
                  {
                    outlets: { 'structure-outlet': [splitQueryParameters[0]] },
                  },
                ]);
              }
            });
        }
      }
    } else if (splitQueryParameters.length === 3) {
      //console.log('checking for the PSS')
      let pssLink = splitQueryParameters.length - 1;
      window.open(pssLink.toString());
    } else {
      //if already login
      console.log('here it is!');
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
      // this.router.navigate(["structure"]);
    }
  }

  secretKey = 'Secret message';

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(
      CryptoJS.enc.Utf8
    );
  }

  blinkCur() {
    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(
      '<span class="blinking-cursor">' + showText + '|</span>'
    );
    // $('.fake-input span').text('|');
  }
  blinkCurOut() {
    $('.fake-input span').text('');

    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    if (inputValue.length > 0) {
      for (i = 0; i < numChars; i++) {
        showText += '&#8226;';
      }

      $('.fake-input').html(showText);
    } else {
      $('.fake-input').html('Password');
    }
  }
  RemoveSavePassword() {
    var inputValue = this.loginForm.controls.password.value;
    var numChars = inputValue.length;
    var showText = '';
    var i;

    for (i = 0; i < numChars; i++) {
      showText += '&#8226;';
    }

    $('.fake-input').html(showText);
  }

  getCompanies() {
    this.loginService.companyListForUserManagement().then((companies) => {
      if (companies.status != 0) {
        this.router.navigate(['companySetup']);
      }
    });
  }

  getCompaniesByEmailId() {
    //get array of company Id using email id
    this.listOfCompanyDetails = [];
    this.companyList = [];
    let emailId = this.userName;
    if (emailId != '') {
      this.isEmailForCompany = 'Either due to low bandwidth or API unavailable';
      this.loginService.getCompanyIdsByEmailId(emailId).subscribe((companyIds) => {
        // @ts-ignore
        if (companyIds.status == 0) {
          // @ts-ignore
          if (companyIds.companyIds.length > 0) {
            this.loginService
              .getCompanyListByIds(companyIds)
              .subscribe((companies) => {
                // @ts-ignore
                if (companies.status == 0) {
                  this.loginForm.get('userName').clearValidators();
                  this.loginForm.get('userName').setErrors({ email: false });
                  this.loginForm.get('userName').updateValueAndValidity();

                  // @ts-ignore
                  this.listOfCompanyDetails = companies.companyList;
                  // @ts-ignore
                  this.companyList = companies.companyList.map(function (o) {
                    return {
                      label: o.CompanyName,
                      value: o._id,
                    };
                  });
                } else {
                  this.companyList = [];
                  // this.router.navigate(["companySetup"]);
                }
              });
          } else {
            this.isEmailForCompany =
              'Either due to low bandwidth or API unavailable';
          }

        } else {
          this.isEmailForCompany = "Please enter a valid email id or username";
        }
      });
    }

    // this.loginService.companyListForUserManagement().then(companies => {
    //   if (companies.status == 0) {
    //     // this.selectedCompanyId = companies.companyList[0]._id;
    //     // this.chatEngineURL = companies.companyList[0].ChatEngineURL;
    //     // this.eva = companies.companyList[0].EVA;
    //     this.listOfCompanyDetails = companies.companyList;
    //     this.companyList = companies.companyList.map(function (o) {
    //       return {
    //         'label': o.CompanyName,
    //         'value': o._id
    //       }
    //     });
    //   } else {
    //     this.router.navigate(["companySetup"]);
    //   }
    // });
  }

  login(loginDetails) {
    if (this.loginForm.status == 'VALID') {
      if (this.isADCompany) {
        this.loginModel = {
          UserName: this.loginForm.controls.userName.value,
          Password: this.loginForm.controls.password.value,
          CompanyId: this.selectedCompanyId,
        };
      } else {
        this.loginModel = {
          UserName: loginDetails.UserName,
          Password: loginDetails.Password,
          // "MobileNumber": this.loginForm.controls.mobileNumber.value,
          CompanyId: loginDetails.CompanyId,
        };
      }

      // localStorage.setItem('UserName', this.loginModel.UserName);
      const self = this;

      if (
        _.toLower(this.loginForm.controls.userName.value) ==
        'superadmin@gmail.com'
      ) {
        this.loginFunction(this.userLoginDetails);
      } else {
        // check for company's details
        // this.companyService.getCompanyDetails(this.loginModel).then(companyData => {
        //     console.log("companyService", companyData)

        if (this.isADCompany) {
          this.loginForADUser();
        } else {
          this.loginFunction(this.userLoginDetails);
        }
        // end of companies data
        // })
      }
    }
  }

  loginFunction(userLoginDetails) {
    console.log('this.loginModel', userLoginDetails);
    this.LoginDisabled = true;
    this.loginService.login(userLoginDetails).then((data) => {
      const self = this;
      let listOfCompany = _.groupBy(self.listOfCompanyDetails, '_id');
      let companyNameAndValue = this.companyList.find(function (company) {
        return company.value == self.selectedCompanyId;
      });
      let companyData = _.first(listOfCompany[self.selectedCompanyId]);
      this.chatEngineURL = _.get(companyData, 'ChatEngineURL');
      this.eva = _.get(companyData, 'EVA');
      if (data.status == 0) {
        this.LoginDisabled = false;

        if (rocketChatRemove.isRemove.toString() != 'true') {
          let loginChatModel: any;
          loginChatModel = {
            user: data.user.RocketChatUserName, //FirstName + data.user.LastName,
            password: data.user.RocketChatPassword, //this.loginForm.controls.password.value//"Demo@1234"
          };

          this.rocketChatService
            .loginToRocketChat(loginChatModel, this.chatEngineURL)
            .then((rocketChatData) => {
              if (rocketChatData.status == 'success') {
                // location.reload();
                // Switching to the subsidiary data ----start
                localStorage.setItem('isSubsidiary', 'false');
                // Switching to the subsidiary data ----end
                localStorage.setItem(
                  'companyDetailsGoveva',
                  safeJsonStringify(companyNameAndValue)
                );
                localStorage.setItem(
                  'companyDetails',
                  safeJsonStringify({
                    companyName: companyNameAndValue.label,
                    companyID: companyNameAndValue.value,
                  })
                );
                localStorage.setItem('user', safeJsonStringify(data.user));
                const companyEva = this.eva ? this.eva : 'null';
                localStorage.setItem('companyEVA', companyEva);

                if (data.user.FirstTimeLogin) {
                  localStorage.setItem(
                    'rocketAuth',
                    rocketChatData.data.authToken
                  );
                  localStorage.setItem(
                    'rocketUserId',
                    rocketChatData.data.userId
                  );
                  localStorage.setItem('rocketBaseURL', this.chatEngineURL);
                  // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                  this.router.navigate(['/changePassword']);
                } else {
                  // location.reload();
                  // localStorage.setItem("user", safeJsonStringify(data.user));
                  localStorage.setItem('token', data.token);
                  localStorage.setItem(
                    'resources',
                    safeJsonStringify(data.resources)
                  );

                  localStorage.setItem(
                    'rocketAuth',
                    rocketChatData.data.authToken
                  );
                  localStorage.setItem(
                    'rocketUserId',
                    rocketChatData.data.userId
                  );
                  localStorage.setItem('rocketBaseURL', this.chatEngineURL);

                  this.showTopBar();
                  // this.router.navigate(["dashboards/alpha"]);
                  this.returnUrl =
                    this.route.snapshot.queryParams['returnUrl'] || '/';
                  console.log(' this.returnUrl', this.returnUrl);
                  if (this.returnUrl != '/') {
                    console.log(' 2222', this.returnUrl);
                    try {
                      var check64 =
                        btoa(atob(this.returnUrl)) == this.returnUrl;
                    } catch (err) {
                      var check64 = false;
                    }
                    if (check64 == true) {
                      this.returnUrl = atob(this.returnUrl);
                    } else {
                      this.returnUrl = this.returnUrl;
                    }
                    if (_.includes(this.returnUrl, 'http')) {
                      console.log(' 3333', this.returnUrl);

                      // let pssRdirectURL = _.trim(this.returnUrl.toString(), "http")
                      // let pssUrl = environment.pssUIURL + pssRdirectURL
                      // let splittedQueryParamsArray = this.returnUrl.split("?")
                      window.open(this.returnUrl, '_self');
                    } else {
                      console.log(' 4444', this.returnUrl);
                      // this.router.navigate(this.returnUrl);
                      const urlAfterSplit = this.returnUrl.split(':');
                      const lastElement =
                        urlAfterSplit[urlAfterSplit.length - 1];
                      const redirectUrl = _.first(lastElement.split(')'));
                      this.router.navigate([
                        'structure',
                        { outlets: { 'structure-outlet': redirectUrl } },
                      ]);
                      // location.reload();
                    }
                  } else {
                    console.log(' 4444', this.returnUrl);
                    // location.reload();
                    this.router.navigate([
                      'structure',
                      { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                    ]);
                  }
                }
              }
              // this.router.navigate(["structure"]);
            });
        } else {
          console.log(444444444444444444444444444444);

          // Switching to the subsidiary data ----start
          localStorage.setItem('isSubsidiary', 'false');
          // Switching to the subsidiary data ----end
          localStorage.setItem(
            'companyDetailsGoveva',
            safeJsonStringify(companyNameAndValue)
          );
          localStorage.setItem(
            'companyDetails',
            safeJsonStringify({
              companyName: companyNameAndValue.label,
              companyID: companyNameAndValue.value,
            })
          );
          localStorage.setItem('user', safeJsonStringify(data.user));
          const companyEva = this.eva ? this.eva : 'null';
          localStorage.setItem('companyEVA', companyEva);

          if (data.user.FirstTimeLogin) {
            localStorage.setItem('rocketBaseURL', this.chatEngineURL);
            // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            localStorage.setItem('token', data.token);
            this.router.navigate(['/changePassword']);
          } else {
            // location.reload();
            // localStorage.setItem("user", safeJsonStringify(data.user));

            // console.log("userpsaread", this.lastloginService.returnUserDetails(data.userWithLoginDetails));

            localStorage.setItem('token', data.token);
            localStorage.setItem(
              'resources',
              safeJsonStringify(data.resources)
            );

            localStorage.setItem('rocketBaseURL', this.chatEngineURL);

            self.showTopBar();
            // this.router.navigate(["dashboards/alpha"]);
            self.returnUrl =
              self.route.snapshot.queryParams['returnUrl'] || '/';
            console.log(' this.returnUrl', self.returnUrl);
            if (self.returnUrl != '/') {
              console.log(' 22222222uuu', self.returnUrl);

              try {
                var check64 = btoa(atob(this.returnUrl)) == this.returnUrl;
              } catch (err) {
                var check64 = false;
              }
              if (check64 == true) {
                this.returnUrl = atob(this.returnUrl);
              } else {
                this.returnUrl = this.returnUrl;
              }

              if (
                _.includes(self.returnUrl, 'http') ||
                _.includes(self.returnUrl, 'https')
              ) {
                console.log('666666', self.returnUrl);

                //  let pssRdirectURL = _.trim(self.returnUrl.toString(), "http")
                //  let pssUrl = environment.pssUIURL + pssRdirectURL
                // let splittedQueryParamsArray = this.returnUrl.split("?")
                window.open(self.returnUrl, '_self');
              } else {
                console.log('77777777', self.returnUrl);
                // this.router.navigate(this.returnUrl);
                const urlAfterSplit = self.returnUrl.split(':');
                const lastElement = urlAfterSplit[urlAfterSplit.length - 1];
                const redirectUrl = _.first(lastElement.split(')'));
                self.router.navigate([
                  'structure',
                  { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                ]);
                location.reload();
              }
            } else {
              console.log('888888', self.returnUrl);
              location.reload();
              self.router.navigate([
                'structure',
                { outlets: { 'structure-outlet': 'dashboards/alpha' } },
              ]);
            }
          }
        }
      } else {
        this.LoginDisabled = false;

        $('#alertTitle').css('color', '#f00');
        $('#alertTitle').text('Failure!');
        $('#alertMessage').css('color', '#f00');
        $('#messagePopUp').modal('toggle');
        $('#alertMessage').text(data.message);
        this.messagePopUpTimeoutToogle();
        self.router.navigate(['/login']);
      }
    });
  }

  loginForADUser() {
    // console.log("this.loginModel",this.loginModel);
    // this.companyService.getCompanyLoginForADUserWithCredentials(this.loginModel)
    this.loginService
      .loginForADUser(this.loginModel)
      .then((data) => {
        // console.log("companyService", data)

        //
        const self = this;
        let listOfCompany = _.groupBy(self.listOfCompanyDetails, '_id');
        let companyNameAndValue = this.companyList.find(function (company) {
          return company.value == self.selectedCompanyId;
        });
        let companyData = _.first(listOfCompany[self.selectedCompanyId]);
        this.chatEngineURL = _.get(companyData, 'ChatEngineURL');
        this.eva = _.get(companyData, 'EVA');
        if (data.status == 0) {
          if (rocketChatRemove.isRemove.toString() != 'true') {
            let loginChatModel: any;
            loginChatModel = {
              user: data.user.RocketChatUserName, //FirstName + data.user.LastName,
              password: data.user.RocketChatPassword, //this.loginForm.controls.password.value//"Demo@1234"
            };

            this.rocketChatService
              .loginToRocketChat(loginChatModel, this.chatEngineURL)
              .then((rocketChatData) => {
                if (rocketChatData.status == 'success') {
                  // location.reload();
                  // Switching to the subsidiary data ----start
                  localStorage.setItem('isSubsidiary', 'false');
                  // Switching to the subsidiary data ----end
                  localStorage.setItem(
                    'companyDetailsGoveva',
                    safeJsonStringify(companyNameAndValue)
                  );
                  localStorage.setItem(
                    'companyDetails',
                    safeJsonStringify({
                      companyName: companyNameAndValue.label,
                      companyID: companyNameAndValue.value,
                    })
                  );
                  localStorage.setItem('user', safeJsonStringify(data.user));
                  const companyEva = this.eva ? this.eva : 'null';
                  localStorage.setItem('companyEVA', companyEva);

                  // if (data.user.FirstTimeLogin) {
                  //   localStorage.setItem("rocketAuth", rocketChatData.data.authToken);
                  //   localStorage.setItem("rocketUserId", rocketChatData.data.userId);
                  //   localStorage.setItem("rocketBaseURL", this.chatEngineURL);
                  //   // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                  //   this.router.navigate(["/changePassword"]);
                  // } else {
                  // location.reload();
                  // localStorage.setItem("user", safeJsonStringify(data.user));
                  localStorage.setItem('token', data.token);
                  localStorage.setItem(
                    'resources',
                    safeJsonStringify(data.resources)
                  );

                  localStorage.setItem(
                    'rocketAuth',
                    rocketChatData.data.authToken
                  );
                  localStorage.setItem(
                    'rocketUserId',
                    rocketChatData.data.userId
                  );
                  localStorage.setItem('rocketBaseURL', this.chatEngineURL);

                  this.showTopBar();
                  // this.router.navigate(["dashboards/alpha"]);
                  this.returnUrl =
                    this.route.snapshot.queryParams['returnUrl'] || '/';
                  if (this.returnUrl != '/') {
                    try {
                      var check64 =
                        btoa(atob(this.returnUrl)) == this.returnUrl;
                    } catch (err) {
                      var check64 = false;
                    }
                    if (check64 == true) {
                      this.returnUrl = atob(this.returnUrl);
                    } else {
                      this.returnUrl = this.returnUrl;
                    }
                    if (_.includes(this.returnUrl, 'http')) {
                      // let pssRdirectURL = _.trim(this.returnUrl.toString(), "http")
                      // let pssUrl = environment.pssUIURL + pssRdirectURL
                      // let splittedQueryParamsArray = this.returnUrl.split("?")
                      window.open(this.returnUrl, '_self');
                    } else {
                      // this.router.navigate(this.returnUrl);
                      const urlAfterSplit = this.returnUrl.split(':');
                      const lastElement =
                        urlAfterSplit[urlAfterSplit.length - 1];
                      const redirectUrl = _.first(lastElement.split(')'));
                      this.router.navigate([
                        'structure',
                        { outlets: { 'structure-outlet': redirectUrl } },
                      ]);
                      location.reload();
                    }
                  } else {
                    location.reload();
                    this.router.navigate([
                      'structure',
                      { outlets: { 'structure-outlet': 'dashboards/alpha' } },
                    ]);
                  }

                  // }
                }
                // this.router.navigate(["structure"]);
              });
          } else {
            // Switching to the subsidiary data ----start
            localStorage.setItem('isSubsidiary', 'false');
            // Switching to the subsidiary data ----end
            localStorage.setItem(
              'companyDetailsGoveva',
              safeJsonStringify(companyNameAndValue)
            );
            localStorage.setItem(
              'companyDetails',
              safeJsonStringify({
                companyName: companyNameAndValue.label,
                companyID: companyNameAndValue.value,
              })
            );
            localStorage.setItem('user', safeJsonStringify(data.user));
            const companyEva = this.eva ? this.eva : 'null';
            localStorage.setItem('companyEVA', companyEva);

            //  if (data.user.FirstTimeLogin) {
            //    localStorage.setItem("rocketBaseURL", this.chatEngineURL);
            //    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            //    this.router.navigate(["/changePassword"]);
            //  } else {
            // location.reload();
            // localStorage.setItem("user", safeJsonStringify(data.user));
            localStorage.setItem('token', data.token);
            localStorage.setItem(
              'resources',
              safeJsonStringify(data.resources)
            );

            localStorage.setItem('rocketBaseURL', this.chatEngineURL);

            this.showTopBar();
            // this.router.navigate(["dashboards/alpha"]);
            this.returnUrl =
              this.route.snapshot.queryParams['returnUrl'] || '/';
            if (this.returnUrl != '/') {
              try {
                var check64 = btoa(atob(this.returnUrl)) == this.returnUrl;
              } catch (err) {
                var check64 = false;
              }
              if (check64 == true) {
                this.returnUrl = atob(this.returnUrl);
              } else {
                this.returnUrl = this.returnUrl;
              }
              if (_.includes(this.returnUrl, 'http')) {
                // let pssRdirectURL = _.trim(this.returnUrl.toString(), "http")
                // let pssUrl = environment.pssUIURL + pssRdirectURL
                // let splittedQueryParamsArray = this.returnUrl.split("?")
                window.open(this.returnUrl, '_self');
              } else {
                // this.router.navigate(this.returnUrl);
                const urlAfterSplit = this.returnUrl.split(':');
                const lastElement = urlAfterSplit[urlAfterSplit.length - 1];
                const redirectUrl = _.first(lastElement.split(')'));
                this.router.navigate([
                  'structure',
                  { outlets: { 'structure-outlet': redirectUrl } },
                ]);
                location.reload();
              }
            } else {
              location.reload();
              this.router.navigate([
                'structure',
                { outlets: { 'structure-outlet': 'dashboards/alpha' } },
              ]);
            }

            //  }
          }
        } else {
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').css('color', '#f00');
          $('#messagePopUp').modal('toggle');
          $('#alertMessage').text(data.message);
          this.messagePopUpTimeoutToogle();
        }
      })
      .catch((exception) => {
        console.log('exception', exception);
      });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  hideTopBar() {
    $('.cat__top-bar').hide();
    $('.cat__menu-left').hide(); //hide the sidebar
    $('.cat__top-bar__right').hide(); //hide the logout icon
    $('.cat__footer').hide();
  }

  showTopBar() {
    $('.cat__menu-left').show();
    $('.cat__top-bar__right').show();
    $('.cat__top-bar').show();
    $('.cat__footer').show();
  }

  companySelection(companyId) {
    // console.log("companyId",companyId)
    this.companyService.getcompanyDetailsNonValidateURL(companyId).subscribe(
      (companyData) => {
        // console.log("companyData",companyData)
        if (_.size(companyData) > 0) {
          // @ts-ignore
          if (
            !_.isUndefined(companyData.companyData) &&
            _.size(companyData.companyData) > 0
          ) {
            // @ts-ignore
            this.isADCompany =
              companyData.companyData.IsADSubscribedFlag || false;
          }
        }

        if (this.isADCompany) {
          this.loginForm.controls.userName.clearValidators();
          this.loginForm.controls.userName.setValidators([Validators.required]);
          this.loginForm.controls.userName.updateValueAndValidity();
        } else {
          this.loginForm.controls.userName.clearValidators();
          this.loginForm.controls.userName.setValidators([
            Validators.required,
            Validators.email,
          ]);
          this.loginForm.controls.userName.updateValueAndValidity();
        }
      },
      (error) => {
        // console.log("runtimeError : " , error)
      }
    );
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  async sendOTP() {
    var emailId = _.toLower(this.loginForm.get('userName').value);
    const companyId = this.loginForm.get('companyName').value;
    const mobileNumber = this.loginForm.get('mobileNumber').value;

    const self = this;
    let listOfCompany = _.groupBy(self.listOfCompanyDetails, '_id');
    let companyData = _.first(listOfCompany[companyId]);
    this.eva = _.get(companyData, 'EVA');

    var checkUserDetails = {
      EmailId: emailId,
      CompanyId: companyId.value, // check for the company ID
      MobileNumber: mobileNumber,
    };

    var getUserEmail = await this.loginService.getUserEmail(checkUserDetails);

    // .then(userEmailResult => {
    console.log('userEmailResult', getUserEmail);
    if (!getUserEmail.status) {
      if (getUserEmail.userDetails[0].ADID) {
        this.isADUser = true;
        this.adUserEmailId = getUserEmail.userDetails[0].EmailId;
        console.log('this.adUserEmailId', this.adUserEmailId);
      }
    } else {
      const modalRef = this.modalService.open(NotificationComponent);
      modalRef.componentInstance.notify = {
        status: 'Failure',
        message: getUserEmail.message,
      };
      return;
    }

    // });
    if (this.isADUser) {
      console.log('emailId', this.adUserEmailId);
      var loginOTPObject = {
        EmailId: this.adUserEmailId,
        CompanyId: companyId.value, // check for the company ID
        MobileNumber: mobileNumber,
      };
      console.log('loginOTPObject', loginOTPObject);
    } else {
      var loginOTPObject = {
        EmailId: emailId,
        CompanyId: companyId.value, // check for the company ID
        MobileNumber: mobileNumber,
      };
    }

    this.loginService.wrongOTPAttemptCheck(loginOTPObject).then((result) => {
      if (!result.status) {
        if (result.SecretCodeAttempt >= 5) {
          const modalRef = this.modalService.open(NotificationComponent);
          modalRef.componentInstance.notify = {
            status: 'Failure',
            message:
              'You have entered the wrong OTP five times.It will unblock after 15 minutes. Old OTP will not work after 15 minutes, So Generate new OTP after 15 Minutes.',
          };

          this.router.navigate(['login']);
        } else {
          this.loginService.submitEmail(loginOTPObject).then((response) => {
            if (!response.status) {
              localStorage.removeItem('userToken');
              localStorage.setItem('emailId', emailId);
              localStorage.setItem('companyId', companyId);
              localStorage.setItem('mobileNumber', mobileNumber);
              this.loginService.clearUserCredential();
              this.loginService.setUserCredential(response);
              localStorage.setItem('userToken', response.userToken);
              const companyEva = this.eva ? this.eva : 'null';
              localStorage.setItem('companyEVA', companyEva);
              this.redirectPath = decodeURIComponent(`${window.location.hash.split('returnUrl=')[1]}`)
              if (this.redirectPath == 'undefined') {
                this.router.navigate(['Submit/LoginOTP']);
              }
              else {
                this.router.navigate(['Submit/LoginOTP'], { queryParams: { returnUrl: this.redirectPath } });
              }

              const modalRef = this.modalService.open(NotificationComponent);
              modalRef.componentInstance.notify = {
                status: 'Success',
                message: response.message,
              };
            } else {
              // show error message to the user
              const modalRef = this.modalService.open(NotificationComponent);
              modalRef.componentInstance.notify = {
                status: 'Failure',
                message: response.message,
              };
              // this.modalService.open(NotificationComponent).result.then(result => {

              // })
            }
          });
        }
      } else {
        // show error message to the user
        const modalRef = this.modalService.open(NotificationComponent);
        modalRef.componentInstance.notify = {
          status: 'Failure',
          message: result.message,
        };
      }
    });
  }
}
