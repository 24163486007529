<!-- START: forms/internal assets -->
<div class="stockprice">
  <section class="card">
      <button _ngcontent-c3="" aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span _ngcontent-c3="" aria-hidden="true">×</span>
      </button>
      <div class="card-header">
          <div class="row">
              <!-- BSE -->
              <div class="col-lg-4 col-md-6">
                  <div class="row1">
                      <div class="row" *ngIf="!isBSEDataFound" style="text-transform: initial;">
                          <label class="col-lg-12">This Company is not listed in BSE</label>
                      </div>
                      <div class="row" *ngIf="isBSEDataFound">
                          <div class="col-md-12">
                              <span style="color: #808282;">CLOSING PRICE (BSE)</span>
                              <h3 class="mb-0  mt-1">
                                  <span class="share-price">{{bseStockClosingPrice | number : '1.2-2'}}</span>
                                  <span *ngIf="bseDifferenceInStockPrice == 0" style="color: #a5a7ab; font-size: 18px;" class="ml-3">                    
                                  <i class="fa fa-caret-left"></i>
                                  <i class="fa fa-caret-right mr-1"></i>
                                  <span>{{bseDifferenceInStockPrice | number : '1.2-2'}} ({{bsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                                  <span *ngIf="bseDifferenceInStockPrice > 0" style="font-size: 18px;" class="ml-3">
                                  <i class="fa fa-caret-up mr-1"></i>
                                  <span>{{bseDifferenceInStockPrice | number : '1.2-2'}} ({{bsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                                  <span *ngIf="bseDifferenceInStockPrice < 0" style="font-size: 18px;" class="ml-3">
                                  <i class="fa fa-caret-down mr-1"></i>
                                  <span>{{bseDifferenceInStockPrice | number : '1.2-2'}} ({{bsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                              </h3>
                          </div>
                          <div class="col-md-12 share-detail-uppercase">
                              <div style="padding: 5px 0px;">
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div><span class="low-high-text">Previous</span> <span class="red-text-low">Low</span>                                                <span class="devider-minus">/</span> <span class="green-text-low">High</span></div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div>
                                              <span class="red-text-low">{{bsePreviousSPLow | number : '1.2-2'}}</span>
                                              <span class="devider-minus">/</span>
                                              <span class="green-text-low">{{bsePreviousSPHigh | number : '1.2-2'}}</span></div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div><span class="low-high-text">52 Week</span> <span class="red-text-low">Low</span>                                                <span class="devider-minus">/</span> <span class="green-text-low">High</span></div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div style="margin-bottom: 0px;">
                                              <span class="red-text-low">{{bseWeek52SPLow | number : '1.2-2'}}</span>
                                              <span class="devider-minus">/</span>
                                              <span class="green-text-low">{{bseWeek52SPHigh | number : '1.2-2'}}</span></div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
              <!-- NSE -->
              <div class="col-lg-4 col-md-6">
                  <div class="row1">
                      <div class="row" *ngIf="!isNSEDataFound" style="text-transform: initial;">
                          <label>This Company is not listed in NSE</label>
                      </div>
                      <div class="row" *ngIf="isNSEDataFound">
                          <div class="col-md-12">
                              <span style="color: #808282;">CLOSING PRICE (NSE)</span>
                              <h3 class="mb-0  mt-1">
                                  <span class="share-price">{{nseStockClosingPrice | number : '1.2-2'}}</span>
                                  <span *ngIf="nseDifferenceInStockPrice == 0" style="color: #a5a7ab; font-size: 18px;" class="ml-3">                    
                                          <i class="fa fa-caret-left"></i>
                                          <i class="fa fa-caret-right mr-1"></i>
                                          <span>{{nseDifferenceInStockPrice | number : '1.2-2'}} ({{nsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                                  <span *ngIf="nseDifferenceInStockPrice > 0" style="font-size: 18px;" class="ml-3">
                                          <i class="fa fa-caret-up mr-1"></i>
                                          <span>{{nseDifferenceInStockPrice | number : '1.2-2'}} ({{nsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                                  <span *ngIf="nseDifferenceInStockPrice < 0" style="font-size: 18px;" class="ml-3">
                                          <i class="fa fa-caret-down mr-1"></i>
                                          <span>{{nseDifferenceInStockPrice | number : '1.2-2'}} ({{nsePercentageChange | number : '1.2-2'}}%)</span>
                                  </span>
                              </h3>
                          </div>
                          <div class="col-md-12 share-detail-uppercase">
                              <div style="padding: 5px 0px;">
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div><span class="low-high-text">Previous</span> <span class="red-text-low">Low</span>                                                <span class="devider-minus">/</span> <span class="green-text-low">High</span></div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div>
                                              <span class="red-text-low">{{nsePreviousSPLow | number : '1.2-2'}}</span>
                                              <span class="devider-minus">/</span>
                                              <span class="green-text-low">{{nsePreviousSPHigh | number : '1.2-2'}}</span></div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div><span class="low-high-text">52 Week</span> <span class="red-text-low">Low</span>                                                <span class="devider-minus">/</span> <span class="green-text-low">High</span></div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div style="margin-bottom: 0px;">
                                              <span class="red-text-low">{{nseWeek52SPLow | number : '1.2-2'}}</span>
                                              <span class="devider-minus">/</span>
                                              <span class="green-text-low">{{nseWeek52SPHigh | number : '1.2-2'}}</span></div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4"> </div>
          </div>
      </div>

      <div class="nav-tabs-horizontal">
          <ul class="nav nav-tabs inverse" role="tablist" style="background: #07a7a5;">
              <li class="nav-item">
                  <a class="nav-link active" data-target="#marketCap" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="true"
                      (click)="tabToogle('MCAP')">Market Capitalization</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-target="#priceEarning" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                      (click)="tabToogle('PE')">PE</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-target="#bookValue" data-toggle="tab" href="javascript: void(0);" role="tab" aria-expanded="false"
                      (click)="tabToogle('BookValue')">Book Value (Per Share)</a>
              </li>
          </ul>
          <div class="tab-content ">
              <div class="tab-pane active" id="marketCap" role="tabcard" aria-expanded="true">
                  <div class="row sub-tabs-section">
                      <div id="standaloneMCAPSection" class="tabs-2 widget_sp pull-left" (click)="getStandaloneMCAPData()">
                          <span class="widget_sp_title">Standalone</span>
                      </div>
                      <div id="consolidatedMCAPSection" class="tabs-2 widget_sp" (click)="getConsolidatedMCAPData()">
                          <span class="widget_sp_title">Consolidated</span>
                      </div>
                  </div>
                  <div class="card-block watermark" style="min-height: 300px;">
                      <div id="standaloneMCAPDataSection" style="display: none;">
                          <div id="standaloneMCapContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="standaloneMCapChart"></chart>
                          </div>
                      </div>
                      <div id="consolidatedMCAPDataSection" style="display: none;">
                          <div id="consolidatedMCapContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="consolidatedMCapChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="tab-pane" id="priceEarning" role="tabcard" aria-expanded="false">
                  <div class="row sub-tabs-section">
                      <div id="standalonePESection" class="tabs-2 widget_sp pull-left" (click)="getStandalonePEData()">
                          <span class="widget_sp_title">Standalone</span>
                      </div>
                      <div id="consolidatedPESection" class=" tabs-2 widget_sp" (click)="getConsolidatedPEData()">
                          <span class="widget_sp_title">Consolidated</span>
                      </div>
                  </div>
                  <div class="card-block watermark" style="min-height: 300px;">
                      <div id="standalonePEDataSection" style="display: none;">
                          <div id="standalonePEContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="standalonePEChart"></chart>
                          </div>
                      </div>
                      <div id="consolidatedPEDataSection" style="display: none;">
                          <div id="consolidatedPEContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="consolidatedPEChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="tab-pane" id="bookValue" role="tabcard" aria-expanded="false">
                  <div class="row sub-tabs-section">
                      <div id="standaloneBookValueSection" class="tabs-2 widget_sp pull-left" (click)="getStandaloneBookValueData()">
                          <span class="widget_sp_title">Standalone</span>
                      </div>
                      <div id="consolidatedBookValueSection" class="cat__core__widg tabs-2 widget_sp" (click)="getConsolidatedBookValueData()">
                          <span class="widget_sp_title">Consolidated</span>
                      </div>
                  </div>
                  <div class="card-block watermark" style="min-height: 300px;">
                      <div id="standaloneBookValueDataSection" style="display: none;">
                          <div id="standaloneBookValueContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="standaloneBookValueChart"></chart>
                          </div>
                      </div>
                      <div id="consolidatedBookValueDataSection" style="display: none;">
                          <div id="consolidatedBookValueContainer" class="m-auto" width="100%" style="padding: 40px;">
                              <chart type="StockChart" [options]="consolidatedBookValueChart"></chart>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>
<!-- END: forms/internal assets -->