import * as stringify from 'json-stringify-safe';

/**
 * This is simply a wrapper around json-stringify-safe.
 * @param obj The value to convert to a JSON string
 * @param serializer (optional) Function altering the stringification behavior
 * @param indent (optional) String or Number used for inserting white space into output for readability. Number of spaces OR string up to 10 characters to use
 * @param decycler default '[Circular]' - This function determines the return value used when a circular reference is encountered.
 * @returns String representation of obj in a JSON format.
 * @remarks If the underlying library is replaced, the parameters can be changed to appropriate generic types to prevent breaking changes.
 * @see https://github.com/moll/json-stringify-safe
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
 */
export function safeJsonStringify(
  obj: any,
  serializer?: stringify.EntryProcessor | null,
  indent?: string | number | null,
  decycler?: stringify.EntryProcessor
): string {
  return stringify(obj, serializer, indent, decycler);
}
