<div class="modal-header">
    <h5 class="modal-title"><i class="fa fa-question-circle-o mr-2"></i>Confirm</h5>
    <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="confirmation('No')">
                                    <span aria-hidden="true">×</span>
                                </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            Are you sure you want to deactivate user?
        </div>
      </div>  
</div>
<div class="modal-footer text-center">
  <button class="btn btn-sm btn-primary" (click)="confirmation('Yes')">Confirm</button>
  <button class="btn btn-sm" (click)="confirmation('No')">Cancel</button>
</div>
