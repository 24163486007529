<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Referral Tracking</strong>
        </span>
    </div>

    <nav ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" style="padding: 15px;" style="padding: 15px;">
        <ng-container ngbNavItem>
            <a ngbNavLink>By Question</a>
            <ng-template ngbNavContent class="pt-3">    
            <div class="card-block watermark">
                <div class="row">
                <div class="col-lg-12">
                    <!-- <button *ngIf="referredQuestions else loading" style="float: right; margin-bottom: 10px;" class="btn btn-sm btn-default-style" 
                    (click)="sendBulkReminderEmail()">Send Reminder to All
                    </button> -->
                    <table *ngIf="referredQuestions else loading" class="table table-hover compact cell-border nowrap stripe" style="table-layout: fixed;">
                    <thead>
                        <tr>
                            <td style="width: 4%;">Sr No</td>
                            <td style="width: 10%;">Category</td>
                            <td style="width: 50%;">Question</td>
                            <td style="width: 10%;">Referred To</td>
                            <td style="width: 8%;">Referred Date</td>
                            <td style="width: 10%;">IDR</td>
                            <td style="width: 10%;">IDA</td>
                            <td style="width: 8%;">Answered (IDP)</td>
                            <td style="width: 10%;">Send Reminder (IDP)</td>
                            <td style="width: 8%;">Reviewed (IDR/RAM)</td>
                            <td style="width: 8%;">Submitted (IDR/RAM)</td>                            
                            <td style="width: 10%;">Send Reminder (IDR)</td>                                                                    
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let question of referredQuestions; let j = index">
                            <tr *ngFor="let answerData of question.aggregatedAnswers ; let i = index">
                                <td>{{j+1}}-{{i+1}}</td>
                                <td>{{question.CategoryName}}</td>
                                <td>{{question.QuestionText}}</td>
                                <td>{{answerData.UserName}}</td>
                                <td>{{answerData.lastEmailSent | date: format:"mediumDate"}}</td>
                                <td>{{answerData.IDRUserName!=''?answerData.IDRUserName:'Not specified'}}</td> 
                                <td>{{answerData.IDAUserName!=''?answerData.IDAUserName:'Not specified'}}</td>
                                <td #answeredStatus>{{answerData.textAnswer? "Yes": 
                                    (answerData.tabularStructure && isTabularAnswerDirty(answerData))? "Yes": 
                                    answerData.mcqAnswer? "Yes": 
                                    answerData.binaryAnswer?"Yes": "No" }}</td>
                                <td *ngIf='answeredStatus.innerHTML=="No" else answered'>
                                <button class="btn btn-sm btn-default-style" (click)="sendReminderEmail(question, answerData)">Send Reminder</button>
                                </td> 
                                <!-- <td #reviewedStatus>{{(question.ProvidedAnswer && question.QuestionStatus=='Answered')? "Yes": 
                                    (question.tabularStructure && isTabularAnswerDirty(question))? "Yes": 
                                    question.mcqAnswer? "Yes": "No" }}</td> -->
                                <td #reviewedStatus>{{answerData.approved?'Yes':'No'}}</td>
                                <td #submittedStatus>{{(question.QuestionStatus != 'Not Answered' && question.QuestionStatus != 'Marked')? "Yes": "No" }}</td>
                                <td *ngIf='((reviewedStatus.innerHTML=="No" && submittedStatus.innerHTML=="No") ||
                                (reviewedStatus.innerHTML=="Yes" && submittedStatus.innerHTML=="No")) else answered'>
                                <button class="btn btn-sm btn-default-style" (click)="sendReminderEmail(question, answerData, true)">Send Reminder</button>
                                </td>                                    
                                
                            </tr>           
                            </ng-container>
                        </tbody>
                    </table>

                    <ng-template #empty_cell>
                    <td></td>
                    </ng-template>

                    <ng-template #answered>
                        <td>N.A. - Answered</td>
                    </ng-template>                    

                    <ng-template #loading>
                        <div>Loading...</div>
                    </ng-template>
                </div>
            </div>
            </div>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a ngbNavLink>By User</a>
            <ng-template ngbNavContent class="pt-3">    
            <div class="card-block watermark">
                <div class="row">
                <div class="col-lg-12">
                    <table *ngIf="allUniqueUsers else loading" class="table table-hover compact cell-border nowrap stripe" style="table-layout: fixed;">
                    <thead>
                        <tr>
                            <td style="width: 4%;">User Name</td>
                            <td style="width: 4%;">User Email</td>
                            <td style="width: 4%;">IDP Questions (Answered / Unanswered)</td>
                            <td style="width: 4%;">Send Reminder (IDP)</td>                            
                            <td style="width: 4%;">IDR Questions (Approved / Unapproved)</td>
                            <td style="width: 4%;">IDR Questions (Answered / Unanswered)</td>                            
                            <td style="width: 4%;">Send Reminder (IDR)</td>                            
                            <!-- <td style="width: 4%;">IDA Questions (Answered / Unanswered)</td>
                            <td style="width: 4%;">Send Reminder (IDA)</td>                             -->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let user of allUniqueUsers" >           
                            <tr>
                                <td>{{user.UserName}}</td>
                                <td>{{user.Email}}</td>
                                
                                <td>{{Count(IDPQuestions(user)).answered}} / {{Count(IDPQuestions(user)).unanswered}}</td>
                                <ng-container 
                                [ngTemplateOutlet]='Count(IDPQuestions(user)).unanswered==0 ? noUnanswered : IDPReminder' 
                                [ngTemplateOutletContext]="{user:user, unanswered:Count(IDPQuestions(user)).unanswered}">
                                </ng-container>
                                
                                <td>{{IDRCount(IDRQuestions(user)).answered}} / {{IDRCount(IDRQuestions(user)).unanswered}}</td>
                                <td>{{IDRQCount(IDRMainQuestions(user)).answered}} / {{IDRQCount(IDRMainQuestions(user)).unanswered}}</td>
                                <ng-container 
                                [ngTemplateOutlet]='IDRCount(IDRQuestions(user)).unanswered==0 ? noUnanswered : 
                                IDRQCount(IDRMainQuestions(user)).unanswered == 0 ? noUnanswered: 
                                IDRReminder' 
                                [ngTemplateOutletContext]="{user:user, unanswered:IDRCount(IDRQuestions(user)).unanswered}">
                                </ng-container>                                
                                
                                <!-- <td>{{IDRACount(IDAQuestions(user)).answered}} / {{IDRACount(IDAQuestions(user)).unanswered}}</td>
                                <ng-container 
                                [ngTemplateOutlet]='(IDRACount(IDAQuestions(user)).unanswered==0 && assessmentState=="ReviewStarted" )? noUnanswered : assessmentState!="ReviewStarted" ? reviewNotStarted: IDAReminder' 
                                [ngTemplateOutletContext]="{user:user, unanswered:IDRACount(IDAQuestions(user)).unanswered}">
                                </ng-container>                                 -->
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>

                    <ng-template #empty_cell>
                    <td></td>
                    </ng-template>

                    <ng-template #noUnanswered>
                        <td>No unanswered</td>
                    </ng-template>                    

                    <ng-template #loading>
                        <div>Loading...</div>
                    </ng-template>

                    <ng-template #reviewNotStarted>
                        <div>Assessment not at Review Stage.</div>
                    </ng-template>

                    <ng-template #IDPReminder let-user='user' let-unanswered='unanswered'>
                        <button class="btn btn-sm btn-default-style" 
                        (click)="sendUserReminderEmail(user.UserName,user.Email,'IDP',unanswered)">Send Reminder
                        </button>
                    </ng-template>

                    <ng-template #IDRReminder let-user='user' let-unanswered='unanswered'>
                        <button class="btn btn-sm btn-default-style" 
                        (click)="sendUserReminderEmail(user.UserName,user.Email,'IDR',unanswered)">Send Reminder
                        </button>
                    </ng-template>
                    
                    <ng-template #IDAReminder let-user='user' let-unanswered='unanswered'>
                        <button class="btn btn-sm btn-default-style" 
                        (click)="sendUserReminderEmail(user.UserName,user.Email,'IDA',unanswered)">Send Reminder
                        </button>
                    </ng-template>                    
                </div>
            </div>
            </div> 
            </ng-template>
        </ng-container>
    </nav> 
    
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>