import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditTrailService } from './../../../services/audit-trail.service';
import { UserMasterService } from './../../../services/user-master.service';
import { forkJoin, Subject } from 'rxjs';

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import * as _ from 'lodash';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
})
export class AuditComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  public userList: any;
  public collectionList: any;
  public DateRangeFromModel: any;
  public DateRangeToModel: any;
  public DateRangeFrom: any;
  public DateRangeTo: any;
  public auditTrailFilter: FormGroup;
  public databaseBackupForm: FormGroup;
  public autoBackupForm: FormGroup;
  public loglist: any;
  public errorValue: boolean;
  public path: string;
  public backupSchedulerFrequencyList: any;
  selectedValue: any;

  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();

  constructor(
    private auditTrailService: AuditTrailService,
    private userMasterService: UserMasterService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.errorValue = false;
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      dom: 'lBfrtip',
      processing: true,
      buttons: [
        {
          text: '<i class="fa fa-files-o"></i> Download',
          className: 'exportExcel',
          title: 'Audit Trail List',
          extend: 'collection',
          exportOptions: {
            modifier: {
              search: 'applied',
              order: 'applied',
            },
          },
          buttons: ['pdfHtml5', 'csvHtml5', 'print', 'excelHtml5'],
        },
      ],
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, 'All'],
      ],
      scrollX: true,
    };

    this.backupSchedulerFrequencyList = [
      { label: 'Hourly', value: 'Hourly' },
      { label: 'Daily', value: 'Daily' },
    ];

    this.autoBackupForm = new FormGroup({
      autoBackupFrequency: new FormControl('', [Validators.required]),
      autoBackupServerName: new FormControl('', [Validators.required]),
      autoBackupPassword: new FormControl('', [Validators.required]),
      autoBackupLocation: new FormControl('', [Validators.required]),
      autoBackupServerUserName: new FormControl('', [Validators.required]),
    });

    //console.log('inside ngOninit')
    this.auditTrailFilter = new FormGroup({
      username: new FormControl('', []),
      collectionName: new FormControl('', []),
      DateRangeFrom: new FormControl('', []),
      DateRangeTo: new FormControl('', [Validators.required]),
    });

    this.databaseBackupForm = this.fb.group({
      serverName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      serverUserName: new FormControl('', [Validators.required]),
    });

    let promiseArray = [];
    // promiseArray.push(this.userMasterService.getUserList())
    // promiseArray.push(this.auditTrailService.getCollectionNameList())
    // this.path = '/home/ec2-user/db_backup/'
    forkJoin([
      this.userMasterService.getUserList(),
      this.auditTrailService.getCollectionNameList(),
      this.auditTrailService.getauditTrailLogs(),
      this.auditTrailService.getDatabaseBackupLocation(),
    ]).subscribe((reposnse) => {
      if (_.size(reposnse[3].result)) {
        let dbLocationObject = _.first(reposnse[3].result);
        this.path = _.get(dbLocationObject, 'path');
      } else {
        this.path = '';
      }
      reposnse[0].result.push(0, { Title: '', FirstName: 'All', LastName: '' });
      reposnse[0].result = _.filter(reposnse[0].result, function (element) {
        return element.Title != null;
      });
      this.userList = _.map(reposnse[0].result, function (userInformation) {
        return {
          label:
            _.get(userInformation, 'Title') +
            ' ' +
            _.get(userInformation, 'FirstName') +
            ' ' +
            _.get(userInformation, 'LastName'),
          value: _.get(userInformation, '_id'),
        };
      });
      // this.userList = []
      this.userList.reverse();
      reposnse[1].collectionList.push({ label: 'All', value: null });
      this.collectionList = reposnse[1].collectionList;
      this.collectionList.reverse();
      // //console.log('reposnse[2].loggerList', reposnse[2].loggerList)
      // for(let index = 0; index < reposnse[2].loggerList.length; index++) {
      //   reposnse[2].loggerList[index].Data = reposnse[2].loggerList[index].Data
      //   // //console.log('reposnse[2].loggerList[index].Data', reposnse[2].loggerList[index].Data)
      // }
      if (!reposnse[2].status) {
        this.loglist = reposnse[2].loggerList;
      } else {
        this.loglist = [];
        this.errorValue = true;
      }
      this.dtTrigger.next();
    });
  }

  getInputValueChange(event: Event): string {
    // @ts-ignore
    return (event.target ? (event.target as HTMLInputElement) : event).value;
  }

  takeDatabaseBackUp() {
    const serverDetails = {
      serverName: this.databaseBackupForm.controls['serverName'].value,
      serverPath: this.databaseBackupForm.controls['location'].value,
      serverPassword: this.databaseBackupForm.controls['password'].value,
      serverUserName: this.databaseBackupForm.controls['serverUserName'].value,
    };
    this.auditTrailService
      .takeDatabaseBackup(serverDetails)
      .then((response) => {
        //console.log('response take back up', response)
        if (!response.status) {
          this.databaseBackupForm.reset();
          this.path = _.get(response.result, 'path');
          $('#alertTitle').text('Success!');
          $('#alertTitle').css('color', 'black');
          $('#alertMessage').text('Backup has been taken!');
          $('#alertMessage').css('color', 'black');
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
        } else {
          $('#alertTitle').css('color', '#f00');
          $('#alertTitle').text('Failure!');
          $('#alertMessage').text(response.message);
          $('#alertMessage').css('color', '#f00');
          $('#messagePopUp').modal('toggle');
          this.messagePopUpTimeoutToogle();
        }
      });
  }

  setAutoBackupScheduler() {
    const setParameters = {
      companyId: JSON.parse(localStorage.getItem('user')).CompanyId,
      BackupScheduleFrequency:
        this.autoBackupForm.controls['autoBackupFrequency'].value,
      serverName: this.autoBackupForm.controls['autoBackupServerName'].value,
      serverPath: this.autoBackupForm.controls['autoBackupLocation'].value,
      serverPassword: this.autoBackupForm.controls['autoBackupPassword'].value,
      serverUserName:
        this.autoBackupForm.controls['autoBackupServerUserName'].value,
    };

    //console.log("auto backup form", this.autoBackupForm.controls['autoBackupFrequency'].value);

    this.auditTrailService
      .setAutoBackupScheduler(setParameters)
      .then(
        (response) => {
          //console.log("response");
        },
        (error) => console.log('')
      )
      .catch();
  }

  filterLog() {
    // let dateFrom = this.auditTrailFilter.controls['DateRangeFrom'].value
    // let dateTo = this.auditTrailFilter.controls['DateRangeTo'].value
    const {username, collectionName} = this.auditTrailFilter.value
    let filterObject = {
      UserName: username ? username.value : null,
      CollectionName: collectionName ? collectionName.value : null,
      DateRangeFrom: new Date(
        this.setDate(this.auditTrailFilter.controls['DateRangeFrom'].value)
      ),
      DateRangeTo: new Date(
        this.setDate(this.auditTrailFilter.controls['DateRangeTo'].value)
      ),
    };
    // if (dateFrom) {
    //   filterObject.DateRangeFrom = new Date(dateFrom.year, dateFrom.month, dateFrom.day)
    // }
    // if (dateTo) {
    //   filterObject.DateRangeTo = new Date(dateTo.year, dateTo.month, dateTo.day)
    // }
    //console.log('checking for the filter Object', filterObject)
    this.loglist = [];
    $('#logtable').DataTable().destroy();
    this.auditTrailService.getFilterLogs(filterObject).then((response) => {
      //console.log('response for the filterlog', response)
      if (!response.status) {
        this.errorValue = false;
        if (_.size(this.loglist)) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }

        this.loglist = response.filterLogList;
        this.dtTrigger.next();
        // }
      } else {
        // if (_.size(this.loglist)) {
        //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //     dtInstance.destroy();
        //   });
        // }
        this.errorValue = true;
        this.loglist = [];
      }
    });
  }

  clearFilter() {
    this.auditTrailFilter.reset();
    // this.ngOnInit();
    var filterObjects = { filter: null };
    this.loglist = [];
    //console.log('filterObject', filterObjects)
    $('#logtable').DataTable().destroy();
    this.auditTrailService.getFilterLogsAll(filterObjects).then((response) => {
      //console.log('response for the filterlog', response)
      if (!response.status) {
        this.errorValue = false;

        if (_.size(this.loglist)) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }

        this.loglist = response.filterLogList;
        this.dtTrigger.next();
        // }
      } else {
        // if (_.size(this.loglist)) {
        //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //     dtInstance.destroy();
        //   });
        // }
        this.errorValue = true;
        this.loglist = [];
      }
    });
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == 'modal fade show') {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  setDate(date) {
    if (date != '' && date != null) {
      return date.month + '-' + date.day + '-' + date.year;
    }
    return null;
  }
}
