import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class RouteGuard implements CanActivate {
  isRouteAllowed: boolean;
  constructor(
    private router: Router,
    private activated: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.isRouteAllowed = false;
  }

  canActivate(
    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.isRouteAllowed = false;
    var self = this;
    let resourcesValue = JSON.parse(localStorage.getItem('resources'));

    var nextPath = next.routeConfig.path;

    _.forEach(resourcesValue, function (data) {
      let routematched = nextPath
        .toLowerCase()
        .indexOf(data.Menu.toLowerCase());

      if (routematched >= 0) {
        self.isRouteAllowed = true;
        return;
      }
    });
    if (this.isRouteAllowed) {
      console.log('this.isRouteAllowed', this.isRouteAllowed);
      return true;
    } else {
      console.log('I would have routed back to structure; dashboards/alpha');
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': 'dashboards/alpha' } },
      ]);
      return false;
    }
  }
}
