import { Component, OnInit } from '@angular/core';
import { DashboardMyTaskService } from './../../../services/dashboardmytask.service';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;

@Component({
  selector: 'app-company-news',
  templateUrl: './company-news.component.html',
  styleUrls: ['./company-news.component.css'],
})
export class CompanyNewsComponent implements OnInit {
  displayLabel: string;
  selectedTab: string;
  isCompanyNewsFetch: any;
  constructor(private dashboardMyTaskService: DashboardMyTaskService) {}

  ngOnInit() {
    this.isCompanyNewsFetch = localStorage.getItem('isCompanyNewsFetch');
    // if(this.isCompanyNewsFetch = "false"){
    this.showRecentCompanyNews();
    // }
    this.selectedTab = 'AllCompany';
  }

  recentCompanyNews: any;
  recentPeerCompanyNews: any;

  showRecentCompanyNews() {
    this.companyNewsDetails = [];
    this.displayLabel = 'Latest';

    this.isCompanyNewsFetch = localStorage.getItem('isCompanyNewsFetch');

    if ((this.isCompanyNewsFetch = 'false')) {
      this.dashboardMyTaskService.getCompanyAllNews().then((companyNews) => {
        // this.recentCompanyNews = companyNews.companyNews;
        this.recentCompanyNews = companyNews.companyNews;
        jQuery(function () {
          jQuery('#companyNews').children(':gt(4)').hide();
        });
      });
    } else {
      this.dashboardMyTaskService.getRecentCompanyNews().then((companyNews) => {
        if (companyNews.status == 0) {
          this.recentCompanyNews = companyNews.recentCompanyNews;
        }
        // else{
        //   this.recentCompanyNews = null;
        // }
      });
    }
  }

  showRecentPeerCompanyNews() {
    console.log('nupesh1');
    this.companyNewsDetails = [];
    this.displayLabel = 'Latest';
    this.dashboardMyTaskService
      .getRecentPeerCompanyNews()
      .then((companyNews) => {
        //
        if (companyNews.status == 0) {
          // this.taskDetailList = taskWithProcessList.CategoryWithTaskAndProcess;
          this.recentPeerCompanyNews = companyNews.recentPeerCompanyNews;
          //this.dtTrigger.next();
        } else {
          this.recentCompanyNews = null;
        }
      });
  }

  showCompanyAllNews() {
    this.companyNewsDetails = [];
    this.displayLabel = 'All';
    this.dashboardMyTaskService.getCompanyAllNews().then((companyNews) => {
      //
      if (companyNews.status == 0) {
        // this.taskDetailList = taskWithProcessList.CategoryWithTaskAndProcess;
        this.recentCompanyNews = companyNews.companyNews;
        //this.dtTrigger.next();
      } else {
        this.recentCompanyNews = null;
      }
    });
  }

  showPeerCompanyAllNews() {
    console.log('nupesh3');
    this.companyNewsDetails = [];
    this.displayLabel = 'All';
    this.dashboardMyTaskService.getPeerCompanyAllNews().then((companyNews) => {
      //
      if (companyNews.status == 0) {
        // this.taskDetailList = taskWithProcessList.CategoryWithTaskAndProcess;
        this.recentPeerCompanyNews = companyNews.peerCompanyNews;
        //this.dtTrigger.next();
      } else {
        this.recentCompanyNews = null;
      }
    });
  }

  companyNewsDetails: any;
  showCompanyNewsDetailsByNewsId(newsId) {
    console.log('nupesh4');
    if (newsId != '') {
      this.dashboardMyTaskService
        .getCompanyNewsDetailsByNewsId(newsId)
        .then((companyNews) => {
          //
          if (companyNews.status == 0) {
            // this.taskDetailList = taskWithProcessList.CategoryWithTaskAndProcess;
            $('#companyNewsDialog').modal('toggle');
            this.companyNewsDetails = companyNews.companyNews;
            //this.dtTrigger.next();
          }
        });
    }
  }

  filterNews(data) {
    this.displayLabel = data;
    if (this.selectedTab == 'AllCompany') {
      if (data == 'All') {
        this.showCompanyAllNews();
      } else if (data == 'Latest') {
        this.showRecentCompanyNews();
      }
    } else if (this.selectedTab == 'PeerCompany') {
      if (data == 'All') {
        this.showPeerCompanyAllNews();
      } else if (data == 'Latest') {
        this.showRecentPeerCompanyNews();
      }
    }
  }

  tabSwitch(tabName) {
    if (tabName == 'AllCompany') {
      if (this.displayLabel == 'All') {
        this.showCompanyAllNews();
      } else if (this.displayLabel == 'Latest') {
        this.showRecentCompanyNews();
      }
    } else if (tabName == 'PeerCompany') {
      if (this.displayLabel == 'All') {
        this.showPeerCompanyAllNews();
      } else if (this.displayLabel == 'Latest') {
        this.showRecentPeerCompanyNews();
      }
    }
  }
}
