<section>
  <div class="modal-header">
    <h4 class="modal-title">Tags:</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div style="min-height: 350px">
      <div class="row" *ngIf="showTags" style="padding: 2%">
        <span
          class="tagButton btn btn-sm btn-primary"
          *ngFor="let file of selectedTagForFile"
          >{{ file.label }}
          <i
            class="fa fa-close"
            style="cursor: pointer"
            (click)="multipleTagsDeselection(file)"
          ></i>
        </span>
      </div>

      <div class="row" style="margin-bottom: 2%">
        <label class="col-lg-12" style="padding: 0"
          ><span class="col-lg-11">Select:</span></label
        >
        <div class="col-lg-12" style="padding: 0">
          <ng-select
            class="col-lg-11"
            bindLabel="label"
            bindValue="value"
            [items]="taglist"
            [multiple]="true"
            (add)="selectedTagList($event)"
            (remove)="multipleTagsDeselection($event)"
            [ngModel]="selectedTagId"
            placeholder="No Tags Selected"
            [clearable]="false"
          >
          </ng-select>

          <i
            style="float: right; color: lightgray; cursor: pointer"
            aria-hidden="true"
            *ngIf="!showAddCustomDiv"
            class="col-lg-1 fa fa-plus-circle fa-2x"
            (click)="addCustomTagDiv()"
          >
          </i>
          <i
            style="float: right; color: lightgray; cursor: pointer"
            aria-hidden="true"
            *ngIf="showAddCustomDiv"
            class="col-lg-1 fa fa-minus-circle fa-2x"
            (click)="removeCustomTagDiv()"
          >
          </i>
        </div>
      </div>

      <div class="row" *ngIf="showAddCustomDiv">
        <form style="width: 100%" [formGroup]="addTagForm" novalidate>
          <label class="col-lg-12" style="padding: 0"
            ><span class="col-lg-11">Add custom tag:</span></label
          >
          <div class="col-lg-12 form-group" style="padding-right: 0">
            <input
              type="text"
              class="col-lg-10 form-control"
              [formControl]="addTagForm.controls['tagName']"
              [value]="newTagName"
              (change)="newTagName = getInputValueChange($event)"
            />

            <button
              style="width: 100%"
              class="col-lg-2 btn btn-sm btn-primary"
              style="
                float: right;
                padding-left: 10;
                padding-right: 0;
                cursor: pointer;
              "
              [disabled]="!addTagForm.valid"
              (click)="saveTags()"
            >
              Add
            </button>

            <div
              *ngIf="tagAlreadyPresent"
              class="form-control-error-custom p-0 mt-1"
            >
              Tag Already Present
            </div>
          </div>
        </form>

        <label class="col-lg-12" style="padding: 0"
          ><span class="col-lg-11">Remove tag:</span></label
        >
        <div class="col-lg-12 form-group" style="padding-right: 0">
          <ng-select
            class="col-lg-10"
            style="padding: 0"
            [items]="taglist"
            (change)="selectedTagListToRemove($event)"
            placeholder="No Tags Selected"
            [clearable]="false"
          >
          </ng-select>
          <button
            style="width: 100%"
            class="col-lg-2 btn btn-sm btn-primary"
            style="
              float: right;
              padding-left: 10;
              padding-right: 0;
              cursor: pointer;
            "
            (click)="removeTagsFromList()"
          >
            Remove
          </button>
        </div>
        <div *ngIf="tagAlreadyInUse" class="form-control-error-custom p-0 mt-1">
          Tag Already In Use
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      *ngIf="!isFolderCreation"
      class="btn btn-primary btn-sm"
      [disabled]="disabledOnclick"
      (click)="saveTagsToFile()"
    >
      Save
    </button>
    <button
      type="button"
      *ngIf="isFolderCreation"
      class="btn btn-primary btn-sm"
      [disabled]="disabledOnclick"
      (click)="saveTagsToFolder()"
    >
      Save
    </button>
    <button type="button" class="btn btn-default btn-sm" (click)="close()">
      Cancel
    </button>
  </div>
</section>
