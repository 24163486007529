<section class="company-setup">
  <div style="width: 95%; margin: 0 auto; position: absolute; top: 5px; left: 40px;" class="pb-2 pl-3">
    <img src="assets/modules/dummy-assets/common/img/GovEVA.png" alt="GovEVA">
  </div>
  <div class="inner-wrap">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title cat__core__title " id="myModalLabel">Setup Company</h4>
      </div>
      <form [formGroup]="companyMasterForm" class="well">
        <div class="modal-body watermark" style="min-height: 300px;">
          <div class="row">
            <div class="col-md-4 mb-4">
              <label class="form-control-label">Company Logo</label>
              <a class="img-circle img-responsive" id="companyLogoImg" (click)="showUploadImagePopUp()">
                <img  id="companyLogo" alt="" class="img-circle img-responsive" src="../../assets/modules/dummy-assets/common/img/avatars/Company-default.png"
                  alt="Company Logo" title="Change Company Logo">
                <span>Change</span>
              </a>
              <a class="remove" (click)="removeLogoImage()">
                <span>Remove</span>
              </a>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-6"></div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label" for="l0">Compliance Engine</label>
              <div class="form-group">
                <select [formControl]="companyMasterForm.controls['complianceEngine']" class="form-control"
                (change)="complianceEngineChange($event.target.value)">
                  <option value="" style="color:#bebecd;">Select</option>
                  <option value="PSS" selected>PSS</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label" for="l0">API URL
                <span style="color: red;">*</span>
              </label>
              <div class="form-group">
                <input class="form-control" type="url" placeholder="URL" name="url"
                  [formControl]="companyMasterForm.controls['complianceEngineURL']">
                <div
                  *ngIf="companyMasterForm.controls['complianceEngineURL'].hasError('required') && companyMasterForm.controls['complianceEngineURL'].touched"
                  class="form-control-error-custom">
                  Compliance URL is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label" for="l0">Chat Engine Base URL
              </label>
              <div class="form-group">
                <input class="form-control" type="url" placeholder="URL" name="url"
                  [formControl]="companyMasterForm.controls['chatEngineURL']">
              </div>
            </div>
            <div class="col-md-6">
              <div id="companyName">
                <input type="text" style="display:none">
                <label class="form-control-label" for="l0">Company Name
                  <span style="color: red;">*</span>
                </label>
                <span style="font-style: italic;font-size: 12px;margin-top: -10px;">As registered with ROC</span>
                <div class="form-group ddl-custom" *ngIf="isCompanySetupWithoutMaster">
                  <input id="typeahead-template" type="text" class="form-control" formControlName="companyName" />
                  <span *ngIf="searching">searching...</span>
                  <input class="form-control" type="hidden" name="companyName" [formControl]="companyMasterForm.controls['companyName']">
                  <div *ngIf="companyMasterForm.controls['companyName'].hasError('required') && companyMasterForm.controls['companyName'].touched"
                    class="form-control-error-custom">
                    Company name is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['companyName'].hasError('maxlength') && companyMasterForm.controls['companyName'].touched"
                    class="form-control-error-custom">
                    Company name should be maximum 100 character.
                  </div>
                </div>
                <div class="form-group ddl-custom" *ngIf="!isCompanySetupWithoutMaster">
                    <input id="typeahead-template" type="text" class="form-control" formControlName="companyName"
                    [ngbTypeahead]="search" (selectItem)="selectedItem($event)"
                    (focusout)="setPrivateEnteredCompanyName()" />
                    <span *ngIf="searching">searching...</span>
                    <input class="form-control" type="hidden" name="companyName"
                    [formControl]="companyMasterForm.controls['companyName']">
                    <div
                      *ngIf="companyMasterForm.controls['companyName'].hasError('required') && companyMasterForm.controls['companyName'].touched"
                      class="form-control-error-custom">
                      Company name is required.
                    </div>
                    <div
                      *ngIf="companyMasterForm.controls['companyName'].hasError('maxlength') && companyMasterForm.controls['companyName'].touched"
                      class="form-control-error-custom">
                      Company name should be maximum 100 character.
                    </div>
                </div>
                <div class="form-group float-right btns">
                  <button id="btnCheckMapping" type="button" class="btn btn-sm btn-primary" 
                  (click)="checkMapping()">Check Mapping
                  </button>
                </div>
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-flex">
              <label class="form-control-label">Is GovEVA Boards Subscribed
              </label>
              <div class="form-group">
                <label class="form-check-label">
                  <input type="checkbox" [checked]="isGEMSubscribedFlag"
                    [formControl]="companyMasterForm.controls['isGEMSubscribedFlag']"
                    (change)="onGEMSubscriptionChange($event)">
                    <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="col-md-6" *ngIf="isGEMSubscribedFlag">
              <label class="form-control-label">GovEVA Boards API URL
                <span style="color: red;">*</span>
              </label>
              <div class="form-group">
                <input class="form-control" type="url" placeholder="URL" name="url"
                  [formControl]="companyMasterForm.controls['GEMAccessURL']" required>
                <div
                  *ngIf="companyMasterForm.controls['GEMAccessURL'].hasError('required') && companyMasterForm.controls['GEMAccessURL'].touched"
                  class="form-control-error-custom">
                  GovEVA Boards URL is required.
                </div>
              </div>
            </div>
          </div>
          <!-- START AD -->
          <div class="row">
            <div class="col-md-6  d-flex">
              <label class="form-control-label">Is AD Subscribed
              </label>
              <div class="form-group">
                <label class="form-check-label">
                  <input type="checkbox" [checked]="isADSubscribedFlag"
                    [formControl]="companyMasterForm.controls['isADSubscribedFlag']"
                    (change)="onADSubscriptionChange($event)">
                    <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngFor="let server of adServerArray; let count = index; let last=last; let first= first">

            <div class="col-md-12 pb-2">
              <i class="fa fa-close btn btn-sm btn-light" style="float: right!important;"
                (click)="removeADServerConfirguration(count)" *ngIf="!first && last"></i>
            </div>


            <div class="col-md-12 p-0">
              <div class="col-md-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD URL
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="url" placeholder="Url" [name]="'ADUrl'+ count"
                    [formControl]="companyMasterForm.controls['ADUrl'+count]" required>
                  <div
                    *ngIf="companyMasterForm.controls['ADUrl'+count].hasError('required') && companyMasterForm.controls['ADUrl'+count].touched"
                    class="form-control-error-custom">
                    AD Url is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD Administrator Email ID
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="email" placeholder="Email ID" [name]="'ADEmailID' + count"
                    [formControl]="companyMasterForm.controls['ADEmailID' + count]" required>
                  <div
                    *ngIf="companyMasterForm.controls['ADEmailID' + count].hasError('required') && companyMasterForm.controls['ADEmailID' + count].touched"
                    class="form-control-error-custom">
                    AD Administrator email is required.
                  </div>
                  <div
                    *ngIf="companyMasterForm.controls['ADEmailID' + count].hasError('email') && companyMasterForm.controls['ADEmailID' + count].touched && !companyMasterForm.controls['ADEmailID' + count].hasError('required')"
                    class="form-control-error-custom">
                    Please enter valid email Id.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="col-md-6" *ngIf="isADSubscribedFlag">
                <label class="form-control-label">AD Administrator Password:
                  <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input class="form-control" type="password" placeholder="AD Password" [name]="'ADPassword' + count"
                    [formControl]="companyMasterForm.controls['ADPassword' + count]" required>
                  <div
                    *ngIf="companyMasterForm.controls['ADPassword' + count].hasError('required') && companyMasterForm.controls['ADPassword' + count].touched"
                    class="form-control-error-custom">
                    AD Administrator password is required.
                  </div>
                </div>
              </div>

              <div class="col-md-6 btns" *ngIf="isADSubscribedFlag && last" >
                <button class="btn btn-sm btn-primary" style="float:right"  (click)="addAdServerControls()">Add More Server</button>
              </div>
            </div>

          </div>
          <!-- END AD -->
          <!--Registration Type-->
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label">Registration ID Type
                <span _ngcontent-c2="" style="color: red;">*</span>
              </label>
              <div class="form-group">
                <select [formControl]="companyMasterForm.controls['registrationIDType']" class="form-control"
                 (change)="onSelectRegistrationId()">
                  <option value="" style="color:#bebecd;">Select</option>
                  <option *ngFor="let registrationIDType of registrationIDTypes" value="{{registrationIDType}}">
                    {{registrationIDType}}</option>
                </select>
                <div
                  *ngIf="companyMasterForm.controls['registrationIDType'].hasError('required') && companyMasterForm.controls['registrationIDType'].touched"
                  class="form-control-error-custom">
                  Registration ID Type is required.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Registration Number
                <span _ngcontent-c2="" style="color: red;">*</span>
              </label>
              <!--Company setup without master-->
              <div class="form-group" *ngIf="isCompanySetupWithoutMaster">
                <input [formControl]="companyMasterForm.controls['registrationNumber']" class="form-control"
                  placeholder="Registration Number"
                  (focusout)="checkForCINForCompanyWithoutMaster(companyMasterForm.controls['registrationNumber'].value)">
                <div
                  *ngIf="companyMasterForm.controls['registrationNumber'].hasError('required') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  Registration number is required.
                </div>
                <div
                  *ngIf="companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  Registration number should not be allowed special characters.
                </div>
                <div
                  *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany && !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('maxlength') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  {{companyMasterForm.controls['registrationIDType'].value == "FCRN" && companyMasterForm.controls['registrationNumber'].hasError('maxlength')  ? "Registration number should be 6 digit ": "Registration
              number should be 21 digit"}}
                </div>
                <div
                  *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany && !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('minlength') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  {{companyMasterForm.controls['registrationIDType'].value == "FCRN" && companyMasterForm.controls['registrationNumber'].hasError('minlength') ? "Registration number should be 6 digit ": "Registration
              number should be 21 digit"}}
                </div>
                <div *ngIf="errorUniqueCINForCompany&&!errorValidCINForCompany" class="form-control-error-custom">
                  Please enter unique Registration number for CIN.
                </div>

                <div
                  *ngIf="errorValidCINForCompany&&!companyMasterForm.controls['registrationNumber'].hasError('required')"
                  class="form-control-error-custom">
                  Please enter valid Registration number for CIN.
                </div>
              </div>

              <!--Company setup with master-->
              <div class="form-group" *ngIf="!isCompanySetupWithoutMaster">
                <input [formControl]="companyMasterForm.controls['registrationNumber']" class="form-control"
                  placeholder="Registration Number" (keydown)="restrictCIN($event)"
                  (keyup)="getCompanyByCIN()"
                  (focusout)="checkForRepeatCINForCompany(companyMasterForm.controls['registrationNumber'].value)">
                <div
                  *ngIf="companyMasterForm.controls['registrationNumber'].hasError('required') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  Registration number is required.
                </div>
                <div
                  *ngIf="companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  Registration number should not be allowed special characters.
                </div>
                <div
                  *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany && !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('maxlength') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  {{companyMasterForm.controls['registrationIDType'].value == "FCRN" && companyMasterForm.controls['registrationNumber'].hasError('maxlength')  ? "Registration number should be 6 digit ": "Registration
              number should be 21 digit"}}
                </div>
                <div
                  *ngIf="!errorUniqueCINForCompany && !errorValidCINForCompany && !companyMasterForm.controls['registrationNumber'].hasError('pattern') && companyMasterForm.controls['registrationNumber'].hasError('minlength') && companyMasterForm.controls['registrationNumber'].touched"
                  class="form-control-error-custom">
                  {{companyMasterForm.controls['registrationIDType'].value == "FCRN" && companyMasterForm.controls['registrationNumber'].hasError('minlength') ? "Registration number should be 6 digit ": "Registration
              number should be 21 digit"}}
                </div>
                <div *ngIf="errorUniqueCINForCompany&&!errorValidCINForCompany" class="form-control-error-custom">
                  Please enter unique Registration number for CIN.
                </div>

                <div
                  *ngIf="errorValidCINForCompany&&!companyMasterForm.controls['registrationNumber'].hasError('required')"
                  class="form-control-error-custom">
                  Please enter valid Registration number for CIN.
                </div>
              </div>
            </div>
          </div>
          
          <div id="companyData">
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label" for="l0">Date of Incorporation</label>
                <div class="form-group">
                  <div class="input-group" (click)="$event.stopPropagation()">
                    <input class="form-control" [attr.readonly]="true" [minDate]="minDate" [maxDate]="maxDate" [formControl]="companyMasterForm.controls['dateOfIncorporation']"
                      [(ngModel)]="dateOfIncorporationModel" style="z-index: 0;background-color:white;" placeholder="dd-mm-yyyy"
                      name="dpIncorporation" ngbDatepicker #a="ngbDatepicker" (click)="a.toggle();d.close();s.close()" (document:click)="a.close();s.close();d.close();">
                    <button class="input-group-addon" type="button" (click)="clearDateOfIncoporation()">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" type="button" (click)="a.toggle();d.close();s.close()">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Type of Company <span style="color: red;">*</span></label>
                <div class="form-group">
                  <select class="form-control" [formControl]="companyMasterForm.controls['typeOfCompany']"
                    (change)="ListedConditionCheck(companyMasterForm.controls['typeOfCompany'].value)" required>
                    <option value="" style="color:#bebecd;">Select</option>
                    <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">{{typeOfCompany}}
                    </option>
                  </select>
                  <div *ngIf="companyMasterForm.controls['typeOfCompany'].hasError('required') && companyMasterForm.controls['typeOfCompany'].touched"
                    class="form-control-error-custom">
                    Type of Company is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['typeOfCompany'].hasError('maxlength') && companyMasterForm.controls['typeOfCompany'].touched"
                    class="form-control-error-custom">
                    Type of Company should be maximum 100 character.
                  </div>
                </div>
              </div>
            </div>  
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Category of Company <span style="color: red;">*</span></label>
                <div class="form-group">
                  <select class="form-control" [formControl]="companyMasterForm.controls['categoryOfCompany']"
                    name="categoryOfCompany" required>
                    <option value="" style="color:#bebecd;">Select</option>
                    <option *ngFor="let categoryOfCompany of categoriesOfCompany" value="{{categoryOfCompany}}">
                      {{categoryOfCompany}}</option>
                  </select>
                  <div *ngIf="companyMasterForm.controls['categoryOfCompany'].hasError('required') && companyMasterForm.controls['categoryOfCompany'].touched"
                    class="form-control-error-custom">
                    Category of Company is required.
                  </div>
                  <div *ngIf="companyMasterForm.controls['categoryOfCompany'].hasError('maxlength') && companyMasterForm.controls['categoryOfCompany'].touched"
                    class="form-control-error-custom">
                    Category of Company should be maximum 100 character.
                  </div>
                </div>
                <label class="form-control-label">ISIN <span style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="ISIN" name="isin"
                    [formControl]="companyMasterForm.controls['ISIN']" maxlength="12" required>
                  <div *ngIf="companyMasterForm.controls['ISIN'].hasError('required') && companyMasterForm.controls['ISIN'].touched"
                    class="form-control-error-custom">
                    The ISIN field is required.
                  </div>
                  <div
                    *ngIf="companyMasterForm.controls['ISIN'].hasError('minlength') && companyMasterForm.controls['ISIN'].touched"
                    class="form-control-error-custom">
                    The ISIN field must accept only 12 digits.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Registered Office Address</label>
                <div class="form-group">
                  <textarea [formControl]="companyMasterForm.controls['registeredOfficeAddress']"
                    class="form-control" rows="4" style="min-height: 125px;"></textarea>

                  <div
                    *ngIf="companyMasterForm.controls['registeredOfficeAddress'].hasError('minlength') && companyMasterForm.controls['registeredOfficeAddress'].touched"
                    class="form-control-error-custom">
                    Registered Office Address should be minimum 10 character.
                  </div>
                  <div
                    *ngIf="companyMasterForm.controls['registeredOfficeAddress'].hasError('maxlength') && companyMasterForm.controls['registeredOfficeAddress'].touched"
                    class="form-control-error-custom">
                    Registered Office Address should be maximum 500 character.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">EVA
                </label>
                <div class="form-group">
                  <input type="number" class="form-control" placeholder="EVA" name="eva"
                    [formControl]="companyMasterForm.controls['EVA']" (keydown)="onlyDecimalNumber($event)">
                  <div
                    *ngIf="companyMasterForm.controls['EVA'].hasError('pattern') && companyMasterForm.controls['EVA'].touched"
                    class="form-control-error-custom">
                    Please enter maximum 5 digits before decimal point and 2 decimal after decimal point
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Exchange Code
                </label>
                <div class="form-group">
                  <input class="form-control" placeholder="Exchange Code" name="exchangeCode"
                    [formControl]="companyMasterForm.controls['exchangeCode']">
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-12 d-flex" *ngIf="hideListedCompany" id="listCompanyCheck">
                <label class="form-control-label">Listed Company</label>
                <div class="form-group">
                  <label class="form-check-label" style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['listedCompany']"
                      style="position:absolute" name="listedCompany" value=true class="form-check-input"
                      (click)="showListingTypeDiv()">Yes
                      <span class="checkmark"></span>
                  </label>
                  <label class="form-check-label"  style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['listedCompany']"
                      style="position:absolute" name="listedCompany" value=false class="form-check-input"
                      (click)="hideListingTypeDiv()">No
                      <span class="checkmark"></span>
                  </label>
                  <div *ngIf="showSelected" class="mt-3 list-comp-submenu">
                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['equityListing']"
                        [checked]="equityListingFlag"
                        (change)="onListingTypeChange(companyMasterForm.controls['equityListing'].value, 'equityListing')">Equity
                      Listing
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['equityListingSMESegment']"
                        [checked]="equityListingSMESegmentFlag" [(ngModel)]="equityListingSMESegmentFlag"
                        (change)="onListingTypeChange(companyMasterForm.controls['equityListingSMESegment'].value, 'equityListingSMESegment')">SME
                      Listing
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                      <input type="checkbox" [formControl]="companyMasterForm.controls['onlyDebtListing']"
                        [checked]="onlyDebtListingFlag"
                        (change)="onListingTypeChange(companyMasterForm.controls['onlyDebtListing'].value, 'onlyDebtListing')">Only
                      Debt Listing
                      <span class="checkmark"></span>
                    </label>
                    <div>
                      <label class="container">
                        <input type="checkbox" [formControl]="companyMasterForm.controls['debtListing']"
                          [checked]="debtListingFlag"
                          (change)="onListingTypeChange(companyMasterForm.controls['debtListing'].value , 'debtListing')">Debt
                        Listing (along with other)
                        <span class="checkmark"></span></label>
                    </div>

                    <div *ngIf="!isCheckedListedCompany" class="form-control-error-custom">
                      Please select at least one Listing type
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex">
                <label class="form-control-label">NBFC Regulations</label>
                <div class="form-group">
                  <label class="form-check-label" style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['nbfcRegulations']"
                      style="position:absolute" name="nbfc" value="Yes" class="form-check-input"
                      (click)="nbfcRegulationsValueTrue()">Yes
                      <span class="checkmark"></span>
                    </label>
                  <label class="form-check-label" style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['nbfcRegulations']"
                      style="position:absolute" name="nbfc" value="No" class="form-check-input"
                      (click)="nbfcRegulationsValueFalse()">No<span class="checkmark"></span>
                    </label>
                </div>
              </div>

              <div class="col-md-6 d-flex">
                <label class="form-control-label">Accepts Deposits</label>
                <div class="form-group">
                  <label class="form-check-label" style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['acceptsDeposits']"
                      style="position:absolute" name="acceptdeposits" value=true class="form-check-input"
                      (click)="acceptDepositValueTrue()">Yes
                      <span class="checkmark"></span></label>
                  <label class="form-check-label" style=" margin-left: 18px;">
                    <input type="radio" [formControl]="companyMasterForm.controls['acceptsDeposits']"
                      style="position:absolute" name="acceptdeposits" value=false class="form-check-input"
                      (click)="acceptDepositValueFalse()">No
                      <span class="checkmark"></span></label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6" *ngIf="acceptsDeposits">
                <label class="form-control-label" for="l0">Asset Size
                  <span _ngcontent-c2="" style="color: red;">*</span>
                </label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['amountoFDeposits']"
                    class="form-control" placeholder="Asset Size" required>
                  <div
                    *ngIf=" companyMasterForm.controls['amountoFDeposits'].hasError('required') && companyMasterForm.controls['amountoFDeposits'].touched"
                    class="form-control-error-custom">
                    Asset Size is required.
                  </div>
                  <div
                    *ngIf=" companyMasterForm.controls['amountoFDeposits'].hasError('min') && companyMasterForm.controls['amountoFDeposits'].touched"
                    class="form-control-error-custom">
                    Please enter amount greater than 0.
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Number of Shareholders</label>
                <div class="form-group">

                  <input type="number" [formControl]="companyMasterForm.controls['noOfShareholders']"
                    class="form-control" (keydown)="onlyNumber($event)">
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Number of Debenture-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfDebentureHolders']"
                    class="form-control"  (keydown)="onlyNumber($event)">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Number of Deposit-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfDepositHolders']"
                    class="form-control" (keydown)="onlyNumber($event)">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Number of Security-Holders</label>
                <div class="form-group">
                  <input type="number" [formControl]="companyMasterForm.controls['noOfSecurityHolders']"
                    class="form-control" (keydown)="onlyNumber($event)">
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Financial Year</label>
                <div class="form-group">
                  <div class="input-group" (click)="$event.stopPropagation()"
                    style="float:left; width:50%; padding-right: 5px;">
                    <span style="padding: 4px 5px 0 0;">From</span>
                    <input class="form-control" [attr.readonly]="true"
                      [formControl]="companyMasterForm.controls['FinancialYearFrom']"
                      [(ngModel)]="financialYearFromModel" style="padding:6px;background-color:white;"
                      placeholder="dd-mm-yyyy" name="dpFinancialYearFrom" ngbDatepicker #d="ngbDatepicker"
                      (click)="d.toggle();a.close();s.close()" (document:click)="a.close();s.close();d.close();">
                    <button class="input-group-addon" type="button" (click)="clearFinancialYearFrom()">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" type="button" (click)="d.toggle();a.close();s.close()">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                  <div class="input-group" (click)="$event.stopPropagation()" style="width:50%;">
                    <span style="padding: 4px 5px 0 0;">To</span>
                    <input class="form-control" [attr.readonly]="true"
                      [formControl]="companyMasterForm.controls['FinancialYearTo']"
                      [(ngModel)]="financialYearToModel" style="padding:6px;background-color:white;"
                      placeholder="dd-mm-yyyy" name="dpFinancialYearTo" ngbDatepicker #s="ngbDatepicker"
                      (click)="s.toggle();a.close();d.close()" (document:click)="a.close();s.close();d.close();">
                    <button class="input-group-addon" type="button" (click)="clearFinancialYearTo()">
                      <span class="fa fa-close" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                    <button class="input-group-addon" type="button" (click)="s.toggle();a.close();d.close()">
                      <span class="fa fa-calendar" style="width: 1.2rem; height: 1rem; cursor: pointer;"></span>
                    </button>
                  </div>
                  <div *ngIf="error.isError" style="color: #fb434a;font-size: 80%;z-index: 1;">
                    {{ error.errorMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Type of Industry</label>
                <div class="form-group">
                  <select (change)="selectIndustry($event)"
                    [formControl]="companyMasterForm.controls['typeOfIndustry']" class="form-control">
                    <option value="" style="color:#bebecd;">Select</option>
                    <option *ngFor="let typeOfIndustry of industryTypes" value="{{typeOfIndustry.IND_CODE}}">
                      {{typeOfIndustry.INDUSTRY}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label custom-tooltip">
                  Peer Companies
                  <span class="custom-tooltiptext">
                    Define companies against which you want to do Peer Analysis for Ratios
                  </span>
                </label>
                 <div class="form-group">
                  <ng-select id=peersdropdown [options]="peersList" [ngModel]="chosenPeers"
                    [formControl]="companyMasterForm.controls['peersName']" [multiple]="true"
                    (selected)="getSelectedPeers($event)" (deselected)="multiplePeersDeselected($event)">
                  </ng-select>
                  <div *ngIf="invalidPeers" class="form-control-error-custom">
                    Maximum 5 Peer companies can be selected.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
              <b>Subsidiary Company Information (This will be a table to be filled by CS of Company)</b>
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="320">Name Of Subsidiary Company</th>
                    <th width="230">CIN/Other</th>
                    <th width="420">Type</th>
                    <th width="20">Shareholding</th>
                    <th width="100">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subsidiary of subsidiaryArray; let indexCount = index">
                    <td>
                      <div *ngIf="!subsidiary.isEdit">
                        {{subsidiary.Name}}
                      </div>
                      <div *ngIf="subsidiary.isEdit" class="ddl-custom">
                            <input type="text" class="form-control"
                              [(ngModel)]="subsidiary.dirtyFincode == 'other'? subsidiary.dirtyFincode : subsidiary.dirtySubsideryName"
                              formControlName="exSubsidiaryName" [ngbTypeahead]="searchSubsidiaryCompany"
                              (selectItem)="getExistingSubsidiarySelected($event, subsidiary)" />
                          </div>

                          <div *ngIf="subsidiary.isEdit && subsidiary.isFoundOtherSubsidery">
                            <input class="form-control" value="{{subsidiary.otherSubsideryName}}"
                              [(ngModel)]='subsidiary.otherSubsideryName' type="text"
                              (focusout)="onLeaveEventOfExistingOtherSubsidery($event,subsidiary)" />
                          </div>
                    </td>
                    <td>
                          <div *ngIf="!subsidiary.isEdit">
                            {{subsidiary.CIN}}
                          </div>
                          <div *ngIf="subsidiary.isEdit">
                            <input class="form-control" type="text" maxlength="21"
                              [formControl]="companyMasterForm.controls['exSubsidiaryCIN']" value="{{subsidiary.CIN}}"
                              [(ngModel)]='subsidiary.dirtyCIN'
                              (focusout)="checkForRepeatCINForExistingSubsidery(subsidiary)" />
                            <div *ngIf="!subsidiary.isValidCIN" class="form-control-error-custom">
                              Please enter valid CIN of 21 characters.
                            </div>

                            <div *ngIf="!subsidiary.isUniqueCIN && subsidiary.isValidCIN"
                              class="form-control-error-custom">
                              Please enter unique CIN of 21 characters.
                            </div>

                          </div>
                    </td>
                    <td>
                      <div *ngIf="!subsidiary.isEdit">
                            {{subsidiary.Type}}
                      </div>
                      <div *ngIf="subsidiary.isEdit">
                            <select class="form-control" type="text"
                              [formControl]="companyMasterForm.controls['exSubsidiaryType']"
                              value="{{subsidiary.Type}}" [(ngModel)]='subsidiary.dirtySubsideryType'>
                              <option value="" style="color:#bebecd;">Select</option>
                              <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">
                                {{typeOfCompany}}</option>
                            </select>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="!subsidiary.isEdit">
                            {{subsidiary.Shareholding + " % "}}
                      </div>
                      <div *ngIf="subsidiary.isEdit">
                            <input class="form-control" type="number" min="1" max="100" maxlength = "3"
                              [formControl]="companyMasterForm.controls['exSubsidiaryShareholding']"
                              value="{{subsidiary.Shareholding}}" [(ngModel)]='subsidiary.dirtyShareholding'
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                      </div>
                    </td>
                        <td>
                          <div *ngIf="subsidiary.isEdit">
                            <button class="btn btn-sm btn-primary"
                              [disabled]="((!subsidiary.isValidExSubsideryName) ||  !subsidiary.isValidCIN || subsidiary.dirtySubsideryType=='' || subsidiary.dirtyShareholding==null || errorUniqueCIN || !(subsidiary.dirtyShareholding > 0 && subsidiary.dirtyShareholding <= 100 ))"
                              type="button" (click)="updateSubsidiaryData(subsidiary)">
                              <i class="fa fa-plus pr-2"></i>Update</button>
                            <button class="btn btn-sm btn-primary" type="button"
                              (click)="cancelDirtySelection(subsidiary)">Cancel</button>
                          </div>
                          <div *ngIf="!subsidiary.isEdit">
                            <button class="btn btn-sm btn-primary" type="button"
                              (click)="selectSubsiderydata(subsidiary);">Edit</button>
                            <button class="btn btn-sm btn-danger" type="button"
                              (click)="deleteSubsidiaryData(indexCount)">Delete</button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="ddl-custom" *ngIf="isCompanySetupWithoutMaster">
                          <input type="text" class="form-control" formControlName="newSubsidiaryCompanyName" />
                          <input class="form-control" [(ngModel)]="otherSubsideryName"
                            [formControl]="companyMasterForm.controls['otherSubsidiaryCompanyName']" type="text"
                            id="newSubsidiaryName" name="newSubsidiaryName" style="display:none"
                            (focusout)="onLeaveEventOfOtherSubsideryName()" />
                          <div *ngIf="companyMasterForm.controls['newSubsidiaryCompanyName'].hasError('maxlength')"
                            class="form-control-error-custom">
                            Name should be less than 100 characters.
                          </div>

                        </td>
                        <td class="ddl-custom" *ngIf="!isCompanySetupWithoutMaster">
                          <input type="text" class="form-control" formControlName="newSubsidiaryCompanyName"
                            [ngbTypeahead]="searchSubsidiaryCompany"
                            (selectItem)="selectedSubsidiaryCompanyItem($event)" />
                          <input class="form-control" [(ngModel)]="otherSubsideryName"
                            [formControl]="companyMasterForm.controls['otherSubsidiaryCompanyName']" type="text"
                            id="newSubsidiaryName" name="newSubsidiaryName" style="display:none"
                            (focusout)="onLeaveEventOfOtherSubsideryName()" />
                          <div *ngIf="companyMasterForm.controls['newSubsidiaryCompanyName'].hasError('maxlength')"
                            class="form-control-error-custom">
                            Name should be less than 100 characters.
                          </div>

                        </td>
                        <td>
                          <input class="form-control" [formControl]="companyMasterForm.controls['newSubsidiaryCIN']"
                            type="text" id="newSubsidiaryCIN" name="newSubsidiaryCIN" maxlength="21"
                            (focusout)="checkForRepeatCIN(companyMasterForm.controls['newSubsidiaryCIN'].value)" />
                          <div
                            *ngIf=" companyMasterForm.controls['newSubsidiaryCIN'].touched &&  companyMasterForm.controls['newSubsidiaryCIN'].hasError('maxlength') && !errorUniqueCIN"
                            class="form-control-error-custom">
                            Please enter CIN number of 21 characters.
                          </div>
                          <div *ngIf="errorUniqueCIN" class="form-control-error-custom">
                            Please enter Unique CIN of 21 characters.
                          </div>
                          <div
                            *ngIf="companyMasterForm.controls['newSubsidiaryCIN'].touched && !companyMasterForm.controls['newSubsidiaryCIN'].hasError('minlength') && !companyMasterForm.controls['newSubsidiaryCIN'].hasError('maxlength') &&  companyMasterForm.controls['newSubsidiaryCIN'].hasError('pattern') && !errorUniqueCIN"
                            class="form-control-error-custom">
                            Please enter valid CIN of 21 characters.
                          </div>
                        </td>
                        <td>
                          <select class="form-control" [formControl]="companyMasterForm.controls['newSubsidiaryType']"
                            type="text" id="newSubsidiaryType" name="newSubsidiaryType">
                            <option value="" style="color:#bebecd;">Select</option>
                            <option *ngFor="let typeOfCompany of typesOfCompany" value="{{typeOfCompany}}">
                              {{typeOfCompany}}</option>
                          </select>
                          <div
                            *ngIf=" companyMasterForm.controls['newSubsidiaryType'].touched && companyMasterForm.controls['newSubsidiaryType'].value== ''"
                            class="form-control-error-custom">
                            Please select company type.
                          </div>
                        </td>
                        <td>
                          <input class="form-control" type="number" min="1" max="100" maxlength = "3"
                            [formControl]="companyMasterForm.controls['newSubsidiaryShareholding']"
                            id="newSubsidiaryShareholding" name="newSubsidiaryShareholding" 
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                             />
                          <div *ngIf=" companyMasterForm.controls['newSubsidiaryShareholding'].hasError('max')"
                            class="form-control-error-custom">
                            Please enter valid percentage upto 100.
                          </div>
                          <div *ngIf=" companyMasterForm.controls['newSubsidiaryShareholding'].hasError('min')"
                            class="form-control-error-custom">
                            Please enter value greater than 0.
                          </div>
                        </td>
                        <td>
                          <button class="btn btn-sm btn-primary" type="button"
                            [disabled]="!( (companyMasterForm.controls['newSubsidiaryCompanyName'].value!='' && isValidSubsdieryName  && companyMasterForm.controls['newSubsidiaryCompanyName'].valid) && (companyMasterForm.controls['newSubsidiaryCIN'].valid)  && (companyMasterForm.controls['newSubsidiaryType'].value!='' && companyMasterForm.controls['newSubsidiaryType'].valid) && (companyMasterForm.controls['newSubsidiaryShareholding'].value!='' && companyMasterForm.controls['newSubsidiaryShareholding'].valid) && !(errorUniqueCIN))"
                            (click)="addSubsidiaryData()">
                            <i class="fa fa-plus pr-2"></i>Add</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <div style="width:100%; text-align: left;">
              <span style="color: #ff0000">*</span> Indicates Mandatory field
            </div>

            <button id="btnsaveCompany" type="button" class="btn btn-sm btn-primary"
              [disabled]="((!companyMasterForm.valid) || (errorUniqueCINForCompany) || invalidPeers || errorValidCINForCompany)"
              (click)="addCompany()">Save</button>
            <button id="btnClearData" type="button" class="btn btn-sm btn-primary" (click)="clearData()">Clear</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="uploadCompanyLogoPopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 50px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="border: 1px solid rgba(0,0,0,.2);">
        <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
          Select Company Logo
        </div>
        <div class="modal-body" style="padding: 10px 15px;">
          <label for="file">Choose File</label>
          <input #myInput type="file" id="file" (change)="handleFileInput($event.target.files)">
          <img [src]="base64Code" alt="base64Code" style="height: auto; width: 100%;">
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-sm" [disabled]="isInvalidImage" (click)="setCompanyLogo()">Set as company
            logo
          </button>
          <button class="btn btn-sm" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
    style="top: 50px;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div id="alertPopUpTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
          Title!
        </div>
        <div class="modal-body" style="padding: 10px 15px;">
          <div id="alertMessage" style="font-size: 16px;">
            Message..
          </div>
        </div>
      </div>
    </div>
  </div>
</section>