import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  OnChanges,
  Input,
  Output,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { DashboardService } from './../../../services/dashboard.service';

import * as _ from 'lodash';
import { environment } from './../../../../environments/environment';
import {
  NgbDateStruct,
  NgbModal,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';
import { CalendarTaskDetailsComponent } from './../calendar-task-details/calendar-task-details.component';
import { MeetingdetailsComponent } from '../meetingdetails/meetingdetails.component';
import { NotificationComponent } from './../notification/notification.component';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import * as moment from 'moment';
//have to implement taskdetails based on task from compliance or not
import { ActivatedTaskFromComplianceService } from './../../../services/activatedTaskFromCompliance.service';
import { DashboardMyTaskService } from './../../../services/dashboardmytask.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;

@Component({
  selector: 'app-calendar-task',
  templateUrl: './calendar-task.component.html',
  styleUrls: ['./calendar-task.component.css'],
})
export class CalendarTaskComponent implements OnInit, AfterViewInit, OnChanges {
  public activeId: any;
  public googleCalendarAPI: string;
  meetingListCache: any;
  private meetingList: any;

  @Input('refreshData') refreshData: any;
  @Input('rowFive') rowFive: any;

  @Output() outputStatus = new EventEmitter();

  public monthNameArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // isMyTaskFetch: any;
  isMeetingFetch: any;

  constructor(
    private companyMasterService: CompanyMasterService,
    private meetingMasterService: MeetingMasterService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService,
    private dashboardMyTaskService: DashboardMyTaskService,
    private router: Router
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    const self = this;
    // this.googleCalendarAPI = environment.clientUrl + 'calendar/google/auth'
    var userInfo = JSON.parse(localStorage.getItem('user'));
    // this.activeId = "myTaskTab"
    // let calenderDomId = "myTask"
    // const taskList = [];
    // this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");
    // if(this.isMyTaskFetch != "false"){
    //   setTimeout(function () {
    //     self.fullCalender(calenderDomId, taskList)
    //   }, 100)
    // }
    this.activeId = 'meetingTab';
    let calenderDomId = 'meeting';
    const meetingList = [];
    this.isMeetingFetch = localStorage.getItem('isMeetingFetch');
    if (this.isMeetingFetch != 'false') {
      setTimeout(function () {
        self.fullCalender(calenderDomId, meetingList);
      }, 100);
    }
  }

  ngOnChanges() {
    ////console.log('calendar task object changes', this.refreshData);
    this.ngAfterViewInit();
  }

  fullCalender(calendarDOMID, taskList) {
    const self = this;
    $('#' + calendarDOMID).fullCalendar({
      //aspectRatio: 2,
      height: 475,
      header: {
        left: 'prev',
        center: 'title',
        // right: 'month, agendaWeek, agendaDay'
        right: 'next',
      },
      buttonIcons: {
        prev: 'none fa fa-chevron-left',
        next: 'none fa fa-chevron-right',
        prevYear: 'none fa fa-arrow-left',
        nextYear: 'none fa fa-arrow-right',
      },
      // theme: true,
      // themeSystem:'bootstrap3',
      displayEventTime: false,
      editable: false,
      eventLimit: false, // allow "more" link when too many events
      eventStartEditable: false,
      viewRender: function (view, element) {
        // ////console.log('checking for the view render', view, element)
        // if (!(/Mobi/.test(navigator.userAgent)) && jQuery().jScrollPane) {
        //   $('.fc-scroller').jScrollPane({
        //     autoReinitialise: true,
        //     autoReinitialiseDelay: 100
        //   });
        // }
      },
      // defaultDate: '2017-05-12',
      events: function (start, end, timezone, callback) {
        const titleCalendar = $('#' + calendarDOMID).fullCalendar(
          'getView'
        ).title;
        const monthName = _.first(titleCalendar.split(' '));
        const year = parseInt(titleCalendar.split(' ')[1]);
        let monthindex = _.findIndex(self.monthNameArray, function (o) {
          return o == monthName;
        });
        const firstDate = new Date(year, monthindex);
        const lastDate = new Date(year, monthindex + 1, 0);
        const userId = JSON.parse(localStorage.getItem('user'))._id;
        const uniqueUserId = JSON.parse(
          localStorage.getItem('user')
        ).UniqueUserId;
        let rangeOfDate = {
          userId: userId,
          firstDate: firstDate,
          lastDate: lastDate,
        };
        let userInfo = JSON.parse(localStorage.getItem('user'));
        let assignedToId = userInfo._id;
        let companyId = userInfo.CompanyId;
        const isMyTask = localStorage.getItem("isMyTaskFetch");
        //console.log("isMyTask", isMyTask)
        self.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUserId).then(responseForCompanyAndUserId => {
          if (!responseForCompanyAndUserId.status) {
            if (calendarDOMID == 'meeting') {
              //console.log('it is inside meeting')
              let meetingPromise = [];
              for (var meetingIndex = 0; meetingIndex < responseForCompanyAndUserId.listOfId.length; meetingIndex++) {
                meetingPromise.push(self.meetingMasterService.getMeetingList(responseForCompanyAndUserId.listOfId[meetingIndex].CompanyId))
              }
              // to get GEM URL First and then calling other function
              var gemURL = '';
              self.companyMasterService
                .getGEMUrl()
                .then((responseCompany) => {
                  //console.log("response GEM URL", responseCompany);
                  if (responseCompany.status == 0) {
                    var companyDetails = responseCompany.companyData;
                    //console.log("response GEM URL companyDetails", companyDetails);
                    if (_.size(companyDetails)) {
                      gemURL = companyDetails[0].GEMAccessURL
                        ? companyDetails[0].GEMAccessURL
                        : '';
                      //console.log("gemURL", gemURL);
                      if (_.size(gemURL)) {
                        meetingPromise.push(
                          self.meetingMasterService.getRegularMeetingList(gemURL)
                        );
                      }
                    }
                  }
                });

              forkJoin(meetingPromise).subscribe((responseforMeetings) => {
                //console.log('responseforMeetings', responseforMeetings)
                let errorObject = _.find(
                  responseforMeetings,
                  function (element) {
                    return _.get(element, 'status') == 1;
                  }
                );
                if (_.isUndefined(errorObject)) {
                  let userSpecificMeetingList: any = [];
                  let tempArray = [];
                  self.meetingList = _.map(
                    responseforMeetings,
                    function (element) {
                      if (!_.isUndefined(_.get(element, 'MeetingList'))) {
                        //console.log("element -- ", element)
                        return _.concat(
                          tempArray,
                          _.get(element, 'MeetingList')
                        );
                      } else {
                        //console.log("element -- else", element)
                        // this is regular meeting list
                        return _.concat(tempArray, element);
                      }

                      // return _.concat(tempArray, _.get(element, 'MeetingList'))
                    }
                  );
                  //console.log('self.meetingList for meeting', self.meetingList)
                  self.meetingList = _.flattenDeep(self.meetingList);
                  // self.meetingList = _.reduce(responseforMeetings, function (currentElement, nextElement) {
                  //   return _.concat(currentElement, nextElement)
                  // })
                  if (_.size(self.meetingList)) {
                    userSpecificMeetingList = _.filter(
                      self.meetingList,
                      function (meetingObject) {
                        if (_.size(_.get(meetingObject, 'Directors'))) {
                          var directorArray = _.find(
                            _.get(meetingObject, 'Directors'),
                            function (directorObject) {
                              if (
                                _.get(directorObject, 'UserGovevaId') !=
                                undefined
                              ) {
                                let assignedUser = _.find(
                                  responseForCompanyAndUserId.listOfId,
                                  function (element) {
                                    return (
                                      element._id ==
                                      _.get(directorObject, 'UserGovevaId')
                                    );
                                  }
                                );
                                if (!_.isUndefined(assignedUser)) {
                                  // if (_.get(directorObject, 'UserGovevaId') == assignedToId) {
                                  return directorObject;
                                }
                              }
                            }
                          );

                          if (_.size(directorArray)) {
                            return meetingObject;
                          }
                        }

                        if (_.size(_.get(meetingObject, 'Members'))) {
                          var memberArray = _.find(
                            _.get(meetingObject, 'Members'),
                            function (memberObject) {
                              let assignedUser = _.find(
                                responseForCompanyAndUserId.listOfId,
                                function (element) {
                                  return (
                                    element._id ==
                                    _.get(memberObject, 'UserGovevaId')
                                  );
                                }
                              );
                              if (!_.isUndefined(assignedUser)) {
                                // if (_.get(memberObject, 'UserGovevaId') == assignedToId) {
                                return memberObject;
                              }
                            }
                          );

                          if (_.size(memberArray)) {
                            return meetingObject;
                          }
                        }

                        if (_.size(_.get(meetingObject, 'Invitees'))) {
                          var inviteesArray = _.find(
                            _.get(meetingObject, 'Invitees'),
                            function (inviteesObject) {
                              let assignedUser = _.find(
                                responseForCompanyAndUserId.listOfId,
                                function (element) {
                                  return (
                                    element._id ==
                                    _.get(inviteesObject, 'UserGovevaId')
                                  );
                                }
                              );
                              if (!_.isUndefined(assignedUser)) {
                                // if (_.get(inviteesObject, 'UserGovevaId') == assignedToId) {
                                return inviteesObject;
                              }
                            }
                          );

                          if (_.size(inviteesArray)) {
                            return meetingObject;
                          }
                        }

                        if (_.size(_.get(meetingObject, 'meetingType'))) {
                          var meetingType = _.get(
                            meetingObject,
                            'meetingType'
                          );
                          if (!_.isUndefined(meetingType)) {
                            // //console.log("regular meeting added",meetingType, 'REGULAR_MEETING');
                            if (meetingType == 'REGULAR_MEETING') {
                              return meetingObject;
                            }
                          }
                        }
                      }
                    );
                  }
                  var meetingListMain: any = [];
                  //console.log('userSpecificMeetingList for meeting', userSpecificMeetingList)
                  self.meetingListCache = userSpecificMeetingList;
                  const meetingList = userSpecificMeetingList.map(function (
                    o
                  ) {
                    return {
                      _id: o.meetingType ? o.meetingId : o._id,
                      title: o.MeetType == 'Meeting' ? o.MeetingTypeName : `CR-${o.MeetingTypeName}`,
                      start: o.meetingType ? o.meetingDateInDate : o.MeetingDate,
                      end: o.meetingType ? o.meetingDateInDate : o.MeetingDate,
                      className: 'fc-event-default',
                      activatedTaskFromCompliance: 'meeting',
                    };
                  });
                  //console.log("meetingList----calendar", meetingList);
                  callback(meetingList);
                }
              });

              // self.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUserId).then(responseForCompanyAndUserId => {
              //   if(!responseForCompanyAndUserId.status) {
              //     if (calendarDOMID == 'meeting') {
              //       //console.log('it is inside meeting')
              //       let meetingPromise = [];
              //       for(var meetingIndex = 0; meetingIndex < responseForCompanyAndUserId.listOfId.length; meetingIndex++) {
              //         meetingPromise.push(self.meetingMasterService.getMeetingList(responseForCompanyAndUserId.listOfId[meetingIndex].CompanyId))
              //       }
              //       meetingPromise.push(self.meetingMasterService.getRegularMeetingList())

              //       Observable.forkJoin(meetingPromise).subscribe(responseforMeetings => {
              //         //console.log('responseforMeetings', responseforMeetings)
              //         let errorObject = _.find(responseforMeetings, function (element) { return _.get(element, 'status') == 1 })
              //         if (_.isUndefined(errorObject)) {
              //           let userSpecificMeetingList: any = []
              //           let tempArray = [];
              //           self.meetingList = _.map(responseforMeetings, function(element) {

              //             if(!_.isUndefined(_.get(element, 'MeetingList'))) {
              //               //console.log("element -- ",element)
              //               return  _.concat(tempArray, _.get(element, 'MeetingList'))
              //             }else{
              //               //console.log("element -- else",element)
              //               // this is regular meeting list
              //               return _.concat(tempArray , element);
              //             }

              //             // return _.concat(tempArray, _.get(element, 'MeetingList'))
              //           })
              //           //console.log('self.meetingList for meeting', self.meetingList)
              //           self.meetingList = _.flattenDeep(self.meetingList)
              //           // self.meetingList = _.reduce(responseforMeetings, function (currentElement, nextElement) {
              //           //   return _.concat(currentElement, nextElement)
              //           // })
              //           if (_.size(self.meetingList)) {
              //             userSpecificMeetingList = _.filter(self.meetingList, function (meetingObject) {

              //               if (_.size(_.get(meetingObject, 'Directors'))) {
              //                 var directorArray = _.find(_.get(meetingObject, 'Directors'), function (directorObject) {
              //                   if (_.get(directorObject, 'UserGovevaId') != undefined) {
              //                     let assignedUser = _.find(responseForCompanyAndUserId.listOfId, function(element){ return element._id == _.get(directorObject, 'UserGovevaId')})
              //                 if (!_.isUndefined(assignedUser)) {
              //                     // if (_.get(directorObject, 'UserGovevaId') == assignedToId) {
              //                       return directorObject;
              //                     }
              //                   }
              //                 })

              //                 if (_.size(directorArray)) {
              //                   return meetingObject;
              //                 }
              //               }

              //               if (_.size(_.get(meetingObject, 'Members'))) {

              //                 var memberArray = _.find(_.get(meetingObject, 'Members'), function (memberObject) {
              //                   let assignedUser = _.find(responseForCompanyAndUserId.listOfId, function(element){ return element._id == _.get(memberObject, 'UserGovevaId')})
              //                 if (!_.isUndefined(assignedUser)) {
              //                   // if (_.get(memberObject, 'UserGovevaId') == assignedToId) {
              //                     return memberObject;
              //                   }
              //                 });

              //                 if (_.size(memberArray)) {
              //                   return meetingObject;
              //                 }

              //               }

              //               if (_.size(_.get(meetingObject, 'Invitees'))) {

              //                 var inviteesArray = _.find(_.get(meetingObject, 'Invitees'), function (inviteesObject) {
              //                   let assignedUser = _.find(responseForCompanyAndUserId.listOfId, function(element){ return element._id == _.get(inviteesObject, 'UserGovevaId')})
              //                 if (!_.isUndefined(assignedUser)) {
              //                   // if (_.get(inviteesObject, 'UserGovevaId') == assignedToId) {
              //                     return inviteesObject;
              //                   }
              //                 });

              //                 if (_.size(inviteesArray)) {
              //                   return meetingObject;
              //                 }

              //               }

              //             if(_.size( _.get(meetingObject, 'meetingType'))){
              //               var meetingType = _.get(meetingObject, 'meetingType')
              //               if(!_.isUndefined(meetingType)){
              //                 // //console.log("regular meeting added",meetingType, 'REGULAR_MEETING');
              //                 if(meetingType == 'REGULAR_MEETING'){
              //                   return meetingObject;
              //                 }
              //               }

              //             }

              //             })
              //           }
              //       var meetingListMain: any = [];
              //           //console.log('userSpecificMeetingList for meeting', userSpecificMeetingList)
              //           self.meetingListCache = userSpecificMeetingList;
              //           const meetingList = userSpecificMeetingList.map(function (o) {
              //             return {
              //               _id: o.meetingType ? o.meetingId : o._id,
              //               title: o.meetingType ? o.meetingName : (_.size(o.MeetingTypeID) ? o.MeetingTypeID.MeetingType : ""),
              //               start: o.meetingType ? o.meetingDateInDate : o.MeetingDate,
              //               end: o.meetingType ? o.meetingDateInDate : o.MeetingDate,
              //               className: 'fc-event-default',
              //               activatedTaskFromCompliance: "meeting"
              //             }
              //           })

              //           callback(meetingList);
              //         }

              //       })
            } else if (calendarDOMID == 'myTask' && isMyTask != 'false') {
              let myTaskPromise = [];
              var taskListMain: any = [];
              for (
                var userIndex = 0;
                userIndex < responseForCompanyAndUserId.listOfId.length;
                userIndex++
              ) {
                rangeOfDate.userId =
                  responseForCompanyAndUserId.listOfId[userIndex]._id;
                myTaskPromise.push(
                  self.dashboardService.getTaskListbydateRange(rangeOfDate)
                );
              }

              forkJoin(myTaskPromise).subscribe(
                (responseFormyTaskForDifferentUserId) => {
                  //console.log('responseFormyTaskForDifferentUserId', responseFormyTaskForDifferentUserId)
                  let errorObject = _.find(
                    responseFormyTaskForDifferentUserId,
                    function (element) {
                      return !_.isUndefined(_.get(element, 'taskList'));
                    }
                  );
                  //console.log('in my task', _.isNil(errorObject))
                  if (_.isNil(errorObject)) {
                    let taskArray = [];
                    let tempList = [];
                    taskArray = _.map(
                      responseFormyTaskForDifferentUserId,
                      function (element) {
                        return _.concat(tempList, _.get(element, 'result'));
                      }
                    );
                    taskArray = _.flatten(taskArray);
                    //console.log('taskArray======>', taskArray)
                    let taskList = taskArray.map(function (o) {
                      return {
                        _id: o._id,
                        title: o.TaskName,
                        start: moment(o.StartDate),
                        end: moment(o.EndDate).add(1, 'days'),
                        className: 'fc-event-default',
                        activatedTaskFromCompliance: false,
                      };
                    });
                    //console.log("taskList", taskList);
                    // callback(taskList)
                    taskListMain = taskListMain.concat(taskList);
                    //console.log("tasklistMain", taskListMain);
                  }
                }
              );

              // self.dashboardService.getTaskListbydateRange(rangeOfDate).then(response => {
              //   //console.log("mytask", response);
              // if (!response.status) {
              //   const taskList = response.result.map(function (o) {
              //     return {
              //       _id: o._id,
              //       title: o.TaskName,
              //       start: moment(o.StartDate),
              //       end: moment(o.EndDate).add(1, 'days'),
              //       className: 'fc-event-default',
              //       activatedTaskFromCompliance: false
              //     }
              //   })
              //   //console.log("taskList", taskList);
              //   // callback(taskList)
              //   taskListMain = taskListMain.concat(taskList);
              //   //console.log("tasklistMain", taskListMain);
              // }
              if (companyId != '') {
                // self.activatedTaskFromComplianceService.getActivatedTaskWithAdditionalJsonData(companyId).then(result => {

                self.activatedTaskFromComplianceService
                  .getMyActivatedTaskList(companyId, assignedToId)
                  .then((result) => {
                    //console.log("testservice result", result);

                    if (result != undefined) {
                      //added on 5Apr
                      //removed such task which has start date and end date null or date key not available

                      result = result.filter(
                        (g) =>
                          g.StartDate != null &&
                          g.StartDate != undefined &&
                          g.EndDate != undefined &&
                          g.EndDate != null
                      );
                      // g.StartDate!= null || g.StartDate!= undefined || g.EndDate!= null ||

                      const activeTaskList = result.map(function (o) {
                        return {
                          _id: o._id,
                          title: o.TaskName,
                          start: moment(o.StartDate), //"2018-02-27T13:47:10.957Z",//o.TriggerStartDate,
                          end: moment(o.EndDate).add(1, 'days'),
                          className: 'bday_block',
                          activatedTaskFromCompliance: true, //o.ActivatedTaskFromCompliance,
                        };
                      });

                      taskListMain = taskListMain.concat(activeTaskList);
                    }
                    callback(taskListMain);
                  });
              }
              // })
            } else {
              var teamTaskListMain: any = [];

              self.dashboardService
                .getTeamTaskByUserId(rangeOfDate)
                .then((response) => {
                  //console.log("mytask by userID", response);
                  if (!response.status) {
                    const taskList = response.result.map(function (o) {
                      return {
                        _id: o._id,
                        title: o.TaskName,
                        start: moment(o.StartDate),
                        end: moment(o.EndDate).add(1, 'days'),
                        className: 'fc-event-default',
                        activatedTaskFromCompliance: false,
                      };
                    });

                    teamTaskListMain = teamTaskListMain.concat(taskList);
                    callback(teamTaskListMain);
                  }
                });

              if (companyId != '') {
                //api in goveva calls the internally pss api by sending user ids and date range.

                let rangeOfDateForCompliance = {
                  companyId: companyId,
                  userId: userId,
                  firstDate: firstDate,
                  lastDate: lastDate,
                };

                // TODO: Data manupulate here !!!!

                self.activatedTaskFromComplianceService
                  .getComplianceTeamTaskList(rangeOfDateForCompliance)
                  .then((result) => {
                    //console.log("testservice result", result);

                    if (result != undefined) {
                      //removed such task which has start date and end date null or date key not available

                      result = result.filter(
                        (g) =>
                          g.StartDate != null &&
                          g.StartDate != undefined &&
                          g.EndDate != undefined &&
                          g.EndDate != null
                      );

                      const taskList = result.map(function (o) {
                        return {
                          _id: o._id,
                          title: o.TaskName,
                          start: moment(o.StartDate),
                          end: moment(o.EndDate).add(1, 'days'),
                          className: 'bday_block',
                          activatedTaskFromCompliance: true,
                        };
                      });
                      teamTaskListMain = teamTaskListMain.concat(taskList);
                      callback(teamTaskListMain);
                    }
                  });
              }
            }
          }
        });
      }, //taskList,
      eventClick: function (calEvent, jsEvent, view) {
        // click of the event in the calender.
        // add modal here to show task details.
        // get information for the task
        // ////console.log('calEvent', calEvent, jsEvent)

        //console.log("calEvent, jsEvent, view", calEvent, jsEvent, view);

        if (calEvent.activatedTaskFromCompliance == 'meeting') {
          const meetingDetails = _.find(
            self.meetingListCache,
            function (meeting) {
              // as regular meeting doesnt have _id  we have to map id accordingly
              var meetingId = meeting.meetingType
                ? meeting.meetingId
                : meeting._id;
              if (meetingId == calEvent._id) {
                return meeting;
              }
            }
          );

          //console.log("meetingDetails", meetingDetails);

          if (_.size(meetingDetails)) {
            const modalRef = self.modalService.open(MeetingdetailsComponent, {
              windowClass: 'modal-xlC',
            });
            modalRef.componentInstance.meetingObject = meetingDetails;
            modalRef.result
              .then(
                (response) => {
                  console.log('response from calender view ---->', response);
                  var joinInPerson = localStorage.getItem('joinInPerson');
                  if (joinInPerson != 'yes') {
                    self.router.navigate(['webex', response]);
                  } else {
                    var meetingIdToSend = localStorage.getItem('meetingId');
                    self.router.navigate(['/attending-inperson', response]);
                  }
                },
                (error) => {
                  //console.log("modalthenerror", error)
                }
              )
              .catch((error) => {
                //console.log("Catch Error", error)
              });
          } else {
            const modalRef = self.modalService.open(MeetingdetailsComponent, {
              size: 'lg',
            });
            modalRef.componentInstance.meetingObject = [];
            modalRef.result
              .then(
                (response) => {
                  console.log('response', response);
                },
                (error) => {
                  //console.log("modalthenerror", error)
                }
              )
              .catch((error) => {
                //console.log("Catch Error", error)
              });
          }
        } else {
          const modalRef = self.modalService.open(
            CalendarTaskDetailsComponent,
            { size: 'lg' }
          );
          modalRef.componentInstance.taskId = {
            _id: calEvent._id,
            calendarId: calendarDOMID,
            activatedTaskFromCompliance: calEvent.activatedTaskFromCompliance,
          };
          modalRef.result.then(
            (response) => {
              // update the class name here if we are using status.
              if (!response.status) {
                self.outputStatus.emit(response);
                self.ngAfterViewInit();
                const notificationModalRef = self.modalService.open(
                  NotificationComponent
                );
                notificationModalRef.componentInstance.notify = {
                  status: 'Success',
                  message: 'Task status is updated successfully',
                };
              } else {
                const notificationModalRef = this.modalService.open(
                  NotificationComponent
                );
                notificationModalRef.componentInstance.notify = {
                  status: 'Failure',
                  message: 'OOPS! Something went wrong.',
                };
              }
            },
            (reason) => { }
          );
        }
      },
      eventRender: function (event, element) {
        // add condtion for the className according to the status.
        element.css({
          cursor: 'pointer',
        });
      },
    });
  }

  tabSelected(data) {
    // ////console.log(data, data.nextId == "teamTaskTab")
    if (this.isMeetingFetch != 'false') {
      const self = this;
      const userInfo = JSON.parse(localStorage.getItem('user'));
      if (data == 'meetingTab') {
        const calendarId = 'meeting';
        const meetingList = [];
        setTimeout(function () {
          self.fullCalender(calendarId, meetingList);
        }, 100);
      } else if (data == 'teamTaskTab') {
        const calendarId = 'teamTask';
        const taskList = [];

        setTimeout(function () {
          self.fullCalender(calendarId, taskList);
        }, 100);
      } else {
        ////console.log('inside my task')
        const calendarId = 'myTask';
        const taskList = [];
        setTimeout(function () {
          self.fullCalender(calendarId, taskList);
        }, 100);
      }
    }
  }
}
