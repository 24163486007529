<!-- START: charts/chartistjs -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Chartist.js</strong>
            <a href="https://gionkunz.github.io/chartist-js/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>CSS Styling & Animations</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-css-animations chartist-animated chartist-theme-dark height-300"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>SMIL Animations</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-smil-animations height-300 chartist"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Line</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-line height-300 chartist"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Area</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-area height-300 chartist"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Scatter</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-scatter height-300 chartist"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Horizontal Bar</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-horizontal-bar height-300 chartist"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Bi-polar Line</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-bi-polar-line height-300 chartist"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Bi-polar Bar</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-bi-polar-bar height-300 chartist"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Stacked Bar</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-stacked-bar height-300 chartist"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Overlapping Bar</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-overlapping-bar height-300 chartist"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Simple Pie</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-simple-pie height-300 chartist"></div>
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Pie w/ Labels</strong></h5>
                <p class="text-muted">Element: read <a href="https://gionkunz.github.io/chartist-js/" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <div class="chart-pie-w-labels height-300 chartist"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: charts/chartistjs -->
