import { Component } from '@angular/core';

@Component({
  selector: 'cat-page',
  templateUrl: './loading-progress.html'
})

export class ComponentsLoadingProgress {
  declare NProgress:any;
}

