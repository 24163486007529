import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { RequestPasswordComponent } from './request-password/request-password.component';
import { SubmitOtpComponent } from './submit-otp/submit-otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NotificationComponent } from './notification/notification.component';
import { LoginService } from './../services/login.service';
import { SuccessNotificationComponent } from './success-notification/success-notification.component';

export const routes: Routes = [
  { path: 'requestPassword', component: RequestPasswordComponent },
  { path: 'SubmitOTP', component: SubmitOtpComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
  ],
  declarations: [
    RequestPasswordComponent,
    SubmitOtpComponent,
    ResetPasswordComponent,
    NotificationComponent,
    SuccessNotificationComponent,
  ],
  entryComponents: [NotificationComponent, SuccessNotificationComponent],
  providers: [LoginService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ForgotPasswordModule {}
