import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-img-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthGuard } from './authGuard/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CompanySetupComponent } from './companySetup/companySetup.component';
import { CreateiccheckericmakerComponent } from './createiccheckericmaker/createiccheckericmaker.component';
// import { StructureModule } from './structure/structure.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { GoogleOrgChartService } from './google-org-charts.service';
// // import { SafeHtmlPipe } from './pipe/safe-html.pipe'
import {
  MyHttpInterceptor,
  MyHttpInterceptor2,
  subsidiaryInterceptor,
} from './interceptor/interceptorClass';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { OTPLoginComponent } from './otp-login/otp-login.component';
import { RouteGuard } from './routeGaurd/route.guard';
import { IndividualMeetingComponent } from './individual-meeting/individual-meeting.component';

import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { ExportExcelService } from './services/export-excel.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { GfmService } from './services/gfm.service';
import { LastLoginDetailsService } from './services/last-login-details.service';
import { LoginService } from './services/login.service';
import { OrganisationChartService } from './services/organisationChart.service';
import { OTPLoginService } from './services/otp-login.service';
import { SmtpService } from './services/smtp.service';
import { SharedModule } from './shared/sharedmodule';
import { SuperAdminSetupComponent } from './superAdminSetup/superAdminSetup.component';
import { NotificationComponent } from './otp-login/notification/notification.component';
import { SuccessNotificationComponent } from './otp-login/success-notification/success-notification.component';
import { SubmitOtpComponent } from './otp-login/submit-otp/submit-otp.component';
import { AutologinComponent } from './autologin/autologin.component';
import { BoardEvalStaticComponent } from './board-eval-static/board-eval-static.component';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateParserFormatter} from './services/datepicker-adapter'

import {
  SocialLoginModule,
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { DataImportService } from './services/dataImport.service';
import { WebexComponent } from './structure/dashboards/webex/webex.component';
import { WebexService } from './services/webex.service';
import { StructureModule } from './structure/structure.module';
declare var NProgress: any;

@NgModule({
  declarations: [
    AppComponent,
    OTPLoginComponent,
    LoginComponent,
    CompanySetupComponent,
    ChangePasswordComponent,
    NotificationComponent,
    SubmitOtpComponent,
    SuccessNotificationComponent,
    IndividualMeetingComponent,
    SuperAdminSetupComponent,
    CreateiccheckericmakerComponent,LogoutComponent, AutologinComponent, BoardEvalStaticComponent,
    //  SafeHtmlPipe
  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    StructureModule,
    SocialLoginModule,
    ForgotPasswordModule,
    NgSelectModule,
    SharedModule,
    NgbModule,
    ImageCropperModule,
    NgAddToCalendarModule,
    NgxPaginationModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [
    NgbActiveModal,
    LoginService,
    ForgotPasswordService,
    OTPLoginService,
    GoogleOrgChartService,
    OrganisationChartService,
    RouteGuard,
    AuthGuard,
    GfmService,
    ExportExcelService,
    LastLoginDetailsService,
    DatePipe,
    SmtpService,
    DataImportService,
    WebexService,
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor2,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: subsidiaryInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '661788623556-su8stf2nvaq4fvgh4b2a0vh11ivr3ve7.apps.googleusercontent.com'
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    
  ],
  entryComponents: [
    
    NotificationComponent
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule {
  constructor(private router: Router) {
    router.events.subscribe((event) => {
      // if(event instanceof NavigationStart) {
      //   NProgress.start();
      // }
      // if(event instanceof NavigationEnd) {
      //   setTimeout(function(){
      //     NProgress.done();
      //   }, 200);
      // }
    });
  }
}

