import { reject } from 'lodash';
import { baseUrl, getHeaders } from '../urls';
import { safeJsonStringify } from '../../../../shared/utils';

interface CreateRoomParams {
  title: string;
  teamId?: string;
}

export type ROOM_TYPE = 'group' | 'direct';
export type SORT_BY = 'id' | 'lastactivity' | 'created';
interface ListRoomsParams {
  teamId?: string;
  type?: ROOM_TYPE;
  sortBy?: SORT_BY;
  max?: string;
}

export class Group {
  getRoomDetails(roomId?: string) {
    return new Promise((resolve, reject) => {
      fetch(baseUrl + '/v1/rooms/' + roomId, {
        method: 'get',
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  createRoom(params: CreateRoomParams) {
    return new Promise((resolve, reject) => {
      fetch(baseUrl + '/v1/rooms', {
        method: 'post',
        body: safeJsonStringify(params),
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }

  listRooms(params?: ListRoomsParams) {
    var url = new URL(baseUrl + '/v1/rooms');
    Object.keys(params).forEach((val, ind) => {
      url.searchParams.append(val, params[val]);
    });
    return new Promise((resolve, reject) => {
      fetch(url.toString(), {
        method: 'get',
        headers: getHeaders(),
      })
        .then((resJson) => resolve(resJson))
        .catch((err) => reject(err));
    });
  }
}
