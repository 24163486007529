import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { NgbDateStruct, NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MeetingagendacomplianceComponent } from '../meetingagendacompliance/meetingagendacompliance.component';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import { ViewSDKClient } from '../../../services/ViewSDKClient.service';
import { environment } from '../../../../environments/environment';
import { data } from 'jquery';
import * as _ from 'lodash';
import { take, last } from 'rxjs/operators';
import { Subscription } from 'rxjs';

declare var $: any;
declare var jQuery: any;

import { MeetingagendaComponent } from '../meetingagenda/meetingagenda.component';
//import { Router, ActivatedRoute } from '@angular/router';

@Component({
  providers: [MeetingagendaComponent],
  selector: 'app-agendadetail',
  templateUrl: './agendadetail.component.html',
  styleUrls: ['./agendadetail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgendadetailComponent {

  @Input('agendaObject') agendaObject: any;
  @Input('meetingObject') meetingObject: any;
  @Input('clickedAgenda') clickedAgenda: any;
  @Input('currentAgenda') currentAgenda: any;
  @Output() emitFileURL = new EventEmitter();


  urlSafe: SafeResourceUrl;
  agendaCompliances: any;
  isAgendaObjectEmpty: boolean;
  modalRef: NgbModalRef;
  agendaPdfIsAvailable: any;
  agendaUplaodedPdfID: any;
  agendaGenerationTitle: any;
  agendaGenerationNumber: any;
  agendaGenerationID: any;
  AgendaFile: any;
  count: number = null;
  newurl: any;
  FinalUrl: any;
  AttachMent: any;
  AgendaNumber: any;
  currentAgendaOnDisplay:number; // starts@:0 keep track of current agenda being displayed to show the pin icon for attachment
  showAgenda: any;
  hideAgenda: any;
  groupList: any = [];
  updateAgenda: Subscription;
  constructor(
    public activeModal: NgbActiveModal,
    public meetingMasterService: MeetingMasterService,
    public elementRef: ElementRef,
    private viewSDKClient: ViewSDKClient,
    public sanitizer: DomSanitizer) {
    this.meetingMasterService.Pagination.subscribe(id => {

      this.showAgenda = true;
      this.hideAgenda = false;
      this.currentAgendaOnDisplay=0;
      this.AgendaNumber = id;
      this.count = this.getMaxIndex(this.agendaObject)
    })
  }

  getButtonsDisabled(page) {
    console.log("00000000000000", page, this.count)
    if (page == this.count) {
      $('.next').hide();
      $('.lastAgenda').show();
      $('.back').show();
      $('.firstAgenda').hide();
    }
    if (page == -1) {
      $('.next').show();
      $('.lastAgenda').hide();
      $('.back').hide();
      $('.firstAgenda').show();
    }
    if (page >= 0 && page != this.count) {
      $('.back').show();
      $('.firstAgenda').hide();
      $('.next').show();
      $('.lastAgenda').hide();
    }

    if (page <= 1) {
      $('.next').show();
      $('.lastAgenda').hide();
      $('.back').hide();
      $('.firstAgenda').show();
    }

  }

  title = 'angularpdf';


  async ngOnChanges() {
    if (this.currentAgenda) {
      const { FinalUrl, page } = this.currentAgenda
      console.log("=========start", page, this.currentAgenda);
      this.currentAgendaOnDisplay=page-1;
      console.log("=========startAttach currentAgendaOnDisplay", this.currentAgendaOnDisplay);

      this.viewSDKClient.ready().then(() => {
        /* Invoke file preview */
        /* By default the embed mode will be Full Window */
        console.log("=========then", page, this.currentAgenda);
        this.viewSDKClient.previewFile('pdf-div', {},this.currentAgenda.FinalUrl,this.currentAgenda.filename);
      });


      this.AttachMent = FinalUrl;
      if (page != null && page >= 0)
        this.AgendaNumber = page;
      else
        this.AgendaNumber = 1;

      this.showAgenda = true;
      this.hideAgenda = false;
      this.count = this.getMaxIndex(this.agendaObject)
      this.getButtonsDisabled(page);
      if (_.size(this.agendaObject)) {
        this.agendaObject = _.sortBy(this.agendaObject, o => o.Agendaorder)
        this.agendaObject = (this.agendaObject).map(function (item) {
          var o = Object.assign({}, item);
          o.fileType = (item.AgendaGenerationPdf).map(function (subItem) {
            var oSub = Object.assign({}, subItem);
            oSub = (subItem.UploadFileName).slice(-3)
            return oSub;
          });
          return o;
        });
        this.isAgendaObjectEmpty = false;
      } else {
        this.isAgendaObjectEmpty = true;
      }
    }
  }

  public async updateComponent(fileID) {
    const response = await this.meetingMasterService.getAttendanceFileName(fileID)
    var url = environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0";
    var arr = url.split('https:'); // split string        
    var exc = arr[1];
    var url_exc = encodeURIComponent(exc);
    var newUrlPdf = url_exc + '/' + this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName)
    this.FinalUrl = location.protocol + "//" + location.host + "/#/pdfviewer/https:" + newUrlPdf;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.FinalUrl);
    this.groupList.push({ 'name': this.FinalUrl.toString() });
    console.log(this.groupList)
  }

  initiateUploadAgendaPdf(data) {
    this.agendaPdfIsAvailable = data.AgendaGenerationPdf.IsPdfAvailable;
    this.agendaUplaodedPdfID = data.AgendaGenerationPdf.DocumentManagementID;
    this.agendaGenerationTitle = data.AgendaTitle;
    this.agendaGenerationNumber = data.AgendaNumber;
    this.agendaGenerationID = data._id;
    this.AgendaFile = data.AgendaGenerationPdf.map(element => {
      var o = Object.assign({}, element)
      o.FileType = (element.PdfName).substr(-3)
      return o;
    })

    $("#AgendaPdfUploadTitlePSPDF").html("Attachment For Agenda");
    $("#agendaPdfUploadDialogPSPFD").modal('toggle');
    $("#AgendaTitleOnPopup").html(this.agendaGenerationTitle);
    $('body').css('overflow', 'hidden');
    $('.modal').css('overflow', 'auto');
  }

  downloadDocumentFile(fileID, fileType) {
    if (fileType == 'PDF' || fileType == 'pdf') {
      $('#myModalForDocument').data('modal', null);
      console.log('agendadetail2')
      this.meetingMasterService.getAttendanceFileName(fileID).then(response => {
        var url = environment.PSSFileurl + "/Document_Type/" + response.data.Filename + "#toolbar=0";
        var arr = url.split('https:'); // split string        
        var exc = arr[1];
        var url_exc = encodeURIComponent(exc);
        var newUrlPdf = url_exc + '/' + this.meetingObject._id + '/' + fileID + '/' + btoa(response.data.OriginalFileName)
        this.AttachMent = location.protocol + "//" + location.host + "/#/pdfviewer/https:" + newUrlPdf;
        this.showAgenda = false;
        this.hideAgenda = true;
        $("#agendaPdfUploadDialogPSPFD").modal('hide');
        $('body').css('overflow', 'hidden');
        $('.modal').css('overflow', 'auto');

        this.viewSDKClient.ready().then(() => {
          /* Invoke file preview */
          /* By default the embed mode will be Full Window */
          console.error("=========downloadDocumentFile> then",  url);
          var filename = response.data.OriginalFileName ? response.data.OriginalFileName : "File";
          this.viewSDKClient.previewFile('pdf-div-attach', {},url,filename);
        });
      })
    } else {
      this.meetingMasterService.getAttendanceFile(fileID);
    }
  }

  closeModal() {
    $('body').css('overflow', 'hidden');
    $('.modal').css('overflow', 'auto');
  }

  showCurrent() {
    this.showAgenda = true;
    this.hideAgenda = false;
  }

  async showNext() {
    console.log("showNext", this.AgendaNumber, this.agendaObject, this.agendaObject.length)
    this.showAgenda = true;
    this.hideAgenda = false;
    if(this.currentAgendaOnDisplay< this.agendaObject.length-1){//should now be increased if already on last agenda
      this.currentAgendaOnDisplay ++;
    }
    this.count = this.getMaxIndex(this.agendaObject)
    this.onEmitNext(1);
  }

  onEmitNext(increaseBy) {
    try {
        let agendaDetails = this.agendaObject.filter(ele => ele.AgendaNumber == (this.AgendaNumber + increaseBy).toString())
        this.emitFileURL.emit({ agendaId: agendaDetails[0].AgendaConvertedDocxToPdf.DocumentManagementID, page: this.AgendaNumber + increaseBy })
    } catch (err) {
      this.onEmitNext(increaseBy + 1);
    }
  }

  getMaxIndex(array) {
    let arr = array
    let result = arr.sort(
      function (a, b) {
        return parseFloat(b['AgendaNumber']) - parseFloat(a['AgendaNumber']);
      }
    )[0]['AgendaNumber']
    return result
  }

  onEmitBack(increaseBy) {
    try {
        let agendaDetails = this.agendaObject.filter(ele => ele.AgendaNumber == (this.AgendaNumber - increaseBy).toString())
        this.emitFileURL.emit({ agendaId: agendaDetails[0].AgendaConvertedDocxToPdf.DocumentManagementID, page: this.AgendaNumber - increaseBy })
      }
    catch (err) {
      console.log("errerrerrerrerr", err)
      this.onEmitBack(increaseBy + 1);
    }
  }

  async showBack() {
    this.showAgenda = true;
    if(this.currentAgendaOnDisplay>0){ //should now be reduced if already on first agenda
      this.currentAgendaOnDisplay --;
    }
    this.hideAgenda = false;
    this.count = this.getMaxIndex(this.agendaObject)
    this.onEmitBack(1);
  }
}