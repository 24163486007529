import { Component, OnInit, OnChanges, Input, ViewChild, ViewChildren, AfterViewInit, Output, EventEmitter, QueryList } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DataTableDirective } from 'angular-datatables';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Rx';
import * as _ from 'lodash';
import { DashboardMyTaskService } from './../../../services/dashboardmytask.service';
import { DashboardService } from './../../../services/dashboard.service';
import { UserMasterService } from './../../../services/user-master.service';
import { MeetingMasterService } from './../../../services/meetingMaster.service'
import { NotificationComponent } from '../notification/notification.component';
import { ActivatedTaskFromComplianceService } from './../../../services/activatedTaskFromCompliance.service';
import { NgbDateStruct, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { taskStatus } from '../../../enums/taskStatus'

@Component({
  selector: 'app-bulk-update-task',
  templateUrl: './bulk-update-task.component.html',
  styleUrls: ['./bulk-update-task.component.css']
})
export class BulkUpdateTaskComponent implements OnInit {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtElement: DataTableDirective;
  dtGovernanceOptions: DataTables.Settings = {};
  dtGovernanceTrigger: Subject<any> = new Subject();

  dtComplianceOptions: DataTables.Settings = {};
  dtComplianceTrigger: Subject<any> = new Subject();

  // dtOptions: DataTables.Settings = {};
  // The trigger needed to re-render the table
  // dtTrigger: Subject<any> = new Subject();
  filteredData: any;
  displayLabel: string;

  isGovernanceTabActive: boolean = false;
  isComplianceTabActive: boolean = false;

  //my task activites -- 3 Jan
  //display task details
  complianceTaskDetails: any = [];
  complianceTaskDetailsCache: any = [];
  governanceTaskDetails: any = [];
  governanceTaskDetailsCache: any = [];
  myTaskDetails: any = [];
  myTaskDetailsCache: any = [];
  myTaskList: any = [];
  taskObj: any = {
    taskListIds: [],
    taskStatus: 'All'
  }
  complianceTaskList: any = [];
  complianceTaskObj: any = {
    taskListIds: [],
    taskStatus: 'All'
  }
  complianceTaskStatusList: any = [];
  activeComplianceTab: boolean = false;
  isComplianceTabClick: boolean = false;
  governanceTaskSelected = [];
  compliancedTaskSelected = [];

  associatedCompanyList: any;
  associatedCompanyByIdList: any;
  selectedCompanyById: any;
  taskStatusList: any;
  selectedtaskStatus: any;
  selectedProcessById: any;
  govevaProcessList: any;
  processByIdList: any = [];
  selectedFromDate: any;
  selectedToDate: any;
  minDateForToDate: any;
  selectedTaskStatusToChange: any;


  associatedCompanyListComp: any;
  associatedCompanyByIdListComp: any;
  selectedCompanyByIdComp: any;
  selectedtaskStatusComp: any;
  selectedProcessByIdComp: any;
  govevaProcessListComp: any;
  processByIdListComp: any;
  selectedFromDateComp: any;
  selectedToDateComp: any;
  minDateForToDateComp: any;
  selectedTaskStatusToChangeComp: any;
  meetingTypeList: any;
  selectedMeetingTypeById: any;
  public completionDate: any;
  public showCompletionCalendar: boolean = false;
  isMyTaskFetch: any;

  constructor(private dashboardService: DashboardService, private dashboardMyTaskService: DashboardMyTaskService, private modalService: NgbModal, private activatedTaskFromComplianceService: ActivatedTaskFromComplianceService, private userService: UserMasterService, public meetingService: MeetingMasterService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.isMyTaskFetch = localStorage.getItem("isMyTaskFetch");
    this.dtGovernanceOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // pageLength: 6,
      order: [1, 'asc'],
      columnDefs: [{ orderable: false, targets: [0] }],
      scrollCollapse: true,
      searching: false,
      lengthChange: true,
      lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"]],
      language: { //made changes for datatables for next previous button
        "paginate": {
          "first": "<<",
          "last": ">>",
          "next": ">",
          "previous": "<"
        }
      }
    };

    this.dtComplianceOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // pageLength: 6,
      order: [1, 'asc'],
      columnDefs: [{ orderable: false, targets: [0] }],
      scrollCollapse: true,
      searching: false,
      lengthChange: true,
      lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"]],
      language: { //made changes for datatables for next previous button
        "paginate": {
          "first": "<<",
          "last": ">>",
          "next": ">",
          "previous": "<"
        }
      }
    };

    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let companyId = loginUsers.CompanyId;

    //GetCompany
    if (this.isMyTaskFetch != "false") {
      let uniqueUserId = loginUsers.UniqueUserId;
      this.userService.getAssociatedCompanyList(uniqueUserId).subscribe(response => {
        //  
        if (response.status == 0) {
          if (_.size(response.companyList) <= 0) {
            this.associatedCompanyList = null;
            this.associatedCompanyListComp = null;
          } else {
            this.associatedCompanyList = this.associatedCompanyListComp = response.companyList.map(function (o) {
              return {
                'label': o.CompanyName,
                'value': o._id
              }
            });
            this.associatedCompanyByIdList = this.associatedCompanyList;
            this.associatedCompanyByIdListComp = this.associatedCompanyListComp;
          }
        } else {
          this.associatedCompanyList = null;
          this.associatedCompanyListComp = null;
        }
      });

      this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails({ 'dataType': "count" }).then(response => {
        if (!response.status) {
          this.govevaProcessList = response.TaskList.map(function (o) {
            return {
              'label': o.ProcessName,
              'value': o.ProcessId
            }
          });
          this.processByIdList = this.govevaProcessList;
        }
      }).catch(reason => console.log(reason));
      this.taskStatusList = taskStatus;

      //Compliance Definition------------------------------------------------------------------------
      this.dashboardService.getActivatedProcessList(companyId).then(response => {
        //  
        if (!response.status) {
          this.processByIdListComp = response.processList.map(function (o) {
            return {
              'label': o.ProcessName,
              'value': o.ProcessID
            }
          })
        }
        // console.log("response compliance process list", response);
      }).catch(reason => console.log(reason));

      this.meetingService.getMeetingTypeList(companyId).then(response => {
        //  
        if (!response.status) {
          this.meetingTypeList = response.data.map(function (o) {
            return {
              'label': o.MeetingType,
              'value': o._id
            }
          })
        }
        // console.log("response for compliance meeting type list", response);
      }).catch(reason => console.log(reason));
      //-----------------------------


      this.initialisationGovernanceTab();
      // this.showGovernanceTasks();
    }
  }

  showGovernanceTasks() {
    this.selectedCompanyByIdComp = "";
    this.selectedtaskStatusComp = "";
    this.selectedProcessByIdComp = "";
    this.selectedMeetingTypeById = "";
    this.selectedFromDateComp = "";
    this.selectedToDateComp = "";
    this.selectedTaskStatusToChange = "";
    if (!this.isGovernanceTabActive) {
      this.initialisationGovernanceTab();
      this.isGovernanceTabActive = true;
      this.isComplianceTabActive = false;
    }
  }

  initialisationGovernanceTab() {

    this.isGovernanceTabActive = true;
    this.isComplianceTabActive = false;

    $(".gov").val("All");
    $(".govFilter").val("All");
    this.activeComplianceTab = false;
    $("#govTab").addClass("active");
    $("#governanceTab").addClass("active");
    $("#compTab").removeClass("active");
    $("#complianceTab").removeClass("active");
    // this.displayLabel = "All";   
    // this.complianceTaskDetails = [];
    if (this.isComplianceTabClick && _.size(this.complianceTaskDetails)) {
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
    }
    this.governanceTaskDetails = [];
    this.governanceTaskDetailsCache = [];
    var loginUsers = JSON.parse(localStorage.getItem("user"));
    let assignedToId = loginUsers._id;
    let companyId = loginUsers.CompanyId;
    let uniqueUesrId = loginUsers.UniqueUserId;

    // $('#myTasksTable').DataTable({ destroy: true }).destroy();
    //  
    if (assignedToId != "") {
      this.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUesrId).then((responseOfListOfId) => {
        if (!responseOfListOfId.status) {
          var promiseArray = [];
          var compliancePromiseArray = [];
          var self = this;
          for (var index = 0; index < responseOfListOfId.listOfId.length; index++) {
            promiseArray.push(self.dashboardMyTaskService.getMyTaskList(_.get(responseOfListOfId.listOfId[index], '_id')))
            compliancePromiseArray.push(self.activatedTaskFromComplianceService.getMyActivatedTaskList(_.get(responseOfListOfId.listOfId[index], 'CompanyId'), _.get(responseOfListOfId.listOfId[index], '_id')))
          }
          Observable.forkJoin(promiseArray).subscribe(multipleCompanyTaskList => {
            let taskList = _.filter(multipleCompanyTaskList, function (element) { return !_.isNil(_.get(element, 'taskList')) })
            let mytaskList = { taskList: [] }
            let tempList = [];
            mytaskList.taskList = _.map(taskList, function (element) { return _.concat(tempList, _.get(element, 'taskList')) })
            mytaskList.taskList = _.flatten(mytaskList.taskList)
            // mytaskList.taskList = _.reduce(taskList, function (currentElement, nextElement) { return _.concat(_.get(currentElement, 'taskList'), _.get(nextElement, 'taskList')) })
            mytaskList.taskList.forEach(task => {
              if (task.Status === "Completed" || task.Status === "Done" || task.Status === "DelayedCompletion") {
                task.LocalStatus = task.Status;
                task.ColorDisplayStatus = task.Status;
              } else {
                //  
                var tempEndDate: Date = new Date(task.EndDate);
                var date = tempEndDate.getDate();
                var month = tempEndDate.getMonth();
                var year = tempEndDate.getFullYear();
                var endDate = new Date(year, month, date);

                var tempTodayDate: Date = new Date();
                var dateT = tempTodayDate.getDate();
                var monthT = tempTodayDate.getMonth();
                var yearT = tempTodayDate.getFullYear();
                var todayDate = new Date(yearT, monthT, dateT);


                if (endDate < todayDate) {
                  task.LocalStatus = task.Status;
                  task.ColorDisplayStatus = "Expired";
                } else {
                  task.LocalStatus = task.Status;
                  task.ColorDisplayStatus = task.Status;
                }
              }
            });
            for (var taskIndex = 0; taskIndex < mytaskList.taskList.length; taskIndex++) {
              //  
              mytaskList.taskList[taskIndex].isSelected = false;
              var taskCompanyId = mytaskList.taskList[taskIndex].CompanyId;
              var filteredCompanyData: any = _.filter(this.associatedCompanyList, function (company) { return (company.value == taskCompanyId); })

              mytaskList.taskList[taskIndex].CompanyName = filteredCompanyData[0].label;
            }
            //console.log('mytaskList.taskList', mytaskList.taskList)
            this.governanceTaskDetails = mytaskList.taskList;
            this.governanceTaskDetailsCache = mytaskList.taskList;
            console.log("this.governanceTaskDetails", this.governanceTaskDetails)
            console.log("this.processByIdList", this.processByIdList)
            var self = this;
            var indexs;
            // _.forEach(self.processByIdList, function (data) {
            //   _.forEach(data.task, function (taskid) {
            //     console.log("taskid", taskid._id)
            //     _.forEach(self.governanceTaskDetails, function (taskid) {
            //       console.log("taskid.TaskID" ,taskid.TaskID)
            //     })
            //     indexs = _.findIndex(self.governanceTaskDetails, function(o){ return _.get(o, 'TaskID') === taskid._id; });
            //   })
            //   console.log("indexs", indexs)
            // })
            var result;

            // this.processByIdList.forEach(element =>{
            //   console.log("element.task",element.task)
            //   result = element.task.filter(n => this.governanceTaskDetails.some(n2 => n2.TaskID == n._id))
            // })
            // this.governanceTaskDetails.forEach(element =>{
            //   console.log("element",element)
            //   result = element.filter(n => this.processByIdList.task.some(n2 => n2._id == n.TaskID))
            // })
            var arr1 = [];
            var arr2 = [];

            for (var i = 0; i < this.governanceTaskDetails.length; i++) {
              arr1.push(this.governanceTaskDetails[i])

            }
            for (var j = 0; j < this.processByIdList.length; j++) {
              arr2.push(this.processByIdList[j])

              var result = arr2[j].task.filter(n => arr1.some(n2 => n2.TaskID === n._id))
              console.log("result", result)
            }
            console.log("arr1", arr1)
            console.log("arr2", arr2)
            console.log("result", result)

            if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
              this.dtGovernanceTrigger.next();
              this.dtComplianceTrigger.next();
            } else {
              this.dtGovernanceTrigger.next();
              this.dtComplianceTrigger.next();
            }
          })
        }
      })
    } else {
      // if no api calls go list will be blank 
      this.governanceTaskDetails = [];
      this.dtGovernanceTrigger.next();
      this.dtComplianceTrigger.next();
    }
  }

  //from date change event function
  selectedFromDateChange() {
    console.log("selectedFromDate", this.selectedFromDate);
    if (this.selectedFromDate) {
      // this.isFromDateSelected = true ;
      this.minDateForToDate = this.selectedFromDate;
      this.selectedToDate = this.selectedFromDate;
      console.log("value has been selected")
    } else {
      console.log("value is empty");

    }

  }

  selectedFromDateChangeForComp() {
    if (this.selectedFromDateComp) {
      // this.isFromDateSelected = true ;
      this.minDateForToDateComp = this.selectedFromDateComp;
      this.selectedToDateComp = this.selectedFromDateComp;
      console.log("value has been selected")
    } else {
      console.log("value is empty");

    }

  }

  selectedToDateChange() {
    console.log("selectedToDate", this.selectedToDate);
    // this.selectedToDate = this.setDate(this.selectedToDate);
    console.log("after converting", this.selectedToDate);
  }

  // reset search filter values
  clearGovFilterValues() {
    // this.selectedInputProcessName = "" ;
    this.selectedCompanyById = "";
    this.selectedtaskStatus = "";
    this.selectedProcessById = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
    this.selectedTaskStatusToChange = "";
    this.searchGovFilteredMyTask();
  }

  // reset search filter values
  clearComplianceFilterValues() {
    // this.selectedInputProcessName = "" ;
    this.selectedCompanyByIdComp = "";
    this.selectedtaskStatusComp = "";
    this.selectedProcessByIdComp = "";
    this.selectedMeetingTypeById = "";
    this.selectedFromDateComp = "";
    this.selectedToDateComp = "";
    this.selectedTaskStatusToChange = "";
    this.searchComplianceFilteredMyTask();
  }

  searchGovFilteredMyTask() {
    console.log("this.dtElementthis.dtElement", this.dtElements);

    var searchObject = {};
    var logedinUsersData = JSON.parse(localStorage.getItem("user"));

    var taskStatusNumber;
    switch (this.selectedtaskStatus) {
      case "Pending":
        taskStatusNumber = 1
        break;
      case "Completed":
        taskStatusNumber = 2
        break;
      case "Delayed Completion":
        taskStatusNumber = 3
        break;
      case "Partially Completed":
        taskStatusNumber = 4
        break;
      case "NA":
        taskStatusNumber = 5
        break;
      case "Done":
        taskStatusNumber = 6
        break;
      case "Cancelled":
        taskStatusNumber = 7
        break;
    }

    searchObject = {
      "AssignedToId": logedinUsersData._id,
      "uniqueUserId": logedinUsersData.UniqueUserId,
      "companyId": this.selectedCompanyById && this.selectedCompanyById.value ? this.selectedCompanyById.value : null,
      "taskStatus": this.selectedtaskStatus && this.selectedtaskStatus.value ? this.selectedtaskStatus.value : null,
      "processId": this.selectedProcessById && this.selectedProcessById.value ? this.selectedProcessById.value : null,
      "fromDate": this.setDate(this.selectedFromDate),
      "toDate": this.setDate(this.selectedToDate)
    };

    this.dashboardService.getMyTaskFilteredList(searchObject).then(response => {

      if (!response.status) {
        this.governanceTaskDetails = [];

        for (var taskIndex = 0; taskIndex < response.taskList.length; taskIndex++) {

          response.taskList[taskIndex].isSelected = false;
          var taskCompanyId = response.taskList[taskIndex].CompanyId;
          var filteredCompanyData: any = _.filter(this.associatedCompanyList, function (company) { return (company.value == taskCompanyId); })

          response.taskList[taskIndex].CompanyName = filteredCompanyData[0].label;
        }

        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        });

        this.governanceTaskDetails = response.taskList
        this.dtGovernanceTrigger.next();

        if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
          this.dtGovernanceTrigger.next();
          this.dtComplianceTrigger.next();
        }
      } else {
        this.governanceTaskDetails = [];

        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        });

        this.dtGovernanceTrigger.next();

        if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
          this.dtGovernanceTrigger.next();
          this.dtComplianceTrigger.next();
        }
      }
    });
  }

  setDate(date) {
    if (date != "" && date != null) {
      //return date.day + "-" + date.month + "-" + date.year;
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }

  showComplianceTasks() {
    this.selectedCompanyById = "";
    this.selectedtaskStatus = "";
    this.selectedProcessById = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
    this.selectedTaskStatusToChange = "";
    if (!this.isComplianceTabActive) {

      $(".comp").val("All");
      $(".compFilter").val("All");
      this.activeComplianceTab = true;
      $("#govTab").removeClass("active");
      $("#governanceTab").removeClass("active");
      $("#compTab").addClass("active");
      $("#complianceTab").addClass("active");
      this.isComplianceTabClick = true;

      this.displayLabel = "All";


      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });

      // $('#complianceTasksTable').DataTable({ }).destroy();

      this.complianceTaskDetails = [];
      this.complianceTaskDetailsCache = [];
      var loginUsers = JSON.parse(localStorage.getItem("user"));
      let assignedToId = loginUsers._id;
      let companyId = loginUsers.CompanyId;
      let uniqueUesrId = loginUsers.UniqueUserId;


      if (assignedToId != "" && this.isMyTaskFetch != "false") {
        if (companyId != "") {
          this.dashboardMyTaskService.getUserIdbyUniqueUserId(uniqueUesrId).then((responseOfListOfId) => {

            if (!responseOfListOfId.status) {
              var promiseArray = [];
              var compliancePromiseArray = [];
              var self = this;
              // for (var index = 0; index < responseOfListOfId.listOfId.length; index++) {
              // promiseArray.push(self.dashboardMyTaskService.getMyTaskList(_.get(responseOfListOfId.listOfId[index], '_id')))
              // compliancePromiseArray.push(self.activatedTaskFromComplianceService.getMyActivatedTaskList(_.get(responseOfListOfId.listOfId[index], 'CompanyId'), _.get(responseOfListOfId.listOfId[index], '_id')))
              // }
              self.activatedTaskFromComplianceService.getMyActivatedTaskList(companyId, assignedToId).then(listOfcomplianceTaskList => {


                if (listOfcomplianceTaskList) {

                  let removedUndefinedValues = _.filter(listOfcomplianceTaskList, function (element) { return !_.isNil(element) })
                  if (_.size(removedUndefinedValues)) {
                    let complianceTaskList = _.flattenDeep(removedUndefinedValues)
                    complianceTaskList = complianceTaskList.filter(g => _.get(g, 'StartDate') != null && _.get(g, 'StartDate') != undefined && _.get(g, 'EndDate') != undefined && _.get(g, 'EndDate') != null)

                    for (let complianceIndex = 0; complianceIndex < complianceTaskList.length; complianceIndex++) {
                      let task = {
                        ActivatedTaskFromCompliance: true,
                        // TaskStatus: "",
                        LocalStatus: "",
                        EndDate: "",
                        ColorDisplayStatus: "",
                        isSelected: false
                      }

                      var taskCompanyId = complianceTaskList[complianceIndex].CompanyID;
                      var filteredCompanyData: any = _.filter(this.associatedCompanyList, function (company) { return (company.value == taskCompanyId); })
                      complianceTaskList[complianceIndex].CompanyName = filteredCompanyData[0].label;

                      complianceTaskList[complianceIndex] = _.assign(task, complianceTaskList[complianceIndex])
                      complianceTaskList[complianceIndex].ActivatedTaskFromCompliance = true;

                      switch (_.toInteger(complianceTaskList[complianceIndex].TaskStatus)) {
                        case 1://Pending
                          //////console.log('it is coming here')
                          complianceTaskList[complianceIndex].LocalStatus = "Pending";
                          break;
                        case 2://Completed
                          complianceTaskList[complianceIndex].LocalStatus = "Completed";
                          break;
                        case 3://DelayedCompletion
                          complianceTaskList[complianceIndex].LocalStatus = "DelayedCompletion";
                          break;
                        case 4://PartiallyCompleted
                          complianceTaskList[complianceIndex].LocalStatus = "PartiallyCompleted";
                          break;
                        case 5://NA
                          complianceTaskList[complianceIndex].LocalStatus = "NA";
                          break;
                        case 6://Done
                          complianceTaskList[complianceIndex].LocalStatus = "Done";
                          break;
                        case 7://Cancelled
                          complianceTaskList[complianceIndex].LocalStatus = "Cancelled";
                          break;
                        default:
                          break;
                      }
                      if (complianceTaskList[complianceIndex].LocalStatus != "Completed" && complianceTaskList[complianceIndex].LocalStatus != "Done" && complianceTaskList[complianceIndex].LocalStatus != "DelayedCompletion") {
                        //  
                        // var endDate: Date = new Date(Date.parse(task.EndDate));
                        // var todayDate: Date = new Date(Date.parse(Date()));

                        var tempEndDate: Date = new Date(complianceTaskList[complianceIndex].EndDate);
                        var date = tempEndDate.getDate();
                        var month = tempEndDate.getMonth();
                        var year = tempEndDate.getFullYear();
                        var endDate = new Date(year, month, date);

                        var tempTodayDate: Date = new Date();
                        var dateT = tempTodayDate.getDate();
                        var monthT = tempTodayDate.getMonth();
                        var yearT = tempTodayDate.getFullYear();
                        var todayDate = new Date(yearT, monthT, dateT);

                        if (endDate < todayDate) {
                          complianceTaskList[complianceIndex].ColorDisplayStatus = "Expired";
                        } else {
                          complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
                        }
                      } else {
                        complianceTaskList[complianceIndex].LocalStatus = complianceTaskList[complianceIndex].LocalStatus;
                        complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
                      }
                    }
                    //console.log('complianceTaskList =======>', complianceTaskList)
                    this.complianceTaskDetails = complianceTaskList;
                    this.complianceTaskDetailsCache = complianceTaskList;



                    if (this.complianceTaskDetails != null || _.size(this.complianceTaskDetails) > 0) {
                      // this.dtGovernanceTrigger.next();
                      console.log("his.complianceTaskDetails", this.complianceTaskDetails);
                      this.dtGovernanceTrigger.next();
                      this.dtComplianceTrigger.next();
                    } else {
                      console.log("else --- his.complianceTaskDetails", this.complianceTaskDetails);
                      this.dtGovernanceTrigger.next();
                      this.dtComplianceTrigger.next();
                    }
                  }

                } else {
                  this.complianceTaskDetails = [];
                  console.log("else total his.complianceTaskDetails", this.complianceTaskDetails);
                  this.dtGovernanceTrigger.next();
                  this.dtComplianceTrigger.next();
                }

              })
            }
          })
        }
        // this.dtComplianceTrigger.next();
      } else {
        this.complianceTaskDetails = [];
        console.log("else totally his.complianceTaskDetails", this.complianceTaskDetails);
        setTimeout(() => {
          this.dtComplianceTrigger.next();
        }, 1000);
      }

      this.isGovernanceTabActive = false;
      this.isComplianceTabActive = true;

    }

  }

  searchComplianceFilteredMyTask() {

    this.selectedCompanyById = "";
    this.selectedtaskStatus = "";
    this.selectedProcessById = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
    this.selectedTaskStatusToChange = "";

    $(".comp").val("All");
    $(".compFilter").val("All");
    this.activeComplianceTab = true;
    $("#govTab").removeClass("active");
    $("#governanceTab").removeClass("active");
    $("#compTab").addClass("active");
    $("#complianceTab").addClass("active");
    this.isComplianceTabClick = true;

    this.displayLabel = "All";

    var searchObject: any = {};
    var logedinUsersData = JSON.parse(localStorage.getItem("user"));

    var taskStatusNumber = null;
    switch (this.selectedtaskStatusComp) {
      case "Pending":
        taskStatusNumber = 1
        break;
      case "Completed":
        taskStatusNumber = 2
        break;
      case "DelayedCompletion":
        taskStatusNumber = 3
        break;
      case "PartiallyCompleted":
        taskStatusNumber = 4
        break;
      case "NA":
        taskStatusNumber = 5
        break;
      case "Done":
        taskStatusNumber = 6
        break;
      case "Cancelled":
        taskStatusNumber = 7
        break;
    }

    // searchObject = { 'companyId': this.selectedCompanyById, 'taskStatus': this.selectedtaskStatus, 'processId': this.selectedProcessById, 'fromDate': this.setDate(this.selectedFromDate), 'toDate': this.setDate(this.selectedToDate) };
    searchObject = {
      "AssignedUserID": logedinUsersData._id,
      "CompanyID": (this.selectedCompanyByIdComp == undefined || this.selectedCompanyByIdComp == "") ? null : this.selectedCompanyByIdComp,
      "MeetingTypeId": (this.selectedMeetingTypeById == undefined || this.selectedMeetingTypeById == "") ? null : this.selectedMeetingTypeById,
      "TaskStatusId": taskStatusNumber,
      "ProcessId": (this.selectedProcessByIdComp == undefined || this.selectedProcessByIdComp == "") ? null : this.selectedProcessByIdComp,
      "FromDate": this.setDate(this.selectedFromDateComp),
      "ToDate": this.setDate(this.selectedToDateComp),
      "govEvaAssignedUserIds": null
    };

    this.dashboardMyTaskService.getUserIdbyUniqueUserId(logedinUsersData.UniqueUserId).then(responseforUser => {
      if (!responseforUser.status) {

        var userList = [this.userService.getUserList()];
        var userIDList: any = responseforUser.listOfId;
        var userIDs: any = [];
        userIDList.forEach(userData => {
          var data: any = userData;
          userIDs.push(data._id);
        });

        searchObject.govEvaAssignedUserIds = userIDs;

        this.dashboardService.getMyFilteredActivatedTaskList(searchObject).then(responseFilteredTask => {

          if (!responseFilteredTask.status) {
            this.complianceTaskDetails = [];

            var removedUndefinedValues = responseFilteredTask.taskList;
            let complianceTaskList: any = _.flatten(removedUndefinedValues)

            _.forEach(complianceTaskList, function (element) {
              var companyObject = _.find(responseforUser.listOfId, function (user) { return user._id == _.get(element, 'AssignedTo') })
              _.assign(element, { CompanyName: companyObject.CompanyName })
              var createdBy: any = _.filter(userList, ["_id", _.get(element, 'CreatedBy')]);
              if (createdBy.length) {
                _.assign(element, { Owner: createdBy[0].FirstName + " " + createdBy[0].LastName })
              }
              else {
                _.assign(element, { Owner: " " })
              }

              if (_.get(element, 'CategoryName') == undefined) {
                // //console.log("there is no such field with value CategoryName");
                _.assign(element, { CategoryName: "" })
              }
              if (_.get(element, 'ProcessName') == undefined) {
                // //console.log("there is no such field with value CategoryName");
                _.assign(element, { ProcessName: "" })
              }

              var assignedByID = _.get(element, 'AssignedTo');
              var assignedTo = _.filter(userList, ["_id", _.get(element, 'AssignedTo')]);
              // _.assign(o, { assignedByName: assignedTo[0].FirstName + "" + assignedTo[0].LastName })
              // assignedTo.find(function (t) {
              //     _.get(element, 'AssignedTo') = t.FirstName + " " + t.LastName;
              //     return false;
              // })

              if (_.get(element, 'TriggerStartDate') > _.get(element, 'DueDate')) {

                _.assign(element, { LocalStatus: _.get(element, 'TaskStatus') }, { AssignedByID: assignedByID }, { ActivatedTaskFromCompliance: true }, { StartDate: _.get(element, 'DueDate') }, { EndDate: _.get(element, 'TriggerStartDate') });
              }

              else {

                _.assign(element, { LocalStatus: _.get(element, 'TaskStatus') }, { AssignedByID: assignedByID }, { ActivatedTaskFromCompliance: true }, { StartDate: _.get(element, 'TriggerStartDate') }, { EndDate: _.get(element, 'DueDate') });
              }
            });

            complianceTaskList = complianceTaskList.filter(g => _.get(g, 'StartDate') != null && _.get(g, 'StartDate') != undefined && _.get(g, 'EndDate') != undefined && _.get(g, 'EndDate') != null)

            for (let complianceIndex = 0; complianceIndex < complianceTaskList.length; complianceIndex++) {
              let task = {
                ActivatedTaskFromCompliance: true,
                // TaskStatus: "",
                LocalStatus: "",
                EndDate: "",
                ColorDisplayStatus: "",
                isSelected: false
              }

              var taskCompanyId = complianceTaskList[complianceIndex].CompanyID;
              var filteredCompanyData: any = _.filter(this.associatedCompanyList, function (company) { return (company.value == taskCompanyId); })
              complianceTaskList[complianceIndex].CompanyName = filteredCompanyData[0].label;

              complianceTaskList[complianceIndex] = _.assign(task, complianceTaskList[complianceIndex])
              complianceTaskList[complianceIndex].ActivatedTaskFromCompliance = true;

              switch (_.toInteger(complianceTaskList[complianceIndex].TaskStatus)) {
                case 1://Pending
                  //////console.log('it is coming here')
                  complianceTaskList[complianceIndex].LocalStatus = "Pending";
                  break;
                case 2://Completed
                  complianceTaskList[complianceIndex].LocalStatus = "Completed";
                  break;
                case 3://DelayedCompletion
                  complianceTaskList[complianceIndex].LocalStatus = "DelayedCompletion";
                  break;
                case 4://PartiallyCompleted
                  complianceTaskList[complianceIndex].LocalStatus = "PartiallyCompleted";
                  break;
                case 5://NA
                  complianceTaskList[complianceIndex].LocalStatus = "NA";
                  break;
                case 6://Done
                  complianceTaskList[complianceIndex].LocalStatus = "Done";
                  break;
                case 7://Cancelled
                  complianceTaskList[complianceIndex].LocalStatus = "Cancelled";
                  break;
                default:
                  break;
              }
              if (complianceTaskList[complianceIndex].LocalStatus != "Completed" && complianceTaskList[complianceIndex].LocalStatus != "Done" && complianceTaskList[complianceIndex].LocalStatus != "DelayedCompletion") {
                //  
                // var endDate: Date = new Date(Date.parse(task.EndDate));
                // var todayDate: Date = new Date(Date.parse(Date()));

                var tempEndDate: Date = new Date(complianceTaskList[complianceIndex].EndDate);
                var date = tempEndDate.getDate();
                var month = tempEndDate.getMonth();
                var year = tempEndDate.getFullYear();
                var endDate = new Date(year, month, date);

                var tempTodayDate: Date = new Date();
                var dateT = tempTodayDate.getDate();
                var monthT = tempTodayDate.getMonth();
                var yearT = tempTodayDate.getFullYear();
                var todayDate = new Date(yearT, monthT, dateT);

                if (endDate < todayDate) {
                  complianceTaskList[complianceIndex].ColorDisplayStatus = "Expired";
                } else {
                  complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
                }
              } else {
                complianceTaskList[complianceIndex].LocalStatus = complianceTaskList[complianceIndex].LocalStatus;
                complianceTaskList[complianceIndex].ColorDisplayStatus = complianceTaskList[complianceIndex].LocalStatus;
              }
            }
            console.log('this.dtElements ', this.dtElements)

            console.log("this.complianceTaskDetails", complianceTaskList)
            if (this.dtElements != undefined && this.dtElements != null) {
              this.dtElements.forEach((dtElement: DataTableDirective) => {
                console.log(' dtElement.dtInstance', dtElement.dtInstance)
                if (dtElement.dtInstance != undefined && dtElement.dtInstance != null) {
                  dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.destroy();
                  });
                }

              });


            }

            this.complianceTaskDetails = complianceTaskList;
            console.log("this.complianceTaskDetails", this.complianceTaskDetails)
            this.dtGovernanceTrigger.next();

            if (this.complianceTaskDetails != null || _.size(this.complianceTaskDetails) > 0) {
              this.dtGovernanceTrigger.next();
              this.dtComplianceTrigger.next();
            }
          } else {
            this.complianceTaskDetails = [];

            this.dtElements.forEach((dtElement: DataTableDirective) => {
              if (dtElement.dtInstance != undefined && dtElement.dtInstance != null) {
                dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                  dtInstance.destroy();
                });
              }
            });

            this.dtGovernanceTrigger.next();

            if (this.complianceTaskDetails != null || _.size(this.complianceTaskDetails) > 0) {
              this.dtGovernanceTrigger.next();
              this.dtComplianceTrigger.next();
            }
          }
        });

      }
    });


  }



  checkTask(event, task, index) {

    if (!this.governanceTaskDetails[index].isSelected) {
      this.governanceTaskDetails[index].isSelected = true;
    } else {
      this.governanceTaskDetails[index].isSelected = false;
    }
    this.governanceTaskSelected = _.filter(this.governanceTaskDetails, function (element) { return element.isSelected == true })

  }

  markTasks(event) {

    var taskStatus = event;
    this.taskObj.taskStatus = taskStatus && taskStatus.value ? taskStatus.value : null;
    // this.taskObj.taskListIds = this.myTaskList;
  }

  updateStatus() {
    let self = this;
    if (this.activeComplianceTab == false) {
      // Goverance Tab for updating status...
      let selectedTask = _.filter(self.governanceTaskDetails, function (element) { return element.isSelected })
      if (_.size(selectedTask)) {

        this.taskObj.taskListIds = _.map(selectedTask, function (element) { return _.get(element, '_id') })
        this.dashboardMyTaskService.bulkUpdateTaskStatus(this.taskObj).then(response => {
          if (!response.status) {
            this.activeModal.close(response);
          }
          else {
            this.activeModal.dismiss(response);
          }
        })
      } else {
        this.activeModal.close({ status: 1, message: 'Please select task' });
      }
    }
    else {
      //   // compliance
      let complianceSelectedtask = _.filter(self.complianceTaskDetails, function (element) { return element.isSelected == true })
      this.complianceTaskObj.taskListIds = _.map(complianceSelectedtask, function (element) { return element._id });
      if (_.size(this.completionDate)) {
        this.complianceTaskObj.completionDate = new Date(this.completionDate.year, this.completionDate.month, this.completionDate.day);//this.completionDate;
      }
      this.dashboardMyTaskService.bulkUpdateForComplianceTask(this.complianceTaskObj).then((response) => {
        this.activeModal.close(response)
      })
    }

  }

  filterGovernaceTasks(data) {
    // $('input:checkbox').attr('checked', false);
    // $("#checkAll").prop('checked', false);
    this.taskObj.taskStatus = "";
    this.taskObj.taskListIds = [];
    if (data.target.value == 'All') {
      this.governanceTaskDetails = [];

      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.governanceTaskDetails = this.governanceTaskDetailsCache;
      // this.dtGovernanceTrigger.next();
      if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    else {
      this.governanceTaskDetails = [];
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.governanceTaskDetails = _.filter(this.governanceTaskDetailsCache, function (o) { return o.LocalStatus === data.target.value });
      // this.dtGovernanceTrigger.next();
      if (this.governanceTaskDetails != null || _.size(this.governanceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    // $('#myTasksTable').DataTable({ destroy: true }).destroy();    
  }

  markComplianceTasks(event) {

    var taskStatus = event;
    this.complianceTaskObj.taskStatus = taskStatus;
    //   this.complianceTaskObj.taskListIds = this.complianceTaskList;

    if (this.selectedTaskStatusToChange == "Completed") {
      this.showCompletionCalendar = true;
    } else {
      this.showCompletionCalendar = false;
      this.completionDate = null;
    }
  }


  markAll(event) {
    if (event.target.checked) {
      this.governanceTaskDetails.forEach(element => {
        element.isSelected = true;
      });
    } else {
      this.governanceTaskDetails.forEach(element => {
        element.isSelected = false;
      });
    }
    this.governanceTaskSelected = _.filter(this.governanceTaskDetails, function (element) { return element.isSelected == true })
  }

  markAllCompliance($event) {
    if ($event.target.checked) {
      this.complianceTaskDetails.forEach(function (element) {
        element.isSelected = true;
      })
    } else {
      this.complianceTaskDetails.forEach(function (element) {
        element.isSelected = false;
      })
    }
    this.compliancedTaskSelected = _.filter(this.complianceTaskDetails, function (element) { return element.isSelected == true })
  }

  checkComplianceTask(event, task, index) {
    if (!this.complianceTaskDetails[index].isSelected) {
      this.complianceTaskDetails[index].isSelected = true;
    } else {
      this.complianceTaskDetails[index].isSelected = false;
    }
    this.compliancedTaskSelected = _.filter(this.complianceTaskDetails, function (element) { return element.isSelected == true })
  }

  filterComplianceTasks(data) {
    // $('input:checkbox').attr('checked', false);
    // $("#checkAll").prop('checked', false);
    this.taskObj.taskStatus = "";
    this.taskObj.taskListIds = [];
    if (data.target.value == 'All') {
      this.complianceTaskDetails = [];

      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.complianceTaskDetails = this.complianceTaskDetailsCache;
      // this.dtGovernanceTrigger.next();
      if (this.complianceTaskDetails != null || _.size(this.complianceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    else {
      this.complianceTaskDetails = [];
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      });
      this.complianceTaskDetails = _.filter(this.complianceTaskDetailsCache, function (o) { return o.LocalStatus === data.target.value });
      // this.dtGovernanceTrigger.next();
      if (this.complianceTaskDetails != null || _.size(this.complianceTaskDetails) > 0) {
        this.dtGovernanceTrigger.next();
        this.dtComplianceTrigger.next();
      }
    }
    // $('#myTasksTable').DataTable({ destroy: true }).destroy();    
  }

  today() {
    var now = new Date();
    return { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  }

}
