<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Role Master</strong>
    </span>
  </div>

  <div class="card-block watermark p-3">
    <div class="row">
      <div class="col-lg-12">
        <button
          type="button"
          class="btn btn-primary btn-sm mb-3"
          data-toggle="modal"
          data-target="#myModal"
          (click)="addRoleModal()"
          [appHasPermission]="{
            Url: '/api/v1/secure/roleMaster/addRole',
            method: 'post',
            menu: 'RoleMaster'
          }"
        >
          Add Role
        </button>

        <!-- Role Master Table  -->
        <div
          class="table-responsive margin-bottom-50"
          [appHasPermission]="{
            Url: '/api/v1/secure/roleMaster/getUserRoles',
            method: 'get',
            menu: 'RoleMaster'
          }"
        >
          <table
            datatable
            [dtOptions]="dtOptions"
            id="roleMasterTable"
            [dtTrigger]="dtTrigger"
            class="table table-hover table-bordered compact cell-border nowrap table-striped dataTable"
            style="background: transparent"
            width="100%"
          >
            <thead>
              <tr>
                <th style="width: 5%">Sr. No.</th>
                <th style="width: 75%">Name</th>
                <th
                  style="width: 5%"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/roleMaster/editRole',
                    method: 'put',
                    menu: 'RoleMaster'
                  }"
                >
                  Edit
                </th>
                <th
                  style="width: 5%"
                  [hidden]="isGuestSuperAdmin"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/roleMaster/deleteRole',
                    method: 'delete',
                    menu: 'RoleMaster'
                  }"
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let roleNames of listOfRoleNames; let count = index">
                <td style="text-align: center; width: 5%">{{ count + 1 }}</td>
                <td style="width: 75%">{{ roleNames.RoleName.trim() }}</td>
                <td
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                  (click)="editRoleModal(roleNames)"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/roleMaster/editRole',
                    method: 'put',
                    menu: 'RoleMaster'
                  }"
                >
                  <div class="fa fa-pencil"></div>
                </td>
                <td
                  style="
                    width: 5%;
                    text-align: center;
                    color: #74708d;
                    cursor: pointer;
                  "
                  [hidden]="isGuestSuperAdmin"
                  data-toggle="modal"
                  data-target="#roleDeletionMessageModal"
                  (click)="deleteConfirmationModal(roleNames)"
                  [appHasPermission]="{
                    Url: '/api/v1/secure/roleMaster/deleteRole',
                    method: 'delete',
                    menu: 'RoleMaster'
                  }"
                >
                  <div class="fa fa-trash-o"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- alert title modal -->
  <div
    class="modal fade"
    id="messagePopUp"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
    style="top: 50px"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div
          id="alertTitle"
          class="modal-header"
          style="font-size: 20px; font-weight: bold; padding: 5px 15px"
        ></div>
        <div class="modal-body" style="padding: 10px 15px">
          <div id="alertMessage" style="font-size: 16px"></div>
        </div>
      </div>
    </div>
  </div>
</section>
