<section class="mt-4 mb-5 analytics">
	<div class="container">
		<div class="row">
			<div class="col-md-12 title">
				<div class="mr-auto">
				<h4><img src="/assets/images/agenda-icon.png" alt="Analytics">Analytics:</h4>
			</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<ul class="nav nav-tabs" id="analyticsTab" role="tablist">
				  <li class="nav-item" role="presentation">
					<a class="nav-link active" id="AgendaAnalysis-tab" data-toggle="tab" href="#AgendaAnalysis" role="tab" aria-controls="AgendaAnalysis" aria-selected="true">Agenda Analysis</a>
				  </li>
				  <li class="nav-item" role="presentation">
					<a class="nav-link" id="AttendanceRecord-tab" data-toggle="tab" href="#AttendanceRecord" role="tab" aria-controls="AttendanceRecord" aria-selected="true">Attendance Records</a>
				  </li>
				</ul>
				<div class="tab-content" id="analyticsTabContent">
					<div class="tab-pane fade show active" id="AgendaAnalysis" role="tabpanel" aria-labelledby="AgendaAnalysis-tab">
						<div class="row px-3">
							<div class="col-md-6 col-sm-12">
								<h3>Agenda type</h3>
							</div>
							<div class="col-md-6 col-sm-12">
								<form class="agenda-search">
								  <input type="search" placeholder="Search" name="search">
								  <button type="submit" style="margin-top:0px !important;"><img alt="search" src="/assets/images/search-icon.png"></button>
								</form>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="accordion" id="AnalysisList">
									<div class="card">
										<div class="card-header" id="listhead1" style="height:50px;">
											
										</div>
									</div>
									<div class="card">
										<div class="card-header" id="listhead1" style="height:50px;">
											
										</div>
									</div>
									<div class="card">
										<div class="card-header" id="listhead1" style="height:50px;">
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="AttendanceRecord" role="tabpanel" aria-labelledby="AttendanceRecord-tab">
						<div class="container">
							<div class="row">
							</div>					
							<div class="row">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

