import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { CommonFunction } from '../../app/helpers/commonFunction';

@Injectable()
export class UINotificationService {

    constructor(
        private http: HttpClient,
        private commonFunction: CommonFunction) { }

    getUINotificationForUser(userID): Promise<any> {
        var token = localStorage.getItem('token')
        const url = environment.clientUrl + 'uiNotifications/userId/'+userID;
        return this.http.get(url, { headers: { 'x-access-token': token }}).toPromise()
            .then((response: any) => {
                return Promise.resolve({ ...response, ...JSON.parse(this.commonFunction.decrypt(response.value)) });
            })
            .catch(this.handleError)
    }

    getUINotificationByID(notificationID): Promise<any> {
        const url = environment.clientUrl + 'uiNotifications/getNotificationById/' + notificationID;
        return this.http.get(url).toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(this.handleError)
    }

    markNotificationStatusToReadByID(notificationID): Promise<any> {
        return this.http.post(environment.clientUrl + 'uiNotifications/updateReadNotification/' + notificationID, (notificationID))
            .toPromise()
            .then(response => { return Promise.resolve(response); })
            .catch(this.handleError)
    }

    deleteNotificationById(notificationId): Promise<any> {
        //var headers = new Headers();
        //head('Content-Type', 'application/json');
        return this.http.get(environment.clientUrl + 'uiNotifications/deleteNotification/' + notificationId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError)
    }

    deleteAllNotification(UserId): Promise<any> {

        return this.http.get(environment.clientUrl + 'uiNotifications/deleteAllNotification/' + UserId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            }).catch(this.handleError)

    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
