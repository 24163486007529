import { Component, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CompanyMasterService } from '../../services/companymaster.service';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Rx';
import * as _ from 'lodash';
import { CompanySetupService } from '../../services/companySetup.service';
import { RocketChatService } from "./../../services/rocketChat.service";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, switchMap, tap, catchError, elementAt } from 'rxjs/operators';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var swal: any;

@Component({
  selector: 'app-companymaster',
  templateUrl: './companymaster.component.html',
  styleUrls: ['./companymaster.component.css']
})
export class CompanymasterComponent implements OnInit {
  companyMasterForm: FormGroup;
  companies: any;
  typesOfCompany: any[];
  categoriesOfCompany: any[];
  registrationIDTypes: any[];
  industryTypes: any[];
  companyId: string;
  toDisableTradingWindowlabel: boolean;
  subsidiaryName: string;
  errorUniqueCIN: boolean;
  errorUniqueCINForCompany: boolean;

  tempCompanyInputSelected: any;
  tempCompanySelectedCIN: any;

  editCompanyData: any = true;
  editBtn: any = false;
  cancelBtn: any = true;
  IsUpdate: any = false;
  isGEMSubscribedFlag: boolean = false;
  financialYearFromModel: any;
  financialYearToModel: any;
  dateOfIncorporationModel: any;
  error: any = { isError: false, errorMessage: '' };
  numList: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  companyAutoCompleteList: any;
  subsidiaryAutoCompleteList: any;
  companyNameModel: any;
  subsidiaryNameModel: any;
  peersList: any;
  selectedPeers: any;
  industryCode: any;
  housingCode: any;
  invalidPeers: any;
  chosenPeers: any;
  tradingWindowValue: any;
  companyIdForTrading: string;


  equityListing: any;
  equityListingSMESegment: any;
  onlyDebtListing: any;
  debtListing: any;

  acceptsDeposits: boolean;
  debtListingLabelHide: any;
  equityListingFlag: boolean;
  equityListingSMESegmentFlag: boolean;
  onlyDebtListingFlag: boolean;
  debtListingFlag: boolean;
  showSelected: boolean;
  hideListedCompany: boolean;
  errorValidCINForCompany: boolean = false;
  minDate: any;
  maxDate: any;
  subsidiaryFincode: any;


  pattern = "^[a-zA-Z0-9][a-zA-Z0-9\-\_\.\,\)\('’\"\n ]*$";//Pattern for validating blank input and space at first place

  subsidiaryArray: Array<any> = [];
  newSubsidiary: any = {};

  //image cropper
  isInvalidImage: any = true;

  croppedWidth: number;
  croppedHeight: number;
  cropperSettings: CropperSettings;

  base64Code: any;
  isChekedListedCompany: boolean = true;

  isEditSubsideryMode: boolean = false;
  companyLabel: any;
  private formData: any = new FormData();
  otherSubsideryName: string;
  isValidSubsdieryName: boolean = true;
  private subsidaryList: any;
  private defaultImage: any;
  imageCropperBase64: any;
  companyLogoObject: any = {
    type: "companyLogo",
    id: ""
  }

  isADSubscribedFlag : boolean = false;
  adServerArray : any = [];

  @ViewChild('cropper') cropper: ImageCropperComponent;
  subsidiaryCompanyDetailsList: any;
  searchFailed: boolean = false;
  subsidiaryCompanyNameArray: any[];
  selectedCompanyByTypeAhead: any;
  constructor(private router: Router, private companyMasterService: CompanyMasterService, private companySetupService: CompanySetupService, private rocketChatService: RocketChatService) {
    //cropper settings
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 350;
    this.cropperSettings.height = 150;

    this.cropperSettings.croppedWidth = 350;
    this.cropperSettings.croppedHeight = 150;

    this.cropperSettings.canvasWidth = 465;
    this.cropperSettings.canvasHeight = 300;

    this.cropperSettings.minWidth = 10;
    this.cropperSettings.minHeight = 10;

    this.cropperSettings.rounded = false;
    this.cropperSettings.keepAspect = false;

    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.cropperSettings.noFileInput = true;
    this.base64Code = {};
    this.imageCropperBase64 = {};
    this.incorporationDateValidation();
    this.peersList = [];
  }

  ngOnInit() {
    var x = document.getElementById("leftMenu").classList;
    var leftSpace = document.querySelector('body').classList;
    x.add("visible");
    leftSpace.add("cat__menu-left--visible");

    // this.formData = new FormData();
    $("#newSubsidiaryName").hide();
    $("#exSubsidiaryName").hide();
    var self = this;
    this.toDisableTradingWindowlabel = false;
    this.getSubsidiaryAutoComplete();
    let resourcesValue = JSON.parse(localStorage.getItem('resources'));
    // this.companyIdForTrading = JSON.parse(localStorage.getItem('user')).CompanyId;
    // This is for the RFactor Assessment
    _.forEach(resourcesValue, function (data) {
      if (data.Menu == "CompanyMaster") {
        let child = data.ChildResources.filter(function (childData) {
          if (childData.Url == "companyMaster/edit") {
            // self.toDisableTradingWindowlabel = true;
            return childData;
          }
        })

        // console.log("child", child);
        if (_.size(child)) {
          self.toDisableTradingWindowlabel = true;
        } else {
          self.toDisableTradingWindowlabel = false;
        }
      }
    })

    // console.log("this.todisable,", this.toDisableTradingWindowlabel);
    $("body").removeClass("dashboard-page");
    this.showSelected = false;
    this.hideListedCompany = true;
    this.debtListingLabelHide = true;
    this.acceptsDeposits = false;
    this.isGEMSubscribedFlag = false;

    this.getTypesOfCompany();
    this.getCategoryOfCompany();
    this.getRegistrationIDTypes();
    //this.getIndustryTypes(); // industry tyep filter on the basis of industry code
    this.getCompaniesList();

    // this.getcompanyAutoComplete();

    this.companyMasterForm = new FormGroup({
      companyName: new FormControl('', [Validators.required, Validators.maxLength(100)
      ]),
      companyFINcode: new FormControl(''),
      complianceEngineURL: new FormControl('', [Validators.required]),
      chatEngineURL: new FormControl('', []),
      complianceEngine: new FormControl(''),

      // CIN: new FormControl('', [
      //   Validators.required, Validators.minLength(21), Validators.maxLength(21), Validators.pattern(this.pattern)
      // ]),

      dateOfIncorporation: new FormControl('', []),
      ISIN: new FormControl('', [Validators.minLength(12)]),

      //changes for pss tp goveva ---
      registrationIDType: new FormControl('', [Validators.required
      ]),
      registrationNumber: new FormControl('', [Validators.required, Validators.minLength(21), Validators.maxLength(21), Validators.pattern(this.pattern)
      ]),
      // ---------------

      exchangeCode: new FormControl('', []),

      listedCompany: new FormControl('', []),
      equityListing: new FormControl('', []),
      equityListingSMESegment: new FormControl('', []),
      onlyDebtListing: new FormControl('', []),
      debtListing: new FormControl('', []),

      // classOfCompany: new FormControl('', []),
      // typeOfCompany: new FormControl('', []),

      typeOfCompany: new FormControl('', []),
      categoryOfCompany: new FormControl('', []),

      registeredOfficeAddress: new FormControl('', [
        Validators.minLength(10), Validators.maxLength(500)
        // , Validators.pattern(this.pattern)
      ]),
      FinancialYearFrom: new FormControl('', []),
      FinancialYearTo: new FormControl('', []),
      nbfcRegulations: new FormControl('', []),
      acceptsDeposits: new FormControl('', []),

      amountoFDeposits: new FormControl('', []),

      noOfShareholders: new FormControl('', []),
      noOfDebentureHolders: new FormControl('', []),
      noOfDepositHolders: new FormControl('', []),
      noOfSecurityHolders: new FormControl('', []),
      typeOfIndustry: new FormControl('', []),
      peersName: new FormControl('', []),
      CompanyLogoImage: new FormControl(''),

      //-------------subsidiary to add
      newSubsidiaryCompanyName: new FormControl('', [Validators.maxLength(100)]),
      newSubsidiaryCIN: new FormControl('', [Validators.minLength(21), Validators.maxLength(21), , Validators.pattern("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$")]),
      newSubsidiaryType: new FormControl('', []),
      newSubsidiaryShareholding: new FormControl('', [Validators.max(100), Validators.min(0), Validators.maxLength(2)]),
      //--EVA added
      // EVA: new FormControl('', [Validators.pattern(/^\d{1,5}(\.\d{1,2})?$/)]),
      EVA: new FormControl('', [Validators.pattern(/^\d{1,5}(\.\d{2,2})?$/)]),
      // /^[0-9]+(\.[0-9]{1,2})?$/
      // Validators.pattern(/^\d+\.\d{2}$/)
      //-----subsidery to edit
      exSubsidiaryName: new FormControl('', []),
      exSubsidiaryCIN: new FormControl('', []),
      exSubsidiaryType: new FormControl('', []),
      exSubsidiaryShareholding: new FormControl('', []),
      Fincode: new FormControl('', []),
      otherSubsidiaryCompanyName: new FormControl(''),
      isGEMSubscribedFlag: new FormControl(Boolean, []),
      isADSubscribedFlag: new FormControl(Boolean, []),
      GEMAccessURL: new FormControl('', [])
    });

    this.adServerArray.push({
      ADUrl : '',
      ADEmailID : '',
      ADPassword : ''
    })

    this.companyMasterForm.disable();

    this.editCompanyData = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.errorUniqueCIN = false;
    this.errorUniqueCINForCompany = false;
  }

  incorporationDateValidation() {
    this.minDate = { year: 1900, month: 1, day: 1 };
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    this.maxDate = { year: year, month: month, day: day };
  }

  getTypesOfCompany() {
    this.companyMasterService.getTypesOfCompany().then(types => {
      if (types.status == 0) {
        this.typesOfCompany = types.companyClassList.sort();
        // console.log("types of company", this.typesOfCompany);
      }
    })
  }

  // getTypesOfCompany() {
  //   this.companyMasterService.getTypesOfCompany().then(types => {
  //     if (types.status == 0) {
  //       this.typesOfCompany = types.typesOfCompanyList;
  //     }
  //   })
  // }
  getCategoryOfCompany() {
    this.companyMasterService.getCategoryOfCompany().then(categories => {
      if (categories.status == 0) {
        this.categoriesOfCompany = categories.typesOfCompanyList.sort();
      }
    })
  }

  getRegistrationIDTypes() {
    this.companyMasterService.getRegistrationIDTypes()
      .then(regTypes => {
        // console.log("this.regTypes",regTypes)
        if (regTypes.status == 0) {
          this.registrationIDTypes = regTypes.registrationIDList.sort();
          // console.log("this.registrationIDTypes",this.registrationIDTypes)
        }
      })
  }

  getIndustryTypes(industryCode) {
    
    this.companyMasterService.getIndustryTypes(industryCode)
      .then(indusTypes => {
        
        if (indusTypes.status == 0) {
          if ('INDUSTRY' in indusTypes == false) {
            indusTypes.industryList.INDUSTRY = indusTypes.industryList[0];
            indusTypes.industryList.IND_CODE = industryCode;
            this.getAllIndustryTypeList();
          }
          this.industryTypes = indusTypes.industryList.sort();
          // console.log('indusTypes.industryList.INDUSTRY', indusTypes.industryList.INDUSTRY)
          this.companyMasterForm.controls.typeOfIndustry.setValue(indusTypes.industryList.IND_CODE);
        }
      })
  }

  selectIndustry(event) {
    
    this.selectedPeers = [];
    this.industryCode = event.target.value;
    if (this.industryCode != undefined)
      this.getIndustryTypes(this.industryCode);

    //get industry And Housing Wise Peers from industry and housing code
    this.companyMasterService.getindustryAndHousingWisePeers(this.industryCode, this.housingCode)
      .then(peers => {
        if (peers.status == 0 && peers.IndustryAndHousingPeers.length > 0) {

           
          let uniquePeers = [];
          uniquePeers = _.uniqBy(peers.IndustryAndHousingPeers, "Fincode");

          //this.peersList = peers.IndustryAndHousingPeers.map(function (o) {
          this.peersList = uniquePeers.map(function (o) {
            return {
              'label': o.COMPNAME,
              'value': o.Fincode  //o.FINCODE   
            }
          });

          //alphabetically order
          this.peersList = this.peersList.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          });
        }

        //  
        // if (this.peersList.length > 0) {
        //   let indexOfItem = this.peersList.findIndex(o => o.value === itemValue);
        //   if (indexOfItem > -1)
        //     //reomve the peers name (if peers is then same company name which is is selected ) from the collection 
        //     this.peersList.splice(indexOfItem, 1);
        // }
      });
  }

  getAllIndustryTypeList() {
    this.companyMasterService.getIndustryTypesList()
      .then(indusTypeList => {
        
        if (indusTypeList.status == 1) {
          //this.industryTypes = indusTypeList.listOfIndustryType;
          // this.companyMasterForm.controls.typeOfIndustry.setValue(indusTypes.industryList[0]);

          // Declare a new array
        let newArray = [];
              
        // Declare an empty object
        let uniqueObject = {};
          
        // Loop for the array elements
        for (let i in indusTypeList.listOfIndustryType) {  
            // Extract the title
            var objTitle = indusTypeList.listOfIndustryType[i]['IND_CODE'];  
            // Use the title as the index
            uniqueObject[objTitle] = indusTypeList.listOfIndustryType[i];
        }
          
        
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }
        
        this.industryTypes = newArray;
                  
        }
      })
  }

  // subsidiary dropdown list
  getSubsidiaryAutoComplete() {

    this.companySetupService.getcompanyAutoComplete().then(subAutoComplete => {

      if (subAutoComplete.status == 0) {

        let uniqueSubsidiary = [];
        uniqueSubsidiary = _.uniqBy(subAutoComplete.companyList, "FinCode");


        //alphabetically sort
        uniqueSubsidiary = uniqueSubsidiary.sort(function (a, b) {
          return a.CompanyName.localeCompare(b.CompanyName);
        });

        // to remove Selected company from subsidiary dropdown
        // if (this.companyLabel != undefined) {
        //   
        //   let subsidiaryLabel = this.companyLabel;
        //   let indexOfCompany = _.findIndex(uniqueSubsidiary, function (company) { return _.get(company, 'CompanyName') == subsidiaryLabel })
        //   uniqueSubsidiary.splice(indexOfCompany, 1);
        // }

        this.subsidiaryAutoCompleteList = uniqueSubsidiary.map(function (o) {

          return {
            // '_id': o.FinCode,
            'label': o.CompanyName,
            'value': o.FinCode.toString()
          }
        })

        this.subsidiaryAutoCompleteList = [{ 'label': 'Other', 'value': 'other' }, ...this.subsidiaryAutoCompleteList]
      }
    })

  }

  getCompaniesList() {
    
    this.companyMasterService.getCompanyList().then(company => {

      // console.log("company details", company);
      if (company.status == 0) {
        this.selectedCompanyByTypeAhead = company.companyData
        this.companyId = company.companyData._id;
        this.companyMasterForm.controls.companyName.setValue(company.companyData.CompanyName);
        this.companyMasterForm.controls.companyName.setValue(company.companyData.CompanyName);
        this.companyMasterForm.controls.companyFINcode.setValue(company.companyData.FinCode);
        let dt;
        if (company.companyData.DateOfIncorporation != null) {
          dt = new Date(this.setFormatDate(company.companyData.DateOfIncorporation));
          this.dateOfIncorporationModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          this.companyMasterForm.controls.dateOfIncorporation.setValue(this.dateOfIncorporationModel);
        }
        // this.companyMasterForm.controls.classOfCompany.setValue(company.companyData.ClassOfCompany);
        // this.companyMasterForm.controls.typeOfCompany.setValue(company.companyData.TypeOfCompany);
        this.companyMasterForm.controls.typeOfCompany.setValue(company.companyData.TypeOfCompany);
        if (company.companyData.TypeOfCompany == "" || company.companyData.TypeOfCompany == null || company.companyData.TypeOfCompany == "One Person Company") {
          this.hideListedCompany = false;
        }


        this.companyMasterForm.controls.categoryOfCompany.setValue(company.companyData.CategoryOfCompany);
        this.companyMasterForm.controls.registeredOfficeAddress.setValue(company.companyData.RegisteredOfficeAddress);
        this.companyMasterForm.controls.registrationIDType.setValue(company.companyData.RegistrationIDType);
        this.companyMasterForm.controls.registrationNumber.setValue(company.companyData.RegistrationNumber);
        this.companyMasterForm.controls.ISIN.setValue(company.companyData.ISIN);
        this.companyMasterForm.controls.exchangeCode.setValue(company.companyData.ExchangeCode);
        // this.companyMasterForm.controls.CIN.setValue(company.companyData.CIN);
        // this.companyMasterForm.patchValue({ nbfcRegulations: company.companyData.NBFCRegulations, acceptsDeposits: company.companyData.AcceptsDeposits });
        this.companyMasterForm.controls.nbfcRegulations.setValue(company.companyData.NBFCRegulations);

        this.companyMasterForm.controls.acceptsDeposits.setValue(company.companyData.AcceptsDeposits);

        if (company.companyData.AcceptsDeposits == "false") {
          this.acceptDepositValueFalse();
          // this.companyMasterForm.controls.acceptsDeposits.setValue('true');          
          // this.companyMasterForm.controls.amountoFDeposits.setValue(company.companyData.AmountOfDeposits);
          this.companyMasterForm.controls.amountoFDeposits.setValue(company.companyData.AssetSize);
          // console.log("AmountOfDeposits-yes", company.companyData.AcceptsDeposits)
        }
        else {
          this.companyMasterForm.controls.amountoFDeposits.setValue(null);
        }


        if (company.companyData.AcceptsDeposits == "false") {
          this.companyMasterForm.controls.acceptsDeposits.setValue('false');
          // console.log("AmountOfDeposits-no", company.companyData.AcceptsDeposits)
        }



        this.getIndustryTypes(company.companyData.IndustryCode);
        this.industryCode = company.companyData.IndustryCode;

        this.companyMasterForm.controls.noOfShareholders.setValue(company.companyData.NumberOfShareholders);
        this.companyMasterForm.controls.noOfDebentureHolders.setValue(company.companyData.NumberOfDebentureHolders);
        this.companyMasterForm.controls.noOfDepositHolders.setValue(company.companyData.NumberOfDepositHolders);
        this.companyMasterForm.controls.noOfSecurityHolders.setValue(company.companyData.NumberOfSecurityHolders);


        if (company.companyData.Listed) {
          // console.log("company.Listed", company.companyData.Listed);
          this.companyMasterForm.controls.listedCompany.setValue('true');
          this.showSelected = true;
          //this.showListingTypeDiv();
        }
        else if (company.companyData.Listed == null) {
          // console.log("company.Listed - null", company.companyData.Listed);
          $("#listCompanyCheck").hide();
          $("#listCompanyCheck1").hide();
          this.hideListedCompany = false;

        }
        else {
          // console.log("company.Listed - else", company.companyData.Listed);
          this.companyMasterForm.controls.listedCompany.setValue('false');
          this.showSelected = false;

        }

        // console.log("company.Listing type", company.companyData.ListingType);
        var listingType = company.companyData.ListingType;

         

        if (listingType) {
          listingType.forEach(element => {
            switch (element) {

              case "DebtListing":
                this.debtListingFlag = true;
                this.companyMasterForm.controls.debtListing.setValue(element);
                break;

              case "EquityListing":
                this.equityListingFlag = true;
                this.companyMasterForm.controls.equityListing.setValue(element);
                break;

              case "OnlyDebtListing":
                this.onlyDebtListingFlag = true;
                this.companyMasterForm.controls.onlyDebtListing.setValue(element);
                break;

              case "EquityListingSMESegment":
                this.equityListingSMESegmentFlag = true;
                this.companyMasterForm.controls.equityListingSMESegment.setValue(element);
                break;

            }
          })
        }

        // //get the subsidiary autocomplete list


        // var debListing = company.companyData.DebtListing || false;
        // this.companyMasterForm.controls.debtListing.setValue(debListing);
        // this.debtListingFlag = debListing;
        // this.debtListing = debListing;

        // var equityListing = company.companyData.EquityListing || false;
        // this.companyMasterForm.controls.equityListing.setValue(equityListing);
        // this.equityListingFlag = equityListing;
        // this.equityListing = equityListing;

        // var onlyDebtListing = company.companyData.OnlyDebtListing || false;
        // this.companyMasterForm.controls.onlyDebtListing.setValue(onlyDebtListing);
        // this.onlyDebtListingFlag = onlyDebtListing;
        // this.onlyDebtListing = onlyDebtListing;

        // var equityListingSMESegment = company.companyData.EquityListingSMESegment || false;
        // this.companyMasterForm.controls.equityListingSMESegment.setValue(equityListingSMESegment);
        // this.equityListingSMESegmentFlag = equityListingSMESegment;
        // this.equityListingSMESegment = equityListingSMESegment;
        //---------------------------------------------
        // console.log("company.companyData.IsGEMSubscribedFlag_________________",company.companyData.IsGEMSubscribedFlag)
        this.companyMasterForm.controls.isGEMSubscribedFlag.setValue(company.companyData.IsGEMSubscribedFlag);
        if (company.companyData.IsGEMSubscribedFlag) {
          this.isGEMSubscribedFlag = true;
          this.companyMasterForm.controls.GEMAccessURL.setValidators([Validators.required]);
          this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();
        } else {
          this.isGEMSubscribedFlag = false;
          this.companyMasterForm.controls.GEMAccessURL.reset();
          this.companyMasterForm.controls.GEMAccessURL.clearValidators();
          this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();
        }
        this.companyMasterForm.controls.GEMAccessURL.setValue(company.companyData.GEMAccessURL);
        this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();

        this.companyMasterForm.controls.complianceEngine.setValue(company.companyData.ComplianceEngine);
        this.companyMasterForm.controls.complianceEngineURL.setValue(company.companyData.ComplianceEngineUrl);
        this.companyMasterForm.controls.chatEngineURL.setValue(company.companyData.ChatEngineURL);
        if (company.companyData.CompanyLogo != null && company.companyData.CompanyLogo != "") {
          // $("#companyLogoDisplay").attr("src", company.companyData.CompanyLogoImage);
          // $("#companyLogo").attr("src", company.companyData.CompanyLogoImage);
          // this.base64Code.image = company.companyData.CompanyLogoImage;
          this.base64Code.image = environment.serverIP + company.companyData.CompanyLogo;
          this.defaultImage = environment.serverIP + company.companyData.CompanyLogo;
        }
        else {
          this.defaultImage = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
          // this.base64Code.image = environment.serverIP + company.companyData.CompanyLogo;

          this.base64Code.image = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
          company.companyData.CompanyLogoImage = this.base64Code.image;
          $('#companyLogo').attr('src', company.companyData.CompanyLogoImage);
          $("#companyLogoDisplay").attr("src", company.companyData.CompanyLogoImage);
        }

        this.companyMasterForm.controls.CompanyLogoImage.setValue(company.companyData.CompanyLogoImage);
        this.subsidiaryArray = company.companyData.SubsidiaryCompanies;

        //12-Mar
        //added isEdit property to subsidiaryArray        
        
        for (var i = 0, len = this.subsidiaryArray.length; i < len; ++i) {
          // this.subsidiaryArray.push(toPush[i]);
          this.subsidiaryArray[i].Index = i + 1;
          this.subsidiaryArray[i].isEdit = false;
          //dirty properties
          this.subsidiaryArray[i].dirtySubsideryName = this.subsidiaryArray[i].Name;
          this.subsidiaryArray[i].dirtyCIN = this.subsidiaryArray[i].CIN;
          this.subsidiaryArray[i].dirtySubsideryType = this.subsidiaryArray[i].Type;
          this.subsidiaryArray[i].dirtyShareholding = this.subsidiaryArray[i].Shareholding;
          //validation
          this.subsidiaryArray[i].isUniqueCIN = true;
          this.subsidiaryArray[i].isValidCIN = true;//21 digits
          this.subsidiaryArray[i].isValidExSubsideryName = true;

          if (this.subsidiaryArray[i].Fincode == null || this.subsidiaryArray[i].Fincode == "0") {
            this.subsidiaryArray[i].otherSubsideryName = this.subsidiaryArray[i].Name;
            this.subsidiaryArray[i].isFoundOtherSubsidery = true;
            this.subsidiaryArray[i].dirtyFincode = "other";
            this.subsidiaryArray[i].dirtyOtherSubsideryName = this.subsidiaryArray[i].Name;
            this.subsidiaryArray[i].isFoundDirtyOtherSubsidery = true;
          }
          else {
            this.subsidiaryArray[i].isFoundOtherSubsidery = false;
            this.subsidiaryArray[i].isFoundDirtyOtherSubsidery = false;
            this.subsidiaryArray[i].dirtyOtherSubsideryName = "";
          }
        }

        if (company.companyData.FinancialYearFrom != null) {
          dt = new Date(this.setFormatDate(company.companyData.FinancialYearFrom));
          this.financialYearFromModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          this.companyMasterForm.controls.FinancialYearFrom.setValue(this.financialYearFromModel);
        }
        if (company.companyData.FinancialYearTo != null) {
          dt = new Date(this.setFormatDate(company.companyData.FinancialYearTo));
          this.financialYearToModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          this.companyMasterForm.controls.FinancialYearTo.setValue(this.financialYearToModel);
        }

         
        this.bindPeersofSelectedCompany(company.companyData.FinCode);

        this.tradingWindowValue = company.companyData.TradingWindowStatus;
        if (company.companyData.TradingWindowStatus == 'On') {
          $('#tradingWindowOpenID').addClass('active');
        } else if (company.companyData.TradingWindowStatus == 'Off') {
          $('#tradingWindowClosedID').addClass('active');
        }


        // bind selectedPeers list to display the peers


        if (company.companyData.Peers.length > 0) {
          this.selectedPeers = company.companyData.Peers.map(function (o) {
            return {
              'label': o.PeerName,
              'value': o.PeerFincode
            }
          });
        }
        else {
          this.selectedPeers = [];
        }

        this.chosenPeers = company.companyData.Peers.map(function (o) {
          return o.PeerFincode;
        });

        // this.companyMasterForm.controls.typeOfIndustry.setValue(company.companyData.IndustryType);

        // $("#myModalLabel").text("Edit Company");
        $('#btnsaveCompany').hide();
        $('#btnupdateCompany').hide();
        this.error = { isError: false, errorMessage: '' }

        this.companyMasterForm.controls.EVA.setValue(company.companyData.EVA);


        var isAdFlagSubscribed = company.companyData.IsADSubscribedFlag ? company.companyData.IsADSubscribedFlag : false;


        
        var adServer = [];
        adServer = company.companyData.ADServersDetails ? company.companyData.ADServersDetails : [{
          ADUrl : '',
          ADEmailID : '',
          ADPassword : ''
        }]
        var count = _.size(company.companyData.ADServersDetails); 

        if(isAdFlagSubscribed){
          var intialCount =0;
          for (intialCount ; intialCount < count ; intialCount++ ){
            this.companyMasterForm.addControl('ADUrl'+ intialCount ,new FormControl('', [Validators.required]) )
            this.companyMasterForm.addControl('ADEmailID'+ intialCount,new FormControl('', [Validators.required, Validators.email]) )
            this.companyMasterForm.addControl('ADPassword'+ intialCount ,new FormControl('', [Validators.required]))
          }
  
          intialCount =0;
          for (intialCount ; intialCount < count ; intialCount++ ){
            this.companyMasterForm.controls['ADUrl'+ intialCount].updateValueAndValidity()
            this.companyMasterForm.controls['ADEmailID'+ intialCount].updateValueAndValidity()
            this.companyMasterForm.controls['ADPassword'+ intialCount].updateValueAndValidity()
          }
        }        

        this.companyMasterForm.updateValueAndValidity();

         
        this.isADSubscribedFlag = isAdFlagSubscribed;

        this.companyMasterForm.controls.isADSubscribedFlag.setValue(isAdFlagSubscribed);

        if(isAdFlagSubscribed) {
          this.adServerArray = company.companyData.ADServersDetails;
      

          intialCount =0;
          for (intialCount ; intialCount < count ; intialCount++ ){
            this.companyMasterForm.controls['ADUrl'+ intialCount].setValue(adServer[intialCount].ADUrl);
            
            this.companyMasterForm.controls['ADEmailID'+ intialCount].setValue(adServer[intialCount].ADEmailID)
      
            this.companyMasterForm.controls['ADPassword'+ intialCount].setValue(adServer[intialCount].ADPassword)
        
          }

        }
       
        this.companyMasterForm.updateValueAndValidity();
        this.companyMasterForm.disable();


       


      }
    })
  }

  // //get the company autocomplete list
  // getcompanyAutoComplete() {
  //   this.companyMasterService.getcompanyAutoComplete()
  //     .subscribe(compAutoComplete => {
  //       if (compAutoComplete.status == 0) {
  //         this.companyAutoCompleteList = compAutoComplete.companyList.map(function (o) {
  //           return {
  //             // '_id': o.FinCode,
  //             'label': o.CompanyName,
  //             'value': o.FinCode
  //           }
  //         })
  //       }
  //     })

  // }


  // onlyNumber(ev) {
  //   var keyCode = window.event ? ev.keyCode : ev.which;
  //   //codes for 0-9
  //   if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9)) {

  //     if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
  //       ev.preventDefault();
  //     }
  //     if (ev.target.value + ev.key > 100) {
  //       ev.preventDefault();
  //     }
  //   }
  //   else {
  //     if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
  //       ev.preventDefault();
  //     }
  //   }
  // }

  // //show Add compnay master pop up
  // showAddPopUp() {
  //   $("#myModalLabel").text("Add Company");
  //   $('#btnsaveCompany').show();
  //   $('#btnupdateCompany').hide();
  //   this.companyMasterForm.reset();
  //   this.error = { isError: false, errorMessage: '' }
  //   this.getcompanyAutoComplete();
  //   this.companyId = ""; //reset companyId
  //   this.selectedPeers = [];
  //   this.peersList = [];
  //   this.chosenPeers = [];
  //   this.companyNameModel = "";
  // }

  setDate(date) {

    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }

  getCompanyNameByFinCode(finCode) {
    if (finCode != "") {
      return this.companyAutoCompleteList.find(e => e.value == finCode);
    }
  }

  messagePopUpTimeoutToogle() {
    setTimeout(function () {
      var popUpClass = $('#messagePopUp').attr('class');
      if (popUpClass == "modal fade show") {
        $('#messagePopUp').modal('toggle');
      }
    }, 3000);
  }

  //showing company details for edit.
  displaySelectedCompanyForEdit(companyId) {
    
    this.selectedPeers = [];
    this.chosenPeers = [];

    this.companyMasterService.getcompanyAutoComplete()
      .then(compAutoComplete => {
        if (compAutoComplete.status == 0) {
          //to fill the autocomplte list
          this.companyAutoCompleteList = compAutoComplete.companyList.map(function (o) {
            return {
              // '_id': o.FinCode,
              'label': o.CompanyName,
              'value': o.CompanyName
            }
          },
          )
        }


        if (companyId != "") {

          // this.getcompanyAutoComplete()
          this.companyId = companyId;

          var companyToEdit = this.companies.filter(data => data._id == companyId);
          this.companyNameModel = companyToEdit[0].CompanyName;
          this.bindPeersofSelectedCompany(companyToEdit[0].FinCode);

          // bind selectedPeers list to display the peers
          if (companyToEdit[0].Peers.length > 0) {
            this.selectedPeers = companyToEdit[0].Peers.map(function (o) {
              return {
                'label': o.PeerName,
                'value': o.PeerFincode
              }
            });
          }

          this.chosenPeers = companyToEdit[0].Peers.map(function (o) {
            return o.PeerFincode;
          });


          $('#companyDialog').modal('toggle') //toggle after binding list



          this.companyMasterForm.controls.CIN.setValue(companyToEdit[0].CIN);
          // this.companyMasterForm.controls.companyName.setValue("IDBI Bank Ltd.");  //companyToEdit[0].CompanyName

          //this.companyMasterForm.controls.companyName = new FormControl("Raymond Ltd.");

          let dt;
          if (companyToEdit[0].DateOfIncorporation != null) {
            dt = new Date(this.setFormatDate(companyToEdit[0].DateOfIncorporation));
            this.dateOfIncorporationModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          }

          // this.companyMasterForm.controls.classOfCompany.setValue(companyToEdit[0].ClassOfCompany);
          // this.companyMasterForm.controls.typeOfCompany.setValue(companyToEdit[0].TypeOfCompany);

          this.companyMasterForm.controls.typeOfCompany.setValue(companyToEdit[0].TypeOfCompany);
          this.companyMasterForm.controls.categoryOfCompany.setValue(companyToEdit[0].CategoryOfCompany);

          this.companyMasterForm.controls.registeredOfficeAddress.setValue(companyToEdit[0].RegisteredOfficeAddress);
          // this.companyMasterForm.controls.registrationIDType.setValue(companyToEdit[0].RegistrationIDType);
          // this.companyMasterForm.controls.registrationNumber.setValue(companyToEdit[0].RegistrationNumber);
          this.companyMasterForm.controls.ISIN.setValue(companyToEdit[0].ISIN);
          this.companyMasterForm.controls.exchangeCode.setValue(companyToEdit[0].ExchangeCode);
          //  
          if (companyToEdit[0].FinancialYearFrom != null) {
            dt = new Date(this.setFormatDate(companyToEdit[0].FinancialYearFrom));
            this.financialYearFromModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          }

          if (companyToEdit[0].FinancialYearTo != null) {
            dt = new Date(this.setFormatDate(companyToEdit[0].FinancialYearTo));
            this.financialYearToModel = { month: dt.getMonth() + 1, day: dt.getDate(), year: dt.getFullYear() };
          }

          this.companyMasterForm.patchValue({ nbfcRegulations: companyToEdit[0].NBFCRegulations, acceptsDeposits: companyToEdit[0].AcceptsDeposits });
          this.companyMasterForm.controls.noOfShareholders.setValue(companyToEdit[0].NumberOfShareholders);
          this.companyMasterForm.controls.noOfDebentureHolders.setValue(companyToEdit[0].NumberOfDebentureHolders);
          this.companyMasterForm.controls.noOfDepositHolders.setValue(companyToEdit[0].NumberOfDepositHolders);
          this.companyMasterForm.controls.noOfSecurityHolders.setValue(companyToEdit[0].NumberOfSecurityHolders);
          // this.companyMasterForm.controls.typeOfIndustry.setValue(companyToEdit[0].IndustryType);

          $("#myModalLabel").text("Edit Company");
          $('#btnsaveCompany').hide();
          $('#btnupdateCompany').show();
          this.error = { isError: false, errorMessage: '' }

        }
      })

  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
      //.toLocaleDateString();
    }
    return null;
  }

  acceptDepositValueTrue() {
    //if Accepts Deposits value is true then asset size invisible irespective of the NBFC Regulations.
    this.acceptsDeposits = false;
    this.companyMasterForm.controls.amountoFDeposits.clearValidators();
    this.companyMasterForm.controls.amountoFDeposits.updateValueAndValidity();
  }

  acceptDepositValueFalse() {
    //if Accepts Deposits value is false nbfcRegulations true asset size visible.
    if (this.companyMasterForm.controls.nbfcRegulations.value == "Yes") {
      this.acceptsDeposits = true;
      this.companyMasterForm.controls.amountoFDeposits.setValidators([Validators.required, Validators.min(0)]);
    }
    else {
      this.acceptsDeposits = false;
      this.companyMasterForm.controls.amountoFDeposits.clearValidators();
      this.companyMasterForm.controls.amountoFDeposits.updateValueAndValidity();
    }
  }

  nbfcRegulationsValueTrue() {
    //if NBFC Regulations is true then asset size visible if accept deposits false.
    if (this.companyMasterForm.controls.acceptsDeposits.value == "false") {
      this.acceptsDeposits = true;
      this.companyMasterForm.controls.amountoFDeposits.setValidators([Validators.required, Validators.min(0)]);
    }
  }

  nbfcRegulationsValueFalse() {
    //if NBFC Regulations is false then asset size is hide.
    this.acceptDepositValueTrue();
  }

  // showCatDiv() {
  showListingTypeDiv() {
    this.showSelected = true;
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (this.companyMasterForm.controls.typeOfCompany.value == "Public") {
      //reset listing type checkboxes
      this.resetListingTypeCheckboxes();
      //set default Equity Listing checked
      this.equityListingFlag = true;
      //this.companyMasterForm.controls.equityListing.setValue(true);
    }
    else if (this.companyMasterForm.controls.typeOfCompany.value == "Private Limited Company (which is subsidiary of Public Company)" ||
      this.companyMasterForm.controls.typeOfCompany.value == "Private Limited Company (which is not a subsidiary of Public Company)") {
      this.resetListingTypeCheckboxes();
      this.hideListedCompany = true;
      this.companyMasterForm.controls['equityListing'].disable();
      this.companyMasterForm.controls['equityListingSMESegment'].disable();
      this.companyMasterForm.controls['onlyDebtListing'].enable();
      this.companyMasterForm.controls['debtListing'].disable();
      this.equityListingFlag = false;
      this.equityListingSMESegmentFlag = false;
      this.debtListingFlag = false;
      this.onlyDebtListingFlag = true;
    }
    //this.onlyDebtListingFlag = false;     
  }

  hideListingTypeDiv() {
    this.showSelected = false;
  }
  // addCompany() {

  //   //  

  //   //map selected peers details with PeerName & PeerFincode
  //   let selectedPeersList = [];
  //   selectedPeersList = this.selectedPeers.map(function (o) {
  //     return {
  //       'PeerName': o.label,
  //       'PeerFincode': o.value
  //     }
  //   });

  //   if (this.companyMasterForm.status == "VALID") {

  //     if (this.compareTwoDates()) {

  //       //get company name by pincode
  //       var company = this.getCompanyNameByFinCode(this.companyMasterForm.controls.companyName.value);

  //       //  
  //       if (company.value != "") { 

  //         //  


  //         var newCompany = {
  //           //this.companyMasterForm.controls['companyName'].value
  //           CIN: this.companyMasterForm.controls.CIN.value,
  //           CompanyName: company.label,
  //           FinCode: this.companyMasterForm.controls.companyName.value,
  //           DateOfIncorporation: this.setDate(this.companyMasterForm.controls.dateOfIncorporation.value),
  //           ClassOfCompany: ((this.companyMasterForm.controls.classOfCompany.value == "") ? null : this.companyMasterForm.controls.classOfCompany.value),
  //           TypeOfCompany: ((this.companyMasterForm.controls.typeOfCompany.value == "") ? null : this.companyMasterForm.controls.typeOfCompany.value),
  //           RegisteredOfficeAddress: this.companyMasterForm.controls.registeredOfficeAddress.value,
  //           RegistrationIDType: this.companyMasterForm.controls.registrationIDType.value,
  //           RegistrationNumber: this.companyMasterForm.controls.registrationNumber.value,
  //           IndustryType: ((this.companyMasterForm.controls.typeOfIndustry.value == "") ? null : this.companyMasterForm.controls.typeOfIndustry.value),
  //           FinancialYearFrom: this.setDate(this.companyMasterForm.controls.FinancialYearFrom.value),
  //           FinancialYearTo: this.setDate(this.companyMasterForm.controls.FinancialYearTo.value),
  //           NBFCRegulations: this.companyMasterForm.controls.nbfcRegulations.value,
  //           AcceptsDeposits: this.companyMasterForm.controls.acceptsDeposits.value,
  //           NumberOfShareholders: this.companyMasterForm.controls.noOfShareholders.value,
  //           NumberOfDebentureHolders: this.companyMasterForm.controls.noOfDebentureHolders.value,
  //           NumberOfDepositHolders: this.companyMasterForm.controls.noOfDepositHolders.value,
  //           NumberOfSecurityHolders: this.companyMasterForm.controls.noOfSecurityHolders.value,
  //           IndustryCode: this.industryCode,
  //           HousingCode: this.housingCode,
  //           Peers: selectedPeersList
  //         }

  //         //  
  //         this.companyMasterService.addCompany(newCompany)
  //           .subscribe(company => {
  //             if (company.status == 0) {
  //               $('#companyDialog').modal('toggle');
  //               $('#alertTitle').text("Success!");
  //               $('#alertTitle').css("color", "black");
  //               $('#alertMessage').text("Company created successfully.");
  //               $('#alertMessage').css("color", "black");
  //               $('#messagePopUp').modal('toggle');
  //               this.messagePopUpTimeoutToogle();

  //             } else {
  //               $('#companyDialog').modal('toggle');
  //               $('#alertTitle').css('color', '#f00');
  //               $('#alertTitle').text("Failure!");
  //               $('#alertMessage').text(company.message);
  //               $('#alertMessage').css('color', '#f00');
  //               $('#messagePopUp').modal('toggle');
  //               this.messagePopUpTimeoutToogle();
  //               // $.notify({
  //               //   title: '<strong>FAILUER!</strong>',
  //               //   message: company.message
  //               // }, {
  //               //     type: 'danger'
  //               //   });
  //             }
  //             this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //               dtInstance.destroy();
  //             });
  //             this.getCompaniesList();
  //             this.companyMasterForm.reset();
  //           });
  //       }

  //     }
  //     else {
  //       return false;
  //     }

  //   } else {
  //     console.log('Invalid form submit.')
  //     return false;
  //   }
  // }

  ResetCompanyForm() {
    this.companyMasterForm.reset();
    this.selectedPeers = [];
    this.invalidPeers = false;
  }

  editCompany() {
     
    if (this.companyMasterForm.status == "VALID") {

      if (this.companyMasterForm.controls.Fincode.value == "other") {
        this.companyMasterForm.value.Fincode = null
      }

      if (this.compareTwoDates()) {
        // var company = this.getCompanyNameByFinCode(this.companyMasterForm.controls.companyName.value);
        //map selected peers details with PeerName & PeerFincode
        let selectedPeersList = [];
        if (_.size(this.selectedPeers)) {
          selectedPeersList = this.selectedPeers.map(function (o) {
            return {
              'PeerName': o.label,
              'PeerFincode': o.value
            }
          });
        }

        let amountOfDeposit: any;

         

        if (this.companyMasterForm.controls.acceptsDeposits.value == "false" && this.companyMasterForm.controls.nbfcRegulations.value == "Yes") {
          amountOfDeposit = this.companyMasterForm.controls.amountoFDeposits.value
        } else {
          amountOfDeposit = null;
        }

        var listingtype = [];

        var listed = false;
        if (this.companyMasterForm.controls.listedCompany.value == "true") {
          listed = true;
          if (this.equityListingFlag)   //this.companyMasterForm.controls.equityListing.value
          {
            listingtype.push("EquityListing");
          }

          if (this.equityListingSMESegmentFlag)  //this.companyMasterForm.controls.equityListingSMESegment.value
          {
            listingtype.push("EquityListingSMESegment");
          }
          if (this.onlyDebtListingFlag) {   //this.companyMasterForm.controls.onlyDebtListing.value
            listingtype.push("OnlyDebtListing");
          }
          if (this.debtListingFlag) {  //this.companyMasterForm.controls.debtListing.value
            listingtype.push("DebtListing");
          }
        }
        else {
          listingtype = [];
        }

        // at lease one listed company selection is mandatory if Listed company option is true. 
        if (listed == true && listingtype.length == 0) {
          this.isChekedListedCompany = false;
          return false;
        }
        else {
          this.isChekedListedCompany = true;
        }

         

        var address;
        if (this.companyMasterForm.controls.registeredOfficeAddress.value != null) {
          address = this.companyMasterForm.controls.registeredOfficeAddress.value.trim();
        }
        else
          address = this.companyMasterForm.controls.registeredOfficeAddress.value;

          var adServerDetails = [];

          var count = _.size(this.adServerArray); 

          
          var intialCount =0;
          if(this.isADSubscribedFlag){
            for (intialCount ; intialCount < count ; intialCount ++ ){
              adServerDetails.push({
                ADUrl: this.companyMasterForm.controls['ADUrl'+intialCount].value ? this.companyMasterForm.controls['ADUrl'+intialCount].value : null,
                ADEmailID: this.companyMasterForm.controls['ADEmailID'+intialCount].value ?  this.companyMasterForm.controls['ADEmailID'+intialCount].value : null,
                ADPassword: this.companyMasterForm.controls['ADPassword'+intialCount].value ? this.companyMasterForm.controls['ADPassword'+intialCount].value : null 
              })
            }
          }else{
            adServerDetails = [{
              ADUrl: '',
              ADEmailID: '', 
              ADPassword: ''
            }]
          }
          


        var updatedCompany = {
          //this.companyMasterForm.controls['companyName'].value
          CompanyId: this.companyId,
          // CIN: this.companyMasterForm.controls.CIN.value,
          //company name is disable while editing
          CompanyName: this.companyMasterForm.controls.companyName.value,
          FinCode: this.companyMasterForm.controls.companyFINcode.value,
          DateOfIncorporation: this.setDate(this.companyMasterForm.controls.dateOfIncorporation.value),

          // ClassOfCompany: ((this.companyMasterForm.controls.classOfCompany.value == "") ? null : this.companyMasterForm.controls.classOfCompany.value),
          // TypeOfCompany: ((this.companyMasterForm.controls.typeOfCompany.value == "") ? null : this.companyMasterForm.controls.typeOfCompany.value),

          TypeOfCompany: ((this.companyMasterForm.controls.typeOfCompany.value == "") ? null : this.companyMasterForm.controls.typeOfCompany.value),
          CategoryOfCompany: ((this.companyMasterForm.controls.categoryOfCompany.value == "") ? null : this.companyMasterForm.controls.categoryOfCompany.value),

          RegisteredOfficeAddress: address, //this.companyMasterForm.controls.registeredOfficeAddress.value,
          RegistrationIDType: this.companyMasterForm.controls.registrationIDType.value,
          RegistrationNumber: this.companyMasterForm.controls.registrationNumber.value,
          ISIN: this.companyMasterForm.controls.ISIN.value,
          ExchangeCode: this.companyMasterForm.controls.exchangeCode.value,
          IndustryType: ((this.companyMasterForm.controls.typeOfIndustry.value == "") ? null : this.companyMasterForm.controls.typeOfIndustry.value),
          IndustryCode: this.industryCode,
          FinancialYearFrom: this.setDate(this.companyMasterForm.controls.FinancialYearFrom.value),
          FinancialYearTo: this.setDate(this.companyMasterForm.controls.FinancialYearTo.value),
          NBFCRegulations: this.companyMasterForm.controls.nbfcRegulations.value,
          AcceptsDeposits: this.companyMasterForm.controls.acceptsDeposits.value,
          // AmountOfDeposits: amountOfDeposit,
          AssetSize: amountOfDeposit,
          NumberOfShareholders: this.companyMasterForm.controls.noOfShareholders.value,
          NumberOfDebentureHolders: this.companyMasterForm.controls.noOfDebentureHolders.value,
          NumberOfDepositHolders: this.companyMasterForm.controls.noOfDepositHolders.value,
          NumberOfSecurityHolders: this.companyMasterForm.controls.noOfSecurityHolders.value,
          ListingType: listingtype,
          ComplianceEngine: this.companyMasterForm.controls.complianceEngine.value,
          ComplianceEngineUrl: this.companyMasterForm.controls.complianceEngineURL.value.trim(),
          ChatEngineURL: ((this.companyMasterForm.controls.chatEngineURL.value == "" || _.size(this.companyMasterForm.controls.chatEngineURL.value) == 0) ? "" :this.companyMasterForm.controls.chatEngineURL.value.trim()),
          CompanyLogoImage: '',//this.companyMasterForm.controls.CompanyLogoImage.value,
          SubsidiaryCompanies: this.subsidiaryArray,
          Peers: selectedPeersList,
          Listed: listed,
          EVA: this.companyMasterForm.controls.EVA.value,
          ClientUIHostIP: location.origin,
          GEMAccessURL : this.companyMasterForm.controls.isGEMSubscribedFlag.value == true ? this.companyMasterForm.controls.GEMAccessURL.value : " ",
          // GEMAccessURL: this.companyMasterForm.controls.GEMAccessURL.value,
          isGEMSubscribedFlag: this.companyMasterForm.controls.isGEMSubscribedFlag.value,
          IsADSubscribedFlag : this.companyMasterForm.controls.isADSubscribedFlag.value,
          ADServersDetails : adServerDetails
        }
        // console.log("updatedCompany===================",updatedCompany)

        // console.log("this.companyMasterForm.controls.isADSubscribedFlag.value,",this.companyMasterForm.controls.isADSubscribedFlag.value,)

        this.companyMasterService.editCompany(updatedCompany)
          .then(company => {
            if (company.status == 0) {
              this.companySetupService.synchistoricalData().then(syncResponse => {
                // console.log('syncResponse', syncResponse)
              })
              // if (_.size(this.formData.get('files'))) {
              this.companySetupService.uploadCompanyLogo(this.companyId, this.formData).then(function (filUploadResponse) {
                // console.log('filUploadResponse', filUploadResponse)
                if (!filUploadResponse.status && (!_.isUndefined(filUploadResponse.companyData.CompanyLogo) || !_.isEmpty(filUploadResponse.companyData.CompanyLogo))) {
                  this.base64code.image = environment.serverIP + filUploadResponse.companyData.CompanyLogo
                } else {
                  this.base64code.image = this.defaultImage
                }
              })
              // }
              localStorage.removeItem('companyEVA')
              localStorage.setItem('companyEVA', this.companyMasterForm.controls.EVA.value)


              $('#companyDialog').modal('toggle');
              $('#alertPopUpTitle').text("Success!");
              $('#alertPopUpTitle').css("color", "black");
              $('#alertMessage').text("Company updated successfully.");
              $('#alertMessage').css("color", "black");
              $('#messagePopUp').modal('toggle');
              this.messagePopUpTimeoutToogle();

              location.reload();
              this.toogle('cancel');
               
              //update company logo on dashboard
              // if (this.base64Code.image != null)
              //   $("#companyLogoImg").attr("src", this.base64Code.image);



            } else {
              $('#companyDialog').modal('toggle');
              $('#alertPopUpTitle').css('color', '#f00');
              $('#alertPopUpTitle').text("Failure!");
              $('#alertMessage').text(company.message);
              $('#alertMessage').css('color', '#f00');
              $('#messagePopUp').modal('toggle');
              this.messagePopUpTimeoutToogle();
              // $.notify({
              //   title: '<strong>FAILUER!</strong>',
              //   message: company.message
              // }, {
              //     type: 'danger'
              //   });
            }
            // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //   dtInstance.destroy();
            // });
            this.getCompaniesList();
            // this.companyMasterForm.reset();
          });
      }
      else {
        return false;
      }
    }
  }

  compareTwoDates() {

    if (this.financialYearToModel == null && this.financialYearFromModel == null) {
      this.error = { isError: false, errorMessage: '' }
      return true;
    }

    if (this.financialYearToModel == null || this.financialYearFromModel == null) {
      this.error = {
        isError: true, errorMessage: 'Both date is required.'
      };
      return false;
    }

    if (new Date(this.setDate(this.financialYearToModel)) < new Date(this.setDate(this.financialYearFromModel))) {
      this.error = {
        isError: true, errorMessage: 'Financial year to date cannot be set before Financial year from date.'
      };
      return false;
    }
    else
      this.error = { isError: false, errorMessage: '' }

    return true;
  }

  //validation for numbers not exceeding a limit
  onlyNumber(ev) {
     
    var keyCode = window.event ? ev.keyCode : ev.which;
    //codes for 0-9
    if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9)) {

      if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
        ev.preventDefault();
      }
      //restrict up to 10 digits
      // if ((ev.target.value + ev.key).length > 11) {
      //   ev.preventDefault();
      // }
      // 
    }
    else {
      if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
        ev.preventDefault();
      }
    }
  }

  //validation for numbers not exceeding a limit
  onlyDecimalNumber(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;

    if (keyCode != 190) {

      //codes for 0-9
      if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106) || (keyCode > 34 && keyCode < 41) || (keyCode == 16) || (keyCode == 46) || (keyCode == 9) || (keyCode == 110)) {

        if (((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) && this.numList.indexOf(ev.key) == -1) {
          ev.preventDefault();
        }
        //restrict up to 10 digits
        if ((ev.target.value + ev.key).length > 11) {
          ev.preventDefault();
        }
        // 
      }
      else {
        if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
          ev.preventDefault();
        }
      }
    }
  }

  ListedConditionCheck(typeOfCompanyValue) {
    switch (typeOfCompanyValue) {

      case "":
        //this.companyMasterForm.controls.listedCompany.setValue('');
        // this.companyMasterForm.controls.listedCompany.disable();        
        this.hideListedCompany = false;
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        this.companyMasterForm.controls.listedCompany.setValue('false');
        this.showSelected = false;
        break;
      case "Public":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].enable();
        this.companyMasterForm.controls['equityListingSMESegment'].enable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].enable();
        this.equityListingFlag = true;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        break;
      case "Private Limited Company (which is not a subsidiary of Public Company)":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].disable();
        this.companyMasterForm.controls['equityListingSMESegment'].disable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].disable();
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = true;
        break;
      case "Private Limited Company (which is subsidiary of Public Company)":
        this.hideListedCompany = true;
        this.companyMasterForm.controls['equityListing'].disable();
        this.companyMasterForm.controls['equityListingSMESegment'].disable();
        this.companyMasterForm.controls['onlyDebtListing'].enable();
        this.companyMasterForm.controls['debtListing'].disable();
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = true;
        break;
      case "One Person Company":
        this.hideListedCompany = false;
        this.equityListingFlag = false;
        this.equityListingSMESegmentFlag = false;
        this.debtListingFlag = false;
        this.onlyDebtListingFlag = false;
        this.companyMasterForm.controls.listedCompany.setValue('false');
        this.showSelected = false;

    }
    // console.log("value ", typeOfCompanyValue);
  }


  // checkboxListing(typeOfCompanyValue, checkboxvalue) {
  onListingTypeChange(listedType, isChecked) {
    // console.log("data, checkbox value", listedType);
     
    switch (listedType) {
      case "equityListing":
        if (isChecked == true) {
          this.equityListingSMESegmentFlag = false;
          this.onlyDebtListingFlag = false;
          this.equityListingFlag = true;
        }
        else {
          this.equityListingFlag = false;
        }
        break;
      case "equityListingSMESegment":
        if (isChecked == true) {
          this.equityListingFlag = false;
          this.onlyDebtListingFlag = false;
          this.equityListingSMESegmentFlag = true;
        }
        else {
          this.equityListingSMESegmentFlag = false;
        }
        break;
      case "onlyDebtListing":
        if (isChecked == true) {
          this.equityListingFlag = false;
          this.equityListingSMESegmentFlag = false;
          this.debtListingFlag = false;
          this.onlyDebtListingFlag = true;
        }
        else {
          this.onlyDebtListingFlag = false;
        }
        break;
      case "debtListing":
        if (isChecked == true) {
          this.onlyDebtListingFlag = false;
          this.debtListingFlag = true;
        }
        else {
          this.debtListingFlag = false;
        }
        break;
    }

  }

  //--DELETE selected category for assessment
  deleteConfirmation(companyId) {
    this.companyId = companyId
    $('#confirmation').modal('toggle');
  }

  // deleteCompany() {

  //   this.companyMasterService.deleteCompany(this.companyId).subscribe(data => {
  //     if (data.status == 0) {
  //       $('#confirmation').modal('toggle');
  //       $('#alertTitle').text("Success!");
  //       $('#alertTitle').css("color", "black");
  //       $('#alertMessage').text("Company deleted successfully.");
  //       $('#alertMessage').css("color", "black");
  //       $('#messagePopUp').modal('toggle');
  //       this.messagePopUpTimeoutToogle();

  //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //         dtInstance.destroy();
  //       });
  //       this.getCompaniesList();
  //     }
  //     else {
  //       $('#confirmation').modal('toggle');
  //       $('#alertTitle').text("Failure!");
  //       $('#alertTitle').css('color', '#f00');
  //       $('#alertMessage').text(data.message);
  //       $('#alertMessage').css('color', '#f00');
  //       $('#messagePopUp').modal('toggle');
  //       this.messagePopUpTimeoutToogle();
  //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //         dtInstance.destroy();
  //       });
  //     }
  //   });
  // }

  //get the peers list of selected company and binds to the peer list. 
  getPeersListOnCompanySelected(item: any) {
    this.companyLabel = item.label;
    this.bindPeersofSelectedCompany(item.value);
    this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
    this.tempCompanySelectedCIN = this.tempCompanyInputSelected.CIN;
    // this.getSubsidiaryAutoComplete();
  }

  //bind peers of selected company
  bindPeersofSelectedCompany(itemValue) {

    //get industry and housing code by selected company
    this.companyMasterService.getSelectedCompanyDetailsFromFinCode(itemValue).then(selectedCompany => {
      if (selectedCompany.status == 0) {
        //this.industryCode = selectedCompany.selectedCompanyDetails.IndustryCode;
        this.housingCode = selectedCompany.selectedCompanyDetails.HousingCode;

        //get industry And Housing Wise Peers from industry and housing code
        this.companyMasterService.getindustryAndHousingWisePeers(this.industryCode, this.housingCode)
          .then(peers => {
            if (peers.status == 0 && peers.IndustryAndHousingPeers.length > 0) {

               
              let uniquePeers = [];
              uniquePeers = _.uniqBy(peers.IndustryAndHousingPeers, "Fincode");

              //this.peersList = peers.IndustryAndHousingPeers.map(function (o) {
              this.peersList = uniquePeers.map(function (o) {
                return {
                  'label': o.COMPNAME,
                  'value': o.Fincode  //o.FINCODE   
                }
              });

              //alphabetically order
              this.peersList = this.peersList.sort(function (a, b) {
                return a.label.localeCompare(b.label);
              });
            }

            //  
            if (this.peersList.length > 0) {
              let indexOfItem = this.peersList.findIndex(o => o.value === itemValue);
              if (indexOfItem > -1)
                //reomve the peers name (if peers is then same company name which is is selected ) from the collection 
                this.peersList.splice(indexOfItem, 1);
            }
          });
      }
    });
  }


  //bind selected peers id and name to the list
  getSelectedPeers(item: any) {
     
    // this.selectedPeers.push([item.label, item.value]);
    this.selectedPeers.push(item);
    //invalidPeers
    if (this.selectedPeers.length <= 5)
      this.invalidPeers = false;
    else
      this.invalidPeers = true;
  }

  //remove peers from list which is removed from ng-select control
  multiplePeersDeselected(item: any) {
    //  
    let indexOfItem = this.selectedPeers.findIndex(o => o.value === item.value);
    this.selectedPeers.splice(indexOfItem, 1);

    if (this.selectedPeers.length <= 5)
      this.invalidPeers = false;
    else
      this.invalidPeers = true;
  }

  restrictCIN(ev) {
    var keyCode = window.event ? ev.keyCode : ev.which;

    var keys = [8, 46, 35, 36, 37, 38, 39, 40];

    if (keys.indexOf(keyCode) == -1) {
      if ((keyCode > 95 && keyCode < 106 || keyCode == 32) && this.numList.indexOf(ev.key) == -1) {
        ev.preventDefault();
      }
      else if (keyCode > 185 || keyCode == 16) {
        ev.preventDefault();
      }
      else if ((ev.target.value + ev.key).length > 21) {
        ev.preventDefault();
      }
    }
    //   else {
    //     if (keyCode != 0 && keyCode != 8 && keyCode != 13 && !ev.ctrlKey) {
    //       ev.preventDefault();
    //     }     
    // }
  }

  toogle(value) {
    
    // this.base64Code = {};
    if (value == 'edit') {
      this.companyMasterForm.enable();
      this.editCompanyData = false;
      this.editBtn = true;
      this.cancelBtn = false;
      this.IsUpdate = true;
      console.log("this.companyMasterForm",this.companyMasterForm);
      this.companyMasterForm.controls.companyName.disable();
      this.companyMasterForm.controls.complianceEngineURL.disable();
      this.companyMasterForm.controls.chatEngineURL.disable();
      // this.companyMasterForm.controls.GEMAccessURL.disable();
      // this.companyMasterForm.controls.isGEMSubscribedFlag.disable();
      //30 Mar
      // this.companyMasterForm.controls.typeOfIndustry.disable();
      // console.log('this.base64Code.image', this.base64Code.image)
      // this.base64Code.image = this.companyMasterForm.controls.CompanyLogoImage.value;
      $('#btnupdateCompany').show();
      this.getAllIndustryTypeList();

    } else if (value == 'cancel') {
      this.companyMasterForm.disable();
      this.editCompanyData = true;
      this.editBtn = false;
      this.cancelBtn = true;
      this.IsUpdate = false;
      this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
      this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');
      this.companyMasterForm.controls.newSubsidiaryType.setValue('');



      this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');
      this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
      this.companyMasterForm.controls.CompanyLogoImage.setValue('');
      this.subsidiaryArray = this.subsidiaryArray.filter(function (subsidiary) { return _.get(subsidiary, '_id') })

      this.base64Code.image = this.defaultImage;
      $('#btnupdateCompany').hide();

      //reset listed type checkboxes on cancel button
      this.resetListingTypeCheckboxes();

      // added on 10 Mar - on cancel page was not refresh
      this.getCompaniesList();
    }
  }

  resetListingTypeCheckboxes() {
    this.equityListingFlag = false;
    this.debtListingFlag = false;
    this.equityListingSMESegmentFlag = false;
    this.onlyDebtListingFlag = false;
  }

  toggleTradingWindow(value) {
    
    //this.companyIdForTrading = this.companyId;
    this.companyMasterService.toggleTradingWindow(value, this.companyId).then(response => {
      if (response.status == 0) {
        // this.industryTypes = indusTypes.industryList;
      }
    })
  }

  //profile upload
  showUploadImagePopUp() {
    // 
    // this.resetImage()
    //this.imageCropperBase64 = {};
    // this.isInvalidImage = true;
    $("#uploadCompanyLogoPopUp").modal('toggle');
    if (this.base64Code.image == null || this.base64Code.image == "") {
      this.base64Code.image = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
    }
    //   this.myFileInput.nativeElement.value = "";
    // setTimeout(function(){ $("#uploadProfilePopUp").modal('toggle'); }, 100);
  }

  removeLogoImage() {
    
    this.companyLogoObject.id = this.companyId;
    this.companyMasterService.removeLogo(this.companyLogoObject).then(response => {
       
      if (response.status == 0) {
        this.base64Code.image = "../../assets/modules/dummy-assets/common/img/avatars/Company-default.png";
        this.companyMasterForm.controls.CompanyLogoImage.setValue(this.base64Code.image);
        $('#companyLogo').attr('src', this.base64Code.image);
        $('#companyLogoDisplay').attr('src', this.base64Code.image);
      }
    })

  }

  //reset profile image & cropper in profile dialog
  // resetImage() {
  //   this.cropper.reset();        //reset cropper 
  //   $("#custom-input").val("");  //reset file name title
  //   this.isInvalidImage = true;
  // this.base64Code = {};
  // this.base64Code.image = this.defaultImage;
  // }

  restoreImage() {
    // 
    // this.base64Code = {};
    this.base64Code.image = this.defaultImage;
  }

  //set the image for cropping
  fileChangeListener($event) {
    //  

    //  this.cropper.reset();
    this.isInvalidImage = true;

    var image: any = new Image();
    var file: File = $event.target.files[0];
    var fileType = file["type"];
    var fileSize = file["size"];
    var ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];

    if (fileSize < 5000 || fileSize > 2000000) {
      
      this.isInvalidImage = true;
      $('#messagePopUp').css('z-index', '9999');
      $('#alertPopUpTitle').css('color', '#f00');
      $('#alertPopUpTitle').text("Failure!");
      $('#alertMessage').text("Please upload image of size 5 KB to 2 MB");
      $('#alertMessage').css('color', '#f00');
      $('#messagePopUp').modal('toggle');
      this.messagePopUpTimeoutToogle();
    }
    else if ($.inArray(fileType, ValidImageTypes) > 0) {
      // valid file type code goes here.
      this.isInvalidImage = false;
      this.formData.append('files', file)
    }
    else {
      this.isInvalidImage = true;          //if uploaded file is invalid
    }
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      this.base64Code.image = image.src;
      //  that.cropper.setImage(image);
    };

    myReader.readAsDataURL(file);

  }

  //test image cropper
  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
  }

  setCompanyLogo() {
    
    // if (Object.keys(this.imageCropperBase64).length != 0) {
    //   let imageString = this.imageCropperBase64.image;
    //   this.base64Code.image = imageString;
    this.companyMasterForm.controls.CompanyLogoImage.setValue(this.base64Code.image);
    $('#companyLogo').attr('src', this.base64Code.image);
    $('#uploadCompanyLogoPopUp').modal('toggle');
    //}
  }

  checkForRepeatCIN(CIN) {
     
    let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == CIN })

    if (!_.isNil(findCIN) || (this.companyMasterForm.controls['registrationNumber'].value == CIN && this.companyMasterForm.controls['registrationIDType'].value == "CIN")) {
      this.errorUniqueCIN = true;
    } else {
      this.errorUniqueCIN = false;
    }
  }

  checkForRepeatCINForExistingSubsidery(subsideryModel) {
     
    if (subsideryModel.dirtyCIN != undefined && subsideryModel.dirtyCIN != "") {

      //check for valid length
      // if ((subsideryModel.dirtyCIN.trim().length) == 21) {
      //   subsideryModel.isValidCIN = true;
      // }
      // else {
      //   subsideryModel.isValidCIN = false;
      //   return;
      // }

      //check for valid CIN
      var regExp = new RegExp("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$");

      if (regExp.test(subsideryModel.dirtyCIN)) {
        subsideryModel.isValidCIN = true;
      }
      else
        subsideryModel.isValidCIN = false;



      let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == subsideryModel.dirtyCIN && subsidiary.Index != subsideryModel.Index })


      if (!_.isNil(findCIN) || (this.companyMasterForm.controls['registrationNumber'].value == subsideryModel.dirtyCIN && this.companyMasterForm.controls['registrationIDType'].value == "CIN")) {
        subsideryModel.isUniqueCIN = false;
      } else {
        subsideryModel.isUniqueCIN = true;
      }
    }
    else {
      subsideryModel.isValidCIN = true;
      subsideryModel.isUniqueCIN = true;
    }
  }

  checkForRepeatCINForCompany(CIN) {
     
    if (this.companyMasterForm.controls['registrationIDType'].value == "CIN") {

      //check for valid CIN
      var regExp = new RegExp("^([A-Z]){1}([0-9]){5}([A-Z]){2}([0-9]){4}([A-Z]){3}([0-9]){6}?$");

      if (regExp.test(CIN)) {
        this.errorValidCINForCompany = false;
      }
      else
        this.errorValidCINForCompany = true;


      let findCIN = this.subsidiaryArray.find(function (subsidiary) { return subsidiary.CIN == CIN })

      if (!_.isNil(findCIN)) {
        this.errorUniqueCINForCompany = true;
      } else {
        this.errorUniqueCINForCompany = false;
      }
    }
    else {
      this.errorUniqueCINForCompany = false;
      this.errorValidCINForCompany = false;
    }
  }



  onSelectRegistrationId() {
    this.checkForRepeatCINForCompany(this.companyMasterForm.controls['registrationNumber'].value);
  }


  addSubsidiaryData() {

    var newSubsidiaryCIN = (this.companyMasterForm.controls.newSubsidiaryCIN.value.trim())

    if (newSubsidiaryCIN == "") {
      this.addSubsideryInfo();
    }
    else if (this.companyMasterForm.controls.newSubsidiaryCIN.value == this.tempCompanySelectedCIN) {
      // console.log("Cannot have CIN number of your parent.please enter different ");
      $('#alertPopUpTitle').css('color', '#f00');
      $('#alertPopUpTitle').text("Failure!");
      $('#alertMessage').text("Please enter CIN different from parent company CIN");
      $('#alertMessage').css('color', '#f00');
      $('#messagePopUp').modal('toggle');
    } else {
      this.addSubsideryInfo();
    }
  }

  addSubsideryInfo() {

   
    if (this.subsidiaryArray == undefined || this.subsidiaryArray == null) {
      this.subsidiaryArray = [];
    }

    
    let rowIndex = 1;
    if (this.subsidiaryArray.length) {
      rowIndex = rowIndex + this.subsidiaryArray.length;
    }

    var isFoundOtherSubsidery = false;

    if (this.companyMasterForm.controls['newSubsidiaryCompanyName'].value == "other") {
      isFoundOtherSubsidery = true;
    }

    // this.newSubsidiary = {
    //   Name: $('#newSubsidiaryName').val(),
    //   CIN: $('#newSubsidiaryCIN').val(),
    //   Type: $('#newSubsidiaryType').val(),
    //   Shareholding: $('#newSubsidiaryShareholding').val()
    // }

    // console.log('this.subsidiaryFincode', this.subsidiaryFincode)

    // var name = $('#newSubsidiaryName').val();
    // if (name == undefined ){
    //   name = this.companyMasterForm.controls['newSubsidiaryCompanyName'].value ;
    // }


    this.newSubsidiary = {
      Index: rowIndex,
      Name: this.companyMasterForm.controls.newSubsidiaryCompanyName.value,
      CIN: this.companyMasterForm.controls.newSubsidiaryCIN.value,//$('#newSubsidiaryCIN').val(),
      Type: this.companyMasterForm.controls.newSubsidiaryType.value,//$('#newSubsidiaryType').val(),
      Shareholding: $('#newSubsidiaryShareholding').val(),
      //added on 20Mar
      isEdit: false,
      //dirty properties
      dirtySubsideryName: $('#newSubsidiaryName').val(), //this.companyMasterForm.controls.newSubsidiaryCompanyName.value,
      dirtyCIN: this.companyMasterForm.controls.newSubsidiaryCIN.value,// $('#newSubsidiaryCIN').val(),
      dirtySubsideryType: this.companyMasterForm.controls.newSubsidiaryType.value,// $('#newSubsidiaryType').val(),
      dirtyShareholding: $('#newSubsidiaryShareholding').val(),
      //validation
      isUniqueCIN: true,
      isValidCIN: true,      //21 digits
      Fincode: _.toString(this.subsidiaryFincode),
      isFoundOtherSubsidery: isFoundOtherSubsidery,
      isFoundDirtyOtherSubsidery: isFoundOtherSubsidery,
      dirtyFincode: _.toString(this.subsidiaryFincode),
      otherSubsideryName: $('#newSubsidiaryName').val(),
      dirtyOtherSubsideryName: $('#newSubsidiaryName').val(),
      isValidExSubsideryName: true
    }


    if (this.newSubsidiary.Name == "") {
      this.newSubsidiary.Name = this.subsidiaryName;
      this.newSubsidiary.dirtySubsideryName = this.subsidiaryName;
    }

    this.subsidiaryArray.push(this.newSubsidiary)
    console.log("subsidiaryArray",this.subsidiaryArray)
    console.log("newSubsidiary",this.newSubsidiary)
    this.newSubsidiary = {};
    this.companyMasterForm.controls.newSubsidiaryCompanyName.setValue('');
    this.companyMasterForm.controls.newSubsidiaryCompanyName.updateValueAndValidity();
    this.companyMasterForm.controls.newSubsidiaryCIN.setValue('');
    this.companyMasterForm.controls.Fincode.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.setValue('');
    this.companyMasterForm.controls.newSubsidiaryType.markAsUntouched();
    this.companyMasterForm.controls.newSubsidiaryShareholding.setValue('');
    // $('#newSubsidiaryName').val('');
    // $('#newSubsidiaryCIN').val('');
    // $('#newSubsidiaryType').val('');
    // $('#newSubsidiaryShareholding').val('');
    $('#newSubsidiaryName').focus();
    $('#newSubsidiaryName').hide();


    // console.log("subsidiary", this.subsidiaryArray);
  }

  deleteSubsidiaryData(index) {
    
    this.subsidiaryArray.splice(index, 1);
  }

  selectSubsiderydata(subsideryModel) {
    
    // console.log('subsideryModel', subsideryModel.Fincode)
    //console.log('subsideryModelData', _.isEmpty(subsideryModel.Fincode), _.isNull(subsideryModel.Fincode))
    if (_.isNull(subsideryModel.Fincode) || (subsideryModel.Fincode == "0")) {
      // console.log('subsideryModel.Fincode', subsideryModel.Fincode);
      // $("#newSubsidiaryName").show();
      $("#exSubsidiaryName").show();
      subsideryModel.Fincode = "other";
    } else {
      $("#newSubsidiaryName").hide();
      $("#exSubsidiaryName").hide();
    }

    subsideryModel.Fincode = _.toString(subsideryModel.Fincode)
    subsideryModel.isEdit = true;
    //once record is editable other records will not.
    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id != subsideryModel._id) {
      if (this.subsidiaryArray[i].Index != subsideryModel.Index) {
        this.subsidiaryArray[i].isEdit = false;
      }
    }
  }

  cancelDirtySelection(subsideryModel) {
    
    if (this.subsidiaryArray == undefined || this.subsidiaryArray == null) {
      return false;
    }

    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id == subsideryModel._id) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {

        if (subsideryModel.dirtyFincode == "other") {
          if (subsideryModel.dirtyOtherSubsideryName != "") {
            this.subsidiaryArray[i].Name = subsideryModel.dirtyOtherSubsideryName;
          }
        }

        this.subsidiaryArray[i].dirtySubsideryName = subsideryModel.Name;
        this.subsidiaryArray[i].dirtyCIN = subsideryModel.CIN;
        this.subsidiaryArray[i].dirtySubsideryType = subsideryModel.Type;
        this.subsidiaryArray[i].dirtyShareholding = subsideryModel.Shareholding;
        this.subsidiaryArray[i].isEdit = false;
        this.subsidiaryArray[i].dirtyFincode = subsideryModel.Fincode;
        //  this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isFoundOtherSubsidery = subsideryModel.isFoundDirtyOtherSubsidery;
        this.subsidiaryArray[i].otherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = true;

        break; //Stop this loop, we found it!
      }
    }
  }

  //added on 13 mar
  updateSubsidiaryData(subsideryModel) {
   
    if (subsideryModel.dirtyOtherSubsideryName == "other") {
      subsideryModel.dirtyFincode = "other";
    }

    if (subsideryModel.isValidExSubsideryName == false || subsideryModel.isValidCIN == false) {
      return;
    }


    if (this.subsidiaryArray == undefined || this.subsidiaryArray == null) {
      return false;
    }

    //check unique cin no with company cin no
    this.checkForRepeatCINForExistingSubsidery(subsideryModel);

    if (subsideryModel.isUniqueCIN == false) {
      return false;
    }

    if (subsideryModel.isFoundOtherSubsidery == false) {
      subsideryModel.dirtyOtherSubsideryName = "";
      subsideryModel.otherSubsideryName = "";
    }
    //subsideryModel.dirtySubsideryName = this.subsidiaryName;

    // for (var i in this.subsidiaryArray) {    
    //   if (this.subsidiaryArray[i].Index == subsideryModel.Index) {
    //     this.subsidiaryArray[i].Name = subsideryModel.dirtySubsideryName;
    //     this.subsidiaryArray[i].CIN = subsideryModel.dirtyCIN;
    //     this.subsidiaryArray[i].Type = subsideryModel.dirtySubsideryType;
    //     this.subsidiaryArray[i].Shareholding = subsideryModel.dirtyShareholding;
    //     this.subsidiaryArray[i].isEdit = false;
    //     break; 
    //   }
    // }

    for (var i in this.subsidiaryArray) {
      // if (this.subsidiaryArray[i]._id == subsideryModel._id) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {

        if (subsideryModel.dirtyFincode == "other") {
          //this.subsidiaryArray[i].Name = subsideryModel.dirtyOtherSubsideryName;
          this.subsidiaryArray[i].Name = subsideryModel.otherSubsideryName;
        }
        else {
          this.subsidiaryArray[i].Name = subsideryModel.dirtySubsideryName;
        }

        this.subsidiaryArray[i].CIN = subsideryModel.dirtyCIN;
        this.subsidiaryArray[i].Type = subsideryModel.dirtySubsideryType;
        this.subsidiaryArray[i].Shareholding = subsideryModel.dirtyShareholding;
        this.subsidiaryArray[i].isEdit = false;
        this.subsidiaryArray[i].Fincode = subsideryModel.dirtyFincode;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = true;
        if (this.subsidiaryArray[i].dirtyOtherSubsideryName == "") {
          this.subsidiaryArray[i].isFoundOtherSubsidery = false;
          this.subsidiaryArray[i].isFoundDirtyOtherSubsidery = false;
        }
        //this.subsidiaryArray[i].otherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        break; //Stop this loop, we found it!
      }
    }

    this.hideOtherSubsideryNameField();

  }

  hideOtherSubsideryNameField() {
    $("#newSubsidiaryName").hide();
  }

  getSubsidiarySelected(event) {
    
    // console.log('event.value.FinCode', event.value)
    if (event.value == 'other') {
      $("#newSubsidiaryName").show();
      $("#exSubsidiaryName").show();
      this.subsidiaryFincode = event.value;
      this.otherSubsideryName = "";
      //if 'other' is slected as a subsidery  & other subsidery name is blank.
      this.isValidSubsdieryName = false;
      this.companyMasterForm.controls['newSubsidiaryCompanyName'].setValue(event.value);
    } else {
      $("#newSubsidiaryName").hide();
      $("#exSubsidiaryName").hide();
      this.subsidiaryFincode = event.value;
      this.subsidiaryName = event.label;
      this.isValidSubsdieryName = true;

    }
  }

  redirectToCompanySetup() {
    // this.rocketChatService.logoutFromRocketChat().then(rocketChatResponse => {
    //   if (rocketChatResponse.status == "success") {
    //     for (var count = 0; count < 10000; count++) {
    //       window.clearInterval(count);
    //     }
    //     window.localStorage.clear();
    //     // window.open(environment.pssUIBaseUrl + 'logout', "_self")
    //     //window.open(environment.pssUIBaseUrl + 'logout').close();  
    //     location.reload();
    //this.router.navigate(["/companySetup"]);         
      // swal({
      //   title: "Please enter your password.",                      
      //   type: "warning",
      //   confirmButtonClass: "btn-success",
      //   confirmButtonText: "Authenticate"
      // },function (inputValue) {
        
      // })
      this.router.navigate(["companySetup"]);
    //   }
    // });
  }

  // OLD CODE START HERE
  // getExistingSubsidiarySelected(event, subsidiary) {
  //   console.log('getExistingSubsidiarySelected', event, subsidiary)
  //   //  
  //   var isFoundOtherSubsidery = false;
  //   let subsidiaryobject = null;
  //   console.log("event.value == 'other'", event.value == 'other')
  //   //subsidiary.dirtyOtherSubsideryName = "";
  //   if (event.value == 'other') {
  //     isFoundOtherSubsidery = true;
  //     //isValidExSubsideryName -- if other subsidery name is blank then update button disable.
  //     subsidiary.isValidExSubsideryName = false;

  //     // subsidiary.dirtySubsideryName = "";     
  //   }
  //   else {
  //     isFoundOtherSubsidery = false;
  //     subsidiary.isValidExSubsideryName = true;
  //     subsidiaryobject = _.find(this.subsidaryList, function (companyObject) { return companyObject.Fincode == subsidiary })
  //     this.companySetupService.checkSubsidiaryCompany(subsidiaryobject).then(function (responseForSubsidiary) {
  //       console.log('responseForSubsidiary', responseForSubsidiary)
  //     })
  //   }

  //   subsidiary.dirtySubsideryName = event.label;
  //   subsidiary.dirtyFincode = event.value;

  //   for (var i in this.subsidiaryArray) {
  //     if (this.subsidiaryArray[i].Index == subsidiary.Index) {
  //       this.subsidiaryArray[i].dirtySubsideryName = subsidiary.dirtySubsideryName;
  //       this.subsidiaryArray[i].dirtyCIN = subsidiary.dirtyCIN;
  //       this.subsidiaryArray[i].dirtySubsideryType = subsidiary.dirtySubsideryType;
  //       this.subsidiaryArray[i].dirtyShareholding = subsidiary.dirtyShareholding;
  //       this.subsidiaryArray[i].isFoundOtherSubsidery = isFoundOtherSubsidery;
  //       this.subsidiaryArray[i].dirtyFincode = subsidiary.dirtyFincode;
  //       this.subsidiaryArray[i].dirtyOtherSubsideryName = subsidiary.dirtyOtherSubsideryName;
  //       this.subsidiaryArray[i].isValidExSubsideryName = subsidiary.isValidExSubsideryName;
  //       break; //Stop this loop, we found it!
  //     }
  //   }
  // }
  // END HERE

  //when new subsidery is to be added
  onLeaveEventOfOtherSubsideryName() {
    if (this.otherSubsideryName.trim() == "")
      this.isValidSubsdieryName = false;
    else {
      this.subsidiaryName = this.otherSubsideryName.trim();
      this.isValidSubsdieryName = true;
    }
    console.log("this.otherSubsideryName.",this.otherSubsideryName)
  }

  onLeaveEventOfExistingOtherSubsidery(event, subsideryModel) {

    //putting here if cancel then we should get orignal value from dirty other subsidery name.
    subsideryModel.dirtyOtherSubsideryName = subsideryModel.otherSubsideryName;
    subsideryModel.otherSubsideryName = event.currentTarget.value;
    if (event.currentTarget.value == "") {
      subsideryModel.isValidExSubsideryName = false;
    }
    else
      subsideryModel.isValidExSubsideryName = true;

    for (var i in this.subsidiaryArray) {
      if (this.subsidiaryArray[i].Index == subsideryModel.Index) {
        this.subsidiaryArray[i].otherSubsideryName = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsideryModel.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].Name = subsideryModel.otherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = subsideryModel.isValidExSubsideryName;
        break; //Stop this loop, we found it!
      }
    }
  }
  // NEW CODE FOR SLECCTED SUBSIDIARY
  getExistingSubsidiarySelected(event, subsidiary) {
    // console.log('getExistingSubsidiarySelected', event, subsidiary)
    //  
    var isFoundOtherSubsidery = false;
    let subsidiaryobject = null;
    // console.log("event.value == 'other'", event.value == 'other')
    //subsidiary.dirtyOtherSubsideryName = "";
    if (event.item == 'other') {
      isFoundOtherSubsidery = true;
      //isValidExSubsideryName -- if other subsidery name is blank then update button disable.
      subsidiary.isValidExSubsideryName = false;
      subsidiary.dirtyFincode = event.item;
      // subsidiary.dirtySubsideryName = "";     
    }
    else {
      isFoundOtherSubsidery = false;
      subsidiary.isValidExSubsideryName = true;
      // subsidiaryobject = _.find(this.subsidaryList, function (companyObject) { return companyObject.Fincode == subsidiary })
      subsidiaryobject = _.find(this.subsidiaryCompanyDetailsList, function (companyObject) { return companyObject.CompanyName == event.item })
      // console.log('subsidiaryobject', subsidiaryobject)
      subsidiaryobject.Fincode = subsidiaryobject.FinCode
      subsidiary.dirtyFincode = subsidiaryobject.FinCode
      subsidiary.dirtyOtherSubsideryName = subsidiaryobject.CompanyName
      subsidiary.Name = subsidiaryobject.CompanyName
      subsidiary.Fincode = subsidiaryobject.FinCode
      isFoundOtherSubsidery = false
      let newSubsidiaryObject = {
        Fincode: subsidiaryobject.FinCode,
        CIN: subsidiaryobject.CIN
      }

      this.companySetupService.checkSubsidiaryCompany(subsidiaryobject).then(function (responseForSubsidiary) {
        // this.companySetupService.checkSubsidiaryCompany(subsidiaryobject).then(function (responseForSubsidiary) {
        // console.log('responseForSubsidiary', responseForSubsidiary)
      })
    }

    subsidiary.dirtySubsideryName = event.item;
    // subsidiary.dirtyFincode = event.value;

    for (var i in this.subsidiaryArray) {
      if (this.subsidiaryArray[i].Index == subsidiary.Index) {
        this.subsidiaryArray[i].dirtySubsideryName = subsidiary.dirtySubsideryName;
        this.subsidiaryArray[i].dirtyCIN = subsidiary.dirtyCIN;
        this.subsidiaryArray[i].dirtySubsideryType = subsidiary.dirtySubsideryType;
        this.subsidiaryArray[i].dirtyShareholding = subsidiary.dirtyShareholding;
        this.subsidiaryArray[i].isFoundOtherSubsidery = isFoundOtherSubsidery;
        this.subsidiaryArray[i].dirtyFincode = subsidiary.dirtyFincode;
        this.subsidiaryArray[i].dirtyOtherSubsideryName = subsidiary.dirtyOtherSubsideryName;
        this.subsidiaryArray[i].isValidExSubsideryName = subsidiary.isValidExSubsideryName;
        break; //Stop this loop, we found it!
      }
    }
  }


  // FOR SELECTION OF THE SUBSIDIARY
  // START HERE
  // NEW CODE
  searchSubsidiaryCompany = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap(term => (_.isNil(term) || _.isEmpty(term)) ? [] :
        this.companySetupService.getCompanyListTypeAhead(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
          .toPromise()
          .then((response:any) => {
            // console.log('checking for the API response', response)
            if (!response.status && _.size(response.listOfCompany)) {
              this.subsidiaryCompanyDetailsList = response.listOfCompany

              if (this.companyLabel != undefined) {
                
                let subsidiaryLabel = this.companyLabel;
                let indexOfCompany = _.findIndex(this.subsidiaryCompanyDetailsList, function (company) { return _.get(company, 'CompanyName') == subsidiaryLabel })
                this.subsidiaryCompanyDetailsList.splice(indexOfCompany, 1);
              }
              var otherCompanyName = ['other']
              this.subsidiaryCompanyNameArray = _.map(response.listOfCompany, function (element) {
                return _.get(element, 'CompanyName')
              })
              this.subsidiaryCompanyNameArray = _.concat(otherCompanyName, this.subsidiaryCompanyNameArray)
              return this.subsidiaryCompanyNameArray;
              // this.companyMasterForm.controls['companyName'].setValue(companyNameArray)
            } else {
              // this.searchFailed = true;
              // this.companyMasterForm.controls['companyName'].setValue([])
              return []
            }
          })
      ),
      // tap(() => this.searching = false)
    )
  formatterForSubsidiaryCompany = (x: { CompanyName: string }) => { 
    // console.log(x); return x.CompanyName; 
  }

  selectedSubsidiaryCompanyItem(data) {
    this.companyLabel = data.item
    if (data.item != 'other') {
      let subsidiaryCompanyDetails = _.find(this.subsidiaryCompanyDetailsList, function (element) { return element.CompanyName == data.item })
      // console.log('subsidiaryCompanyDetails', subsidiaryCompanyDetails)
      this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
      this.tempCompanySelectedCIN = this.selectedCompanyByTypeAhead.CIN;
      this.bindPeersofSelectedCompany(subsidiaryCompanyDetails.FinCode);
      this.subsidiaryFincode = subsidiaryCompanyDetails.FinCode;
      this.subsidiaryName = data.item;
      this.otherSubsideryName = "";
    } else {
      this.tempCompanyInputSelected = this.companyMasterForm.controls.companyName.value;
      this.tempCompanySelectedCIN = this.selectedCompanyByTypeAhead.CIN;
      this.bindPeersofSelectedCompany('');

      $("#newSubsidiaryName").show();
      $("#exSubsidiaryname").show();
      this.subsidiaryFincode = data.item;
      this.otherSubsideryName = "";
      //if 'other' is slected as a subsidery  & other subsidery name is blank.
      this.isValidSubsdieryName = false;
      this.companyMasterForm.controls['newSubsidiaryCompanyName'].setValue(data.item);
    }

    // this.getPeersListOnCompanySelected(subsidiaryCompanyDetails)
  }

  clearDateOfIncoporation() {
    this.dateOfIncorporationModel = null;
    this.companyMasterForm.controls.dateOfIncorporation.setValue(null);
  }

  clearFinancialYearFrom() {
    this.financialYearFromModel = null;
    this.companyMasterForm.controls.FinancialYearFrom.setValue(null);
  }

  clearFinancialYearTo() {
    this.financialYearToModel = null;
    this.companyMasterForm.controls.FinancialYearTo.setValue(null);
  }

  // END HERE
  onGEMSubscriptionChange(event) {
    // 
    if (event.target.checked) {
      // console.log("flag Enabled")
      this.companyMasterForm.controls.GEMAccessURL.reset();
      this.isGEMSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isGEMSubscribedFlag.setValue(true);
      this.companyMasterForm.controls.GEMAccessURL.setValidators([Validators.required])
      this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();
      // console.log("this.companyMasterForm.controls.GEMAccessURL",this.companyMasterForm.controls.GEMAccessURL.valid)

    } else {
      // console.log("flag disabled")
      this.isGEMSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isGEMSubscribedFlag.setValue(false);
      this.companyMasterForm.controls.GEMAccessURL.reset();
      this.companyMasterForm.controls.GEMAccessURL.setValue("");
      this.companyMasterForm.controls.GEMAccessURL.clearValidators();
      this.companyMasterForm.controls.GEMAccessURL.updateValueAndValidity();

    }
  }


  onADSubscriptionChange(event) {
    
    if (event.target.checked) {
      this.isADSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isADSubscribedFlag.setValue(true);
      this.companyMasterForm.controls.isADSubscribedFlag.updateValueAndValidity();
      this.setAdServerControls(true)
    } else {
      this.isADSubscribedFlag = event.target.checked;
      this.companyMasterForm.controls.isADSubscribedFlag.setValue(false);
      this.companyMasterForm.controls.isADSubscribedFlag.updateValueAndValidity();
      this.setAdServerControls(false)
    }
  }

  setAdServerControls(flag){
    if(flag){
      this.companyMasterForm.addControl('ADUrl'+ 0 , new FormControl('', [Validators.required]))
      this.companyMasterForm.addControl('ADEmailID'+ 0 , new FormControl('', [Validators.required , Validators.email]))
      this.companyMasterForm.addControl('ADPassword'+ 0 , new FormControl('', [Validators.required]))
    }else{
      this.companyMasterForm.setControl('ADUrl'+ 0 , new FormControl('', []))
      this.companyMasterForm.setControl('ADEmailID'+ 0 , new FormControl('', []))
      this.companyMasterForm.setControl('ADPassword'+ 0 , new FormControl('', []))

      var count = _.size(this.adServerArray); 
      var intialCount =0;
      for (count ; count > intialCount ; count-- ){
        this.companyMasterForm.removeControl('ADUrl'+ count )
        this.companyMasterForm.removeControl('ADEmailID'+ count )
        this.companyMasterForm.removeControl('ADPassword'+ count )
      }
      this.companyMasterForm.updateValueAndValidity();

      this.adServerArray = [{
        ADUrl : '',
        ADEmailID : '',
        ADPassword : ''
      }]

    }
  }


  addAdServerControls(){

    var count = _.size(this.adServerArray); 
    

    this.companyMasterForm.addControl('ADUrl'+ count , new FormControl('', [Validators.required]))
    this.companyMasterForm.addControl('ADEmailID'+ count , new FormControl('', [Validators.required, Validators.email]))
    this.companyMasterForm.addControl('ADPassword'+ count , new FormControl('', [Validators.required]))

    
    this.adServerArray.push({
      ADUrl : '',
      ADEmailID : '',
      ADPassword : ''
    })
    
    this.companyMasterForm.updateValueAndValidity();
    console.log("this.companyMasterForm",this.companyMasterForm)
    
  }

  removeADServerConfirguration(index){
    this.companyMasterForm.removeControl('ADUrl'+ index )
    this.companyMasterForm.removeControl('ADEmailID'+ index )
    this.companyMasterForm.removeControl('ADPassword'+ index )
    this.companyMasterForm.updateValueAndValidity();
    this.adServerArray.splice(index,1)
  }

}
