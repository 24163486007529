<section class="card">
  <div class="card-header"></div>
  <div class="card-body watermark">
    <div class="row float-right">
      <div class="col-lg-12">
        <form [formGroup]="uploadForm">
          <input
            type="file"
            class="w-50 mr-4"
            name="file"
            formControlName="file1"
            placeholder="select file"
            (change)="fileEvent($event)"
          />
          <button class="btn btn-success" (click)="uploadFile()">Upload</button>
          <button
            class="btn btn-success fa fa-plus"
            data-toggle="modal"
            data-target="#myModal"
          >
            Folder
          </button>
        </form>
      </div>
    </div>

    <!-- modal for folder configuration -->
    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog modal-s" role="document">
        <div class="modal-content">
          <div class="modal-header" style="justify-content: unset !important">
            <h4 class="modal-title" id="myModalLabel">Create Folder</h4>
            <button
              type="button"
              class="close"
              style="right: 5px; position: absolute"
              data-dismiss="modal"
              (click)="closeForm('myModal')"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body pt-3 pb-3">
            <form [formGroup]="uploadForm" novalidate>
              <div
                class="form-group d-inline-flex col-lg-12 p-0"
                style="height: 30px; margin-bottom: 0"
              >
                <label class="label col-lg-3 p-0 text-left">
                  <span style="vertical-align: -webkit-baseline-middle"
                    >Folder Name
                  </span>
                </label>
                <div class="col-lg-9">
                  <input
                    class="form-control"
                    placeholder="enter folder name"
                    type="text"
                    [formControl]="uploadForm.controls['folderName']"
                    name="folderName"
                    [value]="folderName"
                    (keyup)="getFolderName($event)"
                    (change)="getFolderName($event)"
                  />
                </div>
              </div>
              <div
                class="form-group d-inline-flex col-lg-12 p-0"
                style="height: 30px; margin-bottom: 0"
              >
                <label class="label col-lg-3 p-0 text-left">
                  <span style="vertical-align: -webkit-baseline-middle"
                    >Upload document
                  </span>
                </label>
                <div class="col-lg-9">
                  <input
                    class="form-control"
                    placeholder="upload file"
                    type="file"
                    multiple
                    (change)="multipleFileEvent($event)"
                  />
                </div>
              </div>
              <div *ngFor="let file of uploadMultipleFileDocument">
                <span>{{ file.name }}</span>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              [disabled]="disabledOnclick"
              (click)="saveFolderNameWithFileUplaod()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
