<!-- START: components/tabs -->
<section class="card">
  <div class="card-header">
    <span class="cat__core__title">
      <strong>Data Import</strong>
    </span>
  </div>
  <div class="card-block">
    <div class="row">
      <div class="col-lg-4">
        <h5 class="text-black"><strong>Balance Sheet</strong></h5>
        <!--         <p class="text-muted">Description</p> -->
        <div>
          <input type="file" [(ngModel)]="BSFile" class="card-box-p" (change)="onChangeFile($event)">
          <button aria-expanded="false" class="btn btn-outline-primary btn1" data-toggle="dropdown" (click)="uploadBS()"
            style="padding:8px !important">Validate & Upload</button>
        </div>
        <a href="/" target="_blank">Download Template<small><i class="icmn-download ml-1">
            </i></small></a>
      </div>
      <div class="col-lg-4">
        <h5 class="text-black"><strong>Profit and Loss Account</strong></h5>
        <!--         <p class="text-muted">Description</p> -->
        <div>
          <input type="file" class="card-box-p">
          <button aria-expanded="false" class="btn btn-outline-primary btn1" data-toggle="dropdown"
            style="padding:8px !important">Validate & Upload</button>
        </div>
        <a href="/" target="_blank">Download Template<small><i class="icmn-download ml-1">
            </i></small></a>
      </div>
      <div class="col-lg-4">
        <h5 class="text-black"><strong>Financial Ratios</strong></h5>
        <!--         <p class="text-muted">Description</p> -->
        <div>
          <input type="file" class="card-box-p">
          <button aria-expanded="false" class="btn btn-outline-primary btn1" data-toggle="dropdown"
            style="padding:8px !important">Validate & Upload</button>
        </div>
        <a href="/" target="_blank">Download Template<small><i class="icmn-download ml-1">
            </i></small></a>
      </div>
    </div>
  </div>
</section>