import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { HasPermissionDirective } from '../directive/has-permission.directive';
import { SafeHtmlPipe } from './../pipe/safe-html.pipe'

@NgModule({
    imports:[NgSelectModule],
    declarations:[HasPermissionDirective, SafeHtmlPipe],
    exports:[HasPermissionDirective, SafeHtmlPipe, NgSelectModule]
})


export class SharedModule{}