
<div class="modal fade" id="messagePopUp" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" style="top: 50px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div id="alertTitle" class="modal-header" style="font-size: 20px; font-weight: bold; padding: 5px 15px;">
        Title!
      </div>
      <div class="modal-body" style="padding: 10px 15px;">
        <div id="alertMessage" style="font-size: 16px;">
          Message..
        </div>
      </div>
    </div>
  </div>
</div>