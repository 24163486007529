import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CompanyMasterService } from './../services/companymaster.service';
import { CommonFunction } from '../helpers/commonFunction';

@Injectable()
export class GfmService {

  constructor(private http: HttpClient, private httpWithoutToken: HttpClient, private companyMasterService: CompanyMasterService,
    public commonFunction: CommonFunction) { }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getAllFilesAndFolders(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, isCompliance): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    const url = gemAccessUrl + "gfm/getAllFilesAndFolders/" + isCompliance;
    // console.log("url", url)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, value) => { headers[key] = value };

    head('accept', '*/*');
    head('authentication-token', token);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('Content-Type', 'application/json');
    head('companyId', companyID);
    head('uniqueUserId', uniqueUserId);
    head('companyName', companyName);
    head('userId', userId);

    return this.httpWithoutToken.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  getAllFilesAndFoldersForSharedFolder(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, gfmRecordIdForFolder): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    const url = gemAccessUrl + "gfm/getAllFilesAndFoldersForSharedFolder/" + gfmRecordIdForFolder;
    // console.log("url", url)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.httpWithoutToken.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  isMeetingStarted(meetingId, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var token = localStorage.getItem('token');
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');

    const url = gemAccessUrl + "meetingAttendance/hasMeetingStarted/" + meetingId;
    const headerDict = {
      'accept': '*/*',
      'authentication-token': token,
      'companyId': companyID,
      'companyName': companyName,
      'complianceEngine': complianceEngine,
      'complianceEngineUrl': complianceEngineUrl,
      'governanceUrl': governanceUrl,
      'userId': userId,
      'Access-Control-Allow-Origin': '*',
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };

    return this.httpWithoutToken.get(url, requestOptions).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }


  getFilesAndFoldersAccordingToPath(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, gfmRecordIdForFolder): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    const url = gemAccessUrl + "gfm/getFilesAndFoldersAccordingToPath";
    console.log("url", url)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.httpWithoutToken.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  postFileUploadForGFM(fileObjectArray, recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;

    // const url = "http://52.66.23.123:8085/api/" + "gfm/uploadFiles";

    const url = gemAccessUrl + "gfm/uploadFiles";

    var governanceUrl = governanceUrl;


    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);

    let formData = new FormData();
    //formData.append("files", fileObject);
    for (let file of fileObjectArray) {
      formData.append('files', file);
    }
    formData.append("recordDetails", recordDetails)

    return this.http.post(url, formData, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      }, error => {
        console.log("error", error.status)
        return error.status
      })
      .catch(this.handleError);
  }

  postMultipleFileUploadForGFM(fileObject, recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/uploadFiles";
    const url = gemAccessUrl + "gfm/uploadFiles";

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };


    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);



    return this.http.post(url, fileObject, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      }, error => {
        console.log("error:", error.error.message)
        return error.status
      })
      .catch(this.handleError);
  }

  // for all folder operations
  createFolderForGFM(fileObject, recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/folderOperations";
    const url = gemAccessUrl + "gfm/folderOperations";
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.http.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  renameOrRemoveFiles(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, isComplianceFileParameter): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/renameOrRemoveFiles/false";
    const url = gemAccessUrl + "gfm/renameOrRemoveFiles/" + isComplianceFileParameter;

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.put(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  // get Tag Details By Tag Id
  getTagDetailsByTagId(tagId, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {


    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva')

    // localStorage.getItem('companyDetailsGoveva')
    // const url = "http://52.66.23.123:8085/api/" + "gfm/tag/get/" + tagId;
    const url = gemAccessUrl + "gfm/tag/get/" + tagId;

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.http.get(url, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
    //  return console.log("responseCompanyDetails",responseCompanyDetails);

  }


  createTag(TagName, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/tag/create";
    var url = gemAccessUrl + "gfm/tag/create";


    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    var object = {
      "companyId": companyID,
      "tagName": TagName,
      "uniqueUserId": uniqueUserId,
      "userId": userId
    }
    console.log("response----------")
    return this.http.post(url, object, { headers: headers }).toPromise()
      .then(response => {
        console.log("response----------", response)
        return Promise.resolve(response);
      }, error => {
        console.log("error", error.status)
        return error.status
      })
      .catch(this.handleError);

  }

  deleteTag(tagIdToRemove, TagName, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/tag/create";
    var url = gemAccessUrl + "gfm/tag/remove/" + tagIdToRemove;


    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    var object = {
      "companyId": companyID,
      "gfmTagId": tagIdToRemove,
      "tagName": TagName,
      "uniqueUserId": uniqueUserId,
      "userId": userId
    }
    console.log("response----------")
    return this.http.put(url, object, { headers: headers }).toPromise()
      .then(response => {
        console.log("response----------", response)
        return Promise.resolve(response);
      }, error => {
        console.log("error", error.status)
        return error.status
      })
      .catch(this.handleError);

  }


  // save/ assign tags to Files
  tagOperationsForFile(object, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/tagOperationsForFile";
    var url = gemAccessUrl + "gfm/tagOperationsForFile";


    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.post(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);


  }

  tagOperationsForFolder(object, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/tagOperationsForFile";
    var url = gemAccessUrl + "gfm/tagOperationsForFolder";


    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.post(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);


  }

  searchFilesAndFolders(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var complianceEngine;
    var complianceEngineUrl;
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID

    var companyName = localStorage.getItem('companyDetailsGoveva');

    complianceEngine = complianceEngine;
    complianceEngineUrl = complianceEngineUrl;

    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/searchFilesAndFolders";
    const url = gemAccessUrl + "gfm/searchFilesAndFolders";

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');

    return this.http.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  multipleRemovalOfFilesAndFolders(recordDetails, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/multipleRemovalOfFilesAndFolders";
    const url = gemAccessUrl + "gfm/multipleRemovalOfFilesAndFolders";

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.post(url, recordDetails, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  shareFile(listOfGFMRecordRequest, complianceEngine, complianceEngineUrl, gfmRecordId, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id.toString();
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId.toString();
    var userName = JSON.parse(localStorage.getItem('user')).FirstName.toString() + " " + JSON.parse(localStorage.getItem('user')).LastName.toString()
    var token = localStorage.getItem('token').toString();
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails')).toString();
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID.toString()
    var companyName = localStorage.getItem('companyDetailsGoveva').toString()
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;


    // const url = "http://52.66.23.123:8085/api/" + "gfm/shareFile/" + gfmRecordId + "?userName=" + userName;
    const url = gemAccessUrl + "gfm/shareFile/" + gfmRecordId + "?userName=" + userName;

    console.log("gfmRecordId", gfmRecordId)
    // console.log("url", url)
    console.log("userName", userName)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };


    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    //  head('userName',userName.toString())
    head('Content-Type', 'application/json');
    console.log("headers", headers)

    var object = {
      "allGFMRecords": listOfGFMRecordRequest
    }

    return this.http.put(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  shareFolder(listOfGFMRecordRequest, complianceEngine, complianceEngineUrl, gfmRecordId, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id.toString();
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId.toString();
    var userName = JSON.parse(localStorage.getItem('user')).FirstName.toString() + " " + JSON.parse(localStorage.getItem('user')).LastName.toString()
    var token = localStorage.getItem('token').toString();
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails')).toString();
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID.toString()
    var companyName = localStorage.getItem('companyDetailsGoveva').toString()
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;


    // const url = "http://52.66.23.123:8085/api/" + "gfm/shareFile/" + gfmRecordId + "?userName=" + userName;
    const url = gemAccessUrl + "gfm/shareFolder/" + gfmRecordId + "?userName=" + userName;

    console.log("gfmRecordId", gfmRecordId)
    // console.log("url", url)
    console.log("userName", userName)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    //  head('userName',userName.toString())
    head('Content-Type', 'application/json');
    console.log("headers", headers)

    var object = {
      "allGFMRecords": listOfGFMRecordRequest
    }

    return this.http.put(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  downloadGFMFile(object, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, isShared, gfmRecordId) {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/downloadFile/false";
    // var url = gemAccessUrl + "gfm/downloadFile/" + isShared;
    var url = gemAccessUrl + "gfm/downloadFile/" + gfmRecordId

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };
    console.log("object.idobject.id", object.id);
    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', object.id || uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.post(url, object, { headers: headers, responseType: 'blob' }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      }, error => {
        console.log("error:", error.message)
        return error.status
      })
      .catch(this.handleError);

  }

  downloadComplianceFile(object, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, isShared, isComplianceFileLink) {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id.toString();
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId.toString();
    var userName = JSON.parse(localStorage.getItem('user')).FirstName.toString() + " " + JSON.parse(localStorage.getItem('user')).LastName.toString()
    var firstName = JSON.parse(localStorage.getItem('user')).FirstName.toString();
    var lastName = JSON.parse(localStorage.getItem('user')).LastName.toString();
    var token = localStorage.getItem('token').toString();
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails')).toString();
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID.toString()
    var companyName = localStorage.getItem('companyDetailsGoveva').toString()
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    var userDetails = {
      "firstName": firstName,
      "lastName": lastName,
      "uniqueUserId": uniqueUserId
    }
    var arr = [];
    arr.push(userDetails);
    console.log("userDetails", userDetails)
    // var url = "http://52.66.23.123:8085/api/" + "gfm/downloadFile/false";
    var url = isComplianceFileLink;

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    // head('accept', '*/*');
    // head('Access-Control-Allow-Origin', '*');
    // head('authentication-token', token);
    // head('companyId', companyID);
    // head('companyName', companyName);
    // head('complianceEngine', complianceEngine);
    // head('complianceEngineUrl', complianceEngineUrl);
    // head('governanceUrl', governanceUrl);
    // head('govevacompany', companyName);
    // head('uniqueUserId', uniqueUserId);
    // head('userId', userId);
    // // head('userDetails', arr);
    // head('Content-Type', 'application/json');

    head('accept', '*/*');
    // head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');
    head('govevacompany', companyName);
    console.log(headers);

    // window.location.href =  url;
    // console.log("headers",headers)
    // return Promise.resolve()

    const options = { headers, responseType: 'blob' as 'blob' };

    return this.http.get(url, options).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);


  }

  downloadZipFileById(object, complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl, isShared, gfmRecordId) {

    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID
    var companyName = localStorage.getItem('companyDetailsGoveva');


    // var url = "http://52.66.23.123:8085/api/" + "gfm/downloadFile/false";
    var url = gemAccessUrl + "gfm/downloadZipFileById/" + gfmRecordId;

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    head('Content-Type', 'application/json');


    return this.http.get(url, { headers: headers, responseType: 'blob' }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);

  }


  getAllGFMTags(complianceEngine, complianceEngineUrl, governanceUrl, gemAccessUrl): Promise<any> {
    var complianceEngine;
    var complianceEngineUrl;
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id;
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId
    var token = localStorage.getItem('token');
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID

    var companyName = localStorage.getItem('companyDetailsGoveva');

    complianceEngine = complianceEngine;
    complianceEngineUrl = complianceEngineUrl;

    // this.companyMasterService.getCompanyList().then(company => {
    //   if (company.status == 0) {
    //     complianceEngine = company.companyData.ComplianceEngine;
    //     complianceEngineUrl = company.companyData.ComplianceEngineUrl;
    //   } else {
    //     console.log("company data blank")
    //   }
    // })

    // const url = "http://52.66.23.123:8085/api/" + "gfm/searchFilesAndFolders";
    const url = gemAccessUrl + "gfm/tag/getAll";

    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    // headers = head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    // headers = head('Content-Type', 'application/json');

    return this.http.get(url, { headers: headers }).toPromise().then(response => {
      return Promise.resolve(response);
    })
      .catch(this.handleError);
  }

  fileRemovalBySharedUser(complianceEngine, complianceEngineUrl, gfmRecordId, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id.toString();
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId.toString();
    var userName = JSON.parse(localStorage.getItem('user')).FirstName.toString() + " " + JSON.parse(localStorage.getItem('user')).LastName.toString()
    var token = localStorage.getItem('token').toString();
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails')).toString();
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID.toString()
    var companyName = localStorage.getItem('companyDetailsGoveva').toString()
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    console.log("complianceEngine---------", complianceEngine)

    // const url = "http://52.66.23.123:8085/api/" + "gfm/shareFile/" + gfmRecordId + "?userName=" + userName;
    const url = gemAccessUrl + "gfm/fileRemovalBySharedUser/" + gfmRecordId

    console.log("gfmRecordId", gfmRecordId)
    // console.log("url", url)
    console.log("userName", userName)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    //  head('userName',userName.toString())
    head('Content-Type', 'application/json');
    console.log("headers", headers)

    var object = {

    }

    return this.http.put(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  folderRemovalBySharedUser(complianceEngine, complianceEngineUrl, gfmRecordId, governanceUrl, gemAccessUrl): Promise<any> {
    var userInfo = JSON.parse(localStorage.getItem('user'));
    var userId = JSON.parse(localStorage.getItem('user'))._id.toString();
    var uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId.toString();
    var userName = JSON.parse(localStorage.getItem('user')).FirstName.toString() + " " + JSON.parse(localStorage.getItem('user')).LastName.toString()
    var token = localStorage.getItem('token').toString();
    var companyInfo = JSON.parse(localStorage.getItem('companyDetails')).toString();
    var companyID = JSON.parse(localStorage.getItem('companyDetails')).companyID.toString()
    var companyName = localStorage.getItem('companyDetailsGoveva').toString()
    var complianceEngine = complianceEngine;
    var complianceEngineUrl = complianceEngineUrl;
    console.log("complianceEngine---------", complianceEngine)

    // const url = "http://52.66.23.123:8085/api/" + "gfm/shareFile/" + gfmRecordId + "?userName=" + userName;
    const url = gemAccessUrl + "gfm/folderRemovalBySharedUser/" + gfmRecordId

    console.log("gfmRecordId", gfmRecordId)
    // console.log("url", url)
    console.log("userName", userName)
    var governanceUrl = governanceUrl;
    let headers = {};
    let head = (key, header) => { headers[key] = header };

    head('accept', '*/*');
    head('Access-Control-Allow-Origin', '*');
    head('authentication-token', token);
    head('companyId', companyID);
    head('companyName', companyName);
    head('complianceEngine', complianceEngine);
    head('complianceEngineUrl', complianceEngineUrl);
    head('governanceUrl', governanceUrl);
    head('uniqueUserId', uniqueUserId);
    head('userId', userId);
    //  head('userName',userName.toString())
    head('Content-Type', 'application/json');
    console.log("headers", headers)

    var object = {

    }

    return this.http.put(url, object, { headers: headers }).toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }
}
