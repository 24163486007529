<!-- START: components/calendar -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Calendar</strong>
            <a href="http://fullcalendar.io/" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="example-calendar-block"></div>
    </div>
</section>
<!-- END: components/calendar -->
