<style>
    .custom-tooltip {
        position: relative;
        display: inline-block;
    }

    .custom-tooltiptext {
        visibility: hidden;
        width: 200px;
        border: 1px solid #514d6a;
        background-color: #FFF;
        color: #514d6a;
        font-family: Calibri;
        border-radius: 3px;
        padding: 5px;
        position: absolute;
        z-index: 1;
    }

    .custom-tooltip:hover .custom-tooltiptext {
        visibility: visible;
    }
    .lastLoginTime{
        font-size: 12px;
        display: none
    }
    .lastLoginTime a{
        font-size: 14px;
    }
    .m-t-0{
        margin-top:0px;
        
    }
    .cat__menu-left__logo{
        background-color:#d9efee!important;
        padding: 12px 0;
    }

    .customMenuLeftInActive > a {
        font-size: 16px;
        font-weight: normal;
        background-color: transparent;
        color: #000;
        position: relative;
        min-height: 3.21rem;
        padding: 0.85rem 3.57rem 0.85rem 1.42rem;
        display: block;
        cursor: pointer !important;
        text-align: -webkit-match-parent;
        list-style: none;
        font-family: Segoe UI;
        line-height: 1.5;
    }
    .customMenuLeftInActive:hover > a {
        font-size: 16px;
        font-weight: normal;
        background: #07a7a5 !important;
        color: #FFF !important;
        position: relative;
        min-height: 3.21rem;
        padding: 0.85rem 3.57rem 0.85rem 1.42rem;
        display: block;
        cursor: pointer !important;
        text-align: -webkit-match-parent;
        list-style: none;
        font-family: Segoe UI;
        line-height: 1.5;
    }
    .customMenuLeftInActive > a::after{
        border-width: 0.35rem 0.35rem 0.35rem 0.35rem;
        border-color: #ec0c0c00 #06828000 #0014ff00 #000;
        box-sizing: inherit;
        display: block;
        content: '';
        border-style: solid;
        position: absolute;
        top: 1.21rem;
        right: 3.85rem;

    }
    .customMenuLeftInActive:hover > a::after{
        border-width: 0.35rem 0.35rem 0.35rem 0.35rem;
        border-color: #ec0c0c00 #06828000 #0014ff00 #FFF;
        box-sizing: inherit;
        display: block;
        content: '';
        border-style: solid;
        position: absolute;
        top: 1.21rem;
        right: 3.85rem;

    }

    

    


    .customMenuLeftActive > a{
        font-size: 16px;
        font-weight: normal;
        background: #37a7a5;
        color: #ffffff;
        position: relative;
        min-height: 3.21rem;
        padding: 0.85rem 3.57rem 0.85rem 1.42rem;
        display: block;
        cursor: pointer !important;
        text-align: -webkit-match-parent;
        list-style: none;
        font-family: Segoe UI;
        line-height: 1.5;
        transition: all 2s linear;
    }


    

    

    .customMenuLeftActive > a::after{
        border-width: 0.35rem 0.35rem 0.35rem 0.35rem;
        border-color: #ec0c0c00 #06828000 #0014ff00 #FFF;
        transform: rotate(-90deg);
        box-sizing: inherit;
        display: block;
        content: '';
        border-style: solid;
        position: absolute;
        top: 1.21rem;
        right: 3.85rem;

    }


    .customMenuLeftInActiveSubMenu > a {
        font-size: 16px;
        font-weight: normal;
        background-color: transparent;
        color: #FFF;
        position: relative;
        min-height: 3.21rem;
        padding: 0.85rem 3.57rem 0.85rem 1.42rem;
        display: block;
        cursor: pointer !important;
        text-align: -webkit-match-parent;
        list-style: none;
        font-family: Segoe UI;
        line-height: 1.5;
    }

    .customMenuLeftInActiveSubMenu > a::after{
        border-width: 0.35rem 0.35rem 0.35rem 0.35rem;
        border-color: #ec0c0c00 #06828000 #0014ff00 #FFF;
        box-sizing: inherit;
        display: block;
        content: '';
        border-style: solid;
        position: absolute;
        top: 1.21rem;
        right: 3.85rem;

    }
    

    @media screen and (max-width:991px) {
        .lastLoginTime{
        display: block;
     }
    }
</style>
<nav class="cat__menu-left">
    <div class="cat__menu-left__lock cat__menu-left__action--menu-toggle">
        <i class="fa fa-times closebtn" (click)="toggleNav()" aria-hidden="true"></i>
    </div>
    <div class="cat__menu-left__logo">
        <a href="/#/structure/(structure-outlet:dashboards/alpha)">
            <img src="../../../assets/modules/dummy-assets/common/img/GovEVA.png" style="max-height: 2.9rem;" alt="GovEVA" />
        </a>
    </div>
    <div class="cat__menu-left__inner">

        <ul class="cat__menu-left__list cat__menu-left__list--root m-t-0">
            <li class="cat__menu-left__item cat__menu-left--colorful--secondary lastLoginTime">    <a href="javascript:void();">       <b>Last Login: {{lastLoginDetails.lastLoginAttempt ? "Successful" : "UnSuccessful"}} on {{lastLoginDetails.lastloginTime ? (lastLoginDetails.lastloginTime | date :'dd-MM-yyyy hh:mm:ss a') : ""}}</b></a> 
            </li>
            <li  
                [ngClass]="{'customMenuLeftInActive': menuName !== 'Master', 'customMenuLeftActive': menuName === 'Master'}"
                *ngIf="masterMenuEnable && FinalMenu.Master">
                <a href="javascript: void(0);" (click)="activeMenu('Master')">
                    Masters
                </a>
                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName == 'Master' ? 'block' : 'none'}">
                    <li class="cat__menu-left__item "
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Company'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'CompanyMaster'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'companymaster'}}]" class="subitem" (click)="activeMenu('Company')">
                            <span class="form-control-label custom-tooltip">
                                Company
                                <span class="custom-tooltiptext">
                                    A single source of common business data that will be across application for an
                                    entire enterprise
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Role'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'RoleMaster'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'role-master/roleMaster'}}]" class="subitem"
                           (click)="activeMenu('Role')">

                            <span class="form-control-label custom-tooltip">
                                Roles
                                <span class="custom-tooltiptext" style="left: 0;top: 34px;">
                                    Different employees of the company who will be using the GovEVA software and extent
                                    of powers assigned to them accordingly.
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'User'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'UserMaster'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'userMaster/userManagement'}}]" class="subitem"
                            (click)="activeMenu('User')">

                            <span class="form-control-label custom-tooltip">
                                Users
                                <span class="custom-tooltiptext" style="left: 0;top: 34px;">
                                    List of different employees of the company who will be the end users of the
                                    software/application.
                                </span>
                            </span>
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Individual Meeting'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'individual-meeting'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'individual-meeting'}}]" class="subitem"
                            (click)="activeMenu('Individual Meeting')">

                            <span class="form-control-label custom-tooltip">
                                Users
                                <span class="custom-tooltiptext" style="left: 0;top: 34px;">
                                    List of different employees of the company who will be the end users of the
                                    software/application.
                                </span>
                            </span>
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('upsimaster')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'upsimaster'}"  [appHasPermission]="{'Url': '', 'method':'', 'menu': 'UPSI Master'}">
                        <a href="{{menuLinkObject.UPSIMaster}}" class="subitem">
                           UPSI Master 
                        </a>
                    </li>
                    <li class="cat__menu-left__item" (click)="activeMenu('upsimaster')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'upsimaster'}"  [appHasPermission]="{'Url': '', 'method':'', 'menu': 'UPSI Parameter'}" >
                        <a  href="{{menuLinkObject.UPSIParam}}" class="subitem">
                            UPSI Parameter
                        </a>
                    </li>

                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Company Financial Details'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig) && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'CompanyFinancialDetails'}">
                        <a href="{{menuLinkObject.CompanyFinancialDetails}}" class="subitem" (click)="activeMenu('Company Financial Details')">
                            Company Financial Details
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Committee'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Committee'}">
                        <a href="{{menuLinkObject.Commitee}}" class="subitem" (click)="activeMenu('Committee')">
                            Committee
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('branch')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'branch'}">
                        <a href="{{menuLinkObject.branch}}" class="subitem">
                            Branch
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('department')"  [ngClass]="{'cat__menu-left__item--active': menuName === 'department'}">
                        <a href="{{menuLinkObject.department}}" class="subitem">
                            Department
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('Tag')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'Tag'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Tag'}">
                        <a href="{{menuLinkObject.Group}}" class="subitem">
                            Tag
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Category'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig) && !MeetingMakerWithoutAgenda"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Category'}">
                        <a href="{{menuLinkObject.AgendaCategories}}" class="subitem" (click)="activeMenu('Category')">
                            Agenda Categories
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('userteam')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'userteam'}">
                        <a href="{{menuLinkObject.userteam}}" class="subitem">
                            <i class="cat__menu-left__icon fa fa-tags"></i>
                            User Team Master
                        </a>
                    </li>

                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Holiday'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig) && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Holiday'}">
                        <a href="{{menuLinkObject.Holiday}}" class="subitem" (click)="activeMenu('Holiday')">
                            Holiday
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'agendaGroup'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig) && !MeetingMakerWithoutAgenda"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'AgendaGroup'}">
                        <a href="{{menuLinkObject.agendaGroup}}" class="subitem" (click)="activeMenu('agendaGroup')">
                            Agenda Group
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'defaultMeeting'}" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig) && !MeetingMakerWithoutAgenda"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'DefaultMeeting'}">
                        <a href="{{menuLinkObject.defaultMeeting}}" class="subitem" (click)="activeMenu('defaultMeeting')">
                            Default Meeting Details



                        </a>
                    </li>
                    <li class="cat__menu-left__item"  
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'NCD Document'}"  
                        *ngIf="!isSuperAdmin && (isNCDConfig ) ">
                        <a class="subitem" href="{{menuLinkObject.NCDDocumentGenerationSetup}}">
                            <span class="form-control-label custom-tooltip">
                                NCD Document Generation Master Setup 
                            </span>
                        </a>
                    </li>

                      
                    <li class="cat__menu-left__item" (click)="activeMenu('Meetings')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'Meetings'}"
                    [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Meetings'}">
                    <a href="{{menuLinkObject.MeetingEmailTemplates}}" class="subitem">
                        Meeting Email Templates
                    </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('TaskAndActivities')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'Task and Activities'}"
                    [appHasPermission]="{'Url': '', 'method':'', 'menu': 'LibraryTask'}">
                    <a href="{{menuLinkObject.emailReminderTemplatesTask}}" class="subitem">
                        Task Email Templates
                    </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('Email Template UPSI')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'Email Template UPSI'}"
                    [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Email Template'}">
                    <a href="{{menuLinkObject.emailReminderTemplatesUpsi}}" class="subitem">
                        UPSI Email Templates
                    </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('ncd')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda) && (!isSuperAdmin && (isNCDConfig ) && ncdMenu)" [ngClass]="{'cat__menu-left__item--active': menuName === 'ncd'}">
                    <a href="{{menuLinkObject.emailReminderTemplatesNcd}}" class="subitem">
                        NCD Email Templates
                    </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('Meetings')" *ngIf="(isAllConfig || isGovevaConfig || isPSSConfig)  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)" [ngClass]="{'cat__menu-left__item--active': menuName === 'Meetings'}"
                    [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Meetings'}">
                    <a href="{{menuLinkObject.ComplianceCertificateEmailTemplates}}" class="subitem">
                        Compliance Certificate Email Templates
                    </a>
                    </li>

                </ul>
            </li>

            <!-- Governance -->
            

            <!-- <li [ngClass]="{'customMenuLeftInActive': menuName !== 'Board Entity', 'customMenuLeftActive': menuName === 'Board Entity'}"
                *ngIf="boardEvaluationMenuEnable && (isBoardEvaluationConfig)">

                <a *ngIf="isGovernance" (click)="activeMenu('Board Entity')" href="javascript: void(0);">
                    Materiality
                </a>
                <a *ngIf="!isGovernance" (click)="activeMenu('Board Entity')" href="javascript: void(0);">
                    Evaluation
                </a>                
                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName == 'Board Entity' ? 'block' : 'none'}">

                    <li class="cat__menu-left__item cat__menu-left__submenu " *ngIf="isEvaluationMasterEnable"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'BoardEvaluationMaster'}">
                        <a (click)="activeMenu('BoardEvaluationMaster')" class="subitem">
                            Evaluation Master
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list">
                            <li class="cat__menu-left__item" (click)="activeMenu('Evaluation Entity')"
                                [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationType/getAllEvaluationType', 'method':'get', 'menu': 'EvaluationType'}"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Evaluation Entity'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'entityMaster'}}]" class="subitem">
                                    Entity
                                </a>
                            </li>


                            <li class="cat__menu-left__item" (click)="activeMenu('Evaluate Criteria')"
                                [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationCriteria/getAllEvaluationCriteria', 'method':'get', 'menu': 'EvaluationCriteria'}"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Evaluate Criteria'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'evaluateCategory'}}]" class="subitem">
                                    Criteria
                                </a>
                            </li>

                            <li class="cat__menu-left__item"
                                [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationQuestion/getAllEvaluationQuestion', 'method':'get', 'menu': 'EvaluationQuestion'}"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'QuestionMaster'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'questionMasterBoardEval'}}]"
                                    class="subitem" (click)="activeMenu('QuestionMaster')">
                                    <span class="form-control-label custom-tooltip">
                                        Question Master
                                    </span>
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('Evaluate Questionnaire Setup')"
                                [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationFormSetup/getAllEvaluationFormSetup', 'method':'get', 'menu': 'EvaluationFormSetup'}"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Evaluate Questionnaire Setup'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'evaluateFormSetup'}}]" class="subitem">
                                    Questionnaire Setup
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('EvaluationQuestionnaire')"
                        [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationBank/viewboardEvaluationBank', 'method':'get', 'menu': 'QuestionnaireBank'}"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'EvaluationQuestionnaire'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'evaluationForms'}}]" class="subitem">
                            Questionnaire Bank
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('QuestionnaireReview')"
                        [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationFormReview/getAllEvaluationFormReview', 'method':'get', 'menu': 'EvaluationFormReview'}"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'QuestionnaireReview'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'evaluationReview'}}]" class="subitem">
                            Questionnaire Review
                        </a>
                    </li>


                    <li class="cat__menu-left__item" (click)="activeMenu('Evaluation Monitoring')"
                        [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationFormMonitoring/getAllEvaluationFormMonitoring', 'method':'get', 'menu': 'EvaluationFormMonitoring'}"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Evaluation Monitoring'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'evaluationMonitoring'}}]" class="subitem">
                            Evaluation Management And Monitoring
                        </a>
                    </li> 

                    <li *ngIf="EvaluationManagementAndMonitoringMenuEnable" class="cat__menu-left__item" (click)="activeMenu('Evaluation Monitoring')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Evaluation Monitoring'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'evaluationMonitoring'}}]" class="subitem"
                            style="color: white">
                            Management And Monitoring
                        </a>
                    </li>

                    

                    <li class="cat__menu-left__item" (click)="activeMenu('assignedEvaluationFormList')"
                        [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationFormExecution/getAllEvaluationFormExecution', 'method':'get', 'menu': 'EvaluationFormExecution'}"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'assignedEvaluationFormList'}">
                        <a [routerLink]="[{outlets:{'structure-outlet':'evaluationFormList'}}]" class="subitem">
                            Answer and Submission
                        </a>
                    </li>

                    <li class="cat__menu-left__item cat__menu-left__submenu"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Reports'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'EvaluationReports'}">
                        <a (click)="activeMenu('Reports')" class="subitem">
                            Reports
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list">

                            <li class="cat__menu-left__item"  [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationReports/getDetailedReport', 'method':'get', 'menu': 'EvaluationReports'}"
                             [ngClass]="{'cat__menu-left__item--active': menuName === 'Individual Report List'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'individualReportList'}}]" class="subitem">
                                    <span class="form-control-label custom-tooltip">
                                        Detailed Report
                                    </span>
                                </a>
                            </li>
                            <li class="cat__menu-left__item"
                                [appHasPermission]="{'Url': '/api/v1/secure/boardEvaluationReports/getSummaryReport', 'method':'get', 'menu': 'EvaluationReports'}"
                                [ngClass]="{'cat__menu-left__item--active': menuName === ' Summary Report'}">
                                <a [routerLink]="[{outlets:{'structure-outlet':'summaryReport'}}]" class="subitem">
                                    <span class="form-control-label custom-tooltip">
                                        Summary Report
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>              -->
            
            <li 
                [ngClass]="{'customMenuLeftInActive': menuName !== 'Compliance', 'customMenuLeftActive': menuName === 'Compliance'}"
                *ngIf="complianceMenuEnable && (isAllConfig || isPSSConfig) && FinalMenu.Complaince">
                <a href="javascript: void(0);" 
                (click)="activeMenu('Compliance')">

                    Compliance 
                </a>
                

                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : isComplianceMenuActive(menuName) ? 'block' : 'none'}">
                    <li class="cat__menu-left__item"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Meetings'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Meetings'}">
                        <a href="{{menuLinkObject.Meetings}}" class="subitem" 
                            (click)="activeMenu('Meetings')">
                            Meetings
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Agenda'}" *ngIf="!MeetingMakerWithoutAgenda">
                        <a href="{{menuLinkObject.AgendaSearch}}" class="subitem" (click)="activeMenu('Agenda')" [appHasPermission]="{'Url': '', 'method':'get', 'menu': 'Agenda'}">
                            Agenda Search
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Templates'}" *ngIf="!MeetingMakerWithoutAgenda">
                        <a href="{{menuLinkObject.Templates}}" class="subitem" (click)="activeMenu('Templates')" [appHasPermission]="{'Url': '', 'method':'get', 'menu': 'Templates'}">
                            Templates
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'Agenda'}" *ngIf="!MeetingMakerWithoutAgenda">
                        <a href="{{menuLinkObject.Agenda}}" class="subitem" (click)="activeMenu('Agenda')" [appHasPermission]="{'Url': '', 'method':'get', 'menu': 'Agenda'}">
                            Agenda
                        </a>
                    </li>
                    <li 
                        [ngClass]="{'customMenuLeftInActiveSubMenu': menuName !== 'TaskAndActivities', 'customMenuLeftActive': menuName === 'TaskAndActivities'}"
                        *ngIf="taskAndActivityEnable  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)">
                        <a (click)="activeMenu('TaskAndActivities')">

                            Task
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName ==  'TaskAndActivities' ? 'block' : 'none'}">

                            <li class="cat__menu-left__item" (click)="activeMenu('Library Task List')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Library Task List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'LibraryTask'}">
                                <a href="{{menuLinkObject.LibraryTaskList}}" class="subitem" >
                                    Library Task List
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('Activated Task List')"
                            [ngClass]="{'cat__menu-left__item--active': menuName === 'Activated Task List'}"
                            [appHasPermission]="{'Url': '', 'method':'', 'menu': 'ActivatedTaskAll'}">
                            <a href="{{menuLinkObject.ActivatedTaskList}}" class="subitem">
                                Activated Task List
                            </a>
                        </li>
                        </ul>
                    </li>
                    <li  [ngClass]="{'customMenuLeftInActiveSubMenu': menuName !== 'Process', 'customMenuLeftActive': menuName === 'Process'}" *ngIf="processEnable  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)">
                        <a (click)="activeMenu('Process')">
                            Process
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName ==  'Process' ? 'block' : 'none'}">
                            <li class="cat__menu-left__item" (click)="activeMenu('Process List')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Process List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Process'}">
                                <a href="{{menuLinkObject.ProcessList}}" class="subitem">
                                    Library Process List
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('Activited Process Tasks')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Activited Process Tasks'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'ActivatedProcessTasks'}">
                                <a href="{{menuLinkObject.ActivatedProcessTasks}}" class="subitem">
                                    Activated Process List
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li 
                        [ngClass]="{'customMenuLeftInActiveSubMenu': menuName !== 'Certificate', 'customMenuLeftActive': menuName === 'Certificate'}"
                        *ngIf="true">
                        <a href="javascript: void(0);" (click)="activeMenu('Certificate')">
                            Compliance Certificate
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName ==  'Certificate' ? 'block' : 'none'}">
                            
                           <li class="cat__menu-left__item" (click)="activeMenu('Certificate')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'process'}"
                                *ngIf="true">
                                <a class="subitem" [routerLink]="['complianceItem']">
                                    Compliance Item
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('Certificate')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'process'}"
                                *ngIf="true">
                                <a class="subitem" [routerLink]="['complianceCertificateList']">
                                    Compliance Certificate Library
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('Certificate')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'activatedProcesses'}"
                                *ngIf="true">
                                <a class="subitem" [routerLink]="['activatedComplianceCertificate']">
                                    <span class="cat__menu-left__icon"></span>
                                    Activated Compliance Certificate
                                </a>
                            </li>

                        </ul>
                    </li>

                    <!-- <li class="cat__menu-left__item cat__menu-left__submenu"
[ngClass]="{'cat__menu-left__item--active': menuName === 'Process'}" *ngIf="processEnable  && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)">
<a (click)="activeMenu('Process')">
    Compliance Certificate
</a>
<ul class="cat__menu-left__list sub-menu-list">
    <li class="cat__menu-left__item" (click)="activeMenu('Process List')"
        [ngClass]="{'cat__menu-left__item--active': menuName === 'Process List'}"
        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Process'}">
        <a href="{{menuLinkObject.ComplianceItem}}" class="subitem">
            Compliance Item
        </a>
    </li>
    <li class="cat__menu-left__item" (click)="activeMenu('Activited Process Tasks')"
        [ngClass]="{'cat__menu-left__item--active': menuName === 'Activited Process Tasks'}"
        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'ActivatedProcessTasks'}">
        <a href="{{menuLinkObject.ComplianceCertificateLibrary}}" class="subitem">
            Compliance Certificate Library
        </a>
    </li>
    <li class="cat__menu-left__item" (click)="activeMenu('Activited Process Tasks')"
        [ngClass]="{'cat__menu-left__item--active': menuName === 'Activited Process Tasks'}"
        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'ActivatedProcessTasks'}">
        <a href="{{menuLinkObject.ActivatedComplianceCertificate}}" class="subitem">
            Activated Compliance Certificate
        </a>
    </li>
</ul>
</li> -->

                    <li [ngClass]="{'customMenuLeftInActiveSubMenu': menuName !== 'Register', 'customMenuLeftActive': menuName === 'Register'}"
                        *ngIf="registerComplianceUser">
                        <a (click)="activeMenu('Register')">

                            Register
                        </a>
                        <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName ==  'Register' ? 'block' : 'none'}" *ngIf="(!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)">
                            <li class="cat__menu-left__item" (click)="activeMenu('Director')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Director'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Director'}">
                                <a href="{{menuLinkObject.Director}}" class="subitem">
                                    Director
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('KMP')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'KMP'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'KMP'}">
                                <a href="{{menuLinkObject.KMP}}" class="subitem">
                                    KMP
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mgt1list')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mgt1list'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mgt1list'}">
                                <a href="{{menuLinkObject.mgt1list}}" class="subitem">
                                    MGT - 1
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mgt2List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mgt2List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mgt2List'}">
                                <a href="{{menuLinkObject.mgt2list}}" class="subitem">
                                    MGT - 2
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('Ben3')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Ben3'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Ben3'}">
                                <a href="{{menuLinkObject.ben3list}}" class="subitem">
                                    BEN - 3
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('sh2List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'sh2List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'sh2List'}">
                                <a href="{{menuLinkObject.sh2list}}" class="subitem">
                                    SH - 2
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('sh3List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'sh3List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'sh3List'}">
                                <a href="{{menuLinkObject.sh3list}}" class="subitem">
                                    SH - 3
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mastersh6')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mastersh6'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mastersh6'}">
                                <a href="{{menuLinkObject.mastersh6}}" class="subitem">
                                    SH - 6
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mastersh10list')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mastersh10list'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mastersh10list'}">
                                <a href="{{menuLinkObject.mastersh10list}}" class="subitem">
                                    SH - 10
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('depositorList')" [ngClass]="{'cat__menu-left__item--active': menuName === 'depositorList'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'depositorList'}">
                                <a href="{{menuLinkObject.depositorList}}" class="subitem">
                                    Deposit
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('chg7List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'chg7List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'chg7List'}">
                                <a href="{{menuLinkObject.chg7List}}" class="subitem">
                                    CHG - 7
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mbp2List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mbp2List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mbp2List'}">
                                <a href="{{menuLinkObject.mbp2List}}" class="subitem">
                                    MBP - 2
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mbp3List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mbp3List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mbp3List'}">
                                <a href="{{menuLinkObject.mbp3List}}" class="subitem">
                                    MBP - 3
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mbp4List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mbp4List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mbp4List'}">
                                <a href="{{menuLinkObject.mbp4List}}" class="subitem">
                                    MBP - 4A
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('mbp4List')" [ngClass]="{'cat__menu-left__item--active': menuName === 'mbp4List'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'mbp4bList'}">
                                <a href="{{menuLinkObject.mbp4bList}}" class="subitem">
                                    MBP - 4B
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('Inspection')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Inspection'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Inspection'}">
                                <a href="{{menuLinkObject.inspectionList}}" class="subitem">
                                   Inspection
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('proxy')" [ngClass]="{'cat__menu-left__item--active': menuName === 'proxy'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Proxy'}">
                                <a href="{{menuLinkObject.proxyList}}" class="subitem">
                                   Proxy
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('masterbankparticulars')" [ngClass]="{'cat__menu-left__item--active': menuName === 'masterbankparticulars'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'masterbankparticulars'}">
                                <a href="{{menuLinkObject.masterbankparticulars}}" class="subitem">
                                    Bank Account Particulars
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('powerofattorney')" [ngClass]="{'cat__menu-left__item--active': menuName === 'powerofattorney'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'powerofattorney'}">
                                <a href="{{menuLinkObject.powerofattorneylist}}" class="subitem">
                                   Power of Attorney
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('paymentOfDividend')" [ngClass]="{'cat__menu-left__item--active': menuName === 'paymentOfDividend'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'paymentOfDividend'}">
                                <a href="{{menuLinkObject.paymentOfDividendList}}" class="subitem">
                                   Payment of Dividend
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('investment')" [ngClass]="{'cat__menu-left__item--active': menuName === 'investment'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'investment'}">
                                <a href="{{menuLinkObject.investmentlist}}" class="subitem">
                                   Investments
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('ecslist')" [ngClass]="{'cat__menu-left__item--active': menuName === 'ecslist'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'ecslist'}">
                                <a href="{{menuLinkObject.ecslist}}" class="subitem">
                                  Electronic Clearing Service (ECS)
                                </a>
                            </li>

                            <li class="cat__menu-left__item" (click)="activeMenu('commonSeal')" [ngClass]="{'cat__menu-left__item--active': menuName === 'commonSeal'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'commonSeal'}">
                                <a href="{{menuLinkObject.documentundercommonseallist}}" class="subitem">
                                   Documents Executed under Common Seal
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('Masterinvestorcomplaints')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Masterinvestorcomplaints'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Masterinvestorcomplaints'}">
                                <a href="{{menuLinkObject.masterinvestorcomplaints}}" class="subitem">
                                   Investor Complaints
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('dividendMandateList')" [ngClass]="{'cat__menu-left__item--active': menuName === 'dividendMandateList'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'dividendMandateList'}">
                                <a href="{{menuLinkObject.dividendMandateList}}" class="subitem">
                                   Dividend Mandate
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('postallballotlist')" [ngClass]="{'cat__menu-left__item--active': menuName === 'postallballotlist'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'postallballotlist'}">
                                <a href="{{menuLinkObject.postallballotlist}}" class="subitem">
                                   Postal Ballot
                                </a>
                            </li>

                        </ul>

                        <ul class="cat__menu-left__list sub-menu-list" *ngIf="(MeetingMakerWithoutAgenda || MeetingMakerWithAgenda)">
                            <li class="cat__menu-left__item" (click)="activeMenu('Director')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'Director'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Director'}">
                                <a href="{{menuLinkObject.Director}}" class="subitem">
                                    Director
                                </a>
                            </li>
                            <li class="cat__menu-left__item" (click)="activeMenu('KMP')"
                                [ngClass]="{'cat__menu-left__item--active': menuName === 'KMP'}"
                                [appHasPermission]="{'Url': '', 'method':'', 'menu': 'KMP'}">
                                <a href="{{menuLinkObject.KMP}}" class="subitem">
                                    KMP
                                </a>
                            </li>
                          

                        </ul>
                    </li>

                </ul>
            </li>


            <!-- UPSI -->
            <li [ngClass]="{'customMenuLeftInActive': menuName !== 'upsi', 'customMenuLeftActive': menuName === 'upsi'}"
                *ngIf="!isSuperAdmin && (isUPSIConfig ) && upsiMenu && FinalMenu.UPSI">
                <a href="javascript: void(0);" (click)="activeMenu('upsi')">
                    UPSI
                </a>

                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName == 'upsi' ? 'block' : 'none'}">
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'upsiSharer'}" [appHasPermission]="{'Url': '', 'method':'', 'menu': 'UPSI Sharer'}">
                        <a href="{{menuLinkObject.UPSISharer}}" class="subitem" (click)="activeMenu('upsiSharer')">
                            UPSI Sharer
                        </a>
                    </li>

                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'upsiRecipient'}" [appHasPermission]="{'Url': '', 'method':'', 'menu': 'UPSI Recipient'}">
                        <a href="{{menuLinkObject.UPSIRecipient}}" class="subitem" (click)="activeMenu('upsiRecipient')">
                            UPSI Recipient
                        </a>
                    </li>

                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'upsiDetails'}" *ngIf="UPSIDetailMenuEnable">
                        <a href="{{menuLinkObject.UPSIDetails}}" class="subitem" (click)="activeMenu('upsiDetails')">
                            UPSI Details
                        </a>
                    </li>
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'upsibulkdata'}" *ngIf="UPSIBulkDataMenuEnable">
                        <a href="{{UPSIBulkData}}" class="subitem" style="color: white" (click)="activeMenu('upsibulkdata')">
                            UPSI Bulk Data
                        </a>
                    </li>                    
                    <li class="cat__menu-left__item" [ngClass]="{'cat__menu-left__item--active': menuName === 'upsiEmailsLog'}">
                        <a href="{{menuLinkObject.UPSIEmailLog}}" class="subitem" (click)="activeMenu('upsiEmailsLog')">
                            UPSI Email Log
                        </a>
                    </li>
                    <li class="cat__menu-left__item" *ngIf="isUPSIConfig && upsiEmailMenuEnable" [ngClass]="{'cat__menu-left__item--active': menuName === 'Email Template UPSI'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Email Template'}">
                        <a href="{{menuLinkObject.EmailTemplatesCompliance}}" class="subitem" (click)="activeMenu('Email Template UPSI')">
                            Email Templates
                        </a>
                    </li>
                </ul>
            </li>


            <li [ngClass]="{'customMenuLeftInActive': menuName !== 'ncd', 'customMenuLeftActive': menuName === 'ncd'}"
                *ngIf="!isSuperAdmin && (isNCDConfig ) && ncdMenu && FinalMenu.NCD ">
                <a href="javascript: void(0);" (click)="activeMenu('ncd')">
                    NCD
                </a>
                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName == 'ncd' ? 'block' : 'none'}">
                    <li class="cat__menu-left__item" (click)="activeMenu('NCD-issueList')" [ngClass]="{'cat__menu-left__item--active': menuName === 'NCD-issueList'}">
                        <a class="subitem"   href="{{menuLinkObject.ncdIssuesList}}" [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Term Sheet'}">
                            Term Sheet 
                        </a>
                    </li>
                    <li class="cat__menu-left__item" (click)="activeMenu('Termsheet Library')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Termsheet Library'}">
                        <a class="subitem"  href="{{menuLinkObject.ncdLibrary}}" [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Term Sheet Library'}">
                            Term Sheet Library
                        </a>
                    </li>

                    <li class="cat__menu-left__item" (click)="activeMenu('Allottees Library')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Allottees Library'}">
                        <a class="subitem"   href="{{menuLinkObject.ncdAllotteesLibrary}}" [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Allottees Library'}">
                            Allottees Library
                        </a>
                    </li>
                </ul>
            </li>

            <li class="cat__menu-left__item cat__menu-left--colorful--primary"
                (click)="activeMenu('complianceRegulations')"
                [ngClass]="{'cat__menu-left__item--active': menuName === 'complianceRegulations'}"
                *ngIf="lawAndRegulationMenuEnable && FinalMenu.Laws">
                <a href="{{menuLinkObject.LawRegulation}}">
                    Laws & Regulations
                </a>
            </li>

            <!-- lawAndRegulationMenu  -->
            <!-- <li class="cat__menu-left__item cat__menu-left__menu" (click)="activeMenu('Laws & Regulations')" [ngClass]="{'cat__menu-left__item--active': menuName === 'Laws & Regulations'}"
                *ngIf="lawAndRegulationMenuEnable && (isPSSConfig || isAllConfig) && (!MeetingMakerWithoutAgenda && !MeetingMakerWithAgenda)">
                <a href="{{menuLinkObject.LawsAndRegulations}}">
                    Laws & Regulations
                </a>
            </li> -->

            <!-- Admin -->
            <li  (click)="activeMenu('Admin')" 
            [ngClass]="{'customMenuLeftInActive': menuName !== 'Admin', 'customMenuLeftActive': menuName === 'Admin'}"
            *ngIf="masterMenuEnable && FinalMenu.Admin">
                <a href="javascript: void(0);">
                    Admin
                </a>
                <ul class="cat__menu-left__list sub-menu-list" [ngStyle] = "{'display' : menuName == 'Admin' ? 'block' : 'none'}">
                    <li class="cat__menu-left__item" (click)="activeMenu('Admin')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Audit Trail'}"
                        [appHasPermission]="{'Url': '', 'method':'', 'menu': 'Admin'}" *ngIf="auditTrailEnable">
                        <a [routerLink]="[{outlets:{'structure-outlet':'auditTrail'}}]" class="subitem">
                            Audit Trail & Database Back up
                        </a>
                    </li>
                <!-- </ul> -->
                <!-- for smtp -->
                <!-- <ul class="cat__menu-left__list sub-menu-list"> -->
                    <li class="cat__menu-left__item" (click)="activeMenu('Admin')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Audit Trail'}"
                        >
                        <a [routerLink]="[{outlets:{'structure-outlet':'conmpany-config'}}]" class="subitem">
                            Company Config
                        </a>
                    </li>
                <!-- </ul> -->
                <!-- <ul class="cat__menu-left__list sub-menu-list"> -->
                    <li class="cat__menu-left__item" (click)="activeMenu('Admin')"
                        [ngClass]="{'cat__menu-left__item--active': menuName === 'Audit Trail'}"
                        >
                        <a [routerLink]="[{outlets:{'structure-outlet':'data-import'}}]" class="subitem">
                            Data Import
                        </a>
                    </li>
                </ul>
            </li>

            <!-- for mandatory inclusion -->
            <li *ngIf="isGEMConfig && FinalMenu.File" class="cat__menu-left__item cat__menu-left__menu" (click)="activeMenu('GFMDashboard')"
                [ngClass]="{'cat__menu-left__item--active': menuName === 'GFMDashboard'}">
                <a [routerLink]="[{outlets:{'structure-outlet':'gfmDashboard'}}]" [queryParams]="{ isCompliance : 'false' }">
                    GovEVA File Manager
                </a>
            </li>

        </ul>
    </div>
</nav>