import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserManagementComponent } from './user-management/user-management.component';
import { CreateUserModalComponent } from './create-user-modal/create-user-modal.component';

import { UserMasterService } from './../../services/user-master.service';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { CompanyMasterService } from '../../services/companymaster.service';

// import { HasPermissionDirective } from '../../directive/has-permission.directive';

import { SharedModule } from '../../shared/sharedmodule';

import { ImageCropperModule } from 'ngx-img-cropper';
import { AuthGuard } from '../../authGuard/auth.guard';
import { RouteGuard } from '../../routeGaurd/route.guard';
import { CommaSeperatedNumber } from '../../helpers/CommaSeperatedNumber';
import { CommonFunction } from '../../helpers/commonFunction';
import { PasswordConfirmationModalComponent } from './password-confirmation-modal/password-confirmation-modal.component';

export const routes: Routes = [
  {
    path: 'userMaster/userManagement',
    component: UserManagementComponent,
    canActivate: [AuthGuard, RouteGuard],
    outlet: 'structure-outlet',
  },
  // { path: 'userMaster', component: UserManagementComponent ,outlet:'structure-outlet'}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataTablesModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ImageCropperModule,
    NgSelectModule,
  ],
  declarations: [
    UserManagementComponent,
    CreateUserModalComponent,
    ConfirmationModalComponent,
    EditUserModalComponent,
    CommaSeperatedNumber,
    PasswordConfirmationModalComponent,
    // ImageCropperComponent
  ],
  providers: [UserMasterService, CommonFunction, CompanyMasterService],
  entryComponents: [
    CreateUserModalComponent,
    ConfirmationModalComponent,
    EditUserModalComponent,
    PasswordConfirmationModalComponent,
  ],
  exports: [
    NgSelectModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class UserMasterModule {}
