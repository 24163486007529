import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LastLoginDetailsService {
  userDetails: any;

  constructor() {}

  public returnUserDetails(user) {
    this.userDetails = user;
  }

  public getUserDetails() {
    return this.userDetails;
  }
}
