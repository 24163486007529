<!-- START: layout/sidebars -->
<!-- Top Submenu -->
<nav class="cat__core__top-sidebar cat__core__top-sidebar--bg">
    <span class="cat__core__title d-block mb-2">
        <strong>Top Sidebar w/ Background</strong>
    </span>
    <nav class="breadcrumb mb-0">
        <a class="breadcrumb-item" href="javascript: void(0);">Home</a>
        <a class="breadcrumb-item" href="javascript: void(0);">Library</a>
        <a class="breadcrumb-item" href="javascript: void(0);">Data</a>
        <span class="breadcrumb-item active">Bootstrap</span>
    </nav>
</nav>
<nav class="cat__core__top-sidebar">
    <span class="cat__core__title d-block mb-2">
        <strong>Top Sidebar w/o Background</strong>
    </span>
    <nav class="breadcrumb mb-0 bg-white">
        <a class="breadcrumb-item" href="javascript: void(0);">Home</a>
        <a class="breadcrumb-item" href="javascript: void(0);">Library</a>
        <a class="breadcrumb-item" href="javascript: void(0);">Data</a>
        <span class="breadcrumb-item active">Bootstrap</span>
    </nav>
</nav>
<!-- End Top Submenu -->

<!-- Panel with Left Sidebar -->
<section class="card cat__core__card-with-sidebar">
    <div class="cat__core__card-sidebar">
        <div class="btn-group-vertical btn-group-justified">
            <button type="button" class="btn">Provide Files</button>
            <button type="button" class="btn">User Action</button>
            <button type="button" class="btn">Access History</button>
        </div>
        <br />
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book.</p>
    </div>
    <div class="card-header">
        <div class="pull-right mt-2">
            <span class="mr-3">
                User:
                <a href="javascript: void(0);">Administrator</a>
            </span>
            <div class="dropdown d-inline-block">
                <a href="javascript: void(0);" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <i class="icmn-database mr-2"></i>
                    <span class="hidden-lg-down">Dashboards</span>
                    <span class="caret"></span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="" role="menu">
                    <div class="dropdown-header">Active</div>
                    <a class="dropdown-item" href="javascript:void(0)">Project Management</a>
                    <a class="dropdown-item" href="javascript:void(0)">User Inetrface Development</a>
                    <a class="dropdown-item" href="javascript:void(0)">Documentation</a>
                    <div class="dropdown-header">Inactive</div>
                    <a class="dropdown-item" href="javascript:void(0)">Marketing</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0)"><i class="dropdown-icon icmn-cog"></i> Settings</a>
                </ul>
            </div>
        </div>
        <span class="cat__core__title">
            <strong>Panel w/ Left Sidebar</strong>
        </span>
    </div>
    <div class="card-block">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of
            Lorem Ipsum.</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages.</p>
    </div>
</section>
<!-- End Panel with Left Sidebar -->

<!-- Panel with Right Sidebar -->
<section class="card cat__core__card-with-sidebar cat__core__card-with-sidebar--right">
    <div class="cat__core__card-sidebar">
        <div class="btn-group-vertical btn-group-justified">
            <button type="button" class="btn">Provide Files</button>
            <button type="button" class="btn">User Action</button>
            <button type="button" class="btn">Access History</button>
        </div>
        <br />
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book.</p>
    </div>
    <div class="card-header">
        <div class="pull-right mt-2">
            <span class="mr-3">
                User:
                <a href="javascript: void(0);">Administrator</a>
            </span>
            <div class="dropdown d-inline-block">
                <a href="javascript: void(0);" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <i class="icmn-database mr-2"></i>
                    <span class="hidden-lg-down">Dashboards</span>
                    <span class="caret"></span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="" role="menu">
                    <div class="dropdown-header">Active</div>
                    <a class="dropdown-item" href="javascript:void(0)">Project Management</a>
                    <a class="dropdown-item" href="javascript:void(0)">User Inetrface Development</a>
                    <a class="dropdown-item" href="javascript:void(0)">Documentation</a>
                    <div class="dropdown-header">Inactive</div>
                    <a class="dropdown-item" href="javascript:void(0)">Marketing</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0)"><i class="dropdown-icon icmn-cog"></i> Settings</a>
                </ul>
            </div>
        </div>
        <span class="cat__core__title">
            <strong>Panel w/ Right Sidebar</strong>
        </span>
    </div>
    <div class="card-block">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages.</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy
            text ever since the 1500s, when an unknown printer took a galley
            of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of
            Lorem Ipsum.</p>
    </div>
</section>
<!-- End Panel with Right Sidebar -->
<!-- END: layout/sidebars -->