import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DashboardService } from './../../../services/dashboard.service';

import { TaskListComponent } from '../task-list/task-list.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-pictorial-roadmap',
  templateUrl: './pictorial-roadmap.component.html',
  styleUrls: ['./pictorial-roadmap.component.css'],
})
export class PictorialRoadmapComponent implements OnInit {

  processList: any;
  selectedFromDate: any;
  selectedToDate: any;
  selectedInputProcessName: any;
  // isFromDateSelected : boolean = false;
  minDateForToDate: any;
  dataType: any; //this is for task count or full details if datatype is count it shows task countwise data
  selectedProcessById: any;
  processByIdList: any;
  isFromDateSelected: boolean = false;

  constructor(private dashboardService: DashboardService, public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit() {
    var self = this;
    this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails({ 'dataType': "count" }).then(response => {
      if (!response.status) {
        this.processList = response.TaskList;
        if (_.size(this.processList)) {
          self.processByIdList = this.processList.map(function (o) {
            return {
              'label': o.ProcessName,
              'value': o.ProcessId
            }
          });
        }
      }

    })

  }

  showDetailsRoadmapTask() {

    this.activeModal.dismiss('dismissed');

    const modalRef = this.modalService.open(TaskListComponent, {
      size: 'lg',
      windowClass: 'pictorialRoadmapShowDetailModal',
      backdrop: 'static'
    })
    modalRef.componentInstance.type = { 'name': "Roadmap" }
    modalRef.result.then(response => {
      if (response.status === 0) {

      } else {

      }
    })
  }
  searchFilter() {
    var searchObject = {};
    var toDateToSearch = null;
    var fromDateToSearch = null;
    if (this.selectedToDate) {
      toDateToSearch = this.setFormatDate(this.setDate(this.selectedToDate));
    }
    if (this.selectedFromDate) {
      fromDateToSearch = this.setFormatDate(this.setDate(this.selectedFromDate));
    }

    searchObject = {
      'processName': this.selectedInputProcessName,
      'processId': this.selectedProcessById,
      'toDate': toDateToSearch,
      'fromDate': fromDateToSearch,
      'dataType': "count"
    };

    this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails(searchObject).then(response => {
      if (!response.status) {
        this.processList = response.TaskList;
      }
    })
  }

  setDate(date) {
    if (date != "" && date != null) {
      return date.month + "-" + date.day + "-" + date.year;
    }
    return null;
  }

  setFormatDate(date) {
    if (date != null) {
      return new Date(date);
    } else {
      return null;
    }
  }

  inputProcessNameChange() {
  }

  selectedFromDateChange() {
    if (this.selectedFromDate != null && this.selectedFromDate != "") {
      this.minDateForToDate = this.selectedFromDate;
      this.selectedToDate = this.selectedFromDate;
      this.isFromDateSelected = true;
    } else {
      this.isFromDateSelected = false;
      this.selectedToDate = this.selectedFromDate;
    }

  }

  selectedToDateChange() {
    if (this.selectedToDate != "" && this.selectedToDate != null) {
    } else {
      if (this.selectedFromDate != null && this.selectedFromDate != "") {
        this.selectedToDate = this.selectedFromDate;
      }
    }
  }


  // reset search filter values
  clearFilterValues() {
    this.selectedInputProcessName = "";
    this.selectedFromDate = "";
    this.selectedToDate = "";
    this.selectedProcessById = "";
    this.dashboardService.getAllTaskListOfRoadMapForCountOrFullDetails({ 'dataType': "count" }).then(response => {
      if (!response.status) {
        this.processList = response.TaskList;
      }
    })
  }
  cancelModal() {
    this.activeModal.dismiss('dismissed')
  }

}
