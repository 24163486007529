<!-- START: components/slider -->
<section class="card">
    <div class="card-header">
        <span class="cat__core__title">
            <strong>Slider</strong>
            <a href="http://ionden.com/a/plugins/ion.rangeSlider/en.html" target="_blank" class="btn btn-sm btn-primary ml-2">Official Documentation <i class="icmn-link ml-1"><!-- --></i></a>
        </span>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Basic w/ Grid</strong></h5>
                <p class="text-muted">Element: read <a href="http://ionden.com/a/plugins/ion.rangeSlider/en.html" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <input type="text" id="slider-1" class="form-control" name="example_name" value="" />
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Basic w/o Grid</strong></h5>
                <p class="text-muted">Element: read <a href="http://ionden.com/a/plugins/ion.rangeSlider/en.html" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <input type="text" id="slider-2" class="form-control" name="example_name" value="" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Range w/ Min-Max</strong></h5>
                <p class="text-muted">Element: read <a href="http://ionden.com/a/plugins/ion.rangeSlider/en.html" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <input type="text" id="slider-3" class="form-control" name="example_name" value="" />
                </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-black"><strong>Basic Range</strong></h5>
                <p class="text-muted">Element: read <a href="http://ionden.com/a/plugins/ion.rangeSlider/en.html" target="_blank">official documentation<small><i class="icmn-link ml-1"><!-- --></i></small></a></p>
                <div class="mb-5">
                    <input type="text" id="slider-4" class="form-control" name="example_name" value="" />
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END: components/slider -->
