import { Component, OnInit, ElementRef } from '@angular/core';

import { GoogleOrgChartService } from '../../../google-org-charts.service';
import { OrganisationChartService } from '../../../services/organisationChart.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

declare var google: any;
// declare var $: any;
// declare var jQuery: any;
// require('orgchart');

@Component({
  selector: 'app-orgchart',
  templateUrl: './orgchart.component.html',
  // styleUrls: ['./orgchart.component.css'],
})
export class OrgchartComponent implements OnInit {
  // @Input() data: any[];
  // @Input() elementId:string;
  elementId: any = 'directorChart';
  orgcompanyName: string = '';
  data: any[];
  dataSrc: any;
  idDirectorChart: any;
  idManagementChart: any;
  nameDirector: any;
  nameManagement: any;
  userTypeDirector: any;
  userTypeManagement: any;
  refersToDirector: any;
  refersToManagement: any;
  committeesDirector: any;
  CommitteesManagement: any;
  tooltipDirector: any;
  tooltipManagement: any;
  directorsHierarchyData: any = [['Name', 'Manager', 'ToolTip']];
  managementHierarchyData: any = [['Name', 'Manager', 'ToolTip']];
  remarksForDirector: string = '';
  remarksForManagement: string = '';

  constructor(
    private elementRef: ElementRef,
    private _orgChartService: GoogleOrgChartService,
    private organisationService: OrganisationChartService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    const resourcesValue = JSON.parse(localStorage.getItem('resources'));
    this.orgcompanyName = JSON.parse(localStorage.getItem('user')).CompanyName;
    this.elementId = 'directorChart';

    const self = this;

    var isDirectorOrgchartPresent;
    var toMakeManagementOrgchartActive;

    _.forEach(resourcesValue, function (data) {
      if (data.Menu === 'Dashboard') {
        isDirectorOrgchartPresent = _.find(
          data.ChildResources,
          function (object) {
            return object.Url == 'organizationChartBoardMemeber';
          }
        );
        // console.log("isDirectorOrgchartPresent",isDirectorOrgchartPresent);
        if (_.isNil(isDirectorOrgchartPresent)) {
          toMakeManagementOrgchartActive = _.find(
            data.ChildResources,
            function (object) {
              return object.Url == 'organizationChart-ExecutiveManagement';
            }
          );
          //  console.log("toMakeManagementOrgchartActive",toMakeManagementOrgchartActive);
          if (_.isNil(toMakeManagementOrgchartActive)) {
          } else {
            $('#managementTab').addClass('active');
            $('#managementTabLink').addClass('active');

            //  console.log("class add");
          }
        } else {
        }
      }
    });

    this.organisationService.getDirectorHierarchyData().then((response) => {
      if (_.size(response.companyHierarchy)) {
        var companyID = JSON.parse(
          localStorage.getItem('companyDetailsGoveva')
        ).value;
        var directorsId = [];
        response.companyHierarchy.forEach((companyDirector) => {
          directorsId.push(companyDirector._id);
        });
        // var directorsData = _.filter(response.companyHierarchy, "_id");

        // var directorsId = _.get(_.filter(response.companyHierarchy, function (userData) { return userData }), "_id");
        var directorDetails = {
          CompanyID: companyID,
          DirectorIDS: directorsId,
        };
        this.organisationService
          .getDiscloserOfInterestOfDirectors(directorDetails)
          .then((directorshipData) => {
            let stringHelper = '';
            response.companyHierarchy.forEach((result) => {
              let findUserObject;
              if (!_.isNil(result.ReportsTo)) {
                findUserObject = _.find(
                  response.companyHierarchy,
                  function (userData) {
                    return userData._id == result.ReportsTo;
                  }
                ); // result.ReportsTo
                if (_.isUndefined(findUserObject)) {
                  result.ReportsTo = null;
                }
              }
              // console.log('findUserObject', findUserObject)
              if (result.IsActive) {
                var directorwiseData;
                if (
                  directorshipData.status == 0 &&
                  _.size(directorshipData.data) > 0
                ) {
                  directorshipData.data.Director.forEach((directorship) => {
                    if (directorship.DirectorID == result._id) {
                      directorwiseData = directorship;
                    }
                  });
                  // directorwiseData = _.filter(directorshipData.data, function (o) { return (o.DirectorID == result._id) });
                }

                this.idDirectorChart = result._id;
                this.nameDirector = result.FirstName + ' ' + result.LastName;
                this.userTypeDirector = result.TypeOfUser;
                this.refersToDirector = result.ReportsTo;
                this.tooltipDirector = result.TypeOfUser;
                // this.committeesDirector = result.Committees;
                this.committeesDirector = _.size(directorwiseData.CommitteeList)
                  ? directorwiseData.CommitteeList
                  : [];
                var committeeDirectorHtml = '';
                if (this.committeesDirector) {
                  this.committeesDirector.forEach((committee) => {
                    ////console.log("committee", committee);
                    const CommitteeName =
                      committee.CommitteeName == 'Other'
                        ? committee.OtherCommitteeName
                        : committee.CommitteeName;

                    committeeDirectorHtml = committeeDirectorHtml.concat(
                      `<li>${CommitteeName}</li>`
                    );
                  });
                } else {
                  committeeDirectorHtml = '';
                }

                //remarks
                // console.log("in director:", result.Remarks);
                if (result.Remarks != undefined && result.Remarks != null)
                  this.remarksForDirector = result.Remarks;
                else this.remarksForDirector = '';

                var statusColor: any;
                var activeStatus: any;

                if (result.IsActive) {
                  statusColor = "#66c0bf";
                  activeStatus = "Active"
                }
                else {
                  statusColor = "red";
                  activeStatus = "Deactivated"
                }

                // this.directorsHierarchyData.push([{v :this.id, f: this.name + "<div style='color:gray;font-size:smaller'>" + this.userType + "</div>"}, this.refersTo, this.tooltip]); 
                if (result.ProfilePicture == undefined || result.ProfilePicture == null || result.ProfilePicture == '') {

                  if (result.Remarks == undefined || result.Remarks == null || result.Remarks == '') {

                    if (directorwiseData == undefined || directorwiseData == null || directorwiseData == '') {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status:'+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count:0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                        // + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                    else {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status:'+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count:0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus
                        //  + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Wholetime Director Count :<ul style="list-style-type:none">' + directorwiseData.WholetimeDirectorcount + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                  } else {
                    if (directorwiseData == undefined || directorwiseData == null || directorwiseData == '') {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status:'+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count:0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                        // + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForDirector + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                    else {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width:100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status:'+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count:0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                        // + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForDirector + '</ul></div><div style="font-size: 0.9rem;">Wholetime Director Count :<ul style="list-style-type:none">' + directorwiseData.WholetimeDirectorcount + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                  }
                } else {
                  if (result.Remarks == undefined || result.Remarks == null || result.Remarks == '') {
                    if (directorwiseData == undefined || directorwiseData == null || directorwiseData == '') {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info style="display: inline-flex;width: 100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus
                        //  + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    } else {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus
                        //  + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Wholetime Director Count :<ul style="list-style-type:none">' + directorwiseData.WholetimeDirectorcount + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                  }
                  else {
                    if (directorwiseData == undefined || directorwiseData == null || directorwiseData == '') {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus
                        //  + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForDirector + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    } else {
                      this.directorsHierarchyData.push([{
                        v: this.idDirectorChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameDirector + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeDirectorHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameDirector + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus
                        //  + '</div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeDirectorHtml + '</ul></div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForDirector + '</ul></div><div style="font-size: 0.9rem;">Wholetime Director Count :<ul style="list-style-type:none">' + directorwiseData.WholetimeDirectorcount + '</ul></div></span></div>'
                      }, this.refersToDirector, '']);
                    }
                  }
                }

                stringHelper = `<div class="info" style="display: inline-flex;width:100%;">
                  <div class="user"><img src="${
                    !result.ProfilePicture
                      ? '/assets/images/org-chart-default-user.png'
                      : result.ProfilePicture
                  }"></div>
                  <div class="customtooltiptext"><ul><li><strong>
                          ${this.nameDirector}
                          </strong></li><li>Status:
                          ${activeStatus}
                          </li><li>Experience:
                          ${
                            result.Experience == undefined
                              ? ''
                              : result.Experience + 'yrs'
                          }
                          </li><li>Area of Expertise:
                          ${
                            result.AreaOfExpertise == undefined
                              ? ''
                              : result.AreaOfExpertise
                          }
                          </li><li>Designation:
                          ${
                            result.Designation == undefined
                              ? ''
                              : result.Designation
                          }
                          </li><li>Committees: <ul class="committee">${committeeDirectorHtml}</ul></li>
                          <li>Wholetime Directore Count:0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">
                          ${this.nameDirector}
                          </div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:${statusColor}">${activeStatus}`;

                this.directorsHierarchyData.push([
                  {
                    v: this.idDirectorChart,
                    f: stringHelper,
                  },
                  this.refersToDirector,
                  '',
                ]);

                if (!_.isNil(isDirectorOrgchartPresent)) {
                  // console.log("directors call")
                  this._orgChartService.BuildOrgChart(
                    this.elementId,
                    this.directorsHierarchyData
                  );
                  // console.log(this.elementId)
                }
              }
              //----------
            });
          });

        //----
      } else {
        this._orgChartService.BuildOrgChart(
          this.elementId,
          this.directorsHierarchyData
        );
        // console.log(this.elementId)
      }
    });

    this.organisationService.getManagementHierarchyData().then((response) => {
      // console.log("management data OtherMember", response);

      if (_.size(_.get(response, 'companyHierarchy'))) {
        var companyID = JSON.parse(
          localStorage.getItem('companyDetailsGoveva')
        ).value;
        var OtherMember = [];
        var companyHierarchy = _.get(response, 'companyHierarchy');
        companyHierarchy.forEach((member) => {
          OtherMember.push(member._id);
        });

        var OtherMemberDetails = {
          CompanyID: companyID,
          OtherMember: OtherMember,
        };

        this.organisationService
          .getDiscloserOfInterestOfDirectors(OtherMemberDetails)
          .then((OtherMember) => {
            _.get(response, 'companyHierarchy').forEach((result) => {
              if (result.IsActive) {
                var MemberData;
                if (OtherMember.status == 0 && _.size(OtherMember.data) > 0) {
                  OtherMember.data.OtherMember.forEach((OtherMemberData) => {
                    if (OtherMemberData.OtherMemberId == result._id) {
                      MemberData = OtherMemberData;
                    }
                  });
                }
                // directorwiseData = _.filter(directorshipData.data, function (o) { return (o.DirectorID == result._id) });

                this.idManagementChart = result._id;
                this.nameManagement = result.FirstName + ' ' + result.LastName;
                this.userTypeManagement = result.TypeOfUser;
                this.refersToManagement = result.ReportsTo;
                this.tooltipManagement = result.TypeOfUser;
                // this.CommitteesManagement = result.Committees;
                this.CommitteesManagement = MemberData
                  ? _.size(MemberData.CommitteeList)
                    ? MemberData.CommitteeList
                    : []
                  : [];

                var committeeManagementHtml = '';
                if (_.size(this.CommitteesManagement)) {
                  this.CommitteesManagement.forEach((committee) => {
                    // console.log("committee--------------------------", committee);
                    var CommitteeName;
                    if (committee.CommitteeName == 'Other') {
                      CommitteeName = committee.OtherCommitteeName;
                    } else {
                      CommitteeName = committee.CommitteeName;
                    }
                    committeeManagementHtml = committeeManagementHtml.concat(
                      '<li>' + CommitteeName + '</li>'
                    );
                  });
                } else {
                  committeeManagementHtml = '';
                }

                var statusColor: any;
                var activeStatus: any;
                if (result.IsActive) {
                  statusColor = '#66c0bf';
                  activeStatus = 'Active';
                } else {
                  statusColor = 'red';
                  activeStatus = 'Deactivated';
                }

                //remarks
                if (result.Remarks != undefined && result.Remarks != null)
                  this.remarksForManagement = result.Remarks;
                else
                  this.remarksForManagement = "";
  

  
                //  this.managementHierarchyData.push([{v :this.id, f: this.name + "<div style='color:gray;font-size:smaller'>" + this.userType + "</div>"}, this.refersTo, this.tooltip]); 
  
  
                if (result.ProfilePicture == undefined || result.ProfilePicture == null || result.ProfilePicture == '') {
  
                  if (result.Remarks == undefined || result.Remarks == null || result.Remarks == '') {
                    this.managementHierarchyData.push([{
                      v: this.idManagementChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameManagement + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeManagementHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameManagement + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                      // + '</span></div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeManagementHtml + '</ul></div></span></div>'
                    }, this.refersToManagement, '']);
                  }
                  else {
                    this.managementHierarchyData.push([{
                      v: this.idManagementChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="/assets/images/org-chart-default-user.png" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameManagement + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeManagementHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameManagement + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                      // + '</span></div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees :<ul style="list-style-type:none">' + committeeManagementHtml + '</ul></div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForManagement + '</ul></div> </span></div>'
                    }, this.refersToManagement, '']);
                  }
                } else {
  
                  if (result.Remarks == undefined || result.Remarks == null || result.Remarks == '') {
                    this.managementHierarchyData.push([{
                      v: this.idManagementChart, f: '<div class="info" style="display: inline-flex;width:100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameManagement + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeManagementHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameManagement + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                      // + '</span></div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees : <ul style="list-style-type:none">' + committeeManagementHtml + '</ul>' + '</div></span></div>'
                    }, this.refersToManagement, '']);
                  }
                  else {
                    this.managementHierarchyData.push([{
                      v: this.idManagementChart, f: '<div class="info" style="display: inline-flex;width: 100%;"><div class="user"><img src="' + result.ProfilePicture + '" alt="org-chart"></div><div class="customtooltiptext"><ul><li><strong>' + this.nameManagement + '</strong></li><li>Status: '+ activeStatus +' </li><li>Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</li><li>Area of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</li><li>Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</li><li>Committees: <ul class="committee">' + committeeManagementHtml + '</ul></li><li>Wholetime Directore Count: 0</li></ul></div><span class="infotext"><div style="font-size: 15px; font-weight: bold;">' + this.nameManagement + '</div><div style="font-size: 0.9rem;font-weight:600;">Status: <span style="color:' + statusColor + '">' + activeStatus 
                      // + '</span></div><div style="font-size: 0.9rem;">Experience: ' + (result.Experience == undefined ? '' : result.Experience + 'yrs') + '</div><div style="font-size: 0.9rem;">Area Of Expertise: ' + (result.AreaOfExpertise == undefined ? '' : result.AreaOfExpertise) + '</div><div style="font-size: 0.9rem;">Designation: ' + (result.Designation == undefined ? '' : result.Designation) + '</div><div style="font-size: 0.9rem;">Committees : <ul style="list-style-type:none">' + committeeManagementHtml + '</ul>' + '</div><div style="font-size: 0.9rem;">Remarks :<ul style="list-style-type:none">' + this.remarksForManagement + '</ul></div> </span></div>'
                    }, this.refersToManagement, '']);
                  }
                }
              }
              //---------
            });
          });
      }

      if (_.isNil(isDirectorOrgchartPresent)) {
        if (_.isNil(toMakeManagementOrgchartActive)) {
        } else {
          $('#managementTab').addClass('active');
          $('#managementTabLink').addClass('active');
          this._orgChartService.BuildOrgChart(
            'managementChart',
            this.managementHierarchyData
          );
          // console.log("class add");
        }
      } else {
      }
    });

    $('<style>')
      .text(
        '.infotext { text-align:left; width: max-content;left: 60px; top: 0;   } table { border-collapse: inherit;'
      )
      .appendTo('head');

    // this.organisationService.getData()
    // .subscribe(res => {
    //   //console.log("organisation Chart" , res.companyHierarchy);
    //   this.dataSrc = res.companyHierarchy;

    //   this.dataSrc.forEach(result => {
    //     this.id = result._id;
    //     this.name = result.FirstName + " " + result.LastName;
    //     this.userType = result.TypeOfUser;
    //     this.refersTo = result.ReportsTo;
    //     this.tooltip = result.TypeOfUser;
    //     this.data.push([{v :this.id, f: this.name + "<div style='color:blue;font-size:smaller'>" + this.userType + "</div>"}, this.refersTo, this.tooltip]);
    //   })

    // this._orgChartService.BuildOrgChart(this.elementId , this.directorsHierarchyData);

    // }) ;
  }

  OrgChartId(value) {
    this.elementId = value;
    if (value == 'directorChart') {
      this._orgChartService.BuildOrgChart(
        this.elementId,
        this.directorsHierarchyData
      );
    } else {
      this._orgChartService.BuildOrgChart(
        this.elementId,
        this.managementHierarchyData
      );
    }
  }
}

//console.log("value", value);
//console.log("inside orgchartId");
//console.log("elementId", this.elementId);
//console.log("data", this.data);
// ngOnInit() {

// //   this.script.load('orgChart').then(data => {
// //     //console.log('script loaded ', data);
// // }).catch(error => //console.log(error));

// // $.getScript("../../../../../../node_modules/orgchart/dist/js/jquery.orgchart.min.js");

//   $(function(){

//---------------------------------------------------------------------------------------------------------------------------------------------
//   var datasource = {
//     'id': '1',
//     'name': 'G N Bajpai',
//     'title': 'Founder Chairman',
//     'renumeration':'65 lpa',
//     'experience':'15 years',
//     'committees':['Boards','Shares','Sales'],
//     'children': [
//       { 'id': '2', 'name': 'Shailesh Haribhakti', 'title': 'Founder Vice-Chairman','renumeration':'15 lpa','experience':'10 years','committees':['Shares','Departments','Sales'],
//       'children': [
//         { 'id': '6', 'name': 'Ankita Trivedi', 'title': 'Company Secretary', 'renumeration':'8 lpa','experience':'6 years','committees':['Departments','Acquisition'] },
//         { 'id': '7', 'name': 'Kamalji Sahay', 'title': 'Leader', 'renumeration':'8 lpa','experience':'7 years','committees':['Departments','Acquisition'] }
//       ]
//       },
//       { 'id': '3', 'name': 'Jangoo Dalal', 'title': 'Founder Managing Director','renumeration':'18 lpa','experience':'12 years','committees':['Shares','Departments','Finance'],
//         'children': [
//           { 'id': '4', 'name': 'Chetan Gandhi', 'title': 'Company Secretary' , 'renumeration':'10 lpa','experience':'8 years','committees':['Finance','Departments','Acquisition']},
//           // { 'id': '5', 'name': 'Hei Hei', 'title': 'senior engineer', 'renumeration':'10 lpa','experience':'8 years','committees':['Finance','Departments','Acquisition']}
//           { 'id': '7', 'name': 'Kamalji Sahay', 'title': 'Leader', 'renumeration':'8 lpa','experience':'7 years','committees':['Departments','Acquisition'] }
//         ]
//       },
//       // { 'id': '8', 'name': 'Yu Jie', 'title': 'department manager' , 'renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] },
//       // { 'id': '9', 'name': 'Yu Li', 'title': 'department manager','renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] },
//       // { 'id': '10', 'name': 'Hong Miao', 'title': 'department manager','renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] },
//       // { 'id': '11', 'name': 'Yu Wei', 'title': 'department manager','renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] },
//       // { 'id': '12', 'name': 'Chun Miao', 'title': 'department manager','renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] },
//       // { 'id': '13', 'name': 'Yu Tie', 'title': 'department manager','renumeration':'12 lpa','experience':'9 years','committees':['Shares','Departments','Finance'] }
//     ]
//   };

//   var nodeTemplate = function(data) {
//     return `<div style="border: 1px solid rgb(55, 167, 165);">
//       <div class="title" style="height: 40px;">
//       <img class="avatar" style="vertical-align: middle;height: inherit;float: left;padding:2px" src="../../../assets/images/${data.id}.jpg">

//       <div style="display: inline-grid;margin-right: 5px;margin-left: 5px;">
//           <span style="color:#37a7a5;font-size:1.15rem;font-weight:550;text-align:left">${data.name}</span>
//           <span style="color:darkslategray;font-size:0.9rem;font-weight:500;text-align:left">${data.title}</span>
//       </div>
//         </div>

//     `;
//   };

//   $('#directorChart').orgchart({
//     'data' : datasource,
//     // 'visibleLevel': 2,
//     'nodeContent': 'title',
//     'toggleSiblingsResp': true,
//     'nodeTemplate': nodeTemplate,
//     'nodeID': 'id',
//     'pan':true,
//     'zoom':false,
//     'createNode': function($node, data) {

//       var secondMenuIcon = $('<i>', {
//         'class': 'fa fa-info-circle second-menu-icon',

//         click: function() {
//           // committes(data.committees)
//           $(this).siblings('.second-menu').toggle();

//         }
//       });

//       function committes(values){
//         var result="";
//         //console.log("values",values);
//         values.forEach(data => {
//           //console.log("data - result",data , result);

//           result = result + "<li style ='text-align: left;'> " + data + "</li>" ;
//         });
//         //console.log("result",result);
//         return result;

//         // result = values.join("<br>");

//       }

//       // var secondMenu = `<div class="second-menu">
//       //                    <br>
//       //                    <b>Name:` + data.name +
//       //                    `<br>Renumeration:` + data.renumeration +
//       //                    `<br>Experience:` + data.experience +
//       //                    `<br>Committees:` +
//       //                    committes(data.committees) +
//       //                    `</b></div>`;

//       var secondMenu = `<div class="second-menu" style="color: #ffffff;width: max-content;margin: 5px;">
//                           <div class="b" style="margin: 8px;">

//                             <div>
//                               <div style="float:left;display: inline-flex;">Name :</div>
//                               <div style="text-align:left;display: inline-flex;">${data.name}</div>
//                             </div>`

//                             +

//                             `<div>
//                               <div style="float:left;display: inline-flex;">Renumeration :</div>
//                               <div style="text-align:left;display: inline-flex;">${data.renumeration}</div>
//                             </div>`

//                             +

//                             `<div>
//                               <div style="float:left;display: inline-flex;">Experience :</div>
//                               <div style="text-align:left;display: inline-flex;">${data.experience}</div>
//                             </div>`

//                             +

//                             `<div>
//                                 <div style="text-align: left;">Committees :</div>
//                                   <ul>
//                             `
//                             +
//                             `
//                             ${committes(data.committees)} ` +

//                          `
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>

//                          `;

//       //console.log("secondmenu",secondMenu);

//       $node.append(secondMenuIcon).append(secondMenu);

//     }
//   });

//   $('#managementChart').orgchart({
//     'data' : datasource,
//     // 'visibleLevel': 2,
//     'nodeContent': 'title',
//     'toggleSiblingsResp': true,
//     'nodeTemplate': nodeTemplate,
//     'nodeID': 'id',
//     'pan':true,
//     'zoom':false,
//     'createNode': function($node, data) {

//       var secondMenuIcon = $('<i>', {
//         'class': 'fa fa-info-circle second-menu-icon',

//         click: function() {
//           committes(data.committees)
//           $(this).siblings('.second-menu').toggle();

//         }
//       });

//       function committes(values){
//         var result="";
//         //console.log("values",values);
//         values.forEach(data => {
//           //console.log("data - result",data , result);

//           result = result + "<li style ='text-align: left;'> " + data + "</li>" ;
//         });
//         //console.log("result",result);
//         return result;
//       }

//       // var secondMenu = `<div class="second-menu"><img class="avatar" src="../../../../assets/images/` + data.id + `.jpg">` + `<br><b>Name:` + data.name + `<br>Renumeration:` + data.renumeration + `<br>Experience:` + data.experience + `<br>Committees:` + committes(data.committees) + `</b></div>`;

//       var secondMenu = `<div class="second-menu" style="color: #ffffff;width: max-content;margin: 5px;">
//       <div class="b" style="margin: 8px;">

//         <div>
//           <div style="float:left;display: inline-flex;">Name :</div>
//           <div style="text-align:left;display: inline-flex;">${data.name}</div>
//         </div>`

//         +

//         `<div>
//           <div style="float:left;display: inline-flex;">Renumeration :</div>
//           <div style="text-align:left;display: inline-flex;">${data.renumeration}</div>
//         </div>`

//         +

//         `<div>
//           <div style="float:left;display: inline-flex;">Experience :</div>
//           <div style="text-align:left;display: inline-flex;">${data.experience}</div>
//         </div>`

//         +

//         `<div>
//             <div style="text-align: left;">Committees :</div>
//               <ul>
//         `
//         +
//         `
//         ${committes(data.committees)} ` +

//      `
//             </ul>
//           </div>
//         </div>
//       </div>

//      `;

//       $node.append(secondMenuIcon).append(secondMenu);

//     }
//   });

// });
//   //---------------------------------------------------------------------------------------------------------------------------------------------
// } }
