import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})
export class DashboardMyTaskService {
  constructor(private http: HttpClient) {}

  //returns task list for logged in user. This list will show under My Task in Dashboard
  getMyTaskList(assignedId): Promise<any> {
    return this.http
      .get(
        environment.clientUrl +
          'roadmap/tasksForAssignedUser?AssignedToId=' +
          assignedId
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  bulkUpdateTaskStatus(task): Promise<any> {
    return this.http
      .post(environment.clientUrl + 'dashboard/bulkUpdateTasks', task)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  //get all recent Company News
  getRecentCompanyNews(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'companyNews/recentCompanyNews')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getRecentPeerCompanyNews(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'companyNews/recentPeerCompanyNews')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getCompanyAllNews(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'companyNews/companyAllNews')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getPeerCompanyAllNews(): Promise<any> {
    return this.http
      .get(environment.clientUrl + 'companyNews/peerCompanyNews')
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  //Company News details of selected news headline
  getCompanyNewsDetailsByNewsId(newsId): Promise<any> {
    return this.http
      .get(
        environment.clientUrl +
          'companyNews/companyNewsByNewsId?NewsID=' +
          newsId
      )
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  getUserIdbyUniqueUserId(uniqueUserId): Promise<any> {
    var url =
      environment.clientUrl +
      'userMaster/getUserListByUniqueUserList/' +
      uniqueUserId;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }

  bulkUpdateForComplianceTask(complianceTaskObject): Promise<any> {
    let url = environment.clientUrl + 'dashboard/bulkUpdateComplianceTask';
    return this.http
      .post(url, complianceTaskObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      });
  }
}
