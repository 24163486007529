import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';

import { DataTableDirective } from 'angular-datatables';
import { RoleMasterService } from '../../services/rolemaster.service';

import { PermissionLinks } from './../../enums/permissionLinks';

import { Subject } from 'rxjs';
// import { HasPermissionDirective } from '../../directive/has-permission.directive';
import * as _ from 'lodash';
declare var $: any;

declare var jQuery: any;

@Component({
  selector: 'app-rolemaster',
  templateUrl: './rolemaster.component.html',
  styleUrls: ['./rolemaster.component.css'],
})
export class RolemasterComponent implements OnInit {
  roleForm: FormGroup;
  inputRoleName: any;
  listOfRoleNames: any;
  editRoleObject: any;
  deleteRoleObject: any;
  parentActivityObject: any;
  childActivityObject: any;
  formData: boolean = false;
  totalNumberOfParentActivity: number;
  isParentActivityChecked: any = [];
  roleId: any;
  temporaryHoldInputRoleName: any;
  errorMessage: any;
  headerSuccessMessage: string = 'Success !';
  headerErrorMessage: string = 'Failure !';
  headerSuccessFlag: boolean = false;
  headerErrorFlag: boolean = false;
  roleNamePatternRegEx: any = '^[a-zA-Z][a-zA-Z-_/ ]*$';
  checkboxValidationFlag: boolean = false;
  inputPasteFlag: boolean = false;

  loggedInUserInfo: any;
  isGuestSuperAdmin: boolean;

  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  get arrayOfParentActivities(): FormArray {
    return <FormArray>this.roleForm.get('arrayOfParentActivities');
  }

  get validationInputRoleName() {
    return this.roleForm.get('roleName');
  }

  constructor(
    private roleMasterService: RoleMasterService,
    public fb: FormBuilder,
    public renderer: Renderer2
  ) {
    this.loggedInUserInfo = JSON.parse(localStorage.getItem('user')).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }
  }

  ngOnInit() {
    $('body').removeClass('dashboard-page');

    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      paging: true,
      // scrollY: "440px",
      // scrollCollapse: true,
      stateSave: true,
      stateDuration: -1,
      pageLength: 10,
      search: true,
      language: {
        //made changes for datatables for next previous button
        paginate: {
          first: '<<',
          last: '>>',
          next: '>',
          previous: '<',
        },
      },
    };

    this.loggedInUserInfo = JSON.parse(localStorage.getItem('user')).TypeOfUser;
    if (this.loggedInUserInfo === PermissionLinks.GuestSuperAdmin) {
      this.isGuestSuperAdmin = true;
    } else {
      this.isGuestSuperAdmin = false;
    }
    //console.log("loggedInUserInfo,this.isGuestSuperAdmin ", this.loggedInUserInfo, this.isGuestSuperAdmin);

    this.listOfRoles();
    this.formInitialisation();
  }

  //to get list of all Role Names with dt.trigger which is used to rerender datatable
  listOfRoles() {
    this.listOfRoleNames = [];
    $('#roleMasterTable').DataTable({ destroy: true }).destroy();
    this.roleMasterService.getRolesList()
      .then(res => {

        //this.listOfRoleNames = res.RolesList;
        //sort by created on
        this.listOfRoleNames = _.orderBy(res.RolesList, ['CreatedOn'], ['desc']);

        if (this.isGuestSuperAdmin) {
          this.listOfRoleNames = _.filter(res.RolesList, function (o) {
            if (o.RoleName != PermissionLinks.Admin) {
              return o;
             }
          })
        }
        this.dtTrigger.next();
        // setTimeout(() => {this.dtTrigger.next()});

      });
  }

  // listOfRolesWithoutDttrigger(){
  //   this.listOfRoleNames=[];
  //   this.roleMasterService.getRolesList()
  //   .then(res => {
  //     //console.log("res",res);
  //     this.listOfRoleNames = res.RolesList;
  //     //console.log("listOfRoleNames",this.listOfRoleNames);});
  // }

  //to initialise the form
  formInitialisation() {
    this.roleForm = this.fb.group({
      roleName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.roleNamePatternRegEx),
        ],
      ],
      arrayOfParentActivities: this.fb.array([]),
    });
  }

  //to get checkbox list and basically will create form model.
  getRoleActivityCheckboxList() {
    this.ngOnInit(); // this will get the updated list of role names and basic form initialsation with 0 parent and child checbox

    this.roleMasterService
      .roleActivityCheckboxList() //this will call will get parent and child activities as a whole
      .then(
        (res) => {
          this.parentActivityObject = res.Resources; //will store all data parent Activities

          //console.log("parentActivityObject", this.parentActivityObject);

          if (res) {
            //will display form only when the data has come.
            this.formData = true;
          }
          _.remove(this.parentActivityObject, function (a: any) {
            return a.MenuDisplayName == 'Roadmap';
          });
          var i = 0;
          const parentArray: FormArray = <FormArray>(
            this.roleForm.get('arrayOfParentActivities')
          );
          this.parentActivityObject.forEach((element) => {
            if (element.MenuDisplayName == 'Assessment') {
              _.remove(element.ChildResources, function (a: any) {
                return (
                  a.DisplayName == 'IC Execute' || a.DisplayName == 'IC Review'
                );
              });
            }
            parentArray.push(this.createParentFormGroup()); // this is creating  parent activity group
            //console.log("parent", parentArray);

            this.childActivityObject = element.ChildResources; // this will store current child activities
            //console.log(this.childActivityObject);
            this.createChildFormControls(
              this.parentActivityObject,
              this.childActivityObject,
              parentArray,
              i
            ); // this will create child activity inside parent group

            i++;
          });

          var a;
          this.totalNumberOfParentActivity = res.Resources.length; // this will store how many parent activities are there.
          this.isParentActivityChecked = []; // this will reinitialise this array
          for (a = 0; a < this.totalNumberOfParentActivity; a++) {
            this.isParentActivityChecked.push(false); // assign value=false to parent actvity checkbox initially.
            //console.log(this.isParentActivityChecked[a]);
          }

          // this.toCheckValidationForCheckbox();      //validation initialisation
          this.checkboxValidationFlag = false;
        },
        (error) => console.log('error of response', error)
      );
  }

  //to create Parent Groups
  createParentFormGroup(): FormGroup {
    const group = this.fb.group({});
    return group;
  }

  //to create children activity group inside parent
  createChildFormControls(
    parentActivityObject,
    childActivityObject,
    parentArray,
    i
  ) {
    const parentActivity: FormGroup = <FormGroup>parentArray.controls[i];

    childActivityObject.forEach((element) => {
      parentActivity.addControl(element.Url, this.fb.control(false));
    });
  }

  //parentactivity checkbox click event
  parentActivityCheckboxClicked(event, i) {
    const parentNode: FormGroup = <FormGroup>(
      this.arrayOfParentActivities.controls[i]
    );

    var childObject = this.parentActivityObject[i].ChildResources;

    if (event.target.checked) {
      this.isParentActivityChecked[i] = event.target.checked;

      childObject.forEach((child) => {
        parentNode.controls[child.Url].setValue(true);
      });
      this.checkboxValidationFlag = true;
      //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
    } else {
      this.isParentActivityChecked[i] = event.target.checked;
      childObject.forEach((child) => {
        parentNode.controls[child.Url].setValue(false);
      });
      this.checkboxValidationFlag = false;
      //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
    }

    this.toCheckValidationForCheckbox(); // to validate added now 12/21/17 6:59 pm
  }

  //Child Activity checkbox click event
  childCheckboxClicked(event, i) {
    // this.checkboxValidationFlag = false;
    const parentNode: FormGroup = <FormGroup>(
      this.arrayOfParentActivities.controls[i]
    );

    var childObject = this.parentActivityObject[i].ChildResources;
    var count = 0;
    childObject.forEach((child) => {
      if (parentNode.controls[child.Url].value) {
        count++;
      }
    });

    var checkboxId = 'parentCheckbox' + i;
    //console.log("getelementbyid", document.getElementById(checkboxId));
    var checkbox;
    checkbox = document.getElementById(checkboxId);

    if (count == childObject.length) {
      checkbox.indeterminate = false;
      this.isParentActivityChecked[i] = true;
      //console.log(" checkbox.indeterminate  = false");
    } else if (count < childObject.length && count > 0) {
      checkbox.indeterminate = true;
      //console.log(" checkbox.indeterminate  = true");
    } else if (count == 0) {
      checkbox.indeterminate = false;
      this.isParentActivityChecked[i] = false;
      //console.log(" checkbox.indeterminate  = false");
    }

    this.toCheckValidationForCheckbox();

    //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
  }

  //------------------------------------for validation of checkbox ------------------------------------------------------------------------------
  toCheckValidationForCheckbox() {
    var count = 0;
    for (var i = 0; i < this.totalNumberOfParentActivity; i++) {
      const parentNode: FormGroup = <FormGroup>(
        this.arrayOfParentActivities.controls[i]
      ); //this will hold one formgroup at a time in parentNode through loop

      //console.log("parent activity object")

      var childObject = this.parentActivityObject[i].ChildResources; // this will hold the child resources of current parent Node

      childObject.forEach((child) => {
        // this will only push those values which are true i.e checked
        if (parentNode.controls[child.Url].value) {
          count++; // this will tell if atleast one checkbox has a true value
        }
      });

      //console.log("final object", this.inputRoleName);
    }

    if (count) {
      this.checkboxValidationFlag = true;
      //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
    } else {
      this.checkboxValidationFlag = false;
      //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
    }
  }

  //------------------------------------ when input text box for RoleName gets change -----------------------------------------------------------
  inputRoleNameTextboxChangedDueToPaste(event) {
    const inputTextboxValue = this.roleForm.get('roleName').value;
    this.validationInputRoleName.setValue(inputTextboxValue.trim());
    //console.log("input roleform", this.roleForm.controls.roleName.value);
    this.inputPasteFlag = true;
  }

  inputRoleNameTextboxChanged(event) {
    if (!this.inputPasteFlag) {
      this.validationInputRoleName.setValue(event.target.value.trim());
    }
    this.validationInputRoleName.setValue(event.target.value.trim());
    this.inputPasteFlag = false;
  }

  // ---------------------------------------------------------To add new role -----------------------------------------------------------------------------------------

  submitAddRole() {
    this.inputRoleName = this.roleForm.get('roleName').value.trim();

    var finalRoleActivities: any = [];

    for (var i = 0; i < this.totalNumberOfParentActivity; i++) {
      const parentNode: FormGroup = <FormGroup>(
        this.arrayOfParentActivities.controls[i]
      ); //this will hold one formgroup at a time in parentNode through loop

      var childObject = this.parentActivityObject[i].ChildResources; // this will hold the child resources of current parent Node

      childObject.forEach((child) => {
        // this will only push those values which are true i.e checked
        if (parentNode.controls[child.Url].value) {
          finalRoleActivities.push(child.Url);
        }
      });

      //console.log("final object", this.inputRoleName, finalRoleActivities);
    }

    //console.log(this.roleForm);

    this.roleMasterService
      .addNewRole(this.inputRoleName, finalRoleActivities)
      .then((res) => {
        //console.log("roles added successfully", res);
        //console.log("roles added successfully", res.status);

        if (res.status == 0) {
          this.errorMessage = 'Role added successfully';
          this.headerSuccessFlag = true;
          $('#myModal').modal('hide');
          $('#errorMessageModal').modal('show');

          setTimeout(() => {
            $('#errorMessageModal').modal('hide');

            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.listOfRoles();
            });

            //clear state after adding record
            var datable = $('#roleMasterTable').DataTable();
            datable.state.clear();

            var a;
            for (a = 0; a < this.totalNumberOfParentActivity; a++) {
              this.isParentActivityChecked[a] = false;
            }
            this.headerSuccessFlag = false;
          }, 2000);
        } else {
          this.errorMessage = res.message;
          $('#myModal').modal('hide');
          this.headerErrorFlag = true;
          // this.headerErrorMessage = "Failure !"
          $('#errorMessageModal').modal('toggle');

          setTimeout(() => {
            $('#errorMessageModal').modal('toggle');
            this.headerErrorFlag = false;
          }, 2000);
        }
      });
  }

  //------------------------When modal closes, it should perform following operations.-------------------------------------------------------------------------------------
  closeForm() {
    // this.roleForm.reset();
    this.ngOnInit();
    //console.log("form closed");
  }

  //edit modal close
  editModalClose() {
    // $('#roleMasterTable').DataTable({destroy : true}).destroy();
    // this.ngOnInit();
    this.formInitialisation();
  }

  //------------------------------------------this function will hold the changes of the Role Name and will reflect in submitUpdateRole Function-----------------------
  editRoleNameObjectToPassIntoModal(roleObject) {
    //console.log("role Object to be edited", roleObject);

    this.temporaryHoldInputRoleName = '';
    this.inputRoleName = roleObject.RoleName;
    this.roleId = roleObject._id;
    this.editRoleObject = roleObject;

    //console.log("edit name", this.inputRoleName);

    this.temporaryHoldInputRoleName = this.inputRoleName;

    this.getRoleActivityCheckboxList();

    // const parentNode:FormGroup = <FormGroup>this.arrayOfParentActivities;

    this.roleForm.get('roleName').setValue(this.inputRoleName);

    this.roleMasterService.getRoleToEdit(this.inputRoleName).then((res) => {
      //console.log("edit response", res.resources);

      // $('#editingRoleModal').modal('toggle');

      var resultUrl: any = [];
      //console.log(res.resources);
      res.resources.forEach((resultObject) => {
        //console.log("resob", resultObject);
        resultObject.ChildResources.forEach((childResultObject) => {
          resultUrl.push(childResultObject.Url);
        });
      });
      //console.log("resultUrl", resultUrl);

      //  var c=0;
      var count = 0;

      for (var i = 0; i < this.totalNumberOfParentActivity; i++) {
        const parentNode: FormGroup = <FormGroup>(
          this.arrayOfParentActivities.controls[i]
        ); //this will hold one formgroup at a time in parentNode through loop
        var childLength = 0;
        //console.log("parent activity object", parentNode);

        var childObject = this.parentActivityObject[i].ChildResources; // this will hold the child resources of current parent Node
        //console.log("child", childObject);

        var resultChildLength = 0;
        childObject.forEach((child) => {
          // this will only push those values which are true i.e checked

          resultUrl.forEach((element) => {
            if (child.Url == element) {
              parentNode.controls[child.Url].setValue(true);
              resultChildLength++;
            }
          });

          childLength++;
        });

        if (resultChildLength) {
          this.checkboxValidationFlag = true;
        }

        //for indeterminate checkbox
        var checkboxId = 'parentCheckboxEditModal' + i;
        //console.log("getelementbyid", document.getElementById(checkboxId));
        var checkbox;
        checkbox = document.getElementById(checkboxId);

        //console.log("checkbox", checkbox);

        if (childLength == resultChildLength) {
          // this.renderer.setElementProperty(checkbox.nativeElement,'indeterminate',false);
          checkbox.indeterminate = false;
          //console.log("equal to");
          this.isParentActivityChecked[i] = true;
        } else if (resultChildLength < childLength && resultChildLength > 0) {
          // this.renderer.setElementProperty(checkbox.nativeElement,'indeterminate',true);
          checkbox.indeterminate = true;
          //console.log("not equal to");
        } else if (resultChildLength == 0) {
          // this.renderer.setElementProperty(checkbox.nativeElement,'indeterminate',false);
          checkbox.indeterminate = false;
          //console.log("equal to 0");
          this.isParentActivityChecked[i] = false;
        }

        //console.log("count", count)
        //console.log("final object", this.inputRoleName);
      }
      this.toCheckValidationForCheckbox();

      //console.log("count", count)

      $('#editingRoleModal').modal('toggle');

      if (this.isGuestSuperAdmin) {
        this.roleForm.controls.roleName.disable();
        this.roleForm.controls.roleName.updateValueAndValidity();
      }
    });

    //console.log("form model", this.roleForm);

    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    //   });
    // this.dtTrigger.next();
  }

  //---------------------------------------------------------this function will call the url to make the changes-----------------------------------------------------------
  // submitUpdateRole(){
  //   //
  //   var id = this.editRoleObject._id;
  //   var roleName= this.inputRoleName;
  //   this.roleMasterService.editRole(id,roleName)
  //   .then(res=>{
  //     //console.log("edit role response message", res);
  //     //foreach is used to reflect the changes made in our view component by matching the id from repsonse with each object in rolenameslist's id.
  //     this.listOfRoleNames.forEach(roleObject => {
  //       if(roleObject._id == id)
  //       {
  //         roleObject.RoleName = res.UpdatedRole.RoleName ;
  //       }
  //       //console.log("response edit for each", roleObject);
  //     })
  //   });

  //   $('#editingRoleModal').modal('hide');
  //   this.inputRoleName = "";
  // }

  submitUpdateRole() {
    this.inputRoleName = this.roleForm.get('roleName').value;

    var finalRoleActivities: any = [];

    for (var i = 0; i < this.totalNumberOfParentActivity; i++) {
      const parentNode: FormGroup = <FormGroup>(
        this.arrayOfParentActivities.controls[i]
      ); //this will hold one formgroup at a time in parentNode through loop

      //console.log("paarent activity object")
      var childObject = this.parentActivityObject[i].ChildResources; // this will hold the child resources of current parent Node

      childObject.forEach((child) => {
        // this will only push those values which are true i.e checked
        if (parentNode.controls[child.Url].value) {
          finalRoleActivities.push(child.Url);
        }
      });

      //console.log("final object", this.roleId, this.inputRoleName, finalRoleActivities);
    }

    //console.log(this.roleForm);

    this.roleMasterService
      .editRole(this.roleId, this.inputRoleName, finalRoleActivities)
      .then((res) => {
        //console.log("roles updated successfully", res);

        // $('#editingRoleModal').modal('hide');
        // this.errorMessage = res.message;

        if (res.status == 0) {
          //
          this.errorMessage = 'Role updated successfully';
          this.headerSuccessFlag = true;

          // this.listOfRolesWithoutDttrigger(); //This will reflect the changes inside the table without destroying and rerendering the table

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.listOfRoles();
          });

          $('#editingRoleModal').modal('hide');
          $('#errorMessageModal').modal('show');
          setTimeout(() => {
            $('#errorMessageModal').modal('hide');

            // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //   dtInstance.destroy();
            //   });
            this.headerSuccessFlag = false;
            this.ngOnInit();
          }, 3000);
        } else {
          this.errorMessage = res.message;
          $('#editingRoleModal').modal('hide');
          this.headerErrorFlag = true;
          $('#errorMessageModal').modal('show');

          setTimeout(() => {
            $('#errorMessageModal').modal('hide');
            this.headerErrorFlag = false;
            // this.roleForm.get('roleName').setValue(this.temporaryHoldInputRoleName);
          }, 3000);
        }
        // this.listOfRoles();
        // this.formInitialisation();
      });
  }

  //---this function will hold the content of the role clicked for deletion so that deleterole function will be able to catch the object-----------------------
  deleteRoleNameObjectToPassIntoModal(roleObject) {
    this.deleteRoleObject = roleObject;
    //console.log("role to be deleted", this.deleteRoleObject);
  }

  deleteRole() {
    var roleId = this.deleteRoleObject._id;
    this.roleMasterService.deleteRole(roleId).then((res) => {
      //console.log("response after deletion", res);

      // this.listOfRoles();
      // this.formInitialisation();

      // this.errorMessage = res.message;
      if (res.status == 0) {
        this.errorMessage = 'Role deleted successfully';
        $('#roleDeletionMessageModal').modal('hide');
        this.headerSuccessFlag = true;
        $('#errorMessageModal').modal('show');

        setTimeout(() => {
          $('#errorMessageModal').modal('hide');

          this.inputRoleName = '';

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.listOfRoles();
          });

          this.headerSuccessFlag = false;
        }, 3000);
      } else {
        this.errorMessage = res.message;
        $('#roleDeletionMessageModal').modal('hide');
        this.headerErrorFlag = true;
        $('#errorMessageModal').modal('show');

        setTimeout(() => {
          $('#errorMessageModal').modal('hide');
          this.headerErrorFlag = false;
        }, 3000);
      }
    });
  }

  // --------------------------------------------------------------------------------------------------------------------------

  //Child Activity checkbox click event
  childCheckboxEditModalClicked(event, i) {
    // this.checkboxValidationFlag = false;
    //console.log('event,i', event, i)
    const parentNode: FormGroup = <FormGroup>(
      this.arrayOfParentActivities.controls[i]
    );

    var childObject = this.parentActivityObject[i].ChildResources;
    var a = 0;
    childObject.forEach((child) => {
      //

      if (parentNode.controls[child.Url].value) {
        // //console.log("parentNode.controls[child.Url].value",parentNode.controls[child.Url].value)
        a++;
      }
    });

    var checkboxId = 'parentCheckboxEditModal' + i;
    //console.log("getelementbyid", document.getElementById(checkboxId));
    var checkbox;
    checkbox = document.getElementById(checkboxId);

    if (a == childObject.length) {
      checkbox.indeterminate = false;
      this.isParentActivityChecked[i] = true;
      //console.log(" checkbox.indeterminate  = false");
      // //console.log("this.checkpar", this.checkedParent[i]);
    } else if (a < childObject.length && a > 0) {
      checkbox.indeterminate = true;
      //console.log(" checkbox.indeterminate  = true");
    } else if (a == 0) {
      checkbox.indeterminate = false;
      this.isParentActivityChecked[i] = false;
      //console.log(" checkbox.indeterminate  = false");
      // //console.log("this.checkpar", this.checkedParent[i]);
    }

    this.toCheckValidationForCheckbox(); //added now 12/21/17 6:59 pm

    //console.log("this.checkboxValidationFlag", this.checkboxValidationFlag);
  }
}
