// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// LOCAL UI ONLY
// demo.${domain}
// dev.${domain}
const subdomain = 'secretarial'  //const subdomain = 'dev'
const domain = 'emkayglobal.com'
const BasePATH_GFMRootPath = `C:/GEM/GFM`;
const BaseURL_MasterAPI = `https://api.goveva.com`;
const BaseURL_PSSAPI = `https://${subdomain}.${domain}`;
const BaseURL_PSSUI = `https://${subdomain}.${domain}`;
const BaseURL_GEMURL = `https://${subdomain}.${domain}/gem`;
const BaseURL_PSSFileurl = `https://${subdomain}.${domain}/capi/`;
const BaseURL_PSSFileNotValidurl = `https://${subdomain}.${domain}/capi`;
//Prod
const WebexUrl = `https://${subdomain}.${domain}/webex/index.html`;
const BaseURL_UIBaseUrl = `https://${subdomain}.${domain}`;
const BaseURL_GovevaAPI = `https://${subdomain}.${domain}`; // Client API
const GovevaApipath = `gapi/api/v1/`;

//Local Frontend
// const WebexUrl = `http://localhost:4200/webex/index.html`;
// const BaseURL_UIBaseUrl = `http://localhost:4200`;
// const BaseURL_GovevaAPI = `https://${subdomain}.${domain}`; // Client API
// const GovevaApipath = `gapi/api/v1/`;

//Local frontend + backend
// const WebexUrl = `http://localhost:4200/webex/index.html`;
// const BaseURL_UIBaseUrl = `http://localhost:4200`;
// const BaseURL_GovevaAPI = `http://localhost:5000`; // Client API
// const GovevaApipath = `api/v1/`;



/*
const BasePATH_GFMRootPath = '/home/ec2-user/GEM/GFM/';
const BaseURL_MasterAPI = 'https://test-masterapi.${domain}.com';
const BaseURL_GovevaAPI = 'https://dev.${domain}.com'; // Client API
const BaseURL_PSSAPI = 'https://dev.${domain}.com';
const BaseURL_PSSUI = 'https://dev.${domain}.com';
const BaseURL_GEMURL = 'https://dev.${domain}.com/gemapi';
const BaseURL_PSSFileurl = 'https://dev.${domain}.com/capi/';
const BaseURL_PSSFileNotValidurl = 'https://dev.${domain}.com/complianceapi';
const WebexUrl = 'http://localhost:4200/assets/html/test.html';
const BaseURL_UIBaseUrl = 'http://localhost:4200';
const GovevaApipath = 'gapi/api/v1/';
*/

export const environment = {
  production: false,
  envName: 'development',
  gemIp_domain : "staging.goveva.com:8085" ,//52.66.23.123:8085 -- previous value
  FinalMenu:{
    Master:true,
    ESG:false,
    Complaince:true,
    UPSI:false,
    NCD:false,   //Keeping in staging to be false if not subscribed by Client
    Laws:false,  //Keeping in staging to be false if not subscribed by Client
    Admin:true,
    File:true
  },
  Service: {
    All: false,
    PSS: true,
    GovEva: true,
    UPSI: true,
    NCD: true,
    BoardEvaluation: true,
    GEM: true,
    GemWeb: true,
    Chat: false,
    Governance: true,
    RegularMeeting: true,
    MeetingMakerWithAgenda: false,
    MeetingMakerWithoutAgenda: false,
    // GemWeb: false,
    // Governance: true,
    // RegularMeeting: false,
    // MeetingMakerWithAgenda : false,
    // MeetingMakerWithoutAgenda : false,
    CompanySetupWithoutMasterApi: false,
    AdobePdfEmbedKey : "279969b4c017419ca5e312654130f7e8",  //localhostKey: 8c0cd670273d451cbc9b351b11d22318

    //Authorisation key check with the master API
    authorization: 'QmVhcmVyIDcxRDUwRjk5ODc1Mjk=',
  },

  url: `${BaseURL_MasterAPI}/api/v1/secure/`,
  clientUrl: `${BaseURL_GovevaAPI}/${GovevaApipath}secure/`,
  nonValidateUrl: `${BaseURL_GovevaAPI}/${GovevaApipath}`,
  pssServerUrl: `${BaseURL_PSSAPI}/capi/api/v1/secure/`,
  pssUIBaseUrl: `${BaseURL_PSSUI}/compliance/#/`,
  pssUIURL: `${BaseURL_PSSUI}/compliance/#/`,
  serverIP: `${BaseURL_GovevaAPI}/gapi/`,
  GEMURL: `${BaseURL_GEMURL}/api/`,
  GFMRootPath: BasePATH_GFMRootPath,
  sessionTime: 28800000,
  PSSFileurl: BaseURL_PSSFileurl,
  PSSFileNotValidurl: `${BaseURL_PSSFileNotValidurl}/api/v1/`,
  webexUrl: WebexUrl,
  UIBaseUrl: BaseURL_UIBaseUrl,
  licenseKey:'HXjIKysyTGmk_E9s0yB84kosqWfOG3KDtRwhDvGWqA0WWceef6OMtbQ4BVZpsOVfM9oBeMn2TBZ7NJCNY27jpz4tLYxg6wuSMhaopb167uqIBxlsc0xe_UjFm-iKeL6W9kFlkzVqBrqg5CuZk0LSRYrqaiFWf-tY0bjIQCYiSBdTJZtZUf9JA2GOmC4fwxsnMnYugFij3xm9l7v4XE3yuB8-VXSxTcux_hBGIl3yQLDcKJAIdASKfW56b6gUSsF3uhzo0kRmAimUOsGGtqcvxTEOrX8uGYkNMyHTrVY3BgpPRj-ewsqAFvLWcbgbzS3TUl-t2uxuGI1ZGnhz2dWA42rcQRQ5IQGq6xiQ8ykRditC115EA0bDBC-72fFP1EACttFK9nBD6pPEcBZUNk4sJ3Cs2WKLQNBvPcuLXk-0Iogzlf87otX117pSQWycW8yLHm2o4JyQyFPqFR0z1e8Gq6DjMLFJyRu44Qbe6BrRPJa-TG2Wa77N1ePIlJnMsox1GDXcSIZ7WL5PpvmGjI_f1wpbcIAxJO50ITUBXkJNh3gu80AKlAAr8D_AhWIlAkitT3ZrrQriTUnXPeSy9Lo45nSq_rPOQTuBqKNzVrQmDR4='  
};

export const PssLink = {
  //Governance
  Reports: 'Reports',
  //Compliance
  Meetings: `${environment.pssUIBaseUrl}meeting`,
  Agenda: `${environment.pssUIBaseUrl}agenda`,
  AgendaSearch: `${environment.pssUIBaseUrl}agendaSearch`,
  Regulation: `${environment.pssUIBaseUrl}regulation`,
  //Report Menu
  ComplianceSnapshot: `${environment.pssUIBaseUrl}complianceSnapshot`,
  TaskStatus: `${environment.pssUIBaseUrl}taskStatus`,
  // Document Management
  Commitee: `${environment.pssUIBaseUrl}committee`,
  Template: `${environment.pssUIBaseUrl}template`,
  KMP: `${environment.pssUIBaseUrl}KMP`,
  Director: `${environment.pssUIBaseUrl}director`,
  CompanyFinancialDetails: `${environment.pssUIBaseUrl}company/financeDetails`,
  AgendaCategories: `${environment.pssUIBaseUrl}category`,
  Documents: `${environment.pssUIBaseUrl}documentManagement`,
  DocumentType: `${environment.pssUIBaseUrl}documentType`,
  EditDirector: `${environment.pssUIBaseUrl}director/edit/{userId}`,
  EditKMP: `${environment.pssUIBaseUrl}KMP/edit/{userId}`,
  Group: `${environment.pssUIBaseUrl}group`,
  LibraryTaskList: `${environment.pssUIBaseUrl}tasks/library`,
  ActivatedTaskList: `${environment.pssUIBaseUrl}tasks/activated`,
  ProcessList: `${environment.pssUIBaseUrl}process`,
  ActivatedProcessTasks: `${environment.pssUIBaseUrl}activatedprocess`,
  Holiday: `${environment.pssUIBaseUrl}holiday`,
  LawsAndRegulations: `${environment.pssUIBaseUrl}compliance`,
  EmailTemplatesCompliance: `${environment.pssUIBaseUrl}emailReminderTemplates`,
  UPSISharer: `${environment.pssUIBaseUrl}upsisharer`,
  UPSIRecipient: `${environment.pssUIBaseUrl}upsirecipient`,
  UPSIDetails: `${environment.pssUIBaseUrl}upsidetails`,
  UPSIEmailLog: `${environment.pssUIBaseUrl}upsiEmailsLog`,
  UPSIMaster: `${environment.pssUIBaseUrl}upsimaster`,
  UPSIParam: `${environment.pssUIBaseUrl}upsimaster`,
  Templates: `${environment.pssUIBaseUrl}docxTemplates`,
  defaultMeeting: `${environment.pssUIBaseUrl}defaultMeeting`,
  agendaGroup: `${environment.pssUIBaseUrl}agendaGroup`,
  inspectionList: `${environment.pssUIBaseUrl}inspectionList`,
  proxyList: `${environment.pssUIBaseUrl}proxyList`,
  masterbankparticulars: `${environment.pssUIBaseUrl}masterbankparticulars`,
  powerofattorneylist: `${environment.pssUIBaseUrl}powerofattorneylist`,
  paymentOfDividendList: `${environment.pssUIBaseUrl}paymentOfDividendList`,
  investmentlist: `${environment.pssUIBaseUrl}investment-list`,
  ecslist: `${environment.pssUIBaseUrl}ecslist`,
  ben3list: `${environment.pssUIBaseUrl}ben3list`,
  documentundercommonseallist: `${environment.pssUIBaseUrl}documentundercommonseallist`,
  masterinvestorcomplaints: `${environment.pssUIBaseUrl}masterinvestorcomplaints`,
  dividendMandateList: `${environment.pssUIBaseUrl}dividendMandateList`,
  postallballotlist: `${environment.pssUIBaseUrl}postallballotlist`,
  chg7List: `${environment.pssUIBaseUrl}chg7List`,
  mbp2List: `${environment.pssUIBaseUrl}mbp2List`,
  mbp3List: `${environment.pssUIBaseUrl}mbp3List`,
  mbp4List: `${environment.pssUIBaseUrl}mbp4List`,
  mbp4bList: `${environment.pssUIBaseUrl}mbp4bList`,
  mgt1list: `${environment.pssUIBaseUrl}mgt1list`,
  mgt2list: `${environment.pssUIBaseUrl}mgt2-list`,
  sh2list: `${environment.pssUIBaseUrl}sh2-list`,
  sh3list: `${environment.pssUIBaseUrl}sh3-list`,
  mastersh10list: `${environment.pssUIBaseUrl}mastersh10list`,
  mastersh6: `${environment.pssUIBaseUrl}mastersh6`,
  depositorList: `${environment.pssUIBaseUrl}depositorList`,
  NCDDocumentGenerationSetup: `${environment.pssUIBaseUrl}ncd/document/generation/mster/setup`,
  ncdIssuesList: `${environment.pssUIBaseUrl}ncd-issues-List`,
  ncdLibrary: `${environment.pssUIBaseUrl}ncd-Library`,
  ncdAllotteesLibrary: `${environment.pssUIBaseUrl}ncd-allottees-Library`,
  emailReminderTemplatesTask: `${environment.pssUIBaseUrl}emailReminderTemplates/Task`,
  emailReminderTemplatesUpsi: `${environment.pssUIBaseUrl}emailReminderTemplates/Upsi`,
  emailReminderTemplatesNcd: `${environment.pssUIBaseUrl}emailReminderTemplates/Ncd`,
  userTeamMaster: `${environment.pssUIBaseUrl}userTeamMaster`,
  unapprovedLibraryTasks: `${environment.pssUIBaseUrl}tasks/unapprovedLibrary`,
  department: `${environment.pssUIBaseUrl}department`,
  branch: `${environment.pssUIBaseUrl}branch`,
  userteam: `${environment.pssUIBaseUrl}user/team`,
  ComplianceItem: `${environment.pssUIBaseUrl}complianceItem`,
  ComplianceCertificateLibrary: `${environment.pssUIBaseUrl}complianceCertificateList`,
  ActivatedComplianceCertificate: `${environment.pssUIBaseUrl}activatedComplianceCertificate`,
  MeetingEmailTemplates: `${environment.pssUIBaseUrl}emailReminderTemplates/Meeting`,
  ComplianceCertificateEmailTemplates: `${environment.pssUIBaseUrl}emailReminderTemplates/ComplianceCertificate`,
  //Laws & Regulations
  LawRegulation : `${environment.pssUIBaseUrl}compliance`,
};

// // The file contents for the current environment will overwrite these during build.
// // The build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // The list of which env maps to which file can be found in `.angular-cli.json`.

// // const BasePATH_GFMRootPath = '/home/ec2-user/GEM/GFM/';
// // const BaseURL_MasterAPI = 'https://test-masterapi.${domain}.com';
// // const BaseURL_GovevaAPI = 'https://ardvark.${domain}.com'; // Client API
// // const BaseURL_PSSAPI = 'https://ardvark.${domain}.com';
// // const BaseURL_PSSUI = 'https://ardvark.${domain}.com';
// // const BaseURL_GEMURL = 'https://ardvark.${domain}.com/gemapi';
// // const BaseURL_PSSFileurl = 'https://ardvark.${domain}.com/capi/';
// // const BaseURL_PSSFileNotValidurl = 'https://ardvark.${domain}.com/complianceapi';
// // const WebexUrl = 'http://localhost:4200/assets/html/test.html';
// // const BaseURL_UIBaseUrl = 'http://localhost:4200';
// // const GovevaApipath = 'gapi/api/v1/';

// // const BaseURL_PSSUI = 'http://35.154.99.11'
// // const BaseURL_PSSAPI = 'http://35.154.99.11:82'
// // const BaseURL_GovevaAPI = 'http://192.168.0.11'
// // const BaseURL_MasterAPI = 'http://192.168.0.108'

// export const environment = {
//   production: false,
//   envName: 'default',
//   Service: {
//     All: false,
//     PSS: true,
//     GovEva: true,
//     UPSI: true,
//     NCD: true,
//     BoardEvaluation: true,
//     GEM: true,
//     GemWeb: true,
//     Chat: false,
//     Governance: true,
//     RegularMeeting: true,
//     MeetingMakerWithAgenda: false,
//     MeetingMakerWithoutAgenda: false,
//     // GemWeb: false,
//     // Governance: true,
//     // RegularMeeting: false,
//     // MeetingMakerWithAgenda : false,
//     // MeetingMakerWithoutAgenda : false,
//     CompanySetupWithoutMasterApi: false,
//     //Authorisation key check with the master API
//     authorization: 'QmVhcmVyIDcxRDUwRjk5ODc1Mjk=',
//   },

//   // url: `${BaseURL_MasterAPI}/api/v1/secure/`,
//   // clientUrl: `${BaseURL_GovevaAPI}/${GovevaApipath}secure/`,
//   // nonValidateUrl: `${BaseURL_GovevaAPI}/${GovevaApipath}`,
//   // pssServerUrl: `${BaseURL_PSSAPI}/complianceapi/v1/secure/`,
//   // pssUIBaseUrl: `${BaseURL_PSSUI}/compliance/#/`,
//   // pssUIURL: `${BaseURL_PSSUI}/compliance/#/`,
//   // serverIP: BaseURL_GovevaAPI,
//   // GEMURL: `${BaseURL_GEMURL}/api/`,
//   // GFMRootPath: BasePATH_GFMRootPath,
//   // sessionTime: 28800000,
//   // PSSFileurl: BaseURL_PSSFileurl,
//   // PSSFileNotValidurl: `${BaseURL_PSSFileNotValidurl}/api/v1/`,
//   // webexUrl: WebexUrl,
//   // UIBaseUrl: BaseURL_UIBaseUrl,
// };

// export const PssLink = {
//   //Governance
//   Reports: 'Reports',
//   //Compliance
//   // Meetings: environment.pssUIBaseUrl + 'meeting',
//   // Agenda: environment.pssUIBaseUrl + 'agenda',
//   // Regulation: environment.pssUIBaseUrl + 'regulation',
//   // //Report Menu
//   // ComplianceSnapshot: environment.pssUIBaseUrl + 'complianceSnapshot',
//   // TaskStatus: environment.pssUIBaseUrl + 'taskStatus',
//   // // Document Management
//   // Commitee: environment.pssUIBaseUrl + 'committee',
//   // Template: environment.pssUIBaseUrl + 'template',
//   // KMP: environment.pssUIBaseUrl + 'KMP',
//   // Director: environment.pssUIBaseUrl + 'director',
//   // CompanyFinancialDetails: environment.pssUIBaseUrl + 'company/financeDetails',
//   // AgendaCategories: environment.pssUIBaseUrl + 'category',
//   // Documents: environment.pssUIBaseUrl + 'documentManagement',
//   // DocumentType: environment.pssUIBaseUrl + 'documentType',
//   // EditDirector: environment.pssUIBaseUrl + 'director/edit/{userId}',
//   // EditKMP: environment.pssUIBaseUrl + 'KMP/edit/{userId}',
//   // Group: environment.pssUIBaseUrl + 'group',
//   // LibraryTaskList: environment.pssUIBaseUrl + 'tasks/library',
//   // ActivatedTaskList: environment.pssUIBaseUrl + 'tasks/activated',
//   // ProcessList: environment.pssUIBaseUrl + 'process',
//   // ActivatedProcessTasks: environment.pssUIBaseUrl + 'activatedprocess',
//   // Holiday: environment.pssUIBaseUrl + 'holiday',
//   // LawsAndRegulations: environment.pssUIBaseUrl + 'compliance',
//   // EmailTemplatesCompliance: environment.pssUIBaseUrl + 'emailReminderTemplates',
//   // UPSISharer: environment.pssUIBaseUrl + 'upsisharer',
//   // UPSIRecipient: environment.pssUIBaseUrl + 'upsirecipient',
//   // UPSIDetails: environment.pssUIBaseUrl + 'upsidetails',
//   // UPSIEmailLog: environment.pssUIBaseUrl + 'upsiEmailsLog',
//   // UPSIMaster: environment.pssUIBaseUrl + 'upsimaster',
//   // UPSIParam: environment.pssUIBaseUrl + 'upsimaster',
//   // Templates: environment.pssUIBaseUrl + 'docxTemplates',
//   // defaultMeeting: environment.pssUIBaseUrl + 'defaultMeeting',
//   // agendaGroup: environment.pssUIBaseUrl + 'agendaGroup',
//   // inspectionList: environment.pssUIBaseUrl + 'inspectionList',
//   // proxyList: environment.pssUIBaseUrl + 'proxyList',
//   // masterbankparticulars: environment.pssUIBaseUrl + 'masterbankparticulars',
//   // powerofattorneylist: environment.pssUIBaseUrl + 'powerofattorneylist',
//   // paymentOfDividendList: environment.pssUIBaseUrl + 'paymentOfDividendList',
//   // investmentlist: environment.pssUIBaseUrl + 'investment-list',
//   // ecslist: environment.pssUIBaseUrl + 'ecslist',
//   // ben3list: environment.pssUIBaseUrl + 'ben3list',
//   // documentundercommonseallist:
//   //   environment.pssUIBaseUrl + 'documentundercommonseallist',
//   // masterinvestorcomplaints:
//   //   environment.pssUIBaseUrl + 'masterinvestorcomplaints',
//   // dividendMandateList: environment.pssUIBaseUrl + 'dividendMandateList',
//   // postallballotlist: environment.pssUIBaseUrl + 'postallballotlist',
//   // chg7List: environment.pssUIBaseUrl + 'chg7List',
//   // mbp2List: environment.pssUIBaseUrl + 'mbp2List',
//   // mbp3List: environment.pssUIBaseUrl + 'mbp3List',
//   // mbp4List: environment.pssUIBaseUrl + 'mbp4List',
//   // mbp4bList: environment.pssUIBaseUrl + 'mbp4bList',
//   // mgt1list: environment.pssUIBaseUrl + 'mgt1list',
//   // mgt2list: environment.pssUIBaseUrl + 'mgt2-list',
//   // sh2list: environment.pssUIBaseUrl + 'sh2-list',
//   // sh3list: environment.pssUIBaseUrl + 'sh3-list',
//   // mastersh10list: environment.pssUIBaseUrl + 'mastersh10list',
//   // mastersh6: environment.pssUIBaseUrl + 'mastersh6',
//   // depositorList: environment.pssUIBaseUrl + 'depositorList',
//   // NCDDocumentGenerationSetup:
//   //   environment.pssUIBaseUrl + 'ncd/document/generation/mster/setup',
//   // ncdIssuesList: environment.pssUIBaseUrl + 'ncd-issues-List',
//   // ncdLibrary: environment.pssUIBaseUrl + 'ncd-Library',
//   // ncdAllotteesLibrary: environment.pssUIBaseUrl + 'ncd-allottees-Library',
//   // emailReminderTemplatesTask:
//   //   environment.pssUIBaseUrl + 'emailReminderTemplates/Task',
//   // emailReminderTemplatesUpsi:
//   //   environment.pssUIBaseUrl + 'emailReminderTemplates/Upsi',
//   // emailReminderTemplatesNcd:
//   //   environment.pssUIBaseUrl + 'emailReminderTemplates/Ncd',
//   // userTeamMaster: environment.pssUIBaseUrl + 'userTeamMaster',
//   // unapprovedLibraryTasks: environment.pssUIBaseUrl + 'tasks/unapprovedLibrary',
//   // department: environment.pssUIBaseUrl + 'department',
//   // branch: environment.pssUIBaseUrl + 'branch',
//   // userteam: environment.pssUIBaseUrl + 'user/team',
//   // ComplianceItem: environment.pssUIBaseUrl + 'complianceItem',
//   // ComplianceCertificateLibrary:
//   //   environment.pssUIBaseUrl + 'complianceCertificateList',
//   // ActivatedComplianceCertificate:
//   //   environment.pssUIBaseUrl + 'activatedComplianceCertificate',
//   // MeetingEmailTemplates:
//   //   environment.pssUIBaseUrl + 'emailReminderTemplates/Meeting',
//   // ComplianceCertificateEmailTemplates:
//   //   environment.pssUIBaseUrl + 'emailReminderTemplates/ComplianceCertificate',
// };
