import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class BISCommunicationService {

    constructor(private http: HttpClient) {

    }

    //get list of Compliance Task
    url: any;

    getComplianceTaskList(cin, startDate, endDate): Promise<any> {

        return this.http.get(environment.clientUrl + 'BISapiMaster/getComplianceTaskList?CIN=' + cin + '&FromDate=' + startDate + '&ToDate=' + endDate)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get list of additional actions list
    getAdditionalActionsList(cin, startDate, endDate): Promise<any> {

        return this.http.get(environment.clientUrl + 'BISapiMaster/getAdditionalActionsList?CIN=' + cin + '&FromDate=' + startDate + '&ToDate=' + endDate)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get list of Time Based Compliance List
    getTimeBasedComplianceList(cin, startDate, endDate): Promise<any> {

        return this.http.get(environment.clientUrl + 'BISapiMaster/getTimeBasedComplianceList?CIN=' + cin + '&FromDate=' + startDate + '&ToDate=' + endDate)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get task details
    getTaskDetails(taskType, taskId): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/getTaskDetails?TaskID=' + taskId + '&TaskType=' + taskType)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get meeting list
    getMeetingList(cin, startDate, endDate) : Promise<any>{
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetMeetingList?CIN=' + cin + '&FromDate=' + startDate + '&ToDate=' + endDate)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get meeting details
    getMeetingDetails(meetingId): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetMeetingDetails?MeetingId=' + meetingId )
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get Meeting Resolution List
    getMeetingResolutionList(meetingId): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetMeetingResolutionList?MeetingId=' + meetingId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get Meeting Action Point List
    getMeetingActionPointList(meetingId): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetMeetingActionPointList?MeetingId=' + meetingId)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get Notification List
    getNotificationList(cin): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetNotificationList?CIN=' + cin)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

    //get Notification detail List
    getNotificationDetailList(cin): Promise<any> {
        return this.http.get(environment.clientUrl + 'BISapiMaster/GetNotificationDetailList?CIN=' + cin)
            .toPromise()
            .then(response => {
                return Promise.resolve(response);
            })
    }

}