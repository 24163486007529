import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SuperAdminSetupService {
  constructor(private http: HttpClient) {}

  createUser(userObject): Promise<any> {
    const url = environment.nonValidateUrl + "user/register";
    return this.http
      .post(url, userObject)
      .toPromise()
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
