import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  DoCheck,
} from '@angular/core';
import { UserMasterService } from '../../../services/user-master.service';
import { govevaFileManagerService } from '../../../services/govevaFileManager.service';
import { CompanyMasterService } from '../../../services/companymaster.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GfmService } from '../../../services/gfm.service';
import { environment } from '../../../../environments/environment';
import { MeetingMasterService } from '../../../services/meetingMaster.service';
import {
  ActivatedRoute,
  RouterState,
  Router,
  NavigationEnd,
  UrlSerializer,
  UrlTree,
  DefaultUrlSerializer,
} from '@angular/router';
import { CommonFunction } from './../../../helpers/commonFunction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagPopupComponentComponent } from '../../govevaFileManager/tag-popup-component/tag-popup-component.component';
import { GfmconfirmationPopupComponent } from '../../govevaFileManager/gfmconfirmation-popup/gfmconfirmation-popup.component';

import { RoadmapModuleService } from '../../../services/roadmapModule.service';
import { TypeOfUser } from '../../../enums/TypeOfUser';
import * as _ from 'lodash';

import { GfmPopupforShareComponent } from '../../govevaFileManager/gfm-popupfor-share/gfm-popupfor-share.component';
import { safeJsonStringify } from '../../../shared/utils';

declare var $: any;
declare var NProgress: any;
declare var jQuery: any;

@Component({
  selector: 'app-gfm-dashboard',
  templateUrl: 'gfm-dashboard.component.html',
  styleUrls: ['./gfm-dashboard.component.css'],
})
export class GfmDashboardComponent implements OnInit, DoCheck {
  recordPath: any = ' ';
  fileList: any = [];
  recentFileList: any = [];
  folderList: any = [];
  shareList: any = [];
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  searchResultsList: any = [];
  uploadForm = new FormGroup({
    folderName: new FormControl('', [
      Validators.required,
      Validators.pattern(new RegExp('^[a-zA-Z0-9_  -]+$')),
    ]),
    folderNameAfterRename: new FormControl('', [
      Validators.required,
      Validators.pattern(new RegExp('^[a-zA-Z0-9_  -]+$')),
    ]),
    fileName: new FormControl('', []),
    searchByValue: new FormControl('', []),
    multipleFileDocumentForUpload: new FormControl('', []),
  });
  filedata: any;
  fileName: any;
  token: any;
  companyID: any;
  companyName: any;
  complianceEngine: any;
  complianceEngineUrl: any;
  governanceUrl: any;
  recordDetails: any;
  uniqueUserId: any;
  userId: any;
  gemAccessUrl: any;
  folderName: any;
  multipleFileDocumentForUpload: any;
  count: any;
  uploadMultipleFileDocument: any = [];
  gfmResponse: any;
  recordNameAlreadyPresent: boolean = false;
  contextmenu: boolean = false;
  fileExtension: any;
  fileNameWithExtension: any;
  folderNameAfterRename: any;
  users: any = [];
  userListToBind: any = [];
  searchByType: any = 'NAME';
  searchByValue: any;
  isDeleteAllSelected: boolean = false;
  multipleRecordsListToDelete: any = [];
  isRecentFile: boolean = true;
  fileId: any;
  breadcrumbs: any = [];
  baseFileManagerPath: any = '';
  baseFileManagerRecordPathWithParams: any = '';
  params: any = '';
  sharedFileName: any = '';
  gfmRecordId: any = '';
  FileExtension: boolean = true;
  multifileExtension: boolean = true;
  loggedInUserUniqueId: any;

  showBreadCrumpath: boolean = false;
  showShareListDiv: boolean = false;
  folderNamePresentWhileRename: boolean = false;
  fileAfterRenameSame: boolean = false;

  gemAccessURL: any = '';

  showAddTagButtonForCreateFolder: boolean = false;
  selectedTagsForFolder: any = [];
  showAddTagDiv: boolean = false;

  showBreadcrumbs: boolean = false;
  renameFileDetails: any;

  isCompliance: boolean = false;
  gfmRecordIdForFolder: any = '';
  isComplianceFileParameter: any;
  showSpinner: boolean = false;
  sharedFolder: boolean = false;
  recordType: any = '';
  gfmRecordObject: any;
  gfmRecordArray: any = [];
  hierarchyArray: any = [];
  index = 0;
  globaluniqueUserId: any;
  isComplianceFileLink: any;
  SharedFolderUniqueUserId: any;
  isSharedFolder: any;

  GFMRootPath: any = '';
  disabledOnclick: boolean;
  files: any[] = [];
  parse(url: any): UrlTree {
    let dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    let dus = new DefaultUrlSerializer(),
      path = dus.serialize(tree);
    // use your regex to replace as per your requirement.
    return path.replace(/%2F/g, '/').replace(/%20/g, ' ');
  }

  constructor(
    private httpClient: HttpClient,
    private gfmservice: GfmService,
    private companyMasterService: CompanyMasterService,
    private meetingMasterService: MeetingMasterService,
    private userMasterService: UserMasterService,
    private gfmFilemanagerService: govevaFileManagerService,
    private modalService: NgbModal,
    private roadmapService: RoadmapModuleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonFunction: CommonFunction
  ) {}

  ngOnInit() {
    this.GFMRootPath = environment.GFMRootPath;
    // console.log("this.GFMRootPath",this.GFMRootPath)
    var self = this;
    this.loggedInUserUniqueId = JSON.parse(
      localStorage.getItem('user')
    ).UniqueUserId;
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.activatedRoute.queryParams.subscribe((params) => {
      this.isCompliance = params['isCompliance'];
      this.gfmRecordIdForFolder = params['gfmRecordId'];
      this.sharedFolder = params['sharedFolder'];
      if (this.isCompliance == undefined) {
        this.isCompliance = false;
      }
    });

    this.activatedRoute.params.subscribe((params) => {
      this.gfmRecordObject = {
        gfmRecordId: this.gfmRecordIdForFolder,
        path: params.internalPath,
      };
      if (this.gfmRecordId != undefined) {
        this.gfmRecordArray.push(this.gfmRecordObject);
        localStorage.setItem(
          'gfmRecordObject',
          safeJsonStringify(this.gfmRecordArray)
        );
        localStorage.setItem('gfmRecordId', this.gfmRecordIdForFolder);
        localStorage.setItem('path', params.internalPath);
      }

      this.params = params.internalPath;
      var newpath = this.params;
      if (newpath != undefined) {
        if (newpath.charAt(0) == '/') {
          newpath = newpath.substring(1, _.size(newpath));
        }
        this.breadcrumbs = newpath.split('/');
        if (_.size(this.breadcrumbs) > 0) {
          this.showBreadcrumbs = true;
        }
      }
    });

    let userInfo = JSON.parse(localStorage.getItem('user'));
    this.userId = JSON.parse(localStorage.getItem('user'))._id;
    this.uniqueUserId = JSON.parse(localStorage.getItem('user')).UniqueUserId;
    this.token = localStorage.getItem('token');
    this.companyID = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyID;
    this.companyName = JSON.parse(
      localStorage.getItem('companyDetails')
    ).companyName;
    this.governanceUrl = environment.nonValidateUrl;
    this.SharedFolderUniqueUserId = localStorage.getItem(
      'SharedFolderUniqueUserId'
    );
    this.isSharedFolder = localStorage.getItem('isSharedFolder');
    if (this.params != undefined) {
      var paramsSize = _.size(this.params);
      if (this.params.charAt(paramsSize - 1) == '/') {
        this.params = this.params.slice(0, -1);
      }
    } else {
      this.params = '';
    }
    // this.recordPath = "/home/ec2-user/GEM/GFM/" + this.uniqueUserId;
    // this.recordPath = this.GFMRootPath + this.uniqueUserId;
    this.recordPath = this.uniqueUserId;

    this.recordDetails = safeJsonStringify({
      recordPath: this.recordPath + this.params,
    });
    this.baseFileManagerPath = this.recordPath;
    this.baseFileManagerRecordPathWithParams = this.recordPath + this.params;

    this.companyMasterService
      .getCompanyList()
      .then((companyResponse) => {
        this.complianceEngine = companyResponse.companyData.ComplianceEngine;
        this.complianceEngineUrl =
          companyResponse.companyData.ComplianceEngineUrl;
        if (
          companyResponse.companyData.ClientLoginAPIUrl != undefined &&
          companyResponse.companyData.ClientLoginAPIUrl != '' &&
          _.size(companyResponse.companyData.ClientLoginAPIUrl) > 0
        ) {
          this.governanceUrl = companyResponse.companyData.ClientLoginAPIUrl;
        }

        if (
          companyResponse.companyData.GEMAccessURL != undefined &&
          companyResponse.companyData.GEMAccessURL != '' &&
          _.size(companyResponse.companyData.GEMAccessURL) > 0
        ) {
          this.gemAccessURL = companyResponse.companyData.GEMAccessURL;
        } else {
          //this.gemAccessURL = 'http://52.66.23.123:8085/api/';
          this.gemAccessURL = "http://" + environment.gemIp_domain + "/api/";
        }

        if (this.gfmRecordIdForFolder == undefined) {
          if (
            localStorage.getItem('isSharedFolder') == 'true' &&
            this.params != '/'
          ) {
            // this.recordPath = "/home/ec2-user/GEM/GFM/" + this.SharedFolderUniqueUserId;
            this.recordPath = this.GFMRootPath + this.SharedFolderUniqueUserId;
            this.recordDetails = safeJsonStringify({
              recordPath: this.recordPath + this.params,
            });
            this.getFilesAndFoldersAccordingToPath(
              this.recordDetails,
              this.complianceEngine,
              this.complianceEngineUrl,
              this.isCompliance
            );
          } else {
            this.getAllFilesAndFolders(
              this.recordDetails,
              this.complianceEngine,
              this.complianceEngineUrl,
              this.isCompliance
            );
          }
        } else {
          this.getAllFilesAndFoldersForSharedFolder(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.gfmRecordIdForFolder
          );
        }
      })
      .catch((error) => {});

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.getUserList();

    $(document).mouseup(function (e) {
      var container = $('.rightMenu');

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && _.size(container.has(e.target)) === 0) {
        container.hide();
      }
    });
  }


  //parth
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFile();
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    this.prepareFilesList($event);
  }

  getUserList() {
    this.roadmapService.getUserList().then((response) => {
      if (response.status == 0) {
        // console.log("response.result",response.result)
        response.result = response.result.filter(function (o) {
          return o.IsActive != false;
        });
        //check RoleName IC maker, IC Checker and remove it from report to list
        response.result = response.result.filter(function (userElement) {
          return (
            _.get(_.first(userElement.Roles), 'RoleName') != 'IC Maker' &&
            _.get(_.first(userElement.Roles), 'RoleName') != 'IC Checker'
          );
        });

        var users = response.result;
        users = response.result.filter((user)=>{return user.CompanyId == this.companyID});

        this.userListToBind = users.map(function (user) {
          return {
            label: user.FirstName + ' ' + user.LastName,
            value: user._id,
            unqiueUserId: user.UniqueUserId,
          };
        });
      } else {
      }
    });
  }

  getAllFilesAndFolders(
    recordDetails,
    complianceEngine,
    complianceEngineUrl,
    isCompliance
  ) {
    this.showSpinner = true;
    this.gfmservice
      .getAllFilesAndFolders(
        recordDetails,
        complianceEngine,
        complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL,
        isCompliance
      )
      .then((response) => {
        this.showSpinner = false;
        this.gfmResponse = response;
        this.fileList = this.gfmResponse.allFilesList;
        this.recentFileList = this.gfmResponse.allRecentFilesList;
        if (_.size(this.recentFileList) > 0) {
          for (var i = 0; i < _.size(this.recentFileList); i++) {
            this.recentFileList[i].isRecentFile = true;
          }
        }
        this.folderList = this.gfmResponse.allFoldersList;
        this.shareList = this.gfmResponse.allSharedWithFilesList;

        this.fileList = this.fileList.filter((file)=>{return file.companyId == this.companyID});
        this.recentFileList = this.recentFileList.filter((file)=>{return file.companyId == this.companyID});
        this.folderList = this.folderList.filter((file)=>{return file.companyId == this.companyID});
        this.shareList = this.shareList.filter((file)=>{return file.companyId == this.companyID});

        console.log(this.fileList, this.shareList);

        if (_.size(this.shareList) > 0) {
          this.showShareListDiv = true;
        } else {
          this.showShareListDiv = false;
        }

        if (_.size(this.shareList) > 0) {
          this.showShareListDiv = true;
        } else {
          this.showShareListDiv = false;
        }
      });
  }

  getAllFilesAndFoldersForSharedFolder(
    recordDetails,
    complianceEngine,
    complianceEngineUrl,
    gfmRecordIdForFolder
  ) {
    this.showSpinner = true;
    this.gfmservice
      .getAllFilesAndFoldersForSharedFolder(
        recordDetails,
        complianceEngine,
        complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL,
        gfmRecordIdForFolder
      )
      .then((response) => {
        this.showSpinner = false;
        this.gfmResponse = response;
        this.fileList = this.gfmResponse.allFilesList;
        this.recentFileList = this.gfmResponse.allRecentFilesList;
        if (_.size(this.recentFileList) > 0) {
          for (var i = 0; i < _.size(this.recentFileList); i++) {
            this.recentFileList[i].isRecentFile = true;
          }
        }
        this.folderList = this.gfmResponse.allFoldersList;
        this.shareList = this.gfmResponse.allSharedWithFilesList;

        this.fileList = this.fileList.filter((file)=>{return file.companyId == this.companyID});
        this.recentFileList = this.recentFileList.filter((file)=>{return file.companyId == this.companyID});
        this.folderList = this.folderList.filter((file)=>{return file.companyId == this.companyID});
        this.shareList = this.shareList.filter((file)=>{return file.companyId == this.companyID});

        if (_.size(this.shareList) > 0) {
          this.showShareListDiv = true;
        } else {
          this.showShareListDiv = false;
        }
      });
  }

  getFilesAndFoldersAccordingToPath(
    recordDetails,
    complianceEngine,
    complianceEngineUrl,
    isCompliance
  ) {
    this.showSpinner = true;
    this.gfmservice
      .getFilesAndFoldersAccordingToPath(
        recordDetails,
        complianceEngine,
        complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL,
        isCompliance
      )
      .then((response) => {
        this.showSpinner = false;
        this.gfmResponse = response;
        this.fileList = this.gfmResponse.allFilesList;
        this.recentFileList = this.gfmResponse.allRecentFilesList;
        if (_.size(this.recentFileList) > 0) {
          for (var i = 0; i < _.size(this.recentFileList); i++) {
            this.recentFileList[i].isRecentFile = true;
          }
        }
        this.folderList = this.gfmResponse.allFoldersList;
        this.shareList = this.gfmResponse.allSharedWithFilesList;

        this.fileList = this.fileList.filter((file)=>{return file.companyId == this.companyID});
        this.recentFileList = this.recentFileList.filter((file)=>{return file.companyId == this.companyID});
        this.folderList = this.folderList.filter((file)=>{return file.companyId == this.companyID});
        this.shareList = this.shareList.filter((file)=>{return file.companyId == this.companyID});

        if (_.size(this.shareList) > 0) {
          this.showShareListDiv = true;
        } else {
          this.showShareListDiv = false;
        }
      });
  }

  closeForm(id) {
    $('#' + id).modal('toggle');
    this.ngOnInit();
  }

  fileEvent(event) {
    console.log('this.filedata', this.filedata);
    var checkfile = this.commonFunction.checkFileExtension(event);
    if (checkfile) {
      this.filedata = <File>event.target.files[0];
      this.uploadFile();
      this.FileExtension = true;
    } else {
      this.FileExtension = false;
      var self = this;
      var modal = document.getElementById('myModal');
      modal.style.display = 'none';
      const modalRef = this.modalService.open(GfmconfirmationPopupComponent, {
        backdrop: 'static',
      });
      var message = 'Please upload valid file format.';
      modalRef.componentInstance.Message = message;
    }
    // this.filedata = <File>event.target.files[0];
  }

  uploadFile() {
    this.showSpinner=true;
    let formData = new FormData();
    for (let file of this.files) {
      formData.append('file', file);
    }
    
    this.gfmservice
      .postFileUploadForGFM(
        this.files,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((response) => {
        this.showSpinner=false;
        if (response == 409) {
          $('#uploadedSingleFileAlreadyPresent').modal('toggle');
        }
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      })
      .catch((error) => {});
    this.files.length = 0;  
  }

  deleteSelectedFile(event, file, index) {
    var checkthis = this.uploadMultipleFileDocument.splice(index, 1);
    var filesList: FileList = <FileList>this.uploadMultipleFileDocument;
    if (_.size(filesList) > 0) {
    } else {
      this.showAddTagButtonForCreateFolder = false;
    }
  }

  addNewFolder() {
    this.selectedTagsForFolder = [];
    this.showAddTagButtonForCreateFolder = false;
    this.uploadForm.controls.folderName.reset();
    this.multipleFileDocumentForUpload = '';
    this.uploadMultipleFileDocument = [];
    $('#myModal').modal('toggle');
  }

  multipleFileEvent(event) {
    var self = this;
    this.count = _.size(event.target.files);
    var i;
    for (i = 0; i < this.count; i++) {
      var checkfile = this.commonFunction.checkFileExtension(event);
      if (checkfile) {
        self.uploadMultipleFileDocument.push(<File>event.target.files[i]);
        this.multifileExtension = true;
      } else {
        this.multifileExtension = false;
      }
    }
    if (_.size(event.target.files) > 0) {
      this.showAddTagButtonForCreateFolder = true;
    } else {
      this.showAddTagButtonForCreateFolder = false;
    }
    if (_.size(self.uploadMultipleFileDocument) > 0) {
      self.uploadMultipleFileDocument = _.uniqBy(
        self.uploadMultipleFileDocument,
        'name'
      );
    }
  }

  getFolderName(event) {
    this.folderName = event.target.value;
    var self = this;
    var i;
    if (!_.isUndefined(this.gfmResponse)) {
      if (_.size(this.gfmResponse.allFoldersList) > 1) {
        for (i = 0; i < _.size(self.gfmResponse.allFoldersList); i++) {
          if (
            this.folderName.toLowerCase() ==
            self.gfmResponse.allFoldersList[i].recordName.toLowerCase()
          ) {
            self.recordNameAlreadyPresent = true;
            return;
          } else {
            self.recordNameAlreadyPresent = false;
          }
        }
      }
    }
    if (event.target.value.trim() == '') {
      this.uploadForm.controls.folderName.setValue(event.target.value.trim());
      this.uploadForm.controls.folderName.updateValueAndValidity();
    }
  }

  saveFolderNameWithFileUplaod() {
    this.disabledOnclick = true;
    var self = this;
    var tempPath = JSON.parse(this.recordDetails);
    let formData = new FormData();

    var tagsForFolder = [];
    if (_.size(this.selectedTagsForFolder) > 0) {
      _.forEach(self.selectedTagsForFolder, function (tag) {
        tagsForFolder.push(tag.value);
      });
    }

    this.recordDetails = safeJsonStringify({
      action: 'create',
      recordName: this.folderName,
      //"recordPath": "/home/ec2-user/GEM/GFM/" + this.uniqueUserId
      recordPath: tempPath.recordPath,
      tags: tagsForFolder,
    });

    this.gfmservice
      .createFolderForGFM(
        this.filedata,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((response) => {
        this.disabledOnclick = false;
        // console.log("response.recordName", response.gfmRecordrecordName)
        if (response) {
          this.recordDetails = safeJsonStringify({
            recordPath:
              tempPath.recordPath + '/' + response.gfmRecord.recordName,
            tags: tagsForFolder,
          });

          // console.log("this.recordDetails",this.recordDetails)

          var count1 = _.size(this.uploadMultipleFileDocument);
          var i = 0;
          for (i = 0; i < count1; i++) {
            formData.append('files', this.uploadMultipleFileDocument[i]);
          }
          formData.append('recordDetails', this.recordDetails);

          this.gfmservice
            .postMultipleFileUploadForGFM(
              formData,
              this.recordDetails,
              this.complianceEngine,
              this.complianceEngineUrl,
              this.governanceUrl,
              this.gemAccessURL
            )
            .then((response) => {
              if (response) {
                this.recordDetails = safeJsonStringify({
                  recordPath: tempPath.recordPath,
                });
                this.showSpinner = true;
                this.gfmservice
                  .getAllFilesAndFolders(
                    this.recordDetails,
                    this.complianceEngine,
                    this.complianceEngineUrl,
                    this.governanceUrl,
                    this.gemAccessURL,
                    this.isCompliance
                  )
                  .then((response) => {
                    this.showSpinner = false;
                    $('#myModal').modal('toggle');
                    if (response) {
                      $('#folderCreatedSuccessfully').modal('toggle');
                      this.gfmResponse = response;
                      if (_.size(this.gfmResponse.allFoldersList) == 0) {
                      }
                      this.fileList = this.gfmResponse.allFilesList;
                      this.recentFileList = this.gfmResponse.allRecentFilesList;
                      this.folderList = this.gfmResponse.allFoldersList;

                      this.fileList = this.fileList.filter((file)=>{return file.companyId == this.companyID});
                      this.recentFileList = this.recentFileList.filter((file)=>{return file.companyId == this.companyID});
                      this.folderList = this.folderList.filter((file)=>{return file.companyId == this.companyID});
                      this.shareList = this.shareList.filter((file)=>{return file.companyId == this.companyID});

                    }
                  });
              }
            });
        }
      });
  }

  getSelectedFolderFileList(folder, isSharedFolder, i) {
    if (_.size(this.breadcrumbs) == 1 && isSharedFolder == 'true') {
      localStorage.setItem('isSharedFolder', isSharedFolder);
    }
    this.shareRecords = isSharedFolder;
    localStorage.setItem('SharedFolderUniqueUserId', folder.uniqueUserId);
    this.isCompliance = folder.isComplianceFile;
    this.gfmRecordIdForFolder = folder.gfmRecordId;
    this.recordPath = folder.recordPath;
    var input = this.recordPath;
    var period = input.lastIndexOf('/');
    var pluginName = input.substring(0, period);
    this.fileExtension = input.substring(period + 1);
    var index = index + 1;

    var object = {
      gfmRecordIdForFolder: folder.gfmRecordId,
      recordName: folder.recordName,
      recordPath: folder.recordPath,
      isSharedFolder: isSharedFolder,
      index: index,
    };
    this.hierarchyArray.push({
      gfmRecordIdForFolder: folder.gfmRecordId,
      recordName: folder.recordName,
      recordPath: folder.recordPath,
      isSharedFolder: isSharedFolder,
      index: index,
    });

    if (this.fileExtension == folder.recordName) {
      // this.recordPath = "/home/ec2-user/GEM/GFM/" + folder.uniqueUserId;
      this.recordPath = this.GFMRootPath + folder.uniqueUserId;

      this.baseFileManagerPath = this.recordPath;
      this.recordPath = environment.GFMRootPath + folder.recordPath;
    } else {
      // this.baseFileManagerPath = folder.recordPath
      // this.recordPath = "/home/ec2-user/GEM/GFM/" + folder.uniqueUserId;
      this.recordPath = this.GFMRootPath + folder.uniqueUserId;

      this.baseFileManagerPath = this.recordPath;
      this.recordPath =
        environment.GFMRootPath + folder.recordPath + '/' + folder.recordName;
    }
    var sampleArray = [];
    sampleArray.push(folder);
    localStorage.setItem('sampleArray', _.toString(sampleArray));
    this.recordDetails = safeJsonStringify({
      recordPath: this.recordPath,
    });

    index = this.recordPath.indexOf(this.baseFileManagerPath);
    var pathstring = this.recordPath;
    var path = '';
    var newpath = '';
    if (index >= 0) {
      path = this.recordPath.slice(index, _.size(this.baseFileManagerPath));
      // var newpath = "";
      newpath = pathstring.substring(_.size(path), _.size(this.recordPath));
    } else {
      var firstSlashPosition = folder.recordPath.search('/') + 1;
      newpath = folder.recordPath.slice(firstSlashPosition);
    }

    if (isSharedFolder == 'true') {
      this.globaluniqueUserId = folder.uniqueUserId;
    }
    /*
    if (newpath.charAt(0) == '/') {
      newpath = newpath.substring(1, _.size(newpath));
    }

    if (newpath.charAt((_.size(newpath) - 1)) == '/') {
      newpath = newpath.substring(_.size(newpath) - 1, -1)
    }*/

    this.breadcrumbs = newpath.split('/');
    newpath = newpath.trim();
    var newLink = this.serialize(this.parse(newpath));
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    if (
      isSharedFolder == 'true' ||
      localStorage.getItem('isSharedFolder') == 'true'
    ) {
      this.router.navigate(
        [
          'structure',
          { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
        ],
        {
          queryParams: {
            gfmRecordId: this.gfmRecordIdForFolder,
            sharedFolder: 'true',
          },
        }
      );
    } else {
      this.router.navigate(
        [
          'structure',
          { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
        ],
        { queryParams: { isCompliance: this.isCompliance } }
      );
    }
    // //console.log("this.recordDetails", this.recordDetails)
    // below code has been commenedted out as breadcrum list will handle the reinitialisation part for the folders to be fetched
    // this.getAllFilesAndFolders(this.recordDetails, this.complianceEngine, this.complianceEngineUrl)
  }

  showRecentFileMenu(file, i, menuName) {
    $('#' + menuName + i).toggle('show');
  }

  showFolderMenu(file, i, menuName) {
    $('#' + menuName + i).toggle('show');
  }

  showAllFileMenu(file, i, menuName) {
    $('#' + menuName + i).toggle('show');
  }

  showShareFileMenu(file, i, menuName) {
    $('#' + menuName + i).toggle('show');
  }

  // rename file
  renameFileDialogBox(file, i, menuName) {
    this.fileAfterRenameSame = false;
    this.renameFileDetails = new Object();
    this.renameFileDetails = file;
    var tempPath = JSON.parse(this.recordDetails);
    $('#' + menuName + i).toggle('hide');
    this.fileNameWithExtension = file.recordName;
    this.recordPath = file.recordPath;
    var input = this.recordPath;
    var period = input.lastIndexOf('/');
    var pluginName = input.substring(0, period);
    this.fileExtension = input.substring(period + 1);

    if (this.fileExtension == this.fileNameWithExtension) {
      this.recordPath = pluginName;
    } else {
    }
    this.fileName = file.recordName;
    var inputs = this.fileName;
    var periods = inputs.lastIndexOf('.');
    var pluginNames = inputs.substring(0, periods);
    this.fileExtension = inputs.substring(periods + 1);
    this.fileName = pluginNames;
    $('#renameFileModal').modal('toggle');
  }

  renameFolderDialogBox(folder, i, menuName) {
    this.folderNamePresentWhileRename = false;
    $('#' + menuName + i).toggle('hide');
    $('#renameFolderModal').modal('toggle');
    this.folderNameAfterRename = folder.recordName;
    this.folderName = folder.recordName;
  }

  ngDoCheck() {
    if (_.size(this.breadcrumbs) <= 0) {
      // this.showBreadCrumpath = false;
    } else {
      if (_.size(this.breadcrumbs) == 1 && this.breadcrumbs[0] == '') {
        this.showBreadCrumpath = false;
      } else {
        this.showBreadCrumpath = true;
      }
    }
    if (_.size(this.selectedTagsForFolder) > 0) {
      this.showAddTagDiv = true;
    } else {
      this.showAddTagDiv = false;
    }
  }

  getNewFileName(event) {
    var fileRename = event.target.value;
    var self = this;
    var i;
    var input = this.fileNameWithExtension;
    var period = input.lastIndexOf('.');
    var pluginName = input.substring(0, period);
    var fileExtension = input.substring(period + 1);
    fileRename = fileRename + '.' + fileExtension;
    if (_.size(this.gfmResponse.allFilesList) > 0) {
      for (i = 0; i < _.size(this.gfmResponse.allFilesList); i++) {
        this.fileName = this.gfmResponse.allFilesList[i].recordName;
        if (fileRename == this.fileName) {
          this.fileAfterRenameSame = true;
          i = _.size(this.gfmResponse.allFilesList);
        } else {
          this.fileAfterRenameSame = false;
        }
      }
    }
    if (event.target.value.trim() == '') {
      this.uploadForm.controls.fileName.setValue(event.target.value.trim());
      this.uploadForm.controls.fileName.updateValueAndValidity();
    }
    this.fileName = event.target.value;
  }

  getNewFolderName(event) {
    var folderRename = event.target.value;
    var self = this;
    var i;
    if (_.size(this.gfmResponse.allFoldersList) > 0) {
      for (i = 0; i < _.size(this.gfmResponse.allFoldersList); i++) {
        if (
          folderRename.toLowerCase() ==
          this.gfmResponse.allFoldersList[i].recordName.toLowerCase()
        ) {
          self.folderNamePresentWhileRename = true;
          return;
        } else {
          self.folderNamePresentWhileRename = false;
          this.folderNameAfterRename = event.target.value;
        }
      }
    }
  }

  renameFileName() {
    $('#renameFileModal').modal('toggle');
    var tempPath = JSON.parse(this.recordDetails);
    this.fileName = this.fileName.trim() + '.' + this.fileExtension;
    // this.recordPath = this.GFMRootPath + this.uniqueUserId;
    this.recordPath = this.uniqueUserId + this.params;
    this.recordDetails = safeJsonStringify({
      action: 'rename',
      recordName: this.fileNameWithExtension,
      recordPath: this.recordPath,
      renameRecordName: this.fileName.trim(),
    });

    this.isComplianceFileParameter = this.renameFileDetails.isComplianceFile;
    this.gfmservice
      .renameOrRemoveFiles(
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL,
        this.isComplianceFileParameter
      )
      .then((response) => {
        if (response.status != 500) {
          this.recordDetails = safeJsonStringify({
            recordPath: tempPath.recordPath,
          });
          $('#renameFolderModalSuccessfull').modal('toggle');
          this.getAllFilesAndFolders(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.isCompliance
          );
        } else {
          this.recordDetails = safeJsonStringify({
            recordPath: tempPath.recordPath,
          });
        }
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      })
      .catch((error) => {
        this.recordDetails = safeJsonStringify({
          recordPath: tempPath.recordPath,
        });
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      });
  }

  renameFolderName() {
    $('#renameFolderModal').modal('toggle');
    var tempPath = JSON.parse(this.recordDetails);
    this.recordDetails = safeJsonStringify({
      action: 'rename',
      recordName: this.folderName,
      recordPath: tempPath.recordPath,
      renameRecordName: this.folderNameAfterRename.trim(),
    });
    this.gfmservice
      .createFolderForGFM(
        this.filedata,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((response) => {
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      });
  }

  // delete file
  confirmDeleteFileDialogBox(file, i) {
    var tempPath = JSON.parse(this.recordDetails);
    $('#' + i).toggle('show');
    this.fileName = file.recordName;
    this.recordPath = file.recordPath;
    var input = this.recordPath;
    var period = input.lastIndexOf('/');
    var pluginName = input.substring(0, period);
    this.fileExtension = input.substring(period + 1);
    if (file.recordName == this.fileExtension) {
      this.recordPath = pluginName;
    } else {
    }
    this.isComplianceFileParameter = file.isComplianceFile;
    $('#deleteFileModel').modal('toggle');
  }

  confirmDeleteSharedFileDialogBox(file, i) {
    this.recordType = file.recordType;
    $('#' + i).toggle('show');
    this.sharedFileName = file.recordName;
    this.gfmRecordId = file.gfmRecordId;
    this.uniqueUserId = file.uniqueUserId;
    if (this.sharedFolder == undefined) {
    } else {
      this.recordDetails = safeJsonStringify({
        recordPath: file.recordPath,
      });
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.gfmRecordIdForFolder = params['gfmRecordId'];
      this.sharedFolder = params['sharedFolder'];
    });
    $('#deleteSharedFileModel').modal('toggle');
  }

  confirmDeleteSharedFile() {
    $('#deleteSharedFileModel').modal('toggle');
    if (this.recordType == 'folder') {
      this.gfmservice
        .folderRemovalBySharedUser(
          this.complianceEngine,
          this.complianceEngineUrl,
          this.gfmRecordId,
          this.governanceUrl,
          this.gemAccessURL
        )
        .then((response) => {
          if (response) {
            $('#sharedFolderDeleteSuccessfully').modal('toggle');
            if (this.sharedFolder) {
              this.getAllFilesAndFoldersForSharedFolder(
                this.recordDetails,
                this.complianceEngine,
                this.complianceEngineUrl,
                this.gfmRecordIdForFolder
              );
            } else {
              this.getAllFilesAndFolders(
                this.recordDetails,
                this.complianceEngine,
                this.complianceEngineUrl,
                this.isCompliance
              );
            }
          }
        })
        .catch((error) => {});
    } else {
      this.gfmservice
        .fileRemovalBySharedUser(
          this.complianceEngine,
          this.complianceEngineUrl,
          this.gfmRecordId,
          this.governanceUrl,
          this.gemAccessURL
        )
        .then((response) => {
          if (response) {
            $('#sharedFileDeleteSuccessfully').modal('toggle');
            if (this.sharedFolder) {
              this.getAllFilesAndFoldersForSharedFolder(
                this.recordDetails,
                this.complianceEngine,
                this.complianceEngineUrl,
                this.gfmRecordIdForFolder
              );
            } else {
              this.getAllFilesAndFolders(
                this.recordDetails,
                this.complianceEngine,
                this.complianceEngineUrl,
                this.isCompliance
              );
            }
          }
        })
        .catch((error) => {});
    }
  }

  confirmDeleteFile() {
    $('#deleteFileModel').modal('toggle');
    var tempPath = JSON.parse(this.recordDetails);
    this.recordPath = this.uniqueUserId + this.params;
    this.recordDetails = safeJsonStringify({
      action: 'remove',
      recordName: this.fileName,
      recordPath: this.recordPath,
    });

    this.gfmservice
      .renameOrRemoveFiles(
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL,
        this.isComplianceFileParameter
      )
      .then((response) => {
        this.recordDetails = safeJsonStringify({
          action: 'remove',
          recordName: this.fileName,
          recordPath: tempPath.recordPath,
        });
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      });
  }

  // delete folder
  confirmDeleteFolderDialogBox(folder) {
    this.folderName = folder.recordName;
    $('#deleteFolderModel').modal('toggle');
  }

  confirmDeleteFolder() {
    $('#deleteFolderModel').modal('toggle');
    var tempPath = JSON.parse(this.recordDetails);
    this.recordDetails = safeJsonStringify({
      action: 'remove',
      recordName: this.folderName,
      recordPath: tempPath.recordPath,
    });
    this.gfmservice
      .createFolderForGFM(
        this.filedata,
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((response) => {
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
      });
  }

  // this will short the name if it is longer than set character
  sendShortName(fileRecordName) {
    if (_.size(fileRecordName) > 20) {
      return fileRecordName.substring(0, 20) + '...';
    } else {
      return fileRecordName;
    }
  }

  openTagPopupComponent(fileDetail, index, menuName) {
    var self = this;
    $('#' + menuName + index).toggle('hide');
    const modalRef = this.modalService.open(TagPopupComponentComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.fileDetail = fileDetail;
    modalRef.componentInstance.complianceEngine = this.complianceEngine;
    modalRef.componentInstance.complianceEngineUrl = this.complianceEngineUrl;
    modalRef.componentInstance.gfmRecordId = this.fileId;
    modalRef.componentInstance.governanceUrl = this.governanceUrl;
    modalRef.componentInstance.gemAccessURL = this.gemAccessURL;
    modalRef.componentInstance.isFolderCreation = false;
    modalRef.componentInstance.tagPopupCloseParameter.subscribe(
      (receivedEntry) => {
        self.navigationFunction(receivedEntry);
      }
    );
    modalRef.result.then((resp) => {});
  }

  // share file
  shareFileDialogBox(file, i) {
    // $('#' + i).toggle('show')
    this.fileId = file.gfmRecordId;
    var recordName = file.recordName;
    var complianceDocumentId = file.complianceDocumentId;
    const modalRef = this.modalService.open(GfmPopupforShareComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.ObjectToSend = this.userListToBind;
    modalRef.componentInstance.recordDetails = this.recordDetails;
    modalRef.componentInstance.recordName = recordName;
    modalRef.componentInstance.complianceEngine = this.complianceEngine;
    modalRef.componentInstance.complianceEngineUrl = this.complianceEngineUrl;
    modalRef.componentInstance.gfmRecordId = this.fileId;
    modalRef.componentInstance.fileDetails = file;
    modalRef.componentInstance.governanceUrl = this.governanceUrl;
    modalRef.componentInstance.complianceDocumentId = complianceDocumentId;
    modalRef.componentInstance.gemAccessURL = this.gemAccessURL;
    modalRef.componentInstance.sharedFolder = this.sharedFolder;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.gfmRecordIdForFolder = params['gfmRecordId'];
      this.sharedFolder = params['sharedFolder'];
    });
    modalRef.result.then(
      (resp) => {
        if (this.sharedFolder) {
          this.getAllFilesAndFoldersForSharedFolder(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.gfmRecordIdForFolder
          );
        } else {
          this.getAllFilesAndFolders(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.isCompliance
          );
        }
      },
      (error) => {
        if (this.sharedFolder) {
          this.getAllFilesAndFoldersForSharedFolder(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.gfmRecordIdForFolder
          );
        } else {
          this.getAllFilesAndFolders(
            this.recordDetails,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.isCompliance
          );
        }
      }
    );
  }

  getTypeToSerch(event) {
    this.searchByType = event.target.innerText;
  }

  searchFileName(event) {
    var regex = new RegExp('^[a-zA-Z0-9_  -]+$');

    console.log(regex.exec(event.target.value.trim()));
    if (event.target.value.trim() == '') {
      this.uploadForm.controls.searchByValue.setValue(
        event.target.value.trim().replace(regex, '')
      );
      this.uploadForm.controls.searchByValue.updateValueAndValidity();
      this.searchByValue = event.target.value.replace(regex, '');
    } else {
      this.uploadForm.controls.searchByValue.setValue(
        event.target.value.trim()
      );
      this.uploadForm.controls.searchByValue.updateValueAndValidity();
      this.searchByValue = event.target.value.trim();
    }
    var tempPath = JSON.parse(this.recordDetails);
    this.recordDetails = safeJsonStringify({
      action: this.searchByType.toLowerCase(),
      recordName: this.searchByValue,
      recordPath: tempPath.recordPath,
    });
    if (
      this.searchByValue != null &&
      this.searchByValue != undefined &&
      this.searchByValue != ''
    ) {
      this.gfmservice
        .searchFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.governanceUrl,
          this.gemAccessURL
        )
        .then((response) => {
          //this.searchResultsList = response.allSearchResultsList;

          var searchRespose = response;
          searchRespose.allFoldersList = response.allFoldersList ? 
                  searchRespose.allFoldersList.filter((f)=>{return f.companyId==this.companyID}) :  response.allFoldersList;
          searchRespose.allFilesList = response.allFilesList ? 
                  searchRespose.allFilesList.filter((f)=>{return f.companyId==this.companyID}) : response.allFilesList;
          searchRespose.allRecentFilesList = response.allRecentFilesList ? 
                  searchRespose.allRecentFilesList.filter((f)=>{return f.companyId==this.companyID}) : response.allRecentFilesList;
          searchRespose.allSharedWithFilesList = response.allSharedWithFilesList ? 
                  searchRespose.allSharedWithFilesList.filter((f)=>{return f.companyId==this.companyID}) : response.allSharedWithFilesList;
          searchRespose.allSearchResultsList = response.allSearchResultsList ? 
                  searchRespose.allSearchResultsList.filter((f)=>{return f.companyId==this.companyID}) : response.allSearchResultsList;
         
          this.searchResultsList = searchRespose.allSearchResultsList;

          if (this.searchResultsList.length) {
            $('.backgroundBlur').addClass('blur-filter');
          } else {
            $('.backgroundBlur').removeClass('blur-filter');
          }
        });
    } else {
      this.searchResultsList = [];
      $('.backgroundBlur').removeClass('blur-filter');
    }
  }

  deleteSelectedRecords(data) {
    this.isDeleteAllSelected = true;
  }

  cancleSelectedList() {
    this.isDeleteAllSelected = false;
  }

  confirmMultipleDelete() {
    $('#confirmMultipleDelete').modal('toggle');
  }

  getCheckedRecords(event, data) {
    if (data.isRecentFile == true) {
      data.recordPath = data.recordPath + '/' + data.recordName;
    }
    if (event.target.checked == true) {
      this.multipleRecordsListToDelete.push(data);
    } else {
      let indexOfItem = this.multipleRecordsListToDelete.findIndex(
        (o) => o.gfmRecordId === data.gfmRecordId
      );
      if (_.size(this.multipleRecordsListToDelete) > 0) {
        for (var i = 0; i < _.size(this.multipleRecordsListToDelete); i++) {
          if (
            this.multipleRecordsListToDelete[i].gfmRecordId == data.gfmRecordId
          ) {
          }
        }
      }
      if (indexOfItem > -1) {
        this.multipleRecordsListToDelete.splice(indexOfItem, 1);
      }
    }
    this.multipleRecordsListToDelete = _.uniqBy(
      this.multipleRecordsListToDelete,
      'gfmRecordId'
    );
  }

  deleteMultipleRecords() {
    var tempPath = JSON.parse(this.recordDetails);
    this.recordDetails = safeJsonStringify({
      allGFMRecords: this.multipleRecordsListToDelete,
    });
    this.gfmservice
      .multipleRemovalOfFilesAndFolders(
        this.recordDetails,
        this.complianceEngine,
        this.complianceEngineUrl,
        this.governanceUrl,
        this.gemAccessURL
      )
      .then((response) => {
        $('#confirmMultipleDelete').modal('toggle');
        this.recordDetails = safeJsonStringify({
          recordPath: tempPath.recordPath,
        });
        this.getAllFilesAndFolders(
          this.recordDetails,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.isCompliance
        );
        this.isDeleteAllSelected = false;
      });
  }

  downloadGFMFileDialogBox(fileDetails, index, menuName, isShared) {
    this.isComplianceFileLink = fileDetails.link;
    this.gfmRecordId = fileDetails.gfmRecordId;
    $(document).mouseup(function (e) {
      var container = $('#' + menuName + index);
      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && _.size(container.has(e.target)) === 0) {
        container.hide();
      }
    });
    /*    if (isShared =='true') {
      var path = `${fileDetails.recordPath}/${fileDetails.recordName}`
    }
    else {
      path = fileDetails.recordPath;

    }*/
    var path = `${fileDetails.recordPath}/${fileDetails.recordName}`;
    var stringLocation = path.indexOf(fileDetails.recordName);
    var recordPath = '';
    if (stringLocation >= 0) {
      recordPath = path.substring(stringLocation - 1, -1);
    } else {
      // recordPath = fileDetails.recordPath;
      recordPath = path;
    }
    //console.log('path',path)
    var object = {
      recordName: fileDetails.recordName,
      recordPath: recordPath,
      id: fileDetails.recordType == "folder" ? undefined : fileDetails.recordPath.split('/')[0]
    };
    if (fileDetails.recordType == 'file') {
      if (fileDetails.isComplianceFile == true) {
        this.gfmservice
          .downloadComplianceFile(
            object,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.governanceUrl,
            this.gemAccessURL,
            isShared,
            this.isComplianceFileLink
          )
          .then((responseDownloadFile) => {
            var blob = new Blob();
            blob = responseDownloadFile;
            var downloadUrl = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement(
              'a'
            ) as HTMLAnchorElement;
            a.href = downloadUrl;
            a.download = fileDetails.recordName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);
          });
      } else {
        this.gfmservice
          .downloadGFMFile(
            object,
            this.complianceEngine,
            this.complianceEngineUrl,
            this.governanceUrl,
            this.gemAccessURL,
            isShared,
            this.gfmRecordId
          )
          .then((responseDownloadFile) => {
            var blob = new Blob();
            blob = responseDownloadFile;
            var downloadUrl = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement(
              'a'
            ) as HTMLAnchorElement;
            a.href = downloadUrl;
            a.download = fileDetails.recordName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);
          });
      }
    } else {
      this.gfmservice
        .downloadZipFileById(
          object,
          this.complianceEngine,
          this.complianceEngineUrl,
          this.governanceUrl,
          this.gemAccessURL,
          isShared,
          this.gfmRecordId
        )
        .then((responseDownloadFile) => {
          var blob = new Blob();
          blob = responseDownloadFile;
          var downloadUrl = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement(
            'a'
          ) as HTMLAnchorElement;
          a.href = downloadUrl;
          a.download = fileDetails.recordName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(downloadUrl);
        });
    }
  }
  openFileInNewTab(fileDetails) {
    console.log("fileDetailsfileDetails", fileDetails);
    
    this.fileExtension = fileDetails.recordName.split(".").slice(-1);
    var path = `${fileDetails.recordPath}/${fileDetails.recordName}`;
    var stringLocation = path.indexOf(fileDetails.recordName);
    var recordPath = '';
    if (stringLocation >= 0) {
      recordPath = path.substring(stringLocation - 1, -1);
    } else {
      // recordPath = fileDetails.recordPath;
      recordPath = path;
    }      
    //open in new Tab
    var object = {
      recordName: fileDetails.recordName,
      recordPath: recordPath,
      id: fileDetails.recordPath.split('/')[0]
    };

    this.gfmservice
    .downloadGFMFile(
      object,
      this.complianceEngine,
      this.complianceEngineUrl,
      this.governanceUrl,
      this.gemAccessURL,
      'false',
      fileDetails.gfmRecordId
    )
    .then((responseDownloadFile) => {
      let blob = new Blob([responseDownloadFile],{type: "application/pdf"});
      var downloadUrl = URL.createObjectURL(blob);
      window.open(downloadUrl, '_blank');
      URL.revokeObjectURL(downloadUrl);

    });      

  }

  shareRecords() {
    $('#shareFileModal').modal('toggle');
  }

  serachFileRedirect(searchResult) {
    console.log('serachFileRedirect', searchResult);
    var tempPath = JSON.parse(this.recordDetails);
    if (searchResult.recordPath == tempPath.recordPath) {
      this.searchResultsList = [];
    }
    var recordPath = '';
    if (searchResult.recordType == 'folder') {
      recordPath = searchResult.recordPath + '/' + searchResult.recordName;
      this.recordDetails = safeJsonStringify({
        recordPath: searchResult.recordPath + '/' + searchResult.recordName,
      });
    } else {
      recordPath = searchResult.recordPath;
      this.recordDetails = safeJsonStringify({
        recordPath: searchResult.recordPath,
      });
    }

    var indexbackS = recordPath.indexOf('/');

    recordPath = this.replaceBetween(
      recordPath,
      0,
      indexbackS,
      this.baseFileManagerPath
    );

    var index = recordPath.indexOf(this.baseFileManagerPath);
    var pathstring = recordPath;
    var path = '';
    console.log(
      'recordPath,this.baseFileManagerPath',
      recordPath,
      this.baseFileManagerPath
    );
    if (index >= 0) {
      path = recordPath.slice(index, _.size(this.baseFileManagerPath));
      var newpath = '';
      console.log('path', path);
      console.log('recordPath', recordPath);
      console.log('pathstring', pathstring);
      newpath = pathstring.substring(_.size(path), _.size(recordPath));
    }
    console.log('newpath', newpath);
    if (newpath.charAt(0) == '/') {
      newpath = newpath.substring(1, _.size(newpath));
    }

    if (newpath.charAt(_.size(newpath) - 1) == '/') {
      newpath = newpath.substring(_.size(newpath) - 1, -1);
    }

    this.breadcrumbs = newpath.split('/');
    newpath = newpath.trim();
    var newLink = this.serialize(this.parse(newpath));
    this.gfmRecordIdForFolder = searchResult.gfmRecordId;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (searchResult.sharedDoc) {
      this.router.navigate(
        [
          'structure',
          { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
        ],
        {
          queryParams: {
            gfmRecordId: this.gfmRecordIdForFolder,
            sharedFolder: 'true',
          },
        }
      );
    } else {
      this.router.navigate(
        [
          'structure',
          { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
        ],
        { queryParams: { isCompliance: searchResult.isComplianceFile } }
      );
    }
    //below code has been commented out for breadcrum navigation which will handle aall routing and incoming get calls for that folder
    // this.gfmservice.getAllFilesAndFolders(this.recordDetails, this.complianceEngine, this.complianceEngineUrl).then(response => {
    //   //console.log("get response", response)
    //   this.gfmResponse = response;
    //   this.fileList = this.gfmResponse.allFilesList;
    //   this.recentFileList = this.gfmResponse.allRecentFilesList;
    //   this.folderList = this.gfmResponse.allFoldersList;
    //   this.uploadForm.controls.searchByValue.reset();
    //   this.searchResultsList = [];
    // })
  }

  replaceBetween(origin, startIndex, endIndex, insertion) {
    return (
      origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
    );
  }

  navigationLink(redirectionLink, index) {
    // console.log("redirectionLink",redirectionLink)
    // console.log("index",index)
    // console.log("breadcrumbs",this.breadcrumbs)
    this.isSharedFolder = localStorage.getItem('isSharedFolder');
    if (index == null) {
      localStorage.setItem('isSharedFolder', 'false');
    }
    var link = '';
    if (index == null) {
      link = '';
    } else {
      var count = 0;
      for (; count <= index; count++) {
        link = link.concat(this.breadcrumbs[count], '/').trim().toString();
      }
    }
    if (link.charAt(0) == '/') {
      link = link.substring(1, _.size(link));
    }

    var newLink = this.serialize(this.parse(link));
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (this.sharedFolder == true && index != null) {
      this.router.navigate(
        [
          'structure',
          { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
        ],
        {
          queryParams: {
            gfmRecordId: this.gfmRecordIdForFolder,
            sharedFolder: 'true',
          },
        }
      );
    } else {
      this.router.navigate([
        'structure',
        { outlets: { 'structure-outlet': ['gfmDashboard', newLink] } },
      ]);
    }
  }

  navigationFunction(recordPath) {
    var index = this.baseFileManagerRecordPathWithParams.indexOf(
      this.baseFileManagerPath
    );
    var pathstring = this.baseFileManagerRecordPathWithParams;
    var path = '';

    if (index >= 0) {
      path = this.baseFileManagerRecordPathWithParams.slice(
        index,
        _.size(this.baseFileManagerPath)
      );
      var newpath = '';
      newpath = pathstring.substring(
        _.size(path),
        _.size(this.baseFileManagerRecordPathWithParams)
      );
    }

    if (newpath.charAt(0) == '/') {
      newpath = newpath.substring(1, _.size(newpath));
    }

    if (newpath.charAt(_.size(newpath) - 1) == '/') {
      newpath = newpath.substring(_.size(newpath) - 1, -1);
    }

    var pathToParse = newpath;
    this.breadcrumbs = newpath.split('/');

    if (_.size(this.breadcrumbs) <= 1 && this.breadcrumbs[0] == '') {
      this.showBreadCrumpath = false;
    } else {
      this.showBreadCrumpath = true;
    }

    var newLink = this.serialize(this.parse(newpath));
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return true;
    };
    this.getAllFilesAndFolders(
      this.recordDetails,
      this.complianceEngine,
      this.complianceEngineUrl,
      this.isCompliance
    );
  }

  shareCheckType(file, i, p) {
    if (p == 'download') {
      this.downloadGFMFileDialogBox(file, i, 'shareFiles', 'true');
    }
    if (p == 'share') {
      this.shareFileDialogBox(file, i);
    }
  }

  tagPopForFolders() {
    var self = this;
    var modal = document.getElementById('myModal');
    modal.style.display = 'none';
    const modalRef = this.modalService.open(TagPopupComponentComponent, {
      backdrop: 'static',
    });

    var tagsToSend = [];
    if (_.size(this.selectedTagsForFolder)) {
      _.forEach(self.selectedTagsForFolder, function (element) {
        tagsToSend.push(element.value);
      });
    }

    var fileDetail = {
      tags: tagsToSend,
    };

    modalRef.componentInstance.isFolderCreation = true;
    modalRef.componentInstance.complianceEngine = this.complianceEngine;
    modalRef.componentInstance.complianceEngineUrl = this.complianceEngineUrl;
    modalRef.componentInstance.fileDetail = fileDetail;
    modalRef.componentInstance.governanceUrl = this.governanceUrl;
    modalRef.componentInstance.gemAccessURL = this.gemAccessURL;
    modalRef.result
      .then((resp) => {
        modal.style.display = 'initial';
        this.selectedTagsForFolder = resp;
        // $('#myModal').toggle('toggle');
      })
      .catch((error) => {
        modal.style.display = 'initial';
        // this.selectedTagsForFolder = resp;
      });
  }

  removeSelectedTagsForFolder(tag) {
    var self = this;
    if (_.size(this.selectedTagsForFolder) > 0) {
      var found = _.findIndex(self.selectedTagsForFolder, function (element) {
        return _.get(element, 'value') == tag.value;
      });
      if (found >= 0) {
        self.selectedTagsForFolder.splice(found, 1);
      }
    }
  }

  rightClick(event, file, i, menuName) {
    $('#' + menuName + i).toggle('show');
    event.preventDefault();
  }
}
