import {
  Component,
  OnInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { RocketChatService } from './../../../services/rocketChat.service';
import { SocketService } from './../../../services/socket.service';
import * as _ from 'lodash';
import * as io from 'socket.io-client';

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var Chart: any;

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css'],
})
export class ChatsComponent implements OnInit, AfterViewChecked {
  allUsersList: any = [];
  allChannelsList: any = [];
  communicatedUsersList: any = [];
  channelsList: any = [];
  messages: any = [];

  channelMembersList: any = [];
  membersListToAddToChannel: any = [];

  chatWindowStatus: string = '';
  selectedUserName: string = '';
  selectedUserId: string = '';
  selectedChannelName: string = '';
  selectedChannelId: string = '';
  isPartnerExists = false;
  isGroupExists = false;

  partnerUserId: string = '';
  partnerRoomId: string = '';

  userId: any = '';

  userListUpdateInterval: any;
  chatLatestMessagesInterval: any;
  userListRoomUpdateInterval: any;

  channelListUpdateInterval: any;

  lastUpdatedRoomTimeStamp: any;

  roomActive: any;

  @ViewChild('userChatMessageBox') private myUserScrollContainer: ElementRef;
  @ViewChild('channelChatMessageBox')
  private myChannelScrollContainer: ElementRef;

  constructor(
    private rocketChatService: RocketChatService,
    private socketService: SocketService
  ) {}

  ngOnInit() {
    //
    const self = this;
    this.userId = localStorage.getItem('rocketUserId');
    this.getUserData();
    this.getUsersList();
    this.getChannelsList();
    this.setToDefault();

    this.userListUpdateInterval = setInterval(function () {
      self.allUsersList = _.orderBy(
        self.allUsersList,
        ['newMessageCount', 'ts'],
        ['desc', 'desc']
      );
      // oldest
      if (
        self.allUsersList != undefined &&
        self.allUsersList != null &&
        _.size(self.allUsersList)
      ) {
        self.allUsersList.forEach((user) => {
          if (user.PartnerRoomId != undefined && user.PartnerRoomId != null) {
            var userObject = {
              _id: user._id,
              lastMessageDT: user.ts,
              status: user.status,
              PartnerRoomId: user.PartnerRoomId,
            };
            self.getUsersLatestChatData(userObject, 'UserWindow');
          }
        });
      } else {
      }
    }, 1000);

    this.userListRoomUpdateInterval = setInterval(function () {
      self.rocketChatService.getCommunicatedUsersList().then((dmChatData) => {
        if (dmChatData.success == true) {
          if (
            dmChatData.ims != undefined &&
            dmChatData.ims != null &&
            _.size(dmChatData.ims) > 0
          ) {
            if (
              self.allUsersList != undefined &&
              self.allUsersList != null &&
              _.size(self.allUsersList)
            ) {
              dmChatData.ims.forEach((dmData) => {
                var isExist =
                  _.size(
                    _.filter(self.allUsersList, function (o) {
                      return o.PartnerRoomId == dmData._id;
                    })
                  ) > 0
                    ? true
                    : false;
                if (!isExist) {
                  var thisUserId = localStorage.getItem('rocketUserId');
                  var partnerUserId = _.replace(dmData._id, thisUserId, '');
                  self.allUsersList.forEach((user) => {
                    if (user._id == partnerUserId) {
                      user.PartnerRoomId = dmData._id;
                    }
                  });
                }
              });
            }
          }
        }
      });
    }, 2000);

    this.channelListUpdateInterval = setInterval(function () {
      //
      self.allChannelsList = _.orderBy(
        self.allChannelsList,
        ['newMessageCount', '_updatedAt'],
        ['desc', 'desc']
      );
      if (
        self.allChannelsList != undefined &&
        self.allChannelsList != null &&
        _.size(self.allChannelsList) > 0
      ) {
        self.allChannelsList.forEach((currentChannel) => {
          var channelObject = {
            _id: currentChannel._id,
            lastMessageDT: currentChannel._updatedAt,
          };
          self.getChannelLatestChatData(channelObject, 'channelWindow');
        });
      }
    }, 1000);
  }

  ngAfterViewChecked() {
    // this.scrollToBottom();
  }

  //-------------------User CHAT Functions--------------------

  getUserData() {
    this.rocketChatService
      .getQuickUserInformation()
      .then((rocketChatResponse) => {
        //
        if (rocketChatResponse.success == true) {
          if (rocketChatResponse.status == 'offline') {
            this.chatWindowStatus = 'Offline';
          } else {
            this.chatWindowStatus = 'Online';
          }
        } else {
          this.chatWindowStatus = 'Offline';
        }
      });
  }

  getUsersList() {
    const self = this;
    this.allUsersList = [];
    this.userId = localStorage.getItem('rocketUserId');

    this.rocketChatService.getRoomsList().then((rocketChatData) => {
      if (rocketChatData.success == true) {
        if (
          rocketChatData.update != undefined &&
          rocketChatData.update != null &&
          _.size(rocketChatData.update) > 0
        ) {
          var roomsList: any = [];
          roomsList = rocketChatData.update;
          var directMessagesData: any = _.filter(roomsList, function (o) {
            return o.t === 'd';
          });
          var channelsData: any = _.filter(roomsList, function (o) {
            return o.t === 'c';
          });
          var privateChannelsData: any = _.filter(roomsList, function (o) {
            return o.t === 'p';
          });

          var latestUpdatedRoom: any = _.last(directMessagesData);
          // this.lastUpdatedRoomTimeStamp = latestUpdatedRoom._updatedAt;

          // this.allChannelsList = channelsData;
          // this.isGroupExists = (channelsData == undefined || channelsData == null || _.size(channelsData) <= 0) ? false : true;

          //Filter and Map Users
          this.rocketChatService.getUsersList().then((rocketChatData) => {
            if (rocketChatData.success == true) {
              if (
                rocketChatData.users != undefined &&
                rocketChatData.users != null &&
                _.size(rocketChatData.users) > 0
              ) {
                this.rocketChatService.getICUsersData().then((icUserData) => {
                  this.isPartnerExists = true;
                  var partnerUsersList: any = [];
                  partnerUsersList = rocketChatData.users;
                  _.remove(partnerUsersList, function (n: any) {
                    return n._id === self.userId || n._id === 'rocket.cat';
                  });
                  _.remove(partnerUsersList, function (n: any) {
                    return n.active === false;
                  });
                  _.remove(partnerUsersList, function (n: any) {
                    return _.toLower(n.username) === 'superadmin';
                  });

                  if (icUserData.status == 0) {
                    //username
                    if (_.size(icUserData.icUsers) > 0) {
                      icUserData.icUsers.forEach((icUser) => {
                        _.remove(partnerUsersList, function (n: any) {
                          return n.username === icUser.RocketChatUserName;
                        });
                      });
                    }
                  }

                  this.allUsersList = partnerUsersList;

                  if (
                    directMessagesData != undefined &&
                    directMessagesData != null &&
                    _.size(directMessagesData) > 0
                  ) {
                    this.communicatedUsersList = directMessagesData;

                    this.allUsersList.forEach((usersData) => {
                      var roomExists = false;

                      this.communicatedUsersList.forEach(
                        (directMessageRoomData) => {
                          var partnerUserData: any;
                          var directMessageRoomId = directMessageRoomData._id;
                          var partnersId = _.replace(
                            directMessageRoomId,
                            self.userId,
                            ''
                          );
                          if (
                            partnersId != self.userId &&
                            partnersId != 'rocket.cat' &&
                            partnersId == usersData._id
                          ) {
                            // partnerUserData = _.filter(partnerUsersList, function (o) { return o._id === partnersId });
                            roomExists = true;
                            usersData.PartnerRoomId = directMessageRoomId;
                            usersData.newMessageCount = 0;
                            //
                            usersData.ts = directMessageRoomData._updatedAt;
                            // this.allUsersList.push(partnerUserData[0]);
                          }
                        }
                      );
                      if (!roomExists) {
                        usersData.newMessageCount = 0;
                        usersData.ts = usersData.createdAt;
                      }
                    });
                  } else {
                    this.allUsersList.forEach((usersData) => {
                      usersData.newMessageCount = 0;
                      usersData.ts = usersData.createdAt;
                    });
                  }
                });
              } else {
                this.isPartnerExists = false;
              }
            } else {
              this.isPartnerExists = false;
            }
          });
        } else {
          this.rocketChatService.getUsersList().then((rocketChatData) => {
            if (rocketChatData.success == true) {
              if (
                rocketChatData.users != undefined &&
                rocketChatData.users != null &&
                _.size(rocketChatData.users) > 0
              ) {
                this.rocketChatService.getICUsersData().then((icUserData) => {
                  this.isPartnerExists = true;
                  var partnerUsersList: any = [];
                  partnerUsersList = rocketChatData.users;
                  _.remove(partnerUsersList, function (n: any) {
                    return n._id === self.userId || n._id === 'rocket.cat';
                  });
                  _.remove(partnerUsersList, function (n: any) {
                    return _.toLower(n.username) === 'superadmin';
                  });

                  if (icUserData.status == 0) {
                    //username
                    if (_.size(icUserData.icUsers) > 0) {
                      icUserData.icUsers.forEach((icUser) => {
                        _.remove(partnerUsersList, function (n: any) {
                          return n.username === icUser.RocketChatUserName;
                        });
                      });
                    }
                  }

                  this.allUsersList = partnerUsersList;

                  this.allUsersList.forEach((usersData) => {
                    usersData.newMessageCount = 0;
                    usersData.ts = usersData.createdAt;
                  });
                });
              } else {
                this.isPartnerExists = false;
              }
            } else {
              this.isPartnerExists = false;
            }
          });
        }
      } else {
        this.isPartnerExists = false;
      }
    });
    this.allUsersList = _.orderBy(this.allUsersList, ['ts'], ['desc']);
  }

  getUsersLatestChatData(reqUserObject, windowStatus) {
    //
    this.userId = localStorage.getItem('rocketUserId');

    this.rocketChatService
      .getLatestMessages(reqUserObject)
      .then((messagesHistoryData) => {
        // this.partnerRoomId = partnerRoomId;
        // this.partnerUserId = partnerUserID;
        if (messagesHistoryData.success == true) {
          if (
            messagesHistoryData.messages != undefined &&
            messagesHistoryData.messages != null &&
            _.size(messagesHistoryData.messages) > 0
          ) {
            var latestMessages = _.sortBy(messagesHistoryData.messages, ['ts']);
            var lastlatestMessage: any = _.last(latestMessages);
            if (windowStatus == 'InChatWindow') {
              //
              this.allUsersList.forEach((user) => {
                if (user._id == reqUserObject._id) {
                  user.newMessageCount = 0;
                  user.ts =
                    lastlatestMessage == undefined
                      ? Date.now()
                      : lastlatestMessage.ts;
                }
              });

              this.messages = this.messages.concat(latestMessages);
              // this.scrollToBottomUserChat();
            } else if (windowStatus == 'UserWindow') {
              this.allUsersList.forEach((user) => {
                if (user._id == reqUserObject._id) {
                  user.newMessageCount = _.size(messagesHistoryData.messages);
                }
              });
            }
          } else {
            var lastRecord: any = _.last(this.messages);
            if (windowStatus == 'InChatWindow') {
              //
              this.allUsersList.forEach((user) => {
                if (user._id == reqUserObject._id) {
                  user.newMessageCount = 0;
                  user.ts =
                    lastRecord == undefined ? Date.now() : lastRecord.ts;
                }
              });
              // this.scrollToBottomUserChat();
            } else if (windowStatus == 'UserWindow') {
              this.allUsersList.forEach((user) => {
                if (user._id == reqUserObject._id) {
                  user.newMessageCount = _.size(messagesHistoryData.messages);
                }
              });
            }
          }
        }
      });
  }

  getUserChatData(partnerUserID, partnerRoomId) {
    //
    const self = this;
    this.userId = localStorage.getItem('rocketUserId');
    this.messages = [];

    if (
      partnerRoomId != undefined &&
      partnerRoomId != null &&
      partnerRoomId != ''
    ) {
      this.rocketChatService
        .getMessagesCompleteHistory(partnerRoomId, 100)
        .then((messagesHistoryData) => {
          this.partnerRoomId = partnerRoomId;
          this.partnerUserId = partnerUserID;
          if (messagesHistoryData.success == true) {
            if (
              messagesHistoryData.messages != undefined &&
              messagesHistoryData.messages != null &&
              _.size(messagesHistoryData.messages) > 0
            ) {
              this.messages = _.sortBy(messagesHistoryData.messages, ['ts']);
            }
          }
          let userData = _.filter(this.allUsersList, function (o) {
            return o._id === partnerUserID;
          });
          this.selectedUserName = userData[0].name;
          this.selectedUserId = partnerUserID;

          $('#switch').css('display', 'none');
          $('#userListContainer').css('display', 'none');
          $('#userChatContainer').css('display', 'block');
          $('#messageBox').val('');
          $('#messageBox').focus();

          this.chatLatestMessagesInterval = setInterval(function () {
            //
            if (
              self.messages != undefined &&
              self.messages != null &&
              _.size(self.messages) > 0
            ) {
              var lastMessage: any = _.last(self.messages);
              var userObject = {
                _id: self.selectedUserId,
                lastMessageDT: lastMessage.ts,
                PartnerRoomId: self.partnerRoomId,
              };
              self.getUsersLatestChatData(userObject, 'InChatWindow');
            } else {
              var ts = Date.now();
              var userObj = {
                _id: self.selectedUserId,
                lastMessageDT: ts,
                PartnerRoomId: self.partnerRoomId,
              };
              self.getUsersLatestChatData(userObj, 'InChatWindow');
            }
          }, 2000);

          if (this.userListUpdateInterval) {
            clearInterval(this.userListUpdateInterval);
          }
          if (this.userListRoomUpdateInterval) {
            clearInterval(this.userListRoomUpdateInterval);
          }
          // if (this.channelListUpdateInterval) {
          //     clearInterval(this.channelListUpdateInterval);
          // }

          this.scrollToBottomUserChat();
        });
    } else {
      var messageObject = {
        roomId: partnerUserID,
      };
      this.rocketChatService.postMessage(messageObject).then((messagesData) => {
        if (messagesData.success == true) {
          this.partnerRoomId = messagesData.message.rid;
          this.partnerUserId = partnerUserID;
          this.allUsersList.forEach((userData) => {
            if (userData._id == partnerUserID) {
              userData.PartnerRoomId = messagesData.message.rid;
              userData.newMessageCount = 0;
              userData.ts = messagesData.message._updatedAt;
            }
          });
          if (
            messagesData.messages != undefined &&
            messagesData.messages != null &&
            _.size(messagesData.messages) > 0
          ) {
            this.messages.push(messagesData.message);
          }

          let userData = _.filter(this.allUsersList, function (o) {
            return o._id === partnerUserID;
          });
          this.selectedUserName = userData[0].name;
          this.selectedUserId = partnerUserID;

          $('#switch').css('display', 'none');
          $('#userListContainer').css('display', 'none');
          $('#userChatContainer').css('display', 'block');

          this.chatLatestMessagesInterval = setInterval(function () {
            //
            var lastMessage: any = _.last(self.messages);
            var userObject = {
              _id: self.selectedUserId,
              lastMessageDT: lastMessage.ts,
              PartnerRoomId: self.partnerRoomId,
            };
            self.getUsersLatestChatData(userObject, 'InChatWindow');
          }, 2000);

          if (this.userListUpdateInterval) {
            clearInterval(this.userListUpdateInterval);
          }
          if (this.userListRoomUpdateInterval) {
            clearInterval(this.userListRoomUpdateInterval);
          }
          // if (this.channelListUpdateInterval) {
          //     clearInterval(this.channelListUpdateInterval);
          // }

          $('#messageBox').val('');
          $('#messageBox').focus();
          this.scrollToBottomUserChat();
        }
      });
    }
  }

  getBackToUsersList() {
    const self = this;
    if (this.chatLatestMessagesInterval) {
      clearInterval(this.chatLatestMessagesInterval);
    }
    this.messages = [];
    this.selectedUserName = '';
    this.selectedUserId = '';
    $('#userListContainer').css('display', 'block');
    $('#userChatContainer').css('display', 'none');
    $('#switch').css('display', 'block');

    this.rocketChatService
      .getMessagesCompleteHistory(this.partnerRoomId, 1)
      .then((messagesHistoryData) => {
        if (messagesHistoryData.success == true) {
          if (
            messagesHistoryData.messages != undefined &&
            messagesHistoryData.messages != null &&
            _.size(messagesHistoryData.messages) > 0
          ) {
            //
            this.userListUpdateInterval = setInterval(function () {
              if (
                self.allUsersList != undefined &&
                self.allUsersList != null &&
                _.size(self.allUsersList)
              ) {
                self.allUsersList.forEach((user) => {
                  user.ts =
                    messagesHistoryData.ts == undefined
                      ? user.ts
                      : messagesHistoryData.ts;
                  if (
                    user.PartnerRoomId != undefined &&
                    user.PartnerRoomId != null
                  ) {
                    var userObject = {
                      _id: user._id,
                      lastMessageDT: user.ts,
                      status: user.status,
                      PartnerRoomId: user.PartnerRoomId,
                    };
                    self.getUsersLatestChatData(userObject, 'UserWindow');
                  }
                });
              }
            }, 1000);

            this.userListRoomUpdateInterval = setInterval(function () {
              self.rocketChatService
                .getCommunicatedUsersList()
                .then((dmChatData) => {
                  if (dmChatData.success == true) {
                    if (
                      dmChatData.ims != undefined &&
                      dmChatData.ims != null &&
                      _.size(dmChatData.ims) > 0
                    ) {
                      if (
                        self.allUsersList != undefined &&
                        self.allUsersList != null &&
                        _.size(self.allUsersList)
                      ) {
                        dmChatData.ims.forEach((dmData) => {
                          var isExist =
                            _.size(
                              _.filter(self.allUsersList, function (o) {
                                return o.PartnerRoomId == dmData._id;
                              })
                            ) > 0
                              ? true
                              : false;
                          if (!isExist) {
                            var thisUserId =
                              localStorage.getItem('rocketUserId');
                            var partnerUserId = _.replace(
                              dmData._id,
                              thisUserId,
                              ''
                            );
                            self.allUsersList.forEach((user) => {
                              if (user._id == partnerUserId) {
                                user.PartnerRoomId = dmData._id;
                              }
                            });
                          }
                        });
                      }
                    }
                  }
                });
            }, 2000);

            // this.channelListUpdateInterval = setInterval(function () {
            //
            //     if (self.allChannelsList != undefined && self.allChannelsList != null && _.size(self.allChannelsList)) {
            //         self.allChannelsList.forEach(channel => {
            //             var channelObject = {
            //                 _id: channel._id,
            //                 lastMessageDT: channel.ts
            //             }
            //             self.getChannelLatestChatData(channelObject, "channelWindow");
            //         });
            //     }
            // }, 1000);
          }
        }
      });
  }

  scrollToBottomUserChat(): void {
    try {
      //
      setTimeout(() => {
        document.querySelector('#userChatMessageBox').scrollTop =
          document.querySelector('#userChatMessageBox').scrollHeight;
      }, 100);
    } catch (err) {}
  }

  //-------------------User CHAT Functions END--------------------

  //-------------------Channel CHAT Functions--------------------

  getChannelsList() {
    this.rocketChatService.getChannelListJoinedByUser().then((channelData) => {
      if (channelData.success == true) {
        if (
          channelData.channels != undefined &&
          channelData.channels != null &&
          _.size(channelData.channels) > 0
        ) {
          this.allChannelsList = channelData.channels;
          this.isGroupExists =
            channelData.channels == undefined ||
            channelData.channels == null ||
            _.size(channelData.channels) <= 0
              ? false
              : true;

          this.allChannelsList.forEach((channel) => {
            channel.newMessageCount = 0;
          });

          this.allChannelsList = _.orderBy(
            this.allChannelsList,
            ['newMessageCount', '_updatedAt'],
            ['desc', 'desc']
          );
        }
      }
    });
  }

  showCreateGroupSection() {
    $('#switch').css('display', 'none');
    $('#userContainer').css('display', 'none');
    $('#channelContainer').css('display', 'block');

    $('#userListContainer').css('display', 'block');
    $('#userChatContainer').css('display', 'none');

    $('#channelListContainer').css('display', 'none');
    $('#channelChatContainer').css('display', 'none');
    $('#membersListSection').css('display', 'none');
    $('#createCompanySection').css('display', 'block');

    $('#groupOptions').css('display', 'none');
  }

  backFromCreateGroup() {
    $('#switch').css('display', 'block');
    $('#userContainer').css('display', 'none');
    $('#channelContainer').css('display', 'block');

    $('#userListContainer').css('display', 'block');
    $('#userChatContainer').css('display', 'none');

    $('#channelListContainer').css('display', 'block');
    $('#channelChatContainer').css('display', 'none');
    $('#membersListSection').css('display', 'none');
    $('#createCompanySection').css('display', 'none');

    $('#groupOptions').css('display', 'block');
  }

  createChannel() {
    const self = this;
    if ($('#groupName').val().trim() != '') {
      var channelObject = {
        name: $('#groupName').val(),
      };

      this.rocketChatService
        .createChannel(channelObject)
        .then((channelData) => {
          if (channelData.success == true) {
            if (
              channelData.channel != undefined &&
              channelData.channel != null
            ) {
              channelData.channel.newMessageCount = 0;
              this.allChannelsList.push(channelData.channel);
            }
            this.backFromCreateGroup();
          }
        });
    }
  }

  getChannelChatData(channelId) {
    //
    const self = this;
    this.userId = localStorage.getItem('rocketUserId');
    this.messages = [];

    if (channelId != undefined && channelId != null && channelId != '') {
      this.rocketChatService
        .getChannelCompleteMsgHistory(channelId, 100)
        .then((messagesHistoryData) => {
          if (messagesHistoryData.success == true) {
            if (
              messagesHistoryData.messages != undefined &&
              messagesHistoryData.messages != null &&
              _.size(messagesHistoryData.messages) > 0
            ) {
              this.messages = _.sortBy(messagesHistoryData.messages, ['ts']);
              _.remove(this.messages, function (n: any) {
                return (
                  n.msg === '' || n.t === 'uj' || n.t === 'au' || n.t === 'ru'
                );
              });
            }
          }
          let channelData = _.filter(this.allChannelsList, function (o) {
            return o._id === channelId;
          });
          this.selectedChannelName = channelData[0].name;
          this.selectedChannelId = channelId;

          $('#switch').css('display', 'none');
          $('#channelListContainer').css('display', 'none');
          $('#channelChatContainer').css('display', 'block');
          $('#membersListSection').css('display', 'none');
          $('#createCompanySection').css('display', 'none');

          $('#grpMessageBox').val('');
          $('#grpMessageBox').focus();

          this.chatLatestMessagesInterval = setInterval(function () {
            //
            if (
              self.messages != undefined &&
              self.messages != null &&
              _.size(self.messages) > 0
            ) {
              var lastMessage: any = _.last(self.messages);
              var channelObject = {
                _id: self.selectedChannelId,
                lastMessageDT: lastMessage.ts,
              };
              self.getChannelLatestChatData(channelObject, 'InChatWindow');
            } else {
              var ts = Date.now();
              var channelObj = {
                _id: self.selectedChannelId,
                lastMessageDT: ts,
              };
              self.getChannelLatestChatData(channelObj, 'InChatWindow');
            }
          }, 2000);

          // if (this.userListUpdateInterval) {
          //     clearInterval(this.userListUpdateInterval);
          // }
          // if (this.userListRoomUpdateInterval) {
          //     clearInterval(this.userListRoomUpdateInterval);
          // }
          if (this.channelListUpdateInterval) {
            clearInterval(this.channelListUpdateInterval);
          }

          this.scrollToBottomChannelChat();
        });
    }
  }

  getChannelLatestChatData(reqUserObject, windowStatus) {
    //
    this.userId = localStorage.getItem('rocketUserId');

    this.rocketChatService
      .getChannelLatestMessges(reqUserObject)
      .then((messagesHistoryData) => {
        if (messagesHistoryData.success == true) {
          if (
            messagesHistoryData.messages != undefined &&
            messagesHistoryData.messages != null &&
            _.size(messagesHistoryData.messages) > 0
          ) {
            var latestMessages = _.sortBy(messagesHistoryData.messages, ['ts']);
            _.remove(latestMessages, function (n: any) {
              return (
                n.msg === '' || n.t === 'uj' || n.t === 'au' || n.t === 'ru'
              );
            });
            var lastlatestMessage: any = _.last(latestMessages);
            if (windowStatus == 'InChatWindow') {
              //
              this.allChannelsList.forEach((channel) => {
                if (channel._id == reqUserObject._id) {
                  channel.newMessageCount = 0;
                  channel.ts =
                    lastlatestMessage == undefined
                      ? Date.now
                      : lastlatestMessage.ts;
                }
              });

              this.messages = this.messages.concat(latestMessages);
              // this.scrollToBottomChannelChat();
            } else if (windowStatus == 'channelWindow') {
              this.allChannelsList.forEach((channel) => {
                if (channel._id == reqUserObject._id) {
                  channel.newMessageCount = _.size(latestMessages);
                }
              });
            }
          } else {
            var lastRecord: any = _.last(this.messages);
            if (windowStatus == 'InChatWindow') {
              //
              this.allChannelsList.forEach((channel) => {
                if (channel._id == reqUserObject._id) {
                  channel.newMessageCount = 0;
                  channel.ts =
                    lastRecord == undefined ? Date.now() : lastRecord.ts;
                }
              });
              // this.scrollToBottomChannelChat();
            } else if (windowStatus == 'channelWindow') {
              this.allChannelsList.forEach((channel) => {
                if (channel._id == reqUserObject._id) {
                  channel.newMessageCount = _.size(latestMessages);
                }
              });
            }
          }
        }
      });
  }

  getBackToChannelsList() {
    const self = this;
    if (this.chatLatestMessagesInterval) {
      clearInterval(this.chatLatestMessagesInterval);
    }
    this.messages = [];
    this.selectedChannelName = '';
    // this.selectedChannelId = "";
    $('#channelListContainer').css('display', 'block');
    $('#channelChatContainer').css('display', 'none');
    $('#membersListSection').css('display', 'none');
    $('#createCompanySection').css('display', 'none');
    $('#switch').css('display', 'block');

    // this.rocketChatService.getChannelCompleteMsgHistory(this.selectedChannelId, 1).then(messagesHistoryData => {
    //     if (messagesHistoryData.success == true) {
    //         if (messagesHistoryData.messages != undefined && messagesHistoryData.messages != null && _.size(messagesHistoryData.messages) > 0) {
    //
    this.channelListUpdateInterval = setInterval(function () {
      //
      if (
        self.allChannelsList != undefined &&
        self.allChannelsList != null &&
        _.size(self.allChannelsList)
      ) {
        self.allChannelsList.forEach((channel) => {
          var channelObject = {
            _id: channel._id,
            lastMessageDT: channel._updatedAt,
          };
          self.getChannelLatestChatData(channelObject, 'channelWindow');
        });
      }
    }, 1000);
    //         }
    //     }
    // });
  }

  scrollToBottomChannelChat(): void {
    try {
      //
      setTimeout(() => {
        document.querySelector('#channelChatMessageBox').scrollTop =
          document.querySelector('#channelChatMessageBox').scrollHeight;
      }, 100);
    } catch (err) {}
  }

  channelFunctions(selectedOption) {
    this.channelMembersList = [];
    this.membersListToAddToChannel = [];
    var rocketUserId = localStorage.getItem('rocketUserId');
    if (selectedOption == 'MembersList') {
      this.rocketChatService
        .getChannelMembersList(this.selectedChannelId)
        .then((membersData) => {
          if (membersData.success == true) {
            if (
              membersData.members != undefined &&
              membersData.members != null &&
              _.size(membersData.members) > 0
            ) {
              this.channelMembersList = membersData.members;

              $('#channelChatContainer').css('display', 'none');
              $('#membersListSection').css('display', 'block');
            }
          }
        });
    } else if (selectedOption == 'InviteUser') {
      //
      this.rocketChatService.getUsersList().then((userDataList) => {
        if (userDataList.success == true) {
          if (
            userDataList.users != undefined &&
            userDataList.users != null &&
            _.size(userDataList.users) > 0
          ) {
            this.rocketChatService.getICUsersData().then((icUserData) => {
              var usersList: any = userDataList.users;
              _.remove(usersList, function (n: any) {
                return n._id === rocketUserId || n._id === 'rocket.cat';
              });
              _.remove(usersList, function (n: any) {
                return n.active === false;
              });
              _.remove(usersList, function (n: any) {
                return _.toLower(n.username) === 'superadmin';
              });

              if (icUserData.status == 0) {
                //username
                if (_.size(icUserData.icUsers) > 0) {
                  icUserData.icUsers.forEach((icUser) => {
                    _.remove(usersList, function (n: any) {
                      return n.username === icUser.RocketChatUserName;
                    });
                  });
                }
              }

              this.rocketChatService
                .getChannelMembersList(this.selectedChannelId)
                .then((membersData) => {
                  if (membersData.success == true) {
                    if (
                      membersData.members != undefined &&
                      membersData.members != null &&
                      _.size(membersData.members) > 0
                    ) {
                      var channelMmbrList = membersData.members;
                      channelMmbrList.forEach((channelMember) => {
                        _.remove(usersList, function (n: any) {
                          return n._id === channelMember._id;
                        });
                      });

                      this.membersListToAddToChannel = usersList;

                      $('#channelChatContainer').css('display', 'none');
                      $('#inviteUserToChannelSection').css('display', 'block');
                    } else {
                      this.membersListToAddToChannel = usersList;

                      $('#channelChatContainer').css('display', 'none');
                      $('#inviteUserToChannelSection').css('display', 'block');
                    }
                  } else {
                    this.membersListToAddToChannel = usersList;

                    $('#channelChatContainer').css('display', 'none');
                    $('#inviteUserToChannelSection').css('display', 'block');
                  }
                });
            });
          }
        }
      });
    }
  }

  inviteUserToChannel(userId) {
    var userObject = {
      roomId: this.selectedChannelId,
      userId: userId,
    };
    this.rocketChatService
      .inviteUserToChannel(userObject)
      .then((channelData) => {
        if (channelData.success == true) {
          if (channelData.channel != undefined && channelData.channel != null) {
            _.remove(this.membersListToAddToChannel, function (o: any) {
              return o._id === userId;
            });
          }
        }
      });
  }

  removeUserFromChannel(userId) {
    var userObject = {
      roomId: this.selectedChannelId,
      userId: userId,
    };
    this.rocketChatService
      .removeUserFromChannel(userObject)
      .then((channelData) => {
        if (channelData.success == true) {
          if (channelData.channel != undefined && channelData.channel != null) {
            _.remove(this.channelMembersList, function (o: any) {
              return o._id === userId;
            });
          }
        }
      });
  }

  getBackToChannelFromMembersList() {
    $('#channelListContainer').css('display', 'none');
    $('#channelChatContainer').css('display', 'block');
    $('#membersListSection').css('display', 'none');
    $('#inviteUserToChannelSection').css('display', 'none');
  }

  //-------------------Channel CHAT Functions END--------------------

  setToDefault() {
    this.roomActive = 'User';

    $('#switch').css('display', 'block');
    $('#userContainer').css('display', 'block');
    $('#channelContainer').css('display', 'none');

    $('#userListContainer').css('display', 'block');
    $('#userChatContainer').css('display', 'none');

    $('#channelListContainer').css('display', 'block');
    $('#channelChatContainer').css('display', 'none');
    $('#membersListSection').css('display', 'none');
    $('#createCompanySection').css('display', 'none');

    $('#oneToOneSelector').addClass('selected');
    $('#groupSelector').removeClass('selected');

    $('#groupOptions').css('display', 'none');
  }

  sendMessage(section) {
    //
    var messageText: string = '';
    var roomID: string = '';
    if (section == 'directMsg') {
      messageText = $('#messageBox').val();
      roomID = this.partnerRoomId;
    } else if (section == 'groupMsg') {
      messageText = $('#grpMessageBox').val();
      roomID = this.selectedChannelId;
    }

    if (messageText != '') {
      var messageObject = {
        roomId: roomID, //(this.partnerRoomId == undefined || this.partnerRoomId == null || this.partnerRoomId == "") ? this.selectedChannelId : this.partnerRoomId,
        channel: '',
        text: messageText,
        alias: '',
        emoji: '',
        avatar: '',
        attachments: [],
      };
      this.rocketChatService.sendMessage(messageObject).then((messagesData) => {
        //
        if (messagesData.success == true) {
          // this.socket.emit('save-message', messagesData.message);
          this.messages.push(messagesData.message);
          $('#messageBox').val('');
          $('#messageBox').focus();
          $('#grpMessageBox').val('');
          $('#grpMessageBox').focus();

          // $('#chatMessageBox').stop().animate({ scrollTop: $("#chatMessageBox")[0].scrollHeight }, 100);
          if (this.roomActive == 'User') {
            this.scrollToBottomUserChat();
          } else if (this.roomActive == 'Channel') {
            this.scrollToBottomChannelChat();
          }

          // this.socketService.sendMessage(messageObject);
        }
      });
    }
  }

  switchBetweenChatSection(sectionName) {
    this.messages = [];
    if (sectionName == 'OneToOne') {
      this.setToDefault();
    } else if (sectionName == 'Group') {
      this.roomActive = 'Channel';

      $('#switch').css('display', 'block');
      $('#userContainer').css('display', 'none');
      $('#channelContainer').css('display', 'block');

      $('#userListContainer').css('display', 'block');
      $('#userChatContainer').css('display', 'none');

      $('#channelListContainer').css('display', 'block');
      $('#channelChatContainer').css('display', 'none');
      $('#membersListSection').css('display', 'none');
      $('#createCompanySection').css('display', 'none');

      $('#oneToOneSelector').removeClass('selected');
      $('#groupSelector').addClass('selected');

      $('#groupOptions').css('display', 'block');
    }
  }

  deleteSelectedMessage(messageId, sectionType) {
    var roomId: string = '';
    if (sectionType == 'directMessage') {
      roomId = this.partnerRoomId;
    } else if (sectionType == 'channelMessage') {
      roomId = this.selectedChannelId;
    }

    var messageObject = {
      roomId: roomId,
      msgId: messageId,
    };
    this.rocketChatService
      .deleteMessageById(messageObject)
      .then((messageData) => {
        if (messageData.success == true) {
          _.remove(this.messages, function (o: any) {
            return o._id === messageData._id;
          });
        }
      });
  }

  editMessageOptionSelected(messageId, sectionType) {
    $('#' + messageId + 'msg').css('display', 'none');
    $('#' + messageId + 'txt').css('display', 'none');

    // var roomId: string = "";
    // if (sectionType == "directMessage") {
    //     roomId = this.partnerRoomId;
    // } else if (sectionType == "channelMessage") {
    //     roomId = this.selectedChannelId;
    // }

    // var messageObject = {
    //     "roomId": roomId,
    //     "msgId": messageId
    // }
    // this.rocketChatService.deleteMessageById(messageObject).then(messageData => {
    //     if (messageData.success == true) {
    //         _.remove(this.messages, function (o: any) { return o._id === messageData._id });
    //     }
    // });
  }

  deleteAllMessages(sectionType) {
    var roomId: string = '';
    if (sectionType == 'directMessage') {
      roomId = this.partnerRoomId;
    }

    var messageObject = {
      roomId: roomId,
    };
    this.rocketChatService
      .deleteAllMessages(messageObject)
      .then((messageData) => {
        if (messageData.success == true) {
          this.messages = [];
          _.remove(this.messages, function (o: any) {
            return o._id === messageData._id;
          });
        }
      });
  }
}
