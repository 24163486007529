import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input('question') question: any;
  @Input('forIDP') forIDP: boolean;
  @Input('forIDPTable') forIDPTable: boolean
  @Input('IDPTableData') IDPTableData: any
  @Output() uploadOrDeleteEvent = new EventEmitter();
  
  fileName = '';
  fileUrl: string;
  fileUploadObject: any;
  currentUser : any;
  currentIDPUserInfo: any;
  questionLocked: boolean;

  constructor(
    private http: HttpClient) {
      
    }

  onFileSelected(event) {
    let fileArray = [...event.target.files]
    fileArray.map(file=>this.uploadFile(file)) 
    this.fileUploadObject = '';
    this.uploadOrDeleteEvent.emit(this.fileUploadObject);
  }

  uploadFile(file){
    this.fileName = file.name;
    const formData = new FormData();
    formData.append('files', file);

    const questionId = this.question._id;
    if (this.forIDP) {
      // this.questionService.fileUploadIDP(formData, questionId, this.currentUser).then((response) => {
      // });  
    }
    else {
      // this.questionService.fileUpload(formData, questionId).then((response) => {
      // });        

    }

  }

  removeSelectedFile($event, evidenceDocument) {
    const id = this.question._id;
    const fileName = evidenceDocument.OriginalFileName
    
    if (evidenceDocument.CopiedMetaData){
      // this.questionService.multiRemoveFileMetaData(id, fileName).then((response) => {
      // });      
    }
    else if (this.forIDP) {
      // this.questionService.multiRemoveFileIDP(id, fileName, this.currentUser).then((response) => {
      // });

    }
    else {
      // this.questionService.multiRemoveFile(id, fileName).then((response) => {
      // });

    }

    this.fileUploadObject = '';
    this.uploadOrDeleteEvent.emit(this.fileUploadObject);    

  }

  downloadFile($event, evidenceDocument) {
    let fileName = evidenceDocument.OriginalFileName
    if (this.forIDP) {
      // this.questionService
      // .downloadMultiEvidenceIDP(this.question._id, fileName, this.currentUser)
      // .subscribe((response) => {
      //     const downloadLink = document.createElement('a');
      //     downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
  
      //     const contentDisposition = response.headers.get('Content-Disposition');
      //     const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().slice(1,-1);
      //     downloadLink.download = fileName;
      //     downloadLink.click();
      // });    
    }

    else if (this.IDPTableData) {
      // this.questionService
      // .downloadMultiEvidenceIDP(this.question._id, fileName, this.IDPTableData.EmailId)
      // .subscribe((response) => {
      //     const downloadLink = document.createElement('a');
      //     downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
  
      //     const contentDisposition = response.headers.get('Content-Disposition');
      //     const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().slice(1,-1);
      //     downloadLink.download = fileName;
      //     downloadLink.click();
      // });    
    }

    else {
      // this.questionService
      // .downloadMultiEvidence(this.question._id, fileName)
      // .subscribe((response) => {
      //     const downloadLink = document.createElement('a');
      //     downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
  
      //     const contentDisposition = response.headers.get('Content-Disposition');
      //     const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().slice(1,-1);
      //     downloadLink.download = fileName;
      //     downloadLink.click();
      // });    

    }
  }

  copyEvidenceToQuestion($event, evidenceDocument) {
    const fileName = evidenceDocument.OriginalFileName
    let evidenceData = this.IDPTableData.EvidenceDocuments.filter(file=>file.OriginalFileName==fileName)[0]
    evidenceData.CopiedMetaData = true
    // this.questionService.copyEvidence(evidenceData,  this.question._id).then((response) => {
    // });
    this.fileUploadObject = '';
    this.uploadOrDeleteEvent.emit(this.fileUploadObject);    


  }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem('CopyEmailAddress')
    if (this.forIDP) {
      this.currentIDPUserInfo = this.question.aggregatedAnswers.filter(item=>item.EmailId==this.currentUser)[0]
    } 
    this.questionLocked = (this.question.QuestionStatus != 'Not Answered' && this.question.QuestionStatus != 'Marked')
  }

  ngOnChanges(): void {
    this.currentUser = localStorage.getItem('CopyEmailAddress')
    if (this.forIDP) {
      this.currentIDPUserInfo = this.question.aggregatedAnswers.filter(item=>item.EmailId==this.currentUser)[0]
    } 
    this.questionLocked = (this.question.QuestionStatus != 'Not Answered' && this.question.QuestionStatus != 'Marked')
  }

}
