<section class="card mb-3" order-id="card-6">
    <div class="card-header">
        <div class="pull-right cat__core__sortable__control">
            <i class="icmn-minus mr-2 cat__core__sortable__collapse" data-toggle="tooltip" data-placement="left" title="" data-original-title="Minimize"></i>
            <i class="icmn-plus mr-2 cat__core__sortable__uncollapse" data-toggle="tooltip" data-placement="left" title="" data-original-title="Expand"></i>
        </div>
        <h5 class="mb-0 mr-3 d-inline-block" >
            Chat
        </h5>
    </div>

    <div class="card-block pt-0 pl-0 pr-0" style="height: 200px; overflow: none;">
        <div id="switch" class="pt-2 pb-1">
            <span id="oneToOneSelector" (click)="switchBetweenChatSection('OneToOne')" class="p-1 pl-2 pr-2 ml-2 mr-2 heading-button">
                <img alt="" src="../../../../assets/images/DefaultImage.png" style="width:15px;" class="mr-2">User List</span>
            <span id="groupSelector" (click)="switchBetweenChatSection('Group')" class="p-1 pl-2 pr-2 ml-2 heading-button">
                <img alt="" src="../../../../assets/images/DefaultGroup.png" style="width:15px;" class="mr-2">Group List</span>
            <span id="groupOptions" style="display: none;" class="mr-3 pull-right dropdown">
                <span aria-expanded="false" class="dropdown-notification-toggle" data-toggle="dropdown" style="cursor: pointer;" title="Options"><i class="fa fa-ellipsis-v"></i></span>
            <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">
                <a class="dropdown-item" href="javascript:void(0)" (click)="showCreateGroupSection()">Create Group</a>
            </ul>
            </span>
        </div>
        <div id="userContainer">

            <div id="userListContainer">
                <div style="height: 166px; overflow: auto;" class="task-custom-scroll mCustomScrollbar">
                    <div *ngIf="isPartnerExists == true" class="pl-2 pr-2">
                        <div class="row" *ngFor="let user of allUsersList;let count=index" class="userContainer">
                            <div id="{{user._id}}" (click)="getUserChatData(user._id, user.PartnerRoomId)" style="cursor: pointer;">
                                <img src="../../../../assets/images/DefaultImage.png" alt="" style="width:100%;">
                                <div class="pt-1" style="text-transform: uppercase;">
                                    {{user.name}}
                                    <span *ngIf="user.newMessageCount!=0" style="background: #ff100a; color: #fff; border-radius: 50%; padding: 1px 6px; font-weight: bold; font-size: 12px;">{{user.newMessageCount}}</span>
                                    <i *ngIf="user.status=='online'" class="fa fa-circle" title="Online" style="color: #2de0a5; float: right;"></i>
                                    <i *ngIf="user.status=='away'" class="fa fa-circle" title="Away" style="color: #ffd21f; float: right;"></i>
                                    <i *ngIf="user.status=='busy'" class="fa fa-circle" title="Busy" style="color: #f5455c; float: right;"></i>
                                    <i *ngIf="user.status=='offline'" class="fa fa-circle" title="Offline" style="color: #cbced1; float: right;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isPartnerExists == false" class="p-4" style="text-align: center;">No users available!</div>
                </div>
            </div>

            <div id="userChatContainer">
                <div class="pt-2 pb-2" style="border-bottom: 1px solid #3b3554; margin-bottom: 6px;">
                    <span (click)="getBackToUsersList()" style="cursor: pointer;">
                        <i class="fa fa-arrow-left pt-1 pr-2" style="float: left;"></i>
                        <img alt="" src="" style="width:100%; ">
                    </span>
                    <span class="pl-3" style="text-transform: uppercase; color: #c1c0ca; font-weight: bold;">
                        {{selectedUserName}}
                    </span>
                </div>

                <div id="userChatMessageBox" class="card-block pl-0 pr-0 pt-2 pb-1 chat-custom-scroll mCustomScrollbarasd">
                    <div *ngIf="messages">
                        <div *ngFor="let message of messages">
                            <div *ngIf="message.msg!=''">
                                <div *ngIf="userId != message.u._id">
                                    <div class="container pt-2">

                                        <p class="mb-0">{{message.msg}}</p>
                                        <span class="time-right" style="font-size: 10px; color: #151323;">{{message.ts | date : 'shortTime'}}</span>
                                    </div>
                                </div>
                                <div *ngIf="userId == message.u._id">
                                    <div class="container darker pt-2 mr-1">
                                        <span class="time-right dropdown">
                                                    <span aria-expanded="false" class="dropdown-notification-toggle" data-toggle="dropdown" style="cursor: pointer;" title="Options"><i class="fa fa-ellipsis-v"></i></span>
                                        <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">

                                            <a class="dropdown-item" href="javascript:void(0)" (click)="deleteSelectedMessage(message._id, 'directMessage')">
                                                        <i class="fa fa-trash mr-2" style="color: #e84845;"></i>Delete</a>
                                        </ul>
                                        </span>
                                        <p id="{{message._id}}msg" class="mb-0">{{message.msg}}</p>
                                        <span class="time-right" style="font-size: 10px; color: #151323;">{{message.ts | date : 'shortTime'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!messages" class="p-4" style="text-align: center;">No Messages!</div>
                </div>

                <div>
                    <input id="messageBox" type="text" class="form-control" (keyup.enter)="sendMessage('directMsg')" style="width:90%; float: left;">
                    <i class="fa fa-paper-plane sendMessage__icon" (click)="sendMessage('directMsg')"></i>
                </div>
            </div>

        </div>

        <div id="channelContainer">

            <div id="channelListContainer">
                <div style="height: 166px; overflow: auto;">
                    <div *ngIf="isGroupExists == true">
                        <div class="row" *ngFor="let channel of allChannelsList;let count=index" class="userContainer">
                            <div id="{{channel._id}}" (click)="getChannelChatData(channel._id)" style="cursor: pointer;">
                                <img src="../../../../assets/images/DefaultGroup.png" alt="" style="width:100%;">
                                <div class="pt-1" style="text-transform: uppercase;">
                                    {{channel.name}}
                                    <span *ngIf="channel.newMessageCount!=0" style="background: #ff100a; color: #fff; border-radius: 50%; padding: 1px 6px; font-weight: bold; font-size: 12px;">{{channel.newMessageCount}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isGroupExists == false" class="p-4" style="text-align: center;">No groups available!</div>
                </div>
            </div>

            <div id="channelChatContainer">
                <div class="pt-2 pb-2" style="border-bottom: 1px solid #3b3554; margin-bottom: 6px;">
                    <span (click)="getBackToChannelsList()" style="cursor: pointer;">
                        <i class="fa fa-arrow-left pt-1 pr-2" style="float: left;"></i>
                        <img alt="" src="" style="width:100%;">
                    </span>
                    <span class="pl-3" style="text-transform: uppercase; color: #c1c0ca; font-weight: bold;">
                        {{selectedChannelName}}
                    </span>
                    <span class="mr-3 pull-right dropdown">
                        <span aria-expanded="false" class="dropdown-notification-toggle" data-toggle="dropdown" style="cursor: pointer;" title="Options"><i class="fa fa-ellipsis-v"></i></span>
                    <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">
                        <a class="dropdown-item" href="javascript:void(0)" (click)="channelFunctions('MembersList')">
                                <img src="../../../../assets/images/DefaultGroup.png" alt="" style="width:15px;" class="mr-2">Members List</a>
                        <div class="dropdown-divider m-0"></div>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="channelFunctions('InviteUser')">
                                <i class="fa fa-plus mr-2" style="width: 15px; color: #149ea8;"></i>Invite User</a>
                    </ul>
                    </span>
                </div>

                <div id="channelChatMessageBox" class="card-block pl-0 pr-0 pt-2 pb-1 chat-custom-scroll mCustomScrollbarasd">
                    <div *ngIf="messages">
                        <div *ngFor="let message of messages">
                            <div>
                                <div *ngIf="userId != message.u._id">
                                    <div class="container pt-2">
                                        <span class="time-right mr-2" style="font-size: 10px; color: #151323;">{{message.u.name}}</span>
                                        <p class="mb-0">{{message.msg}}</p>
                                        <span class="time-right" style="font-size: 10px; color: #151323;">{{message.ts | date : 'shortTime'}}</span>
                                    </div>
                                </div>
                                <div *ngIf="userId == message.u._id">
                                    <div class="container darker pt-2 mr-1">
                                        <span class="time-right dropdown">
                                                    <span aria-expanded="false" class="dropdown-notification-toggle" data-toggle="dropdown" style="cursor: pointer;" title="Options"><i class="fa fa-ellipsis-v"></i></span>
                                        <ul aria-labelledby="" class="dropdown-menu dropdown-menu-right p-0 dropdown-control-pointing" role="menu">

                                            <a class="dropdown-item" href="javascript:void(0)" (click)="deleteSelectedMessage(message._id, 'channelMessage')">
                                                        <i class="fa fa-trash mr-2" style="color: #e84845;"></i>Delete</a>
                                        </ul>
                                        </span>
                                        <span class="time-right mr-2" style="font-size: 10px; color: #151323;">You</span>
                                        <p class="mb-0">{{message.msg}}</p>
                                        <span class="time-right" style="font-size: 10px; color: #151323;">{{message.ts | date : 'shortTime'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!messages" class="p-4" style="text-align: center;">No Messages!</div>
                </div>

                <div>
                    <input id="grpMessageBox" type="text" class="form-control" (keyup.enter)="sendMessage('groupMsg')" style="width:90%; float: left;">
                    <i class="fa fa-paper-plane sendMessage__icon" (click)="sendMessage('groupMsg')"></i>
                </div>
            </div>

            <div id="membersListSection">
                <div class="pt-2 pb-2" style="border-bottom: 1px solid #3b3554; margin-bottom: 6px;">
                    <span (click)="getBackToChannelFromMembersList()" style="cursor: pointer;">
                            <i class="fa fa-arrow-left pt-1 pr-2" style="float: left;"></i>
                            <img alt="" src="" style="width:100%;">
                        </span>
                    <span class="pl-3" style="text-transform: uppercase; color: #c1c0ca; font-weight: bold;">
                            {{selectedChannelName}} (Members list)
                        </span>
                </div>
                <div *ngIf="channelMembersList" style="height: 156px; overflow: auto;">
                    <div class="row" *ngFor="let member of channelMembersList;let count=index" class="userContainer">
                        <div id="{{member._id}}">
                            <img alt="" src="../../../../assets/images/DefaultImage.png" style="width:100%;">
                            <span class="pt-1" style="text-transform: uppercase;">
                                {{member.name}}
                            </span>
                            <div class="pull-right inviteButton" (click)="removeUserFromChannel(member._id)">Remove</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!channelMembersList" class="p-4" style="text-align: center;">
                    Add members to group!
                </div>
            </div>

            <div id="inviteUserToChannelSection" style="display: none;">
                <div class="pt-2 pb-2" style="border-bottom: 1px solid #3b3554; margin-bottom: 6px;">
                    <span (click)="getBackToChannelFromMembersList()" style="cursor: pointer;">
                                <i class="fa fa-arrow-left pt-1 pr-2" style="float: left;"></i>
                                <img alt="" src="" style="width:100%;">
                            </span>
                    <span class="pl-3" style="color: #c1c0ca; font-weight: bold;">
                        Invite users to <span style="text-transform: uppercase;">{{selectedChannelName}}</span>
                    </span>
                </div>
                <div *ngIf="membersListToAddToChannel" style="height: 156px; overflow: auto;">
                    <div class="row" *ngFor="let member of membersListToAddToChannel;let count=index" class="userContainer">
                        <div id="{{member._id}}">
                            <img alt="" src="../../../../assets/images/DefaultImage.png" style="width:100%;">
                            <span class="pt-1" style="text-transform: uppercase;">
                                {{member.name}}
                            </span>
                            <div class="pull-right inviteButton" (click)="inviteUserToChannel(member._id)">Invite</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!membersListToAddToChannel" class="p-4" style="text-align: center;">
                    No user available!
                </div>
            </div>

            <div id="createCompanySection">
                <div class="pt-2 pb-2" style="border-bottom: 1px solid #3b3554; margin-bottom: 6px;">
                    <span (click)="backFromCreateGroup()" style="cursor: pointer;">
                                <i class="fa fa-arrow-left pt-1 pr-2" style="float: left;"></i>
                                <img alt="" src="" style="width:100%;">
                            </span>
                    <span class="pl-3" style="text-transform: uppercase; color: #c1c0ca; font-weight: bold;">
                                Create Group
                            </span>
                </div>
                <div>
                    <input id="groupName" type="text" class="form-control mt-4 mb-2" placeholder="Group Name">
                    <button class="btn btn-sm btn-primary pull-right mr-2" (click)="createChannel()">Create</button>
                </div>
            </div>

        </div>
    </div>
</section>
