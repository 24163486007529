<section id="webExSection" style="background:#000;width:100% !important;height:100vh !important;">

    <div class="container-fluid" style="height: 100% !important;">
        <!-- LOADER -->
        <div id="cover-spin">
            <div class="container" style="height: 200px;position: relative;">
                <div class="vertical-center"
                    style="margin: 0;position: absolute;top: 240%;text-align:center;width: 100%;padding: 0 20px;">
                    Please wait for a few seconds, while the system checks and ensures a secure meeting environment for you
                </div>
            </div>
        </div>
        <div class="row" style="height: 100% !important;">
            <div id="sec1" class="col-lg-6 col-sm-6 col-xs-12" style="padding:0px !important;height: 100% !important;overflow: hidden;">

            </div>

            <div id="sec2" class="col-lg-6 col-sm-6 col-xs-12"
                style="padding:0px !important;height: 100% !important;background-color: #d9efee; overflow: hidden; display: flex; flex-direction: column;">
                <div class="main-img" style="padding:0px !important; overflow: auto;">
                    <iframe id="iframeWind" frameBorder="0" style="width: 100%;height: 100vh !important;" src=""></iframe>
                </div>
            </div>
            <div id="ControlBtn" class="col-lg-12 col-sm-12 col-xs-12" style="position: absolute;bottom: 0;left: 0;right: 0;clear: both;padding:0px !important;display:none;">
                <div class="inner-box-old">
                  <div class="row thumb-img" id="innerVideo">        
                    <div id="contorl-buts" class="col-md-12 text-center" style="height:50px;background-color: #D9EfEE; ">
                     
                        <!-- <button class="btn btn-default btn-link" (click)="leaveMeeting();" class="btn btn-default btn-link" style="position: relative;font-size: 14px;font-weight: 500;cursor: pointer;background:black !important;border-radius: 5px;margin-right: 10px;">
                            Leave Meeting</button> -->

                        <button id="leaveMeeting" title="Leave Meeting" (click)="leaveMeeting();">
                                <i style="background: #ff0000;color: white;" class="fa fa-phone"></i>
                        </button>
                      <button id="dashboard" type="button" (click)="openIfram();">
                          <i style="color: white;" title="Dashboard" class="fa fa-dashboard"></i>
                      </button>
                      <button id="iframeBackButton" type="button" (click)="openMeetingNew();">
                          <i style="color: white;" title="Meeting Details" class="fa fa-info"></i>
                      </button>
                   
                    </div>
                  </div>
                </div>        
               </div>  
        </div>
    </div>

</section>